import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from './redux/store/store';
import Utils from 'root/utils';
import './i18n';
import 'react-toastify/dist/ReactToastify.min.css';

import App from './App';

const isMobile = Utils.force2Mobile();
const cssStyle = isMobile ? './assets/96c/mobile.scss' : './assets/96c/desktop.scss';

import(/* webpackMode: "lazy" */ `${cssStyle}`).then(() => {
    const FooterBottom = lazy(() => import(`./webapp/layout/footer/component/desktop/FooterBottom`));

    window.cssLoaded = true;
    let app = (
        <Provider store={store}>
            <App></App>
        </Provider>
    );

    let footerBtm = (
        <Provider store={store}>
            <BrowserRouter>
                <Suspense fallback={<div></div>}>
                    <FooterBottom />
                </Suspense>
            </BrowserRouter>
        </Provider>
    );

    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

    let initAppInterval = setInterval(() => {
        // wait appjs load together
        if (window.initApp) {
            if (!window.location.pathname.includes('/game')) {
                // hide when using game page
                ReactDOM.render(footerBtm, document.getElementById('footer-btm'));
            }

            clearInterval(initAppInterval);
        }
    }, 100);

    ReactDOM.render(app, document.getElementById('root'));
});
