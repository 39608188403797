import React from 'react';
import LotteryBanner from './components/LotteryBanner';
import LotteryGames from './components/LotteryGames';

class LotteryPanel extends React.Component {
    render() {
        return (
            <div className='provider-panel'>
               <LotteryBanner />
               <LotteryGames />
            </div>
        );
    }
}

export default LotteryPanel;
