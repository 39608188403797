// react/library
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';

// components/files
import TransactionNote from 'root/webapp/shared/transaction-note/transaction-note.selector';

// controller/logic
import vm from '../../express-deposit.controller';
import BankIcon from '../../../../../../../../components/molecules/bank-icon/bank-icon';

class QrCodePay extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
    }

    componentDidMount() {
        vm.initCompSettings();
        if (this.state.depositAllSettings !== null) {
            vm.loadExpressDepositFilteredBank();
            vm.loadBankAccounts();
            vm.getCorrectDepositMinMaxLimit();
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    componentDidUpdate() {}

    render() {
        const {
            showAllBankMaintenaceMsg,
            showNotBankSupportMsg,
            expressDepositMemberBankList,
            expressDepositMerchantBankList,
            verifyAccountList,
            isFormValid,
            fields,
            verifyBank,
        } = this.state;

        let transactionNoteProps = {
            hideDisplayInfo: this.state.compSettings.hideDisplayInfo,
            customOnlineTransactionMode: this.props.t('transaction:transaction.deposit.onlinetransfer.online', 'Online'),
            depositMinMaxLimit: this.state.depositMinMaxLimit,
            showCustomArticle: this.state.showCustomDepositArticle,
            customArticleContent: this.state.showCustomDepositArticleContent,
            customArticleIsJson: this.state.customArticleIsJson,
            customContentStyle: this.state.customContentStyle,
            hideDefaultNote: [!this.state.showDefaultArticle, true, true],
            transactionMethodName: this.props.t('transaction:transaction.transfer.quickTransfer', 'Quick Pay'),
            transactionMethodTitle: this.props.t('transaction:transaction.deposit.expressDeposit.expressDepositInstructionTitle', 'Instruction to use "Express Deposit":'),
        };

        let userCurrency = this.props.user && this.props.user.account && this.props.user.account.currency ? this.props.user.account.currency : null;

        if (userCurrency === 'KHUSD') userCurrency = 'USD';

        return (
            <Translation>
                {(t) => (
                    <>
                        <div className='group-item bank-options'>
                            <div className='title'>
                                <span>{t('transaction:transaction.deposit.expressDeposit.toBankAccount', 'To Bank Account')}</span>
                                <span className='required'>*</span>
                            </div>
                            <div
                                className={`bank-list express-deposit-bank-box ${
                                    this.state.showAllBankMaintenaceMsg || this.state.showNotBankSupportMsg ? 'express-deposit-msg-box' : ''
                                }`}
                            >
                                {expressDepositMemberBankList && !this.state.showAllBankMaintenaceMsg ? (
                                    <Fragment>
                                        {expressDepositMerchantBankList && !this.state.showNotBankSupportMsg ? (
                                            <Fragment>
                                                {expressDepositMerchantBankList.map((bank, i) => {
                                                    return (
                                                        <ul key={i}>
                                                            <Fragment>
                                                                <li className='icon-hover-box'>
                                                                    <span className={`icon-box`}>
                                                                        <i className={['icon-bank'].join(' ')}>
                                                                            <BankIcon bankCode={expressDepositMemberBankList.bankCode || expressDepositMemberBankList.code} />
                                                                        </i>
                                                                    </span>
                                                                    <span>{bank.name}</span>
                                                                </li>
                                                                <div className='express-deposit-bank-list'>
                                                                    <span>{bank.accountName}</span>
                                                                    <span>{bank.accountNumber}</span>
                                                                </div>
                                                            </Fragment>
                                                        </ul>
                                                    );
                                                })}
                                            </Fragment>
                                        ) : (
                                            <span className='request-text'>
                                                {t(
                                                    'transaction:transaction.deposit.expressDeposit.unavailableExpressBank',
                                                    'Express Deposit unavailable now, Please SELECT other Deposit Option, sorry for inconvenience caused'
                                                )}
                                            </span>
                                        )}
                                    </Fragment>
                                ) : (
                                    <span className='request-text'>
                                        {t('transaction:transaction.deposit.expressDeposit.requestAddBank', 'Please add your BANK account details for use Express Deposit')}
                                    </span>
                                )}
                            </div>
                        </div>
                        {/* pending */}
                        {expressDepositMemberBankList && !showAllBankMaintenaceMsg && (
                            <div className='group-item bank-options'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.expressDeposit.yourBankAccount', 'Your Bank Account')}</span>
                                    <span className='required'>*</span>
                                </div>

                                <div className='bank-list express-deposit-bank-column'>
                                    <ul>
                                        <Fragment>
                                            <li className='icon-hover-box'>
                                                <span className={`icon-box`}>
                                                    <i className={['icon-bank'].join(' ')}>
                                                        <BankIcon bankCode={expressDepositMemberBankList.bankCode || expressDepositMemberBankList.code} />
                                                    </i>
                                                </span>
                                                <span>{expressDepositMemberBankList.bankName}</span>
                                            </li>
                                            <div className='express-deposit-bank-list'>
                                                <span>
                                                    {expressDepositMemberBankList.accountName
                                                        ? expressDepositMemberBankList.accountName
                                                        : expressDepositMemberBankList.accountHolder}
                                                </span>
                                                <span>{expressDepositMemberBankList.accountNumber}</span>
                                            </div>
                                        </Fragment>
                                    </ul>
                                </div>

                                <button
                                    className='btn-remove'
                                    onClick={() => {
                                        vm.removeBankList(expressDepositMemberBankList);
                                    }}
                                >
                                    {t('transaction:transaction.deposit.expressDeposit.remove', 'Remove').toUpperCase()}
                                </button>
                            </div>
                        )}

                        <div className='bank-add'>
                            <div className='group-item input-item'>
                                <div className='title'>
                                    <span>{t('bank:bank.selectBank', 'Select Bank')}</span>
                                    <span className={`required ${expressDepositMemberBankList && !showAllBankMaintenaceMsg ? 'non-required' : ''}`}>*</span>
                                </div>
                                <div className='group'>
                                    <div className='input-box'>
                                        <select
                                            value={this.state.selectedMemberBankIndex}
                                            onChange={(e) => vm.handleBankDetailChange(e)}
                                            disabled={expressDepositMemberBankList && !showAllBankMaintenaceMsg}
                                        >
                                            <option value=''>{t('settings:settings.dropdown.pleaseselect', '--- Please Select ---')}</option>
                                            {verifyBank.map((bank, index) => (
                                                <option key={index} value={index}>
                                                    {bank.bankName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div
                                    className='addBankExressDeposit'
                                    onClick={() => {
                                        vm.addButtonOnClicked();
                                    }}
                                >
                                    <span>{t('transaction:transaction.deposit.expressDeposit.addExpressDeposit', 'Click here to add bank for Express Deposit')}</span>
                                </div>
                            </div>

                            <div className='group-item input-item'>
                                <div className='title'>
                                    <span>{t('bank:bank.newBank.account-name', 'Account Name')}</span>
                                    <span className={`required ${expressDepositMemberBankList && !showAllBankMaintenaceMsg ? 'non-required' : ''}`}>*</span>
                                </div>
                                <div className='group'>
                                    <div className='input-box'>
                                        <input
                                            type='text'
                                            value={fields['verifyAccountName']}
                                            onChange={(e) => vm.handleFieldChange(e, 'accountNumber')}
                                            id='accountNumber'
                                            placeholder={verifyAccountList.accountName ? verifyAccountList.accountName : t('bank:bank.newBank.account-name', 'Account Name')}
                                            disabled
                                        ></input>
                                    </div>
                                </div>
                            </div>
                            <div className='group-item input-item'>
                                <div className='title'>
                                    <span>{t('bank:bank.newBank.account-number', 'Account Number')}</span>
                                    <span className={`required ${expressDepositMemberBankList && !showAllBankMaintenaceMsg ? 'non-required' : ''}`}>*</span>
                                </div>
                                <div className='group'>
                                    <div className='input-box'>
                                        <input
                                            type='text'
                                            value={fields['verifyAccountNumber']}
                                            onChange={(e) => vm.handleFieldChange(e, 'accountNumber')}
                                            id='accountNumber'
                                            placeholder={
                                                verifyAccountList.accountNumber ? verifyAccountList.accountNumber : t('bank:bank.newBank.account-number', 'Account Number')
                                            }
                                            disabled
                                        ></input>
                                    </div>
                                </div>
                            </div>
                            <button
                                className='btn-submit'
                                disabled={!isFormValid}
                                onClick={() => {
                                    vm.handleSubmitExpressDepositBank();
                                }}
                            >
                                {t('global:entity.action.select').toUpperCase()}
                            </button>
                        </div>

                        <TransactionNote {...transactionNoteProps} />
                        {/* --- notice box ---*/}
                        {/* <div className='notice-box'>
                            <span className='item-title'>
                                {t('transaction:transaction.deposit.expressDeposit.expressDepositInstructionTitle', 'Instruction to use "Express Deposit":')}
                            </span>
                            <div className='display-info'>
                                <span className='key'>{t('transaction:transaction.deposit.expressDeposit.expressDepositInstruction1', 'Instruction1')}</span>
                                <span className='value'></span>
                            </div>
                            <div className='display-info'>
                                <span className='key'>{t('transaction:transaction.deposit.expressDeposit.expressDepositInstruction2', 'Instruction2')}</span>
                                <span className='value'></span>
                            </div>
                            <div className='display-info'>
                                <span className='key'>{t('transaction:transaction.deposit.expressDeposit.expressDepositInstruction3', 'Instruction3')}</span>
                                <span className='value'></span>
                            </div>
                        </div> */}
                    </>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'settings'])(withRouter(QrCodePay)));
