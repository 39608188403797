import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import Modal from 'react-modal';
import commonUtil from 'root/utils/common-util';

import SmallLoading from 'root/components/smallLoading';

import vm from '../transaction-note.controller';
class TransactionNote extends React.Component {
    isMount = false;
    props = {};

    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
        this.state = {
            isModalOpen: false,
            isFaqModalOpen: false,
        };
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }
    componentDidMount() {
        commonUtil.loadCustomTranslation('transactionNote', this, function () {});
    }

    toggleModal() {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    }

    toggleFaqModal() {
        this.setState({ isFaqModalOpen: !this.state.isFaqModalOpen });
    }

    render() {
        const { customTranslationObj } = this.state;
        const {
            user,
            // HIDE CONTENT
            hideDisplayInfo,
            hideTransactionMode,
            hideTotalAllowed,
            // SHOW CONTENT
            customOnlineTransactionMode,
            // NAMING DISPLAY
            transactionMethodName,
            transactionMethodTitle,
            // LIMIT
            minLimit,
            depositMinMaxLimit,
            dailyLimit,
            dailyCountBalance,
            // ARTICLE
            customArticleContent,
            customTotalAllowTitle,
            customOptionTitle,
            customModeTile,
            customMinMaxTitle,
            showCustomArticle,
            bottomImage,
            customArticleIsJson,
            // LOADING
            isSubLoading,
            customFilename,
        } = this.props;

        let userCurrency = user && user.account && user.account.currency ? user.account.currency : null;
        const importantNotice = `important-notice-${customFilename}`;
        const dailyLimitBalance = `dailyLimitBalance-${customFilename}`;
        const totalallowed = `totalallowed-${customFilename}`;
        const minLimitBank = `minLimitBank-${customFilename}`;
        const maxLimit = `maxLimit-${customFilename}`;
        const unlimited = `unlimited-${customFilename}`;
        if (userCurrency === 'KHUSD') userCurrency = 'USD';

        return (
            <Translation>
                {(t) => (
                    <div className='notice-box'>
                        {transactionMethodTitle ? (
                            <span className='item-title'>{transactionMethodTitle}</span>
                        ) : (
                            <span className='item-title'>
                                {customTranslationObj && customTranslationObj[importantNotice]
                                    ? customTranslationObj[importantNotice]
                                    : t('transaction:transaction.important-notice', 'IMPORTANT NOTICE')}
                            </span>
                        )}

                        {/************************ NOTES MODAL ***********************/}
                        <div className='display-info'>
                            {!hideDisplayInfo && (
                                <Fragment>
                                    {userCurrency === 'MYR' ? (
                                        <div className='note-info-container'>
                                            {/************************ TRANSACTION MIN LIMIT ONLY ***********************/}

                                            <div className='display-info'>
                                                <span className='key'>
                                                    {customTranslationObj && customTranslationObj[minLimitBank]
                                                        ? customTranslationObj[minLimitBank]
                                                        : t('transaction:transaction.deposit.expressDeposit.minLimit', 'Min Limit')}
                                                </span>
                                                <span className='value'> {userCurrency + ' ' + window.SPL_Other.formatAmountNoDecimal(depositMinMaxLimit.min)}</span>
                                            </div>

                                            {/************************ TRANSACTION MIN & MAX LIMIT ***********************/}
                                            {user.account && user.account.currency && depositMinMaxLimit && (
                                                <div className='display-info'>
                                                    <span className='key'>
                                                        {customTranslationObj && customTranslationObj[maxLimit]
                                                            ? customTranslationObj[maxLimit]
                                                            : customMinMaxTitle
                                                            ? customMinMaxTitle
                                                            : t('transaction:transaction.deposit.cashdeposit.min/maxlimit', 'Min/Max Limit')}
                                                    </span>
                                                    <span className='value'> {userCurrency + ' ' + window.SPL_Other.formatAmountNoDecimal(depositMinMaxLimit.max)}</span>
                                                </div>
                                            )}
                                            {/************************ TRANSACTION DAILY LIMIT ***********************/}
                                            {dailyLimit ? (
                                                <div className='display-info'>
                                                    <span className='key'>{t('transaction:transaction.withdrawal.dailyLimitBalance', 'Daily Limit Balance')}</span>
                                                    <span className='value'>{dailyLimit}</span>
                                                </div>
                                            ) : (
                                                <div className='display-info'>
                                                    <span className='key'>
                                                        {customTranslationObj && customTranslationObj[dailyLimitBalance]
                                                            ? customTranslationObj[dailyLimitBalance]
                                                            : t('transaction:transaction.deposit.cashdeposit.dailylimit', 'Daily Limit')}
                                                    </span>
                                                    <span className='value'>
                                                        {customTranslationObj && customTranslationObj[unlimited]
                                                            ? customTranslationObj[unlimited]
                                                            : t('transaction:transaction.deposit.cashdeposit.unlimited', 'Unlimited')}
                                                    </span>
                                                </div>
                                            )}
                                            {/************************ TRANSACTION DAILY COUNT BALANCE ***********************/}
                                            {dailyCountBalance && (
                                                <div className='display-info'>
                                                    <span className='key'>{t('transaction:transaction.withdrawal.dailyCountBalance', 'Daily Count Balance')}</span>
                                                    <span className='value'>{dailyCountBalance}</span>
                                                </div>
                                            )}
                                            {/************************ TRANSACTION TOTAL ALLOWED ***********************/}
                                            {!hideTotalAllowed && (
                                                <div className='display-info'>
                                                    <span className='key'>
                                                        {customTranslationObj && customTranslationObj[totalallowed]
                                                            ? customTranslationObj[totalallowed]
                                                            : customTotalAllowTitle
                                                            ? customTotalAllowTitle
                                                            : t('transaction:transaction.deposit.cashdeposit.totalallowed', 'Total Allowed')}
                                                    </span>
                                                    <span className='value'>
                                                        {customTranslationObj && customTranslationObj[unlimited]
                                                            ? customTranslationObj[unlimited]
                                                            : t('transaction:transaction.deposit.cashdeposit.unlimited', 'Unlimited')}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <div className='note-info-container'>
                                            {/************************ TRANSACTION METHOD NAME ***********************/}
                                            {transactionMethodName && (
                                                <div className='display-info'>
                                                    <span className='key'>
                                                        {customOptionTitle ? customOptionTitle : t('transaction:transaction.deposit.cashdeposit.option', 'Option:')}
                                                    </span>
                                                    <span className='value'>{transactionMethodName}</span>
                                                </div>
                                            )}
                                            {/************************ TRANSACTION MODE ***********************/}
                                            {!hideTransactionMode && (
                                                <div className='display-info'>
                                                    <span className='key'>
                                                        {customModeTile ? customModeTile : t('transaction:transaction.deposit.cashdeposit.mode', 'Mode')}
                                                    </span>
                                                    {customOnlineTransactionMode ? (
                                                        customOnlineTransactionMode
                                                    ) : (
                                                        <span className='value'>{t('transaction:transaction.deposit.cashdeposit.offline', 'Online & Offline')}</span>
                                                    )}
                                                </div>
                                            )}
                                            {/************************ TRANSACTION MIN LIMIT ONLY ***********************/}
                                            {minLimit && (
                                                <div className='display-info'>
                                                    <span className='key'>{t('transaction:transaction.deposit.expressDeposit.minLimit', 'Min Limit')}</span>
                                                    <span className='value'>{isSubLoading ? <SmallLoading /> : minLimit}</span>
                                                </div>
                                            )}
                                            {/************************ TRANSACTION MIN & MAX LIMIT ***********************/}
                                            {user.account && user.account.currency && depositMinMaxLimit && (
                                                <div className='display-info'>
                                                    <span className='key'>
                                                        {customMinMaxTitle
                                                            ? customMinMaxTitle
                                                            : t('transaction:transaction.deposit.cashdeposit.min/maxlimit', 'Min/Max Limit')}
                                                    </span>
                                                    <span className='value'>
                                                        {userCurrency + ' ' + depositMinMaxLimit.min + '.00' + ' / ' + depositMinMaxLimit.max + '.00'}
                                                    </span>
                                                </div>
                                            )}
                                            {/************************ TRANSACTION DAILY LIMIT ***********************/}
                                            {dailyLimit ? (
                                                <div className='display-info'>
                                                    <span className='key'>{t('transaction:transaction.withdrawal.dailyLimitBalance', 'Daily Limit Balance')}</span>
                                                    <span className='value'>{dailyLimit}</span>
                                                </div>
                                            ) : (
                                                <div className='display-info'>
                                                    <span className='key'>{t('transaction:transaction.deposit.cashdeposit.dailylimit', 'Daily Limit')}</span>
                                                    <span className='value'>{t('transaction:transaction.deposit.cashdeposit.unlimited', 'Unlimited')}</span>
                                                </div>
                                            )}
                                            {/************************ TRANSACTION DAILY COUNT BALANCE ***********************/}
                                            {dailyCountBalance && (
                                                <div className='display-info'>
                                                    <span className='key'>{t('transaction:transaction.withdrawal.dailyCountBalance', 'Daily Count Balance')}</span>
                                                    <span className='value'>{dailyCountBalance}</span>
                                                </div>
                                            )}
                                            {/************************ TRANSACTION TOTAL ALLOWED ***********************/}
                                            {!hideTotalAllowed && (
                                                <div className='display-info'>
                                                    <span className='key'>
                                                        {customTotalAllowTitle
                                                            ? customTotalAllowTitle
                                                            : t('transaction:transaction.deposit.cashdeposit.totalallowed', 'Total Allowed')}
                                                    </span>
                                                    <span className='value'>{t('transaction:transaction.deposit.cashdeposit.unlimited', 'Unlimited')}</span>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </Fragment>
                            )}

                            {/************************ TRANSACTION DEFAULT / CUSTOM ARTICLE ***********************/}
                            {customArticleIsJson ? (
                                <div
                                    className='note-info-detail'
                                    style={customArticleContent && customArticleContent.noteInfoDetailStyle ? customArticleContent.noteInfoDetailStyle : {}}
                                >
                                    {customArticleContent && (
                                        <>
                                            {(customArticleContent.content || []).map((content, i) => (
                                                <Fragment key={i}>{vm.renderCustomContent(customArticleContent, content)}</Fragment>
                                            ))}
                                        </>
                                    )}
                                </div>
                            ) : (
                                <div
                                    className='note-info-detail'
                                    dangerouslySetInnerHTML={{
                                        __html: customArticleContent,
                                    }}
                                ></div>
                            )}

                            {/************************ BOTTOM IMAGE ***********************/}
                            {bottomImage && <img src={bottomImage}></img>}
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps, null)(withTranslation()(withRouter(TransactionNote)));
