import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { TweenMax } from 'gsap';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import LeftSubMenu from 'root/webapp/shared/component/wap/LeftSubMenu';
import HomeMemberProfile from 'root/webapp/shared/component/wap/HomeMemberProfile';

import vm from '../games-3d.controller';

class Games_3D extends React.Component {
    tween1;
    tween2;

    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        vm.getProviderList(this.tween1, this.tween2, '');
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    toggleAni() {
        this.tween1 && this.tween1.kill();
        this.tween2 && this.tween2.kill();

        TweenMax.set('.brand-page ul.ani-ul > li', {
            alpha: 0,
        });

        this.tween1 = TweenMax.delayedCall(0.3, function () {
            this.tween2 = TweenMax.staggerTo('.brand-page ul.ani-ul > li', 0.3, { alpha: 1 }, 0.08);
        });
    }

    onClick(provider) {
        const { history } = this.props;

        history.push({
            pathname: '/games/' + provider.routeName,
            query: { provider: provider.provider },
        });
    }

    checkMaintenance(games) {
        if (games) {
            if (!games.underMaintenance){
                this.onClick(games);
                vm.updateSelectedProvider(games);
            }
        }
    }

    render() {
        return (
            <Translation>
                {(t) => (
                    <Fragment>
                        <div className='games-3d-page'>
                            <HomeMemberProfile />
                            <div className='games-3d-box-container'>
                                <div className='brand-page'>
                                    <LeftSubMenu />
                                    <div className='games-3d-list-box'>
                                        <span className='item-title'>{t('global:global.submenu.games.mobile.title', 'IMMERSIVE AND EXCITING 3D GAMES')}</span>
                                        <ul className='ani-ul'>
                                            {this.state.brandList.map((gamesProvider, i) => (
                                                <li
                                                    key={i}
                                                    onClick={() => {
                                                        this.checkMaintenance(gamesProvider);
                                                    }}
                                                >
                                                    <LazyLoadImage 
                                                        src={'/public/html/images/3d_games/mobile/' + gamesProvider.key + '.png'} 
                                                        alt='' 
                                                        className={
                                                            gamesProvider.underMaintenance
                                                                ? 'maintenance-cover'
                                                                : ''
                                                        }
                                                    />
                                                    {gamesProvider.isHotProvider && (
                                                        <div className='hotnewicon-3d-container'>
                                                            <LazyLoadImage src='/public/html/images/hoticon_mobile.svg' alt=''></LazyLoadImage>
                                                        </div>
                                                    )}
                                                    {gamesProvider.isNewProvider && (
                                                        <div className='hotnewicon-3d-container'>
                                                            <LazyLoadImage src='/public/html/images/newicon_mobile.svg' alt=''></LazyLoadImage>
                                                        </div>
                                                    )}
                                                    <p>{t(gamesProvider.mobileText)}</p>

                                                    {gamesProvider.underMaintenance && 
                                                        <div className='maintenance-hover-box'></div>
                                                    }
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation('global')(withRouter(Games_3D)));
