const controller = {
    vm: null,

    init(vm) {
        controller.vm = vm;
        controller.vm.state = {
            phone: '',
            displayPhone: '',
            enterPhone: '',
            isCorrectPhone: false,
            step: 'INPUT_PHONE',
            errorMsg: '',
            attemptCount: 0,
            countdown: 0,
            allowResend: false,
            resendTimerId: null,
            otpCode: null,
            codeIncorrect: false,
        };
    },

    getMemberPhone() {
        let phone = controller.vm.props.user.account.phone;
        let displayPhone = controller.maskPhone(phone);
        controller.vm.setState({ phone: phone, displayPhone: displayPhone });
    },

    maskPhone(base) {
        if (base && base !== '') {
            if (base.includes('+')) {
                base = base.substring(1, base.length);
            }

            if (base.length > 4) {
                return '+' + controller.repeatStr('*', 4) + base.substring(base.length - 4);
            } else {
                return '+' + controller.repeatStr('*', 4) + base;
            }
        }
        return base;
    },

    repeatStr(character, length) {
        var result = '';
        for (var x = 0; x < length; x++) result += character;
        return result;
    },

    sendVerificationCode() {
        window.SPL_Member.triggerSendVerification('PHONE', controller.vm.state.phone)
            .then(() => {
                controller.goToStep('ENTER_OTP');
            })
            .catch((error) => {
                let errorMsg = error.message.response.data;
                if (errorMsg.message === 'Phone Number Incorrect') {
                    controller.vm.setState({ errorMsg: 'global:global.verification.differentNumber' });
                } else if (errorMsg.message === 'Maximum attempts Exceed') {
                    controller.vm.setState({ errorMsg: 'global:global.verification.maxAttempts' });
                } else if (errorMsg.message === 'transaction.transfer.phone.has.been.verified') {
                    controller.vm.props.closePhoneVerificationDialog();
                } else {
                    controller.vm.setState({ errorMsg: 'global:global.verification.generalError' });
                }
                controller.goToStep('FAILED');
            });
    },

    goToStep(step) {
        switch (step) {
            case 'ENTER_OTP':
                controller.startResendCountdownTimer();
                break;
            case 'SUCCEED':
                break;
            case 'FAILED':
                break;
            default:
                break;
        }
        controller.vm.setState({ step: step });
    },

    startResendCountdownTimer() {
        let vm = controller.vm;
        function onTimer() {
            vm.setState({ countdown: vm.state.countdown - 1 });
            if (vm.state.countdown <= 0) {
                clearInterval(vm.state.resendTimerId);
                vm.setState({ allowResend: true, resendTimerId: null });
            }
        }

        let resendTimerId = setInterval(onTimer, 1000);

        controller.vm.setState({
            allowResend: false,
            countdown: 60,
            resendTimerId: resendTimerId,
        });
    },

    stopResendCountdownTimer() {
        clearInterval(controller.vm.state.resendTimerId);
    },

    handleFieldChanged(e) {
        controller.vm.setState({ enterPhone: e.target.value }, () => {
            let { phone, enterPhone } = controller.vm.state;
            const { user } = controller.vm.props;

            // for line register will empty phone
            // we just validate format
            if (phone === undefined || phone === null || phone === '') {
                if (enterPhone.includes('+')) {
                    enterPhone = enterPhone.substring(1, enterPhone.length);
                }
                let currency = user.account.currency;
                let telCode = '';
                window.SPL_JsonSettings.getTelCodeSettings(function (telCodeSettings) {
                    if (telCodeSettings) {
                        let keys = Object.keys(telCodeSettings);
                        for (let i = 0; i < keys.length; i++) {
                            let currentObj = telCodeSettings[keys[i]];
                            if (currentObj.currency && currentObj.currency === currency) {
                                telCode = keys[i];
                                break;
                            }
                        }
                        // if detect enter phone contains telcode, remove it
                        if (enterPhone.indexOf(telCode) === 0) {
                            enterPhone = enterPhone.substring(telCode.length, enterPhone.length);
                        }
                        window.SPL_Register.validatePhone(enterPhone, window.merchantCode, telCode).then(function (errMsgs) {
                            if (errMsgs.length === 0) {
                                controller.vm.setState({ isCorrectPhone: true });
                            } else {
                                controller.vm.setState({ isCorrectPhone: false });
                            }
                        });
                    }
                });
            } else {
                if (phone.includes('+')) {
                    phone = phone.substring(1, phone.length);
                }
                if (enterPhone.includes('+')) {
                    enterPhone = enterPhone.substring(1, enterPhone.length);
                }
                let isCorrectPhone = false;
                if (phone === enterPhone) {
                    isCorrectPhone = true;
                }
                controller.vm.setState({ isCorrectPhone: isCorrectPhone });
            }
        });
    },

    handleOtpChange(e) {
        controller.vm.setState({ otpCode: e.target.value });
    },

    handleOtpChangeByValue(value) {
        controller.vm.setState({ otpCode: value });
    },

    validateOtp() {
        window.SPL_Member.validateOtp(controller.vm.state.phone, controller.vm.state.otpCode)
            .then((result) => {
                if (result) {
                    controller.vm.setState({ codeIncorrect: false });
                    controller.goToStep('SUCCEED');
                } else {
                    controller.vm.setState({ codeIncorrect: true });
                }
            })
            .catch(() => {
                controller.vm.setState({ errorMsg: 'global:global.verification.generalError' });
                controller.goToStep('FAILED');
            });
    },

    resendOtp() {
        controller.sendVerificationCode();
    },
};

export default controller;
