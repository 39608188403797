import moment from 'moment';
import { browserName, osName, osVersion, mobileModel } from 'react-device-detect';

import core from 'root/utils/core';
import Utils from 'root/utils';
import { userAction } from 'root/redux/action/user.action';
import { portalAction } from 'root/redux/action/portal.action';
import notification from 'root/utils/notification';
import React from 'react';
import { languageAction } from 'root/redux/action/language.action';
import { gu_getNestedValue, gu_getSettingFromPortalSettings } from '../../../utils/general-util';
import { REG_FIELD } from './register-constants';
import { validatePassword, onPasswordValidationChecking } from 'root/components/molecules/passwordInput/passwordInput';
import { systemFeatureAction } from 'root/redux/action/system-feature.action';

const controller = {
    vm: null,
    timeOut: null,
    intervalRefId: null,

    init(vm) {
        this.vm = vm;

        let defaultDob = moment(Date.now()).subtract(18, 'years').toDate();
        let defaultDate = new Date().getDate();
        let defaultMonth = defaultDob.getMonth() + 1;
        let defaultYear = defaultDob.getFullYear();
        let defaultDateLength = new Date(defaultYear, defaultMonth, 0).getDate();
        let active = 0;
        if (window.SPL_Handler) {
            let queryParam = window.SPL_Handler.parse_query_string();
            if (queryParam.forceStep3) {
                active = 2;
            }
        }

        this.vm.state = {
            active: active,
            list: [{ value: 'register:register.step1' }, { value: 'register:register.step2' }, { value: 'register:register.step3' }],
            memberBenefit: '',
            maxDob: defaultDob,
            regConfig: {},
            telCodeList: null,
            isRegisterSuccess: false,
            fields: {
                searchKeyword: '',
                username: '',
                password: '',
                confirmPassword: '',
                fullname: '',
                firstname: '',
                lastname: '',
                email: '',
                currency: '',
                telCode: '',
                internationalTelCode: '',
                phone: '',
                affiliateid: '',
                dob: defaultDob,
                referralSource: '',
                referralOtherSource: '',
                bank: { index: -999 },
                bankAccName: '',
                bankAccNo: '',
                otherBank: '',
                otpCode: '',
                otherContact: {},
                conditionCheck: false,
                disabledAffiliate: false,
                captchaToken: null,
                date: defaultDate,
                month: defaultMonth,
            },
            optionsList: {
                currency: [],
                telCode: [],
                internationalTelCode: [],
                internationalTelCodeOriginal: [],
                referralSources: [],
                bankList: [],
                date: [],
                month: [],
            },
            errors: {},
            debouncedInputMS: '300',
            isFormValid: false,
            disabledButton: false,
            ipWhiteList: false,
            referralToggle: false,
            autoReferralToggle: false,
            currentTelCodeKey: REG_FIELD.TELCODE,
            telCodeDropdownToggle: false,
            customDropdownToggle: null,
            showSliderCaptcha: false,
            dateLength: defaultDateLength,

            autoFill: {},

            currencyMapping: null,
            checkSpecialCharsError: false,
            isStepProceed: false,
            isExternalRegister: false,
            turnstileValidation: false,
            showSpecialCharsErrMsg: false,
        };
    },

    initCompSettings() {
        return new Promise((resolve) => {
            const vm = controller.vm;
            const { settings } = vm.props.portal;
            let stateToUpdate = {};

            // Step 1: Standard way to get from portal settings
            let tempSettings = gu_getSettingFromPortalSettings({
                vm,
                settingName: 'registerConfiguration',
                notViewType: true,
                notFeatures: true,
            });

            let internationalTelcode = gu_getNestedValue(settings, 'internationalTelcode');

            // Step 2: Massage again to get the specific data you need (Skip if not needed)
            if (internationalTelcode) {
                stateToUpdate = {
                    currentTelCodeKey: REG_FIELD.INTERNATIONAL_TELCODE,
                };
            }
            // Step 3: Initial all into compSettings
            stateToUpdate = {
                ...stateToUpdate,
                regConfig: tempSettings,
                customAffiliateErrorMsg: tempSettings.customAffiliateErrorMsg,
            };

            controller.vm.setState(stateToUpdate, () => {
                resolve();
            });
        });
    },

    getBrandLogo() {
        const vm = controller.vm;
        const { language } = vm.props;
        window.SPL_Content.getBrandLogo(language.countryLanguageKey).then((res) => {
            vm.setState({ brandLogoObj: res });
        });
    },

    getMemberBenefit() {
        const { language } = controller.vm.props;
        window.SPL_Content.readMemberBenefit(language.countryLanguageKey, (res) => {
            controller.vm.setState({ memberBenefit: res.data });
        });
    },

    initCheckIpDevice() {
        window.SPL_Member.validateIpDevice('')
            .then((data) => {
                const turnstileValidation = controller.checkTurnstileValidation();
                if (data.error) {
                    core.portalIsLoading(controller.vm.props, false);
                    controller.vm.setState({ disabledButton: false }, () => {
                        const isMobile = Utils.force2Mobile();

                        if (isMobile) {
                            controller.vm.props.dispatch(portalAction.floatingPageUpdated(''));
                        }

                        controller.vm.props.history.push('/redirect-error?' + data.error);
                    });
                } else {
                    controller.vm.setState({
                        turnstileValidation,
                        ipWhiteList: true,
                    });
                }
            })
            .catch(() => {
                const turnstileValidation = controller.checkTurnstileValidation();
                controller.vm.setState({ ipWhiteList: true, turnstileValidation });
            });
    },

    processAffiliateOrReferralCodeLogicWithTimeOut() {
        let count = 1;
        window.processAffiliateIntervalRefId = setInterval(() => {
            if (count < 10) {
                count++;
                controller.processAffiliateOrReferralCodeLogic();
            } else {
                clearInterval(window.processAffiliateIntervalRefId);
            }
        }, 1000);
    },

    processAffiliateOrReferralCodeLogic() {
        // 1. try get referral code first
        // var refCode = window.SPL_Cookie.getCookie('RefCode');
        // var refCurrency = window.SPL_Cookie.getCookie('Ref_Currency');
        // var affCode = window.SPL_Cookie.getCookie('AffCode');
        // var refid = window.SPL_Cookie.getCookie('refid');
        // var affid = window.SPL_Cookie.getCookie('affid');
        // let fields = controller.vm.state.fields;

        // if (refCode) {
        //     fields.affiliateid = refCode;
        //     fields.currency = refCurrency;
        //     fields.disabledAffiliate = true;
        //     controller.vm.setState({ fields, disabledAffiliate: true, referralToggle: true });
        // } else if (affCode) {
        //     // else try get affiliate
        //     fields.affiliateid = affCode;
        //     fields.disabledAffiliate = true;
        //     controller.vm.setState({ fields, disabledAffiliate: true, referralToggle: true });
        // }

        const vm = controller.vm;
        let fields = vm.state.fields;

        vm.props.dispatch(userAction.getAffiliateOrReferralCode()).then(() => {
            fields.affiliateid = vm.props.user.affConfig.affiliateid;

            if (vm.props.user.affConfig.currency && vm.props.user.affConfig.currency !== '') {
                // fields.currency = vm.props.user.affConfig.currency === 'KHUSD' ? 'USD' : vm.props.user.affConfig.currency;
                // if no use below code, the field will keep kena overwrite. However, still got issue, not sure why this function need iterate through 10 times.
                // so will have case keep trigger state update cause rerender
                var e = {
                    target: {
                        value: vm.props.user.affConfig.currency === 'KHUSD' ? 'USD' : vm.props.user.affConfig.currency,
                    },
                };
                handleCurrencyOnChange(e);
            }
            fields.disabledAffiliate = vm.props.user.affConfig.disabledAffiliate;

            controller.vm.setState({
                fields,
                disabledAffiliate: vm.props.user.affConfig.disabledAffiliate,
                autoReferralToggle: vm.props.user.affConfig.referralToggle,
            });
        });
    },

    toggleDropdown(field) {
        const vm = controller.vm;

        if (field) {
            if (field === vm.state.customDropdownToggle) vm.setState({ customDropdownToggle: null });
            else vm.setState({ customDropdownToggle: field });
        } else {
            vm.setState({ telCodeDropdownToggle: !vm.state.telCodeDropdownToggle });
        }
    },

    getCountryInfo() {
        const { portal } = controller.vm.props;
        if (portal.settings.internationalTelcode) {
            controller._getInternationalCountryInfo();
        } else {
            controller._getCountryInfo();
        }
    },

    _getInternationalCountryInfo() {
        window.SPL_Content.getInternationalCountryInfo().then((telCodeObj) => {
            controller._getInternationalOptionList(telCodeObj);
        });
    },

    _getInternationalOptionList(telCodeObj) {
        const { fields, regConfig } = controller.vm.state;
        const { language } = controller.vm.props;
        const { portalTelCodeInfo, telCodeInfo } = telCodeObj;

        const obj = {
            currency: [],
            internationalTelCode: [],
            internationalTelCodeOriginal: [],
            referralSources: [],
            date: Array.from({ length: controller.vm.state.dateLength }, (_, i) => i + 1),
            month: Array.from({ length: 12 }, (_, i) => i + 1),
        };

        for (let i = 0; i < portalTelCodeInfo.length; i++) {
            const currentPortalTelCodeInfo = portalTelCodeInfo[i];
            obj.currency.push(currentPortalTelCodeInfo.currency);

            if (currentPortalTelCodeInfo.country === language.country) {
                fields.currency = currentPortalTelCodeInfo.currency;
                fields.internationalTelCode = '+' + currentPortalTelCodeInfo.telCode;
            }
        }

        const telCodeList = [];
        const availableCountryList = [];
        const nonAvailableCountryList = [];
        let _telCodeInfo = telCodeInfo;

        // if enable internationalFeature, but don't want show those international country telcode
        if (!gu_getNestedValue(regConfig, 'internationalFeature.isInternationalTelcode')) {
            _telCodeInfo = (telCodeInfo || []).filter((arr1) =>
                (portalTelCodeInfo || []).some((arr2) => {
                    return arr1.telCode === arr2.telCode;
                })
            );
        }

        for (let j = 0; j < _telCodeInfo.length; j++) {
            const currentTelCodeInfo = _telCodeInfo[j];
            if (currentTelCodeInfo.telCode && currentTelCodeInfo.supportTelCode) {
                const internationalTelCodeObj = {
                    telCode: '+' + currentTelCodeInfo.telCode + ' ' + currentTelCodeInfo.name.default,
                    availableCountry: currentTelCodeInfo.availableCountry,
                };

                // handle ensure available country always on top of the list, instead of manual listing at json
                if (internationalTelCodeObj.availableCountry) {
                    availableCountryList.push(internationalTelCodeObj);
                } else {
                    nonAvailableCountryList.push(internationalTelCodeObj);
                }
            }
        }
        obj.internationalTelCode.push(...availableCountryList, ...nonAvailableCountryList);
        obj.internationalTelCodeOriginal.push(...availableCountryList, ...nonAvailableCountryList);

        controller._getOptionReferralSource(obj);

        controller.vm.setState({ fields, telCodeList, optionsList: obj }, () => {
            controller.enableFieldByCurrency();
            controller.currencyMapping();
        });
    },

    _getCountryInfo() {
        window.SPL_Content.getCountryInfo().then((telCodeList) => {
            const { language } = controller.vm.props;
            controller.vm.setState({ telCodeList: telCodeList });
            controller._getOptionList(telCodeList, language.country);
        });
    },

    _getOptionList(telCodeList, country) {
        let obj = {
            currency: [],
            telCode: [],
            telCodeOri: [],
            referralSources: [],
            bankList: [],
            date: Array.from({ length: controller.vm.state.dateLength }, (_, i) => i + 1),
            month: Array.from({ length: 12 }, (_, i) => i + 1),
        };

        let fields = controller.vm.state.fields;

        for (let i = 0; i < telCodeList.length; i++) {
            obj.currency.push(telCodeList[i].currency);
            obj.telCode.push('+' + telCodeList[i].telCode + ' ' + telCodeList[i].name);
            obj.telCodeOri.push('+' + telCodeList[i].telCode + ' ' + telCodeList[i].name);
            if (telCodeList[i].country === country) {
                fields.currency = telCodeList[i].currency;
                fields.telCode = '+' + telCodeList[i].telCode;
            }

            controller.vm.setState({ fields }, () => {
                controller.enableFieldByCurrency();
                controller.currencyMapping();
            });
        }

        controller._getOptionReferralSource(obj);
        controller.vm.setState({ optionsList: obj });
    },

    _getOptionReferralSource(obj) {
        const { regConfig } = controller.vm.state;

        let regReferralSources = controller.vm.state.regConfig.registerReferralSources;
        if (regReferralSources && regReferralSources.length > 0) {
            controller.vm.setState({ showReferralSources: true });
            obj['referralSources'] = regReferralSources;
        }

        if (regConfig.hideBank === undefined || !regConfig.hideBank) {
            // controller.getBankList(function(data) {
            //     obj['bankList'] = data;
            //     controller.vm.setState({
            //         optionsList: obj
            //     });
            // });
        }
    },

    enableFieldByCurrency() {
        const { currency } = controller.vm.state.fields;

        // otherContact
        const { regConfig } = controller.vm.state;
        if (regConfig.otherContacts && regConfig.otherContacts.length > 0) {
            for (var i = 0; i < regConfig.otherContacts.length; i++) {
                if (regConfig.otherContacts[i].currencies && regConfig.otherContacts[i].currencies.length > 0) {
                    regConfig.otherContacts[i].isEnabled = regConfig.otherContacts[i].currencies.includes(currency);
                } else {
                    regConfig.otherContacts[i].isEnabled = true;
                }
            }
        }

        // OTP
        if (regConfig.registerOTPCurrencyControl) {
            if (regConfig.registerOTPCurrencyControl[currency]) {
                regConfig.registerPhoneVerification = true;
            } else {
                regConfig.registerPhoneVerification = false;
            }
        } else {
            regConfig.registerPhoneVerification = false;
        }

        controller.vm.setState({ regConfig });
    },

    handleTelCodeSearch(keyword) {
        const vm = controller.vm;
        const { portal } = controller.vm.props;
        const { optionsList, fields } = vm.state;

        const keywordValue = !!keyword ? keyword.target.value : '';
        const searchKeyword = keywordValue.toLowerCase();

        fields.searchKeyword = keywordValue;

        var telCodeKey = 'telCode';
        var telCodeOriKey = 'telCodeOri';
        var cloneTelCode = [];
        var filteredTelCode = [];

        if (portal && portal.settings && portal.settings.internationalTelcode) {
            telCodeKey = 'internationalTelCode';
            telCodeOriKey = 'internationalTelCodeOriginal';

            let internationalTelCode = optionsList.internationalTelCodeOriginal && optionsList.internationalTelCodeOriginal.telCode;
            cloneTelCode = internationalTelCode && internationalTelCode.length > 0 ? [...internationalTelCode] : [];
        } else {
            telCodeKey = 'telCode';
            telCodeOriKey = 'telCodeOri';
            cloneTelCode = optionsList.telCodeOri && optionsList.telCodeOri.length > 0 ? [...optionsList.telCodeOri] : [];
        }

        if (searchKeyword && searchKeyword.length > 0) {
            filteredTelCode = cloneTelCode.filter((e) => {
                const telCode = e.toLowerCase();
                if (telCode && telCode.indexOf(searchKeyword) !== -1) {
                    return e;
                }
                return false;
            });
            optionsList[telCodeKey] = filteredTelCode;
        } else {
            optionsList[telCodeKey] = optionsList[telCodeOriKey];
        }
        vm.setState({ fields, optionsList });
    },

    handleFieldChanged(e, field, isOnBlur = false) {
        const { portal } = controller.vm.props;
        const { fields, telCodeList, regConfig, optionsList, currentTelCodeKey } = controller.vm.state;
        const { isFullName, hideContact, hideDob, hideBank, otherContacts, registerPhoneVerification } = regConfig;

        let isInternationalTelcode = false;
        if (portal && portal.settings && portal.settings.internationalTelcode) {
            isInternationalTelcode = portal.settings.internationalTelcode;
        }

        let errors = controller.vm.state.errors;
        controller.vm.setState({ disabledButton: true });
        if (field.includes('otherContact')) {
            let otherContact = field.split('.')[1];
            fields['otherContact'][otherContact] = e.target.value;
            controller.vm.setState({ fields });
        } else if (field === currentTelCodeKey) {
            if (fields[field] !== e) {
                const telCode = e.target.value || e.target.innerText;
                const subStringIndex = telCode.substring(0).search(/[A-Za-z]/);
                if (subStringIndex !== -1) {
                    fields[field] = telCode.substring(0, subStringIndex);
                } else {
                    fields[field] = telCode;
                }
                fields[field] = fields[field].replace(/\s/g, '');

                // // Binding currency and telcode field
                // for (let i = 0; i < telCodeList.length; i++) {
                //     if (`+${telCodeList[i].telCode}` === fields[field]) {
                //         fields['currency'] = telCodeList[i].currency;
                //         controller.vm.setState({ fields });
                //     }
                // }

                if (regConfig.hideBank === undefined || !regConfig.hideBank) {
                    controller.getBankList(function (data) {
                        optionsList.bankList = data;
                        fields.bank = '';
                        controller.vm.setState({ fields });
                    });
                }

                controller.enableFieldByCurrency();
                controller.currencyMapping();
                // After change telcode, validate phone again
                let phone = fields['phone'];
                window.SPL_Register.validatePhone(phone, window.merchantCode, fields[field]).then((errMsg) => {
                    controller.handleInputError(errMsg, 'phone', errors);
                });
            }
        } else if (field === 'currency') {
            if (fields[field] !== e) {
                fields[field] = e.target.value;

                if (regConfig.hideBank === undefined || !regConfig.hideBank) {
                    controller.getBankList(function (data) {
                        optionsList.bankList = data;
                        fields.bank = '';
                        controller.vm.setState({ fields });
                    });
                }

                // // Binding telcode and currency field
                // for (let i = 0; i < telCodeList.length; i++) {
                //     if (telCodeList[i].currency === fields[field]) {
                //         fields['telCode'] = '+' + telCodeList[i].telCode;
                //         break;
                //     }
                // }
                controller.vm.setState({ fields });

                controller.enableFieldByCurrency();
                controller.currencyMapping();
                controller.handleInputError('', 'currency', errors);
            }
        } else {
            if (field === 'bank') {
                if (regConfig.hideBank === undefined || !regConfig.hideBank) {
                    fields['bankAccName'] = regConfig.isFullName ? fields['fullname'] : fields['firstname'] + ' ' + fields['lastname'];
                    if (e.target.value !== '-999') {
                        fields['bank'] = optionsList['bankList'][e.target.value];
                        fields['bank'].index = e.target.value;
                        fields['bankAccNo'] = '';

                        if (!regConfig.hideRegistrationOtherBank) {
                            controller.vm.setState({ selectedOtherBank: fields['bank'].bankId === '-1' });
                            if (fields['bank'].bankId === -1) {
                                controller.vm.setState({ selectedOtherBank: true });
                            }
                        }
                    }
                    controller.vm.setState({ fields });
                }
            } else {
                fields[field] = field === 'conditionCheck' || field === 'dob' ? e : e.target.value;
                controller.vm.setState({ fields });
            }
        }

        if (field === 'password') {
            handlePasswordValidation();
            if (fields['confirmPassword']) {
                controller.validateConfirmPassword();
            }
        }

        if (field === 'confirmPassword' && typeof fields['confirmPassword'] !== undefined) {
            controller.validateConfirmPassword();
        }

        if (field === 'username' && isOnBlur) {
            handleUsernameValidation();
        }

        if (!controller.vm.state.fields.hideAffiliate && field === 'affiliateid' && typeof fields['affiliateid'] !== undefined) {
            handleReferralValidation();
        }

        if (isFullName === undefined || isFullName) {
            if (field === 'fullname' && typeof fields['fullname'] !== undefined) {
                let fullname = fields['fullname'];
                fields['bankAccName'] = fullname;
                window.SPL_Register.validateFullName(fullname, window.merchantCode).then((errMsg) => {
                    controller.handleInputError(errMsg, 'fullname', errors);
                });
            }
        } else {
            let firstname = fields['firstname'] !== undefined ? fields['firstname'] : '';
            let lastname = fields['lastname'] !== undefined ? fields['lastname'] : '';
            let errorOccurs = false;

            if (field === 'firstname') {
                if (typeof fields['firstname'] !== undefined && typeof fields['lastname'] !== undefined) {
                    let firstErrMsg = [];
                    if (firstname === '' || firstname === null) {
                        errorOccurs = true;
                        firstErrMsg.push('global:global.form.fullname-placeholderv4');
                    }
                    controller.handleInputError(firstErrMsg, 'firstname', errors);
                    // IGNORE checking if last/first name is null
                    if (!errorOccurs) {
                        window.SPL_Register.validateFullName(lastname + ' ' + firstname, window.merchantCode).then((errMsg) => {
                            controller.handleInputError(errMsg, 'lastname', errors);
                        });
                    }
                }
            } else if (field === 'lastname') {
                if (typeof fields['firstname'] !== undefined && typeof fields['lastname'] !== undefined) {
                    let lastErrMsg = [];
                    if (lastname === '' || lastname === null) {
                        errorOccurs = true;
                        lastErrMsg.push('global:global.form.fullname-placeholderv3');
                    }
                    controller.handleInputError(lastErrMsg, 'lastname', errors);
                    // IGNORE checking if last/first name is null
                    if (!errorOccurs) {
                        window.SPL_Register.validateFullName(lastname + ' ' + firstname, window.merchantCode).then((errMsg) => {
                            controller.handleInputError(errMsg, 'lastname', errors);
                        });
                    }
                }
            }
            fields['bankAccName'] = fields['firstname'] + ' ' + fields['lastname'];
        }

        if (field === 'email' && typeof fields['email'] !== undefined && isOnBlur) {
            let email = fields['email'];
            window.SPL_Register.validateEmail(email, window.merchantCode).then((errMsg) => {
                controller.handleInputError(errMsg, 'email', errors);
            });
        }

        if (field === 'phone') {
            handlePhoneValidation();
        }

        if (hideDob) {
            controller.vm.state.fields.dob = '';
        } else {
            if (field === 'date' && typeof fields['date'] !== undefined) {
                let date = fields['date'];
                let errMsg = [];
                if (date === undefined || date === null) {
                    errMsg.push('global:global.form.dob-placeholder');
                }
                controller.handleInputError(errMsg, 'date', errors);
            }

            if (field === 'month' && typeof fields['month'] !== undefined) {
                let month = fields['month'];
                let errMsg = [];
                let dateLength = new Date(fields.dob.getFullYear(), fields.month, 0).getDate();

                if (month === undefined || month === null) {
                    errMsg.push('global:global.form.dob-placeholder');
                } else if (dateLength !== controller.vm.state.dateLength) {
                    if (fields['date'] > dateLength) {
                        fields['date'] = 1;
                    }

                    controller.vm.setState({ dateLength, fields }, () => {
                        optionsList.date = Array.from({ length: controller.vm.state.dateLength }, (_, i) => i + 1);
                    });
                }

                controller.handleInputError(errMsg, 'month', errors);
            }

            if (field === 'dob' && typeof fields['dob'] !== undefined) {
                let dob = fields['dob'];
                let errMsg = [];
                if (dob === undefined || dob === null) {
                    errMsg.push('global:global.form.dob-placeholder');
                }
                controller.handleInputError(errMsg, 'dob', errors);
            }
        }

        if (controller.vm.state.showReferralSources) {
            if (field === 'referralSource' && typeof fields['referralSource'] !== undefined) {
                let referralSource = fields['referralSource'];
                let errMsg = [];
                if (referralSource === '' || referralSource === null) {
                    errMsg.push('global:global.messages.validate.specifyOther.required');
                }
                controller.handleInputError(errMsg, 'referralSource', errors);

                if (field === 'referralOtherSource' && typeof fields['referralOtherSource'] !== undefined && (referralSource === 'other' || referralSource === 'friend')) {
                    let referralOtherSource = fields['referralOtherSource'];
                    errMsg = [];
                    if (referralOtherSource === '' || referralOtherSource === null) {
                        errMsg.push('global:global.messages.validate.specifyOther.required');
                    }
                    if (referralSource === 'friend') {
                        controller.validateFriendLogin(function (data) {
                            if (!data) {
                                errMsg.push('global:global.messages.validate.specifyOther.friendNotExist');
                                controller.handleInputError(errMsg, 'referralOtherSource', errors);
                            }
                        });
                    }
                    controller.handleInputError(errMsg, 'referralOtherSource', errors);
                }
            }
        }

        if (field.includes('otherContact') && otherContacts !== undefined && otherContacts.length > 0) {
            let otherContact = fields['otherContact'];
            for (let i = 0; i < otherContacts.length; i++) {
                let errMsg = [];

                if (
                    otherContacts[i].isMandatory &&
                    otherContacts[i].isEnabled &&
                    (otherContact[otherContacts[i].type] === undefined || otherContact[otherContacts[i].type] === '')
                ) {
                    errMsg.push('global:global.messages.validate.specifyOther.required');
                }
                controller.handleInputError(errMsg, 'otherContact.' + otherContacts[i].type, errors);
            }
        }

        if ((field.includes('bank') || field.includes('Bank')) && (hideBank === undefined || !hideBank) && typeof fields['bank'] !== undefined) {
            let bank = fields['bank'];
            let bankErrMsg = [];
            let otherBankErrMsg = [];
            let accNoErrMsg = [];

            if (bank === '' || bank.index === -999) {
                bankErrMsg.push('global:global.messages.validate.bank.required');
            } else {
                bank = fields['bank'];
                let registerAccount = {
                    accountNumber: fields['bankAccNo'],
                    bankId: bank.bankId,
                };

                if (!fields['bankAccNo']) {
                    accNoErrMsg.push('global:global.messages.validate.bank.accountRequired');
                }

                if (bank.bankId === -1 && (fields['otherBank'] === undefined || fields['otherBank'] === '')) {
                    otherBankErrMsg.push('bank:bank.newBank.bankNamerequired');
                }
                controller.handleInputError(otherBankErrMsg, 'otherBank', errors);

                window.SPL_Register.validateBankAccount(registerAccount, controller.vm.state.optionsList.bankList).then((err) => {
                    if (err.duplicateBankAccount) {
                        accNoErrMsg.push('global:global.verification.duplicateBankAcc');
                    } else if (err.isOverAccountNumberLenghtLimit) {
                        accNoErrMsg.push('global:global.messages.bank.accountInvalid');
                    }
                    controller.handleInputError(accNoErrMsg, 'bankAccNo', errors);
                });
            }
            controller.handleInputError(bankErrMsg, 'bank', errors);
            controller.handleInputError(otherBankErrMsg, 'otherBank', errors);
            controller.handleInputError(accNoErrMsg, 'bankAccNo', errors);
        }

        if (field === 'conditionCheck' && typeof fields['conditionCheck'] !== undefined) {
            let conditionCheckErrMsg = [];
            controller.handleInputError(conditionCheckErrMsg, 'conditionCheck', errors);
        }

        if (field === 'otpCode' && typeof fields['otpCode'] !== undefined) {
            controller.validateOtp();
        }
    },

    validateConfirmPassword() {
        const { fields } = controller.vm.state;
        let errors = controller.vm.state.errors;
        if (typeof fields['confirmPassword'] !== undefined) {
            let password = fields['password'];
            let confirmPassword = fields['confirmPassword'];
            let errMsg = window.SPL_Register.validateConfirmPasswordWithoutPromise(password, confirmPassword);
            controller.handleInputError(errMsg, 'confirmPassword', errors);
        }
    },

    handleInputError(errMsg, field, errors) {
        if ((errMsg || []).length > 0) {
            errors[field] = (errMsg || []).length > 0 && typeof errMsg[0] === 'object' ? errMsg : errMsg[0];
        } else {
            errors[field] = '';
        }
        controller.updateErrorsMsg(errors);
    },

    updateErrorsMsg(errors) {
        const { PASSWORD } = REG_FIELD;
        let isFormValid = true;

        if (errors && errors[PASSWORD] && Array.isArray(errors[PASSWORD])) {
            isFormValid = onPasswordValidationChecking(errors[PASSWORD]);
        }

        for (let key in errors) {
            if (key !== PASSWORD) {
                if (errors[key] !== '') {
                    isFormValid = false;
                    break;
                }
            }
        }

        isFormValid = checkEmptyValidation(isFormValid);

        controller.vm.setState(
            {
                errors,
                isFormValid: isFormValid,
            },
            () => {
                if (controller.vm.state.isStepProceed) {
                    handleStepStopper(2);
                }
            }
        );

        controller.vm.setState({ disabledButton: false });
        if (!controller.vm.state.regConfig?.oneStepRegistration && controller.vm.state.active === 0) {
            controller.checkBeforeProceedStep2();
        }

        if (controller.vm.state.active === 0) {
            // if (window.isAffiliate) {
            //     // controller.checkIfConditionCheck();
            //     controller.checkBeforeSubmitAffForm();
            // } else {
            //     controller.checkBeforeSubmitForm();
            // }
        }
    },

    checkBeforeProceedStep2() {
        const { fields } = controller.vm.state;

        if (!fields['password'] || !fields['confirmPassword'] || !fields['username']) {
            controller.vm.setState({
                isFormValid: false,
            });
        }
    },

    proceedTo(step) {
        switch (step) {
            case 1:
                break;
            case 2:
                if (!controller.vm.state.isFormValid) {
                    return;
                }
                controller.vm.setState({
                    isFormValid: false,
                    active: 1,
                });
                break;
            default:
                break;
        }
    },

    handleStep1Validation() {
        const { fields, regConfig } = controller.vm.state;
        let errors = controller.vm.state.errors;

        controller.vm.setState({ disabledButton: true });

        handlePasswordValidation();

        if (typeof fields['confirmPassword'] !== undefined) {
            controller.validateConfirmPassword();
        }

        handleUsernameValidation();

        if (!controller.vm.state.fields.hideAffiliate && typeof fields['affiliateid'] !== undefined) {
            let affid = fields['affiliateid'];
            window.SPL_Register.validateAffiliate(affid, window.merchantCode, regConfig?.customAffiliateErrorMsg).then((errMsg) => {
                controller.handleInputError(errMsg, 'affiliateid', errors);
            });
        }
    },

    handleStep2Validation() {
        const { fields, currentTelCodeKey } = controller.vm.state;
        const { hideContact, hideDob, hideBank, otherContacts } = controller.vm.state.regConfig;
        let errors = controller.vm.state.errors;

        handleFullNameValidation(fields?.fullname, errors);

        if (typeof fields['email'] !== undefined) {
            let email = fields['email'];
            window.SPL_Register.validateEmail(email, window.merchantCode).then((errMsg) => {
                controller.handleInputError(errMsg, 'email', errors);
            });
        }

        if (!hideContact && typeof fields['phone'] !== undefined) {
            let phone = fields['phone'];
            let telCode = fields[currentTelCodeKey];
            window.SPL_Register.validatePhone(phone, window.merchantCode, telCode).then((errMsg) => {
                controller.handleInputError(errMsg, 'phone', errors);
            });
        }

        if (hideDob) {
            controller.vm.state.fields.dob = '';
        } else {
            if (typeof fields['date'] !== undefined) {
                let date = fields['date'];
                let errMsg = [];
                if (date === undefined || date === null) {
                    errMsg.push('global:global.form.dob-placeholder');
                }
                controller.handleInputError(errMsg, 'date', errors);
            }

            if (typeof fields['month'] !== undefined) {
                let month = fields['month'];
                let errMsg = [];
                if (month === undefined || month === null) {
                    errMsg.push('global:global.form.dob-placeholder');
                }
                controller.handleInputError(errMsg, 'month', errors);
            }

            if (typeof fields['dob'] !== undefined) {
                let dob = fields['dob'];
                let errMsg = [];
                if (dob === undefined || dob === null) {
                    errMsg.push('global:global.form.dob-placeholder');
                }
                controller.handleInputError(errMsg, 'dob', errors);
            }
        }

        if (controller.vm.state.showReferralSources) {
            if (typeof fields['referralSource'] !== undefined) {
                let referralSource = fields['referralSource'];
                let errMsg = [];
                if (referralSource === '' || referralSource === null) {
                    errMsg.push('global:global.messages.validate.specifyOther.required');
                }
                controller.handleInputError(errMsg, 'referralSource', errors);

                if (typeof fields['referralOtherSource'] !== undefined && (referralSource === 'other' || referralSource === 'friend')) {
                    let referralOtherSource = fields['referralOtherSource'];
                    errMsg = [];
                    if (referralOtherSource === '' || referralOtherSource === null) {
                        errMsg.push('global:global.messages.validate.specifyOther.required');
                    }
                    if (referralSource === 'friend') {
                        controller.validateFriendLogin(function (data) {
                            if (!data) {
                                errMsg.push('global:global.messages.validate.specifyOther.friendNotExist');
                                controller.handleInputError(errMsg, 'referralOtherSource', errors);
                            }
                        });
                    }
                    controller.handleInputError(errMsg, 'referralOtherSource', errors);
                }
            }
        }

        if (otherContacts !== undefined && otherContacts.length > 0) {
            let otherContact = fields['otherContact'];

            for (let i = 0; i < otherContacts.length; i++) {
                let errMsg = [];
                if (
                    otherContacts[i].isMandatory &&
                    otherContacts[i].isEnabled &&
                    (otherContact[otherContacts[i].type] === undefined || otherContact[otherContacts[i].type] === '')
                ) {
                    errMsg.push('global:global.messages.validate.specifyOther.required');
                }
                controller.handleInputError(errMsg, 'otherContact.' + otherContacts[i].type, errors);
            }
        }

        if ((hideBank === undefined || !hideBank) && typeof fields['bank'] !== undefined) {
            let bank = fields['bank'];
            let bankErrMsg = [];
            let otherBankErrMsg = [];
            let accNoErrMsg = [];

            if (bank === '' || bank.index === -999) {
                bankErrMsg.push('global:global.messages.validate.bank.required');
            } else {
                bank = fields['bank'];
                let registerAccount = {
                    accountNumber: fields['bankAccNo'],
                    bankId: bank.bankId,
                };

                if (bank.bankId === -1 && (fields['otherBank'] === undefined || fields['otherBank'] === '')) {
                    otherBankErrMsg.push('bank:bank.newBank.bankNamerequired');
                }
                controller.handleInputError(otherBankErrMsg, 'otherBank', errors);

                window.SPL_Register.validateBankAccount(registerAccount, controller.vm.state.optionsList.bankList).then((err) => {
                    if (err.duplicateBankAccount) {
                        accNoErrMsg.push('global:global.verification.duplicateBankAcc');
                    } else if (err.isOverAccountNumberLenghtLimit) {
                        accNoErrMsg.push('global:global.messages.bank.accountInvalid');
                    }
                    controller.handleInputError(accNoErrMsg, 'bankAccNo', errors);
                });
            }
            controller.handleInputError(bankErrMsg, 'bank', errors);
            controller.handleInputError(otherBankErrMsg, 'otherBank', errors);
            controller.handleInputError(accNoErrMsg, 'bankAccNo', errors);
        }
    },

    handleSubmit() {
        const { disabledButton, isFormValid, currentTelCodeKey, currencyMapping, isExternalRegister } = controller.vm.state;

        if (disabledButton || !isFormValid) {
            return;
        }
        controller.vm.setState({ disabledButton: true }, () => {
            core.portalIsLoading(controller.vm.props, true);

            if (submitValidationPicker()) {
                return;
            }

            const { language, screen } = controller.vm.props;
            const { hideDob, hideContact, hideBank, registerPhoneVerification, otherContacts, registerEmailOptional } = controller.vm.state.regConfig;
            const {
                username,
                firstname,
                lastname,
                password,
                fullname,
                email,
                currency,
                telCode,
                internationalTelCode,
                phone,
                affiliateid,
                bank,
                bankAccNo,
                referralSource,
                referralOtherSource,
                date,
                month,
                dob,
                otherBank,
                otherContact,
                captchaToken,
            } = controller.vm.state.fields;

            let registerPhone = '';
            if (!hideContact) {
                registerPhone = phone;
                if (phone.startsWith('0')) {
                    registerPhone = phone.substring(1, phone.length);
                }

                const telCodeKey = currentTelCodeKey === REG_FIELD.INTERNATIONAL_TELCODE ? internationalTelCode : telCode;
                registerPhone = telCodeKey + registerPhone;
                if (registerPhoneVerification) {
                    if (!controller.vm.state.otpVerified) {
                        return;
                    }
                }
            }

            if (!hideDob) {
                dob.setDate(date);
                dob.setMonth(month - 1);
            }

            let registerEmail = email;
            const defaultEmail = (username || fullname.replace(/ /g, '_')) + '@' + window.merchantCode + '.noemail';

            if (email === '' && registerEmailOptional) {
                registerEmail = defaultEmail;
            }

            let languageKey = language.key;
            let regObj = {
                login: username,
                password: password,
                email: registerEmail,
                name: fullname === '' ? firstname + lastname : fullname,
                langKey: languageKey,
                language: languageKey.toUpperCase(),
                phone: hideContact ? null : registerPhone,
                merchantCode: window.merchantCode,
                currency: currencyMapping ? currencyMapping : currency,
                affiliateName: affiliateid === '' ? null : affiliateid,
                referralSource: window.isAffiliate ? 'Affiliate' : referralSource ? referralSource : null,
                specifyOtherSource: referralOtherSource ? referralOtherSource : null,
                dob: hideDob ? null : dob, // NOTE: sp2 will pass default dob, not sure passing null here would effect anything not.
                captchaToken: captchaToken,
                verificationType: 'REGISTRATION',
            };

            if (isExternalRegister) {
                let providerString = controller.vm.props.location.search;
                let urlParams = new URLSearchParams(providerString);
                regObj = {
                    login: username,
                    password: password,
                    name: fullname === '' ? firstname + ' ' + lastname : fullname,
                    email: registerEmail,
                    langKey: languageKey,
                    language: languageKey.toUpperCase(),
                    phone: hideContact && !registerPhone ? '' : registerPhone,
                    merchantCode: window.merchantCode,
                    country: controller.vm.props.language.country,
                    currency: currencyMapping ? currencyMapping : currency,
                    affiliateName: affiliateid === '' ? null : affiliateid,
                    referralSource: window.isAffiliate ? 'Affiliate' : referralSource ? referralSource : null,
                    specifyOtherSource: referralOtherSource ? referralOtherSource : null,
                    dob: hideDob ? null : dob,
                    socialMediaUserId: urlParams.get('socialMediaUserId'),
                    socialMediaProvider: urlParams.get('socialMediaProvider'),
                    requestToken: urlParams.get('requestToken'),
                    picture: urlParams.get('picture'),
                    isSocialMediaRegister: isExternalRegister,
                    captchaToken: captchaToken,
                    receiveOffersFromMerchant: false,
                    verificationType: 'EXTERNAL_REGISTRATION',
                };
            }

            if (hideBank === undefined && !hideBank && bank) {
                if (regObj.registerBankId === -1) {
                    if (otherBank === '') {
                        controller.vm.state.errors['otherBank'] = 'bank:bank.newBank.bankNamerequired';
                        controller.vm.setState({ isFormValid: false, disabledButton: false });
                        return;
                    }
                    regObj.bankName = otherBank;
                    regObj.accountNumber = bankAccNo;
                } else {
                    regObj.registerBankId = bank.bankId;
                    regObj.bankBranch = bank.bankBranch;
                    regObj.bankName = bank.bankName;
                    regObj.accountNumber = bankAccNo;
                }
            }

            if (otherContacts && otherContacts.length > 0) {
                let contactJson = {};
                let contactArr = [];

                for (let i = 0; i < otherContacts.length; i++) {
                    if (otherContacts.isEnabled) {
                        contactJson = {
                            contactType: otherContacts[i].type,
                            value: otherContact[otherContacts[i].type] === null ? '' : otherContact[otherContacts[i].type],
                        };
                        contactArr.push(contactJson);
                    }
                }

                regObj.otherContacts = JSON.stringify(contactArr);
            }

            window.SPL_Member.validateIpDevice(username).then((data) => {
                if (data.error) {
                    core.portalIsLoading(controller.vm.props, false);
                    controller.vm.setState({ disabledButton: false }, () => {
                        const isMobile = Utils.force2Mobile();

                        if (isMobile) {
                            controller.vm.props.dispatch(portalAction.floatingPageUpdated(''));
                        }

                        controller.vm.props.history.push('/redirect-error?' + data.error);
                    });
                } else {
                    const fpRegisterParam = {
                        deviceType: screen.viewType,
                        deviceOs: `${browserName}_${osName}_${osVersion}_${mobileModel}`,
                    };
                    const finalRegObject = {
                        ...regObj,
                        ...fpRegisterParam,
                    };
                    window.SPL_Register.registerAccount(finalRegObject)
                        .then((data) => {
                            core.portalIsLoading(controller.vm.props, false);
                            if (data.status === 'S') {
                                if (!window.isAgent && !window.isAffiliate) {
                                    if (window.SPL_LiteApp.isZT828LiteApp()) {
                                        window.SPL_LiteApp.onRegisterSuccess(username, password);
                                    }
                                }

                                controller.vm.setState({ disabledButton: false });

                                if (isExternalRegister && (!username || !password)) {
                                    if (data.data.data.message) {
                                        let messageArr = data.data.data.message.split(';');
                                        controller.vm.state.fields['username'] = messageArr[0];
                                        controller.vm.state.fields['password'] = messageArr[1];
                                    }
                                }

                                controller.loginAndNavigate();
                            } else {
                                if (data.defaultErrMsg && data.defaultErrMsg === 'captcha timeout') {
                                    let fields = controller.vm.state.fields;
                                    fields.captchaToken = null;
                                    controller.vm.setState({ fields });
                                }
                                const { errMsg, passwordRulesSettingJson } = data;

                                const turnstileEnabled = controller.enabledTurnstile();
                                if (turnstileEnabled) {
                                    controller.vm.props.dispatch(systemFeatureAction.clearSiteKey());
                                    controller.vm.props.dispatch(systemFeatureAction.fetchCloudFlareTurnstileSetting());
                                }

                                if (errMsg === 'captcha triggered') {
                                    controller.vm.setState({ showSliderCaptcha: true });
                                } else {
                                    notification.showNotification(
                                        'error',
                                        Array.isArray(errMsg) ? (
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: controller.vm.props.t(errMsg[0], {
                                                        interpolation: { escapeValue: false },
                                                        min: passwordRulesSettingJson?.min || 6,
                                                        max: passwordRulesSettingJson?.max || 12,
                                                        specialCharsFormat: passwordRulesSettingJson?.specialCharsFormat || '~`!@#$%^&*()_-+={[}]|\\:;"\'<,>.?/',
                                                    }),
                                                }}
                                            />
                                        ) : (
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: controller.vm.props.t(errMsg, {
                                                        interpolation: { escapeValue: false },
                                                        min: passwordRulesSettingJson?.min || 6,
                                                        max: passwordRulesSettingJson?.max || 12,
                                                        specialCharsFormat: passwordRulesSettingJson?.specialCharsFormat || '~`!@#$%^&*()_-+={[}]|\\:;"\'<,>.?/',
                                                    }),
                                                }}
                                            />
                                        )
                                    );
                                }
                            }
                        })
                        .catch((err) => {
                            core.portalIsLoading(controller.vm.props, false);
                            controller.vm.setState({ disabledButton: false });
                        });
                }
            });
        });
    },

    loginAndNavigate() {
        let loginObj = {
            domain: '',
            isAffiliate: window.isAffiliate ? window.isAffiliate : false,
            isVipLogin: false,
            language: controller.vm.props.language.key,
            merchantCode: window.merchantCode,
            login: controller.vm.state.fields.username,
            password: controller.vm.state.fields.password,
            rememberMe: false,
        };

        controller.vm.props.dispatch(userAction.login(loginObj)).then(() => {
            controller.vm.props.dispatch(userAction.isAuthenticated()).then(() => {
                if (!window.isAgent && !window.isAffiliate) {
                    if (window.SPL_LiteApp.isZT828LiteApp()) {
                        const { login, password, rememberMe } = loginObj;
                        const { user } = controller.vm.props;
                        const currencyData = user && user.account && user.account.currency;
                        window.SPL_LiteApp.onLoginSuccess(login, password, rememberMe, false, currencyData);
                    }
                }

                controller.vm.setState({ active: 2, isRegisterSuccess: true }, () => {
                    controller.handleRegSuccessful();
                });
            });
        });
    },

    handleRegSuccessful() {
        window.SPL_Register.injectScript(false, () => {
            controller.redirectToDeposit();
        });
    },

    redirectToDeposit(timer = 1500) {
        if (controller.timeOut) {
            clearTimeout(controller.timeOut);
        }

        controller.timeOut = setTimeout(() => {
            if (window.SPL_LiteApp.isZT828LiteApp()) {
                window.SPL_LiteApp.onRegisterCompletedRedirect();
            } else {
                const isMobile = Utils.force2Mobile();
                if (isMobile) {
                    controller.vm.props.dispatch(portalAction.floatingPageUpdated(''));
                }
                controller.vm.props.history.push('/myaccount/deposit');
            }
        }, timer);
    },

    getAffiliateId() {
        let queryParams = window.SPL_Other.getQueryStringParams(controller.vm.props.location.search);
        if (queryParams.hasOwnProperty('affid')) {
            controller.vm.state['fields'].affiliateid = queryParams.affid;
            controller.vm.state.disabledAffiliate = true;
            controller.vm.setState({ disabledAffiliate: true, referralToggle: true });
        }
    },

    getBankList(callback) {
        let hideOtherBank = controller.vm.state.regConfig.hideRegistrationOtherBank ? controller.vm.state.regConfig.hideRegistrationOtherBank : null;
        window.SPL_Transaction.getBankListWithoutLogin(window.merchantCode, controller.vm.state.fields.currency, null, hideOtherBank).then((data) => {
            if (data === undefined) {
                data = [];
            }
            callback(data);
        });
    },

    getRefCode() {
        window.SPL_Content.validateAffParam().then((res) => {
            if (res.validateAffParam) {
                let refCode = '';

                if (res.validateAffParam.refCode) {
                    refCode = res.validateAffParam.refCode;
                } else if (res.validateAffParam.code) {
                    refCode = res.validateAffParam.code;
                }

                if (refCode) {
                    controller.vm.setState({ fields: { ...controller.vm.state['fields'], affiliateid: refCode }, disabledAffiliate: true, referralToggle: true });
                }
            }
        });
    },

    getTnC() {
        let langKey = controller.vm.props.language.countryLanguageKey.replace('_', '-').toLowerCase();
        const { newWindowTerms } = controller.vm.props.portal.settings;

        if (newWindowTerms) {
            window.open('/' + langKey + '/terms', '', 'width=1025,height=700,toolbar=no,location=no,directories=no,status=yes,menubar=no,scrollbars=no');
        } else {
            controller.vm.props.history.push('/terms');
        }
    },

    toggleReferralField(referralToggle) {
        controller.vm.setState({ referralToggle: !referralToggle });
    },

    triggerV3token() {
        controller.vm.setState({ showSliderCaptcha: false });
        let fields = controller.vm.state.fields;
        window.SPL_Content.callGrecaptcha('register', function (token) {
            if (token) {
                fields.captchaToken = token;
                controller.vm.setState(
                    {
                        fields,
                        isFormValid: true,
                    },
                    () => {
                        controller.handleSubmit();
                    }
                );
            }
        });
    },

    getLanguageList() {
        window.SPL_Content.getLanguageList().then((data) => {
            const currentLanguage = {},
                countryLanguageKey = controller.vm.props.language.countryLanguageKey;

            const user = controller.vm.props.user;
            const account = user.account;
            let memberCurrency = null;
            if (account) {
                memberCurrency = account.currency;

                if (memberCurrency) {
                    data = data.filter((list) => list.currency === memberCurrency);
                }
            }

            for (let i = 0; i < data.length; i++) {
                const languages = data[i].languages;
                for (let j = 0; j < languages.length; j++) {
                    if (languages[j].countryLanguageKey === countryLanguageKey) {
                        Object.assign(currentLanguage, languages[j]);
                    }
                }
            }

            controller.vm.setState({ languageList: data, currentLanguage: currentLanguage });
        });
    },

    updateCurrentLanguage() {
        const countryLanguage = controller.vm.props.language;
        controller.vm.setState({ currentLanguage: countryLanguage });
    },

    changeLanguage(langObject) {
        core.setLanguageKeyAndCountryLanguage(controller.vm, langObject.countryLanguageKey, languageAction, false, false, true);
    },

    onLanguageDisplayClick() {
        const { history, dispatch } = controller.vm.props;
        dispatch(portalAction.floatingPageUpdated(''));

        history.push({ pathname: '/language', previousMenuRoute: '/home?p=register' });
    },

    currencyMapping() {
        const { fields } = controller.vm.state;
        let selectedRegion = null;
        let currency = fields['currency'];

        if (currency === 'USD') {
            selectedRegion = 'cambodia';
            currency = 'KHUSD';
        }

        window.SPL_Register.getCurrencyMapping(currency, selectedRegion).then((currencyMapping) => {
            controller.vm.setState({ currencyMapping: currencyMapping });
        });
    },
    checkTurnstileValidation(token = null) {
        // condition to disable submit button, if doesnt meet make it enable
        // 1. master switch got verify_security_validation: ['CLOUDFLARE_TURNSTILE'] in register group
        // 2. token is empty
        const { captchaToken } = controller.vm.state.fields;
        const verifySecurityValidation = controller.enabledTurnstile();
        return captchaToken === null && token === null && !!verifySecurityValidation;
    },
    handleTurnStileToken(token) {
        const turnstileValidation = controller.checkTurnstileValidation(token);
        controller.vm.setState({ fields: { ...controller.vm.state.fields, captchaToken: token }, turnstileValidation });
    },
    enabledTurnstile() {
        const skipLiteApp = controller.turnstileSkipLiteApp();
        return (
            (controller.vm.props?.systemFeatureReducer?.systemFeature?.REGISTRATION?.verify_security_validation || []).includes('CLOUDFLARE_TURNSTILE') &&
            skipLiteApp &&
            !!controller.vm.props?.systemFeatureReducer?.siteKey
        );
    },
    turnstileSkipLiteApp() {
        const { portal } = controller.vm.props;
        const isAllowTurnstileForLiteApp = portal?.settings?.isAllowTurnstileForLiteApp || false;
        return isAllowTurnstileForLiteApp || !window.SPL_LiteApp.isZT828LiteApp();
    },
    turnstileErrorHandling() {
        const { t } = controller.vm.props;
        notification.showNotification('error', t('register:register.messages.error.accessRestricted'));
    },
};

export default controller;

const submitValidationPicker = () => {
    let stopSubmit = false;
    if (controller.vm.state.regConfig?.oneStepRegistration) {
        handleOneStepRegisterValidation();
        if (handleSubmitStopper()) {
            stopSubmit = true;
        }
    } else {
        controller.handleStep1Validation();
        if (handleSubmitStopper()) {
            stopSubmit = true;
        }

        controller.handleStep2Validation();
        if (handleSubmitStopper()) {
            stopSubmit = true;
        }
    }
    return stopSubmit;
};

const handleSubmitStopper = () => {
    if (!controller.vm.state.isFormValid) {
        controller.vm.setState({ disabledButton: false });
        return true;
    }
};

const handleOneStepRegisterValidation = () => {
    const { fields, isExternalRegister } = controller.vm.state;
    const errors = controller.vm.state.errors;

    controller.vm.setState({ disabledButton: true, checkSpecialCharsError: true }, () => {
        if (!isExternalRegister) {
            handlePasswordValidation();

            handleUsernameValidation();

            handlePhoneValidation();
        }

        handleReferralValidation();

        handleFullNameValidation(fields?.fullname, errors);

        handleEmailValidation();
    });
};

export const handlePasswordValidation = () => {
    const { PASSWORD } = REG_FIELD;
    const { passwordReducer } = controller.vm.props;
    const { fields, errors } = controller.vm.state;

    const password = fields[PASSWORD] || '';
    // Check if password field is defined and special error message flag is enabled
    if (password !== undefined) {
        const errMsg = validatePassword(password, passwordReducer?.passwordRules, true);
        handleSpecialErrMsg(errMsg);
        controller.handleInputError(errMsg, PASSWORD, errors);
    }
};

export const handleSpecialErrMsg = (errMsg) => {
    const specialCharError = Array.isArray(errMsg) ? errMsg?.find((errObj) => errObj?.specialCharsFormat) : null;

    if (specialCharError) {
        controller.vm.setState(
            {
                showSpecialCharsErrMsg: !specialCharError.status,
            },
            () => {}
        );
    }
};

export const handleUsernameValidation = () => {
    const { fields, errors } = controller.vm.state;
    const { USERNAME } = REG_FIELD;

    if (typeof fields[USERNAME] !== undefined) {
        window.SPL_Register.validateUsername(fields?.[USERNAME], window.merchantCode).then((errMsg) => {
            controller.handleInputError(errMsg, 'username', errors);
        });
    }
};

export const handleTelCodeChange = (e, key) => {
    updateTelCode(e, key).then(() => {
        handleTelCodeValidation();
        controller.toggleDropdown();
        controller.handleTelCodeSearch('');
    });
};

export const handleTelCodeValidation = () => {
    const { fields, currentTelCodeKey, errors } = controller.vm.state;
    const { PHONE } = REG_FIELD;

    controller.enableFieldByCurrency();
    controller.currencyMapping();
    // After change telcode, validate phone again
    let phone = fields[PHONE];
    window.SPL_Register.validatePhone(phone, window.merchantCode, fields[currentTelCodeKey]).then((errMsg) => {
        controller.handleInputError(errMsg, 'phone', errors);
    });
};

const updateTelCode = (e, key) => {
    const { value, innerText } = e.target;
    let fields = { ...controller.vm.state.fields };
    let telCode = value || innerText;

    return new Promise((resolve) => {
        let massagedTelCode = null;
        const subStringIndex = telCode.substring(0).search(/[A-Za-z]/);
        if (subStringIndex !== -1) {
            massagedTelCode = telCode.substring(0, subStringIndex).replace(/\s/g, '');
        } else {
            massagedTelCode = telCode.replace(/\s/g, '');
        }
        fields[key] = massagedTelCode;
        controller.vm.setState({ fields }, () => {
            resolve();
        });
    });
};

export const handleReferralValidation = () => {
    const { AFFILIATEID } = REG_FIELD;
    const { regConfig, errors, fields } = controller.vm.state;

    if (!fields?.hideAffiliate && typeof affiliateid !== undefined) {
        window.SPL_Register.validateAffiliate(fields[AFFILIATEID], window.merchantCode, regConfig?.customAffiliateErrorMsg).then((errMsg) => {
            controller.handleInputError(errMsg, 'affiliateid', errors);
        });
    }
};

export const handleFullNameOnChange = () => {
    const { FULLNAME } = REG_FIELD;
    const { fields, regConfig, errors } = controller.vm.state;
    const { isFullName } = regConfig;
    const fullname = fields[FULLNAME];
    if (isFullName === undefined || isFullName) {
        if (typeof fullname !== undefined) {
            window.SPL_Register.validateFullName(fullname, window.merchantCode).then((errMsg) => {
                controller.handleInputError(errMsg, 'fullname', errors);
            });
        }
    }
};

export const handleEmailValidation = () => {
    const { EMAIL } = REG_FIELD;
    const { fields, errors, regConfig } = controller.vm.state;
    const email = fields?.[EMAIL];
    if (regConfig.registerEmailOptional) {
        return;
    }
    window.SPL_Register.validateEmail(email, window.merchantCode).then((errMsg) => {
        controller.handleInputError(errMsg, EMAIL, errors);
    });
};

const handleFullNameValidation = (fullname, errors) => {
    const { fields } = controller.vm.state;
    const { isFullName } = controller.vm.state.regConfig;
    if (isFullName) {
        if (typeof fullname !== undefined) {
            window.SPL_Register.validateFullName(fullname, window.merchantCode).then((errMsg) => {
                // purposely change to invalid
                if ((errMsg || []).filter((a) => a.indexOf('specialCharacter') >= 0) > 0) {
                    for (let i = 0; i < errMsg.length; i++) {
                        if (errMsg[i].indexOf('specialCharacter') >= 0) {
                            errMsg[i] = errMsg[i].replace('specialCharacter', 'invalid');
                        }
                    }
                }
                controller.handleInputError(errMsg, 'fullname', errors);
            });
        }
    } else {
        if (typeof fields['firstname'] !== undefined && typeof fields['lastname'] !== undefined) {
            let firstname = fields['firstname'] !== undefined ? fields['firstname'] : '';
            let lastname = fields['lastname'] !== undefined ? fields['lastname'] : '';
            let firstErrMsg = [];
            let lastErrMsg = [];
            let errorOccurs = false;

            if (firstname === '' || firstname === null) {
                errorOccurs = true;
                firstErrMsg.push('global:global.form.fullname-placeholderv4');
            }
            if (lastname === '' || lastname === null) {
                errorOccurs = true;
                lastErrMsg.push('global:global.form.fullname-placeholderv3');
            }

            controller.handleInputError(firstErrMsg, 'firstname', errors);
            controller.handleInputError(lastErrMsg, 'lastname', errors);

            // IGNORE checking if last/first name is null
            if (!errorOccurs) {
                window.SPL_Register.validateFullName(lastname + ' ' + firstname, window.merchantCode).then((errMsg) => {
                    controller.handleInputError(errMsg, 'lastname', errors);
                });
            }
        }
    }
};

export const handleCurrencyOnChange = (e) => {
    const { CURRENCY } = REG_FIELD;
    const { fields, errors } = controller.vm.state;
    let field = { ...fields };

    if (field[CURRENCY] !== e.target.value) {
        field[CURRENCY] = e.target.value;
        controller.vm.setState({ fields: field }, () => {
            controller.enableFieldByCurrency();
            controller.currencyMapping();
            controller.handleInputError('', 'currency', errors);
        });
    }
};

export const handlePhoneValidation = () => {
    const { PHONE } = REG_FIELD;
    const { fields, errors, currentTelCodeKey } = controller.vm.state;
    const { hideContact } = controller.vm.state.regConfig;
    if (!hideContact && typeof fields?.[PHONE] !== undefined) {
        window.SPL_Register.validatePhone(fields?.[PHONE], window.merchantCode, fields?.[currentTelCodeKey]).then((errMsg) => {
            controller.handleInputError(errMsg, 'phone', errors);
        });
    }
};

const checkEmptyValidation = (isFormValid) => {
    const { USERNAME, PASSWORD, PHONE, FULLNAME, EMAIL, CURRENCY } = REG_FIELD;
    const { fields, regConfig, active, isExternalRegister } = controller.vm.state;
    let regForm = [];

    if (regConfig?.oneStepRegistration) {
        regForm = [USERNAME, PASSWORD, PHONE, FULLNAME, EMAIL, CURRENCY];
    } else {
        switch (active) {
            case 1:
                regForm = [USERNAME, PASSWORD];
                break;
            case 2:
                regForm = [PHONE, FULLNAME, EMAIL, CURRENCY];
                break;
            default:
                break;
        }
    }

    if (isExternalRegister) {
        regForm = [EMAIL, FULLNAME];
    }

    if (regConfig.registerEmailOptional) {
        regForm = regForm.filter((item) => item !== EMAIL);
    }

    for (const key of regForm) {
        if (fields[key] === '') {
            isFormValid = false;
            break;
        }
    }
    return isFormValid;
};

export const handleStepProceed = () => {
    controller.vm.setState({ checkSpecialCharsError: true, isStepProceed: true }, () => {
        handlePasswordValidation();
    });
};

export const handleStepStopper = (step) => {
    controller.vm.setState({ isStepProceed: false }, () => {
        if (handleSubmitStopper()) {
            return;
        } else {
            controller.proceedTo(step);
        }
    });
};

export const getCurrencyDescription = (currencyCode) => {
    const standardize = {
        MYR: 'Malaysian Ringgit (MYR)',
        SGD: 'Singapore Dollar (SGD)',
    };
    return standardize[currencyCode] || currencyCode;
};

export const checkIsExternalRegister = () => {
    const isSearch = controller.vm.props.location.search.includes('isExternalRegister=true');
    if (isSearch) {
        controller.vm.setState({ isExternalRegister: true });
    }
};
