import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { withTranslation } from 'react-i18next';
import { DebounceInput } from 'react-debounce-input';
import { LazyLoadImage } from 'react-lazy-load-image-component';

class ProviderSelect extends React.Component {
    constructor() {
        super();
        this.state = {
            showItem: false,
            filteredItemList: [],
            keyword: '',
            selectedProvider: '',
        };
    }

    handleEvent(e) {
        switch (e.type) {
            case 'click':
                this.clickListOut(e);
                break;

            default:
                break;
        }
    }

    clickListOut(e) {
        if (this.state.showItem) {
            const stopEventEl = [this.refs.currentSelectRef, this.refs.itemRef];
            for (let index = 0; index < stopEventEl.length; index++) {
                let child = stopEventEl[index];
                if (child && child.contains(e.target)) {
                    return;
                }
            }
        }
        this.setState((state) => ({ ...state, showItem: false }));
    }

    initEvent(off) {
        window[off ? 'removeEventListener' : 'addEventListener']('click', this);
    }

    toggleShowItem() {
        const { showItem } = this.state;

        this.setState({
            showItem: !showItem,
        });
    }

    componentWillMount() {
        this.initEvent();
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedProvider.code !== prevProps.selectedProvider.code) {
            this.setState({ selectedProvider: this.props.selectedProvider });
        }
    }

    componentWillUnmount() {
        this.initEvent(true);
    }

    handleSearchChanged(e) {
        const keyword = e.target.value;
        let filteredItemList = [];
        const { itemList, t } = this.props;

        if (itemList.length > 0) {
            filteredItemList = itemList.filter((item) => t(item.text).toLowerCase().includes(keyword.toLowerCase()));
        }

        this.setState({ keyword: keyword, filteredItemList: filteredItemList });
    }

    render() {
        const { itemList, t, onChange, isSearching } = this.props;
        const { showItem, filteredItemList, selectedProvider } = this.state;

        const list = filteredItemList.length > 0 ? filteredItemList : itemList;

        return (
            <div className={['provider-select', isSearching ? 'selected' : ''].join(' ')}>
                <div
                    ref={'currentSelectRef'}
                    className={['current-select', showItem ? 'on' : ''].join(' ')}
                    onClick={() => {
                        this.toggleShowItem();
                    }}
                >
                    <div className={['provider-group', isSearching ? 'selected' : ''].join(' ')}>
                        <span className='icon-box'>
                            <LazyLoadImage src={'/public/html/images/slots/icon/' + selectedProvider?.code + '.png'} alt='' />
                        </span>
                        {!isSearching && <span>{t(selectedProvider?.name)}</span>}
                    </div>
                    <div className='item-list-container'>
                        <CSSTransition classNames='spread-bottom' in={showItem} timeout={300} unmountOnExit>
                            <ul
                                className='item-list-box'
                                ref={'itemRef'}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                <div className='search-box-container'>
                                    <div className='search-box'>
                                        <DebounceInput
                                            type='text'
                                            debounceTimeout={1000}
                                            values={this.state.keyword}
                                            placeholder={t('global:global.search', 'Search')}
                                            onChange={(e) => {
                                                this.handleSearchChanged(e);
                                            }}
                                        />
                                        <i className='icon-search'></i>
                                    </div>
                                </div>
                                {list.length > 0 ? (
                                    list.map((item, index) => (
                                        <li
                                            key={index}
                                            className=''
                                            onClick={() => {
                                                onChange(item);
                                                this.toggleShowItem();
                                            }}
                                        >
                                            <div className='provider-group'>
                                                <span className='icon-box text-truncate'>
                                                    <LazyLoadImage src={'/public/html/images/slots/icon/' + item.provider?.toLowerCase() + '.png'} alt='' />
                                                </span>
                                                <span className='text-truncate provider-name'>{t(item.text)}</span>
                                            </div>
                                            <div className='hot-new-box'>
                                                {item.isHotProvider && <LazyLoadImage src='/public/html/images/slots/hot.svg' alt=''></LazyLoadImage>}
                                                {item.isNewProvider && <LazyLoadImage src='/public/html/images/slots/new.svg' alt=''></LazyLoadImage>}
                                            </div>
                                        </li>
                                    ))
                                ) : (
                                    <li>
                                        <span>{t('global:global.form.pleaseSelect')}</span>
                                    </li>
                                )}
                            </ul>
                        </CSSTransition>
                    </div>
                    {!isSearching && (
                        <div className='group'>
                            <span className='icon-box'>
                                <CSSTransition classNames='rotate180' in={showItem} timeout={300}>
                                    <i
                                        className='icon-arrow2'
                                        onClick={() => {
                                            this.toggleShowItem();
                                        }}
                                    ></i>
                                </CSSTransition>
                            </span>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default withTranslation('settings')(ProviderSelect);
