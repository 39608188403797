import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import core from 'root/utils/core';
import DesktopPromo_apply from './desktop/promotion.apply';
import MobilePromo_apply from './mobile/promotion.apply';

class PromotionApply extends React.Component {
    components = {
        desktop: {
            default: DesktopPromo_apply,
        },
        mobile: {
            default: MobilePromo_apply,
        },
    };

    render() {
        const TagName = core.getCorrectComponent(this.components, 'promotionApply', this.props.screen.viewType);
        return <TagName {...this.props}></TagName>;
    }
}

export default connect(mapStateToProps, null)(PromotionApply);
