import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import GameList from 'root/webapp/shared/component/GameList';
import { DebounceInput } from 'react-debounce-input';
import Scr from '../../scr/scr.selector';
import Mega from '../../mega/mega.selector';
import Psy8 from '../../psy8/psy8.selector';
import Loading from 'root/webapp/shared/loading/loading';

import vm from '../fast-games.controller';

let tween1, tween2;

class FastGamesGames extends React.Component {
    constructor() {
        super();
        vm.init(this);
    }

    async componentDidMount() {
        await vm.getProviderList(tween1, tween2, true);
        const tabFilter = this.getDefaultTab();
        await vm.getProviderGameList(this.state.currentProvider, tabFilter, undefined, tween1, tween2);
        this.getTabIndex();
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.portal.pathname !== this.props.portal.pathname) {
            await vm.getProviderList(tween1, tween2, true);
            const tabFilter = this.getDefaultTab();
            await vm.getProviderGameList(this.state.currentProvider, tabFilter, undefined, tween1, tween2);
            this.getTabIndex();
        }

        if (prevProps?.providerReducer?.processedData?.favoriteGames !== this.props.providerReducer?.processedData?.favoriteGames) {
            const tabFilter = this.getDefaultTab();
            vm.getProviderGameList(this.state.currentProvider, tabFilter, undefined, tween1, tween2);
        }
    }

    getDefaultTab() {
        const defaultTab = vm.getDefaultTab();
        this.setState({ tabFilter: defaultTab });
        return defaultTab;
    }

    getTabIndex() {
        const { typeList, tabFilter } = this.state;
        const typeListNames = typeList.map((type) => type.toUpperCase());
        const tabIndex = typeListNames.indexOf(tabFilter);
        if (tabIndex > 0) {
            this.setState({ typeActive: tabIndex });
        }
    }

    render() {
        return (
            <Translation>
                {(t) => (
                    <div className='fastgames-games'>
                        {this.state.loading && <Loading className='vw-100 vh-100'></Loading>}

                        <div className='container'>
                            <div className='fastgames-games-items'>
                                <ul className='brand-list'>
                                    {this.state.brandList.map((providerObj, i) => (
                                        <Fragment>
                                            {this.state.brandList[i].underMaintenance && <div className='maintenance-hover-box'></div>}
                                            <li
                                                key={i}
                                                className={[i === this.state.brandActive ? 'on' : ''].join(' ')}
                                                onClick={() => {
                                                    vm.checkUnderMaintenance(providerObj, this.state.brandList[i].underMaintenance, i);
                                                }}
                                            >
                                                <span className='icon-box'>
                                                    <i className={['on', 'icon-brand-' + providerObj.key].join(' ')}></i>
                                                    <i className={['icon-brand-' + providerObj.key].join(' ')}></i>
                                                </span>
                                                {providerObj.isHotProvider && <div className='hot-provider-fastgames'></div>}
                                                {providerObj.isNewProvider && <div className='new-provider-fastgames'></div>}
                                                <span>{t(providerObj.text)}</span>
                                            </li>
                                        </Fragment>
                                    ))}
                                </ul>
                                <div className={'fastgames-games-right ' + this.state.currentProvider}>
                                    {this.state.currentProvider === 'SCR' || this.state.currentProvider === 'SCR2' ? (
                                        <Scr />
                                    ) : this.state.currentProvider === 'MEGA' ? (
                                        <Mega />
                                    ) : this.state.currentProvider === 'PSY8' ? (
                                        <Psy8 />
                                    ) : (
                                        <Fragment>
                                            <div className='type-nav-box'>
                                                <ul className='type-list'>
                                                    {this.state.typeList.map((name, i) => (
                                                        <li
                                                            key={i}
                                                            className={[i === this.state.typeActive ? 'on' : ''].join(' ')}
                                                            onClick={() => {
                                                                vm.updateTabFilter(name);
                                                                vm.toggleTab(i);
                                                            }}
                                                        >
                                                            <span>{name}</span>
                                                        </li>
                                                    ))}
                                                </ul>
                                                <div className='search-box'>
                                                    <DebounceInput
                                                        type='text'
                                                        debounceTimeout={1000}
                                                        values={this.state.keyword}
                                                        placeholder={t('global:global.search', 'Search')}
                                                        onChange={(e) => {
                                                            vm.handleKeywordChanged(e);
                                                        }}
                                                    />
                                                    <i className='icon-search'></i>
                                                </div>
                                            </div>
                                            <div className='type-game-list-box'>
                                                {this.state.gameList.length > 0 && (
                                                    <Fragment>
                                                        <GameList
                                                            list={this.state.gameList}
                                                            updateMemberFavoriteGame={vm.updateMemberFavoriteGame}
                                                            handleCustomClick={vm.launchSlotGame}
                                                            showMore={this.state.showMore}
                                                        />

                                                        {!this.state.showMore && (
                                                            <div className='more-games' data-screen='mobile' key='mobile' onClick={() => vm.showMoreGames()}>
                                                                <span>{t('global:global.hotgames.more-games', 'more games')}</span>
                                                                <span> &gt; </span>
                                                            </div>
                                                        )}
                                                    </Fragment>
                                                )}
                                            </div>
                                        </Fragment>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation('global')(withRouter(FastGamesGames)));
