import React, { Fragment, Suspense, createPortal } from 'react';
import { Route, Switch, Redirect, withRouter, location } from 'react-router-dom';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { ToastContainer } from 'react-toastify';
import PrivateRoute from 'root/webapp/shared/privateRoute';
import ScrollToTop from 'root/webapp/shared/scrollToTop/scrollToTop';
import FloatingNotification from 'root/webapp/shared/floating-notification/floating-notification';

import Loading from './shared/loading/loading';
import Header from './layout/navbar/component/desktop/navbar';
import Footer from './layout/footer/component/desktop/Footer';
import MHeader from './layout/navbar/component/mobile/Navbar';
import MHeaderMyAccount from './portal/settings/landing/component/navbarMyAccount';
import FloatingRightSideMenu from './layout/sidemenu/component/FloatingRightSideMenu';
import MBottomNavbar from './shared/component/wap/BottomNavbar';
import MFooter from 'root/webapp/layout/footer/component/mobile/Footer';
import RedirectError from './portal/provider/redirect-error/redirect-error.selector';
import Maintenance from './portal/provider/maintenance/maintenance.selector';
import RegisterLanding from './portal/register/mobile/RegisterLanding';
import LoginLanding from './portal/login/mobile/LoginLanding';

import Home from './portal/home/home.selector';
import Login from './portal/login/mobile/Login';
import Reset from './portal/reset-password/mobile/Reset';
import Register from './portal/register/register.selector';
import Download from './portal/download/download.selector';
import Casino from './portal/provider/casino/casino.selector';
import Sports from './portal/provider/sports/sports.selector';
import Esports from './portal/provider/e-sports/e-sports.selector';
import Slots from './portal/provider/slots/slots.selector';
import Fishing from './portal/provider/fishing/fishing.selector';
import Lottery from './portal/provider/lottery/lottery.selector';
import FastGames from './portal/provider/fast-games-1/fast-games.selector';
import Poker from './portal/provider/poker/poker.selector';
import Games from './portal/provider/games-3d/games-3d.selector';
import FourD from './portal/provider/4d/4d.selector';
import Cockfight from './portal/provider/cockfight/cockfight.selector';
import Favourite from './portal/favourite/favourite.selector';
import Promos from './portal/promos/promotion.selector';
import ChangeLanguage from './portal/change-language/change-language.selector';
import SettingLanding from './portal/settings/settings.selector';
import SlotsSubGameMenu from './portal/provider/slots/mobile/SubGameMenu';
import GamesSubGameMenu from './portal/provider/games-3d/mobile/SubGameMenu';
import FishingSubGameMenu from './portal/provider/fishing/mobile/SubGameMenu';
import LotterySubGameMenu from './portal/provider/lottery/mobile/SubGameMenu';
import FastGamesSubGameMenu from './portal/provider/fast-games-1/mobile/SubGameMenu';

import fourDSubGameMenu from './portal/provider/4d/mobile/SubGameMenu';
import QuickPayRedirect from './portal/settings/quickpay-redirect/quickpay-redirect';
import MobileCardRedirect from './portal/settings/mobilecard-redirect/mobilecard-redirect';
import MTransfer from './portal/settings/transfer/component/mobile/m-transfer';
import MProfile from './portal/settings/profile/component/mobile/m-profile';
import MBankDetail from './portal/settings/bank-detail/component/mobile/m-bank-detail';
import MMessage from './portal/settings/message/component/mobile/m-message';
import MReferral from './portal/settings/referral/component/mobile/m-referral';
import MWithdraw from './portal/settings/withdraw/component/mobile/m-withdraw';
import MRebate from './portal/settings/instant-rebate/component/mobile/m-instant-rebate';
import MDeposit from './portal/settings/deposit/component/mobile/m-deposit';
import MHistoryLanding from './portal/settings/history/component/mobile/m-history-landing';
import Reward from 'root/webapp/shared/reward-popup/component/reward-popup';
import AboutUs from 'root/webapp/portal/about-us/about-us.selector';
import Faq from 'root/webapp/portal/faq/faq.selector';
import ResponsibleGaming from 'root/webapp/portal/responsible-gaming/responsible-gaming.selector';
import Terms from 'root/webapp/portal/terms/terms.selector';
import Vip from 'root/webapp/portal/vip/vip.selector';
import Password from 'root/webapp/portal/settings/password/password.selector';
import DepositLanding from './portal/settings/deposit/deposit-landing/deposit-landing.selector';
import Leaderboard from 'root/webapp/portal/leaderboard/leaderboard.selector';
import Sponsorship from 'root/webapp/portal/sponsorship/sponsorship.selector';
import PromotionSpecial from '../components/organisms/special-for-you/special-for-you';
import ForceChangePassword from 'root/components/organisms/force-change-password/force-change-password';

import Utils, { browserHack } from 'root/utils';
import commonUtil from 'root/utils/common-util';
import redirectPage from './portal/redirect-page/redirect-page';

class MainRoute extends React.Component {
    viewType = null;

    componentDidUpdate() {
        if (this.viewType === null || this.viewType !== this.props.screen.viewType) {
            this.viewType = this.props.screen.viewType;

            if (this.viewType === 'mobile') {
                Utils.setMetaTag();
                Utils.removeHoverSideEffect();
                browserHack.initEvents();
            }
        }
    }

    render() {
        const { screen, user, language, portal } = this.props;

        let isWeb = screen.viewType === 'web';
        let isLogin = user.isLogin ? user.isLogin : false;
        let isMyAccountModule = window.location.pathname.split('/').includes('myaccount');
        let toastPosition = isWeb ? 'top-center' : 'bottom-center';

        let pathname = window.location.pathname;
        let sliceCount = 2;
        if (pathname.startsWith('/')) {
            pathname = pathname.split('/');
            pathname = pathname.slice(sliceCount)[0];

            switch (pathname) {
                case 'cmd368':
                case 'virtualsports-landing':
                    pathname = 'sports';
            }
        }

        let depositPagesSettings = commonUtil.getSettingFromPortalSettings({
            vm: this,
            settingName: 'depositPagesSettings',
            notViewType: true,
        });

        const transitionTime = window.location.search === '?p=login' || window.location.search === '?p=register' ? 0 : 500;

        // Check the settings in FE and BE to enable popup which will force members to reset password
        let showForceChangePasswordPopup = false;
        if (portal?.settings?.promptChangePassword) {
            if (user?.account?.requiredResetPassword) {
                showForceChangePasswordPopup = true;
            }
        }

        return (
            <div className={language.key}>
                <Reward />

                {user?.isLogin && !this.props.portal.isLoading && showForceChangePasswordPopup && <ForceChangePassword />}

                {isWeb && (
                    <Fragment>
                        <ToastContainer
                            position={toastPosition}
                            autoClose={10000}
                            hideProgressBar={true}
                            newestOnTop
                            closeOnClick={true}
                            rtl={false}
                            pauseOnFocusLoss={false}
                            draggable={false}
                            pauseOnHover
                        ></ToastContainer>

                        <FloatingNotification />

                        <div className={`header-section`}>
                            <Header />
                        </div>

                        <ScrollToTop>
                            <div className='bg-content-section'>
                                <Switch>
                                    <Route exact key='redirect-error' path='/redirect-error' component={RedirectError}></Route>
                                    <Route exact key='home' path='/home' component={Home}></Route>
                                    <Route exact key='register' path={['/register', '/register/external-quick-register']} component={Register}></Route>
                                    <Route exact key='login' path='/login' component={Home}></Route>
                                    <Route exact key='resetpassword' path='/resetpassword' component={Home}></Route>
                                    <Route exact key='download' path='/download' component={Download}></Route>
                                    <Route exact key='casino' path={['/casino', '/casino/:provider']} component={Casino}></Route>
                                    <Route exact key='slots' path={['/slots', '/slots/:provider', '/918kiss', '/mega888', '/psy8']} component={Slots}></Route>
                                    <Route key='sports-provider' path={['/sports', '/sports/:provider', '/cmd368', '/virtualsports-landing']} component={Sports}></Route>
                                    <Route exact key='e-sports' path='/esports' component={Esports}></Route>
                                    <Route exact key='poker' path='/poker' component={Poker}></Route>
                                    <Route exact key='fishing' path={['/fishing', '/fishing/:provider']} component={Fishing}></Route>
                                    <Route exact key='lottery' path='/lottery' component={Lottery}></Route>
                                    <Route exact key='games' path='/games' component={Games}></Route>
                                    <Route exact key='4d' path='/4d' component={FourD}></Route>
                                    <Route exact key='fastgames' path={['/fastgames', '/fastgames/:provider']} component={FastGames}></Route>
                                    <Route exact key='cockfight' path='/cockfight' component={Cockfight}></Route>
                                    <Route exact key='favourite' path='/favourite' component={Favourite}></Route>
                                    <Route exact key='promotion' path='/promotion' component={Promos}></Route>
                                    <Route exact key='aboutus' path='/aboutus' component={AboutUs}></Route>
                                    <Route exact key='faq' path='/faq' component={Faq}></Route>
                                    <Route exact key='responsible-gaming' path='/responsiblegaming' component={ResponsibleGaming}></Route>
                                    <Route exact key='terms' path='/terms' component={Terms}></Route>
                                    <Route exact key='vip' path='/vip' component={Vip}></Route>
                                    <Route exact key='leaderboard' path='/leaderboard' component={Leaderboard}></Route>
                                    <Route exact key='sponsorship' path='/sponsorship' component={Sponsorship}></Route>
                                    <Route exact key='maintenance' path='/maintenance' component={Maintenance}></Route>
                                    {/* <Route exact key='redirect' path='/redirect-liteapp' component={redirectPage} /> */}

                                    <PrivateRoute exact={false} isLogin={user.isLogin} key='settingLanding' path='/myaccount' component={SettingLanding}></PrivateRoute>
                                    <PrivateRoute exact isLogin={user.isLogin} key='quickpay-redirect' path='/quickpayredirect' component={QuickPayRedirect}></PrivateRoute>
                                    <PrivateRoute
                                        exact
                                        isLogin={user.isLogin}
                                        key='mobilecard-redirect'
                                        path='/mobilecardredirect'
                                        component={MobileCardRedirect}
                                    ></PrivateRoute>
                                    {/* default route not defined */}
                                    <Redirect from='*' to={{ pathname: '/home', search: window.location.search }}></Redirect>
                                </Switch>
                            </div>

                            <div className='footer-section'>
                                <Footer />
                            </div>
                        </ScrollToTop>

                        {this.props.portal.isLoading && <Loading className='vw-100 vh-100'></Loading>}
                    </Fragment>
                )}

                {!isWeb && (
                    <div data-logined={isLogin} data-page={isMyAccountModule ? 'account' : pathname}>
                        <div id='fixed-container'>
                            <CSSTransition key='mheader' classNames='fade-next' in={!isMyAccountModule && !this.props.user.isSpecialDialog} timeout={300} unmountOnExit>
                                <MHeader></MHeader>
                            </CSSTransition>

                            <CSSTransition
                                key='mheader-myaccount'
                                classNames='fade-next'
                                in={isMyAccountModule && !this.props.user.isSpecialDialog}
                                timeout={300}
                                unmountOnExit
                            >
                                <MHeaderMyAccount></MHeaderMyAccount>
                            </CSSTransition>

                            <CSSTransition key='login' classNames='slider-y-bottom-in' in={this.props.portal.view.login} timeout={transitionTime} unmountOnExit>
                                <Login data-page={window.location.pathname} />
                            </CSSTransition>

                            <CSSTransition key='register' classNames='slider-y-bottom-in' in={this.props.portal.view.register} timeout={transitionTime} unmountOnExit>
                                <Register />
                            </CSSTransition>

                            <CSSTransition key='resetpassword' classNames='slider-y-bottom-in' in={this.props.portal.view.reset} timeout={500} unmountOnExit>
                                <Reset data-page={window.location.pathname} />
                            </CSSTransition>

                            <CSSTransition key='right-sidemenu' classNames='side-menu' in={this.props.portal.view.sidemenu} timeout={300} unmountOnExit>
                                <FloatingRightSideMenu />
                            </CSSTransition>

                            <CSSTransition key='bottom-navbar' classNames='slider-y-bottom-in' in={isLogin} timeout={500} unmountOnExit>
                                <MBottomNavbar data-page={window.location.pathname} />
                            </CSSTransition>
                        </div>

                        <ToastContainer
                            position={toastPosition}
                            autoClose={10000}
                            hideProgressBar={true}
                            newestOnTop
                            closeOnClick={true}
                            rtl={false}
                            pauseOnFocusLoss={false}
                            draggable={false}
                            pauseOnHover
                        ></ToastContainer>

                        <FloatingNotification />

                        <ScrollToTop>
                            <TransitionGroup className='wap-page'>
                                <CSSTransition
                                    key={this.props.location.pathname}
                                    timeout={500}
                                    classNames='slider-fade-next'
                                    onEnter={() => {
                                        window.scrollTo(0, 0);
                                    }}
                                >
                                    <Fragment>
                                        <div className='bg-content-section'>
                                            <Suspense>
                                                <Switch location={this.props.location}>
                                                    <Route exact key='redirect-error' path='/redirect-error' component={RedirectError}></Route>
                                                    <Route exact key='home' path='/home' component={Home}></Route>
                                                    <Route exact key='download' path='/download' component={Download}></Route>
                                                    <Route exact key='casino' path='/casino' component={Casino}></Route>
                                                    <Route exact key='slots' path='/slots' component={Slots}></Route>
                                                    <Route exact key='sports' path='/sports' component={Sports}></Route>
                                                    <Route exact key='e-sports' path='/esports' component={Esports}></Route>
                                                    <Route exact key='cockfight' path='/cockfight' component={Cockfight}></Route>
                                                    <Route exact key='poker' path='/poker' component={Poker}></Route>
                                                    <Route exact key='fishing' path='/fishing' component={Fishing}></Route>
                                                    <Route exact key='lottery' path='/lottery' component={Lottery}></Route>
                                                    <Route exact key='games' path='/games' component={Games}></Route>
                                                    <Route exact key='4d' path='/4d' component={FourD}></Route>
                                                    <Route exact key='fastgames' path='/fastgames' component={FastGames}></Route>
                                                    <Route exact key='favourite' path='/favourite' component={Favourite}></Route>
                                                    <Route
                                                        exact
                                                        key='slots'
                                                        path={['/slots', '/slots/:provider', '/918kiss', '/mega888', '/psy8']}
                                                        component={SlotsSubGameMenu}
                                                    ></Route>
                                                    <Route exact key='games' path='/games/:provider' component={GamesSubGameMenu}></Route>

                                                    <Route exact key='fishing' path='/fishing/:provider' component={FishingSubGameMenu}></Route>
                                                    <Route exact key='lottery' path='/lottery/:provider' component={LotterySubGameMenu}></Route>
                                                    <Route exact key='fastgames' path='/fastgames/:provider' component={FastGamesSubGameMenu}></Route>
                                                    <Route exact key='4d' path='/4d/:provider' component={fourDSubGameMenu}></Route>
                                                    <Route exact key='promotion' path='/promotion' component={Promos}></Route>
                                                    <Route exact key='vip' path='/vip' component={Vip}></Route>
                                                    <Route exact key='leaderboard' path='/leaderboard' component={Leaderboard}></Route>
                                                    <Route exact key='sponsorship' path='/sponsorship' component={Sponsorship}></Route>
                                                    <Route exact key='change-language' path='/language' component={ChangeLanguage}></Route>
                                                    <Route exact key='terms' path='/terms' component={Terms}></Route>
                                                    <Route exact key='maintenance' path='/maintenance' component={Maintenance}></Route>
                                                    {/* <Route exact key='redirect' path='/redirect-liteapp' component={redirectPage} /> */}
                                                    <PrivateRoute
                                                        isLogin={user.isLogin}
                                                        exact
                                                        key='settings-transfer'
                                                        path='/myaccount/transfer'
                                                        component={MTransfer}
                                                    ></PrivateRoute>
                                                    <PrivateRoute isLogin={user.isLogin} exact key='profile' path='/myaccount/profile' component={MProfile}></PrivateRoute>
                                                    <PrivateRoute
                                                        isLogin={user.isLogin}
                                                        exact
                                                        key='change-password'
                                                        path='/myaccount/password'
                                                        component={Password}
                                                    ></PrivateRoute>
                                                    <PrivateRoute
                                                        isLogin={user.isLogin}
                                                        exact
                                                        key='bank-details'
                                                        path='/myaccount/bankingdetails'
                                                        component={MBankDetail}
                                                    ></PrivateRoute>
                                                    <PrivateRoute isLogin={user.isLogin} exact key='messaging' path='/myaccount/messaging' component={MMessage}></PrivateRoute>
                                                    <PrivateRoute isLogin={user.isLogin} exact key='referral' path='/myaccount/referral' component={MReferral}></PrivateRoute>
                                                    <PrivateRoute isLogin={user.isLogin} exact key='withdraw' path='/myaccount/withdraw' component={MWithdraw}></PrivateRoute>
                                                    <PrivateRoute isLogin={user.isLogin} exact key='rebate' path='/myaccount/rebate' component={MRebate}></PrivateRoute>
                                                    <PrivateRoute
                                                        isLogin={user.isLogin}
                                                        exact
                                                        key='promotionSpecial'
                                                        path='/myaccount/specialforyou'
                                                        component={PromotionSpecial}
                                                    ></PrivateRoute>
                                                    <PrivateRoute
                                                        isLogin={user.isLogin}
                                                        exact
                                                        key='deposit'
                                                        path='/myaccount/deposit'
                                                        component={depositPagesSettings.enableJsonApi ? DepositLanding : MDeposit}
                                                    ></PrivateRoute>
                                                    <PrivateRoute
                                                        isLogin={user.isLogin}
                                                        exact
                                                        key='history'
                                                        path='/myaccount/history'
                                                        component={MHistoryLanding}
                                                    ></PrivateRoute>
                                                    <PrivateRoute
                                                        isLogin={user.isLogin}
                                                        exact
                                                        key='history'
                                                        path='/myaccount/history/transaction'
                                                        component={MHistoryLanding}
                                                    ></PrivateRoute>
                                                    <PrivateRoute
                                                        isLogin={user.isLogin}
                                                        exact
                                                        key='history'
                                                        path='/myaccount/history/promotion'
                                                        component={MHistoryLanding}
                                                    ></PrivateRoute>
                                                    <PrivateRoute
                                                        isLogin={user.isLogin}
                                                        exact
                                                        key='history'
                                                        path='/myaccount/history/rebate'
                                                        component={MHistoryLanding}
                                                    ></PrivateRoute>
                                                    <PrivateRoute
                                                        isLogin={user.isLogin}
                                                        exact
                                                        key='history'
                                                        path='/myaccount/history/rewards'
                                                        component={MHistoryLanding}
                                                    ></PrivateRoute>
                                                    <PrivateRoute isLogin={user.isLogin} exact key='history' path='/myaccount/history/freeSpin' component={MHistoryLanding} />
                                                    <PrivateRoute
                                                        isLogin={user.isLogin}
                                                        exact
                                                        key='quickpay-redirect'
                                                        path='/quickpayredirect'
                                                        component={QuickPayRedirect}
                                                    ></PrivateRoute>
                                                    <Route exact key='register' path={['/register', '/register/external-quick-register']} component={RegisterLanding}></Route>
                                                    <Route exact key='login' path='/login' component={LoginLanding}></Route>
                                                    <PrivateRoute
                                                        exact
                                                        isLogin={user.isLogin}
                                                        key='mobilecard-redirect'
                                                        path='/mobilecardredirect'
                                                        component={MobileCardRedirect}
                                                    ></PrivateRoute>

                                                    {/* default route not defined */}
                                                    <Redirect from='*' to={{ pathname: '/home', search: window.location.search }}></Redirect>
                                                </Switch>
                                            </Suspense>
                                        </div>

                                        <div className='footer-section'>
                                            <MFooter />
                                        </div>
                                    </Fragment>
                                </CSSTransition>
                            </TransitionGroup>
                        </ScrollToTop>

                        {this.props.portal.isLoading && <Loading className='vw-100 vh-100'></Loading>}
                    </div>
                )}
            </div>
        );
    }
}

export default connect(mapStateToProps, null)(withRouter(MainRoute));
