import React, { useRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import createFloatingIconDragger from './floating-icon-dragger';
import FloatingFreeSpinModal from './freespin-modal';
import useFreespin from './useFreespin';

const FloatingFreeSpin = () => {
    const floatingIconRef = useRef(createFloatingIconDragger(false, true));
    const { closeIcon, data, openModal, showIcon, toggleModal } = useFreespin();

    if (!showIcon) return null;

    return (
        <>
            <div
                id='floating-freespin-icon'
                className={`floating-freespin-icon-wrapper`}
                onTouchStart={floatingIconRef.current.handleDragStart}
                onTouchMove={floatingIconRef.current.handleDrag}
                onTouchEnd={floatingIconRef.current.handleDragEnd}
                onDragStart={floatingIconRef.current.handleDragStart}
                onDrag={floatingIconRef.current.handleDrag}
                onDragEnd={floatingIconRef.current.handleDragEnd}
                draggable
                style={{ display: openModal ? 'none' : undefined }}
            >
                <button className='floating-freespin-icon-close' onClick={closeIcon}></button>
                <button onClick={toggleModal}>{data?.floatIcon && <LazyLoadImage className='floating-freespin-icon-image' src={data?.floatIcon} />}</button>
            </div>

            <FloatingFreeSpinModal modalIsOpen={openModal} handleCloseModal={toggleModal} {...data} />
        </>
    );
};

export default FloatingFreeSpin;
