import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import Slider from 'root/webapp/shared/component/Slider';
import { TweenMax } from 'gsap';

import vm from '../casino.controller';

const aniList = [];
const casinoData = [];

class Casino extends React.Component {
    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        vm.initCompSettings();
        vm.getCasinoProviderList().then((data) => {
            casinoData.length = 0;

            for (let i = 0; i < data.length; i++) {
                casinoData.push(data[i]);
            }
            vm.selectProviderBasedOnUrl();
        });
    }

    componentWillUnmount() {
        casinoData.length = 0;
        this.setState = () => {
            return;
        };
    }

    // animation effect
    doSuspendAni(index) {
        aniList.forEach((item) => item.kill());
        aniList.length = 0;
        const idList = casinoData[index].suspend.map((item, j) => `#suspend_${index}_${j}`);

        const endStyleStore = [];
        const randomItem = (list) => {
            return list[0 + Math.round(Math.random() * (list.length - 1 - 0))];
        };

        TweenMax.delayedCall(0.02, () => {
            idList.map((id, k) => {
                const $item = document.querySelector(id);
                if ($item) {
                    endStyleStore[k] = {
                        left: getComputedStyle($item).left,
                        bottom: getComputedStyle($item).bottom,
                    };

                    TweenMax.set(id, {
                        x: -parseFloat(getComputedStyle($item).width) / 2,
                        y: [2, 3, 4, 10].includes(index) ? '100%' : 0,
                        bottom: 0,
                        left: '50%',
                        opacity: 0,
                        rotation: randomItem([-80, -40, 40, 80, 100]),
                        onComplete: () => {
                            TweenMax.set(id, {
                                left: getComputedStyle($item).left,
                            });

                            TweenMax.to(id, 0.8, {
                                x: 0,
                                y: 0,
                                left: endStyleStore[k].left,
                                bottom: endStyleStore[k].bottom,
                                opacity: 1,
                                rotation: 0,
                                delay: 0.2 * k + 0.5,
                                onComplete: () => {
                                    TweenMax.set(id, { left: '', bottom: '' });
                                    if (k == idList.length - 1) {
                                        idList.map((id) => {
                                            aniList.push(
                                                TweenMax.to(id, randomItem([5, 6, 7]), {
                                                    x: `${randomItem(['+', '-'])}=${randomItem([5, 15, 25])}`,
                                                    y: `${randomItem(['+', '-'])}=${randomItem([5, 15, 25])}`,
                                                    rotation: randomItem([15, 10, -10, -5]),
                                                    yoyo: true,
                                                    repeat: -1,
                                                })
                                            );
                                        });
                                    }
                                },
                            });
                        },
                    });
                }
            });
        });
    }

    render() {
        return (
            <div className='casino-panel'>
                {this.state.list.length > 0 && this.state.selectedIndex !== null && (
                    <Slider
                        autoPlay={false}
                        onChange={(i) => {
                            this.doSuspendAni(i);
                            vm.selectProvider(i);
                        }}
                        items={this.state.list}
                        forceStart={true}
                        forceSelectIndex={this.state.selectedIndex}
                        checkOnclick={true}
                    />
                )}
            </div>
        );
    }
}

export default connect(mapStateToProps)(withTranslation('casino')(withRouter(Casino)));
