import { floatingConstant } from '../constant';

const initialState = {
    beforeLoginNotification: [],
    afterLoginNotification: [],
    storeNotificationId: null,
    closeNotification: [],
};

export function floatingNotification(state = initialState, action) {
    switch (action.type) {
        case floatingConstant.GET_BEFORE_LOGIN_NOTIFICATION:
            return {
                ...state,
                beforeLoginNotification: action.payload,
            };

        case floatingConstant.GET_AFTER_LOGIN_NOTIFICATION:
            return {
                ...state,
                afterLoginNotification: action.payload,
            };

        case floatingConstant.STORE_FLOATING_NOTIFICATION_ID:
            return {
                ...state,
                storeNotificationId: action.payload,
            };
        case floatingConstant.GET_CLOSE_NOTIFICATION:
            return {
                ...state,
                closeNotification: action.payload,
            };

        default:
            return state;
    }
}
