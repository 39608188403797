import React from 'react';
import DownloadPanel from './DownloadPanel';

class DownLoad extends React.Component {
    render() {
        return <DownloadPanel />;
    }
}

export default DownLoad;
