import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import core from 'root/utils/core';
import MChangeLanguage from './component/m-change-language';

class ChangeLanguage extends React.Component {
    components = {
        desktop: {
            default: MChangeLanguage,
        },
        mobile: {
            default: MChangeLanguage,
        },
    };

    render() {
        const TagName = core.getCorrectComponent(this.components, 'change-language', this.props.screen.viewType);
        return <TagName />;
    }
}

export default connect(mapStateToProps, null)(ChangeLanguage);
