import React from 'react';
import FlipMove from 'react-flip-move';
import { useTranslation } from 'react-i18next';
import { BsArrowDown, BsArrowUp, BsArrowUpDown } from 'react-icons/bs';

import CustomDatePicker from 'root/components/customDatePicker';
import Pagination from 'root/webapp/shared/pagination/pagination.selector';

import useFreeSpin from '../useFreeSpin';

const FreeSpin = () => {
    const { t } = useTranslation(['history', 'settings', 'transaction', 'providerName']);
    const {
        DateRanges,
        ColumnConfigs,
        selectedDateRange,
        currentPage,
        itemPerPage,
        totalCount,
        pagingList,
        histories,
        startDate,
        endDate,
        sorting,
        handleDateRange,
        handleDateChanged,
        handlePageClick,
        handlePrevNextClick,
        searchHistory,
        sortHistory,
    } = useFreeSpin();

    return (
        <div>
            <div>
                <div className='group-item transaction-date'>
                    <div className='title'>
                        <span>{t('transaction:transaction.history.filter.trans_date')}</span>
                        <span className='required'>*</span>
                    </div>
                    <div className='group'>
                        <ul>
                            {DateRanges.map((item, i) => (
                                <li key={item} className={i === selectedDateRange ? 'on' : ''} onClick={() => handleDateRange(i)}>
                                    <span>{t(item)}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className='group-item date-select'>
                    <div className='title'>{t('transaction:transaction.history.filter.start_date', 'Start Date')}</div>
                    <div className='group'>
                        <div className='input-box'>
                            <label>
                                <CustomDatePicker
                                    placeholderText={t('transaction:transaction.history.filter.start_date', 'Start Date')}
                                    selected={startDate}
                                    onChange={(date) => handleDateChanged(date, true)}
                                    popperPlacement='bottom-start'
                                />
                                <i className='icon-date' />
                            </label>
                        </div>
                        <span>{t('transaction:transaction.history.filter.to')}</span>
                        <div className='input-box'>
                            <label>
                                <CustomDatePicker
                                    placeholderText={t('transaction:transaction.history.filter.end_date', 'End Date')}
                                    selected={endDate}
                                    onChange={(date) => handleDateChanged(date, false)}
                                    popperPlacement='bottom-end'
                                />
                                <i className='icon-date' />
                            </label>
                        </div>
                    </div>
                    <button className='btn-submit' onClick={() => searchHistory(0)}>
                        {t('transaction:transaction.deposit.cashdeposit.submitbtn', 'Submit').toUpperCase()}
                    </button>
                </div>
            </div>

            <div className='history-list freeSpinHistory'>
                <div className='table-container'>
                    <div className='table-header table-header-6'>
                        <ul>
                            {ColumnConfigs.map(({ key, sortable }) => (
                                <li className={`history-header-${key}`} key={key}>
                                    {sortable ? (
                                        <div className='sorting-column'>
                                            <span>{t(`transaction:transaction.freeSpinHistory.${key}`, key)}</span>
                                            {sortable && !sorting[key] && <BsArrowUpDown onClick={() => sortHistory(key)} />}
                                            {sorting[key] === 'ASC' && <BsArrowUp onClick={() => sortHistory(key)} />}
                                            {sorting[key] === 'DESC' && <BsArrowDown onClick={() => sortHistory(key)} />}
                                        </div>
                                    ) : (
                                        <span>{t(`transaction:transaction.freeSpinHistory.${key}`, key)}</span>
                                    )}
                                </li>
                            ))}
                        </ul>

                        {!!histories.length && (
                            <FlipMove className='table-list table-list-6' enterAnimation='fade' leaveAnimation='fade'>
                                {histories.map((history) => (
                                    <ul key={history.id}>
                                        {ColumnConfigs.map(({ key, render }) => (
                                            <li className={`history-data-${key}`} key={key}>
                                                <span>{render(history, t)}</span>
                                            </li>
                                        ))}
                                    </ul>
                                ))}
                            </FlipMove>
                        )}

                        {!histories.length && (
                            <div className='table-list'>
                                <span>{t('transaction:transaction.emptyHistory')}</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <Pagination
                pagingList={pagingList}
                handlePrevNextClick={handlePrevNextClick}
                totalCount={totalCount}
                currentPage={currentPage}
                itemPerPage={itemPerPage}
                handlePageClick={handlePageClick}
            />
        </div>
    );
};

export default FreeSpin;
