// react/library
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation, Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// components/files
import SVGElements from 'root/components/SVGElements';
import MobileSelect from 'root/webapp/shared/component/mobileSelect/mobileSelect';
import TransactionNote from 'root/webapp/shared/transaction-note/transaction-note.selector';
import AddBankDialog from '../../../../../add-bank-dialog/component/add-bank-dialog_96c';

// controller/logic
import vm from '../../bank-transfer.controller';
import core from 'root/utils/core';
import transactionHelper from 'root/utils/transaction-helper';
import { popupAction } from '../../../../../../../../redux/action';
import { trans_getDepositAmountOptionByMethod } from 'root/utils/transaction-util';
import { AMOUNT_DEPOSIT_METHOD_CODE, MODULE_TYPE as MT } from '@constants';
import BankIcon from '../../../../../../../../components/molecules/bank-icon/bank-icon';
import { useAuthSettingsSetup } from '@components/molecules/auth-settings/auth-settings';
import isEmpty from 'lodash/isEmpty';
class BankTransfer extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
    }

    componentDidMount() {
        vm.initCompSettings();
        if (this.state.depositAllSettings !== null) {
            vm.getMerchantAndMemberBanks();
            // vm.getCorrectDepositMinMaxLimit();
            vm.loadBankTransferDropdownOption();
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    componentDidUpdate(prevProps) {
        const prevDepositAmountOptions = JSON.stringify(trans_getDepositAmountOptionByMethod(prevProps.depositAmountOptions, AMOUNT_DEPOSIT_METHOD_CODE.B));
        const currentDepositAmountOptions = JSON.stringify(this.state.depositAmountOptions);
        if (prevDepositAmountOptions !== currentDepositAmountOptions) {
            this.setState({ depositAmountOptions: trans_getDepositAmountOptionByMethod(this.props.depositAmountOptions, AMOUNT_DEPOSIT_METHOD_CODE.B) });
        }
    }

    render() {
        const {
            bankList,
            selectedBankIndex,
            depositAmountOptions,
            depositMinMaxLimit,
            depositOptions,
            depositAllSettings,
            isFormValid,
            disableDepositButton,
            fields,
            addBankDialogIsShow,
            errors,
            selectedChannelIndex,
            amountErrorValue,
            memberBankList,
            receiptImg,
            receiptRequired,
            showAmountField,
            popupCollection,
            customTranslationObj,
        } = this.state;
        const { user, portal } = this.props;
        let transactionNoteProps = {
            customFilename: this.state.customFilename,
            hideDisplayInfo: this.state.compSettings.hideDisplayInfo,
            depositMinMaxLimit: this.state.depositMinMaxLimit,
            showCustomArticle: this.state.showCustomDepositArticle,
            customArticleContent: this.state.showCustomDepositArticleContent,
            customArticleIsJson: this.state.customArticleIsJson,
            customContentStyle: this.state.customContentStyle,
            hideDefaultNote: [!this.state.showDefaultArticle, true, true],
            transactionMethodName: this.props.t('transaction:transaction.deposit.cashdeposit.banktransfer', 'Bank Transfer'),
        };

        let addBankDialogProps = {
            addBankSuccess: vm.addBankSuccess,
            unOwnedBankList: this.state.unOwnedBankList,
        };

        let userCurrency = user && user.account && user.account.currency ? user.account.currency : null;

        if (userCurrency === 'KHUSD') userCurrency = 'USD';

        return (
            <Translation>
                {(t) => (
                    <Fragment>
                        {(addBankDialogIsShow || (popupCollection && popupCollection['add-bank'])) && (
                            // <div
                            //     className='addBank-box'
                            //     onClick={() => {
                            //         transactionHelper.close(this, 'addBank');
                            //     }}
                            // >
                            //     <div
                            //         className='addBank-popup'
                            //         onClick={(e) => {
                            //             e.stopPropagation();
                            //         }}
                            //     >
                            //         <div className='title'>
                            //             <span>{t('transaction:transaction.deposit.addBank.addBankTitle', 'Add Bank')}</span>
                            //             <i
                            //                 className='icon-close3'
                            //                 onClick={() => {
                            //                     transactionHelper.close(this, 'addBank');
                            //                 }}
                            //             ></i>
                            //         </div>

                            //         <div className='content'>
                            //             <div className='input'>
                            //                 <div className='bankName'>{t('transaction:transaction.deposit.addBank.bankName', 'Bank Name')}</div>
                            //                 <select
                            //                     value={accountBankfields['selectedBankIndex']}
                            //                     onChange={(e) => {
                            //                         vm.handleBankChange(e, 'fullname');
                            //                     }}
                            //                 >
                            //                     {unOwnedBankList &&
                            //                         unOwnedBankList.length > 0 &&
                            //                         unOwnedBankList.map((bank, index) => (
                            //                             <option key={index} value={index}>
                            //                                 {t(bank.bankName)}
                            //                             </option>
                            //                         ))}
                            //                 </select>
                            //             </div>

                            //             {showBankName && (
                            //                 <div className='input'>
                            //                     <div className='bankName'>{t('transaction:transaction.deposit.addBank.yourBankName', 'Your Bank Name')}</div>
                            //                     <DebounceInput
                            //                         type='text'
                            //                         values={accountBankfields['bankName']}
                            //                         placeholder={t('transaction:transaction.deposit.addBank.yourBankName', 'Your Bank Name')}
                            //                         onChange={(e) => {
                            //                             transactionHelper.handleAddBankChange(this, e, 'bankName');
                            //                         }}
                            //                     />
                            //                 </div>
                            //             )}

                            //             <div className='input'>
                            //                 <div>{t('transaction:transaction.deposit.addBank.bankBranch', 'Bank Branch')}</div>
                            //                 <DebounceInput
                            //                     type='text'
                            //                     values={accountBankfields['bankBranch']}
                            //                     placeholder={t('transaction:transaction.deposit.addBank.bankBranch', 'Bank Branch')}
                            //                     onChange={(e) => {
                            //                         transactionHelper.handleAddBankChange(this, e, 'bankBranch');
                            //                     }}
                            //                 />
                            //             </div>

                            //             <div className='input'>
                            //                 <div>{t('transaction:transaction.deposit.addBank.accountName', 'Account Name')}</div>
                            //                 <DebounceInput type='text' placeholder={user.account.name} disabled />
                            //             </div>

                            //             <div className='input'>
                            //                 <div>{t('transaction:transaction.deposit.addBank.accountNumber', 'Account Number')}</div>
                            //                 <DebounceInput
                            //                     type='text'
                            //                     values={accountBankfields['accountNumber']}
                            //                     placeholder={t('transaction:transaction.deposit.addBank.accountNumber', 'Account Number')}
                            //                     onChange={(e) => {
                            //                         transactionHelper.handleAddBankChange(this, e, 'accountNumber');
                            //                     }}
                            //                 />
                            //             </div>

                            //             <div className='input-error'>{t(errors['accountNumber'])}</div>

                            //             <button
                            //                 className='btn-submit'
                            //                 disabled={!transactionHelper.isValidForm(this)}
                            //                 onClick={() => transactionHelper.handdleAddAccountBankSubmit(this)}
                            //             >
                            //                 {t('transaction:transaction.history.filter.submit', 'Submit').toUpperCase()}
                            //             </button>
                            //         </div>
                            //     </div>
                            // </div>
                            <AddBankDialog
                                {...addBankDialogProps}
                                closeFunction={() => {
                                    vm.togglePopup('add-bank');
                                    this.props.dispatch(popupAction.setPopupVisible(false));
                                }}
                            />
                        )}

                        {bankList.length > 0 && (
                            <div className='group-item bank-options'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.onlinetransfer.bank', 'Bank Options')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='bank-list bank-selector'>
                                    <ul className='bank-list'>
                                        {bankList.map((bank, i) => (
                                            <li
                                                key={i}
                                                className='small-bank bank-acc-list'
                                                onClick={() => {
                                                    vm.onBankChanged(bank);
                                                }}
                                            >
                                                <div className='member-bankacc'>
                                                    <label>
                                                        <input
                                                            type='radio'
                                                            name='bank-account'
                                                            value='0'
                                                            checked={selectedBankIndex === i}
                                                            onChange={() => {
                                                                vm.onBankChanged(bank);
                                                            }}
                                                        />
                                                        <span className='checkmark'></span>
                                                        <div className='banklist-group'>
                                                            <div className='group-box bankgroup-box-container'>
                                                                <div className={`bank-img ${bank.code === `ezpqr` ? 'wider-bank-img' : ''}`}>
                                                                    <div
                                                                        className={[
                                                                            'icon-bank',
                                                                            bank.code === `ezpqr` ? 'icon-bank-wider' : 'icon-bank-big',
                                                                            selectedBankIndex === i ? 'icon-bank-on' : 'icon-bank-off',
                                                                        ].join(' ')}
                                                                    >
                                                                        <div>
                                                                            <BankIcon bankCode={bank.code} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='bank-active'></div>
                                                                </div>

                                                                <div className='bank-name'>{bank.name}</div>
                                                            </div>

                                                            {selectedBankIndex === i && (
                                                                <>
                                                                    <div className='bank-details'>
                                                                        <input disabled='' readOnly defaultValue={bankList[i].accountHolder} />
                                                                        <SVGElements
                                                                            className='copy'
                                                                            name='copy'
                                                                            onClick={() => core.copy(t, bankList[selectedBankIndex].accountHolder)}
                                                                        />
                                                                    </div>
                                                                    <div className='bank-details'>
                                                                        <input disabled='' readOnly defaultValue={bankList[i].accountNumber} />
                                                                        <SVGElements
                                                                            className='copy'
                                                                            name='copy'
                                                                            onClick={() => core.copy(t, bankList[selectedBankIndex].accountNumber)}
                                                                        />
                                                                    </div>
                                                                </>
                                                            )}

                                                            {bank.bankLink && (
                                                                <div className={`bank-item`}>
                                                                    <div className='img-container'></div>
                                                                    <a className='bank-link' href={bank.bankLink} target='_blank' rel='noreferrer'>
                                                                        {t('transaction:transaction.deposit.cashdeposit.bankLinkDesc')}
                                                                    </a>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </label>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        )}

                        {showAmountField && (
                            <div className='group-item input-item'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.onlinetransfer.amount', 'Deposit Amount')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='amount-content special-group'>
                                    <div className='input-box'>
                                        <div className='deposit-input-box'>
                                            <input
                                                id='depositamount'
                                                type='numeric'
                                                autoComplete='off'
                                                placeholder={t('global:global.form.online-transfer-amount-placeholder', {
                                                    min: window.SPL_Other.formatAmount(depositMinMaxLimit ? depositMinMaxLimit.min : 0),
                                                    max: window.SPL_Other.formatAmount(depositMinMaxLimit ? depositMinMaxLimit.max : 0),
                                                })}
                                                min={'0'}
                                                value={fields['amount']}
                                                onChange={(e) => vm.handleAmountChange(e)}
                                                onBlur={() => {
                                                    transactionHelper.handleValidation(this);
                                                }}
                                            />
                                            {portal?.settings?.features?.depositPagesSettings?.enableAccValueButton && depositAmountOptions?.length > 0 && (
                                                <div className='deposit-amount-clear-button' onClick={() => transactionHelper.clearInputFieldAmount(this)}>
                                                    <img src='/public/html/images/settings/clear-button.svg' alt='clear-button-img' />
                                                </div>
                                            )}
                                        </div>
                                        <div className='error-validation'>
                                            <Trans
                                                i18nKey={errors['amount']}
                                                values={{
                                                    amount: window.SPL_Other.formatAmount(amountErrorValue),
                                                    currency: userCurrency,
                                                }}
                                            ></Trans>
                                        </div>
                                    </div>
                                    <ul>
                                        {depositAmountOptions.map((item) => (
                                            <li
                                                key={item}
                                                onClick={() => {
                                                    transactionHelper.onDepositAmountClicked(this, item);
                                                }}
                                            >
                                                {item}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        )}

                        {/* {bankList.length > 0 && (
                            <div className='group-item input-item'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.cashdeposit.bankaccount', 'Bank Account')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='bank-account-container'>
                                    <div className='input-box'>
                                        <input
                                            id='backaccount'
                                            type='text'
                                            autoComplete='off'
                                            disabled={true}
                                            readOnly={true}
                                            value={bankList[selectedBankIndex].accountHolder}
                                        />
                                        <AiOutlineCopy onClick={() => core.copy(t, bankList[selectedBankIndex].accountHolder)}></AiOutlineCopy>
                                    </div>
                                    <div className='input-box'>
                                        <input
                                            id='backaccount'
                                            type='text'
                                            autoComplete='off'
                                            disabled={true}
                                            readOnly={true}
                                            value={bankList[selectedBankIndex].accountNumber}
                                        />
                                        <AiOutlineCopy onClick={() => core.copy(t, bankList[selectedBankIndex].accountNumber)}></AiOutlineCopy>
                                    </div>
                                </div>
                            </div>
                        )} */}

                        {depositAllSettings.showDepositChannel && (
                            <MobileSelect
                                itemList={depositOptions}
                                itemKey={'label'}
                                onChange={(e) => vm.changeChannel(e)}
                                itemIndex={selectedChannelIndex}
                                title={t('transaction:transaction.deposit.cashdeposit.channel', 'Deposit Channel')}
                                required={true}
                            />
                        )}

                        {!depositAllSettings.hideSenderBank && (
                            <div className='group-item'>
                                <MobileSelect
                                    itemList={memberBankList}
                                    itemKey={'displayName'}
                                    onChange={(e) => vm.handleBankDetailChange(e)}
                                    itemIndex={this.state.selectedMemberBankIndex}
                                    title={
                                        customTranslationObj && customTranslationObj.bank
                                            ? t(customTranslationObj.bank)
                                            : t('transaction:transaction.deposit.depositBankDetails', 'Bank Details')
                                    }
                                    required={true}
                                    enableInfoIcon={true}
                                    onImportantNoticeIconClick={() => vm.onImportantNoticeIconClick()}
                                    isShowNoticeBox={this.state.isShowNoticeBox}
                                    depositBankDetailInfo={t(
                                        'transaction:transaction.deposit.bank-select-notify',
                                        'Add or select a bank account you wish to transfer the money from.'
                                    )}
                                />

                                {isEmpty(memberBankList) && (
                                    <div className='error-validation'>
                                            <Trans
                                                i18nKey={errors['memberBankList']}
                                            ></Trans>
                                        </div>
                                )}

                                {!depositAllSettings.hideAddBankAccount && (
                                    <AddBankDetailButton
                                        onClick={() => {
                                            vm.togglePopup('add-bank');
                                            this.props.dispatch(popupAction.setPopupVisible(true));
                                        }}
                                        text={t('transaction:transaction.deposit.addBank.add', 'ADD')}
                                    />
                                )}
                            </div>
                        )}

                        {depositAllSettings.showRefId && (
                            <div className='group-item input-item'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.cashdeposit.referenceid', 'Reference')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='amount-content'>
                                    <div className='input-box'>
                                        <input
                                            id='reference'
                                            type='text'
                                            autoComplete='off'
                                            placeholder={t('transaction:transaction.deposit.cashdeposit.referenceid', 'Reference')}
                                            value={this.state.fields['reference']}
                                            onChange={(e) => transactionHelper.handleFieldChanged(this, e, 'reference')}
                                            onBlur={() => {
                                                transactionHelper.handleValidation(this);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        {receiptImg.length > 0 && (
                            <div className='group-item'>
                                <div className='title'></div>
                                <div className='amount-content'>
                                    <div className='input-box receipt-image-box'>
                                        <LazyLoadImage src={receiptImg} alt='' />
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className='group-item submit-container'>
                            <div className='title'></div>
                            <div className='amount-content'>
                                <div className='flexbox m-standard-2-button-container'>
                                    <button
                                        className='btn btn-submit standard-button'
                                        onClick={() => {
                                            vm.submitForm();
                                        }}
                                        disabled={!isFormValid || disableDepositButton || receiptRequired}
                                    >
                                        {t('transaction:transaction.deposit.deposithistory.submitbtn', 'SUBMIT').toUpperCase()}
                                    </button>
                                    {depositAllSettings.uploadReceipt && (
                                        <button
                                            className='btn btn-secondary standard-button'
                                            onClick={() => {
                                                transactionHelper.handleUploadReceiptButtonOnClick();
                                            }}
                                        >
                                            {t('transaction:transaction.deposit.cashdeposit.upload', 'UPLOAD RECEIPT').toUpperCase()}
                                            <input
                                                id='hiddenFileInput'
                                                style={{ display: 'none' }}
                                                type='file'
                                                accept='image/*'
                                                onChange={(e) => transactionHelper.handleReceiptImageChange(e, this)}
                                            ></input>
                                        </button>
                                    )}
                                </div>
                                <div className='error-validation'>
                                    {t(customTranslationObj?.uploadReceiptRemark) ||
                                        t(
                                            'transaction:transaction.deposit.cashdeposit.uploadReceiptRemark',
                                            'Interbank transfer and cash deposit machine require receipts for fast approval of deposits'
                                        )}
                                </div>
                            </div>
                        </div>

                        <TransactionNote {...transactionNoteProps} />
                        {/* <div className='notice-box'>
                            <span className='item-title'>{t('transaction:transaction.important-notice', 'IMPORTANT NOTICE')}</span>
                            <ul>
                                <li>{t('transaction:transaction:rule_1')}</li>
                                <li>{t('transaction:transaction:rule_2')}</li>
                                <li>{t('transaction:transaction:rule_3')}</li>
                                <li>{t('transaction:transaction:rule_4')}</li>
                                <li>{t('transaction:transaction:rule_5')}</li>
                                <li>{t('transaction:transaction:rule_6')}</li>
                                <li>{t('transaction:transaction:rule_7')}</li>
                            </ul>
                        </div> */}
                    </Fragment>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'settings'])(withRouter(BankTransfer)));

export const AddBankDetailButton = ({ text, onClick }) => {
    const { handleSetup } = useAuthSettingsSetup({ module: MT?.BANK_DETAIL_BANK, callback: onClick });

    const onAddBtnClick = () => {
        handleSetup();
    };

    return (
        <button className='addBankButton' onClick={onAddBtnClick}>
            {text}
        </button>
    );
};
