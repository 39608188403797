import commonUtil from 'root/utils/common-util';
import dateUtil from 'root/utils/date-util';

const leaderboardService = {
    getMemberRank(vm) {
        const { dateMonth, dateYear } = vm.state.fields;
        let startEnd = dateUtil.getSelectedMonthStartEnd(dateMonth, dateYear);
        let params = {
            start: startEnd.start.getFullYear() + '-' + commonUtil.getDoubleDigit(startEnd.start.getMonth() + 1) + '-' + commonUtil.getDoubleDigit(startEnd.start.getDate()),
            end: startEnd.end.getFullYear() + '-' + commonUtil.getDoubleDigit(startEnd.end.getMonth() + 1) + '-' + commonUtil.getDoubleDigit(startEnd.end.getDate()),
        };

        window.SPL_Leaderboard.getMemberRank(params).then((data) => {
            vm.setState({
                memberRanking: data && data.memberRanking,
                currentMemberRank: data && data.currentMemberRank,
            });
        });
    },

    refreshLeaderboardMonthAndYear(vm) {
        const { dateMonth } = vm.state.fields;
        const { t } = vm.props;

        let _calendar = leaderboardService.getMonthAndYearList(dateMonth);
        for (let i = 0; i < _calendar.monthList.length; i++) {
            _calendar.monthList[i].text = t(`global:${_calendar.monthList[i].text}`);
        }
        let tempDateMonth = _calendar.month;
        vm.setState({
            calendar: _calendar,
            dateMonth: tempDateMonth,
        });
    },

    getMonthAndYearList(selectedMonth) {
        let returnObj = {};
        const currentDate = new Date();
        let monthList = leaderboardService.getLeaderboardMonthList(currentDate.getFullYear());
        returnObj.monthList = monthList;

        let yearList = currentDate.getFullYear();
        returnObj.yearList = yearList;

        if (selectedMonth) {
            let filteredMonth = monthList.filter((e) => e.value === selectedMonth);

            if (filteredMonth.length == 0) {
                returnObj.month = monthList[0].value;
            } else {
                returnObj.month = filteredMonth[0].value;
            }
        }

        return returnObj;
    },

    getLeaderboardMonthList() {
        // get month list
        let fullMonthOptions = dateUtil.getMonthSelectOption();
        let monthList = [];
        const currentDate = new Date();

        const currentYearMonths = fullMonthOptions.filter((month) => month.value <= currentDate.getMonth() + 1);
        monthList = currentYearMonths.slice(-3);

        return monthList;
    },

    /**
     * 	function to check from portal settings if leaderboard is supported in that currency
     * 	if the user selected currency is not supported will redirected to home page
     * 	will also check against logged in member's currency, if the logged in member select diff currency than its own, then will redirect to homepage
     */
    checkLeaderboardCurrency(vm, currencyLang, memberCurrency, memberActualCurrency) {
        const { leaderboardCurrency, leaderboardCurrencyMapping } = vm.state.compSettings;
        const { history, language } = vm.props;
        if (leaderboardCurrency) {
            let _currencyLang = currencyLang;
            // NOTE: if got country means it's cross region e.g. SGUSD. EXCLUDE KHUSD
            // in old portal they define all involved lang in portal-settings, next time i kena tambah if got more, hence add this pattern.
            if (_currencyLang !== 'KHUSD' && _currencyLang.length === 5 && _currencyLang.indexOf(language.country) !== -1) {
                _currencyLang = _currencyLang.slice(2); // NOTE: so far all country code i see is 2 string.
            }

            if (!leaderboardCurrency.includes(_currencyLang)) {
                history.push('/');
            }
            // let actualCurrency = memberActualCurrency;
            // if (leaderboardCurrencyMapping && leaderboardCurrencyMapping[memberActualCurrency]) {
            //     actualCurrency = leaderboardCurrencyMapping[memberActualCurrency]
            // }

            // if ((memberCurrency && memberCurrency !== currencyLang) && (actualCurrency === null || actualCurrency !== currencyLang)) {
            //     history.push('/');
            // }
        }
    },

    maskLeaderboardRank(token, interval) {
        if (!token) {
            return token;
        }

        var tokenChars = token.split('');
        for (var i = 1; i < token.length; i += interval) {
            tokenChars[i] = '*';
        }

        return tokenChars.join('');
    },

    onDateYearChange(vm, value, field) {
        vm.setState(
            (prevState) => {
                let fields = Object.assign({}, prevState.fields);
                fields[field] = value;
                return { fields };
            },
            () => {
                if (field === 'dateMonth') {
                    leaderboardService.getMemberRank(vm);
                } else if (field === 'dateYear') {
                    leaderboardService.refreshLeaderboardMonthAndYear(vm);
                    leaderboardService.getMemberRank(vm);
                }
            }
        );
    },
};

export default leaderboardService;
