import React from 'react';
import FastGamesBanner from './fast-games-banner';
import FastGamesGames from './fast-games-games';

class FastGames extends React.Component {
    render() {
        return (
            <div className='provider-panel'>
                <FastGamesBanner />
                <FastGamesGames />
            </div>
        );
    }
}

export default FastGames;
