// library
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';

// components
import PasswordGuideLine from 'root/components/molecules/password-guideline/password-guideline';
import PasswordInput from 'root/components/molecules/passwordInput/passwordInput';
import Input from 'root/components/atoms/input/input';
import SelectDropdown from '@components/molecules/select-dropdown/select-dropdown';
import Option from '@components/molecules/option/option';
import styles from './form-field.module.scss';
import { ValidationCheckers } from '../password-guideline/password-guideline';

/**
 *
 * @param {String} layout -- horizontal (default) or vertical or leftRight
 * @returns
 */
const FormField = ({ label, value, errorMessage, ...props }) => {
    const {
        isMandatory,
        placeholder,
        onBlur,
        onChange,
        name,
        isShowSpecialCharsGuide,
        disabled,
        type,
        debouncedInputMS,
        onKeyPress,
        onCopyClick,
        layout,
        copyIcon,
        inputClassName,
        isValidationChecker,
        validationCheckers,

        isSelectDropDown, // dropdown props - to use dropdown field
        selectedLabel, // dropdown props - selected value of dropdown
        dropdownItems, // dropdown props - items (required .map pass the onclick and i18n, format follow this component)
    } = props;
    const [touched, setTouched] = useState(false);
    const [focusedField, setFocusedField] = useState(null);

    const handleFieldChange = (event) => {
        // const { value, name } = event.target;
        onChange && onChange(event);
        setTouched(true);
    };

    const handleFieldBlur = (event) => {
        // const { value, name } = event.target;
        onBlur && onBlur(event);
        setFocusedField(null);
    };

    const handleFieldFocus = (event) => {
        const { name } = event.target;
        setFocusedField(name);
    };

    const getFieldColorClassName = () => {
        if (!errorMessage && focusedField === name) {
            return 'blue';
        } else if (!errorMessage && value) {
            return 'correct';
        } else if ((errorMessage || !!value) && focusedField === name) {
            return 'wrong';
        }

        return ''; // Default case if none of the conditions are met
    };

    const colorField = getFieldColorClassName();

    const _fieldProps = {
        value: value,
        type: type,
        name: name,
        className: `${colorField} ${onCopyClick ? 'copy-input' : ''} ${inputClassName || ''}`,
        onChange: handleFieldChange,
        onBlur: handleFieldBlur,
        onFocus: handleFieldFocus,
        onKeyPress: onKeyPress,
        placeholder: placeholder,
        disabled: disabled,
        debouncedInputMS: debouncedInputMS,
        readOnly: props.readOnly,
    };

    return (
        <FormFieldWrapper className={props.className} layout={layout}>
            {/* LABEL */}
            <FormLabel isMandatory={isMandatory} label={label} />

            {/* INPUT */}
            {type === 'password' ? (
                <PasswordInput {..._fieldProps} useDynamicInputComp={true} layout={layout} hasLabel={label} />
            ) : isSelectDropDown ? (
                <>
                    {/* TODO: make selectDropDown support classNAme too, temp use standardForm control */}
                    <SelectDropdown selectedLabel={selectedLabel} disabled={disabled}>
                        {(dropdownItems || []).map((item) => {
                            const { key, i18n, onClick, isActive } = item;
                            return <Option key={key} className={`${isActive} ? 'active' : ''} `} optionLabel={i18n} onClickFunction={onClick} />;
                        })}
                    </SelectDropdown>
                </>
            ) : (
                <>
                    {onCopyClick ? (
                        <div className={`standard-form-field-wrapper ${styles.formFieldWrapper}`}>
                            <Input {..._fieldProps} />
                            <div className={`${styles.copy} icon-copy`} onClick={onCopyClick}>
                                <SVG src={copyIcon} />
                            </div>
                        </div>
                    ) : (
                        <Input {..._fieldProps} />
                    )}
                </>
            )}

            {/* ERROR MESSAGE */}
            <RenderErrorMessage
                isValidationChecker={isValidationChecker}
                name={name}
                touched={touched}
                isShowSpecialCharsGuide={isShowSpecialCharsGuide}
                value={value}
                errorMessage={errorMessage}
                layout={layout}
                validationCheckers={validationCheckers}
            />
        </FormFieldWrapper>
    );
};

export default FormField;

export const RenderInput = () => {};

export const RenderErrorMessage = ({ ...props }) => {
    const passwordReducer = useSelector((state) => state.passwordReducer);
    const { isValidationChecker, touched, value, isShowSpecialCharsGuide, errorMessage, layout, validationCheckers } = props;
    return (
        <>
            {/* NOTE: validationCheckers is the flexible version of isValidationChecker && passwordReducer?.processedData?.passwordConfig?.newErrorFormat */}
            {isValidationChecker && validationCheckers ? (
                <>
                    {touched && (
                        <div className={`${styles.errorMessageWrapper} error-message-wrapper`}>
                            <ValidationCheckers validationCheckers={validationCheckers} />
                        </div>
                    )}
                </>
            ) : (
                <>
                    {isValidationChecker && passwordReducer?.processedData?.passwordConfig?.newErrorFormat ? (
                        <>
                            {touched && (
                                <div className={`${styles.errorMessageWrapper} error-message-wrapper`}>
                                    <PasswordGuideLine
                                        password={value}
                                        customStatusImage={{
                                            success: 'check-circle',
                                            fail: 'warning-circle',
                                        }}
                                        isShowSpecialCharsGuide={isShowSpecialCharsGuide}
                                    />
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            {errorMessage && (
                                <div className={`standard-error-validation ${styles.standardErrorValidation} ${styles?.[layout] || ''}`}>
                                    <span>{errorMessage}</span>
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
};

export const FormLabel = ({ label, ...props }) => {
    const { isMandatory, className } = props;
    return (
        <>
            {label && (
                <label className={`standard-label ${styles.fieldLabel} ${className}`}>
                    {' '}
                    {label} {isMandatory && <span className={'standard-mandatory'}>&nbsp;*</span>}
                </label>
            )}
        </>
    );
};

export const FormFieldWrapper = ({ ...props }) => {
    const { className, layout } = props;
    return <div className={`standard-form-field ${styles.standardFormField} ${className} ${styles?.[layout] || ''} `}>{props.children}</div>;
};
