import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default class LangDisplay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.getLanguageList();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.countryLanguage.countryLanguageKey !== this.props.countryLanguage.countryLanguageKey) {
            this.props.updateCurrentLanguage();
            this.props.getLanguageList();
        }
    }

    render() {
        const { currentLanguage, onLanguageDisplayClick } = this.props;
        const languageKey = currentLanguage && currentLanguage.key;

        return (
            <div
                className='lan-select-box'
                onClick={() => {
                    onLanguageDisplayClick();
                }}
            >
                {currentLanguage && currentLanguage.src && <LazyLoadImage className='icon-flag' src={currentLanguage && currentLanguage.src} alt='country-flag' />}

                <div className='current-lan-box'>
                    <span>{languageKey && languageKey.toUpperCase().replace(/-(.*)+/, '')}</span>
                </div>
            </div>
        );
    }
}
