import React from 'react';
import FlipMove from 'react-flip-move';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import DatePicker from 'react-datepicker';
import { BsArrowUpDown } from 'react-icons/bs';
import Pagination from 'root/webapp/shared/pagination/pagination.selector';
import moment from 'moment';
import vm from '../../instant-rebate.controller';
import commonUtil from 'root/utils/common-util';

import HistoryPopup from '../../sub-component/history-popup/desktop/history-popup';

class InstantRebate extends React.Component {
    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
    }

    componentDidMount() {
        commonUtil.loadCustomTranslation('instant-rebate', this, function () {});
        vm.getPendingRebateTransaction();
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        const { language } = this.props;
        let datepickerLanguage = '';
        if (language.countryLanguageKey === 'TH_TH') {
            datepickerLanguage = 'th';
        } else if (language.countryLanguageKey === 'ZH_MY') {
            datepickerLanguage = 'zhCN';
        }

        const {
            pendingRebateTransaction,
            totalPendingClaim,
            lastRebateDate,
            lastClaimDate,
            customTranslationObj,
            isGenerating,
            isCompleted,
            isError,
            showHistoryPopup,
            histories,
            selectedHistory,
            disableClaim,
        } = this.state;

        let userCurrency = this.props.user && this.props.user.account && this.props.user.account.currency ? this.props.user.account.currency : null;

        if (userCurrency === 'KHUSD') userCurrency = 'USD';

        return (
            <Translation>
                {(t) => (
                    <div className='rebate-page'>
                        <div className='group-item rebate-option'>
                            <span className={this.state.method === 'rebate' ? 'on' : ''} onClick={() => vm.onMethodChange('rebate')}>
                                {t('settings:settings.instantRebateTab.rebate', 'Rebate')}
                            </span>
                            <span className={this.state.method === 'history' ? 'on' : ''} onClick={() => vm.onMethodChange('history')}>
                                {t('settings:settings.instantRebateTab.history', 'History')}
                            </span>
                        </div>

                        {this.state.method === 'rebate' && (
                            <>
                                <div className='table-header'>
                                    <ul>
                                        <li>
                                            {t('rebate:rebate.lastUpdate', 'Last update ')}
                                            {lastRebateDate}
                                        </li>
                                    </ul>
                                </div>
                                <div className='table-body'>
                                    <div className='claim-amount'>
                                        <span className='text'>{t('rebate:rebate.totalPendingClaim', 'Total Pending Claim')}</span>
                                        <span className='amount'>
                                            {userCurrency} {totalPendingClaim && totalPendingClaim.toFixed(2)}
                                        </span>
                                    </div>
                                    <div className='claim-section'>
                                        <div className='claim-column'>
                                            <div className='claim-date'>
                                                <span>{t('rebate:rebate.lastClaim', 'Last claimed')}</span>
                                                <span>{lastClaimDate}</span>
                                            </div>
                                            <button
                                                className='btn-submit'
                                                // disabled={!vm.isValidForm()}
                                                onClick={() => {
                                                    vm.handleClaimSubmit();
                                                }}
                                                disabled={totalPendingClaim === 0 || isGenerating || disableClaim}
                                            >
                                                {t('rebate:rebate.claimAll', 'CLAIM ALL').toUpperCase()}
                                            </button>
                                        </div>

                                        <div className='claim-processing'>
                                            {isGenerating ? (
                                                <span>{t('rebate:rebate.claimInProgress', 'claimInProgress')}</span>
                                            ) : isCompleted ? (
                                                <span>{t('rebate:rebate.claimCompleted', 'claimCompleted')}</span>
                                            ) : (
                                                isError && <span>{t('rebate:rebate.reclaim', 'reclaim')}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className='line'></div>

                                {/* do the control part */}
                                <div className='provider-table'>
                                    {pendingRebateTransaction && pendingRebateTransaction.length > 0 && (
                                        <>
                                            {pendingRebateTransaction.map((list, i) => (
                                                <>
                                                    <div className='provider-table-header'>
                                                        {customTranslationObj && customTranslationObj[list.product] ? t(customTranslationObj[list.product]) : list.product}
                                                    </div>

                                                    <div className='provider-table-list'>
                                                        {list.productHistories.map((hist, i) => (
                                                            <ul>
                                                                <li>
                                                                    <span>{t('rebate:rebate.memberLevel', 'Membership Level')}</span>
                                                                    <span className='list'>{hist.memberGroupCode}</span>
                                                                </li>
                                                                <li>
                                                                    <span>{t('rebate:rebate.provider', 'Provider')}</span>
                                                                    <span className='list'>
                                                                        {customTranslationObj && customTranslationObj[hist.provider]
                                                                            ? t(customTranslationObj[hist.provider])
                                                                            : hist.provider}
                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    <span>{t('rebate:rebate.rebate', 'Rebate %')}</span>
                                                                    <span className='list'>{hist.rate}%</span>
                                                                </li>
                                                                <li>
                                                                    <span>{t('rebate:rebate.validBet', 'Valid Bet')}</span>
                                                                    <span className='list'>{hist.totalEligibleTurnOver}</span>
                                                                </li>
                                                                <li>
                                                                    <span>{t('rebate:rebate.rebateEarned', 'Rebate Earn')}</span>
                                                                    <span className='list rebate-earn'>{hist.rebateAmount.toFixed(2)}</span>
                                                                </li>
                                                            </ul>
                                                        ))}
                                                    </div>
                                                </>
                                            ))}
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                        {this.state.method === 'history' && (
                            <div className='rebate-history'>
                                <>
                                    <div className='transaction-date'>
                                        <div className='title'>
                                            <span>{t('transaction:transaction.history.filter.trans_date')}</span>
                                            <span className='required'>*</span>
                                        </div>
                                        <div className='group'>
                                            <ul>
                                                {this.state.date.list.map((item, i) => (
                                                    <li
                                                        key={item}
                                                        className={[i === this.state.date.active ? 'on' : ''].join(' ')}
                                                        onClick={() => {
                                                            vm.handleDateRange(i, this);
                                                            this.setState({ ...this.state, date: { ...this.state.date, active: i } });
                                                        }}
                                                    >
                                                        <span>{t(item)}</span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='date-select'>
                                        <div className='title'>{t('transaction:transaction.history.filter.start_date', 'Start Date')}</div>
                                        <div className='group'>
                                            <div className='input-box'>
                                                <label>
                                                    <DatePicker
                                                        locale={datepickerLanguage}
                                                        autoComplete='off'
                                                        type='text'
                                                        placeholderText={t('transaction:transaction.history.filter.start_date', 'Start Date')}
                                                        selectsStart
                                                        selected={this.state.fields.startDate}
                                                        onChange={(date) => vm.handleDateChanged(date, true, this)}
                                                        dateFormat='yyyy-MM-dd'
                                                    />
                                                    {/* <input type="text" placeholder="2020-03-30" /> */}
                                                    <i className='icon-date'></i>
                                                </label>
                                            </div>
                                            <span>{t('transaction:transaction.history.filter.to')}</span>
                                            <div className='input-box'>
                                                <label>
                                                    <DatePicker
                                                        locale={datepickerLanguage}
                                                        autoComplete='off'
                                                        type='text'
                                                        placeholderText={t('transaction:transaction.history.filter.end_date', 'End Date')}
                                                        selectsStart
                                                        selected={this.state.fields.endDate}
                                                        onChange={(date) => vm.handleDateChanged(date, false, this)}
                                                        dateFormat='yyyy-MM-dd'
                                                    />
                                                    <i className='icon-date'></i>
                                                </label>
                                            </div>
                                            <button className='btn-submit' onClick={() => vm.searchHistory(0)}>
                                                {t('transaction:transaction.deposit.cashdeposit.submitbtn', 'Submit').toUpperCase()}
                                            </button>
                                        </div>
                                    </div>
                                </>
                                <div className='history-list'>
                                    <div className='table-header table-header-6'>
                                        <ul>
                                            <li>
                                                <div className='sorting-column'>
                                                    <span>{t('rebate:rebate.transactionDate', 'Transaction Date')}</span>
                                                    {/* <BsArrowUpDown onClick={() => vm.sortHistory('createdDate')}></BsArrowUpDown> */}
                                                </div>
                                            </li>
                                            <li>
                                                <div className='sorting-column'>
                                                    <span>{t('rebate:rebate.rebatePeriod', 'Rebate Period')}</span>
                                                    {/* <BsArrowUpDown onClick={() => vm.sortHistory('provider')}></BsArrowUpDown> */}
                                                </div>
                                            </li>
                                            <li>
                                                <div className='sorting-column'>
                                                    <span>{t('rebate:rebate.totalValidBet', 'Total Valid Bet')}</span>
                                                    {/* <BsArrowUpDown onClick={() => vm.sortHistory('totalValidTurnOver')}></BsArrowUpDown> */}
                                                </div>
                                            </li>
                                            <li>
                                                <div className='sorting-column'>
                                                    <span>{t('rebate:rebate.totalPromoTurnover', 'Total Promo Turnover')}</span>
                                                    {/* <BsArrowUpDown onClick={() => vm.sortHistory('totalPromoTurnOver')}></BsArrowUpDown> */}
                                                </div>
                                            </li>
                                            <li>{t('rebate:rebate.totalEligibleTurnover', 'Total Eligible Turnover')}</li>
                                            <li>
                                                <div className='sorting-column'>
                                                    <span>{t('rebate:rebate.amount', 'Amount')}</span>
                                                    <BsArrowUpDown onClick={() => vm.sortHistory('amount')}></BsArrowUpDown>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    {histories.length ? (
                                        <FlipMove className='table-list table-list-6' enterAnimation='fade' leaveAnimation='fade'>
                                            {histories.map((history, i) => (
                                                <ul key={i}>
                                                    <li>
                                                        <span>{moment(history.rebatePeriodEnd).format('YYYY-MM-DD HH:mm').toString()}</span>
                                                    </li>
                                                    <li
                                                        className='rebate-date'
                                                        onClick={() => {
                                                            vm.toggleHistoryPopup(history);
                                                        }}
                                                    >
                                                        <span>{moment(history.rebatePeriodStart).format('YYYY-MM-DD HH:mm').toString() + '-'}</span>
                                                        <span>{moment(history.rebatePeriodEnd).format('YYYY-MM-DD HH:mm').toString()}</span>
                                                    </li>
                                                    <li>
                                                        <span>{window.SPL_Other.formatAmount(history.totalValidTurnOver)}</span>
                                                    </li>
                                                    <li>
                                                        <span>{window.SPL_Other.formatAmount(history.totalPromoTurnOver)}</span>
                                                    </li>
                                                    <li>
                                                        <span>{window.SPL_Other.formatAmount(history.totalEligibleTurnOver)}</span>
                                                    </li>
                                                    <li>
                                                        <span>{`${userCurrency} ${
                                                            window.SPL_Other.formatAmount(history.releasedAmount) ? window.SPL_Other.formatAmount(history.releasedAmount) : 0
                                                        }`}</span>
                                                    </li>
                                                </ul>
                                            ))}
                                        </FlipMove>
                                    ) : (
                                        <div className='table-list'>
                                            <span>{t('transaction:transaction.emptyHistory')}</span>
                                        </div>
                                    )}
                                </div>
                                {showHistoryPopup && (
                                    <HistoryPopup
                                        item={selectedHistory}
                                        isOpen={showHistoryPopup}
                                        closeFunc={vm.toggleHistoryPopup}
                                        startDate={this.state.rebatePeriodStart}
                                        endDate={this.state.rebatePeriodEnd}
                                    />
                                )}

                                <Pagination
                                    pagingList={this.state.pagingList}
                                    handlePrevNextClick={vm.handlePrevNextClick}
                                    totalCount={this.state.totalCount}
                                    currentPage={this.state.currentPage}
                                    itemPerPage={10}
                                    handlePageClick={vm.handlePageClick}
                                ></Pagination>
                            </div>
                        )}
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'bank', 'register', 'rebate'])(withRouter(InstantRebate)));
