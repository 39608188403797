import React from 'react';
import FlipMove from 'react-flip-move';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import Pagination from 'root/webapp/shared/pagination/pagination.selector';
import DeleteMessageDialog from '../../../delete-message-dialog/delete-message-dialog.selector';
// import SVGMessage from 'root/components/Icon/SVGMessage'
import MessageDetailDialog from '../../../message-detail-dialog/message-detail-dialog.selector';
import vm from '../../message.controller';
import SVGElements from 'root/components/SVGElements';
import commonUtil from '../../../../../../utils/common-util';

let containerScrollRef = React.createRef();
let categoryRef = React.createRef();

class MMessage extends React.Component {
    refRecommendedCarousel = React.createRef();

    scrollLeftMilestones = [];
    scrollRightMilestones = [];
    scrollSteps = 0;
    oneCategorySize = 0;
    halfCategorySize = 0;
    initPosUpdated = false;

    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        vm.getMessages(0, this.state.currentMsgType);
        vm.getMemberMessageCount();
    }

    componentWillUnmount() {
        vm.closeFullMessageDetailDialog();
        this.setState = () => {
            return;
        };
    }

    componentDidUpdate(prevState) {
        const { displayTab, currentMsgType } = this.state;
        const fullCategoryVisibleCount = 2;
        const isScrollable = displayTab.length > fullCategoryVisibleCount;

        if (isScrollable) {
            commonUtil.initTabScrollMilestones({
                displayTab,
                scrollLeftMilestones: this.scrollLeftMilestones,
                scrollRightMilestones: this.scrollRightMilestones,
                fullVisibleCategory: fullCategoryVisibleCount,
                categoryRef,
                isMobile: true,
            });

            commonUtil.updateTabScrollInitPos({
                displayTab,
                currentMsgType,
                scrollLeftMilestones: this.scrollLeftMilestones,
                scrollRightMilestones: this.scrollRightMilestones,
                fullVisibleCategory: fullCategoryVisibleCount,
                containerScrollRef,
                initPosUpdated: this.initPosUpdated,
                setInitPosUpdated: () => {
                    this.setInitPosUpdateFlag(true);
                },
            });

            if (prevState.currentMsgType !== this.state.currentMsgType) {
                commonUtil.updateTabScrollPosOnCategoryClicked({
                    displayTab: this.state.displayTab,
                    currentMsgType: currentMsgType,
                    scrollLeftMilestones: this.scrollLeftMilestones,
                    scrollRightMilestones: this.scrollRightMilestones,
                    fullVisibleCategory: fullCategoryVisibleCount,
                    containerScrollRef,
                    categoryRef,
                    categoryIndex: undefined,
                });
            }
        }
    }

    setInitPosUpdateFlag(flag) {
        this.initPosUpdated = flag;
    }

    render() {
        const { user } = this.props;
        const { currentMsgType, displayTab } = this.state;
        const fullCategoryVisibleCount = 2;

        let messageDetailDialogProps = {
            parentvm: this,
            messageDetailDialogIsShow: this.state.messageDetailDialogIsShow,
            closeMessageDetailDialog: vm.closeMessageDetailDialog,
            selectedMessageObj: this.state.selectedMessageObj,
        };

        let deleteMessageDialogProps = {
            parentvm: this,
            deleteMessageDialogIsShow: this.state.deleteMessageDialogIsShow,
            closeDeleteMessageDialog: vm.closeDeleteMessageDialog,
            selectedMessageIds: this.state.selectedMessageIds,
            getMemberMessageCount: vm.getMemberMessageCount,
        };

        const { isSpecialDialog } = this.props.user;

        return (
            <Translation>
                {(t) => (
                    <div data-page='/myaccount/' className={[isSpecialDialog ? 'is-special-dialog' : ''].join('')}>
                        {(this.state.messageDetailDialogIsShow || this.state.messageFullDetailDialogIsShow) && (
                            <MessageDetailDialog {...messageDetailDialogProps}></MessageDetailDialog>
                        )}
                        {!this.state.messageFullDetailDialogIsShow && (
                            <div className='inbox-page'>
                                {this.state.deleteMessageDialogIsShow && <DeleteMessageDialog {...deleteMessageDialogProps}></DeleteMessageDialog>}
                                <div className='inbox-list inbox-tab-container m-standard-2-tab-container' ref={containerScrollRef}>
                                    {displayTab.map((tab, i) => {
                                        return (
                                            <div
                                                key={i}
                                                id={`tab-${i}`}
                                                className={`inbox-tab standard-tab ${currentMsgType === tab.messageType ? 'on' : ''}`}
                                                onClick={() => {
                                                    vm.handleTabClick(tab.messageType);
                                                    if (displayTab.length > fullCategoryVisibleCount) {
                                                        commonUtil.updateTabScrollPosOnCategoryClicked({
                                                            displayTab: this.state.displayTab,
                                                            currentMsgType: currentMsgType,
                                                            scrollLeftMilestones: this.scrollLeftMilestones,
                                                            scrollRightMilestones: this.scrollRightMilestones,
                                                            fullVisibleCategory: fullCategoryVisibleCount,
                                                            containerScrollRef,
                                                            categoryRef,
                                                            categoryIndex: undefined,
                                                        });
                                                    }
                                                }}
                                                ref={categoryRef}
                                            >
                                                {t(tab.tabHeader)} ({tab.unreadMsg ? user && user[tab.unreadMsg] : 0})
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className='inbox-list'>
                                    <div className='table-header'>
                                        <ul>
                                            <li>
                                                <div className='check-box'>
                                                    <i
                                                        className={['checkbox', this.state.allChecked ? 'on' : ''].join(' ')}
                                                        onClick={() => {
                                                            vm.handleSelectAll();
                                                        }}
                                                    ></i>
                                                </div>
                                            </li>
                                            <li>
                                                <span
                                                    onClick={() => {
                                                        vm.handleSelectAll();
                                                    }}
                                                >
                                                    {t('message:message.selectAll', 'Select All')}
                                                </span>
                                            </li>
                                            <li className='icon-list'>
                                                <SVGElements
                                                    className='icon-read-m'
                                                    name='icon-read-m'
                                                    onClick={() => {
                                                        vm.readMessageByBatch();
                                                    }}
                                                />
                                                {!this.state.allFlag && (
                                                    <SVGElements
                                                        className='icon-star-m'
                                                        name='icon-star-m'
                                                        onClick={() => {
                                                            vm.flagSelectedMessage();
                                                        }}
                                                    />
                                                )}
                                                {this.state.allFlag && (
                                                    <i
                                                        className={['icon-star', 'on', 'margin-0'].join(' ')}
                                                        onClick={() => {
                                                            vm.flagSelectedMessage();
                                                        }}
                                                    ></i>
                                                )}
                                                <SVGElements
                                                    className='icon-delete-m'
                                                    name='icon-delete-m'
                                                    onClick={() => {
                                                        vm.openDeleteMessageDialog();
                                                    }}
                                                />
                                            </li>
                                        </ul>
                                    </div>
                                    <FlipMove className='table-list' enterAnimation='fade' leaveAnimation='fade'>
                                        {this.state.messageList.map((msgData, index) => (
                                            <ul key={index} className={[msgData.status === 'N' || msgData.status === 'FN' ? 'newMessage' : ''].join('')}>
                                                <li>
                                                    <div className='check-box'>
                                                        <i
                                                            className={['checkbox', msgData.isChecked ? 'on' : ''].join(' ')}
                                                            onClick={() => {
                                                                vm.handleRowCheckbox(index);
                                                            }}
                                                        ></i>
                                                    </div>
                                                </li>

                                                <li
                                                    onClick={() => {
                                                        vm.openMessageDetailDialog(index);
                                                        this.setInitPosUpdateFlag(false);
                                                    }}
                                                >
                                                    <span>{msgData.title}</span>
                                                </li>

                                                <li className='icon-list'>
                                                    {!vm.isFlag(msgData.status) && (
                                                        <SVGElements
                                                            className='icon-star-m padding-left-0'
                                                            name='icon-star-m'
                                                            onClick={() => {
                                                                vm.flagMessage(index);
                                                            }}
                                                        />
                                                    )}
                                                    {vm.isFlag(msgData.status) && (
                                                        <i
                                                            className={['icon-star', 'on'].join(' ')}
                                                            onClick={() => {
                                                                vm.flagMessage(index);
                                                            }}
                                                        ></i>
                                                    )}
                                                    <SVGElements
                                                        className='icon-delete-m padding-left-0'
                                                        name='icon-delete-m'
                                                        color='#bfbfbf'
                                                        onClick={() => {
                                                            vm.openOneDeleteMessageDialog(msgData);
                                                        }}
                                                    />
                                                </li>
                                            </ul>
                                        ))}
                                    </FlipMove>
                                    <Pagination
                                        pagingList={this.state.pagingList}
                                        handlePrevNextClick={vm.handlePrevNextClick}
                                        totalCount={this.state.totalCount}
                                        currentPage={this.state.currentPage}
                                        itemPerPage={this.state.itemPerPage}
                                        handlePageClick={vm.handlePageClick}
                                        currentMsgType={currentMsgType}
                                    ></Pagination>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['message', 'settings'])(withRouter(MMessage)));
