import React from 'react';
import { withRouter } from 'react-router-dom';
import { Translation, withTranslation } from 'react-i18next';
import SVGElements from 'root/components/SVGElements';
import NewsLetterPopUp from 'root/webapp/portal/news-letter/news-letter-popup';
import SVG from 'react-inlinesvg';

class BottomNavbar extends React.Component {
    constructor() {
        super();
        this.state = {
            navList: [
                { path: '/home', key: 'home', name: 'global:global.sidebar.home.heading', svgIcon: 'home3' },
                { path: '/myaccount/transfer', key: 'funds', name: 'global:global.sidebar.funds.heading', svgIcon: 'fund2' },
                { path: '/myaccount/rebate', key: 'rebate', name: 'global:global.sidebar.account.rebate', svgIcon: 'rebate', svgPath: '/public/html/images/instant-rebate.svg' },
                { path: '/myaccount/profile', key: 'account', name: 'global:global.sidebar.account.myAccount', svgIcon: 'account2' },
                { path: '/livechat', key: 'livechat', name: 'global:global.sidebar.contact-us.liveChat', svgIcon: 'livechat2' },
            ],
        };
    }

    render() {
        const { history } = this.props;
        return (
            <Translation>
                {(t) => (
                    <div className='nav-bar'>
                        <NewsLetterPopUp></NewsLetterPopUp>
                        <ul>
                            {this.state.navList.map(({ key, name, path, svgIcon, svgPath }) => (
                                <li
                                    key={key}
                                    className={['icon-hover-box', history.location.pathname === path ? 'on' : ''].join(' ')}
                                    onClick={() => {
                                        if (!['home', 'funds', 'rebate', 'account'].includes(key)) return;
                                        history.location.pathname !== path && history.replace(path);
                                    }}
                                >
                                    <span className='icon-box'>
                                        {/* <i className={"icon-wap-" + key}></i> */}
                                        {svgPath ? (
                                            <SVG src={`${svgPath}`} />
                                        ) :
                                            (<SVGElements name={svgIcon} className={`icon-menu-item ${svgIcon}`} on={history.location.pathname === path} />)}

                                    </span>
                                    <span>{t(name)}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </Translation>
        );
    }
}

export default withTranslation('global')(withRouter(BottomNavbar));
