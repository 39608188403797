import Utils from '.';
import core from 'root/utils/core';
import { dropdownAction } from 'root/redux/action';
import { gu_getByViewType, gu_getContentByCurrencyLanguage, gu_getSettingFromPortalSettings, gu_jsonConcat, gu_loadCustomTranslation } from './general-util';

const MEMBERSHIP_COLOUR = {
    SILVER: '#a5a5a5',
    ROSE: '#d89393',
    GOLD: '#a38842',
    PLATINUM: '#a5c0d4',
    DIAMOND: '#7d6daa',
    ROYAL: '#f0c783',
};

const commonUtil = {
    isIOS() {
        const mobileData = Utils.initMobileData();
        if (mobileData.ios) {
            return true;
        }
        return false;
    },

    loadMemberGroupAutomationSummary(vm, user) {
        const { account, isLogin } = user;

        if (isLogin) {
            const { SILVER, ROSE, GOLD, PLATINUM, DIAMOND, ROYAL } = MEMBERSHIP_COLOUR;

            let groupName = account.groupName.toUpperCase(),
                color = '',
                accCurrency = '',
                src = '';

            if (groupName.includes('SILVER')) {
                groupName = 'silver';
                color = SILVER;
            } else if (groupName.includes('JADE')) {
                groupName = 'silver';
                color = SILVER;
            } else if (groupName.includes('ROSE')) {
                groupName = 'rose';
                color = ROSE;
            } else if (groupName.includes('GOLD')) {
                groupName = 'gold';
                color = GOLD;
            } else if (groupName.includes('RUBY')) {
                groupName = 'gold';
                color = GOLD;
            } else if (groupName.includes('PLATINUM')) {
                groupName = 'platinum';
                color = PLATINUM;
            } else if (groupName.includes('SAPPHIRE')) {
                groupName = 'platinum';
                color = PLATINUM;
            } else if (groupName.includes('DIAMOND')) {
                groupName = 'diamond';
                color = DIAMOND;
            } else if (groupName.includes('EMERALD')) {
                groupName = 'diamond';
                color = DIAMOND;
            } else if (groupName.includes('ROYAL')) {
                groupName = 'royal';
                color = ROYAL;
            }

            switch (account.currency.toLowerCase()) {
                case 'myr':
                    accCurrency = 'my';
                    break;
                case 'sgd':
                    accCurrency = 'sg';
                    break;
                case 'thb':
                    accCurrency = 'th';
                    break;
                case 'vnd':
                    accCurrency = 'vn';
                    break;
                case 'khusd':
                    accCurrency = 'kh';
                case 'php':
                    accCurrency = 'ph';
                    break;
                default:
                    console.warn('commonUtil :: loadMemberGroupAutomationSummary : Invalid Currency.');
                    break;
            }

            src = '/public/html/images/vip/icon-' + groupName + '-' + accCurrency + '.png';
            vm.setState({ memberGroupImg: src, memberGroupColor: color });
        }
    },
    // timezoneLayout
    // longGmtTimezone = GMT+0800 (Malaysia Time)
    // shortGmtTimezone = GMT+8
    getNavbarLiveDateTimeFormat(props) {
        const { settings } = props.portal;
        // default format
        let datetimeFormat = 'D/M/YYYY h:mm:ssa';
        let timezoneLayout = 'shortGmtTimezone';

        if (settings && settings.features && settings.features.navbarDateTimeDisplay) {
            if (settings.features.navbarDateTimeDisplay.datetimeFormat) {
                datetimeFormat = settings.features.navbarDateTimeDisplay.datetimeFormat;
            }
            if (settings.features.navbarDateTimeDisplay.timezoneLayout) {
                timezoneLayout = settings.features.navbarDateTimeDisplay.timezoneLayout;
            }
        }

        return {
            datetimeFormat: datetimeFormat,
            timezoneLayout: timezoneLayout,
        };
    },

    getDoubleDigit(param) {
        param = param.toString();
        if (param.length < 2) {
            param = '0' + param;
        }
        return param;
    },

    // Simple function to get JSON by view type, will fallback return original obj if view type not found
    // obj -> the obj/json needed to handle, viewType is obj['web'] / obj['mobile']
    // vm -> this
    getByViewType(obj, vm) {
        return gu_getByViewType(obj, vm); // moved to general-util.js
    },

    // combine 2 json into 1
    jsonConcat(json1, json2, vm) {
        return gu_jsonConcat(json1, json2, vm); // moved to general-util.js
    },

    handleHoverOrUnhoverStyleFromJson(params) {
        const { div, oriStyles, newStyles, isHover, customStyles } = params;

        if (div) {
            let target = div;
            if (isHover) {
                let hoveredStyles = commonUtil.jsonConcat(oriStyles, newStyles);
                hoveredStyles = customStyles ? commonUtil.jsonConcat(hoveredStyles, customStyles) : hoveredStyles;

                for (let key in hoveredStyles) {
                    target.style[key] = hoveredStyles[key];
                }
            } else {
                if (target.className && target.className.includes('active')) {
                    return;
                }
                if (newStyles) {
                    for (let i = 0; i < Object.keys(newStyles).length; i++) {
                        let newKey = Object.keys(newStyles)[i];
                        let oriKey = Object.keys(oriStyles).find((e) => e === newKey);
                        let oriValue = oriKey ? oriStyles[oriKey] : null;

                        if (!oriKey) {
                            target.style[newKey] = null;
                        } else {
                            target.style[oriKey] = oriValue;
                        }
                    }
                }
            }
        }
    },

    scrollElementIntoView(vm, element, behavior) {
        const { screen } = vm.props;
        let scrollTop = window.pageYOffset || element.scrollTop;

        // BA requirements: for mobile push the tab to be the 3rd place when open it
        const additionalOffSet = screen.isMobile ? 112 : 0;
        const finalOffset = element.getBoundingClientRect().top + scrollTop - additionalOffSet;

        window.parent.scrollTo({
            top: finalOffset,
            behavior: behavior,
        });
    },

    loadCustomTranslation(currentPage, vm, callback) {
        if (callback) {
            callback(gu_loadCustomTranslation(currentPage, vm, callback));
        }
        return gu_loadCustomTranslation(currentPage, vm, callback);
    },

    getContentByCurrencyLanguage(vm, content, countryLanguageKeySettings = {}) {
        return gu_getContentByCurrencyLanguage(vm, content, countryLanguageKeySettings); // moved to general-util.js
    },

    getSettingFromPortalSettings(params) {
        return gu_getSettingFromPortalSettings(params);
    },

    getIsZT828LiteApp() {
        if (!window.isAgent && !window.isAffiliate && window.SPL_LiteApp.isZT828LiteApp()) return true;
        return false;
    },

    getAppDownloadLink(vm) {
        window.SPL_JsonSettings.getAppDownloadLinkJson((data) => {
            if (data) vm.setState({ appDownloadLinkData: data });
        });
    },

    // NOTE: must pass string else error in lib
    getQRCodeValue(value) {
        if (!value) return '';

        let check = value.charAt(0);
        let checkHttp = value.substring(0, 4);
        if (check === '/' && checkHttp !== 'http') {
            return window.location.origin + value;
        } else {
            return value;
        }
    },

    onAppDownloadClick(device, downloadLink) {
        if (device === 'android') {
            let link = document.createElement('a');
            link.download = `${window.globMerchantName}.apk`;
            link.href = downloadLink;
            link.target = '_blank';

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            link = null;
        } else {
            window.open(downloadLink, '');
        }
    },

    getDepositImgPath(category) {
        switch (category) {
            case 'bank-icon':
                return '/public/html/images/bank-icon/';

            default:
                return '/public/html/images/deposit/';
        }
    },

    getIsFormattedImageSetting(vm) {
        const { depositPagesSettings } = vm.props.portal.settings.features;
        if (depositPagesSettings) return depositPagesSettings.isFormattedImage;
        return null;
    },

    getDepositMinMax(vm, transactionOption, currency, bank, coin = null, bankType = null) {
        core.portalIsLoading(vm.props, true);
        let param = {};
        if (transactionOption === 'CT') {
            param = {
                transactionOption: transactionOption,
                currency: currency,
                bankCode: bank,
                cryptoCoin: coin,
                merchantCode: window.merchantCode,
                bankType: bankType,
            };
        } else {
            if (isNaN(bank)) {
                param = {
                    transactionOption: transactionOption,
                    currency: currency,
                    bankCode: bank,
                    merchantCode: window.merchantCode,
                };
            } else {
                param = {
                    transactionOption: transactionOption,
                    currency: currency,
                    id: bank,
                    merchantCode: window.merchantCode,
                };
            }
        }
        window.SPL_Transaction.getDepositMinMax(param).then((data) => {
            if (data) {
                vm.setState({ depositMinMaxLimit: data });
            }
            core.portalIsLoading(vm.props, false);
        });
    },

    getAffiliateLink(vm, withTimeout = true) {
        return new Promise((resolve) => {
            const { countryLanguageKey, language, portal } = vm.props;
            let languageKey = null;
            if (countryLanguageKey) {
                languageKey = countryLanguageKey;
            } else if (language && language.countryLanguageKey) {
                languageKey = language.countryLanguageKey;
            }
            if (withTimeout) {
                setTimeout(() => {
                    getAndSetAffiliateLink();
                }, 1000);
            } else {
                getAndSetAffiliateLink();
            }

            function getAndSetAffiliateLink() {
                let isAffiliate = false;
                if (window.isAffiliate) {
                    isAffiliate = true;
                }
                window.SPL_Content.displayAffiliateLink(isAffiliate, languageKey).then((res) => {
                    const forceShowAffiliate = portal.settings && portal.settings.features && portal.settings.features.forceShowAffiliate;

                    vm.setState(
                        {
                            showAffiliate: res.showAffiliate,
                            forceShowAffiliate,
                        },
                        () => {
                            let showAffiliate = vm.state.showAffiliate;
                            if (showAffiliate || forceShowAffiliate) {
                                let domain = window.location.hostname;
                                // task 40901, default all use same domain name to redirect to affiliate portal
                                // only when useAffiliateUrl flag is enabled only will use portal setting value
                                if (portal.settings && portal.settings.features && portal.settings.features.useAffiliateUrl) {
                                    vm.setState({ affiliateLink: portal.settings.affiliateUrl });
                                    resolve();
                                } else if (
                                    portal.settings &&
                                    portal.settings.features &&
                                    portal.settings.features.useLanguageAffiliateUrl &&
                                    portal.settings.features.useLanguageAffiliateUrl[languageKey]
                                ) {
                                    vm.setState({ affiliateLink: portal.settings.features.useLanguageAffiliateUrl[languageKey] });
                                    resolve();
                                } else {
                                    // only work in staging, uat and prod environment
                                    let isStagingUat = false;
                                    if (domain.indexOf('staging2.') >= 0 || domain.indexOf('uat2.') >= 0 || domain.indexOf('staging.') >= 0 || domain.indexOf('uat.') >= 0) {
                                        isStagingUat = true;
                                    }

                                    const liteAppPrefix = 'lite-app';

                                    let affiliateDomain = window.location.protocol + '//';
                                    if (isStagingUat) {
                                        domain = domain.replace('staging2.', 'staging.');
                                        domain = domain.replace('uat2.', 'uat.');
                                        domain = 'aff-' + domain;
                                    } else if (domain.indexOf(liteAppPrefix) !== -1) {
                                        domain = domain.replace(liteAppPrefix, 'aff');
                                    } else {
                                        domain = domain.replace('www.', 'aff.');
                                    }
                                    affiliateDomain += domain;

                                    if (portal.settings && portal.settings.features && portal.settings.features.directAffiliateToCorrectLanguage) {
                                        let languageOri = languageKey.replace('_', '-');
                                        let language = languageOri.toLowerCase();

                                        affiliateDomain = affiliateDomain + '/' + language;
                                    }

                                    vm.setState({ affiliateLink: affiliateDomain });
                                    resolve();
                                }
                            }
                        }
                    );
                });
            }
        });
    },
    //dropdownDispatch
    dropdownUpdate(e = false, item = false, props = false, state = null, hide = null) {
        let dropdownObj = {};
        if (
            e &&
            e.target &&
            (e.target.id === 'dropdownBtn' ||
                e.target.id === 'dropdownContainer' ||
                (e.target.parentElement && (e.target.parentElement.id === 'dropdownBtn' || e.target.parentElement.id === 'dropdownContainer')))
        ) {
            if (item) {
                const dropDownObj = props.dropdown ? props.dropdown : props.oriProps.dropdown;
                dropdownObj = {
                    item: item,
                    isToggle: true,
                    certainElementDropdown: hide ? null : state,
                };

                if (props.dropdown.certainElementDropdown && state === props.dropdown.certainElementDropdown) {
                    dropdownObj = false;
                }

                props.dispatch(dropdownAction.dropdownUpdate(dropdownObj));
            }
        } else {
            dropdownObj = false;
            props.dispatch(dropdownAction.dropdownUpdate(dropdownObj));
        }
    },

    initTabScrollMilestones(config) {
        const { displayTab, scrollLeftMilestones, scrollRightMilestones, fullVisibleCategory, categoryRef, isMobile, noHalfCategory } = config;

        const leftMilestones = scrollLeftMilestones;
        const rightMilestones = scrollRightMilestones;

        if ((leftMilestones.length !== 0 && rightMilestones.length !== 0) || displayTab.length === 0) return;

        const oneCategorySize = categoryRef.current && categoryRef.current.clientWidth;
        const halfCategorySize = noHalfCategory ? oneCategorySize : oneCategorySize / 2;

        const steps = displayTab.length - fullVisibleCategory;

        if (oneCategorySize) {
            for (let i = 0, l = steps; i < l; i++) {
                let value = 0;
                if (i === 0) {
                    value = 0;
                } else {
                    value = oneCategorySize + leftMilestones[i - 1];
                }
                leftMilestones.push(value);
            }

            for (let i = 0, l = steps; i < l; i++) {
                let value = 0;
                if (isMobile) {
                    if (i === 0) {
                        value = halfCategorySize;
                    } else if (i === l - 1) {
                        value = oneCategorySize + rightMilestones[i - 1] + halfCategorySize;
                    } else {
                        value = oneCategorySize + rightMilestones[i - 1];
                    }
                } else {
                    if (i === 0) {
                        value = halfCategorySize;
                    } else {
                        value = oneCategorySize + rightMilestones[i - 1];
                    }
                }
                rightMilestones.push(value);
            }

            // console.log("leftmilestones: " + leftMilestones);
            // console.log("rightmilestones: " + rightMilestones);
        }
    },

    updateTabScrollInitPos(config) {
        const {
            displayTab,
            currentMsgType,
            scrollLeftMilestones,
            scrollRightMilestones,
            containerScrollRef,
            containerScroll,
            fullVisibleCategory,
            initPosUpdated,
            setInitPosUpdated,
        } = config;
        const ref = containerScroll ? containerScroll : containerScrollRef;

        if (!initPosUpdated) {
            const leftMilestones = scrollLeftMilestones;
            const rightMilestones = scrollRightMilestones;

            if (leftMilestones.length !== 0 && rightMilestones.length !== 0 && ref.current) {
                let categoryIndex = 0;
                displayTab.forEach((element, index) => {
                    if (currentMsgType === element.messageType) categoryIndex = index;
                });

                let initialPos = 0;
                if (categoryIndex < displayTab.length - fullVisibleCategory) initialPos = leftMilestones[categoryIndex];
                else initialPos = rightMilestones[rightMilestones.length - 1];

                ref.current.scrollLeft = initialPos;

                setInitPosUpdated && setInitPosUpdated();
            }
        }
    },

    updateTabScrollPosOnCategoryClicked(config) {
        const {
            displayTab,
            currentMsgType,
            scrollLeftMilestones,
            scrollRightMilestones,
            fullVisibleCategory,
            containerScrollRef,
            containerScroll,
            categoryRef,
            categoryIndex,
            noHalfCategory,
        } = config;
        const ref = containerScroll ? containerScroll : containerScrollRef;

        if (ref.current) {
            const oneCategorySize = categoryRef.current && categoryRef.current.clientWidth;
            const halfCategorySize = noHalfCategory ? oneCategorySize : oneCategorySize / 2;

            let initialPos = 0;
            const leftMilestones = scrollLeftMilestones;
            const rightMilestones = scrollRightMilestones;

            let categoryIdx = categoryIndex;
            if (categoryIdx === undefined) {
                displayTab.forEach((element, index) => {
                    if (currentMsgType === element.messageType) categoryIdx = index;
                });
            }

            if (categoryIdx < displayTab.length - fullVisibleCategory) initialPos = leftMilestones[categoryIdx];
            else initialPos = rightMilestones[rightMilestones.length - 1];

            const virualInitPos = oneCategorySize * categoryIdx;
            const withinRange = oneCategorySize * fullVisibleCategory - halfCategorySize;

            const leftCondition = initialPos - ref.current.scrollLeft < 0;
            const rightCondition = virualInitPos - ref.current.scrollLeft > withinRange;

            if (ref.current.scrollLeft !== initialPos && (leftCondition || rightCondition)) {
                ref.current.scrollTo({ left: initialPos, top: 0, behavior: 'smooth' });
                // console.log("update pos based on provider: " + initialPos);
            }
        }
    },
};

export function gu_getNestedValue(obj, key) {
    try {
        if (key) {
            key = key.replace(/\[(\w+)\]/g, '.$1'); // split indexes.
            key = key.replace(/^\.+/, ''); // remove leading dot.
            const a = key.split('.');
            for (var i = 0, n = a.length; i < n; ++i) {
                var k = a[i];
                if (k in obj) {
                    obj = obj[k];
                } else {
                    return;
                }
            }
        }

        return obj;
    } catch (err) {
        return undefined;
    }
}

export function gu_filterCommonKey(parentElement = false, childElement = false, filterSettings = {}) {
    let params = {
        loop: filterSettings.loop || false, // use for filter multi key, eg: web: { THB: { en: "test" } }
        baseOnUserCurrency: filterSettings.baseOnUserCurrency || false, // currency will get member currency
        customFilterKeys: filterSettings.customFilterKeys || [], // use for filter special key
    };

    return window.SPL_Content.filterCommonKeyHandler(gu_getNestedValue(parentElement, childElement), params);
}

export default commonUtil;
