import React, { lazy } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import core from 'root/utils/core';

import DesktopPoker from './desktop/Poker';
import MobilePoker from './mobile/Poker';

class Poker extends React.Component {
    components = {
        desktop: {
            default: DesktopPoker,
        },
        mobile: {
            default: MobilePoker,
        },
    };

    render() {
        const TagName = core.getCorrectComponent(this.components, 'poker', this.props.screen.viewType);
        return <TagName></TagName>;
    }
}

export default connect(mapStateToProps, null)(Poker);
