import React, { Fragment } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { CSSTransition } from 'react-transition-group';
import SVGElements from 'root/components/SVGElements';

export default class Dropdown_Langlist extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            in: false,
        };
    }

    componentDidMount() {
        this.initEvent();
        this.props.getLanguageList();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.countryLanguage.countryLanguageKey !== this.props.countryLanguage.countryLanguageKey) {
            this.props.updateCurrentLanguage();
            this.props.getLanguageList();
        }
    }

    initEvent() {
        document.querySelector('#root').addEventListener('click', (e) => {
            if (this.state.in) {
                let stopEventEl = [document.querySelector('.lan-list-box'), document.querySelector('.current-lan-box')];

                for (let index = 0; index < stopEventEl.length; index++) {
                    let child = stopEventEl[index];
                    child = child && child.$el ? child.$el : child;
                    if (child && child.contains(e.target)) return;
                }

                this.onShow();
            }
        });
    }

    onShow() {
        this.setState((state) => ({ in: !state.in }));
    }

    render() {
        const { currentLanguage, languageList, changeLanguage } = this.props;

        return (
            <div className='lan-select-box'>
                {currentLanguage && currentLanguage.src && <LazyLoadImage className='icon-flag' src={currentLanguage && currentLanguage.src} alt='country-flag' />}

                <div className='current-lan-box' onClick={() => this.onShow()}>
                    <span>{currentLanguage && currentLanguage.display === 'Khmer' ? 'ខ្មែរ' : currentLanguage.display}</span>
                    <CSSTransition classNames='rotate180' in={this.state.in} timeout={300}>
                        <SVGElements className='icon-arrow-down' name={`arrow-down-icon`} />
                    </CSSTransition>
                </div>

                <CSSTransition classNames='spread-bottom' in={this.state.in} timeout={300} unmountOnExit>
                    <ul className='lan-list-box'>
                        {languageList &&
                            languageList.map((langObj, i) => {
                                return (
                                    <li key={i} className={`lang-choice`}>
                                        <LazyLoadImage className='icon-flag' src={langObj.languages[0].src} alt='country-flag' />

                                        {langObj.languages.map((lang, i) => (
                                            <Fragment key={i}>
                                                <span
                                                    className={`lang-select ${currentLanguage && currentLanguage.countryLanguageKey === lang.countryLanguageKey ? 'on' : ''}`}
                                                    onClick={() => changeLanguage(lang)}
                                                >
                                                    {lang.display === 'Khmer' ? 'ខ្មែរ' : lang.display}
                                                </span>
                                                {lang !== langObj.languages[langObj.languages.length - 1] && <span className='divide'>/</span>}
                                            </Fragment>
                                        ))}
                                    </li>
                                );
                            })}
                    </ul>
                </CSSTransition>
            </div>
        );
    }
}
