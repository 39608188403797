import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import vm from '../../footer.controller';
import core from 'root/utils/core';

class FooterLiteApp extends React.Component {
    constructor() {
        super();
        this.state = {
            contactList: [],
        };
    }

    componentDidMount() {}

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        const { language } = this.props;

        return (
            <Translation>
                {(t) => (
                    <div className='mobile-footer-liteapp'>
                        <img src={`/public/html/images/mobile/login/ambassador_${language.countryLanguageKey.toLowerCase()}.png`} alt=''></img>

                        {/* {language && language.country !== 'TH' && (
                            <Fragment>
                                <div className='sponsorship-wrapper'>
                                    <div className='ambassador-text'>NAMEWEE x</div>
                                    <img src='/public/html/images/mobile/login/namewee-me88-logo.svg' alt=''></img>
                                </div>
                                <div className='sponsorship-text'>SPONSORSHIP!</div>
                            </Fragment>
                        )} */}

                        <div className='footer-content'>
                            <div className='category-container'>
                                <div className='category'>
                                    {t('login:login.liteapp.footerGamingLicense')}
                                    <div className='icon-container'>
                                        <img src='/public/html/images/mobile/login/gc-logo.svg' alt='' className='up-size'></img>
                                    </div>
                                </div>
                                <div className='category'>
                                    {t('login:login.liteapp.footerCrypto')}
                                    <div className='icon-container'>
                                        <img src='/public/html/images/mobile/login/btc.svg' alt='' className='down-size'></img>
                                        <img src='/public/html/images/mobile/login/eth.svg' alt='' className='down-size'></img>
                                        <img src='/public/html/images/mobile/login/usdt.svg' alt='' className='down-size'></img>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <p>
                            {t('global:global.login-popup.login-issue-desc1', 'If you encounter any issues while loggin in,')}
                            <br />
                            {t('global:global.login-popup.login-issue-desc2', 'please contact our ')}
                            <span
                                onClick={() => {
                                    core.openLivechat(this.props.language.countryLanguageKey, this.props.screen.viewType);
                                }}
                            >
                                {t('global:global.login-popup.login-issue-cs', 'Customer Service')}
                            </span>
                            {t('global:global.login-popup.login-issue-desc3', ' for further assistance.')}
                        </p>

                        <p className='footer-copyright-info'>{t('login:login.liteapp.footerCopyright')}</p>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(vm.getMapStateToProps)(withTranslation(['login'])(FooterLiteApp));
