import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
// import LotteryPanel from './LotteryPanel';
import LotteryPanel from './LotteryPanel02';


class Lottery extends React.Component {
    render() {
        return <LotteryPanel></LotteryPanel>;
    }
}

export default connect(mapStateToProps)(withTranslation(['lottery', 'casino'])(withRouter(Lottery)));
