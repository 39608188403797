import React from 'react';
import PropTypes from 'prop-types';

const CloseButton = ({ width = '100%', height = '100%', fill = '', className = 'fillsecond', ...props }) => (
    <svg width={width} height={height} className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 90 90' {...props}>
        <path
            fill={fill}
            d='M76.8,13.2c-17.6-17.6-46.1-17.6-63.6,0s-17.6,46.1,0,63.6c17.6,17.6,46.1,17.6,63.6,0S94.4,30.8,76.8,13.2z M57.7,62.6
            L45,49.8L32.3,62.6c-1.3,1.3-3.5,1.3-4.8,0c-1.3-1.3-1.3-3.5,0-4.8L40.2,45L27.4,32.3c-1.3-1.3-1.3-3.5,0-4.8c1.3-1.3,3.5-1.3,4.8,0
            L45,40.2l12.7-12.7c1.3-1.3,3.5-1.3,4.8,0c1.3,1.3,1.3,3.5,0,4.8L49.8,45l12.7,12.7c1.3,1.3,1.3,3.5,0,4.8
            C61.2,63.9,59.1,63.9,57.7,62.6z'
        ></path>
    </svg>
);

CloseButton.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    fill: PropTypes.string,
    className: PropTypes.string,
};

export default CloseButton;
