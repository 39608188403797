// react/library
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation, Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';

// components/files
import SVGBank from 'root/components/svgIcon/svgBank';
import SmallLoading from 'root/webapp/shared/loading/smallLoading';
import MobileSelect from 'root/webapp/shared/component/mobileSelect/mobileSelect';
import TransactionNote from 'root/webapp/shared/transaction-note/transaction-note.selector';

// controller/logic
import vm from '../../p2p-deposit.controller';
import core from 'root/utils/core';
import transactionHelper from 'root/utils/transaction-helper';
import commonUtil from '../../../../../../../../utils/common-util';

class QuickPay extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
    }

    componentDidMount() {
        vm.initCompSettings();
        if (this.state.depositAllSettings !== null) {
            vm.loadDepositDetails();
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    componentDidUpdate() {}

    render() {
        const { bankList, depositMinMaxLimit, isFormValid, disableDepositButton, methodList, selectedMethodIndex, selectedBankIndex, thirdPartyMemberBank, showAmountField } =
            this.state;

        let transactionNoteProps = {
            hideDisplayInfo: this.state.compSettings.hideDisplayInfo,
            customOnlineTransactionMode: this.props.t('transaction:transaction.deposit.onlinetransfer.online', 'Online'),
            depositMinMaxLimit: this.state.depositMinMaxLimit,
            showCustomArticle: this.state.showCustomDepositArticle,
            customArticleContent: this.state.showCustomDepositArticleContent,
            customArticleIsJson: this.state.customArticleIsJson,
            customContentStyle: this.state.customContentStyle,
            hideDefaultNote: [!this.state.showDefaultArticle, true, true],
            transactionMethodName: this.props.t('transaction:transaction.deposit.p2p.p2pOption', 'P2P Transfer'),
        };

        let userCurrency = this.props.user && this.props.user.account && this.props.user.account.currency ? this.props.user.account.currency : null;

        if (userCurrency === 'KHUSD') userCurrency = 'USD';

        return (
            <Translation>
                {(t) => (
                    <Fragment>
                        <div className='group-item bank-options'>
                            <div className='title'>
                                <span>{t('transaction:transaction.deposit.cashdeposit.channel', 'Deposit Channel')}</span>
                                <span className='required'>*</span>
                            </div>

                            <div className='bank-list bank-selector'>
                                <ul className='bank-list'>
                                    {methodList.map((method, i) => (
                                        <li
                                            key={i}
                                            className='small-bank bank-acc-list'
                                            onClick={() => {
                                                vm.onMethodChanged(i);
                                            }}
                                        >
                                            <div className='member-bankacc'>
                                                <label>
                                                    <input
                                                        type='radio'
                                                        name='bank-account'
                                                        value='0'
                                                        checked={selectedMethodIndex === i}
                                                        onChange={() => {
                                                            vm.onMethodChanged(i);
                                                        }}
                                                    />
                                                    <span className='checkmark'></span>
                                                    <div className='banklist-group'>
                                                        <div className='group-box bankgroup-box-container'>
                                                            <div className={`bank-img ${method.code.toLowerCase() === `ezpqr` ? 'wider-bank-img' : ''}`}>
                                                                <div
                                                                    className={[
                                                                        'icon-bank',
                                                                        method.code.toLowerCase() === `ezpqr` ? 'icon-bank-wider' : 'icon-bank-big',
                                                                        selectedMethodIndex === i ? 'icon-bank-on' : 'icon-bank-off',
                                                                    ].join(' ')}
                                                                >
                                                                    {commonUtil.getIsFormattedImageSetting(this) && method.svgData ? (
                                                                        <div
                                                                            className={`icon-${method.code}`}
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: method.svgData,
                                                                            }}
                                                                        ></div>
                                                                    ) : (
                                                                        <SVGBank
                                                                            className={`${core.getCorrectBankIcon(method.code, this, true)}`}
                                                                            name={`${core.getCorrectBankIcon(method.code, this, true)}`}
                                                                        />
                                                                    )}
                                                                </div>
                                                                <div className='bank-active'></div>
                                                            </div>

                                                            <div className='bank-name'>{method.name}</div>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        {thirdPartyMemberBank && thirdPartyMemberBank.length > 0 && (
                            <MobileSelect
                                itemList={thirdPartyMemberBank}
                                itemKey={'displayName'}
                                onChangeIndexType={true}
                                onChange={(e) => vm.onBankChanged(e)}
                                itemIndex={selectedBankIndex}
                                title={t('transaction:transaction.deposit.onlinetransfer.qrOption', 'Options')}
                                required={true}
                            />
                        )}

                        {showAmountField && (
                            <div className='group-item input-item'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.onlinetransfer.amount', 'Deposit Amount')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='amount-content'>
                                    <div className='input-box'>
                                        <input
                                            id='depositamount'
                                            type='numeric'
                                            autoComplete='off'
                                            placeholder={t('global:global.form.online-transfer-amount-placeholder', {
                                                min: window.SPL_Other.formatAmount(depositMinMaxLimit ? depositMinMaxLimit.min : 0),
                                                max: window.SPL_Other.formatAmount(depositMinMaxLimit ? depositMinMaxLimit.max : 0),
                                            })}
                                            min={'0'}
                                            value={this.state.fields['amount']}
                                            onChange={(e) => vm.handleAmountChange(e)}
                                            onBlur={() => {
                                                transactionHelper.handleValidation(this);
                                            }}
                                        />
                                        <div className='error-validation'>
                                            <Trans
                                                i18nKey={this.state.errors['amount']}
                                                values={{ amount: window.SPL_Other.formatAmount(this.state.amountErrorValue), currency: userCurrency }}
                                            ></Trans>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className='group-item submit-container'>
                            <div className='title'></div>
                            <div className='amount-content'>
                                <div className='flexbox'>
                                    <button
                                        className='btn btn-submit'
                                        onClick={() => {
                                            vm.submitForm();
                                        }}
                                        disabled={!isFormValid || disableDepositButton}
                                    >
                                        {t('transaction:transaction.deposit.deposithistory.submitbtn', 'SUBMIT').toUpperCase()}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <TransactionNote {...transactionNoteProps} />
                        {/* <div className='notice-box'>
                            <span className='item-title'>{t('transaction:transaction.important-notice', 'IMPORTANT NOTICE')}</span>
                            <div className='display-info'>
                                <span className='key'>{t('transaction:transaction.deposit.p2p.option', 'Option: P2P Transfer')}</span>
                                <span className='value'></span>
                            </div>
                            <div className='display-info'>
                                <span className='key'>{t('transaction:transaction.deposit.p2p.mode', 'Mode: Online')}</span>
                                <span className='value'></span>
                            </div>
                            <div className='display-info'>
                                <span className='key'>{t('transaction:transaction.deposit.cashdeposit.min/maxlimit', 'Min/Max Limit')}</span>
                                <span className='value'>
                                    {this.state.depositMinMaxLimit === null ? (
                                        <SmallLoading></SmallLoading>
                                    ) : (
                                        ` ${this.props.user.account.currency} ${window.SPL_Other.formatAmount(
                                            this.state.depositMinMaxLimit && this.state.depositMinMaxLimit.min
                                        )} 
                                                    / ${window.SPL_Other.formatAmount(this.state.depositMinMaxLimit && this.state.depositMinMaxLimit.max)}`
                                    )}
                                </span>
                            </div>

                            <div className='display-info'>
                                <span className='key'>{t('transaction:transaction.deposit.cashdeposit.dailylimit', 'Daily Limit:')}</span>
                                <span className='value'>&nbsp;{t('transaction:transaction.deposit.cashdeposit.unlimited', 'Unlimited')}</span>
                            </div>
                            <div className='display-info'>
                                <span className='key'>{t('transaction:transaction.deposit.cashdeposit.totalallowed', 'Total Allowed:')}</span>
                                <span className='value'>&nbsp; {t('transaction:transaction.deposit.cashdeposit.unlimited', 'Unlimited')}</span>
                            </div>

                            <div className='display-info'>
                                <p>
                                    {t(
                                        'transaction:transaction.deposit.cashdeposit.note1',
                                        'Note: Please use Bank Transfer via your local bank account. We do not accept all kinds of deposit by ""Cheque"" or ""Bank Draft"" (Company OR Personal Cheque) as your deposit method. '
                                    )}
                                </p>
                            </div>
                        </div> */}
                    </Fragment>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'settings'])(withRouter(QuickPay)));
