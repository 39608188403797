import React, { Fragment } from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { MdClose } from 'react-icons/md';

class PromoDetail extends React.Component {
    constructor(props) {
        super();
    }

    render() {
        const { promoInfo, promoContent, index, toggleModal } = this.props;

        return (
            <Translation>
                {(t) => (
                    <div className='promosDetail-popup m-promosDetail-popup'>
                        <div className='popup'>
                            <div className='popup-header' style={{ backgroundColor: promoInfo.line }}>
                                <div className='popup-header-left'>
                                    <span className='popup-title'>{t(promoInfo.title)}</span>
                                </div>

                                <div className='popup-header-right'>
                                    <MdClose style={{ fill: promoInfo.line }} onClick={() => toggleModal('')} />
                                </div>
                            </div>

                            <div className='popup-body'>
                                <img className='promoImg' src={promoInfo.image} />

                                <div className='box-content'>
                                    <span className='content-title'>{t(promoInfo.title)}</span>
                                    <span className='content-desc'>{t(promoInfo.description)}</span>
                                </div>

                                <div className='btn-box'>
                                    {!this.props.user.isLogin && (
                                        <span
                                            className='btn-register'
                                            style={{ backgroundColor: promoInfo.line }}
                                            onClick={() => {
                                                this.props.history.push('/register');
                                            }}
                                        >
                                            {t('global:global.menu.account.register', 'JOIN NOW')}
                                        </span>
                                    )}
                                    {promoInfo.isAllowApply && (
                                        <span
                                            className='btn-apply'
                                            style={{ backgroundColor: promoInfo.darkColor }}
                                            onClick={() => toggleModal('showPromoApply', promoInfo, index)}
                                        >
                                            {t('promotion:promotion.apply')}
                                        </span>
                                    )}
                                </div>

                                {promoInfo.isRemainingTime && (
                                    <div className='time-box'>
                                        <span>{t('promotion:promotion.remainingTime', 'Remaining time')} - </span>
                                        <span className={`time ${promoInfo.remainingUnlimited ? 'unlimited' : ''}`}>
                                            {promoInfo.remainingUnlimited ? (
                                                <Fragment>&#8734;&#xfe0e;</Fragment>
                                            ) : (
                                                <Fragment>
                                                    {promoInfo.remainingDays}
                                                    &nbsp;
                                                    {t('promotion:promotion.days', 'day(s)')} {promoInfo.remainingHours}
                                                    &nbsp;
                                                    {t('promotion:promotion.hours', 'hour(s)')}
                                                </Fragment>
                                            )}
                                        </span>
                                    </div>
                                )}

                                <div className='promo-content' dangerouslySetInnerHTML={{ __html: customizePromoContent(promoContent) }}></div>

                                {/* <div className='btn-box'>
                  {!this.props.user.isLogin &&
                    <span className='btn-register' style={{ backgroundColor: promoInfo.line }} onClick={() => { this.props.history.push('/register') }}>
                      {t('global:global.menu.account.register', 'JOIN NOW')}
                    </span>
                  }
                  {promoInfo.isAllowApply &&
                    <span className="btn-apply" style={{ backgroundColor: promoInfo.darkColor }} onClick={() => toggleModal('showPromoApply', promoInfo, index)}>
                      {t('promotion:promotion.apply')}
                    </span>
                  }
                </div> */}
                            </div>
                        </div>

                        <div className='popup-overlay' onClick={() => toggleModal('')}></div>
                    </div>
                )}
            </Translation>
        );
    }
}

/**
 * This function is using for handle responsive table
 */
function customizePromoContent(htmlTemplate = '') {
    if (!htmlTemplate) {
        return null;
    }
    return htmlTemplate.replaceAll('<table', '<div style="overflow: auto; padding: 0 1px; margin-left: -1px;"><table').replaceAll('</table>', '</table></div>');
}
export default connect(mapStateToProps)(withTranslation()(withRouter(PromoDetail)));
