import React, { FC } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import EsportsPanel from './EsportsPanel';

class Esports extends React.Component {
    render() {
        return <EsportsPanel></EsportsPanel>;
    }
}

export default connect(mapStateToProps)(withTranslation(['sport', 'casino'])(withRouter(Esports)));
