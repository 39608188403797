import React from 'react';
//import WebFooter from "../Footer";
import WebFooter from 'root/webapp/layout/footer/component/desktop/Footer';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import SVGContact from 'root/components/svgIcon/svgContact';

import core from 'root/utils/core';
import vm from '../../footer.controller';

class Footer extends React.Component {
    constructor() {
        super();
        this.state = {
            contactList: [],
        };
    }

    componentDidMount() {
        this.getContact();
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.countryLanguageKey !== this.props.countryLanguageKey) {
            vm.updateCurrentLanguage();
        }

        if (window.location.search) {
            let urlParams = new URLSearchParams(window.location.search);

            if (urlParams.get('id') === 'contact') {
                document.getElementById('contact-box').scrollIntoView();
            }
        }
    }

    getContact() {
        const { language, screen } = this.props;
        core.getContact(language, screen).then((data) => {
            this.setState({ contactList: data.contactList });
        });
    }

    contactOnClick(contact) {
        if (contact.name === 'LiveChat') {
            core.openLivechat(this.props.language.countryLanguageKey, this.props.screen.viewType);
        } else {
            let url = window.SPL_Content.getContactUrl(contact);

            if (url && url !== '') {
                window.open(url);
            }
        }
    }

    render() {
        return (
            <Translation>
                {(t) => (
                    <div className='mobile-footer'>
                        <div className='contact-box' id='contact-box'>
                            <span className='title'>{t('global:footer.m-footer-contactus', 'CONTACT US 24 HOURS SERVICE')}</span>
                            <ul>
                                {this.state.contactList.map((contact, i) => (
                                    <li
                                        key={i}
                                        onClick={() => {
                                            if (contact.customTranslate || contact.isLiveChat) {
                                                this.contactOnClick(contact);
                                            }
                                        }}
                                    >
                                        <span className='icon-box'>
                                            <SVGContact className='contactSvg' name={contact.name}></SVGContact>
                                            {/* <i className="icon-footer-contact-common" style={{ backgroundImage: `url("/public/html/images/contact/${contact.name}.png")` }}></i> */}
                                        </span>
                                        <span>{t(contact.translate, contact.name)}</span>
                                        {contact.isLiveChat ? (
                                            // livechat
                                            <span>{t(contact.desc).toUpperCase()}</span>
                                        ) : (
                                            // normal contact
                                            <span>{contact.customTranslate ? contact.customTranslate : contact.desc}</span>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <WebFooter />
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(vm.getMapStateToProps)(withTranslation(['global'])(Footer));
