import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import { MdClose } from 'react-icons/md';
import vm from '../maintenance.controller';
import core from 'root/utils/core';

class Maintenance extends React.Component {
    constructor() {
        super();
        vm.init(this);
        this.controls = {};
		this.state = {
			days: '00',
			hours: '00',
			minutes: '00',
			seconds: '00',
			getStartDate: '',
			getStartTime: '',
			getEndDate: '',
			getEndTime: '',
            untilFurtherNotice: false,
            getProvider: '',
		};
    }

    componentDidMount() {
        this.timerID = setInterval(() => this.getDisplayDateValue(), 1000);
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    getDisplayDateValue = () => {
		const { history } = this.props;
		if (history.location && history.location.search) {
            let urlParams = history.location.search;

            let queryString = new URLSearchParams(urlParams);
            let getProvider = queryString.get('provider');
            let queryStartTime = queryString.get('startTime');
            let queryEndTime = queryString.get('endTime');

            let startTime = new Date(queryStartTime);
            let endTime = new Date(queryEndTime);

            //split iso format to date and time
            let getStartDate = core.replaceAll(startTime.toLocaleDateString('en-ZA'), '/', '-');
            let getStartTime = startTime.toLocaleTimeString('en-GB');

            let getEndDate = core.replaceAll(endTime.toLocaleDateString('en-ZA'), '/', '-');
            let getEndTime = endTime.toLocaleTimeString('en-GB');

            let [ convertStartHours, convertStartMinutes ] = getStartTime.split(':');
            let [ convertEndHours, convertEndMinutes ] = getEndTime.split(':');

            let maintenanceTime = new Date(endTime) - new Date();
            if (!maintenanceTime) {
                maintenanceTime = 0;
            }
            //converted days, hours, min, sec
            let days = Math.floor(maintenanceTime / (1000 * 60 * 60 * 24));
            maintenanceTime = maintenanceTime - Math.floor(maintenanceTime / (1000 * 60 * 60 * 24)) * 1000 * 60 * 60 * 24;
            let hours = Math.floor(maintenanceTime / (1000 * 60 * 60));
            maintenanceTime = maintenanceTime - Math.floor(maintenanceTime / (1000 * 60 * 60)) * 1000 * 60 * 60;
            let minutes = Math.floor(maintenanceTime / (1000 * 60));
            maintenanceTime = maintenanceTime - Math.floor(maintenanceTime / (1000 * 60)) * 1000 * 60;
            let seconds = Math.floor(maintenanceTime / 1000);
    
            
            if (queryStartTime === queryEndTime) {
                this.setState({ 
                    getStartDate: getStartDate, 
                    getStartTime: convertStartHours+':'+convertStartMinutes,
                    untilFurtherNotice: true,
                    getProvider:getProvider,
                });
            } else {
                this.setState({ 
                    days: days.toString().padStart(2, "0"), 
                    hours: hours.toString().padStart(2, "0"), 
                    minutes: minutes.toString().padStart(2, "0"), 
                    seconds: seconds.toString().padStart(2, "0"), 
                    getStartDate: getStartDate, 
                    getStartTime: convertStartHours+':'+convertStartMinutes,
                    getEndDate: getEndDate, 
                    getEndTime: convertEndHours+':'+convertEndMinutes,
                    getProvider:getProvider,
                });
            }
		}
	};

    getMainPage = () => {
		this.props.history.push('/home');
	};

    render() {
        return (
            <Translation>
                {(t) => (
                    <div className='maintenance-panel'>
                        <div className='maintenance-box-time'>
                            <div className='maintenance-content'>
                                <div className='popup-header-right'>
                                    <i className='icon-close2' onClick={() => this.getMainPage()}></i>
                                </div>
                                <div className='maintenance-icon'>
                                    <img src='/public/html/images/icon/maintenance-icon.svg' />
                                </div>
                                <div className='maintenance-title'>
                                    {this.state.getProvider + ' ' + t('casino:casino.underMaintenance', 'Under Maintenance')}
                                </div>
                                <div className='maintenance-details'>
                                    <div className='maintenance-container'>
                                        <div className="maintenance-container-column1">
                                            <span className='key'>{t('global:global.messages.maintenance.startFrom', 'Start From')}</span>
                                        </div>
                                        <div className="maintenance-container-column2">
                                            <span>:</span>
                                            <span className='dateTime'>{this.state.getStartDate}</span> 
                                            <span className='slashColor'> / </span>
                                            <span className='dateTime'>{this.state.getStartTime}</span>
                                        </div>
                                    </div>
                                    {this.state.untilFurtherNotice ? 
                                        <div className='maintenance-container'>
                                            <div className="maintenance-container-column1">
                                                <span className='key'>{t('global:global.messages.maintenance.estimatedEndTime', 'Estimated End Time')}</span>
                                            </div>
                                            <div className="maintenance-container-column2">
                                                <span> : </span>
                                                <span className='dateTime'>{t('global:global.messages.maintenance.untilFurtherNotice', 'Until further notice')}</span>
                                            </div>
                                        </div>
                                    :
                                        <div className='maintenance-container'>
                                            <div className="maintenance-container-column1">
                                                <span className='key'>{t('global:global.messages.maintenance.estimatedEndTime', 'Estimated End Time')}</span>
                                            </div>
                                            <div className="maintenance-container-column2">
                                                <span>:</span>
                                                <span className='dateTime'>{this.state.getEndDate}</span> 
                                                <span className='slashColor'> / </span>
                                                <span className='dateTime'>{this.state.getEndTime}</span>
                                            </div>
                                        </div>
                                    }
                                        <div className='maintenance-timer-container'>
                                            <div className='timer-container'>
                                                <span className='timer'>{this.state.days}</span>
                                                <span className='timer'>{this.state.hours}</span>
                                                <span className='timer'>{this.state.minutes}</span>
                                                <span className='timer'>{this.state.seconds}</span>     
                                            </div>
                                            <div className='timer-container'>
                                                <span className='timer-status'>{t('global:global.messages.maintenance.day', 'Days')}</span>
                                                <span className='timer-status'>{t('global:global.messages.maintenance.hour', 'hours')}</span>
                                                <span className='timer-status'>{t('global:global.messages.maintenance.minute', 'minute')}</span>
                                                <span className='timer-status'>{t('global:global.messages.maintenance.second', 'second')}</span>  
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation('casino')(withRouter(Maintenance)));
