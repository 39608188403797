import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import './text.scss';
import { gu_filterCommonKey } from '../../../utils/general-util';

// variant supported format ['text-xs (extra small), text-s (small), text-m (medium), text-l (large), text-xl (extra-large)]
/**
 *
 * @param {Boolean} disableDefaultSpan -- default will include span tag
 * @returns
 */
const Text = ({ textKey, components, values, disableDefaultSpan, ...props }) => {
    const { variant, onClick, children } = props;
    const textType = variant || 'm';

    const _textProps = {
        i18nKey: gu_filterCommonKey(textKey),
        components: components,
        values: {
            merchantName: window.globMerchantName,
            merchant: window.globMerchantName,
            ...values,
        },
    };

    if (disableDefaultSpan) {
        return <Trans {..._textProps}>{children ? children : textKey}</Trans>;
    } else {
        return (
            <span onClick={onClick} className={`text-${textType}`}>
                <Trans {..._textProps}>{children ? children : textKey}</Trans>
            </span>
        );
    }
};

export default Text;

export const useHookTranslatedText = (key, fallback, values, ...props) => {
    const { t } = useTranslation();
    const { variant, onClick } = props;
    const textType = variant || 'm';

    const text = t(key, fallback, {
        merchantName: window.globMerchantName,
        merchant: window.globMerchantName,
        ...values,
    });
    return (
        <span onClick={onClick} className={`text-${textType}`}>
            {text}
        </span>
    );
};
