import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import Pagination from 'root/webapp/shared/pagination/pagination.selector';
import DatePicker from 'react-datepicker';
import FlipMove from 'react-flip-move';
import CustomDatePicker from 'root/components/customDatePicker';
class Referral extends React.Component {
    constructor(props) {
        super();
        this.props = props;
    }

    componentDidMount() {}

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        return (
            <Translation>
                {(t) => (
                    <Fragment>
                        <div className='m-bonus-container myBonus-container'>
                            <div className='filter-container'>
                                <div className='form-field font-bold'>
                                    <label className='mandatory'>{`${t('transaction:transaction.history.filter.start_date', 'Start Date')}:`}</label>
                                    <div className='input-box'>
                                        <label>
                                            <CustomDatePicker
                                                placeholderText={t('transaction:transaction.history.filter.start_date', 'Start Date')}
                                                selected={this.props.fields.startDate}
                                                onChange={(date) => this.props.handleDateChanged(date, true, this)}
                                                popperPlacement='bottom-end'
                                            />
                                            <i className='icon-date'></i>
                                        </label>
                                    </div>
                                </div>

                                <div className='form-field font-bold'>
                                    <label className='mandatory'>{t('transaction:transaction.history.filter.end_date', 'End Date')}</label>
                                    <div className='input-box'>
                                        <label>
                                            <CustomDatePicker
                                                placeholderText={t('transaction:transaction.history.filter.end_date', 'End Date')}
                                                selected={this.props.fields.endDate}
                                                onChange={(date) => this.props.handleDateChanged(date, false, this)}
                                                popperPlacement='bottom-end'
                                            />
                                            <i className='icon-date'></i>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <button className='btn btn-submit' disabled={this.props.isLoading} onClick={() => this.props.searchHistory(0)}>
                                {t('transaction:transaction.deposit.cashdeposit.submitbtn', 'Submit').toUpperCase()}
                            </button>

                            <div className='myBonus-info-container'>
                                <div className='table-header table-header-6'>
                                    <ul>
                                        <li>
                                            <span>{t('settings:settings.memberLogin', 'Member Login')}</span>
                                        </li>
                                        <li>
                                            <span>{t('settings:settings.totalDeposit', 'Total Deposit')}</span>
                                        </li>
                                        <li>
                                            <span>{t('settings:settings.bonusAmount', 'Bonus Amount')}</span>
                                        </li>
                                        <li>
                                            <span>{t('settings:settings.status', 'Status')}</span>
                                        </li>
                                    </ul>
                                </div>
                                {this.props.histories.length ? (
                                    <FlipMove className='table-list table-list-6' enterAnimation='fade' leaveAnimation='fade'>
                                        {this.props.histories.map((history, i) => (
                                            <ul key={i}>
                                                <li>{history.refereeLogin}</li>
                                                <li>
                                                    {window.SPL_Other.formatAmount(history.currentDepositAmount)} /{' '}
                                                    {window.SPL_Other.formatAmount(history.targetDepositAmount)}
                                                </li>
                                                <li>{window.SPL_Other.formatAmount(history.referralBonusAmount)}</li>
                                                <li>{t(history.status)}</li>
                                            </ul>
                                        ))}
                                    </FlipMove>
                                ) : (
                                    <div className='table-list'>
                                        <span>{t('transaction:transaction.emptyHistory')}</span>
                                    </div>
                                )}

                                <Pagination
                                    pagingList={this.props.pagingList}
                                    handlePrevNextClick={this.props.handlePrevNextClick}
                                    handlePageClick={this.props.handlePageClick}
                                ></Pagination>
                            </div>
                        </div>
                    </Fragment>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'referral', 'settings'])(withRouter(Referral)));
