import React, { Suspense } from 'react';
import { Trans, Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import DeleteBankDialog from '../../../delete-bank-dialog/delete-bank-dialog.selector';
import vm from '../../bank-detail.controller';
import MobileSelect from 'root/webapp/shared/component/mobileSelect/mobileSelect';

import { trans_handleUploadReceiptButtonOnClick } from 'root/utils/transaction-util';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BankIcon, { CryptoBankIcon } from '../../../../../../components/molecules/bank-icon/bank-icon';
import { MODULE_TYPE as MT } from '@constants';
import { useAuthSettingsSetup } from '@components/molecules/auth-settings/auth-settings';
import AddCryptoAddressPopup from '@components/organisms/add-crypto-address-popup/add-crypto-address-popup';
import AuthSettings from '@components/molecules/auth-settings/auth-settings';
import { SetupSixDigitPinNotice } from '@components/organisms/set-up-pin-popup/set-up-pin-popup';

class MBankDetail extends React.Component {
    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
        this.myRef = React.createRef();
    }

    componentDidMount() {
        vm.initCompSettings().then(() => {
            vm.loadEwallet().then(() => {
                vm.loadBankAccounts();
            });
        });
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    onAddCryptoAddressBtnClick = () => {
        const { authSettingsReducer } = this.props;
        const _msCryptoAddressModule = authSettingsReducer?.processedData?.[MT.CRYPTO_ADDRESS];

        if (_msCryptoAddressModule?.gaEnabled) {
            vm.getAddressModal(true);
        } else {
            vm.handleMobileAddCoin(true, this);
        }
    };

    render() {
        const {
            memberBankList,
            isAddBank,
            deleteBankDialogIsShow,
            fields,
            selectedTab,
            errors,
            depositAllSettings,
            bankNameErrMsg,
            showBankName,
            selectedBankObj,
            unOwnedBankList,
            method,
            isAddCoin,
            cryptoAddress,
            addressOption,
            networkOption,
            selectedCoinIndex,
            selectedNetworkIndex,
            address,
            disableOtpBtn,
            submittedOTP,
            countdownTimer,
            otpCode,
            cryptoError,
            cryptoAddressDisable,
            isAddEwallet,
            ewalletBank,
            unOwnedEwalletBank,
            deleteEwalletDialogIsShow,
            compSettings,
            receiptImg,
            receiptImgFile,
        } = this.state;

        const { authSettingsReducer } = this.props;
        const _authSettingsBankDetailModule = authSettingsReducer?.processedData?.[MT.BANK_DETAIL_BANK];
        const _authSettingsBankDetailEWalletModule = authSettingsReducer?.processedData?.[MT.BANK_DETAIL_EWALLET];

        const chkBankDetailSixPinStatus =
            (_authSettingsBankDetailModule?.sixPinEnabled && _authSettingsBankDetailModule?.memberSixPinInfo?.sixPinLocked) ||
            (_authSettingsBankDetailModule?.sixPinEnabled && !_authSettingsBankDetailModule?.memberSixPinInfo?.sixPinRegistered) ||
            false;

        const chkBankDetailEWalletSixPinStatus =
            (_authSettingsBankDetailEWalletModule?.sixPinEnabled && _authSettingsBankDetailEWalletModule?.memberSixPinInfo?.sixPinLocked) ||
            (_authSettingsBankDetailEWalletModule?.sixPinEnabled && !_authSettingsBankDetailEWalletModule?.memberSixPinInfo?.sixPinRegistered) ||
            false;

        const { reqBankStatementByCurrencies } = compSettings;

        if (!depositAllSettings) return null;

        let deleteBankDialogProps = {
            parentvm: this,
            closeDeleteBankDialog: vm.closeDeleteBankDialog,
            deleteBankDialogIsShow: deleteBankDialogIsShow,
            selectedBankObj: selectedBankObj,
            method: method,
        };

        let deleteEwalletDialogProps = {
            parentvm: this,
            closeDeleteBankDialog: vm.closeDeleteBankDialog,
            deleteBankDialogIsShow: this.state.deleteEwalletDialogIsShow,
            selectedBankObj: this.state.selectedBankObj,
            method: this.state.method,
            customDeleteDialogHeader: 'bank:bank.deleteEwalletConfirm',
        };

        let userCurrency = this.props.user && this.props.user.account && this.props.user.account.currency ? this.props.user.account.currency : null;

        if (userCurrency === 'KHUSD') userCurrency = 'USD';
        const showBankBranch = depositAllSettings?.showBankBranch
        return (
            <Translation>
                {(t) => (
                    <div data-page='/myaccount/' ref={this.myRef}>
                        <AuthSettings module={MT?.CRYPTO_ADDRESS} isPublic={false} />
                        <AuthSettings module={MT?.BANK_DETAIL_BANK} isPublic={false} />
                        <AuthSettings module={MT?.BANK_DETAIL_EWALLET} isPublic={false} />

                        <div className='bank-page'>
                            <div className='group-item bank-option m-standard-2-tab-container'>
                                <span className={`standard-tab ${this.state.method === 'bank' ? 'on' : ''}`} onClick={() => vm.onMethodChange('bank', 'B')}>
                                    {t('settings:settings.profiletab.bank', 'Bank Details')}
                                </span>
                                <span className={`standard-tab ${this.state.method === 'crypto' ? 'on' : ''}`} onClick={() => vm.onMethodChange('crypto', 'CT')}>
                                    {t('transaction:transaction.deposit.crypto.cryptoAddress', 'Crypto Address')}
                                </span>
                                {this.state.ewalletEnabled && (
                                    <span className={`standard-tab ${this.state.method === 'ewallet' ? 'on' : ''}`} onClick={() => vm.onMethodChange('ewallet')}>
                                        {t('transaction:transaction.deposit.cashdeposit.ewallet')}
                                    </span>
                                )}
                            </div>

                            {deleteBankDialogIsShow && <DeleteBankDialog {...deleteBankDialogProps}></DeleteBankDialog>}
                            {deleteEwalletDialogIsShow && <DeleteBankDialog {...deleteEwalletDialogProps}></DeleteBankDialog>}
                            {this.state.addressModal && (
                                <AddCryptoAddressPopup
                                    isOpen={this.state.addressModal}
                                    onRequestClose={() => vm.getAddressModal(false)}
                                    onCryptoAddressPopupSubmitSuccessCb={() => vm.getCryptoMemberFullAddress()}
                                />
                            )}

                            {this.state.method === 'bank' && (
                                <>
                                    {/* Add Bank */}
                                    {chkBankDetailSixPinStatus && (
                                        <section className='set-up-pin-notice-section'>
                                            <Suspense fallback={<div></div>}>
                                                <SetupSixDigitPinNotice module={MT.BANK_DETAIL_BANK} />
                                            </Suspense>
                                        </section>
                                    )}

                                    {!chkBankDetailSixPinStatus && isAddBank && (
                                        <div className={['bank-add', !isAddBank ? 'member-bank' : ''].join(' ')}>
                                            {
                                                <MobileSelect
                                                    itemList={unOwnedBankList}
                                                    itemKey={'bankName'}
                                                    onChange={(e) => vm.handleBankChange(e)}
                                                    itemIndex={fields.selectedBankIndex}
                                                    title={t('bank:bank.bank', 'Bank')}
                                                    required={true && isAddBank}
                                                />
                                            }

                                            {showBankName && (
                                                <div className='group-item input-item'>
                                                    <div className='title'>
                                                        <span> {t('bank:bank.newBank.bankName', 'Bank Name')}</span>
                                                        {<span className='required'>*</span>}
                                                    </div>
                                                    <div className='group'>
                                                        <div className='input-box'>
                                                            {
                                                                <input
                                                                    type='text'
                                                                    value={fields['bankName']}
                                                                    onChange={(e) => vm.handleFieldChange(e, 'bankName')}
                                                                    id='bankName'
                                                                    placeholder={t('bank:bank.newBank.bankName', 'Bank Name')}
                                                                ></input>
                                                            }
                                                        </div>
                                                        {bankNameErrMsg.map((errMsg, index) => {
                                                            return (
                                                                <div key={index} className='error-validation'>
                                                                    <Trans i18nKey={errMsg}></Trans>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                            {showBankBranch && (
                                                <div className='group-item input-item'>
                                                    <div className='title'>
                                                        <span>{t('bank:bank.branch', 'Bank Branch')}</span>
                                                    </div>
                                                    <div className='group'>
                                                        <div className='input-box'>
                                                            {
                                                                <input
                                                                    type='text'
                                                                    value={fields['bankBranch']}
                                                                    onChange={(e) => vm.handleFieldChange(e, 'bankBranch')}
                                                                    id='bankBranch'
                                                                    placeholder={t('bank:bank.branch', 'Bank Branch')}
                                                                ></input>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            <div className='group-item input-item'>
                                                <div className='title'>
                                                    <span>{t('bank:bank.newBank.account-name', 'Account Name')}</span>
                                                </div>
                                                <div className='group'>
                                                    <div className='input-box'>
                                                        <input
                                                            type='text'
                                                            value={fields['accountName']}
                                                            disabled
                                                            id='accountNumber'
                                                            placeholder={t('bank:bank.newBank.account-name', 'Account Name')}
                                                        ></input>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='group-item input-item'>
                                                <div className='title'>
                                                    <span>{t('bank:bank.newBank.account-number', 'Account Number')}</span>
                                                    {<span className='required'>*</span>}
                                                </div>
                                                <div className='group'>
                                                    <div className='input-box'>
                                                        {
                                                            <input
                                                                type='text'
                                                                value={fields['accountNumber']}
                                                                onChange={(e) => vm.handleFieldChange(e, 'accountNumber')}
                                                                id='accountNumber'
                                                                placeholder={t('bank:bank.newBank.account-number', 'Account Number')}
                                                            ></input>
                                                        }
                                                    </div>
                                                    <div className='error-validation'>{t(errors['accountNumber'])}</div>
                                                </div>
                                            </div>

                                            {reqBankStatementByCurrencies && (
                                                <>
                                                    <div>
                                                        {' '}
                                                        {receiptImg.length > 0 && (
                                                            <div className='group-item input-item'>
                                                                <div className='title'></div>
                                                                <div className='group'>
                                                                    <div className='receipt-image-box'>
                                                                        {receiptImgFile && receiptImgFile.type === 'application/pdf' ? (
                                                                            <>
                                                                                <label className='pdf-label'>
                                                                                    {t('transaction:transaction.deposit.pdf-file-name', 'PDF File Name: ')}
                                                                                </label>
                                                                                <span className='pdf-name'>{receiptImgFile.name}</span>
                                                                                <img
                                                                                    src='/public/images/icon/close-red.png'
                                                                                    className='pdf-clear'
                                                                                    onClick={() => vm.handleClearReceipt()}
                                                                                    alt=''
                                                                                />
                                                                            </>
                                                                        ) : (
                                                                            <LazyLoadImage src={receiptImg} alt='' onClick={() => vm.handleClearReceipt()} />
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className='group-item input-item'>
                                                        <div className='title'>
                                                            <span>{t('transaction:transaction.withdrawal.bankStatement', 'Bank Statement')}</span>
                                                            <span className='required'>*</span>
                                                        </div>
                                                        <div className='group'>
                                                            <div className='input-box'>
                                                                {
                                                                    <button
                                                                        className='btn btn-reset standard-button'
                                                                        onClick={() => {
                                                                            trans_handleUploadReceiptButtonOnClick();
                                                                        }}
                                                                    >
                                                                        {t('transaction:transaction.withdrawal.upload', 'UPLOAD').toUpperCase()}
                                                                        <input
                                                                            id='hiddenBankStatementInput'
                                                                            style={{ display: 'none' }}
                                                                            type='file'
                                                                            accept='image/*,.pdf'
                                                                            onChange={(e) => vm.handleReceiptImageChange(e, this)}
                                                                        ></input>
                                                                    </button>
                                                                }
                                                            </div>
                                                            <div className='error-validation'>
                                                                {t(
                                                                    'transaction:transaction.withdrawal.bankStatementRequirement',
                                                                    'Bank statement must show with full name and bank account number'
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                            <div className='group-item submit-container'>
                                                <div className='title'></div>
                                                <div className='amount-content'>
                                                    <div className='flexbox m-standard-2-button-container'>
                                                        <button
                                                            className='btn btn-secondary standard-button'
                                                            onClick={() => {
                                                                vm.handleMobileAddBank(false, this);
                                                            }}
                                                        >
                                                            {t('global:global.verification.close', 'Close').toUpperCase()}
                                                        </button>
                                                        <button
                                                            className='btn-submit standard-button'
                                                            disabled={!vm.isValidForm() || (reqBankStatementByCurrencies && receiptImg.length <= 0)}
                                                            onClick={() => {
                                                                vm.handleSubmit(this);
                                                            }}
                                                        >
                                                            {t('global:entity.action.add').toUpperCase()}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/* member bank details */}
                                    {!chkBankDetailSixPinStatus && !isAddBank && (
                                        <>
                                            {memberBankList.length > 0 && (
                                                <div className='tab-list'>
                                                    {memberBankList.map((bank, i) => (
                                                        <div key={i} className={`tab-header ${selectedTab === i ? 'active' : ''}`} onClick={() => vm.toogleTab(i)}>
                                                            {t('bank:bank.bank', 'Bank')} {i + 1}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}

                                            <div className={['bank-add', !isAddBank ? 'member-bank' : ''].join(' ')}>
                                                {memberBankList.length > 0 && (
                                                    <>
                                                        <div className='group-item input-item'>
                                                            <div className='title'>
                                                                <span> {t('bank:bank.bank', 'Bank')}</span>
                                                            </div>
                                                            <div className='group'>
                                                                <div className='input-box'>
                                                                    <div className='icon-box'>
                                                                        <div className={['icon-bank', 'icon-bank-big'].join(' ')}>
                                                                            <BankIcon bankCode={memberBankList?.[selectedTab]?.bankCode} />
                                                                        </div>
                                                                    </div>
                                                                    <input
                                                                        className='space-left'
                                                                        type='text'
                                                                        value={memberBankList[selectedTab]['bankNameOnly'] || ''}
                                                                        readOnly
                                                                        id='bankName'
                                                                        placeholder={t('bank:bank.newBank.bankName', 'Bank Name')}
                                                                    ></input>
                                                                </div>
                                                                {bankNameErrMsg.map((errMsg, index) => {
                                                                    return (
                                                                        <div key={index} className='error-validation'>
                                                                            <Trans i18nKey={errMsg}></Trans>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>

                                                        {showBankName && (
                                                            <div className='group-item input-item'>
                                                                <div className='title'>
                                                                    <span> {t('bank:bank.newBank.bankName', 'Bank Name')}</span>
                                                                </div>
                                                                <div className='group'>
                                                                    <div className='input-box'>
                                                                        <input
                                                                            type='text'
                                                                            value={memberBankList[selectedTab]['bankName']}
                                                                            readOnly
                                                                            id='bankName'
                                                                            placeholder={t('bank:bank.newBank.bankName', 'Bank Name')}
                                                                        ></input>
                                                                    </div>
                                                                    {bankNameErrMsg.map((errMsg, index) => {
                                                                        return (
                                                                            <div key={index} className='error-validation'>
                                                                                <Trans i18nKey={errMsg}></Trans>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        )}
                                                        {showBankBranch && (
                                                            <div className='group-item input-item'>
                                                                <div className='title'>
                                                                    <span>{t('bank:bank.branch', 'Bank Branch')}</span>
                                                                </div>
                                                                <div className='group'>
                                                                    <div className='input-box'>
                                                                        <input
                                                                            type='text'
                                                                            value={memberBankList[selectedTab]['bankBranch']}
                                                                            readOnly
                                                                            id='bankBranch'
                                                                            placeholder={t('bank:bank.branch', 'Bank Branch')}
                                                                        ></input>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className='group-item input-item'>
                                                            <div className='title'>
                                                                <span>{t('bank:bank.newBank.account-name', 'Account Name')}</span>
                                                            </div>
                                                            <div className='group'>
                                                                <div className='input-box'>
                                                                    <input
                                                                        type='text'
                                                                        value={fields['accountName']}
                                                                        readOnly
                                                                        id='accountNumber'
                                                                        placeholder={t('bank:bank.newBank.account-name', 'Account Name')}
                                                                    ></input>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='group-item input-item'>
                                                            <div className='title'>
                                                                <span>{t('bank:bank.newBank.account-number', 'Account Number')}</span>
                                                            </div>
                                                            <div className='group'>
                                                                <div className='input-box'>
                                                                    <input
                                                                        type='text'
                                                                        value={memberBankList[selectedTab]['accountNumber']}
                                                                        readOnly
                                                                        id='accountNumber'
                                                                        placeholder={t('bank:bank.newBank.account-number', 'Account Number')}
                                                                    ></input>
                                                                </div>
                                                                <div className='error-validation'>{t(errors['accountNumber'])}</div>
                                                            </div>
                                                        </div>
                                                        {reqBankStatementByCurrencies && (
                                                            <div className='group-item verification-container'>
                                                                <div className='verification-title'>
                                                                    <b>{t(t('bank:bank.account.status', 'Status'))}: </b>
                                                                </div>
                                                                <div className={[memberBankList[selectedTab]['verified'] ? 'verified' : 'verification-status']}>
                                                                    {t(`bank:bank.account.verification.${memberBankList[selectedTab]['verifiedDisplayStatus']}`)}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                                {
                                                    <div className='group-item submit-container'>
                                                        <div className='title'></div>
                                                        <div className='amount-content'>
                                                            <div className='flexbox m-standard-2-button-container'>
                                                                {/* show delete bank when member have added bank */}
                                                                {memberBankList.length > 0 && (
                                                                    <button
                                                                        className='btn btn-secondary standard-button'
                                                                        onClick={() => {
                                                                            vm.openDeleteBankDialog(memberBankList[selectedTab], 'bank', this);
                                                                        }}
                                                                    >
                                                                        {t('global:entity.action.delete', 'Delete').toUpperCase()}
                                                                    </button>
                                                                )}
                                                                {depositAllSettings && !depositAllSettings.hideAddBankAccount && (
                                                                    <button
                                                                        className='btn btn-submit standard-button'
                                                                        onClick={() => {
                                                                            vm.handleMobileAddBank(true, this);
                                                                        }}
                                                                    >
                                                                        {t('bank:bank.newBank.addBank', 'AddBank').toUpperCase()}
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </>
                                    )}
                                </>
                            )}

                            {this.state.method === 'crypto' && (
                                <>
                                    {isAddCoin && (
                                        <div className={['bank-add', !isAddCoin ? 'member-bank' : ''].join(' ')}>
                                            <MobileSelect
                                                itemList={addressOption}
                                                itemKey={'displayName'}
                                                itemIndex={selectedCoinIndex}
                                                onChange={(e) => vm.handleCryptoCurrencyChange(e)}
                                                title={t('transaction:transaction.deposit.crypto.cryptoCurrency', 'Crypto Currency')}
                                                required={true && isAddCoin}
                                            />

                                            <MobileSelect
                                                itemList={networkOption}
                                                itemKey={'displayName'}
                                                itemIndex={selectedNetworkIndex}
                                                onChange={(e) => vm.handleCryptoNetworkChange(e)}
                                                title={t('transaction:transaction.deposit.crypto.network', 'Network')}
                                                required={true && isAddCoin}
                                            />

                                            <div className='group-item input-item'>
                                                <div className='title'>
                                                    <span>{t('transaction:transaction.deposit.crypto.address', 'Address')}</span>
                                                    {<span className='required'>*</span>}
                                                </div>
                                                <div className='group'>
                                                    <div className='input-box'>
                                                        <input
                                                            type='text'
                                                            value={address}
                                                            onChange={(e) => vm.handleCryptoAddressChange(e)}
                                                            id='accountNumber'
                                                            placeholder={t('transaction:transaction.deposit.crypto.address', 'Address')}
                                                        ></input>
                                                    </div>
                                                </div>
                                            </div>

                                            {this.props.portal.settings.addCryptoAddressRequireOTP && (
                                                <div className='group-item input-item'>
                                                    <div className='title'>
                                                        <span>OTP</span>
                                                        {<span className='required'>*</span>}
                                                    </div>
                                                    <div className='group group-otp'>
                                                        <div className='input-box'>
                                                            <input
                                                                name='otp'
                                                                type='number'
                                                                value={otpCode}
                                                                onChange={(e) => vm.handleOtpChange(e)}
                                                                // onBlur={vm.validateOtp}
                                                                id='accountNumber'
                                                                placeholder={t('global:global.verification.enterOTPCode')}
                                                                autoComplete='off'
                                                                onKeyDown={(evt) => ['e', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                                                                debounceTimeout={1000}
                                                            ></input>
                                                        </div>

                                                        <button disabled={disableOtpBtn}>
                                                            {submittedOTP === true ? (
                                                                countdownTimer > 0 ? (
                                                                    <span>{countdownTimer}s</span>
                                                                ) : (
                                                                    <span onClick={() => vm.sendOtpCode()}>{t('global:global.verification.resend').toUpperCase()}</span>
                                                                )
                                                            ) : (
                                                                <span onClick={() => vm.sendOtpCode()}>{t('register:register.form.getCode').toUpperCase()}</span>
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            )}

                                            {cryptoError && (
                                                <div className='group-item input-item'>
                                                    <div className='title'></div>
                                                    <div className='group'>
                                                        <div className='error-validation'>{t(this.state.cryptoError)}</div>
                                                    </div>
                                                </div>
                                            )}

                                            <div className='group-item submit-container'>
                                                <div className='title'></div>
                                                <div className='amount-content'>
                                                    <div className='flexbox m-standard-2-button-container'>
                                                        <button
                                                            className='btn btn-secondary standard-button'
                                                            onClick={() => {
                                                                vm.handleMobileAddCoin(false, this);
                                                            }}
                                                        >
                                                            {t('global:global.verification.close', 'Close').toUpperCase()}
                                                        </button>
                                                        <button
                                                            className='btn-submit standard-button'
                                                            disabled={cryptoAddressDisable}
                                                            onClick={() => {
                                                                vm.handleCryptoAddressSubmit(this);
                                                            }}
                                                        >
                                                            {t('transaction:transaction.deposit.crypto.addCoin', 'Add Coin').toUpperCase()}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {!isAddCoin && (
                                        <>
                                            {cryptoAddress.length > 0 && (
                                                <div className='tab-list'>
                                                    {cryptoAddress.map((result, i) => (
                                                        <div key={i} className={`tab-header ${selectedTab === i ? 'active' : ''}`} onClick={() => vm.toogleTab(i)}>
                                                            {t('transaction:transaction.deposit.crypto.cryptoCoin', 'Coin')} {i + 1}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}

                                            <div className={['bank-add', !isAddCoin ? 'member-bank' : ''].join(' ')}>
                                                {cryptoAddress.length > 0 && (
                                                    <>
                                                        <div className='group-item input-item'>
                                                            <div className='title'>
                                                                <span> {t('transaction:transaction.deposit.crypto.cryptoCoin', 'Coin')}</span>
                                                            </div>
                                                            <div className='group'>
                                                                <div className='input-box'>
                                                                    <div className='icon-box'>
                                                                        <div className={['icon-bank', 'icon-bank-big'].join(' ')}>
                                                                            <CryptoBankIcon
                                                                                bankCode={`${cryptoAddress?.[selectedTab]?.coin}_${cryptoAddress?.[selectedTab]?.network}`}
                                                                                coin={cryptoAddress?.[selectedTab]?.coin}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <input
                                                                        className='space-left'
                                                                        type='text'
                                                                        value={cryptoAddress[selectedTab]['coin'] || ''}
                                                                        readOnly
                                                                        id='bankName'
                                                                        placeholder={t('transaction:transaction.deposit.crypto.cryptoCoin', 'Coin')}
                                                                    ></input>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='group-item input-item'>
                                                            <div className='title'>
                                                                <span>{t('transaction:transaction.deposit.crypto.network', 'Network')}</span>
                                                            </div>
                                                            <div className='group'>
                                                                <div className='input-box'>
                                                                    <input
                                                                        type='text'
                                                                        value={cryptoAddress[selectedTab]['network'] || ''}
                                                                        readOnly
                                                                        id='accountNumber'
                                                                        placeholder={t('transaction:transaction.deposit.crypto.network', 'Network')}
                                                                    ></input>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='group-item input-item'>
                                                            <div className='title'>
                                                                <span>{t('transaction:transaction.deposit.crypto.address', 'Address')}</span>
                                                            </div>
                                                            <div className='group'>
                                                                <div className='input-box'>
                                                                    <input
                                                                        type='text'
                                                                        value={cryptoAddress[selectedTab]['address'] || ''}
                                                                        readOnly
                                                                        id='accountNumber'
                                                                        placeholder={t('transaction:transaction.deposit.crypto.address', 'Address')}
                                                                    ></input>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}

                                                <div className='group-item submit-container'>
                                                    <div className='title'></div>
                                                    <div className='amount-content'>
                                                        <div className='flexbox m-standard-2-button-container'>
                                                            {/* show delete bank when member have added bank */}
                                                            {cryptoAddress.length > 0 && (
                                                                <button
                                                                    className='btn btn-secondary standard-button'
                                                                    onClick={() => {
                                                                        vm.openDeleteBankDialog(cryptoAddress[selectedTab], 'crypto', this);
                                                                    }}
                                                                >
                                                                    {t('global:entity.action.delete', 'Delete').toUpperCase()}
                                                                </button>
                                                            )}

                                                            {depositAllSettings && !depositAllSettings.hideAddBankAccount && (
                                                                <AddCryptoAddressButton
                                                                    onClick={this.onAddCryptoAddressBtnClick.bind(this)}
                                                                    text={t('transaction:transaction.deposit.crypto.addCoin', 'Add Coin').toUpperCase()}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}

                            {this.state.method === 'ewallet' && (
                                <>
                                    {chkBankDetailEWalletSixPinStatus && (
                                        <Suspense fallback={<div></div>}>
                                            <SetupSixDigitPinNotice module={MT.BANK_DETAIL_EWALLET} />
                                        </Suspense>
                                    )}

                                    {/* Add Bank */}
                                    {!chkBankDetailEWalletSixPinStatus && isAddEwallet && (
                                        <div className={['bank-add', !isAddEwallet ? 'member-bank' : ''].join(' ')}>
                                            {
                                                <MobileSelect
                                                    itemList={unOwnedEwalletBank}
                                                    itemKey={'bankName'}
                                                    onChange={(e) => vm.handleBankChange(e)}
                                                    itemIndex={fields.selectedBankIndex}
                                                    title={t('bank:bank.newBank.eWallet', 'Ewallet')}
                                                    required={true && isAddEwallet}
                                                />
                                            }

                                            {showBankBranch && (
                                                <div className='group-item input-item'>
                                                    <div className='title'>
                                                        <span>{t('bank:bank.branch', 'Bank Branch')}</span>
                                                    </div>
                                                    <div className='group'>
                                                        <div className='input-box'>
                                                            {
                                                                <input
                                                                    type='text'
                                                                    value={fields['bankBranch']}
                                                                    onChange={(e) => vm.handleFieldChange(e, 'bankBranch')}
                                                                    id='bankBranch'
                                                                    placeholder={t('bank:bank.branch', 'Bank Branch')}
                                                                ></input>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            <div className='group-item input-item'>
                                                <div className='title'>
                                                    <span>{t('bank:bank.newBank.walletName', 'Wallet Name')}</span>
                                                </div>
                                                <div className='group'>
                                                    <div className='input-box'>
                                                        <input
                                                            type='text'
                                                            value={fields['accountName']}
                                                            disabled
                                                            id='accountNumber'
                                                            placeholder={t('bank:bank.newBank.walletName', 'Wallet Name')}
                                                        ></input>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='group-item input-item'>
                                                <div className='title'>
                                                    <span>{t('bank:bank.newBank.walletNumber', 'Wallet No')}</span>
                                                    {<span className='required'>*</span>}
                                                </div>
                                                <div className='group'>
                                                    <div className='input-box'>
                                                        {
                                                            <input
                                                                type='text'
                                                                value={fields['accountNumber']}
                                                                onChange={(e) => vm.handleFieldChange(e, 'accountNumber')}
                                                                id='accountNumber'
                                                                placeholder={t('bank:bank.newBank.walletNumber', 'Wallet No')}
                                                            ></input>
                                                        }
                                                    </div>
                                                    <div className='error-validation'>{t(errors['accountNumber'])}</div>
                                                </div>
                                            </div>

                                            <div className='group-item submit-container'>
                                                <div className='title'></div>
                                                <div className='amount-content'>
                                                    <div className='flexbox m-standard-2-button-container'>
                                                        <button
                                                            className='btn btn-secondary standard-button'
                                                            onClick={() => {
                                                                vm.handleMobileAddEwallet(false, this);
                                                            }}
                                                        >
                                                            {t('global:global.verification.close', 'Close').toUpperCase()}
                                                        </button>
                                                        <button
                                                            className='btn-submit standard-button'
                                                            disabled={!vm.isValidForm()}
                                                            onClick={() => {
                                                                vm.handleEwalletSubmit(this);
                                                            }}
                                                        >
                                                            {t('global:entity.action.add').toUpperCase()}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/* ewallet bank details */}
                                    {!chkBankDetailEWalletSixPinStatus && !isAddEwallet && (
                                        <>
                                            {ewalletBank.length > 0 && (
                                                <div className='tab-list'>
                                                    {ewalletBank.map((bank, i) => (
                                                        <div key={i} className={`tab-header ${selectedTab === i ? 'active' : ''}`} onClick={() => vm.toogleTab(i)}>
                                                            {t('bank:bank.newBank.eWallet', 'Bank')} {i + 1}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}

                                            <div className={['bank-add', !isAddEwallet ? 'member-bank' : ''].join(' ')}>
                                                {ewalletBank?.[selectedTab] && (
                                                    <>
                                                        <div className='group-item input-item'>
                                                            <div className='title'>
                                                                <span> {t('bank:bank.newBank.eWallet', 'Bank')}</span>
                                                            </div>
                                                            <div className='group'>
                                                                <div className='input-box'>
                                                                    <div className='icon-box'>
                                                                        <div className={['icon-bank', 'icon-bank-big'].join(' ')}>
                                                                            <BankIcon bankCode={ewalletBank?.[selectedTab]?.bankCode || ewalletBank?.[selectedTab]?.code} />
                                                                        </div>
                                                                    </div>
                                                                    <input
                                                                        className='space-left'
                                                                        type='text'
                                                                        value={ewalletBank[selectedTab]['bankNameOnly'] || ''}
                                                                        readOnly
                                                                        id='bankName'
                                                                        placeholder={t('bank:bank.newBank.bankName', 'Bank Name')}
                                                                    ></input>
                                                                </div>
                                                                {bankNameErrMsg.map((errMsg, index) => {
                                                                    return (
                                                                        <div key={index} className='error-validation'>
                                                                            <Trans i18nKey={errMsg}></Trans>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>

                                                        {showBankName && (
                                                            <div className='group-item input-item'>
                                                                <div className='title'>
                                                                    <span> {t('bank:bank.newBank.walletName', 'Bank Name')}</span>
                                                                </div>
                                                                <div className='group'>
                                                                    <div className='input-box'>
                                                                        <input
                                                                            type='text'
                                                                            value={ewalletBank[selectedTab]['bankName']}
                                                                            readOnly
                                                                            id='bankName'
                                                                            placeholder={t('bank:bank.newBank.walletName', 'Bank Name')}
                                                                        ></input>
                                                                    </div>
                                                                    {bankNameErrMsg.map((errMsg, index) => {
                                                                        return (
                                                                            <div key={index} className='error-validation'>
                                                                                <Trans i18nKey={errMsg}></Trans>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        )}
                                                        {showBankBranch && (
                                                            <div className='group-item input-item'>
                                                                <div className='title'>
                                                                    <span>{t('bank:bank.branch', 'Bank Branch')}</span>
                                                                </div>
                                                                <div className='group'>
                                                                    <div className='input-box'>
                                                                        <input
                                                                            type='text'
                                                                            value={ewalletBank[selectedTab]['bankBranch']}
                                                                            readOnly
                                                                            id='bankBranch'
                                                                            placeholder={t('bank:bank.branch', 'Bank Branch')}
                                                                        ></input>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className='group-item input-item'>
                                                            <div className='title'>
                                                                <span>{t('bank:bank.newBank.walletName', 'Account Name')}</span>
                                                            </div>
                                                            <div className='group'>
                                                                <div className='input-box'>
                                                                    <input
                                                                        type='text'
                                                                        value={fields['accountName']}
                                                                        readOnly
                                                                        id='accountNumber'
                                                                        placeholder={t('bank:bank.newBank.walletName', 'Account Name')}
                                                                    ></input>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='group-item input-item'>
                                                            <div className='title'>
                                                                <span>{t('bank:bank.newBank.walletNumber', 'Account Number')}</span>
                                                            </div>
                                                            <div className='group'>
                                                                <div className='input-box'>
                                                                    <input
                                                                        type='text'
                                                                        value={ewalletBank[selectedTab]['accountNumber']}
                                                                        readOnly
                                                                        id='accountNumber'
                                                                        placeholder={t('bank:bank.newBank.walletNumber', 'Account Number')}
                                                                    ></input>
                                                                </div>
                                                                <div className='error-validation'>{t(errors['accountNumber'])}</div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                {
                                                    <div className='group-item submit-container'>
                                                        <div className='title'></div>
                                                        <div className='amount-content'>
                                                            <div className='flexbox m-standard-2-button-container'>
                                                                {/* show delete bank when member have added bank */}
                                                                {ewalletBank.length > 0 && (
                                                                    <button
                                                                        className='btn btn-secondary standard-button'
                                                                        onClick={() => {
                                                                            vm.openDeleteBankDialog(ewalletBank[selectedTab], 'ewallet', this);
                                                                        }}
                                                                    >
                                                                        {t('global:entity.action.delete', 'Delete').toUpperCase()}
                                                                    </button>
                                                                )}
                                                                <button
                                                                    className='btn btn-submit standard-button'
                                                                    onClick={() => {
                                                                        vm.handleMobileAddEwallet(true, this);
                                                                    }}
                                                                >
                                                                    {t('bank:bank.newBank.addBank', 'AddBank').toUpperCase()}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'bank', 'global', 'register'])(withRouter(MBankDetail)));

export const AddCryptoAddressButton = ({ text, onClick }) => {
    const { handleSetup } = useAuthSettingsSetup({ module: MT.CRYPTO_ADDRESS, callback: onClick });

    const onAddBtnClick = () => {
        handleSetup();
    };

    return (
        <>
            <section className='btn-add-crypto-address-section'>
                <button className='btn btn-submit standard-button' onClick={onAddBtnClick}>
                    {text}
                </button>
            </section>
        </>
    );
};
