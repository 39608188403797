import { userAction } from 'root/redux/action/user.action';
import core from 'root/utils/core';
import memberService from 'root/utils/memberService';

const controller = {
    vm: null,

    init(vm) {
        this.vm = vm;
        this.vm.state = {
            messageList: [],
            allChecked: false,
            messageDetailDialogIsShow: false,
            messageFullDetailDialogIsShow: false,
            selectedMessageObj: null,
            deleteMessageDialogIsShow: false,
            selectedMessageIds: [],

            currentPage: 1,
            itemPerPage: 5,
            totalCount: 0,
            totalPage: 0,
            pagingList: [],
            currentMsgType: 'P',
            allFlag: false,
            displayTab: [
                {
                    id: 'promotions',
                    tabHeader: 'settings:settings.profiletab.promotion',
                    selected: false,
                    isNotification: false,
                    pathname: '/myaccount/messaging/',
                    unreadMsg: 'totalUnreadPromotionCount',
                    messageType: 'P',
                },
                {
                    id: 'payment',
                    tabHeader: 'settings:settings.profiletab.payment',
                    selected: false,
                    isNotification: false,
                    pathname: '/myaccount/messaging',
                    unreadMsg: 'totalUnreadPaymentCount',
                    messageType: 'PT',
                },
                {
                    id: 'system',
                    tabHeader: 'settings:settings.profiletab.system',
                    selected: false,
                    isNotification: true,
                    pathname: '/myaccount/messaging',
                    unreadMsg: 'totalUnreadSystemCount',
                    messageType: 'N',
                },
                {
                    id: 'notification',
                    tabHeader: 'settings:settings.profiletab.notification',
                    selected: false,
                    isNotification: true,
                    pathname: '/myaccount/messaging',
                    unreadMsg: 'totalUnreadNotificationCount',
                    messageType: 'I',
                },
            ],
        };
    },

    getMessages(pageNum, currentMsgType) {
        const { itemPerPage } = controller.vm.state;

        let isNotification = currentMsgType === 'I' ? true : false;
        core.portalIsLoading(controller.vm.props, true);

        window.SPL_Member.getMessages(isNotification, pageNum, itemPerPage, null, '', currentMsgType)
            .then((data) => {
                let totalCount = data.headers['x-total-count'];
                let totalPage = Math.ceil(totalCount / itemPerPage);
                let messageList = data.messages;
                let flagCount = 0;
                let allFlag = false;
                for (let i = 0; i < messageList.length; i++) {
                    messageList[i].isChecked = false;
                    if (messageList[i].status === 'F') {
                        messageList[i].isFlagChecked = true;
                    } else {
                        messageList[i].isFlagChecked = false;
                    }

                    if (controller.isFlag(messageList[i].status)) {
                        flagCount++;
                    }
                }

                if (flagCount > 0 && flagCount === messageList.length) {
                    allFlag = true;
                }
                controller.vm.setState(
                    {
                        messageList: messageList,
                        totalCount: totalCount,
                        totalPage: totalPage,
                        allFlag,
                    },
                    () => {
                        core.portalIsLoading(controller.vm.props, false);

                        controller.handlePagination();
                    }
                );
            })
            .catch((err) => {
                core.portalIsLoading(controller.vm.props, false);
            });
    },

    handleTabClick(currentMsgType) {
        controller.vm.setState({ currentMsgType }, () => {
            controller.getMessages(0, currentMsgType);
            controller.getMemberMessageCount();
        });
    },

    handlePagination() {
        const { currentPage, totalPage } = controller.vm.state;
        let pagingList = [];

        for (let i = 0; i < totalPage; i++) {
            let active = false;
            if (i + 1 === currentPage) {
                active = true;
            }

            let pageObj = {
                pageNum: i + 1,
                active: active,
            };
            pagingList.push(pageObj);
        }
        controller.vm.setState({ pagingList: pagingList });
    },

    handleSelectAll(forceFalse) {
        let messageList = controller.vm.state.messageList;
        let allChecked = controller.vm.state.allChecked;
        // forceFalse apply when pagination
        if (forceFalse) {
            allChecked = false;
        } else {
            allChecked = !allChecked;
        }
        for (let i = 0; i < messageList.length; i++) {
            messageList[i].isChecked = allChecked;
        }

        controller.vm.setState({
            messageList,
            allChecked: allChecked,
        });
    },

    handleRowCheckbox(rowIndex) {
        let messageList = controller.vm.state.messageList;
        let allChecked = controller.vm.state.allChecked;

        messageList[rowIndex].isChecked = !messageList[rowIndex].isChecked;
        allChecked = messageList.every((a) => a.isChecked);

        controller.vm.setState({
            messageList,
            allChecked: allChecked,
        });
    },

    handlePageClick(newPageNum, currentMsgType) {
        const { currentPage, totalPage } = controller.vm.state;
        if (currentPage === newPageNum || newPageNum === 0 || newPageNum > totalPage) {
            // do nothing
        } else {
            controller.vm.setState({ currentPage: newPageNum }, () => {
                controller.getMessages(newPageNum - 1, currentMsgType);
                controller.handleSelectAll(true);
            });
        }
    },

    handlePrevNextClick(isPrev, currentMsgType) {
        const { currentPage } = controller.vm.state;

        if (isPrev) {
            controller.handlePageClick(currentPage - 1, currentMsgType);
        } else {
            controller.handlePageClick(currentPage + 1, currentMsgType);
        }
    },

    openMessageDetailDialog(index) {
        const { messageList } = controller.vm.state;
        const { screen } = controller.vm.props;
        let messageObj = messageList[index];

        document.body.style.overflow = 'hidden';

        controller.deductUnreadMsgCount([messageObj]);

        if (screen.viewType === 'web') {
            // open message detail popup
            controller.vm.setState({
                messageList,
                selectedMessageObj: messageObj,
                messageDetailDialogIsShow: true,
            });
        } else {
            // open message detail popup
            controller.vm.setState({
                messageList,
                selectedMessageObj: messageObj,
                messageFullDetailDialogIsShow: true,
            });
            controller.vm.props.dispatch(userAction.openSpecialDialog());
        }
    },

    flagMessage(index) {
        const { messageList } = controller.vm.state;
        let messageObj = messageList[index];
        if (controller.isFlag(messageObj.status)) {
            messageObj.status = messageObj.status.substr(1, messageObj.status.length);
        } else {
            messageObj.status = `F${messageObj.status}`;
        }

        let updateMessage = {
            id: messageObj.id,
            status: messageObj.status,
            isFlag: controller.isFlag(messageObj.status),
        };
        window.SPL_Member.readAndFlagMessage(updateMessage);
        controller.vm.setState({
            messageList,
        });
    },

    flagSelectedMessage() {
        const { messageList, allFlag } = controller.vm.state;
        core.portalIsLoading(controller.vm.props, true);

        // let selectedMessages = messageList.filter(a => a.isChecked === true);
        for (let i = 0; i < messageList.length; i++) {
            let messageObj = messageList[i];

            if (messageObj.isChecked === true) {
                // if (!controller.isFlag(messageObj.status)) {
                //     // messageObj.status = messageObj.status.substr(1, messageObj.status.length);
                //     messageObj.status = `F${messageObj.status}`;
                // }

                if (!allFlag) {
                    if (messageObj.status.length > 2) {
                        messageObj.status = messageObj.status.substr(1, messageObj.status.length);
                    }
                } else {
                    if (!messageObj.status.includes('F')) {
                        messageObj.status = `F${messageObj.status}`;
                    }
                }

                if (controller.isFlag(messageObj.status)) {
                    if (allFlag) {
                        messageObj.status = messageObj.status.substr(1, messageObj.status.length);
                    }
                } else {
                    messageObj.status = `F${messageObj.status}`;
                }
                let updateMessage = {
                    id: messageObj.id,
                    status: messageObj.status,
                    isFlag: controller.isFlag(messageObj.status),
                };
                window.SPL_Member.readAndFlagMessage(updateMessage);
            }
        }
        controller.vm.setState(
            {
                messageList,
                allFlag: !allFlag,
            },
            () => {
                setTimeout(() => {
                    core.portalIsLoading(controller.vm.props, false);
                }, 1000);
            }
        );
    },

    isFlag(status) {
        if (status === 'FR' || status === 'FN') {
            return true;
        } else {
            return false;
        }
    },

    closeMessageDetailDialog(vm) {
        document.body.style.overflow = '';
        vm.setState({ messageDetailDialogIsShow: false, messageFullDetailDialogIsShow: false });
        controller.closeFullMessageDetailDialog();
    },

    closeFullMessageDetailDialog() {
        controller.vm.props.dispatch(userAction.closeSpecialDialog());
    },

    readMessageByBatch() {
        const { messageList, currentPage, currentMsgType } = controller.vm.state;
        let unreadSelectedMessagesIds = messageList.filter((a) => a.isChecked === true && a.status.includes('N')).map((a) => a.id);

        window.SPL_Member.readMessageByBatch(
            {
                message: unreadSelectedMessagesIds.join(','),
            },
            () => {
                controller.getMessages(currentPage - 1, currentMsgType);
                controller.getMemberMessageCount();
            }
        );
    },

    openDeleteMessageDialog() {
        const { messageList } = controller.vm.state;
        let selectedMessages = messageList.filter((a) => a.isChecked === true);
        if (selectedMessages.length > 0) {
            // popup confirmation first before proceed
            let selectedMessageIds = [];
            for (let i = 0; i < selectedMessages.length; i++) {
                selectedMessageIds.push(selectedMessages[i].id);
            }

            controller.vm.setState({
                deleteMessageDialogIsShow: true,
                selectedMessageIds: selectedMessageIds,
            });
        }
    },
    openOneDeleteMessageDialog(msg) {
        const { messageList } = controller.vm.state;
        let selectedMessages = messageList.filter((a) => a.id === msg.id);
        if (selectedMessages.length > 0) {
            // popup confirmation first before proceed
            let selectedMessageIds = [];
            for (let i = 0; i < selectedMessages.length; i++) {
                selectedMessageIds.push(selectedMessages[i].id);
            }

            controller.vm.setState({
                deleteMessageDialogIsShow: true,
                selectedMessageIds: selectedMessageIds,
            });
        }
    },

    closeDeleteMessageDialog(vm, isServiceComplete) {
        vm.setState(
            {
                deleteMessageDialogIsShow: false,
                selectedMessageIds: [],
            },
            () => {
                if (isServiceComplete) {
                    const { currentPage, currentMsgType } = controller.vm.state;
                    controller.getMessages(currentPage - 1, currentMsgType);
                    controller.handleSelectAll(true);
                }
            }
        );
    },

    getMemberMessageCount() {
        if (controller.vm.props.user.isLogin) {
            memberService.getMemberMessageCount(controller.vm);
        }
    },

    deductUnreadMsgCount(messageObjList) {
        messageObjList.forEach((msg) => {
            if (msg.status === 'N' || msg.status === 'FN') {
                msg.status = msg.status.replace('N', 'R');

                let updateMessage = {
                    id: msg.id,
                    status: msg.status,
                    isFlag: false,
                };

                const { totalUnreadMessageCount, totalUnreadNotificationCount, totalUnreadPaymentCount, totalUnreadPromotionCount, totalUnreadSystemCount } =
                    controller.vm.props.user;

                if (totalUnreadMessageCount && totalUnreadMessageCount > 0) {
                    switch (msg.messageType) {
                        case 'P':
                            controller.vm.props.dispatch(
                                userAction.updateUnreadMsg(
                                    totalUnreadMessageCount - 1,
                                    totalUnreadNotificationCount,
                                    totalUnreadPaymentCount,
                                    totalUnreadPromotionCount - 1,
                                    totalUnreadSystemCount
                                )
                            );
                            break;
                        case 'PT':
                            controller.vm.props.dispatch(
                                userAction.updateUnreadMsg(
                                    totalUnreadMessageCount - 1,
                                    totalUnreadNotificationCount,
                                    totalUnreadPaymentCount - 1,
                                    totalUnreadPromotionCount,
                                    totalUnreadSystemCount
                                )
                            );
                            break;
                        case 'N':
                            controller.vm.props.dispatch(
                                userAction.updateUnreadMsg(
                                    totalUnreadMessageCount - 1,
                                    totalUnreadNotificationCount,
                                    totalUnreadPaymentCount,
                                    totalUnreadPromotionCount,
                                    totalUnreadSystemCount - 1
                                )
                            );
                            break;
                        case 'I':
                            controller.vm.props.dispatch(
                                userAction.updateUnreadMsg(
                                    totalUnreadMessageCount - 1,
                                    totalUnreadNotificationCount - 1,
                                    totalUnreadPaymentCount,
                                    totalUnreadPromotionCount,
                                    totalUnreadSystemCount
                                )
                            );
                            break;
                        default:
                            break;
                    }
                } else {
                    controller.vm.props.dispatch(userAction.updateUnreadMsg(totalUnreadMessageCount - 1));
                }

                window.SPL_Member.readAndFlagMessage(updateMessage);
            }
        });
    },
};

export default controller;
