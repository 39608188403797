import transactionHelper from 'root/utils/transaction-helper';
import notification from 'root/utils/notification';
import commonUtil from '../../../../../../utils/common-util';
import { gu_getNestedValue, gu_getSettingFromPortalSettings } from '../../../../../../utils/general-util';
import { PAYMENT_GATEWAY_SETTING } from '../../../../../../utils/constants';
import { trans_getDepositAmountOptionByMethod } from 'root/utils/transaction-util';
import { AMOUNT_DEPOSIT_METHOD_CODE } from 'root/utils/constants';

const METHOD_OR_CHANNEL_CODE = 'R';
const controller = {
    vm: null,

    init(vm) {
        controller.vm = vm;
        controller.vm.state = {
            filteredDepositApiJson: controller.vm.props.filteredDepositApiJson,
            depositAllSettings: controller.vm.props.depositSettings,
            depositMinMaxLimit: { min: 0, max: 0 },
            depositAmountOptions: trans_getDepositAmountOptionByMethod(controller.vm.props.depositAmountOptions, AMOUNT_DEPOSIT_METHOD_CODE.R),

            bankList: [],
            selectedBankIndex: 0,
            hoveredIndex: null,
            reload_date: new Date(),
            receiptImg: [],
            fields: {
                amount: '',
                reference: '',
            },
            errors: {},
            accountBankfields: {
                selectedBankIndex: 0,
                bankName: '',
                bankBranch: '',
                accountName: controller.vm.props.user.account.name,
                accountNumber: '',
            },
            showAmountField: true,

            //notice artical settings
            showDefaultArticle: false,
            showCustomDepositArticle: false,
            showCustomDepositArticleContent: '',
            receiptRequired: true,

            enableCustomArticleJSON: false,
            compSettings: [],
        };
    },

    initReloadPage() {
        // const currency = controller.vm.props.user.account.currency;
        // const account = controller.vm.props.user.account;
        // window.SPL_Transaction.getReloadDetails(account, currency).then((reloadDetails) => {
        //     controller.vm.setState({
        //         reloadDetails: reloadDetails,
        //     });
        // });
    },

    initCompSettings() {
        return new Promise((resolve) => {
            // Step 1: Standard way to get from portal settings
            let tempSettings = commonUtil.getSettingFromPortalSettings({
                vm: controller.vm,
                settingName: 'reloadSettings',
            });

            const receiptRequired = gu_getSettingFromPortalSettings({
                vm: controller.vm,
                settingName: 'reloadRequiredReceipt',
                notFeatures: true,
            });

            // Step 2: Massage again to get the specific data you need (Skip if not needed)
            let isReceiptRequired = true;
            if (receiptRequired === false) {
                isReceiptRequired = receiptRequired;
            }

            // Step 3: Initial all into compSettings
            let compSettings = {
                hideDisplayInfo: tempSettings.hideDisplayInfo,
                showCustomArticle: tempSettings.showCustomArticle,
                enableCustomArticleJSON: tempSettings.enableCustomArticleJSON,
                isCustomArticlePath: tempSettings.isCustomArticlePath,
            };

            controller.vm.setState({ compSettings: compSettings, receiptRequired: isReceiptRequired }, () => {
                const { showCustomArticle } = controller.vm.state.compSettings;
                if (showCustomArticle) {
                    controller.readCustomDepositArticle();
                }
                resolve();
            });
        });
    },

    getMerchantAndMemberBanks() {
        let loadMerchantBankPromise = controller.loadMerchantBank();
        let loadMemberBankPromise = controller.loadMemberBankAccount();

        Promise.all([loadMerchantBankPromise, loadMemberBankPromise]).then(() => {
            transactionHelper.checkCertainBankRequiredReceipt(controller.vm);
            commonUtil.getDepositMinMax(controller.vm, 'R', controller.vm.props.user.account.currency, controller.vm.state.bankList[controller.vm.state.selectedBankIndex].id);
        });
    },

    async loadMerchantBank() {
        const vm = controller.vm;
        const { paymentGatewaySetting } = vm.props;
        const { filteredDepositApiJson } = vm.state;

        // always get first one channel, because bank transfer currently only has 1 channel
        if (filteredDepositApiJson?.optionCode !== METHOD_OR_CHANNEL_CODE) {
            return;
        }

        if (filteredDepositApiJson && filteredDepositApiJson.channel && Object.values(filteredDepositApiJson.channel) && Object.values(filteredDepositApiJson.channel)[0]) {
            let _bankList = Object.values(filteredDepositApiJson.channel)[0];
            for (let i in _bankList) {
                const bank = _bankList[i];
                bank.accountHolder = bank.accountName;
                // bank.svgData = await window.SPL_Other.renderTemplateSVG(`${commonUtil.getDepositImgPath()}${bank.code}.svg`, `${bank.code}`);
            }

            const bank = _bankList[0];
            const { showAmountField } = transactionHelper.getDepositAmountFieldNotRequiredConfig(paymentGatewaySetting, bank);

            vm.setState({
                bankList: _bankList,
                showAmountField,
            });
        }
    },

    loadMemberBankAccount() {
        return new Promise(function (resolve) {
            transactionHelper.loadMemberBankAccounts(controller.vm).then(() => {
                resolve();
            });
        });
    },

    onBankChanged(bankObj, onChangeValue) {
        const vm = controller.vm;
        // transactionHelper.onBankChanged(controller.vm, bankObj, onChangeValue, 'R');
        const { selectedBankIndex, bankList } = vm.state;
        const { paymentGatewaySetting } = vm.props;
        const { amountFieldNotRequired } = PAYMENT_GATEWAY_SETTING;

        const getStateData = (index) => {
            const bank = bankList[index];
            const showAmountField = transactionHelper.getPaymentGatewaySettingBool(paymentGatewaySetting, amountFieldNotRequired, bank);
            return { showAmountField };
        };

        const newIndex = onChangeValue !== undefined ? onChangeValue : bankList.indexOf(bankObj);
        if (newIndex !== selectedBankIndex) {
            const { showAmountField } = getStateData(newIndex);
            vm.setState({ selectedBankIndex: newIndex, showAmountField: showAmountField });
        }
        commonUtil.getDepositMinMax(vm, 'R', vm.props.user.account.currency, bankObj?.id);
    },

    handleAmountChange(e) {
        const value = e.target.value;
        if (value) {
            let regex = '';
            if (controller.vm.props.memberDecimalSettings?.[AMOUNT_DEPOSIT_METHOD_CODE.R]) {
                regex = /^[0-9]+$/;
            } else {
                regex = /^(\d*)\.{0,1}(\d){0,2}$/;
            }
            if (value.toString().match(regex)) {
                transactionHelper.handleFieldChanged(controller.vm, e, 'amount');
            }
        } else {
            transactionHelper.handleFieldChanged(controller.vm, e, 'amount');
        }
    },

    getReloadDate(date) {
        controller.vm.setState({ reload_date: date });
    },

    isSubmitButtonDisabled() {
        const { receiptRequired, isReceiptValid, fields, errors, isSubmitting } = controller.vm.state;

        const isAmountFieldValid = fields['amount'] && !errors['amount'];
        const _isReceiptValid = receiptRequired && isReceiptValid;

        if (isAmountFieldValid && _isReceiptValid && !isSubmitting) {
            return false;
        } else {
            return true;
        }
    },

    handleReceiptChange(e) {
        if (e && e.target && e.target.files && e.target.files.length > 0) {
            controller.vm.setState(
                {
                    receiptImg: URL.createObjectURL(e.target.files[0]),
                    receiptImgFile: e.target.files[0],
                },
                () => {
                    controller.handleReceiptValidation();
                }
            );
        }
    },

    handleReceiptValidation() {
        const { receiptImg } = controller.vm.state;
        let stateToUpdate = {
            isReceiptValid: receiptImg && receiptImg.length > 0,
        };
        controller.vm.setState(stateToUpdate);
    },

    submitForm() {
        controller.submitReloadDeposit();
    },

    submitReloadDeposit() {
        const { receiptImgFile, reload_date, fields, bankList, selectedBankIndex } = controller.vm.state;
        let month = controller.appendZero(reload_date.getMonth() + 1);
        let date = controller.appendZero(reload_date.getDate());
        let hours = controller.appendZero(reload_date.getHours());
        let minutes = controller.appendZero(reload_date.getMinutes());

        let transDate = reload_date.getFullYear() + '-' + month + '-' + date + ' ' + hours + ':' + minutes;

        controller.vm.setState({ isSubmitting: true });
        window.SPL_Transaction.validateBeforeSubmit(
            null,
            null,
            receiptImgFile,
            fields.reference,
            transDate,
            fields.amount,
            bankList[selectedBankIndex],
            gu_getNestedValue(bankList[selectedBankIndex], 'id') // should be don't need, if follow sp2 suppose is memberBank (get by member R type bank)
        ).then((data) => {
            if (data.msg && data.msg[0] !== 'Success') {
                notification.showNotification('error', controller.vm.props.t(data.msg[0]));
            } else {
                notification.showNotification('success', controller.vm.props.t('transaction:transaction.deposit.request-success'));
            }
            controller.vm.setState({ isSubmitting: false });
        });
    },

    appendZero(val) {
        if (val.toString().length < 2) {
            return '0' + val;
        }
        return val;
    },
    readCustomDepositArticle() {
        const { compSettings } = controller.vm.state;
        const customFilename = compSettings && compSettings.isCustomArticlePath ? 'r' : '';
        transactionHelper.readCustomDepositArticle(controller.vm, customFilename);
    },
};

export default controller;
