import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import core from 'root/utils/core';
import SettingLanding from './landing/component/SettingLanding';

//const MobileSettings = lazy(() => import('./mobile/Member'))

class Settings extends React.Component {
    components = {
        desktop: {
            default: SettingLanding,
        },
        mobile: {
            default: SettingLanding,
        },
    };

    render() {
        const TagName = core.getCorrectComponent(this.components, 'settings', this.props.screen.viewType);
        return <TagName></TagName>;
    }
}

export default connect(mapStateToProps, null)(Settings);
