const controller = {
    vm: null,

    init(vm) {
        this.vm = vm;
        this.vm.state = {
            termsContent: '',
            isLoading: true,
        };
        this.getCollapseItem = this.getCollapseItem.bind(this);
    },

    getTerms() {
        const { language } = controller.vm.props;

        window.SPL_Content.getTermPage(language.countryLanguageKey, true).then((res) => {
            if (res && res.content && res.content.includes('{{MerchantName}}')) {
                res.content = res.content.replace('{{MerchantName}}', window.globMerchantName);
            }

            controller.vm.setState({ termsContent: res.content, isLoading: false });
        });
    },

    getCollapseItem(e) {
        let el = e.target,
            childTag_under_parentTag = e.currentTarget.childNodes,
            previousParentTag = el.parentNode.parentNode;

        if (el && el.parentNode.className === 'collapse-header' && !previousParentTag.className.includes('active')) {
            for (let i = 0; i < childTag_under_parentTag.length; i++) {
                if (childTag_under_parentTag[i].className && childTag_under_parentTag[i].className.includes('active')) {
                    childTag_under_parentTag[i].classList.remove('active');
                    break;
                }
            }

            previousParentTag.classList.add('active');
        } else if (el && el.parentNode.className === 'collapse-header') {
            previousParentTag.classList.remove('active');
        }
    },
};

export default controller;
