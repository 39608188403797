import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import core from 'root/utils/core';

import DesktopCockfight from './desktop/Cockfight';
import MobileCockfight from './mobile';

class Cockfight extends React.Component {
    components = {
        desktop: {
            default: DesktopCockfight,
        },
        mobile: {
            default: MobileCockfight,
        },
    };

    render() {
        const TagName = core.getCorrectComponent(this.components, 'cockfight', this.props.screen.viewType);
        return <TagName></TagName>;
    }
}

export default connect(mapStateToProps, null)(Cockfight);
