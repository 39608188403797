const dateUtil = {
    getSelectedMonthStartEnd(month, year) {
        var start = new Date(year, month -1, 1, 0, 0, 0);
        var lastDay = new Date(year, month, 0);
        var end = new Date(year, month -1, lastDay.getDate(), 23, 59, 59);
        return { start: start, end: end };
    },

    getMonthSelectOption() {
        var months = [];

        months.push({value:1, text:'months.jan'});
        months.push({value:2, text:'months.feb'});
        months.push({value:3, text:'months.mar'});
        months.push({value:4, text:'months.apr'});
        months.push({value:5, text:'months.may'});
        months.push({value:6, text:'months.jun'});
        months.push({value:7, text:'months.jul'});
        months.push({value:8, text:'months.aug'});
        months.push({value:9, text:'months.sep'});
        months.push({value:10, text:'months.oct'});
        months.push({value:11, text:'months.nov'});
        months.push({value:12, text:'months.dec'});

        return months;
    },

    getMonthFromString(mon){
        return new Date(Date.parse(mon +" 1, 2012")).getMonth()+1
    },

};

export default dateUtil;
