import React from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import vm from '../../history-landing.controller';
import MobileSelect from 'root/webapp/shared/component/mobileSelect/mobileSelect';

class MHistoryLanding extends React.Component {
    constructor() {
        super();
        vm.init(this);
        this.state = {
            ...this.state,

            date: {
                active: 0,
                list: [
                    'transaction:transaction.history.filter.today',
                    'transaction:transaction.history.filter.3days',
                    'transaction:transaction.history.filter.aweek',
                    'transaction:transaction.history.filter.amonth',
                ],
            },
            list: [],
        };
    }

    componentDidMount() {
        vm.highlightTab();
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    renderSelectedTab(tabIndex) {
        const Content = vm.getTabContent(tabIndex);
        if (Content) {
            return <Content parent={vm} parentState={vm.vm.state}></Content>;
        }

        return <div></div>;
    }

    render() {
        const { language } = this.props;
        const { displayTab, selectedTabIndex } = this.state;
        return (
            <Translation>
                {(t) => (
                    <div data-page='/myaccount/'>
                        <div className='history-page'>
                            <div className='history-tools'>
                                <MobileSelect
                                    itemList={displayTab}
                                    itemKey={'tabHeader'}
                                    onChange={(e) => vm.toggleTab(e)}
                                    onChangeIndexType={true}
                                    itemIndex={selectedTabIndex}
                                    title={t('transaction:transaction.history.filter.trans_type_no_colon', 'Transaction Type').split(':')}
                                    required={true}
                                />
                                {this.renderSelectedTab(selectedTabIndex)}
                            </div>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}
export default connect(mapStateToProps)(withTranslation('settings')(withRouter(MHistoryLanding)));
