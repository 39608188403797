import { languageConstants } from '../constant';

const initialState = {
    key: window.key,
    countryLanguageKey: window.countryLanguageKey,
    country: window.country,
    defaultTelcode: 'MY',
    currencyLang: window.currencyLang,
};

export function language(state = initialState, action) {
    switch (action.type) {
        case languageConstants.SET_LANGUAGE:
            return {
                ...state,
                key: action.payload.key,
                countryLanguageKey: action.payload.countryLanguageKey,
                country: action.payload.country,
                defaultTelcode: action.payload.country,
                currencyLang: action.payload.currencyLang,
                src: action.payload.src,
                display: action.payload.display,
            };

        default:
            return state;
    }
}
