import React from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';

import ImageHelper from 'root/webapp/shared/image-helper/image-helper';
//import TranslationHelper from 'root/webapp/shared/translation-helper/translation-helper';
import { CSSTransition } from 'react-transition-group';
import commonUtil from 'root/utils/common-util';

class LeaderboardRankScore extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
        this.state = {};
    }

    componentDidMount() {}

    componentDidUpdate() {}

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        // passed props
        const { currentMemberRank, calendar, memberRanking, fields, memberGroupImg, memberGroupColor } = this.props;
        // passed props function
        const { onDateYearChange, user, dropdown } = this.props;
        return (
            <Translation>
                {(t) => (
                    /************************ SETTING MAWIN WALLET ***********************/
                    <div className='nav-leaderboard'>
                        <div className='nav-leaderboard-container'>
                            <div
                                className='icon-leaderboard-svg'
                                id='dropdownBtn'
                                onClick={(e) => commonUtil.dropdownUpdate(e, 'certainElement', this.props, 'leaderboardRank')}
                            >
                                <ImageHelper displayImg={memberGroupImg} className='leaderboard' />
                                <span className='icon-leaderboard-span' style={{ color: memberGroupColor }}>
                                    {user.account && user.account.groupName ? user.account.groupName : ''}
                                </span>
                            </div>
                            <div
                                className='navbar-member-group-desc'
                                id='dropdownBtn'
                                onClick={(e) => commonUtil.dropdownUpdate(e, 'certainElement', this.props, 'leaderboardRank')}
                            >
                                <ImageHelper displayImg={'/public/html/images/leaderboard/gold-medal.png'} className='leaderboard' />
                                <span className='navbar-member-group-rank'>{currentMemberRank ? currentMemberRank : '-'}</span>
                            </div>
                        </div>

                        <CSSTransition
                            classNames='spread-bottom'
                            in={
                                dropdown.certainElementDropdown === 'leaderboardRank' ||
                                dropdown.calenderDropdown ||
                                dropdown.calenderMonthDropdown ||
                                dropdown.calenderYearDropdown
                            }
                            timeout={300}
                            unmountOnExit
                        >
                            <div className='floating-leaderboard-container' onClick={(e) => e.stopPropagation()}>
                                <div className='standard-form-container'>
                                    <div className='standard-form-field month-field'>
                                        <select
                                            className='standard-select'
                                            value={fields.dateMonth}
                                            onChange={(e) => onDateYearChange && onDateYearChange(e.target.value, 'dateMonth')}
                                        >
                                            {((calendar && calendar.monthList) || []).map((month, i) => (
                                                <option key={i} value={month.value}>
                                                    {t(month.text)}
                                                </option>
                                            ))}
                                        </select>
                                        <div className='standard-select-arrow'></div>
                                    </div>
                                    <div className='standard-form-field year-field'>
                                        <select
                                            className='standard-select'
                                            value={fields.dateYear}
                                            onChange={(e) => onDateYearChange && onDateYearChange(e.target.value, 'dateYear')}
                                        >
                                            {/* {((calendar && calendar.yearList) || []).map((year, i) => ( */}
                                            <option>{t(calendar.yearlist)}</option>
                                            {/* ))} */}
                                        </select>
                                        <div className='standard-select-arrow'></div>
                                    </div>
                                </div>
                                <div className='rank-breakdown-container'>
                                    <div className='category-rank'>
                                        <div className='category-box'>
                                            <span className='label'>{t('global:global.menu.sportsbook2', 'Sports')}</span>
                                            <span className='value'>{memberRanking && memberRanking.SPORTSTo ? memberRanking.SPORTSTo : '>100'}</span>
                                        </div>
                                        <div className='category-box'>
                                            <span className='label'>{t('global:global.menu.casino2', 'Casino')}</span>
                                            <span className='value'>{memberRanking && memberRanking.CASINOTo ? memberRanking.CASINOTo : '>100'}</span>
                                        </div>
                                        <div className='category-box'>
                                            <span className='label'>{t('global:global.menu.slot2', 'Slots')}</span>
                                            <span className='value'>{memberRanking && memberRanking.SLOTSTo ? memberRanking.SLOTSTo : '>100'}</span>
                                        </div>
                                        <div className='category-box'>
                                            <span className='label'>{t('global:global.others', 'Others')}</span>
                                            <span className='value'>{memberRanking && memberRanking.OTHERSTo ? memberRanking.OTHERSTo : '>100'}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CSSTransition>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation([''])(withRouter(LeaderboardRankScore)));
