import React from 'react';
import { FaCheckCircle, FaExclamation, FaInfoCircle, FaRegTimesCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';

// icon is using fontawesome, please refer to this link if want to change
// https://react-icons.github.io/react-icons/icons?name=fa
const notification = {
    showNotification(type, content, specialToastParam = null, isSetInnerHtml = false) {
        let toastContent = notification.renderToast(type, content, isSetInnerHtml);

        // special toast configuration
        // please refer https://fkhadra.github.io/react-toastify/api/toast
        // for which item to config
        if (specialToastParam) {
            toast(toastContent, specialToastParam, { className: type });
        } else {
            toast(toastContent, { className: type });
        }
    },

    getCorrectIcon(type) {
        switch (type) {
            case 'success':
                return <FaCheckCircle className='success'></FaCheckCircle>;
            case 'error':
                return <FaRegTimesCircle className='error'></FaRegTimesCircle>;
            case 'warning':
                return <FaExclamation className='warning'></FaExclamation>;
            case 'info':
            default:
                return <FaInfoCircle className='info'></FaInfoCircle>;
        }
    },

    renderToast(type, content, isSetInnerHtml = false) {
        return (
            <div className={`standard-notification-container`}>
                <div className='standard-notification-icon'>{notification.getCorrectIcon(type)}</div>
                {!isSetInnerHtml && <div className={`standard-notification-content ${type}`}>{content}</div>}
                {isSetInnerHtml && <div className={`standard-notification-content ${type}`} style={{ display: 'block' }} dangerouslySetInnerHTML={{ __html: content }}></div>}
            </div>
        );
    },
};

export default notification;
