import React, { useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

function ScrollToTop({ history, children }) {
    useEffect(() => {
        const unlisten = history.listen((e) => {
            if (e.pathname.indexOf('/slots/') >= 0 || e.pathname === '/mega888' || e.pathname === '/918kiss') {
                // do nothing
            } else {
                window.scrollTo(0, 0);
            }
        });
        return () => {
            unlisten();
        };
    }, [history]);

    return <Fragment>{children}</Fragment>;
}

export default withRouter(ScrollToTop);
