// library
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { MdClose } from 'react-icons/md';
import { CSSTransition } from 'react-transition-group';

// components
import SVGTransfer from 'root/components/svgIcon/svgTransfer';
import SmallLoading from 'root/webapp/shared/loading/smallLoading';
import SVGElements from 'root/components/SVGElements';
import MobileSelect from 'root/webapp/shared/component/mobileSelect/mobileSelect';
import notification from 'root/utils/notification';
import SVGProvider from 'root/components/svgIcon/svgProvider';
import Text from 'root/webapp/components/atoms/text/text';
import styles from './promotion-transfer.module.scss';
import SVG from 'react-inlinesvg';
import { promotionAction } from '../../../redux/action/promotion.action';

// logic

const PromotionApply = ({ applyDetails, onApplyClicked, filteredProviderData, isSpecialForYou }) => {
    const props = useSelector((state) => state);
    const languageRedux = useSelector((state) => state.language);
    const [providerSelect, setProviderSelect] = useState(null);
    const [providerSelectIndex, setProviderSelectIndex] = useState(0);
    const [providerType, setProviderType] = useState(null);
    const [providerWallet, setProviderWallet] = useState(null);
    const [providerWalletSelected, setProviderWalletSelected] = useState(null);
    const [transferAmount, setTransferAmount] = useState(null);

    const [processTransfer, setProcessTransfer] = useState(false);
    const [restoreOnLoad, setRestoreOnLoad] = useState(false);
    const [mainWallet, setMainWallet] = useState(null);

    const screen = useSelector((state) => state.screen);
    const userRedux = useSelector((state) => state.user);
    const isMobile = screen.viewType === 'mobile';
    const dispatch = useDispatch();

    const handleTypeChange = (providerType) => {
        //this is to filter when switch tab the provider must select the first one
        const selectedCategory = filteredProviderData?.find((category) => category.key === providerType);
        if (selectedCategory) {
            const firstProvider = selectedCategory.list[0]?.provider;
            setProviderSelect(firstProvider);
            setProviderSelectIndex(0);
        }
        setProviderType(providerType);
    };

    const handleProviderSelect = (providerSelect, index) => {
        setProviderSelect(providerSelect); //deskstop
        setProviderSelectIndex(index); //mobile
    };

    const handleFieldChanged = (e) => {
        let transferRangeHtml = document.getElementById('transfer-range');
        let percentage = (e.target.value / mainWallet?.balance) * 100;
        if (transferRangeHtml) {
            transferRangeHtml.style.background = 'linear-gradient(to right, #bcbcbc 0%, #bcbcbc ' + percentage + '%, #ff4e00 ' + percentage + '%, #ff4e00 100%)';
        }

        if (Number(e.target.value) >= mainWallet?.balance) {
            e.target.value = mainWallet?.balance.toString();
        }

        if (Number(e.target.value) <= mainWallet?.balance) {
            setTransferAmount(Number(e.target.value));
        }
    };

    const restoreWallet = () => {
        setRestoreOnLoad(true);

        window.SPL_Transaction.transferAllBackMainWallet()
            .then(() => {
                setTimeout(() => {
                    getMainWallet();
                    setRestoreOnLoad(false);
                }, 5000);
            })
            .catch(() => {
                setTimeout(() => {
                    getMainWallet();
                    setRestoreOnLoad(false);
                }, 5000);
            });
    };

    const getMainWallet = () => {
        setRestoreOnLoad(true);

        window.SPL_Member.getMainWallet()
            .then((mainWallet) => {
                setMainWallet(mainWallet.data);
                setRestoreOnLoad(false);
                loadAsyncProviderWallet();
            })
            .catch((err) => {
                console.log(err);
                setRestoreOnLoad(false);
            });
    };

    const loadAsyncProviderWallet = () => {
        window.SPL_Provider.loadAsyncProviderWallet(null, props).then((providerWallet) => {
            setProviderWallet(providerWallet?.providerList);
        });
    };

    const handleRequestTransfer = () => {
        if (!processTransfer) {
            setProcessTransfer(true);
            const promotionId = isSpecialForYou ? applyDetails?.id : applyDetails?.promotionId;

            window.SPL_Transaction.requestTransfer(transferAmount, promotionId, 'Member Deposit', providerSelect, null).then((data) => {
                if (data.status === 'F') {
                    notification.showNotification('error', <Text textKey={data.msg} />);
                    setProcessTransfer(false);
                } else {
                    notification.showNotification('success', <Text textKey={'transaction:transaction.deposit.request-success'} />);
                    dispatch(promotionAction.getPromotionData({
                        languageKey: languageRedux?.countryLanguageKey,
                        login: userRedux?.account?.login,
                        size: 10,
                        page: 0,
                    }));
                    onApplyClicked();
                }
            });
        }
    };

    useEffect(() => {
        //main wallet
        getMainWallet();
        loadAsyncProviderWallet();

        //set provider
        setProviderType(filteredProviderData?.[0]?.key); //Lottery, Sport, Fishing
        setProviderSelect(filteredProviderData?.[0]?.list[0].provider); //PPL,QQK,JILI
    }, []);

    useEffect(() => {
        const selectedCategory = providerWallet?.find((category) => category.provider === providerSelect);
        setProviderWalletSelected(selectedCategory?.balance);
    }, [providerSelect, providerWallet]);


    return (
        <section>
            <div className={`promosDetail-popup promosTransfer-popup ${styles.promosDetailPopup} ${isMobile && 'm-promosDetail-popup m-promosTransfer-popup'}`}>
                <div className='popup'>
                    <div className={`popup-header  ${styles.popupHeader}`}>
                        <div className='popup-header-left'>
                            <span className='popup-title'>
                                <Text textKey={'promotion:promotion.apply-promo'}>Apply Promotion</Text>
                            </span>
                        </div>

                        <div className={`popup-header-right  ${styles.popupHeaderRight}`}>
                            <MdClose onClick={() => onApplyClicked()} />
                        </div>
                    </div>

                    <div className='popup-body'>
                        {isMobile ? (
                            <ul className='promo-wallet'>
                                <div className='top'>
                                    <ul className='type-list'>
                                        {(filteredProviderData || []).map((data) => {
                                            const { key, title } = data;
                                            return (
                                                <li
                                                    key={key}
                                                    className={[key === providerType ? 'on' : ''].join(' ')}
                                                    onClick={() => {
                                                        handleTypeChange(key);
                                                    }}
                                                >
                                                    <span className='icon-box'>
                                                        <SVGTransfer
                                                            name={key}
                                                            className={`${key}-svg`}
                                                            color='#bcbcbc'
                                                            // on={i === this.state.typeIndex}
                                                            onColor={'#000'}
                                                        />
                                                    </span>
                                                    <span className='icon-title'>
                                                        <Text textKey={title}>{title}</Text>
                                                    </span>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                    {(filteredProviderData || []).map((category) => {
                                        const { key, list } = category;
                                        return (
                                            key === providerType && (
                                                <MobileSelect
                                                    key={key}
                                                    className='popup-mobile-select'
                                                    itemList={list}
                                                    itemKey={'name'}
                                                    onChange={(e) => handleProviderSelect(e.target?.customObj?.provider, e.target?.value)}
                                                    itemIndex={providerSelectIndex}
                                                    forceSelectIndex={0}
                                                />
                                            )
                                        );
                                    })}
                                </div>

                                <div className='bottom'>
                                    <div className='promo-wallet-title'>
                                        <Text textKey={'promotion:promotion.transferCredit'}>Transfer Credit</Text>
                                    </div>
                                    <div className='wallet'>
                                        <div className='promo-icon-restore'>
                                            {restoreOnLoad ? (
                                                <SmallLoading></SmallLoading>
                                            ) : (
                                                <CSSTransition classNames='rotate360' in={restoreOnLoad} timeout={1000}>
                                                    <SVGElements name='reload-icon' onClick={() => restoreWallet()} />
                                                </CSSTransition>
                                            )}
                                        </div>
                                        <div className='main-wallet'>
                                            <SVGTransfer name='mainWalletV2' className='promo-icon-wallet' />
                                            <div className='wallet-info'>
                                                <span className='mainWallet-title'>
                                                    <Text textKey={'global:global.form.mainWallet'}>Main Wallet</Text>
                                                </span>
                                                <div className='amount-wrapper'>
                                                    <span className='mainWallet-currency'>{languageRedux?.currencyLang}</span>
                                                    {mainWallet && <span className='mainWallet-amount'>{window.SPL_Other.formatAmount(mainWallet?.balance)}</span>}
                                                </div>
                                            </div>
                                        </div>

                                        <LazyLoadImage className='promo-icon-arrow' src={'/public/html/images/promotion/arrow.svg'} />

                                        <div className='provider-wallet'>
                                            <SVG
                                                className='provider-selected'
                                                src={`/public/html/images/promotion/provider/${providerSelect}.svg`}
                                            ><SVGProvider className={`${providerSelect} provider-selected`} name={providerSelect} /></SVG>

                                            <div className='wallet-info'>
                                                <span>
                                                    {providerSelect} <Text textKey={('popupTransfer:popupTransfer.wallet', 'Wallet')}>Wallet</Text>
                                                </span>
                                                <div className='amount-wrapper'>
                                                    <span className='amount-currency'>{languageRedux?.currencyLang} </span>
                                                    <span className='amount-value'>
                                                        {providerWalletSelected ? window.SPL_Other.formatAmount(providerWalletSelected) : window.SPL_Other.formatAmount(0.0)}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='amount'>
                                        <p className='amount-title'>
                                            <Text textKey={'popupTransfer:popupTransfer.transferAmount'}>Transfer Amount</Text>
                                        </p>

                                        <div className='amount-input'>
                                            <span>{languageRedux?.currencyLang}</span>

                                            <input type='number' id='transferamount' value={`${transferAmount}`} placeholder={`0`} onChange={(e) => handleFieldChanged(e)} />
                                        </div>

                                        <div className='amount-range'>
                                            <input
                                                id='transfer-range'
                                                type='range'
                                                className='range-box'
                                                max={mainWallet?.balance}
                                                value={transferAmount || 0}
                                                onChange={(e) => handleFieldChanged(e)}
                                            />
                                        </div>
                                    </div>

                                    <div className={`promo ${styles.promo}`}>
                                        <p className={`promo-title ${styles.promoTitle}`}>
                                            <Text textKey={'global:global.form.promocode'}>Promo Code</Text>
                                        </p>

                                        <p type='number' className={`promo-input ${styles.promoInput}`} id='transferamount'>
                                            {/* promotion Code - bonusName key is from unclaimed API, however promotion API is using title */}
                                            <span>{applyDetails?.bonusName || applyDetails?.title}</span>
                                        </p>
                                    </div>

                                    <div className={`btn-container  ${styles.buttonContainer}`}>
                                        <button disabled='' type='submit' className={`popup-btn ${styles.submitButton}`} onClick={() => handleRequestTransfer()}>
                                            <Text textKey={'message:message.confirm'}>Confirm</Text>
                                        </button>
                                        <button disabled='' type='submit' className={`popup-btn ${styles.cancelButton}`} onClick={() => onApplyClicked()}>
                                            <Text textKey={'global:entity.action.cancel'}>Cancel</Text>
                                        </button>
                                    </div>
                                </div>
                            </ul>
                        ) : (
                            <>
                                <ul className='promo-wallet'>
                                    <div className='promo-wallet-title'>
                                        <Text textKey={'promotion:promotion.transferCredit'}>Transfer Credit</Text>
                                    </div>
                                    <div className='wallet'>
                                        <div className='main-wallet'>
                                            <SVGTransfer name='mainWalletV2' className='promo-icon-wallet' />

                                            <div className='wallet-info'>
                                                <span className='mainWallet-title'>
                                                    <Text textKey={'global:global.form.mainWallet'}>Main Wallet</Text>
                                                </span>
                                                <div className='amount-wrapper'>
                                                    <span className='amount-currency'>{languageRedux?.currencyLang}</span>
                                                    {mainWallet && <span className='amount-value'>{window.SPL_Other.formatAmount(mainWallet?.balance)}</span>}
                                                </div>
                                            </div>

                                            {restoreOnLoad ? (
                                                <SmallLoading></SmallLoading>
                                            ) : (
                                                <span className='promo-icon-restore'>
                                                    <CSSTransition classNames='rotate360' in={restoreOnLoad} timeout={1000}>
                                                        <SVGElements name='reload-icon' onClick={() => restoreWallet()} />
                                                    </CSSTransition>
                                                </span>
                                            )}
                                        </div>

                                        <LazyLoadImage className='promo-icon-arrow' src={'/public/html/images/promotion/arrow.svg'} />

                                        <div className='provider-wallet'>
                                            <SVG
                                                className='provider-selected'
                                                src={`/public/html/images/promotion/provider/${providerSelect}.svg`}
                                            ><SVGProvider className={`${providerSelect} provider-selected`} name={providerSelect} /></SVG>
                                            
                                            <div className='wallet-info'>
                                                <span>
                                                    {providerSelect} <Text textKey={('popupTransfer:popupTransfer.wallet', 'Wallet')}>Wallet</Text>
                                                </span>
                                                <div className='amount-wrapper'>
                                                    <span className='amount-currency'>{languageRedux?.currencyLang} </span>
                                                    <span className='amount-value'>
                                                        {providerWalletSelected ? window.SPL_Other.formatAmount(providerWalletSelected) : window.SPL_Other.formatAmount(0.0)}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='amount'>
                                        <p className='amount-title'>
                                            <Text textKey={'popupTransfer:popupTransfer.transferAmount'}>Transfer Amount</Text>
                                        </p>

                                        <div className='amount-input'>
                                            <span>{languageRedux?.currencyLang}</span>

                                            <input type='number' id='transferamount' value={`${transferAmount}`} placeholder={`0`} onChange={(e) => handleFieldChanged(e)} />
                                        </div>

                                        <div className='amount-range'>
                                            <input
                                                id='transfer-range'
                                                type='range'
                                                className='range-box'
                                                min={0}
                                                max={mainWallet?.balance}
                                                value={transferAmount || 0}
                                                onChange={(e) => handleFieldChanged(e)}
                                            />
                                        </div>
                                    </div>

                                    <div className={`promo ${styles.promo}`}>
                                        <p className={`promo-title ${styles.promoTitle}`}>
                                            <Text textKey={'global:global.form.promocode'}>Promo Code</Text>
                                        </p>

                                        <p type='number' className={`promo-input ${styles.promoInput}`} id='transferamount'>
                                            {/* promotion Code - bonusName key is from unclaimed API, however promotion API is using title */}
                                            <span>{applyDetails?.bonusName || applyDetails?.title}</span>
                                        </p>
                                    </div>
                                </ul>

                                <ul className='promo-provider'>
                                    <div className='type-container'>
                                        <ul className='type-list'>
                                            {(filteredProviderData || []).map((data) => {
                                                const { key, title } = data;
                                                return (
                                                    <li
                                                        key={key}
                                                        className={[key === providerType ? 'on' : ''].join(' ')}
                                                        onClick={() => {
                                                            handleTypeChange(key);
                                                        }}
                                                    >
                                                        <span className='icon-box'>
                                                            <SVGTransfer
                                                                name={key}
                                                                className={`${key}-svg`}
                                                                color='#bcbcbc'
                                                                // on={i === this.state.typeIndex}
                                                                onColor={'#000'}
                                                            />
                                                        </span>
                                                        <span className='icon-title'>
                                                            <Text textKey={title}>{title}</Text>
                                                        </span>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>

                                    <div className='provider-container'>
                                        <ul className='provider-list'>
                                            {(filteredProviderData || []).map((category) => {
                                                const { key, list } = category;
                                                return (
                                                    key === providerType &&
                                                    (list || []).map((providerList, index) => {
                                                        const { provider, name } = providerList;
                                                        return (
                                                            <li
                                                                key={index}
                                                                className={[provider === providerSelect ? 'on' : ''].join(' ')}
                                                                onClick={() => {
                                                                    handleProviderSelect(provider);
                                                                }}
                                                            >
                                                                <span>{name}</span>
                                                            </li>
                                                        );
                                                    })
                                                );
                                            })}
                                        </ul>
                                    </div>

                                    <div className={`btn-container  ${styles.buttonContainer}`}>
                                        <button disabled='' type='submit' className={`popup-btn ${styles.submitButton}`} onClick={() => handleRequestTransfer()}>
                                            <Text textKey={'message:message.confirm'}>Confirm</Text>
                                        </button>
                                        <button disabled='' type='submit' className={`popup-btn ${styles.cancelButton}`} onClick={() => onApplyClicked()}>
                                            <Text textKey={'global:entity.action.cancel'}>Cancel</Text>
                                        </button>
                                    </div>
                                </ul>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default withTranslation(['promotion', 'popupTransfer', 'message'])(PromotionApply);
