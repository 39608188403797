import { promotionConstants } from '../constant/';

export const promotionAction = {
    loadPromotionDetails,
    getPromotionData,
};

function loadPromotionDetails(promotionObj) {
    return (dispatch) => {
        dispatch({ type: promotionConstants.LOAD_PROMOTION_DETAILS, payload: promotionObj });
    };
}

function getPromotionData(params) {
    return (dispatch) => {
        return new Promise(function (resolve, reject) {
            window.SPL_Member.getUnclaimedPromotionData(params)
                .then((data) => {
                    let showPromotionNewTag = false;
                    if (data?.data) {
                        let promotionData = data.data;
                        showPromotionNewTag = promotionData.some((item) => item.hasNewTag);
                    }

                    dispatch({ type: promotionConstants.GET_PROMOTION_DATA, payload: { promotionData: data, showPromotionNewTag: showPromotionNewTag } });
                    resolve();
                })
                .catch((err) => {
                    dispatch({ type: promotionConstants.GET_PROMOTION_DATA, payload: { promotionData: false, showPromotionNewTag: false } });
                    reject();
                });
        });
    };
}
