import { passwordConstants } from '../constant/password.constant';

export const getPasswordRules = () => {
    return (dispatch, getState) => {
        const { passwordReducer } = getState();
        if (!passwordReducer?.passwordRules) {
            window.SPL_Register.getPasswordRules().then((data) => {
                if (data) {
                    dispatch({ type: passwordConstants.GET_PASSWORD_RULES, payload: data });
                }
            });
        }
    };
};

export const getPasswordConfigApi = () => {
    return (dispatch, getState) => {
        const { passwordReducer, portal } = getState();

        if (portal?.settings?.isSkipCheckPasswordConfig) {
            dispatch({ type: passwordConstants.GET_PASSWORD_CONFIG_API, payload: {} });
        } else {
            if (!passwordReducer?.passwordRules) {
                window.SPL_Register.getPasswordRules().then((data) => {
                    dispatch({ type: passwordConstants.GET_PASSWORD_CONFIG_API, payload: data || {} });
                });
            }
        }
    };
};

export const fetchProcessedPasswordConfig = () => {
    return async (dispatch, getState) => {
        const { passwordReducer } = getState();

        if (passwordReducer?.processedData?.passwordConfig) {
            return;
        }

        try {
            const passwordConfigApiData = passwordReducer?.rawData?.passwordConfigApiData;

            const alphabetValidateRules = passwordConfigApiData?.uppercase ? '(?=.*[A-Z])' : '(?=.*[A-Za-z])';
            const digitAndAlphabetPattern = new RegExp(`${alphabetValidateRules}(?=.*[0-9])[a-zA-Z0-9]{1,}.+$`);

            const updatedSpecialChars = {
                ...passwordConfigApiData?.specialChars,
                format: passwordConfigApiData?.specialChars?.format || '~`!@#\\$%^&*\\(\\)_\\-+={\\[}\\]|\\\\:;"\'<,>.?/',
            };

            const passwordValidatePattern = new RegExp(
                `^${alphabetValidateRules}${
                    updatedSpecialChars?.mandatory && passwordConfigApiData?.newErrorFormat ? '(?=.*[' + updatedSpecialChars?.format + '])' : ''
                }(?=.*[0-9])[a-zA-Z0-9${updatedSpecialChars?.allow && passwordConfigApiData?.newErrorFormat ? updatedSpecialChars?.format : ''}]{1,}$`
            );

            const skipAlphanumericCheckPattern = new RegExp(`[a-zA-Z0-9${updatedSpecialChars?.allow ? updatedSpecialChars?.format : ''}]$`);

            const finalPasswordValidationPattern = passwordConfigApiData?.skipAlphanumericCheck ? skipAlphanumericCheckPattern : passwordValidatePattern;

            const passwordConfig = {
                ...passwordConfigApiData,
                minLength: passwordConfigApiData?.minLength || 6,
                maxLength: passwordConfigApiData?.maxLength || 12,
                specialChars: updatedSpecialChars,
                digitAndAlphabetPattern: digitAndAlphabetPattern,
                pwPattern: finalPasswordValidationPattern,
            };

            dispatch({ type: passwordConstants.FETCH_PROCESSED_PASSWORD_CONFIG, payload: passwordConfig });
        } catch (error) {
            console.error(error);
        }
    };
};
