import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import notification from 'root/utils/notification';
import { popupAction } from 'root/redux/action';
import { userAction } from 'root/redux/action/user.action';
import { portalAction } from 'root/redux/action/portal.action';
import { POPUP_VIEW_TYPE } from 'root/redux/constant/popup.constant';
import Text from 'root/components/atoms/text/text';
import SVGContact from 'root/components/svgIcon/svgContact';
import commonUtil from 'root/utils/common-util';
import styles from './telegram-button.module.scss';

import Modal from 'react-modal';

const TelegramButton = ({ breakLinePosition, action }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const screen = useSelector((state) => state?.screen);
    const portal = useSelector((state) => state?.portal);
    const language = useSelector((state) => state?.language);
    const history = useSelector((state) => state?.history);
    const user = useSelector((state) => state?.user);
    const popup = useSelector((state) => state?.popup);

    const fields = {
        captchaToken: '',
    };

    const [regTelegramFrame, setRegTelegramFrame] = useState(null);
    const [telegrameFrameIsLoaded, setTelegrameFrameIsLoaded] = useState(false);

    useEffect(() => {
        if (portal?.settings?.telegram) {
            let telegramFrameUrl = commonUtil.getByViewType(portal?.settings?.telegram?.telegramFrameUrl, { props: { screen: screen } });
            setRegTelegramFrame(telegramFrameUrl.register);
        }
    }, []);

    const telegram_loginAuth = (action) => {
        const telegramSettings = portal?.settings?.telegram;
        const identifier = telegramSettings.identifier;

        let msgParam = {
            identifier: identifier,
            msg: 'auth_login_telegram',
            lang: language.key,
            botFatherDomain: telegramSettings?.domain,
        };

        postMessageToTelegram(msgParam)
            .then((resp) => {
                tele_preRegister(resp, action, fields['captchaToken']);
            })
            .catch((errMsg) => {
                if (errMsg) {
                    notification.showNotification('error', t(errMsg));
                }
            });
    };

    const postMessageToTelegram = (param) => {
        let frame = document.getElementById('telegram-iframe');

        return new Promise(function (resolve) {
            const messageHandler = (event) => {
                if (event?.data) {
                    const { identifier, origin } = event.data;
                    const { botFatherDomain } = param;
                    if (identifier === param.identifier && origin === botFatherDomain) {
                        resolve(event.data);
                        window.removeEventListener('message', messageHandler);
                    }
                }
            };

            window.addEventListener('message', messageHandler);

            const targetOrigin = param?.botFatherDomain;
            frame.contentWindow.postMessage(param, targetOrigin);
        });
    };

    // action: ['register', 'login']
    const tele_preRegister = (dataFromIframe = {}, action, captchaToken) => {
        const apiParam = massage_tele_data(dataFromIframe, action, captchaToken);

        dispatch(portalAction.portalIsLoading(true));
        dispatch(popupAction.togglePopUp({ popupType: POPUP_VIEW_TYPE.TELEGRAM_GUIDELINE_POPUP }));

        tele_preRegister_library(apiParam);
    };

    const massage_tele_data = (dataFromIframe, action, captchaToken) => {
        const iframeUserData = dataFromIframe.user || {};
        const domain = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' ? window.tempDomain : window.location.hostname;

        // Keys get from Iframe's data
        const socialMediaLinkContentDTOKeys = {
            authDate: iframeUserData.auth_date,
            firstName: iframeUserData.first_name,
            hash: iframeUserData.hash,
            id: iframeUserData.id,
            lastName: iframeUserData.last_name || '',
            photoUrl: iframeUserData.photo_url || '',
            userName: iframeUserData.username || '',
        };

        // CashMarket API Param keys
        const apiParamKeys = {
            action: action,
            memberId: null,
            portalRedirect: null,
            provider: 'TELEGRAM',
        };

        const socialMediaRegisterDTOKeys = {
            affCode: window.SPL_Cookie.getCookie('AffCode'),
            isMobile: screen.isMobile,
            lang: language.key,
            requestUrl: window.location.protocol + '//' + domain,
            socialId: window.SPL_Cookie.getCookie('socialId'),
            captchaToken: captchaToken,
            merchantCode: window.merchantCode,
        };

        return {
            ...socialMediaLinkContentDTOKeys,
            ...apiParamKeys,
            ...socialMediaRegisterDTOKeys,
        };
    };

    const tele_preRegister_library = (apiParam) => {
        const telegramSettings = portal?.settings?.telegram;
        const isUseFallbackApi = !!portal?.settings?.telegram?.isUseFallbackApi;
        window.SPL_Telegram.preRegister(apiParam)
            .then(async (data) => {
                if (data) {
                    const { socialMediaProvider, socialMediaUserId, requestToken, login, password } = data;

                    if (socialMediaProvider && socialMediaUserId && requestToken) {
                        dispatch(popupAction.togglePopUp({ popupType: '' }));

                        try {
                            const isProceedStep2 = await intervalCheckForTelegramStatus(
                                socialMediaUserId,
                                telegramSettings?.intervalTimeToCheckStatus,
                                telegramSettings?.intervalAttemptCountToCheckStatus,
                                telegramSettings?.initialDelay,
                                isUseFallbackApi
                            );
                            if (isProceedStep2) {
                                const urlParam = `?socialMediaUserId=${socialMediaUserId}&socialMediaProvider=${socialMediaProvider}&requestToken=${requestToken}&isExternalRegister=true`;
                                navigate('/register/external-quick-register', true, urlParam);
                            }
                        } catch (err) {
                            if (!isUseFallbackApi) {
                                dispatch(portalAction.portalIsLoading(false));
                                onErrorTriggerNotification(err);
                            }
                        }
                    }

                    if (login && password) {
                        let returnData = {
                            login: login,
                            password: password,
                        };
                        memberLoginFunction(returnData);
                    }
                } else {
                    dispatch(popupAction.togglePopUp({ popupType: '' }));
                    dispatch(portalAction.portalIsLoading(false));
                }
            })
            .catch((err) => {
                dispatch(portalAction.portalIsLoading(false));
                dispatch(popupAction.togglePopUp({ popupType: '' }));
                onErrorTriggerNotification(err);
            });
    };

    const navigate = (link, isHref, urlParams = '') => {
        const countryLanguage = language.countryLanguageKey?.toLowerCase().replace('_', '-');
        const currentDomainLanguageKey = countryLanguage?.toLowerCase().replace('_', '-');
        let isHideLangPathUrl = portal?.settings?.features?.hideLangPathUrl;
        if (typeof isHideLangPathUrl === 'object') {
            isHideLangPathUrl = !!isHideLangPathUrl[currentDomainLanguageKey];
        }
        const massageLangPath = isHideLangPathUrl ? '' : '/' + currentDomainLanguageKey;
        const finalUrl = `${isHref ? massageLangPath : ''}${link + urlParams}`;

        isHref ? (window.location.href = finalUrl) : history.push(finalUrl);
    };

    const onErrorTriggerNotification = (err = {}) => {
        const { key, message } = err;
        
        // to update a general error message while don't have key translation and message
        const defaultErrMessage = message || t('register:register.telegramComponent.backendMessages.contactCustomerService');
        dispatch(popupAction.togglePopUp({ popupType: '' }));
        const showMessage = <Text textKey={`register:register.telegramComponent.backendMessages.${key}`}>{defaultErrMessage}</Text>;
        notification.showNotification('error', showMessage);
    };

    const memberLoginFunction = (data = {}) => {
        const password = data.password;
        let loginOri = data.login;
        let loginTrim = loginOri.trim();
        const loginObj = {
            domain: '',
            isAffiliate: window.isAffiliate ? window.isAffiliate : false,
            isAgent: window.isAgent ? window.isAgent : false,
            isVipLogin: false,
            language: language.key,
            merchantCode: window.merchantCode,
            login: loginTrim,
            password: password,
            rememberMe: fields.rememberMe ? fields.rememberMe : false,
            isPhoneLogin: false,
        };

        dispatch(userAction.login(loginObj)).then(() => {
            dispatch(userAction.isAuthenticated()).then(() => {
                if (!window.isAgent && !window.isAffiliate) {
                    if (window.SPL_LiteApp.isZT828LiteApp()) {
                        const { login, password, rememberMe } = loginObj;
                        const currencyData = user && user.account && user.account.currency;
                        window.SPL_LiteApp.onLoginSuccess(login, password, rememberMe, false, currencyData);
                    }
                }
                dispatch(popupAction.togglePopUp({ popupType: '' }));
                dispatch(portalAction.portalIsLoading(false));
                navigate('/myaccount/deposit', true);
            });
        });
    };

    /**
     * @param {string} socialMediaUserId -- id get from preRegister api
     * @param {number} intervalTime - interval time from telegramSettings (default 3000ms)
     * @param {number} intervalAttemptCount - total attempts count allow from telegramSettings (default 20)
     * @param {number} initialDelay -- initial time delay for user to share their contacts in telegram
     */
    function intervalCheckForTelegramStatus(socialMediaUserId, intervalTime = 3000, intervalAttemptCount = 20, initialDelay = 10000, isUseFallbackApi = false) {
        let attempts = intervalAttemptCount;
        let timeoutId;
        const params = {
            socialId: socialMediaUserId,
            merchantCode: window.merchantCode,
        };

        return new Promise((resolve, reject) => {
            const apiFunctionName = isUseFallbackApi ? 'statusCheck' : 'statusCheckV2';

            const checkStatus = async () => {
                try {
                    attempts--;
                    const data = await window.SPL_Telegram[apiFunctionName](params);
                    if (data) {
                        resolve(data);
                        clearTimeout(timeoutId);
                    } else if (attempts > 0) {
                        timeoutId = setTimeout(checkStatus, intervalTime); // Retry after the interval
                    } else {
                        reject({ data: { error: true, errorCode: 'TIME_OUT' } });
                    }
                } catch (err) {
                    if (err?.data?.error && err?.data?.errorCode) {
                        reject(err);
                        clearTimeout(timeoutId);
                    } else if (attempts > 0) {
                        timeoutId = setTimeout(checkStatus, intervalTime); // Retry after the interval
                    } else {
                        reject(err);
                        clearTimeout(timeoutId);
                    }
                }
            };

            timeoutId = setTimeout(checkStatus, initialDelay); // Start checking status after initialDelay
        });
    }

    return (
        <>
            {breakLinePosition === 'top' && <BreakLine action={action} />}
            <TelegramItem
                popup={popup}
                regTelegramFrame={regTelegramFrame}
                setTelegrameFrameIsLoaded={setTelegrameFrameIsLoaded}
                telegrameFrameIsLoaded={telegrameFrameIsLoaded}
                telegram_loginAuth={telegram_loginAuth}
                action={action}
            />
            {breakLinePosition !== 'top' && <BreakLine action={action} />}
        </>
    );
};

export default TelegramButton;

const TelegramItem = ({ popup, regTelegramFrame, setTelegrameFrameIsLoaded, telegrameFrameIsLoaded, telegram_loginAuth, action }) => {
    return (
        <>
            <TelegramModal isOpen={popup.popupType === POPUP_VIEW_TYPE.TELEGRAM_GUIDELINE_POPUP} />
            <div className={`${styles['telegram-container']} ${styles[action + '-telegram-container']} telegram-container`}>
                <iframe
                    id='telegram-iframe'
                    className='telegrame-iframe'
                    title='telegrame'
                    frameBorder='0'
                    src={regTelegramFrame}
                    style={{ display: 'none' }}
                    onLoad={() => {
                        setTelegrameFrameIsLoaded(true);
                    }}
                />

                {telegrameFrameIsLoaded && (
                    <button className={` ${styles['telegram-button']} telegram-button`} onClick={() => telegram_loginAuth(action)}>
                        <SVGContact className='register-telegram' name={'register-telegram'}></SVGContact>
                        <span>Telegram</span>
                    </button>
                )}
            </div>
        </>
    );
};

const TelegramModal = ({ isOpen }) => {
    const { t } = useTranslation();
    return (
        <>
            <Modal overlayClassName={`${styles.TelegramGuidelinePopupOverlay}`} className={`${styles['TelegramGuidelinePopupContainer']}`} isOpen={isOpen}>
                <div className='standard-popup-body'>
                    <span className={`${styles['TelegramContent']}`}>
                        {t('register:register.telegramGuidelinePopup.topConfirmToProceed', 'Please tap on "Confirm" on your Telegram app to proceed')}
                    </span>
                </div>
            </Modal>
        </>
    );
};

const BreakLine = ({ action }) => {
    const { t } = useTranslation();
    return (
        <div className={`${styles.breakLineContainer} ${styles[action + 'BreakLineContainer']}`}>
            <div className={`${styles.breakLine} ${styles[action + 'BreakLine']}`} />
            <span className={`${styles.breakLineSpan} ${styles[action + 'BreakLineSpan']}`}>{t('global:global:or', 'or')}</span>
            <div className={`${styles.breakLine} ${styles[action + 'BreakLine']}`} />
        </div>
    );
};
