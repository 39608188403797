import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Slider from 'root/webapp/shared/component/Slider';

import HomeMemberProfile from 'root/webapp/shared/component/wap/HomeMemberProfile';

import vm from '../vip.controller';

class Vip extends React.Component {
    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        setTimeout(() => {
            vm.loadVipLandingPage();
            vm.loadMemberGroupAutomationSummary();
            vm.getLevelMsg();
        }, 100);
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        const { table, landingTranslate } = this.state;
        return (
            <Translation>
                {(t) => (
                    <div className='vip-page'>
                        <HomeMemberProfile />

                        <div className='m-vip-panel'>
                            <LazyLoadImage src='/public/html/images/vip/mobile/vip-banner.png' />

                            <div className='vip-status'>
                                {/* <div className='status-img' style={{ backgroundImage: 'url(' + this.state.memberGroupImg + ')' }} /> */}

                                <div className='progress-status'>
                                    <div className='current-status'>
                                        <span className='status-title'>{t('vip:vip.currentStatus', 'Current Status')}</span>
                                        <div className='progress-bar'>
                                            <span style={{ width: this.state.progressBarPercentage }}></span>
                                        </div>
                                    </div>

                                    <div className='status-level'>
                                        {this.state.memberGroupLevel.map((level) => (
                                            <div key={level} className={'level-' + level.toLowerCase()}>
                                                <div className='memberGroupLevelArrowContainer'>
                                                    <hr width='1' size='7.5' className={'level-' + level.toLowerCase()}></hr>
                                                </div>
                                                {/* <div className='memberGroupLevelArrowContainer'>
                          <div className={'memberGroupLevelArrow level-' + level.toLowerCase()}></div>
                        </div> */}
                                                {this.state.memberGroupLevelMessages.map((msg) => (
                                                    <p data-level={level}>{msg[level + '-translate']}</p>
                                                ))}
                                                <LazyLoadImage src={vm.getLevelCard(level.toLowerCase())} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className='vip-table'>
                                <p className='type-title-overlap' dangerouslySetInnerHTML={{ __html: landingTranslate && landingTranslate.table.sub_title }}></p>

                                {this.state.list && this.state.list.length > 0 && (
                                    <Slider autoPlay={false} items={this.state.list} navigation={this.state.navigation} transionName='slider-fade' ignoreTouchEvent={true} />
                                )}
                                <div className='row-title-content'>
                                    {table.map((content, indexTable) => (
                                        <Fragment key={indexTable}>
                                            {content.title && (
                                                <p className='details-title ' dangerouslySetInnerHTML={{ __html: landingTranslate.table.category[content.title] }}></p>
                                            )}
                                            {content.data.map((row, indexRow) => (
                                                <div key={indexRow} className='details-content'>
                                                    {row.colTitle && <p dangerouslySetInnerHTML={{ __html: landingTranslate.table.col[row.colTitle] }}></p>}
                                                </div>
                                            ))}
                                        </Fragment>
                                    ))}
                                </div>
                            </div>

                            <LazyLoadImage className='vip-btm' src='/public/html/images/vip/vip-bottom.png' />
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation('vip')(withRouter(Vip)));
