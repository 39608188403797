import React, { useEffect, useState } from 'react';
import { getPasswordRules } from 'root/redux/action/password.action';
import { useDispatch } from 'react-redux';
import ImageHelper from 'root/webapp/shared/image-helper/image-helper';
import styles from './passwordInput.module.scss';
import Input from 'root/components/atoms/input/input';

const PasswordInput = ({ name, value, placeHolder, onChange, onFocus, useDynamicInputComp = false, type, ...props }) => {
    const [passwordReveal, setPasswordReveal] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPasswordRules());
    }, [dispatch]);

    const _inputProps = {
        ...props,
        type: passwordReveal ? 'text' : 'password',
        onChange, // Ensure onChange is included
        onFocus, // Ensure onFocus is included
    };

    return (
        <>
            {useDynamicInputComp ? (
                <Input name={name} value={value} type={passwordReveal ? 'text' : 'password'} onChange={onChange} {..._inputProps} />
            ) : (
                <input name={name} type={!passwordReveal ? 'password' : 'text'} placeholder={placeHolder} values={value} onChange={onChange} />
            )}
            <div
                className={`${styles.passwordRevealIconContainer} passwordRevealIconContainer ${styles?.[props.layout] || ''} ${props.hasLabel ? styles.hasLabel : ''} `}
                onClick={() => {
                    setPasswordReveal((prevPasswordReveal) => !prevPasswordReveal);
                }}
            >
                <ImageHelper
                    effect={null}
                    displayImg={passwordReveal ? '/public/html/images/passwordReveal.png' : '/public/html/images/notPasswordReveal.png'}
                    className={`${styles.revealPasswordIcon}`}
                />
            </div>
        </>
    );
};

export default PasswordInput;

export const validatePassword = (password, passwordRules, checkSpecialCharsError = false) => {
    let pwMin = passwordRules?.minLength || 6;
    let pwMax = passwordRules?.maxLength || 12;
    let newErrorFormat = passwordRules?.newErrorFormat || false;
    let skipAlphanumericCheck = passwordRules?.skipAlphanumericCheck || false;
    let uppercase = passwordRules?.uppercase || false;
    let specialChars = passwordRules?.specialChars || false;
    let specialCharsFormat = specialChars?.format || '~`!@#\\$%^&*\\(\\)_\\-+={\\[}\\]|\\\\:;"\'<,>.?/';
    let alphabet = uppercase ? '(?=.*[A-Z])(?=.*[a-z])' : '(?=.*[A-Za-z])';

    if (specialChars && !specialChars?.format) specialChars.format = specialCharsFormat;

    let newRegex = new RegExp(
        `^${alphabet}${specialChars?.mandatory && newErrorFormat ? '(?=.*[' + specialCharsFormat + '])' : ''}(?=.*[0-9])[a-zA-Z0-9${
            specialChars?.allow && newErrorFormat ? specialCharsFormat : ''
        }]{1,}$`
    );

    if (skipAlphanumericCheck) {
        newRegex = new RegExp(`[a-zA-Z0-9${specialChars?.allow ? specialCharsFormat : ''}]$`);
    }

    let pwRegex = !password.match(newRegex);

    let errMsg = [];
    if (password.length <= 0) {
        errMsg.push('global:global.form.newpassword-placeholderv2');
    } else {
        if (pwRegex) {
            errMsg.push('password:password.passguide.account');
        } else if (password.length > pwMax) {
            errMsg.push('global:global.messages.validate.newpassword.maxlength');
        } else if (password.length < pwMin) {
            errMsg.push('global:global.messages.validate.newpassword.minlength');
        }
    }

    if (passwordRules) {
        const passwordFormat = [
            {
                status: (!newErrorFormat && errMsg?.length > 0) || password.length > pwMax || password.length < pwMin ? false : true,
                minLength: pwMin,
                maxLength: pwMax,
                error: !newErrorFormat ? errMsg[0] : 'password:password.passguide.minMaxLength',
                newErrorFormat: newErrorFormat,
            },
        ];

        if (newErrorFormat) {
            if (!skipAlphanumericCheck) {
                // Check for alphabetic characters
                passwordFormat.push({
                    status: !password.match(new RegExp(`${alphabet}`)) ? false : true,
                    uppercase: uppercase,
                    error: uppercase ? 'password:password.passguide.alphabeticUppercase' : 'password:password.passguide.alphabetic',
                });

                // Check for numeric characters
                passwordFormat.push({
                    status: !password.match(new RegExp(`(?=.*[0-9])`)) ? false : true,
                    error: 'password:password.passguide.numeric',
                });
            }
            if (checkSpecialCharsError) {
                if ((specialChars?.allow && /[^a-zA-Z0-9]/g.test(password)) || specialChars?.mandatory) {
                    passwordFormat.push({
                        status: !password.match(new RegExp(`^${specialChars?.mandatory ? '(?=.*[' + specialCharsFormat + '])' : ''}[a-zA-Z0-9${specialCharsFormat}]{1,}$`))
                            ? false
                            : !password.match(/\s/),
                        specialCharsFormat: specialCharsFormat.replace(/\\/g, ''),
                        error: 'password:password.passguide.allowSpecialCharacter',
                    });
                } else if (/[^a-zA-Z0-9]/g.test(password)) {
                    passwordFormat.push({
                        status: !pwRegex,
                        specialCharsFormat: specialCharsFormat.replace(/\\/g, ''),
                        error: 'password:password.passguide.notAllowSpecialCharacter',
                    });
                }
            }
        }

        return passwordFormat;
    } else {
        return errMsg;
    }
};

export const onPasswordValidationChecking = (errors) => {
    let status = true;
    let checkErr = (errors || []).filter((params) => !params.status);

    if (checkErr.length > 0) {
        status = false;
    }

    return status;
};
