import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';

import { portalAction } from 'root/redux/action';

import Footer from 'root/webapp/layout/footer/component/mobile/Footer';
import FooterLiteApp from 'root/webapp/layout/footer/component/mobile/FooterLiteApp';
import vm from '../login.controller';
import core from 'root/utils/core';
import commonUtil from '../../../../utils/common-util';
import LangDisplay from 'root/webapp/shared/component/LangDisplay';
import TelegramButton from 'root/webapp/portal/telegram/telegram-button';
import Turnstile from 'react-turnstile';
class Login extends React.Component {
    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        vm.initCheckLiteAppLoginInfo();
        vm.initCloudflareSettings();
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    renderCommon(t) {
        const { isLiteAppLoginData, bioMode } = this.state;
        const telegramFrameUrl = this.props.portal?.settings?.telegram?.telegramFrameUrl;
        const enabledTurnstile = vm.enabledTurnstile();
        return (
            <Fragment>
                {telegramFrameUrl && <TelegramButton action={'login'} />}
                <form action=''>
                    <div className='input'>
                        <input
                            id='login'
                            name='login'
                            type='text'
                            value={this.state.fields['login']}
                            onChange={(e) => {
                                vm.handleFieldChanged(e, 'login');
                            }}
                            placeholder={t('global:global.form.username-placeholder', 'Username')}
                            onKeyPress={(e) => {
                                vm.handleKeyPress(e);
                            }}
                        ></input>
                    </div>
                    <div className='input'>
                        <input
                            id='password'
                            name='password'
                            type='password'
                            value={this.state.fields['password']}
                            onChange={(e) => {
                                vm.handleFieldChanged(e, 'password');
                            }}
                            placeholder={t('global:global.form.newpassword-placeholder', 'Password')}
                            onKeyPress={(e) => {
                                vm.handleKeyPress(e);
                            }}
                        ></input>
                    </div>

                    {isLiteAppLoginData && bioMode && (
                        <div className='bio-container'>
                            {bioMode === 'TouchID' ? (
                                <img src='/public/html/images/mobile/login/touch-icon.svg' alt='' onClick={vm.onBiometricClicked}></img>
                            ) : (
                                <img src='/public/html/images/mobile/login/face-icon.svg' alt='' onClick={vm.onBiometricClicked}></img>
                            )}
                        </div>
                    )}

                    <div className='remember-box'>
                        <div
                            className='remmer-event-box'
                            onClick={() => {
                                vm.toggleRememberMe();
                            }}
                        >
                            <div className={['icon-box', this.state.fields['rememberMe'] ? 'on' : ''].join(' ')}></div>
                            <span>{t('login:login.form.rememberme', 'Remember me')}</span>
                        </div>
                    </div>
                    {this.state.errorMsg && (
                        <div className='msg-error'>
                            <p className='m-error-content' dangerouslySetInnerHTML={{ __html: t(this.state.errorMsg) }}></p>
                        </div>
                    )}
                    {enabledTurnstile && (
                        <Turnstile
                            theme={'light'}
                            language={this.props?.language?.key || 'en'}
                            className='turnstile-wrapper'
                            sitekey={this.props?.systemFeatureReducer?.siteKey}
                            onVerify={(token) => {
                                vm.handleTurnStileToken(token);
                            }}
                            onError={() => {
                                vm.turnstileErrorHandling();
                            }}
                        />
                    )}
                    <span
                        className={`btn-next ${this.state.turnstileValidation && 'btn-next-disabled'}`}
                        onClick={() => {
                            vm.login();
                        }}
                        disabled={this.state.turnstileValidation}
                    >
                        {t('login:login.form.button', 'SIGN IN').toUpperCase()}
                    </span>
                    <div className='to-forget'>
                        <span
                            onClick={() => {
                                this.props.dispatch(portalAction.floatingPageUpdated(''));
                                this.props.dispatch(portalAction.floatingPageUpdated('reset'));
                            }}
                        >
                            {t('login:login.password.forgot', 'Forgot Password?')}
                        </span>
                    </div>
                </form>
                <div className='text-box'>
                    <p>
                        {t('global:global.login-popup.no-account-desc1', 'Do not have an account yet? Click ')}
                        <span
                            onClick={() => {
                                this.props.dispatch(portalAction.floatingPageUpdated(''));
                                this.props.dispatch(portalAction.floatingPageUpdated('register'));
                            }}
                        >
                            {t('global:global.login-popup.link-join-now', 'here')}
                        </span>
                        {t('global:global.login-popup.no-account-desc2', ' to register now!')}
                    </p>
                    <div className='line'></div>
                    <p>
                        {t('global:global.login-popup.login-issue-desc1', 'If you encounter any issues while loggin in,')}
                        <br />
                        {t('global:global.login-popup.login-issue-desc2', 'please contact our ')}
                        <span
                            onClick={() => {
                                core.openLivechat(this.props.language.countryLanguageKey, this.props.screen.viewType);
                            }}
                        >
                            {t('global:global.login-popup.login-issue-cs', 'Customer Service')}
                        </span>
                        {t('global:global.login-popup.login-issue-desc3', ' for further assistance.')}
                    </p>
                </div>
            </Fragment>
        );
    }

    renderContent() {
        return (
            <Fragment>
                <Translation>
                    {(t) => (
                        <div className='mobile-register mobile-login'>
                            <div className='bg-content-section'>
                                <div className='register-panel'>
                                    <div className={`register-box`}>
                                        <div className='title'>
                                            <i
                                                className='icon-close3'
                                                onClick={() => {
                                                    this.props.dispatch(portalAction.floatingPageUpdated(''));
                                                }}
                                            ></i>
                                        </div>

                                        <div className='content'>
                                            <div className='step-content login-content' data-index={0}>
                                                {
                                                    <div className='step-box'>
                                                        {/* <i className='icon-logo2'></i> */}
                                                        {this.renderCommon(t)}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='footer-section'>
                                <Footer />
                            </div>
                        </div>
                    )}
                </Translation>
            </Fragment>
        );
    }

    renderLiteAppContent() {
        const { language, history } = this.props;
        const { currentLanguage, languageList } = this.state;
        const { getLanguageList, updateCurrentLanguage, changeLanguage, onLanguageDisplayClick } = vm;
        let langSource = {
            countryLanguage: language,
            currentLanguage: currentLanguage,
            languageList: languageList,
            getLanguageList: getLanguageList,
            updateCurrentLanguage: updateCurrentLanguage,
            changeLanguage: changeLanguage,
            onLanguageDisplayClick,
            history,
        };

        return (
            <Fragment>
                <Translation>
                    {(t) => (
                        <div className='mobile-register mobile-login-liteapp'>
                            <div className='bg-content-section'>
                                <div className='register-panel'>
                                    <div className={`register-box-liteapp`}>
                                        <div className='title'>
                                            <Fragment>
                                                <img
                                                    src='/public/html/images/mobile/back.png'
                                                    alt=''
                                                    className='back-icon'
                                                    onClick={() => {
                                                        this.props.dispatch(portalAction.floatingPageUpdated(''));
                                                    }}
                                                ></img>
                                                <img src='/public/html/images/mobile/login/header-me88-logo.svg' alt='' className='login-logo'></img>
                                                <LangDisplay {...langSource} />
                                            </Fragment>
                                        </div>

                                        <div className='content'>
                                            <div className='step-content login-content' data-index={0}>
                                                {
                                                    <div className='step-box'>
                                                        <div className='login-header'>
                                                            <div className='login-title'>{t('login:login.liteapp.title')}</div>
                                                            <div className='login-title'>{t('login:login.liteapp.title02')}</div>
                                                            <div className='login-desc'>{t('login:login.liteapp.desc')}</div>
                                                        </div>
                                                        {this.renderCommon(t)}
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                        <FooterLiteApp />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Translation>
            </Fragment>
        );
    }

    render() {
        if (commonUtil.getIsZT828LiteApp()) {
            return this.renderLiteAppContent();
        } else {
            return this.renderContent();
        }
    }
}

export default connect(mapStateToProps, null)(withTranslation(['register', 'global', 'login'])(withRouter(Login)));
