import { walletConstant } from '../constant';
export const walletAction = {
    udpateLoadWallet,
    updateWallet,
    updateProviderWallets,
};

function udpateLoadWallet(loadWallet) {
    return (dispatch) => {
        dispatch({ type: walletConstant.UDPATE_LOAD_WALLET, payload: { loadWallet } });
    };
}

function updateWallet(mainWallet) {
    return (dispatch) => {
        dispatch({ type: walletConstant.UPDATE_WALLET, payload: { mainWallet } });
    };
}

function updateProviderWallets(providerWallets) {
    return (dispatch) => {
        dispatch({ type: walletConstant.UPDATE_PROVIDER_WALLETS, payload: { providerWallets } });
    };
}
