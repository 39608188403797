import React, { Fragment } from 'react';
import { Translation, withTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { mapStateToProps } from 'root/redux/selector/selector';

class AnnouncementPopup extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
        this.state = {
            popupVersion: this.props.popupVersion,
            announcementList: this.props.announcementList,
            fields: {
                dontShowAgain: false,
            },
        };
    }

    componentDidUpdate(prevProps) {
        // if (prevProps !== this.props) {
        //   this.setState({
        //     popupVersion: this.props.popupVersion,
        //     announcementList: this.props.announcementList
        //   });
        // }
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    handleFieldChanged(field) {
        let fields = this.state.fields;
        fields[field] = !fields[field];
        this.setState({ fields });
    }

    handleCloseBtn() {
        const { fields, popupVersion, announcementList } = this.state;
        const { countryLanguageKey } = this.props.language;

        if (popupVersion === 1) {
            if (fields.dontShowAgain) {
                window.SPL_Content.setHideAnnouncement(announcementList);
            }
        } else if (popupVersion === 2) {
            let announcement = announcementList[0];
            if (fields?.dontShowAgain) {
                announcement.frequencyType = 'once';
            }
            window.SPL_Content.setHideImgAnnouncement(announcement.id, announcement.frequencyType, announcement.frequency, countryLanguageKey);
        }

        this.props.closePopupAnnouncement();
    }

    onDontShowAgainClick = (e) => {
        e.stopPropagation();
        this.handleFieldChanged('dontShowAgain');
        if (e.target.tagName.toLowerCase() !== 'input') {
            // Check if the clicked element is not an input element (checkbox)
            if (this.state.announcementList[0] && this.state.announcementList[0].href) {
                window.open(this.state.announcementList[0].href, '_blank');
            }
        }
    };
    render() {
        return (
            <Translation>
                {(t) => (
                    <div
                        className='announcement-box'
                        onClick={() => {
                            this.handleCloseBtn();
                        }}
                    >
                        {/* popup announcement version 1 handling text announcement only */}
                        {this.state.popupVersion === 1 && (
                            <div
                                className='announcement-popup'
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                <p className='announcement-title'>{t('global:global.announcement2', 'Announcement')}</p>

                                <ul className='announcement-content'>
                                    {this.state.announcementList.map((announcement, index) => (
                                        <li key={index} dangerouslySetInnerHTML={{ __html: announcement.contentLanguage }}></li>
                                    ))}
                                </ul>

                                <div className='btn-item'>
                                    <div className='dont-show-again'>
                                        <input type='checkbox' value={this.state.fields['dontShowAgain']} onChange={(e) => this.handleFieldChanged('dontShowAgain')} />
                                        <span class="checkmark"></span>
                                        <span>{t('global:global.dontshowagain', `Don't show again`)}</span>
                                    </div>
                                    <span
                                        className='btn-close'
                                        onClick={() => {
                                            this.handleCloseBtn();
                                        }}
                                    >
                                        {t('global:global.verification.close', 'Close')}
                                    </span>
                                </div>
                            </div>
                        )}

                        {/* popup announcement version 2 handling image announcement */}
                        {this.state.popupVersion === 2 && (
                            <div className='announcement-img-popup'>
                                <i className='icon-close2' onClick={() => this.handleCloseBtn()}></i>
                                <LazyLoadComponent>
                                    <picture>
                                        <source srcSet={this.state.announcementList[0].webPUrl} />
                                        <img
                                            className='announcement-img'
                                            src={this.state.announcementList[0].src}
                                            alt='popup-img'
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (this.state.announcementList[0] && this.state.announcementList[0].href) {
                                                    window.open(this.state.announcementList[0].href, '_blank');
                                                }
                                            }}
                                        />
                                    </picture>
                                </LazyLoadComponent>

                                <div className='btn-item'>
                                    <div className='dont-show-again'>
                                        <input
                                            type='checkbox'
                                            value={this.state.fields['dontShowAgain']}
                                            onClick={(e) => {
                                                this.onDontShowAgainClick(e);
                                            }}
                                        />
                                        <span class="checkmark"></span>
                                        <span>{t('global:global.dontshowagain', `Don't show again`)}</span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation('global')(AnnouncementPopup));
