import React, { Fragment } from 'react';
import HomeMemberProfile from 'root/webapp/shared/component/wap/HomeMemberProfile';
import WebPromos from 'root/webapp/portal/promos/desktop/PromosPanel';
import MFooter from 'root/webapp/layout/footer/component/mobile/Footer';

class Promos extends React.Component {
    render() {
        return (
            <Fragment>
                <div className='promos-page'>
                    <HomeMemberProfile />
                    <div className='promos-box-container'>
                        <WebPromos />
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Promos;
