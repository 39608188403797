import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';

// core/logic/util
import { regionAction } from '@redux/action';

export const useGetLiveChatUrl = () => {
    // redux
    const language = useSelector((state) => state?.language);
    const screen = useSelector((state) => state?.screen);

    // state
    const [liveChatUrl, setLiveChatUrl] = useState(null);

    const getContact = async () => {
        let countryLang = language.countryLanguageKey.replace('_', '-').toLowerCase();
        try {
            const data = await window.SPL_Content.getContact(countryLang, screen.viewType);
            const _liveChatUrl = (data || []).find((contact) => contact.name === 'LiveChat');
            setLiveChatUrl(_liveChatUrl?.url);
        } catch (err) {
            console.error(err);
        }
    };

    const openLiveChat = () => {
        if (liveChatUrl) {
            window.open(liveChatUrl, '', 'width=500,height=700,toolbar=no,location=no,directories=no,status=yes,menubar=no,scrollbars=no');
        }
    };

    useEffect(() => {
        getContact();
    }, []);

    return {
        liveChatUrl,
        openLiveChat,
    };
};

export const useGetCountryRegions = () => {
    // redux
    const dispatch = useDispatch();
    const portal = useSelector((state) => state.portal);
    const regionReducer = useSelector((state) => state.regionReducer);
    const internationalFeature = portal?.settings?.registerConfiguration?.internationalFeature || {};

    useEffect(() => {
        preFetchRegionsRawData();
    }, []);
    useEffect(() => {
        if (regionReducer?.rawData?.countryJson) {
            dispatch(regionAction.fetchProcessedRegions());
            return;
        }

        if (regionReducer?.rawData?.regionSettingsJson) {
            dispatch(regionAction.fetchProcessedInternationalRegions());
            return;
        }
    }, [regionReducer?.rawData?.countryJson, regionReducer?.rawData?.regionSettingsJson]);

    const preFetchRegionsRawData = () => {
        try {
            if (internationalFeature?.supportedRegion) {
                dispatch(regionAction.fetchRegionSettingsJson());
            } else {
                dispatch(regionAction.fetchCountryJson());
            }
        } catch (err) {
            console.error(err);
        }
    };

    return {
        internationalRegions: regionReducer?.processedData?.internationalRegions,
        regions: regionReducer?.processedData?.regions,
    };
};

export const useCommonHrefRedirect = () => {
    const history = useHistory();

    const commonHrefRedirect = (url, noRedirectIfSameRoute) => {
        if (noRedirectIfSameRoute && window.location.pathname.includes(url)) {
            return;
        }

        const check = url.charAt(0);
        const checkHttp = url.substring(0, 4);

        if (check !== '/' && checkHttp !== 'http') {
            history.push('/' + url);
        } else if (check === '/' && checkHttp !== 'http') {
            history.push(url);
        } else if (check !== '/' && checkHttp === 'http') {
            window.open(url, '_blank');
        }
    };

    return { commonHrefRedirect };
};

export const useGetCurrentTranslation = (category) => {
    const [translationData, setTranslationData] = useState(null);
    useEffect(() => {
        const getCurrentTranslation = async () => {
            if (!category) {
                return;
            }

            window.SPL_JsonSettings.getTranslationTransactionJson(category, (data) => {
                setTranslationData(data);
            });
        };
        getCurrentTranslation();
    }, []);

    return {
        translationData,
    };
};
