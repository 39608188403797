import moment from 'moment';
import React, { lazy } from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import SVGElements from 'root/components/SVGElements';
import { walletAction } from 'root/redux/action/wallet.action';
import { mapStateToProps } from 'root/redux/selector/selector';
import Notice from 'root/webapp/shared/component/Notice';
import SmallLoading from 'root/webapp/shared/loading/smallLoading';
import vm from '../settings.controller';
import MemberProfile from './MemberProfile';
import commonUtil from 'root/utils/common-util';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import UserVerifications from '@components/organisms/user-verifications/user-verifications';
import { gu_filterCommonKey } from '@utils/general-util';
import { MODULE_TYPE as MT } from '@utils/constants';
import AuthSettings from '@components/molecules/auth-settings/auth-settings';

class SettingLanding extends React.Component {
    isMount = false;

    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        this.isMount = true;
        const { user } = this.props;
        this.setState({
            username: user.account.login,
            memberGroup: user.account.groupName,
            memberGroupIcon: user.account.imagePath,
            currency: user.account.currency,
            registerDate: user.account.createdDate ? moment(user.account.createdDate).format('YYYY-MM-DD HH:mm').toString() : '',
            memberGroupImg: '',
            memberGroupColor: '',
        });
        vm.chkUseLanding();

        vm.highlightModule();
        vm.refreshWallet();
        vm.getUserSeamlessStatus();
        vm.getWithdrawalLimit();
        vm.getMemberGroupLevel();
        vm.getAdditionalMenu();
        vm.initCompSettings();

        setTimeout(() => {
            commonUtil.loadMemberGroupAutomationSummary(this, user);
        }, 100);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.portal.pathname !== this.props.portal.pathname) {
            vm.highlightModule();
        }

        if (prevProps.wallet.loadWallet !== this.props.wallet.loadWallet) {
            if (this.props.wallet.loadWallet) {
                vm.refreshWallet();
                setTimeout(() => {
                    this.props.dispatch(walletAction.udpateLoadWallet(false));
                }, 500);
            }
        }
    }

    componentWillUnmount() {
        this.isMount = false;
        this.setState = () => {
            return;
        };
    }

    renderSelectedTab() {
        if (this.isMount) {
            const { accountMenu, profileMenu } = this.state;
            let pathname = this.props.location.pathname;
            for (let i = 0; i < accountMenu.length; i++) {
                if (accountMenu[i].path === pathname && accountMenu[i].component) {
                    const Tab = accountMenu[i].component;
                    return <Tab></Tab>;
                }
            }

            for (let i = 0; i < profileMenu.length; i++) {
                if (profileMenu[i].path === pathname && profileMenu[i].component) {
                    const Tab = profileMenu[i].component;
                    return <Tab></Tab>;
                }
            }
            return <div></div>;
        } else {
            return <div></div>;
        }
    }

    render() {
        const { promotionReducer, portal } = this.props;
        const { memberGroupColor, memberGroupImg } = this.state;
        const { promotionData } = promotionReducer;
        let langKey = this.props.language.key;

        let userCurrency = this.props.user && this.props.user.account && this.props.user.account.currency ? this.props.user.account.currency : null;
        const _isEnableVerifications = Object.keys(gu_filterCommonKey(portal?.settings?.features?.settingsConfiguration?.memberVerificationSettings) || [])?.length > 0;

        if (userCurrency === 'KHUSD') userCurrency = 'USD';

        const sortedMenu = this.state.profileMenu?.sort((a, b) => parseInt(a.id) - parseInt(b.id));

        return (
            <Translation>
                {(t) => (
                    <div className='member-panel'>
                        <AuthSettings module={MT?.BIND_AUTHENTICATOR} isPublic={false} />
                        <AuthSettings module={MT?.SIX_PIN} isPublic={false} />

                        <Notice />
                        <div className='container'>
                            <div className='member-com-page'>
                                <div className='member-nav'>
                                    <MemberProfile />
                                    <div className='nav-item'>
                                        <span>{t('global:global.sidebar.account.banking', 'BANKING')}</span>
                                        <ul>
                                            {this.state.accountMenu.map((menu, i) => {
                                                return menu.showMenu ? (
                                                    <li key={i} className={menu.selected ? 'on' : ''}>
                                                        <a
                                                            onClick={() => {
                                                                vm.moduleClicked(menu.path);
                                                            }}
                                                        >
                                                            <span>{t(menu.text)}</span>
                                                            {menu?.name === 'specialForYou' && promotionData?.showPromotionNewTag && (
                                                                <LazyLoadImage src={`/public/html/images/promotion/navbar-new-tag.svg`} />
                                                            )}
                                                        </a>
                                                    </li>
                                                ) : null;
                                            })}
                                        </ul>
                                    </div>
                                    <div className='nav-item'>
                                        <span>{t('global:global.sidebar.account.heading', 'USER PROFILE')}</span>
                                        <ul>
                                            {(sortedMenu || []).map((menu, i) => {
                                                return menu.showMenu ? (
                                                    <li key={i} className={menu.selected ? 'on' : ''}>
                                                        <a
                                                            onClick={() => {
                                                                vm.moduleClicked(menu.path);
                                                            }}
                                                        >
                                                            <span>{t(menu.text)}</span>
                                                        </a>
                                                    </li>
                                                ) : null;
                                            })}
                                        </ul>
                                    </div>
                                    {_isEnableVerifications && <UserVerifications />}
                                </div>
                                <div className='member-main'>
                                    <div className={'member-info ' + langKey}>
                                        <ul>
                                            <li>
                                                <div>
                                                    {this.state.memberGroupIcon && this.state.memberGroupIcon !== '' ? (
                                                        <i className='icon-level' style={{ backgroundImage: `url("${this.state.memberGroupIcon}")` }}></i>
                                                    ) : (
                                                        <i className='icon-level' style={{ backgroundImage: `url("${memberGroupImg}")` }}></i>
                                                    )}
                                                </div>
                                                <div>
                                                    <div className='title'>{t('settings:settings.memberGroup', 'Member Level')}</div>
                                                    <div className='desc' style={{ color: memberGroupColor }}>
                                                        {this.state.memberGroup}
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div>
                                                    <div className='title'>{t('settings:settings.amountRequireUpgrade', 'Amount Required for Upgrade')}</div>
                                                    <div className='desc' style={{ color: memberGroupColor }}>
                                                        {this.state.memberGroup === 'EMERALD'
                                                            ? t('settings:settings.invitation', 'Invitation')
                                                            : `${userCurrency} ${window.SPL_Other.formatAmount(this.state.requireDepositToUpgrade)}`}
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div>
                                                    <div className='title'>{t('settings:settings.withdrawLimit', 'Withdrawal Limit')}</div>
                                                    <div className='desc' style={{ color: memberGroupColor }}>
                                                        {userCurrency} {t(this.state.maxAmountDisplay)}
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div>
                                                    <div className='title'>{t('settings:settings.registrationDate', 'Registration Date')}</div>
                                                    <div className='desc' style={{ color: memberGroupColor }}>
                                                        {this.state.registerDate} &nbsp;
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='member-wallet'>
                                        <div className='group'>
                                            <span className='title'>{t('settings:settings.mainwallet', 'Main Wallet')}</span>
                                            <div className='reload'>
                                                <span>{userCurrency}</span>
                                                {this.state.mainWallet !== null ? (
                                                    <span className='count'>{window.SPL_Other.formatAmount(this.state.mainWallet.balance)}</span>
                                                ) : (
                                                    <SmallLoading></SmallLoading>
                                                )}
                                                <CSSTransition classNames='rotate360' in={this.state.mainWalletRefreshing} timeout={1000}>
                                                    <SVGElements
                                                        className='icon-reload'
                                                        name='reload-icon'
                                                        onClick={() => {
                                                            vm.refreshWallet();
                                                        }}
                                                    />
                                                    {/* <i className="icon-reload" onClick={() => { vm.refreshWallet(); }}></i> */}
                                                </CSSTransition>
                                                <div
                                                    className='restore-box'
                                                    onClick={() => {
                                                        vm.restoreWallet();
                                                    }}
                                                >
                                                    <SVGElements className='icon-restore' name='restore-icon' />
                                                    {/* <i className="icon-restore"></i> */}
                                                    <span className='restore-wallet'>{t('settings:settings.transferwallet', 'Restore Wallet')}</span>
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.hasFakeSeamless && (
                                            <div className='group transfer-group'>
                                                <span className='title'>{t('global:global.menu.account.auto', 'Auto Transfer')}</span>
                                                <i
                                                    className={['icon-transfer', this.state.disabledSeamless ? '' : 'on'].join(' ')}
                                                    onClick={() => {
                                                        vm.updateUserSeamless();
                                                    }}
                                                ></i>
                                            </div>
                                        )}
                                    </div>

                                    <TransitionGroup className='member-page'>
                                        <CSSTransition key={window.location.pathname} timeout={300} classNames='page'>
                                            {this.renderSelectedTab()}
                                        </CSSTransition>
                                    </TransitionGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['settings', 'transaction', 'promotion', 'popupTransfer'])(withRouter(SettingLanding)));
