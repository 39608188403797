import notification from 'root/utils/notification';
import { PAYMENT_GATEWAY_SETTING, OTP_VERIFICATION_TYPE as OVT, MODULE_TYPE as MT, POPUP_TYPE } from '@constants';
import commonUtil from './common-util';
import { gu_massageIndex } from './general-util';
import { popupAction, portalAction, authSettingsAction } from '@redux/action';
import isEmpty from 'lodash/isEmpty';

const transactionHelper = {
    handleFieldChanged(vm, e, field) {
        let fields = vm.state.fields;
        fields[field] = gu_massageIndex(e);
        vm.setState({ fields }, () => {
            transactionHelper.handleValidation(vm);
        });
    },

    handleValidation(vm, bankType = '') {
        const { depositMinMaxLimit, depositAllSettings, memberBankList } = vm.state;
        const hideSenderBank = depositAllSettings?.hideSenderBank;
        let fields = vm.state.fields;
        let errors = {};
        let isFormValid = true;
        let amountErrorValue = 0;
        if (!fields['amount']) {
            isFormValid = false;
            errors['amount'] = 'transaction:transaction.deposit.onlinetransfer.invalidAmount';
        }

        if (typeof fields['amount'] !== undefined) {
            let amount = Number(fields['amount']);
            if (amount >= 0) {
                const minVal = depositMinMaxLimit ? depositMinMaxLimit.min : 0;
                const maxVal = depositMinMaxLimit ? depositMinMaxLimit.max : 0;
                if (amount < minVal) {
                    isFormValid = false;
                    errors['amount'] = 'transaction:transaction.deposit.onlinetransfer.invalidMinAmount';
                    amountErrorValue = minVal;
                } else if (amount > maxVal) {
                    isFormValid = false;
                    errors['amount'] = 'transaction:transaction.deposit.onlinetransfer.invalidMaxAmount';
                    amountErrorValue = maxVal;
                }
            } else {
                isFormValid = false;
                errors['amount'] = 'transaction:transaction.deposit.onlinetransfer.invalidAmount';
            }
        }

        if(!hideSenderBank && isEmpty(memberBankList) && ['B', 'RQR'].includes(bankType)) {
            isFormValid = false;
            errors['memberBankList'] = 'transaction:transaction.deposit.cashdeposit.promptAddBankAcc';
        }

        vm.setState({
            errors: errors,
            amountErrorValue,
            isFormValid: isFormValid,
        });
    },

    onDepositAmountClicked(vm, item) {
        const { enableAccValueButton } = vm.props.portal?.settings?.features?.depositPagesSettings;
        const fields = { ...vm.state.fields };
        fields['amount'] = enableAccValueButton ? transactionHelper.sumUp(fields['amount'], item) : item;

        vm.setState({ fields }, () => {
            transactionHelper.handleValidation(vm);
        });
    },

    sumUp(currentValue, value) {
        const cl = currentValue ? parseFloat(currentValue) : 0;
        const vl = value ? parseFloat(value) : 0;

        let result = cl + vl;

        result = result.toString();

        return result;
    },

    clearInputFieldAmount(vm) {
        vm.setState({ fields: { amount: '' } });
    },

    openAddBankDialog(vm) {
        vm.setState({ addBankDialogIsShow: true });
    },

    handleReceiptValidation(vm) {
        if (vm && vm.state) {
            const { showOnlineBankDetails, depositAllSettings, depositOptions, bankList, selectedMemberBankIndex } = vm.state;
            const { receiptImg, selectedChannelIndex } = vm.state;
            let isReceiptValid = true;
            if (depositOptions && depositOptions.length > 0 && selectedChannelIndex !== undefined && selectedChannelIndex !== null) {
                let channel = depositOptions[selectedChannelIndex];
                let selectedBankObj = null;

                if ((showOnlineBankDetails || depositAllSettings.showOnlineBankingOnly) && bankList.length > 0) {
                    selectedBankObj = bankList[selectedMemberBankIndex];
                }

                if (
                    (channel.value === 'D' ||
                        channel.value === 'A' ||
                        (channel.value === 'O' && depositAllSettings.requiredReceiptForOnlineBanking) ||
                        (depositAllSettings.receiptMandatoryBankCodes &&
                            selectedBankObj &&
                            depositAllSettings.receiptMandatoryBankCodes.indexOf(selectedBankObj.bankCode) !== -1)) &&
                    receiptImg.length === 0
                ) {
                    isReceiptValid = false;
                    vm.setState({ receiptRequired: true, isReceiptValid: isReceiptValid });
                } else {
                    isReceiptValid = true;
                    vm.setState({ receiptRequired: false, isReceiptValid: isReceiptValid });
                }
            }

            return isReceiptValid;
        }
    },

    handleReceiptImageChange(e, vm) {
        if (e && e.target && e.target.files && e.target.files.length > 0) {
            vm.setState(
                {
                    receiptImg: URL.createObjectURL(e.target.files[0]),
                    receiptImgFile: e.target.files[0],
                },
                () => {
                    transactionHelper.handleReceiptValidation(vm);
                }
            );
        }
    },

    handleUploadReceiptButtonOnClick() {
        let hiddenInput = document.getElementById('hiddenFileInput');
        if (hiddenInput) {
            hiddenInput.click();
        }
    },
    handleClearUploadedReceiptButtonOnClick(vm) {
        vm.setState({
            receiptImg: [],
            receiptImgFile: null,
            isReceiptValid: false,
        });
    },

    resetForm(vm) {
        vm.setState(
            {
                fields: {
                    amount: '',
                    reference: '',
                },
                errors: {},
                receiptRequired: false,
                receiptImg: [],
                receiptImgFile: null,
                isReceiptValid: false,
            },
            () => {
                transactionHelper.handleValidation(vm);
            }
        );
    },

    loadMemberBankAccounts(vm) {
        let loadAllOwnedAndUnOwnedBank = new Promise((resolve) => {
            window.SPL_Transaction.loadAllOwnedAndUnOwnedBank().then((data) => resolve(data));
        });
        let loadGetVerifiedBankAccountsByType = new Promise((resolve) => {
            window.SPL_Member.getVerifiedBankAccountsByType().then((value) => resolve(value));
        });

        return new Promise(function (resolve) {
            Promise.all([loadAllOwnedAndUnOwnedBank, loadGetVerifiedBankAccountsByType]).then((result) => {
                let ownedBankList = result[0];
                let verifyMemberBank = result[1];

                let memberBankList = ownedBankList.memberBankList;
                let bankList = ownedBankList.bankList;
                let verifyBank = [];
                for (let i = 0; i < memberBankList.length; i++) {
                    for (let j = 0; j < bankList.length; j++) {
                        const memberBank = memberBankList[i];
                        const bankListName = bankList[i];
                        if (bankListName) {
                            memberBank.displayName = `${memberBank.bankName} ${memberBank.accountNumber} ${memberBank.accountHolder}`;
                        }
                    }
                }

                for (let j = 0; j < verifyMemberBank.length; j++) {
                    if (verifyMemberBank[j] && verifyMemberBank[j].verified) {
                        verifyBank.push(verifyMemberBank[j]);
                    }
                }

                vm.setState({
                    memberBankList: ownedBankList.memberBankList,
                    unOwnedBankList: ownedBankList.unOwnedBankList,
                    verifyBank: verifyBank,
                });
                resolve();
            });
        });
    },

    handleAddBankChange(vm, e, field) {
        let accountBankfields = vm.state.accountBankfields;
        let val = gu_massageIndex(e);

        accountBankfields[field] = val;
        vm.setState({ accountBankfields }, () => {
            if (field === 'bankName') {
                transactionHelper.validateBankName(vm);
            }
            if (field === 'accountNumber') {
                if (transactionHelper.isNumber(val)) {
                    vm.setState({ errors: { accountNumber: '' } });
                } else {
                    vm.setState({ errors: { accountNumber: 'transaction:transaction.deposit.addBank.accountNumberError' } });
                }
            }
        });
    },

    // bank-transfer,banks-apps
    onBankChanged(vm, bankObj, onChangeValue, transactionOption) {
        const { selectedBankIndex, bankList } = vm.state;
        const { paymentGatewaySetting } = vm.props;
        const { amountFieldNotRequired } = PAYMENT_GATEWAY_SETTING;

        const getStateData = (index) => {
            const bank = bankList[index];
            const showAmountField = transactionHelper.getPaymentGatewaySettingBool(paymentGatewaySetting, amountFieldNotRequired, bank);
            const defaultIsFormValid = !showAmountField;

            return { showAmountField, defaultIsFormValid };
        };

        if (onChangeValue !== undefined) {
            const { showAmountField, defaultIsFormValid } = getStateData(onChangeValue);
            vm.setState({ selectedBankIndex: onChangeValue, showAmountField, isFormValid: defaultIsFormValid }, () => {
                transactionHelper.checkCertainBankRequiredReceipt(vm);
            });
        } else {
            let foundIndex = bankList.indexOf(bankObj);
            if (foundIndex !== selectedBankIndex) {
                const { showAmountField, defaultIsFormValid } = getStateData(foundIndex);
                vm.setState({ selectedBankIndex: foundIndex, showAmountField, isFormValid: defaultIsFormValid }, () => {
                    transactionHelper.checkCertainBankRequiredReceipt(vm);
                });
            }
        }
        commonUtil.getDepositMinMax(vm, transactionOption, vm.props.user.account.currency, bankObj.id);
    },

    // bank-transfer,bank-apps
    checkCertainBankRequiredReceipt(vm) {
        const { depositAllSettings, bankList, selectedBankIndex, memberBankList, selectedMemberBankIndex } = vm.state;
        const currency = vm.props.user.account.currency;
        let requiredReceiptForCertainOnlineBanking = depositAllSettings && depositAllSettings.requiredReceiptForCertainOnlineBanking;
        let bankSelected = {};
        let memberBankSelected = {};

        if (bankList && bankList.length > 0) {
            bankSelected = bankList[selectedBankIndex].code;
        }

        if (memberBankList && memberBankList.length > 0) {
            if (memberBankList[selectedMemberBankIndex]) {
                memberBankSelected = memberBankList[selectedMemberBankIndex].bankCode.toUpperCase();
            }
        }

        if (requiredReceiptForCertainOnlineBanking) {
            window.SPL_Transaction.checkCertainBankRequiredReceipt(
                currency,
                requiredReceiptForCertainOnlineBanking,
                bankSelected,
                memberBankSelected,
                transactionHelper.handleReceiptValidation,
                vm
            ).then((res) => {
                vm.setState({ receiptRequired: res });
            });
        }
    },

    // bank-transfer,bank-apps
    handleBankDetailChange(vm, e) {
        vm.setState({ selectedMemberBankIndex: gu_massageIndex(e), selectedBankDetailIndex: gu_massageIndex(e) }, () => {
            transactionHelper.isValidForm(vm);
            transactionHelper.handleValidation(vm);
            transactionHelper.checkCertainBankRequiredReceipt(vm);
        });
    },

    handdleAddAccountBankSubmit(vm) {
        const { t, dispatch, authSettingsReducer, popupReducer } = vm.props;
        const bankDetailBankAuthSettings = authSettingsReducer?.processedData?.[MT.BANK_DETAIL_BANK];

        const { accountBankfields, receiptImgFile } = vm.state;
        let selectedBankIndex = accountBankfields['selectedBankIndex'];
        let accountName = accountBankfields['accountName'];
        let accountNumber = accountBankfields['accountNumber'];
        let bankName = accountBankfields['bankName'];
        let bankBranch = accountBankfields['bankBranch'];
        let bankObj = vm.props.unOwnedBankList && vm.props.unOwnedBankList[selectedBankIndex];
        vm.setState({ statusMsg: '' });

        dispatch(portalAction.setIsSubmitting(true));
        window.SPL_Member.addBankAcc(accountName, accountNumber, bankBranch, bankObj.bankId, bankName, receiptImgFile, popupReducer?.enteredPin, OVT.SIX_PIN)
            .then((data) => {
                if (data.status === 'S') {
                    if (vm.props.addBankSuccess) {
                        vm.props.addBankSuccess && vm.props.addBankSuccess();
                    } else {
                        transactionHelper.close(vm, 'addBank');
                    }
                    if (vm.props.dispatch) {
                        vm.props.dispatch(popupAction.setPopupVisible(false));
                    }

                    if (bankDetailBankAuthSettings?.sixPinEnabled) {
                        dispatch(popupAction.setPopup(POPUP_TYPE.PIN_ENTRY, false));
                        dispatch(popupAction.setEnteredPin(''));
                    }
                    // transactionHelper.close(vm, 'addBank');
                } else {
                    if (data.errorCode === 2717) {
                        dispatch(authSettingsAction.setSixPinLocked()); // this will trigger affiliate-hook setModuleSixPinLocked
                    }

                    if (data.detail.toLowerCase() === 'bank account already in use') {
                        notification.showNotification('error', t('global:global.verification.duplicateBankAcc', 'Bank account is already in use'), { autoClose: 2000 });
                    } else {
                        notification.showNotification('error', vm.props.t(data?.errMsg || data?.detail), { autoClose: 2000 });
                    }
                }
                dispatch(portalAction.setIsSubmitting(false));
            })
            .catch(() => {
                dispatch(portalAction.setIsSubmitting(true));
            });
    },

    validateBankName(vm) {
        window.SPL_Transaction.validateBankName(vm.state.accountBankfields.bankName).then((errMsg) => {
            vm.setState({ bankNameErrMsg: errMsg });
        });
    },

    isNumber(val) {
        return /^\d*$/.test(val);
    },

    isValidForm(vm) {
        const { bankNameErrMsg, errors, showBankName, accountBankfields, compSettings, receiptImg } = vm.state;
        const reqBankStatementByCurrencies = compSettings && compSettings.reqBankStatementByCurrencies;
        const isReceiptValid = reqBankStatementByCurrencies && receiptImg && receiptImg.length <= 0 ? false : true;

        if (showBankName && accountBankfields && (accountBankfields['bankName'] === null || accountBankfields['bankName'] === '')) {
            return false;
        } else if (accountBankfields && (accountBankfields['accountNumber'] === null || accountBankfields['accountNumber'] === '')) {
            return false;
        } else if (reqBankStatementByCurrencies && !isReceiptValid) {
            return false;
        } else {
            let formValid =
                bankNameErrMsg &&
                bankNameErrMsg.length === 0 &&
                (typeof errors.accountNumber === 'undefined' || errors.accountNumber.length === 0) &&
                accountBankfields['accountNumber'].length > 0 &&
                ((showBankName && accountBankfields['bankName']) || !showBankName);
            return formValid;
        }
    },

    close(vm, type) {
        if (type === 'memberVerify') {
            vm.setState({ memberVerifyPopup: false });
        } else if (type === 'shareInfo') {
            vm.setState({ getShareInfo: false });
        } else if (type === 'addBank') {
            vm.setState({ addBankDialogIsShow: false });
            transactionHelper.loadMemberBankAccounts(vm);
        }
    },

    /**
     * NOTE: json setting some is show some is hide condition...
     * amountNotRequired = hide
     * hasSpecialImportantNotice = show
     *
     * either show / hide, add on settings for future in switch case based on default show / hide
     *
     * controller standardized as show XXX show XXX (no hide)
     */
    getPaymentGatewaySettingBool(paymentGatewaySetting, type, checker) {
        const { amountFieldNotRequired, hasSpecialImportantNotice } = PAYMENT_GATEWAY_SETTING;
        let isShow;
        if (!paymentGatewaySetting) {
            switch (type) {
                case amountFieldNotRequired:
                    isShow = true;
                    break;

                case hasSpecialImportantNotice:
                    isShow = false;
                    break;

                default:
                    break;
            }
        } else {
            if (!paymentGatewaySetting[type]) {
                paymentGatewaySetting[type] = [];
            }

            let code = null;
            if (checker.code) {
                code = checker.code;
            } else if (checker.channel) {
                code = checker.channel;
            }

            switch (type) {
                case amountFieldNotRequired:
                    isShow = !paymentGatewaySetting[type].includes(code);
                    break;

                case hasSpecialImportantNotice:
                    isShow = paymentGatewaySetting[type].includes(code);
                    break;

                default:
                    break;
            }
        }
        return isShow;
    },

    getDepositAmountFieldNotRequiredConfig(paymentGatewaySetting, checker) {
        const { amountFieldNotRequired } = PAYMENT_GATEWAY_SETTING;
        const showAmountField = transactionHelper.getPaymentGatewaySettingBool(paymentGatewaySetting, amountFieldNotRequired, checker);
        return { showAmountField, isFormValid: !showAmountField };
    },

    updateBankListWithPaymentGatewaySetting(paymentGatewaySetting, type, bankList) {
        if (paymentGatewaySetting && paymentGatewaySetting[type] && paymentGatewaySetting[type].length > 0) {
            for (var i = 0; i < bankList.length; i++) {
                if (paymentGatewaySetting[type].indexOf(bankList[i].code) >= 0) {
                    bankList[i][type] = true;
                } else {
                    bankList[i][type] = false;
                }
            }
        }
    },

    readCustomDepositArticle(vm, fileNameKey = '', callback = content => content) {
        const { enableCustomArticleJSON } = vm.state.compSettings;
        window.SPL_Content.readCustomDepositArticle(vm.props.language.countryLanguageKey, fileNameKey, enableCustomArticleJSON).then((data) => {
            if (data) {
                let content = null;
                let tempContent = null;
                if (enableCustomArticleJSON) {
                    tempContent = commonUtil.getByViewType(data, vm);
                    content = commonUtil.getContentByCurrencyLanguage(vm, tempContent.content);
                } else {
                    content = data;
                }

                vm.setState({
                    showCustomDepositArticle: true,
                    showCustomDepositArticleContent: callback(content),
                    customArticleIsJson: enableCustomArticleJSON ? true : false,
                    customContentStyle: tempContent && tempContent.style,
                });
            }
        });
    },
};

export default transactionHelper;
