import React from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import core from 'root/utils/core';
import vm from 'root/webapp/portal/settings/profile/profile.controller';
import SVGBank from 'root/components/svgIcon/svgBank';
import commonUtil from '../../../../../../utils/common-util';
import SVGElements from 'root/components/SVGElements';
import EditDobPopup from '../../subcomponent/mobile/edit-dob-popup';
import AuthSettings, { useAuthSettingsSetup } from '@components/molecules/auth-settings/auth-settings';
import { MODULE_TYPE as MT, POPUP_TYPE, OTP_CONTACT_TYPE as OCT } from '@utils/constants';
import { popupAction } from '@redux/action/popup.action';
class MProfile extends React.Component {
    constructor(props) {
        super();
        vm.init(this);
    }

    componentDidMount() {
        vm.populateMemberInfo();
        vm.loadBankAccounts();
        vm.loadContactsVerification();
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    onEditEmailClick = () => {
        const _config = {
            selectedAuthContact: OCT.EMAIL,
        };
        this.props.dispatch(popupAction.setPopup(POPUP_TYPE.EDIT_VERIFY_AUTH, true));
        this.props.dispatch(popupAction.setItemConfig(POPUP_TYPE.EDIT_VERIFY_AUTH, _config));
    };

    onEditPhoneClick = () => {
        const _config = {
            selectedAuthContact: OCT.PHONE,
        };
        this.props.dispatch(popupAction.setPopup(POPUP_TYPE.EDIT_VERIFY_AUTH, true));
        this.props.dispatch(popupAction.setItemConfig(POPUP_TYPE.EDIT_VERIFY_AUTH, _config));
    };

    render() {
        const { isOpenEditDOBPopup, verificationStatus } = this.state;
        const { user, authSettingsReducer } = this.props;
        const _msUpdateMemberInfoModule = authSettingsReducer?.processedData?.[MT.UPDATE_MEMBER_INFO];

        let userCurrency = user && user.account && user.account.currency ? user.account.currency : null;
        if (userCurrency === 'KHUSD') userCurrency = 'USD';

        const editDOBPopupProps = {
            isOpen: isOpenEditDOBPopup,
            closeFunc: () => vm.togglePopup(false),
            updateFieldWithPopupData: () => vm.updateFieldWithPopupData('dob', user && user.account && user.account.dob),
            loadContactsVerification: () => vm.loadContactsVerification(),
        };

        return (
            <Translation>
                {(t) => (
                    <div data-page='/myaccount/'>
                        <AuthSettings module={MT?.UPDATE_MEMBER_INFO} isPublic={false} />

                        {isOpenEditDOBPopup && <EditDobPopup {...editDOBPopupProps}></EditDobPopup>}

                        <div className='profile-page'>
                            <div className='info-box'>
                                <ul>
                                    <li className='center-box'>
                                        <div className='key'>
                                            <span> {t('global:global.form.email', 'Email')}</span>
                                        </div>
                                        <div className='value'>
                                            <input type='text' defaultValue={this.state.fields['email']} readOnly />
                                            {_msUpdateMemberInfoModule?.gaEnabled && <EditProfileDetailIcon onClick={this.onEditEmailClick} />}
                                        </div>
                                    </li>
                                    <li className='center-box'>
                                        <div className='key'>
                                            <span> {t('global:global.form.username', 'Username')}</span>
                                        </div>
                                        <div className='value'>
                                            <input type='text' defaultValue={this.state.fields['username']} readOnly />
                                        </div>
                                    </li>
                                    <li className='center-box'>
                                        <div className='key'>
                                            <span>{t('global:global.form.dob', 'Date of Birth')}</span>
                                        </div>
                                        <div className='value'>
                                            <input type='text' defaultValue={this.state.fields['dob'] || ''} readOnly />
                                            {!(verificationStatus && verificationStatus.birthdayVerified) && (
                                                <SVGElements className='copy' name='copy' onClick={() => vm.togglePopup(true)} />
                                            )}
                                        </div>
                                    </li>
                                    <li className='center-box'>
                                        <div className='key'>
                                            <span> {t('global:global.form.contact', 'Contact Number')}</span>
                                        </div>
                                        <div className='value'>
                                            <input type='text' defaultValue={this.state.fields['phone']} readOnly />
                                            {_msUpdateMemberInfoModule?.gaEnabled && <EditProfileDetailIcon onClick={this.onEditPhoneClick} />}
                                        </div>
                                    </li>
                                    <li className='center-box'>
                                        <div className='key'>
                                            <span> {t('global:global.form.fullname', 'Fullname')}</span>
                                        </div>
                                        <div className='value'>
                                            <input type='text' defaultValue={this.state.fields['fullname']} readOnly />
                                        </div>
                                    </li>

                                    <li className='center-box'>
                                        <div className='key'>
                                            <span>{t('global:global.form.currency', 'Currency')}</span>
                                        </div>
                                        <div className='value'>
                                            <input type='text' defaultValue={this.state.fields['currency']} readOnly />
                                        </div>
                                    </li>
                                    <li className='center-box'>
                                        <div className='key'>
                                            <span> {t('settings:settings.profiletab.bank', 'Banking Details')}</span>
                                        </div>
                                        {this.state.fields['bankDetailsList'] && this.state.fields['bankDetailsList'].length && (
                                            <div className='value'>
                                                {this.state.fields['bankDetailsList'].map((bank, i) => (
                                                    <div key={i} className='bank-container'>
                                                        <div className='bank-icon'>
                                                            <i key={bank.id} className={`icon-bank icon-bank-big`}>
                                                                {commonUtil.getIsFormattedImageSetting(this) && bank.svgData ? (
                                                                    <div
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: bank.svgData,
                                                                        }}
                                                                    ></div>
                                                                ) : bank.bankCode && bank.bankCode.toLowerCase().includes('other') ? (
                                                                    <SVGBank
                                                                        className={`${core.getCorrectBankIcon(bank.bankCode, this, true)}`}
                                                                        name={`${core.getCorrectBankIcon(bank.bankCode, this, true)}`}
                                                                    ></SVGBank>
                                                                ) : (
                                                                    // <SVGBank
                                                                    //     className={`${core.getCorrectBankIcon(bank.bankCode, this, true)}`}
                                                                    //     name={`${core.getCorrectBankIcon(bank.bankCode, this, true)}`}
                                                                    // ></SVGBank>
                                                                    <img
                                                                        className={`${core.getCorrectBankIcon(bank.bankCode, this, true)}`}
                                                                        src={`/public/images/bankImg/${userCurrency}/${bank.bankCode}.png`}
                                                                        alt='bank-icon'
                                                                    />
                                                                )}
                                                            </i>
                                                        </div>
                                                        <div className='bank-value'>
                                                            <div>{bank.accountNumber}</div>
                                                            <div>{bank.accountHolder}</div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation('leaderboard')(withRouter(MProfile)));

export const EditProfileDetailIcon = ({ onClick }) => {
    const { handleSetup } = useAuthSettingsSetup({ module: MT.UPDATE_MEMBER_INFO, callback: onClick });

    const onEditIconClick = () => {
        handleSetup();
    };

    return <SVGElements className='copy' name='copy' onClick={onEditIconClick} />;
};
