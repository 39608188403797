import React, { FC } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import CasinoPanel from './CasinoPanel';

class Casino extends React.Component {
    render() {
        return <CasinoPanel></CasinoPanel>;
    }
}

export default connect(mapStateToProps)(withTranslation('casino')(withRouter(Casino)));
