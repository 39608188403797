// library
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// components
import styles from './menu-item.module.scss';
import SVGIcon from '../svg-icon/svg-icon';
import Text from '../../atoms/text/text';

export const MenuItem = ({ menu = {}, ...props }) => {
    const { t } = useTranslation('');
    const generalRedux = useSelector((state) => state.generalReducer);
    const { hideFlag, icon = {}, iconPosition, onClick } = props;

    if (hideFlag) {
        return null;
    }

    const flexDirection = iconPosition === 'left' ? 'row' : 'row-reverse';

    const { isSVG, directory } = icon;
    const iconSrc = isSVG ? menu?.srcSVG || `${directory}/${menu?.svgName}.svg` : menu?.src;
    let dataPage = generalRedux?.dataPage || '';
    return (
        <div
            className={`${styles.menuItem} ${styles[iconPosition]} ${
                menu?.sref?.toLowerCase() === dataPage?.toLowerCase() || (menu?.sref?.toLowerCase() === 'sponsors' && dataPage?.toLowerCase() === 'sponsorship')
                    ? styles.on
                    : ''
            }`}
            style={{ display: 'flex', flexDirection: flexDirection }}
            data-page={menu?.name?.toLowerCase()}
        >
            <MenuIcon onClick={() => onClick(menu)} isSVG={icon?.isSVG} src={iconSrc} />
            <Text onClick={() => onClick(menu)} textKey={menu.name}>
                {menu.template ? menu?.template(menu) : t(menu?.translation) || t(menu?.content, menu?.name)}
            </Text>
        </div>
    );
};

export const MenuIcon = ({ ...props }) => {
    const { src, isSVG, onClick } = props;

    if (isSVG) {
        return <SVGIcon src={src} onClick={onClick} />;
    } else {
        return <LazyLoadImage src={src} onClick={onClick} />;
    }
};
