import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import core from 'root/utils/core';

import DepositLanding from './component/desktop/deposit-landing';
import MDepositLanding from './component/mobile/deposit-landing';

class DepositLandingSelector extends React.Component {
    components = {
        desktop: {
            default: DepositLanding,
        },
        mobile: {
            default: MDepositLanding,
        },
    };

    render() {
        const TagName = core.getCorrectComponent(this.components, 'deposit-landing', this.props.screen.viewType);
        return <TagName></TagName>;
    }
}

export default connect(mapStateToProps, null)(DepositLandingSelector);
