import React from 'react';

const getViewBox = (name) => {
    switch (name) {
        // NOTE: (SVG2JSX) DO NOT DELETE, ADD VIEWBOX.
        case 'BG':
            return '0 0 50 50';

        case 'HC':
            return '0 0 50 50';

        case 'JL':
            return '0 0 50 50';

        case 'NT':
            return '0 0 50 50';

        case 'RT':
            return '0 0 50 50';

        case 'AB':
            return '0 0 50 50';

        case 'AG2':
            return '0 0 50 50';

        case 'BR':
            return '0 0 50 50';

        case 'BTI':
            return '0 0 50 50';

        case 'CMD':
            return '0 0 50 50';

        case 'DG':
            return '0 0 50 50';

        case 'EBET':
            return '0 0 50 50';

        case 'EVO':
        case 'BMK2':
            return '0 0 50 50';

        case 'GD':
            return '0 0 50 50';

        case 'GP':
            return '0 0 50 50';

        case 'HB':
            return '0 0 50 50';

        case 'IBC':
            return '0 0 50 50';

        case 'IDN':
            return '0 0 50 50';

        case 'IME':
            return '0 0 50 50';

        case 'JKR':
            return '0 0 50 50';

        case 'KM':
        case 'KM2':
            return '0 0 50 50';

        case 'MEGA':
            return '0 0 50 50';

        case 'MGP':
            return '0 0 50 50';

        case 'NS':
            return '0 0 50 50';

        case 'PG':
            return '0 0 50 50';

        case 'PP':
        case 'PPL':
            return '0 0 50 50';

        case 'PT':
        case 'PT2':
            return '0 0 50 50';

        case 'QQK':
            return '0 0 50 50';

        case 'SA':
            return '0 0 50 50';

        case 'SCR2':
            return '0 0 50 50';

        case 'SG':
            return '0 0 50 50';

        case 'TTG':
            return '0 0 50 50';

        case 'UG':
        case 'AES':
        case 'AES2':
            return '0 0 50 50';

        case 'WM':
            return '0 0 50 50';

        default:
            return '0 0 40 40';
    }
};

const getPath = (name, props) => {
    switch (name) {
        // NOTE: (SVG2JSX) DO NOT DELETE, ADD PATH.
        case 'BG':
            return (
                <g {...props}>
                    <g>
                        <path
                            className='st7'
                            d='M24.2176991,9.6230993C13.9685116,8.6540613,9.6453142,14.058197,9.6453142,14.058197
		C3.3840065,22.2573719,9.198061,29.22686,9.198061,29.22686S9.4092197,15.2384291,24.2176991,9.6230993z'
                        />
                        <g>
                            <path
                                className='st7'
                                d='M41.4362335,20.2448349c-0.2981911-4.3604622-2.31073-6.5220604-2.31073-6.5220604
			C33.4605827,6.641489,24.2176991,9.6230993,24.2176991,9.6230993S35.920372,11.2628784,41.4362335,20.2448349z'
                            />
                        </g>
                        <g>
                            <path
                                className='st8'
                                d='M41.4362335,20.2448349C42.0325127,32.46912,30.5535374,38.767971,30.5535374,38.767971
			c8.348341-0.6337204,11.2553844-6.3359795,11.2553844-6.3359795
			C45.2749825,25.0525856,41.4362335,20.2448349,41.4362335,20.2448349z'
                            />
                        </g>
                        <path
                            className='st8'
                            d='M24.2176991,9.6230993C13.9685116,8.6540613,9.6453142,14.058197,9.6453142,14.058197
		C3.3840065,22.2573719,9.198061,29.22686,9.198061,29.22686S9.4092197,15.2384291,24.2176991,9.6230993z'
                        />
                        <g>
                            <path
                                className='st8'
                                d='M41.4362335,20.2448349c-0.2981911-4.3604622-2.31073-6.5220604-2.31073-6.5220604
			C33.4605827,6.641489,24.2176991,9.6230993,24.2176991,9.6230993S35.920372,11.2628784,41.4362335,20.2448349z'
                            />
                        </g>
                        <g>
                            <path
                                className='st8'
                                d='M9.198061,29.22686c0.3975554,7.9633255,6.509697,10.3733482,6.509697,10.3733482
			c7.6029987,3.5778885,14.8457794-0.8322372,14.8457794-0.8322372C15.7202263,39.28965,9.198061,29.22686,9.198061,29.22686z'
                            />
                        </g>
                        <g>
                            <path
                                className='st0'
                                d='M20.0239182,25.4930782v-0.000473h-0.0083923c-0.0325356-0.0021515-0.0648556-0.0050354-0.09795-0.0050354
			c-0.0331802,0-0.0655022,0.0028839-0.0980358,0.0050354h-3.9316492l-0.7474079,3.1658211h4.8834352v-0.0054665
			c0.7904434-0.0571938,1.4151115-0.7418137,1.4151115-1.5799408S20.8143616,25.5502739,20.0239182,25.4930782z'
                            />
                            <path
                                className='st0'
                                d='M20.9328403,20.3741074v-0.0002155h-0.0077896c-0.030426-0.0019798-0.0606804-0.0048199-0.091711-0.0048199
			c-0.030899,0-0.0611534,0.00284-0.0915794,0.0048199h-3.6757126l-0.698864,2.84021h4.5656567v-0.0050354
			c0.7389717-0.0512981,1.3230152-0.6653805,1.3230152-1.4174786
			C22.2558556,21.0394878,21.6718121,20.4254055,20.9328403,20.3741074z'
                            />
                            <path
                                className='st0'
                                d='M41.4362259,20.2448273C35.9203758,11.2628498,24.2176991,9.6230888,24.2176991,9.6230888
			C9.4092379,15.2384386,9.19806,29.2268467,9.19806,29.2268467s6.5221624,10.0627842,21.3554993,9.5411015
			C30.5535583,38.7679482,42.0325317,32.469101,41.4362259,20.2448273z M22.3913765,24.4057713
			c1.0956554,0.5299892,1.8533497,1.6489716,1.8533497,2.9478855c0,1.809454-1.4668427,3.2762527-3.2764244,3.2762527
			c-0.1267853,0-0.2512455-0.0089092-0.3742847-0.0228958l-0.0001297,0.0010757H12.213254l2.9442711-12.3011341h7.1221285
			l-0.000988,0.007616c1.6168652,0.0796165,2.9044189,1.4118004,2.9044189,3.0483322
			C25.1830845,22.9616528,23.9551353,24.2714558,22.3913765,24.4057713z M38.1892815,24.4607716
			c-0.0603828,0.5658379-0.1498108,1.109169-0.27285,1.6234512c-0.708847,2.9338551-2.5693398,4.9599953-7.1043549,4.845993
			c-3.9869938-0.1007061-6.0577602-2.8937054-5.5546265-6.4648399c0.5009403-3.5713501,3.5040417-6.4693146,7.3748817-6.4693146
			c3.1575127,0,5.1812439,1.6458302,5.5502777,4.4858246h-2.7393341c-0.4517059-1.2366848-1.5721092-1.9344292-3.1732216-1.9344292
			c-2.3434887,0-4.1636562,1.7531605-4.4678783,3.9179192c-0.3040943,2.1623917,0.9504089,3.8550014,3.3655071,3.9155102
			c2.4039116,0.0602512,3.5689392-0.8722134,4.1078835-2.2966633h-4.5618706v-2.1868362h4.9531975h2.5671463
			C38.2250862,24.0805912,38.2093773,24.2686157,38.1892815,24.4607716z'
                            />
                        </g>
                    </g>
                </g>
            );

        case 'HC':
            return (
                <g {...props}>
                    <g>
                        <path
                            className='st8'
                            d='M34.9880753,23.7224636l7.7619133-1.5683212c0,0-0.0457764-10.0372486-13.0942001-10.5391111
		S7.4002776,20.7426548,7.2518106,28.8351879C7.103344,36.9277191,16.1682339,40.1584587,23.5707054,39.468399
		c7.4024715-0.6900635,13.8953171-4.9558945,17.1574249-10.3822803l-6.994709-1.6310539
		c0,0-4.0692673,7.9043331-15.2754383,6.3673801c0,0-6.9708042-1.0779343-6.3360147-7.6534023
		c0.2674398-2.7702694,2.1683931-5.7220078,4.7676935-7.8102341c2.7865009-2.2386189,6.3569412-3.5152178,9.0021572-3.450304
		C31.0045433,15.0339699,35.2703743,16.0376949,34.9880753,23.7224636z'
                        />
                        <polygon
                            className='st0'
                            points='22.1696739,8.0706282 12.6761084,37.5132256 20.1758976,25.4091091 25.0926933,23.8501911 
		13.4707241,45.430336 40.9058723,12.9637871 30.1667767,15.1529102 27.3764954,19.6030083 22.2482376,21.2197495 
		31.9141693,4.569665 	'
                        />
                    </g>
                </g>
            );

        case 'JL':
            return (
                <g {...props}>
                    <g>
                        <path className='st0' d='M10.7148352,19.1762695c0.3782234,0,0.6848345,0.3066101,0.6848345,0.6848335v-0.6848335H10.7148352z' />
                        <path
                            className='st0'
                            d='M23.9990196,30.5166988h0.0608597c-0.3782253,0-0.6848316-0.3066082-0.6848316-0.6848335v-9.9186077
		c0-0.3782253,0.3066063-0.6848335,0.6848316-0.6848335h-0.0608597c0.295927,0,0.5380516-0.2421246,0.5380516-0.5380516
		c0-0.2959366-0.2421246-0.5380611-0.5380516-0.5380611h-5.651186c-0.295927,0-0.5380516,0.2421246-0.5380516,0.5380611
		c0,0.295927,0.2421246,0.5380516,0.5380516,0.5380516h0.164093c0.3782253,0,0.6848335,0.3066082,0.6848335,0.6848335v9.9186077
		c0,0.3782253-0.3066082,0.6848335-0.6848335,0.6848335h-0.164093c-0.295927,0-0.5380516,0.2421246-0.5380516,0.5380516v0.0000076
		c0,0.295929,0.2421246,0.5380535,0.5380516,0.5380535h5.651186c0.295927,0,0.5380516-0.2421246,0.5380516-0.5380535v-0.0000076
		C24.5370712,30.7588234,24.2949467,30.5166988,23.9990196,30.5166988z'
                        />
                        <path
                            className='st0'
                            d='M42.7572784,30.5166988h0.0608597c-0.3782234,0-0.6848335-0.3066082-0.6848335-0.6848335v-9.9186077
		c0-0.3782253,0.3066101-0.6848335,0.6848335-0.6848335h-0.0608597c0.2959251,0,0.5380516-0.2421246,0.5380516-0.5380516
		c0-0.2959366-0.2421265-0.5380611-0.5380516-0.5380611h-5.6511841c-0.295929,0-0.5380554,0.2421246-0.5380554,0.5380611
		c0,0.295927,0.2421265,0.5380516,0.5380554,0.5380516h0.164093c0.3782234,0,0.6848297,0.3066082,0.6848297,0.6848335v9.9186077
		c0,0.3782253-0.3066063,0.6848335-0.6848297,0.6848335h-0.164093c-0.295929,0-0.5380554,0.2421246-0.5380554,0.5380516v0.0000076
		c0,0.295929,0.2421265,0.5380535,0.5380554,0.5380535h5.6511841c0.2959251,0,0.5380516-0.2421246,0.5380516-0.5380535v-0.0000076
		C43.29533,30.7588234,43.0532036,30.5166988,42.7572784,30.5166988z'
                        />
                        <path className='st0' d='M26.3140278,19.1762695c0.3782234,0,0.6848335,0.3066101,0.6848335,0.6848335v-0.6848335H26.3140278z' />
                        <path className='st0' d='M26.3140755,30.5167065c0.3782234,0,0.6848335-0.3066101,0.6848335-0.6848335v0.6848335H26.3140755z' />
                        <path
                            className='st0'
                            d='M32.3391609,18.6382027c0-0.295929-0.2421227-0.5380535-0.5380535-0.5380535h-5.6511669
		c-0.2959309,0-0.5380554,0.2421246-0.5380554,0.5380535c0,0.2959309,0.2421246,0.5380554,0.5380554,0.5380554h0.8489304v11.3404522
		h-0.6239433c-0.2959309,0-0.5380554,0.2421246-0.5380554,0.5380535c0,0.2959023,0.2421246,0.5380268,0.5380554,0.5380268h4.802206
		v-0.4592819v-0.6167984V19.8610954c0-0.378233,0.3066349-0.6848373,0.6848392-0.6848373h-0.0608654
		C32.0970383,19.1762581,32.3391609,18.9341335,32.3391609,18.6382027z'
                        />
                        <path
                            className='st0'
                            d='M16.739994,18.6382027c0-0.295929-0.2421246-0.5380535-0.5380554-0.5380535h-5.6511946
		c-0.2959299,0-0.5380545,0.2421246-0.5380545,0.5380535c0,0.2959309,0.2421246,0.5380554,0.5380545,0.5380554h0.8489304v8.1668129
		v0.9026222c0,0.3697491,0,0.7987118,0,1.1792221c0,0.9026222-0.8061991,1.2957134-0.8061991,1.2957134v1.1792221
		c0,0,0.4549265,0,1.3146181,0c1.8053293,0,3.6698732-1.3830566,3.6698732-3.4503231c0-0.0745888,0-0.1369343,0-0.2038345V27.343071
		v-0.2219982v-7.2599773c0-0.378233,0.3066053-0.6848373,0.6848087-0.6848373h-0.0608368
		C16.4978695,19.1762581,16.739994,18.9341335,16.739994,18.6382027z'
                        />
                        <path
                            className='st0'
                            d='M10.693656,28.4058456c0-1.1015339-0.8929672-1.9944916-1.9944935-1.9944916
		S6.70467,27.3043118,6.70467,28.4058456c0,3.057251,3.3920965,3.4940033,3.3920965,3.4940033V30.720623
		c0,0-0.5261364-0.0061779-0.9074984-0.3834667C10.05336,30.1184044,10.693656,29.3379345,10.693656,28.4058456z'
                        />
                        <path
                            className='st0'
                            d='M31.6639862,30.4003353v1.1924763h3.828846c0,0,0-3.5363655,0-3.9003258
		c0-0.3639584-0.2766075-0.6696835-0.5241013-0.6696835c-0.2474899,0-0.3738289,0.1175404-0.5823326,0.5241013
		c-0.1742363,0.3397408-1.2260094,2.1834621-1.7724991,2.6382046c-0.1838074,0.1529484-0.5730171,0.2152309-0.6661606,0.2152309
		C31.7722244,30.4003391,31.6639862,30.4003353,31.6639862,30.4003353z'
                        />
                    </g>
                </g>
            );

        case 'NT':
            return (
                <g {...props}>
                    <g>
                        <polygon
                            className='st0'
                            points='16.516037,26.6866627 14.0092621,22.6288128 10.7946196,17.6020813 10.7608271,17.5500736 
		8.09688,17.5500736 8.09688,32.4499245 11.0525436,32.4499245 11.0525436,23.2913132 13.2580633,26.8595066 16.8069401,32.4499245 
		19.4912586,32.4499245 19.4912586,17.5500736 16.516037,17.5500736 	'
                        />
                        <polygon
                            className='st0'
                            points='21.1539898,32.4499245 30.9210091,32.4499245 30.9210091,29.6281853 24.1297855,29.6281853 
		24.1297855,26.3793907 29.9368114,26.3793907 29.9368114,23.5357456 24.1297855,23.5357456 24.1297855,20.3938389 
		30.9210091,20.3938389 30.9210091,17.5501938 21.1539898,17.5501938 	'
                        />
                        <polygon
                            className='st0'
                            points='32.1206169,20.4148331 35.5336533,20.4148331 35.5336533,32.4499245 38.5099297,32.4499245 
		38.5099297,20.4148331 41.9031181,20.4148331 41.9031181,17.5501938 32.1206169,17.5501938 	'
                        />
                    </g>
                </g>
            );

        case 'RT':
            return (
                <g {...props}>
                    <g id='RT-RedTigerLogo_x5F_Red_3_'>
                        <path
                            id='RT-_x33__4_'
                            className='st0'
                            d='M29.0820808,37.6226006c0.1409111-0.2046013,0.2300797-0.4450264,0.2571468-0.6926193
		c0.0246792-0.2237091,0.0302525-0.4466209,0.2754555-0.5429535c0.4036312-0.1584282,0.8239803,0.0605049,1.1352615,0.3128738
		c0.1592236,0.1289711,0.7754173,0.8876686,0.7754173,0.8876686c-0.148077-2.122448-2.2792816-4.0522308-5.5560951-4.2329483
		c-0.3447189-0.019104-0.6464462-0.0286598-0.9696693-0.0278625c-0.3232212-0.0007973-0.6249504,0.0087547-0.9696674,0.0278625
		c-3.2760181,0.1807175-5.4080162,2.1097069-5.5560951,4.2329483c0,0,0.6161938-0.7586975,0.7754173-0.8876686
		c0.3104858-0.2523689,0.730835-0.471302,1.1352615-0.3128738c0.2460003,0.0963287,0.2515736,0.3192444,0.2754574,0.5429535
		c0.0270672,0.2467957,0.1154366,0.4872208,0.2571449,0.6926193c0.3431244,0.4975739,0.9521542,0.6695328,1.5062504,0.8215942
		c0.5509129,0.1512604,1.1686993,0.2149506,1.7180195,0.0222893c0.2101746-0.0740395,0.4084072-0.1878815,0.5636501-0.3494949
		c0.0724468-0.075634,0.2173386-0.2396317,0.2945614-0.2396317c0.0772247,0,0.2221165,0.1632004,0.2945633,0.2396317
		c0.1552429,0.1616096,0.3534756,0.2762527,0.5636501,0.3494949c0.5493202,0.1926613,1.167902,0.1297684,1.7180195-0.0222893
		C28.1299267,38.2921333,28.7389545,38.1201744,29.0820808,37.6226006z'
                        />
                        <path
                            id='RT-_x32__4_'
                            className='st0'
                            d='M40.5764046,24.5511742L40.5764046,24.5511742
		c0,0.0015926-0.1425056,1.9512787-0.2308731,2.9925995c-0.2237091,2.6446991-0.7793999,5.2694893-2.7083855,7.1945
		c-0.6169891,0.6161957-1.5213776,0.7523308-1.5221748,0.7523308l0,0c0.619381-1.2889099,1.3239441-2.5451813,1.8358459-3.8754921
		c0.5111046-1.3295135,0.8367157-2.7298813,1.240345-4.1007919l0,0c-0.0007973,0-1.3565826,0.48563-1.6559181,0.5620575l0,0
		c0.0206985-0.2603302,1.405941-5.4191608,1.4067383-5.4215488l0,0c-0.0700607,0.0796108-2.3636703,4.9741325-2.4488564,5.1628113
		c-0.0382118,0.0843887-0.0175133,0.1847,0.0533409,0.2452049c0.9609146,0.8088531,0.6400795,2.2864437,0.2332611,2.7672977
		c-0.5859375,0.6910286-1.3048325,1.3382721-1.7729492,2.435318c-0.1910667,0.4474182-0.5445404,1.7458839-0.5453377,1.7458839l0,0
		c-0.0907593-0.0334396-0.2300797-2.8787575,0.6376877-4.118309c0.4370689-0.6249523,0.4402542-2.319088-0.1847-2.6383286
		c-0.6448517-0.3303871-0.7356071-0.6838627-0.3049088-1.2976685c-0.0063705-0.0135345-0.0079613-0.0167198-0.0143318-0.0310478
		c-1.9886971-0.1823101-1.3533974-2.2036495-2.8556671-2.2792816l0,0c0.3948746-0.2818241,0.8677673-0.1847,1.284935,0.043787
		c0.4235306,0.2316704,0.4020348,0.2396297,0.6663475,0.624155c0.1281738,0.187088,0.6567955,0.5389709,0.8948326,0.59231
		c1.8852043,0.4235344,1.699707-1.4847565,1.699707-1.4855537l0,0c-0.9513588-0.0541344-1.7554321-0.434679-1.8167343-1.07794l0,0
		c0.5421524-0.0429897,1.2005386,0.0867767,1.5906372-0.1775341c0.3574562-0.2420177,0.619381-0.9067745,0.6201782-1.3932018l0,0
		c-0.9784279,0.4410477-0.9784279,0.4410477-1.4560966,0.0453796l0,0c1.4895325-1.5181942,2.7816277-5.2432213,2.7306747-6.45889
		l0,0c0.5851479,0.4943886,0.6973991,0.9362326,0.8367195,1.2379618l0,0
		c1.0182343-0.4131851,1.7952423-0.5429516,1.6511421-1.9711838l0,0l-1.1273003,0.580368l0,0
		c0.0660782-0.1719608,0.1433029-0.3407373,0.2300797-0.5031452c0.1456871-0.2706795,0.371788-0.4466209,0.5787773-0.6687374
		c0.4283104-0.4593592,0.8972206-1.0604267,1.0930672-1.6567173c0.1297684-0.3956699,0.2101746-0.8056698,0.2595329-1.2180576
		c0.0509529-0.4235334,0.1138458-0.8940382,0.0501556-1.3191643c-0.109066-0.7252617-0.6655502-1.4218626-1.5436668-1.2793579
		c-0.4681168,0.0764265-0.8836899,0.3073006-1.311203,0.490407c-0.478466,0.2053986-0.9632988,0.4004469-1.4529114,0.5819607
		c-0.4848328,0.1791267-0.9792213,0.3240194-1.4688339,0.485631c-0.5373802,0.1775341-3.0300179,0.3526793-3.5793381,0.4179611l0,0
		l2.1089096,0.3359613c0.1504669,0.0238829,0.3049126,0.0151253,0.4514008-0.0270681
		c0.4896088-0.1393204,1.6519394-0.4665241,2.1025429-0.5644464c0.5262299-0.1138449,1.949688-0.3693981,2.435318,0.344718l0,0
		c-0.2985458,0.6114168-1.3151817,1.0293779-1.415493,1.1830282c-0.0047798,0.0079613-0.0103531,0.0151262-0.0151291,0.0230875
		c0.8040771-0.2221165,1.1615334-0.0429897,1.1615334-0.0429897l0,0c-0.5962906,0.5564842-0.8924446,1.0882902-1.2530861,1.8493776
		c-0.4155731,0.8773203-0.6082344,2.0874157-0.9824066,2.9798613c-0.3319817,0.7937279-0.7555122,1.5508347-1.2538834,2.2522125
		c-0.5294151,0.7451649-1.1511803,1.2737865-1.86689,1.6893597l0,0c0.6806755-0.7324276,1.3438416-1.7259808,1.7243843-2.4456692
		c1.2292061-2.3222704,1.3032417-4.8722296,1.4967003-5.6675501l0,0c-0.5628548,0.245204-1.7411079,0.1114559-1.7419052,0.1114559
		c0.0007973-0.0007954,1.9130669-0.8828926,2.4647751-1.1710863l0,0c-0.2921753-0.1679802-0.5540962-0.2627182-0.8056679-0.28899
		c-1.2546806-0.1289711-1.7267761,0.2659025-2.5388145,0.3821354c-0.4163704,0.0597086-3.3795147-0.9545431-3.9837666-1.0898829
		c0,0,0.0199032,0.0015926,0.0541363,0.0039806c-0.4920006-0.1138449-0.9855919-0.2253008-1.4799805-0.3272038
		c-0.0652809-0.0135336-0.1305637-0.0278645-0.19664-0.0421944c-0.4418449-0.0660772-0.8844852-0.1265821-1.3287182-0.1735535
		c-0.589922-0.0628929-1.6774178-0.1146402-2.1176682-0.1337471c-0.1289711-0.0055723-0.2563496-0.0055723-0.3853207,0
		c-0.4402523,0.0191069-1.5277462,0.0708542-2.1176701,0.1337471c-0.444231,0.0469713-0.8868713,0.1074762-1.3287163,0.1735535
		c-0.0652828,0.0143299-0.1313591,0.0286608-0.1966419,0.0421944c-0.4951839,0.1026993-0.9879799,0.2133589-1.4799786,0.3272038
		c0.0342331-0.002388,0.0541344-0.0039806,0.0541344-0.0039806c-0.6042519,0.1353397-3.5673943,1.1487961-3.9837637,1.0898829
		c-0.8112431-0.1162329-1.2841349-0.5111065-2.5388145-0.3821354c-0.252368,0.0262718-0.5142908,0.1210098-0.8064651,0.28899
		c0.5517082,0.28899,2.4647751,1.1718826,2.4647751,1.1718826s-1.1790476,0.1345434-1.7419024-0.1114559
		c0.1934566,0.7953196,0.2674952,3.3452787,1.4966984,5.6675491c0.3813391,0.7196884,1.0437078,1.7132416,1.7243881,2.4456692
		c-0.7157087-0.4155731-1.3374758-0.9441948-1.8668928-1.6893597c-0.4983683-0.7013779-0.9219027-1.4584846-1.2538824-2.2522106
		c-0.3733788-0.8924465-0.5668344-2.1025429-0.9824076-2.9798632c-0.3606405-0.7610874-0.6559992-1.2928925-1.2530861-1.8493776
		c0,0,0.3582516-0.1791258,1.1615334,0.0429907c-0.004777-0.0079613-0.0103502-0.0151262-0.0151262-0.0230875
		c-0.1003103-0.1544466-1.116951-0.5716114-1.415494-1.1830282c0.48563-0.7141161,1.9090853-0.4585629,2.4353189-0.3447189
		c0.4506016,0.0979233,1.6129313,0.4243307,2.1025429,0.5644464c0.1464844,0.0421944,0.300931,0.050952,0.4513969,0.0270681
		l2.1089115-0.3359613c-0.5493202-0.0644855-3.0419598-0.240427-3.5793381-0.4179611
		c-0.4896107-0.1616116-0.9839993-0.3065042-1.4688339-0.48563c-0.4896116-0.1807184-0.9744463-0.3757668-1.4529114-0.5819607
		c-0.4267187-0.1839027-0.8430872-0.4139805-1.311203-0.4904079c-0.8709536-0.1433048-1.4274387,0.553297-1.537303,1.2777624
		c-0.0636892,0.4243298-0.0007963,0.8948345,0.0501556,1.3191643c0.0493593,0.4123878,0.1297665,0.823184,0.2595339,1.2180576
		c0.1958447,0.5962906,0.6647568,1.1973581,1.0930672,1.6567173c0.2061939,0.2213202,0.432291,0.3980579,0.5787764,0.6687374
		c0.0875721,0.1616116,0.1647959,0.3311844,0.2300777,0.5031452l-1.1273012-0.580368
		c-0.1440964,1.4274359,0.6321163,1.5572023,1.651145,1.9711828c0.1393204-0.3009319,0.2515726-0.7435713,0.8367176-1.2379599
		c-0.050951,1.2156687,1.2411451,4.9399004,2.7306776,6.4588881c-0.4776697,0.3964653-0.4776697,0.3964653-1.4560957-0.0453796
		c0.0007963,0.4864273,0.2627182,1.1503868,0.6201744,1.3932037c0.3900967,0.2643108,1.0476885,0.1345425,1.5906391,0.1775322
		c-0.0613003,0.6432629-0.8645821,1.0230103-1.8167372,1.0779419c0,0-0.1862907,1.9090862,1.699708,1.4855518
		c0.2380381-0.053339,0.7666597-0.4060192,0.8948336-0.59231c0.2643108-0.3845234,0.2428169-0.3924847,0.6663494-0.624155
		c0.4171658-0.2284851,0.8900585-0.3248158,1.2849312-0.043787c-1.5022697,0.0764275-0.8669701,2.0977669-2.8556671,2.2792816
		c-0.0063696,0.0135326-0.0079613,0.0167179-0.0143299,0.0310478c0.4314947,0.6138058,0.3407373,0.9672813-0.3049126,1.2976685
		c-0.6249514,0.3200378-0.6225634,2.0141716-0.1846991,2.6383305c0.8677664,1.2395535,0.728446,4.0856686,0.6376886,4.1183071
		c0,0-0.3542709-1.2984657-0.5453386-1.7458801c-0.4681168-1.0970497-1.1870098-1.7442913-1.7729511-2.4353199
		c-0.4068155-0.4800568-0.7276497-1.9576492,0.2332621-2.7672977c0.0708542-0.0597095,0.09235-0.1600189,0.05334-0.2452049
		c-0.0859814-0.1886787-2.379591-5.0832005-2.4488535-5.1628113c0,0,1.3860378,5.1620159,1.4067373,5.4215488
		c-0.2993402-0.0764275-1.6559219-0.5620575-1.6559219-0.5620575c0.4036312,1.3709126,0.7292423,2.7712803,1.2403488,4.1007957
		c0.5119028,1.3303089,1.2156696,2.5865803,1.8358431,3.8754902c0,0-0.9051838-0.1361351-1.5221739-0.7523308
		c-1.9281931-1.9258041-2.4846773-4.5498009-2.7083864-7.1944981c-0.0883694-1.0413208-0.2308741-2.9926014-0.2308741-2.9926014
		c-0.2579412,0.9847965-0.5700188,1.7092628-0.7220764,2.7004261c-0.2260971,1.4696312-0.4092035,1.9998436-0.0573206,4.0116291
		c0.0318441,0.1839027,0.1791258,0.7754173,0.2826214,0.937027c1.3119984,2.05159,4.1206961,6.1030273,4.1206961,6.1030273
		l0.1488743-1.289711c0.1568346,0.3479042,2.0062122,4.4447174,2.0062122,4.4447174
		c0-0.9760399-0.4975729-3.6509857,0.5525045-4.1071625c0.3455143-0.1504669,0.8319416,0.2109718,0.8383102,0.6782913
		c0.0079613,0.5302124,0.004776,1.7172203,0.004776,1.7172203c0.7778053-0.4354744,1.0859032-0.6695328,1.4728146-0.8430862
		c0.5994759-0.2674942,1.7713585-0.5087166,3.0244465-0.1807175c-0.0135345-0.009552-0.0310497-0.021492-0.0517483-0.0350266
		c-0.8128338-0.5341949-1.8135509-0.7037659-2.7585411-0.4728928c-0.1799221,0.0437851-0.3566608,0.0835876-0.5254364,0.106678
		c-0.1910686,0.0262718-0.3471069-0.1504669-0.2937679-0.3359604c0.1862926-0.6464462,0.5485249-1.9114761,0.6766987-2.406662
		c0.1862907-0.7220764,0.094738-1.405941,0.0087566-2.1335869c-0.2117653-0.6926231-0.6225624-1.2530861-1.4170856-1.4083328
		c-0.2762527-0.0541344-0.3272038-0.4187565-0.3670101-0.6424637c-0.0668736-0.3757668-0.1233978-0.7475548-0.1377277-1.1304874
		c-0.0326405-0.8844833,0.1186218-1.8000183,0.4896107-2.6144447c0.1862907-0.4092026,0.4283104-0.7929306,0.7244663-1.1304836
		c0.3104858-0.3542709,0.6743088-0.587534,1.0452995-0.8653774c0.0222912-0.0167198,0.043787-0.0358257,0.0644855-0.0549316
		c0.3184471-0.2810287,0.3136692-0.573204,0.3272038-0.9672813c0.0262718-0.7690487,0.6225624-1.068388,1.3191643-1.223629
		c-0.1544456,0.1233978-0.3104839,0.2810287-0.4211445,0.4752808c-0.0867767,0.152853-0.0859814,0.8996105-0.099514,1.0890865
		c-0.0525436,0.7411842-0.1663876,0.9441948-1.2960777,1.2713985c0.9824066,0.7961159,1.7116508,1.3876286,2.5945415,2.1033382
		c-1.4218616,0.3415356-2.0269108-0.6981926-2.9050274-1.2729893c0.0700588,1.6877651,1.7235909,1.8183308,2.5897655,2.668581
		c-0.0453796,0.0835915-1.604969-0.0907574-2.8596478-0.529417c0.5540962,1.1710873,1.5285416,0.9704666,2.3389893,1.2825451
		c-0.3725815,0.3654175-1.1551647,0.3240204-1.7172222,0.4283104c-0.2006207,0.0374184-0.3646221-0.0159225-0.5580769,0.0597095
		c0.1297665,0.1950493,0.2173386,0.4529896,0.3972607,0.573204c0.4641361,0.3112812,0.9489708,0.6114178,1.4648533,0.8152218
		c0.2674942,0.1058807,0.6456509,0.0748367,0.9282722-0.0254745c1.1336689-0.4004478,2.2553959-0.8375149,3.4646969-1.299263
		c0.2985439-0.1138439,0.4999599-0.3972626,0.5079212-0.7173023c0.0119419-0.444231,0.0230885-0.9330482-0.0405998-1.4115143
		c-0.0334377-0.2499809-0.3797493-0.5628548-0.6496315-0.6591835c-1.2379608-0.4386597-2.5754356-1.3486214-2.9559784-2.1845436
		c-0.2873993-0.631319-0.1926613-2.486269-0.2014179-2.7688904c-0.0055733-0.1934566-0.1130486-0.9537468-0.4649315-1.5396881
		c-0.1974373-0.2945633-0.5166798-0.5182705-0.7284451-0.6432629c-0.3988552-0.235651-0.8526421-0.3765621-1.2952824-0.5150871
		c-1.898737-0.5939026-2.7378426-1.891571-3.3978224-3.2163086c-0.1568356-0.3144646-0.3598442-0.9394169-0.4991646-1.4521151
		c-0.0748358-0.2770481,0.1003103-0.5596695,0.3821354-0.6153975c0.1170292,0.3144655,0.1639996,0.5421543,0.3192434,0.8781147
		c0.2587376,0.5580788,0.6281347,0.876524,1.3342896,0.8813019c1.5747185,0.0111446,3.09132,0.2754555,3.9527168,1.9066963
		c0.2030106,0.3845253,0.4585629,0.7738247,0.7634754,1.1185436c-0.4856319-0.3686028-0.7746201-0.9099598-1.4600773-1.5794945
		c-0.8948345-0.8749313-2.0070076-0.9402122-2.583395-0.9465809c-0.8168163-0.0079613-1.2339802-0.2069912-1.2339802-0.2069912
		c0.2786407,0.5166798,0.7539215,0.5763893,1.5508327,0.6759033c0.0987186,0.0127373,0.2014179,0.0342331,0.3073025,0.0652828
		c-0.2149525,0.4633389-0.1847,1.0333576,0.1663876,1.4250469c0.3486996,0.388504,0.9505615,0.5134945,1.3884258,0.199028
		c0.1472816-0.1058826,0.2635155-0.2436104,0.3359623-0.4036312c0.19664,0.1854954,0.3924847,0.3869133,0.585144,0.6042519
		c0.9545441,1.0739613,2.2068348,1.1137676,2.2068348,1.1137676s-0.1743507-0.9306602-0.2770481-1.4067383
		c0.7316303-0.298542,0.3550682-0.7515335-0.0350304-1.1710873c-0.5318031-0.5724068-1.0699787-1.1376495-1.6328335-1.7347355
		c0.5676327-0.1624088,0.9840012-0.4498062,1.2634373-0.6981945c0,0-1.3279228,0.2826214-2.5483685,0.1902714
		c-0.1791248-0.0135326-0.4195518-0.1377277-0.5333958-0.2770481l-0.0047779-0.0055723
		c0.8964272-0.1624079,2.23629-0.6950092,2.6837082-0.9553394c-1.5683479-0.9617081-3.4877834-0.4593592-4.7830658-1.0699806
		c1.0938644-0.0605049,1.9321747-0.1695719,3.0586777-0.4720964c0.9577274-0.2579412,2.8262119-1.0214167,2.8055134-1.1304846
		h-4.0410843c1.2706013-0.5724077,2.3931255-1.3979797,3.8635502-1.3685236
		c0.6066418,0.0119419,1.4258442,0.1265831,2.1495132,0.199029c0.3407383,0.0342331,0.8972244,0.0342331,1.2371655,0
		c0.7236691-0.0716505,1.5420761-0.1862907,2.1495132-0.199029c1.4704247-0.0294561,2.5929489,0.7961159,3.8635521,1.3685236l0,0
		h-4.0410843l0,0c-0.0199032,0.1090679,1.847784,0.8725433,2.8055115,1.1304846
		c1.1273022,0.3033199,1.9648132,0.4123878,3.0586815,0.4720964l0,0c-1.2952805,0.6106215-3.2147179,0.1082726-4.7830677,1.0699806
		l0,0c0.4474163,0.2603292,1.787281,0.7929316,2.6837063,0.9553394l0,0l-0.004776,0.0055723
		c-0.1138458,0.1393204-0.3542728,0.2635136-0.5333996,0.2770481c-1.2196503,0.09235-2.5467739-0.1902714-2.5475712-0.1902714l0,0
		c0.2786407,0.2483883,0.6950092,0.5357876,1.2634354,0.6981945l0,0c-0.5628548,0.5970879-1.1010265,1.163126-1.6328335,1.7347374
		c-0.3900967,0.4203491-0.7666607,0.8725433-0.0350285,1.1710873c-0.1026993,0.4760761-0.2770481,1.4067364-0.2770481,1.4067364
		s1.2530861-0.0398045,2.2068329-1.1137657c0.1926613-0.2165451,0.3885059-0.4187565,0.585146-0.6042519l0,0
		c0.0724468,0.1600189,0.1886787,0.2977467,0.3359604,0.4036312c0.4386616,0.3144646,1.0397282,0.1902714,1.3884277-0.1990299
		c0.3510857-0.3916874,0.38134-0.9617081,0.1663857-1.4250469l0,0c0.1058846-0.0310478,0.2085838-0.0525436,0.3073006-0.0652809
		c0.7969131-0.0995159,1.2729912-0.1592236,1.5508347-0.6759033l0,0c-0.0007973,0-0.4179611,0.1990299-1.2339783,0.2069912
		c-0.5771828,0.0055714-1.6885624,0.0716496-2.5833988,0.9465809c-0.68466,0.6695347-0.9744434,1.2100964-1.4600754,1.5794945l0,0
		c0.3057079-0.3455143,0.5604668-0.7340202,0.7634754-1.1185436c0.8613968-1.6312408,2.3779984-1.8955517,3.952713-1.9066982
		c0.706955-0.004776,1.0755539-0.3232231,1.3342934-0.8813c0.1560364-0.3359613,0.2022133-0.5636501,0.3192444-0.8781157l0,0
		c0.2547569-0.0851841,0.502346,0.1448927,0.4370651,0.4052229c-0.1361351,0.5405636-0.3757668,1.304039-0.5540962,1.6622906
		c-0.6599808,1.3247356-1.4990845,2.622406-3.3978214,3.2163086c-0.4426403,0.138525-0.8964272,0.2794361-1.2952805,0.5150871
		c-0.2117672,0.1249905-0.5310097,0.3486996-0.7284451,0.643261c-0.3518848,0.5867386-0.4585648,1.3462334-0.4649315,1.5396881
		c-0.0079613,0.2834187,0.0867767,2.1375732-0.2014179,2.7688923c-0.3805428,0.8359222-1.7180176,1.7458839-2.9559784,2.1845417
		c-0.2706795,0.0955353-0.6161957,0.4092045-0.6496315,0.6591835c-0.0636902,0.478466-0.0525436,0.9672813-0.0406017,1.4115143
		c0.0087566,0.3200378,0.2093792,0.6026611,0.5079231,0.7173023c1.2092991,0.4617462,2.331028,0.8988152,3.4646969,1.299263
		c0.281826,0.099514,0.6607742,0.1313553,0.9282722,0.0254745c0.5158825-0.203804,1.0007172-0.5047379,1.4648514-0.8152218
		c0.179924-0.1202145,0.2674961-0.3781548,0.3972607-0.573204l0,0c-0.1934547-0.0756321-0.3574543-0.0222912-0.5580769-0.0597095
		c-0.5620575-0.1050873-1.3446388-0.0628929-1.7172222-0.4283104l0,0c0.8104458-0.3120785,1.784893-0.1114559,2.3389893-1.2825413
		l0,0c-1.2546806,0.4386597-2.8142719,0.6130104-2.8596497,0.5294151l0,0
		c0.8661747-0.8502502,2.5189114-0.980814,2.5897636-2.668581l0,0c-0.8781147,0.5740013-1.4839573,1.6137276-2.9050255,1.2729912
		l0,0c0.8828926-0.7157097,1.6121349-1.3072224,2.5945415-2.1033382l0,0
		c-1.1304855-0.3272038-1.2435322-0.529417-1.2960777-1.2713985c-0.0135345-0.189476-0.0127373-0.9370289-0.099514-1.0890865
		c-0.1106606-0.194252-0.2659016-0.3518829-0.4211464-0.4752827l0,0c0.6966019,0.1552429,1.2928925,0.4545841,1.3191662,1.2236309
		c0.0135345,0.3940773,0.0079613,0.6862507,0.3272018,0.9672813c0.0207005,0.0183086,0.0421944,0.0382118,0.0644855,0.0549316
		c0.3717861,0.2778454,0.7348156,0.5111065,1.0453014,0.8653774c0.296154,0.3383484,0.5381737,0.7220783,0.7244644,1.1304836
		c0.3709908,0.8144264,0.5222511,1.7299614,0.4896126,2.6144466c-0.0143318,0.3821354-0.070858,0.7547188-0.1377296,1.1304855
		c-0.0398064,0.2245064-0.0907593,0.5891247-0.3670082,0.6424675c-0.7945251,0.1552429-1.204525,0.7157059-1.4170856,1.408329
		c-0.0867767,0.7284431-0.1783295,1.4115143,0.0087566,2.1335907c0.1281719,0.495182,0.490406,1.7602119,0.6766968,2.4066582
		c0.0533409,0.1854935-0.1026993,0.3622322-0.293766,0.3359604c-0.1695747-0.02388-0.3455143-0.0628929-0.5262318-0.106678
		c-0.9449883-0.2308731-1.947298-0.0613022-2.7601357,0.47369c-0.0199032,0.0127373-0.0366192,0.02388-0.0493584,0.0334358
		c1.2530861-0.3279991,2.4249687-0.0867767,3.0244446,0.1807175c0.3877087,0.17276,0.6950092,0.4076118,1.4728146,0.8430862l0,0
		c0,0-0.0031853-1.1870079,0.004776-1.7172203c0.007164-0.4673195,0.4927979-0.8287582,0.8383102-0.6782913
		c1.0500755,0.4561768,0.5525055,3.1311264,0.5525055,4.1071625l0,0c0.0007973-0.0015907,1.8493767-4.0968132,2.0062141-4.4447136
		l0,0l0.1488724,1.2897072l0,0c0.0007973-0.0007973,2.8086967-4.0514336,4.120697-6.1030235
		c0.1034927-0.1616135,0.2507744-0.7531261,0.2826195-0.9370289c0.3518829-2.0117836,0.1687775-2.5419979-0.0573196-4.0116291
		C41.1464233,26.2604351,40.8343468,25.5359688,40.5764046,24.5511742z'
                        />
                        <path
                            id='RT-_x31__4_'
                            className='st0'
                            d='M28.3138294,25.116415c0-0.4896107-0.4601555-0.7165051-0.4601555-0.7165051
		c-0.0676689,0.8462715-0.9179211,1.209301-2.8389492,1.2419415c-1.9202309-0.0326405-2.8015327-0.3956699-2.8692036-1.2419415
		c0,0-0.4601536,0.2268944-0.4601536,0.7165051c0,0.5039425,0.5158825,0.6090279,0.6878433,0.6567955
		c0.4768734,0.1313591,1.1878052,0.4513988,2.6104641,0.4513988c1.4218636,0,2.1662312-0.3200397,2.6431046-0.4513988
		C27.7987423,25.7262402,28.3138294,25.6203575,28.3138294,25.116415z'
                        />
                    </g>
                </g>
            );

        case 'AB':
            return (
                <g {...props}>
                    <g>
                        <path
                            className='st1'
                            d='M24.3532066,26.3854694c-0.1571674-0.2358093-0.1571674-0.2358093-0.2358646-0.5502586
		c-0.0785847-0.3143921-0.1571693-0.4716167-0.2358665-0.4716167c-0.0785847-0.0786419-0.0785847-0.0786419-0.1571674-0.2358665
		c0-0.1571674,0.0785828-0.3143921-0.1571693-0.3143921c-0.2358646,0-0.8646507-0.2358093-1.3363819-0.393034
		c-0.4717331-0.1572266-1.0219345-0.3144512-1.0219345-0.3144512l-0.6287861-0.3144493c0,0-0.3145638-0.1572247-0.3145638-0.393034
		c0-0.1572247-0.1571674-0.1572247,0-0.393034c0.1572819-0.2358093,0.4717331-0.2358093,0.4717331-0.3144493
		c0-0.0785847,0.0785828-0.3144493,0.0785828-0.3144493V22.219265c0,0,0.4717331-0.3144493,0.786068-0.3144493
		c0.3144493,0,0.4717312-0.2358093,0.5503159-0.3144493c0.0785847-0.0785847,0.2357521-0.3144493,0.2357521-0.3144493
		s0.6289005,0.07864,0.786068,0.07864c0.1572819,0,0.3145638,0,0.3145638-0.07864
		c0-0.0785847,0.0785828-0.393034-0.2358665-0.5502605c-0.3144493-0.1572247-0.4716167-0.2358074-0.5502014-0.3143921
		c-0.0786972-0.1572247-0.0786972-0.1572247-0.0786972-0.1572247l-0.3144512-0.7074833c0,0-0.5502014-0.3144512-0.7074833-0.3144512
		c-0.1571674,0-0.4716167,0.0786419-0.7074833,0.2358093c-0.3143368,0.1572247-1.1004047,0.3144493-1.2576866,0.5502586
		c-0.1572819,0.2358665-0.6288986,0.4716759-0.6288986,0.4716759s-0.3144493,0.2358074-0.3144493,0.1572247
		c0-0.0785847-0.0785847-0.3144493-0.0785847-0.3144493c0-0.0785847,0.2358665-0.3144512,0.2358665-0.3144512
		s0.393034-0.4716167,0.3144493-0.4716167s0-0.1572247,0.0785847-0.393034
		c0.1571674-0.2358665,0.6288986-0.7074833,0.6288986-0.8647099c0-0.1572247,0.1571674-0.393034,0.1571674-0.5502586
		s0.0785847-0.3144493,0.0785847-0.393034c0-0.07864,0.1571674-0.2358646,0.1571674-0.2358646
		s0.3145638-0.2358093,0.3145638-0.314394c0-0.07864-0.2358646-0.5502586-0.2358646-0.6288414
		c-0.0786991-0.0786419-0.1572819-0.1572247-0.1572819-0.3144503c0-0.0786409-0.2357521-0.393034-0.2357521-0.4716749
		c0-0.0785837,0-0.1572247-0.1571693-0.2358093c-0.1572819-0.1572247,0-0.3144493-0.4717312-0.3144493
		c0,0,0.3144493,1.0219336-0.3144493,2.2796764c-0.6287861,1.2577419-1.0219345,1.8865852-1.1791019,2.3582592
		c-0.1571674,0.393034-0.2357521,1.1791019-0.786068,1.1791019c0,0-0.1571674-0.6288433-0.3144493-0.786068
		c-0.1572819-0.2358093-0.3144493-0.471674-0.3144493-0.471674l0.9432354-0.8646526c0,0-0.3143368-0.3144493-0.5502014-0.3144493
		c-0.2358665,0-0.3144512-0.0786419-0.4716187-0.1572247c-0.1571674-0.0786419-0.2358665-0.1572266-0.3144493-0.1572266
		c-0.0785847,0-1.9652262,0.8647099-1.9652262,0.8647099s0.0786409-0.393034,0.7074833-0.8647099
		c0.5502586-0.3930321,0.8647089-0.6288414,0.8647089-0.6288414s0.7074833-0.1572247,1.0219326-0.2358665
		c0.3144512,0,1.2576866-0.0785847,1.2576866-0.0785847s-0.4716187-0.9432907-0.7074833-1.0219326
		c-0.2357521-0.0785837-1.8865862,0.3144493-1.8865862,0.3144493s-0.393034,0.2358093-0.7074833,0.3144512
		c-0.314393,0.0785828-1.0218763,0.4716167-1.0218763,0.4716167l-0.9432926,0.7861252h-0.4716749l0.0785837,0.6288433
		c0,0-0.0785837,0.3144493,0.0786409,0.6288986c0.1572247,0.314394,0.3144503,0.393034,0.3144503,0.393034
		s0.4716177-0.07864,0.4716177,0c0,0.0785847-0.0785847,0.393034-0.0785847,0.393034
		c0,0.0785847,0.0785847,0.2358093,0.0785847,0.3144493c0,0.0785847,0.0786409,0.2358093,0,0.2358093
		c-0.0785847,0-0.3144503,0-0.3144503,0l-0.4716177-0.393034c0,0-0.393034-0.07864-0.4716749-0.07864
		c-0.0785837,0-0.2358084,0-0.2358084,0.07864c0,0.0785847,0.0785837,0.1572247,0,0.2358093
		c-0.0786409,0.0785847-0.0786409,0.393034,0,0.471674c0.0785837,0.0785847,0.1572247,0.4716187,0.1572247,0.4716187
		s-0.6288996,0.9432926-0.7074842,1.6507759c0,0.7074833-0.1572247,0.8647079,0,1.2577419
		c0.1572256,0.393034,0.3144503,0.786068,0.3144503,0.9432926c0,0.0786419,0.2358093,0.6289005,0.2358093,0.6289005
		s0.2358084,0.3143921,0.3144493,0.393034c0.0785837,0.0785828,0.8646517,0.6288433,1.1791019,0.6288433
		c0.3144493,0,1.1791582-0.2358093,1.3363266-0.393034c0.2358656-0.1572266,0.5502586-0.3144512,0.6288996-0.3144512
		c0.0785837-0.0785828,0.1572247-0.0785828,0.2358084-0.1572247c0.0786982-0.0785828,0.3144503-0.1571674,0.3144503-0.2358093
		c0.0785837-0.0785828,0.1571674-0.2358074,0.1571674-0.2358074s0.3144493-0.2358093,0.3931484-0.1572266
		c0.0785828,0.0785847,0.3929195,0,0.3929195,0s0.0785847-0.1572247,0.2358665-0.2358074
		c0.0786972-0.0786419,0.3144493-0.393034,0.3144493-0.393034s0-0.3930912,0.0785847-0.5502586
		c0.0785828-0.1572247,0.1571674-0.3930912,0.1571674-0.5502586c0-0.1572266,0-0.3144512,0-0.3930912
		c0-0.0785847,0-0.1571674,0-0.314394c0-0.07864,0-0.3144493,0.0786972-0.3930321
		c0.0445309-0.0445881,0.0627537-0.066227,0.0710678-0.1032429c-0.0224361,0.0321178-0.044075,0.0625839-0.0710678,0.1031857
		c-0.1572819,0.2358646-0.1572819,0.1572247-0.393034,0.1572247c-0.2358646,0-0.1571674-0.0785847-0.1571674-0.1572247
		c0-0.0785847-0.0786972-0.393034-0.0786972-0.4716187c0-0.07864-0.2358665-0.471674-0.2358665-0.5502586
		c0,0,0.3145638,0,0.3931484,0c0.0785828,0,0.1571674,0,0.3929195,0.0785847
		c0.0585384,0.0195313,0.0974903,0.0390644,0.1243668,0.0585957c0.0977173-0.0232906,0.2223129-0.0763054,0.2687817-0.215765
		c0.1571674-0.2358646,0.3143349-0.3144493,0.3143349-0.3144493l0.3145638,0.393034c0,0,0.3143368,0,0.3143368,0.1572247
		s-0.0785847,0.1572247,0,0.393034c0.0785828,0.2358093,0.1572819,0.393034,0.0785828,0.4716759
		c0,0.0785828-0.3143349,0.6288414-0.3143349,0.6288414s-0.786068,1.3363266-0.8646526,1.6507759
		c-0.0786972,0.3144512-0.7074833,0.8647099-0.8647652,1.1005192c-0.1572819,0.2358074-0.4716187,0.6288986-0.7074833,0.7074833
		c-0.1572819,0.0785828-0.8647089,0.2358093-1.0219336,0.2358093c-0.1571684,0-0.314393,0-0.4716177,0.1572247
		c-0.1572256,0.1572247,0.0785837,0.3144493,0.0785837,0.3144493l0.6288996,0.393034c0,0,0.393034,0.1572247,0.5502014,0.0785847
		c0.2358665,0,0.7074833-0.0785847,0.786068-0.1572266c0.0786972,0,0.9433498-0.7860661,1.1006317-1.0218754
		c0.1571674-0.2358093,0.0785847,0,0.2357521-0.393034c0.1571674-0.3144493,0.3144493-0.6289005,0.3144493-0.6289005
		s0.0786972-0.1572247,0.1572819-0.3143921c0.0785847-0.1572247,0.2357521-0.4716759,0.2357521-0.4716759
		s0.1571674-0.5502586,0.3144493-0.6288414c0.1572819-0.0786419,0.2358665-0.1572247,0.3144512-0.3144512
		c0-0.1572247,0.1571674-0.3144493,0.1571674-0.3144493s0.1571674,0,0.1571674,0.1572247s0,0.4716759,0,0.4716759
		s0.1572819,0,0.2359791,0.1572247c0,0.2358093,0.0785847,0.393034,0.0785847,0.393034l0.3929195,0.3143921
		c0,0,0.3931484,0.4716759,0.5503159,0.4716759c0.1571693,0,0.6289005,0.393034,1.0219345,0.5502586
		s0.5502014,0.471674,0.786068,0.6288414c0.2358646,0.1572266,0.4716167,0.2358665,0.7074833,0.3144512
		c0.2358646,0.07864,0.4716167,0.2358074,0.5502014,0.07864C24.510376,27.0929527,24.510376,26.621336,24.3532066,26.3854694z
		 M14.920167,19.3893318c0,0,0.2358093,0.0785828,0.3144503,0.1572247c0.0785837,0.0785828,0.0785837,0.2358074-0.1572247,0.393034
		c-0.2358093,0.1572247-0.4716749,0.2358074-0.4716749,0.2358074s-0.0785837-0.393034-0.0785837-0.4716167
		C14.5271339,19.6251392,14.920167,19.3893318,14.920167,19.3893318z M15.2346172,20.9615231
		c-0.0786409,0.0785847-0.0786409,0.0785847-0.1572247,0.1571674c0,0,0,0-0.0786409,0c0,0,0,0-0.0785847,0
		c0,0-0.0785837,0-0.0785837-0.0785828c-0.0786409-0.0785847,0.0785837-0.0785847,0.1571684-0.0785847
		c0.0786409,0,0.0786409-0.07864,0.1572247-0.07864C15.2346172,20.8042984,15.2346172,20.8828831,15.2346172,20.9615231z
		 M14.5271339,21.9834003c0.1572247-0.2358093,0.2358084-0.5502586,0.393033-0.5502586
		c0.1572256-0.0785847,0.393034-0.1572247,0.393034-0.2358093c0.0786982-0.0786419,0.3144503-0.1572247,0.393034-0.1572247
		s0.1571684-0.0785847,0.2358656,0c0.0785847,0.0785828,0.2358665,0.3144493,0.2358665,0.3144493s0,0.6288433-0.1572819,0.8646526
		c-0.1572819,0.2358646-0.3144503,0.3930893-0.5502024,0.471674c-0.2358656,0.0785847-0.4717312,0.0785847-0.6288996,0
		c-0.0786409,0-0.3144493,0-0.3930902-0.0785847C14.448493,22.533659,14.3699083,22.2192097,14.5271339,21.9834003z
		 M12.6405487,21.2759171c0,0,0.3143921-0.0785847,0.393033-0.0785847c0.0785847,0,0.1572256-0.0786419,0.1572256-0.0786419
		c0.0785837,0,0.1571674-0.0785828,0.1571674-0.0785828s0-0.1572247,0.1572256-0.2358093
		c0.1572247-0.0786419,0.3144493,0.0785847,0.471674,0.2358093c0.1572256,0.0785828,0.393034,0,0.393034,0l-0.0785837,0.2358093
		c0,0-0.2358656,0.2358646-0.3144503,0.3144493c-0.0786409,0.0785828-0.3144493,0.2358093-0.471674,0.2358093
		c-0.1572256,0.07864-0.1572256,0.07864-0.314393,0c-0.2358665-0.0785847-0.5502586-0.2358093-0.5502586-0.2358093
		C12.6405487,21.5117817,12.6405487,21.2759171,12.6405487,21.2759171z M13.5838413,25.2063675
		c-0.1572256,0.0785847-0.6289005,0.1572266-0.7861252,0.1572266c-0.1571674,0-0.2358084-0.1572266-0.2358084-0.1572266
		s-0.3144503-0.471674-0.393034-0.7074833c-0.0785847-0.2358093-0.2358093-0.3144493-0.2358093-0.471674
		c0-0.1571674,0.1572247-0.4716187,0.1572247-0.6288433s0-0.6288986,0-0.6288986l0.0785847-0.393034l0.2358084-0.2358093
		l0.2358665-0.2358093c0,0,0.3143921-0.1572247,0.393033,0.0785847c0,0,0,0.471674,0.0785847,0.7074833
		c0.0786409,0.1572247,0.1572247,0.471674,0.1572247,0.6288986c0,0.1571693,0,0.314394,0,0.393034
		c0,0.0785847,0.2358093,0.2358093,0.3144503,0.393034c0.1572247,0.0785847,0.3143921,0.314394,0.393033,0.393034
		c0.0785847,0.0785847,0.2358093,0.4716759,0.2358093,0.4716759S13.741066,25.1277275,13.5838413,25.2063675z
		 M16.1779671,23.9486256c0,0.1572247-0.3931484,0.1572247-0.5503159,0.3144493
		c-0.1571684,0.1572266-0.2357521,0.1572266-0.393034,0.314394c-0.1572247,0.1572247-0.393034,0.1572247-0.4716749,0.1572247
		c-0.1572247,0-0.1572247-0.1572247-0.1572247-0.1572247s0-0.314394,0-0.393034c0-0.0785847-0.0785837-0.3143921-0.2358093-0.393034
		c-0.1572247-0.0785847-0.2358084-0.5502586-0.0785837-0.6288433c0.1571684-0.07864,0.5502586,0.1572247,0.5502586,0.1572247
		s0.1571684,0,0.393034,0.0785847s0.3144493,0.1572247,0.4716177,0.1572247s0.7074842,0,0.7074842,0
		S16.2565498,23.7914009,16.1779671,23.9486256z M16.4923019,21.0401077h-0.0785828h-0.0785847
		c-0.0785847,0-0.0785847-0.0785847-0.0785847-0.1572247c0,0,0-0.0785847,0.0785847-0.0785847
		c0.0785847-0.0786419,0.2357521-0.0786419,0.3143368,0C16.6495838,20.8828831,16.6495838,21.0401077,16.4923019,21.0401077z
		 M19.715271,21.5903664c0-0.0785847-0.0785847-0.2358093-0.0785847-0.3144493c0-0.1572266,0.1572819-0.1572266,0.2358665-0.314394
		c0.0785847-0.1572247,0.3143368-0.1572247,0.3929195-0.1572247c0.1572819-0.0786419,0.3145638-0.1572247,0.3145638-0.1572247
		l0.6289005-0.1572247c0,0.0785828,0.1571674,0.393034,0,0.5502586c-0.1572819,0.0785828-0.3145638,0.3144493-0.3931484,0.393034
		c-0.0785828,0.0785828-0.393034,0.393034-0.4716167,0.393034c-0.0786991-0.1572266-0.3144512-0.1572266-0.393034-0.1572266
		C19.7939682,21.6689491,19.715271,21.6689491,19.715271,21.5903664z'
                        />
                        <path
                            className='st1'
                            d='M31.506628,32.7527657c-0.0785847-0.1572227-0.1572819-0.3144493-0.3145638-0.3144493
		c-0.1571674-0.0785828-0.3143368-0.0785828-0.4715042-0.0785828c-0.3145638,0-0.6288986,0-0.8647652,0.0785828
		c-0.0785847,0-0.2358665,0.07864-0.3144493,0.07864c-0.1571693,0-0.2357521,0-0.3143368-0.07864
		c0,0,0-0.0785828-0.0786972-0.0785828c0.2358646-0.5502605,0.4716167-1.1005192,0.6288986-1.6507778
		c0-0.0785847,0.0785847-0.2358093,0.0785847-0.3144493c0-0.0785847-0.0785847-0.1572247-0.0785847-0.2358093
		c-0.3144493-0.5502586-0.7861805-0.9432926-1.3363819-1.2577419c-0.3144512-0.1572247-0.6287861-0.2358093-0.9433498-0.1572247
		c-0.0785847-0.0785847,0-0.1572247,0.0785828-0.1572247c0.5503159-0.0785847,1.022049-0.0785847,1.5722504-0.1572266
		c0.2358646,0,0.393034,0,0.6287861,0.0786419c0.2359791,0.1572247,0.3145638,0.393034,0.3931465,0.6288433
		c0.1571693,0.2358646,0.393034,0.3930321,0.6289005,0.3144493c0.0785828-0.393034,0.1571674-0.786068,0.0785828-1.1791019
		c0-0.2358665-0.0785828-0.471674-0.3144493-0.7074833c-0.0786972-0.0786419-0.1572819-0.1572247-0.2358646-0.2358665
		c0-0.0785828-0.0785847-0.0785828-0.0785847-0.1572247c0-0.0785847-0.0785847-0.0785847-0.1571674-0.1571674
		c-0.0785847-0.0786419-0.2358665-0.0786419-0.3145638-0.0786419c-0.1571693,0.0786419-0.1571693,0.2358093-0.2357521,0.4716759
		c0,0.0785828,0,0.0785828,0,0.0785828v0.0786419c0,0.1571674,0,0.2358093,0,0.393034c-0.0785847,0-0.1571693,0-0.2357521-0.0786419
		c-0.0786991-0.0785828-0.0786991-0.1572247-0.1572819-0.2358074c-0.1572819-0.1572266-0.393034-0.1572266-0.6289005-0.1572266
		c-0.2358646,0-0.393034,0.0786419-0.5502014,0.1572266L27.890625,27.721796c-0.0785828,0-0.0785828,0-0.0785828-0.0785828
		c-0.0786991-0.0785847-0.0786991-0.3144512,0-0.393034c0.0785828-0.0786419,0.2357521-0.1572266,0.3143349-0.2358093
		c0.0785847,0,0.0785847-0.0786419,0.0785847-0.1572247c0-0.0786419-0.0785847-0.0786419-0.0785847-0.1572247
		c-0.3143349-0.3144512-0.6288986-0.5502605-0.9432354-0.8647099c-0.1572819-0.1572247-0.3931484-0.3144493-0.6288986-0.3144493
		c-0.2358665,0-0.4717331,0.2358665-0.393034,0.471674c0-0.1572247,0.2358646-0.2358074,0.393034-0.2358074
		c0.1571674,0.0785828,0.2357502,0.2358074,0.3143349,0.3930321c0,0.1572266,0,0.314394,0,0.4716187
		c-0.0785847,0.3144493-0.0785847,0.6289005-0.1571674,1.0219345c0,0.0785828,0,0.0785828,0,0.0785828l-0.0785847,0.0786419
		c-0.3144493,0.0785828-0.6288986,0.2358074-0.8646507,0.393034c-0.1572819,0.1572247-0.3931484,0.3144493-0.5503159,0.4716167
		c0-0.1572247,0.0785828-0.3143921,0.0785828-0.393034c0.0785847-0.5502586,0.1572819-1.1005173,0.3144512-1.572134
		c-0.1572819,0.3930321-0.2358665,0.7860661-0.393034,1.1791c-0.0785847,0.3144512-0.2357521,0.7074852-0.2357521,1.0219345
		c-0.1572819,0.1572247-0.3145638,0.3143921-0.5503159,0.393034c-0.0785847,0-0.0785847,0.0785828-0.1571674,0.0785828
		c-0.0785847,0.0786419-0.0785847,0.0786419-0.0785847,0.1572247c-0.1572819,0.2358093-0.3145638,0.4716759-0.5503159,0.7074852
		c-0.0785847,0.07864-0.1571674,0.07864-0.2358665,0v-0.0785847c0-0.1572247-0.2358646-0.2358665-0.3930321-0.2358665
		c-0.1571693,0-0.3144512,0.1572266-0.393034,0.2358665c-0.0785847,0.0785847-0.1572819,0.0785847-0.2358665,0.0785847
		s-0.0785847-0.1572247-0.0785847-0.2358093c0-0.4716759,0.0785847-0.9432926,0.0785847-1.3363838
		c0.1572819-0.0785828,0.2358665-0.0785828,0.3931484-0.1571674c0.0785828-0.2358665-0.0786991-0.4716759-0.235981-0.7074833
		c-0.1571674-0.2358665-0.4715042-0.8647099-0.786068-1.0219345c-0.3929195-0.1572247-0.4715023,0.4716759-0.4715023,0.7074833
		c0,0.2358093,0.0785828,0.5502586,0.0785828,0.786068c0,0.1572247-0.0785828,0.2358665-0.1572819,0.393034
		c-0.0785828,0.2358665-0.0785828,0.5502586-0.0785828,0.8647079c0,0.393034,0,0.7074852,0,1.1005192
		c-0.393034,0.2358646-0.7861824,0.5502586-1.1791019,0.8647079l-0.0785847,0.0785847
		c-0.0786972,0.07864-0.2359791-0.0785847-0.3145638-0.1571693c-0.0785847-0.1572247,0.0786972-0.3144493-0.0785847-0.3144493
		c-0.0785828,0-0.2357502,0.1572247-0.3143349,0.2358093c-0.1572819,0.2358093-0.2358665,0.471674-0.2358665,0.786068
		c-0.0786972,0.3144493,0,0.7861252,0.3144512,0.9433479c0.2357502,0.1571693,0.5502014,0.0785866,0.8646507-0.07864
		c0.2357521-0.1572266,0.4717312-0.3143921,0.786068-0.3930321c0.0785847,1.0219307,0.1571674,1.9652252,0.1571674,2.9871025
		c0,0.07864,0,0.1572227,0,0.3144493c-0.0785828,0.07864-0.1571674,0.1572227-0.1571674,0.2358093
		c-0.2357521,0.2358627-0.2357521,0.6288986-0.1571674,0.8647079c0.0785828,0.3144493,0.3143349,0.5502586,0.5503159,0.786068
		c0.1571674,0.1572227,0.2357502,0.3144493,0.3929195,0.4716721c0,0.0785866,0.3931465,0.0785866,0.3144493,0
		c0-0.5502586-0.0785847-1.1005173-0.1571674-1.7293587c0.0785828-0.4716759,0.1571674-0.8647079,0.3144493-1.3363838
		c0.3143368-0.786068,0.7074833-1.6507759,0.5502014-2.5154839c-0.0785828,0.2358665-0.1572819,0.5502586-0.2358646,0.7861252
		c-0.1571693,0.4716187-0.6287861,0.786068-0.6287861,1.1791c-0.0786991-0.8647079-0.0786991-1.7293587-0.0786991-2.6726532
		c0.5503159-0.3930893,1.1006317-0.6288986,1.7295322-0.7861233c0.0785828,0,0.1571674,0.07864,0.1571674,0
		c0-0.0785847,0-0.2358093,0-0.3144512c0-0.0785828,0-0.1571674,0.0785847-0.2358074v-0.0785847
		c0.0785828-0.0786419,0.2357521-0.1572247,0.3144493-0.2358665c0.1571674-0.1571674,0.3144493-0.3143921,0.4716187-0.4716167
		c0.0785828-0.0785847,0.1571674-0.2358093,0.3144493-0.1572247c0.0785828,0,0.0785828,0.0785828,0.1572819,0.0785828
		c0,0.2358665,0,0.4716759,0.0785828,0.4716759c0,0.0785828,0,0.1572247,0.0785847,0.2358074
		c-0.2358665,0.5502605-0.4717312,1.0219345-0.6288986,1.5721951c0,0.0785828,0,0.1572227-0.0785847,0.1572227c0,0,0,0-0.0785847,0
		c-0.0785828,0.0785828-0.1572819,0.2358093-0.1572819,0.3930359c0,0.1572227,0.0786991,0.1572227,0.2358665,0.2358055
		c0,0,0,0.0786438,0.0785847,0.0786438c0.0785828,0.3930321,0.3930321,0.786068,0.5503139,1.1005173
		c0,0.0785828,0.0785847,0.0785828,0,0.1572227c0,0.0785866-0.0785828,0.0785866-0.1572819,0.1571693
		c-0.4716167,0.1572266-0.9433479,0.3144493-1.3362694,0.6288986c0.1571693-0.2358093,0.3929214-0.5502586,0.5502033-0.786068
		c-0.3144512,0.3930359-0.6287861,0.7074852-1.0219345,0.8646545c-0.0785828,0-0.0785828,0.07864-0.0785828,0.07864v0.0785828
		c0.0785828,0.1572266,0.1571674,0.3144493,0.2357502,0.4716759c0.1572819,0.2358093,0.5503159,0.1572227,0.7861824,0.0785828
		c0.0785847,0,0.1572819-0.0785828,0.2358665-0.1572227c0.0785828,0,0.0785828-0.0785866,0.1571674-0.0785866
		c0.1571674-0.07864,0.3144493-0.2358093,0.393034-0.3144493c0.1572819-0.1572227,0.393034-0.3144493,0.6287861-0.5502586
		c0.1572819-0.1572227,0.3145638-0.2358093,0.4717312-0.3144493c0.1571674-0.0785828,0.3144493-0.1572266,0.4717312-0.2358093
		c0.3143368-0.1572227,0.6287861-0.3144493,0.9432354-0.4716187c0.3143368-0.1572227,0.7074852-0.2358665,1.0218201-0.2358665
		c-0.0785828,0.7074852,0.0786991,1.4935532,0.1572819,2.2010384c0,0.3930321,0,0.7074814-0.0785828,1.1005173
		c-0.0786991,0.3144493-0.1572819,0.6288986-0.3144512,0.9433479c-0.1571674,0.1571693-0.4716167,0.2358093-0.7074833,0.2358093
		c-0.3143368,0-0.5503159-0.07864-0.8646507-0.1572266c-0.2358665-0.0785828-0.5503159-0.1572227-0.786068-0.3144493
		c-0.2358665-0.1572227-0.3931484-0.3930321-0.5503159-0.6288414c-0.3144512-0.4716759-0.3144512-0.8647079-0.0785847-1.3363838
		c0,0,0,0,0.0785847,0c0.0785828-0.0785828,0.1571674-0.0785828,0.1571674-0.0785828c0.0785847,0,0.2358665,0,0.3931484,0
		c0.3143349,0.0785828,0.5502014,0.4716721,0.786068,0.7074814c0.0785828,0.0785866,0.2357502,0.2358093,0.3930321,0.3144493
		c0.0785847,0.0785866,0.1572819,0.0785866,0.3144512,0.0785866c0.3929195,0,0.3929195-0.3930359,0.3143349-0.7074852
		c0-0.1572227,0-0.2358093-0.0785828-0.3930321c-0.0785847-0.2358093-0.393034-0.3930359-0.6289005-0.4716759
		c-0.3143349-0.1571693-0.7074833-0.2358093-1.1004028-0.2358093c-0.3145638,0.07864-0.6289005,0.3144493-0.9433498,0.5502586
		c-0.2358665,0.2358093-0.4716187,0.4716759-0.5502014,0.786068c-0.0786991,0.2358665,0,0.5503159,0.1571674,0.7861252
		c0.393034,0.8647079,1.1005173,1.6507759,2.0438671,2.043808c0.9432354,0.3930359,1.8864708,0.4716759,2.8298206,0.1572266
		c0-0.3930321,0-0.8647079,0-1.2577438c0-0.1572227,0-0.3144493,0-0.3930321c0-0.07864,0-0.1572266,0.0786972-0.2358093
		c0-0.1572266,0-0.2358665-0.0786972-0.3930893c-0.0785847-0.4716187-0.0785847-0.9432945-0.0785847-1.4149132
		c0-0.2358627,0-0.5502586,0.2358665-0.7074814c0.0785847-0.07864,0.1571674-0.07864,0.1571674-0.1572266
		c0.0785847-0.07864,0-0.1572227-0.0785828-0.2358093c-0.1571693-0.3144493-0.393034-0.5502586-0.5503159-0.8647079
		l0.0785828-0.07864c0.2358665-0.1572266,0.3931484-0.2358093,0.7074852-0.2358093c0.5503159,0,1.1006298,0,1.6508331,0h0.0786972
		C31.5852127,32.9099922,31.5852127,32.8314056,31.506628,32.7527657z M25.3750267,29.3726292
		c0.0786972-0.07864,0.1572819-0.07864,0.2359791-0.1572247c0.0785847,0,0.1571693-0.07864,0.2357521-0.07864
		c0,0.07864,0,0.1572247-0.0785828,0.1572247c-0.0785847,0.07864-0.0785847,0.07864-0.1571693,0.1572247
		c0,0.07864,0,0.07864-0.0786972,0.1572247c-0.0785847,0.0785847-0.1572819,0-0.2358665-0.0785847
		C25.296442,29.5298538,25.296442,29.4512138,25.3750267,29.3726292z M26.0039272,33.5388336h-0.0785847v0.0000572
		c-0.1571674,0.0785828-0.3143368,0.1571693-0.4716187,0.2358093c-0.0786972,0-0.1572819,0.0785828-0.2358646,0
		c0,0,0-0.07864,0-0.1572266c0-0.1572227,0-0.3144493,0.0785828-0.3144493c0.0785847,0,0.1572819-0.0785828,0.1572819-0.0785828
		v-0.07864c0-0.1572266-0.3144493-0.4716187,0-0.5502586h0.0785847c0.1572819,0.1572227,0.3144493,0.3144493,0.393034,0.4716721
		c0.0785847,0.0785866,0.1571674,0.1572266,0.1571674,0.3144493C26.08251,33.4602509,26.08251,33.5388336,26.0039272,33.5388336z
		 M26.5542431,32.202507c0,0,0,0.07864-0.0785847,0.07864c0,0,0,0-0.0785847,0c-0.1572819,0-0.3145638,0-0.3145638-0.07864
		c-0.0785828-0.0785828,0-0.1572227-0.0785828-0.2358093c0-0.0785828-0.0785847-0.0785828-0.1571693-0.1572247
		c0.2357521-0.0785828,0.4716187-0.0785828,0.7074852-0.0785828c0,0.0785828,0.0785828,0.1572247,0.0785828,0.2358074
		C26.6328259,32.0452843,26.5542431,32.1239243,26.5542431,32.202507z M26.0039272,30.8661804
		c0.0785828-0.07864,0.0785828-0.07864,0.1572819-0.1572247v-0.0785847c0-0.07864,0.0785828-0.07864,0.1572819,0
		c0.0785828,0,0.0785828,0,0.1571674,0c0.0785847,0.0785847,0.0785847,0.1571693,0,0.2358093l-0.0785847,0.0785847
		c-0.1572819,0.07864-0.2358646,0.1572247-0.3931465,0.1572247C25.9253426,31.0234051,26.0039272,30.9447651,26.0039272,30.8661804z
		 M26.6328259,29.3726292c0,0,0,0-0.0785828,0.0785847c-0.0785847,0.0785828-0.1571693,0.1572247-0.3144512,0.1572247
		c-0.0785828,0-0.1572819,0.0785847-0.1572819,0.1572247c0,0,0,0.0785847-0.0785828,0.0785847
		c0,0.07864-0.0785847,0.1572247-0.0785847,0.1572247s0,0,0,0.07864h-0.0785847c-0.0785828,0-0.1571674,0-0.1571674,0
		c-0.0785847,0-0.0785847-0.07864-0.0785847-0.1572247c0-0.07864,0.1571693-0.07864,0.2357521-0.1572247
		c0.0785847-0.1572247,0.1571693-0.2358093,0.1571693-0.393034c0-0.07864,0-0.07864,0.0785828-0.1572247
		c0-0.07864,0-0.07864,0.0786991-0.1572247c0.1572819-0.2358093,0.393034-0.2358093,0.5502014-0.0786419
		C26.7114105,29.1367645,26.7114105,29.2154045,26.6328259,29.3726292z M27.9692097,31.809473l-0.0785847,0.0785847
		c-0.1572819,0.1572266-0.3145638,0.2358665-0.5503159,0.2358665c0,0,0,0,0-0.07864c0-0.1572266,0-0.314394,0-0.4716206
		c0-0.07864,0-0.07864,0-0.1572247c0.0785847-0.1572247,0.5503159-0.3144493,0.7074852-0.2358074
		C28.2835464,31.3377991,28.0477943,31.6522484,27.9692097,31.809473z M27.8120422,30.1586971
		c-0.0786991,0.0785847-0.0786991,0.0785847-0.0786991,0.1572247c0,0.1572247-0.1572819,0.2358093-0.3144493,0.2358093
		c-0.0785847,0-0.0785847-0.0785847-0.1571674-0.0785847c0.0785828-0.07864,0.1571674-0.2358074,0.1571674-0.3144493
		c0-0.0785847,0-0.1572247,0-0.3144493v-0.0785847c0.2358665-0.1572247,0.4717312-0.2358093,0.786068-0.2358093
		c0.0785847,0.0785847,0.2358665,0.0785847,0.3145638,0.1572247c0,0,0,0,0.0785847,0
		C28.7552776,30.0801125,28.0477943,30.1586971,27.8120422,30.1586971z'
                        />
                        <path
                            className='st0'
                            d='M25.2964954,8.6985826h0.0786095c0.0786095,0,0.0786095-0.0786104,0.0786095-0.0786104
		c-0.0786095-0.1572199-0.1572189-0.2358265-0.2358265-0.3930416l0,0c-0.0786095-0.1572161-0.1572189-0.2358265-0.1572189-0.3930421
		c0,0-0.0786114-0.0786066-0.0786114,0c0,0-0.0786057,0-0.0786057,0.0786104
		c-0.0786114,0.3144326,0.2358265,0.4716525,0.3930416,0.707478C25.2178879,8.6199722,25.2178879,8.6199722,25.2964954,8.6985826z'
                        />
                        <g>
                            <polygon className='st0' points='17.2034302,42.5856743 17.2030888,42.5791817 17.1997871,42.5788383 		' />
                            <path
                                className='st0'
                                d='M24.3721123,10.1515551c0.0120735-0.0079718,0.0243721-0.0133247,0.0364456-0.0232334
			C24.3954601,10.1389132,24.3842983,10.1446075,24.3721123,10.1515551z'
                            />
                            <path
                                className='st0'
                                d='M17.5928211,43.2863235c0.1572819,0.1572266,0.3930321,0.2358093,0.5502014,0.3144493
			c0.2358646,0.0785828,0.3931465,0.0785828,0.6288986,0c-0.2357521-0.07864-0.4716167-0.07864-0.7074833-0.1572227
			c-0.3906422-0.1562576-0.7029285-0.4681473-0.8610077-0.8578758c0.0020504,0.1286926,0.0105915,0.2574463,0.0749397,0.3862
			C17.3569546,43.129097,17.514122,43.2076836,17.5928211,43.2863235z'
                            />
                            <path
                                className='st0'
                                d='M43.7290573,20.1303558c0.1332512,0.4572678,0.0993118,0.982357-0.1298332,1.4666729
			c0.1015892-0.1070576,0.1953201-0.214056,0.248848-0.3211117C44.1097908,20.9020157,44.0094528,20.4604073,43.7290573,20.1303558z
			'
                            />
                            <path
                                className='st0'
                                d='M42.5903854,18.9962978c-0.550312-0.0786419-1.1005173,0.1572247-1.4149666,0.5502586
			c-0.5503159,0.6288414-0.5503159,2.2010345-1.6507187,1.7293606c0-0.0785847,0-0.1572266-0.0785828-0.2358093
			c-0.0786972-0.1572247-0.0786972-0.1572247-0.235981-0.2358093c-0.0785828-0.0786419-0.2357521-0.0786419-0.3929214-0.1572247
			c-0.15728-0.0786419-0.2358627-0.2358665-0.3931465-0.393034c-0.3143349-0.3144493-0.5502014-0.4716759-0.5502014-0.9433498
			c0-0.1571674,0-0.3143921,0-0.4716187c-0.0785828-0.3930321-0.3930321-0.7074833-0.5502014-1.1005173
			c-0.0785828-0.3144493-0.1572838-0.5502586-0.2358665-0.8647079c0.1572838-0.3144493,0.3930359-0.5502586,0.4716187-0.9432926
			c0.0785828-0.2358093,0-0.393034,0-0.6288996c0-0.2358093,0-0.5502586,0.0785828-0.786068
			c0.0785866-0.3144493,0.3144493-0.6288424,0.3931503-1.0218763c0.0785828-0.3930912-0.078701-0.7074833-0.078701-1.1005745
			c0-0.5502586,0.1572838-1.1005173,0.3144531-1.572135c0-0.0786409-0.1571693-0.1572256-0.1571693-0.0786409
			c0,0-0.6288986,1.3363829-0.786068,1.0219336c0-0.0785847-0.1572838-0.0785847-0.1572838,0
			c0.0785866,0.786067,0,1.4935513-0.15728,2.2010345c-0.0785828-0.0785837-0.0785828-0.2358093-0.1571693-0.3144503
			c0-0.0785837-0.1571655-0.2358084-0.1571655-0.3143921c0,0-0.0785866,0-0.0785866,0.0785837c0,0.0786409,0,0.2358084,0,0.3144493
			c-0.0786972,0.5502596-0.15728,1.1005182-0.0786972,1.7293606c0,0.2358656,0.0786972,0.3930912,0.1572838,0.6288996
			c0,0.0785847-0.0785866,0.1572247-0.1572838,0c0,0-0.0785828,0-0.0785828-0.07864
			c-0.2358665-0.1572256-0.3930359-0.314394-0.3930359-0.4716187c-0.2358627-0.393034-0.1571655-0.7074833-0.2358627-1.1005173
			c-0.0785866-0.3144503-0.1571693-0.6288996-0.1571693-0.9432926c0-0.1572247,0.0785828-0.3930912,0-0.5503159
			c-0.1572838-0.786068-1.2576866-1.572135-0.6288986-2.4368439c0.0786972-0.0785837-0.0785866-0.1572247-0.1572838-0.0785837
			c-0.3143349,0.314393-0.3143349-0.2358665-0.3143349-0.5502586c-0.0785828-0.3930912-0.1571693-0.7861252-0.2358665-1.1791592
			c0-0.0785837-0.15728-0.0785837-0.15728,0c0,0.393034-0.0785866,0.786068-0.3143387,1.1791592
			c0,0.0785837,0,0.0785837,0.0785866,0.1571674c0.1571655,0.0786409,0.3144493,0.9433498,0.3930321,1.1791582
			c0.0786972,0.393034-0.0785828,0.786068-0.0785828,1.1791019c-0.0786972,0.393034,0,0.8647089-0.1572838,1.2577419
			c-0.0785828,0.393034,0,0.393034-0.3929176-0.0785837c-0.078701-0.1572247-0.235981-0.3144493-0.3145638-0.4716749
			c0.5503159,0,0.3145638-0.8647079,0.3145638-1.2577419c0-0.0785837,0-0.0785837-0.078701-0.0785837
			c0,0.0785837,0,0.1572247-0.0785828,0.1572247c-0.0786972,0.1572247-0.0786972,0.314393-0.2358665,0.4716177
			c-0.0785828,0.0786409-0.2357521,0.0786409-0.3143349-0.0785837c0-0.0786409,0-0.0786409,0-0.1572256c0,0,0,0,0-0.0785837
			c0-0.0786409,0-0.0786409,0.0785828-0.1572247v-0.0786409c0-0.1572247,0.0785866-0.1572247,0-0.314393
			c0-0.2358656-0.0785828-0.3930912-0.15728-0.6288996c-0.0785828-0.0785837-0.1571693-0.2358093-0.2358665-0.3144503
			c0,0.1572256,0,0.2358665,0,0.3930912c-0.0785828,0.1571674-0.1571693,0.4716177-0.3929214,0.4716177
			c-0.15728,0-0.2358627-0.0785837-0.3145618-0.1572247c-0.0785847-0.1572256-0.0785847-0.2358093-0.1571674-0.393034
			c-0.0785847-0.3144503-0.0785847-0.6288433-0.0785847-1.0219336c0-0.0785837-0.1571674-0.1571684-0.1571674,0
			c0,0.0786409-0.0785847,0.2358656-0.1572819,0.3144493c-0.0785847,0.1572256-0.1572819,0.3144503-0.3144512,0.3144503
			c-0.1571674,0-0.2357521,0-0.3143349-0.0786409l-0.0786991-0.0785837
			c-0.0785828-0.0786409-0.1572819-0.3144503-0.2358646-0.4716749c-0.1571674-0.2358093-0.3929195-0.4716177-0.6287861-0.707427
			c-0.0786972-0.0786409-0.2358665,0.0785837-0.1572819,0.1571684c0.1572819,0.7074833,0.1572819,0.9433489-0.1571674,0.6288996
			c-0.1571693-0.5502586-0.1571693-1.0219336-0.2358665-1.5721922c-0.0785847-0.3144503-0.1572819-0.5502591-0.393034-0.786068
			h-0.0785847c0.0785847,0.0785842,0.0785847,0.2358088,0.0785847,0.3144498c0,0.0785842,0,0.0785842-0.0785847,0.0785842
			c-0.0785828,0.0785837-0.3930321-0.4716749-0.5503139-0.6288428c-0.2357521-0.3144498-0.3929214-0.6288996-0.5502033-0.9433494
			l-0.0786972-0.0785842c0,0.1572251,0,0.3144498,0,0.393034c0,0.0785837-0.0785847,0.0785837-0.1571674,0.1572247h-0.0785847
			c0,0,0,0-0.0785847,0c-0.0785828-0.0786409-0.0785828-0.0786409-0.2358646-0.1572247
			c-0.0785847-0.0785842-0.2358665-0.1572251-0.3144512-0.2358088c0,0-0.0785828,0-0.1571674,0c0,0,0,0-0.0785828,0
			c0,0,0,0,0,0.0785837c0,0.0786409,0,0.0786409,0,0.1572251c0,0,0,0.0786409,0,0.1572247
			c0,0.0785842,0.0785828,0.0785842,0.0785828,0.0785842h0.0785847l0.0785828,0.0786409
			c0.2358665,0.0785837,0.2358665,0.2358088,0.0785847,0.5502586c0,0-0.0785847,0-0.0785847,0.0785842c0,0,0,0-0.0785828,0
			c-0.0785847,0-0.0785847-0.0785842-0.1571674-0.0785842c-0.3144512-0.2358088-0.5503159-0.4716749-0.8647671-0.7074838
			l-0.0785828-0.0785837h-0.0785847c0.0785847,0.1571679,0.1571674,0.3143926,0.3144493,0.4716177
			c0.4716187,0.4716749,0.9432354,0.9432921,1.4149685,1.3363833c-0.0785847,0.2358093-0.2358665,0.314393-0.3931484,0.1571684
			c-0.0785847-0.0785847-0.0785847-0.0785847-0.1571674-0.1571684c-0.0785847-0.1572247-0.1571674-0.3144503-0.0785847-0.4716749
			c0-0.0785837-0.1571674-0.1572247-0.1571674-0.0785837c0,0.0785837,0,0.0785837,0,0.1571674c0,0-0.0786972,0-0.0786972,0.0786409
			c0,0.1572256,0,0.3144503,0.0786972,0.393034c0.0785847,0.2358093,0.2357521,0.3144503,0.3143349,0.5502586
			c-0.2357502,0.1572256-0.7860661-0.6288424-0.9432354-0.8647079c0-0.0785847-0.0785828,0-0.1572819,0
			c0,0.0786409,0.0786991,0.1572247,0.0786991,0.3144493c0,0.0785847,0,0.1572256,0,0.2358093
			c0,0.1572247-0.0786991,0.2358656-0.2358665,0.2358656c0,0,0,0-0.0786972,0c-0.0785847,0-0.1571693,0-0.1571693-0.0786409
			l-0.0785828-0.0785837l-0.0785847-0.0786409c-0.3144493-0.1572247-0.6288986-0.393034-0.786068-0.7074842
			c-0.0785828-0.0785837-0.1572819,0-0.1572819,0.0786409c0,0.0785847,0,0.0785847,0.0786991,0.1572256
			c0.0785828,0.0785837,0.0785828,0.2358084,0.0785828,0.314393c0,0.1572247-0.0785828,0.3930902-0.2358646,0.471674
			c-0.0786991,0.0785847-0.1572819,0.0785847-0.1572819,0.0785847c-0.0785847,0.0786409-0.0785847,0.0786409-0.1571693,0.1572247
			c0,0.0786409-0.0785828,0.1572247-0.1571674,0.1572247c-0.0785847,0.0786409-0.1572819,0-0.2358665-0.1572247
			c-0.0786972-0.1572247,0-0.314393,0-0.4716177c-0.0786972-0.2358656,0-0.7074842-0.0786972-1.0219336
			c0-0.0785842-0.0785847-0.0785842-0.0785847-0.0785842c-0.0785828,0-0.1571674,0.0785842-0.2357502,0.1572251
			c-0.1571693,0.314393-0.2358665,0.6288424-0.1571693,0.9432926c0-0.0786409,0-0.1572247,0.0785847-0.2358656
			c0,0,0-0.0785847,0.0785847-0.0785847c0-0.0786409,0-0.1572247,0.0785828-0.2358084
			c0.0785847,0.393034,0.1571674,1.1005173-0.2357521,1.1005173c-0.1572819,0-0.6288986-0.4716749-0.7074833-0.6288996
			c-0.2358665-0.2358093-0.4717312-0.393034-0.8647652-0.393034h-0.0785847
			c0.0785847,0.0786409,0.1572819,0.1572247,0.2358665,0.3144503c0.0785828,0.0785837,0.1571674,0.1572247,0.0785828,0.3144493
			c0,0.0785837-0.1572819,0.0785837-0.2358646,0.0785837c-0.0785847,0-0.0785847-0.0785837-0.0785847-0.0785837
			c-0.0786972,0-0.2358646,0.0785837-0.393034,0c-0.2358646-0.0786409-0.5503159-0.1572247-0.7074833-0.3930902
			c-0.0785847-0.0785847-0.0785847,0-0.1571674,0c0,0.0786409,0.0785828,0.0786409,0.0785828,0.1572247
			c0.0785847,0.0786409,0.0785847,0.2358656,0,0.3144493c-0.1572819,0.1572256-0.3931465,0.1572256-0.6288986,0.0785847
			c-0.1571674,0-0.2358665,0-0.3144493-0.1572256c-0.0785847,0-0.0785847,0-0.1572819,0
			C18.8505058,9.248807,18.7719212,9.248807,18.6933384,9.248807c-0.0785847,0.0785847-0.1571693,0-0.2358665-0.0786409
			l-0.0785847-0.0785837c-0.1572819-0.1572247-0.3144493-0.2358084-0.4716167-0.3144493
			c-0.4717312-0.2358093-0.9433498-0.2358093-1.4149685,0c-0.0785828,0-0.0785828,0.0786409-0.0785828,0.0786409
			c0.0785828,0,0.0785828,0,0.1571674,0s0.2357521,0,0.3144493,0c0.0785847,0,0.2358665,0,0.3144512,0
			c0.2357521,0,0.4716167-0.0786409,0.6288986,0.0785837c0.0785847,0.1572247-0.0785828,0.393034-0.2358646,0.5502586
			c0,0.1572247,0,0.3144503-0.1572819,0.3144503c-0.0391788,0-0.0589962,0-0.0785847,0
			c-0.393034,0.0785837-0.6289005-0.3144503-1.1005173-0.0785847c-0.0785847,0-0.0785847,0.1571684,0,0.1571684
			c0.2358646,0.0786409,0.3144493,0.1572247,0.2358646,0.3144493c0,0,0,0.0786409-0.0785828,0.0786409
			c-0.3145638,0.0785847-0.6289005,0.0785847-0.9433498,0.0785847c-1.9652262,0.6288424-3.5373621,2.0438099-4.9523287,3.4587774
			c0,0,0,0.0785837,0,0.1572247c0.0785837,0.0785837,0,0.3144503,0.0785837,0.4716177
			c-0.9432926,1.2577429-1.8080015,2.3582592-2.2010355,3.616003c0.6288996-1.1005173,1.3363838-2.1223946,2.2010355-3.0657444
			c0.0786409-0.0785837,0.0786409-0.0785837,0.1572247-0.1571674c0.0786409,0,0.0786409,0,0.1572247,0
			c0.1572256,0,0.2358093,0,0.393034,0c0.0786409,0.314393,0,0.6288424-0.2358084,0.8646517
			c-0.5502586,0.5502577-1.5721922,1.0219336-1.3363838,1.8866415c-0.2358084,0.2358093-0.4716177,0.4716187-0.786068,0.6288433
			c-0.3143921,0.2358093-0.7074833,0.471674-0.8647079,0.8647079c-0.1571684,0.3144512-0.1571684,0.6288433-0.2358093,0.9432926
			c-0.0785837,0.2358093-0.2358088,0.4716759-0.3930335,0.786068c-0.2358088,0.3930912-0.393034,0.7861252-0.4716749,1.1005745
			c-0.0785837,0.314394-0.2358088,0.7074261-0.2358088,1.0218773c-1.3363266,3.8518105-0.8647084,8.4111061,1.3363266,11.8698845
			c0.1572247,1.1005173,0.9433489,1.9652252,1.4936085,2.9871597c0.0785837,0.2358093,0.2358084,0.3930321,0.393033,0.5502586
			c0.1572256,0.1572227,0.314393,0.3143921,0.4716187,0.4716148c0.5502586,0.4716759,1.1005173,0.9432945,1.6507759,1.4149704
			c0.0786409,0.0785828,0.1572247,0.1572227,0.2358656,0.2358093c0.4716177,0.7074814,1.1005182,1.25774,1.8865852,1.5721893
			c0.5502586,0.2358093,1.100461,0.3144493,1.6507769,0.4716759c0.1571674,0,0.3144493,0.0785828,0.4717312,0.0785828
			c0.2357521,0,0.4715042-0.0785828,0.7074833-0.0785828c0.0785847,0,0.0785847,0,0.1571693,0
			c0,0.02631,0.0029602,0.0526161,0.0033016,0.0789261c0.5488358,0.0010262,1.0195427,0.235981,1.4902496,0.4713326
			c0.1571674,0.0785828,0.2357521,0.1571693,0.393034,0.1571693c0.2358646,0,0.3930321-0.1571693,0.6287842-0.0785866
			c0.0786991,0.0785866,0.1572819,0.1572266,0.3145638,0.2358093c0.3143368,0.1572266,0.6287861-0.4716148,1.0218201-0.3143921
			c0.2358665,0.0785828,0.3144493,0.3143921,0.5503159,0.3143921c0.0785847,0,0.0785847-0.0785828,0.1571674-0.0785828
			c0.1571693-0.0785828,0.3931484-0.0785828,0.5503159-0.0785828c0.8646526,0,1.6507206,0,2.5153713,0
			c-0.2357521,0-0.3929195-0.1572266-0.5502014-0.3144493c2.5940685-0.4716759,5.1882515-1.4935532,7.0747223-3.3015556
			c-1.6507187,0.6288452-3.2228546,1.3363266-4.7164059,2.1223946c-0.4717312,0.2358665-0.8647652,0.4716759-1.4149666,0.5502586
			c-0.2358665,0-0.4717331,0-0.7074833,0.07864c-0.2358665,0-0.4717331,0.1572266-0.7074852,0.1572266
			c-0.393034,0.0785828-0.7860661,0.0785828-1.1005173,0.0785828c-0.7861805,0-1.5722485,0-2.3583164-0.0785828
			c-0.4716187-0.07864-0.8646526-0.1572266-1.2576847,0c-1.1005192-0.2358665-2.1224518-0.5502586-3.1443853-1.0219345
			c-0.6288996-0.3144493-1.2576857-0.6288986-1.8865852-0.9432907c0.1572247,0.1572227,0.3144503,0.3144493,0.5502586,0.3930321
			c-0.314393-0.0785828-0.6288424-0.1572227-0.8646517-0.3144493h-0.0786409c0,0-0.0785837,0-0.0785837-0.0785828
			c-0.3144503-0.4716759-0.7074842-0.9432945-1.2577429-1.2577438c0.1572256,0,0.393034,0,0.5502586,0
			c-0.6288996-0.3930321-1.2577419-0.7074814-1.8080006-1.1791573c-0.6288996-0.4716187-1.1791592-1.1791039-1.6507769-1.8865852
			c-0.3144493-0.3930359-0.6288996-0.8647079-0.9432926-1.2577438c-0.2358656-0.7074814-0.7861242-1.4149666-0.9433494-2.1223946
			c-0.0785837-0.2358627-0.0785837-0.5502586-0.1571679-0.7861214c-0.0786409-0.1572266-0.1572247-0.2358112-0.2358656-0.3930359
			c-0.1572251-0.2358093-0.1572251-0.4716167-0.1572251-0.786068c-0.0785837-0.8647079-0.2358088-1.6507759-0.314393-2.5154839
			c0-0.2358093-0.0786409-0.4716759-0.0786409-0.7074833c0-0.6288433,0.0786409-1.2577438,0.1572251-1.9652271
			c0.0786409-0.4716187,0.1572251-0.9432926,0.1572251-1.4149113c0,0.0785847,0,0.0785847,0,0.1571693
			c0.3144498-1.2576866,0.7074833-2.5940685,1.2577424-3.7731705c0.2358084-0.393034,0.393034-0.7861252,0.786067-1.1005173
			c0.2358093-0.2358665,0.5502596-0.393034,0.7074842-0.7074852l-0.4716749,0.1572266
			c0.2358656-0.3144512,0.4716749-0.6289005,0.7074833-0.9433498c0,0,0,0,0,0.07864c0,0.0785847,0.0786409,0.0785847,0.1572256,0
			c0.393034-0.393034,0.7074833-0.8647079,1.1005173-1.2577419c0.0786409-0.0785847,0.1572247-0.1572247,0.2358656-0.2358093
			c0.0785847-0.07864,0.1571684-0.2358646,0.2358093-0.2358646c0.3144493-0.2358093,0.7074833-0.2358093,1.1005173-0.2358093
			h0.0785837c0.4716749-0.1572247,0.7861252-0.6288433,1.0219336-1.0218773v-0.0786409
			c-0.393033-0.4716177,3.0657434-2.1223936,3.4587774-2.3582592c0.3144512-0.1572256,0.5503159-0.314393,0.8646526-0.4716187
			c0.3144493-0.1572247,0.6288986-0.471674,0.9433498-0.5502586c0.3144493-0.1572247,0.3144493-0.0786409,0,0.1571684
			c-0.1572819,0.1572247-0.2358665,0.2358656-0.4716187,0.3930902c-0.1572819,0.0785847-0.3931484,0.1571684-0.5503159,0.2358093
			v0.0785837c-0.0785847,0.0786409-0.2357521,0.1572256-0.393034,0.1572256c0,0,0,0.0786409,0.0785847,0.0786409
			c0.3144493,0.0785837,0.6288986,0.0785837,0.8647652,0.0785837c0.3929195-0.0785837,1.1791019-0.5502586,1.4935513-0.2358093
			h0.0785847c0.2357521-0.2358084,0.6288986-0.1572247,0.8646507-0.2358084
			c0.2358665-0.0786409,0.2358665-0.1572256,0.4717312-0.2358665c1.0218201-0.5502586,0.7074852,0,1.3363838,0.3144503
			c0.0785847,0.0785837,0.1571693,0.0785837,0.2357521,0.0785837c0.3144493,0.0786409,0.6289005-0.0785837,0.8646526,0.0786409
			c0.2358646,0.1572256,0.3144493,0.4716177,0.4717312,0.7074842c0.1571674,0.3143921,0.3929195,0.7074833,0.786068,0.7074833
			c0,0,0.0785828,0,0.0785828-0.0786409c0-0.0785837,0.0785847-0.1572247,0.0785847-0.1572247
			c-0.0785847-0.2358093,0-0.314393,0.3144493-0.2358093l0.0786991,0.0785837c0.2357521,0,0.4715042-0.0785837,0.7073689-0.1572247
			c0.5502014-0.314393,0.9433498-0.4716177,1.5722504-0.2358084c0.3929195,0.1572247,0.7860661,0.3144493,1.1789875,0.471674
			c0.0786972,0.0785847,0.2359791,0.0785847,0.3145638,0.1572256h0.0785828c0,0,0.0785847,0,0.1571693,0
			c0.1571674,0.0785837,0.3144493,0.2358084,0.3931465,0.393034c0,0.0785837-0.0786972,0.1572247-0.1572819,0.1572247
			c-0.0786972,0-0.1572819-0.0786409-0.2358646-0.0786409c0,0-0.5503159-0.314393-0.6289005-0.314393
			c-0.6287861-0.3144503-1.3362694-0.5502586-2.0437527-0.393034c-0.2358665,0-0.4716187,0.0785837-0.6289005,0.2358093
			c-0.3144493,0.2358084-0.4716167,0.8647079-0.8646507,1.0218763c-0.0786991,0-0.0786991,0.0786409-0.1572819,0.0786409
			c-0.0785847,0-0.0785847,0-0.1571693,0c-0.0785828,0-0.0785828,0.0785837-0.0785828,0.1572247
			c0.0785828,0.1572256,0.1571674,0.1572256,0.2357521,0.2358093c0.0785828,0,0.1572819,0,0.2358646,0
			c0.1572819,0,0.2358665,0.0785837,0.2358665,0.2358084c0,0.1572256-0.1572819,0.2358093-0.2358665,0.2358093
			c-0.1572819,0.1572256-0.3144493,0.2358656-0.4716167,0.2358656c0,0,0,0,0,0.0785847
			c-0.3931484,0.2358093-1.1006317-0.0785847-1.0219345-0.5502596c-0.0786972-0.0785837,0-0.1572247,0.1572819-0.3144493
			c0.1571693-0.314393,0.3929195-0.393034,0.786068-0.314393c0.0785847,0,0.0785847,0,0.0785847-0.0786409
			c-0.0785847,0-0.1571693-0.0785837-0.1571693-0.0785837c-0.3931465,0-0.7861805,0.2358084-0.8647652,0.5502586h-0.0786972
			c-0.1571674,0.1572247,0,0.0785837-0.1571674,0.2358084c0,0.0786409,0,0.0786409,0,0.1572256
			c0,0.0785837,0,0.2358093-0.0785847,0.3144493c-0.0785847,0.0785847-0.2357521,0.0785847-0.3144493,0
			c-0.1571693,0-0.1571693-0.07864-0.1571693-0.3144493c0.0785847,0,0.1571693-0.0785847,0.0785847-0.1572256
			c-0.0785847-0.0785837-0.0785847-0.1572247-0.1572819-0.2358084c-0.1571674,0.0785837-0.2357521,0.2358084-0.3929195,0.393034
			c-0.0785847,0.2358093-0.0785847,0.5502586,0.1571674,0.7074833c0.1571674,0.1572247,0.4716187,0.0785847,0.6289005,0.1572247
			c0.3143349,0,0.7074833,0,1.0218182,0.0785847c0,0,0.0785847-0.0785847,0.0785847,0
			c0.1572819,0,0.2359791,0.07864,0.3145638,0.2358093c0,0.07864,0,0.1572247-0.0785847,0.2358646c0,0,0,0,0,0.0785847
			c-0.1572819,0.393034-0.6288986,0.1572247-0.7861805-0.1572247c0,0,0,0,0,0.07864
			c0.0786972,0.4716187,0.393034,1.1005173,0.8647652,0.707428c0.2357521-0.1571693,0.3929195-0.707428,0.6287861-0.786068
			c0.1572819,0,0.4716187,0.1572247,0.7074833,0.1572247c0.2358665-0.0785847,0.4716187-0.1572247,0.6289005-0.393034
			s0.1572819-0.5502586,0.3144493-0.7861252c0.1571674-0.2358084,0.393034-0.3143921,0.6289005-0.4716177
			c0,0,0.3929195-0.3144493,0.3143349-0.3144493h0.0785847c0.0785828,0,0.1572819,0.0786409,0.2358646,0.0786409
			c0.2358665,0.0785837,0.5502033,0,0.8647671,0c0.0785828,0.0785837,0.2357521-0.0786409,0.1571674-0.1572247
			c0.1571674,0.1572247,0.0785847,0.3143921,0.1571674,0.4716177c0.1572819,0.0786409,0.3145638,0.1572247,0.2358665,0.393033
			c0,0.1572247-0.0785847,0.2358665,0,0.3930912c0.0786972,0.0785847,0.1572819,0.1572247,0.2358665,0.1572247
			c0.1571674,0.1571674,0.2357502,0.314394,0.3143349,0.4716187c0.1572819,0,0.1572819-0.1572247,0.3145638-0.2358093
			c0,0,0.0785847,0,0.1571674,0c0.0785847-0.1572247,0.2357521-0.3144493,0.393034-0.393034
			c0.0785847,0,0.1572819-0.07864,0.2358665-0.07864c0,0,0.0785847-0.0785847,0.1571674-0.0785847
			c0.0785847,0,0.1571693,0.0785847,0.2357521,0.1572247c0,0.0785847,0,0.1572247-0.0785828,0.2358093
			c-0.1571693,0.0785828-0.3143368,0.0785828-0.3929214,0.0785828c-0.1572819,0.0786419-0.2358646,0.1572266-0.3145638,0.2358665
			c-0.0785828,0.1572247-0.2357521,0.393034-0.2357521,0.5502586c0,0.2358093,0.0785847,0.4716187,0.3144512,0.5502586
			c0.0785828,0,0.1571674-0.07864,0.2358646-0.07864c0.3143368-0.0785847,0.5502014-0.1572247,0.8646507-0.0785847
			c0.0785866,0,0.0785866,0,0.1571693,0.0785847c0.1257362,0.0629234,0.2088737,0.1366673,0.3145638,0.2397938v-0.0039291
			c0,0,0,0,0.0785828,0c0.3143387,0.0785847,0.2357521,0.393034,0.1571693,0.5502586
			c0,0.0727768-0.0687904,0.0707264-0.1412239,0.0087128c0.0173111,0.108366,0.0543251,0.2187824,0.1412239,0.3056812
			c0,0,0.0785828,0,0.0785828,0.07864c0,0,0,0,0,0.0785847h0.0785866c0,0,0,0.0786419,0.0785828,0.1572247
			c0,0.0785847,0.0785828,0.0785847,0.0785828,0.1572247c0.0786972-0.0785828,0.1572838-0.0785828,0.1572838,0
			c0.0786972,0.0785847,0.15728,0.1572247,0.15728,0.2358093c0.0785866,0.0786419-0.0785828,0.2358093-0.15728,0.1572247
			c-0.2358665-0.1572247-0.4716187-0.3144493-0.628788-0.4716167c-0.0786972,0-0.0786972,0.0785828-0.0786972,0.1572247
			c0.0786972,0.0785828,0.1572838,0.2358093,0.2358665,0.3143921c0.1571693,0.1572247,0.3143349,0.3144512,0.4716187,0.4716759
			c0.15728,0.1572247,0.3144493,0.2358074,0.4716187,0.393034c0.0785828,0.0785828,0.15728,0.1572247,0.15728,0.2358074
			c0.0785828,0.0786419,0.2358665,0.2358665,0.3144493,0.3144512c0,0.0785828,0,0.0785828-0.0785828,0.0785828
			c-0.1572838,0-0.3145638-0.1571674-0.4717331-0.2358074c-0.0785828,0-0.2357521-0.1572266-0.2357521-0.3144512
			c0-0.0785828-0.0785828-0.0785828-0.15728,0c0.0786972,0.1572247,0.15728,0.3144512,0.2358665,0.3930912
			c0.0785828,0.1571674,0.2357521,0.3143921,0.3143349,0.5502586c0.15728,0.1572247,0.3145638,0.314394,0.4717331,0.4716187
			c0.1571655,0.1572247,0.3143349,0.393034,0.3143349,0.6288986c-0.2357521-0.07864-0.3929214-0.2358646-0.5502014-0.471674
			v-0.0785847c-0.0785866-0.07864-0.1571693-0.1572247-0.2358665-0.2358646l-0.0785828,0.07864v0.0785847
			c0.2358627,0.5502586,0.4717293,0.8647079,0.8646507,1.0219326v0.0785847c0.0786972,0,0.2359772,0.07864,0.15728,0.2358093
			c-0.0785828,0.07864-0.0785828,0.07864-0.15728,0.1572247c-0.3143349,0.07864-0.5502014,0.471674-0.628788,0.7861233
			c0,0.0785847-0.0785828,0.2358093,0,0.314394c0,0.07864,0.1572838,0.2358646,0.2358665,0.2358646
			c0.0785866,0,0.0785866,0,0.1571693-0.07864c0.1571655-0.0785847,0.2357521-0.2358093,0.3144493-0.3143921
			c-0.0785828,0-0.1572838,0.0785828-0.2358665,0.0785828s-0.1571655,0.0785847-0.1571655,0.0785847
			c-0.0785866,0-0.1571693,0-0.1571693-0.0785847c0-0.0785828,0-0.1572247,0-0.2358093
			c0.0785828-0.3144493,0.5502014-0.471674,0.6287842-0.7861233c0.0786972-0.0785847,0.2358665-0.1571693,0.2358665-0.2358093
			c0.2357521,0.07864,0.4716187,0.2358093,0.7074852,0.3144493c0,0,0.0785828,0.0785847,0.1571655,0.0785847
			c0.2357521,0.07864,0.5503159,0.1572247,0.786068,0.2358646c0.2358665-0.1572247,0.3144493-0.6288986,0.3931503-0.8647079
			c0-0.07864,0-0.07864-0.078701-0.07864c-0.0785828,0.1572247-0.15728,0.2358646-0.3144493,0.1572247
			c-0.1571655,0-0.3143349-0.1572247-0.3143349-0.1572247v-0.0785847c-0.0786972-0.1572247-0.15728-0.2358093-0.0786972-0.471674
			c0-0.0785847,0.0786972-0.2358093,0.0786972-0.2358093v-0.0785847c0-0.07864-0.0786972-0.1572247-0.0786972-0.2358646
			l-0.0785828-0.0785847l-0.0785866-0.0785847c-0.2358665-0.3144493-0.2358665-0.7861233,0.1572838-0.9433498
			c0.2357521-0.0785828,0.3929176,0.0786419,0.5502014,0.2358665c0.0785828,0.0785847,0.15728,0.1572247,0.2358665,0.2358093
			c0.1571655,0.1572247,0.1571655,0.393034,0.2357521,0.471674c0.1571655,0.1571693,0.3930321,0.314394,0.3144493,0.4716187
			c0,0.07864,0,0.1572247,0,0.2358093c0,0,0,0.07864,0,0.1572247c-0.0785866,0.07864-0.0785866,0.1572247-0.1572838,0.2358646
			c0.0785866-0.07864,0.1572838-0.07864,0.1572838-0.1572247c0,0,0,0,0,0.0785847
			c0.0785828,0.0785828,0.15728,0.1572247,0.2358665,0.1572247c0,0,0,0,0.0785828,0
			c0.2357521,0.0785847,0.4716187,0.0785847,0.7074814,0.1572247c0.1571693,0.0785847,0.3930359,0.0785847,0.6289024,0.1572247
			c0.0785828,0,0.1571655,0,0.1571655,0s0,0,0.0785866,0.0785847l0.0785828,0.07864
			c0.0785828,0,0.0785828-0.07864,0.1572838-0.1572247c0.0785828,0,0.1571655,0,0.1571655,0
			c0.0786972,0,0.0786972-0.07864,0-0.1572247c-0.2358665-0.2358665-0.3930321-0.471674-0.4716187-0.7861252
			c-0.0785828-0.3143921,0-0.4716167,0-0.7860661c0.4716187,0.1572247,1.1005173-0.0785847,1.3363838-0.5502586
			c0.3143387-0.6288433,0.3143387-1.3363266,1.1791039-1.5721931c0.5358505-0.164856,1.0654373,0.0627537,1.3745346,0.4266319
			C43.5664215,19.5721817,43.1684914,19.1201515,42.5903854,18.9962978z M15.6276512,12.6290007
			c0,0.0785847-0.0785847,0.1572256-0.1571684,0.1572256c-0.3145065,0.1572247-0.6288996,0.2358084-0.8647652,0.4716177
			c-0.4716177,0.3144493-0.786068,0.7074833-1.3363266,0.8647079c-0.0785837,0-0.1572247,0-0.2358093,0
			c-0.1572247-0.0785837,0-0.393034-0.1572247-0.5502586c-0.0786409-0.0785837-0.2358084,0-0.3144493,0
			c-0.0785847,0.0785837-0.1572256,0.1572247-0.2358093,0.2358093c-0.0786409,0-0.2358093,0-0.2358093-0.1572256
			c0,0,0-0.0785837,0.0785847-0.0785837c0.2358084-0.393034,0.6288424-0.6288424,0.9432926-0.9432926
			c0.3144493-0.2358656,0.6288996-0.4716749,0.9432926-0.7861242l-0.0785847,0.1572247
			c0.0785847-0.1572247,0.3144503-0.2358084,0.4716749-0.1572247c0.1571684,0,0.393034,0.0786409,0.5502586,0.1572247
			c0.2358093,0.0786409,0.5502586,0.2358656,0.707427,0.4716749C15.5490665,12.471776,15.6276512,12.5503597,15.6276512,12.6290007z
			 M16.8854504,12.1573257c-0.0786972,0-0.1572819,0-0.2359791,0c0,0,0,0-0.0785847,0c0,0,0,0,0-0.0785837
			c0-0.0786409,0.0785847-0.1572247,0.1572819-0.2358084c0.1572819,0.0785837,0.3144493,0.0785837,0.3144493,0.1571674
			C17.0426178,12.078742,16.964035,12.1573257,16.8854504,12.1573257z M24.5889587,10.0349321
			c0,0.0785837-0.0785828,0.0785837-0.0785828,0.0785837c-0.0785847,0-0.0785847,0-0.1571693,0.0786409l-0.0785828,0.0785837
			c0,0,0,0,0,0.0785847h-0.0785847c-0.0786972,0-0.1572819,0-0.2358665-0.0785847l-0.0786972-0.0785837
			c0.0786972,0,0.0786972,0,0.1572819,0h0.0785847h0.0786972c0,0,0,0,0.0785847,0c0,0,0.0712948-0.0667391,0.1453228-0.0743704
			c0.0015945-0.0013666,0.0031891-0.0029612,0.0047836-0.0042706h-0.0715237c0-0.0785837,0.0785847-0.0785837,0.0785847-0.1572247
			c0,0,0,0,0.0785847,0l0.0785828-0.0785837C24.5889587,9.8777075,24.5889587,9.9562912,24.5889587,10.0349321z
			 M29.2270088,11.9215174l-0.0786972,0.0785837c-0.0785847,0-0.1572819,0-0.2358665-0.0785837
			c-0.1571674-0.1572247-0.3143349-0.2358093-0.4716167-0.393034c0,0-0.0785847-0.0786409-0.1572819-0.0786409
			c-0.0785847,0-0.0785847,0.0786409-0.0785847,0.1572247c0,0.0786409,0,0.1572256,0,0.2358665c0,0,0-0.0786409-0.0785847-0.0786409
			c0-0.0785847-0.0785828-0.1572256-0.0785828-0.1572256c0,0.0786409,0.0785828,0.1572256,0.0785828,0.2358093
			c-0.0785828-0.0785837-0.0785828-0.0785837-0.0785828-0.1571684c-0.0785847-0.0786409-0.0785847-0.2358656-0.1571693-0.3144493
			c0-0.0786409,0-0.1572256,0.0785847-0.1572256c0,0,0-0.0785837,0.0785847-0.0785837c0.0785828,0,0.1571674,0,0.1571674,0
			s0,0,0.0785847,0c0,0,0,0,0.0786972,0c0.1572819,0,0.2358665,0.1571684,0.3144493,0.2358093
			c0.0785847,0.0785837,0.1571674,0.0785837,0.2357521,0.1572247c0.1572819,0.0785837,0.2358665,0.1572247,0.3145638,0.314393
			C29.2270088,11.8429337,29.2270088,11.8429337,29.2270088,11.9215174z M26.7899933,15.6947441l-0.0785828,0.0785847v0.0785837
			c0,0.0786409-0.0785847,0.0786409-0.1571674,0c-0.0785847-0.0785837-0.0785847-0.1571684,0-0.2358093
			c0.0785828-0.0785837,0.1571674-0.0785837,0.1571674-0.0785837c0-0.0786409,0-0.1572247,0-0.2358084
			c0-0.0786409,0.0785828-0.0786409,0.1572819-0.0786409c0.0785828,0,0.0785828,0.0786409,0.0785828,0.0786409
			C27.0258598,15.4588785,26.8686924,15.6161032,26.7899933,15.6947441z M27.9692097,14.8300362
			c0,0.0785837-0.1571674,0.1572247-0.2358665,0.1572247c-0.0785828,0-0.0785828,0-0.0785828-0.0786409
			c0-0.0785837,0.0785828-0.0785837,0.0785828-0.1572247c0,0,0-0.0785847,0.0786991-0.0785847c0,0,0,0,0.0785828,0
			c0,0,0.0785847,0,0.0785847,0.0785847C28.0477943,14.7513952,28.0477943,14.7513952,27.9692097,14.8300362z
			 M30.7991447,11.6070671c0.786068,0.0786409,1.6507187,0.7861252,2.0437546,1.4149675
			c0.0786972,0.1572247,0.3144493,0.393034,0.4716148,0.6288424c0.3145638,0.2358665,0.4717331,0.6289005,0.2358665,0.9433498
			c-0.0785828,0.0785837-0.2358665,0.0785837-0.2358665-0.0786409c0-0.0785837,0-0.1572247-0.0785828-0.2358084
			c0,0-0.0028458-0.0715799-0.0742569-0.0761929c0.0135536,0.0150909,0.0168571,0.0186777-0.0043259-0.0024481
			c0.0025024,0,0.0020485,0.0022774,0.0043259,0.0024481c-0.0371284-0.0411711-0.1614952-0.1807432-0.1614952-0.2382574
			c-0.2358665-0.2358093-0.5503159-0.393034-0.786068-0.6288424c-0.15728-0.1572256-0.0786972-0.4716749-0.15728-0.6288996
			C31.8995476,12.3931923,30.6418629,11.9215174,30.7991447,11.6070671z M32.9215965,17.8957787
			c-0.3145638-0.2358646-0.5503159-0.471674-0.9433498-0.471674c-0.0786991,0-0.1572819,0-0.2358665,0
			S31.5852127,17.34552,31.6637955,17.26688c0-0.0785847,0.0785847-0.0785847,0.0785847-0.0785847
			c0.0785847,0,0.1571674-0.07864,0.2358665-0.07864c0.0785847-0.0785847,0.2358646-0.0785847,0.3144474,0h-0.0785828
			c0.1571693,0.07864,0.2357521,0.1572247,0.3929214,0.2358646c0.3145638,0.2358093,0.6288986,0.5502586,0.8647652,0.8646526
			c0.0785828,0.07864,0.0785828,0.1572247,0.15728,0.2358665C33.3931007,18.131588,33.1573486,18.0530052,32.9215965,17.8957787z
			 M32.6856155,16.2450027c-0.0785828-0.07864-0.1571693-0.1572247-0.2357521-0.2358646
			c-0.0785828-0.0785847-0.0785828-0.2358093,0-0.314394c0-0.0786409,0.0785828-0.0786409,0.0785828-0.1572247v-0.0786409
			c-0.3143349-0.314393-0.5501995-0.6288424-0.9432335-0.8646517c-0.1572819-0.0786409-0.3144512-0.1572247-0.3931484-0.3144493
			c-0.0785847-0.1572256-0.0785847-0.3144503-0.0785847-0.4716749c0-0.2358093,0-0.393034,0-0.6288433
			c0.1572819,0.0785847,0.3145638,0.1572256,0.3931484,0.3144503c0.0785847,0.0785837,0.0785847,0.2358084,0.1571674,0.314393
			c0.0785847,0.1572247,0.2357521,0.1572247,0.3930359,0.2358656c0.2358627,0.1572247,0.5502014,0.314393,0.786068,0.4716177
			c0.2358627,0.1572247,0.4716148,0.3144503,0.6288986,0.5502586c0.0785828,0.1572256,0.15728,0.3144503,0.15728,0.393034
			c0,0.0786409,0,0.1572247,0.0785866,0.2358656c0.0785828,0.0785847,0.1571655,0.1572256,0.1571655,0.314394
			c0.1571693,0.2358646,0.1571693,0.5502586,0.1571693,0.8647079s0.0786972,0.6289005,0.15728,0.8647079
			C33.7076645,17.26688,33.2359314,16.7952614,32.6856155,16.2450027z M35.0439339,20.8042984
			c-0.0785866,0.0785847-0.1571693,0.1572247-0.2358665,0.1572247c-0.0785828,0-0.0785828-0.07864-0.0785828-0.07864
			c0-0.0785847,0-0.0785847-0.0785866-0.1572266c0-0.0785828-0.0785828-0.1572247-0.1571655-0.2358074
			c-0.1571693-0.2358093-0.1571693-0.5502586,0-0.786068h-0.0785866c0,0,0,0,0-0.0786419c0,0,0,0,0.0785866,0
			c0.1571655,0.0786419,0.3143349,0.0786419,0.3930321,0.2358665c0,0.0785847,0,0.0785847,0,0.1571674
			c0,0.0786419,0,0.2358665,0,0.3144512l-0.0786972,0.07864c0,0.0785847,0.0786972,0.1571674,0.0786972,0.2358093
			C35.0439339,20.6470737,35.1226311,20.7256565,35.0439339,20.8042984z M37.2449684,18.9962978
			c-0.0785866,0.0785828-0.0785866,0.0785828-0.1571693,0.0785828c-0.0786972-0.0785828-0.15728-0.0785828-0.2358665-0.1572247
			c-0.0785828-0.0785847-0.1571655-0.1572247-0.2357521-0.2358093c-0.0785828,0-0.2358627,0-0.3145638,0
			c-0.0785828,0-0.1571655-0.0785828-0.2357521-0.1572247c-0.0785828-0.0785828-0.0785828-0.2358093-0.0785828-0.3144493
			c0-0.1572247,0-0.393034,0.0785828-0.5502586c0.0785866,0,0.1571693,0.07864,0.2357521,0.1572247
			c0.1572838,0.07864,0.3931503,0.2358093,0.4717331,0.3144493c0.2357521,0.1572247,0.5503159,0.393034,0.6288986,0.6288433
			C37.2449684,18.8390713,37.2449684,18.9176559,37.2449684,18.9962978z M38.8958015,21.5117817
			c-0.0785866,0.0785847-0.0785866,0.1572247-0.0785866,0.1572247c-0.0785828,0-0.0785828,0-0.15728-0.07864
			c0-0.0785847-0.0785828-0.1572247-0.0785828-0.2358093c0-0.1572247,0.0785828-0.3144493,0.2358627-0.3144493h0.0785866
			c0.0785828,0,0.1571655,0.0785828,0.2357521,0.1572247C39.2101364,21.354557,39.0529671,21.5117817,38.8958015,21.5117817z'
                            />
                            <path
                                className='st0'
                                d='M43.5336227,21.747591c0.0279045-0.0488014,0.042141-0.1006794,0.0656013-0.1505623
			c-0.0476074,0.050169-0.0940742,0.1003933-0.1441841,0.1505623H43.5336227z'
                            />
                        </g>
                    </g>
                </g>
            );

        case 'AG2':
            return (
                <g {...props}>
                    <g>
                        <path
                            className='st1'
                            d='M21.906517,33.268013l2.8673515-16.5357018H9.2853374L6,35.6771317h18.8083611l0.417984-2.4091187H21.906517z
		 M20.0814381,28.6687241h-6.634922l-3.3396835,4.4627228h-2.573503l10.8652992-14.5192261l0.1130524-0.2066784l0.014204,0.0364819
		l0.0272274-0.0364819l0.3669758,1.0223103l3.7567539,9.3975067l-0.7466488,4.3060875h-0.1060028L20.0814381,28.6687241z'
                        />
                        <path
                            className='st0'
                            d='M25.1916924,14.3228703l-0.4176617,2.409441h-0.0001621L21.906517,33.268013h18.8078766L44,14.3228703
		H25.1916924z M32.2937279,28.5853195c1.671402-0.0627403,3.2609596-0.9756565,4.3027496-2.3047829H32.652092l0.3558884-2.3071499
		h6.4659653l-0.3707428,2.1325951c-1.3336449,2.6214466-4.0814896,4.5307369-7.0284767,4.6057987
		c-3.821701,0.0970173-6.5169201-2.92202-6.0189209-6.7439899c0.4977303-3.8222389,3.9993763-6.9991531,7.8209686-7.0964394
		c3.0153275-0.076622,5.3271599,1.7868767,5.9256172,4.4534149h-2.2979469
		c-0.6049156-1.4501953-2.036602-2.3945904-3.8467216-2.3273296c-2.6476002,0.0992241-5.0991192,2.3261452-5.4757252,4.9737434
		C27.8053913,26.618885,29.646452,28.684166,32.2937279,28.5853195z'
                        />
                        <polygon className='st1' points='17.8063393,22.8423557 19.2265587,26.4782829 15.0854836,26.4782829 	' />
                    </g>
                </g>
            );

        case 'BR':
            return (
                <g {...props}>
                    <g>
                        <path
                            className='st0'
                            d='M44.483696,17.6831741v1.0885334h-5.1526489v5.4881821c0,0.4122639,0.0650253,0.7217865,0.195076,0.9285698
		c0.1313515,0.2067814,0.3589439,0.3446369,0.6853714,0.4135647c0.2327919,0.0429153,0.5813332,0.0650253,1.046917,0.0650253
		c0.8466377,0,1.3824501-0.1313515,1.6061401-0.3940563c0.2132835-0.2497005,0.321228-0.6996784,0.321228-1.3512383
		c0-0.1183472-0.0104065-0.2679062-0.028614-0.4499798h1.8298302C44.9960976,23.7721958,45,24.0062885,45,24.1753559
		c0,0.7764091-0.2314911,1.375948-0.6983795,1.8012161c-0.5956345,0.538414-1.6334496,0.8063202-3.1121368,0.8063202
		c-1.2771072,0-2.2030754-0.1365528-2.7792053-0.4122639c-0.5865326-0.2874146-0.9168625-0.7451954-0.9922943-1.3694439
		c-0.0182076-0.1378555-0.0273094-0.3849525-0.0273094-0.7412949v-5.4881821h-1.8428345v-1.0885334h1.8428345v-2.1575584h1.9403725
		v2.1575584H44.483696z'
                        />
                        <path
                            className='st1'
                            d='M29.5538235,16.1719818h-7.0240612c-2.3943615,0-4.3528252,1.95854-4.3528252,4.3515129v3.2916126
		c0,2.3942852,1.9584637,4.3528252,4.3528252,4.3528252h7.0240612c2.3929729,0,4.3514347-1.95854,4.3514347-4.3528252v-3.2916126
		C33.9052582,18.1305218,31.9467964,16.1719818,29.5538235,16.1719818z M30.8152466,26.1118317
		c-0.4487019,0.2406254-1.04949,0.4109898-1.8025188,0.5162621c-0.7530308,0.1041164-1.7556305,0.2341461-3.0081081,0.2341461
		c-2.3565731,0-3.982172-0.2757149-4.7859478-0.8883038c-0.5241299-0.4083672-0.8114891-1.1131935-0.9520073-1.8935242
		c-0.1027279-0.5930004-0.1533222-1.2355118-0.1533222-1.9286938c0-0.9454517,0.0687943-1.6880703,0.210701-2.2264652
		c0.1391296-0.5383968,0.2912159-1.0663815,0.6371918-1.4006329c0.4955921-0.4968262,1.2341213-0.8180447,2.216053-0.9637299
		c0.8037758-0.1131401,1.7934189-0.0779705,2.7844505-0.0779705c1.8324451,0,3.1278038,0.1287174,3.8859234,0.3875427
		c1.1588497,0.3966446,1.8039074,1.3225842,1.9351711,2.777895c0.0273018,0.3290081,0.0467377,0.905117,0.0559921,1.7297173
		H21.6323586c0,1.1093369,0.2184124,2.0144539,0.5512753,2.4488106c0.3967209,0.5202713,1.2342777,0.6516132,2.1785736,0.7660637
		c0.4121456,0.0493584,0.9635754,0.0754261,1.6553688,0.0754261c1.3006039,0,2.2265434-0.0572262,2.7779732-0.1703644
		c0.58922-0.126173,1.2771568-0.4278793,1.3616848-0.9065056c0.0181999-0.1274071,0.0273018-0.351141,0.0273018-0.6723576h1.6542892
		c0.0089455,0.1703644,0.0141907,0.3030167,0.0141907,0.3966427C31.8530159,25.1546574,31.5071964,25.7529011,30.8152466,26.1118317
		z'
                        />
                        <path
                            className='st1'
                            d='M30.3080559,21.1854668c0-1.8818493-1.1249466-2.3214245-1.9650822-2.4475746
		c-0.47859-0.0702267-1.3642406-0.0702267-2.3643398-0.0702267c-1.3421326,0-2.1666622,0.0351124-2.7622986,0.1742687
		c-0.8622437,0.20158-1.3733463,0.6411552-1.5333118,1.5046978c-0.0416164,0.239296-0.0585232,0.4096642-0.0468178,0.8388348
		H30.3080559z'
                        />
                        <path
                            className='st0'
                            d='M16.2389889,19.8875389c-0.1846313-0.6814594-0.5852098-1.218544-1.2003431-1.6126442
		c-0.7633638-0.482481-1.9820623-0.7231045-3.6584091-0.7231045c-1.3316069,0-2.4059315,0.1287956-3.2252865,0.3849983
		c-0.557754,0.1755314-0.9492307,0.4811707-1.1728873,0.9194603l-0.0416465-0.0286121v-4.9809933H5v12.8048954h1.9404163
		l-0.0974836-1.1067142l0.0558372-0.0091019c0.3627868,0.431736,0.8465028,0.7413082,1.4526896,0.9285622
		c0.7166281,0.2132454,1.6411791,0.3185959,2.7792063,0.3185959c1.8427792,0,3.1407595-0.2431698,3.8950233-0.730896
		c0.5397072-0.3498306,0.9259396-0.8180447,1.1574621-1.4032536c0.2341461-0.5838985,0.3498306-1.3733311,0.3498306-2.3682175
		C16.5329819,21.3935204,16.4354992,20.5950661,16.2389889,19.8875389z M14.3689089,23.8099403
		c-0.1118279,0.6502247-0.4005766,1.1288509-0.8648577,1.434412c-0.4382133,0.2822704-1.3498077,0.4227123-2.7375603,0.4227123
		c-1.5358286,0-2.5412045-0.206768-3.015974-0.6190681c-0.2887487-0.2497234-0.4863381-0.6034107-0.5929222-1.0599022
		c-0.1079721-0.4564915-0.1613412-1.1730404-0.1613412-2.1484928c0-0.5747986,0.0610814-1.0923729,0.1807761-1.5475521
		c0.1405182-0.5319176,0.4565687-0.9351177,0.9507732-1.2108326c0.5201178-0.2757149,1.4239998-0.4122219,2.7088699-0.4122219
		c1.4422016,0,2.3969851,0.187254,2.8625002,0.5617638c0.2976942,0.2445583,0.5045385,0.5891438,0.6216116,1.0326786
		c0.1156845,0.4434566,0.1730642,1.1040936,0.1730642,1.9793625C14.4938488,22.8007088,14.4522018,23.3222141,14.3689089,23.8099403
		z'
                        />
                        <g>
                            <path
                                className='st0'
                                d='M38.2681808,30.5403366h2.0025864l-0.1205902,0.7765942l0.0345688,0.0056286
			c0.2419815-0.2894154,0.4847679-0.4920044,0.733181-0.6085739c0.3738289-0.1736488,0.8778915-0.2612782,1.5105858-0.2612782
			c1.057972,0,1.7252312,0.2789631,2.0017815,0.8352833c0.1101418,0.2090206,0.1648064,0.6752987,0.1648064,1.3972282h-1.8651123
			v-0.3062973c0-0.3135338-0.0530624-0.5305939-0.1631966-0.6495743c-0.1447067-0.1479225-0.4252777-0.2202759-0.8465385-0.2202759
			c-0.6560097,0-1.089325,0.1680202-1.3031693,0.5040627c-0.097271,0.1511383-0.1463127,0.419651-0.1463127,0.8055382v3.2655563
			h-2.0025864L38.2681808,30.5403366z'
                            />
                            <path
                                className='st0'
                                d='M36.8862228,31.8563633c-0.091629-0.5370064-0.4687347-0.9140625-1.1302643-1.1303139
			c-0.5009651-0.1664314-1.3322144-0.2500057-2.4938507-0.2500057c-0.7717552,0-1.3553848,0.0240765-1.7526093,0.0699387
			c-0.6560001,0.0731335-1.1230183,0.2242603-1.3987675,0.4525738c-0.2637348,0.2234974-0.3995113,0.6069908-0.4052334,1.1480503
			h2.0187283c0.0120125-0.2323666,0.0522499-0.3947468,0.1213779-0.4871864
			c0.1551323-0.2009964,0.6391258-0.3014946,1.4511147-0.3014946c0.7709923,0,1.2508812,0.1045036,1.441391,0.3135548
			c0.1423531,0.1623802,0.2146301,0.4188213,0.2146301,0.7709446v0.8988075h-0.0345154
			c-0.1552315-0.2275047-0.3505058-0.3770599-0.5860176-0.4469986c-0.4028473-0.1229973-1.0419693-0.1848793-1.9166069-0.1848793
			c-1.1046162,0-1.8981113,0.1334419-2.3821011,0.400322c-0.4437542,0.243618-0.664772,0.6913757-0.664772,1.3449898
			c0,0.5956955,0.2041416,1.0185661,0.6133785,1.2701912c0.2460003,0.1543694,0.5531197,0.2636871,0.9181156,0.3264275
			c0.365757,0.0651245,0.8561363,0.096447,1.4719944,0.096447c0.4372711,0,0.8232422-0.0176888,1.1567726-0.0522537
			c0.2757492-0.0313683,0.538723-0.0980644,0.7854843-0.2025681c0.2701263-0.1198044,0.496769-0.3111229,0.6825104-0.5740013
			l0.051487,0.0056267l-0.0957298,0.7596931h2.0026131V32.459301C36.9553528,32.3242378,36.9328499,32.123291,36.8862228,31.8563633
			z M34.6858559,34.9764175c-0.2652588,0.1471214-0.7708931,0.2202568-1.5201454,0.2202568
			c-0.6842232,0-1.1504822-0.0498199-1.3972435-0.1503181c-0.2419949-0.1004982-0.3626118-0.3248062-0.3626118-0.6728783
			c0-0.3014946,0.0941086-0.498436,0.2846165-0.5916862c0.2475262-0.1238098,0.7251282-0.1857414,1.4333801-0.1857414
			c0.8055077,0,1.3345032,0.0739899,1.5868912,0.2211151c0.1729622,0.1004982,0.2597313,0.2934341,0.2597313,0.5788155
			C34.9704742,34.678978,34.8756027,34.8719139,34.6858559,34.9764175z'
                            />
                            <path
                                className='st0'
                                d='M26.0797615,28.1679325v3.1610546l-0.0345173,0.0056267
			c-0.1495075-0.258873-0.3561287-0.4485703-0.6214848-0.5683746c-0.4260178-0.1929398-1.0386353-0.2901955-1.8392792-0.2901955
			c-0.6432247,0-1.1729832,0.0522518-1.5869865,0.1567535c-0.6681099,0.1704369-1.1231155,0.510498-1.3642521,1.0209961
			c-0.1439781,0.3014469-0.2154903,0.8545647-0.2154903,1.6585007c0,0.4831314,0.0313702,0.91082,0.0940151,1.2814407
			c0.0756111,0.4333115,0.2999668,0.7806206,0.6737347,1.043499c0.489521,0.3448753,1.309618,0.5161209,2.4607658,0.5161209
			c0.7758541,0,1.3692093-0.0948715,1.7774925-0.2837563c0.3047352-0.1423073,0.5523567-0.3714333,0.7421017-0.6841774
			l0.0426216,0.0056267l-0.0772343,0.8931808h1.9503632v-7.916296H26.0797615z M25.9977608,34.2577248
			c-0.0490093,0.2323189-0.1342506,0.4035645-0.2547722,0.5161247c-0.2419949,0.2234497-0.7564983,0.33601-1.5452251,0.33601
			c-0.7073936,0-1.1737442-0.1045036-1.3980045-0.3135071c-0.2419968-0.2203064-0.3625164-0.7339973-0.3625164-1.5427475
			c0-0.7838631,0.1317711-1.2726212,0.3971272-1.4671803c0.2178726-0.1615696,0.6608639-0.2428074,1.3288784-0.2428074
			c0.7540188,0,1.254982,0.0948734,1.5017452,0.2838078c0.1559906,0.1198044,0.2628765,0.278944,0.3199902,0.475935
			c0.0570183,0.1969414,0.0860062,0.5008202,0.0860062,0.9108658
			C26.0709896,33.6772881,26.0468655,34.0253601,25.9977608,34.2577248z'
                            />
                            <path
                                className='st0'
                                d='M19.3807373,31.8563633c-0.0917263-0.5370064-0.4687347-0.9140625-1.1303616-1.1303139
			c-0.5008678-0.1664314-1.332119-0.2500057-2.4946127-0.2500057c-0.7717543,0-1.3546219,0.0240765-1.75175,0.0699387
			c-0.6567631,0.0731335-1.1231146,0.2242603-1.3997221,0.4525738c-0.2644024,0.2234974-0.3995113,0.6069908-0.4050417,1.1480503
			h2.0193939c0.0121098-0.2323666,0.0522518-0.3947468,0.1206169-0.4871864
			c0.1559906-0.2009964,0.639123-0.3014946,1.4510193-0.3014946c0.7709904,0,1.2517395,0.1045036,1.4414845,0.3135548
			c0.1431198,0.1623802,0.2154884,0.4188213,0.2154884,0.7709446v0.8988075h-0.0346107
			c-0.1558952-0.2275047-0.3513622-0.3770599-0.586874-0.4469986c-0.4027538-0.1229973-1.0418768-0.1848793-1.9165125-0.1848793
			c-1.1046162,0-1.8981104,0.1334419-2.3812428,0.400322c-0.4437542,0.243618-0.6657267,0.6913757-0.6657267,1.3449898
			c0,0.5956955,0.2042379,1.0185661,0.6134748,1.2701912c0.2467632,0.1543694,0.5538816,0.2636871,0.9188776,0.3264275
			c0.3657589,0.0651245,0.8553753,0.096447,1.4719963,0.096447c0.4373655,0,0.8223848-0.0176888,1.1560097-0.0522537
			c0.2766075-0.0313683,0.5386257-0.0980644,0.7862473-0.2025681c0.2701244-0.1198044,0.4968643-0.3111229,0.6817455-0.5740013
			l0.0522499,0.0056267l-0.0956345,0.7596931H19.44977V32.459301C19.44977,32.3242378,19.4272671,32.123291,19.3807373,31.8563633z
			 M17.180275,34.9764175c-0.2652607,0.1471214-0.770895,0.2202568-1.5202427,0.2202568
			c-0.6840324,0-1.1511469-0.0498199-1.3971472-0.1503181c-0.2419958-0.1004982-0.3626118-0.3248062-0.3626118-0.6728783
			c0-0.3014946,0.0940142-0.498436,0.2837582-0.5916862c0.2484798-0.1238098,0.7252235-0.1857414,1.4334755-0.1857414
			c0.8055067,0,1.3345041,0.0739899,1.5877504,0.2211151c0.1727715,0.1004982,0.2588711,0.2934341,0.2588711,0.5788155
			C17.4641285,34.678978,17.3700199,34.8719139,17.180275,34.9764175z'
                            />
                            <path
                                className='st0'
                                d='M5,30.5403366h2.0033908l-0.1213937,0.7765942l0.0345688,0.0056286
			c0.2419829-0.2894154,0.4863772-0.4920044,0.7331834-0.6085739c0.3746305-0.1736488,0.8778901-0.2612782,1.5105824-0.2612782
			c1.058774,0,1.7268391,0.2789631,2.0025864,0.8352833c0.109334,0.2090206,0.1640015,0.6752987,0.1640015,1.3972282H9.4626083
			v-0.3062973c0-0.3135338-0.0546665-0.5305939-0.1640015-0.6495743c-0.1439028-0.1479225-0.4252777-0.2202759-0.8457327-0.2202759
			c-0.6560059,0-1.0901275,0.1680202-1.3031688,0.5040627c-0.0980792,0.1511383-0.1463146,0.419651-0.1463146,0.8055382v3.2655563H5
			V30.5403366z'
                            />
                        </g>
                    </g>
                </g>
            );

        case 'BTI':
            return (
                <g {...props}>
                    <g>
                        <g>
                            <path
                                className='st0'
                                d='M35.4835281,20.5781307c0.8629837,0.5547028,1.2854004,1.295578,1.2854004,2.259222
			c0,1.8271599-1.5469322,3.0759907-3.822361,3.0759907c-2.2757225,0-3.8138523-1.2405796-3.8138523-3.0757236
			c0-0.9702339,0.4152622-1.7116585,1.281004-2.261137c-0.6740494-0.5467186-1.0158958-1.2075615-1.0158958-1.9806252
			c0-1.662981,1.4303284-2.8056593,3.5569954-2.8056593c2.1263733,0,3.5484505,1.1344271,3.5484505,2.8056593
			c0,0.7815838-0.3432121,1.4449024-1.0200081,1.9822731H35.4835281z M34.243782,22.7570515
			c0-0.6765289-0.5084915-1.1479015-1.2886963-1.1479015c-0.7804832,0-1.2974911,0.4713726-1.2974911,1.1479015
			c0,0.6765137,0.5170078,1.1476059,1.2974911,1.1476059C33.7352905,23.9046574,34.243782,23.4335651,34.243782,22.7570515
			 M31.8487225,18.6742439c0,0.5582657,0.4526615,0.9652767,1.1063633,0.9652767c0.6534233,0,1.097847-0.407011,1.097847-0.9652767
			c0-0.5579967-0.4444237-0.965023-1.097847-0.965023C32.301384,17.7092209,31.8487225,18.1162472,31.8487225,18.6742439'
                            />
                            <path
                                className='st0'
                                d='M43.7143211,20.5781307C44.5775833,21.1328335,45,21.8737087,45,22.8373528
			c0,1.8271599-1.5466614,3.0759907-3.8223724,3.0759907c-2.275444,0-3.813839-1.2405796-3.813839-3.0757236
			c0-0.9702339,0.4155312-1.7116585,1.2809906-2.261137c-0.6740494-0.5467186-1.0158844-1.2075615-1.0158844-1.9806252
			c0-1.662981,1.4305992-2.8056593,3.5569839-2.8056593c2.1263733,0,3.5484619,1.1344271,3.5484619,2.8056593
			C44.7343407,19.3774414,44.3911324,20.04076,43.7143211,20.5781307L43.7143211,20.5781307z M42.4748535,22.7570515
			c0-0.6765289-0.5084915-1.1479015-1.2887077-1.1479015c-0.7807503,0-1.297493,0.4713726-1.297493,1.1479015
			c0,0.6765137,0.5167427,1.1476059,1.297493,1.1476059C41.966362,23.9046574,42.4748535,23.4335651,42.4748535,22.7570515
			 M40.0797806,18.6742439c0,0.5582657,0.4526749,0.9652767,1.1063652,0.9652767c0.6537018,0,1.097847-0.407011,1.097847-0.9652767
			c0-0.5579967-0.4441452-0.965023-1.097847-0.965023C40.5324554,17.7092209,40.0797806,18.1162472,40.0797806,18.6742439'
                            />
                            <path
                                className='st0'
                                d='M16.0121059,17.0250053c-1.9120092,0-2.6061392,1.3590965-2.6061392,1.3590965
			s-0.7667255-1.3590965-2.5053492-1.3590965c-0.7337227,0-1.3197689,0.2235851-1.7542801,0.5035343v1.8461552
			c0,0,0.3658981-0.3833714,0.9710503-0.3833714c1.1626158,0,1.2150097,1.0073643,1.2150097,1.48699v4.8921547h2.553195v-4.8921547
			c0-0.3352432,0.038497-1.48699,1.2790623-1.48699c1.1626158,0,1.2789488,0.9548435,1.2789488,1.4344559v4.9446888h2.5261059
			v-5.4771023C18.9697094,18.9115715,18.2421684,17.0250053,16.0121059,17.0250053z'
                            />
                            <path
                                className='st0'
                                d='M5,18.8623466v0.9677544c0.3162627-0.1017456,0.5558009-0.2166996,0.7705855-0.2659245
			c0.3840466-0.0880032,0.582737,0.1726971,0.582737,0.5354404l0.00138,5.2708511h2.550858v-8.6430264
			c0,0-1.0868483,0.6553497-2.0946207,1.2061939C5.8031678,18.4844818,5,18.8623466,5,18.8623466z'
                            />
                            <path
                                className='st0'
                                d='M28.3449516,21.1177101c0-2.2154884-1.7676296-4.2123337-4.2042236-4.2123337
			c-2.6696701,0-4.4117126,1.9217682-4.4117126,4.292635c0,2.3705845,1.7757378,4.292635,4.4117126,4.292635
			c1.8868504,0,3.1660519-0.8959866,3.6766109-1.5507889l-1.5361996-1.1352425c0,0-0.689045,0.7191639-1.981596,0.7191639
			c-1.5513382,0-1.7273445-1.3313293-1.7273445-1.3313293h5.6675568
			C28.2397556,22.1924496,28.3449516,21.6256523,28.3449516,21.1177101z M25.6627769,20.6463356h-3.1963081
			c0-0.0010986-0.0001411-0.0021954-0.0001411-0.003294c0-0.9870186,0.6805134-1.7523651,1.5632915-1.7523651
			c0.8826523,0,1.6332855,0.7653465,1.6332855,1.7523651C25.6629047,20.6441402,25.6627769,20.645237,25.6627769,20.6463356z'
                            />
                        </g>
                        <g>
                            <path
                                className='st0'
                                d='M8.5631542,27.8293209c-0.7222033,0-1.3498416,0.1275482-1.8649931,0.3790226
			c-0.6974182,0.3374596-1.1099973,0.8577595-1.2255344,1.5450726c-0.1246886,0.7050457,0.1250701,1.2590904,0.7195344,1.6003647
			c0.4404144,0.262722,1.0272527,0.3959904,1.7441177,0.3959904c0.3927503,0,0.6791148,0.0242138,0.8472733,0.070734
			c0.3058119,0.0915146,0.3237333,0.2455654,0.2985668,0.3940868c-0.0297422,0.1530952-0.1151562,0.3685341-0.5155325,0.4709167
			c-0.1845551,0.0482368-0.4789276,0.0726395-0.8758721,0.0726395c-0.4671063,0-0.6913176-0.0423241-0.7988472-0.0779762
			c-0.2230673-0.0730209-0.3408923-0.2091484-0.371397-0.4287834c-0.0076265-0.0547218-0.0377498-0.1035271-0.0831261-0.1346054
			c-0.0457573-0.0312653-0.1021914-0.0419426-0.1559563-0.0285988l-1.1317315,0.2718773
			c-0.0960908,0.0230675-0.1597695,0.1143913-0.1483302,0.2125816c0.127358,1.0863533,0.9689116,1.6371574,2.5010204,1.6371574
			c0.7317367,0,1.3570867-0.1279297,1.857367-0.379406c0.6905546-0.3374596,1.1023712-0.8569946,1.22439-1.5452614
			c0.1216383-0.7014217-0.127739-1.2548962-0.7191525-1.5999832c-0.4411774-0.262722-1.0283966-0.3957996-1.7444992-0.3957996
			c-0.3980889,0-0.683691-0.0240231-0.8503242-0.0716877c-0.2859831-0.0806465-0.3244958-0.2175369-0.2947536-0.3963718
			c0.0263104-0.1489029,0.1075301-0.3595772,0.505619-0.4674892c0.1872234-0.0484257,0.4880781-0.0728302,0.8937931-0.0728302
			c0.3515692,0,0.6188688,0.029171,0.7916021,0.0857964c0.2264996,0.0777874,0.3439426,0.2133427,0.3698721,0.4264965
			c0.0064821,0.055481,0.0369873,0.1056232,0.0827446,0.1372719c0.0461388,0.0316486,0.1044798,0.0421352,0.1578627,0.0287876
			l1.130969-0.2806435c0.0953283-0.0236416,0.1582441-0.114584,0.1471863-0.2120094
			C10.9284277,28.380125,10.0899248,27.8293209,8.5631542,27.8293209z'
                            />
                            <path
                                className='st0'
                                d='M17.4046192,28.233511c-0.3797874-0.2682533-0.9250622-0.4041901-1.6209545-0.4041901H12.451004
			c-0.0945654,0-0.1757851,0.067873-0.1921806,0.1611042l-1.0611897,5.9900169
			c-0.0102949,0.0570068,0.0053387,0.1153488,0.0427065,0.1595802c0.0369873,0.0442314,0.0918961,0.0697784,0.1494741,0.0697784
			h1.1225805c0.0949469,0,0.1761656-0.0682526,0.1921806-0.1616745l0.4015207-2.2983551h2.0461168
			c0.6890287,0,1.2773924-0.1353645,1.7502184-0.4030457c0.5643406-0.3239231,0.9136219-0.8367863,1.0379295-1.5246735
			C18.0684814,29.1128139,17.8885021,28.5785961,17.4046192,28.233511z M16.4330368,29.7547512
			c-0.0404186,0.2394638-0.1571007,0.3849335-0.3694897,0.4587173c-0.0953283,0.0345097-0.2978048,0.075882-0.7225857,0.075882
			h-1.9763355l0.1830292-1.008379h2.0472603c0.3992329,0,0.5898876,0.0444241,0.6806412,0.0821724
			C16.3846111,29.4073772,16.4791756,29.4855461,16.4330368,29.7547512z'
                            />
                            <path
                                className='st0'
                                d='M21.8773994,27.8293209c-0.9212475,0-1.7128506,0.3056202-2.3523083,0.907711
			c-0.6131496,0.5736828-1.0078068,1.3315392-1.172533,2.2533588c-0.1635818,0.9361191-0.0308857,1.7048416,0.3923702,2.2819557
			c0.4404144,0.6221123,1.1385937,0.9374542,2.0758572,0.9374542c0.9147663,0,1.7075119-0.3052406,2.3557415-0.9069481
			c0.6196308-0.5736847,1.0146694-1.3290596,1.1732941-2.2455425c0.1635838-0.942028,0.0308857-1.7136116-0.3935127-2.2920609
			C23.5071239,28.1442833,22.8077984,27.8293209,21.8773994,27.8293209z M21.0091534,32.7581482
			c-0.5010433,0-0.8346901-0.1509972-1.022295-0.4644356c-0.1776905-0.286747-0.2203979-0.7027569-0.1273575-1.2364025
			c0.0949459-0.545084,0.2928467-0.9753933,0.5895061-1.279871c0.3183956-0.3340282,0.7237282-0.4964676,1.2396431-0.4964676
			c0.4968491,0,0.8324032,0.1519527,1.0264893,0.4638653c0.1780739,0.2897968,0.2207794,0.7088585,0.1277409,1.2446003
			c-0.0949478,0.5298328-0.2920856,0.9584255-0.583025,1.2705307
			C21.9323082,32.5951385,21.5231609,32.7581482,21.0091534,32.7581482z'
                            />
                            <path
                                className='st0'
                                d='M30.955658,28.233511c-0.3797855-0.2682533-0.9250622-0.4041901-1.6209545-0.4041901h-3.3326607
			c-0.0945644,0-0.1757832,0.067873-0.1921806,0.1611042l-1.0611897,5.9900169
			c-0.010294,0.0570068,0.0053387,0.1153488,0.0427074,0.1595802c0.0369873,0.0442314,0.0918961,0.0697784,0.1494732,0.0697784
			h1.1225815c0.0949459,0,0.1761646-0.0682526,0.1921806-0.1616745l0.4015198-2.2983551h1.317812l1.2217216,2.3547897
			c0.033556,0.0646324,0.1002846,0.1052399,0.1731148,0.1052399h1.3372593c0.067873,0,0.1311703-0.0354614,0.1666336-0.0934219
			c0.0354614-0.0581474,0.0381298-0.1305962,0.0068626-0.1910362l-1.174057-2.2853889
			c1.0085678-0.2324085,1.6087513-0.8428879,1.7849178-1.8179016C31.6195202,29.1128139,31.4395409,28.5785961,30.955658,28.233511z
			 M27.0986938,29.2809715h2.0472603c0.3992329,0,0.5898895,0.0444241,0.6806412,0.0821724
			c0.1090546,0.0442333,0.203619,0.1224022,0.1574821,0.3916073c-0.0404205,0.2394638-0.1571007,0.3849335-0.3694916,0.4587173
			c-0.0953274,0.0345097-0.2978039,0.075882-0.7225857,0.075882h-1.9763355L27.0986938,29.2809715z'
                            />
                            <path
                                className='st0'
                                d='M37.8974304,27.8994808c-0.0369873-0.0444221-0.0918961-0.0701599-0.1498566-0.0701599h-4.8628616
			c-0.0949478,0-0.1761665,0.0682545-0.1921806,0.1618652l-0.1841736,1.0611897
			c-0.0099144,0.0568161,0.0057182,0.1149654,0.0427055,0.1590061c0.0373688,0.0442333,0.0918961,0.0695896,0.1494751,0.0695896
			h1.6217155l-0.8316383,4.6996613c-0.0102959,0.0568161,0.0053368,0.1151581,0.0427055,0.1593895
			c0.0369873,0.0442314,0.0918961,0.0697784,0.1494751,0.0697784h1.1271553c0.0945663,0,0.1757851-0.0678711,0.1921806-0.161293
			l0.8438416-4.7675362h1.7086563c0.0941849,0,0.1750221-0.0674915,0.1921806-0.1603413l0.1929436-1.0611897
			C37.9500504,28.0024357,37.9344177,27.9439049,37.8974304,27.8994808z'
                            />
                            <path
                                className='st0'
                                d='M41.4291344,27.8293209c-0.7222023,0-1.3498421,0.1275482-1.864994,0.3790226
			c-0.6974182,0.3374596-1.1099968,0.8577595-1.2255325,1.5450726c-0.124691,0.7050457,0.1250687,1.2590904,0.719532,1.6003647
			c0.4404144,0.262722,1.0272522,0.3959904,1.7441177,0.3959904c0.3927498,0,0.6791153,0.0242138,0.8472748,0.070734
			c0.3058128,0.0915146,0.3237343,0.2455654,0.2985649,0.3940868c-0.0297394,0.1530952-0.1151543,0.3685341-0.5155334,0.4709167
			c-0.1845512,0.0482368-0.4789238,0.0726395-0.8758698,0.0726395c-0.4671059,0-0.6913185-0.0423241-0.7988472-0.0779762
			c-0.2230682-0.0730209-0.3408928-0.2091484-0.3713989-0.4287834c-0.0076256-0.0547218-0.0377502-0.1035271-0.0831261-0.1346054
			c-0.0457573-0.0312653-0.1021881-0.0419426-0.1559563-0.0285988l-1.1317291,0.2718773
			c-0.0960922,0.0230675-0.159771,0.1143913-0.1483307,0.2125816c0.1273575,1.0863533,0.9689102,1.6371574,2.5010185,1.6371574
			c0.731739,0,1.35709-0.1279297,1.8573685-0.379406c0.6905556-0.3374596,1.1023712-0.8569946,1.2243919-1.5452614
			c0.1216354-0.7014217-0.127739-1.2548962-0.7191544-1.5999832c-0.4411774-0.262722-1.0283966-0.3957996-1.7444992-0.3957996
			c-0.3980904,0-0.6836891-0.0240231-0.8503227-0.0716877c-0.285984-0.0806465-0.3244972-0.2175369-0.294754-0.3963718
			c0.02631-0.1489029,0.1075287-0.3595772,0.505619-0.4674892c0.1872215-0.0484257,0.4880791-0.0728302,0.8937912-0.0728302
			c0.3515701,0,0.6188698,0.029171,0.7916031,0.0857964c0.2264977,0.0777874,0.3439445,0.2133427,0.369873,0.4264965
			c0.0064812,0.055481,0.0369873,0.1056232,0.0827446,0.1372719c0.0465202,0.0316486,0.1040955,0.0421352,0.1578636,0.0287876
			l1.1309662-0.2806435c0.0953293-0.0236416,0.1582451-0.114584,0.1471863-0.2120094
			C43.7944069,28.380125,42.9559021,27.8293209,41.4291344,27.8293209z'
                            />
                        </g>
                    </g>
                </g>
            );

        case 'CMD':
            return (
                <g {...props}>
                    <g>
                        <polygon
                            className='st0'
                            points='29.0592766,11.6787319 26.2504978,20.8204918 26.2155209,20.8204918 23.2469902,11.6787319 
		19.3172665,11.6787319 19.3172665,24.9711895 21.9326096,24.9711895 21.9326096,15.6446018 21.966362,15.6446018 
		25.0765953,24.9711895 27.2282505,24.9711895 30.3396416,15.5517931 30.3746834,15.5517931 30.3746834,24.9711895 
		32.9893303,24.9711895 32.9893303,11.6787319 	'
                        />
                        <g>
                            <defs>
                                <path
                                    id='CMD-SVGID_1_'
                                    d='M36.921814,14.1369143h1.9036713c0.6578331,0,1.2106743,0.1026802,1.6581306,0.307539
				c0.4479675,0.2045965,0.8078842,0.4991894,1.080513,0.8837376c0.2736549,0.384943,0.4682465,0.8469076,0.5863304,1.3861341
				c0.1206627,0.5402622,0.178299,1.1452065,0.178299,1.8158836c0,0.7325306-0.0835686,1.3531418-0.2531242,1.8615704
				c-0.1713562,0.5090599-0.3980331,0.9217224-0.6806793,1.2373581c-0.2839317,0.3170433-0.6068764,0.5433388-0.9696198,0.6796532
				c-0.3620987,0.1377354-0.7378006,0.2055054-1.1233864,0.2055054H36.921814V14.1369143z M34.2526207,11.6787319v13.2924576
				h5.236599c0.9302139,0,1.7383499-0.1700821,2.4232712-0.5117683c0.6867142-0.341423,1.2589302-0.8094215,1.7184486-1.4060192
				c0.4577217-0.5953236,0.8017197-1.3028412,1.0282669-2.1212406C44.8858871,20.1124725,45,19.219101,45,18.2507706
				c0-1.1036205-0.1397858-2.0657864-0.4166451-2.8852253c-0.2789268-0.8186617-0.6636047-1.5011415-1.156498-2.0473061
				c-0.4941826-0.5462837-1.0779495-0.9567776-1.7529907-1.2294064c-0.6738701-0.2723665-1.402813-0.4101009-2.1846466-0.4101009
				H34.2526207z'
                                />
                            </defs>
                            <use xlinkHref='#CMD-SVGID_1_' style={{ overflow: 'visible', fill: '#8C8C8C' }} />
                            <clipPath id='CMD-SVGID_3_'>
                                <use xlinkHref='#CMD-SVGID_1_' style={{ overflow: 'visible' }} />
                            </clipPath>
                            <rect x='34.2526207' y='11.6787319' className='st2' width='10.7473803' height='13.2924566' />
                        </g>
                        <g>
                            <defs>
                                <path
                                    id='CMD-SVGID_5_'
                                    d='M12.2671614,23.0114193c0.0491581,0.0016708,0.0815048,0.0016708,0.0085964-0.0001316
				C12.2729969,23.0112877,12.2699213,23.0112877,12.2671614,23.0114193 M15.8324203,11.6667976
				c0.0218191,0.0073214,0.0442162,0.0142479,0.0658512,0.0223312c0.016613,0.0061646,0.0307951,0.0132227,0.0468445,0.0193872
				c0.59552,0.2333555,0.913456,0.553092,0.9489441,0.9604979c0.020792,0.2434883-0.0684795,0.513423-0.2630081,0.8089094
				c-0.1017189,0.1537695-0.2320023,0.4359016-0.3919353,0.6037874c0-1.0219841-1.9412088-1.23172-2.6371737-1.1708775
				c-1.4885511,0.1328573-2.9053087,0.5732546-3.8907003,2.0239496C8.7261,16.3835468,8.5607653,17.7692986,8.7212763,19.5738792
				C8.8261509,20.7255001,9.242547,21.6077003,9.96597,22.221384c0.645505,0.5460339,1.3324137,0.8094234,2.3011913,0.7900352
				c-0.0688734-0.0024319-0.1704617-0.0085945-0.0613546-0.0188599c0.7368288-0.0690594,1.4375391-0.2687912,2.1032591-0.5946922
				c0.9879036-0.4832592,1.8983469-1.2464809,2.729722-2.2946472c0.1302795,0.230793,0.2006912,0.4170399,0.2135868,0.5572052
				c0.0053883,0.0623817,0.0036526,0.1251431-0.0014744,0.1884308c-0.0508385,0.6424294-0.6352406,1.321701-1.7566423,2.0381832
				c-0.9521523,0.6079025-1.9562998,0.9510078-2.9661522,1.2294083c-1.3963985,0.3845615-3.0188971,0.1213036-3.0188971,0.1213036
				c-0.5672874-0.1479988-1.1951408-0.5107288-1.6362472-0.8524284c-0.9284658-0.7189274-1.3281693-1.7731266-1.4467788-3.1628494
				c-0.1884298-2.1938858,0.4280148-4.1522484,1.8430252-5.8740349c1.0611267-1.2893429,2.336484-2.1394587,3.8135586-2.5745974
				l-0.0236712-0.0062828c0.1158361-0.0318346,0.227953-0.0593052,0.3395042-0.0858688
				c0.0195055-0.0053883,0.0382481-0.0102777,0.0578852-0.0148916H6.9760013C5.8903489,11.6667976,5,12.547328,5,13.6234779
				v9.3919239c0,1.0766621,0.8903489,1.9568119,1.9760013,1.9568119h9.1029968c1.0880852,0,1.9776573-0.8801498,1.9776573-1.9568119
				v-9.3919239c0-1.0761499-0.8895721-1.9566803-1.9776573-1.9566803H15.8324203z'
                                />
                            </defs>
                            <use xlinkHref='#CMD-SVGID_5_' style={{ overflow: 'visible', fill: '#8C8C8C' }} />
                            <clipPath id='CMD-SVGID_6_'>
                                <use xlinkHref='#CMD-SVGID_5_' style={{ overflow: 'visible' }} />
                            </clipPath>
                            <rect x='5' y='11.6667976' className='st3' width='13.0566559' height='13.3054161' />
                        </g>
                        <g>
                            <defs>
                                <path
                                    id='CMD-SVGID_7_'
                                    d='M22.6242619,35.5889168c-0.343605-0.3401451-0.5157356-0.7692413-0.5157356-1.287159
				c0-0.5181808,0.1721306-0.9472885,0.5157356-1.2871742c0.3434887-0.3401489,0.793375-0.5100975,1.3500671-0.5100975
				c0.5561695,0,1.0063171,0.1699486,1.3499355,0.5100975c0.3438683,0.3398857,0.5157375,0.7689934,0.5157375,1.2871742
				c0,0.5179176-0.1718693,0.9470139-0.5157375,1.287159c-0.3436184,0.339901-0.793766,0.5099678-1.3499355,0.5099678
				C23.4176369,36.0988846,22.9677505,35.9288177,22.6242619,35.5889168 M23.5813026,26.6757698l-2.5363483,3.918251
				c-0.1858673,0.2913704-0.3629932,0.5747776-0.5319176,0.8499832c-0.1691723,0.2751923-0.3165283,0.5641327-0.441803,0.8661499
				c-0.1257858,0.3024139-0.2266769,0.6233063-0.3029251,0.9634552c-0.0764961,0.3400192-0.1144962,0.7149467-0.1144962,1.1251755
				c0,0.5936546,0.1116714,1.1308289,0.3355236,1.611145c0.2234707,0.4803123,0.5290909,0.8931198,0.9166088,1.2383957
				c0.3868618,0.3455391,0.8426647,0.6127777,1.3663654,0.8014717c0.5234394,0.1886787,1.0906467,0.2834053,1.7020187,0.2834053
				c0.5996857,0,1.164465-0.0947266,1.6938038-0.2834053c0.528841-0.188694,0.9869423-0.4559326,1.374712-0.8014717
				c0.3868752-0.3452759,0.6926136-0.7580833,0.9162159-1.2383957c0.2234707-0.4803162,0.3356533-1.0174904,0.3356533-1.611145
				c0-0.5180473-0.0875378-1.0063171-0.2619705-1.4653244c-0.1745758-0.4586258-0.4202461-0.8553734-0.7362614-1.1898689
				c-0.3167934-0.3345108-0.6985302-0.5990562-1.1457214-0.7935047c-0.4474545-0.1942139-0.9439354-0.2913723-1.4892063-0.2913723
				c-0.1419659,0-0.2782822,0.0107765-0.4090767,0.0323334c-0.1310558,0.0220814-0.261982,0.0486584-0.3928947,0.0810051
				l2.716814-4.096283H23.5813026z'
                                />
                            </defs>
                            <use xlinkHref='#CMD-SVGID_7_' style={{ overflow: 'visible', fill: '#8C8C8C' }} />
                            <clipPath id='CMD-SVGID_8_'>
                                <use xlinkHref='#CMD-SVGID_7_' style={{ overflow: 'visible' }} />
                            </clipPath>
                            <rect x='19.6538124' y='26.6757698' className='st4' width='8.6409006' height='11.6574316' />
                        </g>
                        <g>
                            <defs>
                                <path
                                    id='CMD-SVGID_9_'
                                    d='M32.2541046,35.6778755c-0.2949753-0.2807236-0.4420662-0.6206093-0.4420662-1.0200653
				c0-0.410099,0.1470909-0.7553749,0.4420662-1.0360985c0.2941818-0.2807121,0.6869583-0.4210091,1.1783142-0.4210091
				c0.4908447,0,0.883358,0.1402969,1.1780701,0.4210091c0.294445,0.2807236,0.4415512,0.6259995,0.4415512,1.0360985
				c0,0.399456-0.1471062,0.7393417-0.4415512,1.0200653c-0.2947121,0.2807121-0.6872253,0.4210091-1.1780701,0.4210091
				C32.9410629,36.0988846,32.5482864,35.9585876,32.2541046,35.6778755 M32.4009323,30.8853912
				c-0.2615891-0.2482471-0.3927612-0.550396-0.3927612-0.9067249c0-0.3452759,0.1311722-0.6419182,0.3927612-0.8905468
				c0.2621155-0.2479839,0.6056023-0.3722324,1.0314865-0.3722324c0.4253731,0,0.7688599,0.1242485,1.0304489,0.3722324
				c0.2621155,0.2486286,0.3927765,0.5452709,0.3927765,0.8905468c0,0.356329-0.130661,0.6584778-0.3927765,0.9067249
				c-0.2615891,0.248497-0.6050758,0.372366-1.0304489,0.372366C33.0065346,31.2577572,32.6630478,31.1338882,32.4009323,30.8853912
					M31.9265327,26.6757698c-0.4692726,0.1296387-0.8813038,0.3265457-1.2355709,0.5909595
				c-0.3545284,0.264677-0.6330585,0.5937843-0.8345795,0.987587c-0.2019806,0.3943138-0.3026104,0.8558846-0.3026104,1.3844643
				c0,0.6261311,0.171751,1.1603489,0.5154228,1.6029263c0.3434868,0.4425793,0.8263645,0.7556381,1.4481316,0.9390602v0.0320969
				c-0.6982651,0.1838036-1.2330074,0.5159988-1.6037025,0.9959221c-0.3710098,0.4804459-0.5564957,1.0767937-0.5564957,1.7890549
				c0,0.5505142,0.1119328,1.0362206,0.335535,1.4572411c0.2235241,0.4210091,0.5236225,0.7688599,0.9002323,1.0443153
				c0.3760853,0.2751923,0.8099346,0.4828796,1.3010292,0.6233063c0.4908409,0.1400337,1.0035019,0.2104988,1.5384941,0.2104988
				c0.5339699,0,1.047142-0.0704651,1.5379829-0.2104988c0.4908295-0.1404266,0.9246826-0.348114,1.3010292-0.6233063
				c0.3763466-0.2754555,0.6761818-0.6233063,0.9000473-1.0443153C37.3948174,36.0340614,37.507,35.5483551,37.507,34.9978409
				c0-0.7122612-0.1856003-1.308609-0.5565605-1.7890549c-0.370945-0.4799232-0.9056854-0.8121185-1.6037025-0.9959221v-0.0320969
				c0.6220322-0.1834221,1.104908-0.4964809,1.4483948-0.9390602c0.3437386-0.4425774,0.5154915-0.9767952,0.5154915-1.6029263
				c0-0.5285797-0.1037216-0.9901505-0.311142-1.3844643c-0.2071762-0.3938026-0.4854546-0.7229099-0.8345795-0.987587
				c-0.3491402-0.2644138-0.7583466-0.4613209-1.227356-0.5909595c-0.4690247-0.1293869-0.9711571-0.1943302-1.505127-0.1943302
				C32.8974266,26.4814396,32.3958054,26.5463829,31.9265327,26.6757698'
                                />
                            </defs>
                            <use xlinkHref='#CMD-SVGID_9_' style={{ overflow: 'visible', fill: '#8C8C8C' }} />
                            <clipPath id='CMD-SVGID_10_'>
                                <use xlinkHref='#CMD-SVGID_9_' style={{ overflow: 'visible' }} />
                            </clipPath>
                            <rect x='29.3571281' y='26.4814396' className='st5' width='8.1498737' height='11.8517628' />
                        </g>
                        <g>
                            <defs>
                                <path
                                    id='CMD-SVGID_11_'
                                    d='M13.0843515,26.6354713c-2.0947952,0.4474545-2.3494568,2.486021-2.3494568,2.486021l2.3248119,0.6629734
				c0.0762348-0.3020306,0.2400198-0.5559177,0.4909611-0.7609081c0.250679-0.204977,0.5673399-0.3076706,0.9492073-0.3076706
				c0.4036856,0,0.7447281,0.1132088,1.0228777,0.3398857c0.2780304,0.2268105,0.4172897,0.5129242,0.4172897,0.8582001
				c0,0.2700653-0.0548096,0.4885292-0.1636543,0.6557827c-0.1092377,0.1673737-0.2536354,0.2968941-0.433588,0.3884144
				c-0.1802149,0.0920315-0.3791723,0.1539001-0.597373,0.1863651c-0.2184763,0.0322285-0.4420652,0.0486584-0.6710567,0.0486584
				h-0.8018522v1.8796558h0.5727291c0.2727604,0,0.5453892,0.0082169,0.8184128,0.024395
				c0.2724972,0.0161705,0.5179186,0.0673904,0.7362499,0.1537704c0.218214,0.0865135,0.3953533,0.2184639,0.5319176,0.3966293
				c0.1360645,0.1781654,0.2046089,0.4289742,0.2046089,0.7529411c0,0.4318008-0.128231,1.0101814-0.384429,1.2853737
				c-0.2565947,0.2752075-0.6523151,0.4129257-1.1865454,0.4129257c-0.5239515,0-0.9192915-0.1187286-1.1865311-0.3561974
				c-0.2675028-0.2374535-0.4446287-0.5611725-0.5319176-0.971405l-2.4720354,0.5989227
				c0.2399015,1.0255737,0.6200991,1.775692,1.3673906,2.2503662c0.7471733,0.475174,1.6391249,0.7126312,2.6757383,0.7126312
				c0.5672083,0,1.1046457-0.0704651,1.6117868-0.2104988c0.5074024-0.1404266,0.9492054-0.3533707,1.3258018-0.6397362
				c0.3763485-0.2857323,0.6763153-0.6446114,0.9000492-1.0765419c0.223341-0.4316559,0.3355217-0.9335403,0.3355217-1.5057564
				c0-0.3344955-0.0492878-0.6529579-0.1473541-0.9552383c-0.0981846-0.3021507-0.2400188-0.5719528-0.4256363-0.8095551
				c-0.1856022-0.2373238-0.4117699-0.4370575-0.6790085-0.599041c-0.2674904-0.1618538-0.5756874-0.2644157-0.9246807-0.3075409
				v-0.0485268c0.6435871-0.1296387,1.1346779-0.4210091,1.4726448-0.8743649
				c0.3382168-0.4533577,0.507391-0.9981155,0.507391-1.6352749c0-0.5503979-0.1062813-1.025322-0.3189602-1.4247761
				c-0.2126942-0.3991909-0.4991894-0.7311249-0.8592243-0.9959202c-0.3599167-0.264164-0.7772064-0.4583645-1.2518826-0.5828762
				c-0.4745312-0.123867-0.968317-0.1861153-1.480979-0.1861153C13.9926023,26.4814396,13.5279551,26.5404816,13.0843515,26.6354713
				'
                                />
                            </defs>
                            <use xlinkHref='#CMD-SVGID_11_' style={{ overflow: 'visible', fill: '#8C8C8C' }} />
                            <clipPath id='CMD-SVGID_12_'>
                                <use xlinkHref='#CMD-SVGID_11_' style={{ overflow: 'visible' }} />
                            </clipPath>
                            <rect x='10.3749781' y='26.4814396' className='st6' width='8.2162895' height='11.8517628' />
                        </g>
                    </g>
                </g>
            );

        case 'DG':
            return (
                <g {...props}>
                    <path
                        className='st0'
                        d='M15.0845108,33.9503098v0.0011406h-0.0864239
	C15.026515,33.9514503,15.0560818,33.9514503,15.0845108,33.9503098z M14.9707966,29.112915v-1.8012333H8.9123631H8.4129057
	c-1.9461527,0-2.5834627-0.6059742-2.5834627-0.6059742c0,2.4132671,3.0829201,2.4072075,3.0829201,2.4072075H14.9707966z
	 M9.8785572,31.626627h0.1081486h0.3913012h2.1104088v-1.8000965h-2.1104088v-0.0011387H9.8785572
	c-1.9461527,0-2.5834627-0.5295296-2.5834627-0.5295296C7.2950945,31.7091293,9.8785572,31.626627,9.8785572,31.626627z
	 M12.4931564,33.9988251v-1.8000946h-1.2243843v-0.0011406h-0.4994564c-1.9461536,0-2.583456-0.6059723-2.583456-0.6059723
	c0,2.1653862,2.0787735,2.3827782,2.5060692,2.4046173v0.0025902h0.0773869h0.4994564H12.4931564z M45.5,23.8217945
	c0,1.4498558-1.0871048,2.6461296-2.491478,2.8167019c-0.1137161,0.0159149-0.2296982,0.0227375-0.3479652,0.0227375h-7.6313515
	c-1.5681152,0-2.8394394,1.271328-2.8394394,2.8394432c0,1.5692539,1.2713242,2.8405781,2.8394394,2.8405781v-2.577898h1.8000946
	v4.1880932h-1.7136726c-0.0284271,0-0.0579948,0-0.086422-0.0011406c-1.9290276-0.0355911-3.5640755-1.2757225-4.1842289-3.0005817
	c-1.2867928,1.8495464-3.1526089,3.327631-5.8547077,3.8928623c-2.6931515-0.5633659-4.5555935-2.0335236-5.8419571-3.874485
	c-0.6251431,1.7152748-2.2553558,2.9467392-4.177515,2.9822044c-0.028429,0.0011406-0.0579958,0.0011406-0.0864239,0.0011406
	h-1.7136726v-4.1880932h1.8000965v2.577898c1.5681152,0,2.8394375-1.2713242,2.8394375-2.8405781
	c0-1.5681152-1.2713223-2.8394432-2.8394375-2.8394432H7.3394446c-0.1182671,0-0.2342505-0.0068226-0.3479648-0.0227375
	C5.5871067,26.4679241,4.5,25.2716503,4.5,23.8217945c0-0.1569252,0.0125136-0.3115749,0.0375276-0.4616776
	c0.4787388,0.8926525,1.4202881,1.5010262,2.505125,1.5010262h7.928144v0.0170536
	c0.6768293,0.0124855,1.3170547,0.1740284,1.8903923,0.4519825c-0.7603645-3.631855-0.4626503-6.8876915-0.4626503-6.8876915
	c6.7217712,0.3285046,8.5917301-3.2850771,8.5917301-3.2850771s1.8699665,3.6135817,8.5917397,3.2850771
	c0,0,0.298275,3.2618675-0.4647636,6.8977833c0.5787735-0.2843609,1.2267036-0.4494286,1.9119606-0.4620743v-0.0170536h7.9281425
	c1.0848351,0,2.0263863-0.6083736,2.5051231-1.5010262C45.4874878,23.5102196,45.5,23.6648693,45.5,23.8217945z
	 M30.8313675,27.9195004c0.0378036-0.1083775,0.0797672-0.2147274,0.1253872-0.3191814
	c1.3892345-3.6918564,1.0116005-7.812521,1.0116005-7.812521c-5.4593258,0.2668018-6.9780865-2.6680965-6.9780865-2.6680965
	s-1.5187531,2.9348984-6.9780788,2.6680965c0,0-0.3640175,3.9784374,0.9412861,7.6193848
	c0.1131859,0.2290535,0.2077389,0.4688416,0.2813835,0.7177715c0.2592239,0.603941,0.5703335,1.1897163,0.9422436,1.7373409
	c0.4689732-0.2394638,2.4939442-1.2386608,4.674181-1.792017c0,0,2.6406956-0.6696472,2.5522518-2.6154251
	c0,0-0.0505409-1.6804409-1.7562523-1.5919971c0,0-0.5306606,0.265337-0.1010761,0.6570129
	c0,0-1.0992374,0.0631809-0.454855,1.0360661c0,0-1.4909229-0.2400665-1.1497803,0.2526989
	c0,0-1.6551704-0.3032398-1.9205017,0.6570148c0,0-0.0631733,0.9097118,0.9223442,0.9855175
	c0,0-1.8194256,0.454855-2.5775146-0.7454586c0,0-0.4422245,0.3158722-0.3790512-1.1245022
	c0,0,0.1010818-0.7075539,0.5306683-0.8591785c0,0-0.1010818,0.8591785,0.7328262,0.1516247
	c0,0,0.6191101-0.6317444,0.6822834-1.3266678l-0.6570129,0.0379028c0,0,0.4801273-0.5053902,1.225584-0.7454586
	l-0.0252705-0.2526989c0,0-1.7436142-2.8807545,4.0305347-2.653326c0,0-3.1966343-0.0252705-3.3103485,1.5288239
	c0,0,0.3285103-0.4801254,0.7833672-0.5559387c0,0-0.1263523,0.3285103,0.0505409,0.4043159
	c0,0,0.2274284-0.3664055,0.5306625-0.4927597c0,0-0.0379028,0.3157196,0.1516228,0.4358273
	c0,0,0.1263466-1.6487789,3.4114227-0.9033146c0,0,2.1352978,0.8970737,2.6154251,0.1010761
	c0,0-0.1326694,1.168726-2.0910797,0.4927654c0,0-1.3961563-0.511713-2.5269814-0.1705723
	c0,0-0.4359016,0.1137142-0.6949158,0.5812035c0,0,2.950243-0.3980007,4.3274517,1.8636513
	c0,0-0.3158722-0.1642551-0.6570148-0.1010838c0,0,0.4169483,1.1876831,2.1226597,1.3772011c0,0-0.3790455,0.4422245-1.6425323,0
	c0,0,1.8194256,4.1821594-3.9926319,5.9384041c0,0,1.0992393,0.0884438,1.5035534,0.6064796
	c0,0-2.3496284-0.6491985-4.5725632,1.0954285c0.7105808,0.4380455,1.52915,0.774929,2.4751682,0.9728165
	C28.1095371,32.4552231,29.8584175,30.3062477,30.8313675,27.9195004z M34.9154892,33.9514503h0.0864258
	c-0.0284309,0-0.0579987,0-0.0864258-0.0011406V33.9514503z M41.0876389,27.3116817h-6.0584335v1.8012333h6.0584335
	c0,0,3.0829163,0.0060596,3.0829163-2.4072075c0,0-0.6373062,0.6059742-2.5834618,0.6059742H41.0876389z M39.621994,29.8253918
	v0.0011387h-2.1104088v1.8000965h2.1104088h0.3913002h0.1081467c0,0,2.5834656,0.0825024,2.5834656-2.3307648
	c0,0-0.63731,0.5295296-2.5834656,0.5295296H39.621994z M41.8141479,31.5916176c0,0-0.6373024,0.6059723-2.5834579,0.6059723
	h-0.4994545v0.0011406h-1.2243843v1.8000946h1.2243843H39.23069h0.0773888v-0.0025902
	C39.7353745,33.9743958,41.8141479,33.7570038,41.8141479,31.5916176z'
                    />
                </g>
            );

        case 'EBET':
            return (
                <g {...props}>
                    <g>
                        <path
                            className='st0'
                            d='M23.8224125,20.2512436H18.79179h-0.7859211H16.321764h-0.168396h-1.684104
		c0,0,1.4704437,0.198637,1.1022348,1.3711243l-2.5145473,6.7267704c-0.3278456,0.8861809-0.9233665,1.3205242-1.0658731,1.4225903
		h0.560689h0.7297497h1.1227503h0.7859211h4.9016104c1.4595795,0,2.0682011-0.6565952,2.4050293-1.4773083
		c0,0,0.7732906-1.8997669,0.9283085-2.539299c0.1550598-0.6395321-0.9283085-0.7983551-0.9283085-0.7983551
		c1.066618-0.1093998,1.4692669-0.5434685,1.6937943-0.926466l1.0271568-2.4612389
		C25.666872,20.6389084,24.5522022,20.2512436,23.8224125,20.2512436z M19.8604469,27.9602585h-4.4304743l0.8572721-2.0354481
		H20.71768L19.8604469,27.9602585z M21.4826546,24.1085548h-4.4304733l0.8557415-2.0421162h4.4347897L21.4826546,24.1085548z'
                        />
                        <path
                            className='st1'
                            d='M10.5135345,20.1689091c-2.7690802,0-5.5130172,2.2310257-6.4534473,4.8497543
		c-0.9405084,2.6711349,0.3076453,4.8638363,3.076726,4.8115101c2.1421404,0,4.9112606-1.1522865,6.1651802-2.9854088h-2.7691202
		c-0.8881817,0.7332802-1.8286505,0.9951496-2.7690806,0.9951496c-1.1494622,0-1.6196971-1.1522865-1.5152001-2.1997623h7.157897
		c0.208993-0.3142357,0.2002068-0.8459358,0.3047037-1.2125759C14.1813879,22.437355,13.1781588,20.1689091,10.5135345,20.1689091z
		 M6.7188268,23.9641266c0.6792278-1.0475159,1.880899-1.6760235,3.1348577-1.6760235
		c1.3061686,0,1.9331474,0.7332802,1.8808994,1.6760235H6.7188268z'
                        />
                        <path
                            className='st0'
                            d='M25.5231686,21.6175365c0.4651146-0.8527088,1.1918564-1.3759632,1.9185982-1.366272
		c0.6394749,0.0085258,8.899641,0.0470963,8.899641,0.0470963l-1.4616356,1.760067h-6.9914894l-0.8436127,1.9234409h6.3925934
		l-1.4897003,1.8975792H26.293396l-0.8390617,2.070507l8.8672523-0.0436821l-1.5965233,1.879837h-8.0374622h-0.3853569
		l-2.1469841,0.0000019C22.2633553,29.8290348,25.058054,22.4702473,25.5231686,21.6175365z'
                        />
                        <polygon
                            className='st0'
                            points='44.705719,22.0845222 41.345356,22.0845222 38.2485771,29.6746769 35.7554054,29.6746769 
		38.8521881,22.0845222 35.2750244,22.0845222 36.7573738,20.3283386 46.25,20.2779102 	'
                        />
                    </g>
                </g>
            );

        case 'EVO':
        case 'BMK2':
            return (
                <g {...props}>
                    <g>
                        <path
                            className='st0'
                            d='M30.9746971,25.5716133c-8.2833424,0.4091702-12.1737328,3.4297085-14.6025047,7.1553688
		C14.9422636,34.9194107,14.0188055,37.354187,13,39.6439133c10.048872,5.3659706,23.0248909-0.2919044,24-13.9530811'
                        />
                        <path
                            className='st0'
                            d='M31.6518993,24.4535522C33.2684746,24.5052032,35.0418205,24.4615097,37,24.3065529
		c-0.2819939-3.9420433-1.5649223-7.216795-3.4873619-9.7548733C28.774313,8.3020382,20.1474133,6.5384641,13,10.3554239
		C15.7942457,16.6428967,17.8874187,24.022604,31.6518993,24.4535522z'
                        />
                    </g>
                </g>
            );

        case 'GD':
            return (
                <g {...props}>
                    <path
                        className='st0'
                        d='M6.9337325,19.9975319c1.2892222-1.3813782,2.9074082-2.0720673,4.8545613-2.0720673
	c0.8023834,0,1.6312103,0.19734,2.48668,0.59202c0.315546,0.1448479,0.5328169,0.2170734,0.6510229,0.2170734
	c0.2103662,0,0.3881693-0.1580696,0.5330162-0.4738121c0.065712-0.1576748,0.1381369-0.2366123,0.216876-0.2366123
	c0.0919609,0,0.1381369,0.0659122,0.1381369,0.19734c0,0.0266399-0.019536,0.2894974-0.0590038,0.78936
	c-0.0134172,0.1576729-0.0199308,0.5326214-0.0199308,1.124836c0,0.4473686,0.0262451,1.1315479,0.0789347,2.0521374
	c0,0.2502289-0.0659113,0.3751431-0.19734,0.3751431c-0.1843128,0-0.3092318-0.263052-0.3747482-0.7893581
	c-0.1580687-1.1575947-0.5397243-2.0720692-1.1447678-2.7432213c-0.4471731-0.4864426-1.0723457-0.7299595-1.8745298-0.7299595
	c-2.4866819,0-3.7297249,2.210207-3.7297249,6.6306171c0,2.7497349,0.4092836,4.6639309,1.2284412,5.7423935
	c0.5148592,0.6843758,1.2017994,1.0263653,2.0606232,1.0263653c1.0828037,0,1.6246996-0.6839809,1.6246996-2.0523338
	c0-1.0261688-0.0436134-1.6016121-0.1298504-1.726923c-0.0866318-0.1247196-0.3096266-0.1872749-0.668786-0.1872749h-0.4990711
	c-0.2796326,0-0.4193478-0.0593987-0.4193478-0.1776047c0-0.1184063,0.1118927-0.1776047,0.3356762-0.1776047
	c0.4601965,0,1.0389948,0.0067081,1.736393,0.0197315h1.2631721h1.1939077h1.0557652h0.2565422
	c0.1578732,0,0.2368088,0.0560436,0.2368088,0.1675415c0,0.1253128-0.2472649,0.1811581-0.741209,0.1679363
	c-0.5857048-0.0130234-0.8976974,0.1178112-0.9365749,0.3929024c-0.0260506,0.1570835-0.039072,0.674511-0.039072,1.5518837
	v0.8053417c-1.5658922,1.0608997-3.0587683,1.5911522-4.4798136,1.5911522c-1.8680201,0-3.4305573-0.6575356-4.6866231-1.9732018
	C5.6279368,28.7858658,5,27.1479473,5,25.1873722C5,23.1089897,5.6443143,21.378912,6.9337325,19.9975319 M22.4444485,31.2652454
	c0.1578751,0.1450443,0.5063763,0.2174664,1.0459042,0.2174664c2.7363148,0,4.1046677-2.1940231,4.1046677-6.5816765
	c0-2.1736984-0.3224545-3.7648506-0.9669647-4.7728634c-0.6447086-1.0080128-1.6576538-1.5120182-3.039032-1.5120182
	c-0.5000648,0-0.8288307,0.0526886-0.9867001,0.1580677c-0.2105656,0.1320229-0.315546,0.4416485-0.315546,0.9286842v10.1195869
	C22.2867775,30.6919727,22.3390694,31.1730862,22.4444485,31.2652454 M18.3792477,18.1622715
	c0.78936,0,1.5522728,0.006712,2.2891407,0.0197353c1.0524158,0.0132217,1.868021,0.0197353,2.4468212,0.0197353
	c2.3943233,0,4.2297802,0.4933491,5.5057812,1.4798508c1.5917397,1.236927,2.3878136,2.9733181,2.3878136,5.2099686
	c0,1.4997845-0.3421898,2.7957134-1.0259705,3.8875942c-0.7104263,1.1445732-1.7435036,1.9801083-3.0982399,2.506216
	c-0.920784,0.3552132-2.2958508,0.5328197-4.1244011,0.5328197h-0.4341507h-1.1445675h-0.7104244h-1.2827091h-1.2432404
	c-0.19734,0-0.2960091-0.0524921-0.2960091-0.1578732c0-0.1314278,0.1841183-0.19063,0.5525494-0.1776066
	c0.5655785,0.0132217,0.9077644-0.0854473,1.0261688-0.2960091c0.0524921-0.091959,0.0789356-0.4935455,0.0789356-1.20397
	V19.9383297c0-0.5787964-0.0394669-0.9340096-0.1184044-1.0656338c-0.1316242-0.2370052-0.4671021-0.3485031-1.0064316-0.3354778
	c-0.2895012,0.0132236-0.4341488-0.0524921-0.4341488-0.1973381c0-0.1184063,0.0917625-0.1776085,0.2762756-0.1776085H18.3792477z
	 M34.1660423,19.9975319c-1.2894173,1.3813801-1.9339333,3.1114578-1.9339333,5.1898403
	c0,1.9605751,0.6281357,3.5984936,1.8845978,4.9139614c1.2562675,1.3156662,2.8186035,1.9732018,4.686821,1.9732018
	c1.420845,0,2.9139214-0.5302525,4.4796143-1.5911522v-0.8053417c0-0.8773727,0.0130234-1.3948002,0.0392685-1.5518837
	c0.0388832-0.2750912,0.3508759-0.4059258,0.9363785-0.3929024C44.7527351,27.7464771,45,27.6906319,45,27.5653191
	c0-0.1114979-0.0789337-0.1675415-0.2366104-0.1675415h-0.2565422H43.450882h-1.1939049h-1.2629738
	c-0.6973991-0.0130234-1.2761993-0.0197315-1.7365952-0.0197315c-0.2235832,0-0.3354721,0.0591984-0.3354721,0.1776047
	c0,0.118206,0.1397095,0.1776047,0.4191475,0.1776047h0.4990692c0.3591614,0,0.5823555,0.0625553,0.6689873,0.1872749
	c0.0862312,0.1253109,0.1296463,0.7007542,0.1296463,1.726923c0,1.3683529-0.5416946,2.0523338-1.6245003,2.0523338
	c-0.8588181,0-1.5457649-0.3419895-2.0608177-1.0263653c-0.8189583-1.0784626-1.2284431-2.9926586-1.2284431-5.7423935
	c0-4.4204102,1.2434387-6.6306171,3.7297249-6.6306171c0.8023834,0,1.4275551,0.2435169,1.8747292,0.7299595
	c0.604847,0.6711521,0.9866982,1.5856266,1.1445732,2.7432213c0.0657082,0.5263062,0.1904335,0.7893581,0.3749428,0.7893581
	c0.1314278,0,0.1973419-0.1249142,0.1973419-0.3751431c-0.0526886-0.9205894-0.0789337-1.6047688-0.0789337-2.0521374
	c0-0.5922146,0.0065079-0.9671631,0.019928-1.124836c0.0392685-0.4998627,0.0590057-0.7627201,0.0590057-0.78936
	c0-0.1314278-0.0463791-0.19734-0.1381416-0.19734c-0.0789337,0-0.1513596,0.0789375-0.2170753,0.2366123
	c-0.1448402,0.3157425-0.3224449,0.4738121-0.532814,0.4738121c-0.118206,0-0.3354797-0.0722256-0.6512222-0.2170734
	c-0.8552704-0.39468-1.6841011-0.59202-2.4864807-0.59202C37.0732536,17.9254646,35.4552612,18.6161537,34.1660423,19.9975319'
                    />
                </g>
            );

        case 'GP':
            return (
                <g {...props}>
                    <path
                        className='st0'
                        d='M25,11.3327045c7.8157921,0,14.1842117,6.1203003,14.1842117,13.6672955S32.8157921,38.6672935,25,38.6672935
	S10.8157883,32.5469971,10.8157883,25S17.1842098,11.3327045,25,11.3327045 M25,9.0169172
	C15.8815784,9.0169172,8.5,16.171051,8.5,25S15.8815784,40.9830818,25,40.9830818S41.5,33.828949,41.5,25
	S34.1184196,9.0169172,25,9.0169172L25,9.0169172z M18.2180481,37.4887085V19.1071301l10.896616,0.1240635h0.0206757h0.0206776l0,0
	c0.0206776,0,1.7781963,0.0206814,3.0394745,1.2612782c0.8890991,0.8890991,1.3439827,2.2124062,1.3439827,3.9492493
	c0,4.3627834-4.3627815,4.5488739-4.8383446,4.5488739h-4.548872l0.0620289-3.9078941h4.1146584
	c0.6409779,0,1.157896-0.5169182,1.157896-1.1578979s-0.5169182-1.1578979-1.157896-1.1578979h-6.4097767l-0.1447353,8.5601501
	h6.926693c0.0620308,0,1.7781963-0.0206738,3.4943619-0.847744c1.6748161-0.8063889,3.6597748-2.5018787,3.6597748-6.03759
	c0-2.3778152-0.6823311-4.2593937-2.0263138-5.6033821c-1.8815784-1.8815784-4.4248123-1.9436073-4.6729336-1.9436073
	l-13.2330847-0.1447353v20.7594032c0,0.6409798,0.5169172,1.1578941,1.157897,1.1578941
	C17.7218056,38.6672935,18.2387218,38.1296959,18.2180481,37.4887085z'
                    />
                </g>
            );

        case 'HB':
            return (
                <g {...props}>
                    <path
                        className='st0'
                        d='M26.7396965,10.5027103c0.4788017,0,0.8658752-0.3884268,0.8658752-0.8668013V7.1312799
	c0-0.4801679-0.3870621-0.8678756-0.8658752-0.8678756c-0.4782162,0-0.8660469,0.3877077-0.8660469,0.8678756v2.5046291
	C25.8736496,10.1142836,26.2614803,10.5027103,26.7396965,10.5027103z M23.2746582,12.8029423
	c0.4787655,0,0.8657532-0.3884277,0.8657532-0.8669977V9.431572c0-0.4801798-0.3869877-0.8679609-0.8657532-0.8679609
	c-0.478447,0-0.866045,0.3877811-0.866045,0.8679609v2.5043726C22.4086132,12.4145145,22.7962112,12.8029423,23.2746582,12.8029423z
	 M23.2753792,7.0090733c0.4784603,0,0.865509-0.3868175,0.865509-0.8662901v0.018868
	c0-0.4790459-0.3870487-0.8676562-0.865509-0.8676562c-0.4791679,0-0.866766,0.3886104-0.866766,0.8676562v-0.018868
	C22.4086132,6.6222558,22.7962112,7.0090733,23.2753792,7.0090733z M30.2049522,13.4662733
	c0.4785213,0,0.8654003-0.3867559,0.8654003-0.8662901v0.0189295c0-0.4785824-0.386879-0.8677168-0.8654003-0.8677168
	c-0.4790325,0-0.8668747,0.3891344-0.8668747,0.8677168v-0.0189295
	C29.3380775,13.0795174,29.7259064,13.4662733,30.2049522,13.4662733z M37.1342468,19.8888721
	c-0.4783859,0-0.8664589,0.3865871-0.8664589,0.8673038v0.3793888l-0.0011368,0.0567608
	c-0.0296249,0.4527397-0.4057465,0.810236-0.8647385,0.810236c-0.4783401,0-0.8666458-0.3870125-0.8666458-0.8669968v-0.9401379
	v-1.2213974c0-0.4802532-0.3878288-0.867485-0.8661652-0.867485c-0.4780464,0-0.8665848,0.3872318-0.8665848,0.867485v0.0476627
	c0,0.4804249-0.3872414,0.8671799-0.8650455,0.8671799c-0.4782162,0-0.8663998-0.386755-0.8663998-0.8671799v-0.8262367v-2.7961073
	c0-0.4804239-0.388073-0.867485-0.866766-0.867485c-0.4781666,0-0.8628731,0.3870611-0.8628731,0.867485v1.3813505
	c-0.0068684,0.4932671-0.4335918,0.8666553-0.8694744,0.8672161c-0.477972,0.0005989-0.866045-0.3867683-0.866045-0.8662777
	V16.194582v-3.3125229c0-0.4797649-0.3880749-0.8668261-0.8657417-0.8668261c-0.4791679,0-0.866766,0.3870611-0.866766,0.8668261
	l-0.0000114,2.3124762v1.0763826c0,0.4797783-0.3873539,0.8673019-0.8664608,0.8673019
	c-0.4777279,0-0.8658009-0.3875237-0.8658009-0.8673019V15.182766v-0.1279993c0-0.4809484-0.3877087-0.8674736-0.8661079-0.8674736
	c-0.4784603,0-0.8668861,0.3865252-0.8668861,0.8674736v1.1292295v1.4773102c0,0.4802303-0.3872452,0.8677063-0.865633,0.8677063
	c-0.4784584,0-0.866106-0.387476-0.866106-0.8677177v-0.4776669v-3.1434832c0-0.4790573-0.387001-0.8672409-0.8658142-0.8672409
	c-0.4784603,0-0.866106,0.3881836-0.866106,0.8672409v4.1433458v1.3972797c0,0.4795208-0.3878422,0.8676567-0.8658142,0.8676567
	c-0.4788761,0-0.8664742-0.3881359-0.8664742-0.8676567v-0.3969288v-1.0663319c0-0.480669-0.3880596-0.867424-0.8663979-0.867424
	c-0.4785223,0-0.8665953,0.386755-0.8665953,0.867424v2.0665989v2.0121918c0,0.4795094-0.3869886,0.8672409-0.8652658,0.8672409
	c-0.4779844,0-0.8672409-0.3877316-0.8672409-0.8672409v-1.0115967v-0.3284454c0-0.4795341-0.3866463-0.8672409-0.8658752-0.8672409
	c-0.4779844,0-0.8662901,0.3877068-0.8662901,0.8672409l-0.014513,1.3368225L12,37.2018738l12.9975185,7.5041313
	l12.9981899-7.5041313l0.0000114-9.4944515c0.0005951-0.0147572,0.0042686-0.0283318,0.0042686-0.0433216L38,20.756176
	C38,20.2754707,37.6127052,19.8888721,37.1342468,19.8888721z M31.1213455,34.6084595h-4.0827312v-3.1077862h-4.0823517v3.1077862
	h-4.0822544v-9.7317696h4.0822544v3.2715302h4.0823517v-3.2715302h4.0827312V34.6084595z'
                    />
                </g>
            );

        case 'IBC':
            return (
                <g {...props}>
                    <g>
                        <g>
                            <path
                                className='st1'
                                d='M19.271822,28.8533401c0-2.4004555-3.0835056-2.4929543-3.0835056-2.4929543l-5.96877-0.0047722
			l-3.995852,11.7955666c0,0,3.2840304,0,5.4993548,0s5.0076866-0.8341675,5.8038731-3.1479073
			c0.796051-2.3136673-1.2564602-3.0400772-1.2564602-3.0400772S19.271822,31.2537937,19.271822,28.8533401z M12.0071316,35.1910286
			c-0.4009829,0.0617752-0.5912905,0.0691719-0.5912905,0.0691719l0.6351871-2.0522423c0,0,1.4959736-0.1143456,1.3536634,0.9055557
			C13.2287703,34.9784698,12.4080477,35.1293182,12.0071316,35.1910286z M13.7696438,30.7545319
			c-0.3681784,0.1006317-0.9052877,0.1006317-0.9052877,0.1006317l0.6804276-1.8841171c0,0,1.3552771-0.2085247,1.2824078,0.7440205
			C14.7387934,30.4574738,14.1378899,30.6539001,13.7696438,30.7545319z'
                            />
                            <polygon
                                className='st1'
                                points='17.2829475,38.0473518 24.7077389,38.0473518 25.6942482,35.0136147 22.5270309,35.0136147 
			23.0202866,33.4559669 25.9538574,33.4559669 26.9144077,30.6781597 24.0327568,30.6781597 24.5260124,29.2762756 
			27.6932316,29.2762756 28.6797409,26.4205875 21.2030296,26.4205875 		'
                            />
                            <polygon
                                className='st1'
                                points='28.5499382,29.3801193 30.9642925,29.3801193 28.0566826,38.1511688 32.2104111,38.1511688 
			35.2361183,29.3880596 37.7277603,29.3880596 38.8044548,26.4205875 29.5104866,26.4205875 		'
                            />
                        </g>
                        <g>
                            <polygon
                                className='st0'
                                points='5,23.4756336 10.0394506,11.8488579 14.9689493,11.8488579 14.2652588,17.9536171 
			17.5589199,11.8488579 22.5140743,11.8488579 19.5820313,23.4756336 15.9238997,23.4756336 18.2332916,15.3929663 
			13.8352251,23.4756336 10.8152199,23.4756336 11.8512087,15.5102472 8.8018837,23.4756336 		'
                            />
                            <path
                                className='st0'
                                d='M27.0697098,11.8488207l-7.2170277,11.626771h3.901001l1.0055828-1.8914452h2.4922142L26.93997,23.4755917
			h3.8681297l0.7269459-11.626771H27.0697098z M27.8312759,19.4640102l-2.1241016-0.0227222l2.4397125-4.2229967
			L27.8312759,19.4640102z'
                            />
                            <polygon
                                className='st0'
                                points='35.9310265,17.9409943 31.0157795,23.4756336 35.2733536,23.4756336 36.9002304,21.7831917 
			37.3848305,23.4756336 41.5731735,23.4756336 40.1193695,17.8371506 45,11.8488579 40.8808861,11.8488579 39.0463219,13.8218794 
			38.4578781,11.8488579 34.2003059,11.8488579 		'
                            />
                        </g>
                    </g>
                </g>
            );

        case 'IDN':
            return (
                <g {...props}>
                    <path
                        className='st0'
                        d='M7.4741979,36.2342186v-6.6881218h4.5579801c1.1948204,0,1.7920065,0.53158,1.7920065,1.5956421v0.9553185
	c0,0.5414658-0.1527786,0.9571152-0.4592352,1.2469444s-0.7203083,0.4345207-1.2417784,0.4345207H8.3931179v2.4556961H7.4741979z
	 M12.1204748,32.8137703c0.2493887,0,0.4349709-0.044487,0.5569687-0.1339073
	c0.1213245-0.0889702,0.1977139-0.2579269,0.2282696-0.5064163v-0.9746418c0-0.057066-0.0035944-0.0984077-0.0089865-0.1240196
	c-0.0247145-0.1972656-0.0853767-0.3374615-0.1828852-0.4205914c-0.0853767-0.0633583-0.2282696-0.1082935-0.4291296-0.1339073
	c-0.0121326,0-0.036622-0.0026951-0.073019-0.0094357H8.3931179v2.3029194H12.1204748z M29.356699,34.3729401v-3.211153
	c0-0.6099625,0.1694088-1.0482731,0.5082283-1.3153839c0.2962418-0.2348423,0.7439651-0.3522644,1.3431721-0.3522644h4.6800652
	v0.9622269h-5.0341225c-0.1694088,0-0.308342,0.076189-0.4172478,0.2290154
	c-0.1089058,0.1523781-0.1635838,0.3208904-0.1635838,0.5050907v1.133873h4.6547489v0.962677h-4.6547489v1.3117981
	c0,0.0640907,0.0152378,0.1277313,0.0452671,0.1913719c0.0909786,0.1980896,0.2272224,0.3226814,0.4082832,0.3737755
	c0.0909805,0.0255432,0.2783165,0.0380936,0.5629044,0.0380936h4.5711594v0.9626732h-4.816309
	C29.9191551,36.1647339,29.356699,35.5677681,29.356699,34.3729401z M27.8703804,36.1647339h1.4387569l-3.5405025-3.2647438
	l-0.3225765-0.2974739l3.7917347-3.1083775h-1.3079586l-4.1735802,3.4214115v-3.4214115h-0.8680115v6.6705952h0.8680115v-2.1769447
	l0.9281578-0.7608795L27.8703804,36.1647339z M42.6859283,33.0027084c0.0053635-0.0043411,0.0120316-0.0071526,0.0173492-0.0115662
	c0.3217163-0.266861,0.4821243-0.6495667,0.4821243-1.1481323v-0.8796463c0-0.9797611-0.6269493-1.4692249-1.8813553-1.4692249
	h-4.7852211v6.6705952h0.9647331V33.391243h3.9160309c0.0161629,0,0.0300064-0.0027199,0.0459595-0.0029526h0.278965
	c0.3139153,0,0.5707436,0.2568321,0.5707436,0.5707474v2.2056961h0.9614868v-2.1603165
	C43.2567444,33.5796318,43.0260162,33.2091675,42.6859283,33.0027084z M37.4835587,30.3824692h4.0089493
	c0.0382271,0.0062008,0.0639343,0.0086861,0.0766602,0.0086861c0.2108727,0.0235729,0.3608971,0.0649567,0.4505424,0.1232948
	c0.1023712,0.0765457,0.16605,0.2056217,0.1919899,0.3872547c0.0056648,0.0235958,0.0094299,0.0616589,0.0094299,0.1142139
	v0.8974113c-0.032074,0.2288017-0.1122665,0.3843765-0.2396469,0.4663086
	c-0.1280785,0.0823288-0.3229027,0.1232948-0.5847282,0.1232948h-3.9131966V30.3824692z M10.0887022,13.7657804H7.2567444
	v13.8634214h2.8319578V13.7657804z M39.9148636,13.7657967v9.1552238l-9.3109322-9.1552238h-2.4120388v13.86339h2.8319416
	v-9.2496243l9.5248642,9.2496243h2.1981544v-13.86339H39.9148636z M23.2935276,13.7657967h-8.331192h-2.8319893h-0.7573681
	v2.6343632h0.7573681v8.5946617v2.6343651h2.8319893h8.331192c1.8550491,0,3.3588352-1.503788,3.3588352-3.3588352V17.12463
	C26.6523628,15.2695827,25.1485767,13.7657967,23.2935276,13.7657967z M23.8204212,23.9575348
	c0,0.5728626-0.464426,1.0372868-1.0372887,1.0372868h-7.820797v-8.5946617h7.820797
	c0.5728626,0,1.0372887,0.464426,1.0372887,1.0372868V23.9575348z M22.2799911,32.2765274
	c0-1.1476021-0.694458-2.119072-1.6540623-2.4542828c-0.1601276-1.1789532-1.1564484-2.0879059-2.3649406-2.0879059
	c-1.2153435,0-2.2164955,0.9191952-2.3679609,2.1079006c-0.9329891,0.3514671-1.6034813,1.3071938-1.6034813,2.434288
	c0,1.2123451,0.7755098,2.2265739,1.8194628,2.5031662l-1.0227966,1.3850403h2.9250736H18.54636h2.9250736l-1.0207748-1.3822975
	C21.4996281,34.5096741,22.2799911,33.4927063,22.2799911,32.2765274z M19.5931473,34.083889
	c-0.2409,0-0.4694443-0.0484924-0.6808281-0.1301231l-0.0580349,0.1301231l0.9067459,1.2960701h-3.0201969l0.939352-1.2960701
	l-0.0514412-0.1175575c-0.2002811,0.0721321-0.4138699,0.1175575-0.6396275,0.1175575
	c-1.0301104,0-1.8651657-0.8198204-1.8651657-1.8311424c0-0.9943714,0.8085108-1.799305,1.8147717-1.8261528
	c-0.0398998-0.1307259-0.0676975-0.2667236-0.0676975-0.4105015c0-0.7807808,0.6329384-1.413744,1.413744-1.413744
	c0.7808037,0,1.413744,0.6329632,1.413744,1.413744c0,0.1433372-0.0276604,0.2788944-0.0673256,0.4092712
	c1.0121117,0.0203915,1.8271255,0.8288555,1.8271255,1.827383C21.458313,33.2640686,20.6232586,34.083889,19.5931473,34.083889z'
                    />
                </g>
            );

        case 'IME':
            return (
                <g {...props}>
                    <path
                        className='st0'
                        d='M33.3695335,8.5H16.6304665C12.0869532,8.5,8.5,12.0869532,8.5,16.6304665v16.7390671
	C8.5,37.9130478,12.0869532,41.5,16.6304665,41.5h16.7390671C37.9130478,41.5,41.5,37.9130478,41.5,33.3695335V16.6304665
	C41.5,12.0869532,37.6739082,8.5,33.3695335,8.5z M14.9566298,29.5434418c0,0.7174187-0.4782791,1.1956978-1.1956987,1.1956978
	c-0.7174187,0-1.1956978-0.4782791-1.1956978-1.1956978v-8.847744c0-0.7174187,0.4782791-1.1956978,1.1956978-1.1956978
	c0.7174196,0,1.1956987,0.4782791,1.1956987,1.1956978V29.5434418z M29.3044434,30.5
	c-0.4782791,0.4782791-1.1956978,0.4782791-1.6739769,0l-6.9347687-6.9347668v5.9782085
	c0,0.7174187-0.4782791,1.1956978-1.1956978,1.1956978c-0.7172775,0-1.1955566-0.4782791-1.1955566-1.1956978v-8.847744
	C18.3044434,19.9782791,18.7827225,19.5,19.5,19.5c0.4782791,0,0.7174187,0.2391396,0.9565582,0.4782791l8.8478851,8.8477421
	C29.7825813,29.3043022,29.7825813,30.0217209,29.3044434,30.5z M37.1956291,30.5
	c-0.4782829,0.4782791-1.1955566,0.4782791-1.6738358,0l-8.847887-8.8478851c-0.4782791-0.4781361-0.4782791-1.1955566,0-1.6738358
	s1.1956997-0.4782791,1.6739788,0l8.847744,8.8477421C37.6739082,29.3043022,37.6739082,30.0217209,37.1956291,30.5z'
                    />
                </g>
            );

        case 'JKR':
            return (
                <g {...props}>
                    <path
                        className='st0'
                        d='M10.5543404,26.9733334c0.1952734,1.194622,0.2265797,1.3981514,0.2265797,1.3981514H9.7809763H8.7810326
	c0,0,0.1456833-0.2035294,1.0122948-1.3981514c-2.5538611,0.7433205-1.9145513-1.4689388-1.2250605-2.1591625
	c0.6894846-0.6902256,2.8761377-2.3626938,2.8761377-2.3626938s1.2467785,1.6724682,1.5483789,2.3626938
	C13.2943897,25.5043945,12.6904802,27.7166538,10.5543404,26.9733334z M35.7548637,23.6405754
	c-0.874218-1.1762981-2.3278923,0.4745235-2.3278923,0.4745235s-0.5259705-1.6508217-2.0612297-0.4745235
	c-1.6125813,1.2355366-0.4108028,2.7847195-0.1217575,3.1417313c0.2890377,0.3570175,1.0466232,1.1880684,1.0044174,1.5272369
	c0.1483955-0.3391685,1.3729973-1.1702194,1.8626709-1.5272369C34.6007423,26.4252949,36.6731148,24.876112,35.7548637,23.6405754z
	 M17.9106369,15.819128l-3.8475056,11.778965c-0.1746235,0.5345974-0.9027576,2.7637253-2.3360882,2.7192783H7.5842724L6,35.1675491
	h4.9806576c3.7165976,0,7.1013565-0.9291496,9.5446434-8.8810501l3.4190826-10.467371H17.9106369z M43.2486877,25.9291496
	c-3.0263748,9.2383995-10.0879097,9.3445854-12.7426205,9.3445854S20.6836319,33.8136444,22.8339481,25
	s9.096817-10.273737,13.1231251-10.273737C39.4081993,14.726263,46.2750587,16.6907501,43.2486877,25.9291496z
	 M34.5412292,19.3189163c-1.9910355,0-4.3404541,1.0751572-5.4687061,5.3757915
	c-1.1282539,4.3006344,0.8229599,5.8536415,2.8936348,5.8536415s3.8360615-0.9291496,5.0705013-4.6059265
	C38.2843742,22.0665436,37.1959419,19.3189163,34.5412292,19.3189163z'
                    />
                </g>
            );

        case 'KM':
        case 'KM2':
            return (
                <g {...props}>
                    <path
                        className='st0'
                        d='M34.5526314,25.8684273l-9.3789501,6.9473686l-9.3789368-6.9473686H8.5v15.2842026h7.2947445V31.0789547
	l7.6420956,5.9052525h3.1263199l7.9894714-5.9052525v10.0736752H41.5V25.8684273H34.5526314z M8.5,8.8473701v15.6315784h33
	V8.8473701l-7.9894714,7.9894714l-8.3368473-7.9894714l-7.9894714,7.9894714L8.5,8.8473701z'
                    />
                </g>
            );

        case 'MEGA':
            return (
                <g {...props}>
                    <path
                        className='st0'
                        d='M34.9374428,16.3367691h-2.4133949h-1.1258755l-3.2587547,3.768446l-1.5005302-3.768446h-0.5486736H23.562706
	h-1.0802288L7.5,33.6632309h5.1016111l10.8422308-12.5386448l1.2028961,3.0200787l-8.2310429,9.5185661h5.1016102
	l10.8736095-12.5747814l5.0084267,12.5747814H42.5l-6.8997765-17.3264618H34.9374428z M34.9374428,16.3367691h-2.4133949h-1.1258755
	l-3.2587547,3.768446l-1.5005302-3.768446h-0.5486736H23.562706h-1.0802288L7.5,33.6632309h5.1016111l10.8422308-12.5386448
	l1.2028961,3.0200787l-8.2310429,9.5185661h5.1016102l10.8736095-12.5747814l5.0084267,12.5747814H42.5l-6.8997765-17.3264618
	H34.9374428z'
                    />
                </g>
            );

        case 'MGP':
            return (
                <g {...props}>
                    <path
                        className='st0'
                        d='M38.8028641,15.942379c4.9838028,7.634058,2.8669357,17.8516846-4.7504501,22.8354912
	c-7.6340504,4.9838066-17.8516827,2.8669395-22.8354855-4.7504463c0.7334023-1.1167679,1.4501362-2.2668819,2.1502028-3.4169884
	c1.1167717-1.7835007,2.1668711-3.5670013,3.2003012-5.3838406l0.7500706-1.3667946
	c0.2666931-0.4667091,0.5000477-0.9167519,0.7500706-1.3667965c0.3000298-0.5500546,0.6000576-1.13344,0.9000854-1.700161
	c0,0.7834091,0,1.6001492,0,2.3835602c0.0166683,1.700161,0.0833416,3.3503132,0.2666912,5.0504742
	c0.1000099,0.8500843,0.2166882,1.7168331,0.4000378,2.5669098c0.1666832,0.933424,0.4833794,1.8335094,0.9000854,2.683588
	c0.2666912,0.5333824,0.666729,1.016758,1.1167717,1.4167976c0.3000298,0.2333527,0.6333942,0.4500427,1.0000954,0.5667191
	c0.3667011,0.1166801,0.7834072,0.1833534,1.183445,0.1500168c0.1833496-0.0166664,0.3667011-0.0500031,0.5500507-0.0833397
	c0.1000099,0,0.1666832-0.0333366,0.2500229-0.0666771l0.1166782-0.0333366h0.0666733l0.0833416-0.0333366h0.0333366
	l0.1500149-0.0666733l0.2666912-0.1500168c0.1500149-0.0666733,0.2833595-0.1666832,0.4000378-0.2500229
	c0.3833694-0.3167038,0.7834072-0.6500626,1.1167717-1.0501022c0.5667171-0.6667328,1.1167698-1.4001312,1.6001492-2.1502056
	c0.4833775-0.7167339,0.9167519-1.4501343,1.3334579-2.2335434c0.8500786-1.4834785,1.5668125-2.9669514,2.3002148-4.4504242
	c0.1833496-0.3167,0.3333626-0.6833973,0.516716-1.0501003c0.2666931,0.6000576,0.516716,1.2167797,0.8167419,1.8168392
	c0.466713,0.8667488,0.9500923,1.7501621,1.5168114,2.5669098c0.5833893,0.8167458,1.2501183,1.5668125,2.0001907,2.2335434
	c0.1166801,0.1166782,0.3333664,0.1166782,0.4833794,0c0.150013-0.1333485,0.1833534-0.366703,0.0666733-0.5500546l0,0
	c-0.5667191-0.7167339-1.0167618-1.5001431-1.383461-2.3502216c-0.3500366-0.8500843-0.6500664-1.7168331-0.9000893-2.6002464
	c-0.2333527-0.8667507-0.4167061-1.7835026-0.5667191-2.683588c-0.1000099-0.4667091-0.150013-0.9167519-0.2166862-1.3667965
	l-0.0833397-0.7000618c-0.0333366-0.2500267-0.0666733-0.4833813-0.0666733-0.6667328l0,0
	c-0.0833435-0.5167103-0.4167061-0.9334164-0.9000893-1.1167679c-0.7500687-0.300024-1.6334858,0.0666733-1.9335136,0.8167458
	c-0.3000298,0.7167339-0.6333923,1.4668064-0.9667587,2.2002068c-0.3333645,0.7500725-0.7000656,1.4668064-1.033432,2.2002068
	c-0.6833973,1.4501419-1.4168015,2.883606-2.2002068,4.2670727c-0.4000378,0.7000694-0.8000755,1.4001331-1.2167816,2.0335274
	c-0.4000378,0.6333942-0.8500805,1.2167797-1.3334599,1.7835007c-0.2166862,0.2333565-0.4167061,0.4500427-0.666729,0.6333961
	c-0.0333366,0.0166626-0.0833397,0.0500031-0.1166782,0.0833397l0,0l-0.0833416,0.0333366l0,0h-0.0333366l-0.0666733,0.0333366
	c-0.0333347,0,0,0-0.0666733,0c-0.0500031,0,0,0.0333366-0.0833397-0.0333366
	c-0.1500149-0.150013-0.2833614-0.3333664-0.366703-0.516716c-0.3000278-0.6000595-0.516716-1.2667847-0.6333923-1.9335175
	c-0.1500149-0.7500725-0.2833614-1.5168095-0.366703-2.2668819c-0.1666813-1.5501461-0.2500229-3.1502953-0.2500229-4.7504463
	c0-1.6001549,0.0166702-3.2336426,0.0833416-4.8337917c0.0666733-0.8167458,0.0833416-1.6334858,0.1500149-2.4168959
	c0.0666733-0.7834091,0.2166882-1.6001492,0.2166882-2.3835583l0,0c0.0333347-0.6333952-0.3000298-1.2167816-0.8500805-1.5168104
	c-0.7667408-0.3333654-1.6334877-0.0333366-2.0001888,0.7000637l-0.0666733,0.0833397l-0.666729,1.3667955
	c-0.2166882,0.4333706-0.4500427,0.8834143-0.7000656,1.3334579c-0.2500229,0.4500427-0.466711,0.8834143-0.7334023,1.333457
	c-0.2666912,0.4500446-0.5000477,0.8834152-0.7500706,1.3334579c-0.5000477,0.8834209-1.0000954,1.7501717-1.5501471,2.633585
	c-0.2333555,0.4167061-0.516716,0.8834152-0.7834082,1.3001213l-0.8167429,1.3001213
	c-1.1167717,1.7334976-2.2335434,3.4169865-3.3836527,5.1171474c-0.2833595,0.4500446-0.5833883,0.8834152-0.8834171,1.3001213
	C8.7500238,28.2601891,8.5,26.6600399,8.5,25.0265522c0-9.1175289,7.3840313-16.5015602,16.48489-16.5015602
	C30.5354118,8.524992,35.7192345,11.3419228,38.8028641,15.942379z'
                    />
                </g>
            );

        case 'NS':
            return (
                <g {...props}>
                    <g>
                        <path
                            className='st0'
                            d='M12.1209354,26.4134521l-2.3798046-6.1895428l-1.5587482,6.1895428H6.4863038l0.1471243-0.2681141
		c0.0886803-0.1608295,0.1667514-0.3290043,0.2317238-0.499836c0.0653906-0.171648,1.6633945-6.5188389,1.6633945-6.5188389
		c0.0809164-0.3281879,0.1221809-0.6782227,0.1221809-1.0391998v-0.1807518h3.3014269l2.3262777,6.0541573l1.5305414-6.0541573
		h1.7144718l-0.1630688,0.2735329c-0.1050434,0.1749115-0.1879902,0.3431911-0.2476692,0.5003376
		c-0.0608921,0.160099-1.9584579,7.7328701-1.9584579,7.7328701H12.1209354z'
                        />
                        <path
                            className='st0'
                            d='M16.3664379,26.4134521l0.1471233-0.2681141c0.0899143-0.162859,0.1675682-0.3310337,0.2313251-0.4986019
		c0.0657883-0.1724625,1.6637917-6.5088367,1.6637917-6.5088367c0.0804996-0.3303223,0.1217842-0.6844578,0.1217842-1.050436
		v-0.1807518h7.0707798l-0.4401588,1.6937351c0,0-0.3339005-0.2870064-1.0834351-0.2870064h-2.9434204l-0.5218925,2.0987396
		H23.81077c0.3531094,0,0.7985821-0.1441746,0.7985821-0.1441746l-0.43363,1.715477c0,0-0.2779045-0.1648903-0.760582-0.1648903
		h-3.1608105l-0.546833,2.1883411h3.439949c0.5954647,0,0.9203873-0.3373737,0.9203873-0.3373737l-0.431181,1.7438927H16.3664379z'
                        />
                        <path
                            className='st0'
                            d='M37.0869179,26.4134521l0.1471443-0.2681141c0.0886803-0.1610165,0.0886803-0.1610165,0.2317238-0.4990196
		c0.0719223-0.1695976,1.62006-6.3328781,1.62006-6.3328781h-2.2850151l0.3572121-1.4067287h7.3282623l-0.3559799,1.4067287
		h-2.2760162c0,0-1.4757767,5.9319553-1.5607758,6.3339043c-0.0143127,0.06744-0.0600967,0.3185825-0.0216789,0.5500984
		l0.0429192,0.2160091H37.0869179z'
                        />
                        <g>
                            <polygon className='st0' points='34.7961731,20.0598412 39.8637123,14.0474968 34.9427567,14.0474968 32.789772,16.6056404 		' />
                        </g>
                        <path
                            className='st0'
                            d='M9.3794842,36.8881302l0.4303446-1.6915779c0,0,0.2803535,0.2850609,0.6543255,0.2850609h4.06812
		c0.230092,0,0.3531103-0.1032028,0.4103193-0.3455544l0.2680912-1.0730171
		c0.0273914-0.1003342,0.0327063-0.1559105,0.0327063-0.1857529c0-0.0202332,0-0.0740738-0.2014875-0.0740738h-3.0467892
		c-1.2130041,0-1.4680386-0.6720886-1.4680386-1.2358932c0-0.1903343,0.0273914-0.4051094,0.0809383-0.6381741l0.4340267-1.757576
		c0.1454926-0.5968876,0.4115543-1.0500393,0.7912178-1.3448944c0.3792667-0.2955875,0.8627605-0.4452858,1.436964-0.4452858
		h6.0429201l-0.4319763,1.6966858c0,0-0.2742424-0.289957-0.7307434-0.289957h-4.000699
		c-0.2219105,0-0.3404303,0.1020718-0.3980579,0.3419781l-0.2043543,0.7881622
		c-0.0273695,0.0982838-0.0326843,0.1529408-0.0326843,0.1822777c0,0.0196056,0,0.0721283,0.2018843,0.0721283h3.0463934
		c1.2130032,0,1.4680157,0.6740379,1.4680157,1.2391605c0,0.1909599-0.0273685,0.4062386-0.0813141,0.639698l-0.5104656,2.0424309
		c-0.2930355,1.1901131-1.0384903,1.7941742-2.2147064,1.7941742H9.3794842z'
                        />
                        <path
                            className='st0'
                            d='M17.7561016,36.8881302l0.1471443-0.2676926c0.090313-0.1632767,0.1753101-0.3286057,0.2317238-0.4990196
		c0.1238346-0.3743706,1.6637726-6.5088387,1.6637726-6.5088387c0.0809364-0.3316402,0.1218033-0.6850643,0.1218033-1.050436
		v-0.1807518h6.3755856c1.4872475,0,1.7994709,0.692955,1.7994709,1.27421c0,0.1742001-0.0273705,0.3805847-0.081316,0.612936
		l-0.4851246,1.9360676c-0.3020306,1.1959496-1.1627216,1.8022308-2.5584221,1.8022308h-3.5049438
		c0,0-0.5022621,2.2719154-0.5243378,2.4394836c-0.034338,0.2627983,0.0441303,0.4418106,0.0441303,0.4418106H17.7561016z
		 M24.2567577,32.6006317c0.2734261,0,0.3821316-0.0593643,0.4229984-0.0948105
		c0.034338-0.0306549,0.1234379-0.1394653,0.2039356-0.5003395l0.4213676-1.6928139
		c0.0506802-0.231514,0.0584431-0.3267441,0.0584431-0.3627148c0-0.0422878-0.0057125-0.0970497-0.034338-0.115757
		c-0.0159225-0.0108395-0.0837841-0.0460758-0.2819843-0.0460758h-2.5228691l-0.7004871,2.8125114H24.2567577z'
                        />
                        <path
                            className='st0'
                            d='M26.5113144,36.8881302l0.147543-0.2681122c0.0886784-0.1614342,0.16675-0.3294029,0.2317219-0.4994164
		c0.0649948-0.1696205,1.8497524-6.9195747,1.8607826-7.5584583v-0.1807518h3.1240425c0,0-0.1287308,0.1698284-0.205986,0.428524
		c-0.0461807,0.1555996-1.9216709,7.1210423-1.9461956,7.276556c-0.0236874,0.151413-0.0355511,0.2959023-0.0355511,0.4301567
		c0,0.0960236,0.0053158,0.1377296,0.008997,0.1540718l0.0445499,0.2174301H26.5113144z'
                        />
                        <path
                            className='st0'
                            d='M36.4205093,36.8881302l-2.379406-6.1895409l-1.559166,6.1895409h-1.6960583l0.147522-0.2681122
		c0.0887012-0.1608276,0.1663532-0.3290024,0.2317467-0.499836c0.06497-0.1716461,1.6633701-6.5188389,1.6633701-6.5188389
		c0.0809174-0.3281879,0.1217842-0.6782227,0.1217842-1.0391998v-0.1807518h3.3014069l2.3266945,6.0541592l1.5305405-6.0541592
		h1.7140541l-0.1630478,0.2735329c-0.1046257,0.1749115-0.1875916,0.3431911-0.2472687,0.5003376
		c-0.060894,0.160099-1.9588585,7.7328682-1.9588585,7.7328682H36.4205093z'
                        />
                        <polygon className='st0' points='38.0353584,28.3814011 34.582756,22.4539394 31.6681995,25.9079647 33.1128197,28.3814011 	' />
                        <g>
                            <path
                                className='st1'
                                d='M27.4969921,14.1854105c0,0-1.0576363,0.5015306-1.8931427,1.4326897l0.3942413,0.6785326
			c1.5799255-1.3689947,3.6215439-1.6877956,3.6215439-1.6877956s-2.0196953,0.9543962-3.0337734,2.6993217l0.4704494,0.809679
			c1.058876-1.2206306,2.4442158-1.9484482,3.5465393-2.365345l-1.5343227-2.640624h-4.9209347l1.1243229,1.9349499
			C26.3770123,14.364893,27.4969921,14.1854105,27.4969921,14.1854105z'
                            />
                            <path
                                className='st1'
                                d='M30.8636475,16.2013111c-1.0453777,0.7704258-2.3119736,1.9924793-2.8246307,3.6078243l0.8329906,1.433567
			l-6.0822468,7.1386776h4.9209328l6.0822487-7.1386776h0.0000343L30.8636475,16.2013111z'
                            />
                        </g>
                    </g>
                </g>
            );

        case 'PG':
            return (
                <g {...props}>
                    <g>
                        <path
                            className='st0'
                            d='M36.8645287,20.0122681c-1.2312698,0.0139313-2.4628792,0.0137253-3.694149-0.0004787
		c-0.2939911-0.0034142-0.4204636,0.117733-0.4955826,0.3812637c-0.156723,0.5498028-0.3347549,1.0934601-0.5032959,1.6398487
		c-0.7911396,2.5657921-1.5830307,5.1313801-2.3722591,7.6977863c-0.1407452,0.4578171-0.120327,0.4824696,0.3684254,0.4852695
		c0.584631,0.0034142,1.1692619,0.0008183,1.753891,0.0005455c0.5970612-0.0002728,1.1942558-0.0088768,1.7910423,0.0035515
		c0.2758255,0.0057373,0.4292717-0.0864544,0.5152473-0.3637123c0.9760017-3.1461887,1.9596519-6.2899895,2.948082-9.4322853
		C37.2749519,20.1095123,37.1983299,20.0085125,36.8645287,20.0122681z'
                        />
                        <path
                            className='st0'
                            d='M39.1969719,13.228611H17.2887154c-3.5161161,0-6.6139555,2.3114805-7.6150875,5.6820698L6.1991682,30.6079426
		c-0.9134469,3.0753727,1.3907266,6.1634464,4.598855,6.1634464h21.9082565c3.5161171,0,6.6139565-2.3114128,7.6150894-5.682003
		l3.4744568-11.6972599C44.7092743,16.3167534,42.4051018,13.228611,39.1969719,13.228611z M17.6959286,35.5080223
		c-0.2876377,0.0768967-0.583128,0.1221046-0.8844242,0.12183c-2.0652256-0.0019112-4.1305895,0.010994-6.1957474-0.0047798
		c-1.7041092-0.0129051-3.1015959-1.1367569-3.3944235-2.7467613c-0.1672425-0.9197292-0.0391302-1.8359089,0.2388783-2.725317
		c1.1052742-3.5363979,2.1021719-7.1121998,3.3602104-10.5935574c0.9947119-2.7528381,3.0736656-4.4688988,6.0488558-4.8558302
		c1.7155151-0.2230349,3.4539051-0.0417929,5.1815739-0.0777817c0.0870705-0.0018444,0.1742077-0.0002737,0.2612782-0.0002737
		c1.6173801,0.0001364,3.2347603,0.0060101,4.8520737-0.0048485c0.3271084-0.0021849,0.4167061,0.0708847,0.3034134,0.4064627
		c-0.5133362,1.5194521-1.0130157,3.0437536-1.4986248,4.5723581c-0.0997047,0.3139286-0.2517185,0.4258556-0.5835381,0.42449
		c-2.9361305-0.0116768-5.8723278-0.0000687-8.8083878-0.0148182c-0.3770294-0.0018444-0.5377159,0.1199856-0.6493702,0.4809666
		c-0.9548998,3.0883465-1.931242,6.170002-2.9004135,9.253912c-0.1582966,0.5036392-0.140132,0.5317059,0.379487,0.5327301
		c1.3063202,0.0025272,2.6127768-0.0077858,3.9189596,0.0062828c0.3535366,0.0037556,0.545433-0.1087189,0.6344814-0.4668312
		c0.1258602-0.5059605,0.3037548-0.9988098,0.4550858-1.4986248c0.1266098-0.4180031,0.102026-0.4479809-0.3247871-0.4581566
		c-0.5963097-0.0142727-1.1930962,0.0186424-1.7891979-0.0479393c-0.2456398-0.0274525-0.2958336-0.1255169-0.2273388-0.3407688
		c0.5015907-1.5757236,1.0018826-3.1518574,1.4999218-4.7286739c0.0603008-0.1908016,0.1704521-0.283062,0.3794193-0.2827873
		c2.3762875,0.0034828,4.7525768,0.0048485,7.1288643-0.0017071c0.3447952-0.0009575,0.2805347,0.1758461,0.2126541,0.3851547
		c-0.5100574,1.5729923-1.0182037,3.1465988-1.5264187,4.7201385c-0.32868,1.0175209-0.6096249,2.0534115-0.9940281,3.0493526
		C21.8446159,33.0211067,20.2910175,34.8142662,17.6959286,35.5080223z M43.1496658,18.8544102
		c-0.282856,1.5339317-0.8604546,2.9830456-1.3157425,4.4667816c-0.7290649,2.376152-1.4866066,4.7436981-2.2449646,7.110836
		c-0.3420677,1.0678501-1.042038,1.9168987-1.777317,2.7300282c-1.0113068,1.1183853-2.1953888,1.9792519-3.6944885,2.33395
		c-0.3765526,0.089119-0.7563133,0.1371269-1.1441994,0.1352119c-0.9078484-0.0046425-1.8156967-0.001503-2.7236118-0.001503
		c-0.9699917,0.0001373-1.940052,0.0042343-2.9100437-0.0006828c-2.0609226-0.0105133-3.9266758-2.2097282-3.6236038-4.2406044
		c0.1813774-1.2152195,0.6403561-2.3472633,1.0016785-3.5077152c0.8275375-2.6580524,1.6857376-5.306612,2.5239296-7.9613876
		c0.8159294-2.5844345,2.5690022-4.2552185,5.064045-5.1940022c0.7477074-0.2813549,1.5250549-0.4185495,2.3358612-0.4054375
		c1.5168571,0.0245848,3.0344009,0.0110626,4.5515976,0.0053263c0.6842003-0.0025949,1.3334312,0.1494188,1.9509773,0.427701
		C42.7685394,15.4851866,43.496376,16.9738407,43.1496658,18.8544102z'
                        />
                    </g>
                </g>
            );

        case 'PP':
        case 'PPL':
            return (
                <g {...props}>
                    <g>
                        <path
                            className='st0'
                            d='M18.3288059,15.1522179c-0.512846-2.8683929-0.9323254-5.735302-0.7434559-8.8074989
		c1.9148064,2.4338055,3.175766,5.0740108,4.3882427,7.7657137c0.0003567-2.2157106,0.7823391-4.0440273,2.6264267-5.5445786
		c-0.4011269,0.9973269-0.6926498,1.8742523-0.8219242,2.7969532c-0.2131042,1.5209999-0.1254578,3.0256224,0.249773,4.5107126
		c0.0805225,0.3187103,0.0845261,0.4377918-0.3099575,0.4623814c-1.9496231,0.121521-3.7838764,0.6652966-5.4784012,1.6411514
		c-0.3412361,0.1965199-0.4715576,0.2030869-0.6294441-0.2099323c-0.848774-2.220438-2.1950626-4.0651417-4.2074451-5.3828478
		c-0.0818644-0.053607-0.1932163-0.0832758-0.2080221-0.223032C15.3855915,12.323143,17.0412331,13.3869085,18.3288059,15.1522179z'
                        />
                        <path
                            className='st0'
                            d='M25.1067619,17.6597824C17.7938976,17.6579933,12.0001736,23.3946438,12,30.6373901
		c-0.0001154,7.1996384,5.76614,13.0144272,12.9092216,13.0178909c7.2154503,0.0034599,13.0867348-5.7580605,13.0907745-12.8460903
		C38.0041542,23.5151405,32.2645607,17.6615143,25.1067619,17.6597824z M24.9311523,42.2761993
		c-6.4190044-0.0201378-11.6012421-5.2626228-11.5623455-11.61763c0.039588-6.4682293,5.1542473-11.6179771,11.6629314-11.6202278
		c6.4286423-0.0021915,11.5975494,5.2545471,11.5772934,11.6434269
		C36.6008949,36.8874817,31.6477051,42.2972641,24.9311523,42.2761993z'
                        />
                        <path
                            className='st0'
                            d='M26.6114521,20.9133301c-1.9708328,0.1374779-2.7528152,1.6526146-1.7251053,3.3421631
		c1.0065594,1.6547737,3.6307049,2.4246559,5.3813133,1.5787964c0.7264004-0.3509827,1.0487347-0.9167213,1.0020943-1.7274399
		c-0.0294247-0.5115204-0.2225666-0.9597816-0.5153198-1.3646355c-0.9059219-1.2528133-2.1717205-1.8008366-3.7029972-1.8567314
		C26.9168053,20.8939629,26.7640591,20.9026833,26.6114521,20.9133301z'
                        />
                        <path
                            className='st1'
                            d='M19.4846058,28.7409477c-0.1812057-0.3094368-0.4544029-0.5547009-0.8198166-0.7357941
		c-0.3654747-0.180975-0.8116245-0.271637-1.3383369-0.271637h-2.3705244v6.8646355h1.1259661v-2.7854538h1.2445583
		c0.5662441,0,1.0304565-0.0970669,1.3926411-0.2913742c0.3620682-0.1941338,0.6255112-0.4459782,0.7902126-0.7555313
		c0.1644707-0.3094368,0.2468796-0.6386108,0.2468796-0.9876938C19.7561855,29.3962364,19.6656399,29.0505581,19.4846058,28.7409477
		z M18.2845421,30.6028805c-0.2108116,0.1942501-0.530117,0.291317-0.9580898,0.291317h-1.2445583v-2.2420635h1.2445583
		c0.8494225,0,1.2741051,0.3752823,1.2741051,1.1259651C18.6005573,30.1335888,18.4951801,30.4087467,18.2845421,30.6028805z'
                        />
                        <g>
                            <polygon
                                className='st1'
                                points='21.8895397,27.7335396 20.763567,27.7335396 20.763567,34.598156 24.2106514,34.598156 
			24.2106514,33.6894798 21.8895397,33.6894798 		'
                            />
                        </g>
                        <path
                            className='st1'
                            d='M28.6354542,27.7235909H27.331686l-2.4593983,6.8745613h1.1753654l0.4938755-1.402626h2.8742123
		l0.4938755,1.402626h1.1852341L28.6354542,27.7235909z M26.8575459,32.2769127l1.1260242-3.1902294l1.1160965,3.1902294H26.8575459
		z'
                        />
                        <g>
                            <polygon
                                className='st1'
                                points='34.3542023,27.7335396 32.7640152,31.1016979 31.1737919,27.7335396 29.9194012,27.7335396 
			32.2010117,32.109127 32.2010117,34.598156 33.3269844,34.598156 33.3269844,32.109127 35.5987167,27.7335396 		'
                            />
                        </g>
                    </g>
                </g>
            );

        case 'PT':
        case 'PT2':
            return (
                <g {...props}>
                    <path
                        className='st0'
                        d='M17.6115971,18.0363083l-0.0194969-2.1056051l0,0c0-0.5458946-0.0194969-1.0917969,0-1.6376915
	c0.0389938-0.8578348,0.5264015-1.5012169,1.3257504-1.7351704c0.5458984-0.1754675,1.0333061,0,1.5012169,0.2924471
	c0.74086,0.4484148,1.462225,0.9163294,2.1835899,1.3647442c0.3119411,0.1949596,0.6433773,0.3704271,0.9358215,0.6043882
	l0.4289188,0.3119411l0.4484138-0.2144613c1.7741642-0.7408619,3.6263161-1.0722952,5.517458-1.3062496
	c2.5345192-0.3119402,5.0690422-0.350934,7.5450745,0.409421c0.8188477,0.2534542,1.6181946,0.5848885,2.3395615,1.0722961
	c2.2030792,1.4817171,3.0804176,3.743289,2.5150261,6.3752966c-0.3704338,1.7156773-1.1892776,3.2168941-2.203083,4.6206245
	c-1.5012169,2.0666122-3.3533669,3.7627888-5.4004822,5.2250137c-4.074728,2.9049549-8.5588799,4.8740788-13.5109463,5.7904072
	c-1.5792007,0.29245-3.1584034,0.4679146-4.7765961,0.3899269c-2.0081205-0.097477-3.9577522-0.3899269-5.7514133-1.4037361
	c-1.5012169-0.8383408-2.5540161-2.0276184-3.0024328-3.7237968c-0.3119407-1.2087688-0.2144589-2.3980446,0.1364737-3.5873203
	c0.5264015-1.7936592,1.501215-3.3533688,2.6709948-4.7765999c1.832653-2.2420788,4.055234-4.0552292,6.5117693-5.5759468
	L17.6115971,18.0363083z M20.7700024,15.6577635c-0.5069046,0-0.8188457,0.2339535-0.9943123,0.6628752
	c-0.1169777,0.2729473-0.1169777,0.5653954-0.1169777,0.8578339c0,1.4817181,0.0194969,2.9634418,0.0584908,4.445158
	c0.0389938,1.3842373,0.0194969,2.7684746,0.0194969,4.1527176c0,0.6628761-0.0194969,1.3257504,0.0194969,1.9886265
	c0.0389938,0.81884,0.760355,1.2282696,1.5012169,0.8968277c0.1754665-0.0779877,0.350935-0.1949596,0.5069046-0.292448
	c2.7099876-1.6376896,5.4004803-3.2948742,8.1104679-4.9325657c0.1754665-0.0974789,0.3314362-0.1949596,0.4874077-0.3314323
	c0.4679108-0.4094219,0.5069046-1.0333042,0.0779858-1.5012188c-0.1949635-0.2144604-0.4679108-0.3704262-0.7018681-0.5264015
	c-1.0722961-0.6823673-2.1445942-1.3647423-3.2168903-2.0276184c-1.6376915-1.0333023-3.275383-2.0666122-4.9130726-3.0804214
	C21.315897,15.7942371,21.0429497,15.6577635,20.7700024,15.6577635z M17.650589,24.5675793
	c-2.1835871,2.632-2.4565363,4.8155918-2.4565363,4.8155918c-0.3314371,2.0666122,0.7603569,3.7432899,2.8074694,4.1527176
	c1.48172,0.2924461,2.9634399,0.1754684,4.406168-0.1949615c4.4256649-1.1697731,8.110466-3.5093346,11.0154171-7.0186691
	c0.8383408-1.0138092,1.5012169-2.1445923,1.9691277-3.3728619c0.3509369-0.9163284,0.5069046-1.871645,0.3119431-2.8269672
	c-0.2729492-1.4622231-1.2672615-2.2615719-2.6515007-2.5540199c-1.5597038-0.3314342-3.0999146-0.1754665-4.6206303,0.2534542
	l-0.3509331,0.0974789l0.4679108,0.2534542c1.052803,0.6043892,2.1056023,1.2087708,3.1389084,1.813158
	c0.5653934,0.3119411,1.130785,0.6238823,1.6766834,0.9748173c1.052803,0.6823673,1.0722961,2.0666122,0.0779877,2.8074665
	c-0.1559715,0.1169796-0.3314362,0.2144604-0.4874077,0.3119411c-3.1584053,1.9301376-6.2973099,3.8407764-9.4557152,5.7709064
	c-1.0722942,0.643383-2.1250973,1.3062496-3.1973934,1.9496326c-0.5458984,0.3314342-1.11129,0.4484158-1.7351704,0.1559734
	c-0.7018681-0.3314342-0.9748173-0.9358215-0.9943142-1.6571827c-0.0194969-1.2867584,0-2.5735149-0.0194969-3.8602715
	L17.650589,24.5675793z M11.9186735,30.202013c-0.1949644,0.643383-0.1754675,1.1697769-0.1559706,1.6766853
	c0.0389929,1.813158,0.9163275,3.0609188,2.573513,3.7822819c1.5597057,0.6823692,3.1973972,0.643383,4.8350849,0.4289207
	c2.4565372-0.3314323,4.757103-1.2087708,6.9601841-2.3200569c0.3899288-0.1949615,1.9886265-1.0722961,2.3785496-1.2867584
	c-0.9358215,0.4484138-2.6904907,1.0917969-3.4118557,1.3062515c-2.5930099,0.7993469-5.2250118,1.1307869-7.9155025,0.7798538
	c-0.8773346-0.1169815-1.7156763-0.3509331-2.5150251-0.7603531C13.20543,33.0679741,12.1916218,31.9761772,11.9186735,30.202013z
	 M27.1452942,16.6520729c0.2339573-0.0584869,0.4484158-0.0974808,0.682373-0.1559753
	c2.0471115-0.40942,4.0942268-0.5848875,6.1608353-0.1754665c1.7351723,0.3509331,3.275383,1.0333023,4.2891922,2.5930138
	c0,0,0.2729454,0.4289207,0.2729454,0.7213612c0,0,0.4289169-2.4175472-1.6571884-3.8797703
	c-1.052803-0.7993488-2.3005638-1.0722961-3.5873222-1.111289c-2.4760323-0.0974808-4.7960949,0.5653944-7.0576668,1.462224
	l-0.3704319,0.1949596l0.760355,0.4874077L27.1452942,16.6520729z'
                    />
                </g>
            );

        case 'QQK':
            return (
                <g {...props}>
                    <g>
                        <path
                            className='st0'
                            d='M15.5294199,34.4675789h2.4641638l-0.6484642-1.9453926h1.5563145
		c2.2047787,0,3.6313992-1.1672363,4.1501694-3.5017052l2.4641647-10.116045
		c0.1296921-0.518774,0.1296921-0.9078503,0.1296921-1.2969284c0-1.4266243-0.7781563-2.2047787-2.5938568-2.2047787h-9.9863482
		c-1.0375414,0-1.9453907,0.3890772-2.7235479,0.9078503c-0.6484642,0.518774-1.1672354,1.4266186-1.4266214,2.5938568
		L6.450922,29.1501713c-0.1296926,0.518774-0.1296926,0.9078503-0.1296926,1.2969284
		c0,1.4266243,0.9078493,2.2047787,2.7235494,2.2047787h5.8361769l0.6484642,1.9453926V34.4675789z M13.3246431,27.8532429
		l0.7781582,2.4641666h-2.0750866c-0.5187712,0-0.7781572-0.1296959-0.7781572-0.518774
		c0-0.1296959,0.129694-0.2593861,0.129694-0.518774l2.5938559-10.7645035
		c0.1296921-0.6484642,0.5187712-1.0375404,1.1672354-1.0375404h4.9283276c0.5187702,0,0.7781582,0.1296959,0.7781582,0.5187721
		c0,0.1296959-0.129694,0.259388-0.129694,0.518774l-2.5938587,10.7645035
		c-0.1296921,0.6484642-0.5187702,1.0375423-1.1672344,1.0375423h-0.2593861l-0.7781563-2.4641666H13.3246431z
		 M36.6693497,34.597271l-0.6484642-1.9453926h1.5563164c2.2047806,0,3.6313972-1.1672363,4.1501694-3.5017071l2.4641685-10.2457352
		c0.1296883-0.518774,0.1296883-0.9078503,0.1296883-1.2969284c0-1.4266243-0.7781525-2.2047787-2.5938568-2.2047787H31.870718
		c-1.0375443,0-1.9453926,0.3890772-2.7235508,0.9078503c-0.6484642,0.518774-1.1672344,1.4266186-1.4266205,2.5938568
		l-2.4641628,10.2457352c-0.129694,0.518774-0.129694,0.9078503-0.129694,1.2969284
		c0,1.4266243,0.9078503,2.2047787,2.7235489,2.2047787h5.8361778l0.6484642,1.9453926H36.6693497z M32.1301041,27.8532429
		l0.7781563,2.4641666h-2.0750847c-0.5187702,0-0.7781563-0.1296959-0.7781563-0.518774
		c0-0.1296959,0.1296921-0.2593861,0.1296921-0.518774l2.5938568-10.7645035
		c0.1296921-0.6484642,0.5187721-1.0375404,1.1672363-1.0375404h4.9283257c0.5187721,0,0.7781563,0.1296959,0.7781563,0.5187721
		c0,0.1296959-0.1296921,0.259388-0.1296921,0.518774l-2.5938568,10.7645035
		c-0.1296921,0.6484642-0.5187721,1.0375423-1.1672363,1.0375423h-0.2593842l-0.7781601-2.4641666H32.1301041z'
                        />
                    </g>
                </g>
            );

        case 'SA':
            return (
                <g {...props}>
                    <path
                        className='st0'
                        d='M32.9234772,26.6098881l3.9052925-2.428503c0,0-1.2689476,0.2953587-4.0365639,1.6955738
	c0,0-1.0173454-2.54883-1.0173454-3.1614265l1.8159065,0.0656376c0,0-1.3345795-0.5469608-2.1222019-0.5469608
	c0,0-1.5205479-5.4039612-1.0939198-11.4861546l-1.2142525-1.0282841c0,0-0.6672916-0.7329264-1.0611,0
	c-0.3938103,0.7329254,0.459446,2.0018721-0.5797787,5.7102585c-1.0282841,3.7083855-2.4941368,8.5653915-2.4941368,8.5653915
	s-2.6691647,1.4002151-3.0410957,2.1222019c0,0,0.1203308-1.7612114-1.8815422-3.6427498
	c0,0-5.1851778-4.0475025-3.861536-6.6729069c1.258007-2.5050764,2.7129211-3.0520363,3.0301571-2.9863997
	s0.4813232,0.5907173-0.3828716,1.3892784c-0.8641968,0.7985611-1.2798862,1.4111567-0.9845276,1.6627579
	s2.1331425-1.2361298,2.1331425-1.2361298s2.373806-1.4002161,2.6691628-2.8004351
	c0.2953587-1.4002199,0.2516022-2.6582251-1.3892784-2.373806c-1.6408787,0.2844191-6.6947861,3.0410967-8.0293684,6.4978828
	s1.4002199,5.5461712,2.1331463,6.213459c0.7329264,0.6672916,4.3756781,3.4020901,3.6427507,4.5616455
	c0,0-1.0282841,0.7876225-0.4266281,1.4549141c0.6125946,0.6672916-1.7065144,2.1878395-2.1878395,2.1222038h-0.8532562
	c0,0-0.3609934-0.3609962,0.6672897-0.9079552c0.743866-0.4047489,2.3081703-1.575243,1.2798862-1.575243
	S12.5,30.0119781,12.5,31.5872192c0,1.5752449,2.6144676,1.4549141,4.2553463,0.5469589
	c1.6408787-0.9079533,2.6691647-1.9471741,3.6427517-3.095789c0.9735889-1.1595554,2.975462-2.920763,3.7083874-3.0957928
	c0.7329254-0.1859646-1.2142506,2.7347984-1.2142506,2.7347984s1.8377857,0.9626503,3.4020901-4.1350155
	c0,0,2.1222057-1.1814346,3.4567852-1.4220943l0.9626484,3.8724766c0,0-4.7804279,2.833252-3.3145752,6.3009739
	c0,0,0.7876205,1.5971222,3.949049,4.4413147c3.1614265,2.8441887,3.5880585,3.402092,4.8023071,2.4285011
	c1.2142487-0.973587,0.5469589-3.0410919,0-4.5616455C35.5926399,34.0813599,32.9234772,26.6098881,32.9234772,26.6098881z
	 M26.6006222,23.2624912l1.8815403-5.7102604l1.0939198,4.8570004L26.6006222,23.2624912z M34.2908745,38.4570351
	c-0.2078438,0-4.1897106-3.8505936-5.0867233-4.8132439s-1.5533657-2.4175625-0.459446-3.7849617
	s2.2534733-2.1440811,2.2534733-2.1440811l3.2817593,10.2828445C34.2908745,38.0085258,34.4877815,38.4570351,34.2908745,38.4570351
	z M36.7521935,22.1357613c1.1923714,0.2734795,0.8641968,0.7329235-0.3172379,0.8204384
	c-1.1923714,0.0984497-1.2798843-0.6344757-1.2798843-0.6344757C35.1113167,21.9169712,35.5598221,21.8732147,36.7521935,22.1357613
	z'
                    />
                </g>
            );

        case 'SCR2':
            return (
                <g {...props}>
                    <g>
                        <path
                            className='st0'
                            d='M15.7501802,30.5252838c0,0,17.0183525-3.3832588,25.9619179-3.4042282
		c4.2646408-0.0100002,2.846241,1.1222725-0.7107697,1.1222725C30.190094,28.2433281,13.168952,31.4231033,15.7501802,30.5252838z
		 M16.7628231,16.3760891c6.0493259-0.9207792,9.1016846,2.0926819,9.1016846,2.0926819s3.8417454-2.2022858,8.7804737-1.9093113
		c4.9387245,0.2929764,8.0446815,2.5371151,8.0446815,2.5371151s-1.3393173-1.6322918-6.1943359-3.097168
		s-10.6308193,1.548584-10.6308193,1.548584s-1.7994366-2.6269703-7.7923851-2.0971222
		c-6.46982,0.57201-11.0521898,5.5291195-11.0521898,5.5291195S10.7134981,17.2968693,16.7628231,16.3760891z
		 M26.0992641,34.6197166c8.8198376-0.0005684,16.0671902-7.0055771,16.0671902-7.0055771
		s-6.2477188,4.9673653-15.3662701,5.8412247c-9.1185513,0.8738632-18.0419006-4.4354477-18.0419006-4.4354477
		C13.6100368,32.6293411,18.3196011,34.6202164,26.0992641,34.6197166z M35.301384,17.4785652
		c0.6013222-0.0455437,0.9668198,0.3051891,0.9561615,0.7319107c-0.0110016,0.4354382-0.543499,0.9125443-0.8745193,1.1888351
		c-0.710041,0.5931511-1.0181732,0.7785587-1.2577209,0.9752655c-0.2053909-0.1623802-0.4825859-0.3014259-1.0901871-0.7924862
		c-0.2832756-0.2283802-0.7325897-0.6295319-0.6604729-1.0704308c0.0708885-0.432745,0.5118904-0.8466911,1.1171188-0.8925381
		c0.018795-0.0014229,0.0376968-0.002491,0.0567932-0.0031948c0.5067482-0.0180836,0.802002,0.3791904,0.802002,0.3791904
		s0.3755264-0.4495468,0.8861275-0.5102177C35.2585106,17.4822826,35.2801437,17.4801731,35.301384,17.4785652L35.301384,17.4785652
		z M5.4168687,29.7231579c1.0793386-0.1321564,2.025682-0.4610329,2.8126683-0.9774628
		c0.7890682-0.5173073,1.3952398-1.1987572,1.8016243-2.0254269c0.4063044-0.8262959,0.5489397-1.6759377,0.4242096-2.5252609
		c-0.1099606-0.7474194-0.4169054-1.3195457-0.9122057-1.700346c-0.4706078-0.3618755-1.0273113-0.522974-1.6547971-0.4788837
		c-0.0554543,0.0038967-0.111433,0.0094757-0.1681633,0.016571c-0.8000064,0.1008263-1.4295301,0.4438267-1.8705349,1.0187759
		c-0.4877996,0.6334534-0.674376,1.3671513-0.5547113,2.1809921c0.0906925,0.6165771,0.3430567,1.0830154,0.7498417,1.3861122
		c0.3847365,0.286726,0.8482242,0.4130497,1.3775983,0.3758545c0.0498023-0.0035,0.1002612-0.0083256,0.1514587-0.0148487
		c0.2448525-0.0309181,0.4833937-0.0952187,0.7109156-0.1912918c-0.0908403,0.2140942-0.19767,0.4049931-0.3197274,0.5713634
		c-0.2623134,0.3574257-0.6053376,0.6567078-1.0193853,0.889307c-0.4187999,0.2351265-0.9594536,0.4076881-1.6068597,0.5129681
		c-0.169209,0.0276146-0.2970386,0.1499596-0.3094707,0.2963772l-0.0283599,0.3345852
		c-0.0080132,0.0949116,0.0339336,0.1870422,0.1144142,0.2510509c0.0750289,0.0596371,0.1762304,0.0890961,0.2790341,0.081871
		C5.4018831,29.7249413,5.4094419,29.7242279,5.4168687,29.7231579z M7.7082639,22.978857
		c0.1042967-0.0073299,0.2652998,0.0038471,0.4593291,0.1845779c0.1287727,0.1198559,0.301796,0.3951283,0.3945265,1.0254593
		c0.0471687,0.3205013,0.0594511,0.7378025,0.0365686,1.2418652c-0.1427164,0.1270485-0.2785349,0.2287445-0.4049149,0.3030834
		c-0.1294518,0.0763836-0.2492824,0.1219196-0.3559299,0.1354465l-0.0226636,0.0023251
		c-0.0751834,0.0052834-0.1937513-0.0059509-0.3537722-0.1632881c-0.1122608-0.1105957-0.2652273-0.3719196-0.3559074-0.9883137
		c-0.0905604-0.6162205-0.0473075-1.0957508,0.1289735-1.4253712c0.1299925-0.2440891,0.3022699-0.2951393,0.4377151-0.3121529
		C7.6842947,22.9809055,7.6963201,22.9796963,7.7082639,22.978857z M11.6107683,28.9217052
		c0.5828094-0.1552296,1.1330614-0.265934,1.6355247-0.3292141c0.128006-0.0161266,0.2984848-0.0317612,0.5068645-0.0464039
		c0.3498945-0.0245857,0.8142929-0.0469761,1.380065-0.0664349c0.0052385-0.000185,0.0104771-0.00037,0.0157022-0.0007381
		c0.1600704-0.0112476,0.2969036-0.1091747,0.3377266-0.2435093l0.0872192-0.2869759
		c0.0281048-0.0926666,0.0064583-0.1902466-0.05898-0.2659168c-0.0654516-0.0758514-0.1678038-0.121685-0.2790785-0.1252022
		c-0.1972027-0.0062561-0.4005861-0.0044003-0.6010485,0.0054798c-0.1172171,0.0049458-0.1981544,0.0045986-0.2515392,0.0028648
		c0.0049143-0.0612316,0.0158834-0.1552525,0.0390434-0.2969398l0.3326263-2.7991257l0.4495792-3.0366287
		c0.0144587-0.0977402-0.0246086-0.1946449-0.1057835-0.2624454c-0.0754986-0.063261-0.1796389-0.0948887-0.2856417-0.0874405
		c-0.0078917,0.0005531-0.231411,0.0285091-0.231411,0.0285091c-0.0905151,0.0112972-0.1737022,0.0506039-0.2338018,0.1104107
		c-0.2264805,0.2252693-0.4492064,0.4246788-0.6620283,0.5926723c-0.1435699,0.1133957-0.5798454,0.3987522-1.842845,1.1139202
		c-0.1652203,0.093523-0.2199106,0.2791138-0.1248646,0.42383l0.1670866,0.2548447
		c0.0489817,0.0748158,0.131959,0.1285934,0.2298422,0.1489582c0.038825,0.0080605,0.0786667,0.010746,0.1180182,0.0079803
		c0.0598259-0.0042038,0.1184635-0.0207558,0.1707249-0.0487461c0.4298086-0.2309647,0.6950846-0.3662586,0.8584099-0.44557
		c-0.0122385,0.1888237-0.0432148,0.5426102-0.1176624,1.2035198l-0.3572836,2.3216228
		c-0.104579,0.6377945-0.2156687,0.7955322-0.2431421,0.8285465c-0.0062065,0.0077496-0.0684309,0.078495-0.35495,0.1646328
		c-0.2849159,0.0891361-0.5386877,0.1746197-0.7543106,0.2539482c-0.1147032,0.0422516-0.1986494,0.1302471-0.2235889,0.2343922
		l-0.0683136,0.2845516c-0.0263824,0.1095486,0.0161171,0.2217541,0.1118555,0.2954769
		c0.0752344,0.0579777,0.1748152,0.085722,0.2748461,0.0786934C11.5568237,28.9333572,11.5840511,28.9288845,11.6107683,28.9217052z
		 M18.6242371,28.2268867c0.8405781-0.1060562,1.5294456-0.4387817,2.0473976-0.9893322
		c0.5224304-0.5554371,0.7417278-1.1468048,0.6518841-1.7574062c-0.0781918-0.531702-0.4484005-1.05093-1.1022491-1.5467529
		c0.4480515-0.3145256,0.7974815-0.6477184,1.0417538-0.993454c0.2623215-0.3694916,0.3685627-0.7380733,0.3158131-1.0953007
		c-0.0556831-0.3791466-0.2889614-0.6852913-0.6933537-0.9101143c-0.345171-0.1918697-0.7566967-0.2726593-1.2231522-0.2398834
		c-0.0570545,0.0040092-0.1152725,0.0097446-0.1744423,0.0171947c-0.7051334,0.0890408-1.2810917,0.3646469-1.7119026,0.8192997
		c-0.4360905,0.4603252-0.6159077,0.9745007-0.5344639,1.5280972c0.0634575,0.4318066,0.2948761,0.8335266,0.6893883,1.1983719
		c-0.5553074,0.398674-0.9127922,0.6843452-1.087225,0.8679256c-0.2769604,0.2862301-0.4738884,0.5767117-0.5850677,0.862812
		c-0.11479,0.2942162-0.1527481,0.5806561-0.1127453,0.8510151c0.0614185,0.4180546,0.3116302,0.7791424,0.7436047,1.0731544
		c0.3904762,0.2662106,0.8983822,0.3795395,1.509552,0.3365955C18.4722919,28.2439613,18.5473213,28.236496,18.6242371,28.2268867z
		 M19.5214291,25.5360107c0.1440907,0.1807652,0.2287312,0.351263,0.2516575,0.5067139
		c0.0251179,0.1713886-0.0064487,0.3474922-0.096447,0.5384865c-0.0891533,0.1892929-0.2161942,0.3380947-0.3885746,0.4547939
		c-0.1716461,0.1164646-0.3492413,0.1848927-0.5426826,0.2092724c-0.0251713,0.003233-0.0500755,0.0057144-0.0748158,0.007452
		c-0.2250175,0.01581-0.4192734-0.0308781-0.5935307-0.1422367c-0.1944771-0.1245651-0.2997742-0.2824574-0.3313198-0.4965439
		c-0.0367966-0.2506542,0.0209751-0.503746,0.1766605-0.7741413c0.1415138-0.2463608,0.4386673-0.5594254,0.8847084-0.9330502
		C19.2315712,25.2223225,19.4293995,25.4205208,19.5214291,25.5360107z M19.9269943,21.8043766
		c0.1264935,0.0797901,0.1942692,0.176506,0.2131062,0.3042717c0.0170326,0.1158218-0.0298347,0.261734-0.1392612,0.4339828
		c-0.0752563,0.1186504-0.2603626,0.333334-0.7091293,0.7047749c-0.3090744-0.2562065-0.4830112-0.5063648-0.5182552-0.7456093
		c-0.0284004-0.1921844,0.0236225-0.3547306,0.1638031-0.5117702c0.139555-0.1568127,0.311058-0.2432804,0.5396709-0.2719612
		c0.0181599-0.0023727,0.0362625-0.0040092,0.0540714-0.0052624C19.6850986,21.7019768,19.8109188,21.7309837,19.9269943,21.8043766
		z M31.3788261,28.0638561c0.5455933-0.0686893,0.9896927-0.2518368,1.319809-0.5441799
		c0.3543549-0.3134251,0.5104294-0.633215,0.4640236-0.9504795c-0.0248985-0.1682968-0.1233101-0.3105812-0.2851639-0.4116573
		c-0.1436996-0.0897369-0.3138504-0.1304398-0.492157-0.1179104c-0.0170631,0.0011978-0.0344048,0.0029659-0.0515099,0.0050812
		c-0.1837921,0.0229702-0.3483963,0.1023712-0.4799042,0.233387c-0.0949421,0.0977268-0.1603718,0.2464046-0.2263412,0.5139694
		c-0.0618706,0.2557583-0.2034168,0.2942276-0.3315296,0.3103619c-0.0151863,0.0019798-0.0304108,0.0034161-0.04566,0.004488
		c-0.2451725,0.0172272-0.5202637-0.0771732-0.8173809-0.2806454c-0.2433033-0.1666622-0.6501484-0.5789127-1.2067375-1.5955448
		c-0.5395966-0.9898491-0.9007626-1.6046085-1.1042805-1.8792019c-0.1339226-0.1809311-0.2817936-0.3499107-0.4418736-0.5050507
		c0.632225-0.4845276,1.3477859-1.1160679,2.132534-1.8824711c0.3214149-0.3155022,0.5792999-0.5376759,0.7668114-0.6601925
		c0.1802063-0.1181622,0.4237041-0.2308998,0.7234306-0.3349705c0.1298389-0.04496,0.2211056-0.1477318,0.2364655-0.2661972
		l0.0182114-0.1404247c0.0133419-0.1029644-0.0326557-0.2039528-0.1234035-0.2707138
		c-0.0743771-0.0549297-0.1712704-0.0810337-0.2687416-0.0741844c-0.021328,0.0014992-0.0426483,0.0046425-0.0637474,0.0094166
		c-0.445528,0.098774-0.8440628,0.1708431-1.1844158,0.2137737c-0.1959896,0.0247421-0.4297924,0.0466557-0.6952267,0.0653057
		c-0.1883316,0.0132332-0.3935966,0.0249138-0.6155834,0.0350266c-0.0036259,0.0002537-0.0071583,0.0003204-0.0107841,0.0005741
		c-0.1812916,0.0127392-0.3290081,0.1357498-0.3477707,0.2912064l-0.037962,0.3138676
		c-0.011507,0.0955219,0.0277691,0.1893044,0.1070175,0.2555923c0.0754719,0.0628986,0.1793995,0.0945415,0.2854023,0.0870934
		c0.0053329-0.0003757,0.1363964-0.0141544,0.1907749-0.0196228c-0.1524658,0.1515045-0.3768673,0.3682175-0.7068481,0.6777363
		c-0.3356972,0.3135796-0.694788,0.6191101-1.0669289,0.9081879c-0.1025524,0.0794296-0.2018757,0.1529636-0.2976494,0.2205811
		l0.1555805-1.0024948c0.0362911-0.2278137,0.1323719-0.6128693,0.2856922-1.1441994
		c0.0294647-0.1022682-0.0016289-0.2092419-0.0831108-0.2859783c-0.0759792-0.0716381-0.1869144-0.1084576-0.3003826-0.1004848
		c-0.0079975,0.0005627-0.4367332,0.0546398-0.4367332,0.0546398c-1.5539093,0.1958542-2.5976791,0.5520535-3.190876,1.0886936
		c-0.6068287,0.5485687-0.8697586,1.1305676-0.7815342,1.7303143c0.0375557,0.2553539,0.1477623,0.4602585,0.3276405,0.6088867
		c0.2366581,0.195652,0.4755516,0.2232971,0.634449,0.2121334c0.0162106-0.0011387,0.032608-0.0026569,0.0489674-0.0047207
		c0.2134018-0.0270634,0.3971004-0.122982,0.5313568-0.2774105c0.1313686-0.1513004,0.1869202-0.3261623,0.1605186-0.5051403
		c-0.0208702-0.1429806-0.1066017-0.2878017-0.2676487-0.4536629c-0.1189613-0.1251163-0.1301517-0.1806469-0.1301517-0.1806469
		c-0.022047-0.1505756,0.0901566-0.319912,0.3366451-0.5059967c0.2995987-0.2309551,0.7117844-0.4153347,1.2291489-0.5502415
		l-0.3362331,2.1499176c-0.1865025,1.2090874-0.3501225,2.1276722-0.4870968,2.7330036
		c-0.0450039,0.2090435-0.1206493,0.3694115-0.2185974,0.4640579c-0.0577145,0.0556183-0.2195148,0.1688309-0.6540527,0.3004761
		c-0.1521511,0.0461636-0.2548084,0.1698475-0.2547245,0.3067913l0.0001812,0.2649288
		c0.0000191,0.1009293,0.0563412,0.1947918,0.1515141,0.2528324c0.0707722,0.0432968,0.1569176,0.0628433,0.2428703,0.0568027
		c0.0296478-0.0020828,0.0591831-0.007267,0.0880756-0.0155144c0.7202568-0.2047462,1.2519455-0.329504,1.5804062-0.3708687
		c0.0765953-0.0095863,0.1638889-0.0179157,0.262001-0.0248089c0.1101608-0.00774,0.2334175-0.0136585,0.3679333-0.0179901
		c0.161171-0.0047436,0.3001556-0.011034,0.4170361-0.0192471c0.0873394-0.0061378,0.1631298-0.0134735,0.2299519-0.0218258
		c0.0232639-0.0029144,0.0815601-0.0121307,0.174221-0.0279655c0.1597977-0.027319,0.2828674-0.1396389,0.303936-0.2775211
		l0.0430355-0.2813129c0.0157375-0.1024017-0.0276203-0.2039413-0.1161232-0.2723236
		c-0.0750084-0.0578098-0.174614-0.0859165-0.2750721-0.0788574c-0.0181293,0.0012722-0.0363827,0.0038357-0.0544643,0.0072994
		c-0.0878277,0.0175076-0.1555672,0.0295811-0.2035789,0.0356979c-0.0207176,0.002552-0.0418892,0.0047722-0.0635376,0.0062923
		c-0.0904331,0.0063553-0.1902065,0.0033092-0.2986565-0.0088177c0.0183125-0.1786461,0.0491772-0.4104652,0.09231-0.6934319
		l0.2461891-1.5950584c0.0769348,0.0821762,0.1465149,0.1634083,0.208477,0.242979
		c0.187149,0.2399063,0.478796,0.708704,0.8673649,1.3946743c0.4432716,0.77355,0.8423862,1.3243904,1.2202282,1.6838245
		c0.3879566,0.3691444,0.8054886,0.6299801,1.2410526,0.7752705c0.3867264,0.1289768,0.7867451,0.1802235,1.1888962,0.1519661
		C31.2627163,28.0769501,31.3207226,28.0712299,31.3788261,28.0638561z M33.5090866,26.3108902
		c0.2370949-0.0300083,0.4997253-0.1418953,0.803421-0.3420563c0.2741547-0.181263,0.6679459-0.5435371,1.2038231-1.1074162
		c0.0646667-0.0679893,0.0945549-0.1551132,0.0828438-0.2409573c-0.0118217-0.0858383-0.0641823-0.1629753-0.1450195-0.2137947
		l-0.2782669-0.1746273c-0.0735474-0.0462112-0.1611595-0.0652885-0.2467957-0.0592709
		c-0.0993881,0.0069847-0.1960678,0.0479698-0.2643356,0.1196861c-0.1897354,0.1991024-0.3350754,0.3422413-0.4455452,0.4447174
		c0.0301514-0.3228264,0.1148186-0.906929,0.3196564-1.9633503c0.2482529-1.2728481,0.2296562-1.4002647,0.2184868-1.4768219
		c-0.0263329-0.1795311-0.1360016-0.3279743-0.3083229-0.4178925c-0.1353989-0.070755-0.3001328-0.0999546-0.4896355-0.0866394
		c-0.0252762,0.0017757-0.0513496,0.0043392-0.0784416,0.0077057c-0.3131561,0.0395584-0.6503334,0.1795387-1.0305901,0.4278641
		c-0.3599854,0.2348328-0.7255211,0.5843353-1.1175175,1.0682564c-0.05966,0.0736732-0.0812302,0.1647816-0.0593109,0.2510071
		c0.0219212,0.0862255,0.0852985,0.1600285,0.1746101,0.2033043l0.3068905,0.1488476
		c0.0659027,0.0319366,0.1394577,0.0446873,0.2112274,0.0396442c0.1023788-0.0071926,0.2012634-0.0503445,0.2691383-0.1245937
		c0.1263008-0.1379623,0.2263107-0.2444572,0.305439-0.3262634c-0.334156,1.6730976-0.4641304,2.7105446-0.3970795,3.1660519
		c0.0328789,0.2224045,0.1371422,0.3960934,0.3098564,0.5159721c0.1612358,0.1120892,0.3645668,0.1628952,0.5880928,0.1471882
		C33.4636803,26.3159084,33.4860344,26.3137894,33.5090866,26.3108902z M37.9064865,25.6366043
		c0.6919861-0.087019,1.2453918-0.3312397,1.6451836-0.7255669c0.411499-0.4061203,0.5847054-0.8527279,0.5149002-1.3276062
		c-0.068119-0.4631119-0.410038-0.9091778-1.0165558-1.326231c-0.4732285-0.3243904-0.6241226-0.47085-0.6721802-0.5294571
		c-0.0715904-0.0869408-0.113266-0.1767139-0.1276093-0.2740765c-0.0126572-0.0855961,0.0065994-0.1516762,0.062252-0.2137299
		c0.0711555-0.0794182,0.1352844-0.0970898,0.1943626-0.1043491c0.0592918-0.0041656,0.1824036,0.0427685,0.3520012,0.1884632
		c0.1700287,0.1442013,0.2902222,0.2227898,0.4005241,0.2627621c0.110096,0.0401688,0.2305984,0.0560207,0.3581429,0.0470581
		c0.0189819-0.0013332,0.0382462-0.0032368,0.057045-0.0056534c0.219265-0.0274754,0.4073181-0.1209564,0.5438881-0.2698803
		c0.1385651-0.1510754,0.1978798-0.3272991,0.1713867-0.5106583c-0.0274887-0.1853008-0.1477432-0.4447174-0.5646553-0.6280708
		c-0.2498398-0.1102524-0.5625763-0.1531868-0.9294281-0.1274109c-0.0630264,0.0044289-0.1282692,0.0108414-0.1954002,0.0193996
		c-0.6111221,0.0771313-1.1018791,0.3039665-1.4588585,0.6746254c-0.3637695,0.377718-0.5151062,0.7938995-0.4499092,1.2367363
		c0.0681839,0.4640217,0.3926353,0.9085732,0.9656029,1.3224983c0.4251976,0.3043613,0.5926857,0.4520321,0.6578217,0.5218735
		c0.068737,0.0737934,0.1072922,0.1451359,0.118084,0.2178802c0.0110664,0.0751038-0.0073051,0.1568451-0.0565491,0.2506294
		c-0.0460968,0.0882626-0.1166992,0.1572189-0.2160149,0.2110043c-0.1170731,0.063446-0.2649117,0.1069279-0.4390221,0.1288528
		c-0.016571,0.002079-0.0328484,0.0037708-0.0486336,0.0048809c-0.0833931,0.0058594-0.1465797-0.0026836-0.1935921-0.0280857
		c-0.0473976-0.024828-0.0496712-0.0403938-0.0520515-0.0559502c-0.0041237-0.0282345-0.0077133-0.0625401-0.0106468-0.0935993
		c-0.0035782-0.0692291-0.0085564-0.1248302-0.0148544-0.1671734c-0.0261765-0.1788101-0.1348228-0.3295193-0.314415-0.4359303
		c-0.1543121-0.0913677-0.3359795-0.1327248-0.525589-0.1194019c-0.0188751,0.0013275-0.0380325,0.0032215-0.0577927,0.0057068
		c-0.2358131,0.0299168-0.4352875,0.1331615-0.5775337,0.2989388c-0.1374207,0.1612339-0.194191,0.355381-0.1639137,0.5620613
		c0.0512199,0.3476429,0.2697525,0.6330662,0.6320877,0.8251915c0.2983437,0.1584072,0.6927834,0.2217464,1.172142,0.1880646
		C37.7439156,25.6549759,37.8239441,25.6469746,37.9064865,25.6366043z M42.5104218,25.1112976
		c0.6920662-0.0873909,1.245594-0.3314362,1.6450653-0.7257404c0.4115143-0.4059391,0.5848236-0.8525543,0.515007-1.3276138
		c-0.0681229-0.4631119-0.4100418-0.9091778-1.0167732-1.3262157c-0.4721985-0.3235493-0.6237755-0.4705105-0.6721802-0.529459
		c-0.0713615-0.0867729-0.1130371-0.1765461-0.1273918-0.2740917c-0.0126572-0.0855942,0.0065956-0.1516743,0.0619431-0.2135258
		c0.0714607-0.0796204,0.1355934-0.0972919,0.1943474-0.1045284c0.0594025-0.0041733,0.1827278,0.0427456,0.3523369,0.1886215
		c0.1692314,0.1435261,0.2888794,0.2219696,0.4007492,0.262928c0.1100845,0.0399876,0.2306938,0.0558319,0.3585587,0.0468464
		c0.0184479-0.0012951,0.037178-0.0031605,0.057045-0.0056534c0.2188148-0.0278091,0.406559-0.1210861,0.543438-0.2702141
		c0.1390572-0.1516571,0.1981316-0.3282299,0.1710052-0.5098991c-0.0271797-0.1855049-0.1473389-0.4451122-0.5647011-0.6287994
		c-0.250042-0.110054-0.5629807-0.1527939-0.9302559-0.1269855c-0.0625992,0.0043983-0.1274185,0.0107803-0.1943474,0.0191402
		c-0.6112328,0.0771408-1.1020813,0.3041649-1.4588585,0.6746254c-0.363678,0.3775291-0.5151215,0.7937183-0.4501114,1.2369347
		c0.068409,0.4641876,0.3929672,0.9087315,0.9656982,1.3223076c0.4259949,0.3050385,0.593029,0.4523754,0.6580467,0.5220413
		c0.0686302,0.073801,0.1071854,0.1451435,0.1179771,0.2178879c0.0110664,0.0751038-0.0073051,0.1568451-0.0565605,0.2504482
		c-0.0460968,0.0882607-0.1166992,0.157217-0.2160187,0.2110043c-0.1170692,0.0634441-0.2649078,0.1069279-0.4394455,0.1288815
		c-0.0167847,0.0020943-0.0331688,0.0037937-0.0491676,0.0049191c-0.082859,0.0058212-0.1458206-0.0025539-0.1931763-0.0282974
		c-0.0470543-0.0244865-0.0493279-0.0400524-0.0513649-0.0538044c-0.0041656-0.02878-0.0078049-0.0638123-0.010788-0.0956001
		c-0.0035553-0.0688667-0.0085449-0.124649-0.014843-0.1669922c-0.0261765-0.1788101-0.1348228-0.3295193-0.3139648-0.4355965
		c-0.1541252-0.0917454-0.3360023-0.1330891-0.5258255-0.119751c-0.0189819,0.0013351-0.0381393,0.0032291-0.0573654,0.0056763
		c-0.2362671,0.0295849-0.4360466,0.1330318-0.5776787,0.2984009c-0.1378746,0.1609001-0.194725,0.3554192-0.1644211,0.5624619
		c0.0512314,0.3478279,0.2696571,0.6332569,0.6320038,0.8255634c0.2985458,0.1582108,0.6930923,0.2215443,1.1727715,0.1878395
		C42.3480644,25.1296539,42.4278793,25.1216698,42.5104218,25.1112976z'
                        />
                    </g>
                </g>
            );

        case 'SG':
            return (
                <g {...props}>
                    <path
                        className='st0'
                        d='M30.1257515,38.1695862c1.6259003,1.7854767,4.2158585,2.7042007,7.7050858,2.7178535l-0.0062523,1.5979614
	c-3.9545822-0.0154724-6.9453354-1.1082458-8.8808842-3.2429543c-2.4008675-2.6579819-2.1711731-5.8281784-2.1358566-6.1781693
	l0.2254944-2.3666325l2.2924175,0.6278381c1.0048485,0.2684536,4.2131996,0.7700901,5.6120453-0.4722939
	c1.785305-1.5821457,2.3687668-5.4402523-0.0649223-9.4631424c-2.1953125-3.6203785-7.1192207-7.43256-9.3245392-9.0340099
	c-2.2208405,1.5841322-7.1786747,5.3649235-9.3970547,8.9607563c-2.4639387,4.003727-1.9127922,7.8662739-0.1420307,9.4623337
	c1.3932953,1.2533092,4.6033669,0.7767849,5.6133423,0.5162163l2.2848759-0.5974884l0.2162952,2.3559208
	c0.0325775,0.3502579,0.2385101,3.5221558-2.1851921,6.1612625c-1.9470272,2.1195183-4.9431839,3.1888657-8.9049349,3.1733627
	l0.0062523-1.5979614c3.4902811,0.0136566,6.087245-0.8847733,7.7240095-2.6574821
	c1.990427-2.1640511,1.7753429-4.8295059,1.7666168-4.9432983l-0.039381-0.4706841l-0.4638329,0.1206932
	c-0.0432663,0.0101624-4.7582893,1.2292404-7.087225-0.8704243c-2.2530689-2.0350552-3.0675039-6.7516594-0.1424828-11.4932709
	c2.7790546-4.5125599,9.4535103-9.1895294,10.7584114-10.0801487c1.2916718,0.9007788,7.9283962,5.6246643,10.6781483,10.1640244
	c2.886879,4.7643528,2.0354538,9.4744377-0.2344208,11.4917984c-2.3494072,2.0813599-7.0516891,0.8254318-7.0938187,0.8149376
	l-0.4618206-0.1243172l-0.0450649,0.4703522C28.3902569,33.3263435,28.1615963,35.9900627,30.1257515,38.1695862z
	 M37.5926399,33.8442688c3.0685234-2.7251434,4.293045-8.5894814,0.6972084-14.5257111
	C34.9754639,13.8523769,27.0069637,8.4802685,26.6680546,8.252903l-1.1008472-0.7383046l-1.1085949,0.7296596
	c-0.3385677,0.2247152-8.3540268,5.5342827-11.7090855,10.9743662c-3.6411238,5.9079151-2.4615831,11.7816582,0.5845757,14.5307274
	c1.8148823,1.6368217,4.3289642,1.9278374,6.2989006,1.8484612c-0.1660347,0.3191185-0.3865871,0.6414986-0.6749687,0.9461174
	c-1.1589622,1.2345734-3.1485281,1.8579903-5.9088287,1.8471909l-0.0062504,1.597332
	c3.2493782,0.0127106,5.6493416-0.7896996,7.1206512-2.3911057c1.2924442-1.3988342,1.5358925-3.0725174,1.5688496-3.8702736
	c-1.3580608,0.2773399-5.132616,0.8118553-7.326128-1.1663513c-2.5546026-2.302866-3.4779634-7.3380814-0.2938356-12.5012398
	c3.1675081-5.1458273,10.9000807-10.2614584,11.2262907-10.4805288l0.2209129-0.1447325l0.221777,0.1464653
	c0.3244877,0.2216158,8.0076199,5.3975639,11.1399288,10.5680408c3.1456299,5.1879253,2.181839,10.2157555-0.3917618,12.498558
	c-2.2078705,1.9609871-5.9790745,1.396946-7.3359795,1.1089821c0.0288239,0.7979965,0.258007,2.4735298,1.538517,3.8824348
	c1.4628448,1.6128807,3.8544502,2.4340439,7.1017227,2.4467506l0.0062485-1.597332
	c-2.7592468-0.0107956-4.7449265-0.6497688-5.8952465-1.8933792c-0.2880974-0.3068695-0.5009556-0.6309471-0.6593208-0.9513359
	C33.244503,35.7381554,35.7650032,35.4668388,37.5926399,33.8442688z'
                    />
                </g>
            );

        case 'TTG':
            return (
                <g {...props}>
                    <path
                        className='st0'
                        d='M37.5,8.1265116v33.7469788h-25V8.1265116H37.5z M35.9182587,9.708252H14.0817404V40.291748h21.8365173
	V9.708252z M15.8391943,16.2987633v6.3488636l5.5966597-2.1748714v17.5088043h3.5641575V12.0149288L15.8391943,16.2987633z
	 M25.0000114,9.7082481v2.3066807l9.1608162,4.2838345v6.3488636l-5.5966606-2.1748714v17.5088043h-3.5641556v2.3101921h10.9182854
	V9.7082481H25.0000114z'
                    />
                </g>
            );

        case 'UG':
        case 'AES':
        case 'AES2':
            return (
                <g {...props}>
                    <path
                        className='st0'
                        d='M29.3987236,25.9131889c-0.2477627-0.4180984-1.0839615-0.5264931-1.4168911-0.5497208
	c-0.3329296-0.0232258-2.4311657,0.1083946-2.709898,1.649168c-0.2787342,1.5407715,2.5937595,1.7498188,3.8480549,2.7098999
	c1.2542953,0.9600792,1.2388096,2.6479588,0.0309715,3.8712826c-1.20784,1.2233276-2.4931068,1.7420769-4.4055214,1.416893
	c-1.9124165-0.3251877-2.8957214-1.571743-2.6479588-2.9654045c0.2477627-1.4014053,1.6646519-1.7498169,2.1911469-1.5717392
	c0.526495,0.1780796,0.4180984,0.5342369,0.224535,0.6116638s-1.0684738-0.4103546-1.5949688,0.3097038
	s-0.1935635,1.5020561-0.1935635,1.5020561c0.0851688,0.4413261,1.3317204,2.1524353,3.9564533,1.5175438
	c2.2995434-0.5574646,2.7408695-2.0440369,2.6789265-3.0196018c-0.0619411-0.975563-2.1214638-1.6259384-3.3138199-2.0750103
	c-1.1923542-0.44907-2.0827503-2.1524315-0.8749084-3.3680153c1.2155819-1.2155838,3.0660553-1.35495,4.0183907-0.9987926
	c0.9600811,0.3561573,0.8826523,1.0839615,0.6968327,1.4943161c-0.1858196,0.4103565-1.2697811,0.6348915-1.4014053,0.3329315
	c-0.1316223-0.30196,0.1393661-0.5497227,0.1393661-0.5497227C29.0193386,26.4319401,29.6464844,26.3390312,29.3987236,25.9131889z
	 M43.4824562,26.1609516c0,0-0.2787323-0.2400208-0.5264931,0.3793869
	c-0.2477646,0.6116638-1.0297623,1.8814449-1.0297623,1.8814449s0.1316223-2.1369476-0.2555084-2.7098999
	c0,0-0.1858215-0.1858215-0.4180984,0c-0.2400208,0.1858234-0.1858215,0.30196-0.1083946,0.665863
	c0.0774269,0.3639011,0.0619392,1.6956215-0.4955254,2.4234238c-0.5574646,0.7200584-0.7123146,0.1316223-0.7123146-0.1858234
	c0-0.3174438,0.1006546-0.9987907,0.2709885-1.3781757c0.1703377-0.3793869,0.0619431-0.5806923-0.2709885-0.5342369
	c-0.3329315,0.0464573-0.5187492,0.7200603-0.5187492,0.7200603s-0.29422-0.2013054-0.5729523,0.4645519
	c-0.2787323,0.6658611-1.0839615,2.1524334-1.6646538,2.3537426c-0.5806923,0.2013054-1.1071892-0.4413261-1.1071892-0.4413261
	s0.5497246-1.2388096,1.2930107-1.9046707c0,0,0.0619392-0.2709904-0.0464592-0.4335861
	c-0.1083946-0.1625919-0.3561554-0.1083946-0.6813469,0.3561573c-0.3251839,0.4645538-0.9368477,1.5485134-0.9368477,1.5485134
	s-0.5032654-0.7665176-0.6658554-1.3859234c-0.1625938-0.6194038-0.5264969-0.3406715-0.6271515-0.1393661
	s-0.1161385,0.5032692,0.1083946,0.9910488c0,0-0.16259,0.0309696-0.3251839,0.4103546
	c-0.1625938,0.3793869-1.1071892,2.3692265-1.8814468,2.6169891c-0.7742558,0.2477627-0.7432842-0.7200584-0.7432842-0.7200584
	s0.9445934-0.2709904,1.354948-1.0220203c0.4103584-0.75103,0.3406715-1.3007507-0.0232239-1.4710884
	c-0.3638992-0.1703377-1.4710903,0.2013054-1.9588699,2.0208111s0.6581173,2.1756611,1.3472061,1.9975796
	c0.6890907-0.1703377,1.6801414-1.4091454,2.4079399-3.3757591c0,0,0.4103546,0.6813469,0.5729523,0.820713
	c0,0-0.5806923,1.4091473-0.5342369,1.8272476c0.0464592,0.4180984,0.5497208,0.3638992,0.6116638-0.0619431
	c0.0619431-0.4335842,0.2787323-1.1613846,0.3793869-1.3007507c0,0,0.7819977,0.6426334,1.6878815,0.2787323
	c0.90588-0.3639011,1.7420769-2.3847103,1.7420769-2.3847103s-0.0541954,1.3084946,0.90588,1.3239803
	c0,0,0.6348915,0.0851669,1.1149292-0.7819996L41.144207,29.4438c0,0-0.1625938,0.224535-0.3329315,0.4180984
	c-0.1703377,0.1935654-0.9136238,1.0142784-1.3317223,1.4556046s-1.4943161,1.5562534-1.1613846,2.5085926
	c0.3329315,0.9445953,1.9666138,0.30196,3.0660553-2.9034634c0,0,0.2245369-0.6581173,0.3406715-1.347208
	c0,0,0.8052292-1.05299,1.4943161-2.2608318C43.2269516,27.2913666,43.7999039,26.4319401,43.4824562,26.1609516z
	 M32.2402458,29.3818607c0.5729523-0.5187511,0.29422,0.4103546,0.1006546,0.6426334
	c-0.1935616,0.2400208-0.44907,0.4722977-0.6968307,0.5342369C31.6363258,30.5587311,31.6672955,29.8928699,32.2402458,29.3818607z
	 M39.2704964,33.4079933c0,0-0.46455,0.1858215-0.2400208-0.5264931c0.2245369-0.7123146,1.873703-2.3847103,1.873703-2.3847103
	S40.4396286,32.5950241,39.2704964,33.4079933z M43.79216,25.1776447l-0.44907,0.3716431l0.5652084,0.1238823l0.2167892,0.5419788
	l0.29422-0.4955235L45,25.6809101l-0.3793869-0.4413261l0.1393661-0.5574627l-0.5342369,0.2245331l-0.4877815-0.3097019
	L43.79216,25.1776447z M24.5814438,20.0274639l-0.0987988-0.827446c0,0,0.4939995-0.4940014,0.839798-0.9139023
	c0.3458004-0.4075508,0.4446011-0.3581505,0.9756508-0.8768463c0.5310497-0.5186977,2.0377464-2.6058474,1.7660465-3.9272947
	c-0.271698-1.3214474-5.2116909-1.0126982-5.2116909-1.0126982s-0.1729031-0.5310488-0.5434017-1.6672497
	c-0.3828487-1.1361961-0.7533493-1.5931492-2.3835468-1.704299c-1.6301956-0.0988016-2.8651943,1.9512997-3.396244,3.0133934
	c-0.5310497,1.0620995-0.4322491,1.4696465-0.4322491,1.7660475c0,0.296402,0.0370483,0.7533503-0.6175003,1.0991478
	c-0.6545486,0.3457985-0.6298485,0.4940014-0.6298485,0.4940014h0.6174984v0.0988016
	c-1.1979485,0.5433969-1.1855984,1.9389467-1.1855984,1.9389467c-3.8902445,0.9262486-6.0020905,3.3468437-6.0020905,3.3468437
	c-3.8284936,4.7300453-3.248045,8.916687-3.248045,8.916687c0.2346497,3.5567951,2.7293458,9.1142864,8.7437859,10.7074299
	c6.2490902,1.6548996,10.3245811-2.0377464,10.3245811-2.0377464l-1.2226467-1.8030968
	c-2.2106457,1.6301956-3.2603951,1.5931473-3.2603951,1.5931473c0.1852493-3.8655434-0.0987988-5.5451355-0.3581505-6.3602409
	c-0.2593479-0.827446-0.1852493-1.000349,0.0123501-1.4449444c0.2099514-0.4446011,0.5310497-0.6916008,0.8397999-1.5437489
	c0.3087502-0.8521461,0.5804482-1.1855965,1.3337975-1.9512978c0.7533493-0.7780514,1.000349-1.5807972,1.2349987-2.1612473
	s0.5680981-0.716301,0.5680981-0.716301c0.839798-0.4322472,1.1485481-1.1361961,1.1485481-1.1361961l0.5804482,0.8892002
	l1.6672478-1.4078979C26.4215908,21.7441101,24.5814438,20.0274639,24.5814438,20.0274639z M22.5436954,14.9516211
	c0.3087502-0.2346478,0.3951988-1.0620995,0.3951988-1.0620995c2.7169971,0.1975994,2.8404961,0.9262486,2.8404961,0.9262486
	c-0.6051502,0.2593489-1.1855984,1.2102976-1.1855984,1.2102976c-0.6421986-0.1111498-1.2596989,0.3828506-1.2596989,0.3828506
	l-0.8027477-1.1485481L22.5436954,14.9516211z M15.8129568,21.3118629c0,0,0.135849,0.6051502,0.0370493,1.0497456
	c0,0.0123482-0.0123501,0.0370483-0.0123501,0.0494022c-0.1481991,0.4569473-0.8644981,1.1485481-0.8644981,1.1485481
	c0.3210993-0.0370483,0.8150988-0.3828506,0.8150988-0.3828506s0.2593489,0.3951988-0.2346497,0.9015484
	c-0.4939985,0.5063477-1.6672478,0.7039471-1.6672478,0.7039471C15.2078075,22.9914608,15.8129568,21.3118629,15.8129568,21.3118629
	z M14.3062601,17.8785648c0,0-0.0864496,1.0991478-0.0864496,1.4572983c0,0-2.0253973,0.6669006-3.0380955,1.4572983
	l-0.8027477-1.1855965C10.3789654,19.6199112,11.465764,18.4960632,14.3062601,17.8785648z M13.9481106,19.8545647
	c0,0,0.0494003,0.8027496-0.3211002,1.4572983c-0.3828497,0.6545467-0.6298485,1.4449444-0.8521481,1.7660465
	c-0.2222996,0.3210983-0.3210993,0.6298485-0.8521481,1.0126991c-0.5310497,0.3828506-1.5437469,1.704298-1.840147,2.0377483
	c-0.2963991,0.3457985-0.7903986,1.3584957-1.6301975,1.3955498c-0.839798,0.0370522-1.062098-0.0370483-1.062098-0.0370483
	S7.9954195,21.583559,13.9481106,19.8545647z M9.8355675,35.6007881c0,0-2.3464966-2.0377464-2.5564461-5.8662395
	l0.4816494-0.0123482c0.271699-0.6174984,0.9138985-0.5186958,1.0867987-0.6051502
	c0.1728992-0.0864487,1.7042961-1.3708496,1.7042961-1.3708496s-0.7903986,1.1361961-0.8521481,3.4456463
	C9.637969,33.501297,9.8726168,34.612793,9.8355675,35.6007881z M8.1436195,22.003458l0.1234999-0.5186958l0.3334494,0.4075508
	l0.5310488-0.0370483L8.8475685,22.29986l0.1975994,0.4816494l-0.5063486-0.1358509l-0.4075499,0.3458023l-0.0247002-0.5310497
	l-0.4445996-0.2717018L8.1436195,22.003458z M6.2664223,24.6710548l1.2843981,0.5186958
	c0,0-0.8891993,1.7660484-0.7533493,4.6188946l-1.4202476,0.0864506
	C5.3772235,29.8950958,5.2043238,26.8693542,6.2664223,24.6710548z M7.4890699,33.4642448L6.9703708,33.340744L6.575171,33.6988983
	l-0.0370498-0.5310516L6.081172,32.9084969l0.4816489-0.1975975l0.1111503-0.5186958l0.3457994,0.3951988l0.5186992-0.0494041
	l-0.271699,0.444603L7.4890699,33.4642448z M11.9474134,39.268734c0,0-2.2229967-0.963295-4.1125431-3.2850914l1.0003481-0.85215
	c0,0,0.8644991,1.3584976,3.667944,2.9763451L11.9474134,39.268734z M15.5412579,40.1455841l-0.5063486-0.123497
	l-0.3951988,0.3457947l-0.0370502-0.5310478l-0.4569492-0.2717018l0.4939995-0.1975975l0.1111498-0.5186996l0.3334503,0.4075546
	l0.5310488-0.0494041l-0.284049,0.444603L15.5412579,40.1455841z M12.9354115,37.8484879
	c0,0,0.3087492-1.8030968,0.3951988-2.3958969c0.0864496-0.5927963,0.3457994-1.8277969,0.9138985-2.8034439
	c0,0,0.8150988,3.7543945,1.8771973,5.9403419C16.1340561,38.5894814,14.29391,38.7253342,12.9354115,37.8484879z
	 M14.5656099,32.3033409c0,0,0.7286491-1.9512978-1.6301975-4.939991c0,0-0.8521481-1.0126972-1.8524971-0.5063477
	l1.1608982-1.0250511c0,0,0.568099,1.284399,2.5934944,2.9392967c2.0377474,1.6425495,3.3221464,2.0871449,3.6061945,2.2353477
	c0.28405,0.1482029,0.419899,0.2346478,0.0494003,0.4940014C18.110054,31.7475967,16.5292568,32.3033409,14.5656099,32.3033409z
	 M22.6177979,37.3174362l0.7286491,1.0373993c-2.4205952,1.6919441-5.0017433,1.9142456-5.0017433,1.9142456L18.258255,38.997036
	C21.0369987,38.6635857,22.6177979,37.3174362,22.6177979,37.3174362z M24.9395924,22.8556099l-0.6545486-0.716301
	c0.1605473,0.9632969-0.9138985,1.7783966-1.4572983,1.8771973c-0.5433998,0.0988026-0.9262486-0.3334503-1.3090973-0.7286491
	s-1.1114979-0.2840481-1.1114979-0.2840481c-2.0624466,0.6174984-3.5814934-0.1728992-3.5814934-0.1728992
	s-0.1235008-0.3828506,0.0370483-1.3337994c0.1729012-0.9509506,1.4696484-2.3835468,1.4696484-2.3835468l0.0247002-1.7660484
	h0.1975994c0,0,0.0123501,1.1732502,0.0494003,1.6548977c0.0370483,0.4940014,0.4816494,1.0991478,0.6915989,1.4202499
	c0.2222996,0.3210964,0.6669006,0.703949,0.9509487,1.2596989c0.28405,0.5557499,0.3334503,0.3210964,0.3334503,0.3210964
	c0.5063496-0.9632969-0.0123501-2.0377483-0.0123501-2.0377483c0.9015484,1.0126991,0.1728992,2.0500965,0.0617485,2.4823437
	c-0.0987988,0.4322472,0.8150978,0.4940014,0.8150978,0.4940014c-0.3704967-0.8768463-0.1728973-1.8771973-0.1728973-1.8771973
	c0.135849,0.9632969,0.4445992,1.7413464,0.4445992,1.7413464c0.9015484-0.3457966,1.8771973-1.0497456,1.9636478-1.8154488
	c0.0864487-0.7533493,0.2963982-0.9756489,0.2963982-0.9756489c1.1732502,0.7286491,2.0377464,2.0377483,2.0377464,2.0377483
	L24.9395924,22.8556099z'
                    />
                </g>
            );

        case 'WM':
            return (
                <g {...props}>
                    <g>
                        <path
                            className='st1'
                            d='M35.8978195,37.377697C32.9889755,39.9418106,29.1732998,41.5,25,41.5c-9.098115,0-16.5-7.4018326-16.5-16.5
		c0-3.0269012,0.820118-5.8655148,2.2485857-8.3068123c0.4714804-0.8057766,1.0092745-1.5680799,1.6059284-2.2796135
		l3.4259052,8.7911072l3.0174131-7.7428875h0.0498924l3.0174122,7.7428875l2.8144646-7.2221231h2.4942932l-5.2946243,13.5863733
		l-0.0034142,0.0087662l-0.0107193-0.0037804l-0.0107269,0.0037804l-0.0034122-0.0087662l-3.0282192-7.7706223l-3.0282269,7.7706223
		l-0.0034122,0.0087662l-0.0107203-0.0037804l-0.0107269,0.0037804l-0.0034122-0.0087662L11.875947,19.586071
		C11.184762,21.2554092,10.8023252,23.083765,10.8023252,25c0,7.8285904,6.369029,14.1976738,14.1976748,14.1976738
		c3.8960419,0,7.4302177-1.5777969,9.9981308-4.1275215L35.8978195,37.377697z'
                        />
                        <path
                            className='st0'
                            d='M25,8.5c-4.2651615,0-8.1575356,1.6267185-11.0892963,4.2923021l0.9064722,2.3249302
		C17.3989754,12.457798,21.0095272,10.8023252,25,10.8023252c7.8286476,0,14.1976738,6.369029,14.1976738,14.1976748
		c0,1.9938641-0.4139252,3.8926353-1.1590996,5.6160412l-3.8802338-9.9195728l-0.0034142-0.0087643l-0.0107193,0.0037804
		l-0.0107269-0.0037804l-0.0034142,0.0087585l-3.0282173,7.7706165l-3.0282269-7.7706566l-0.0034122-0.0088139l-0.0107212,0.0036945
		l-0.010725-0.0039616l-0.0034142,0.0084057l-5.2740669,13.5318222h2.4921856l2.7960205-7.1783867l3.0174141,7.7082939h0.0498924
		l3.0174122-7.7198219l3.3621216,8.7133179c0.6111069-0.7098808,1.1626587-1.4722786,1.6474762-2.2792206
		C40.6425781,30.9937992,41.5,28.0953026,41.5,25C41.5,15.901885,34.098114,8.5,25,8.5z'
                        />
                    </g>
                </g>
            );

        default:
            return <path />;
    }
};

const getStyle = (name, props) => {
    switch (name) {
        // NOTE: (SVG2JSX) DO NOT DELETE, ADD STYLE.
        case 'BG':
            return `
	 .${name} .st0{fill:#8C8C8C;}
	 .${name} .st7{fill:#231F20;}
	 .${name} .st8{fill:#606060;}
`;

        case 'HC':
            return `
	 .${name} .st0{fill:#8C8C8C;}
	 .${name} .st8{fill:#606060;}
`;

        case 'JL':
            return `
	 .${name} .st0{fill:#8C8C8C;}
`;

        case 'NT':
            return `
	 .${name} .st0{fill:#8C8C8C;}
`;

        case 'RT':
            return `
	 .${name} .st0{fill:#8C8C8C;}
`;

        case 'AB':
            return `
	 .${name} .st0{fill:#8C8C8C;}
	 .${name} .st1{fill:#FFFFFF;}
`;

        case 'AG2':
            return `
	 .${name} .st0{fill:#8C8C8C;}
	 .${name} .st1{fill:#FFFFFF;}
`;

        case 'BR':
            return `
	 .${name} .st0{fill:#8C8C8C;}
	 .${name} .st1{fill:#FFFFFF;}
`;

        case 'BTI':
            return `
	 .${name} .st0{fill:#8C8C8C;}
`;

        case 'CMD':
            return `
	 .${name} .st0{fill:#8C8C8C;}
	 .${name} .st2{clip-path:url(#CMD-SVGID_3_);fill:#8C8C8C;}
	 .${name} .st3{clip-path:url(#CMD-SVGID_6_);fill:#8C8C8C;}
	 .${name} .st4{clip-path:url(#CMD-SVGID_8_);fill:#8C8C8C;}
	 .${name} .st5{clip-path:url(#CMD-SVGID_10_);fill:#8C8C8C;}
	 .${name} .st6{clip-path:url(#CMD-SVGID_12_);fill:#8C8C8C;}
`;

        case 'DG':
            return `
	 .${name} .st0{fill:#8C8C8C;}
`;

        case 'EBET':
            return `
	 .${name} .st0{fill:#8C8C8C;}
	 .${name} .st1{fill:#FFFFFF;}
`;

        case 'EVO':
        case 'BMK2':
            return `
	 .${name} .st0{fill:#8C8C8C;}
`;

        case 'GD':
            return `
	 .${name} .st0{fill:#8C8C8C;}
`;

        case 'GP':
            return `
	 .${name} .st0{fill:#8C8C8C;}
`;

        case 'HB':
            return `
	 .${name} .st0{fill:#8C8C8C;}
`;

        case 'IBC':
            return `
	 .${name} .st0{fill:#8C8C8C;}
	 .${name} .st1{fill:#FFFFFF;}
`;

        case 'IDN':
            return `
	 .${name} .st0{fill:#8C8C8C;}
`;

        case 'IME':
            return `
	 .${name} .st0{fill:#8C8C8C;}
`;

        case 'JKR':
            return `
	 .${name} .st0{fill:#8C8C8C;}
`;

        case 'KM':
        case 'KM2':
            return `
	 .${name} .st0{fill:#8C8C8C;}
`;

        case 'MEGA':
            return `
	 .${name} .st0{fill:#8C8C8C;}
`;

        case 'MGP':
            return `
	 .${name} .st0{fill:#8C8C8C;}
`;

        case 'NS':
            return `
	 .${name} .st0{fill:#8C8C8C;}
	 .${name} .st1{fill:#FFFFFF;}
`;

        case 'PG':
            return `
	 .${name} .st0{fill:#8C8C8C;}
`;

        case 'PP':
        case 'PPL':
            return `
	 .${name} .st0{fill:#8C8C8C;}
	 .${name} .st1{fill:#FFFFFF;}
`;

        case 'PT':
        case 'PT2':
            return `
	 .${name} .st0{fill:#8C8C8C;}
`;

        case 'QQK':
            return `
	 .${name} .st0{fill:#8C8C8C;}
`;

        case 'SA':
            return `
	 .${name} .st0{fill:#8C8C8C;}
`;

        case 'SCR2':
            return `
	 .${name} .st0{fill:#8C8C8C;}
`;

        case 'SG':
            return `
	 .${name} .st0{fill:#8C8C8C;}
`;

        case 'TTG':
            return `
	 .${name} .st0{fill:#8C8C8C;}
`;

        case 'UG':
        case 'AES':
        case 'AES2':
            return `
	 .${name} .st0{fill:#8C8C8C;}
`;

        case 'WM':
            return `
	 .${name} .st0{fill:#8C8C8C;}
	 .${name} .st1{fill:#FFFFFF;}
`;

        default:
            return <path />;
    }
};

const SVGProvider = ({ name = '', style = {}, fill = '', viewBox = '', width = '100%', className = '', height = '100%', color = '' }) => (
    <svg
        width={width}
        style={style}
        height={height}
        className={className}
        xmlns='http://www.w3.org/2000/svg'
        viewBox={viewBox || getViewBox(name)}
        xmlnsXlink='http://www.w3.org/1999/xlink'
    >
        <style dangerouslySetInnerHTML={{ __html: getStyle(name, color) }}></style>
        {getPath(name, { fill })}
    </svg>
);

export default SVGProvider;
