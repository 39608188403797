import { useSelector } from 'react-redux';
import { psw_validatePasswordLengthValidity } from 'root/utils/password-util';

// this function is use when submitForm with newSpecialChars format
export const usePreCheckSpecialCharFormatValidity = (password) => {
    const passwordReducer = useSelector((state) => state.passwordReducer);

    const passwordConfig = passwordReducer?.processedData?.passwordConfig;
    const isSpecialCharCheckValid = useValidatePasswordSpecialCharsValidity(password);

    // skipCheckSpecialChars will true until trigger this function. As special char check required trigger onSubmit
    if (passwordConfig?.newErrorFormat && !isSpecialCharCheckValid) {
        return false;
    } else {
        return true;
    }
};

export const useValidatePassword = (password, skipCheckSpecialChars) => {
    const passwordReducer = useSelector((state) => state.passwordReducer);
    const passwordConfigRedux = passwordReducer?.processedData?.passwordConfig;

    const isSpecialCharValid = useValidatePasswordSpecialCharsValidity(password);
    const isFormatValid = useValidatePasswordFormatValidity(password);

    if (!passwordConfigRedux) {
        return;
    }
    let errorKey = null;

    const { minLength, maxLength, pwPattern, newErrorFormat } = passwordConfigRedux;

    if (newErrorFormat) {
        // Error Key from here are not using, since UI have guideline message.
        if (!psw_validatePasswordLengthValidity(password, minLength, maxLength)) {
            errorKey = 'invalid_password_length';
        } else if (!isFormatValid) {
            errorKey = 'password_format_not_match';
        } else if (!skipCheckSpecialChars && !isSpecialCharValid) {
            errorKey = 'password_missing_special_characters';
        }
    } else {
        if (!password || password?.length <= 0) {
            errorKey = 'password_required';
        } else {
            if (!password.match(pwPattern)) {
                errorKey = 'password_format_not_match';
            } else if (password.length > maxLength) {
                errorKey = 'password_max_length_exceeded';
            } else if (password.length < minLength) {
                errorKey = 'password_min_length_not_met';
            }
        }
    }
    // return errMsg;
    return errorKey;
};

export const useValidatePasswordFormatValidity = (password) => {
    const passwordReducer = useSelector((state) => state.passwordReducer);
    const digitAndAlphabetPattern = passwordReducer?.processedData?.passwordConfig?.digitAndAlphabetPattern;
    return password?.match(digitAndAlphabetPattern);
};

export function useValidatePasswordSpecialCharsValidity(password) {
    const passwordReducer = useSelector((state) => state.passwordReducer);
    const _specialChars = passwordReducer?.processedData?.passwordConfig?.specialChars || {};

    // const allSpecialChars = /[^a-zA-Z0-9]/g; // any value other than alphabet and digit are treat as special character
    const allSpecialCharsRegex = /[^a-zA-Z0-9]/g; // any value other than alphabet and digit are treat as special character
    // const specialCharsPattern = new RegExp(`[${_specialChars?.format}]`);

    if (_specialChars?.mandatory && _specialChars?.allow) {
        // return password.match(specialCharsPattern);
        return password.match(new RegExp(`^${'(?=.*[' + _specialChars?.format + '])'}[a-zA-Z0-9${_specialChars?.format}]{1,}$`));
    } else if (_specialChars?.allow) {
        const isPasswordHasSpecialChars = allSpecialCharsRegex.test(password);
        if (isPasswordHasSpecialChars) {
            return password.match(new RegExp(`^[a-zA-Z0-9${_specialChars?.format}]{1,}$`));
        } else {
            return true;
        }
    } else if (!_specialChars?.allow) {
        return allSpecialCharsRegex.test(password);
    } else {
        return true;
    }
}

export const useValidateNumericPassword = (password) => {
    return /\d/.test(password);
};
