import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Trans, Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import Footer from 'root/webapp/layout/footer/component/mobile/Footer';
import { CSSTransition } from 'react-transition-group';

import vm from '../reset-password.controller';
import core from 'root/utils/core';
import { portalAction } from 'root/redux/action';
import Turnstile from 'react-turnstile';

class Reset extends React.Component {
    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        vm.initCloudflareSettings();
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        const enabledTurnstile = vm.enabledTurnstile();
        return (
            <Fragment>
                <Translation>
                    {(t) => (
                        <div className='mobile-register mobile-reset'>
                            <div className='bg-content-section'>
                                <div className='register-panel'>
                                    <div className='register-box'>
                                        <div className='title'>
                                            <span> </span>
                                            <i
                                                className='icon-close3'
                                                onClick={() => {
                                                    this.props.dispatch(portalAction.floatingPageUpdated(''));
                                                }}
                                            ></i>
                                        </div>

                                        <div className='content'>
                                            <CSSTransition classNames='alert' unmountOnExit timeout={300} in={this.state.requestSuccess}>
                                                <div className='input-success'>{t('reset:reset.request.messages.success')}</div>
                                            </CSSTransition>

                                            {this.state.requestFail ? (
                                                <div className='input-fail'>
                                                    <Trans i18nKey='reset:reset.request.messages.notfound'></Trans>
                                                </div>
                                            ) : (
                                                !this.state.requestSuccess && (
                                                    <div className='step-content' data-index={0}>
                                                        <div className='step-box'>
                                                            <p className='reset-title'>{t('reset:reset.finish.title', 'Reset Password')}</p>

                                                            <div className='msg-tips'>
                                                                <p>{t('reset:reset.request.messages.info')}</p>
                                                                <p>
                                                                    <Trans i18nKey={'reset:reset.request.messages.info-2'}>
                                                                        If you don't have an email address, <br /> please contact our
                                                                        <span
                                                                            onClick={() => {
                                                                                core.openLivechat(this.props.language.countryLanguageKey, this.props.screen.viewType);
                                                                            }}
                                                                        >
                                                                            customer service
                                                                        </span>
                                                                        .
                                                                    </Trans>
                                                                </p>
                                                            </div>

                                                            <form action=''>
                                                                <div className='input'>
                                                                    <input
                                                                        type='text'
                                                                        value={this.state.fields['email']}
                                                                        onChange={(e) => {
                                                                            vm.handleFieldChanged(e, 'email');
                                                                        }}
                                                                        placeholder={t('global:global.form.email', 'Email')}
                                                                    />

                                                                    <span className='input-error'>{t(this.state.errors['email'])}</span>
                                                                </div>

                                                                {enabledTurnstile && (
                                                                    <Turnstile
                                                                        theme={'dark'}
                                                                        language={this.props?.language?.key || 'en'}
                                                                        className='turnstile-wrapper'
                                                                        sitekey={this.props?.systemFeatureReducer?.siteKey}
                                                                        onVerify={(token) => {
                                                                            vm.handleTurnStileToken(token);
                                                                        }}
                                                                        onError={() => {
                                                                            vm.turnstileErrorHandling();
                                                                        }}
                                                                    />
                                                                )}

                                                                <span
                                                                    className={`btn-next ${this.state.turnstileValidation && 'btn-next-disabled'}`}
                                                                    onClick={() => {
                                                                        vm.reset();
                                                                    }}
                                                                    disabled={this.state.disabledButton || this.state.turnstileValidation}
                                                                >
                                                                    {t('reset:reset.finish.title', 'RESET PASSWORD').toUpperCase()}
                                                                </span>
                                                            </form>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='footer-section'>
                                <Footer />
                            </div>
                        </div>
                    )}
                </Translation>
            </Fragment>
        );
    }
}

export default connect(mapStateToProps, null)(withTranslation(['register', 'reset'])(withRouter(Reset)));
