import React, { isValidElement } from 'react';
import { CSSTransition } from 'react-transition-group';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { TweenLite, TweenMax, Linear, gsap } from 'gsap';
gsap.ticker.lagSmoothing(0, null);

// content = banner
// tab = custom pagination button
class Slider extends React.Component {
    timer = null;
    initiated = false;

    constructor(props) {
        super();
        this.props = props;
        this.state = {
            index: -1,
            direction: 'next',
            dur: null,
            timeout: null,
            allowPause: null,
            transionName: null,
            autoPlay: null,
            items: [],
            forceTransionName: null,
            forceStart: false,
            ignoreTouchEvent: false,
            ignoreItemUpdate: true,
        };
    }

    tick() {
        this.next();
    }

    start() {
        this.stop();

        this.state.autoPlay &&
            (this.timer = TweenMax.delayedCall(this.state.dur / 1000, () => {
                this.tick();
            }));
    }

    stop() {
        this.state.autoPlay && this.timer && this.timer.kill();
    }

    next() {
        const size = this.state.items.length;
        const index = this.state.index === size - 1 ? 0 : this.state.index + 1;
        this.slideTo(index, 'next');
    }

    prev() {
        const size = this.state.items.length;
        const index = this.state.index === 0 ? size - 1 : this.state.index - 1;
        this.slideTo(index, 'prev');
    }

    slideTo = (index, direction) => {
        if (index == this.state.index) return;
        direction = direction ?? (index > this.state.index ? 'next' : 'prev');
        this.setState((state) => ({
            ...state,
            index,
            direction,
        }));
        this.start();
        this.props.onChange && this.props.onChange(index);
    };

    onStart(e) {
        if (this.initiated) return;
        let point = e.touches ? e.touches[0] : e;
        let target = this.refs.elRef;
        target.distX = 0;
        target.pointX = point.pageX;
        target.startTime = +new Date();
        this.initiated = true;
    }

    onMove(e) {
        if (!this.initiated) return;
        e.preventDefault();
        let point = e.touches ? e.touches[0] : e;
        let target = this.refs.elRef;
        const deltaX = point.pageX - target.pointX;
        target.pointX = point.pageX;
        target.distX += deltaX;
    }

    onEnd(e) {
        if (!this.initiated) return;
        this.initiated = false;
        let target = this.refs.elRef;
        target.endTime = +new Date();
        let distance = target.distX,
            speed = distance / (target.endTime - target.startTime);
        if (Math.abs(distance) < 10) return this.props.moveByClick && this.next();
        speed < 0 ? this.next() : this.prev();
    }

    componentDidMount() {
        this.setState({
            dur: this.props.dur || 6000,
            timeout: this.props.timeout || 500,
            allowPause: this.props.allowPause ?? true,
            transionName: this.props.transionName || 'fade',
            autoPlay: this.props.autoPlay ?? true,
            items: this.props.items,
            forceTransionName: this.props.forceTransionName || '',
            forceStart: this.props.forceStart ?? false,
            ignoreTouchEvent: this.props.ignoreTouchEvent ?? false,
            ignoreItemUpdate: this.props.ignoreItemUpdate ?? true,
            checkOnclick: this.props.checkOnclick ?? false,
        });
        requestAnimationFrame(() => {
            this.setState({
                ...this.state,
                index: 0,
            });
            this.start();

            if (this.props.forceStart) {
                let forceIndex = 0;
                if (this.props.forceSelectIndex) {
                    forceIndex = this.props.forceSelectIndex;
                    this.setState({ index: forceIndex });
                }
                this.props.onChange && this.props.onChange(forceIndex);
            }

            this.initEvent();
        });
    }

    componentDidUpdate() {
        if (this.state.ignoreItemUpdate === false && this.props.items !== this.state.items) {
            this.setState({ items: this.props.items });
        }
    }

    componentWillUnmount() {
        this.stop();
        this.initEvent(true);
        this.setState = () => {
            return;
        };
    }

    initEvent(remove) {
        const eventType = remove
            ? (el, type, fn, options) => {
                  el.removeEventListener(type, fn, options);
              }
            : (el, type, fn, options) => {
                  if (el) {
                      el.addEventListener(type, fn, options);
                  }
              };

        const target = this.props.eventTarget ? document.querySelector(this.props.eventTarget) : this.refs.elRef;

        if (this.props.ignoreTouchEvent && this.props.ignoreTouchEvent === true) {
        } else {
            eventType(target, 'touchstart', this);
            eventType(target, 'touchmove', this);
            eventType(target, 'touchend', this);
            eventType(target, 'touchcancel', this);
        }

        eventType(target, 'mousedown', this);
        eventType(target, 'mousemove', this);
        eventType(target, 'mouseup', this);
        eventType(target, 'pointerover', this);
        eventType(target, 'pointerout', this);
        // if (this.props.moveByClick) {
        //   eventType(target, 'click', this);
        // }
    }

    handleEvent(e) {
        switch (e.type) {
            case 'touchstart':
                this.onStart(e);
                break;
            case 'touchmove':
                this.onMove(e);
                break;
            case 'touchend':
                this.onEnd(e);
                break;
            case 'touchcancel':
                this.onEnd(e);
                break;
            case 'mousedown':
                this.onStart(e);
                break;
            case 'mousemove':
                this.onMove(e);
                break;
            case 'mouseup':
                this.onEnd(e);
                break;
            case 'pointerover':
                this.state.allowPause && this.stop();
                break;
            case 'pointerout':
                this.state.allowPause && this.start();
                break;
            case 'click':
                this.next();
                break;
            default:
                break;
        }
    }

    checkProviderMaintenance(slideObj, i) {
        if (this.state.checkOnclick) {
            if (slideObj.providerMaintenance === null) {
                this.slideTo(i);
            }
        } else {
            this.slideTo(i);
        }
    }

    render() {
        return (
            <div ref={'elRef'} className='slider'>
                <div className='slider-container'>
                    {this.state.items.map((node, index) => {
                        return (
                            <CSSTransition
                                key={'item-' + index}
                                classNames={this.state.forceTransionName ? this.state.forceTransionName : this.state.transionName + '-' + this.state.direction}
                                in={this.state.index === index}
                                timeout={this.state.timeout}
                                unmountOnExit
                            >
                                <div key={'item-' + index} data-index={index}>
                                    {typeof this.props.renderItem === 'function' ? this.props.renderItem(node.content) : node.content}
                                </div>
                            </CSSTransition>
                        );
                    })}
                </div>

                {[
                    <ul key='pagination' className={['pagination', this.state.items[0]?.tab ? '' : 'default'].join(' ')}>
                        {this.state.items.map((node, i) => (
                            <li key={i} onClick={() => this.checkProviderMaintenance(this.state.items[i], i)} className={this.state.index === i ? 'on' : ''}>
                                {this.state.items[i].tab}
                                {this.state.items[i].providerMaintenance}
                            </li>
                        ))}
                    </ul>,
                    <ul key='navigation' className='navigation'>
                        <li
                            className='prev'
                            key='prev'
                            onClick={() => {
                                this.prev();
                            }}
                        >
                            {this.props.navigation}
                        </li>
                        <li
                            className='next'
                            key='next'
                            onClick={() => {
                                this.next();
                            }}
                        >
                            {this.props.navigation}
                        </li>
                    </ul>,
                ]}
            </div>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['app', 'sport', 'casino', 'poker', 'fishing', 'slot', 'vip', 'lottery', 'cockfight', 'fourD'])(withRouter(Slider)));
