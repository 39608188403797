import core from 'root/utils/core';
import SettingsData from './../../settings-data';
import notification from 'root/utils/notification';
import commonUtil from '../../../../../utils/common-util';
import { trans_massageHideAddBank } from 'root/utils/transaction-util';
import { gu_getNestedValue } from '../../../../../utils/general-util';

const controller = {
    vm: null,
    depositTabs: SettingsData.depositTabs,
    init(vm) {
        controller.vm = vm;

        controller.vm.state = {
            isLoading: true,
            isSubLoading: false,
            depositAllSettings: { uploadReceipt: false },
            depositTabs: {},
            customTranslationObj: {},

            defaultTab: null,
            displayTab: [],
            selectedTabIndex: 0,
            depositDetails: null,
            depositAmountOptions: [],

            methodList: [],
            memberBankList: [],
            paymentGatewaySetting: null,
            paymentCategories: [],
            selectedPaymentCategory: null,

            compSettings: {},

            memberDecimalSettings: {},
        };
    },

    initCompSettings() {
        return new Promise((resolve) => {
            controller.vm.setState({}, () => {
                window.SPL_Content.getBankNameJson(controller.vm.props);

                // Step 1: Standard way to get from portal settings
                let tempSettings = commonUtil.getSettingFromPortalSettings({
                    vm: controller.vm,
                    settingName: 'depositSettings',
                });

                // Step 2: Massage again to get the specific data you need (Skip if not needed)

                // Step 3: Initial all into compSettings
                let compSettings = {
                    depositSettings: tempSettings,
                };

                controller.vm.setState({ compSettings }, () => {
                    resolve();
                });
            });
        });
    },

    initDepositPage() {
        core.portalIsLoading(controller.vm.props, true);

        const { depositSettings } = controller.vm.state.compSettings;
        const currency = controller.vm.props.user.account.currency;

        let getMerchantBanksPromise = null;
        let loadDepositRelatedSettingsPromise = null;

        getMerchantBanksPromise = new Promise((resolve) => {
            if (gu_getNestedValue(depositSettings, 'enableCategorizedDepositTab')) {
                resolve();
            } else {
                window.SPL_Member.getMerchantBanks().then((depositApiJson) => resolve(depositApiJson));
            }
        });

        loadDepositRelatedSettingsPromise = new Promise((resolve) => {
            window.SPL_Transaction.loadDepositRelatedSettings(currency).then((depositSettings) => resolve(depositSettings));
        });

        let getDefaultTabPromise = controller.loadDefaultTab();

        let getCategorizedTabAndMerchantBanksPromise = new Promise((resolve) => {
            if (gu_getNestedValue(depositSettings, 'enableCategorizedDepositTab')) {
                window.SPL_Content.loadPaymentOptionCategory(controller.vm.props).then((res) => resolve(res));
            } else {
                resolve();
            }
        });

        // load deposit settings json
        Promise.all([getMerchantBanksPromise, loadDepositRelatedSettingsPromise, getDefaultTabPromise, getCategorizedTabAndMerchantBanksPromise]).then(async (result) => {
            let depositApiJson = result[0];
            let depositAllSettings = result[1];
            let merchantBanksByPaymentCategories = result[3];

            const vm = controller.vm;
            const { defaultTab, customTranslationObj } = vm.state;

            let { selectedTabIndex } = vm.state.selectedTabIndex;
            let depositTabs = controller.depositTabs;
            let _displayTab = [];
            let filteredDepositApiJson = {};
            let paymentCategories = [];
            let merchantBanksByOptions = {};
            let selectedPaymentCategory = null;

            if (merchantBanksByPaymentCategories && merchantBanksByPaymentCategories.length > 0) {
                paymentCategories = merchantBanksByPaymentCategories;
                if (paymentCategories && paymentCategories[0]) {
                    selectedPaymentCategory = paymentCategories[0]; // default take index 0
                    merchantBanksByOptions = paymentCategories[0].paymentOption;
                }
            } else if (depositApiJson) {
                merchantBanksByOptions = depositApiJson;
            } else {
                let depositApiJson = await window.SPL_Member.getMerchantBanks(controller.vm.props);
                merchantBanksByOptions = depositApiJson;
            }

            if (merchantBanksByOptions && Object.keys(merchantBanksByOptions).length > 0) {
                _displayTab = controller.getFilterDisplayTabs(depositTabs, merchantBanksByOptions); // rearrange follow API data sequence
                _displayTab = controller.customTabArrangement(_displayTab, depositSettings); // rearrange menu if want to overwrite API data sequence
                let foundDefaultTab = defaultTab ? _displayTab.find((tab) => tab.key === defaultTab) : null;
                let foundDefaultTabCode = foundDefaultTab && foundDefaultTab.code ? foundDefaultTab.code : null;
                for (let i = 0; i < _displayTab.length; i++) {
                    const displayTab = _displayTab[i];

                    if (foundDefaultTabCode && displayTab.actualCode === foundDefaultTabCode) {
                        displayTab.selected = true;
                        selectedTabIndex = i;
                    } else if (i === 0) {
                        displayTab.selected = true;
                        selectedTabIndex = i;
                    } else {
                        displayTab.selected = false;
                    }

                    if (customTranslationObj && customTranslationObj[displayTab.code]) {
                        displayTab.translation = customTranslationObj[displayTab.code];
                    } else if (displayTab.code !== displayTab.actualCode) {
                        displayTab.translation = displayTab.actualCode;
                    }

                    // displayTab.svgData = await window.SPL_Other.renderTemplateSVG(`${commonUtil.getDepositImgPath()}${displayTab.code}.svg`, `${displayTab.code}`);
                }

                let defaultTabCode = _displayTab[selectedTabIndex] && _displayTab[selectedTabIndex].actualCode;

                filteredDepositApiJson = {
                    channel: gu_getNestedValue(merchantBanksByOptions, `${defaultTabCode}.bank`) || merchantBanksByOptions[defaultTabCode],
                    optionCode: gu_getNestedValue(_displayTab, `${selectedTabIndex}.code`),
                };

                // init hideAddBankAccount by 1st tab code
                if (depositAllSettings) {
                    depositAllSettings.hideAddBankAccount = trans_massageHideAddBank(controller.vm, 'deposit', defaultTabCode);
                }
            } else {
                let errMsg = window.SPL_Translation.getTranslatedErrorMsg({ message: 'Contact Customer Service to setup Deposit' }, 'deposit');
                notification.showNotification('error', errMsg);
            }

            window.SPL_Content.getBankName(_displayTab, 'option');
            controller.vm.setState({
                depositAllSettings: depositAllSettings,
                filteredDepositApiJson: filteredDepositApiJson,
                depositApiJson: merchantBanksByOptions,
                displayTab: _displayTab,
                selectedTabIndex: selectedTabIndex,
                paymentCategories: paymentCategories,
                selectedPaymentCategory: selectedPaymentCategory,
            });
            core.portalIsLoading(controller.vm.props, false);
        });
    },

    initPaymentGatewaySetting() {
        window.SPL_JsonSettings.getPaymentGatewaySetting((paymentGatewaySetting) => {
            controller.vm.setState({ paymentGatewaySetting });
        });
    },

    loadDefaultTab() {
        let currency = controller.vm.props.user.account.currency;
        window.SPL_JsonSettings.getDepositJson(function (settings) {
            if (settings && settings['defaultDepositMethod']) {
                if (settings['defaultDepositMethod'][currency] && settings['defaultDepositMethod'][currency] !== '') {
                    controller.vm.setState({ defaultTab: settings['defaultDepositMethod'][currency] });
                }
            }
        });
    },

    toggleTab(i, tab) {
        const { depositApiJson } = controller.vm.state;
        let depositAllSettings = controller.vm.state.depositAllSettings;

        controller.vm.setState({ isSubLoading: true }, () => {
            let filteredDepositApiJson = null;
            // depositApiJson only got value when enableJsonApi is found
            if (depositApiJson && tab.code) {
                filteredDepositApiJson = {
                    channel: gu_getNestedValue(depositApiJson, `${tab.actualCode}.bank`) || depositApiJson[tab.actualCode],
                    optionCode: tab.code,
                };
            }

            controller.vm.setState(
                {
                    isSubLoading: false,
                    selectedTabIndex: i,
                    filteredDepositApiJson: filteredDepositApiJson,
                    depositAllSettings,
                },
                () => {
                    controller.highlightTab(tab);
                    controller.processHideAddBankByMemberBanks();
                }
            );
        });
    },

    onPaymentCategoryChange(category) {
        let depositAllSettings = controller.vm.state.depositAllSettings;
        let depositTabs = controller.depositTabs;
        let selectedPaymentCategory = category;
        let merchantBanksByOptions = selectedPaymentCategory.paymentOption;
        let displayTab = [];
        let optionCode = null;
        let actualCode = null;

        displayTab = controller.getFilterDisplayTabs(depositTabs, merchantBanksByOptions);
        displayTab = controller.customTabArrangement(displayTab, depositAllSettings);
        window.SPL_Content.getBankName(displayTab, 'option');

        for (let i = 0; i < displayTab.length; i++) {
            let tab = displayTab[i];
            if (i === 0) {
                tab.selected = true;
                optionCode = tab.code;
                actualCode = tab.actualCode;
            } else {
                tab.selected = false;
            }
        }

        let filteredDepositApiJson = {
            channel: gu_getNestedValue(merchantBanksByOptions, `${actualCode}.bank`) || merchantBanksByOptions[actualCode],
            optionCode: optionCode,
        };

        controller.vm.setState({ displayTab, filteredDepositApiJson, selectedPaymentCategory, depositAllSettings, depositApiJson: merchantBanksByOptions }, () => {
            controller.processHideAddBankByMemberBanks();
        });
    },

    highlightTab(tab = null) {
        const { displayTab } = controller.vm.state;
        for (let i in displayTab) {
            if (displayTab[i].actualCode === tab.actualCode) {
                displayTab[i].selected = true;
            } else {
                displayTab[i].selected = false;
            }
        }
    },

    getTabContent() {
        const { displayTab } = controller.vm.state;
        let tab = displayTab.filter((e) => e.selected === true)[0];
        return tab && tab.component ? tab.component : null;
    },

    loadDepositOptions() {
        window.SPL_Transaction.loadDepositOptions().then((data) => {
            controller.vm.setState({ depositAmountOptions: data });
        });
    },

    getMemberDepositWithdrawDecimalSettings() {
        window.SPL_Member.getMemberDepositWithdrawDecimalSettings().then((data) => {
            if (data) {
                controller.vm.setState({ memberDecimalSettings: data?.deposit || {} });
            }
        });
    },

    getFilterDisplayTabs(depositTabs, depositOptions) {
        let depositOptionCodes = depositOptions && typeof depositOptions === 'object' ? Object.keys(depositOptions) : [];
        let newOrderDisplayTab = [];
        for (let i = 0; i < depositOptionCodes.length; i++) {
            let code = depositOptionCodes[i];
            if (gu_getNestedValue(depositOptions, `${code}.type`)) {
                code = depositOptions[code]['type'];
            }
            let foundTab = depositTabs.find((tab) => tab.code === code);

            if (foundTab) {
                let params = {
                    code: foundTab.code,
                    actualCode: depositOptionCodes[i],
                    component: foundTab.component,
                    key: foundTab.key,
                    translation: depositOptionCodes[i] !== foundTab.code ? depositOptionCodes[i] : foundTab.translation,
                    selected: foundTab.selected,
                };
                newOrderDisplayTab.push(params);
            }
        }
        return newOrderDisplayTab;
    },

    customTabArrangement(displayTab, depositSettings) {
        const { features } = controller.vm.props.portal.settings;
        if (depositSettings.depositTabOrdering && depositSettings.depositTabOrdering.length > 0) {
            let orderedMenuList = [];
            let nonOrderList = displayTab.filter(function (el) {
                return depositSettings.depositTabOrdering.indexOf(el.id) < 0;
            });

            for (let i = 0; i < depositSettings.depositTabOrdering.length; i++) {
                let menu = displayTab.filter(function (e) {
                    return e.key === depositSettings.depositTabOrdering[i];
                })[0];
                if (menu !== undefined && menu !== null) {
                    orderedMenuList.push(menu);
                }
            }

            if (features && features.swapDepositOrderTab) {
                displayTab = nonOrderList.concat(orderedMenuList);
            } else {
                displayTab = orderedMenuList.concat(nonOrderList);
            }
        }
        return displayTab;
    },

    // Limit Registered Bank For Member
    processHideAddBankByMemberBanks() {
        // window.SPL_Transaction.loadAllOwnedAndUnOwnedBank(controller.vm.props).then((data) => {
        //     let { depositSettings, filteredDepositApiJson } = controller.vm.state;
        //     let memberBankList = data.memberBankList;
        //     let optionCode = filteredDepositApiJson && filteredDepositApiJson.optionCode ? filteredDepositApiJson.optionCode : null;
        //     depositSettings.hideAddBankAccount = trans_massageHideAddBank(controller.vm, 'deposit', optionCode, memberBankList);
        //     controller.vm.setState({ depositSettings });
        // });

        let { depositAllSettings, filteredDepositApiJson } = controller.vm.state;
        let optionCode = filteredDepositApiJson && filteredDepositApiJson.optionCode ? filteredDepositApiJson.optionCode : null;
        depositAllSettings.hideAddBankAccount = trans_massageHideAddBank(controller.vm, 'deposit', optionCode);

        controller.vm.setState({ depositAllSettings });
    },
};

export default controller;
