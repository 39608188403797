import moment from 'moment';
import { floatingAction } from 'root/redux/action';

const controller = {
    vm: null,

    init(vm) {
        this.vm = vm;
        this.vm.state = {
            beforeLoginNotification: [],
            afterLoginNotification: [],
            afterLoginAPIrunCheck: false,
            floatingNotificationList: [],
            actual: [],
            notificationType: null,
            currentNotificationIndex: 0,
            testing: 'a',
            closedFloatingNotification: [],
            count: 0,
        };
    },

    initFloatingNotificationData(isDidmount) {
        const { user } = controller.vm.props;

        if (user.isLogin) {
            controller.getAfterLoginFloatingNotification(isDidmount);
        } else {
            controller.getBeforeLoginFloatingNotification(isDidmount);
        }
    },

    getAfterLoginFloatingNotification(isDidmount) {
        const { language, screen, user } = controller.vm.props;
        const { afterLoginNotification } = controller.vm.props.floatingNotification;
        const { afterLoginAPIrunCheck } = controller.vm.state;

        let contentType = screen.viewType;

        if (user.isLogin && user.account && !afterLoginAPIrunCheck) {
            window.SPL_Member.getAfterLoginFloatingNotificationData({
                language: language.countryLanguageKey,
                contentType: contentType.toUpperCase(),
                merchantCode: window.merchantCode,
                memberId: user.account.id,
            }).then((data) => {
                let afterLoginData = [];
                if (data && data.data) {
                    let afterLogin = data.data;
                    if (afterLogin.length > 0) {
                        afterLoginData = afterLogin;
                        controller.vm.props.dispatch(floatingAction.getAfterLoginNotification(afterLoginData));
                        controller.vm.setState({ afterLoginNotification: afterLoginData, afterLoginAPIrunCheck: true }, () => {
                            controller.getNotificationByTimer(isDidmount);
                            controller.addBackNotificationThatAlwaysShow();
                        });
                    }
                }
            });
        } else {
            controller.vm.setState({ afterLoginNotification }, () => {
                controller.getNotificationByTimer(isDidmount);
                controller.addBackNotificationThatAlwaysShow();
            });
        }
    },

    getBeforeLoginFloatingNotification(isDidmount) {
        const { user } = controller.vm.props;
        const { beforeLoginNotification } = controller.vm.props.floatingNotification;

        if (!user.isLogin && beforeLoginNotification && beforeLoginNotification.length < 1) {
            window.SPL_Content.getBeforeLoginFloatingNotificationData().then((data) => {
                if (data && data.length > 0) {
                    controller.vm.props.dispatch(floatingAction.getBeforeLoginNotification(data));
                    controller.vm.setState({ beforeLoginNotification: data }, () => {
                        controller.getNotificationByTimer(isDidmount);
                        controller.addBackNotificationThatAlwaysShow();
                    });
                }
            });
        } else {
            controller.vm.setState({ beforeLoginNotification }, () => {
                controller.getNotificationByTimer(isDidmount);
                controller.addBackNotificationThatAlwaysShow();
            });
        }
    },

    getNotificationByTimer(isDidmount) {
        const { beforeLoginNotification, afterLoginNotification } = controller.vm.state;
        const { location, user } = controller.vm.props;

        let getTheNotification = beforeLoginNotification;
        if (user.isLogin) {
            getTheNotification = afterLoginNotification;
        }

        let notificationType = null;
        let now = moment(Date.now());
        let start = '';
        let end = '';
        let currentRoute = location.pathname && location.pathname === '/' ? '/home' : location.pathname;
        let displayFrequencyType = '';
        let displayFrequency = '';

        for (let a = 0; a < getTheNotification.length; a++) {
            notificationType = getTheNotification[a].displayNotification;
            start = moment(getTheNotification[a].startDateTime);
            end = moment(getTheNotification[a].endDateTime);
            displayFrequencyType = getTheNotification[a].displayFrequencyType;
            displayFrequency = getTheNotification[a].displayFrequency;

            let dateDiff = end.diff(now, 'days');
            if (dateDiff > 23) {
                end = moment(now).add(23, 'd');
            }

            let dateDiff2 = start.diff(now, 'days');
            if (dateDiff2 > 23) {
                start = moment(now).add(23, 'd');
            }

            if (displayFrequencyType && displayFrequencyType !== 'ONCE' && displayFrequencyType !== 'ALWAYS' && displayFrequency) {
                if (displayFrequencyType === 'DAY') {
                    displayFrequencyType = 'd';
                } else if (displayFrequencyType === 'HOUR') {
                    displayFrequencyType = 'h';
                } else if (displayFrequencyType === 'WEEK') {
                    displayFrequencyType = 'w';
                }

                let renewalDate = moment(getTheNotification[a].startDateTime).add(displayFrequency, displayFrequencyType);
                while (now === renewalDate || now > renewalDate) {
                    renewalDate = renewalDate.add(displayFrequency, displayFrequencyType);
                }
                controller.getClosedNotificationData(getTheNotification[a].id, renewalDate, start, displayFrequency, displayFrequencyType);
            }

            let displayPage = getTheNotification[a].displayPage === '/' ? '/home' : getTheNotification[a].displayPage;
            if (currentRoute.includes(displayPage)) {
                let nowToStart = now - start;
                let nowToEnd = now - end;
                if (nowToStart > 0 && nowToEnd < 30000) {
                    controller.addOneNotification(getTheNotification[a]);

                    let endToNow = end - now;

                    setTimeout(() => {
                        this.removeOneNotification(getTheNotification[a]);
                    }, endToNow);
                } else if (nowToStart > -30000 && nowToEnd < 0) {
                    let startToNow = start - now;
                    let endToNow = end - now;

                    setTimeout(() => {
                        controller.addOneNotification(getTheNotification[a]);
                    }, startToNow);

                    setTimeout(() => {
                        controller.removeOneNotification(getTheNotification[a]);
                    }, endToNow);
                }

                controller.vm.setState({ notificationType });
            } else {
                controller.removeOneNotification(getTheNotification[a], 'CHANGE_ROUTE');
            }
        }

        let closedFloatingNotification = JSON.parse(window.SPL_Cookie.getCookie('closedFloatingNotification'));
        if (closedFloatingNotification !== null || closedFloatingNotification !== '') {
            controller.vm.setState({ closedFloatingNotification });
            if (isDidmount) {
                controller.vm.props.dispatch(floatingAction.getCloseNotification(closedFloatingNotification));
            }
        }
    },

    addBackNotificationThatAlwaysShow() {
        const { beforeLoginNotification, afterLoginNotification, count } = controller.vm.state;
        const { user } = controller.vm.props;

        let getTheNotification = beforeLoginNotification;
        if (user.isLogin) {
            getTheNotification = afterLoginNotification;
        }

        let closedFloatingNotification = JSON.parse(window.SPL_Cookie.getCookie('closedFloatingNotification'));
        if (closedFloatingNotification === null || closedFloatingNotification === '') {
            closedFloatingNotification = [];
        }
        if (closedFloatingNotification.length > 0 && count === 0) {
            for (let i = 0; i < getTheNotification.length; i++) {
                let checkCookieID = closedFloatingNotification.findIndex((a) => a.id === getTheNotification[i].id);
                if (checkCookieID > -1 && getTheNotification[i].displayFrequencyType === 'ALWAYS') {
                    let newData = {
                        id: getTheNotification[i].id,
                        type: 'ON_GOING',
                        floatingNotificationId: [
                            {
                                id: 'temp',
                                floatingNotificationContents: [
                                    {
                                        id: 'temp',
                                    },
                                ],
                            },
                        ],
                    };

                    closedFloatingNotification.splice(checkCookieID, 1);
                    closedFloatingNotification.push(newData);
                    window.SPL_Cookie.setCookie('closedFloatingNotification', JSON.stringify(closedFloatingNotification), 365);
                }
            }
        }

        controller.vm.setState({ count: 999 });
    },

    notificationTypeCheck() {
        const { notificationType, oneByOneNotification } = controller.vm.state;
        let getIndex = controller.vm.state.currentNotificationIndex;

        if (notificationType === 'ONE_BY_ONE' && oneByOneNotification && oneByOneNotification.length > 0) {
            let tempNotification = [];
            if (oneByOneNotification[getIndex]) {
                tempNotification.push(oneByOneNotification[getIndex]);
            }
            controller.vm.setState({ floatingNotificationList: tempNotification });
        }
    },

    closeNotification(notification, floatingNotificationSettingId, interactionType) {
        const { user } = controller.vm.props;

        let closedFloatingNotification = JSON.parse(window.SPL_Cookie.getCookie('closedFloatingNotification'));
        if (closedFloatingNotification === null || closedFloatingNotification === '') {
            closedFloatingNotification = [];
        }

        let floatingNotificationSettingIdArr = {
            id: floatingNotificationSettingId,
            type: 'ON_GOING',
            floatingNotificationId: [
                {
                    id: notification.floatingNotificationId,
                    floatingNotificationContents: [
                        {
                            id: notification.id,
                        },
                    ],
                },
            ],
        };

        let floatingNotificationId = {
            id: notification.floatingNotificationId,
            floatingNotificationContents: [
                {
                    id: notification.id,
                },
            ],
        };

        if (closedFloatingNotification.length === 0) {
            closedFloatingNotification.push(floatingNotificationSettingIdArr);
            window.SPL_Cookie.setCookie('closedFloatingNotification', JSON.stringify(closedFloatingNotification), 365);
        } else {
            let checkCookieID = closedFloatingNotification.findIndex((a) => a.id === floatingNotificationSettingId);
            if (checkCookieID < 0) {
                closedFloatingNotification.push(floatingNotificationSettingIdArr);
                window.SPL_Cookie.setCookie('closedFloatingNotification', JSON.stringify(closedFloatingNotification), 365);
            } else {
                for (let i = 0; i < closedFloatingNotification.length; i++) {
                    if (closedFloatingNotification[i].type !== 'WHOLE_ARRAY') {
                        let notif = closedFloatingNotification[i]['floatingNotificationId'];
                        let checkNotificationID = notif.findIndex((a) => a.id === notification.floatingNotificationId);
                        if (checkNotificationID < 0 && closedFloatingNotification[i].id === floatingNotificationSettingId) {
                            closedFloatingNotification[i]['floatingNotificationId'].push(floatingNotificationId);
                            window.SPL_Cookie.setCookie('closedFloatingNotification', JSON.stringify(closedFloatingNotification), 365);
                        } else {
                            if (closedFloatingNotification[i].id === floatingNotificationSettingId) {
                                for (let j = 0; j < notif.length; j++) {
                                    let content = notif[j]['floatingNotificationContents'];
                                    let checkContentID = content.findIndex((a) => a.id === notification.id);
                                    if (checkContentID < 0 && notif[j].id === notification.floatingNotificationId) {
                                        closedFloatingNotification[i]['floatingNotificationId'][j]['floatingNotificationContents'].push({ id: notification.id });
                                        window.SPL_Cookie.setCookie('closedFloatingNotification', JSON.stringify(closedFloatingNotification), 365);
                                    }
                                    // let checkContentID2 = content.findIndex((a) => a.id === 'temp');
                                    // if (checkContentID2 > -1 && notif[j].id === notification.floatingNotificationId) {
                                    //     closedFloatingNotification[i]['floatingNotificationId'][j]['floatingNotificationContents'].splice(checkContentID2, 1);
                                    //     window.SPL_Cookie.setCookie('closedFloatingNotification', JSON.stringify(closedFloatingNotification), 365);
                                    // }
                                }
                            }
                        }
                    }
                }
            }
        }

        controller.vm.props.dispatch(floatingAction.getCloseNotification(closedFloatingNotification));
        if (interactionType !== 'claimed') {
            controller.floatingNotificationInteraction(user, notification, 'NON_INTEREST');
        }
    },

    removeOneNotification(obj, type) {
        const { floatingNotificationList } = controller.vm.state;
        let objWithIdIndex = floatingNotificationList.findIndex((a) => a.id === obj.id);

        let tempFloatArr = floatingNotificationList;
        if (objWithIdIndex > -1) {
            tempFloatArr.splice(objWithIdIndex, 1);

            let closedFloatingNotification = JSON.parse(window.SPL_Cookie.getCookie('closedFloatingNotification'));
            if (closedFloatingNotification === null || closedFloatingNotification === '') {
                closedFloatingNotification = [];
            }

            let arrayType = 'WHOLE_ARRAY';
            if (type === 'CHANGE_ROUTE') {
                arrayType = 'ON_GOING';
            }

            let wholeDeleteArray = {
                id: obj.id,
                type: arrayType,
                floatingNotificationId: [
                    {
                        id: 'temp',
                        floatingNotificationContents: [
                            {
                                id: 'temp',
                            },
                        ],
                    },
                ],
            };

            if (closedFloatingNotification.length === 0) {
                closedFloatingNotification.push(wholeDeleteArray);
                window.SPL_Cookie.setCookie('closedFloatingNotification', JSON.stringify(closedFloatingNotification), 365);
            } else {
                let checkCookieID = closedFloatingNotification.findIndex((a) => a.id === obj.id);
                if (checkCookieID > -1) {
                    for (let i = 0; i < closedFloatingNotification.length; i++) {
                        if (closedFloatingNotification[i].id === obj.id) {
                            closedFloatingNotification[i].type = arrayType;
                            window.SPL_Cookie.setCookie('closedFloatingNotification', JSON.stringify(closedFloatingNotification), 365);
                        }
                    }
                } else {
                    closedFloatingNotification.push(wholeDeleteArray);
                    window.SPL_Cookie.setCookie('closedFloatingNotification', JSON.stringify(closedFloatingNotification), 365);
                }
            }

            controller.vm.setState({ floatingNotificationList: tempFloatArr });
        }
    },

    addOneNotification(obj) {
        const { floatingNotificationList } = controller.vm.state;
        let objWithIdIndex = floatingNotificationList.findIndex((a) => a.id === obj.id);
        let tempFloatArr = floatingNotificationList;
        if (objWithIdIndex < 0) {
            tempFloatArr.push(obj);
            controller.vm.setState({ floatingNotificationList: tempFloatArr });
        }
    },

    filterNotificationContent(content, type, floatingNotificationSettingId) {
        const { language, screen, user } = controller.vm.props;
        const { closedFloatingNotification } = controller.vm.state;
        let contentType = screen.viewType;
        let filteredContent = [];
        if (content && content.length > 0) {
            // for (let i = 0; i < content.length; i++) {
            //     if (content[i].type === contentType.toUpperCase() && content[i].language === language.countryLanguageKey && (content[i].imagePath || content[i].content)) {
            //         if (floatingNotificationSettingId && closedFloatingNotification && closedFloatingNotification.length > 0) {
            //             for (let a = 0; a < closedFloatingNotification.length; a++) {
            //                 if (closedFloatingNotification[a].id === floatingNotificationSettingId) {
            //                     let notif = closedFloatingNotification[a]['floatingNotificationId'];
            //                     let checkNotifID = notif.findIndex((j) => j.id === content[i].floatingNotificationId);
            //                     if (checkNotifID > -1) {
            //                         for (let b = 0; b < notif.length; b++) {
            //                             if (notif[b].id === content[i].floatingNotificationId) {
            //                                 let notifContent = notif[b]['floatingNotificationContents'];
            //                                 let checkContentID = notifContent.findIndex((j) => j.id === content[i].id);
            //                                 if (checkContentID < 0) {
            //                                     filteredContent.push(content[i]);
            //                                 }
            //                             }
            //                         }
            //                     } else {
            //                         let checkNotifID = notif.findIndex((j) => j.id === content[i].floatingNotificationId);
            //                         if (checkNotifID < 0) {
            //                             filteredContent.push(content[i]);
            //                         }
            //                     }
            //                 } else {
            //                     let checkNotificationID = closedFloatingNotification.findIndex((a) => a.id === floatingNotificationSettingId);
            //                     if (checkNotificationID < 0) {
            //                         filteredContent.push(content[i]);
            //                     }
            //                 }
            //             }
            //         } else {
            //             filteredContent.push(content[i]);
            //         }
            //     }
            // }

            for (let i = 0; i < content.length; i++) {
                const currentContent = content[i];
                const isValidContent =
                    currentContent.type === contentType.toUpperCase() &&
                    currentContent.language === language.countryLanguageKey &&
                    (currentContent.imagePath || currentContent.content);

                if (!isValidContent) continue;

                if (!floatingNotificationSettingId || !closedFloatingNotification || closedFloatingNotification.length === 0) {
                    filteredContent.push(currentContent);
                    continue;
                }

                const closedNotification = closedFloatingNotification.find((notification) => notification.id === floatingNotificationSettingId);

                if (!closedNotification) {
                    filteredContent.push(currentContent);
                    continue;
                }

                const notificationIds = closedNotification.floatingNotificationId;
                const currentNotificationId = currentContent.floatingNotificationId;

                const isNotificationIdPresent = notificationIds.some((notificationId) => notificationId.id === currentNotificationId);

                if (!isNotificationIdPresent) {
                    filteredContent.push(currentContent);
                    continue;
                }

                const currentNotification = notificationIds.find((notificationId) => notificationId.id === currentNotificationId);

                if (!currentNotification) {
                    filteredContent.push(currentContent);
                    continue;
                }

                const isContentPresent = currentNotification.floatingNotificationContents.some((content) => content.id === currentContent.id);

                if (!isContentPresent) {
                    filteredContent.push(currentContent);
                }
            }
        }

        if (type === 'ONE_BY_ONE') {
            let onebyone_content = [];
            if (filteredContent && filteredContent.length > 0) {
                onebyone_content.push(filteredContent[0]);
            }
            return onebyone_content;
        } else {
            return filteredContent;
        }
    },

    notificationClick(data, floatingNotificationSettingId, displayFrequencyType) {
        const { language, user } = controller.vm.props;
        let pathLang = language.countryLanguageKey && language.countryLanguageKey.toLowerCase().replace('_', '-');

        if (data && data.redirectUrl && data.urlOpenIn) {
            if (data.urlOpenIn === 'CURRENT_PAGE') {
                let redirectLink = data.redirectUrl;
                let domainPath = window.location.protocol + '//' + window.location.hostname + '/' + pathLang;
                if (redirectLink.includes(domainPath)) {
                    redirectLink = redirectLink.replace(domainPath, '');
                }

                if (data.isAllowConversion && data.isAllowConversion === true) {
                    // controller.vm.props.history.push({ pathname: redirectLink, state: { notificationId: data.floatingNotificationId } });
                    controller.vm.props.history.push(redirectLink);
                    controller.vm.props.dispatch(floatingAction.storeFloatingNotificationId(data.floatingNotificationId));
                } else {
                    controller.vm.props.history.push(redirectLink);
                }
            } else {
                if (data.isAllowConversion && data.isAllowConversion === true) {
                    window.open(data.redirectUrl + '?floatingNotificationId=' + data.floatingNotificationId, '_blank');
                } else {
                    window.open(data.redirectUrl, '_blank');
                }
            }
        }

        controller.floatingNotificationInteraction(user, data, 'INTEREST');
        controller.closeNotification(data, floatingNotificationSettingId, 'claimed');
    },

    floatingNotificationInteraction(user, data, type) {
        if (user.isLogin && user.account) {
            let params = {
                memberId: user.account.id,
                floatingNotificationId: data.floatingNotificationId,
                applicationType: type,
                merchantCode: window.merchantCode,
            };

            window.SPL_Member.floatingNotificationInteraction(params);
        }
    },

    getClosedNotificationData(id, renewalDate, startDate, displayFrequency, displayFrequencyType) {
        let closedFloatingNotification = JSON.parse(window.SPL_Cookie.getCookie('closedFloatingNotification'));
        if (closedFloatingNotification === null || closedFloatingNotification === '') {
            closedFloatingNotification = [];
        }

        let addRenewalDate = {
            id: id,
            renewalDate: renewalDate,
            type: 'ON_GOING',
            floatingNotificationId: [
                {
                    id: 'temp',
                    floatingNotificationContents: [
                        {
                            id: 'temp',
                        },
                    ],
                },
            ],
        };
        if (closedFloatingNotification.length === 0) {
            closedFloatingNotification.push(addRenewalDate);
            window.SPL_Cookie.setCookie('closedFloatingNotification', JSON.stringify(closedFloatingNotification), 365);
        } else {
            let checkCookieID = closedFloatingNotification.findIndex((a) => a.id === id);
            if (checkCookieID > -1) {
                for (let i = 0; i < closedFloatingNotification.length; i++) {
                    if (closedFloatingNotification[i].id === id) {
                        let now = moment(Date.now());
                        let currentRenewal = closedFloatingNotification[i].renewalDate ? moment(closedFloatingNotification[i].renewalDate) : renewalDate;
                        if (now === currentRenewal || now > currentRenewal) {
                            closedFloatingNotification.splice(checkCookieID, 1);
                            let newRenewal = currentRenewal.add(displayFrequency, displayFrequencyType);
                            let addRenewalDate = {
                                id: id,
                                renewalDate: newRenewal,
                                type: 'ON_GOING',
                                floatingNotificationId: [
                                    {
                                        id: 'temp',
                                        floatingNotificationContents: [
                                            {
                                                id: 'temp',
                                            },
                                        ],
                                    },
                                ],
                            };
                            closedFloatingNotification.push(addRenewalDate);
                            window.SPL_Cookie.setCookie('closedFloatingNotification', JSON.stringify(closedFloatingNotification), 365);
                            // let endToNow = newRenewal - currentRenewal;
                            // setTimeout(() => {
                            //     controller.getClosedNotificationData(id, renewalDate, startDate, displayFrequency, displayFrequencyType);
                            // }, endToNow);
                        } else {
                            closedFloatingNotification[i].renewalDate = currentRenewal;
                            window.SPL_Cookie.setCookie('closedFloatingNotification', JSON.stringify(closedFloatingNotification), 365);
                            // let endToNow = currentRenewal - startDate;
                            // setTimeout(() => {
                            //     controller.getClosedNotificationData(id, renewalDate, startDate, displayFrequency, displayFrequencyType);
                            // }, endToNow);
                        }
                    }
                }
            } else {
                closedFloatingNotification.push(addRenewalDate);
                window.SPL_Cookie.setCookie('closedFloatingNotification', JSON.stringify(closedFloatingNotification), 365);
            }
        }
    },
};

export default controller;
