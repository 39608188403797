export default class EventEmitter {
    events = {};
    on(name, fn) {
        if (!this.events[name]) {
            this.events[name] = [];
        }
        this.events[name].push(fn);
        return this;
    }

    emit(name, ...args) {
        if (!this.events[name]) {
            return this;
        }
        const fns = this.events[name];
        //不能直接遍历fns ,期间如果有off行为 会出问题
        fns.map((fn) => fn).forEach((fn) => fn(...args));
        return this;
    }

    off(name, fn) {
        //fn  传空为解绑全部
        if (!this.events[name]) {
            return this;
        }
        if (!fn) {
            this.events[name] = null;
            return this;
        }
        const index = this.events[name].indexOf(fn);
        this.events[name].splice(index, 1);
        return this;
    }
    // 单次绑定事件,执行完后解绑
    once(name, fn) {
        const only = (...args) => {
            fn(args);
            this.off(name, only);
        };
        this.on(name, only);
        return this;
    }
}
