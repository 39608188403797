import BankTransfer from './deposit/sub-component/bank-transfer/bank-transfer.selector';
import Crypto from './deposit/sub-component/crypto/crypto.selector';
import QuickPay from './deposit/sub-component/quick-pay/quick-pay.selector';
import QrCodePay from './deposit/sub-component/qrcode-pay/qrcode-pay.selector';
import Reload from './deposit/sub-component/reload/reload.selector';
import BankApps from './deposit/sub-component/bank-apps/bank-apps.selector';
import ExpressDeposit from './deposit/sub-component/express-deposit/express-deposit.selector';
import P2PDeposit from './deposit/sub-component/p2p-deposit/p2p-deposit.selector';
import MobileCardDeposit from './deposit/sub-component/mobile-card-deposit/mobile-card-deposit.selector';
import BankQRDeposit from './deposit/sub-component/bank-qr-deposit/bank-qr-deposit.selector';
import EwalletApps from './deposit/sub-component/ewallet-apps/ewallet-apps.selector';
import RegionQR from './deposit/sub-component/region-qr/region-qr.selector';

const SettingsData = {
    depositTabs: [
        {
            code: 'B',
            key: 'bank', //online-transfer
            translation: 'transaction:transaction.deposit.cashdeposit.banktransfer',
            selected: false,
            component: BankTransfer,
        },
        {
            code: 'RQR',
            key: 'regionqr', //Region QR
            translation: 'transaction:transaction.deposit.regionQR.regionQR',
            selected: false,
            component: RegionQR,
        },
        {
            code: 'QP',
            key: 'quickpay',
            translation: 'settings:settings.deposittab.quickPay',
            selected: false,
            component: QuickPay,
        },
        {
            code: 'CT',
            key: 'crypto',
            translation: 'transaction:transaction.deposit.cashdeposit.crypto',
            selected: false,
            component: Crypto,
        },
        {
            code: 'QR',
            key: 'qrpay',
            translation: 'transaction:transaction.deposit.cashdeposit.qrpay',
            selected: false,
            component: QrCodePay,
        },
        {
            code: 'R',
            key: 'reload',
            translation: 'transaction:transaction.deposit.reload.title',
            selected: false,
            component: Reload,
        },
        {
            code: 'BA',
            key: 'bankApps',
            translation: 'transaction:transaction.deposit.bankApps.tab',
            selected: false,
            hideshowFlag: 'bankAppsEnabled',
            component: BankApps,
        },
        {
            code: 'E',
            key: 'expressDeposit',
            translation: 'transaction:transaction.deposit.expressDeposit.expressDeposit',
            selected: false,
            component: ExpressDeposit,
        },
        {
            code: 'P2P',
            key: 'p2p',
            translation: 'transaction:transaction.deposit.p2p.p2p',
            selected: false,
            component: P2PDeposit,
        },
        {
            code: 'MC',
            key: 'mobileCard',
            translation: 'transaction:transaction.deposit.mobileCard.mobileCardTitle',
            selected: false,
            component: MobileCardDeposit,
        },
        {
            code: 'BQR',
            key: 'bankqr',
            translation: 'transaction:transaction.deposit.bankqr.bankqrTitle',
            selected: false,
            component: BankQRDeposit,
        },
        {
            code: 'EA',
            key: 'ewallet',
            translation: 'transaction:transaction.deposit.cashdeposit.ewallet',
            selected: false,
            component: EwalletApps,
        },
    ],
};

export default SettingsData;
