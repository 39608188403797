import commonUtil from '../../../../utils/common-util';

const controller = {
    vm: null,

    init(vm) {
        this.vm = vm;
        this.vm.state = {
            fields: {
                email: '',
                phone: '',
                username: '',
                fullname: '',
                dob: '',
                currency: '',
                bankDetails: null,
                bankDetailsList: [],
            },
            isOpenEditDOBPopup: false,
            verificationStatus: {
                birthdayVerified: true,
            },
        };
    },

    populateMemberInfo() {
        let fields = controller.vm.state;
        const { account } = controller.vm.props.user;

        if (account.phone) {
            fields.phone = window.SPL_Other.censored(account.phone, 'phone');
        }
        if (account.email) {
            fields.email = window.SPL_Other.censored(account.email, 'email');
        }
        fields.username = account.login;
        fields.fullname = account.name;
        fields.dob = account.dob;
        fields.currency = account.currency;

        if (fields.currency === 'KHUSD') fields.currency = 'USD';

        controller.vm.setState({ fields });
    },

    loadBankAccounts() {
        window.SPL_Member.loadBankAccounts().then(async (res) => {
            if (res.length > 0) {
                let fields = controller.vm.state;
                fields.bankDetails = res[0];
                fields.bankDetailsList = res;

                for (const bank of fields.bankDetailsList) {
                    bank.svgData = await window.SPL_Other.renderTemplateSVG(`${commonUtil.getDepositImgPath()}${bank.code}.svg`, `${bank.code}`);
                }

                controller.vm.setState({ fields });
            }
        });
    },

    togglePopup(flag) {
        controller.vm.setState({ isOpenEditDOBPopup: flag });
    },

    updateFieldWithPopupData(type, value) {
        const vm = controller.vm;
        const { fields } = vm.state;
        fields[type] = value;

        vm.setState({ fields });
    },

    loadContactsVerification() {
        // why bother renaming the response key = =
        window.SPL_Member.loadContactsVerification().then((res) => {
            if (res) controller.vm.setState({ verificationStatus: res });
        });
    },
};

export default controller;
