import slotVm from './slot.controller';
import { TweenMax } from 'gsap';
import { gu_getNestedValue } from 'root/utils/general-util';
import providerService from 'root/utils/providerService';
import notification from 'root/utils/notification';
import { portalAction } from 'root/redux/action';
import { PROVIDER_DATA } from 'root/utils/constants';
import { pu_processGameDataWithFavGames } from '@utils/provider-util';

const controller = {
    vm: null,

    init(vm) {
        this.vm = vm;
        this.vm.state = {
            currentProvider: '',
            currentProviderName: '',
            currentProviderKey: '',
            tabFilter: 'HTML5',
            keyword: '',
            brandActive: 0,
            typeActive: 0,
            typeList: [],
            brandList: [],
            gameList: [],
            hotGamesSlider: [],
            favoritedGame: [],
            tween1: null,
            tween2: null,
            isSearching: false,
            subCurrentProvider: '',
            subCurrentProviderName: '',
            subCurrentProviderKey: '',
        };
    },

    updateSelectedProvider(providerObj) {
        const { keyword, tabFilter } = controller.vm.state;
        const { history } = controller.vm.props;

        switch (providerObj.provider) {
            case 'MEGA':
            case 'SCR2':
            case 'PSY8':
                history.push('/' + providerObj.routeName);
                break;
            default:
                if (providerObj.route) {
                    history.push(providerObj.route);
                } else {
                    history.push('/slots/' + providerObj.routeName);
                }
                break;
        }

        controller.vm.setState(
            { subCurrentProvider: providerObj.provider, subCurrentProviderKey: providerObj.key, showMore: false, subCurrentProviderName: providerObj.text },
            () => {
                controller.getProviderGameList(providerObj.provider, tabFilter, keyword);
            }
        );
    },

    getMobileProviderList() {
        return new Promise(function (resolve) {
            const { language } = controller.vm.props;
            let currencyLang = language.currencyLang;

            if (language.country === 'KH') currencyLang = 'KHUSD';

            let loadGetSubmenu = new Promise((resolve) => {
                window.SPL_Content.getSubmenu(language.countryLanguageKey, 'mobile', 'SL').then((data) => resolve(data));
            });

            let loadProviderGameMaintenanceList = new Promise((resolve) => {
                window.SPL_Provider.providerGameMaintenanceList(null, null, currencyLang).then((result) => resolve(result));
            });

            Promise.all([loadGetSubmenu, loadProviderGameMaintenanceList]).then((loadResult) => {
                let submenu = loadResult[0];
                let providerMaintenance = loadResult[1];

                let brandList = [];

                for (let i = 0; i < submenu.length; i++) {
                    let provider = submenu[i].provider;
                    submenu[i]['text'] = submenu[i].content;
                    submenu[i]['key'] = provider.toLowerCase();

                    if (providerMaintenance) {
                        for (let j = 0; j < providerMaintenance.length; j++) {
                            let maintenanceProvider = providerMaintenance[j].provider;
                            if (provider === maintenanceProvider) {
                                submenu[i]['underMaintenance'] = providerMaintenance[j].gameMaintenance;
                            }
                        }
                    }
                    brandList.push(submenu[i]);
                }

                controller.vm.setState({ brandList: brandList }, () => {
                    resolve();
                });
            });
        });
    },

    populateFromSlotLanding(tween1, tween2) {
        return new Promise((resolve) => {
            if (slotVm) {
                const pathname = window.location.pathname;
                const pathArray = pathname.split('/');

                let currentProvider = gu_getNestedValue(slotVm, 'vm.state.currentProvider') || '';
                let currentProviderName = gu_getNestedValue(slotVm, 'vm.state.currentProviderName') || '';
                let currentProviderKey = gu_getNestedValue(slotVm, 'vm.state.currentProviderKey') || '';

                let subCurrentProvider = gu_getNestedValue(slotVm, 'vm.state.currentProvider') || '';
                let subCurrentProviderName = gu_getNestedValue(slotVm, 'vm.state.currentProviderName') || '';
                let subCurrentProviderKey = gu_getNestedValue(slotVm, 'vm.state.currentProviderKey') || '';

                for (const filterKey of Object.values(PROVIDER_DATA)) {
                    const filterRouteName = filterKey.routeName;

                    if (filterRouteName && pathArray.includes(filterRouteName)) {
                        currentProvider = filterKey.provider;
                        currentProviderKey = filterKey.key;
                        currentProviderName = filterKey.text;
                        subCurrentProvider = filterKey.provider;
                        subCurrentProviderKey = filterKey.key;
                        subCurrentProviderName = filterKey.text;
                        break; // Exit the loop early once a match is found
                    }
                }

                controller.vm.setState(
                    {
                        currentProvider: currentProvider,
                        currentProviderKey: currentProviderKey,
                        currentProviderName: currentProviderName,
                        subCurrentProvider: subCurrentProvider,
                        subCurrentProviderKey: subCurrentProviderKey,
                        subCurrentProviderName: subCurrentProviderName,
                        tween1: tween1,
                        tween2: tween2,
                    },
                    () => {
                        resolve();
                    }
                );
            } else {
                resolve();
            }
        });
    },

    getDefaultTab() {
        const { currentProvider } = controller.vm.state;
        // all WhiteLabel required set NS default tab as hot
        if (currentProvider === 'NS') {
            return 'HOT';
        } else {
            return 'HTML5';
        }
    },

    updateTabFilter(type) {
        controller.vm.setState({ tabFilter: type }, () => {
            const { currentProvider, tabFilter, keyword } = controller.vm.state;
            controller.getProviderGameList(currentProvider, tabFilter, keyword);
        });
    },

    resetProviderFilter() {
        controller.vm.setState({
            tabFilter: 'HTML5',
            keyword: '',
            typeActive: 0,
            typeList: [],
            gameList: [],
        });
    },

    getProviderGameList(provider, tabFilter = 'HTML5', keyword = '', tween1, tween2) {
        const { screen, language, portal } = controller.vm.props;

        controller.vm.setState({ gameList: [] });
        let passProvider = provider;
        // switch (provider) {
        //   case 'PT2':
        //     passProvider = 'PT';
        //     break;
        //   default:
        //     break;
        // }

        return new Promise(async (resolve) => {
            let data = await window.SPL_Content.getAllSlotGames(
                screen.viewType,
                true,
                passProvider,
                tabFilter,
                keyword,
                portal &&
                    portal.settings &&
                    portal.settings.features &&
                    portal.settings.features.providerPageSettings &&
                    portal.settings.features.providerPageSettings.hotNewUseJson
            );
            for (let i = 0; i < data.displayGames.length; i++) {
                data.displayGames[i].imggame = `/public/html/games/images/s1/${provider}/${data.displayGames[i].game}.jpg`;
                data.displayGames[i].text = language.key === 'zh-CN' ? data.displayGames[i].nameCN : data.displayGames[i].name;
                data.displayGames[i].isFav = false;
            }

            for (let k = 0; k < data.slotCategory.length; k++) {
                switch (data.slotCategory[k]) {
                    case 'HTML5':
                        data.slotCategory[k] = 'ALL';
                        break;
                    default:
                        break;
                }
            }

            controller.vm.setState({ typeList: data.slotCategory }, () => {
                controller.getMemberFavoriteGame(data.displayGames, tween1, tween2);
                resolve();
            });
        });
    },

    getMemberFavoriteGame(gameList, tween1, tween2) {
        const _favoriteGames = controller.vm.props.providerReducer?.processedData?.favoriteGames || [];
        const newGameList = pu_processGameDataWithFavGames(gameList, _favoriteGames);
        controller.vm.setState({ gameList: newGameList, loading: false }, () => {
            controller.doSuspendAni(tween1, tween2);
        });
    },

    updateMemberFavoriteGame(game) {
        controller.vm.setState({ loading: true }, () => {
            const { screen } = controller.vm.props;
            const { isLogin } = controller.vm.props.user;
            const { favoritedGame, tween1, tween2 } = controller.vm.state;
            let isMobile = null;

            if (screen.viewType === 'web') {
                isMobile = false;
            } else {
                isMobile = true;
            }

            if (isLogin) {
                let delelteGame = false;

                for (let i = 0; i < favoritedGame.length; i++) {
                    if (favoritedGame[i].id === game.id) {
                        delelteGame = true;
                    }
                }

                if (delelteGame === true) {
                    window.SPL_Member.deleteMemberFavoriteGame(game, isMobile).then((data) => {
                        controller.getProviderGameList(game.provider, undefined, undefined, tween1, tween2);
                    });
                } else {
                    window.SPL_Member.updateMemberFavoriteGame(game, isMobile).then((data) => {
                        controller.getProviderGameList(game.provider, undefined, undefined, tween1, tween2);
                    });
                }
            } else {
                if (isMobile) {
                    controller.vm.props.dispatch(portalAction.floatingPageUpdated('login'));
                } else {
                    notification.showNotification('error', controller.vm.props.t('home:home.loginText'));
                }

                controller.vm.setState({ loading: false });
            }
        });
    },

    doSuspendAni(tween1, tween2) {
        tween1 && tween1.kill();
        tween2 && tween2.kill();

        TweenMax.set('ul.game-content li', {
            alpha: 0,
        });

        tween1 = TweenMax.delayedCall(0.3, function () {
            tween2 = TweenMax.staggerTo('ul.game-content li', 0.5, { alpha: 1 });
        });
    },

    handleKeywordChanged(e) {
        controller.vm.setState({ keyword: e.target.value }, () => {
            const { currentProvider, tabFilter, keyword } = controller.vm.state;
            controller.getProviderGameList(currentProvider, tabFilter, keyword);
        });
    },

    launchSlotGame(gameObj, isFun) {
        if (gameObj) {
            gameObj.category = 'SL';
        }

        const { language, screen, user } = controller.vm.props;
        let params = {
            domain: window.location.hostname,
            isMobile: screen.isMobile,
            isFun: isFun,
            isMenuMode: !isFun,
            isMiniGame: false,
            languageKey: language.key,
        };

        if (controller.vm.state.provider === 'UL' || gameObj.provider === 'UL') {
            params.gameCode = 'S-UL01';
            params.provider = 'UL';
        } else {
            params.gameCode = gameObj.game;
            params.provider = gameObj.provider;
        }

        let isMobile = false;
        if (screen.viewType === 'mobile') {
            isMobile = true;
        }

        providerService.launchGame(gameObj, language, user.isLogin, screen, isFun, params, controller.vm);
    },
};

export default controller;
