import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import core from 'root/utils/core';

import History from './component/desktop/history-landing';
//const MobileSettings = lazy(() => import('./mobile/Member'))

class HistorySelector extends React.Component {
    components = {
        desktop: {
            default: History,
        },
        mobile: {
            default: History,
        },
    };

    render() {
        const TagName = core.getCorrectComponent(this.components, 'history-landing', this.props.screen.viewType);
        return <TagName></TagName>;
    }
}

export default connect(mapStateToProps, null)(HistorySelector);
