import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Translation, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Slider from 'react-slick';
// components
import SVGElements from 'root/components/SVGElements';

class Ad extends React.Component {
    constructor(props) {
        super(props);
        this.sliderRef = React.createRef();
    }

    _getSelectorContentClassname(disabled, index) {
        const { screen, activeImage } = this.props;
        const { viewType } = screen;

        let selectorContentClassname = `selector-content ${disabled ? 'disabled' : 'pointer'}`;
        if (viewType === 'mobile') selectorContentClassname = `${index === activeImage ? 'selector-content-img-container-active' : 'selector-content-img-container'}`;
        return selectorContentClassname;
    }

    previous = () => {
        this.sliderRef.current.slickPrev();
    };

    next = () => {
        this.sliderRef.current.slickNext();
    };

    render() {
        const { videoUrl, videoSlideObjList, nameweeSelectorOnClick, activeImage, language, screen } = this.props;
        const { viewType } = screen;
        const langKey = language?.key?.toLowerCase();

        const mySGCondition = ['MY', 'SG', 'VN', 'TH', 'KH', 'PH'].includes(language?.country);

        const settings = {
            autoplay: false,
            dots: false,
            slidesToScroll: 1,
            slidesToShow: 5,
            swipeToSlide: true,
            arrows: false,
        };

        const renderBannerImage = () => <LazyLoadImage src={`/public/html/images/homepage/dota-banner-${langKey}.png`} className='dota-banner' />;

        const renderVideoFrame = () => (
            <iframe
                src={videoUrl}
                height='100%'
                width='100%'
                title='YouTube video player'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
            />
        );

        const renderMaintenanceMessage = (t) => <span className='maintenance-span'>{t('casino:casino.underMaintenance', 'Under Maintenance')}</span>;

        const renderSlider = (t) => (
            <div className='slider-container'>
                {videoSlideObjList?.length > 5 && (
                    <>
                        <div className='icon arrow-left' onClick={this.previous}>
                            <SVGElements className='icon-arrow-prev' name='left-arrow' />
                        </div>
                        <div className='icon arrow-right' onClick={this.next}>
                            <SVGElements className='icon-arrow-next' name='right-arrow' />
                        </div>
                    </>
                )}
                <Slider ref={this.sliderRef} {...settings}>
                    {videoSlideObjList?.length > 0 &&
                        videoSlideObjList.map((data, index) => {
                            const { disabled, videoSrc, imgSrc } = data;
                            return (
                                <div className='selector-container' key={index}>
                                    <div className={this._getSelectorContentClassname(disabled, index)} onClick={() => nameweeSelectorOnClick(videoSrc, index)}>
                                        <div className='img-container'>
                                            <LazyLoadImage src={imgSrc} className={`${activeImage === index ? 'active' : ''}`} />
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </Slider>
            </div>
        );

        return (
            <Translation>
                {(t) => (
                    <div className={`dota2-box ${mySGCondition ? 'custom-style' : ''}`}>
                        {!mySGCondition && renderBannerImage()}
                        {mySGCondition && (
                            <div className='container'>
                                <p className='item-title'>{t('home:home.ads.collaborate', 'me88 x Collaborating Partners')}</p>
                                <div className='mv-source'>
                                    <div className='top-container'>
                                        <div className={`iframe-container ${!videoUrl ? 'maintenance-container' : ''}`}>
                                            {videoUrl ? renderVideoFrame() : renderMaintenanceMessage(t)}
                                        </div>
                                        {viewType === 'web' && (
                                            <div className='side-banner'>
                                                <LazyLoadImage src={`/public/html/images/homepage/namewee-side-banner-${langKey}.png`} alt='' />
                                            </div>
                                        )}
                                    </div>
                                    {viewType === 'mobile' ? (
                                        <div className='selector-container'>
                                            <div className='selector-content'>
                                                {videoSlideObjList?.length > 0 &&
                                                    videoSlideObjList.map((data, index) => {
                                                        const { disabled, videoSrc, imgSrc } = data;
                                                        return (
                                                            <div
                                                                key={index}
                                                                className={this._getSelectorContentClassname(disabled, index)}
                                                                onClick={() => nameweeSelectorOnClick(videoSrc, index)}
                                                            >
                                                                <div className='img-container'>
                                                                    <LazyLoadImage src={imgSrc} className={`${activeImage === index ? 'active' : ''}`} />
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        </div>
                                    ) : (
                                        <div id='ads' style={{ display: 'block' }}>
                                            <div className='slider-title'>
                                                <div className='slider-container-wrapper'>{renderSlider(t)}</div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </Translation>
        );
    }
}

export default withTranslation(['home', 'casino'])(withRouter(Ad));
