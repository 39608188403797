const controller = {
    vm: null,

    init(vm) {
        this.vm = vm;
        this.vm.state = {
            disableSubmitBtn: false,
            selectedMessageIds: this.vm.props.selectedMessageIds,
        };
    },

    submitForm() {
        controller.vm.setState({ disableSubmitBtn: true });
        window.SPL_Member.deleteMessage(controller.vm.state.selectedMessageIds).then(() => {
            controller.vm.setState({ disableSubmitBtn: false }, () => {
                setTimeout(function () {
                    controller.vm.props.closeDeleteMessageDialog(controller.vm.props.parentvm, true);
                    controller.vm.props.getMemberMessageCount();
                }, 1000);
            });
        });
    },
};

export default controller;
