import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Slider from 'root/webapp/shared/component/Slider';

import vm from '../scr.controller';
import core from 'root/utils/core';

class Scr extends React.Component {
    tween1;
    tween2;

    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        vm.getScrGuide();
        vm.getScrUrl();
        vm.getGameLoginCredential();
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        return (
            <Translation>
                {(t) => (
                    <Fragment>
                        <div className='scr-page'>
                            <div className='scr-box-container'>
                                <div className='scr-info'>
                                    <p className='info-title'>
                                        {t('slot:slot.scr.title_1', 'ANDROID / IOS')}
                                        <br />
                                        {t('slot:slot.scr.title_2', 'Download Instruction')}
                                    </p>
                                    <span className='info-guide guide-pass'>
                                        {t('slot:slot.scr.desc_1', 'Login ID and password on the bottom and')}
                                        <br />
                                        {t('slot:slot.scr.desc_2', ' tutorial on the right side.')}
                                    </span>
                                    <span
                                        className='btn-download'
                                        onClick={() => {
                                            vm.downloadScrApp();
                                        }}
                                    >
                                        {t('global:global.sidebar.download.heading', 'DOWNLOAD')}
                                    </span>
                                    <div className='user-box box-id'>
                                        <span>{this.state.userObj.playerId ? this.state.userObj.playerId : t('global:global.form.username-placeholder', 'Username')}</span>
                                        <LazyLoadImage
                                            src='/public/html/images/slots/icon/copy.png'
                                            onClick={() => {
                                                core.copy(t, this.state.userObj.playerId);
                                            }}
                                        />
                                    </div>
                                    <div className='user-box box-pass'>
                                        <span>
                                            {this.state.userObj.playerPassword
                                                ? this.state.userObj.playerPassword
                                                : t('global:global.form.newpassword-placeholder', 'Password')}
                                        </span>
                                        <LazyLoadImage
                                            src='/public/html/images/slots/icon/copy.png'
                                            onClick={() => {
                                                core.copy(t, this.state.userObj.playerPassword);
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className='scr-img-guide'>
                                    {this.state.scrGuide.length > 0 && (
                                        <Slider autoPlay={false} items={this.state.scrGuide} navigation={this.state.navigation} transionName='slider-fade' />
                                    )}
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['slot', 'transaction', 'global'])(withRouter(Scr)));
