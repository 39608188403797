//text cannot be used when u wan specific string
//not support controller rendering, only support componenent rendering
//Eg: dont use in controller to call it

import React from 'react';
import { Trans } from 'react-i18next';
// import { useSelector } from 'react-redux';
// import { useTranslation } from 'react-i18next';
import { gu_filterCommonKey } from 'root/utils/general-util';

const Text = ({ textKey, components, values, children }) => {
    return (
        <Trans
            i18nKey={gu_filterCommonKey(textKey)}
            components={components}
            values={{
                merchantName: window.globMerchantName,
                merchant: window.globMerchantName,
                ...values,
            }}
        >
            {children ? children : textKey}
        </Trans>
    );
};

export default Text;
