import { walletConstant } from '../constant';

const initialState = {
    loadWallet: false,
};

export function wallet(state = initialState, action) {
    switch (action.type) {
        case walletConstant.UDPATE_LOAD_WALLET:
            return {
                ...state,
                loadWallet: action.payload.loadWallet,
            };

        case walletConstant.UPDATE_WALLET:
            return {
                ...state,
                mainWallet: action.payload.mainWallet,
            };

        case walletConstant.UPDATE_PROVIDER_WALLETS:
            return {
                ...state,
                providerWallets: action.payload.providerWallets,
            };

        default:
            return state;
    }
}
