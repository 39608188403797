import React from 'react';
import { Translation, withTranslation } from 'react-i18next';
import Slider from 'root/webapp/shared/component/Slider';
import SliderMobile from 'root/webapp/shared/component/Slider2';
class UPEvents extends React.Component {
    constructor() {
        super();
        this.state = {
            items: [],
        };
    }

    componentDidUpdate() {
        if (this.props.oddsFeedArray && this.props.oddsFeedArray.length !== this.state.items.length) {
            this.setState({ items: this.props.oddsFeedArray });
        }
    }

    getNavs() {
        return <i className='icon-arrow-left'></i>;
    }

    renderItem({ id, oddFeedTitle, home, away, startDate, sportsBannerPlayNow }) {
        const { t } = this.props;
        let matchTime = new Date(startDate);
        let date = matchTime.getDate() + '/' + (matchTime.getMonth() + 1) + '/' + matchTime.getFullYear();

        const hours = matchTime.getHours() < 10 ? `0${matchTime.getHours()}` : matchTime.getHours();
        const minutes = matchTime.getMinutes() < 10 ? `0${matchTime.getMinutes()}` : matchTime.getMinutes();
        let time = hours + ':' + minutes;
        return (
            <div key={id} className='item-match'>
                <span className='icon-epl' dangerouslySetInnerHTML={{ __html: oddFeedTitle }}></span>
                <div className='teams'>
                    <div className='team'>
                        <i className='icon-team-bg'>
                            <img
                                className='icon-team-default'
                                src={`${home.teamImg}.svg`}
                                alt=''
                                onError={(e) => {
                                    e.target.src = '/public/html/images/team/default-home.svg';
                                }}
                            ></img>
                        </i>
                        <span>{home.teamName}</span>
                    </div>
                    <div className='info'>
                        <span className='time'>
                            {date}
                            <br />
                            {time}
                        </span>
                        <span className='vs'>VS</span>
                        <span
                            className='btn'
                            onClick={() => {
                                sportsBannerPlayNow('IBC', '1', id);
                            }}
                        >
                            {t('home:home.betNow', 'Bet Now').toLowerCase()}
                        </span>
                    </div>
                    <div className='team'>
                        <i className='icon-team-bg'>
                            <img
                                className='icon-team-default'
                                src={`${away.teamImg}.svg`}
                                alt=''
                                onError={(e) => {
                                    e.target.src = '/public/html/images/team/default-away.svg';
                                }}
                            ></img>
                        </i>
                        <span>{away.teamName}</span>
                    </div>
                </div>
                <div className='nums-list'>
                    <li>
                        <span>{home.odds}</span>
                        <span>{t('home:home.oddsfeed.home')}</span>
                    </li>
                    <li>
                        <span>{Math.abs(home.handicap)}</span>
                        <span>{t('home:home.oddsfeed.hdp')}</span>
                    </li>
                    <li>
                        <span>{away.odds}</span>
                        <span>{t('home:home.oddsfeed.away')}</span>
                    </li>
                </div>
            </div>
        );
    }

    render() {
        return (
            <Translation>
                {(t) => (
                    <div className='up-events'>
                        <div className='item-title'>{t('global:global.upcomingEvents', 'UPCOMING EVENTS')}</div>
                        <div className='slider-box' data-screen='web' key='web'>
                            {this.state.items.length > 0 && (
                                <Slider
                                    autoPlay={true}
                                    items={this.state.items}
                                    renderItem={(item) => this.renderItem(item)}
                                    navigation={this.getNavs()}
                                    transionName='slider-fade'
                                />
                            )}
                        </div>
                        <div className='slider-box' data-screen='pad' key='pad'>
                            {this.state.items.length > 0 && <SliderMobile space={'0.32rem'} items={this.state.items} renderItem={(item) => this.renderItem(item)} />}
                        </div>
                        <div className='slider-box' data-screen='mobile' key='mobile'>
                            {this.state.items.length > 0 && (
                                <SliderMobile activedIndex={0} space={'0.30rem'} items={this.state.items} renderItem={(item) => this.renderItem(item)} />
                            )}
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default withTranslation('global')(UPEvents);
