import { userAction } from 'root/redux/action/user.action';
import core from 'root/utils/core';
import providerCore from 'root/utils/provider';
import { walletAction } from 'root/redux/action/wallet.action';
import notification from 'root/utils/notification';
import eventEmitter, { EVENT_TYPE } from '../../../../utils/eventEmmiter';
import { gu_getNestedValue } from '../../../../utils/general-util';
import { POPUP_TYPE } from '@constants';
import { popupAction } from '@redux/action';

const controller = {
    vm: null,
    restoreWalletEvent: null,

    init(vm) {
        vm.state = {
            iconLoading: true,
            disabledDepositButton: false,
            providerList: [],
            categorizedProviderList: [],
            categorizedProviderListByMoreType: [],
            totalvalidturnoveramount: 0,
            transferAmount: 0,
            minimumAmount: 0.01,
            maximumAmount: 0,
            fromProvider: 0,
            toProvider: 0,
            promoList: [],
            promoListVisible: false,
            selectedPromotion: 0,
            allowSpecialCode: false,
            showPromoTnc: false,
            specialPromoCode: '',
            specialPromo: null,
            isValidSpecialPromoCode: false,
            activeTab: 'wallet',
            specialPromoDialogIsShow: false,
            specialPromoDetail: null,
            specialPromoAmountPlaceHolder: '',
            phoneVerificationDialogIsShow: false,
            phoneVerificationDialogClosedCallback: null,
            restoreOnLoad: false,
            mainWallet: null,
            totalProviderTurnover: 0,
            totalProviderBalance: 0,
            typeIndexFrom: 0,
            typeIndexTo: 0,
            showInputForm: true,
            isSuccess: false,
            providerBalance: null,
            customTranslationObj: {},
        };

        this.vm = vm;
    },

    registerEvent() {
        this.restoreWalletEvent = eventEmitter.subscribe(EVENT_TYPE.TRANSFER_LOAD_PROVIDER_WALLET, () => {
            controller.loadProviders();
        });
    },

    unregisterEvent() {
        this.restoreWalletEvent.unsubscribe();
    },

    loadPortalSettings() {
        window.SPL_Content.getPortalSettings().then((data) => {
            if (data) {
                if (data.showPromoTnC) {
                    controller.vm.setState({ showPromoTnc: data.showPromoTnC });
                }
                if (data.allowSpecialCode) {
                    controller.vm.setState({ allowSpecialCode: data.allowSpecialCode });
                }
                if (data.features && data.features.hideTransferProviderIcon) {
                    controller.vm.setState({ hideTransferProviderIcon: data.features.hideTransferProviderIcon });
                }
            }
        });
    },

    loadAsyncProviderWallet(check) {
        window.SPL_Provider.loadAsyncProviderWallet(check, controller.vm.props).then((providerWallet) => {
            if (providerWallet.requireToReCallApi) {
                setTimeout(() => {
                    controller.loadAsyncProviderWallet(false);
                }, 1000);
            } else {
                controller.updateProviderList(providerWallet.providerList);
            }
        });
    },

    updateProviderList(providerWallet) {
        const { providerList } = controller.vm.state;
        for (let i = 0; i < providerList.length; i++) {
            let results = providerWallet.filter(function (e) {
                return e.provider === providerList[i].provider;
            })[0];

            if (results) {
                providerList[i].balance = results.balance ? results.balance : 0;
                providerList[i].serverIsUnderMaintenance = results.serverIsUnderMaintenance;
                providerList[i].isdisplay = true;
                providerList[i].serverMaintenanceStr = results.serverIsUnderMaintenance ? 'error:error.underMaintainance' : '';
            } else {
                providerList[i].serverMaintenanceStr = '';
            }

            controller.vm.setState({ providerList: providerList });
        }
    },

    loadProviders(loadAsync) {
        const vm = controller.vm;
        const { t } = vm.props;
        const { customTranslationObj } = vm.state;
        vm.setState({ iconLoading: true });

        const getCorrectProviderName = (providerObj) => {
            if (gu_getNestedValue(customTranslationObj, providerObj.provider)) {
                return customTranslationObj[providerObj.provider];
            }

            return providerObj.name;
        };

        window.SPL_Provider.getAllProviderWalletList(null, false, controller.vm.props)
            .then((data) => {
                let tempProviderList = data.providerList;
                let providerList = [];
                let fromProvider;
                let foundMainWalletIndex = 0;
                let totalBalance = 0;
                let totalTurnover = 0;

                let mainWallet = tempProviderList.filter((a) => a.provider === null)[0];
                tempProviderList = tempProviderList.filter((a) => a.provider !== null);
                tempProviderList = tempProviderList.slice(0).sort(function (a, b) {
                    return a['provider'] > b['provider'] ? 1 : a['provider'] < b['provider'] ? -1 : 0;
                });

                providerList.push(mainWallet);
                for (let i = 0; i < tempProviderList.length; i++) {
                    providerList.push(tempProviderList[i]);
                }

                for (let i = 0; i < providerList.length; i++) {
                    providerList[i].display = providerList[i].name ? getCorrectProviderName(providerList[i]) : t('settings:settings.mywallet', 'My Wallet');
                    providerList[i].key = providerList[i].provider;

                    // default select main wallet
                    if (providerList[i].provider === null) {
                        providerList[i].key = 'main';
                        providerList[i].name = t('settings:settings.mywallet', 'My Wallet');
                        foundMainWalletIndex = i;
                        fromProvider = providerList[i];
                    } else {
                        if (providerCore.providers[providerList[i].provider]) {
                            providerList[i].transferSettingIcon = providerCore.providers[providerList[i].provider].transferSettingIcon;
                        } else {
                            // please uncomment this to get to know apa provider missing
                            //console.log(providerList[i].provider);
                        }
                    }
                    totalBalance += providerList[i].balance;
                    totalTurnover += providerList[i].turnover;
                }
                let transferValue = window.SPL_Other.formatAmount(fromProvider.balance) || window.SPL_Other.formatAmount('0');
                transferValue = transferValue.replace(/,/g, '');

                providerList = providerList.slice(0).sort(function (a, b) {
                    return a['provider'] > b['provider'] ? 1 : a['provider'] < b['provider'] ? -1 : 0;
                });

                // hardcode for sbo provider is after cmd provider to make sure sport provider show 1st, before esport provider show
                let providerSBO = providerList.map((a) => a.provider).indexOf('SBO');
                let providerCMD = providerList.map((a) => a.provider).indexOf('CMD');
                if (providerSBO > -1 && providerCMD > -1) {
                    providerList.splice(providerCMD + 1, 0, providerList[providerSBO]);
                    providerList.splice(providerSBO + 1, 1);
                }

                let categorizedProviderList = window.SPL_Provider.categorizeProvider(providerList);

                for (let i = 0; i < categorizedProviderList.length; i++) {
                    const category = categorizedProviderList[i];
                    if (category.name === 'sports') {
                        category.title = t('global:global.menu.sportsbook2') + ' / ' + t('global:global.menu.esport2');
                    } else if (category.name === 'casinoFishingSlots') {
                        category.title = t('global:global.menu.casino2') + ' / ' + t('global:global.menu.slot2') + ' / ' + t('global:global.menu.fishing2');
                    } else if (category.name === 'fight') {
                        category.title = t('global:global.menu.cockfight');
                    } else if (category.name === 'others') {
                        category.title = t('global:global.others');
                    }
                }

                // Filter out category with no game(s) included
                categorizedProviderList = categorizedProviderList.filter((p) => p.list.length > 0);

                // Hide UL because UL added to SG Slot
                providerList = providerList.filter((p) => p.provider !== 'UL');
                window.SPL_Provider.categoriseProviderByMoreType(providerList, vm.props.language.currencyLang).then((categorizedProviderListByMoreType) => {
                    // for (let i = 0; i < categorizedProviderListByMoreType.length; i++) {
                    //     const category = categorizedProviderListByMoreType[i];
                    //     if (category.name === 'sports') {
                    //         category.title = t('global:global.menu.sportsbook2');
                    //     } else if (category.name === 'casino') {
                    //         category.title = t('global:global.menu.live_casino');
                    //     } else if (category.name === 'slots') {
                    //         category.title = t('global:global.menu.slot2');
                    //     } else if (category.name === 'fishing') {
                    //         category.title = t('global:global.menu.fishing2');
                    //     } else if (category.name === 'esports') {
                    //         category.title = t('global:global.menu.esport2');
                    //     } else if (category.name === 'pokers') {
                    //         category.title = t('global:global.menu.poker2');
                    //     } else if (category.name === 'lottery') {
                    //         category.title = t('global:global.menu.lottery2');
                    //     } else if (category.name === 'threeD') {
                    //         category.title = t('global:global.menu.games2');
                    //     } else if (category.name === 'fourD') {
                    //         category.title = t('global:global.menu.4d');
                    //     } else if (category.name === 'cockfight') {
                    //         category.title = t('global:global.menu.cockfight');
                    //     } else if (category.name === 'fastgames') {
                    //         category.title = t('global:global.menu.fastgames');
                    //     } else if (category.name === 'others') {
                    //         category.title = t('settings:settings.mywallet', 'My Wallet');
                    //     }
                    // }

                    vm.setState({
                        categorizedProviderListByMoreType: categorizedProviderListByMoreType,
                    });
                });

                vm.props.dispatch(walletAction.updateProviderWallets(providerList));

                const { wallet } = vm.props;
                vm.setState({
                    providerList: (wallet && wallet.providerWallets) || providerList,
                    totalvalidturnoveramount: data.totalvalidturnoveramount,
                    categorizedProviderList: categorizedProviderList,
                    totalProviderBalance: totalBalance,
                    totalProviderTurnover: totalTurnover,
                    fromProvider: foundMainWalletIndex, // purposely set to item count because could not bind object to value
                    toProvider: foundMainWalletIndex, // purposely set to item count because could not bind object to value
                    iconLoading: false,
                    transferAmount: transferValue,
                    maximumAmount: transferValue,
                });
            })
            .then(() => {
                if (loadAsync) {
                    controller.loadAsyncProviderWallet(true);
                }
            });
    },

    loadPromotions(provider) {
        let promotionList = [];
        if (provider) {
            const { user, language, portal, screen } = controller.vm.props;
            let platform = null;

            if (portal && portal.settings && portal.settings.getPromoCodeByPlatform) {
                if (window.SPL_LiteApp.isZT828LiteApp()) {
                    platform = 'A';
                } else if (screen.viewType === 'web') {
                    platform = 'W';
                } else {
                    platform = 'M';
                }
            }

            window.SPL_Provider.loadPromotions(provider, user.account.login, language.countryLanguageKey, platform).then((promoList) => {
                promotionList = promoList;
                controller.vm.setState({
                    promoList: promotionList,
                    selectedPromotion: -1,
                    specialPromoCode: '',
                    specialPromo: null,
                    isValidSpecialPromoCode: false,
                    specialPromoAmountPlaceHolder: '',
                    promoListVisible: true,
                });
            });
        } else {
            controller.vm.setState({
                promoList: promotionList,
                selectedPromotion: -1,
                specialPromoCode: '',
                specialPromo: null,
                isValidSpecialPromoCode: false,
                specialPromoAmountPlaceHolder: '',
                promoListVisible: false,
            });
        }
    },

    getMinimumAmountForTransfer(from, to, promo) {
        let minimumAmount = window.SPL_Transaction.getMinimumAmountForTransfer(from, to, promo);
        controller.vm.setState({ minimumAmount: minimumAmount });
    },

    getCorrespondingProviderObjectByIndex(index) {
        return controller.vm.state.providerList[index];
    },

    getCorrespondingPromotionObjectByIndex(index) {
        return controller.vm.state.promoList[index];
    },

    getCorrespondingIndexByProviderObject(provider) {
        return controller.vm.state.providerList.findIndex((p) => p.provider === provider.provider);
    },

    changeProviderFrom(e) {
        let fromProvider = controller.getCorrespondingProviderObjectByIndex(e.target.value);
        let toProvider = controller.getCorrespondingProviderObjectByIndex(controller.vm.state.toProvider);

        controller.vm.setState({
            fromProvider: e.target.value,
            transferAmount: fromProvider.balance,
            maximumAmount: fromProvider.balance,
        });
        controller.getMinimumAmountForTransfer(fromProvider.provider, toProvider.provider, null);
    },

    changeProviderTo(e) {
        let fromProvider = controller.getCorrespondingProviderObjectByIndex(controller.vm.state.fromProvider);
        let toProvider = controller.getCorrespondingProviderObjectByIndex(e.target.value);

        controller.vm.setState({ toProvider: e.target.value });
        controller.getMinimumAmountForTransfer(fromProvider.provider, toProvider.provider, null);
        controller.loadPromotions(toProvider.provider);
    },

    changeProviderFromObjectParams(provider, categoryProviderList) {
        let fromProviderIndex = 0;
        if (categoryProviderList) {
            fromProviderIndex = categoryProviderList.findIndex((p) => p.provider === provider.provider);
        } else {
            fromProviderIndex = controller.getCorrespondingIndexByProviderObject(provider);
        }

        let toProvider = controller.getCorrespondingProviderObjectByIndex(controller.vm.state.toProvider);
        // console.log(Object.is(controller.vm, vm));
        controller.vm.setState(
            {
                fromProvider: fromProviderIndex,
                transferAmount: provider.balance,
                maximumAmount: provider.balance,
            },
            () => {
                setTimeout(() => {}, 3000);
            }
        );
        controller.getMinimumAmountForTransfer(provider.provider, toProvider.provider, null);
    },

    changeProviderToObjectParams(provider, categoryProviderList) {
        let fromProvider = controller.getCorrespondingProviderObjectByIndex(controller.vm.state.fromProvider);
        let toProviderIndex = 0;
        if (categoryProviderList) {
            toProviderIndex = categoryProviderList.findIndex((p) => p.provider === provider.provider);
        } else {
            toProviderIndex = controller.getCorrespondingIndexByProviderObject(provider);
        }

        controller.vm.setState({ toProvider: toProviderIndex });
        controller.getMinimumAmountForTransfer(fromProvider.provider, provider.provider, null);
        controller.loadPromotions(provider.provider);
    },

    swapProvider() {
        let fromProviderIndex = controller.vm.state.fromProvider;
        let toProviderIndex = controller.vm.state.toProvider;

        // this part direct swap
        let toProvider = controller.getCorrespondingProviderObjectByIndex(controller.vm.state.fromProvider);
        let fromProvider = controller.getCorrespondingProviderObjectByIndex(controller.vm.state.toProvider);

        controller.vm.setState({
            fromProvider: toProviderIndex,
            toProvider: fromProviderIndex,
            transferAmount: fromProvider.balance,
            maximumAmount: fromProvider.balance,
        });
        controller.getMinimumAmountForTransfer(fromProvider.provider, toProvider.provider, null);
        controller.loadPromotions(toProvider.provider);
    },

    handleAmountChange(e) {
        let value = e.target.value;
        if (value) {
            if (value.toString().match(/^(\d*)\.{0,1}(\d){0,2}$/)) {
                controller.vm.setState({ transferAmount: value }, () => {
                    controller.verifySpecialPromotion();
                });
            }
        } else {
            controller.vm.setState({ transferAmount: value }, () => {
                controller.verifySpecialPromotion();
            });
        }
    },

    changePromotionByEvent(e) {
        controller.changePromotion(e.target.value);
    },

    changePromotion(index) {
        if (index !== -1) {
            let selectedPromotion = controller.getCorrespondingPromotionObjectByIndex(index);
            let fromProvider = controller.getCorrespondingProviderObjectByIndex(controller.vm.state.fromProvider);
            let toProvider = controller.getCorrespondingProviderObjectByIndex(controller.vm.state.toProvider);
            controller.getMinimumAmountForTransfer(fromProvider.provider, toProvider.provider, selectedPromotion);
        }
        controller.vm.setState({ selectedPromotion: index });
    },

    handleSpecialPromoCodeChange(e) {
        controller.vm.setState({ specialPromoCode: e.target.value }, () => {
            controller.verifySpecialPromotion();
        });
    },

    verifySpecialPromotion() {
        if (controller.vm.state.allowSpecialCode && controller.vm.state.specialPromoCode !== null && controller.vm.state.specialPromoCode !== '') {
            let fromProvider = controller.getCorrespondingProviderObjectByIndex(controller.vm.state.fromProvider);
            let toProvider = controller.getCorrespondingProviderObjectByIndex(controller.vm.state.toProvider);
            let login = controller.vm.props.user.account.login;
            let specialPromoCode = controller.vm.state.specialPromoCode;
            let transferAmount = controller.vm.state.transferAmount;

            window.SPL_Transaction.verifyPromotionCode(null, login, specialPromoCode, fromProvider.provider, toProvider.provider, transferAmount).then((result) => {
                let transferAmount = controller.vm.state.transferAmount;

                controller.vm.setState(
                    {
                        isValidSpecialPromoCode: result.isValidPromo,
                        isValidSpecialPromoAmount: result.isValidSpecialPromoAmount,
                        isValidSpecialPromoProvider: result.isValidSpecialPromoProvider,
                        transferAmount: transferAmount,
                        specialPromoAmountPlaceHolder: result.amountPlaceHolder,
                    },
                    () => {
                        if (controller.vm.state.isValidSpecialPromoCode) {
                            controller.vm.setState({ specialPromo: result.specialPromo });
                        }
                    }
                );
            });
        } else {
            controller.vm.setState({ specialPromoAmountPlaceHolder: '', specialPromo: null });
        }
    },

    submitTransfer(t) {
        controller.vm.setState({ iconLoading: true, disabledDepositButton: true, isSuccess: false });

        let mainWallet = controller.vm.state.providerList.filter((a) => a.provider === null)[0];
        let fromProvider = controller.getCorrespondingProviderObjectByIndex(controller.vm.state.fromProvider);
        let toProvider = controller.getCorrespondingProviderObjectByIndex(controller.vm.state.toProvider);
        let transferAmount = controller.vm.state.transferAmount;
        let promotionObj = null;
        let promotionId = 0;
        const { isValidSpecialPromoCode, isValidSpecialPromoAmount, isValidSpecialPromoProvider } = controller.vm.state;
        if (controller.vm.state.specialPromo) {
            promotionObj = controller.vm.state.specialPromo;
            promotionId = promotionObj.id;
        } else if (controller.vm.state.promoList.length > 0 && controller.vm.state.selectedPromotion >= 0) {
            promotionObj = controller.getCorrespondingPromotionObjectByIndex(controller.vm.state.selectedPromotion);
            promotionId = promotionObj.id;
        }
        if (promotionObj !== null) {
            if (transferAmount < promotionObj.minDeposit) {
                let msg = t('transaction:transaction.transfer.invalidMinAmount') + promotionObj.minDeposit;
                notification.showNotification('error', msg);
                controller.vm.setState({ showLoading: false, disabledDepositButton: false, iconLoading: false });
                return false;
            } else if (
                transferAmount > promotionObj.maxDeposit &&
                (promotionObj.exceedMaxBonus === undefined || promotionObj.exceedMaxBonus === null || !promotionObj.exceedMaxBonus)
            ) {
                let msg = t('transaction:transaction.transfer.invalidMaxAmount') + promotionObj.maxDeposit;
                notification.showNotification('error', msg);
                controller.vm.setState({ showLoading: false, disabledDepositButton: false, iconLoading: false });
                return false;
            } else if (controller.vm.state.specialPromo && !isValidSpecialPromoCode && isValidSpecialPromoAmount && !isValidSpecialPromoProvider) {
                controller.vm.setState({ showLoading: false, disabledDepositButton: false, iconLoading: false });
                return false;
            }
        }

        // if provider is not null, transfer is from balance tab, and only applicable for mainwallet to provider
        let remark = fromProvider.provider === null ? 'Member Deposit' : 'Member Withdraw';

        //Note: User need to do verification only can use promotion ( not only for )
        if (promotionObj && promotionObj.isPhoneVerification) {
            core.portalIsLoading(controller.vm.props, false);
            window.SPL_Member.getPhoneRequiredVerification().then(function (isReqVerification) {
                // if require phone verification, update status to S
                // frontend need to handle open sms popup to verify phone
                // before proceed
                if (isReqVerification) {
                    // controller.openSmsVerificationDialog(continueSubmitTransfer);
                    controller.vm.props.dispatch(popupAction.setPopup(POPUP_TYPE.VERIFY_PHONE, true));
                } else {
                    continueVerifySubmit();
                }
            });
        } else {
            continueVerifySubmit();
        }

        function continueVerifySubmit() {
            window.SPL_Transaction.validateTransferFormV2(mainWallet.balance, transferAmount, fromProvider, toProvider, promotionObj, false).then((validateRes) => {
                switch (validateRes.status) {
                    case 'F':
                        notification.showNotification('error', t(validateRes.msg, { amount: validateRes.amount || 0 }));
                        controller.vm.setState({ showLoading: false, disabledDepositButton: false, iconLoading: false });
                        break;
                    case 'V':
                        controller.vm.setState({ showLoading: false, disabledDepositButton: false, iconLoading: false });
                        // controller.openSmsVerificationDialog(continueSubmitTransfer);
                        controller.vm.props.dispatch(popupAction.setPopup(POPUP_TYPE.VERIFY_PHONE, true));
                        break;
                    default:
                        continueSubmitTransfer();
                        break;
                }
            });
        }

        function continueSubmitTransfer() {
            core.portalIsLoading(controller.vm.props, true);
            window.SPL_Transaction.requestTransfer(transferAmount, promotionId, remark, toProvider.provider, fromProvider.provider)
                .then((data) => {
                    controller.vm.setState({ showLoading: false, disabledDepositButton: false, iconLoading: false });
                    if (data.status === 'F') {
                        if (typeof data.msg === 'object') {
                            if (data.msg && data.msg.data && data.msg.data.detail) {
                                notification.showNotification('error', t(data.msg.data.detail));
                            } else {
                                notification.showNotification('error', t(data.msg));
                            }
                        } else {
                            // colon will cause front msg deleted when alert
                            let msg = data.msg.replace(':', '-');
                            notification.showNotification('error', t(msg));
                        }
                    } else {
                        controller.vm.setState({ isSuccess: true });
                        controller.vm.props.dispatch(walletAction.udpateLoadWallet(true));
                        controller.resetOnClick();
                        controller.loadProviders();
                    }
                    core.portalIsLoading(controller.vm.props, false);
                })
                .catch(() => {
                    core.portalIsLoading(controller.vm.props, true);
                });
        }
    },

    toggleTab(tab) {
        if (controller.vm.state.activeTab !== tab) {
            controller.vm.setState({ activeTab: tab }, () => {
                controller.loadProviders();
            });
        }
    },

    goToPromoPage() {
        controller.vm.props.history.push('/promotion');
    },

    openSpecialPromoDialog() {
        let promotionObj = null;
        let promotionDetail = {};
        if (controller.vm.state.specialPromo) {
            promotionObj = controller.vm.state.specialPromo;
        } else if (controller.vm.state.promoList.length > 0 && controller.vm.state.selectedPromotion >= 0) {
            promotionObj = controller.getCorrespondingPromotionObjectByIndex(controller.vm.state.selectedPromotion);
        }

        if (promotionObj) {
            if (promotionObj.bonusGroup === 'FREE_BET') {
                promotionDetail = {
                    title: promotionObj.bonusName,
                    targetType: promotionObj.targetType.split('BY_')[1],
                    targetMultiplier: promotionObj.targetMultiplier + 'x',
                    dynamic: {
                        bonusAmount: controller.vm.props.user.account.currency + ' ' + promotionObj.bonusAmount,
                        targetDepositAmount: controller.vm.props.user.account.currency + ' ' + promotionObj.targetDepositAmount,
                    },
                };
            } else {
                promotionDetail = {
                    title: promotionObj.bonusName,
                    targetType: promotionObj.targetType.split('BY_')[1],
                    targetMultiplier: promotionObj.targetMultiplier + 'x',
                    dynamic: {
                        rate: promotionObj.rate * 100 + '%',
                        minDeposit: controller.vm.props.user.account.currency + ' ' + promotionObj.minDeposit,
                        maxDeposit: promotionObj.exceedMaxBonus ? 'No Limit' : controller.vm.props.user.account.currency + ' ' + promotionObj.maxDeposit,
                        maxBonus: controller.vm.props.user.account.currency + ' ' + promotionObj.maxBonus,
                    },
                };
            }

            controller.vm.setState({
                specialPromoDialogIsShow: true,
                specialPromoDetail: promotionDetail,
            });
        }
    },

    closeSpecialPromoDialog(vm) {
        vm.setState({ specialPromoDialogIsShow: false }, () => {
            vm.setState({ specialPromoDetail: null });
        });
    },

    openSmsVerificationDialog(continueSubmitCallback) {
        controller.vm.props.dispatch(userAction.openSpecialDialog());
        controller.vm.setState({
            phoneVerificationDialogIsShow: true,
            phoneVerificationDialogClosedCallback: continueSubmitCallback,
        });
    },

    closePhoneVerificationDialog(vm) {
        controller.vm.props.dispatch(userAction.closeSpecialDialog());
        vm.setState({ phoneVerificationDialogIsShow: false });
    },

    getMainWallet() {
        const vm = controller.vm;
        const { wallet } = vm.props;
        vm.setState({
            iconLoading: true,
            restoreOnLoad: true,
        });

        window.SPL_Member.getMainWallet().then((mainWallet) => {
            vm.props.dispatch(walletAction.updateWallet(mainWallet.data));
            vm.setState({
                mainWallet: (wallet && wallet.mainWallet) || mainWallet.data,
                iconLoading: false,
                restoreOnLoad: false,
            });
        });
    },

    restoreWallet() {
        const { restoreOnLoad, disableWithdrawButton } = controller.vm.state;

        if (!restoreOnLoad && !disableWithdrawButton) {
            controller.vm.setState({ iconLoading: true, restoreOnLoad: true });
            window.SPL_Transaction.transferAllBackMainWallet()
                .then(() => {
                    setTimeout(() => {
                        controller.getMainWallet();
                    }, 5000);
                })
                .catch(() => {
                    setTimeout(() => {
                        controller.getMainWallet();
                    }, 5000);
                });
        }
    },

    AllInOnClick() {
        const { maximumAmount } = controller.vm.state;
        controller.vm.setState({ transferAmount: maximumAmount });
    },

    resetOnClick() {
        controller.vm.setState({ transferAmount: 0, fromProvider: 0, toProvider: 0, selectedPromotion: -1, specialPromoCode: '' });
    },

    providerCategorySelect(providerIndex, type) {
        const { categorizedProviderListByMoreType, providerList } = controller.vm.state;
        for (let i = 0; i < categorizedProviderListByMoreType.length; i++) {
            const providerType = categorizedProviderListByMoreType[i];
            for (let j = 0; j < providerType.list.length; j++) {
                const provider = providerType.list[j];
                if (provider.name === providerList[providerIndex].name) {
                    let typeIndexFrom = controller.vm.state.typeIndexFrom;
                    let typeIndexTo = controller.vm.state.typeIndexTo;
                    if (type === 'from') {
                        typeIndexFrom = i;
                        controller.vm.setState({ typeIndexFrom });
                    } else {
                        typeIndexTo = i;
                        controller.vm.setState({ typeIndexTo });
                    }
                    break;
                }
            }
        }
    },

    onCategorySelect(typeIndex, type) {
        const { categorizedProviderListByMoreType } = controller.vm.state;

        let indexTo = 0;
        let indexFrom = 0;

        // after change category, pick the first provider
        let provider = categorizedProviderListByMoreType[typeIndex].list[0];

        if (type === 'from') {
            indexFrom = typeIndex;
            if (provider) {
                controller.changeProviderFromObjectParams(provider, categorizedProviderListByMoreType[typeIndex].list);
            }

            controller.vm.setState({ transferSelectIndexFrom: indexFrom, transferSelectItemIndexFrom: -1 });
        } else {
            indexTo = typeIndex;
            if (provider) {
                controller.changeProviderToObjectParams(provider, categorizedProviderListByMoreType[typeIndex].list);
            }
            controller.vm.setState({ transferSelectIndexTo: indexTo, transferSelectItemIndexTo: -1 });
        }
    },

    updateProviderSelect(providerIndex, type) {
        let indexTo = 0;
        let indexFrom = 0;

        if (type === 'from') {
            indexFrom = providerIndex;
            controller.vm.setState({ transferSelectIndexFrom: indexFrom, transferSelectItemIndexFrom: -1 });
        } else {
            indexTo = providerIndex;
            controller.vm.setState({ transferSelectIndexTo: indexTo, transferSelectItemIndexTo: -1 });
        }
    },

    updateProviderItemSelect(providerIndex, type) {
        let indexTo = 0;
        let indexFrom = 0;

        if (type === 'from') {
            indexFrom = providerIndex;
            controller.vm.setState({ transferSelectItemIndexFrom: indexFrom });
        } else {
            indexTo = providerIndex;
            controller.vm.setState({ transferSelectItemIndexTo: indexTo });
        }
    },

    showHideInputForm(active) {
        if (active === -1) {
            controller.vm.setState({ showInputForm: true });
        } else {
            controller.vm.setState({ showInputForm: false });
        }
    },

    toggleShowPromoDetails() {
        const { specialPromo, selectedPromotion } = controller.vm.state;
        const { currency } = controller.vm.props.user.account;

        let promotion = controller.vm.state.promoList[selectedPromotion];
        let promodetails = null;
        if (specialPromo != null) {
            promotion = specialPromo;
        }
        let promodetailsContainer = [];
        if (promotion === null) {
            return;
        } else if (promotion.bonusGroup === 'FREE_BET') {
            promodetailsContainer.push(currency + ' ' + promotion.bonusAmount);
            promodetailsContainer.push(promotion.targetType.split('BY_')[1]);
            promodetailsContainer.push(promotion.targetMultiplier + 'x');
            promodetailsContainer.push(currency + ' ' + promotion.targetDepositAmount);

            promodetails = {
                bonusAmount: promodetailsContainer[0],
                targetType: promodetailsContainer[1],
                targetMultiplier: promodetailsContainer[2],
                targetDepositAmount: promodetailsContainer[3],
            };
        } else {
            promodetailsContainer.push(promotion.rate * 100 + '%');
            promodetailsContainer.push(promotion.targetType.split('BY_')[1]);
            promodetailsContainer.push(promotion.targetMultiplier + 'x');
            promodetailsContainer.push(currency + ' ' + promotion.minDeposit);
            if (promotion.exceedMaxBonus) {
                promodetailsContainer.push('No Limit');
            } else {
                promodetailsContainer.push(currency + ' ' + promotion.maxDeposit);
            }
            promodetailsContainer.push(currency + ' ' + promotion.maxBonus);

            promodetails = {
                rate: promodetailsContainer[0],
                targetType: promodetailsContainer[1],
                targetMultiplier: promodetailsContainer[2],
                minDeposit: promodetailsContainer[3],
                maxDeposit: promodetailsContainer[4],
                maxBonus: promodetailsContainer[5],
            };
        }
        promodetails.promodetailsTitle = promotion.bonusName;
        controller.vm.setState({ promotionDetailsDialogIsShow: true, promodetails });
    },
    closePromotionDetailsDialog() {
        controller.vm.setState({ promotionDetailsDialogIsShow: false });
    },
};

export default controller;
