import React from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import vm from '../../history-landing.controller';

class HistoryLanding extends React.Component {
    constructor() {
        super();
        vm.init(this);
        this.state = {
            ...this.state,
            type: {
                active: 0,
            },
            date: {
                active: 0,
                list: [
                    'transaction:transaction.history.filter.today',
                    'transaction:transaction.history.filter.3days',
                    'transaction:transaction.history.filter.aweek',
                    'transaction:transaction.history.filter.amonth',
                ],
            },
            list: [],
        };
    }

    componentDidMount() {
        vm.highlightTab();
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    renderSelectedTab() {
        const Content = vm.getTabContent();
        if (Content) {
            return <Content parent={vm} parentState={vm.vm.state}></Content>;
        }

        return <div></div>;
    }

    render() {
        const { language } = this.props;
        return (
            <Translation>
                {(t) => (
                    <div className='history-page'>
                        <div className='history-tools'>
                            <div className='group-item transaction-type'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.history.filter.trans_type_no_colon')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='group'>
                                    <ul>
                                        {this.state.displayTab &&
                                            this.state.displayTab.length > 0 &&
                                            this.state.displayTab.map((tab, i) => (
                                                <li key={i} className={[tab.selected ? 'on' : '', language.key].join(' ')} onClick={() => vm.toggleTab(tab.id)}>
                                                    <span>{t(tab.tabHeader)}</span>
                                                </li>
                                            ))}
                                    </ul>
                                </div>
                            </div>
                            {this.renderSelectedTab()}
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}
export default connect(mapStateToProps)(withTranslation('settings')(withRouter(HistoryLanding)));
