import React, { Fragment } from 'react';
import Games_3D_Banner from './Games-3D-Banner';
import Games_3D_Games from './Games-3D-Games';

class Games_3D extends React.Component {
    render() {
        return (
            <Fragment>
                <Games_3D_Banner />
                <Games_3D_Games />
            </Fragment>
        );
    }
}

export default Games_3D;
