import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { portalAction } from 'root/redux/action/portal.action';

const TRANS_TYPE = 'freeSpinHistory';

const DateRanges = [
    'transaction:transaction.history.filter.today',
    'transaction:transaction.history.filter.3days',
    'transaction:transaction.history.filter.aweek',
    'transaction:transaction.history.filter.amonth',
];

const ColumnConfigs = [
    {
        key: 'createdDate',
        sortable: true,
        render: (history) => moment(history.createdDate).format('YYYY-MM-DD HH:mm'),
    },
    {
        key: 'provider',
        render: (history, t) => t(`providerName:ProviderName.${history.provider}`),
    },
    {
        key: 'promotionId',
        render: (history) => history.promotionId,
    },
    {
        key: 'totalWinLoss',
        render: (history) => (typeof history.totalWinLoss === 'number' ? `${history['currency']} ${history.totalWinLoss}` : '-'),
    },
    {
        key: 'freeSpinCount',
        render: (history) => history.freeSpinCount,
    },
    {
        key: 'status',
        sortable: true,
        render: (history, t) => t(`transaction:transaction.freeSpinHistory.statusValue.${history.status}`),
    },
    {
        key: 'startTime',
        render: (history) => moment(history.startTime).format('YYYY-MM-DD HH:mm'),
    },
    {
        key: 'endTime',
        render: (history) => moment(history.endTime).format('YYYY-MM-DD HH:mm'),
    },
];

const getInitialRangeDate = () => {
    const timeRange = 6 * 24 * 3600000; // aweek
    const endDate = new Date();
    const startDate = new Date(endDate.getTime() - timeRange);

    return { startDate, endDate };
};

const useFreeSpin = () => {
    const [histories, setHistories] = useState([]);
    const [sorting, setSorting] = useState({ createdDate: 'DESC' });
    const [rangeDate, setRangeDate] = useState(getInitialRangeDate());
    const [selectedDateRange, setSelectedDateRange] = useState(2);
    const [paging, setPaging] = useState({ currentPage: 1, itemPerPage: 5, totalCount: 0, totalPage: 0 });
    const language = useSelector((s) => s.language);
    const dispatch = useDispatch();

    const { currentPage, itemPerPage, totalPage, totalCount } = paging;
    const { startDate, endDate } = rangeDate;

    const searchHistory = () => {
        dispatch(portalAction.portalIsLoading(true));

        const sort = Object.entries(sorting)
            .map(([k, v]) => `${k},${v.toUpperCase()}`)
            .join(';');

        const params = {
            start: moment(startDate).format('YYYY-MM-DD'),
            end: moment(endDate).format('YYYY-MM-DD'),
            page: currentPage,
            size: itemPerPage,
            sort,
        };

        window.SPL_Transaction.loadHistories(TRANS_TYPE, params)
            .then((data) => {
                const totalCount = data.totalElements;
                const totalPage = data.totalPages;
                const histories = data.content;

                setHistories(histories);
                setPaging({
                    ...paging,
                    totalCount,
                    totalPage,
                });
            })
            .finally(() => dispatch(portalAction.portalIsLoading(false)));
    };

    useEffect(() => {
        searchHistory();
    }, [sorting, currentPage]);

    let datepickerLanguage = '';
    if (language.countryLanguageKey === 'TH_TH') {
        datepickerLanguage = 'th';
    } else if (language.countryLanguageKey === 'ZH_MY') {
        datepickerLanguage = 'zhCN';
    }

    const handleDateChanged = (date, isStartDate) => {
        const newRangeDate = { ...rangeDate };
        if (isStartDate) {
            newRangeDate.startDate = date;
        } else {
            newRangeDate.endDate = date;
        }
        setRangeDate(newRangeDate);
    };

    const handlePrevNextClick = (isPrev, firstLast) => {
        if (firstLast) {
            if (firstLast === 'first') {
                handlePageClick(1);
            } else {
                handlePageClick(totalPage);
            }
        } else {
            if (isPrev) {
                handlePageClick(currentPage - 1);
            } else {
                handlePageClick(currentPage + 1);
            }
        }
    };

    const handlePageClick = (newPageNum) => {
        if (!(currentPage === newPageNum || newPageNum === 0 || newPageNum > totalPage)) {
            setPaging({ ...paging, currentPage: newPageNum });
        }
    };

    const sortHistory = (key) => {
        const newSorting = {};
        if (sorting[key] === 'DESC') {
            newSorting[key] = 'ASC';
        } else {
            newSorting[key] = 'DESC';
        }

        setSorting(newSorting);
    };

    const handleDateRange = (type) => {
        const newRangeDate = { ...rangeDate, endDate: new Date() };

        switch (type) {
            case 0:
                newRangeDate.startDate = new Date(newRangeDate.endDate.getTime());
                break;
            case 1:
                newRangeDate.startDate = new Date(newRangeDate.endDate.getTime() - 2 * 24 * 3600000);
                break;
            case 2:
                newRangeDate.startDate = new Date(newRangeDate.endDate.getTime() - 6 * 24 * 3600000);
                break;
            case 3:
                newRangeDate.startDate = new Date(newRangeDate.endDate.getTime() - 30 * 24 * 3600000);
                break;
            default:
                break;
        }

        setSelectedDateRange(type);
        setRangeDate(newRangeDate);
    };

    const pagingList = useMemo(() => {
        const { currentPage, totalPage } = paging;
        const list = [];

        for (let i = 0; i < totalPage; i++) {
            list.push({ pageNum: i + 1, active: currentPage === i });
        }

        return list;
    }, [currentPage, totalPage]);

    return {
        DateRanges,
        ColumnConfigs,
        datepickerLanguage,
        selectedDateRange,
        currentPage,
        itemPerPage,
        totalPage,
        totalCount,
        pagingList,
        histories,
        startDate,
        endDate,
        sorting,
        handleDateRange,
        handleDateChanged,
        handlePageClick,
        handlePrevNextClick,
        searchHistory,
        sortHistory,
    };
};

export default useFreeSpin;
