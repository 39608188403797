import React, { Fragment } from 'react';

import HomeMemberProfile from 'root/webapp/shared/component/wap/HomeMemberProfile';
import WebDownLoad from '../desktop/DownloadPanel';

class Download extends React.Component {
    render() {
        return (
            <Fragment>
                <div className='download-page'>
                    <HomeMemberProfile />
                    <WebDownLoad />
                </div>
            </Fragment>
        );
    }
}

export default Download;
