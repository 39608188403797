import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import DatePicker from 'react-datepicker';

class CustomDatePicker extends React.Component {
    constructor() {
        super();
        this.state = {
            popperPlacement: 'bottom-end',
            modifiers: [
                {
                    name: 'preventOverflow',
                    options: {
                        rootBoundary: 'viewport',
                        tether: false,
                        altAxis: false,
                    },
                },
            ],
        };
    }

    componentDidMount() {
        let popperPlacement = this.props.popperPlacement ?? 'bottom-end';
        this.setState({ popperPlacement: popperPlacement });
    }

    render() {
        const { language } = this.props;
        let datepickerLanguage = '';
        if (language.countryLanguageKey === 'TH_TH') {
            datepickerLanguage = 'th';
        } else if (language.countryLanguageKey === 'ZH_MY') {
            datepickerLanguage = 'zhCN';
        }

        const CustomInput = (props) => {
            return <input onClick={props.onClick} value={props.value} type='text' readOnly={true} />;
        };

        return (
            <DatePicker
                locale={datepickerLanguage}
                autoComplete='off'
                type='number'
                placeholderText={this.props.placeholderText}
                selectsStart
                selected={this.props.selected}
                onChange={this.props.onChange}
                dateFormat='yyyy-MM-dd'
                popperPlacement={this.state.popperPlacement}
                modifiers={this.state.modifiers}
                customInput={<CustomInput />}
            />
        );
    }
}

export default connect(mapStateToProps)(CustomDatePicker);
