import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import core from 'root/utils/core';
import DesktopRedirectError from './desktop/redirect-error';
import MobileRedirectError from './mobile';

class RedirectError extends React.Component {
    components = {
        desktop: {
            default: DesktopRedirectError,
        },
        mobile: {
            default: MobileRedirectError,
        },
    };

    render() {
        const TagName = core.getCorrectComponent(this.components, 'redirect-error', this.props.screen.viewType);
        return <TagName />;
    }
}

export default connect(mapStateToProps, null)(RedirectError);
