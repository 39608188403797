import { portalAction, userAction } from 'root/redux/action';
import commonUtil from 'root/utils/common-util';

const controller = {
    vm: null,

    init(vm) {
        this.vm = vm;
        this.vm.state = {
            navList: {
                popularSubmenu: [],
                submenu: [],
            },
            selectedMenuRoute: '',
            affiliateLink: '',
        };
    },

    initCompSettings() {
        return new Promise((resolve) => {
            let tempSettings = commonUtil.getSettingFromPortalSettings({
                vm: controller.vm,
                settingName: 'sideMenuSettings',
                notViewType: true,
            });
            let compSettings = {
                welcomeBonus: tempSettings.welcomeBonusValue,
            };

            controller.vm.setState({ compSettings }, () => {
                resolve();
            });
        });
    },

    getMobileSidebar() {
        const { navList } = controller.vm.state;
        const { countryLanguageKey, currencyLang } = controller.vm.props.language;
        const { isLogin } = controller.vm.props.user;
        const { settings } = controller.vm.props.portal;
        let popularSubmenu = ['Home', 'ESportsbook', 'Sports', 'Casino', 'Slot', 'Fishing', 'Promotion', 'Vip', 'Leaderboard'];

        if (settings && settings.popularSubmenu && settings.popularSubmenu[currencyLang]) {
            popularSubmenu = settings.popularSubmenu[currencyLang];
        }

        window.SPL_Content.getSp2SidebarMenu(isLogin, countryLanguageKey).then((data) => {
            for (let i = 0; i < data.length; i++) {
                if (popularSubmenu.indexOf(data[i].name) !== -1) {
                    navList.popularSubmenu.push(data[i]);
                } else {
                    navList.submenu.push(data[i]);
                }
            }

            controller.vm.setState({ navList: navList });
        });
    },

    submenuClicked(menu) {
        const { countryLanguageKey } = controller.vm.props.language;

        switch (menu.name) {
            case 'Login':
                controller.vm.props.dispatch(portalAction.floatingPageUpdated('login'));
                break;

            case 'Logout':
                controller.vm.props.dispatch(userAction.logout()).then(() => {
                    if (!window.isAgent && !window.isAffiliate) {
                        if (window.SPL_LiteApp.isZT828LiteApp()) {
                            window.SPL_LiteApp.onLogoutTapped();
                        }
                    }

                    window.location.href = '/home';
                });
                break;

            case 'Desktop':
                window.SPL_Other.setViewPort('web');
                controller.closeSubmenu();
                break;

            case 'Language':
                if (controller.vm.props.history.location.pathname !== menu.route) {
                    controller.vm.props.history.push({ pathname: menu.route, previousMenuRoute: controller.vm.state.selectedMenuRoute });
                }
                controller.closeSubmenu();
                break;

            case 'Contact':
                controller.vm.props.history.push('/home?id=contact');
                controller.closeSubmenu();
                if (controller.vm.props.location.pathname === '/home') {
                    let contactElem = document.getElementById('contact-box');
                    if (contactElem) {
                        contactElem.scrollIntoView();
                    }
                }
                break;

            case 'Settings':
                if (!window.isAgent && !window.isAffiliate) {
                    if (window.SPL_LiteApp.isZT828LiteApp()) {
                        window.SPL_LiteApp.onSettingsTapped();
                    }
                }
                controller.closeSubmenu();
                break;

            case 'Affiliate':
                window.open(controller.vm.state.affiliateLink);
                break;

            default:
                if (menu.url) {
                    let url = menu.url;

                    if (menu.url[countryLanguageKey]) {
                        url = menu.url[countryLanguageKey];
                    }

                    window.open(url);
                } else if (controller.vm.props.history.location.pathname !== menu.route || (window.location.search && window.location.search !== '')) {
                    controller.vm.props.history.push(menu.route);
                }
                controller.closeSubmenu();
                break;
        }
        controller.getCurrentPage();
    },

    closeSubmenu() {
        controller.vm.props.dispatch(portalAction.floatingPageUpdated(''));

        if (!window.isAgent && !window.isAffiliate) {
            if (window.SPL_LiteApp.isZT828LiteApp()) {
                window.SPL_LiteApp.onSideMenuToggle(false);
            }
        }
    },

    // special handling for selected menu
    specialHandlingForMenu(pathname) {
        switch (pathname.toLowerCase()) {
            case 'slots':
                pathname = 'slot';
                break;
            case 'promotion':
                pathname = 'promotions';
                break;
        }
        if (pathname === '') {
            pathname = 'home';
        }

        return pathname;
    },

    getCurrentPage() {
        const { location } = controller.vm.props;
        let pathname = location.pathname,
            datapathname = '',
            sliceCount = 1;

        if (pathname.startsWith('/')) {
            datapathname = pathname.split('/');
            datapathname = pathname.slice(sliceCount);
        }

        datapathname = controller.specialHandlingForMenu(datapathname);

        controller.vm.setState({ selectedMenuRoute: pathname, selectedMenuName: datapathname });
    },

    // special handling for selected menu
    specialHandlingForMenu(pathname) {
        switch (pathname.toLowerCase()) {
            // case 'slots':
            //   pathname = 'slot';
            //   break;
            case 'promotions':
                pathname = 'promotion';
                break;
            case 'esports':
                pathname = 'e-sport';
                break;
            case 'cmd368':
            case 'virtualsports-landing':
                pathname = 'sports';
                break;
            default:
                break;
        }
        if (pathname === '') {
            pathname = 'home';
        }

        return pathname;
    },

    getWelcomeBonusValue() {
        const { language } = controller.vm.props;
        const { compSettings } = controller.vm.state;

        if (compSettings && compSettings.welcomeBonus) {
            return compSettings.welcomeBonus[language.currencyLang];
        }
    },
};

export default controller;
