export const popupConstants = {
    POPUP_UPDATE: 'POPUP_UPDATE',
    SET_POPUP_VISIBLE: 'SET_POPUP_VISIBLE',
    TOGGLE_POPUP: 'TOGGLE_POPUP',
    SET_POPUP: 'SET_POPUP',
    SET_SUBMIT_SUCCESS_CB: 'SET_SUBMIT_SUCCESS_CB',
    SET_ENTERED_PIN: 'SET_ENTERED_PIN',
    SET_ITEM_CONFIG: 'SET_ITEM_CONFIG',
};

export const POPUP_VIEW_TYPE = {
    WEB_APP: 'WEB_APP',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    KYC_NOTIFICATION: 'KYC_NOTIFICATION',
    TELEGRAM_GUIDELINE_POPUP: 'TELEGRAM_GUIDELINE_POPUP',
    DEPOSIT_AMOUNT: 'DEPOSIT_AMOUNT',
};
