import React, { lazy } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import core from 'root/utils/core';

import DesktopMega from './desktop/Mega';
import MobileMega from './mobile/Mega';

class Mega extends React.Component {
    components = {
        desktop: {
            default: DesktopMega,
        },
        mobile: {
            default: MobileMega,
        },
    };

    render() {
        const TagName = core.getCorrectComponent(this.components, 'mega', this.props.screen.viewType);
        return <TagName></TagName>;
    }
}

export default connect(mapStateToProps, null)(Mega);
