import notification from 'root/utils/notification';

const controller = {
    vm: null,

    init(vm) {
        this.vm = vm;
        this.vm.state = {
            disableSubmitBtn: false,
            selectedBankObj: this.vm.props.selectedBankObj,
            method: this.vm.props.method,
        };
    },

    submitForm() {
        controller.vm.setState({ disableSubmitBtn: true });

        if (controller.vm.state.method === 'crypto') {
            window.SPL_Transaction.deleteMemberCryptoAddress(controller.vm.state.selectedBankObj.id)
                .then(() => {
                    controller.vm.setState({ disableSubmitBtn: false }, () => {
                        controller.vm.props.closeDeleteBankDialog(controller.vm.props.parentvm, true);
                    });
                })
                .catch((err) => {
                    controller.vm.setState({ disableSubmitBtn: false });
                });
        } else {
            window.SPL_Member.updateBankAccStatus(controller.vm.state.selectedBankObj.memberBankId).then((data) => {
                if (data.status === 'S') {
                    controller.vm.setState({ disableSubmitBtn: false }, () => {
                        controller.vm.props.closeDeleteBankDialog(controller.vm.props.parentvm, true);
                    });
                } else {
                    controller.vm.setState({ disableSubmitBtn: false });
                    notification.showNotification('error', controller.vm.props.t(data.errMsg));
                }
            });
        }
    },
};

export default controller;
