import React from 'react';
import context from '../context';
const lanList = [
    [
        { key: 'en_US', name: 'English' },
        { key: 'zh_CN', name: '简体中文' },
    ],
    [
        { key: 'ms_MS', name: 'Bahasa Malayu' },
        { key: 'th_TH', name: 'ไทย' },
    ],
    [
        { key: 'id_ID', name: 'Bahasa Indonesia' },
        { key: 'vi_VN', name: 'Tiếng Việt' },
    ],
];

class Language extends React.Component {
    _updateLan;
    constructor() {
        super();
        this.state = { lan: window._cacheData || 'en_US' };
    }

    updateLan(lanKey) {
        if (lanKey == this.state.lan) return;
        window._cacheData = lanKey;
        this.setState((state) => ({ lan: lanKey }));
    }

    componentWillMount() {
        this._updateLan = this.updateLan.bind(this);
        context.event.on('sync-lan', this._updateLan);
    }

    componentWillUnmount() {
        context.event.off('sync-lan', this._updateLan);
    }

    render() {
        return (
            <div className='language-list-popup'>
                <ul>
                    {lanList.map((group, j) => (
                        <li key={j + 'li'}>
                            {group.map(({ key, name }, i) => [
                                <div
                                    key={i + 'div'}
                                    onClick={() => {
                                        context.event.emit('sync-lan', key);
                                    }}
                                >
                                    <i className={'icon-' + key}></i>
                                    <span className={key == this.state.lan ? 'on' : ''}>{name}</span>
                                </div>,
                                i == 0 && <span key='divide' className='divide'></span>,
                            ])}
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}

export default Language;
