import { dropdownConstants } from '../constant';

const initialState = {
    certainElementDropdown: null,
};

export function dropdown(state = initialState, action) {
    switch (action.type) {
        case dropdownConstants.DROPDOWN_UPDATE:
            return {
                ...state,
                certainElementDropdown: action.payload.certainElementDropdown,
            };
        default:
            return state;
    }
}
