import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';

import Banner from 'root/webapp/portal/home/desktop/Banner';
import Notice from 'root/webapp/shared/component/Notice';
import HomeMemberProfile from 'root/webapp/shared/component/wap/HomeMemberProfile';
import LeftSubMenu from 'root/webapp/shared/component/wap/LeftSubMenu';
import SportsGames from 'root/webapp/portal/home/desktop/sports/SportsGames';
import UPEvents from 'root/webapp/portal/home/desktop/sports/UPEvents';
import DroppingHot from 'root/webapp/portal/home/desktop/DroppingHot';
import Donotmiss from 'root/webapp/portal/home/desktop/Donotmiss';
import AppDownload from 'root/webapp/portal/home/mobile/AppDownload';
import AnnouncementPopup from 'root/webapp/shared/announcement-popup/announcement-popup.selector';
import { CSSTransition } from 'react-transition-group';
import vm from '../home.controller';
import Ad from '../desktop/Ad';
import commonUtil from 'root/utils/common-util';
import TopWinner from '../desktop/TopWinner';
import PromoteSectionBanner from '../desktop/PromoteSectionBanner';

class Home extends React.Component {
    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        commonUtil.loadCustomTranslation('home', this, function () {
            vm.getHomePageBanner(true);
            vm.getAnnouncement('mobile');
            vm.getSportsScrollingBanner('mobile');
            vm.getHomePageOddsFeed();
            vm.getHotGames();
            vm.detemineOpenRegisterOrLoginPopup();
            vm.onHomeMounted();
            vm.getVideoSliderData();
            vm.getTopWinnerList();
        });
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        const { favoritedGame, announcementPopupList, announcementPopupVersion } = this.state;
        const { updateMemberFavoriteGame, closePopupAnnouncement } = vm;
        let favouriteSource = {
            favoritedGame: favoritedGame,
            updateMemberFavoriteGame: updateMemberFavoriteGame,
        };

        let announcementPopupSource = {
            announcementList: announcementPopupList,
            popupVersion: announcementPopupVersion,
            closePopupAnnouncement: closePopupAnnouncement,
        };

        let adsProps = {
            videoUrl: this.state.videoUrl,
            videoSlideObjList: this.state.videoSlideObjList,
            nameweeSelectorOnClick: vm.nameweeSelectorOnClick,
            activeImage: this.state.activeImage,
        };

        return (
            <Fragment>
                <div className='home-page'>
                    <Banner items={this.state.homePageBanner} />

                    {/* Announcement Popup */}
                    <CSSTransition classNames='alert' unmountOnExit timeout={300} in={this.state.announcementPopupIsShow}>
                        <AnnouncementPopup {...announcementPopupSource} />
                    </CSSTransition>

                    <Notice items={this.state.announcementsDisplay} />

                    <HomeMemberProfile />

                    <div className='sports-box'>
                        <LeftSubMenu />
                        <div className='group'>
                            <SportsGames sportsScrollingBanner={this.state.sportsScrollingBanner} />
                            <UPEvents oddsFeedArray={this.state.oddsFeedArray} />
                        </div>
                    </div>

                    {['MY', 'SG', 'TH', 'VN', 'KH', 'PH'].includes(this.props.language.country) && (
                        <Ad {...this.props} getSocialMediaUrl={vm.getSocialMediaUrl} {...adsProps} />
                    )}
                    <TopWinner topWinnerList={this.state.topWinnerList} />

                    <DroppingHot maxGameShow={10} hotGameList={this.state.hotGameList} {...favouriteSource} />

                    {/* <Donotmiss /> */}
                    <PromoteSectionBanner {...this.props} affiliateOnClick={vm.affiliateOnClick} />
                    {!window.SPL_LiteApp.isZT828LiteApp() && <AppDownload />}
                </div>
            </Fragment>
        );
    }
}

export default connect(mapStateToProps)(withTranslation('home')(withRouter(Home)));
