import commonUtil from '../../../../../../utils/common-util';
import { trans_handlePGDepositCountDown } from 'root/utils/transaction-util';
import notification from 'root/utils/notification';

const METHOD_OR_CHANNEL_CODE = 'MC';
const controller = {
    vm: null,

    init(vm) {
        controller.vm = vm;
        controller.vm.state = {
            filteredDepositApiJson: controller.vm.props.filteredDepositApiJson,
            depositAllSettings: controller.vm.props.depositSettings,
            compSettings: null,
            specialNoticeContent: null,

            depositMinMaxLimit: { min: 0, max: 0 },
            methodList: [],
            selectedMethodIndex: 0,
            selectedBankIndex: 0,

            // should be can put at parent de variables
            fields: {
                amount: null,
                reference: '',
            },
            errors: {},
            disableDepositButton: false,
            isFormValid: false,
            selectedMethodCache: {},
            depositSubmitCountdown: null,
        };
    },

    initCompSettings() {
        const { filteredDepositApiJson } = controller.vm.state;
        const { customCountdown } = controller.vm.props;
        const paymentCode = filteredDepositApiJson && filteredDepositApiJson.optionCode;

        return new Promise((resolve) => {
            let compSettings = {};

            controller.vm.setState({ compSettings }, () => {
                trans_handlePGDepositCountDown(controller.vm, false, customCountdown, paymentCode, Date.now().valueOf(), { disableDepositButton: true });
                resolve();
            });
        });
    },

    loadDepositDetails() {
        return new Promise((resolve) => {
            const { filteredDepositApiJson } = controller.vm.state;

            // if enableJsonAPi true from deposit-landing
            if (filteredDepositApiJson && filteredDepositApiJson.channel) {
                controller.newDepositMethod();
            }
        });
    },

    async newDepositMethod() {
        const { filteredDepositApiJson } = controller.vm.state;
        let _methodList = [];

        if (filteredDepositApiJson?.optionCode !== METHOD_OR_CHANNEL_CODE) {
            return;
        }

        for (let channel in filteredDepositApiJson.channel) {
            if (filteredDepositApiJson.channel[channel]) {
                let method = filteredDepositApiJson.channel[channel][0];

                if (method.code === 'MGP2P') {
                    method.displayName = method.displayName ? method.displayName : 'Mango Pay';
                } else if (method.code === 'TP2P') {
                    method.displayName = method.displayName ? method.displayName : 'TPAY';
                } else {
                    method.displayName = method.displayName ? method.displayName : window.SPL_Translation.getBankChannelTranslation(method, 'channelCode');
                }

                // method.svgData = await window.SPL_Other.renderTemplateSVG(`${commonUtil.getDepositImgPath()}${method.code}.svg`, `${method.code}`);
                _methodList.push(method);
            }
        }

        if (_methodList.length > 0) {
            controller.vm.setState({ methodList: _methodList }, () => {
                commonUtil.getDepositMinMax(
                    controller.vm,
                    'MC',
                    controller.vm.props.user.account.currency,
                    controller.vm.state.methodList[controller.vm.state.selectedMethodIndex].code
                );
                controller.setDefaultCheckedValue();
                controller.getSpecialDepositNotice();
            });
        }
    },

    onMethodChanged(e) {
        let methodList = controller.vm.state.methodList;
        let selectedMethodIndex = null;
        if (e && e.target) {
            selectedMethodIndex = e.target.value;
        } else if (e || e === 0) {
            for (let i in methodList) {
                if (i === e) {
                    methodList[i].isChecked = true;
                } else {
                    methodList[i].isChecked = false;
                }
            }
            selectedMethodIndex = e;
        }

        controller.vm.setState({ selectedMethodIndex, methodList }, () => {
            commonUtil.getDepositMinMax(
                controller.vm,
                'MC',
                controller.vm.props.user.account.currency,
                controller.vm.state.methodList[controller.vm.state.selectedMethodIndex].code
            );
            controller.getSpecialDepositNotice();
        });
    },

    getSpecialDepositNotice() {
        const { methodList, selectedMethodIndex } = controller.vm.state;
        const { screen } = controller.vm.props;
        let methodCode = methodList[selectedMethodIndex].code;

        //check special deposit
        window.SPL_Content.readSpecialDepositNotice(screen.viewType).then((res) => {
            if (res) {
                let content = commonUtil.getContentByCurrencyLanguage(controller.vm, res.content.content);

                controller.vm.setState({
                    specialNoticeContent: content.content[methodCode] ? content.content[methodCode] : content.content && content.content.default,
                });
            }
        });
    },

    setDefaultCheckedValue() {
        const { selectedMethodIndex, methodList } = controller.vm.state;

        if (selectedMethodIndex === 0 && methodList.length > 0) {
            methodList[0].isChecked = true;
        }

        controller.vm.setState({ methodList });
    },

    submitForm() {
        const vm = controller.vm;
        const { methodList, selectedMethodIndex, depositSubmitCountdown } = vm.state;
        const { language, filteredDepositApiJson, depositSubmission, t, customCountdown } = vm.props;

        const countdown = customCountdown || typeof customCountdown === 'number' ? customCountdown : 30;
        const paymentCode = filteredDepositApiJson && filteredDepositApiJson.optionCode;
        const submissionData = depositSubmission && depositSubmission.submissionData;
        const paymentObj = submissionData && submissionData[paymentCode];

        if (paymentObj && paymentObj.submissionRetryTime > Date.now().valueOf()) {
            // controller.vm.setState({ disableDepositButton: true });
            notification.showNotification('error', t('transaction:transaction.deposit.resubmitError', { retryTime: depositSubmitCountdown }));
        } else {
            proceedSubmit();
        }

        function proceedSubmit() {
            let methodCode = methodList[selectedMethodIndex].code;
            let methodId = methodList[selectedMethodIndex].id;
            let langPath = language.countryLanguageKey.replace('_', '-').toLowerCase();
            let callbackUrl = `/${langPath}/myaccount/deposit`;
            let stateUrl = `/${langPath}/mobilecardredirect`;

            let methodParam = {
                id: methodId,
                code: methodCode,
            };

            window.SPL_Transaction.requestDepositOnlineTransfer(methodParam, methodParam.code, null, null, null, stateUrl, language.key, callbackUrl, null, null).then(
                (data) => {
                    if (data.errorCode) {
                        notification.showNotification('error', data.message);
                    } else {
                        trans_handlePGDepositCountDown(controller.vm, true, countdown, paymentCode, Date.now().valueOf(), { disableDepositButton: false });
                    }
                }
            );
        }
    },
};

export default controller;
