import { floatingConstant } from '../constant';
export const floatingAction = {
    getAfterLoginNotification,
    getBeforeLoginNotification,
    storeFloatingNotificationId,
    getCloseNotification,
};

function getBeforeLoginNotification(settings) {
    return (dispatch) => {
        dispatch({ type: floatingConstant.GET_BEFORE_LOGIN_NOTIFICATION, payload: settings });
    };
}

function getAfterLoginNotification(settings) {
    return (dispatch) => {
        dispatch({ type: floatingConstant.GET_AFTER_LOGIN_NOTIFICATION, payload: settings });
    };
}

function storeFloatingNotificationId(id) {
    return (dispatch) => {
        dispatch({ type: floatingConstant.STORE_FLOATING_NOTIFICATION_ID, payload: id });
    };
}

function getCloseNotification(data) {
    return (dispatch) => {
        dispatch({ type: floatingConstant.GET_CLOSE_NOTIFICATION, payload: data });
    };
}
