import React from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { TweenLite, TweenMax } from 'gsap';

const aniList = [];
class FastGamesBanner extends React.Component {
    constructor() {
        super();

        this.state = {
            amount: 1006795.66,
            currency: '',
            jackpotList: {},
            jackpotTimer: null,
        };
    }

    componentWillMount() {
        this.doSuspendAni();
    }

    componentWillUnmount() {
        if (this.state.jackpotTimer) {
            clearInterval(this.state.jackpotTimer);
            this.setState({ jackpotTimer: null });
        }
    }

    componentDidMount() {
        const { user, language } = this.props;
        let currency = '';
        if (user.isLogin) {
            currency = user.account.currency;
        } else {
            currency = language.currencyLang;
        }
        let vm = this;
        setTimeout(function () {
            vm.setState({ currency: currency }, () => {
                let jackpotList = vm.getItem(vm.props.t);
                vm.setState({ jackpotList: jackpotList });

                let jackpotTimer = setInterval(function () {
                    let value = vm.state.amount;
                    const randomNum = vm.genRand(0.01, 0.99, 2);
                    let newNumber = (parseFloat(value) + randomNum).toFixed(2);
                    let lastDigit = newNumber.slice(-1);
                    if (parseInt(lastDigit) === 0) {
                        newNumber = parseFloat(newNumber) + 0.11;
                    }
                    value = newNumber;

                    vm.setState({ amount: newNumber }, () => {
                        let newJackpotList = vm.getItem(vm.props.t);
                        vm.setState({ jackpotList: newJackpotList });
                    });
                }, 6150);

                vm.setState({ jackpotTimer: jackpotTimer });
            });
        }, 500);
    }

    genRand(min, max, decimalPlaces) {
        var rand = Math.random() * (max - min) + min;
        var power = Math.pow(10, decimalPlaces);
        return Math.floor(rand * power) / power;
    }

    runNumber() {
        let x = { n: 0 };
        let nn = x.n;
        TweenLite.to(x, 10, {
            n: 100,
            onUpdate: () => {
                this.setState((state) => ({
                    amount: state.amount + x.n - nn,
                }));
                nn = x.n;
            },
        });
    }

    format(num) {
        let strs = num.toFixed(2).split('.');
        let str = strs[0];

        let len = str.length;
        let n = Math.floor(len / 3);
        let nf = len % 3;

        let res = str.substr(0, nf);
        for (let i = 0; i < n; i++) {
            res = res + ',' + str.substr(nf + i * 3, 3);
        }
        res = res + '.' + strs[1];
        res = nf > 0 ? res : res.substr(1);
        return res;
    }

    doSuspendAni() {
        aniList.forEach((item) => item.kill());
        aniList.length = 0;
        const randomItem = (list) => {
            return list[0 + Math.round(Math.random() * (list.length - 1 - 0))];
        };
        const idList = ['#suspend-fg-1', '#suspend-fg-2', '#suspend-fg-3', '#suspend-fg-4'];
        const endStyleStore = [];

        TweenMax.delayedCall(0.05, () => {
            idList.map((id, i) => {
                const $item = document.querySelector(id);
                endStyleStore[i] = {
                    left: getComputedStyle($item).left,
                    bottom: getComputedStyle($item).bottom,
                };
                TweenMax.set(id, {
                    x: -parseFloat(getComputedStyle($item).width) / 2,
                    y: 0,
                    bottom: 0,
                    left: '50%',
                    opacity: 0,
                    rotation: [0, 3].includes(i) ? 0 : randomItem([-80, -40, 40, 80, 100]),
                    onComplete: () => {
                        TweenMax.set(id, {
                            left: getComputedStyle($item).left,
                        });

                        aniList.push(
                            TweenMax.to(id, 0.8, {
                                x: 0,
                                y: 0,
                                left: endStyleStore[i].left,
                                bottom: endStyleStore[i].bottom,
                                opacity: 1,
                                rotation: 0,
                                delay: 0.2 * i + 0.5,
                                onComplete: () => {
                                    TweenMax.set(id, {
                                        left: '',
                                        bottom: '',
                                    });

                                    if (i === idList.length - 1) {
                                        idList.map((id) => {
                                            aniList.push(
                                                TweenMax.to(id, randomItem([5, 6, 7]), {
                                                    x: `${randomItem(['+', '-'])}=${randomItem([5, 15, 25])}`,
                                                    y: `${randomItem(['+', '-'])}=${randomItem([5, 15, 25])}`,
                                                    rotation: randomItem([15, 10, -10, -5]),
                                                    yoyo: true,
                                                    repeat: -1,
                                                })
                                            );
                                        });
                                    }
                                },
                            })
                        );
                    },
                });
            });
        });
    }

    getItem(t) {
        return {
            left: [],
            center: [
                {
                    content: (
                        <div>
                            <span>{t('global:global.progressiveJackpot', 'PROGRESSIVE JACKPOT')}</span>
                            <span>{window.SPL_Other.formatAmount(this.state.amount)}</span>
                            {/* <span>{this.state.currency} {this.format(this.state.amount)}</span> */}
                            <LazyLoadImage src='/public/html/images/slots/logo.png' alt='' />
                        </div>
                    ),
                },
                {
                    content: (
                        <div>
                            <span>{t('global:global.progressiveJackpot', 'PROGRESSIVE JACKPOT')}</span>
                            <span>{window.SPL_Other.formatAmount(this.state.amount)}</span>
                            {/* <span>{this.state.currency} {this.format(this.state.amount)}</span> */}
                            <LazyLoadImage src='/public/html/images/slots/logo.png' alt='' />
                        </div>
                    ),
                },
            ],
            right: [],
        };
    }

    render() {
        return (
            <Translation>
                {(t) => (
                    <div className='provider-banner provider-banner-fast-games'>
                        <div className='container'>
                            <div className='suspend' id='suspend-fg-1'>
                                <LazyLoadImage src='/public/html/images/fast-games/icon/suspend/suspend_1.png' alt='' />
                            </div>
                            <div className='suspend' id='suspend-fg-2'>
                                <LazyLoadImage src='/public/html/images/fast-games/icon/suspend/suspend_2.png' alt='' />
                            </div>
                            <div className='suspend' id='suspend-fg-3'>
                                <LazyLoadImage src='/public/html/images/fast-games/icon/suspend/suspend_3.png' alt='' />
                            </div>
                            <div className='suspend' id='suspend-fg-4'>
                                <LazyLoadImage src='/public/html/images/fast-games/icon/suspend/suspend_4.png' alt='' />
                            </div>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['slot', 'global'])(withRouter(FastGamesBanner)));
