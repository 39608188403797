import React from 'react';

const list = [
    { key: 'whatsapp', value: '018 888 8888' },
    { key: 'wechat', value: 'ME88CS.Help' },
    { key: 'skype', value: 'ME88CS.Help' },
    { key: 'download', value: 'Download' },
];

class ContactUs extends React.Component {
    render() {
        return (
            <div className='contact-popup'>
                <ul>
                    {list.map(({ key, value }) => (
                        <li key={key}>
                            <span className='icon-box'>
                                <i className={'icon-' + key}></i>
                            </span>

                            <span>{value}</span>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}

export default ContactUs;
