import React, { Fragment } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SVGElements from 'root/components/SVGElements';

const controller = {
    vm: null,
    init(vm) {
        this.vm = vm;
        this.vm.state = {
            list: [],
            html: '<div></div>',
            landingTranslate: null,
            table: [],
            memberGroupImg: '',
            progressBarPercentage: 0,
            memberGroupLevel: [],
            memberGroupLevelMessages: [],
            navigation: <SVGElements className='icon-arrow-prev' name='arrow-down-icon' />,
            bannerIcon: [
                { left: -50, bottom: 120, width: 89, height: 100, suspendImg: '/public/html/images/vip/suspend/1_1.png' },
                { left: 50, bottom: 20, width: 130, height: 98, suspendImg: '/public/html/images/vip/suspend/1_2.png' },
            ],
        };
    },

    getVipBannerElement() {
        const { t, portal, language } = controller.vm.props;
        let vipContentBaseLangKey = portal.settings.vipContent[language.key.toLowerCase()],
            vipDesc1 = 'vip:vip.desc1',
            vipDesc2 = 'vip:vip.desc2',
            vipDesc3 = 'vip:vip.desc3';

        if (portal.settings.vipContent && vipContentBaseLangKey) {
            if (vipContentBaseLangKey.desc_1) {
                vipDesc1 = vipContentBaseLangKey.desc_1;
            }

            if (vipContentBaseLangKey.desc_2) {
                vipDesc2 = vipContentBaseLangKey.desc_2;
            }

            if (vipContentBaseLangKey.desc_3) {
                vipDesc3 = vipContentBaseLangKey.desc_3;
            }
        }

        return new Promise(function (resolve) {
            let list = [];
            let bannerElement = generateVipBannerElement();

            list.push(bannerElement);
            controller.vm.setState({ list: list });
            resolve(controller.vm.state.bannerIcon);
        });

        function generateVipBannerElement() {
            return {
                content: (
                    <div>
                        <LazyLoadImage src='/public/html/images/vip/vip-banner.jpg' alt='' />

                        <div className='container'>
                            <div className='vip-suspend'>
                                {controller.vm.state.bannerIcon.map((item, j) => (
                                    <div id={`suspend_0_${j}`} className={['suspend'].join(' ')} key={`1_${j + 2}`}>
                                        <LazyLoadImage src={item.suspendImg} alt='' />
                                    </div>
                                ))}
                            </div>
                            <div className='content'>
                                <p className='title'>{t('vip:vip.vipProgram', 'VIP PROGRAM')}</p>

                                <div className='desc'>
                                    <p>{t(vipDesc1)}</p>
                                    <p>{t(vipDesc2)}</p>
                                    <p>{t(vipDesc3)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                ),
            };
        }
    },

    loadMemberGroupAutomationSummary() {
        const { key } = controller.vm.props.language;
        const { account, isLogin } = controller.vm.props.user;
        let lang_key = key.toLowerCase();

        if (isLogin) {
            let dataPercentage = 0,
                groupName = account.groupName.toUpperCase();

            if (groupName.includes('SILVER')) {
                groupName = 'SILVER';
            } else if (groupName.includes('GOLD')) {
                groupName = 'GOLD';
            } else if (groupName.includes('PLATINUM')) {
                groupName = 'PLATINUM';
            } else if (groupName.includes('DIAMOND')) {
                groupName = 'DIAMOND';
            } else if (groupName.includes('ROYAL')) {
                groupName = 'ROYAL';
            } else if (groupName.includes('ROSE')) {
                groupName = 'ROSE';
            }

            window.SPL_Member.loadMemberGroupAutomationSummary(false, account.currency, groupName, account.imagePath, lang_key).then((data) => {
                controller.vm.setState({
                    memberGroupImg: data.userGroupImage,
                });
            });
        } else {
            controller.vm.setState({ memberGroupImg: '/public/html/images/vip/hand-silver.png' });
        }
    },

    loadVipLandingPage() {
        const { language, screen, portal } = controller.vm.props;
        const { account, isLogin } = controller.vm.props.user;
        let langCurrency = language.currencyLang;

        if (isLogin && account) {
            langCurrency = account.currency;
        }

        if (language && language.key) {
            window.SPL_Content.loadVipLandingPage(langCurrency, language.key.toLowerCase(), portal.settings).then((data) => {
                controller.vm.setState({ html: data.html, landingTranslate: data.landingTranslate, table: data.table }, () => {
                    if (screen.viewType === 'mobile') {
                        controller.getVipCardElement();
                    }
                });
            });
        }
    },

    // get level msg and init progressbar
    getLevelMsg() {
        const { currencyLang, key } = controller.vm.props.language;
        const { user } = controller.vm.props;
        let path = '/public/html/settings/member-settings.json',
            memberLevel = {};

        window.SPL_JsonSettings.getMemberSettingsJson(function (json) {
            if (json && json.memberGroupLevelMessages) {
                let memberLevelGroup = json.memberGroupLevelMessages,
                    groupLevelKey =
                        Object.keys(memberLevelGroup) && Object.keys(memberLevelGroup).filter((group) => memberLevelGroup[group] && memberLevelGroup[group][currencyLang]);

                if (groupLevelKey) {
                    for (let i = 0; i < groupLevelKey.length; i++) {
                        memberLevel[groupLevelKey[i]] = memberLevelGroup[groupLevelKey[i]][currencyLang][key.toLowerCase()];
                        memberLevel[groupLevelKey[i] + '-translate'] = memberLevelGroup[groupLevelKey[i]]['levelTranslate'][key.toLowerCase()];
                    }

                    controller.vm.state.memberGroupLevel = groupLevelKey;
                    controller.vm.state.memberGroupLevelMessages.push(memberLevel);

                    //initprogressBar
                    if (user && user.account && user.account.groupName) {
                        // minus additionalOffset in order to get center
                        let totalGroup = groupLevelKey.length;
                        let oneSectionWidth = 100 / totalGroup;
                        let additionalOffset = oneSectionWidth / 2;

                        let groupIndex = -1;
                        for (let i = 0; i < groupLevelKey.length; i++) {
                            if (user.account.groupName.includes(groupLevelKey[i])) {
                                groupIndex = i;
                                break;
                            }
                        }
                        if (groupIndex >= 0) {
                            let displayIndex = parseInt(groupIndex) + 1;
                            controller.vm.setState({ progressBarPercentage: displayIndex * oneSectionWidth - additionalOffset + '%' });
                        } else {
                            controller.vm.setState({ progressBarPercentage: 0 });
                        }
                    }
                }
            }
        });
    },

    getVipCardElement() {
        const { memberGroupLevel } = controller.vm.state;
        let list = [];

        for (let i = 0; i < memberGroupLevel.length; i++) {
            let level = memberGroupLevel[i];
            let cardElement = controller.generateVipCardElement(level, i);

            list.push(cardElement);
        }

        controller.vm.setState({ list: list });
    },

    generateVipCardElement(level, indexLevel) {
        const { memberGroupLevelMessages, landingTranslate, table } = controller.vm.state;
        const { currencyLang, countryLanguageKey } = controller.vm.props.language;
        return {
            content: (
                <div key={indexLevel}>
                    <div className='vip-type'>
                        <div className='type-desc'>
                            {/* <p className='type-title' dangerouslySetInnerHTML={{ __html: landingTranslate.table.sub_title }}></p> */}
                            {memberGroupLevelMessages.map((msg) => (
                                <p key={msg} className='type-remark' dangerouslySetInnerHTML={{ __html: msg[level] }}></p>
                            ))}
                        </div>

                        <div className='type-card'>
                            <LazyLoadImage src={'/public/html/images/vip/mobile/' + level.toLowerCase() + '-card.png'} />
                        </div>
                    </div>

                    <div className={`type-details ${countryLanguageKey}-type-details`}>
                        {table.map((content, indexTable) => (
                            <Fragment key={indexTable}>
                                {/* {content.title && <p className='details-title' dangerouslySetInnerHTML={{ __html: landingTranslate.table.category[content.title] }}></p>} */}
                                {content.data.map((row, indexRow) => (
                                    <div
                                        key={indexRow}
                                        className={`details-content
                                    ${
                                        // countryLanguageKey === 'EN_MY' || countryLanguageKey === 'EN_SG' || countryLanguageKey === 'MS_MY'
                                        //     ? 'EN-details-content'
                                        //     : countryLanguageKey === 'TH_TH' || countryLanguageKey === 'EN_TH'
                                        //     ? 'TH-details-content'
                                        //     : countryLanguageKey === 'ZH_MY' || countryLanguageKey === 'ZH_SG'
                                        //     ? 'ZH-details-content'
                                        //     : countryLanguageKey === 'EN_VN'
                                        //     ? 'VND-EN-details-content'
                                        //     : countryLanguageKey === 'VI_VN'
                                        //     ? 'VND-EN-details-content'
                                        //     : ''
                                        currencyLang === 'MYR'
                                            ? 'MYR-details-content'
                                            : currencyLang === 'SGD'
                                            ? 'SGD-details-content'
                                            : currencyLang === 'THB'
                                            ? 'THB-details-content'
                                            : currencyLang === 'VND'
                                            ? 'VND-details-content'
                                            : currencyLang === 'PHP'
                                            ? 'PHP-details-content'
                                            : currencyLang === 'USD' || currencyLang === 'KHUSD'
                                            ? 'USD-details-content'
                                            : ''
                                    }`}
                                    >
                                        {/* {row.colTitle && <p dangerouslySetInnerHTML={{ __html: landingTranslate.table.col[row.colTitle] }}></p>} */}
                                        {row.col.map(
                                            (col, indexCol) =>
                                                indexLevel === indexCol &&
                                                (controller.isEnableCol1(col) ? (
                                                    <p key={indexCol} dangerouslySetInnerHTML={{ __html: landingTranslate.table.col[col] }}></p>
                                                ) : (
                                                    <p key={indexCol} dangerouslySetInnerHTML={{ __html: col ? col : '-' }}></p>
                                                ))
                                        )}
                                    </div>
                                ))}
                            </Fragment>
                        ))}
                    </div>
                </div>
            ),
        };
    },

    getLevelCard(type) {
        const { language } = controller.vm.props;
        const { account, isLogin } = controller.vm.props.user;
        let country = '',
            accCurrency = '';

        if (isLogin && account) {
            switch (account.currency.toLowerCase()) {
                case 'myr':
                    accCurrency = 'my';
                    break;

                case 'sgd':
                    accCurrency = 'sg';
                    break;

                case 'thb':
                    accCurrency = 'th';
                    break;

                case 'vnd':
                    accCurrency = 'vn';
                    break;

                case 'php':
                    accCurrency = 'ph';
                    break;

                case 'usd':
                case 'khusd':
                    accCurrency = 'kh';
                    break;

                default:
                    break;
            }

            country = accCurrency;
        } else {
            country = language.country.toLowerCase();
        }

        let src = '/public/html/images/vip/icon-' + type + '-' + country + '.png';

        return src;
    },

    isEnableCol1(col) {
        return (
            col == 'yes' ||
            col == 'high' ||
            col == 'first' ||
            col == 'lifeTime' ||
            col == '3Mon' ||
            col == 'spInv1' ||
            col == 'spInv3' ||
            col == 'firstTimeDeposit' ||
            col == 'unlimited' ||
            col == 'monthly' ||
            col == 'inviteOnly' ||
            col == 'quarterly' ||
            col == 'invitation' ||
            col == 'priority'
        );
    },

    isEnableCol2(col) {
        return col == 'spInv2' || col == 'spInv4' || col == 'comingSoon' || col == 'identityVerfDesc';
    },
};

export default controller;
