import React, { Fragment } from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { MdClose } from 'react-icons/md';
import SVGTransfer from 'root/components/svgIcon/svgTransfer';
import SmallLoading from 'root/components/smallLoading';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { CSSTransition } from 'react-transition-group';
import SVGElements from 'root/components/SVGElements';
import SVGProvider from 'root/components/svgIcon/svgProvider';
import SVG from 'react-inlinesvg';
import PromotionTransfer from '../../../../components/organisms/promotion-transfer/promotion-transfer';

class PromoTransfer extends React.Component {
    constructor(props) {
        super();
        this.props = props;
        this.state = {
            itemIndex: 0,
            typeIndex: 0,
        };
    }

    componentDidUpdate() {
        if (this.state.typeIndex !== this.props.typeIndex) {
            this.setState({ typeIndex: this.props.typeIndex });
        }
        if (this.state.itemIndex !== this.props.itemIndex) {
            this.setState({ itemIndex: this.props.itemIndex });
        }
    }

    render() {
        const {
            promoInfo,
            mainWallet,
            restoreOnLoad,
            selectedProvider,
            selectedProviderImage,
            selectedProviderBalance,
            providerDropDown,
            providerList,
            transferAmount,
            transferSliderMin,
            transferSliderMax,
            targetTurnover,
            bonusAmount,
            toggleModal,
            restoreWallet,
            toogleDropdown,
            handleProviderSelect,
            handleTypeChange,
            handleFieldChanged,
            handleRequestTransfer,
            categorizedProviderListByMoreType,
        } = this.props;

        let userCurrency = this.props.user && this.props.user.account && this.props.user.account.currency ? this.props.user.account.currency : null;

        if (userCurrency === 'KHUSD') userCurrency = 'USD';

        return (
            <Translation>
                {(t) => (
                    // <div className='promosDetail-popup promosTransfer-popup'>
                    //     <div className='popup'>
                    //         <div className='popup-header' style={{ backgroundColor: promoInfo.line }}>
                    //             <div className='popup-header-left'>
                    //                 <span className='popup-title'>{t('promotion:promotion.apply-promo')}</span>
                    //             </div>

                    //             <div className='popup-header-right'>
                    //                 <MdClose style={{ fill: promoInfo.line }} onClick={() => toggleModal('')} />
                    //             </div>
                    //         </div>

                    //         <div className='popup-body'>
                    //             <ul className='promo-wallet'>
                    //                 <div className='promo-wallet-title'> {t('promotion:promotion.transferCredit', 'Transfer Credit')}</div>
                    //                 <div className='wallet'>
                    //                     <div className='main-wallet'>
                    //                         <SVGTransfer name='mainWalletV2' className='promo-icon-wallet' />

                    //                         <div className='wallet-info'>
                    //                             <span className='mainWallet-title'>{t('global:global.form.mainWallet')}</span>
                    //                             <div className='amount-wrapper'>
                    //                                 <span className='amount-currency'>{userCurrency}</span>
                    //                                 {mainWallet && <span className='amount-value'>{window.SPL_Other.formatAmount(mainWallet.balance)}</span>}
                    //                             </div>
                    //                         </div>

                    //                         {restoreOnLoad ? (
                    //                             <SmallLoading></SmallLoading>
                    //                         ) : (
                    //                             <span className='promo-icon-restore'>
                    //                                 <CSSTransition classNames='rotate360' in={restoreOnLoad} timeout={1000}>
                    //                                     {/* <i className='icon-refesh' onClick={() => restoreWallet()}></i> */}
                    //                                     <SVGElements name='reload-icon' onClick={() => restoreWallet()} />
                    //                                 </CSSTransition>
                    //                             </span>
                    //                         )}
                    //                     </div>

                    //                     <LazyLoadImage className='promo-icon-arrow' src={'/public/html/images/promotion/arrow.svg'} />

                    //                     <div className='provider-wallet'>
                    //                         {/* <LazyLoadImage className='provider-selected' src={selectedProviderImage} /> */}
                    //                         <SVG src={`/public/html/images/promotion/${selectedProvider}.svg`} className='provider-selected'>
                    //                             <SVGProvider className={`${selectedProvider} provider-selected`} name={selectedProvider} />
                    //                         </SVG>
                    //                         <div className='wallet-info'>
                    //                             <span>
                    //                                 {selectedProvider} {t('popupTransfer:popupTransfer.wallet', 'Wallet')}
                    //                             </span>
                    //                             <div className='amount-wrapper'>
                    //                                 <span className='amount-currency'>{userCurrency}</span>
                    //                                 <span className='amount-value'>
                    //                                     {selectedProviderBalance ? window.SPL_Other.formatAmount(selectedProviderBalance) : window.SPL_Other.formatAmount(0.0)}
                    //                                 </span>
                    //                             </div>
                    //                         </div>

                    //                         {/* this part like no use dao */}
                    //                         {providerDropDown && (
                    //                             <div className='provider-list'>
                    //                                 {providerList.map(
                    //                                     (provider) =>
                    //                                         provider.name && (
                    //                                             <LazyLoadImage
                    //                                                 key={provider.provider}
                    //                                                 onClick={() => {
                    //                                                     handleProviderSelect(provider);
                    //                                                     toogleDropdown();
                    //                                                 }}
                    //                                                 className='provider-icon'
                    //                                                 src={selectedProviderImage}
                    //                                                 style={{ border: '1px solid' + promoInfo.line }}
                    //                                             />
                    //                                         )
                    //                                 )}
                    //                             </div>
                    //                         )}
                    //                     </div>
                    //                 </div>

                    //                 <div className='amount'>
                    //                     <p className='amount-title'>{t('popupTransfer:popupTransfer.transferAmount')}</p>

                    //                     <div className='amount-input'>
                    //                         <span>{userCurrency}</span>

                    //                         <input
                    //                             type='number'
                    //                             id='transferamount'
                    //                             value={transferAmount}
                    //                             placeholder={`0`}
                    //                             onChange={(e) => handleFieldChanged(e, 'transferAmount')}
                    //                         />
                    //                     </div>

                    //                     <div className='amount-range'>
                    //                         <input
                    //                             id='transfer-range'
                    //                             type='range'
                    //                             className='range-box'
                    //                             min={transferSliderMin}
                    //                             max={transferSliderMax}
                    //                             value={transferAmount}
                    //                             onChange={(e) => handleFieldChanged(e, 'transferAmount')}
                    //                         />
                    //                         {/* <span className='amount-info'>
                    //                             {this.props.user.account.currency} {transferAmount}
                    //                         </span> */}
                    //                     </div>
                    //                 </div>

                    //                 <div className='turnover-bonus'>
                    //                     <p className={`promo-title`}>{t('global:global.form.promocode')}</p>

                    //                     <p type='number' className={`promo-input`} id='transferamount'>
                    //                         <span>{promoInfo.title}</span>
                    //                     </p>
                    //                 </div>
                    //             </ul>

                    //             {/* --------------- Promo Provider --------------- */}
                    //             <ul className='promo-provider'>
                    //                 {/* <li className='provider-title'>{t('popupTransfer:popupTransfer.selectProvider', 'Select Provider')}</li> */}

                    //                 <div className='type-container'>
                    //                     <ul className='type-list'>
                    //                         {categorizedProviderListByMoreType.length ? (
                    //                             categorizedProviderListByMoreType.map(
                    //                                 ({ name, title, key, list }, i) =>
                    //                                     list.length > 0 && (
                    //                                         <li
                    //                                             key={i}
                    //                                             className={[i === this.state.typeIndex ? 'on' : ''].join(' ')}
                    //                                             onClick={() => {
                    //                                                 handleTypeChange(i);
                    //                                             }}
                    //                                         >
                    //                                             <span className='icon-box'>
                    //                                                 <SVGTransfer
                    //                                                     name={key}
                    //                                                     className={`${key}-svg`}
                    //                                                     color='#bcbcbc'
                    //                                                     on={i === this.state.typeIndex}
                    //                                                     onColor={'#000'}
                    //                                                 />
                    //                                             </span>
                    //                                             <span>{title}</span>
                    //                                         </li>
                    //                                     )
                    //                             )
                    //                         ) : (
                    //                             <SmallLoading></SmallLoading>
                    //                         )}
                    //                     </ul>
                    //                 </div>

                    //                 <div className='provider-container'>
                    //                     <ul className='provider-list'>
                    //                         {categorizedProviderListByMoreType.length ? (
                    //                             categorizedProviderListByMoreType[this.state.typeIndex].list.map((provider, i) => (
                    //                                 <li
                    //                                     key={i}
                    //                                     className={[i === this.state.itemIndex ? 'on' : ''].join(' ')}
                    //                                     onClick={() => {
                    //                                         handleProviderSelect(provider, i);
                    //                                     }}
                    //                                 >
                    //                                     <span>{provider.name}</span>
                    //                                 </li>
                    //                             ))
                    //                         ) : (
                    //                             <SmallLoading />
                    //                         )}
                    //                     </ul>
                    //                 </div>

                    //                 <div className='btn-container'>
                    //                     <button
                    //                         disabled=''
                    //                         type='submit'
                    //                         className='popup-btn'
                    //                         style={{ backgroundColor: promoInfo.line }}
                    //                         onClick={() => handleRequestTransfer()}
                    //                     >
                    //                         {t('message:message.confirm', 'Confirm')}
                    //                     </button>

                    //                     <button disabled='' type='submit' className={`popup-btn`} onClick={() => toggleModal('')}>
                    //                         {t('global:entity.action.cancel', 'Cancel')}
                    //                     </button>
                    //                 </div>
                    //             </ul>
                    //         </div>

                    //         {/* <div className='popup-footer' style={{ borderTop: '1px solid' + promoInfo.line }}>
                    //             <div className='popup-footer-left'></div>

                    //             <div className='popup-footer-right'>
                    //                 <button
                    //                     disabled=''
                    //                     type='submit'
                    //                     className='popup-btn'
                    //                     style={{ backgroundColor: promoInfo.line }}
                    //                     onClick={() => handleRequestTransfer()}
                    //                 >
                    //                     {t('transaction:transaction.history.filter.submit', 'Submit').toUpperCase()}
                    //                 </button>
                    //             </div>
                    //         </div> */}
                    //     </div>

                    //     <div className='popup-overlay' onClick={() => toggleModal('')}></div>
                    // </div>
                    <PromotionTransfer applyDetails={promoInfo} onApplyClicked={() => toggleModal('')} filteredProviderData={categorizedProviderListByMoreType} />
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['popupTransfer', 'settings', 'transaction'])(withRouter(PromoTransfer)));
