import React from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import Slider from 'root/webapp/shared/component/Slider';
import SliderMobile from 'root/webapp/shared/component/Slider2';
import SVGElements from 'root/components/SVGElements';

const PromoteSectionBanner = (props) => {
    const languageState = props.language.country;
    const mobile = props.screen.viewType === 'mobile' ? true : false;
    const languageKey = props.language.key;

    return (
        <Translation>
            {(t) => (
                <div className='live-tv-container'>
                    <div className='container'>
                        <LiveTv t={t} mobile={mobile} languageKey={languageKey} />
                        {languageState && languageState === 'VN' ? <Affiliate t={t} mobile={mobile} props={props} /> : <Testimonial mobile={mobile} t={t} />}
                    </div>
                </div>
            )}
        </Translation>
    );
};

export default connect(mapStateToProps)(withTranslation(['global'])(withRouter(PromoteSectionBanner)));

const Testimonial = ({ t, mobile }) => {
    const slidesContent = renderSlide({ mobile });

    const navigation = (
        <div className='icon'>
            <SVGElements className='icon-arrow-prev' name='left-arrow' />
        </div>
    );

    return (
        <div className='testimonial'>
            {mobile && <p className='test'>{t(`global:global.testimonial.testimonial-title`)}</p>}
            {mobile ? (
                <SliderMobile activedIndex={0} space={'0.31rem'} enableClick={false} autoPlay={false} items={slidesContent} />
            ) : (
                <Slider autoPlay={false} checkOnclick={true} navigation={navigation} items={slidesContent} transionName='slider-fade' />
            )}
        </div>
    );
};

const LiveTv = ({ t, mobile, languageKey }) => {
    const filename = '/public/html/images/live-tv/';
    const backgroundImg = mobile ? `${filename}m-live-tv-background-${languageKey}.png` : `${filename}live-tv-background-${languageKey}.png`;
    const logoImg = `${filename}live-tv-logo.jpg`;
    const iosImg = `${filename}live-tv-ios.jpg`;
    const googleImg = `${filename}live-tv-google.jpg`;

    const downloadTitle = t('global:global.livetv.live-tv.title.download');
    const appTitle = t('global:global.livetv.live-tv.title.app');
    const favContent = t('global:global.livetv.live-tv.content.favourite');
    const appContent = t('global:global.livetv.live-tv.content.app');

    return (
        <div className='live-tv'>
            <div className='live-tv-background'>
                <img src={backgroundImg} alt='Live TV Background' />
                <a href='https://apps.apple.com/my/app/88-sports-live-tv/id6502379974' className='clickable-area app-store' target='_blank' rel='noopener noreferrer'></a>
                <a
                    href='https://play.google.com/store/apps/details?id=com.me88.livetv.football&hl=ms'
                    className='clickable-area google-play'
                    target='_blank'
                    rel='noopener noreferrer'
                ></a>
            </div>
        </div>
    );
};

const Affiliate = ({ t, mobile, props }) => {
    return (
        <div className='affiliate'>
            <div className='affiliate-background'>
                <img
                    src={mobile ? '/public/html/images/live-tv/m-homepage-aff-section.png' : '/public/html/images/live-tv/homepage-aff-section.png'}
                    alt='Affiliate Section'
                />
            </div>
            <div className='affiliate-content'>
                <div className='affiliate-title'>
                    <p>{t('global:global.livetv.affiliate.title')}</p>
                </div>
                <div className='affiliate-desc'>
                    <p>{t('global:global.livetv.affiliate.content')}</p>
                </div>
                <div className='affiliate-button'>
                    <button
                        className='button-join'
                        onClick={() => {
                            props.affiliateOnClick();
                        }}
                    >
                        {t('global:global.livetv.affiliate.join')}
                    </button>
                </div>
            </div>
        </div>
    );
};

const renderSlide = ({ mobile }) => {
    let slidesContent = [];
    const temp = 10;

    for (let i = 1; i <= temp; i++) {
        let obj = {};

        obj.content = (
            <Translation>
                {(t) => (
                    <div className='testimonial-container'>
                        {!mobile && <p className='test'> {t(`global:global.testimonial.testimonial-title`)}</p>}

                        <div className='testimonial-content'>
                            <div className='testimonial-title'>
                                <p>{t(`global:global.testimonial.testimonial-content.${i.toString()}.title`)}</p>
                            </div>
                            <div className='testimonial-desc'>
                                <p>{t(`global:global.testimonial.testimonial-content.${i.toString()}.desc`)}</p>
                            </div>
                            <div className='testimonial-icon'>{star(5)}</div>
                        </div>
                    </div>
                )}
            </Translation>
        );
        slidesContent.push(obj);
    }

    return slidesContent;
};

const star = (num) => {
    let starArray = [];
    let starComponent = {};

    for (let i = 0; i < num; i++) {
        starComponent = <img src='/public/html/images/live-tv/star.svg'></img>;
        starArray.push(starComponent);
    }

    return starArray;
};
