import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import core from 'root/utils/core';

import P2PDeposit1 from './component/desktop/p2p-deposit';

import MobileP2PDeposit1 from './component/mobile/p2p-deposit';

class P2PDeposit extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
    }

    components = {
        desktop: {
            default: P2PDeposit1,
        },
        mobile: {
            default: MobileP2PDeposit1,
        },
    };

    render() {
        const TagName = core.getCorrectComponent(this.components, 'p2p-deposit', this.props.screen.viewType);
        return <TagName {...this.props} />;
    }
}

export default connect(mapStateToProps, null)(P2PDeposit);
