// library
import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// core / logic / helper
import { useRequestOtp } from '@hooks/otp-hook';
import { SIX_PIN_SETUP_FIELD, MODULE_TYPE as MT, OTP_CONTACT_TYPE as OCT, OTP_VERIFICATION_TYPE as OVT, POPUP_TYPE } from '@constants';
import notification from '@utils/notification';
import { authSettingsAction, popupAction } from '@redux/action';
import { useGetCurrentTranslation } from '@hooks/general-hook';

const _verificationType = OVT.SIX_PIN;

export const useGetSixPinSetupFields = () => {
    const authSettingsReducer = useSelector((state) => state.authSettingsReducer);
    const _sixPinAuthSettings = authSettingsReducer?.processedData?.[MT?.SIX_PIN];

    const initFields = () => ({
        [SIX_PIN_SETUP_FIELD.NEW_PIN]: { value: '', isMandatory: true, errMsg: '', touched: false },
        [SIX_PIN_SETUP_FIELD.CONFIRM_PIN]: { value: '', isMandatory: true, errMsg: '', touched: false },
        ...(_sixPinAuthSettings?.otp_feature && {
            [SIX_PIN_SETUP_FIELD.OTP]: { value: '', isMandatory: true, errMsg: '', touched: false },
        }),
    });

    const [fields, setFields] = useState(initFields);
    const [changedField, setChangedField] = useState(null);

    // hooks
    const {
        isNewPinValid,
        isConfirmPinValid,
        isNewPinLengthValid,
        newPinValidationCheckers,
        confirmPinValidationCheckers,
        validateSixPinLength,
        validateSixPin,
        validateConfirmSixPin,
    } = useValidateSixPinRules({
        newPin: fields?.[SIX_PIN_SETUP_FIELD?.NEW_PIN]?.value,
        confirmPin: fields?.[SIX_PIN_SETUP_FIELD?.CONFIRM_PIN]?.value,
    });

    const onInputFieldChange = (event) => {
        const { name, value } = event.target;
        const truncatedValue = value?.slice(0, 6);
        setFields((prevFields) => ({
            ...prevFields,
            [name]: { ...prevFields?.[name], value: truncatedValue, touched: true },
        }));
        setChangedField(name);
    };

    const handleKeyPress = (event) => {
        const isValidKey = /^\d$/.test(event?.key);

        const hasSelection = event.target.selectionStart !== event.target.selectionEnd;

        if (isValidKey && hasSelection) {
            event.target.value = '';
            return;
        }

        if (!isValidKey) {
            event.preventDefault();
        }
        if (event?.target?.value.length >= 6 && event?.key !== 'Backspace') {
            event.preventDefault();
        }
    };

    useEffect(() => {
        if (!fields?.[changedField]?.touched) {
            return;
        }
        switch (changedField) {
            case SIX_PIN_SETUP_FIELD.NEW_PIN:
                onValidateSixPin();
                break;
            case SIX_PIN_SETUP_FIELD.CONFIRM_PIN:
                validateConfirmSixPin();
                break;
            default:
                break;
        }
    }, [changedField, fields?.[changedField]?.value, fields?.[changedField]?.touched]);

    const onValidateSixPin = async () => {
        const _newPin = fields?.[SIX_PIN_SETUP_FIELD?.NEW_PIN]?.value;
        const _confirmPin = fields?.[SIX_PIN_SETUP_FIELD?.CONFIRM_PIN]?.value;
        if (_newPin) {
            validateSixPinLength(_newPin);
        }
        if (_confirmPin) {
            validateConfirmSixPin(_newPin, _confirmPin);
        }
    };
    useEffect(() => {
        // valid liao only proceed API
        if (isNewPinLengthValid) {
            validateSixPin();
        }
    }, [isNewPinLengthValid]);

    // used for after validate newPin and confirmPin, and set errors based on status
    useEffect(() => {
        setFields((prevFields) => ({
            ...prevFields,
            [SIX_PIN_SETUP_FIELD.NEW_PIN]: { ...prevFields?.[SIX_PIN_SETUP_FIELD.NEW_PIN], errMsg: isNewPinValid ? '' : 'hasError' },
            [SIX_PIN_SETUP_FIELD.CONFIRM_PIN]: { ...prevFields?.[SIX_PIN_SETUP_FIELD.CONFIRM_PIN], errMsg: isConfirmPinValid ? '' : 'hasError' },
        }));
    }, [isConfirmPinValid, isNewPinValid]);

    return { fields, newPinValidationCheckers, confirmPinValidationCheckers, setFields, onInputFieldChange, handleKeyPress };
};

export const useValidateSixPinRules = ({ newPin, confirmPin }) => {
    // redux
    const language = useSelector((state) => state.language);
    const currentLang = language?.key;

    // state
    const [isNewPinValid, setIsNewPinValid] = useState(false); // overall of pins validation
    const [newPinValidationCheckers, setNewPinValidationCheckers] = useState(null);
    const [confirmPinValidationCheckers, setConfirmPinValidationCheckers] = useState(null);
    const [isNewPinLengthValid, setIsNewPinLengthValid] = useState(false);
    const [isConsecutiveValidationValid, setIsConsecutiveValidationValid] = useState(false); // Not contain identical digits for 3 in a row (e.g., 111323)
    const [isSequenceValidationValid, setIsSequenceValidationValid] = useState(false); // 'Not contain consecutive digits (e.g., 123456, 456789)'
    const [isPhoneValidationValid, setIsPhoneValidationValid] = useState(false);
    const [isConfirmPinValid, setIsConfirmPinValid] = useState(false);

    // hooks
    const { translationData } = useGetCurrentTranslation('transaction');

    const validateSixPinLength = () => {
        const _minLength = 6;
        const _maxLength = 6;
        const isValid = newPin?.length >= _minLength && newPin?.length <= _maxLength;
        setIsNewPinLengthValid(isValid);
    };

    const validateConfirmSixPin = () => {
        const isValid = confirmPin?.length > 0 && newPin === confirmPin;
        setIsConfirmPinValid(isValid);
    };

    // after length === 6, call BE API to validate
    // BE cover scope: phone format, consecutive, sequence,
    const validateSixPin = async () => {
        try {
            const res = await window.SPL_Transaction.verifyCurrentSixDigitPin(newPin);
            if (res?.status === 'F') {
                let _isPhoneValidationValid = true;
                let _isConsecutiveValidationValid = true;
                let _isSequenceValidationValid = true;
                switch (res?.errorCode) {
                    case 2721:
                        _isPhoneValidationValid = false;
                        setIsNewPinValid(false);
                        break;
                    case 2733:
                        _isConsecutiveValidationValid = false;
                        setIsNewPinValid(false);
                        break;
                    case 2734:
                        _isSequenceValidationValid = false;
                        setIsNewPinValid(false);
                        break;
                    default:
                        break;
                }
                setIsPhoneValidationValid(_isPhoneValidationValid);
                setIsConsecutiveValidationValid(_isConsecutiveValidationValid);
                setIsSequenceValidationValid(_isSequenceValidationValid);
            } else {
                setIsPhoneValidationValid(true);
                setIsConsecutiveValidationValid(true);
                setIsSequenceValidationValid(true);
                setIsNewPinValid(true);
            }
        } catch (err) {
            if (err) {
            }
        }
    };

    useEffect(() => {
        const _checkers = {
            lengthValidation: {
                status: isNewPinLengthValid,
                message: translationData?.sixDigitPin?.lengthError?.[currentLang] || 'The PIN must consist of precisely 6 digits.',
            },
            sequenceValidation: {
                status: isNewPinLengthValid && isSequenceValidationValid,
                message: translationData?.sixDigitPin?.sixDigitPinSequenceError?.[currentLang] || 'Not contain consecutive digits (e.g., 123456, 456789)',
            },
            consecutiveValidation: {
                status: isNewPinLengthValid && isConsecutiveValidationValid,
                message: translationData?.sixDigitPin?.sixDigitPinConsecutiveError?.[currentLang] || 'Not contain identical digits for 3 in a row (e.g., 111323)',
            },
            phoneValidation: {
                status: isNewPinLengthValid && isPhoneValidationValid,
                message: translationData?.sixDigitPin?.phoneMatchError?.[currentLang] || 'The PIN cannot include the phone number.',
            },
        };
        setNewPinValidationCheckers(_checkers);

        const _confirmCheckers = {
            matchWith: { status: isConfirmPinValid, message: translationData?.sixDigitPin?.pinNotMatchError?.[currentLang] || 'PIN does not match.' },
        };
        setConfirmPinValidationCheckers(_confirmCheckers);
    }, [translationData, isNewPinLengthValid, isPhoneValidationValid, isConsecutiveValidationValid, isSequenceValidationValid, isConfirmPinValid]);

    return {
        newPinValidationCheckers,
        confirmPinValidationCheckers,

        isNewPinValid,
        isNewPinLengthValid,
        isConfirmPinValid,
        isConsecutiveValidationValid: isNewPinLengthValid && isConsecutiveValidationValid,
        isSequenceValidationValid: isNewPinLengthValid && isSequenceValidationValid,
        isPhoneValidationValid: isNewPinLengthValid && isPhoneValidationValid,
        validateSixPinLength,
        validateSixPin,
        validateConfirmSixPin,
    };
};

// check prod flow, this part code like unused, hence put here first, TBC with mk
export const useGetSixPinVerifyStatus = () => {
    const sixPinAuthSettings = useSelector((state) => state?.authSettingsReducer?.processedData?.[MT.SIX_PIN]);

    const [isReqPreVerify, setIsReqPreVerify] = useState(false);

    useEffect(() => {
        if (sixPinAuthSettings?.authMethod?.length === 0 || !sixPinAuthSettings?.authMethod) {
            setIsReqPreVerify(true);
        }
    }, [sixPinAuthSettings]);

    return {
        isReqPreVerify,
    };
};

export const useSendVerification = ({ preferMethod, preferContact, onRequestOtpSuccessCb, onRequestOtpFailedCb }) => {
    const _otpApiParam = {
        verificationType: _verificationType,
        verificationMethod: preferMethod || preferContact,
        contactType: preferContact,
    };
    const onRequestOtpSuccess = () => {
        onRequestOtpSuccessCb && onRequestOtpSuccessCb();
    };

    const onRequestOtpFail = (err) => {
        onRequestOtpFailedCb && onRequestOtpFailedCb(err);
    };

    const { requestOtp, verifyAttemptCount, isRequestingOtp, otpCountdownTimer, isOtpRequested } = useRequestOtp({
        splParams: { otpOptions: { otpType: _verificationType, isPublic: false }, data: _otpApiParam },
        onRequestOtpSuccess, // Callback on successful OTP request
        onRequestOtpFail, // Callback on failed OTP request
    });

    const isOtpButtonDisabled = useCallback(() => {
        const isDisabled = otpCountdownTimer > 0 || isRequestingOtp || (preferContact === OCT.PHONE && !preferMethod);
        return isDisabled;
    }, [otpCountdownTimer, isRequestingOtp, preferMethod, preferContact]);

    const handleSendVerification = useCallback(() => {
        requestOtp(); // dependency on the splParams, only _otpApiParam fields need cater.
    }, [preferMethod, preferContact]);

    return {
        verifyAttemptCount,
        otpCountdownTimer,
        isOtpRequested,
        handleSendVerification,
        isOtpButtonDisabled,
    };
};

export const useSubmitSixPinSetupForm = ({ onVerifySuccessCb, onVerifyFailedCb, preferMethod, preferContact, fields, isOtpRequested }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('transaction');

    // redux
    const language = useSelector((state) => state.language);
    const currentLang = language?.key;
    const authSettingsReducer = useSelector((state) => state.authSettingsReducer);
    const _sixPinAuthSettings = authSettingsReducer?.processedData?.[MT?.SIX_PIN];

    // hooks
    const { translationData } = useGetCurrentTranslation('transaction');

    // state
    const [isSubmitting, setIsSubmitting] = useState(false);

    const isSubmitBtnDisabled = useCallback(() => {
        // If no OTP feature is enabled, consider it valid by default
        const isOtpFeatureEnabled = _sixPinAuthSettings?.otp_feature;
        const isOtpRelatedValid = isOtpFeatureEnabled ? fields?.[SIX_PIN_SETUP_FIELD.OTP]?.value && isOtpRequested : true;

        // Check if any field is empty
        const hasEmptyField = Object.values(fields).some((field) => !field.value);

        // Check if any field has an error, skip OTP field
        const hasErrorBlocking = Object.entries(fields).some(([key, field]) => {
            return key !== 'otp' && field.errMsg;
        });

        // Determine if submit button should be disabled
        const isDisabled = isSubmitting || !isOtpRelatedValid || hasEmptyField || hasErrorBlocking || (isOtpFeatureEnabled && preferContact === OCT.PHONE && !preferMethod);

        return isDisabled;
    }, [isSubmitting, _sixPinAuthSettings?.otp_feature, fields, isOtpRequested, preferContact, preferMethod]);

    const handleSubmitSixPinSetupForm = useCallback(async () => {
        setIsSubmitting(true);
        const _apiParams = {
            newSixDigitPin: fields?.[SIX_PIN_SETUP_FIELD?.NEW_PIN]?.value,
            confirmSixDigitPin: fields?.[SIX_PIN_SETUP_FIELD?.CONFIRM_PIN]?.value,
            action: 'SET',
            verificationType: _verificationType,
            // because auth-settings is based on otp_feature true
            ...(_sixPinAuthSettings?.otp_feature && {
                verifyKey: fields?.[SIX_PIN_SETUP_FIELD?.OTP]?.value,
                verificationMethod: preferMethod,
            }),
        };

        try {
            const res = await window.SPL_Transaction?.handleSetSixDigitPin(_apiParams);
            setIsSubmitting(false);
            if (res === true) {
                dispatch(authSettingsAction.setSixPinMemberRegistered()).then(() => {
                    setIsSubmitting(false);
                    if (onVerifySuccessCb) {
                        onVerifySuccessCb(res);
                    } else {
                        dispatch(popupAction.setPopup(POPUP_TYPE.SIX_PIN_SETUP, false));
                        notification.showNotification('success', translationData?.sixDigitPin?.pinCreated?.[currentLang] || 'Pin creation was successful');
                    }
                });
            } else {
                if (onVerifyFailedCb) {
                    onVerifyFailedCb(res);
                } else {
                    if (res?.errorCode) {
                        // t only used for handled 2725 error, temp quick solution
                        notification.showNotification('error', t(res?.errMsg, res?.errMsg));
                    }
                }
            }
        } catch (err) {
            setIsSubmitting(false);
        }
    }, [
        fields?.[SIX_PIN_SETUP_FIELD?.NEW_PIN]?.value,
        fields?.[SIX_PIN_SETUP_FIELD?.CONFIRM_PIN]?.value,
        fields?.[SIX_PIN_SETUP_FIELD?.OTP]?.value,
        fields?.[SIX_PIN_SETUP_FIELD?.GA]?.value,
    ]);

    return {
        handleSubmitSixPinSetupForm,
        isSubmitBtnDisabled,
    };
};
