import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { mapStateToProps } from 'root/redux/selector/selector';
import image from 'root/utils/image';
import PhoneVerificationDialog from '../../../phone-verification-dialog/phone-verification-dialog.selector';
import PromotionDetailsDialog from '../../../promotion-details-dialog/promotion-details-dialog.selector';
import SpecialPromoDialog from '../../../special-promo-dialog/special-promo-dialog.selector';
import vm from '../../transfer.controller';
import TransferSelect from './m-transferSelect';
import Turnover from './m-turnover';
import { DebounceInput } from 'react-debounce-input';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { walletAction } from 'root/redux/action/wallet.action';
import { gu_loadCustomTranslation } from '../../../../../../utils/general-util';

class Transfer extends React.Component {
    isMount = false;
    props = null;
    updateProviderCount = 0;

    constructor(props) {
        super();
        this.props = props;
        this.updateProviderCount = props.updateProviderCount;
        vm.init(this);
        this.state = {
            ...this.state,
            special: false,
            promo: false,
            balance: false,
            turnover: false,
        };
    }

    componentDidMount() {
        this.isMount = true;
        gu_loadCustomTranslation('transfer', this, function () {
            vm.loadProviders(true);
        });
        vm.loadPortalSettings();
        vm.getMainWallet();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.wallet.loadWallet !== this.props.wallet.loadWallet) {
            if (this.props.wallet.loadWallet) {
                vm.loadProviders(true);
                vm.getMainWallet();
                setTimeout(() => {
                    this.props.dispatch(walletAction.udpateLoadWallet(false));
                }, 500);
            }
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    toggleBtn(key, isShow) {
        this.setState((state) => {
            return { ...state, [key]: isShow ?? !state[key] };
        });
    }

    handleEvent(e) {
        switch (e.type) {
            case 'click':
                this.clickListOut(e);
                break;

            default:
                break;
        }
    }

    clickListOut(e) {
        if (this.state.special) {
            let special = false;
            const stopEventEl = [this.refs.currentSpecialRef];
            for (let index = 0; index < stopEventEl.length; index++) {
                let child = stopEventEl[index];
                if (child && child.contains(e.target)) {
                    special = true;
                    break;
                }
            }

            this.setState((state) => ({ ...state, special }));
        }

        if (this.state.promo) {
            let promo = false;
            const stopEventEl = [this.refs.currentPromoRef];
            for (let index = 0; index < stopEventEl.length; index++) {
                let child = stopEventEl[index];
                if (child && child.contains(e.target)) {
                    promo = true;
                    break;
                }
            }

            this.setState((state) => ({ ...state, promo }));
        }
    }

    initEvent(off) {
        window[off ? 'removeEventListener' : 'addEventListener']('click', this);
    }

    componentWillMount() {
        this.initEvent();
    }

    componentWillUnmount() {
        this.initEvent(true);
    }

    render() {
        const { t } = this.props;
        const { showInputForm, isValidSpecialPromoAmount, isValidSpecialPromoProvider, specialPromo } = this.state;

        let specialPromoDialogProps = {
            closeSpecialPromoDialog: vm.closeSpecialPromoDialog,
            specialPromoDialogIsShow: this.state.specialPromoDialogIsShow,
            parentvm: this,
            specialPromoDetail: this.state.specialPromoDetail,
        };

        let smsVerificationDialogProps = {
            parentvm: this,
            phoneVerificationDialogIsShow: this.state.phoneVerificationDialogIsShow,
            closePhoneVerificationDialog: vm.closePhoneVerificationDialog,
            phoneVerificationDialogClosedCallback: this.state.phoneVerificationDialogClosedCallback,
        };
        let promotionDetailsDialogProps = {
            parentvm: this,
            promotionDetailsDialogIsShow: this.state.promotionDetailsDialogIsShow,
            closePromotionDetailsDialog: vm.closePromotionDetailsDialog,
            promodetails: this.state.promodetails,
        };

        return (
            <div data-page='/myaccount/' className={`${this.state.phoneVerificationDialogIsShow ? 'is-phone-verification' : ''} `}>
                {/* special promo dialog */}
                {this.state.specialPromoDetail !== null && <SpecialPromoDialog {...specialPromoDialogProps}></SpecialPromoDialog>}
                {/* verify phone verification sms popup */}
                {this.state.phoneVerificationDialogIsShow && <PhoneVerificationDialog {...smsVerificationDialogProps}></PhoneVerificationDialog>}
                {this.state.promotionDetailsDialogIsShow && <PromotionDetailsDialog {...promotionDetailsDialogProps}></PromotionDetailsDialog>}

                {!this.state.phoneVerificationDialogIsShow && (
                    <div className='transfer-page'>
                        <Turnover
                            parentState={this.state}
                            getMainWallet={vm.getMainWallet}
                            loadProviders={() => {
                                vm.loadProviders(true);
                            }}
                            showHideInputForm={vm.showHideInputForm}
                        />
                        {this.state.showInputForm && (
                            <>
                                <div className='transfer-select-item'>
                                    <div className='title-item'>{t('transaction:transaction.transfer.from', 'From')}</div>
                                    <div className='center-box'>
                                        <TransferSelect
                                            parentState={this.state}
                                            onChange={(provider) => {
                                                vm.changeProviderFromObjectParams(provider);
                                            }}
                                            providerIndex={this.state.fromProvider}
                                            providerCategorySelect={vm.providerCategorySelect}
                                            onCategorySelect={vm.onCategorySelect}
                                            updateProviderItemIndex={vm.updateProviderItemSelect}
                                            updateProviderIndex={vm.updateProviderSelect}
                                            type={'from'}
                                        />
                                    </div>
                                </div>
                                <div className='transfer-select-item transfer-btn-box'>
                                    <div className='center-box'>
                                        <i
                                            className='icon-transfer-btn'
                                            onClick={() => {
                                                vm.swapProvider();
                                            }}
                                        ></i>
                                    </div>
                                </div>
                                <div className='transfer-select-item'>
                                    <div className='title-item'>{t('transaction:transaction.transfer.to', 'To')}</div>
                                    <div className='center-box'>
                                        <TransferSelect
                                            parentState={this.state}
                                            onChange={(provider) => {
                                                vm.changeProviderToObjectParams(provider);
                                            }}
                                            providerIndex={this.state.toProvider}
                                            providerCategorySelect={vm.providerCategorySelect}
                                            onCategorySelect={vm.onCategorySelect}
                                            updateProviderItemIndex={vm.updateProviderItemSelect}
                                            updateProviderIndex={vm.updateProviderSelect}
                                            type={'to'}
                                            isInvalid={specialPromo && !isValidSpecialPromoProvider}
                                        />
                                    </div>
                                </div>
                                {this.state.allowSpecialCode === false && (
                                    <div>
                                        <div className='transfer-select-item group-code'>
                                            <div className='center-box'>
                                                <div className='group'>
                                                    <span>{t('transaction:transaction.transfer.promocode', 'Promo Code')} *</span>
                                                    {this.state.promoList.length && this.state.selectedPromotion >= 0 ? (
                                                        <span onClick={() => vm.toggleShowPromoDetails()}>
                                                            {t('transaction:transaction.transfer.promoDetail.viewDetail', 'Promo Detail')}
                                                        </span>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                                <div
                                                    ref={'currentPromoRef'}
                                                    className={['current-select', this.state.promo ? 'on' : ''].join(' ')}
                                                    onClick={() => {
                                                        if (!this.state.specialPromoCode) {
                                                            this.toggleBtn('promo');
                                                        }
                                                    }}
                                                >
                                                    <span>
                                                        {this.state.promoList.length && this.state.selectedPromotion >= 0
                                                            ? this.state.promoList[this.state.selectedPromotion].bonusName
                                                            : t('global:global.form.pleaseSelect')}
                                                    </span>
                                                    <CSSTransition classNames='spread-bottom' in={this.state.promo} timeout={300} unmountOnExit>
                                                        <ul
                                                            className='item-list-box'
                                                            ref={'itemRef'}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                            }}
                                                        >
                                                            <li
                                                                onClick={() => {
                                                                    vm.changePromotion(-1);
                                                                    if (!this.state.specialPromoCode) {
                                                                        this.toggleBtn('promo');
                                                                    }
                                                                }}
                                                            >
                                                                {t('global:global.form.pleaseSelect')}
                                                            </li>
                                                            {this.state.promoList.map((promotion, index) => (
                                                                <li
                                                                    key={index}
                                                                    className={[index === this.state.itemIndex ? 'on' : ''].join(' ')}
                                                                    onClick={() => {
                                                                        vm.changePromotion(index);
                                                                        if (!this.state.specialPromoCode) {
                                                                            this.toggleBtn('promo');
                                                                        }
                                                                    }}
                                                                >
                                                                    <span>{promotion.bonusName}</span>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </CSSTransition>
                                                    <div className='group'>
                                                        <span className='icon-box'>
                                                            <CSSTransition classNames='rotate180' in={this.state.promo} timeout={300}>
                                                                <i className='icon-arrow2'></i>
                                                            </CSSTransition>
                                                        </span>
                                                    </div>
                                                </div>
                                                {/* <div className="input-box">
                        <input type="text" defaultValue="0.00" disabled />
                      </div> */}
                                                {this.state.showPromoTnc && this.state.promoList !== null && this.state.promoList.length > 0 && (
                                                    <div className='promo-tnc' onClick={() => vm.goToPromoPage()}>
                                                        <span>{t('transaction:transaction.transfer.tnCMessage')}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {this.state.allowSpecialCode && (
                                    <div>
                                        <div className='transfer-select-item group-code'>
                                            <div className='center-box'>
                                                <div className='group'>
                                                    <span>{t('transaction:transaction.transfer.specialCode', 'Special Code')}</span>
                                                </div>
                                                <div className='input-box'>
                                                    <DebounceInput
                                                        type='text'
                                                        placeholder={t('transaction:transaction.transfer.specialCode', 'Special Code')}
                                                        disabled={this.state.promoList.length > 0 && this.state.selectedPromotion >= 0}
                                                        value={this.state.specialPromoCode}
                                                        onChange={(e) => {
                                                            vm.handleSpecialPromoCodeChange(e);
                                                        }}
                                                        debounceTimeout={500}
                                                    ></DebounceInput>
                                                    {this.state.specialPromoCode !== '' && this.state.isValidSpecialPromoCode && (
                                                        <LazyLoadImage
                                                            src={image.specialPromoCodeValid}
                                                            style={{ cursor: 'pointer' }}
                                                            className='specialCodeImg'
                                                            onClick={() => vm.openSpecialPromoDialog()}
                                                            alt='valid.png'
                                                        />
                                                    )}
                                                    {this.state.specialPromoCode !== '' && !this.state.isValidSpecialPromoCode && (
                                                        <LazyLoadImage src={image.specialPromoCodeInvalid} className='specialCodeImg' alt='invalid.png' />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='transfer-select-item group-code'>
                                            <div className='center-box'>
                                                <div className='group'>
                                                    <span>{t('transaction:transaction.transfer.promocode', 'Promo Code')} *</span>
                                                    {this.state.promoList.length && this.state.selectedPromotion >= 0 ? (
                                                        <span onClick={() => vm.toggleShowPromoDetails()}>
                                                            {t('transaction:transaction.transfer.promoDetail.viewDetail', 'Promo Detail')}
                                                        </span>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                                <div
                                                    ref={'currentPromoRef'}
                                                    className={['current-select', this.state.promo ? 'on' : ''].join(' ')}
                                                    onClick={() => {
                                                        if (!this.state.specialPromoCode) {
                                                            this.toggleBtn('promo');
                                                        }
                                                    }}
                                                >
                                                    <span>
                                                        {this.state.promoList.length && this.state.selectedPromotion >= 0
                                                            ? this.state.promoList[this.state.selectedPromotion].bonusName
                                                            : t('global:global.form.pleaseSelect')}
                                                    </span>
                                                    <CSSTransition classNames='spread-bottom' in={this.state.promo} timeout={300} unmountOnExit>
                                                        <ul
                                                            className='item-list-box'
                                                            ref={'itemRef'}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                            }}
                                                        >
                                                            <li
                                                                onClick={() => {
                                                                    vm.changePromotion(-1);
                                                                    if (!this.state.specialPromoCode) {
                                                                        this.toggleBtn('promo');
                                                                    }
                                                                }}
                                                            >
                                                                {t('global:global.form.pleaseSelect')}
                                                            </li>
                                                            {this.state.promoList.map((promotion, index) => (
                                                                <li
                                                                    key={index}
                                                                    className={[index === this.state.itemIndex ? 'on' : ''].join(' ')}
                                                                    onClick={() => {
                                                                        vm.changePromotion(index);
                                                                        if (!this.state.specialPromoCode) {
                                                                            this.toggleBtn('promo');
                                                                        }
                                                                    }}
                                                                >
                                                                    <span>{promotion.bonusName}</span>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </CSSTransition>
                                                    <div className='group'>
                                                        <span className='icon-box'>
                                                            <CSSTransition classNames='rotate180' in={this.state.promo} timeout={300}>
                                                                <i className='icon-arrow2'></i>
                                                            </CSSTransition>
                                                        </span>
                                                    </div>
                                                </div>
                                                {this.state.showPromoTnc && this.state.promoList !== null && this.state.promoList.length > 0 && (
                                                    <div className='promo-tnc' onClick={() => vm.goToPromoPage()}>
                                                        <span>{t('transaction:transaction.transfer.tnCMessage')}</span>
                                                    </div>
                                                )}
                                                {/* { <div className="input-box">
                      <input type="text" defaultValue="0.00" disabled />
                    </div>} */}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className='transfer-select-item group-amount'>
                                    <div className='title-item'>{t('transaction:transaction.transfer.amount', 'Amount')}</div>
                                    <div className='center-box'>
                                        <div className='input-box'>
                                            <input
                                                type='numeric'
                                                className={[specialPromo && !isValidSpecialPromoAmount ? 'danger' : '']}
                                                placeholder={
                                                    this.state.specialPromoAmountPlaceHolder !== null && this.state.specialPromoAmountPlaceHolder !== ''
                                                        ? this.state.specialPromoAmountPlaceHolder
                                                        : t('global:global.form.accountamount-placeholder', 'Amount')
                                                }
                                                min='0'
                                                max={this.state.maximumAmount}
                                                value={this.state.transferAmount}
                                                onChange={(e) => vm.handleAmountChange(e)}
                                            ></input>
                                            <span className='btn-allin' onClick={vm.AllInOnClick}>
                                                {t('transaction:transaction.transfer.allIn')}
                                            </span>
                                        </div>
                                        <div className='btns-tools m-standard-2-button-container'>
                                            <button
                                                className='btn btn-submit standard-button'
                                                onClick={() => vm.submitTransfer(t)}
                                                disabled={
                                                    this.state.disabledDepositButton || (this.state.specialPromoCode !== '' && this.state.isValidSpecialPromoCode === false)
                                                }
                                            >
                                                {t('transaction:transaction.history.filter.submit', 'Submit').toUpperCase()}
                                            </button>
                                            <span className='btn-reset standard-button' onClick={vm.resetOnClick}>
                                                {t('transaction:transaction.reset')}
                                            </span>
                                        </div>
                                    </div>
                                    {this.state.isSuccess && <div className='center-box success'>{t('transaction:transaction.transfer.success')}</div>}
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default connect(mapStateToProps, null)(withTranslation(['transaction', 'home', 'settings'])(withRouter(Transfer)));
