import React, { lazy } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import core from 'root/utils/core';

import DesktopSports from './desktop/Sports';
import MobileSports from './mobile';

class Sports extends React.Component {
    components = {
        desktop: {
            default: DesktopSports,
        },
        mobile: {
            default: MobileSports,
        },
    };

    render() {
        const TagName = core.getCorrectComponent(this.components, 'sports', this.props.screen.viewType);
        return <TagName></TagName>;
    }
}

export default connect(mapStateToProps, null)(Sports);
