export const providerConstants = {
    GET_PROVIDER_MAINTENANCE_LIST: 'GET_PROVIDER_MAINTENANCE_LIST',

    GET_JUST_FOR_YOU_GAME: 'GET_JUST_FOR_YOU_GAME',
    SET_JUST_FOR_YOU_GAME: 'SET_JUST_FOR_YOU_GAME',

    GET_HOT_GAME_SLIDER: 'GET_HOT_GAME_SLIDER_JSON',
    SET_HOT_GAME_SLIDER: 'SET_HOT_GAME_SLIDER',

    GET_MEMBER_FAVORITE_GAMES: 'GET_MEMBER_FAVORITE_GAMES',
    UPDATE_MEMBER_FAVORITE_GAMES: 'UPDATE_MEMBER_FAVORITE_GAMES',
    SET_MEMBER_FAVORITE_GAMES: 'SET_MEMBER_FAVORITE_GAMES',
};
