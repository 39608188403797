export const mapStateToProps = (state) => {
    return {
        language: state.language,
        user: state.user,
        game: state.game,
        register: state.register,
        screen: state.screen,
        portal: state.portal,
        wallet: state.wallet,
        modal: state.modal,
        layout: state.layout,
        dropdown: state.dropdown,
        floatingNotification: state.floatingNotification,
        depositSubmission: state.depositSubmission,
        generalReducer: state.generalReducer,
        passwordReducer: state.passwordReducer,
        promotionReducer: state.promotionReducer,
        systemFeatureReducer: state.systemFeatureReducer,
        authSettingsReducer: state.authSettingsReducer,
        regionReducer: state.regionReducer,
        popupReducer: state.popupReducer,
        providerReducer: state.providerReducer,
    };
};
