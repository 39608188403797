import { depositAction } from 'root/redux/action';
import moment from 'moment';
import { gu_getSettingFromPortalSettings } from './general-util';
import isEmpty from 'lodash/isEmpty';

// type: [deposit/withdraw]
export function trans_massageHideAddBank(vm, type, optionCode, memberBankList) {
    const { settings } = vm.props.portal;

    let hideAddBankAccount = settings.hideAddBankAccount ? settings.hideAddBankAccount : false;
    hideAddBankAccount = processSettingByTypeAndOptionCode(hideAddBankAccount, type, optionCode);

    // if memberBankList passed from controller, then check allow register how many banks
    if (memberBankList) {
        memberBankList = memberBankList ? memberBankList : [];

        let limitMemberRegisteredBankNum = gu_getSettingFromPortalSettings({
            vm: vm,
            settingName: 'limitMemberRegisteredBankNum',
            defaultValue: false,
        });
        limitMemberRegisteredBankNum = processSettingByTypeAndOptionCode(limitMemberRegisteredBankNum, type, optionCode);

        if (limitMemberRegisteredBankNum && memberBankList.length >= limitMemberRegisteredBankNum) {
            hideAddBankAccount = true;
        }
    }

    return hideAddBankAccount;
}

function processSettingByTypeAndOptionCode(setting, type, optionCode) {
    if (setting && setting.constructor === Object) {
        if (setting[type]) {
            if (setting[type].constructor === Object) {
                if (setting[type][optionCode] !== undefined && setting[type][optionCode] !== null) {
                    setting = setting[type][optionCode];
                } else if (setting[type]['ALL']) {
                    setting = setting[type]['ALL'];
                } else {
                    setting = false;
                }
            } else {
                setting = setting[type];
            }
        } else {
            setting = false;
        }
    }
    return setting;
}

export function trans_handlePGDepositCountDown(vm, isSubmitted, countDownInterval, optionCode, submissionTime, setStateObj) {
    const { depositSubmission } = vm.props;
    const submissionData = depositSubmission && depositSubmission.submissionData;
    const selectedPaymentObj = submissionData && submissionData[optionCode];
    const submissionRetryTime = selectedPaymentObj && selectedPaymentObj.submissionRetryTime; //
    const newSubmissionRetryTime = moment(submissionTime).add(countDownInterval, 'seconds').valueOf();

    const newTimeDuration = moment(newSubmissionRetryTime).diff(Date.now().valueOf(), 'seconds');
    const oldTimeDuration = moment(submissionRetryTime).diff(Date.now().valueOf(), 'seconds');

    let newSubmissionData = {};
    let secondCount = null;

    if (isSubmitted) {
        if (selectedPaymentObj && submissionRetryTime > Date.now().valueOf()) {
            vm.setState({ depositSubmitCountdown: oldTimeDuration }, () => {
                getTimeDiff();
            });
        } else {
            newSubmissionData = { ...submissionData };
            newSubmissionData[optionCode] = { submissionRetryTime: newSubmissionRetryTime };
            vm.props.dispatch(depositAction.updateDepositSubmission(newSubmissionData));
            vm.setState({ depositSubmitCountdown: newTimeDuration }, () => {
                getTimeDiff();
            });
        }
        vm.setState(setStateObj);
    } else {
        if (selectedPaymentObj && submissionRetryTime > Date.now().valueOf()) {
            vm.setState({ depositSubmitCountdown: oldTimeDuration }, () => {
                getTimeDiff();
            });
        }
    }

    function getTimeDiff() {
        if (vm.state.depositSubmitCountdown > 0) {
            setTimeout(() => {
                secondCount = vm.state.depositSubmitCountdown - 1;
                vm.setState({ depositSubmitCountdown: secondCount });

                getTimeDiff();
            }, 1000);
        }
    }
}

// upload bank statement util function
export function trans_handleUploadReceiptButtonOnClick() {
    let hiddenInput = document.getElementById('hiddenBankStatementInput');
    if (hiddenInput) {
        hiddenInput.click();
    } else {
        console.log('hiddenBankStatementInput is not found');
    }
}

export function trans_handleBankStatementReceiptImageChange(vm, e) {
    let { errors } = vm.state;
    errors['receipt'] = '';

    let stateToUpdate = {
        errors,
        receiptImg: [],
        receiptImgFile: null,
        isInvalidUploadFileType: false,
    };

    if (e && e.target && e.target.files && e.target.files.length > 0) {
        let receipt = e.target.files[0];
        window.SPL_Transaction.validateReceiptImage(receipt).then((data) => {
            const { errMsg, isInvalidUploadFileType } = data;

            errors['receipt'] = errMsg;
            if (data.isInvalidUploadFileType) {
                stateToUpdate = {
                    ...stateToUpdate,
                    isInvalidUploadFileType: isInvalidUploadFileType,
                    errors,
                };
            } else {
                stateToUpdate = {
                    ...stateToUpdate,
                    receiptImg: URL.createObjectURL(receipt),
                    receiptImgFile: receipt,
                };
            }
            vm.setState(stateToUpdate);
        });
    } else {
        vm.setState(stateToUpdate);
    }
}

export function trans_handleBankStatementClearReceipt(vm) {
    let inputID = document.getElementById('hiddenBankStatementInput');
    let mobileInputID = document.getElementById('uploadBankStatementInput');
    if (inputID) {
        inputID.value = null;
    }
    if (mobileInputID) {
        mobileInputID.value = null;
    }
    vm.setState({
        receiptImg: [],
        receiptImgFile: null,
    });
}
export function trans_getDepositAmountOptionByMethod(data, method = 'default') {
    const methodList = ['QR', 'BA', 'eWallet1', 'B', 'CT', 'QP', 'TQR', 'P2P', 'MC', 'EA', 'RQR', 'R', 'BQR'];
    if (isEmpty(data)) return [];
    if (isEmpty(data[method]) || !methodList.includes(method)) return data['default'];
    return data[method];
}
// upload bank statement util function end
