import { t } from 'i18next';
import React from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';

class Donotmiss extends React.Component {
    constructor() {
        super();
        this.state = {
            list: [
                {
                    key: 'casino',
                    title: 'global:global.menu.casino',
                    desc1: 'global:global.donotmiss.casino.desc1',
                    desc2: 'global:global.donotmiss.casino.desc2',
                    route: '/casino',
                },
                {
                    key: 'slots',
                    title: 'global:global.menu.slot',
                    desc1: 'global:global.donotmiss.slots.desc1',
                    desc2: 'global:global.donotmiss.slots.desc2',
                    route: '/slots',
                },
                {
                    key: 'fishing',
                    title: 'global:global.menu.fishing',
                    desc1: 'global:global.donotmiss.fishing.desc1',
                    desc2: 'global:global.donotmiss.fishing.desc2',
                    route: '/fishing',
                },
                // {
                //     key: 'poker',
                //     title: 'global:global.menu.poker',
                //     desc1: 'global:global.donotmiss.poker.desc1',
                //     desc2: 'global:global.donotmiss.poker.desc2',
                //     route: '/poker',
                // },
            ],
        };
    }

    componentDidMount() {
        const { language } = this.props;
        if (language.currencyLang === 'VND') {
            const { list } = this.state;

            let newList = [...list];
            newList.pop();
            this.setState({ list: newList });
        }
    }

    handleClick(obj) {
        this.props.history.push(obj.route);
    }

    handleMoreClick() {
        this.props.history.push('/slots');
    }

    render() {
        return (
            <Translation>
                {(t) => (
                    <div className='donot-miss'>
                        <div className='container'>
                            <span className='item-title'>{t('global:global.donotmiss.title', "DON'T MISS IT")}</span>
                            <div
                                className='more-games'
                                data-screen='mobile'
                                onClick={() => {
                                    this.handleMoreClick();
                                }}
                            >
                                <span>{t('global:global.donotmiss.more', 'MORE')}</span>
                                <span> {'>'} </span>
                            </div>
                            <ul className='donot-miss-list'>
                                {this.state.list.map((content) => (
                                    <li
                                        key={content.key}
                                        onClick={() => {
                                            this.handleClick(content);
                                        }}
                                    >
                                        <span className='title'>{t(content.title)}</span>
                                        <div className='content'>
                                            <div>
                                                <p>{t(content.desc1)}</p>
                                                <p>{t(content.desc2)}</p>
                                            </div>
                                        </div>
                                        <div className='hover-box'></div>
                                        <div className='bottom-box'>
                                            <div className='bottom'>
                                                <span>{t('home:home.web.playNow', 'Play Now')}</span>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['global', 'home'])(withRouter(Donotmiss)));
