import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Trans, Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { TweenMax } from 'gsap';
import { isAndroid } from 'react-device-detect';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import LeftSubMenu from 'root/webapp/shared/component/wap/LeftSubMenu';
import HomeMemberProfile from 'root/webapp/shared/component/wap/HomeMemberProfile';

import vm from '../poker.controller';
import core from 'root/utils/core';

class Poker extends React.Component {
    tween1;
    tween2;

    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        vm.getGameLoginCredential();
        vm.getPokerBannerElement(this.props.screen.viewType);
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    toggleAni() {
        this.tween1 && this.tween1.kill();
        this.tween2 && this.tween2.kill();

        TweenMax.set('.brand-page ul.ani-ul > li', {
            alpha: 0,
        });

        this.tween1 = TweenMax.delayedCall(0.3, function () {
            this.tween2 = TweenMax.staggerTo('.brand-page ul.ani-ul > li', 0.3, { alpha: 1 }, 0.08);
        });
    }

    checkMaintenance() {
        if (this.state.list && !this.state.list.underMaintenance) {
            vm.launchGame();
        }
    }

    render() {
        return (
            <Translation>
                {(t) => (
                    <Fragment>
                        <div className='poker-page'>
                            <HomeMemberProfile />
                            <div className='poker-box-container'>
                                <div className='brand-page'>
                                    <LeftSubMenu />
                                    <div className='poker-list-box'>
                                        <span className='item-title'>{t('poker:poker.mobile.title', 'Awesome Poker Game')}</span>
                                        <ul className='ani-ul'>
                                            <li
                                                className='img-box'
                                                onClick={() => {
                                                    this.checkMaintenance();
                                                }}
                                            >
                                                <LazyLoadImage
                                                    src='/public/html/images/poker/mobile/banner.png'
                                                    className={
                                                        this.state.list && this.state.list.underMaintenance && this.state.list.underMaintenance.gameMaintenance
                                                            ? 'maintenance-cover'
                                                            : ''
                                                    }
                                                />

                                                <p
                                                    className={
                                                        this.state.list && this.state.list.underMaintenance && this.state.list.underMaintenance.gameMaintenance
                                                            ? 'maintenance-cover'
                                                            : ''
                                                    }
                                                >
                                                    <Trans i18nKey={'poker:poker.mobile.content'}></Trans>
                                                    <br />
                                                    <span>
                                                        {t('poker:poker.mobile.availableOn', 'Available on')}
                                                        <LazyLoadImage src='/public/html/images/poker/mobile/ios-android.png' />
                                                    </span>
                                                </p>
                                                {this.state.list && this.state.list.underMaintenance && this.state.list.underMaintenance.gameMaintenance && (
                                                    <div className='maintenance-hover-box'></div>
                                                )}
                                            </li>

                                            {/* <li className='user-box'>
                        <div>
                          <span>{this.state.userObj.playerId}</span>
                          <LazyLoadImage src='/public/html/images/poker/mobile/copy.png' onClick={() => { core.copy(t, this.state.userObj.playerId); }} />
                        </div>

                        <div>
                          <span>{this.state.userObj.playerPassword}</span>
                          <LazyLoadImage src='/public/html/images/poker/mobile/copy.png' onClick={() => { core.copy(t, this.state.userObj.playerPassword); }} />
                        </div>

                        <span className='btn-poker-download' onClick={() => vm.downloadApps()}>
                          {isAndroid && <Trans>{t('poker:poker.mobile.androidDownload')}</Trans>}
                          {!isAndroid && <Trans>{t('poker:poker.mobile.iosDownload')}</Trans>}
                        </span>
                      </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['poker', 'transaction'])(withRouter(Poker)));
