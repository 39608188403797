import { walletAction } from 'root/redux/action/wallet.action';
import core from 'root/utils/core';
import notification from 'root/utils/notification';
import { AMOUNT_DEPOSIT_METHOD_CODE, MODULE_TYPE as MT, POPUP_TYPE, OTP_VERIFICATION_TYPE as OVT } from '@constants';
import { portalAction, authSettingsAction } from '@redux/action';
import { popupAction } from '@redux/action/popup.action';
const controller = {
    vm: null,

    init(vm) {
        this.vm = vm;
        this.vm.state = {
            selectedBankIndex: 0,
            fields: {
                accountNumber: '',
                amount: '',
            },
            disabledFields: {
                accountNumber: false,
            },
            errors: {},
            selectedMemberBank: null,
            isLoading: true,
            filteredBankList: [],
            memberDecimalSettings: {},
        };
    },

    onWithdrawAmountClicked(item) {
        let fields = controller.vm.state.fields;
        fields['amount'] = item;
        controller.vm.setState({ fields }, () => {
            controller.handleValidation();
        });
    },

    checkFirstMemberBank() {
        Promise.all([window.SPL_Transaction.getWithdrawalBankList(), window.SPL_Member.getMemberBanksByType('R', '', controller.vm.props)]).then((response) => {
            // list of withdrawal bank available, list of member owned bank
            const [ewalletList, memberBankList] = response;
            let { filteredBankList, filterMemberBankList } = controller.vm.state;

            if (ewalletList && ewalletList.R && ewalletList.R.R.length > 0) {
                let filterEwalletList = ewalletList.R.R;

                for (let i = 0; i < filterEwalletList.length; i++) {
                    const currentunOwnedBank = filterEwalletList[i];

                    if (currentunOwnedBank && currentunOwnedBank.code === 'TNG') {
                        currentunOwnedBank.isSvgImage = true;
                    }
                    if (memberBankList && memberBankList.length > 0) {
                        filterMemberBankList = memberBankList.filter((e) => e.bankCode === filterEwalletList[i].code);
                        if (filterMemberBankList.length > 0) {
                            for (let j = 0; j < filterMemberBankList.length; j++) {
                                const currentMemberBank = filterMemberBankList[j];
                                if (currentunOwnedBank.code === currentMemberBank.bankCode) {
                                    currentunOwnedBank.accountName = currentMemberBank.accountName;
                                    currentunOwnedBank.accountNumber = currentMemberBank.accountNumber;
                                    currentunOwnedBank.memberBankId = currentMemberBank.memberBankId;
                                    currentunOwnedBank.name = currentunOwnedBank.name;

                                    filteredBankList.push(currentunOwnedBank);
                                }
                            }
                        } else {
                            filteredBankList.push(currentunOwnedBank);
                        }
                    } else {
                        filteredBankList.push(currentunOwnedBank);
                    }
                }
            }
            controller.vm.setState({ filteredBankList, showLoading: false, ewalletList: ewalletList.R.R }, () => {
                controller.selectBank(0);
            });
        });
    },
    selectBank(index, bankObj) {
        let { fields, disabledFields, filteredBankList, ewalletList, selectedMemberBank } = controller.vm.state;
        if (filteredBankList && ewalletList && ewalletList.length > 0 && filteredBankList.length > 0) {
            //filter memberbank in bank list only take the first
            if (bankObj && bankObj.length > 0) {
                let filterBankObj = bankObj.filter((data) => data)[0];
                if (filterBankObj.name && filterBankObj.accountNumber) {
                    fields['bankName'] = filterBankObj.name;
                    fields['accountNumber'] = filterBankObj.accountNumber;
                    disabledFields['accountNumber'] = true;
                    selectedMemberBank = filterBankObj;
                } else {
                    fields['bankName'] = filterBankObj.name;
                    fields['accountNumber'] = '';
                    disabledFields['accountNumber'] = false;
                    selectedMemberBank = filterBankObj;
                }
            } else {
                if (filteredBankList[index]) {
                    filteredBankList = filteredBankList[index];

                    if (filteredBankList && filteredBankList.name && filteredBankList.accountNumber) {
                        fields['bankName'] = filteredBankList.name;
                        fields['accountNumber'] = filteredBankList.accountNumber;
                        disabledFields['accountNumber'] = true;
                        selectedMemberBank = filteredBankList;
                    } else {
                        fields['bankName'] = filteredBankList.name;
                        fields['accountNumber'] = '';
                        disabledFields['accountNumber'] = false;
                        selectedMemberBank = filteredBankList;
                    }
                }
            }
        }
        controller.vm.setState({ backActive: index, disabledFields, selectedMemberBank: selectedMemberBank, fields, isLoading: false }, () => {
            controller.handleValidation();
        });
    },

    checkFirstMemberBank1() {
        const { ewalletList, ewalletDisplayBank } = controller.vm.props;
        let { fields, disabledFields } = controller.vm.state;
        let { selectedMemberBank } = controller.vm.state;

        if (ewalletDisplayBank) {
            //filter memberbank in bank list only take the first data
            for (let i = 0; i < ewalletList.length; i++) {
                selectedMemberBank = ewalletDisplayBank.filter((data) => data.code === ewalletList[i].code)[0];

                if (selectedMemberBank && selectedMemberBank.code === 'TNG') {
                    ewalletList[i].isSvgImage = true;
                }
            }
            if (selectedMemberBank && selectedMemberBank.accountNumber) {
                fields['accountNumber'] = selectedMemberBank.accountNumber;
                disabledFields['accountNumber'] = true;
            }
        }
        controller.vm.setState({ disabledFields, fields, selectedMemberBank: selectedMemberBank, isLoading: false, ewalletList }, () => {
            controller.handleValidation();
        });
    },

    handleChanged(selectedBankIndex) {
        return (callback) => {
            controller.vm.setState({ selectedBankIndex: selectedBankIndex }, () => {
                const { selectedBankIndex } = controller.vm.state;
                const { ewalletDisplayBank } = controller.vm.props;
                let fields = controller.vm.state.fields;
                let disabledFields = controller.vm.state.disabledFields;

                let bankObj = ewalletDisplayBank[selectedBankIndex];

                if (bankObj && bankObj.accountNumber) {
                    fields['accountNumber'] = bankObj.accountNumber;
                    disabledFields['accountNumber'] = true;
                } else {
                    fields['accountNumber'] = '';
                    disabledFields['accountNumber'] = false;
                }

                controller.vm.setState({ fields, disabledFields, selectedMemberBank: bankObj }, () => {
                    callback && callback();
                });
            });
        };
    },

    handleValidation() {
        const { minmaxLimit, depositSettings, t, user } = controller.vm.props;
        const currency = user.account.currency;
        let fields = controller.vm.state.fields;
        let errors = {};
        let isFormValid = true;

        for (const key in minmaxLimit) {
            minmaxLimit[key] = minmaxLimit[key].replace(/,/g, '');
        }

        if (typeof fields['accountNumber'] !== undefined) {
            let accountNumberStr = fields['accountNumber'];
            if (!accountNumberStr || accountNumberStr.length <= 0) {
                isFormValid = false;
                errors['accountNumber'] = 'inputHelpBlock:inputHelpBlock.transfer.accountNumberRequired';
            } else {
                if (!accountNumberStr.match(/^[0-9]*$/)) {
                    isFormValid = false;
                    errors['accountNumber'] = 'transaction:transaction.withdrawal.accountNumber-error';
                } else {
                    errors['accountNumber'] = '';
                }
            }
        }

        const minLimitNumber = minmaxLimit && minmaxLimit.minLimit ? Number(minmaxLimit.minLimit) : 0;
        const maxLimitNumber = minmaxLimit && minmaxLimit.maxLimit ? Number(minmaxLimit.maxLimit) : 0;
        if (typeof fields['amount'] !== undefined) {
            let amountStr = fields['amount'];
            if (!amountStr || amountStr.length <= 0) {
                isFormValid = false;
                errors['amount'] = 'transaction:transaction.withdrawal.invalidAmount';
            } else {
                let amount = Number(amountStr);
                if (amount >= 0) {
                    // decimal checking
                    if (depositSettings.disableDecimalWithdraw && !amountStr.match(/^[0-9]*$/)) {
                        isFormValid = false;
                        errors['amount'] = 'transaction:transaction.withdrawal.disableDecimalError';
                    } else if (amount < minLimitNumber) {
                        isFormValid = false;
                        errors['amount'] = t('transaction:transaction.deposit.onlinetransfer.min_maxAmount', {
                            currency,
                            minLimit: window.SPL_Other.formatAmountNoDecimal(minLimitNumber),
                            maxLimit: window.SPL_Other.formatAmountNoDecimal(maxLimitNumber, t),
                        });
                    } else if (amount > maxLimitNumber) {
                        isFormValid = false;
                        errors['amount'] = t('transaction:transaction.deposit.onlinetransfer.min_maxAmount', {
                            currency,
                            minLimit: window.SPL_Other.formatAmountNoDecimal(minLimitNumber),
                            maxLimit: window.SPL_Other.formatAmountNoDecimal(maxLimitNumber, t),
                        });
                    } else {
                        errors['amount'] = '';
                    }
                } else {
                    isFormValid = false;
                    errors['amount'] = 'transaction:transaction.withdrawal.invalidAmount';
                }
            }
        }

        controller.vm.setState({
            fields,
            errors,
            isFormValid: isFormValid,
        });
    },

    getMemberDepositWithdrawDecimalSettings() {
        window.SPL_Member.getMemberDepositWithdrawDecimalSettings().then((data) => {
            if (data) {
                controller.vm.setState({ memberDecimalSettings: data?.withdrawal || {} });
            }
        });
    },

    handleAmountChange(e) {
        const value = e.target.value;
        if (value) {
            let regex = '';
            if (controller.vm.state.memberDecimalSettings?.[AMOUNT_DEPOSIT_METHOD_CODE.EA]) {
                regex = /^[0-9]+$/;
            } else {
                regex = /^(\d*)\.{0,1}(\d){0,2}$/;
            }
            if (value.toString().match(regex)) {
                controller.handleFieldChanged(e, 'amount');
            }
        } else {
            controller.handleFieldChanged(e, 'amount');
        }
    },

    handleFieldChanged(e, field) {
        let fields = controller.vm.state.fields;
        fields[field] = e.target.value;

        controller.vm.setState({ fields }, () => {
            controller.handleValidation();
        });
    },

    submitForm() {
        const { isFormValid, fields } = controller.vm.state;
        const { t, authSettingsReducer, popupReducer, dispatch } = controller.vm.props;
        const { amount } = fields;

        const _authSettingsWithdrawalEwallet = authSettingsReducer?.processedData?.[MT.WITHDRAWAL_EWALLET];
        const _config = {
            gaEnabled: _authSettingsWithdrawalEwallet?.gaEnabled,
            sixPinEnabled: _authSettingsWithdrawalEwallet?.sixPinEnabled,
        };

        if (isFormValid) {
            controller.vm.setState({ disableWithdrawButton: true });
            // get main wallet amount again and do validation before proceed
            window.SPL_Member.getMainWallet().then((mainWallet) => {
                if (amount > mainWallet.data.balance) {
                    controller.vm.setState({ disabledButton: false });
                    notification.showNotification('error', t('transaction:transaction.transfer.insuffficientMainWallet'));
                    return;
                }

                if (_authSettingsWithdrawalEwallet?.sixPinEnabled && !popupReducer?.enteredPin) {
                    dispatch(popupAction.setItemConfig(POPUP_TYPE.PIN_ENTRY, _config));
                    dispatch(popupAction.setPopup(POPUP_TYPE.PIN_ENTRY, true));
                    dispatch(popupAction.setSubmitSuccessCb(controller.continueSubmitForm.bind(this)));
                    controller.vm.setState({ disableWithdrawButton: false });
                } else {
                    controller.continueSubmitForm();
                }
            });
        }
    },

    continueSubmitForm() {
        const { isFormValid, fields, selectedMemberBank } = controller.vm.state;
        const { t, user, popupReducer, dispatch } = controller.vm.props;
        const { account } = user;
        const { amount, bankBranch, accountNumber } = fields;

        if (isFormValid) {
            dispatch(portalAction.setIsSubmitting(true));
            controller.vm.setState({ disableWithdrawButton: true });
            let _memberBankId = 0;
            let _bankId = 0;
            let _bankName = '';
            let _bankBranch = bankBranch;

            let _bankObj = selectedMemberBank;

            if (_bankObj) {
                const { memberBankId, bankId, id, bankName, name } = _bankObj;
                if (memberBankId) _memberBankId = memberBankId;

                if (bankId) _bankId = bankId;

                if (id) _bankName = bankName;
                else _bankName = fields.bankName ? fields.bankName : name;
            }

            let withdrawObj = {
                accountName: account.name,
                accountNumber: accountNumber,
                memberBankId: _memberBankId,
                memberBankBankId: _bankId,
                amount: amount,
                bankBranch: _bankBranch,
                bankName: _bankName,
                merchantCode: window.merchantCode,
                transactionOption: 'R',
                sixDigitPin: popupReducer?.enteredPin,
                verificationType: OVT.WITHDRAWAL_EWALLET,
            };
            core.portalIsLoading(controller.vm.props, true);

            window.SPL_Transaction.requestWithdraw(withdrawObj)
                .then((data) => {
                    if (data.status === 'Fail') {
                        if (data.errorCode === 2717) {
                            dispatch(authSettingsAction.setSixPinLocked()); // this will trigger affiliate-hook setModuleSixPinLocked
                        }

                        controller.vm.setState({ disableWithdrawButton: false });
                        notification.showNotification('error', t(data.message));
                    } else {
                        controller.checkFirstMemberBank();
                        controller.vm.props.dispatch(walletAction.udpateLoadWallet(true));
                        controller.vm.setState({
                            disableWithdrawButton: false,
                            filteredBankList: [],
                            fields: {
                                bankBranch: '',
                                accountNumber: '',
                                amount: '',
                            },
                        });
                        dispatch(popupAction.setPopup(POPUP_TYPE.PIN_ENTRY, false));
                        dispatch(popupAction.setEnteredPin(''));
                        notification.showNotification('success', t('transaction:transaction.withdrawal.request-success'));
                    }
                    core.portalIsLoading(controller.vm.props, false);
                    dispatch(portalAction.setIsSubmitting(false));
                })
                .catch((err) => {
                    controller.vm.setState({ disableWithdrawButton: false });
                    core.portalIsLoading(controller.vm.props, false);
                    dispatch(portalAction.setIsSubmitting(false));
                });
        }
    },
};

export default controller;
