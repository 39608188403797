import React, { lazy } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import core from 'root/utils/core';

import DesktopFishing from './desktop/Fishing';
import MobileFishing from './mobile';

class Fishing extends React.Component {
    components = {
        desktop: {
            default: DesktopFishing,
        },
        mobile: {
            default: MobileFishing,
        },
    };

    render() {
        const TagName = core.getCorrectComponent(this.components, 'fishing', this.props.screen.viewType);
        return <TagName></TagName>;
    }
}

export default connect(mapStateToProps, null)(Fishing);
