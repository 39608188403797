import React from 'react';
import { Translation, withTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import commonUtil from 'root/utils/common-util';
import moment from 'moment';

class HistoryPopup extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
        this.state = {};
    }

    componentDidMount() {
        commonUtil.loadCustomTranslation('instant-rebate', this, function () {});
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        const { isOpen, closeFunc, item, startDate, endDate } = this.props;
        const { customTranslationObj } = this.state;
        return (
            <Translation>
                {(t) => (
                    <Modal
                        overlayClassName='standard-popup-overlay reward-popup-overlay'
                        className='standard-popup-modal-container reward-popup-container'
                        isOpen={isOpen}
                        shouldCloseOnOverlayClick={false}
                        shouldCloseOnEsc={false}
                        onRequestClose={() => closeFunc()}
                    >
                        <div className='standard-popup-modal-header reward-popup-header'>
                            <div className='popup-header-left'>
                                <div className='popup-title'>{moment(startDate).format('YYYY-MM-DD').toString() + '-' + moment(endDate).format('YYYY-MM-DD').toString()}</div>
                            </div>
                            <div className='standard-modal-close' onClick={() => closeFunc()}>
                                X
                            </div>
                        </div>
                        {item && item.length > 0 && (
                            <div className='popup-provider-table'>
                                {item.map((hist, i) => (
                                    <>
                                        <div className='popup-provider-table-header'>
                                            {customTranslationObj && customTranslationObj[hist.product] ? t(customTranslationObj[hist.product]) : hist.product}
                                        </div>

                                        <div className='popup-provider-table-list'>
                                            {hist.productHistories.map((list, i) => (
                                                <ul>
                                                    <li>
                                                        <span>{t('rebate:rebate.memberLevel', 'Membership Level')}</span>
                                                        <span className='list'>{list.memberGroupCode}</span>
                                                    </li>
                                                    <li>
                                                        <span>{t('rebate:rebate.provider', 'Provider')}</span>
                                                        <span className='list'>
                                                            {customTranslationObj && customTranslationObj[list.provider]
                                                                ? t(customTranslationObj[list.provider])
                                                                : list.provider}
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>{t('rebate:rebate.rebate', 'Rebate %')}</span>
                                                        <span className='list'>{list.rate}%</span>
                                                    </li>
                                                    <li>
                                                        <span>{t('rebate:rebate.validBet', 'Valid Bet')}</span>
                                                        <span className='list'>{list.totalEligibleTurnOver}</span>
                                                    </li>
                                                    <li>
                                                        <span>{t('rebate:rebate.rebateEarned', 'Rebate Earn')}</span>
                                                        <span className='list rebate-earn'>{list.releasedAmount.toFixed(2)}</span>
                                                    </li>
                                                </ul>
                                            ))}
                                        </div>
                                    </>
                                ))}
                            </div>
                        )}
                    </Modal>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['rebate'])(HistoryPopup));
