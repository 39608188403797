import React from 'react';
import notification from 'root/utils/notification';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';

class QuickPayRedirect extends React.Component {
    isCalled = false;

    componentDidMount() {
        if (!this.isCalled) {
            this.isCalled = true;
            window.myProps = this.props;
            window.addEventListener('message', this.showToastMessage, false);
            window.SPL_Transaction.onlineTransferRedirectUrl();
        }
    }

    showToastMessage(event) {
        let toastInterval = null;
        let props = event.currentTarget.myProps;
        const { viewType } = props.screen;
        if (viewType === 'mobile' && event && event.data !== undefined && event.data !== null && event.data.identifier === 'zt828') {
            let eventData = event.data.data;
            for (let i = 0; i < event.data.data.length; i++) {
                if (eventData[i].key === 'isRedirectToAPP' && eventData[i].value === true) {
                    toastInterval = setInterval(() => {
                        if (props.t) {
                            notification.showNotification(
                                'info',
                                props.t(
                                    'transaction:transaction.deposit.qrpay.appReminder',
                                    'Redirecting to TrueMoney APP \nKindly ensure that you have downloaded the TrueMoney APP'
                                ),
                                {
                                    autoClose: 5000,
                                    style: {
                                        background: '#fccbc7',
                                        border: '1px solid #ff0000',
                                        'white-space': 'pre-line',
                                    },
                                }
                            );
                            _toastClearIntervalFunc();
                        }
                    }, 100);
                }
            }
        }

        function _toastClearIntervalFunc() {
            clearInterval(toastInterval);
        }
    }

    render() {
        return <div></div>;
    }
}

export default connect(mapStateToProps, null)(withTranslation(['transaction'])(withRouter(QuickPayRedirect)));
