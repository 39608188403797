import React, { useState, useEffect } from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { MdClose } from 'react-icons/md';

const UploadImage = ({ children, onChange, value, accept, ...rest }) => {
    /**
     * @param {[File, React.SetStateAction<File>]}
     */
    const [fileData, setFileData] = useState();
    const [imagePath, setImagePath] = useState(value);
    const [acceptType, setAcceptType] = useState('image/*,.pdf');

    useEffect(() => {
        if (accept?.length) {
            const acceptType = [];
            for (let i = 0; i < accept.length; i++) {
                acceptType.push(`.${accept[i]}`);
            }
            setAcceptType(acceptType.join(','));
        }
    }, [accept]);

    /**
     * @param {React.ChangeEvent<HTMLInputElement>} event
     */
    const _onChange = (event) => {
        const files = event.target.files;
        if (!files.length) return;

        let fileData = null;
        let imagePath = null;

        if (!accept?.length || accept.some((type) => files[0].type.includes(type))) {
            fileData = files[0];
            imagePath = URL.createObjectURL(files[0]);
        }

        setFileData(fileData);
        setImagePath(imagePath);
        onChange(event);
    };

    return (
        <div className='uploadWrapper' {...rest}>
            <input className='inputFile' type='file' accept={acceptType} onChange={_onChange} />
            {children(fileData, imagePath)}
        </div>
    );
};

class PromoApply extends React.Component {
    constructor(props) {
        super();
    }

    componentDidMount() {
        this.props.getCountryInfo().then(() => {
            this.props.getApplyFormField();
        });
    }

    render() {
        const {
            promoInfo,
            fieldsDisplay,
            promoApplyInput,
            telCodeOption,
            disableSubmitBtn,
            toggleModal,
            handleFieldChanged,
            handleValidation,
            handleApplySubmit,
            promotionDisabledField,
            providerWalletsList,
        } = this.props;

        return (
            <Translation>
                {(t) => (
                    <div className='promosDetail-popup promosApply-popup'>
                        <div className='popup'>
                            <div className='popup-header' style={{ backgroundColor: promoInfo.line }}>
                                <div className='popup-header-left'>
                                    <span className='popup-request'>{t('promotion:promotion.fillup-detail', 'Please fill up the detail')}</span>
                                    <span className='popup-title'>{t(promoInfo.title)}</span>
                                </div>

                                <div className='popup-header-right'>
                                    <MdClose style={{ fill: promoInfo.line }} onClick={() => toggleModal('')} />
                                </div>
                            </div>

                            <div className='popup-body'>
                                {/* Full Name form */}
                                {fieldsDisplay.showName && (
                                    <div className='body-field name-field'>
                                        <span className='field-title'>{t('global:global.form.fullname', 'Full Name')}</span>

                                        <input
                                            type='text'
                                            className='field-input'
                                            value={promoApplyInput['name']}
                                            disabled={promotionDisabledField.includes('NAME')}
                                            onChange={(e) => {
                                                handleFieldChanged(e, 'name');
                                            }}
                                            onBlur={() => handleValidation()}
                                        />
                                    </div>
                                )}

                                {/* Phone number form */}
                                {fieldsDisplay.showPhone && (
                                    <div className='body-field phone-field'>
                                        <span className='field-title'>{t('global:global.form.phone', 'Telephone Number')}</span>

                                        <div className='field-option'>
                                            <select
                                                className='option-select'
                                                value={promoApplyInput['telCode']}
                                                disabled={promotionDisabledField.includes('PHONE')}
                                                onChange={(e) => {
                                                    handleFieldChanged(e, 'telCode');
                                                    handleValidation();
                                                }}
                                            >
                                                {telCodeOption.map((option) => (
                                                    <option key={option} value={'+' + option}>
                                                        {'+' + option}
                                                    </option>
                                                ))}
                                            </select>

                                            <input
                                                type='number'
                                                className='field-input option-input'
                                                value={promoApplyInput['phone']}
                                                disabled={promotionDisabledField.includes('PHONE')}
                                                onChange={(e) => handleFieldChanged(e, 'phone')}
                                                onBlur={() => handleValidation()}
                                            />
                                        </div>
                                    </div>
                                )}

                                {/* E-mail form */}
                                {fieldsDisplay.showEmail && (
                                    <div className='body-field email-field'>
                                        <span className='field-title'>{t('global:global.form.email', 'Email Address')}</span>

                                        <input
                                            type='email'
                                            className='field-input'
                                            value={promoApplyInput['email']}
                                            disabled={promotionDisabledField.includes('EMAIL')}
                                            onChange={(e) => handleFieldChanged(e, 'email')}
                                            onBlur={() => handleValidation()}
                                        />
                                    </div>
                                )}

                                {/* bet id form */}
                                {fieldsDisplay.showBetId && (
                                    <div className='body-field name-field'>
                                        <span className='field-title'>{t('global:global.form.betId', 'Bet Ticket ID')}</span>

                                        <input
                                            type='text'
                                            className='field-input'
                                            value={promoApplyInput['betId']}
                                            disabled={promotionDisabledField.includes('BET_TICKET_ID')}
                                            onChange={(e) => handleFieldChanged(e, 'betId')}
                                            onBlur={() => handleValidation()}
                                        />
                                    </div>
                                )}

                                {/* show score prediction form */}
                                {fieldsDisplay.showScorePrediction && (
                                    <div className='body-field name-field'>
                                        <span className='field-title'>{t('global:global.form.scorePrediction', 'Score Prediction')}</span>

                                        <input
                                            type='text'
                                            className='field-input'
                                            value={promoApplyInput['scorePrediction']}
                                            disabled={promotionDisabledField.includes('SCORE_PREDICTION')}
                                            onChange={(e) => handleFieldChanged(e, 'scorePrediction')}
                                            onBlur={() => handleValidation()}
                                        />
                                    </div>
                                )}

                                {/* e-wallet form */}
                                {fieldsDisplay.showEWallet && (
                                    <div className='body-field name-field'>
                                        <span className='field-title'>{t('global:global.form.eWallet', 'E-Wallet')}</span>

                                        <input
                                            type='text'
                                            className='field-input'
                                            value={promoApplyInput['ewallet']}
                                            disabled={promotionDisabledField.includes('E_WALLET')}
                                            onChange={(e) => handleFieldChanged(e, 'ewallet')}
                                            onBlur={() => handleValidation()}
                                        />
                                    </div>
                                )}

                                {/* provider wallet form */}
                                {fieldsDisplay.showProviderWallet && (
                                    <div className='body-field name-field'>
                                        <span className='field-title'>{t('global:global.form.providerWallet', 'Provider Wallet')}</span>

                                        {/* <input
                                            type='text'
                                            className='field-input'
                                            value={promoApplyInput['providerWallet']}
                                            disabled={promotionDisabledField.includes('PROVIDER_WALLET')}
                                            onChange={(e) => handleFieldChanged(e, 'providerWallet')}
                                            onBlur={() => handleValidation()}
                                        /> */}
                                        <select
                                            className='field-select'
                                            value={promoApplyInput['providerWallet']}
                                            onChange={(e) => {
                                                handleFieldChanged(e, 'providerWallet');
                                                handleValidation();
                                            }}
                                        >
                                            {(providerWalletsList || []).map((provider, i) => (
                                                <option key={i} value={provider.name}>
                                                    {provider.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                )}

                                {/* Address form */}
                                {fieldsDisplay.showAddress && (
                                    <div className='body-field address-field'>
                                        <label className='field-title'>{t('global:global.form.address', 'Address')}</label>
                                        <textarea
                                            className='field-input field-text'
                                            rows='5'
                                            maxLength='150'
                                            value={promoApplyInput['address']}
                                            disabled={promotionDisabledField.includes('ADDRESS')}
                                            onChange={(e) => handleFieldChanged(e, 'address')}
                                            onBlur={() => handleValidation()}
                                        />
                                    </div>
                                )}

                                {/* Remark area form */}
                                {fieldsDisplay.showRemark && (
                                    <div className='body-field remark-field'>
                                        <label className='field-title'>{t('global:global.form.remark', 'Remark')}</label>

                                        <textarea
                                            className='field-input field-text'
                                            rows='5'
                                            value={promoApplyInput['remark']}
                                            disabled={promotionDisabledField.includes('REMARK')}
                                            onChange={(e) => handleFieldChanged(e, 'remark')}
                                            onBlur={() => handleValidation()}
                                        ></textarea>
                                    </div>
                                )}

                                {/* shipping address form */}
                                {fieldsDisplay.showShippingAddress && (
                                    <div className='body-field address-field'>
                                        <label className='field-title'>{t('global:global.form.shippingAddress', 'Shipping Address')}</label>

                                        <textarea
                                            className='field-input field-text'
                                            rows='5'
                                            maxLength='150'
                                            value={promoApplyInput['shippingAddress']}
                                            disabled={promotionDisabledField.includes('SHIPPING_ADDRESS')}
                                            onChange={(e) => handleFieldChanged(e, 'shippingAddress')}
                                            onBlur={() => handleValidation()}
                                        ></textarea>
                                    </div>
                                )}

                                {/* Upload area form */}
                                {fieldsDisplay.showReceiptImage && (
                                    <div className='body-field address-field'>
                                        <label className='field-title'>{t('global:global.form.uploadImage', 'Upload Image')}</label>
                                        <div className='field-option'>
                                            <UploadImage
                                                accept={this.props.portal.settings?.features?.promotionSettings?.uploadImageType || []}
                                                value={promoApplyInput['receiptImagePath']}
                                                onChange={(e) => handleFieldChanged(e, 'receiptImageFileToUpload')}
                                            >
                                                {(file, imagePath) => (
                                                    <div className='upload-image-field d-flex standard-m-form-col-full standard-form-field'>
                                                        {!imagePath && (
                                                            <div className='uploadButton'>
                                                                <span>{t('global:global.form.uploadImage', 'Upload image')}</span>
                                                                <span className='uploadIcon'>
                                                                    <img src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA/UlEQVR4nO3VsWrDMBAGYE/d2ofoE4SStoOn0D2rXyOvEUq7lw4ldEgKhWTq0mfIZgi6s30n33lJ8hBFTtzBlChxNeaHH2mQvuEQKIr2AdheAcsCSF8OV5bI1U3kiyHtAcnIdw5Ypq5e1BwJIstbmqYXSPqel+VtENCtXtQcC5KMgfR1P9MJsqxXq81lZ7AdIBm5uyeBppB7k5d3QUAsNEHW57qFJv8CsdAESB6Ntdeubt9G4RSwfugsT0j66brby3dnMGN9yLiK3VOpSzJAq8POYJMGjP4IdAGB5ANYZsHAjKs4s7YfDDwUOIO/CT7D3RegX/4voFWWubvbQD9jIKNV9PL9+AAAAABJRU5ErkJggg==' />
                                                                </span>
                                                            </div>
                                                        )}
                                                        {imagePath && <img src={imagePath} width='100%' alt={file?.name || 'receipt image'} />}
                                                    </div>
                                                )}
                                            </UploadImage>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className='popup-footer'>
                                <div className='popup-footer-left'></div>

                                <div className='popup-footer-right'>
                                    <button
                                        disabled={disableSubmitBtn}
                                        type='submit'
                                        className='popup-btn'
                                        style={{ backgroundColor: promoInfo.line }}
                                        onClick={() => handleApplySubmit()}
                                    >
                                        {t('message:message.confirm', 'Confirm').toUpperCase()}
                                    </button>

                                    <button disabled='' type='submit' className={`popup-btn`} onClick={() => toggleModal('')}>
                                        {t('global:entity.action.cancel', 'Cancel')}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className='popup-overlay' onClick={() => toggleModal('')}></div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['promotion', 'transaction', 'message'])(withRouter(PromoApply)));
