import { screenConstants } from '../constant';
import Utils from 'root/utils';

const initialState = {
    width: Math.min(document.documentElement.clientWidth, window.outerWidth, window.innerWidth),
    height: Math.min(document.documentElement.clientHeight, window.outerHeight, window.innerHeight),
    isMobile: false, // Math.min(document.documentElement.clientWidth, window.outerWidth, window.innerWidth) <= 768 ? true : false,
    viewType: Utils.force2Mobile() ? 'mobile' : 'web', // Math.min(document.documentElement.clientWidth, window.outerWidth, window.innerWidth) <= 768 ? 'mobile' : 'web',
    isMobileAgent: false,
};

export function screen(state = initialState, action) {
    switch (action.type) {
        case screenConstants.UPDATE_SCREEN_SIZE:
            return {
                ...state,
                width: action.payload.width,
                height: action.payload.height,
                isMobile: action.payload.isMobile,
                viewType: action.payload.viewType,
                isMobileAgent: action.payload.isMobileAgent,
            };

        default:
            return state;
    }
}
