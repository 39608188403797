import React from 'react';
import { connect } from 'react-redux';
import { Trans, Translation, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import { AiOutlineCopy } from 'react-icons/ai';
import SmallLoading from 'root/webapp/shared/loading/smallLoading';
import SVGElements from 'root/components/SVGElements';
import QRCode from 'qrcode.react';
import { DebounceInput } from 'react-debounce-input';
import DatePicker from 'react-datepicker';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { EmailIcon, EmailShareButton, LineIcon, LineShareButton, TelegramIcon, TelegramShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';

import vm from '../../deposit.controller';
import core from 'root/utils/core';
import { Fragment } from 'react';
import SVGBank from 'root/components/svgIcon/svgBank';
import SVGBankApp from 'root/components/svgIcon/svgBankApp';
import { trans_getDepositAmountOptionByMethod } from 'root/utils/transaction-util';

class Deposit extends React.Component {
    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
    }

    componentDidMount() {
        vm.initDepositPage();
        vm.loadBankAccounts();
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        const {
            isFormValid,
            disableDepositButton,
            cryptoQrLink,
            depositAllSettings,
            isBankApp,
            isQRPay,
            qrPayDepositChannelObjs,
            selectedChannelIndex,
            expressDepositMemberBankList,
            expressDepositMerchantBankList,
            verifyAccountList,
            showAllBankMaintenaceMsg,
            depositAmountOptions,
        } = this.state;

        const amountOptions = trans_getDepositAmountOptionByMethod(depositAmountOptions);

        let bankList = this.state.unOwnedBankList;

        let userCurrency = this.props.user && this.props.user.account && this.props.user.account.currency ? this.props.user.account.currency : null;

        if (userCurrency === 'KHUSD') userCurrency = 'USD';

        return (
            <Translation>
                {(t) => (
                    <div className='deposit-page'>
                        {this.state.memberVerifyPopup && (
                            <div
                                className='memberVerify-box'
                                onClick={() => {
                                    vm.close('memberVerify');
                                }}
                            >
                                <div
                                    className='memberVerify-popup'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <div className='title'>
                                        <span>{t('transaction:transaction.deposit.crypto.memberVerify', 'Member Information Verification')}</span>
                                        <i
                                            className='icon-close3'
                                            onClick={() => {
                                                vm.close('memberVerify');
                                            }}
                                        ></i>
                                    </div>
                                    <div className='content'>
                                        {this.state.showName && (
                                            <div className='input'>
                                                <DebounceInput
                                                    type='text'
                                                    debounceTimeout='1000'
                                                    values={this.state.cryptoFields['fullname']}
                                                    placeholder={t('global:global.form.fullname', 'Full Name')}
                                                    onChange={(e) => {
                                                        vm.handleCryptoFieldChanged(e, 'fullname');
                                                    }}
                                                />
                                                <div className='input-error'>
                                                    {this.state.errors['fullname'] !== 'global:global.form.fullname-placeholderv2' && t(this.state.errors['fullname'])}
                                                </div>
                                            </div>
                                        )}

                                        {this.state.showEmail && (
                                            <div className='input'>
                                                <DebounceInput
                                                    type='text'
                                                    debounceTimeout='1000'
                                                    values={this.state.fields['email']}
                                                    placeholder={t('global:global.form.email', 'Email Address')}
                                                    onChange={(e) => {
                                                        vm.handleCryptoFieldChanged(e, 'email');
                                                    }}
                                                />
                                                <div className='input-error'>{t(this.state.errors['email'])}</div>
                                            </div>
                                        )}

                                        {this.state.showPhone && (
                                            <div className='input'>
                                                <div>
                                                    <select
                                                        className='phone'
                                                        value={this.state.fields['telCode']}
                                                        onChange={(e) => {
                                                            vm.handleCryptoFieldChanged(e, 'telCode');
                                                        }}
                                                    >
                                                        {this.state.optionsList['telCode'].map((option, i) => (
                                                            <option key={i} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <DebounceInput
                                                        type='text'
                                                        debounceTimeout='1000'
                                                        values={this.state.fields['phone']}
                                                        placeholder={t('global:global.form.phone', 'Telephone Number')}
                                                        onChange={(e) => {
                                                            vm.handleCryptoFieldChanged(e, 'phone');
                                                        }}
                                                        className='short'
                                                    />
                                                </div>
                                                <div className='input-error'>{t(this.state.errors['phone'])}</div>
                                            </div>
                                        )}

                                        {this.state.showDob && (
                                            <div className='input'>
                                                <div>
                                                    <span className='item-title'>{t('global:global.form.dob', 'Date of Birth')}</span>
                                                    <DatePicker
                                                        autoComplete='off'
                                                        type='text'
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        placeholderText={t('transaction:transaction.deposit.cashdeposit.depositdate', 'Deposit Date/Time')}
                                                        selectsStart
                                                        selected={this.state.cryptoFields.dob}
                                                        maxDate={this.state.maxDob}
                                                        popperPlacement='bottom-end'
                                                        onChange={(e) => {
                                                            vm.handleCryptoFieldChanged(e, 'dob');
                                                        }}
                                                        timeFormat='yyyy-MM-dd'
                                                        dateFormat='yyyy-MM-dd'
                                                        className='short'
                                                    />
                                                </div>
                                                <div className='input-error'>{t(this.state.errors['dob'])}</div>
                                            </div>
                                        )}

                                        {this.state.requestRefill && (
                                            <div className='input'>
                                                <div className='input-error'>{t('transaction:transaction.deposit.crypto.fillAgain', 'Please fill in again.')}</div>
                                            </div>
                                        )}

                                        <button className='btn-submit' disabled={this.state.memberVerifyDisable} onClick={() => vm.handleMemberVerifySubmit()}>
                                            {t('transaction:transaction.history.filter.submit', 'Submit').toUpperCase()}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {this.state.getShareInfo && (
                            <div
                                className='shareInfo-box'
                                onClick={() => {
                                    vm.close('shareInfo');
                                }}
                            >
                                <div
                                    className='shareInfo-popup'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <div className='title'>
                                        <span>{t('settings:settings.inviteFriend', 'Invite Friend')}</span>
                                        <i
                                            className='icon-close3'
                                            onClick={() => {
                                                vm.close('shareInfo');
                                            }}
                                        ></i>
                                    </div>
                                    <div className='content shareInfo-content'>
                                        <EmailShareButton url={this.state.cryptoQrCode} className='shareInfo-link'>
                                            <EmailIcon size={32} round={true} className='share-icon' />
                                            <span className='m-l-5'>Email</span>
                                        </EmailShareButton>
                                        <WhatsappShareButton url={this.state.cryptoQrCode} className='shareInfo-link'>
                                            <WhatsappIcon size={32} round={true} className='share-icon' />
                                            <span className='m-l-5'>Whatsapp</span>
                                        </WhatsappShareButton>
                                        <TelegramShareButton url={this.state.cryptoQrCode} className='shareInfo-link'>
                                            <TelegramIcon size={32} round={true} className='share-icon' />
                                            <span className='m-l-5'>Telegram</span>
                                        </TelegramShareButton>
                                        <LineShareButton url={this.state.cryptoQrCode} className='shareInfo-link'>
                                            <LineIcon size={32} round={true} className='share-icon' />
                                            <span className='m-l-5'>LINE</span>
                                        </LineShareButton>
                                        <button
                                            className='shareInfo-copy'
                                            onClick={() => {
                                                core.copy(t, this.state.cryptoQrCode);
                                            }}
                                        >
                                            <AiOutlineCopy></AiOutlineCopy> <span>Copy to Clipboard</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {this.state.showQrBankList && (
                            <div
                                className='qrbanklist-box'
                                onClick={() => {
                                    vm.toggleQrPayBankList(false);
                                }}
                            >
                                <div
                                    className='qrbanklist-popup'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <div className='title'>
                                        <span>{t('transaction:transaction.deposit.qrpay.supportedBanks.popup-title', 'List of supported bank for QR code deposit')}</span>
                                        <i
                                            className='icon-close3'
                                            onClick={() => {
                                                vm.toggleQrPayBankList(false);
                                            }}
                                        ></i>
                                    </div>
                                    <div className='content qrbanklist-content'>
                                        {this.state.qrpaySupportedBankList.map((bank, index) => {
                                            return bank.isDummyBank ? (
                                                <div className='bank-container' key={index} style={{ marginRight: index % 3 === 0 ? '0' : '0.15rem' }}></div>
                                            ) : (
                                                <div className='bank-container' key={index} style={{ marginRight: index % 3 === 0 ? '0' : '0.15rem' }}>
                                                    <div className='icon-container'>
                                                        {/* <SVGBank
                                                            className={`${core.getCorrectBankIcon(bank.code, this, true)}`}
                                                            name={`${core.getCorrectBankIcon(bank.code, this, true)}`}
                                                        ></SVGBank> */}
                                                        {bank.code && bank.code.toLowerCase().includes('other') ? (
                                                            <SVGBank
                                                                className={`${core.getCorrectBankIcon(bank.code, this, true)}`}
                                                                name={`${core.getCorrectBankIcon(bank.code, this, true)}`}
                                                            ></SVGBank>
                                                        ) : (
                                                            <img
                                                                className={`${core.getCorrectBankIcon(bank.code, this, true)}`}
                                                                src={`/public/images/bankImg/${userCurrency}/${bank.code}.png`}
                                                                alt='bank-icon'
                                                            />
                                                        )}
                                                    </div>
                                                    <div className='bankname-container'>{bank.bankName}</div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className='group-item deposit-options'>
                            <div className='title'>
                                <span>{t('transaction:transaction.deposit.onlinetransfer.option', 'Deposit Options')}</span>
                                <span className='required'>*</span>
                            </div>
                            <div className='deposit-options-list'>
                                <ul>
                                    {this.state.methodList.map((method, i) => (
                                        <li
                                            key={i}
                                            className={['icon-hover-box', this.state.selectedMethodIndex === i ? 'on' : ''].join(' ')}
                                            onClick={() => {
                                                vm.onMethodListChanged(method);
                                            }}
                                        >
                                            <span className='icon-box'>
                                                {method.key !== 'fpy2' && <i className={'icon-deposit-' + method.key}></i>}
                                                {method.key === 'fpy2' && (
                                                    <SVGElements
                                                        className={method.key}
                                                        name={method.key}
                                                        width='30px'
                                                        height='100%'
                                                        color={this.state.selectedMethodIndex === i ? '#FF4E00' : 'gray'}
                                                    />
                                                )}
                                            </span>
                                            <span>{method.code === 'H2PP' ? 'Promptpay' : t(method.shortName)}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        {this.state.isExpressDeposit && (
                            <Fragment>
                                <div className='group-item bank-options'>
                                    <div className='title'>
                                        <span>{t('transaction:transaction.deposit.expressDeposit.toBankAccount', 'To Bank Account')}</span>
                                        <span className='required'>*</span>
                                    </div>
                                    <div
                                        className={`bank-list express-deposit-bank-box ${
                                            this.state.showAllBankMaintenaceMsg || this.state.showNotBankSupportMsg ? 'express-deposit-msg-box' : ''
                                        }`}
                                    >
                                        {expressDepositMemberBankList && !this.state.showAllBankMaintenaceMsg ? (
                                            <Fragment>
                                                {expressDepositMerchantBankList && !this.state.showNotBankSupportMsg ? (
                                                    <Fragment>
                                                        {expressDepositMerchantBankList.map((bank, i) => {
                                                            return (
                                                                <ul>
                                                                    <Fragment>
                                                                        <li className='icon-hover-box'>
                                                                            <span className={`icon-box`}>
                                                                                <i className={['icon-bank'].join(' ')}>
                                                                                    {/* <SVGBank
                                                                                    className={`${core.getCorrectBankIcon(bank.bankCode, this, true)}`}
                                                                                    name={`${core.getCorrectBankIcon(bank.bankCode, this, true)}`}
                                                                                ></SVGBank> */}
                                                                                    {bank.bankCode && bank.bankCode.toLowerCase().includes('other') ? (
                                                                                        <SVGBank
                                                                                            className={`${core.getCorrectBankIcon(bank.bankCode, this, true)}`}
                                                                                            name={`${core.getCorrectBankIcon(bank.bankCode, this, true)}`}
                                                                                        ></SVGBank>
                                                                                    ) : (
                                                                                        <img
                                                                                            className={`${core.getCorrectBankIcon(bank.bankCode, this, true)}`}
                                                                                            src={`/public/images/bankImg/${userCurrency}/${bank.bankCode}.png`}
                                                                                            alt='bank-icon'
                                                                                        />
                                                                                    )}
                                                                                </i>
                                                                            </span>
                                                                            <span>{bank.name}</span>
                                                                        </li>
                                                                        <div className='express-deposit-bank-list'>
                                                                            <span>{bank.accountName}</span>
                                                                            <span>{bank.accountNumber}</span>
                                                                        </div>
                                                                    </Fragment>
                                                                </ul>
                                                            );
                                                        })}
                                                    </Fragment>
                                                ) : (
                                                    <span className='request-text'>
                                                        {t(
                                                            'transaction:transaction.deposit.expressDeposit.unavailableExpressBank',
                                                            'Express Deposit unavailable now, Please SELECT other Deposit Option, sorry for inconvenience caused'
                                                        )}
                                                    </span>
                                                )}
                                            </Fragment>
                                        ) : (
                                            <span className='request-text'>
                                                {t(
                                                    'transaction:transaction.deposit.expressDeposit.requestAddBank',
                                                    'Please add your BANK account details for use Express Deposit'
                                                )}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                {expressDepositMemberBankList && !showAllBankMaintenaceMsg && (
                                    <div className='group-item bank-options'>
                                        <div className='title'>
                                            <span>{t('transaction:transaction.deposit.expressDeposit.yourBankAccount', 'Your Bank Account')}</span>
                                            <span className='required'>*</span>
                                        </div>

                                        <div className='bank-list express-deposit-bank-column'>
                                            <ul>
                                                <Fragment>
                                                    <li className='icon-hover-box'>
                                                        <span className={`icon-box`}>
                                                            <i className={['icon-bank'].join(' ')}>
                                                                {/* <SVGBank
                                                                className={`${core.getCorrectBankIcon(expressDepositMemberBankList.bankCode, this, true)}`}
                                                                name={`${core.getCorrectBankIcon(expressDepositMemberBankList.bankCode, this, true)}`}
                                                            ></SVGBank> */}
                                                                {expressDepositMemberBankList.bankCode &&
                                                                expressDepositMemberBankList.bankCode.toLowerCase().includes('other') ? (
                                                                    <SVGBank
                                                                        className={`${core.getCorrectBankIcon(expressDepositMemberBankList.bankCode, this, true)}`}
                                                                        name={`${core.getCorrectBankIcon(expressDepositMemberBankList.bankCode, this, true)}`}
                                                                    ></SVGBank>
                                                                ) : (
                                                                    <img
                                                                        className={`${core.getCorrectBankIcon(expressDepositMemberBankList.bankCode, this, true)}`}
                                                                        src={`/public/images/bankImg/${userCurrency}/${expressDepositMemberBankList.bankCode}.png`}
                                                                        alt='bank-icon'
                                                                    />
                                                                )}
                                                            </i>
                                                        </span>
                                                        <span>{expressDepositMemberBankList.bankName}</span>
                                                    </li>
                                                    <div className='express-deposit-bank-list'>
                                                        <span>
                                                            {expressDepositMemberBankList.accountName
                                                                ? expressDepositMemberBankList.accountName
                                                                : expressDepositMemberBankList.accountHolder}
                                                        </span>
                                                        <span>{expressDepositMemberBankList.accountNumber}</span>
                                                    </div>
                                                </Fragment>
                                            </ul>
                                        </div>

                                        <button
                                            className='btn-remove'
                                            onClick={() => {
                                                vm.removeBankList(expressDepositMemberBankList);
                                            }}
                                        >
                                            {t('transaction:transaction.deposit.expressDeposit.remove', 'Remove').toUpperCase()}
                                        </button>
                                    </div>
                                )}

                                <div className='bank-add'>
                                    <div className='group-item input-item'>
                                        <div className='title'>
                                            <span>{t('bank:bank.selectBank', 'Select Bank')}</span>
                                            <span className={`required ${expressDepositMemberBankList && !showAllBankMaintenaceMsg ? 'non-required' : ''}`}>*</span>
                                        </div>
                                        <div className='group'>
                                            <div className='input-box'>
                                                <select
                                                    value={this.state.selectedChannel}
                                                    onChange={(e) => vm.handleBankDetailChange(e)}
                                                    disabled={expressDepositMemberBankList && !showAllBankMaintenaceMsg}
                                                >
                                                    <option value=''>{t('settings:settings.dropdown.pleaseselect', '--- Please Select ---')}</option>
                                                    {this.state.verifyBank.map((bank, index) => (
                                                        <option key={index} value={index}>
                                                            {bank.bankName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div
                                            className='addBankExressDeposit'
                                            onClick={() => {
                                                vm.addButtonOnClicked();
                                            }}
                                        >
                                            <span>{t('transaction:transaction.deposit.expressDeposit.addExpressDeposit', 'Click here to add bank for Express Deposit')}</span>
                                        </div>
                                    </div>

                                    <div className='group-item input-item'>
                                        <div className='title'>
                                            <span>{t('bank:bank.newBank.account-name', 'Account Name')}</span>
                                            <span className={`required ${expressDepositMemberBankList && !showAllBankMaintenaceMsg ? 'non-required' : ''}`}>*</span>
                                        </div>
                                        <div className='group'>
                                            <div className='input-box'>
                                                <input
                                                    type='text'
                                                    value={this.state.fields['verifyAccountName']}
                                                    onChange={(e) => vm.handleFieldChange(e, 'accountNumber')}
                                                    id='accountNumber'
                                                    placeholder={
                                                        verifyAccountList.accountName ? verifyAccountList.accountName : t('bank:bank.newBank.account-name', 'Account Name')
                                                    }
                                                    disabled
                                                ></input>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='group-item input-item'>
                                        <div className='title'>
                                            <span>{t('bank:bank.newBank.account-number', 'Account Number')}</span>
                                            <span className={`required ${expressDepositMemberBankList && !showAllBankMaintenaceMsg ? 'non-required' : ''}`}>*</span>
                                        </div>
                                        <div className='group'>
                                            <div className='input-box'>
                                                <input
                                                    type='text'
                                                    value={this.state.fields['verifyAccountNumber']}
                                                    onChange={(e) => vm.handleFieldChange(e, 'accountNumber')}
                                                    id='accountNumber'
                                                    placeholder={
                                                        verifyAccountList.accountNumber
                                                            ? verifyAccountList.accountNumber
                                                            : t('bank:bank.newBank.account-number', 'Account Number')
                                                    }
                                                    disabled
                                                ></input>
                                            </div>
                                        </div>
                                    </div>
                                    <button
                                        className='btn-submit'
                                        disabled={!isFormValid}
                                        onClick={() => {
                                            vm.handleSubmitExpressDepositBank();
                                        }}
                                    >
                                        {t('global:entity.action.select').toUpperCase()}
                                    </button>
                                </div>

                                {/* --- notice box ---*/}
                                <div className='notice-box'>
                                    <span className='item-title'>
                                        {t('transaction:transaction.deposit.expressDeposit.expressDepositInstructionTitle', 'Instruction to use "Express Deposit":')}
                                    </span>
                                    <div className='display-info'>
                                        <span className='key'>{t('transaction:transaction.deposit.expressDeposit.expressDepositInstruction1', 'Instruction1')}</span>
                                        <span className='value'></span>
                                    </div>
                                    <div className='display-info'>
                                        <span className='key'>{t('transaction:transaction.deposit.expressDeposit.expressDepositInstruction2', 'Instruction2')}</span>
                                        <span className='value'></span>
                                    </div>
                                    <div className='display-info'>
                                        <span className='key'>{t('transaction:transaction.deposit.expressDeposit.expressDepositInstruction3', 'Instruction3')}</span>
                                        <span className='value'></span>
                                    </div>
                                </div>
                            </Fragment>
                        )}

                        {isQRPay && (
                            <div className='group-item bank-options'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.cashdeposit.channel', 'Deposit Channel')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='bank-list'>
                                    <ul>
                                        {qrPayDepositChannelObjs.map((e, i) => (
                                            <li
                                                key={i}
                                                className={[
                                                    'icon-hover-box',
                                                    selectedChannelIndex === i ? 'on' : '',
                                                    e.channel.toLowerCase() === 'ezpqr' || e.channel.toLowerCase() === 'h2pp' ? 'qrbank-item' : '',
                                                ].join(' ')}
                                                onClick={() => {
                                                    vm.changeQRChannel(i);
                                                }}
                                                onMouseEnter={() => {
                                                    this.setState({ qrHoveredIndex: i });
                                                }}
                                                onMouseLeave={() => {
                                                    this.setState({ qrHoveredIndex: null });
                                                }}
                                            >
                                                <span
                                                    className={`icon-box ${
                                                        e.channel.toLowerCase() === 'ezpqr' || e.channel.toLowerCase() === 'h2pp' ? 'qrbank-icon-box' : ''
                                                    }`}
                                                >
                                                    <i
                                                        className={[
                                                            'icon-bank',
                                                            selectedChannelIndex === i || (this.state.qrHoveredIndex !== null && this.state.qrHoveredIndex === i)
                                                                ? 'icon-bank-on'
                                                                : 'icon-bank-off',
                                                            e.channel.toLowerCase() === 'ezpqr' || e.channel.toLowerCase() === 'h2pp'
                                                                ? 'qrbank-icon-bank'
                                                                : e.channel.toLowerCase() === 'jpy'
                                                                ? 'qrbank-icon-jpy'
                                                                : '',
                                                        ].join(' ')}
                                                    >
                                                        <SVGBank
                                                            customClassName={e.channel.toLowerCase() === 'ezpqr' || e.channel.toLowerCase() === 'h2pp' ? `ezpqrSVG` : ''}
                                                            className={`${core.getCorrectBankIcon(e.channel, this, true)}`}
                                                            name={`${core.getCorrectBankIcon(e.channel, this, true)}`}
                                                        ></SVGBank>
                                                    </i>
                                                </span>
                                                <span>{e.channelName}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        )}

                        {isQRPay && (
                            <div className='group-item bank-options'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.onlinetransfer.qrOption', 'Options')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='bank-list'>
                                    <ul>
                                        {this.state.qrPayList.map((bank, i) => {
                                            return (
                                                <li
                                                    key={i}
                                                    className={[
                                                        'icon-hover-box',
                                                        this.state.selectedBankIndex === i ? 'on' : '',
                                                        bank.code.toLowerCase() === 'ezpqr' || bank.code.toLowerCase() === 'h2pp' ? 'qrbank-item' : '',
                                                    ].join(' ')}
                                                    onClick={() => {
                                                        vm.onBankChanged(bank);
                                                    }}
                                                    onMouseEnter={() => {
                                                        this.setState({ hoveredIndex: i });
                                                    }}
                                                    onMouseLeave={() => {
                                                        this.setState({ hoveredIndex: null });
                                                    }}
                                                >
                                                    <span
                                                        className={`icon-box ${
                                                            bank.code.toLowerCase() === 'ezpqr' || bank.code.toLowerCase() === 'h2pp' ? 'qrbank-icon-box' : ''
                                                        }`}
                                                    >
                                                        <i
                                                            className={[
                                                                'icon-bank',
                                                                this.state.selectedBankIndex === i || (this.state.hoveredIndex !== null && this.state.hoveredIndex === i)
                                                                    ? 'icon-bank-on'
                                                                    : 'icon-bank-off',
                                                                bank.code.toLowerCase() === 'ezpqr' || bank.code.toLowerCase() === 'h2pp'
                                                                    ? 'qrbank-icon-bank'
                                                                    : bank.bank.channel.toLowerCase() === 'jpy'
                                                                    ? 'qrbank-icon-jpy'
                                                                    : '',
                                                            ].join(' ')}
                                                        >
                                                            {/* <SVGBank
                                                                customClassName={bank.code.toLowerCase() === 'ezpqr' || bank.code.toLowerCase() === 'h2pp' ? `ezpqrSVG` : ''}
                                                                className={`${core.getCorrectBankIcon(bank.code, this, true)}`}
                                                                name={`${core.getCorrectBankIcon(bank.code, this, true)}`}
                                                            ></SVGBank> */}
                                                            {bank.code && bank.code.toLowerCase().includes('other') ? (
                                                                <SVGBank
                                                                    customClassName={
                                                                        bank.code.toLowerCase() === 'ezpqr' || bank.code.toLowerCase() === 'h2pp' ? `ezpqrSVG` : ''
                                                                    }
                                                                    className={`${core.getCorrectBankIcon(bank.code, this, true)}`}
                                                                    name={`${core.getCorrectBankIcon(bank.code, this, true)}`}
                                                                ></SVGBank>
                                                            ) : (
                                                                <img
                                                                    className={`${core.getCorrectBankIcon(bank.code, this, true)}`}
                                                                    src={`/public/images/bankImg/${userCurrency}/${bank.code}.png`}
                                                                    alt='bank-icon'
                                                                />
                                                            )}
                                                        </i>
                                                    </span>
                                                    <span>{bank.displayName}</span>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                        )}

                        {!this.state.isCrypto && !this.state.isQRPay && !this.state.isExpressDeposit && (
                            <div className='group-item bank-options'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.onlinetransfer.bank', 'Bank Options')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='bank-list'>
                                    <ul>
                                        {this.state.bankList.map((bank, i) => (
                                            <li
                                                key={i}
                                                className={[
                                                    'icon-hover-box',
                                                    this.state.selectedBankIndex === i ? 'on' : '',
                                                    bank.code.toLowerCase() === 'ezpqr' || bank.code.toLowerCase() === 'h2pp' ? 'qrbank-item' : '',
                                                ].join(' ')}
                                                onClick={() => {
                                                    vm.onBankChanged(bank);
                                                }}
                                                onMouseEnter={() => {
                                                    this.setState({ hoveredIndex: i });
                                                }}
                                                onMouseLeave={() => {
                                                    this.setState({ hoveredIndex: null });
                                                }}
                                            >
                                                <span
                                                    className={`icon-box ${
                                                        bank.code.toLowerCase() === 'ezpqr' || bank.code.toLowerCase() === 'h2pp' ? 'qrbank-icon-box' : ''
                                                    }`}
                                                >
                                                    <i
                                                        className={[
                                                            'icon-bank',
                                                            this.state.selectedBankIndex === i || (this.state.hoveredIndex !== null && this.state.hoveredIndex === i)
                                                                ? 'icon-bank-on'
                                                                : 'icon-bank-off',
                                                            bank.code.toLowerCase() === 'ezpqr' || bank.code.toLowerCase() === 'h2pp' ? 'qrbank-icon-bank' : '',
                                                        ].join(' ')}
                                                    >
                                                        {isBankApp ? (
                                                            <SVGBankApp
                                                                customClassName={bank.code.toLowerCase() === 'ezpqr' || bank.code.toLowerCase() === 'h2pp' ? `ezpqrSVG` : ''}
                                                                className={`${core.getCorrectBankIcon(bank.code, this, true)}`}
                                                                name={`${core.getCorrectBankIcon(bank.code, this, true)}`}
                                                            ></SVGBankApp>
                                                        ) : bank.code && bank.code.toLowerCase().includes('other') ? (
                                                            <SVGBank
                                                                customClassName={bank.code.toLowerCase() === 'ezpqr' || bank.code.toLowerCase() === 'h2pp' ? `ezpqrSVG` : ''}
                                                                className={`${core.getCorrectBankIcon(bank.code, this, true)}`}
                                                                name={`${core.getCorrectBankIcon(bank.code, this, true)}`}
                                                            ></SVGBank>
                                                        ) : (
                                                            // <SVGBank
                                                            //     customClassName={bank.code.toLowerCase() === 'ezpqr' || bank.code.toLowerCase() === 'h2pp' ? `ezpqrSVG` : ''}
                                                            //     className={`${core.getCorrectBankIcon(bank.code, this, true)}`}
                                                            //     name={`${core.getCorrectBankIcon(bank.code, this, true)}`}
                                                            // ></SVGBank>
                                                            <img
                                                                className={`${core.getCorrectBankIcon(bank.code, this, true)}`}
                                                                src={`/public/images/bankImg/${userCurrency}/${bank.code}.png`}
                                                                alt='bank-icon'
                                                            />
                                                        )}
                                                    </i>
                                                </span>
                                                <span>{bank.shortBankName}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        )}

                        {(this.state.isOnlineTransfer || this.state.isReload) && this.state.bankList.length > 0 && (
                            <div className='group-item input-item'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.cashdeposit.bankaccount', 'Bank Account')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='bank-account-container'>
                                    <div className='input-box'>
                                        <input
                                            id='backaccount'
                                            type='text'
                                            autoComplete='off'
                                            disabled={true}
                                            readOnly={true}
                                            value={this.state.bankList[this.state.selectedBankIndex].accountHolder}
                                        />
                                        <AiOutlineCopy onClick={() => core.copy(t, this.state.bankList[this.state.selectedBankIndex].accountHolder)}></AiOutlineCopy>
                                    </div>
                                    <div className='input-box'>
                                        <input
                                            id='backaccount'
                                            type='text'
                                            autoComplete='off'
                                            disabled={true}
                                            readOnly={true}
                                            value={this.state.bankList[this.state.selectedBankIndex].accountNumber}
                                        />
                                        <AiOutlineCopy onClick={() => core.copy(t, this.state.bankList[this.state.selectedBankIndex].accountNumber)}></AiOutlineCopy>
                                    </div>
                                </div>
                            </div>
                        )}

                        {isBankApp && this.state.bankList.length > 0 && (
                            <div className='group-item input-item'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.cashdeposit.bankaccount', 'Bank Account')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='bank-account-container'>
                                    <div className='input-box'>
                                        <input
                                            id='backaccount'
                                            type='text'
                                            autoComplete='off'
                                            disabled={true}
                                            readOnly={true}
                                            value={this.state.bankList[this.state.selectedBankIndex].accountHolder}
                                        />
                                        <AiOutlineCopy onClick={() => core.copy(t, this.state.bankList[this.state.selectedBankIndex].accountHolder)}></AiOutlineCopy>
                                    </div>
                                    <div className='input-box'>
                                        <input
                                            id='backaccount'
                                            type='text'
                                            autoComplete='off'
                                            disabled={true}
                                            readOnly={true}
                                            value={this.state.bankList[this.state.selectedBankIndex].bankAppsPhone}
                                        />
                                        <AiOutlineCopy onClick={() => core.copy(t, this.state.bankList[this.state.selectedBankIndex].bankAppsPhone)}></AiOutlineCopy>
                                    </div>
                                </div>
                            </div>
                        )}

                        {!this.state.isCrypto && !this.state.isExpressDeposit && (
                            <div className='group-item input-item'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.onlinetransfer.amount', 'Deposit Amount')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='amount-content'>
                                    <div className='input-box'>
                                        <input
                                            id='depositamount'
                                            type='numeric'
                                            autoComplete='off'
                                            placeholder={t('global:global.form.online-transfer-amount-placeholder', {
                                                min: window.SPL_Other.formatAmount(this.state.depositMinMaxLimit && this.state.depositMinMaxLimit.min),
                                                max: window.SPL_Other.formatAmount(this.state.depositMinMaxLimit && this.state.depositMinMaxLimit.max),
                                            })}
                                            min={'0'}
                                            value={this.state.fields['amount']}
                                            onChange={(e) => vm.handleFieldChanged(e, 'amount')}
                                            onBlur={() => {
                                                vm.handleValidation();
                                            }}
                                        />
                                        <div className='error-validation'>
                                            <Trans
                                                i18nKey={this.state.errors['amount']}
                                                values={{ amount: window.SPL_Other.formatAmount(this.state.amountErrorValue), currency: userCurrency }}
                                            ></Trans>
                                        </div>
                                    </div>
                                    <ul>
                                        {amountOptions.map((item) => (
                                            <li
                                                key={item}
                                                onClick={() => {
                                                    vm.onDepositAmountClicked(item);
                                                }}
                                            >
                                                {item}
                                            </li>
                                        ))}
                                    </ul>
                                    {isQRPay && (
                                        <div className='qr-reminder error-validation'>
                                            {t('transaction:transaction.deposit.qrpay.reminder', 'Reminder: QR deposit can only be used to scan once only')}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                        {this.state.isReload && (
                            <div className='group-item input-item'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.reload.date_time', 'Deposit Date/Time')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='date-content'>
                                    <DatePicker
                                        selected={this.state.reload_date}
                                        onChange={(date) => vm.getReloadDate(date)}
                                        timeInputLabel='Time:'
                                        dateFormat='dd/MM/yyyy HH:mm'
                                        showTimeInput
                                    />
                                </div>
                            </div>
                        )}

                        {isQRPay && this.state.qrpaySupportedBankList.length > 0 && (
                            <div className='group-item input-item'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.qrpay.supportedBanks.title', 'Supported Banks')}</span>
                                </div>
                                <div className='amount-content'>
                                    <div
                                        className='qr-information'
                                        onClick={() => {
                                            vm.toggleQrPayBankList(true);
                                        }}
                                    >
                                        {t('transaction:transaction.deposit.qrpay.supportedBanks.detail', 'Click to see the bank list for QR code deposit')}
                                    </div>
                                </div>
                            </div>
                        )}

                        {(this.state.isOnlineTransfer || isBankApp) && this.state.depositAllSettings.showDepositChannel && (
                            <div className='group-item input-item'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.cashdeposit.channel', 'Deposit Channel')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='amount-content'>
                                    <div className='input-box'>
                                        <select value={this.state.selectedChannel} onChange={(e) => vm.changeChannel(e)}>
                                            {this.state.depositOptions.map((channel, index) => (
                                                <option key={index} value={index}>
                                                    {channel.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        )}

                        {(this.state.isOnlineTransfer || isBankApp) && this.state.depositAllSettings.showDepositChannel && (
                            <div className='group-item input-item'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.depositBankDetails', 'Bank Details')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='amount-content'>
                                    <div className='input-box'>
                                        <select value={this.state.selectedChannel} onChange={(e) => vm.handleBankDetailChange(e)}>
                                            {this.state.memberBankList.map((bank, index) => (
                                                <option key={index} value={index}>
                                                    {bank.displayName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                {!depositAllSettings.hideAddBankAccount && (
                                    <button className='addBankButton' onClick={() => vm.openAddBankDialog()}>
                                        {t('transaction:transaction.deposit.addBank.add', 'ADD')}
                                    </button>
                                )}
                            </div>
                        )}

                        {this.state.addBankDialogIsShow && (
                            <div
                                className='addBank-box'
                                onClick={() => {
                                    vm.close('addBank');
                                }}
                            >
                                <div
                                    className='addBank-popup'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <div className='title'>
                                        <span>{t('transaction:transaction.deposit.addBank.addBankTitle', 'Add Bank')}</span>
                                        <i
                                            className='icon-close3'
                                            onClick={() => {
                                                vm.close('addBank');
                                            }}
                                        ></i>
                                    </div>

                                    <div className='content'>
                                        <div className='input'>
                                            <div className='bankName'>{t('transaction:transaction.deposit.addBank.bankName', 'Bank Name')}</div>
                                            <select
                                                value={this.state.accountBankfields['selectedBankIndex']}
                                                onChange={(e) => {
                                                    vm.handleBankChange(e, 'fullname');
                                                }}
                                            >
                                                {bankList &&
                                                    bankList.length > 0 &&
                                                    bankList.map((bank, index) => (
                                                        <option key={index} value={index}>
                                                            {t(bank.bankName)}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>

                                        {this.state.showBankName && (
                                            <div className='input'>
                                                <div className='bankName'>{t('transaction:transaction.deposit.addBank.yourBankName', 'Your Bank Name')}</div>
                                                <DebounceInput
                                                    type='text'
                                                    values={this.state.accountBankfields['bankName']}
                                                    placeholder={t('transaction:transaction.deposit.addBank.yourBankName', 'Your Bank Name')}
                                                    onChange={(e) => {
                                                        vm.handleAddBankChange(e, 'bankName');
                                                    }}
                                                />
                                            </div>
                                        )}

                                        <div className='input'>
                                            <div>{t('transaction:transaction.deposit.addBank.bankBranch', 'Bank Branch')}</div>
                                            <DebounceInput
                                                type='text'
                                                values={this.state.accountBankfields['bankBranch']}
                                                placeholder={t('transaction:transaction.deposit.addBank.bankBranch', 'Bank Branch')}
                                                onChange={(e) => {
                                                    vm.handleAddBankChange(e, 'bankBranch');
                                                }}
                                            />
                                        </div>

                                        <div className='input'>
                                            <div>{t('transaction:transaction.deposit.addBank.accountName', 'Account Name')}</div>
                                            <DebounceInput type='text' placeholder={this.props.user.account.name} disabled />
                                        </div>

                                        <div className='input'>
                                            <div>{t('transaction:transaction.deposit.addBank.accountNumber', 'Account Number')}</div>
                                            <DebounceInput
                                                type='text'
                                                values={this.state.accountBankfields['accountNumber']}
                                                placeholder={t('transaction:transaction.deposit.addBank.accountNumber', 'Account Number')}
                                                onChange={(e) => {
                                                    vm.handleAddBankChange(e, 'accountNumber');
                                                }}
                                            />
                                        </div>

                                        <div className='input-error'>{t(this.state.errors['accountNumber'])}</div>

                                        <button className='btn-submit' disabled={!vm.isValidForm()} onClick={() => vm.handdleAddAccountBankSubmit()}>
                                            {t('transaction:transaction.history.filter.submit', 'Submit').toUpperCase()}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {(this.state.isReload || (this.state.isOnlineTransfer && this.state.depositAllSettings.showRefId)) && (
                            <div className='group-item input-item'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.cashdeposit.referenceid', 'Reference')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='amount-content'>
                                    <div className='input-box'>
                                        <input
                                            id='reference'
                                            type='text'
                                            autoComplete='off'
                                            placeholder={t('transaction:transaction.deposit.cashdeposit.referenceid', 'Reference')}
                                            value={this.state.fields['reference']}
                                            onChange={(e) => vm.handleFieldChanged(e, 'reference')}
                                            onBlur={() => {
                                                vm.handleValidation();
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        {this.state.isCrypto && (
                            <Fragment>
                                <div className='group-item bank-options'>
                                    <div className='title'>
                                        <span>
                                            {/* {t('transaction:transaction.deposit.crypto.cryptoOption', 'Crypto Options')} */}
                                            {t('transaction:transaction.deposit.onlinetransfer.bank', 'Bank Options')}
                                        </span>
                                        <span className='required'>*</span>
                                    </div>
                                    <div className='bank-list'>
                                        <ul>
                                            {this.state.cryptoChain &&
                                                this.state.cryptoChain.length > 0 &&
                                                this.state.cryptoChain.map((chain, i) => (
                                                    <li
                                                        key={i}
                                                        className={['icon-hover-box', this.state.selectedChainIndex === i ? 'on' : ''].join(' ')}
                                                        onClick={() => {
                                                            vm.onChainChanged(chain);
                                                        }}
                                                        onMouseEnter={() => {
                                                            this.setState({ hoveredIndex: i });
                                                        }}
                                                        onMouseLeave={() => {
                                                            this.setState({ hoveredIndex: null });
                                                        }}
                                                    >
                                                        <span className='icon-box'>
                                                            <i
                                                                className={[
                                                                    'icon-bank',
                                                                    this.state.selectedChainIndex === i || (this.state.hoveredIndex !== null && this.state.hoveredIndex === i)
                                                                        ? 'icon-bank-on'
                                                                        : 'icon-bank-off',
                                                                ].join(' ')}
                                                            >
                                                                <SVGBank
                                                                    className={`${core.getCorrectBankIcon(chain.coin, this, true)}`}
                                                                    name={`${core.getCorrectBankIcon(chain.coin, this, true)}`}
                                                                ></SVGBank>
                                                            </i>
                                                        </span>
                                                        <span>{chain.displayName}</span>
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                </div>

                                {this.state.cryptoOptions && this.state.cryptoOptions.length > 1 && (
                                    <div className='group-item input-item crypto-item'>
                                        <div className='title'>
                                            <span>{t('transaction:transaction.deposit.cashdeposit.channel', 'Deposit Channel')}</span>
                                            <span className='required'>*</span>
                                        </div>
                                        <div className='amount-content'>
                                            <div className='input-box crypto-box'>
                                                <select onChange={(e) => vm.changeCryptoChannel(e)}>
                                                    {this.state.cryptoOptions.map((channel, index) => (
                                                        <option key={index} value={index}>
                                                            {channel.displayName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className='group-item bank-options crypto-options'>
                                    <div className='title'>
                                        <span>{/* {t('transaction:transaction.deposit.crypto.scanQrCode', 'Scan QR Code')} */}</span>
                                        {/* <span className="required">*</span> */}
                                    </div>
                                    <div className='bank-list'>
                                        <ul className='crypto-qr'>
                                            {this.state.cryptoQrCode && (
                                                <Fragment>
                                                    <li className='qr-box'>
                                                        <QRCode id='cryptoQR' value={vm.qrcode()} size={159} className='crypto-qr-img' />
                                                    </li>

                                                    <li className='box-info'>
                                                        <p className='info-minDeposit'>
                                                            {t('transaction:transaction.deposit.crypto.minDeposit', 'Min Deposit')} = {this.state.chainName}{' '}
                                                            {this.state.selectedChainMin}
                                                        </p>
                                                        <p className='info-currentRate'>{t('transaction:transaction.deposit.crypto.currentRate', 'Current exchange rate')}</p>
                                                        <p className='info-rate'>
                                                            {this.state.chainName} 1 = {userCurrency} {this.state.exchangeRate.toFixed(2)}
                                                        </p>
                                                        <p className='info-note'>
                                                            {t(
                                                                'transaction:transaction.deposit.crypto.cryptoDepositNote1',
                                                                'Note: The current exchange rate above is FOR REFERENCE ONLY.'
                                                            )}
                                                        </p>
                                                        <p className='info-note info-note-spacing'>
                                                            {t(
                                                                'transaction:transaction.deposit.crypto.cryptoDepositNote2',
                                                                'For the most accurate exchange rates, please refer to official {{chain}} exchange rates.',
                                                                {
                                                                    chain: this.state.chainName,
                                                                }
                                                            )}
                                                        </p>
                                                        {this.state.chainName !== 'BTC' && <p className='info-chain'>CHAIN : {this.state.chainBranch}</p>}
                                                        <p className='info-scanQr'>
                                                            {t('transaction:transaction.deposit.crypto.scanQrCodeWithWallet', 'SCAN THE QR CODE WITH YOUR CHAIN WALLET', {
                                                                chain: this.state.chainName,
                                                            })}
                                                        </p>
                                                        <span onClick={() => vm.downloadQrCode()}>{t('transaction:transaction.deposit.crypto.saveQr', 'SAVE QR CODE')}</span>
                                                    </li>
                                                </Fragment>
                                            )}
                                        </ul>
                                    </div>
                                </div>

                                <div className='group-item bank-options crypto-options crypto-shareBox'>
                                    <div className='title'>
                                        <span>
                                            {/* {t('transaction:transaction.deposit.crypto.sendToChainAddress', 'or send to this Chain address', { chain: this.state.chainName })} */}
                                        </span>
                                    </div>
                                    <div className='bank-list'>
                                        <ul>
                                            <span>
                                                {t('transaction:transaction.deposit.crypto.sendToChainAddress', 'or send to this Chain address', {
                                                    chain: this.state.chainName,
                                                })}
                                            </span>
                                            <li className='share-box'>
                                                <p className='box-code'>{this.state.cryptoQrCode}</p>
                                                <p className='btn-share' onClick={() => vm.getShareInfo()}>
                                                    {t('transaction:transaction.deposit.crypto.share', 'SHARE')}
                                                </p>
                                            </li>
                                            {cryptoQrLink && (
                                                <button className='checkStatus' onClick={() => vm.getCryptoQrCode(cryptoQrLink)}>
                                                    {t('transaction:transaction.checkStatus', 'Check Status')}
                                                </button>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </Fragment>
                        )}

                        {this.state.receiptImg.length > 0 && (
                            <div className='group-item'>
                                <div className='title'></div>
                                <div className='amount-content'>
                                    <div className='input-box receipt-image-box'>
                                        <LazyLoadImage src={this.state.receiptImg} alt='' />
                                    </div>
                                </div>
                            </div>
                        )}

                        {!this.state.isCrypto && !this.state.isExpressDeposit && (
                            <div className='group-item submit-container'>
                                <div className='title'></div>
                                <div className='amount-content'>
                                    <div className='flexbox'>
                                        <button
                                            className='btn btn-submit'
                                            onClick={() => {
                                                vm.submitForm();
                                            }}
                                            disabled={
                                                !isFormValid || disableDepositButton || ((this.state.isOnlineTransfer || this.state.isReload) && this.state.receiptRequired)
                                            }
                                        >
                                            {t('transaction:transaction.deposit.deposithistory.submitbtn', 'SUBMIT').toUpperCase()}
                                        </button>
                                        {((this.state.depositAllSettings.uploadReceipt && (this.state.isOnlineTransfer || isBankApp)) || this.state.isReload) && (
                                            <button
                                                className='btn btn-reset'
                                                onClick={() => {
                                                    vm.handleUploadReceiptButtonOnClick();
                                                }}
                                            >
                                                {t('transaction:transaction.deposit.cashdeposit.upload', 'UPLOAD RECEIPT').toUpperCase()}
                                                <input
                                                    id='hiddenFileInput'
                                                    style={{ display: 'none' }}
                                                    type='file'
                                                    accept='image/*'
                                                    onChange={(e) => vm.handleReceiptImageChange(e)}
                                                ></input>
                                            </button>
                                        )}
                                    </div>
                                    {(this.state.isOnlineTransfer || this.state.isReload || isBankApp) && this.state.receiptRequired && (
                                        <div className='error-validation'>{t('transaction:transaction.deposit.receiptIsRequired')}</div>
                                    )}
                                </div>
                            </div>
                        )}

                        {/* notice box */}
                        {this.state.isOnlineTransfer && (
                            <div className='notice-box'>
                                <span className='item-title'>{t('transaction:transaction.important-notice', 'IMPORTANT NOTICE')}</span>
                                <div className='display-info'>
                                    <span className='key'>{t('transaction:transaction.deposit.onlinetransfer.option1', 'Option:Bank Transfer')}</span>
                                    <span className='value'></span>
                                </div>
                                <div className='display-info'>
                                    <span className='key'>{t('transaction:transaction.deposit.onlinetransfer.mode1', 'Mode:Online & Offline')}</span>
                                    <span className='value'></span>
                                </div>
                                <div className='display-info'>
                                    <span className='key'>{t('transaction:transaction.deposit.onlinetransfer.min/maxlimit', 'Min/Max Limit')}</span>
                                    <span className='value'>
                                        {this.state.depositMinMaxLimit === null ? (
                                            <SmallLoading></SmallLoading>
                                        ) : (
                                            ` ${userCurrency} ${window.SPL_Other.formatAmount(this.state.depositMinMaxLimit && this.state.depositMinMaxLimit.min)} 
                                                    / ${window.SPL_Other.formatAmount(this.state.depositMinMaxLimit && this.state.depositMinMaxLimit.max)}`
                                        )}
                                    </span>
                                </div>

                                <div className='display-info'>
                                    <span className='key'>{t('transaction:transaction.deposit.onlinetransfer.dailylimit', 'Daily Limit:')}</span>
                                    <span className='value'>&nbsp;{t('transaction:transaction.deposit.onlinetransfer.unlimited', 'Unlimited')}</span>
                                </div>
                                <div className='display-info'>
                                    <span className='key'>{t('transaction:transaction.deposit.onlinetransfer.totalallowed', 'Total Allowed:')}</span>
                                    <span className='value'>&nbsp; {t('transaction:transaction.deposit.onlinetransfer.unlimited', 'Unlimited')}</span>
                                </div>

                                <div className='display-info'>
                                    <p>
                                        {t(
                                            'transaction:transaction.deposit.onlinetransfer.note1',
                                            'Note: Please use Bank Transfer via your local bank account. We do not accept all kinds of deposit by ""Cheque"" or ""Bank Draft"" (Company OR Personal Cheque) as your deposit method. '
                                        )}
                                    </p>
                                </div>

                                <div className='display-info'>
                                    <p>
                                        {t(
                                            'transaction:transaction.deposit.onlinetransfer.note2',
                                            'Once you have successfully submitted your deposit form and once your funds is cleared in our account, just leave it to our team to process your transactions as speedy as possible. If more than 10 minutes, let us know by contacting our Customer Service support. They will assist you 24/7 anytime. '
                                        )}
                                    </p>
                                </div>

                                <div className='display-info'>
                                    <p>
                                        {t(
                                            'transaction:transaction.deposit.onlinetransfer.note3',
                                            'Please make sure that you fill up accurate bank account details to avoid any inconveniences & using third-party withdrawal is NOT accepted by us.'
                                        )}
                                    </p>
                                </div>
                            </div>
                        )}

                        {this.state.isCrypto && this.state.chainRemark && this.state.chainRemark.length > 0 && (
                            <div className='notice-box '>
                                <span className='item-title'>{t('transaction:transaction.deposit.crypto.reminder', 'REMINDER')} :</span>
                                <ul className='crypto-remark'>
                                    {this.state.chainRemark.map((info, index) => (
                                        <li key={index}>{info}</li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {this.state.isReload && (
                            <div className='notice-box'>
                                <span className='item-title'>{t('transaction:transaction.important-notice', 'IMPORTANT NOTICE')}</span>
                                <div className='display-info'>
                                    <span className='key'>{t('transaction:transaction.deposit.reload.option1', 'Option: True Money')}</span>
                                    <span className='value'></span>
                                </div>
                                <div className='display-info'>
                                    <span className='key'>{t('transaction:transaction.deposit.reload.mode1', 'Mode: Online')}</span>
                                    <span className='value'></span>
                                </div>
                                <div className='display-info'>
                                    <span className='key'>{t('transaction:transaction.deposit.onlinetransfer.min/maxlimit', 'Min/Max Limit')}</span>
                                    <span className='value'>
                                        {this.state.depositMinMaxLimit === null ? (
                                            <SmallLoading></SmallLoading>
                                        ) : (
                                            ` ${userCurrency} ${window.SPL_Other.formatAmount(this.state.depositMinMaxLimit && this.state.depositMinMaxLimit.min)} 
                                                    / ${window.SPL_Other.formatAmount(this.state.depositMinMaxLimit && this.state.depositMinMaxLimit.max)}`
                                        )}
                                    </span>
                                </div>

                                <div className='display-info'>
                                    <span className='key'>{t('transaction:transaction.deposit.cashdeposit.dailylimit', 'Daily Limit:')}</span>
                                    <span className='value'>&nbsp;{t('transaction:transaction.deposit.cashdeposit.unlimited', 'Unlimited')}</span>
                                </div>
                                <div className='display-info'>
                                    <span className='key'>{t('transaction:transaction.deposit.cashdeposit.totalallowed', 'Total Allowed:')}</span>
                                    <span className='value'>&nbsp; {t('transaction:transaction.deposit.cashdeposit.unlimited', 'Unlimited')}</span>
                                </div>

                                <div className='display-info'>
                                    <p>
                                        {t(
                                            'transaction:transaction.deposit.reload.note1',
                                            'Now you can deposit by using True Money anytime anywhere. Normally True Money transfer will take about 3 minutes. 3rd party account deposit is not allowed. If you have any inquiry, please contact our live support.'
                                        )}
                                    </p>
                                </div>
                            </div>
                        )}

                        {!this.state.isOnlineTransfer && !this.state.isCrypto && !isQRPay && !this.state.isReload && !this.state.isExpressDeposit && (
                            <div className='notice-box'>
                                <span className='item-title'>{t('transaction:transaction.important-notice', 'IMPORTANT NOTICE')}</span>
                                <div className='display-info'>
                                    <span className='key'>{t('transaction:transaction.deposit.cashdeposit.option1', 'Option:Bank Transfer')}</span>
                                    <span className='value'></span>
                                </div>
                                <div className='display-info'>
                                    <span className='key'>{t('transaction:transaction.deposit.cashdeposit.mode1', 'Mode:Online & Offline')}</span>
                                    <span className='value'></span>
                                </div>
                                <div className='display-info'>
                                    <span className='key'>{t('transaction:transaction.deposit.cashdeposit.min/maxlimit', 'Min/Max Limit')}</span>
                                    <span className='value'>
                                        {this.state.depositMinMaxLimit === null ? (
                                            <SmallLoading></SmallLoading>
                                        ) : (
                                            ` ${userCurrency} ${window.SPL_Other.formatAmount(this.state.depositMinMaxLimit && this.state.depositMinMaxLimit.min)} 
                                                    / ${window.SPL_Other.formatAmount(this.state.depositMinMaxLimit && this.state.depositMinMaxLimit.max)}`
                                        )}
                                    </span>
                                </div>

                                <div className='display-info'>
                                    <span className='key'>{t('transaction:transaction.deposit.cashdeposit.dailylimit', 'Daily Limit:')}</span>
                                    <span className='value'>&nbsp;{t('transaction:transaction.deposit.cashdeposit.unlimited', 'Unlimited')}</span>
                                </div>
                                <div className='display-info'>
                                    <span className='key'>{t('transaction:transaction.deposit.cashdeposit.totalallowed', 'Total Allowed:')}</span>
                                    <span className='value'>&nbsp; {t('transaction:transaction.deposit.cashdeposit.unlimited', 'Unlimited')}</span>
                                </div>

                                <div className='display-info'>
                                    <p>
                                        {t(
                                            'transaction:transaction.deposit.cashdeposit.note1',
                                            'Note: Please use Bank Transfer via your local bank account. We do not accept all kinds of deposit by ""Cheque"" or ""Bank Draft"" (Company OR Personal Cheque) as your deposit method. '
                                        )}
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['global', 'settings', 'transaction', 'register', 'bank'])(withRouter(Deposit)));
