// library
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';
import React from 'react';

// util
import { CMS_PAYMENT_GATEWAY_IMAGE_PATH } from '../../../utils/constants';

/**
 *
 * @param {cryptoPgCode} cryptoPgCode
 * @returns
 */
const PaymentGatewayIcon = ({ pgCode }) => {
    const userRedux = useSelector((state) => state.user);
    const currency = userRedux?.account?.currency;

    return (
        <SVG src={`${CMS_PAYMENT_GATEWAY_IMAGE_PATH}/${currency}/${pgCode}.svg`}>
            <SVG src={`/public/html/images/deposit/${pgCode}.svg`} />
        </SVG>
    );
};

PaymentGatewayIcon.propTypes = {
    pgCode: PropTypes.string.isRequired, // payment gateway code
};

export default PaymentGatewayIcon;
