const controller = {
    vm: null,

    init(vm) {
        this.vm = vm;
        this.vm.state = {
            aboutContent: '',
            isLoading: true,
        };
    },

    getAboutUs() {
        const { language } = controller.vm.props;

        window.SPL_Content.getAboutUsPage(language.countryLanguageKey, true).then((res) => {
            if (res && res.content && res.content.includes('{{MerchantName}}')) {
                res.content = res.content.replace('{{MerchantName}}', window.globMerchantName);
            }

            controller.vm.setState({ aboutContent: res.content, isLoading: false });
        });
    },
};

export default controller;
