import React from 'react';
import Time from 'react-live-clock';
import commonUtil from 'root/utils/common-util';

export default class Clock extends React.Component {
    timer;
    constructor(props) {
        super(props);
        let date = new Date();
        let offsetInHours = date.getTimezoneOffset() / -60;

        this.state = {
            date: date,
            offsetInHours: offsetInHours,
            liveClockDateTimeFormat: null,
        };
    }

    componentDidMount() {
        this.getLiveClockDateTimeFormat();
        this.timer = setInterval(() => this.tick(), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    tick() {
        this.setState({ date: new Date() });
    }

    getDateStr(date, fmt) {
        let o = {
            'M+': date.getMonth() + 1,
            'd+': date.getDate(),
            'h+': date.getHours(),
            'm+': date.getMinutes(),
            's+': date.getSeconds(),
            'q+': Math.floor((date.getMonth() + 3) / 3),
        };
        if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
        for (var k in o) if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
        return fmt;
    }

    getLiveClockDateTimeFormat() {
        let liveClockDateTimeFormat = commonUtil.getNavbarLiveDateTimeFormat(this.props);
        this.setState({
            liveClockDateTimeFormat: liveClockDateTimeFormat,
        });
    }

    render() {
        let date = window.SPL_Other.getTimeNowByCountryLanguageKey(this.props.language.countryLanguageKey);
        let timeZone = window.SPL_Other.getTimeZoneByCurrency(this.props.language.currencyLang);
        return (
            <span className='date-time'>
                <Time date={date && date.fullDateTime.toString()} format={this.state.liveClockDateTimeFormat && this.state.liveClockDateTimeFormat.datetimeFormat} ticking={true} />
                <span>&nbsp;({timeZone})</span>
            </span>
        );
    }
}
