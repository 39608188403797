// library
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import SVG from 'react-inlinesvg';
import { useHistory } from 'react-router-dom';

// components
import styles from './user-verifications.module.scss';

// logic/util
import { gu_filterCommonKey } from '@utils/general-util';
import { MODULE_TYPE, POPUP_TYPE } from '@constants';
import { popupAction } from '@redux/action';
import { useGetContactVerificationStatus } from '@hooks/member-hook';

const UserVerifications = () => {
    const { t } = useTranslation('settings');
    const history = useHistory();

    // redux
    const dispatch = useDispatch();
    const portal = useSelector((state) => state.portal);

    const verifications = useSelector((state) => state.user?.verifications);

    const _userVerificationModule = useSelector((state) => state?.systemFeatureReducer?.systemFeature?.[MODULE_TYPE?.USER_VERIFICATION]);
    const memberVerificationSettings = portal?.settings?.features?.settingsConfiguration?.memberVerificationSettings;
    const _isEnableVerifyBirthday = gu_filterCommonKey(memberVerificationSettings?.birthdateVerification);
    const _isEnableVerifyBank = gu_filterCommonKey(memberVerificationSettings?.bankVerification);

    // hooks
    const { getContactVerificationStatus } = useGetContactVerificationStatus();

    useEffect(() => {
        getContactVerificationStatus();
    }, []);

    const onProfileIconClick = useCallback(() => {
        if (verifications?.personal?.birthdayVerified) {
            return;
        }
        history.push('/myaccount/profile');
    }, [verifications?.personal?.birthdayVerified, history]);
    const onPhoneIconClick = useCallback(() => {
        if (verifications?.personal?.phoneVerified) {
            return;
        }
        dispatch(popupAction.setPopup(POPUP_TYPE.VERIFY_PHONE, true));
    }, [verifications?.personal?.phoneVerified, dispatch]);
    const onEmailIconClick = useCallback(() => {
        if (verifications?.personal?.emailVerified) {
            return;
        }
        dispatch(popupAction.setPopup(POPUP_TYPE.VERIFY_EMAIL, true));
    }, [verifications?.personal?.emailVerified, dispatch]);
    const onBankIconClick = useCallback(() => {
        if (verifications?.personal?.withdrawalVerified) {
            return;
        }
        history.push('/myaccount/bankingdetails');
    }, [history, verifications?.personal?.withdrawalVerified]);

    // hooks
    return (
        <>
            {/* fetch auth-settings for USER_VERIFICATION */}
            <div className={`${styles.userVerifications} user-verification-container`}>
                <span className='fs-16'> {t('settings:settings.userVerification', 'User Verification')} </span>
                <div className={`${styles.verificationIcons} verification-icons`}>
                    {_isEnableVerifyBirthday && <VerificationIcon type={'profile'} isActive={verifications?.personal?.birthdayVerified} onClick={onProfileIconClick} />}
                    {_userVerificationModule?.phone_verification_feature && (
                        <VerificationIcon type={'phone'} isActive={verifications?.personal?.phoneVerified} onClick={onPhoneIconClick} />
                    )}
                    {_userVerificationModule?.email_verification_feature && (
                        <VerificationIcon type={'email'} isActive={verifications?.personal?.emailVerified} onClick={onEmailIconClick} />
                    )}
                    {_isEnableVerifyBank && <VerificationIcon type={'bank'} isActive={verifications?.personal?.withdrawalVerified} onClick={onBankIconClick} />}
                </div>
            </div>
        </>
    );
};

export default UserVerifications;

export const VerificationIcon = ({ type, isActive, title, onClick }) => {
    return (
        <div title={title} className={`${styles.verifyIcon} verify-icon ${isActive ? styles.active : ''}`} onClick={onClick}>
            <SVG src={`/public/html/images/settings/user-verification/icon-${type}.svg`} />
        </div>
    );
};
