import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import vm from '../mega.controller';
import core from 'root/utils/core';

class Mega extends React.Component {
    tween1;
    tween2;

    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        vm.getMegaUrl();
        vm.getGameLoginCredential();
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        return (
            <Translation>
                {(t) => (
                    <Fragment>
                        <div className='mega-page'>
                            <div className='mega-box-container'>
                                <div className='mega-info'>
                                    <p className='info-title'>Mega888 Test ID</p>
                                    <span className='info-guide guide-acc'>{t('slot:slot.mega.account', 'Account')}: test1 - test10000</span>
                                    <span className='info-guide guide-pass'>{t('slot:slot.mega.password', 'Password')}: Aa1234</span>
                                    <span
                                        className='btn-download'
                                        onClick={() => {
                                            vm.downloadMegaApp();
                                        }}
                                    >
                                        {t('global:global.sidebar.download.heading', 'DOWNLOAD')}
                                    </span>
                                    <div className='user-box box-id'>
                                        <span>{this.state.userObj.playerId ? this.state.userObj.playerId : t('global:global.form.username-placeholder', 'Username')}</span>
                                        <LazyLoadImage
                                            src='/public/html/images/slots/icon/copy.png'
                                            onClick={() => {
                                                core.copy(t, this.state.userObj.playerId);
                                            }}
                                        />
                                    </div>
                                    <div className='user-box box-pass'>
                                        <span>
                                            {this.state.userObj.playerPassword
                                                ? this.state.userObj.playerPassword
                                                : t('global:global.form.newpassword-placeholder', 'Password')}
                                        </span>
                                        <LazyLoadImage
                                            src='/public/html/images/slots/icon/copy.png'
                                            onClick={() => {
                                                core.copy(t, this.state.userObj.playerPassword);
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className='mega-iframe'>
                                    <iframe name='megaFrame' title='mega888' id='megaFrame' src={this.state.megaUrl}></iframe>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['slot', 'transaction'])(withRouter(Mega)));
