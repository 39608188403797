import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';

class LoginLanding extends React.Component {
    constructor() {
        super();
    }

    componentDidMount() {
        let queryParam = window.location.search;
        if (queryParam.indexOf('openLogin') < 0) {
            if (queryParam.indexOf('?') <= -1) {
                queryParam += '?openLogin=1';
            } else {
                queryParam += '&openLogin=1';
            }
        }
        this.props.history.push('/home' + queryParam);
    }

    render() {
        return <div></div>;
    }
}

export default connect(mapStateToProps, null)(withTranslation('global')(withRouter(LoginLanding)));
