import { promotionConstants } from '../constant/promotion.constant';

const initialState = {
    promotionData: null,
};

export function promotionReducer(state = initialState, action) {
    switch (action.type) {
        case promotionConstants.GET_PROMOTION_DATA:
            return {
                ...state,
                promotionData: action.payload,
            };
        default:
            return state;
    }
}
