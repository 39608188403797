import { popupConstants } from '../constant';
export const popupAction = {
    popupUpdate,
    setPopupVisible,
    togglePopUp,
    setPopup,
    setSubmitSuccessCb,
    setEnteredPin,
    setItemConfig,
};
const dataCache = {};

function popupUpdate(popupObj) {
    return (dispatch) => {
        Object.assign(dataCache, popupObj.data);

        dispatch({ type: popupConstants.POPUP_UPDATE, payload: { page: popupObj.page, data: dataCache } });
    };
}

function setPopupVisible(flag) {
    return (dispatch) => {
        dispatch({ type: popupConstants.SET_POPUP_VISIBLE, payload: { isActive: flag } });
    };
}

function togglePopUp(popupObj) {
    return (dispatch) => {
        const { popupType, popupProps } = popupObj;
        dispatch({ type: popupConstants.TOGGLE_POPUP, payload: { popupType, popupProps } });
    };
}

function setPopup(item, isVisible) {
    return (dispatch) => {
        dispatch({ type: popupConstants.SET_POPUP, payload: { item, isVisible } });
    };
}

function setSubmitSuccessCb(fn) {
    return (dispatch) => {
        dispatch({ type: popupConstants.SET_SUBMIT_SUCCESS_CB, payload: { fn } });
    };
}

function setEnteredPin(enteredPin) {
    return (dispatch) => {
        return new Promise((resolve) => {
            dispatch({ type: popupConstants.SET_ENTERED_PIN, payload: { enteredPin } });
            resolve();
        });
    };
}

function setItemConfig(item, config) {
    return (dispatch) => {
        dispatch({ type: popupConstants.SET_ITEM_CONFIG, payload: { item, config } });
    };
}
