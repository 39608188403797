import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import { Translation, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import QRCode from 'qrcode.react';
import vm from '../../footer.controller';
import commonUtil from 'root/utils/common-util';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const MAX_MERGE_LAYOUT_CONTENT = 2;
class Footer extends React.Component {
    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        // both web and mobile using the same component...
        const isMobile = this.props.screen.viewType === 'mobile';
        vm.generateCategoryList(isMobile);
        vm.getMiddleContainerItemList(isMobile);
        vm.getSupportInfo();
        vm.getFollowUsList();
        vm.moveDynamicSeoContentToCorrectPosition();
        vm.getAppDownloadLink();
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.countryLanguageKey !== this.props.countryLanguageKey) {
            vm.updateCurrentLanguage();
        }
        if (this.props.portal.pathname !== prevProps.portal.pathname && this.props.screen.viewType === 'web') {
            vm.updateDynamicSeoContent();
        }
    }

    renderCommon(e, i, t, isMergeLayout) {
        return (
            <li key={i} data-type={e.type} className={e.category}>
                {!e.hideTitle && (
                    <div className='title'>
                        {t(e.title)}
                        {e.iconVerified && <i className='icon-verified' />}
                    </div>
                )}

                <div className={`${e.category}${isMergeLayout ? '-ftrmerge' : '-box'}`}>
                    {e.list.map((columnObj, i) =>
                        e.type === 'text' ? (
                            <span
                                key={i}
                                onClick={() => {
                                    vm.handleCategoryClick(e.category, columnObj);
                                }}
                            >
                                {t(columnObj.name)}
                            </span>
                        ) : e.type === 'text-icon' ? (
                            <div key={i}>
                                {t(columnObj.text)} &nbsp; <div className={'icon-' + columnObj.name.toLowerCase()}></div>
                            </div>
                        ) : (
                            <i
                                key={i}
                                className={'icon-' + columnObj.name.toLowerCase()}
                                onClick={() => {
                                    vm.handleCategoryClick(e.category, columnObj);
                                }}
                            ></i>
                        )
                    )}
                </div>
                {e.text && <div className='content-text'>{t(e.text)}</div>}
            </li>
        );
    }

    renderQRDownload(t, deviceType) {
        const { appDownloadLinkData } = this.state;
        return (
            <div className='download-container'>
                <QRCode
                    value={commonUtil.getQRCodeValue(appDownloadLinkData && appDownloadLinkData[deviceType])}
                    level='M'
                    renderAs='svg'
                    size={59}
                    bgColor='#efefef'
                    fgColor='black'
                />
                <div className={`corner-img-container`}>
                    <img src={`/public/html/images/download/${deviceType}-icon.svg`} alt=''></img>
                </div>

                <div className='download'>
                    <div>{t(`app:app.appDownload.${deviceType}`)}</div>
                    <button
                        onClick={() => {
                            commonUtil.onAppDownloadClick(deviceType, appDownloadLinkData && appDownloadLinkData[deviceType]);
                        }}
                    >
                        {t(`app:app.download`)}
                    </button>
                </div>
            </div>
        );
    }

    generateContainer(container, t) {
        if (container?.category === 'copy-right') {
            const transText = container?.list?.map((item) => {
                return {
                    ...item,
                    text: t(item.text, `Copyright {{merchant}} @ {{year}}. All rights reserved.`, {
                        merchant: window.globMerchantName,
                        year: new Date().getFullYear(),
                    }),
                };
            });
            return { ...container, list: transText };
        }
        return container;
    }

    render() {
        const { categoryList, supportInfoList, followUsList, middleItemList } = this.state;
        const { language } = this.props;
        let container = [];
        return (
            <Translation>
                {(t) => (
                    <div id='footer'>
                        <div className='top-footer'>
                            <div className='container'>
                                <div className='top-footer-box'>
                                    <div className='top'>
                                        <ul className='provider'>
                                            {categoryList.length > 0 &&
                                                categoryList.map((providerObj, i) => {
                                                    if (providerObj.isDesktopMergeLayout || providerObj.isMobileMergeLayout) {
                                                        const isMaxedContent = container.length % MAX_MERGE_LAYOUT_CONTENT === 0;
                                                        if (isMaxedContent) container = [];
                                                        const formatProviderObj = this.generateContainer(providerObj, t);
                                                        container.push(this.renderCommon(formatProviderObj, i, t, true));
                                                        if (container.length % MAX_MERGE_LAYOUT_CONTENT === 0) {
                                                            return (
                                                                <div key={i} className={`mergeLayout ${providerObj.category}`}>
                                                                    {container}
                                                                </div>
                                                            );
                                                        }
                                                    } else {
                                                        return <Fragment key={i}>{this.renderCommon(providerObj, i, t, false)}</Fragment>;
                                                    }
                                                })}
                                        </ul>
                                        <LazyLoadImage
                                            className='cm-image'
                                            src={`/public/html/images/footer/CM-${language.key}.png`}
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = `/public/html/images/footer/CM-en.png`;
                                            }}
                                            alt='Conor Mcgregor'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='top-footer-separator'></div>
                        <div className='container middle-footer-container'>
                            {middleItemList.length > 0 &&
                                middleItemList.map((providerObj, i) => {
                                    if (providerObj.isDesktopMergeLayout || providerObj.isMobileMergeLayout) {
                                        const isMaxedContent = container.length % MAX_MERGE_LAYOUT_CONTENT === 0;
                                        if (isMaxedContent) container = [];
                                        const formatProviderObj = this.generateContainer(providerObj, t);
                                        container.push(this.renderCommon(formatProviderObj, i, t, true));
                                        if (container.length % MAX_MERGE_LAYOUT_CONTENT === 0) {
                                            return (
                                                <div key={i} className={`mergeLayout ${providerObj.category}`}>
                                                    {container}
                                                </div>
                                            );
                                        }
                                    } else {
                                        return this.renderCommon(providerObj, i, t, false);
                                    }
                                })}
                        </div>
                        <div id='dynamic-seo-footer'></div>
                        <div className='bottom-footer-separator'></div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps, null)(withTranslation(['app'])(withRouter(Footer)));
