import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import core from 'root/utils/core';

import WithDraw from './component/desktop/withdraw';
//const MobileSettings = lazy(() => import('./mobile/Member'))

class WithdrawSelector extends React.Component {
    components = {
        desktop: {
            default: WithDraw,
        },
        mobile: {
            default: WithDraw,
        },
    };

    render() {
        const TagName = core.getCorrectComponent(this.components, 'withdraw', this.props.screen.viewType);
        return <TagName></TagName>;
    }
}

export default connect(mapStateToProps, null)(WithdrawSelector);
