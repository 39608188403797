import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';

class floatingNotificationContent extends React.Component {
    constructor() {
        super();
        this.state = {
            notificationContent: [],
        };
    }

    componentDidMount() {
        this.getContent();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.notificationContent !== this.props.notificationContent) {
            this.getContent();
        }
    }

    getContent() {
        this.setState({ notificationContent: this.props.notificationContent });
    }

    renderctaButtonName(data) {
        const { customTranslationObj } = this.props;
        let type = data?.ctaButtonType;
        let name = customTranslationObj?.clickHere || 'Click Here';

        switch (type) {
            case 'CLAIM_NOW':
                name = customTranslationObj?.claimNow || 'Claim Now';
                break;
            case 'PLAY_NOW':
                name = customTranslationObj?.playNow || 'Play Now';
                break;
            case 'CLICK_HERE':
                name = customTranslationObj?.clickHere || 'Click Here';
                break;
            case 'KNOW_MORE':
                name = customTranslationObj?.knowMore || 'Know More';
                break;
            case 'JOIN_NOW':
                name = customTranslationObj?.joinNow || 'Join Now';
                break;
            case 'APPLY_NOW':
                name = customTranslationObj?.applyNow || 'Apply Now';
                break;
            case 'LEARN_MORE':
                name = customTranslationObj?.learnMore || 'Learn More';
                break;
            case 'OTHERS':
                name = data?.ctaButtonName;
                break;
            default:
        }

        return name;
    }

    onCloseNotificationClick(data, floatingNotificationSettingId, displayFrequencyType) {
        if (this.props.notificationType === 'SLIDING') {
            this.props.setNotificationClose();
        } else {
            this.props.closeNotification(data, floatingNotificationSettingId, displayFrequencyType);
        }
    }

    render() {
        const { notificationContent } = this.state;
        return (
            <div>
                {notificationContent &&
                    notificationContent.map((data, index) => {
                        const { redirectUrl, ctaButtonType } = data;
                        return (
                            <div
                                key={index}
                                className={`
                                ${this.props.screen.viewType === 'web' ? 'floating-banner-container' : 'floating-banner-container'}
                                ${data.horizontalPosition === 'RIGHT' ? 'floating-banner-container-right' : 'floating-banner-container-left'}`}
                                style={{ top: data.verticalPosition + '%' }}
                            >
                                <div className='floating-banner-img'>
                                    <img
                                        src={data.imagePath}
                                        onClick={() =>
                                            redirectUrl &&
                                            !ctaButtonType &&
                                            this.props.notificationClick(data, this.props.floatingNotificationSettingId, this.props.displayFrequencyType)
                                        }
                                    />
                                    <button
                                        className='floating-banner-close-btn'
                                        onClick={() => this.onCloseNotificationClick(data, this.props.floatingNotificationSettingId, this.props.displayFrequencyType)}
                                    >
                                        x{/* <SVGTransfer name='cross-icon' /> */}
                                    </button>
                                </div>
                                <div className={`floating-banner-content ${!data.imagePath && 'withoutImg'}`}>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: data.content,
                                        }}
                                    ></div>
                                    {redirectUrl && ctaButtonType && (
                                        <button onClick={() => this.props.notificationClick(data, this.props.floatingNotificationSettingId, this.props.displayFrequencyType)}>
                                            {this.renderctaButtonName(data)}
                                        </button>
                                    )}
                                </div>
                            </div>
                        );
                    })}
            </div>
        );
    }
}

export default connect(mapStateToProps)(withRouter(floatingNotificationContent));
