import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import { Translation, withTranslation } from 'react-i18next';
import { DebounceInput } from 'react-debounce-input';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { gu_filterCommonKey } from 'root/utils/general-util';

import vm from '../add-bank-dialog.controller';
import transactionHelper from 'root/utils/transaction-helper';
import { trans_handleUploadReceiptButtonOnClick } from '../../../../../utils/transaction-util';

class AddBankDialog extends Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
    }

    componentDidMount() {
        vm.initCompSettings();
        vm.handleBankChange(0, true);
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        const { accountBankfields, showBankName, compSettings, errors, receiptImg, receiptImgFile } = this.state;
        const { reqBankStatementByCurrencies } = compSettings;
        const { closeFunction, user, portal } = this.props;
        let bankList = this.props.unOwnedBankList;
        const showBankBranch = gu_filterCommonKey(portal?.settings?.showBankBranch);

        return (
            <Translation>
                {(t) => (
                    <div className='addBank-box' onClick={closeFunction && closeFunction}>
                        <div
                            className='addBank-popup'
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <div className='title'>
                                <span>{t('transaction:transaction.deposit.addBank.addBankTitle', 'Add Bank')}</span>
                                <i
                                    className='icon-close3'
                                    onClick={closeFunction && closeFunction}
                                    // onClick={() => {
                                    //     transactionHelper.close(this, 'addBank');
                                    // }}
                                ></i>
                            </div>

                            <div className='content'>
                                <div className='input'>
                                    <div className='bankName'>{t('transaction:transaction.deposit.addBank.bankName', 'Bank Name')}</div>
                                    <select
                                        // value={accountBankaccountBankfields['selectedBankIndex']}
                                        value={accountBankfields['selectedBankIndex']}
                                        onChange={(e) => {
                                            vm.handleBankChange(e, 'fullname');
                                        }}
                                    >
                                        {/* {unOwnedBankList &&
                                        unOwnedBankList.length > 0 &&
                                        unOwnedBankList.map((bank, index) => (
                                            <option key={index} value={index}>
                                                {t(bank.bankName)}
                                            </option>
                                        ))} */}
                                        {(bankList || []).map((bank, index) => (
                                            <option key={index} value={index}>
                                                {t(bank.bankName)}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                {showBankName && (
                                    <div className='input'>
                                        <div className='bankName'>{t('transaction:transaction.deposit.addBank.yourBankName', 'Your Bank Name')}</div>
                                        <DebounceInput
                                            type='text'
                                            values={accountBankfields['bankName']}
                                            placeholder={t('transaction:transaction.deposit.addBank.yourBankName', 'Your Bank Name')}
                                            onChange={(e) => {
                                                transactionHelper.handleAddBankChange(this, e, 'bankName');
                                            }}
                                        />
                                    </div>
                                )}
                                {showBankBranch && (
                                    <div className='input'>
                                        <div>{t('transaction:transaction.deposit.addBank.bankBranch', 'Bank Branch')}</div>
                                        <DebounceInput
                                            type='text'
                                            values={accountBankfields['bankBranch']}
                                            placeholder={t('transaction:transaction.deposit.addBank.bankBranch', 'Bank Branch')}
                                            onChange={(e) => {
                                                transactionHelper.handleAddBankChange(this, e, 'bankBranch');
                                            }}
                                        />
                                    </div>
                                )}
                                <div className='input'>
                                    <div>{t('transaction:transaction.deposit.addBank.accountName', 'Account Name')}</div>
                                    <DebounceInput type='text' placeholder={user.account.name} disabled />
                                </div>

                                <div className='input'>
                                    <div>{t('transaction:transaction.deposit.addBank.accountNumber', 'Account Number')}</div>
                                    <DebounceInput
                                        type='text'
                                        values={accountBankfields['accountNumber']}
                                        placeholder={t('transaction:transaction.deposit.addBank.accountNumber', 'Account Number')}
                                        onChange={(e) => {
                                            transactionHelper.handleAddBankChange(this, e, 'accountNumber');
                                        }}
                                    />
                                </div>

                                <div className='input-error'>{t(errors['accountNumber'])}</div>

                                {/****** Bank Statement *******/}
                                {reqBankStatementByCurrencies && (
                                    <div className='input bank-statement-input'>
                                        <div>{t('transaction:transaction.withdrawal.bankStatement', 'Bank Statement')}</div>
                                        <button
                                            className='standard-upload-button upload-btn'
                                            onClick={() => {
                                                trans_handleUploadReceiptButtonOnClick();
                                            }}
                                        >
                                            {t('transaction:transaction.withdrawal.upload', 'Upload')}
                                            <input
                                                id='hiddenBankStatementInput'
                                                style={{ display: 'none' }}
                                                type='file'
                                                accept='image/*,.pdf'
                                                onChange={(e) => vm.handleReceiptImageChange(e, this)}
                                            ></input>
                                        </button>
                                        <div className='input-error'>
                                            {t(
                                                'transaction:transaction.withdrawal.bankStatementRequirement2',
                                                'Bank statement must show with full name and bank account number'
                                            )}
                                        </div>

                                        {receiptImg.length > 0 && (
                                            <div className='receipt-image-box'>
                                                {receiptImgFile && receiptImgFile.type === 'application/pdf' ? (
                                                    <>
                                                        <label className='pdf-label'>{t('transaction:transaction.deposit.pdf-file-name', 'PDF File Name: ')}</label>
                                                        <span className='pdf-name'>{receiptImgFile.name}</span>
                                                        <img src='/public/images/icon/close-red.png' className='pdf-clear' onClick={() => vm.handleClearReceipt()} alt='' />
                                                    </>
                                                ) : (
                                                    <LazyLoadImage src={receiptImg} alt='' />
                                                )}
                                            </div>
                                        )}
                                    </div>
                                )}

                                <button className='btn-submit' disabled={!transactionHelper.isValidForm(this)} onClick={() => vm.handleSubmit()}>
                                    {t('transaction:transaction.history.filter.submit', 'Submit').toUpperCase()}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['home', 'bank', 'transaction'])(AddBankDialog));
