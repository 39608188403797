const controller = {
    vm: null,

    init(vm) {
        this.vm = vm;
        this.vm.state = {
            errorCode: null,
        };
    },
};

export default controller;
