// library
import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { DebounceInput } from 'react-debounce-input';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import DatePicker from 'react-datepicker';

// selector / component
import GameList from 'root/webapp/shared/component/GameList';
import { TweenMax } from 'gsap';
import SVGElements from 'root/components/SVGElements';
import Loading from 'root/webapp/shared/loading/loading';
import Text from 'root/webapp/components/atoms/text/text';

// controller / util
import vm from '../4d.controller';

class SubGameMenu extends React.Component {
    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        if (window.SPL_LiteApp.isZT828LiteApp()) {
            window.SPL_LiteApp.onGameListDisableRefresh(true);
        }

        window.SPL_Other.sequenceCallback(vm.initCompSettings)(() => {
            const { compSettings } = this.state;
            const { fourDPageSettings } = compSettings;

            if (fourDPageSettings?.enable4DDrawResults) {
                vm.getDrawResultConfig(() => {
                    const dateTime = vm.initDrawResultDateTime();
                    vm.checkTimeAndTriggerGetFourDResults();
                    vm.getFourDResults(dateTime);
                });
                vm.getPayoutHistory();
            }
        });

        vm.getFourDProviderList();
    }

    componentWillUnmount() {
        if (window.SPL_LiteApp.isZT828LiteApp()) {
            window.SPL_LiteApp.onGameListDisableRefresh(false);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps?.providerReducer?.processedData?.favoriteGames !== this.props.providerReducer?.processedData?.favoriteGames) {
            vm.getProviderGameList(this.state.currentProvider);
        }
    }

    toggleTab(index) {
        if (index == this.state.typeActive) return;

        this.setState({ typeActive: index });

        this.tween1 && this.tween1.kill();
        this.tween2 && this.tween2.kill();

        TweenMax.set('ul.game-content li', {
            alpha: 0,
        });

        this.tween1 = TweenMax.delayedCall(0.3, function () {
            this.tween2 = TweenMax.staggerTo('ul.game-content li', 0.3, { alpha: 1 }, 0.08);
        });
    }

    onDrawTypeClicked = (index) => {
        this.setState({ jackpotBrandIndex: index });
    };

    onPayoutMenuClicked = (index) => {
        this.setState({ payoutCategoryIndex: index });
    };

    render() {
        const { history } = this.props;
        const {
            compSettings,
            drawList,
            drawListIndex,
            fourDList,
            jackpotBrandIndex,
            payoutCategoryIndex,
            payoutList,
            drawDate,
            drawResultSettings,
            currentProviderKey,
            keyword,
        } = this.state;
        const { fourDPageSettings } = compSettings;

        return (
            <Translation>
                {(t) => (
                    <div className='four-d-page'>
                        {this.state.loading && <Loading className='vw-100 vh-100'></Loading>}
                        <div className='game-list-page'>
                            <FourDGameTools history={history} currentProviderKey={currentProviderKey} t={t} keyword={keyword} />

                            <div className='games-list-wrapper'>
                                {this.state.gameList && this.state.gameList.length > 0 && (
                                    <GameList list={this.state.gameList} keyword={this.state.keyword} updateMemberFavoriteGame={vm.updateMemberFavoriteGame} />
                                )}
                            </div>

                            {fourDPageSettings?.enable4DDrawResults && (
                                <>
                                    <section className='fourD-draw-container'>
                                        <FourDDrawBoxTab drawList={drawList} drawListIndex={drawListIndex} onDrawMenuClicked={vm.onDrawMenuClicked.bind(this)} />

                                        {drawList[drawListIndex] === 'drawResult' && (
                                            <Fragment>
                                                <section className='fourD-draw-results-wrapper'>
                                                    {fourDList && (
                                                        <FourDDrawResultList
                                                            fourDList={fourDList}
                                                            drawList={drawList}
                                                            drawListIndex={drawListIndex}
                                                            drawDate={drawDate}
                                                            drawResultsConfig={drawResultSettings?.drawResultsConfig}
                                                            onDrawDateChange={vm.onDrawDateChange}
                                                        />
                                                    )}
                                                </section>
                                            </Fragment>
                                        )}

                                        {drawList[drawListIndex] === 'drawResult' ? (
                                            <>
                                                <section>
                                                    <FourDDrawResults
                                                        fourDList={fourDList}
                                                        jackpotBrandIndex={jackpotBrandIndex}
                                                        drawDate={drawDate}
                                                        onDrawTypeClicked={this.onDrawTypeClicked}
                                                        fourDResultSequence={drawResultSettings?.fourDResultSequence}
                                                    />
                                                </section>
                                            </>
                                        ) : (
                                            <FourDPayoutDetails
                                                payoutList={payoutList}
                                                payoutCategoryIndex={payoutCategoryIndex}
                                                onPayoutMenuClicked={this.onPayoutMenuClicked}
                                            />
                                        )}
                                    </section>
                                </>
                            )}
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['global', 'fourD'])(withRouter(SubGameMenu)));

export const FourDGameTools = ({ history, currentProviderKey, t, keyword }) => {
    return (
        <div className='tools-box'>
            <div className='btn-box'>
                <div
                    className='icon-box'
                    onClick={() => {
                        history.push('/4d');
                    }}
                >
                    <SVGElements className='icon-back2' name='back-icon' />
                </div>
                <div className='icon-box'>
                    <LazyLoadImage src={'/public/html/images/fourD/icon/' + currentProviderKey.toLowerCase() + '_on.svg'} alt='' />
                </div>
                <div className='provider-name'>{currentProviderKey && t(vm?.providerData?.[currentProviderKey.toUpperCase()]?.providerName)}</div>
            </div>
            <div className='input-box'>
                <DebounceInput
                    type='text'
                    debounceTimeout={1000}
                    values={keyword}
                    placeholder={t('global:global.search', 'Search')}
                    onChange={(e) => {
                        vm.handleKeywordChanged(e);
                    }}
                />
                <i className='icon-search'></i>
            </div>
        </div>
    );
};

export const FourDDrawBoxTab = ({ drawList, drawListIndex, onDrawMenuClicked }) => {
    return (
        <section className='fourD-draw-box'>
            <div className='draw-tab'>
                {(drawList || []).map((list, index) => {
                    return (
                        <div
                            key={index}
                            className={'tab-header ' + (drawListIndex === index && 'active')}
                            onClick={() => {
                                onDrawMenuClicked(index);
                            }}
                        >
                            <div className='tab-inner'>
                                <Text textKey={'fourD:fourD.' + list}></Text>
                            </div>
                        </div>
                    );
                })}
            </div>
        </section>
    );
};

export const FourDDrawResultList = ({ fourDList, drawList, drawDate, drawResultsConfig, drawListIndex }) => {
    const [selectedDrawIndex, setSelectedDrawIndex] = useState(0);

    return (
        <Fragment>
            <div className='draw-result-name-select-container'>
                <select className='draw-result-name-select' value={selectedDrawIndex} onChange={(e) => setSelectedDrawIndex(e.target.value)}>
                    {Object.keys(fourDList).map((bank, index) => {
                        return (
                            <option key={index} value={index}>
                                {bank}
                            </option>
                        );
                    })}
                </select>
                <div className='standard-select-arrow'></div>
            </div>

            <FourDDatePicker
                drawList={drawList}
                drawListIndex={drawListIndex}
                drawDate={drawDate}
                drawResultsConfig={drawResultsConfig}
                onDrawDateChange={vm.onDrawDateChange}
            />

            <FourDDrawResults fourDList={fourDList} selectedDrawIndex={selectedDrawIndex} drawDate={drawDate} />
        </Fragment>
    );
};

export const FourDDatePicker = ({ drawList, drawListIndex, drawDate, drawResultsConfig, onDrawDateChange }) => {
    const today = new Date();
    const startDate = new Date(drawResultsConfig?.startDateConstraint);
    return (
        <>
            {drawList[drawListIndex] === 'drawResult' && (
                <div className='standard-form-field'>
                    <DatePicker
                        id='from-date'
                        className='standard-input'
                        autoComplete='off'
                        type='text'
                        placeholderText={<Text textKey={'reports:reports.startDate'}>{'Start Date'}</Text>}
                        onChange={onDrawDateChange}
                        selected={new Date(drawDate)}
                        dateFormat='yyyy-MM-dd'
                        minDate={startDate}
                        maxDate={today}
                        customInput={<DatePickerCustomInput />}
                    />
                </div>
            )}
        </>
    );
};

export const DatePickerCustomInput = ({ value, onClick }) => {
    return (
        <div className='standard-input' onClick={onClick}>
            {value}
            <LazyLoadImage src={'/public/html/images/fourD/calender-icon.svg'} alt='' className='calender-icon' />
        </div>
    );
};

export const FourDDrawResults = ({ fourDList, selectedDrawIndex, onDrawTypeClicked, drawDate }) => {
    return (
        <>
            {fourDList && (
                <>
                    <FourDDrawTypeResults
                        jackpotName={Object.keys(fourDList)[selectedDrawIndex]}
                        jackpotData={fourDList[Object.keys(fourDList)[selectedDrawIndex]]}
                        drawDate={drawDate}
                    />
                </>
            )}
        </>
    );
};

export const FourDDrawTypeResults = ({ jackpotName, jackpotData }) => {
    const renderRank = (rankData, pokerCombinationTypes) => {
        if (typeof rankData === 'string') {
            return <>{rankData}</>;
        } else if (typeof rankData === 'object') {
            return (
                <>
                    {Object.keys(rankData).map((number, index) => {
                        return (
                            <>
                                <div className={'number-value number-' + Object.keys(rankData).length}>{rankData[number]}</div>
                                {pokerCombinationTypes === '6D' && Object.keys(rankData).length > 1 && index === 0 && <span className='rank-title'>or</span>}
                            </>
                        );
                    })}
                </>
            );
        } else if (Array.isArray(rankData)) {
            return rankData.map((number) => {
                return { number };
            });
        }
    };

    const renderRankTitle = (rankTitleData) => {
        if (rankTitleData !== 'translation') {
            return (
                <>
                    <Text textKey={'fourD:fourD.rank.' + rankTitleData}></Text>
                </>
            );
        }
    };

    return (
        <section className='draw-result-container'>
            <>
                {jackpotData && (
                    <div className={'draw-result-' + jackpotName + ' draw-result-type'}>
                        <div className='draw-result-title'>
                            <div className='provider-icon'>
                                <LazyLoadImage src={`/public/html/images/fourD/provider-icon/${jackpotName}.svg`} alt={jackpotName} />
                            </div>
                            <span className='provider-name'>{<Text textKey={'fourD:fourD.provider.' + jackpotName + '.title'}></Text>}</span>
                            {/* <span className='draw-result-date'>{gu_formatNewDateToCustomFormat(drawDate, 'YYYY-MM-DD (ddd)')}</span> */}
                        </div>
                        <>
                            {Object.keys(jackpotData).map((pokerCombinationTypes, index) => {
                                let pokerCombinationTypesData = jackpotData[pokerCombinationTypes];
                                return (
                                    <Fragment key={index}>
                                        {pokerCombinationTypes !== 'translation' && (
                                            <div className={'result-' + pokerCombinationTypes.replace('+', 'plus')}>
                                                {pokerCombinationTypes !== '4D' && (
                                                    <div className='result-title'>
                                                        <Text textKey={'fourD:fourD.provider.' + jackpotName + '.' + pokerCombinationTypes.replace('+', 'plus')}>
                                                            {'fourD:fourD.provider.' + jackpotName + '.' + pokerCombinationTypes.replace('+', 'plus')}
                                                        </Text>
                                                    </div>
                                                )}
                                                {pokerCombinationTypesData &&
                                                    Object.keys(pokerCombinationTypesData).map((rank, index) => {
                                                        let rankData = pokerCombinationTypesData[rank];
                                                        return (
                                                            <Fragment key={index}>
                                                                <div className={rank + '-rank draw-rank-container'}>
                                                                    <div className='rank-title'>{renderRankTitle(rank)}</div>
                                                                    <div className={`${rankData.length < 2 ? 'single-column' : 'number-container'}`}>
                                                                        {renderRank(rankData, pokerCombinationTypes)}
                                                                    </div>
                                                                </div>
                                                            </Fragment>
                                                        );
                                                    })}
                                            </div>
                                        )}
                                    </Fragment>
                                );
                            })}
                        </>
                    </div>
                )}
            </>
        </section>
    );
};

export const FourDPayoutDetails = ({ payoutList, payoutCategoryIndex, onPayoutMenuClicked }) => {
    const payoutSequence = ['details', 'ibox', 'GD', 'GDibox', '9Lotto', '9Lottoibox'];
    return (
        <>
            {payoutList && (
                <div className='payout-container'>
                    <section className='payout-title-container'>
                        {(payoutSequence || []).map((list, listIndex) => {
                            return (
                                <>
                                    {Object.keys(payoutList).map((payoutTitle) => {
                                        if (list === payoutTitle) {
                                            return (
                                                <div
                                                    key={listIndex}
                                                    className={`payout-title ${payoutCategoryIndex === listIndex && 'active'}`}
                                                    onClick={() => onPayoutMenuClicked(listIndex)}
                                                >
                                                    <Text textKey={'fourD:fourD.' + payoutTitle}></Text>
                                                </div>
                                            );
                                        }
                                    })}
                                </>
                            );
                        })}
                    </section>
                    <section className='payout-table-container'>
                        {(Object.keys(payoutList) || []).map((list) => {
                            if (list === payoutSequence[payoutCategoryIndex]) {
                                return (
                                    <>
                                        <FourDPayoutCategory packageName={payoutSequence[payoutCategoryIndex]} packageData={payoutList[payoutSequence[payoutCategoryIndex]]} />
                                    </>
                                );
                            }
                        })}
                    </section>
                </div>
            )}
        </>
    );
};

export const FourDPayoutCategory = ({ packageName, packageData }) => {
    const iBoxTableSequence = ['big', 'small', 'Prize'];
    return (
        <div className='payout-table'>
            {packageName.includes('ibox') ? (
                <>
                    {iBoxTableSequence.map((header) => {
                        return (
                            <>
                                {Object.keys(packageData).map((iboxHeader) => {
                                    if (header === iboxHeader) {
                                        return (
                                            <div key={iboxHeader} className='payout-table-result'>
                                                <Table data={packageData[iboxHeader]} tableName={iboxHeader} isIbox={packageName.includes('ibox')} />
                                            </div>
                                        );
                                    }
                                })}
                            </>
                        );
                    })}
                </>
            ) : (
                <div className='payout-table-result'>
                    <Table data={packageData} />
                </div>
            )}
        </div>
    );
};

export const Table = ({ data, tableName, isIbox }) => {
    const headers = Object.keys(data);
    const rowTitles = Object.keys(data[headers[0]]);
    const titleArrangement = isIbox
        ? ['First', 'Second', 'Third', 'Fourth', 'Fifth', 'Prize', '4A', '4B', '4C', '4D', '4E', '4F']
        : ['Price', 'Commission', 'First', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth'];

    const headersArrangement = isIbox ? ['ibox24', 'ibox12', 'ibox6', 'ibox4'] : ['Big', 'Small', '4a', 'A', 'Abc', '5D', '2D', '2C', '6D', '2A', '2F'];
    return (
        <table className='payout-details-table'>
            <thead>
                <tr>
                    <th className='payout-table-title'>{tableName && <Text textKey={'fourD:fourD.payoutSubTitle.' + tableName.toLowerCase()}></Text>}</th>
                    {(headersArrangement || []).map((title, index) => (
                        <Fragment key={index}>
                            {(headers || []).map((header, i) => {
                                if (title === header) {
                                    return (
                                        <th key={i} className='payout-table-subtitle'>
                                            <Text textKey={'fourD:fourD.payoutSubTitle.' + header.toLowerCase()}></Text>
                                        </th>
                                    );
                                }
                            })}
                        </Fragment>
                    ))}
                </tr>
            </thead>
            <tbody>
                {(titleArrangement || []).map((rowTitle) => (
                    <>
                        {(rowTitles || []).map((title) => (
                            <>
                                {title === rowTitle && (
                                    <tr key={rowTitle}>
                                        <th>
                                            <Text textKey={'fourD:fourD.payoutDetails.' + rowTitle}>{rowTitle}</Text>
                                        </th>

                                        {(headersArrangement || []).map((title) => (
                                            <>
                                                {(headers || []).map((header) => {
                                                    if (title === header) {
                                                        return (
                                                            <td key={header} className=''>
                                                                {data[header][rowTitle]}
                                                            </td>
                                                        );
                                                    }
                                                })}
                                            </>
                                        ))}
                                    </tr>
                                )}
                            </>
                        ))}
                        {rowTitle === 'Commission' && (
                            <tr key={rowTitle}>
                                <th>
                                    <Text textKey={'fourD:fourD.payoutDetails.' + rowTitle}>{rowTitle}</Text>
                                </th>
                                {headers.map((header) => (
                                    <td key={header} className=''>
                                        0.00%
                                    </td>
                                ))}
                            </tr>
                        )}
                    </>
                ))}
            </tbody>
        </table>
    );
};
