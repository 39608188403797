import React from 'react';

class Slider extends React.Component {
    timer = null;
    initiated = false;

    constructor() {
        super();
        this.state = {
            index: 0,
            dur: null,
            allowPause: null,
            autoPlay: null,
            items: [],
            space: null,
            activedIndex: null,
            defaultIndex: null,
            disableClick: null,
        };
    }

    tick() {
        this.next();
    }

    prev() {
        const size = this.props.items.length;
        const index = this.state.index === 0 ? size - 1 : this.state.index - 1;
        this.slideTo(index);
    }

    next() {
        const size = this.props.items.length;
        const index = this.state.index === size - 1 ? 0 : this.state.index + 1;
        this.slideTo(index);
    }

    start() {
        this.stop();
        this.state.autoPlay && (this.timer = setInterval(() => this.tick(), this.state.dur));
    }

    stop() {
        this.state.autoPlay && clearInterval(this.timer);
    }

    slideTo(index) {
        if (index == this.state.index) return;
        this.start();
        this.setState((state) => ({
            ...state,
            index,
        }));
        this.props.onChange && this.props.onChange(index);
    }

    onStart(e) {
        if (this.initiated) return;
        let point = e.touches ? e.touches[0] : e;
        let target = this.refs.elRef;
        target.distX = 0;
        target.pointX = point.pageX;
        target.startTime = +new Date();
        this.initiated = true;
    }

    onMove(e) {
        if (!this.initiated) return;
        e.preventDefault();
        let point = e.touches ? e.touches[0] : e;
        let target = this.refs.elRef;
        const deltaX = point.pageX - target.pointX;
        target.pointX = point.pageX;
        target.distX += deltaX;
    }

    onEnd(e) {
        if (!this.initiated) return;
        this.initiated = false;
        let target = this.refs.elRef;
        target.endTime = +new Date();
        let distance = target.distX,
            speed = distance / (target.endTime - target.startTime);
        if (Math.abs(distance) < 10) return;

        if (!this.state.enableClick) {
            return; // Return from the current function or block
        } else if (speed < 0) {
            this.next();
        } else {
            this.prev();
        }

        e._stopClick = true;
    }

    componentDidMount() {
        this.setState(
            {
                dur: this.props.dur || 6000,
                allowPause: this.props.allowPause ?? true,
                autoPlay: this.props.autoPlay ?? true,
                space: this.props.space ?? '',
                items: this.props.items,
                activedIndex: this.props.activedIndex ?? Math.floor(this.props.items.length / 2),
                defaultIndex: this.props.defaultIndex ?? 0,
                enableClick: this.props.enableClick ?? 'true',
            },
            () => {
                this.start();
            }
        );
    }

    componentWillUnmount() {
        this.stop();
    }

    clickSlider(e, index) {
        if (e._stopClick) return delete e._stopClick;
        if (index == this.state.index) {
            console.log('click');
        } else {
            this.slideTo(index);
        }
    }

    createList(startIndex, l) {
        let start = startIndex,
            realListIndex = this.state.activedIndex,
            len = l;
        let realList = [];

        realList[this.state.activedIndex] = startIndex;

        for (let index = 0; index < len; index++) {
            realListIndex++;
            start++;
            realListIndex = realListIndex == len ? 0 : realListIndex;
            start = start == len ? 0 : start;
            realList[realListIndex] = start;
        }

        return realList;
    }

    createStyle() {
        let cssList = [];
        for (let index = 0; index < this.props.items.length; index++) {
            const diff = index - this.state.activedIndex;
            const zIndex = -Math.abs(diff);
            const translateX = diff * 100 - 50;
            const util = this.state.space ? this.state.space.replace(parseFloat(this.state.space) + '', '') : '';
            const spacel = this.state.space ? diff * parseFloat(this.state.space) + util : 0;
            cssList.push({
                position: `absolute`,
                top: `50%`,
                left: `50%`,
                transform: `translate(${translateX}%, -50%) translateX(${spacel})`,
                zIndex,
            });
        }
        return cssList;
    }

    calcStyle(i) {
        let list = this.createList(this.state.index, this.props.items.length);
        let cssList = this.createStyle();
        let cssIndex = list.findIndex((it) => it == i);
        return cssList[cssIndex];
    }

    render() {
        return (
            <div
                ref={'elRef'}
                className='slider'
                onTouchStart={(e) => {
                    this.onStart(e);
                }}
                onTouchMove={(e) => {
                    this.onMove(e);
                }}
                onTouchEnd={(e) => {
                    this.onEnd(e);
                }}
                onTouchCancel={(e) => {
                    this.onEnd(e);
                }}
                onMouseDown={(e) => {
                    this.onStart(e);
                }}
                onMouseMove={(e) => {
                    this.onMove(e);
                }}
                onMouseUp={(e) => {
                    this.onEnd(e);
                }}
                onPointerOver={() => {
                    this.state.allowPause && this.stop();
                }}
                onPointerOut={() => {
                    this.state.allowPause && this.start();
                }}
            >
                <div className='slider-container'>
                    {this.state.items.map((node, index) => {
                        return (
                            <div
                                key={'item-' + index}
                                data-index={index}
                                style={this.calcStyle(index)}
                                onClick={(e) => {
                                    this.state.enableClick && this.clickSlider(e, index);
                                }}
                                data-active={index == this.state.index}
                            >
                                {typeof this.props.renderItem === 'function' ? this.props.renderItem(node.content) : node.content}
                            </div>
                        );
                    })}
                </div>

                {[
                    <ul key='pagination' className={['pagination', this.state.items[0]?.tab ? '' : 'default'].join(' ')}>
                        {this.state.items.map((node, i) => (
                            <li key={i} onClick={() => this.slideTo(i)} className={this.state.index === i ? 'on' : ''}>
                                {this.state.items[i].tab}
                            </li>
                        ))}
                    </ul>,
                ]}
            </div>
        );
    }
}

export default Slider;
