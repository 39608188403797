import Device from './Device';
const device = new Device();

export default class Utils {
    static initMobileData() {
        let ua = navigator.userAgent;
        let andriod = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1;
        let ipadPro = !!/macintosh/.test(ua.toLowerCase()) && 'ontouchend' in document;
        let ios = !!/iphone|ipad|ipod/.test(ua.toLowerCase()) || ipadPro;
        let tablet = /(?:iPad|PlayBook)/.test(ua) || (andriod && !/(?:Mobile)/.test(ua)) || (/(?:Firefox)/.test(ua) && /(?:Tablet)/.test(ua)) || ipadPro;
        let device = andriod || ios || tablet;

        return {
            ios: ios,
            andriod: andriod,
            tablet: tablet,
            device: device,
        };
    }

    static force2Mobile() {
        setTimeout(() => {
            Utils.removeHoverSideEffect();
        });

        const mobile = Utils.initMobileData();
        const isMobile = mobile.device && !mobile.tablet;
        const viewMode = sessionStorage.getItem('viewMode');
        if ((viewMode === null && isMobile) || (viewMode && viewMode === 'mobile')) {
            return true;
        }
        // if (isMobile &&  !sessionStorage.getItem("force2Desktop")) {
        //   //window.location.href = "./wap/index.html";
        //   // require("root/page/wap");
        //   return true;
        // }
        return false;
    }

    static setMetaTag() {
        if (device.mobile.andriod) {
            let metaTags = document.querySelectorAll('meta');
            metaTags.forEach((tag) => {
                let content = tag.getAttribute('content');
                if (content && content.includes('viewport-fit=cover')) {
                    tag.setAttribute('content', content.replace('viewport-fit=cover', 'viewport-fit=auto'));
                }
            });
        }
    }

    static removeHoverSideEffect() {
        const changeRules = (rules) => {
            for (let ri = rules.length - 1; ri >= 0; ri--) {
                let cur = rules[ri];

                if (!cur.selectorText && !cur.media) continue;
                if (cur.media) {
                    changeRules(cur.cssRules);
                    continue;
                }

                if (cur.selectorText.match(':hover') && !cur.selectorText.match('force-hover')) {
                    cur.selectorText = cur.selectorText.replace(/:hover/g, '[data-hover-none]');
                }
            }
        };

        if (!('ontouchstart' in document)) return;
        try {
            //prevent exception on browsers not supporting DOM styleSheets properlytyl

            for (let si in document.styleSheets) {
                const styleSheet = document.styleSheets[si];

                if (!styleSheet.rules) continue;

                changeRules(styleSheet.rules);
            }
        } catch (ex) {}
    }
}

class BrowserHack {
    focusTimer;
    initEvents(remove) {
        const eventType = remove
            ? (el, type, fn, options) => {
                  el.removeEventListener(type, fn, options);
              }
            : (el, type, fn, options) => {
                  el.addEventListener(type, fn, options);
              };
        eventType(window, 'touchend', this);
        eventType(window, 'focusin', this);
        eventType(window, 'focusout', this);
    }

    onTouchFullScreen() {
        const de = document.documentElement;
        if (de.requestFullscreen) {
            de.requestFullscreen();
        } else if (de.mozRequestFullScreen) {
            de.mozRequestFullScreen();
        } else if (de.webkitRequestFullScreen) {
            de.webkitRequestFullScreen();
        }
    }

    onFocus(e) {
        if (device.mobile.andriod || (device.mobile.ios && !device.browser.safari)) {
            let target = e.target;
            let count = 0;
            if (target.type !== 'radio' && !target.readOnly && target.type !== 'submit' && target.type !== undefined) {
                this.focusTimer = window.setInterval(() => {
                    target.scrollIntoView({ block: 'center' });
                    if (target.scrollIntoViewIfNeeded && !device.browser.firefox) {
                        target.scrollIntoViewIfNeeded();
                    }

                    count++;
                    if (count == 10) {
                        clearInterval(this.focusTimer);
                    }
                }, 200);
            }
        }
    }

    onBlur() {
        if (device.mobile.andriod || (device.mobile.ios && !device.browser.safari)) {
            clearInterval(this.focusTimer);
        }
    }

    handleEvent(e) {
        switch (e.type) {
            case 'touchend':
                // if (device.mobile.andriod) {
                //   if (!device.browser.huawei) {
                //     this.onTouchFullScreen();
                //   }
                // }
                break;
            case 'focusin':
                this.onFocus(e);
                break;
            case 'focusout':
                this.onBlur();
                break;
        }
    }
}

export const browserHack = new BrowserHack();
