// react/library
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation, Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';

// components/files

// controller/logic
import vm from '../../qrcode-pay.controller';
import transactionHelper from 'root/utils/transaction-helper';
import TransactionNote from 'root/webapp/shared/transaction-note/transaction-note.selector';
import { trans_getDepositAmountOptionByMethod } from 'root/utils/transaction-util';
import { AMOUNT_DEPOSIT_METHOD_CODE } from 'root/utils/constants';
import BankIcon from '../../../../../../../../components/molecules/bank-icon/bank-icon';
import PaymentGatewayIcon from '../../../../../../../../components/molecules/payment-icon/payment-icon';

class QrCodePay extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
    }

    componentDidMount() {
        vm.initCompSettings();
        if (this.state.depositAllSettings !== null) {
            vm.loadQRbank();
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    componentDidUpdate(prevProps) {
        const prevDepositAmountOptions = JSON.stringify(trans_getDepositAmountOptionByMethod(prevProps.depositAmountOptions, AMOUNT_DEPOSIT_METHOD_CODE.QR));
        const currentDepositAmountOptions = JSON.stringify(this.state.depositAmountOptions);
        if (prevDepositAmountOptions !== currentDepositAmountOptions) {
            this.setState({ depositAmountOptions: trans_getDepositAmountOptionByMethod(this.props.depositAmountOptions, AMOUNT_DEPOSIT_METHOD_CODE.QR) });
        }
    }

    render() {
        const {
            bankList,
            depositAmountOptions,
            depositMinMaxLimit,
            isFormValid,
            disableDepositButton,
            qrpaySupportedBankList,
            selectedMethodIndex,
            showAmountField,
            compSettings,
            customFilename,
        } = this.state;

        let transactionNoteProps = {
            hideDisplayInfo: compSettings && compSettings.hideDisplayInfo,
            showCustomArticle: this.state.showCustomDepositArticle,
            customArticleContent: this.state.showCustomDepositArticleContent,
            customArticleIsJson: this.state.customArticleIsJson,
            customContentStyle: this.state.customContentStyle,
            depositMinMaxLimit: this.state.depositMinMaxLimit,
            customFilename: customFilename,
        };

        const { portal } = this.props;
        let userCurrency = this.props.user && this.props.user.account && this.props.user.account.currency ? this.props.user.account.currency : null;

        if (userCurrency === 'KHUSD') userCurrency = 'USD';

        return (
            <Translation>
                {(t) => (
                    <Fragment>
                        {this.state.showQrBankList && (
                            <div
                                className='qrbanklist-box'
                                onClick={() => {
                                    vm.toggleQrPayBankList(false);
                                }}
                            >
                                <div
                                    className='qrbanklist-popup'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <div className='title'>
                                        <span>{t('transaction:transaction.deposit.qrpay.supportedBanks.popup-title', 'List of supported bank for QR code deposit')}</span>
                                        <i
                                            className='icon-close3'
                                            onClick={() => {
                                                vm.toggleQrPayBankList(false);
                                            }}
                                        ></i>
                                    </div>
                                    <div className='content qrbanklist-content'>
                                        {this.state.qrpaySupportedBankList.map((bank, index) => {
                                            return bank.isDummyBank ? (
                                                <div className='bank-container' key={index} style={{ marginRight: index % 3 === 0 ? '0' : '0.15rem' }}></div>
                                            ) : (
                                                <div className='bank-container' key={index} style={{ marginRight: index % 3 === 0 ? '0' : '0.15rem' }}>
                                                    <div className='icon-container'>
                                                        <BankIcon bankCode={bank.code} />
                                                    </div>
                                                    <div className='bankname-container'>{bank.bankName}</div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className='group-item bank-options'>
                            <div className='title'>
                                <span>{t('transaction:transaction.deposit.cashdeposit.channel', 'Deposit Channel')}</span>
                                <span className='required'>*</span>
                            </div>
                            <div className='bank-list'>
                                <ul>
                                    {this.state.methodList.map((e, i) => (
                                        <li
                                            key={i}
                                            className={[
                                                'icon-hover-box',
                                                selectedMethodIndex === i ? 'on' : 'off',
                                                e.channel.toLowerCase() === 'ezpqr' || e.channel.toLowerCase() === 'h2pp' ? 'qrbank-item' : '',
                                            ].join(' ')}
                                            onClick={() => {
                                                vm.changeQRChannel(e, i);
                                            }}
                                            onMouseEnter={() => {
                                                this.setState({ qrHoveredIndex: i });
                                            }}
                                            onMouseLeave={() => {
                                                this.setState({ qrHoveredIndex: null });
                                            }}
                                        >
                                            <span className={`icon-box ${e.channel.toLowerCase() === 'ezpqr' || e.channel.toLowerCase() === 'h2pp' ? 'qrbank-icon-box' : ''}`}>
                                                <div className={`icon-${e.channel}`}>
                                                    <PaymentGatewayIcon pgCode={e.channel} />
                                                </div>
                                            </span>
                                            <span>{e.displayName}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        {bankList.length > 0 && (
                            <div className='group-item bank-options'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.onlinetransfer.bank', 'Bank Options')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='bank-list'>
                                    <ul>
                                        {bankList.map((bank, i) => (
                                            <li
                                                key={i}
                                                className={[
                                                    'icon-hover-box',
                                                    this.state.selectedBankIndex === i ? 'on' : '',
                                                    bank.code.toLowerCase() === 'ezpqr' || bank.code.toLowerCase() === 'h2pp' ? 'qrbank-item' : '',
                                                ].join(' ')}
                                                onClick={() => {
                                                    vm.onBankChanged(bank);
                                                }}
                                                onMouseEnter={() => {
                                                    this.setState({ hoveredIndex: i });
                                                }}
                                                onMouseLeave={() => {
                                                    this.setState({ hoveredIndex: null });
                                                }}
                                            >
                                                <span
                                                    className={`icon-box ${
                                                        bank.code.toLowerCase() === 'ezpqr' || bank.code.toLowerCase() === 'h2pp' ? 'qrbank-icon-box' : ''
                                                    }`}
                                                >
                                                    <i
                                                        className={[
                                                            'icon-bank',
                                                            this.state.selectedBankIndex === i || (this.state.hoveredIndex !== null && this.state.hoveredIndex === i)
                                                                ? 'icon-bank-on'
                                                                : 'icon-bank-off',
                                                            bank.code.toLowerCase() === 'ezpqr' || bank.code.toLowerCase() === 'h2pp' ? 'qrbank-icon-bank' : '',
                                                            `icon-${bank.code}`,
                                                        ].join(' ')}
                                                    >
                                                        <BankIcon bankCode={bank.code} />
                                                    </i>
                                                </span>
                                                <span>{bank.name}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        )}

                        {showAmountField && (
                            <div className='group-item input-item'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.onlinetransfer.amount', 'Deposit Amount')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='amount-content'>
                                    <div className='input-box'>
                                        <div className='deposit-input-box'>
                                            <input
                                                id='depositamount'
                                                type='numeric'
                                                autoComplete='off'
                                                placeholder={t('global:global.form.online-transfer-amount-placeholder', {
                                                    min: window.SPL_Other.formatAmount(depositMinMaxLimit ? depositMinMaxLimit.min : 0),
                                                    max: window.SPL_Other.formatAmount(depositMinMaxLimit ? depositMinMaxLimit.max : 0),
                                                })}
                                                min={'0'}
                                                value={this.state.fields['amount']}
                                                onChange={(e) => vm.handleAmountChange(e)}
                                                onBlur={() => {
                                                    transactionHelper.handleValidation(this);
                                                }}
                                            />
                                            {portal?.settings?.features?.depositPagesSettings?.enableAccValueButton && depositAmountOptions?.length > 0 && (
                                                <div className='deposit-amount-clear-button' onClick={() => transactionHelper.clearInputFieldAmount(this)}>
                                                    <img src='/public/html/images/settings/clear-button.svg' alt='clear-button-img' />
                                                </div>
                                            )}
                                        </div>
                                        <div className='error-validation'>
                                            <Trans
                                                i18nKey={this.state.errors['amount']}
                                                values={{ amount: window.SPL_Other.formatAmount(this.state.amountErrorValue), currency: userCurrency }}
                                            ></Trans>
                                        </div>
                                    </div>
                                    <ul>
                                        {depositAmountOptions.map((item) => (
                                            <li
                                                key={item}
                                                onClick={() => {
                                                    transactionHelper.onDepositAmountClicked(this, item);
                                                }}
                                            >
                                                {item}
                                            </li>
                                        ))}
                                    </ul>
                                    <div className='qr-reminder error-validation'>
                                        {t('transaction:transaction.deposit.qrpay.reminder', 'Reminder: QR deposit can only be used to scan once only')}
                                    </div>
                                </div>
                            </div>
                        )}

                        {qrpaySupportedBankList.length > 0 && (
                            <div className='group-item input-item'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.qrpay.supportedBanks.title', 'Supported Banks')}</span>
                                </div>
                                <div className='amount-content'>
                                    <div
                                        className='qr-information'
                                        onClick={() => {
                                            vm.toggleQrPayBankList(true);
                                        }}
                                    >
                                        {t('transaction:transaction.deposit.qrpay.supportedBanks.detail', 'Click to see the bank list for QR code deposit')}
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className='group-item submit-container'>
                            <div className='title'></div>
                            <div className='amount-content'>
                                <div className='flexbox'>
                                    <button
                                        className='btn btn-submit'
                                        onClick={() => {
                                            vm.submitForm();
                                        }}
                                        disabled={!isFormValid || disableDepositButton || this.state.receiptRequired}
                                    >
                                        {t('transaction:transaction.deposit.deposithistory.submitbtn', 'SUBMIT').toUpperCase()}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <TransactionNote {...transactionNoteProps} />
                    </Fragment>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'settings'])(withRouter(QrCodePay)));
