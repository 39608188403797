import React from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';

import vm from '../redirect-error.controller';

class RedirectError extends React.Component {
    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        if (this.props.location.search) {
            let search = this.props.location.search;
            let errorCode = search.toString().replace('?', '');

            this.setState({ errorCode: errorCode });
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        return (
            <Translation>
                {(t) => (
                    <div className='m-maintenance-panel'>
                        <div className='m-maintenance-box'>
                            <div className='title'>
                                <span>MAINTENANCE {this.state.errorCode}</span>
                            </div>

                            <div className='content'>
                                <p>
                                    System update in progress, please try again later. If you have any questions, please don't hesitate to contact our Customer Service
                                    Department or Live Help.
                                </p>
                                <p>系统更新中， 请稍后再试。 如果您有任何问题， 请联系我们的客户服务部。 或即时帮助。</p>
                                <p>
                                    Sistem pemeliharaan dalam proces, silakan coba lagi nanti. Jika anda memiliki pertanyaan, jangan ragu untuk menghubungi Departemen Layanan
                                    Pelanggan kami atau Bantuan Langsung.
                                </p>
                                <p>
                                    กำลังอยู่ในระหว่างการดำเนินการอัพเดทระบบ โปรดลองอีกครั้งในภายหลัง หากมีคำถามเพิ่มเติมโปรดติดต่อเราฝ่ายบริการลูกค้าทางไลฟ์แชทได้ตลอด 24
                                    ชั่วโมง ขอบคุณค่ะ.
                                </p>
                                <p>
                                    Hệ thống đang tiến hành bảo trì, vui lòng thử lại sau. Để biết thêm chi tiết vui lòng liên hệ Hỗ trợ chăm sóc khách hàng 24/7. Xin cảm ơn
                                    quý khách!
                                </p>
                            </div>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation()(withRouter(RedirectError)));
