import React from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { portalAction } from 'root/redux/action';
import { mapStateToProps } from 'root/redux/selector/selector';
import vm from '../settings.controller';
import MyAccountSummary from './myAccountSummary.js';
import { gu_filterCommonKey } from '@utils/general-util.js';
import { MODULE_TYPE as MT } from '@utils/constants';
import AuthSettings from '@components/molecules/auth-settings/auth-settings';
class MNavbarMyAccount extends React.Component {
    constructor() {
        super();
        vm.init(this);
        // this.state = {
        //     refeshing: false,
        //   };
    }

    componentDidMount() {
        vm.getAdditionalMenu();
    }

    title() {
        const { history } = this.props;
        const str = history.location.pathname.split('/')[2];
        return str ? `global:global.settingTabTitle.${str}` : '';
    }

    render() {
        const { history, portal } = this.props;
        const _isEnableVerifications = Object.keys(gu_filterCommonKey(portal?.settings?.features?.settingsConfiguration?.memberVerificationSettings) || [])?.length > 0;

        return (
            <Translation>
                {(t) => (
                    <div className={`tool-bar funds ${_isEnableVerifications ? 'with-verifications' : ''}`}>
                        <AuthSettings module={MT?.BIND_AUTHENTICATOR} isPublic={false} />
                        <AuthSettings module={MT?.SIX_PIN} isPublic={false} />

                        <div className='title-box'>
                            <div className='left'>
                                <div className='icon-box'>
                                    <i
                                        className='icon-back'
                                        onClick={() => {
                                            const globalHistory = history.globalHistory;
                                            const back = globalHistory[globalHistory.length - 2];
                                            if (back) {
                                                history.replace(back);
                                            }
                                        }}
                                    ></i>
                                </div>
                                <span>{t(this.title()).toUpperCase()}</span>
                            </div>

                            <div className='icon-box'>
                                <i
                                    className='icon-menu'
                                    onClick={() => {
                                        this.props.dispatch(portalAction.floatingPageUpdated('sidemenu'));
                                    }}
                                ></i>
                            </div>
                        </div>
                        <MyAccountSummary vm={vm} parentState={this.state} />
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['settings', 'transaction'])(withRouter(MNavbarMyAccount)));
