// centralize provider function, to minimize duplicate function call
// in the project
import { walletAction } from 'root/redux/action/wallet.action';
import notification from 'root/utils/notification';
import { portalAction } from 'root/redux/action';

const providerService = {
    launchGameFromHomePage(gameObj, language, isLogin, screen, isFun, vm) {
        let isMobile = false;
        if (screen.viewType === 'mobile') {
            isMobile = true;
        }
        let isDemoGame = isFun ? isFun : false;
        let customParam = {
            domain: window.location.hostname,
            isMobile: screen.isMobile,
            isFun: isDemoGame,
            isMenuMode: true,
            isMiniGame: false,
            languageKey: language.key,
            gameCode: gameObj.gameCode,
            provider: gameObj.provider,
            matchId: gameObj.matchId,
        };

        if (customParam.isFun === false && isLogin === false) {
            alertLoginCallback();
            return;
        } else {
            if (gameObj.category === 'SLOT') {
                gameObj.category = 'SL';
            }
        }

        window.SPL_Provider.startProviderGameV2(gameObj.provider, gameObj.category, language.key, isMobile, isDemoGame, isLogin, alertLoginCallback, customParam).then(() => {
            if (vm && vm.props && vm.props.dispatch) {
                // force update wallet amount in navbar
                let count = 0;
                let interval = setInterval(() => {
                    if (count < 5) {
                        vm.props.dispatch(walletAction.udpateLoadWallet(true));
                        count++;
                    } else {
                        clearInterval(interval);
                    }
                }, 3000);
            }
        });

        function alertLoginCallback() {
            if (isMobile) {
                vm.props.dispatch(portalAction.floatingPageUpdated('login'));
            } else {
                notification.showNotification('error', vm.props.t('home:home.loginText'));
            }
        }
    },

    launchGame(gameObj, language, isLogin, screen, isFun, gameParam, vm, desktop) {
        let isMobile = false;
        if (screen.viewType === 'mobile' && !desktop) {
            isMobile = true;
        }
        window.SPL_Provider.startProviderGameV2(gameObj.provider, gameObj.category, language.key, isMobile, isFun, isLogin, alertLoginCallback, gameParam, gameParam).then(
            () => {
                if (vm && vm.props && vm.props.dispatch) {
                    // force update wallet amount in navbar
                    let count = 0;
                    let interval = setInterval(() => {
                        if (count < 5) {
                            vm.props.dispatch(walletAction.udpateLoadWallet(true));
                            count++;
                        } else {
                            clearInterval(interval);
                        }
                    }, 3000);
                }
            }
        );

        function alertLoginCallback() {
            if (isMobile) {
                vm.props.dispatch(portalAction.floatingPageUpdated('login'));
            } else {
                notification.showNotification('error', vm.props.t('home:home.loginText'));
            }
        }
    },
};

export default providerService;
