import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SVGElements from 'root/components/SVGElements';

const controller = {
    vm: null,
    init(vm) {
        this.vm = vm;
        this.vm.state = {
            userObj: {
                playerId: '',
                playerPassword: '',
            },
            psy8Url: '',
            psy8Guide: [],
            navigation: <SVGElements className='icon-arrow-prev' name='arrow-down-icon' />,
        };
    },

    getPsy8Guide() {
        let psy8Guide = [],
            bannerElement = '';
        const { key } = controller.vm.props.language;

        for (let i = 0; i < 8; i++) {
            bannerElement = generatePsy8GuideElement(i);
            psy8Guide.push(bannerElement);
        }

        controller.vm.setState({ psy8Guide: psy8Guide });

        function generatePsy8GuideElement(i) {
            return {
                content: (
                    <div>
                        <LazyLoadImage src={`/public/html/images/slots/psy8/${i + 1}${key === 'zh-CN' ? '-cn' : ''}.jpg`} />
                    </div>
                ),
            };
        }
    },

    getPsy8Url() {
        let domain = '';
        const { language } = controller.vm.props;

        if (window.location.hostname === 'localhost') {
            domain = window.tempDomain;
        } else {
            domain = window.location.hostname;
        }

        let param = {
            domain: domain,
            provider: 'PSY8',
            merchantCode: window.merchantCode,
            currency: language && language.currencyLang,
        };

        window.SPL_Provider.getPsy8Url(param).then((data) => {
            controller.vm.setState({ psy8Url: data.gameLink });
        });
    },

    getGameLoginCredential() {
        let domain = '';

        if (window.location.hostname === 'localhost') {
            domain = window.tempDomain;
        } else {
            domain = window.location.hostname;
        }

        let params = {
            domain: domain,
            provider: 'PSY8',
        };

        window.SPL_Provider.getGameSession(params).then((userObj) => {
            controller.vm.setState({ userObj: userObj });
        });
    },

    downloadPsy8App() {
        window.SPL_Other.openNewWindow(controller.vm.state.psy8Url);
    },
};

export default controller;
