import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import HomeMemberProfile from 'root/webapp/shared/component/wap/HomeMemberProfile';
import SVGElements from 'root/components/SVGElements';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { portalAction } from 'root/redux/action/portal.action';
import vm from '../change-language.controller';

class ChangeLanguage extends React.Component {
    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        vm.getLanguageList();
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    onBackButtonClicked() {
        const { location, history, dispatch } = this.props;
        if (location.previousMenuRoute) {
            history.push(location.previousMenuRoute);

            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const popOutParam = urlParams.get('p');
            if (popOutParam) {
                dispatch(portalAction.floatingPageUpdated(popOutParam));
            }
        } else {
            history.push('/home');
        }
    }

    render() {
        const { currentLanguage } = this.state;
        return (
            <Translation>
                {(t) => (
                    <Fragment>
                        <HomeMemberProfile />

                        <div className='m-language-container'>
                            <div className='title-box'>
                                <SVGElements
                                    className='icon-back2'
                                    name='back-icon'
                                    onClick={() => {
                                        this.onBackButtonClicked();
                                    }}
                                />
                                <p>{t('global:global.menu.language', 'Region and Language')}</p>
                            </div>

                            <div className='country-language-box'>
                                {this.state.languageList.map((languageObj, index) => {
                                    return (
                                        <div key={index} className='country-box'>
                                            <LazyLoadImage className='flag-img' src={languageObj.languages[0].src} alt={`${languageObj.countryName}.png`} />
                                            <div className='language-box'>
                                                {languageObj.languages.map((language, index2) => {
                                                    return (
                                                        <p
                                                            key={index2}
                                                            className={`language-column ${currentLanguage.countryLanguageKey === language.countryLanguageKey ? 'active' : ''}`}
                                                            onClick={() => vm.changeLanguage(language)}
                                                        >
                                                            {language.display === 'Khmer' ? 'ខ្មែរ' : language.display}
                                                        </p>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </Fragment>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation('')(withRouter(ChangeLanguage)));
