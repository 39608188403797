import React, { Fragment } from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import ShareModal from 'root/webapp/shared/shareModal/shareModal';

class Referral extends React.Component {
    constructor(props) {
        super();
        this.props = props;
    }

    componentDidMount() {}

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        const { referralSettings, referralOption, disableMemberBecomeReferral } = this.props;
        const { key } = this.props.language;

        let lang = '';
        if (key) {
            lang = key.toLowerCase();
        }
        return (
            <Translation>
                {(t) => (
                    <Fragment>
                        {referralSettings && referralSettings !== null && (
                            <div className='myreferral-container'>
                                {!disableMemberBecomeReferral && (
                                    <>
                                        <div className='referral-code-link-container'>
                                            <div className='referralCode-container'>
                                                <div>
                                                    <span className='primary-text'>{t('referral:referral.choosePackage', 'Share your favorite offers to your friends')}</span>{' '}
                                                    &nbsp;
                                                    <span className='font-bold'>{t('referral:referral.top.desc3', 'Share your favorite offers to your friends')}</span>
                                                </div>
                                                <div className='share-wrap'>
                                                    <input type='text' defaultValue={referralSettings.referralSetting.referralCode} readOnly />
                                                    <div className='share-container'>
                                                        <ShareModal
                                                            txtToShare={referralSettings.referralSetting.referralMsg}
                                                            linkToShare={referralSettings.referralSetting.referralLink}
                                                            isOpen={this.props.showShareModal}
                                                            share={this.props.share.bind(this)}
                                                        ></ShareModal>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='package-container'>
                                            <div className='choose-package'>{t('referral:referral.choosePackage', 'Share your favorite offers to your friends')}</div>
                                            {referralOption && referralOption[lang].spDImg && referralOption[lang].spDImg !== '' && (
                                                <div className='package-option'>
                                                    <label className='share-option'>
                                                        <input type='radio' name='option' checked readOnly />
                                                        <span className='checkmark'></span>
                                                        {t('referral:referral.option', 'Option')}: 1 -{' '}
                                                        {referralOption[lang] && referralOption[lang].amount && (
                                                            <span className='option-amount'>{referralOption[lang].amount}</span>
                                                        )}
                                                    </label>

                                                    <div className='referral-ticket' style={{ backgroundImage: `url(${referralOption[lang].spDImg})` }}>
                                                        <div className='ticket-amount'>{referralOption[lang].amount}</div>
                                                        <div className='ticket-content' dangerouslySetInnerHTML={{ __html: referralOption[lang].details }}></div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}

                                <div className='details-container'>
                                    <div className='title font-bold'>{t('referral:referral.urReferralStatus', 'Your Referral Status')}</div>
                                    <div className='details-desc'>
                                        <div className='details-row'>
                                            <div className='details-title'>
                                                {t('referral:referral.missionComplete', 'Mission Completed')}
                                                {`\n${t('referral:referral.inviteAccepted', 'Invite Accepted')}`}
                                            </div>
                                            <div>{` : \n : `}</div>
                                            <span className='detailsData'>
                                                {`${referralSettings.referralDetail.totalReferree}`}
                                                {`\n${referralSettings.referralDetail.totalConverted === null ? 0 : referralSettings.referralDetail.totalConverted}`}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='ticket-container'>
                                    {referralSettings.terms && referralSettings.terms.hasOwnProperty('title') && referralOption[lang] && (
                                        <div className='termNCondition-container'>
                                            <div className=''>{referralSettings.terms.title}</div>
                                            <br />
                                            <div className='desc'>
                                                <ul>
                                                    <li>
                                                        <p>
                                                            {t(referralOption[lang].termNCondition1, {
                                                                targetDepositAmount: referralSettings.referralDetail.targetDepositAmount,
                                                                expiredDays: referralSettings.referralDetail.expiredDays,
                                                            })}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            {t(referralOption[lang].termNCondition2, {
                                                                targetDepositAmount: referralSettings.referralDetail.targetDepositAmount,
                                                                expiredDays: referralSettings.referralDetail.expiredDays,
                                                                referralBonusAmount: referralSettings.referralDetail.referralBonusAmount,
                                                            })}
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            {t(referralOption[lang].termNCondition3, {
                                                                referralBonusAmount: referralSettings.referralDetail.referralBonusAmount,
                                                            })}
                                                        </p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <br />
                                            <div>
                                                {t(referralOption[lang].effectiveDate, {
                                                    year: referralSettings.referralDetail.year,
                                                    month: referralSettings.referralDetail.month,
                                                    day: referralSettings.referralDetail.day,
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </Fragment>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'referral', 'settings'])(withRouter(Referral)));
