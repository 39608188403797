import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import core from 'root/utils/core';

import ResponsibleGaming1 from './component/responsible-gaming';

class ResponsibleGaming extends React.Component {
    components = {
        desktop: {
            default: ResponsibleGaming1,
        },
        mobile: {
            default: ResponsibleGaming1,
        },
    };

    render() {
        const TagName = core.getCorrectComponent(this.components, 'responsible-gaming', this.props.screen.viewType);
        return <TagName />;
    }
}

export default connect(mapStateToProps, null)(ResponsibleGaming);
