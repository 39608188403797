import React from 'react';
import { Translation } from 'react-i18next';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import SmallLoading from 'root/webapp/shared/loading/smallLoading';
import SVGElements from 'root/components/SVGElements';

class Turnover extends React.Component {
    constructor() {
        super();
        this.state = {
            navList: [{ name: 'transaction:transaction.transfer.showBalance' }, { name: 'transaction:transaction.transfer.showWeeklyTurnOver' }],
            active: -1,
            refeshing: false,
        };
    }

    reloadOnClick = () => {
        const { getMainWallet, loadProviders } = this.props;
        getMainWallet();
        loadProviders();
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.state.active !== prevState.active) {
            const { showHideInputForm } = this.props;
            showHideInputForm(this.state.active);
        }
    }

    currentTop(t) {
        switch (this.state.active) {
            case -1:
                return (
                    <ul className='turnover-nav'>
                        {this.state.navList.map(({ name }, i) => (
                            <li
                                key={t(name)}
                                className={[i === this.state.active ? 'on' : ''].join('')}
                                onClick={() => {
                                    this.setState((state) => ({ ...state, active: i }));
                                }}
                            >
                                {<span>{t(name)}</span>}
                                <i className='icon-arrow-transfer'></i>
                            </li>
                        ))}
                    </ul>
                );
            case 0:
                return (
                    <ul
                        className='balance-item'
                        onClick={() => {
                            this.setState((state) => ({ ...state, active: -1 }));
                        }}
                    >
                        <li style={{ width: '100%' }}>
                            <span>{t('transaction:transaction.transfer.hideBalance', 'Hide Balance')}</span>
                            <i className='icon-close-transfer'></i>
                        </li>
                    </ul>
                );
            case 1:
                return (
                    <ul
                        className='turnover-item'
                        onClick={() => {
                            this.setState((state) => ({ ...state, active: -1 }));
                        }}
                    >
                        <li style={{ width: '100%' }}>
                            <span>{t('transaction:transaction.transfer.hideWeeklyTurnOver', 'Hide Weekly Turnover')}</span>
                            <i className='icon-close-transfer'></i>
                        </li>
                    </ul>
                );

            default:
                break;
        }
    }

    getToolBar() {
        return document.querySelector('.tool-bar.funds');
    }

    render() {
        const { totalProviderBalance, totalProviderTurnover, iconLoading } = this.props.parentState;

        return (
            <Translation>
                {(t) => (
                    <div className='turnover-panel'>
                        {
                            <TransitionGroup className='turnover-top-box'>
                                <CSSTransition key={this.state.active} timeout={500} classNames='fade-next'>
                                    {this.currentTop(t)}
                                </CSSTransition>
                            </TransitionGroup>
                        }
                        {/* {this.getToolBar() &&
              createPortal(
                <TransitionGroup className="turnover-top-box">
                  <CSSTransition
                    key={this.state.active}
                    timeout={500}
                    classNames="fade-next"
                  >
                    {this.currentTop()}
                  </CSSTransition>
                </TransitionGroup>,
                this.getToolBar()
              )} */}
                        <div className='show-item-box'>
                            <CSSTransition classNames='spread-bottom' in={this.state.active === 0} timeout={300} unmountOnExit>
                                <div className='balance-show-box'>
                                    <div className='turnover-content'>
                                        {this.props.parentState.categorizedProviderList.length ? (
                                            this.props.parentState.categorizedProviderList.map(({ title, list }, i) => {
                                                if (title !== 'Wallet') {
                                                    return (
                                                        <div key={title}>
                                                            <div className='title'>
                                                                <span>{title}</span>
                                                            </div>
                                                            <ul>
                                                                {list.map(({ name, balance, display }) => (
                                                                    <li key={display}>
                                                                        <span>{display === 's-Sports' ? 'SBOBet' : display}</span>
                                                                        <span>{balance ? window.SPL_Other.formatAmount(balance) : window.SPL_Other.formatAmount('0.00')}</span>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    );
                                                }
                                            })
                                        ) : (
                                            <SmallLoading></SmallLoading>
                                        )}
                                    </div>
                                </div>
                            </CSSTransition>
                            <CSSTransition classNames='spread-bottom' in={true} timeout={300} unmountOnExit>
                                <div className='turnover-show-box'>
                                    <div className='total-box'>
                                        <span>{t('settings:settings.total')}</span>
                                        <div>
                                            <span>{window.SPL_Other.formatAmount(totalProviderTurnover)}</span>
                                            <span className='icon-box'>
                                                <CSSTransition classNames='rotate360' in={this.state.refeshing} timeout={1000}>
                                                    <SVGElements
                                                        className='icon-reload'
                                                        name='reload-icon'
                                                        onClick={() => {
                                                            this.setState({ ...this.state, refeshing: true }, () => {
                                                                this.setState({ ...this.state, refeshing: false });
                                                            });
                                                        }}
                                                    />
                                                    {/* <i
                            className="icon-reload"
                            onClick={() => {
                              this.setState({ ...this.state, refeshing: true }, () => {
                                this.setState({ ...this.state, refeshing: false })
                              });
                            }}
                          ></i> */}
                                                </CSSTransition>
                                            </span>
                                        </div>
                                    </div>
                                    <div className='turnover-content'>
                                        {this.props.parentState.categorizedProviderList.length ? (
                                            this.props.parentState.categorizedProviderList.map(({ title, list }, i) => {
                                                if (title !== 'Wallet') {
                                                    return (
                                                        <div key={title}>
                                                            <div className='title'>
                                                                <span>{title}</span>
                                                            </div>
                                                            <ul>
                                                                {list.map(({ name, turnover, display }) => (
                                                                    <li key={display}>
                                                                        <span>{display === 's-Sports' ? 'SBOBet' : display}</span>
                                                                        <span>
                                                                            {turnover ? window.SPL_Other.formatAmount(title.turnover) : window.SPL_Other.formatAmount('0.00')}
                                                                        </span>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    );
                                                }
                                            })
                                        ) : (
                                            <SmallLoading></SmallLoading>
                                        )}
                                    </div>
                                </div>
                            </CSSTransition>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default Turnover;
