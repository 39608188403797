import React from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';

import commonUtil from '../../../../utils/common-util';

import Instruction from '../desktop/Instruction';

class AppDownload extends React.Component {
    constructor() {
        super();
        this.state = {
            appDownloadLinkData: {},
            instruction: [],
        };
    }

    componentDidMount() {
        commonUtil.getAppDownloadLink(this);
        this.getAppInstructionList();
    }

    appIntructionOnclick(mobile) {
        this.setState({ openPopoup: !this.state.openPopoup, phoneType: mobile });
    }

    getAppInstructionList() {
        window.SPL_JsonSettings.getAppInstructionJson((data) => {
            this.setState({ instruction: data });
        });
    }

    renderDownloadContent(deviceType) {
        const { appDownloadLinkData } = this.state;
        let imgPath = deviceType === 'ios' ? '/public/html/images/homepage/app_download/ios.svg' : '/public/html/images/homepage/app_download/android.svg';
        return (
            <div className='download-button'>
                <div
                    className='download-btn'
                    onClick={() => {
                        commonUtil.onAppDownloadClick(deviceType, appDownloadLinkData && appDownloadLinkData[deviceType]);
                    }}
                >
                    <img alt='' src={imgPath} />
                </div>
                <div className='instruction-btn' onClick={() => this.appIntructionOnclick(deviceType)}>
                    <img alt='' src={'/public/html/images/homepage/app_download/info-icon.svg'} />
                </div>
            </div>
        );
    }

    render() {
        const { appDownloadLinkData } = this.state;
        const { viewType } = this.props.screen;
        const isMobile = viewType === 'mobile';
        const instructionPopUp = {
            isMobile,
            device: this.state.phoneType,
            togglePopUp: this.appIntructionOnclick.bind(this),
        };

        return (
            <Translation>
                {(t) => (
                    <div className='m-app-download'>
                        <div className='app-download-container'>
                            <div className='section-logo' dangerouslySetInnerHTML={{ __html: t(`app:app.banner.title`) }}></div>
                            <div className='section-download'>
                                {appDownloadLinkData && appDownloadLinkData.ios && <div className='download-block'>{this.renderDownloadContent('ios')}</div>}
                                {appDownloadLinkData && appDownloadLinkData.android && <div className='download-block'>{this.renderDownloadContent('android')}</div>}
                            </div>
                            {this.state.openPopoup && this.state.instruction && (
                                <Instruction type={this.state.phoneType} instructionPopUp={instructionPopUp} instruction={this.state.instruction} />
                            )}
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['app'])(withRouter(AppDownload)));
