import React, { Fragment } from 'react';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ImageHelper from 'root/webapp/shared/image-helper/image-helper';

class MobileSelect extends React.Component {
    constructor() {
        super();
        this.state = {
            itemIndex: -1, // -1 = please select
            showItem: false,
        };
    }

    handleEvent(e) {
        switch (e.type) {
            case 'click':
                this.clickListOut(e);
                break;

            default:
                break;
        }
    }

    clickListOut(e) {
        if (this.state.showItem) {
            const stopEventEl = [this.refs.currentSelectRef, this.refs.itemRef];
            for (let index = 0; index < stopEventEl.length; index++) {
                let child = stopEventEl[index];
                if (child && child.contains(e.target)) {
                    return;
                }
            }

            this.setState((state) => ({ ...state, showItem: false }));
        }
    }

    initEvent(off) {
        window[off ? 'removeEventListener' : 'addEventListener']('click', this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.itemList && this.props.itemList.length > 0 && this.state.itemIndex === -1 && this.props.forceSelectIndex !== undefined) {
            this.setState({
                itemIndex: this.props.forceSelectIndex,
                isSelected: true,
            });
        }
        if (prevProps.newItemArray !== this.props.newItemArray) {
            const { requiredReset } = this.props;
            if (requiredReset) {
                this.setState({
                    isSelected: false,
                });
            }
        }
    }

    currentSelect(index) {
        const { itemList, key } = this.props;

        return itemList.length ? itemList[index][key] : '';
    }

    toggleShowItem(isSelected, isShow) {
        this.setState((state) => {
            return { ...state, isSelected, showItem: isShow ?? !state.showItem };
        });
    }

    componentWillMount() {
        this.initEvent();
    }

    componentWillUnmount() {
        this.initEvent(true);
    }

    disabledDropDown(isSelected, disableDropDown) {
        if (!disableDropDown) {
            this.toggleShowItem(isSelected);
        }
    }
    onChange = (item, index) => {
        const { onChange } = this.props;
        setTimeout(() => {
            onChange(item);
        }, 1);
        this.setState((state) => ({
            ...state,
            itemIndex: index,
            showItem: false,
        }));
    };

    render() {
        const {
            itemList,
            itemKey,
            onChange,
            itemIndex,
            title,
            required,
            onChangeIndexType,
            t,
            isPleaseSelect,
            fieldKeyChange,
            disabledDropDown,
            enableInfoIcon,
            isShowNoticeBox,
            depositBankDetailInfo,
            onImportantNoticeIconClick,
        } = this.props;
        const { showItem, isSelected } = this.state;
        return (
            <div className='mobile-select group-code group-item'>
                <div className='center-box'>
                    <div className='group title'>
                        <span>
                            {title} {required && <span className='required'>*</span>}
                        </span>
                        {enableInfoIcon && (
                            <>
                                <div className='important-notice-container' onClick={() => onImportantNoticeIconClick()}>
                                    <ImageHelper displaySVG={'info-icon'} className={`info-icon`} svgType='SVGElements' isSVG={true} />
                                </div>

                                {isShowNoticeBox && (
                                    <div className={`important-notice-box`}>
                                        <div>{depositBankDetailInfo}</div>
                                    </div>
                                )}
                            </>
                        )}
                        {/* <span>Promo Details</span> */}
                    </div>
                    <div
                        ref={'currentSelectRef'}
                        className={['current-select', showItem || isSelected ? 'on' : ''].join(' ')}
                        onClick={() => {
                            this.disabledDropDown(isSelected, disabledDropDown);
                        }}
                    >
                        <span style={{ fontFamily: 'Nunito-Regular' }}>
                            {isPleaseSelect && !isSelected ? t('global:global.form.pleaseSelect') : itemList.length ? t(itemList[itemIndex || 0][itemKey]) : ''}
                        </span>
                        <CSSTransition classNames='spread-bottom' in={showItem} timeout={300} unmountOnExit>
                            <ul
                                className='item-list-box'
                                ref={'itemRef'}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                {itemList.length > 0 ? (
                                    itemList.map((item, index) => (
                                        <li
                                            key={index}
                                            className={[isSelected && index == itemIndex ? 'on' : ''].join(' ')}
                                            onClick={() => {
                                                let e = {
                                                    target: {
                                                        value: index,
                                                        customValue: item[itemKey],
                                                        customObj: item,
                                                    },
                                                };
                                                if (onChangeIndexType) {
                                                    onChange(index);
                                                } else {
                                                    onChange(e);
                                                }
                                                this.toggleShowItem(true);
                                            }}
                                        >
                                            <span>{item[itemKey] === 's-Sports' ? 'SBOBet' : t(item[itemKey])}</span>
                                        </li>
                                    ))
                                ) : (
                                    <li>
                                        <span>{t('global:global.form.pleaseSelect')}</span>
                                    </li>
                                )}
                            </ul>
                        </CSSTransition>
                        <div className='group'>
                            <span className='icon-box'>
                                <CSSTransition classNames='rotate180' in={showItem} timeout={300}>
                                    <i className='icon-arrow2'></i>
                                </CSSTransition>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
MobileSelect.propTypes = {
    itemList: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    itemKey: PropTypes.string.isRequired,
};
export default withTranslation('settings')(MobileSelect);
