import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';

import Rebate1 from './component/rebate';

import MRebate1 from './component/m-rebate';

import core from 'root/utils/core';

class Rebate extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
    }

    components = {
        desktop: {
            default: Rebate1,
        },
        mobile: {
            default: MRebate1,
        },
    };

    render() {
        const TagName = core.getCorrectComponent(this.components, 'history-rebate', this.props.screen.viewType);
        return <TagName {...this.props}></TagName>;
    }
}

export default connect(mapStateToProps, null)(Rebate);
