import React from 'react';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { mapStateToProps } from 'root/redux/selector/selector';
import SmallLoading from 'root/webapp/shared/loading/smallLoading';
import SVGElements from 'root/components/SVGElements';
import { walletAction } from 'root/redux/action/wallet.action';
import UserVerifications from '@components/organisms/user-verifications/user-verifications';
class MyAccountSummary extends React.Component {
    constructor() {
        super();
        this.state = {
            auto: false,
            refeshing: false,
            showMoneny: true,
        };
    }

    toggleShowMoney(show) {
        this.setState({ ...this.state, showMoneny: show ?? !this.state.showMoneny });
    }

    componentDidMount() {
        this.isMount = true;
        const { vm } = this.props;
        vm.chkUseLanding();
        vm.highlightModule();
        vm.refreshWallet();
        vm.getUserSeamlessStatus();
        vm.getMobileMenuList();
        vm.getCurrentSelectedTab();
        vm.initOnMount(this);
        vm.initCompSettings();
    }

    componentDidUpdate(prevProps) {
        const { vm } = this.props;

        if (prevProps.portal.pathname !== this.props.portal.pathname) {
            vm.highlightModule();
            vm.getMobileMenuList();
        }
        if (prevProps.wallet.loadWallet !== this.props.wallet.loadWallet) {
            if (this.props.wallet.loadWallet) {
                vm.refreshWallet();
                setTimeout(() => {
                    this.props.dispatch(walletAction.udpateLoadWallet(false));
                }, 500);
            }
        }
    }

    componentWillUnmount() {
        this.isMount = false;
        this.setState = () => {
            return;
        };
    }

    render() {
        const { vm, parentState, promotionReducer } = this.props;
        const { promotionData } = promotionReducer;

        let userCurrency = this.props.user && this.props.user.account && this.props.user.account.currency ? this.props.user.account.currency : null;

        if (userCurrency === 'KHUSD') userCurrency = 'USD';

        return (
            <Translation>
                {(t) => (
                    <div>
                        <div className='funds-com'>
                            <div className='main-wallet-box'>
                                <div className='group'>
                                    <span>{t('settings:settings.mainwallet', 'Main Wallet')}</span>
                                    <i
                                        className={['icon-eyes', this.state.showMoneny ? 'on' : ''].join(' ')}
                                        onClick={() => {
                                            this.toggleShowMoney();
                                        }}
                                    ></i>
                                </div>
                                <div className='group'>
                                    <span
                                        className='icon-box icon-box-chat'
                                        onClick={() => {
                                            vm.menuClicked('livechat');
                                        }}
                                    >
                                        <LazyLoadImage src='/public/html/images/mobile/live_chat-2.png' alt='' />
                                    </span>
                                    <span
                                        className='icon-box icon-box-face'
                                        onClick={() => {
                                            vm.menuClicked('profile');
                                        }}
                                    >
                                        <LazyLoadImage src='/public/html/images/mobile/personal.png' alt='' />
                                        {this.props.user.totalUnreadMessageCount > 0 && <span className='message-count'>{this.props.user.totalUnreadMessageCount}</span>}
                                    </span>
                                </div>
                            </div>
                            <div className='currency-box'>
                                <span className='currency'>{userCurrency}</span>
                                {parentState.mainWallet !== null ? (
                                    <span className='count'>{this.state.showMoneny ? window.SPL_Other.formatAmount(parentState.mainWallet.balance) : '******'}</span>
                                ) : (
                                    <SmallLoading></SmallLoading>
                                )}
                                <CSSTransition classNames='rotate360' in={parentState.mainWalletRefreshing} timeout={1000}>
                                    <span className='icon-box'>
                                        <SVGElements
                                            className='icon-reload'
                                            name='reload-icon'
                                            onClick={() => {
                                                vm.refreshWallet();
                                            }}
                                        />
                                        {/* <i
                    className="icon-reload"
                    onClick={() => { vm.refreshWallet(); }}>
                  </i> */}
                                    </span>
                                </CSSTransition>
                            </div>
                            <div className='bottom-box'>
                                <div
                                    className='restore-box'
                                    onClick={() => {
                                        vm.restoreWallet();
                                    }}
                                >
                                    <SVGElements className='icon-restore' name='restore-icon' />
                                    {/* <i className="icon-restore"></i> */}
                                    <span>{t('settings:settings.transferwallet', 'Restore Wallet')}</span>
                                </div>

                                {parentState.hasFakeSeamless && (
                                    <div className='group transfer-group'>
                                        <span className='title'>{t('global:global.menu.account.auto', 'Auto Transfer')}</span>
                                        <i
                                            className={['icon-transfer', parentState.disabledSeamless ? '' : 'on'].join(' ')}
                                            onClick={() => {
                                                vm.updateUserSeamless();
                                            }}
                                        ></i>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='nav-com'>
                            <div className='navbar-list'>
                                <ul className={['wap-funds-list', vm.getMobileMenuList().showMenuListLength > 4 ? 'scrollable' : ''].join(' ')}>
                                    {vm.getMobileMenuList().list.map(
                                        (menu, i) =>
                                            menu.showMenu && (
                                                <li
                                                    key={menu.id}
                                                    className={`${menu.selected ? 'on' : ''} ${menu?.svgPath || menu.svgIcon}`}
                                                    onClick={() => {
                                                        vm.moduleClicked(menu.path);
                                                    }}
                                                >
                                                    <p>
                                                        <span className='icon-box'>
                                                            <SVGElements name={menu.svgIcon} className={`icon-menu-item ${menu.svgIcon}`} on={menu.selected} />
                                                        </span>
                                                        <span>{t(menu.text)}</span>
                                                    </p>

                                                    {menu?.name === 'specialForYou' && promotionData?.showPromotionNewTag && (
                                                        <LazyLoadImage className='navbar-new-tag' src={`/public/html/images/promotion/navbar-new-tag.svg`} />
                                                    )}
                                                </li>
                                            )
                                    )}
                                </ul>
                            </div>
                        </div>
                        <section className='user-verification-section'>
                            <UserVerifications />
                        </section>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps, null)(withRouter(MyAccountSummary));
