import React, { lazy, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';

import { useGetCurrentTranslation } from '@hooks/general-hook';
import { popupAction } from '@redux/action';
import { SIX_PIN_SETUP_FIELD, MODULE_TYPE as MT, POPUP_TYPE, OTP_CONTACT_TYPE } from '@constants';
import { useGetOtpContactAndMethod } from '@hooks/otp-hook';
import { useSubmitSixPinSetupForm, useGetSixPinSetupFields, useSendVerification } from './set-up-pin-popup-hook';
import { useAuthSettingsSetup } from '@components//molecules/auth-settings/auth-settings';

import styles from './set-up-pin-popup.module.scss';
import OtpFormField from '@components/molecules/otp-form-field/otp-form-field';
const Text = lazy(() => import('@components/atoms/text/text'));
const Popup = lazy(() => import('@components/organisms/popup/popup'));
const PopupBody = lazy(() => import('@components/organisms/popup/popup').then((module) => ({ default: module.PopupBody })));
const PopupHeader = lazy(() => import('@components/organisms/popup/popup').then((module) => ({ default: module.PopupHeader })));
const FormField = lazy(() => import('@components/molecules/form-field/form-field'));
const Button = lazy(() => import('@components/atoms/button/button'));
const AuthOtpTabs = lazy(() => import('@components/molecules/auth-otp-tabs/auth-otp-tabs'));
const AuthOtpTab = lazy(() => import('@components/molecules/auth-otp-tabs/auth-otp-tabs').then((module) => ({ default: module.AuthOtpTab })));
const PhoneOtpMethodSelection = lazy(() => import('@components/molecules/phone-otp-method-selection/phone-otp-method-selection'));

const SetupPinPopup = () => {
    const { t } = useTranslation(['otp', 'updateMemberInfo', 'global']);
    const otpNs = 'otp:otp';
    const _popupType = POPUP_TYPE.SIX_PIN_SETUP;

    // redux
    const dispatch = useDispatch();
    const currentLang = useSelector((state) => state.language.key);
    const popupReducer = useSelector((state) => state.popupReducer);
    const authSettingsReducer = useSelector((state) => state.authSettingsReducer);
    const _sixPinAuthSettings = authSettingsReducer?.processedData?.[MT?.SIX_PIN];
    const _authMethods = _sixPinAuthSettings?.authMethod;
    // const _popupConfig = popupReducer?.config?.[_popupType];

    // // hooks
    const { fields, newPinValidationCheckers, confirmPinValidationCheckers, onInputFieldChange, handleKeyPress, setFields } = useGetSixPinSetupFields();

    const { translationData } = useGetCurrentTranslation('transaction');
    const _translationData = translationData?.sixDigitPin;
    const { preferContact, handleSetPreferContact, preferMethod, phoneVerificationMethods } = useGetOtpContactAndMethod({
        module: MT?.SIX_PIN,
    });
    const { isOtpButtonDisabled, handleSendVerification, otpCountdownTimer, isOtpRequested } = useSendVerification({
        preferMethod,
        preferContact,
        onRequestOtpFailedCb: (_errObj) => {
            // means is at ENTER_OTP step
            const _errMsg = t(`otp:otp.request.message.${_errObj?.key}`, _errObj?.message, { attemptCount: _errObj?.maxAttempt });
            setFields((prevFields) => ({
                ...prevFields,
                otp: { ...prevFields.otp, errMsg: _errMsg },
            }));
        },
    });
    const { handleSubmitSixPinSetupForm, isSubmitBtnDisabled } = useSubmitSixPinSetupForm({ fields, preferMethod, preferContact, isOtpRequested });
    // function
    const onRequestClose = () => {
        dispatch(popupAction.setPopup(_popupType, false));
    };

    // UI var

    return (
        <Popup isOpen={popupReducer?.items?.[_popupType]} className={`${styles.setupPinPopup}`} overlayClassName={styles.setupPinPopupOverlay}>
            <section className={styles.popupInnerContainer}>
                <PopupHeader
                    onRequestClose={onRequestClose}
                    popupTitle={_translationData?.setupPinTitle?.[currentLang] || 'Set Up Your 6 Digit Pin'}
                    className={styles.setupPinPopupHeader}
                />

                <PopupBody className={`${styles.setupPinPopupBody}`}>
                    <div className='standard-form-container'>
                        <FormField
                            label={_translationData?.enterPin_beta?.[currentLang] || 'Please enter your 6 digit pin code'}
                            className={styles.formField}
                            placeholder={_translationData?.newPin_beta?.[currentLang] || 'Enter your 6 Digit PIN Code'}
                            type={'password'}
                            onChange={onInputFieldChange}
                            onKeyPress={handleKeyPress}
                            name={SIX_PIN_SETUP_FIELD.NEW_PIN}
                            value={fields?.[SIX_PIN_SETUP_FIELD.NEW_PIN]?.value}
                            errorMessage={t(fields?.[SIX_PIN_SETUP_FIELD.NEW_PIN]?.errMsg, fields?.[SIX_PIN_SETUP_FIELD.NEW_PIN]?.errMsg)}
                            isMandatory={true}
                            layout={'vertical'}
                            isValidationChecker={true}
                            validationCheckers={newPinValidationCheckers}
                        />

                        <FormField
                            className={styles.formField}
                            placeholder={_translationData?.confirmPin_beta?.[currentLang] || 'Confirm your 6 Digit PIN Code'}
                            type={'password'}
                            onChange={onInputFieldChange}
                            onKeyPress={handleKeyPress}
                            name={SIX_PIN_SETUP_FIELD.CONFIRM_PIN}
                            value={fields?.[SIX_PIN_SETUP_FIELD.CONFIRM_PIN]?.value}
                            errorMessage={t(fields?.[SIX_PIN_SETUP_FIELD.CONFIRM_PIN]?.errMsg, fields?.[SIX_PIN_SETUP_FIELD.CONFIRM_PIN]?.errMsg)}
                            isMandatory={true}
                            layout={'vertical'}
                            isValidationChecker={true}
                            validationCheckers={confirmPinValidationCheckers}
                        />

                        {_sixPinAuthSettings?.otp_feature && (
                            <>
                                <div className={styles.breakPoint}></div>
                                <section className={styles.authOtpTabsSection}>
                                    <AuthOtpTabs className={styles.authOtpTabsContainer}>
                                        {(_authMethods || []).map((method) => {
                                            const { type } = method;
                                            const contactType = type && type.toLowerCase();
                                            return (
                                                <Fragment key={method.type}>
                                                    <AuthOtpTab
                                                        tabText={t(`${otpNs}.tab.${contactType}`)}
                                                        onClick={() => handleSetPreferContact(type)}
                                                        isActive={preferContact === type}
                                                    />
                                                </Fragment>
                                            );
                                        })}
                                    </AuthOtpTabs>
                                </section>

                                {preferContact === OTP_CONTACT_TYPE.PHONE && (
                                    <PhoneOtpMethodSelection
                                        selectedLabel={preferMethod || t(`${otpNs}.pleaseSelect`, 'Please Select')}
                                        className={styles.formField}
                                        phoneVerificationMethods={phoneVerificationMethods}
                                        preferMethod={preferMethod}
                                        layout={'vertical'}
                                        isMandatory={true}
                                    />
                                )}

                                <OtpFormField
                                    label={'OTP'}
                                    isMandatory={true}
                                    className={styles.formField}
                                    inputClassName={`fs-16 ${styles.formInput}`}
                                    name={SIX_PIN_SETUP_FIELD.OTP}
                                    value={fields?.[SIX_PIN_SETUP_FIELD.OTP]?.value}
                                    onChange={onInputFieldChange}
                                    disabled={isOtpButtonDisabled()}
                                    onClick={handleSendVerification}
                                    buttonText={t(`${otpNs}.button.getOtp`, 'Get OTP') + ` ${otpCountdownTimer > 0 ? otpCountdownTimer : ''}`}
                                    errMsg={fields?.[SIX_PIN_SETUP_FIELD.OTP]?.errMsg}
                                    layout='vertical'
                                />
                            </>
                        )}
                    </div>

                    <section className={styles.btnActionContainerSection}>
                        <div className={`standard-button-container ${styles.btnActionContainer}`}>
                            <Button className={`primary-btn fs-18`} onClick={handleSubmitSixPinSetupForm} disabled={isSubmitBtnDisabled()}>
                                {t('updateMemberInfo:updateMemberInfo.button.submit', 'Submit')}
                            </Button>
                            <Button className={`secondary-btn fs-18`} onClick={onRequestClose}>
                                {t('updateMemberInfo:updateMemberInfo.button.cancel', 'Cancel')}
                            </Button>
                        </div>
                    </section>
                </PopupBody>
            </section>
        </Popup>
    );
};

export default SetupPinPopup;

export const SetupSixDigitPinNotice = ({ module }) => {
    // redux
    const language = useSelector((state) => state.language);
    const authSettingsReducer = useSelector((state) => state.authSettingsReducer);
    const currentModuleAuthSettings = authSettingsReducer?.processedData?.[module];
    const screen = useSelector((state) => state.screen);

    // hooks
    const { translationData } = useGetCurrentTranslation('transaction');
    const { handleSetup } = useAuthSettingsSetup({ module: module });

    // functions
    const onHyperlinkTextClick = () => {
        handleSetup();
    };

    // UI var
    const _noticeText = currentModuleAuthSettings?.memberSixPinInfo?.sixPinLocked
        ? translationData?.sixDigitPin?.pinLockedTitleV2?.[language.key]
        : translationData?.sixDigitPin?.setSixDigitPinNoticeV2?.[language.key];

    return (
        <div className={styles.setupNoticeContainer}>
            <div className={styles.iconWarning}>
                <SVG src={'/public/html/default_whitelabel/template/images/icon/warning.svg'} />
            </div>
            <div className={`${screen?.viewType === 'mobile' ? 'fs-12' : 'fs-14'}`}>
                <Text disableDefaultSpan={true} textKey={_noticeText} components={[<span key={'1'} className={styles.hyperlinkText} onClick={onHyperlinkTextClick} />]}></Text>
            </div>
        </div>
    );
};
