import React, { Suspense } from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import SmallLoading from 'root/webapp/shared/loading/smallLoading';
import vm from '../../withdraw.controller';
import { CSSTransition } from 'react-transition-group';
import SVGElements from 'root/components/SVGElements';
import Ewallet from '../desktop/ewallet';
import { trans_handleUploadReceiptButtonOnClick } from 'root/utils/transaction-util';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BankIcon, { CryptoBankIcon } from '../../../../../../components/molecules/bank-icon/bank-icon';
import { useAuthSettingsSetup } from '@components/molecules/auth-settings/auth-settings';
import { MODULE_TYPE as MT } from '@constants';
import AuthSettings from '@components/molecules/auth-settings/auth-settings';
import AddCryptoAddressPopup from '@components/organisms/add-crypto-address-popup/add-crypto-address-popup';
import { SetupSixDigitPinNotice } from '@components/organisms/set-up-pin-popup/set-up-pin-popup';

class Withdraw extends React.Component {
    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        vm.initCompSettings().then(() => {
            vm.loadEwallet().then(() => {
                vm.initWithdrawTab();
            });
        });
        vm.getPopupAnnouncement('desktop');
        vm.getMaintenceTime();
        vm.loadCryptoWithdraw();
        vm.getMemberDepositWithdrawDecimalSettings();
        vm.getMerchantWithdrawAmountOptions();
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    _isShowUploadBankStatementField() {
        const { compSettings, displayBankList, memberBankList, selectedBankIndex } = this.state;
        const { reqBankStatementByCurrencies } = compSettings;

        let checkBankRejectedStatus =
            memberBankList &&
            memberBankList.length > 0 &&
            memberBankList.some((bank) => {
                return (
                    displayBankList &&
                    displayBankList[selectedBankIndex] &&
                    bank &&
                    bank.bankCode === displayBankList[selectedBankIndex].bankCode &&
                    bank.verifiedDisplayStatus === 'rejected'
                );
            });

        if (reqBankStatementByCurrencies) {
            if (!(displayBankList && displayBankList[selectedBankIndex] && displayBankList[selectedBankIndex].accountNumber) || checkBankRejectedStatus) {
                return true;
            } else {
                return false;
            }
        }
    }

    renderUploadBankStatementField() {
        const { t } = this.props;
        const { receiptImg, receiptImgFile } = this.state;

        return (
            <>
                {this._isShowUploadBankStatementField() && (
                    <>
                        <div>
                            {receiptImg.length > 0 && (
                                <div className='group-item input-item'>
                                    <div className='title'></div>
                                    <div className='group'>
                                        <div className='receipt-image-box'>
                                            {receiptImgFile && receiptImgFile.type === 'application/pdf' ? (
                                                <>
                                                    <label className='pdf-label'>{t('transaction:transaction.deposit.pdf-file-name', 'PDF File Name: ')}</label>
                                                    <span className='pdf-name'>{receiptImgFile.name}</span>
                                                    <img src='/public/images/icon/close-red.png' className='pdf-clear' onClick={() => vm.handleClearReceipt()} alt='' />
                                                </>
                                            ) : (
                                                <LazyLoadImage src={receiptImg} alt='' onClick={() => vm.handleClearReceipt()} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='group-item input-item'>
                            <div className='title'>
                                <span>{t('transaction:transaction.withdrawal.bankStatement', 'Bank Statement')}</span>
                                <span className='required'>*</span>
                            </div>
                            <div className='group'>
                                <div className='input-box'>
                                    {
                                        <button
                                            className='btn btn-reset'
                                            onClick={() => {
                                                trans_handleUploadReceiptButtonOnClick();
                                            }}
                                        >
                                            {t('transaction:transaction.withdrawal.upload', 'UPLOAD').toUpperCase()}
                                            <input
                                                id='hiddenBankStatementInput'
                                                style={{ display: 'none' }}
                                                type='file'
                                                accept='image/*,.pdf'
                                                onChange={(e) => vm.handleReceiptImageChange(e, this)}
                                            ></input>
                                        </button>
                                    }
                                </div>
                                <div className='error-validation'>
                                    {t('transaction:transaction.withdrawal.bankStatementRequirement2', 'Bank statement must show with full name and bank account number')}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </>
        );
    }

    render() {
        const { currency } = this.props.user.account;
        const { selectedBankIndex, hoveredIndex, withdrawalTab, ewalletList, minmaxLimit, minmaxLimitDisplay, depositSettings, receiptImg, amountOptions } = this.state;

        let userCurrency = this.props.user && this.props.user.account && this.props.user.account.currency ? this.props.user.account.currency : null;

        if (userCurrency === 'KHUSD') userCurrency = 'USD';

        const { authSettingsReducer } = this.props;
        const _authSettingsBankDetailModule = authSettingsReducer?.processedData?.[MT.WITHDRAWAL_BANK];
        const _authSettingsBankDetailEWalletModule = authSettingsReducer?.processedData?.[MT.WITHDRAWAL_EWALLET];

        const chkBankDetailSixPinStatus =
            (_authSettingsBankDetailModule?.sixPinEnabled && _authSettingsBankDetailModule?.memberSixPinInfo?.sixPinLocked) ||
            (_authSettingsBankDetailModule?.sixPinEnabled && !_authSettingsBankDetailModule?.memberSixPinInfo?.sixPinRegistered) ||
            false;

        const chkBankDetailEWalletSixPinStatus =
            (_authSettingsBankDetailEWalletModule?.sixPinEnabled && _authSettingsBankDetailEWalletModule?.memberSixPinInfo?.sixPinLocked) ||
            (_authSettingsBankDetailEWalletModule?.sixPinEnabled && !_authSettingsBankDetailEWalletModule?.memberSixPinInfo?.sixPinRegistered) ||
            false;

        return (
            <Translation>
                {(t) => (
                    <div className='withdraw-page'>
                        <AuthSettings module={MT?.CRYPTO_ADDRESS} isPublic={false} />
                        <AuthSettings module={MT?.WITHDRAWAL_CRYPTO} isPublic={false} />
                        <AuthSettings module={MT?.WITHDRAWAL_BANK} isPublic={false} />
                        <AuthSettings module={MT?.WITHDRAWAL_EWALLET} isPublic={false} />

                        <div className='group-item withdraw-option'>
                            <span className={this.state.isOnlineTransfer ? 'on' : ''} onClick={() => vm.onMethodChange('onlineTransfer', 'B')}>
                                {withdrawalTab.map((data, index) => data.code === 'B' && t(data.translation, data.id))}
                            </span>
                            {this.state.cryptoEnabled && (
                                <span className={this.state.isCrypto ? 'on' : ''} onClick={() => vm.onMethodChange('crypto', 'CT')}>
                                    {withdrawalTab.map((data, index) => data.code === 'CT' && t(data.translation, data.id))}
                                </span>
                            )}
                            {this.state.ewalletEnabled && (
                                <span className={this.state.isEwallet ? 'on' : ''} onClick={() => vm.onMethodChange('ewallet')}>
                                    {withdrawalTab.map((data, index) => data.code === 'R' && t('transaction:transaction.deposit.cashdeposit.ewallet', 'Ewallet'))}
                                </span>
                            )}
                        </div>

                        {this.state.isOnlineTransfer && (
                            <>
                                {chkBankDetailSixPinStatus && (
                                    <Suspense fallback={<div></div>}>
                                        <SetupSixDigitPinNotice module={MT.WITHDRAWAL_BANK} />
                                    </Suspense>
                                )}

                                {!chkBankDetailSixPinStatus && (
                                    <>
                                        <div className='group-item'>
                                            <div className='title'>
                                                <span>{t('transaction:transaction.withdrawal.bankOptions', 'Bank Options')}</span>
                                                <span className='required'>*</span>
                                            </div>
                                            <div className={`bank-list`}>
                                                {this.state.displayBankList && this.state.displayBankList.length > 0 ? (
                                                    <ul>
                                                        {this.state.displayBankList.map((bank, i) => (
                                                            <li
                                                                data-column={i % (currency === 'THB' ? 3 : 4)}
                                                                key={i}
                                                                className={['icon-hover-box', i === this.state.selectedBankIndex ? 'on' : '', `${currency}`].join(' ')}
                                                                onClick={() => {
                                                                    window.SPL_Other.sequenceCallback(vm.handleBankChanged.bind(vm, i))(() => {
                                                                        vm.handleValidation();
                                                                    });
                                                                }}
                                                                onMouseEnter={() => {
                                                                    this.setState({ hoveredIndex: i });
                                                                }}
                                                                onMouseLeave={() => {
                                                                    this.setState({ hoveredIndex: null });
                                                                }}
                                                            >
                                                                <span
                                                                    className={`icon-box ${i === selectedBankIndex || i === hoveredIndex ? 'icon-bank-on' : 'icon-bank-off'}`}
                                                                >
                                                                    <BankIcon bankCode={bank.bankCode} />
                                                                </span>

                                                                <span>{bank.shortBankNameOnly ? bank.shortBankNameOnly : bank.bankNameOnly}</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                ) : (
                                                    <SmallLoading></SmallLoading>
                                                )}
                                            </div>
                                        </div>

                                        {this.state.showBankName && (
                                            <div className='group-item input-item'>
                                                <div className='title'>
                                                    <span>{t('bank:bank.newBank.bankName', 'Bank Name')}</span>
                                                    <span className='required'>*</span>
                                                </div>
                                                <div className='group'>
                                                    <div className='input-box'>
                                                        <input
                                                            type='text'
                                                            placeholder={t('bank:bank.newBank.bankName', 'Bank Name')}
                                                            value={this.state.fields['bankName']}
                                                            onChange={(e) => vm.handleFieldChanged(e, 'bankName')}
                                                            onBlur={() => vm.handleValidation()}
                                                            disabled={this.state.disabledFields['bankName']}
                                                        ></input>
                                                    </div>
                                                    <div className='error-validation'>{t(this.state.errors['bankName'])}</div>
                                                </div>
                                            </div>
                                        )}

                                        <div className='group-item input-item'>
                                            <div className='title'>
                                                <span>{t('bank:bank.newBank.accountName', 'Account Name')}</span>
                                                <span className='required'>*</span>
                                            </div>
                                            <div className='group'>
                                                <div className='input-box'>
                                                    <input type='text' value={this.state.accountName} disabled={true}></input>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='group-item input-item'>
                                            <div className='title'>
                                                <span>{t('transaction:transaction.withdrawal.accountNumber', 'Account Number')}</span>
                                                <span className='required'>*</span>
                                            </div>
                                            <div className='group'>
                                                <div className='input-box'>
                                                    <input
                                                        type='text'
                                                        placeholder='Exp : 1234567'
                                                        value={this.state.fields['accountNumber']}
                                                        onChange={(e) => vm.handleFieldChanged(e, 'accountNumber')}
                                                        onBlur={() => vm.handleValidation()}
                                                        disabled={this.state.disabledFields['bankName']}
                                                    ></input>
                                                </div>
                                                <div className='error-validation'>{t(this.state.errors['accountNumber'])}</div>
                                            </div>
                                        </div>
                                        {/****** Bank Statement *******/}
                                        {this.renderUploadBankStatementField()}

                                        <div className='group-item input-item'>
                                            <div className='title'>
                                                <span>{t('transaction:transaction.withdrawal.amount', 'Amount')}</span>
                                                <span className='required'>*</span>
                                            </div>
                                            <div className='group'>
                                                <div className='input-box'>
                                                    {this.state.minmaxLimit ? (
                                                        <input
                                                            type='numeric'
                                                            min='0'
                                                            value={this.state.fields['amount']}
                                                            onChange={(e) => vm.handleAmountChange(e, 'amount')}
                                                            onBlur={() => vm.handleValidation()}
                                                            placeholder={t('global:global.form.amount-placeholder', {
                                                                min: this.state.minmaxLimit ? this.state.minmaxLimit.minLimitDisplay : '',
                                                                max: this.state.minmaxLimit ? this.state.minmaxLimit.maxLimitDisplay : '',
                                                            })}
                                                        ></input>
                                                    ) : (
                                                        <SmallLoading></SmallLoading>
                                                    )}
                                                </div>
                                                <div className='error-validation'>{t(this.state.errors['amount'])}</div>
                                            </div>
                                        </div>

                                        <button
                                            className='btn-submit'
                                            disabled={
                                                this.state.disableWithdrawButton ||
                                                !this.state.isFormValid ||
                                                (this._isShowUploadBankStatementField() && receiptImg.length <= 0)
                                            }
                                            onClick={() => vm.submitForm()}
                                        >
                                            {t('transaction:transaction.withdrawal.withdraw', 'Withdraw').toUpperCase()}
                                        </button>

                                        <div className='notice-box'>
                                            <span className='item-title'>{t('transaction:transaction.important-notice', 'IMPORTANT NOTICE')}</span>
                                            <div className='display-info'>
                                                <span className='key'>{t('transaction:transaction.deposit.onlinetransfer.min/maxlimit', 'Min/Max Limit')}</span>
                                                <span className='value'>
                                                    {this.state.minmaxLimit === null ? (
                                                        <SmallLoading></SmallLoading>
                                                    ) : (
                                                        userCurrency +
                                                        ' ' +
                                                        window.SPL_Other.formatAmount(this.state.minmaxLimitDisplay.minAmountDisplay) +
                                                        '/' +
                                                        window.SPL_Other.formatAmount(this.state.minmaxLimitDisplay.maxAmountDisplay)
                                                    )}
                                                </span>
                                            </div>
                                            <div className='display-info'>
                                                <span className='key'>{t('transaction:transaction.withdrawal.dailyLimitBalance', 'Daily Limit Balance')}</span>
                                                <span className='value'>
                                                    {this.state.limit === null ? (
                                                        <SmallLoading></SmallLoading>
                                                    ) : (
                                                        userCurrency + ' ' + window.SPL_Other.formatAmount(this.state.limit.dailyMaxAmount)
                                                    )}
                                                </span>
                                            </div>
                                            <div className='display-info'>
                                                <span className='key'>{t('transaction:transaction.withdrawal.dailyCountBalance', 'Daily Count Balance')}</span>
                                                <span className='value'>{this.state.limit === null ? <SmallLoading></SmallLoading> : this.state.limit.maxCount}</span>
                                            </div>
                                            <ul>
                                                <li>
                                                    <p>{t('transaction:transaction.withdrawal.note_1')}</p>
                                                </li>
                                                <li>
                                                    <p>{t('transaction:transaction.withdrawal.note_2')}</p>
                                                </li>
                                                <li>
                                                    <p>{t('transaction:transaction.withdrawal.note_3')}</p>
                                                </li>
                                                <li>
                                                    <p>{t('transaction:transaction.withdrawal.note_4')}</p>
                                                </li>
                                            </ul>
                                            <div className='display-info'>{t('transaction:transaction.withdrawal.note_5')}</div>
                                        </div>
                                    </>
                                )}
                            </>
                        )}

                        {this.state.isCrypto && (
                            <>
                                <div className='group-item crypto-bank-option'>
                                    <div className='title'>
                                        <span>{t('transaction:transaction.withdrawal.bankOptions', 'Bank Options')}</span>
                                        <span className='required'>*</span>
                                    </div>
                                    <div className={`bank-list`}>
                                        {this.state.cryptoChain && this.state.cryptoChain.length > 0 && (
                                            <ul>
                                                {this.state.cryptoChain &&
                                                    this.state.cryptoChain.length > 0 &&
                                                    this.state.cryptoChain.map((chain, i) => (
                                                        <li
                                                            key={i}
                                                            data-column={i}
                                                            className={['icon-hover-box', this.state.selectedChainIndex === i ? 'on' : ''].join(' ')}
                                                            onClick={() => {
                                                                vm.onChainChanged(chain);
                                                            }}
                                                            onMouseEnter={() => {
                                                                this.setState({ hoveredIndex: i });
                                                            }}
                                                            onMouseLeave={() => {
                                                                this.setState({ hoveredIndex: null });
                                                            }}
                                                        >
                                                            <span className='icon-box'>
                                                                <i
                                                                    className={[
                                                                        'icon-bank',
                                                                        this.state.selectedChainIndex === i || (this.state.hoveredIndex && this.state.hoveredIndex === i)
                                                                            ? 'icon-bank-on'
                                                                            : 'icon-bank-off',
                                                                    ].join(' ')}
                                                                >
                                                                    <CryptoBankIcon bankCode={`${chain.coin}_${chain.network}`} coin={chain.coin} />
                                                                </i>
                                                            </span>
                                                            <span>{chain.displayName}</span>
                                                        </li>
                                                    ))}
                                            </ul>
                                        )}
                                    </div>
                                </div>

                                <div className='group-item input-item item-rate'>
                                    <div className='title'></div>
                                    <div className='amount-content'>
                                        <div className='input-box box-info'>
                                            <p className='info-currentRate'>{t('transaction:transaction.deposit.crypto.currentRate', 'Current exchange rate')} : </p>
                                            <div className='box-chainRate'>
                                                {this.state.chainName !== 'BTC' && <p>CHAIN {this.state.chainBranch}</p>}
                                                <p className='info-rate'>
                                                    {this.state.chainName} 1 = {userCurrency} {this.state.cryptoExchangeRate.toFixed(2)}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='input-box box-guaranteed'>
                                            <p className='info-chain'>{t('transaction:transaction.deposit.crypto.guaranteedUntil', 'Guaranteed until')}</p>
                                            <p className='info-time'>: {this.state.guaranteedUntilTime}</p>
                                            {this.state.getlatestRate && (
                                                <span className='reload-box'>
                                                    <CSSTransition classNames='rotate360' in={this.state.reload} timeout={1000}>
                                                        <SVGElements className='icon-reload' name='reload-icon' onClick={() => vm.onReload()} />
                                                    </CSSTransition>
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                {this.state.cryptoOptions && this.state.cryptoOptions.length > 1 && (
                                    <div className='group-item input-item crypto-item'>
                                        <div className='title'>
                                            <span>{t('transaction:transaction.withdrawal.channel', 'Withdraw Channel')}</span>
                                            <span className='required'>*</span>
                                        </div>
                                        <div className='amount-content'>
                                            <div className='input-box crypto-box'>
                                                <select onChange={(e) => vm.changeCryptoChannel(e)}>
                                                    {this.state.cryptoOptions.map((channel, index) => (
                                                        <option key={index} value={index}>
                                                            {channel.displayName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className='group-item input-item crypto-item'>
                                    <div className='title'>
                                        <span>{t('transaction:transaction.deposit.crypto.address', 'Address')}</span>
                                        <span className='required'>*</span>
                                    </div>
                                    <div className='amount-content'>
                                        <div className='input-box address-box'>
                                            <select onChange={(e) => vm.handleMemberAddressChange(e.target.value)}>
                                                {this.state.memberAddressOption && this.state.memberAddressOption.length > 0 ? (
                                                    this.state.memberAddressOption.map((data, index) => (
                                                        <option key={index} value={index}>
                                                            {data.address}
                                                        </option>
                                                    ))
                                                ) : (
                                                    <option>{t('settings:settings.dropdown.pleaseselect')}</option>
                                                )}
                                            </select>

                                            {depositSettings && !depositSettings.hideAddBankAccount && (
                                                <AddCryptoAddressButton onClick={() => vm.getAddressModal(true)} text={t('global:entity.action.add', 'Add')} />
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className='group-item input-item crypto-item'>
                                    <div className='title'>
                                        <span>
                                            {t('transaction:transaction.withdrawal.amount', 'Amount')} ({userCurrency})
                                        </span>
                                        <span className='required'>*</span>
                                    </div>
                                    <div className='group'>
                                        <div className='input-box amount-box'>
                                            <input
                                                type='numeric'
                                                placeholder={
                                                    this.state.cryptoMinmaxLimit &&
                                                    t('global:global.form.amount-placeholder', {
                                                        min: this.state.cryptoMinmaxLimit && this.state.cryptoMinmaxLimit.minLimitDisplay,
                                                        max: this.state.cryptoMinmaxLimit && this.state.cryptoMinmaxLimit.maxLimitDisplay,
                                                    })
                                                }
                                                min='0'
                                                value={this.state.currencyAmount}
                                                onChange={(e) => vm.handleCryptoCurrencyAmountChange(e.target.value)}
                                            ></input>
                                        </div>

                                        <ul>
                                            {amountOptions?.map((item) => (
                                                <li
                                                    key={item}
                                                    onClick={() => {
                                                        vm.handleCryptoCurrencyAmountChange(item);
                                                    }}
                                                >
                                                    {item}
                                                </li>
                                            ))}
                                        </ul>

                                        {this.state.remindMinAmount ? (
                                            <p className='remind-text'>{this.state.remindMinAmount}</p>
                                        ) : this.state.cryptoFeeCharge && this.state.cryptoFeeCharge != 0 ? (
                                            <p className='refill-text'>
                                                {t('transaction:transaction.deposit.crypto.cryptoFeeChargeMsg', {
                                                    chain: this.state.chainName,
                                                    feeCharge: this.state.cryptoFeeCharge,
                                                })}
                                            </p>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>

                                <div className='group-item input-item crypto-item'>
                                    <div className='title'>
                                        <span>
                                            {t('transaction:transaction.withdrawal.amount', 'Amount')} ({this.state.chainName})
                                        </span>
                                        <span className='required'>*</span>
                                    </div>
                                    <div className='group'>
                                        <div className='input-box amount-box'>
                                            <input
                                                type='numeric'
                                                placeholder={this.state.chainName}
                                                min='0'
                                                value={this.state.cryptoAmount}
                                                onChange={(e) => vm.handleCryptoAmountChange(e.target.value)}
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                                <CryptoSubmitButton
                                    disabledWithdrawCryptoButton={this.state.disabledWithdrawCryptoButton}
                                    text={t('transaction:transaction.withdrawal.withdraw', 'Withdraw').toUpperCase()}
                                />
                                {/* <button className='btn-submit' disabled={this.state.disabledWithdrawCryptoButton} onClick={() => cryptoWithdrawSubmitOnclick()}>
                                    {t('transaction:transaction.withdrawal.withdraw', 'Withdraw').toUpperCase()}
                                </button> */}

                                <div className='text-feedback'>
                                    {this.state.withdrawSuccessMsg && <p className='text-success'>{t(this.state.withdrawSuccessMsg)}</p>}
                                    {this.state.withdrawErrMsg && <p className='text-error'>{t(this.state.withdrawErrMsg)}</p>}
                                </div>

                                {this.state.withdrawChainRemark && this.state.withdrawChainRemark.length > 0 && (
                                    <div className='notice-box '>
                                        <span className='item-title'>{t('transaction:transaction.deposit.crypto.reminder', 'REMINDER')} :</span>
                                        <ul className='crypto-remark'>
                                            {this.state.withdrawChainRemark.map((info, index) => (
                                                <li key={index}>{info}</li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </>
                        )}

                        {this.state.isEwallet && (
                            <>
                                {chkBankDetailEWalletSixPinStatus && (
                                    <Suspense fallback={<div></div>}>
                                        <SetupSixDigitPinNotice module={MT.WITHDRAWAL_EWALLET} />
                                    </Suspense>
                                )}

                                {!chkBankDetailEWalletSixPinStatus && (
                                    <>
                                        <Ewallet
                                            amountOptions={amountOptions}
                                            submitForm={vm.submitForm}
                                            ewalletList={ewalletList}
                                            minmaxLimitDisplay={minmaxLimitDisplay}
                                            minmaxLimit={minmaxLimit}
                                            depositSettings={depositSettings}
                                            ewalletDisplayBank={this.state.ewalletDisplayBank}
                                        />
                                    </>
                                )}
                            </>
                        )}

                        {this.state.addressModal && (
                            <AddCryptoAddressPopup
                                isOpen={this.state.addressModal}
                                onRequestClose={() => vm.getAddressModal(false)}
                                onCryptoAddressPopupSubmitSuccessCb={() => vm.getCryptoAddress()}
                            />

                            // moved to AddCryptoAddressPopup
                            // <div
                            //     className='addAddress-box'
                            //     onClick={() => {
                            //         vm.getAddressModal(false);
                            //     }}
                            // >
                            //     <div
                            //         className='addAddress-popup'
                            //         onClick={(e) => {
                            //             e.stopPropagation();
                            //         }}
                            //     >
                            //         <div className='title'>
                            //             <span>{t('transaction:transaction.deposit.crypto.addAddress', 'Add Crypto Address')}</span>
                            //             <i
                            //                 className='icon-close3'
                            //                 onClick={() => {
                            //                     vm.getAddressModal(false);
                            //                 }}
                            //             ></i>
                            //         </div>
                            //         <div className='content addAddress-content'>
                            //             <div className='input'>
                            //                 <div>
                            //                     <p className='item-title'>
                            //                         {this.state.showCurrencyAndCoin
                            //                             ? t('transaction:transaction.deposit.crypto.cryptoCurrency', 'Crypto Currency')
                            //                             : t('transaction:transaction.deposit.crypto.cryptoCoin', 'Coin')}
                            //                         <span className='required'>*</span>
                            //                     </p>
                            //                     <select
                            //                         className='coin'
                            //                         onChange={(e) => {
                            //                             vm.handleCryptoCurrencyChange(e);
                            //                         }}
                            //                     >
                            //                         {this.state.addressOption.map((option, i) => (
                            //                             <option key={i} value={option.displayName}>
                            //                                 {t(option.displayName)}
                            //                             </option>
                            //                         ))}
                            //                     </select>
                            //                 </div>
                            //             </div>

                            //             <div className='input'>
                            //                 <div>
                            //                     <p className='item-title'>
                            //                         {t('transaction:transaction.deposit.crypto.network', 'Network')}
                            //                         <span className='required'>*</span>
                            //                     </p>
                            //                     <select
                            //                         className='network'
                            //                         onChange={(e) => {
                            //                             vm.handleCryptoNetworkChange(e);
                            //                         }}
                            //                     >
                            //                         {this.state.networkOption && this.state.networkOption.length > 0 ? (
                            //                             this.state.networkOption.map((option, i) => (
                            //                                 <option key={i} value={option.displayName}>
                            //                                     {t(option.displayName)}
                            //                                 </option>
                            //                             ))
                            //                         ) : (
                            //                             <option>{t('transaction:transaction.deposit.crypto.selectNetwork')}</option>
                            //                         )}
                            //                     </select>
                            //                 </div>
                            //             </div>

                            //             <div className='input'>
                            //                 <div>
                            //                     <p className='item-title'>
                            //                         {t('transaction:transaction.deposit.crypto.address', 'Address')}
                            //                         <span className='required'>*</span>
                            //                     </p>
                            //                     <input
                            //                         className='text-area'
                            //                         type='text'
                            //                         value={this.state.address}
                            //                         name='address'
                            //                         placeholder={t('transaction:transaction.deposit.crypto.address', 'Address')}
                            //                         onChange={vm.handleAddressChange}
                            //                     />
                            //                 </div>
                            //             </div>

                            //             {this.props.portal.settings.addCryptoAddressRequireOTP && (
                            //                 <div className='input otp-input'>
                            //                     <div>
                            //                         <p className='item-title'>
                            //                             OTP
                            //                             <span className='required'>*</span>
                            //                         </p>
                            //                         <div>
                            //                             <input
                            //                                 className='text-area'
                            //                                 name='otp'
                            //                                 type='number'
                            //                                 autoComplete='off'
                            //                                 onKeyDown={(evt) => ['e', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                            //                                 debounceTimeout={1000}
                            //                                 value={this.state.otpCode}
                            //                                 placeholder={t('global:global.verification.enterOTPCode')}
                            //                                 onChange={vm.handleOtpChange}
                            //                                 // onBlur={vm.validateOtp}
                            //                             />
                            //                             <button disabled={this.state.disableOtpBtn} onClick={() => vm.sendOtpCode()}>
                            //                                 {this.state.submittedOTP === true ? (
                            //                                     this.state.countdownTimer > 0 ? (
                            //                                         <span>{this.state.countdownTimer}s</span>
                            //                                     ) : (
                            //                                         <span>{t('global:global.verification.resend')}</span>
                            //                                     )
                            //                                 ) : (
                            //                                     <span>{t('register:register.form.getCode')}</span>
                            //                                 )}
                            //                             </button>
                            //                         </div>
                            //                     </div>
                            //                 </div>
                            //             )}

                            //             <div className='input-error'>{t(this.state.addAddressErrMsg)}</div>

                            //             <button className='btn-submit' disabled={this.state.cryptoAddressDisable} onClick={() => vm.handleCryptoAddressSubmit()}>
                            //                 {t('transaction:transaction.history.filter.submit', 'Submit').toUpperCase()}
                            //             </button>
                            //         </div>
                            //     </div>
                            // </div>
                        )}
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'bank', 'inputHelpBlock', 'register', 'error'])(withRouter(Withdraw)));

export const AddCryptoAddressButton = ({ onClick, text }) => {
    const { handleSetup } = useAuthSettingsSetup({ module: MT?.CRYPTO_ADDRESS, callback: onClick });
    const onAddBtnClick = () => {
        handleSetup();
    };

    return (
        <span className='btn-addnewbank' onClick={onAddBtnClick}>
            {text}
        </span>
    );
};

const CryptoSubmitButton = ({ disabledWithdrawCryptoButton, text }) => {
    const { handleSetup } = useAuthSettingsSetup({ module: MT?.WITHDRAWAL_CRYPTO, callback: vm.handleWithdrawCryptoSubmit });

    const cryptoWithdrawSubmitOnclick = () => {
        handleSetup();
    };
    return (
        <button className='btn-submit' disabled={disabledWithdrawCryptoButton} onClick={cryptoWithdrawSubmitOnclick}>
            {text}
        </button>
    );
};
