export default class Device {
    isApp;
    version;
    webkit;
    notchScreen;
    phone = {
        iphonex: false,
        iphonexr: false,
        iphonexsmax: false,
    };
    browser = {
        chrome: false,
        safari: false,
        firefox: false,
        ie: false,
        edge: false,
        opera: false,
        qq: false,
        360: false,
        huawei: false,
    };

    mobile = {
        device: false,
        ios: false,
        andriod: false,
        tablet: false,
    };

    constructor() {
        this.isApp = /PKQ$/.test(navigator.userAgent);
        this.mobile = this.initMobileData();
        this.version = this.getVersion();
        this.webkit = this.iosWKWebView();
        this.notchScreen = this.isNotchScreen();
        this.browser = this.initBrowserData();
    }

    initMobileData() {
        let ua = navigator.userAgent;
        let andriod = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1;
        let ipadPro = !!/macintosh/.test(ua.toLowerCase()) && 'ontouchend' in document;
        let ios = !!/iphone|ipad|ipod/.test(ua.toLowerCase()) || ipadPro;
        let tablet = /(?:iPad|PlayBook)/.test(ua) || (andriod && !/(?:Mobile)/.test(ua)) || (/(?:Firefox)/.test(ua) && /(?:Tablet)/.test(ua)) || ipadPro;
        let device = andriod || ios || tablet;

        return {
            ios: ios,
            andriod: andriod,
            tablet: tablet,
            device: device,
        };
    }

    getVersion() {
        let verData = navigator.userAgent.toLocaleLowerCase().match(/os [\d._]*/gi);
        let version = (verData + '').replace(/[^0-9|_.]/gi, '').replace(/_/gi, '.');
        return version;
    }

    iosWKWebView() {
        return this.mobile.ios && navigator.userAgent.indexOf('Safari') == -1;
    }

    isNotchScreen() {
        let ios = this.mobile.ios,
            width = window.screen.width,
            height = window.screen.height;
        this.phone.iphonex = ios && width == 375 && height == 812 && devicePixelRatio == 3; //iphoneX and iphoneXs
        this.phone.iphonexr = ios && width == 414 && height == 896 && devicePixelRatio == 2;
        this.phone.iphonexsmax = ios && width == 414 && height == 896 && devicePixelRatio == 3;
        return this.phone.iphonex || this.phone.iphonexr || this.phone.iphonexsmax;
    }

    initBrowserData() {
        let ua = navigator.userAgent;

        let brower = {
            chrome: ua.indexOf('Chrome') > -1 && ua.indexOf('Safari') > -1,
            safari: ua.indexOf('Safari') > -1 && ua.indexOf('Chrome') == -1,
            firefox: ua.indexOf('Firefox') > -1,
            ie: !!window.ActiveXObject || 'ActiveXObject' in window,
            edge: ua.indexOf('Edge') > -1,
            opera: ua.indexOf('Opera') > -1,
            qq: ua.indexOf('qqbrowse') > -1,
            huawei: /huawei/i.test(ua),
            360: ((option, value) => {
                let mimeTypes = navigator.mimeTypes;
                for (let mt in mimeTypes) {
                    if (mimeTypes[mt][option] == value) {
                        return true;
                    }
                }
                return false;
            })('type', 'application/vnd.chromium.remoting-viewer'),
        };

        if (this.mobile.ios) {
            brower.chrome = /C\w+OS\//.test(ua);
            brower.firefox = /F\w+OS\//.test(ua);
            brower.safari = !brower.chrome && !brower.firefox;
        }

        return brower;
    }

    /**
     * 操作系统信息，后端收集
     */
    osName() {
        let osName = 'Unknow',
            version = '',
            uaResult = [];
        let userAgent = navigator.userAgent,
            appVersion = navigator.appVersion,
            platform = navigator.platform;
        if (this.mobile.andriod) {
            uaResult = /android (\d+(?:\.\d+)+)/i.exec(userAgent) || /android (\d+(?:\.\d+)+)/i.exec(platform);
            version = uaResult ? uaResult[1] : '';
        }
        if (this.mobile.ios) {
            uaResult = /(iPad|iPhone|iPod).*OS ((\d+_?){2,3})/i.exec(userAgent);
            version = uaResult ? uaResult[2] : '';
        }

        if (this.getWinOSName()) {
            osName = this.getWinOSName();
        } else if (this.mobile.ios) {
            osName = 'iOS_' + version;
        } else if (appVersion.indexOf('Mac') !== -1) {
            osName = 'OS X_';
        } else if (appVersion.indexOf('X11') !== -1 && appVersion.indexOf('Linux') === -1) {
            osName = 'OS X';
        } else if (this.mobile.andriod) {
            osName = 'Android_' + version;
        } else if (appVersion.indexOf('Linux') !== -1) {
            osName = 'Linux';
        }

        return osName;
    }

    getWinOSName() {
        let isWinPlatform = navigator.appVersion.indexOf('Win') != -1 && /Win(?:dows )?([^do]{2})\s?(\d+\.\d+)?/.test(navigator.userAgent) && RegExp['$1'] == 'NT';

        if (isWinPlatform) {
            switch (RegExp['$2']) {
                case '5.1':
                    return 'WindowsXP';
                case '6.0':
                    return 'WindowsVista';
                case '6.1':
                    return 'Windows7';
                case '6.2':
                    return 'Windows8';
                case '10.0':
                    return 'Windows10';
                default:
                    return 'NT';
            }
        }
        return '';
    }

    /**
     * 操作系统和浏览器及版本信息，后端收集
     */
    deviceInfo() {
        let ua = navigator.userAgent.toLowerCase();
        let browserType = '';
        if (this.browser.ie) {
            browserType = 'IE-' + parseFloat(RegExp['$1']);
        } else if (this.browser.firefox) {
            let info = ua.match(/firefox\/([\d.]+)/);
            if (info) {
                browserType = 'Firefox' + '-' + info[1];
            } else {
                browserType = 'Firefox' + '-' + ua.match(/f\w+os\/([\d.]+)/)[1];
            }
        } else if (this.browser.opera) {
            browserType = 'Opera' + '-' + ua.match(/opera.([\d.]+)/)[1];
        } else if (this.browser.chrome) {
            let info = ua.match(/chrome\/([\d.]+)/);
            if (info) {
                browserType = 'Chrome' + '-' + info[1];
            } else {
                browserType = 'Chrome' + '-' + ua.match(/c\w+os\/([\d.]+)/)[1];
            }
        } else if (this.browser.edge) {
            browserType = 'Edge';
        } else if (this.browser.safari) {
            if (this.mobile.device) browserType = 'Safari';
            else browserType = 'Safari' + ua.match(/version\/([\d.]+)/)[1];
        }
        return this.osName + ' ' + browserType;
    }

    winHeight() {
        if (!this.mobile.device) {
            return window.innerHeight;
        } else {
            if (this.mobile.andriod) {
                return window.screen.height;
            } else {
                return window.innerHeight;
            }
        }
    }

    landscape() {
        if (this.isMobileLandScape()) {
            return true;
        }
        return false;
    }

    fullDisplay() {
        let { width, height } = window.screen;
        if (this.mobile.ios) {
            [height, width] = [width, height];
        }
        if (typeof orientation !== 'undefined' && width / height >= 2) {
            return true;
        }
        return false;
    }

    getChannel() {
        if (this.isApp) {
            if (this.mobile.andriod) {
                return 'App-a';
            } else {
                return 'App-i';
            }
        } else {
            if (this.mobile.device) return 'mobile';
            else return 'web';
        }
    }

    isMobileLandScape() {
        let screen = window.screen;

        let landscapeExpression1 = window.orientation == 90 || window.orientation == -90;
        let landscapeExpression2 = this.mobile.andriod && screen && window.screen.orientation && window.screen.orientation.type.indexOf('landscape') > -1;

        return landscapeExpression1 || landscapeExpression2;
    }
}
