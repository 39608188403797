import { screenConstants } from '../constant';
import { isMobile } from 'react-device-detect';

export const screenAction = {
    updateScreenSize,
};

function updateScreenSize(width, height, prevMobile) {
    return (dispatch) => {
        let isMobileScreen = false;
        let viewType = 'web';
        let viewMode = window.SPL_Cookie.getCookie('viewMode');

        if (width <= 768 && viewMode !== 'web') {
            isMobileScreen = true;
        } else {
            isMobileScreen = false;
        }

        if (viewMode === 'mobile') {
            viewType = 'mobile';
        }

        setTimeout(() => {
            if (prevMobile !== isMobileScreen) {
                window.location.reload();
            } else {
                dispatch({
                    type: screenConstants.UPDATE_SCREEN_SIZE,
                    payload: { width, height, isMobile: isMobileScreen, viewType, isMobileAgent: isMobile },
                });
            }
        }, 300);
    };
}
