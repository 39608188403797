import React from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import Slider from 'root/webapp/shared/component/Slider';
import { TweenMax } from 'gsap';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import vm from '../poker.controller';

const aniList = [];
const pokerIcon = [];

class Poker extends React.Component {
    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        setTimeout(() => {
            vm.getPokerBannerElement().then((data) => {
                pokerIcon.length = 0;

                for (let i = 0; i < data.length; i++) {
                    pokerIcon.push(data[i]);
                }
            });
        }, 500);
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    // animation effect
    doSuspendAni(index) {
        aniList.forEach((item) => item.kill());
        aniList.length = 0;
        const idList = pokerIcon.map((item, j) => `#suspend_${index}_${j}`);

        const endStyleStore = [];
        const randomItem = (list) => {
            return list[0 + Math.round(Math.random() * (list.length - 1 - 0))];
        };

        TweenMax.delayedCall(0.02, () => {
            idList.map((id, k) => {
                const $item = document.querySelector(id);
                endStyleStore[k] = {
                    left: getComputedStyle($item).left,
                    bottom: getComputedStyle($item).bottom,
                };

                TweenMax.set(id, {
                    x: -parseFloat(getComputedStyle($item).width) / 2,
                    y: [2, 3, 4, 10].includes(index) ? '100%' : 0,
                    bottom: 0,
                    left: '50%',
                    opacity: 0,
                    rotation: randomItem([-80, -40, 40, 80, 100]),
                    onComplete: () => {
                        TweenMax.set(id, {
                            left: getComputedStyle($item).left,
                        });

                        TweenMax.to(id, 0.8, {
                            x: 0,
                            y: 0,
                            left: endStyleStore[k].left,
                            bottom: endStyleStore[k].bottom,
                            opacity: 1,
                            rotation: 0,
                            delay: 0.2 * k + 0.5,
                            onComplete: () => {
                                TweenMax.set(id, { left: '', bottom: '' });
                                if (k == idList.length - 1) {
                                    idList.map((id) => {
                                        aniList.push(
                                            TweenMax.to(id, randomItem([5, 6, 7]), {
                                                x: `${randomItem(['+', '-'])}=${randomItem([5, 15, 25])}`,
                                                y: `${randomItem(['+', '-'])}=${randomItem([5, 15, 25])}`,
                                                rotation: randomItem([15, 10, -10, -5]),
                                                yoyo: true,
                                                repeat: -1,
                                            })
                                        );
                                    });
                                }
                            },
                        });
                    },
                });
            });
        });
    }

    render() {
        let pokerIntro = [
            {
                key: 'biggest',
                icon: '/public/html/images/poker/icon-top/icon-1.png',
                content: 'poker:poker.content-intro1',
            },
            {
                key: 'monthly_active',
                icon: '/public/html/images/poker/icon-top/icon-2.png',
                content: 'poker:poker.content-intro2',
            },
            {
                key: 'asian',
                icon: '/public/html/images/poker/icon-top/icon-3.png',
                content: 'poker:poker.content-intro3',
            },
            {
                key: 'variety',
                icon: '/public/html/images/poker/icon-top/icon-4.png',
                content: 'poker:poker.content-intro4',
            },
            {
                key: 'currency',
                icon: '/public/html/images/poker/icon-top/icon-5.png',
                content: 'poker:poker.content-intro5',
            },
        ];

        let pokerDetails = [
            {
                key: 'tournament',
                icon: '/public/html/images/poker/icon-mid/icon-1.png',
                title: 'poker:poker.title-tournament',
                content: 'poker:poker.content-tournament',
            },
            {
                key: 'poker',
                icon: '/public/html/images/poker/icon-mid/icon-4.png',
                title: 'poker:poker.title-poker',
                content: 'poker:poker.content-poker',
            },
            {
                key: 'jackpot',
                icon: '/public/html/images/poker/icon-mid/icon-2.png',
                title: 'poker:poker.title-jackpot',
                content: 'poker:poker.content-jackpot',
            },
            {
                key: 'coins',
                icon: '/public/html/images/poker/icon-mid/icon-5.png',
                title: 'poker:poker.title-coins',
                content: 'poker:poker.content-coins',
            },
            {
                key: 'table',
                icon: '/public/html/images/poker/icon-mid/icon-3.png',
                title: 'poker:poker.title-table',
                content: 'poker:poker.content-table',
            },
            {
                key: 'games',
                icon: '/public/html/images/poker/icon-mid/icon-6.png',
                title: 'poker:poker.title-games',
                content: 'poker:poker.content-games',
            },
        ];

        return (
            <Translation>
                {(t) => (
                    <div className='poker-panel'>
                        {this.state.bannerIcon && this.state.list.length > 0 && (
                            <Slider autoPlay={false} onChange={this.doSuspendAni} items={this.state.list} forceStart={true} />
                        )}

                        <div className='poker-intro'>
                            {pokerIntro.map(({ key, icon, content }) => (
                                <div key={key} className={'intro-box box-' + key}>
                                    <LazyLoadImage src={icon} />
                                    <span dangerouslySetInnerHTML={{ __html: t(content) }}></span>
                                </div>
                            ))}
                        </div>

                        <div className='poker-content'>
                            <div className='poker-details'>
                                <LazyLoadImage src='/public/html/images/poker/icon-mid/banner.png' />

                                <div className='details-box'>
                                    {pokerDetails.map(({ key, icon, title, content }) => (
                                        <div key={key} className={'box-' + key}>
                                            <LazyLoadImage src={icon} />

                                            <div className='text-box'>
                                                <p>{t(title)}</p>
                                                <span dangerouslySetInnerHTML={{ __html: t(content) }}></span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className='poker-device'>
                                <div className='device-details'>
                                    <p className='title-html' dangerouslySetInnerHTML={{ __html: t('poker:poker.title-html') }}></p>
                                    <span className='content-html'>{t('poker:poker.content-html')}</span>
                                    <p className='title-go' dangerouslySetInnerHTML={{ __html: t('poker:poker.title-goMobile') }}></p>
                                    <span className='content-go text-notice'>{t('poker:poker.content-goMobile')}</span>
                                    <span className='details-go'>{t('poker:poker.details-goMobile')}</span>

                                    <div className='device-platform'>
                                        <LazyLoadImage className='platform-ios' src='/public/html/images/poker/icon-bottom/icon-1.png' />
                                        <LazyLoadImage className='platform-android' src='/public/html/images/poker/icon-bottom/icon-2.png' />
                                        <LazyLoadImage className='platform-window' src='/public/html/images/poker/icon-bottom/icon-3.png' />
                                    </div>
                                </div>

                                <LazyLoadImage src='/public/html/images/poker/icon-bottom/banner.png' />
                            </div>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation('poker')(withRouter(Poker)));
