const image = {
    homeSmallBanner: '/public/html/images/home_img/home-bottom-banner.jpg',
    announcementIconImg: '/public/html/images/common_img/icon_home_notice.png',
    hotIcon: '/public/html/images/home_img/icon-hot.png',
    newIcon: '/public/html/images/home_img/icon-new.png',
    pagcorIcon: '/public/html/images/footer/pagcor_hover.png',
    ezpIcon: '/public/html/images/footer/ftr_pay_eezie_ov.png',
    ssIcon: '/public/html/images/footer/superswan_ov.png',
    chromeFooterIcon: '/public/html/images/footer/ftr_browser_chrome_ov.png',
    ieFooterIcon: '/public/html/images/footer/ftr_browser_ie_ov.png',
    firefoxIcon: '/public/html/images/footer/ftr_browser_ff_ov.png',
    specialPromoCodeValid: '/public/html/images/icon/icon-checked.png',
    specialPromoCodeInvalid: '/public/html/images/icon/icon-invalid.png',
    registerEmail: '/public/html/images/icon/register-mail.png',
    registerContact: '/public/html/images/icon/register-contact.png',
    registerCurrency: '/public/html/images/icon/register-currency.png',
    registerUser: '/public/html/images/icon/register-user.png',
    registerDOB: '/public/html/images/icon/register-dob.png',
    registerPassword: '/public/html/images/icon/register-password.png',
    registerAFF: '/public/html/images/icon/register-affiliate.png',

    imgViewComm: '/public/html/images/livetv/live-commentary-en-6107d4701c.png',
    imgViewStat: '/public/html/images/livetv/live-stat-en-bbf9a7ee9f.png',
    imgLiveTvBanner: '/public/html/images/livetv/livetv-banner.png',
    imgWatchLive: '/public/html/images/livetv/watch_live-en-607f8ab617.png',
    liveTVDefaultHome: '/public/html/images/livetv/livetv_default_home.png',
    liveTVDefaultAway: '/public/html/images/livetv/livetv_default_away.png',
    slotHLUL: ['/public/html/images/slot_img/ultimate-highlight-bn.jpg'],
    slotHLULpng: ['/public/html/images/slot_img/ultimate-highlight-bn.png'],
    vipBottomBanner: ['/public/html/images/home_img/vip-btm.png'],
    defaultTeamHomeImg: ['/public/html/images/team/sportsbook_icon_home.png'],
    defaultTeamAwayImg: ['/public/html/images/team/sportsbook_icon_away.png'],
    defaultPostBanner: ['/public/html/content/sponsors-img/intro-esport.jpg'],

    referralBanner: ['/public/html/images/home_img/referral-banner.png'],
    referralContent: ['/public/html/images/home_img/referral-content.png'],

    sponsorsBanner: ['/public/html/images/home_img/sponsorsBanner.png'],
    sponsorsIcon1: ['/public/html/images/home_img/sponsorsIcon1.png'],
    sponsorsIcon2: ['/public/html/images/home_img/sponsorsIcon2.png'],
    sponsorsIcon3: ['/public/html/images/home_img/sponsorsIcon3.png'],
    sponsorsLogo: ['/public/html/images/home_img/logo.png'],

    imgBankStatus: '/public/html/images/bank_img/bankstatus.png',
    footer18Icon: '/public/html/images/footer/18_only.png',

    PROVIDER_ICON: {
        // slot
        ag: '/public/html/images/icon/provider-ag.png',
        ag2: '/public/html/images/icon/provider-ag.png',
        gp: '/public/html/images/icon/provider-gp.png',
        mega: '/public/html/images/icon/provider_mega888.png',
        mgp: '/public/html/images/icon/provider-mg.png',
        ns: '/public/html/images/icon/provider-ns.png',
        pg: '/public/html/images/icon/provider-pg.png',
        pt: '/public/html/images/icon/provider-pt.png',
        pt2: '/public/html/images/icon/provider-pt.png',
        pp: '/public/html/images/icon/provider-pragmaticplay.png',
        sa: '/public/html/images/icon/provider-asiagaming.png',
        scr2: '/public/html/images/icon/provider_918kiss.png',
        sg: '/public/html/images/icon/provider-spade.png',
        ttg: '/public/html/images/icon/provider-ttg.png',
        ug: '/public/html/images/icon/provider-aesexy.png',
        aes: '/public/html/images/icon/provider-aesexy.png',
        ul: '/public/html/images/icon/provider-ultimate.png',
        // IME
        ime: '/public/html/images/icon/provider-ultimate.png',
        // Casino
        ab: '/public/html/images/icon/provider-allbet.png',
        bmk: '/public/html/images/icon/provider-evo.png',
        ebet: '/public/html/images/icon/provider-ebet.png',
        dg: '/public/html/images/icon/provider-dreamgaming.png',
        gp: '/public/html/images/icon/provider-gp.png',
        // Fishing
        gg: '/public/html/images/icon/provider-gg.png',
        // Sport
        cmd: '/public/html/images/icon/provider-cmd.png',
        ibc: '/public/html/images/icon/.png',
        br: '/public/html/images/icon/.png',
        m8: '/public/html/images/icon/provider-m8bet.png',
        // poker
        idn: '/public/html/images/icon/provider-idnpoker.png',
        // Lottery
        qqk: '/public/html/images/icon/provider-qqkeno.png',
    },
    NAV_ICON: {
        hot: '/public/html/images/slot_img/hot.png',
        all: '/public/html/images/slot_img/all.png',
        new: '/public/html/images/slot_img/NewMember.png',
        slot: '/public/html/images/slot_img/Slot.png',
        arcade: '/public/html/images/slot_img/Casino.png',
        table: '/public/html/images/slot_img/Lottery.png',
        special: '/public/html/images/slot_img/Special.png',
        vip: '/public/html/images/slot_img/VIP.png',
        sports: '/public/html/images/slot_img/Sportbook.png',
        winner: '/public/html/images/slot_img/Winner.png',
    },
    FISHING_BANNER: {
        index: {
            bannerAlt1: ['fishing-bn'],
            banner1: ['/public/html/images/fishing/fishing-bn.jpg'],
        },
        cashfish: {
            bannerAlt1: ['fishing-bn-pt'],
            banner1: ['/public/html/images/fishing/fishing-bn-pt.jpg'],
            bannerAlt2: ['fishing-feature-pt'],
            banner2: ['/public/html/images/fishing/fishing-highlight-pt.jpg'],
            banner2png: ['/public/html/images/fishing/fishing-highlight-pt.png'],
        },
        gggaming: {
            bannerAlt1: ['fishing-bn-gg'],
            banner1: ['/public/html/images/fishing/fishing-bn-gg.jpg'],
            bannerAlt2: ['fishing-feature-gg'],
            banner2: ['/public/html/images/fishing/fishing-highlight-gg.jpg'],
            banner2png: ['/public/html/images/fishing/fishing-highlight-gg.png'],
        },
        safishing: {
            bannerAlt1: ['fishing-bn-sa'],
            banner1: ['/public/html/images/fishing/fishing-bn-sa.jpg'],
            bannerAlt2: ['fishing-feature-sa'],
            banner2: ['/public/html/images/fishing/fishing-highlight-sa.jpg'],
            banner2png: ['/public/html/images/fishing/fishing-highlight-sa.png'],
        },
        sgfishing: {
            bannerAlt1: ['fishing-bn-fg'],
            banner1: ['/public/html/images/fishing/fishing-bn-sg.jpg'],
            bannerAlt2: ['fishing-feature-fg'],
            banner2: ['/public/html/images/fishing/fishing-highlight-sg.jpg'],
            banner2png: ['/public/html/images/fishing/fishing-highlight-sg.png'],
        },
        sgfishingwar: {
            bannerAlt1: ['fishing-bn-fw'],
            banner1: ['/public/html/images/fishing/fishing-bn-fw.jpg'],
            bannerAlt2: ['fishing-feature-fw'],
            banner2: ['/public/html/images/fishing/fishing-highlight-fw.jpg'],
            banner2png: ['/public/html/images/fishing/fishing-highlight-fw.png'],
        },
    },
    POKER_BANNER: {
        poker: {
            desktop: {
                bannerAlt1: ['poker-bn-idn'],
                banner1: ['/public/html/images/poker/poker-bn.jpg'],
                bannerAlt2: ['poker-bn-2'],
                banner2: ['/public/html/images/poker/poker-highlight-bn.jpg'],
                banner2png: ['/public/html/images/poker/poker-highlight-bn.png'],
            },
            mobile: {
                bannerAlt1: ['poker-idn-logo'],
                banner1: ['/public/html/images/cat_img/cat_poker_idn_logo.png'],
                bannerAlt2: ['mobile-banner-idnplay'],
                banner2: ['/public/html/images/poker/mobile_banner_idnplay.jpg'],
                banner2png: ['/public/html/images/poker/mobile_banner_idnplay.png'],
            },
        },
    },
    SLOT_IMAGE: {
        commonBanner: ['/public/html/images/slot_img/common-bn.png'],
        slotWinnerIcon: ['/public/html/images/slot_img/slot_icon_winner.png'],
        slotSpadeIcon: ['/public/html/images/slot_img/Slot-spade.png'],
        slotSpadeIconHome: ['/public/html/images/slot_img/Slot-spade-home.png'],
        slotCurrencyIcon: ['/public/html/images/slot_img/slot-currency.png'],
        defaultSlotImg: ['/public/html/images/slot_img/default-img.png'],
        asiagaming: { banner1: ['/public/html/images/slot_img/slot-bn-ag.jpg'] },
        gameplay: { banner1: ['/public/html/images/slot_img/slot-bn-sa.jpg'] },
        mega888: { banner1: [''] },
        microGamingPlus: { banner1: ['/public/html/images/slot_img/slot-bn-mg.jpg'] },
        nextspin: { banner1: ['/public/html/images/slot_img/slot-bn-ns.jpg'] },
        playNGo: { banner1: ['/public/html/images/slot_img/slot-bn-pg.jpg'] },
        playTech: { banner1: ['/public/html/images/slot_img/slot-bn-pt.jpg'] },
        pragmaticPlay: { banner1: ['/public/html/images/slot_img/slot-bn-pp.jpg'] },
        saGaming: { banner1: ['/public/html/images/slot_img/slot-bn-sa.jpg'] },
        spadeGaming: { banner1: ['/public/html/images/slot_img/slot-bn-sg.jpg'] },
        toptrendgaming: { banner1: ['/public/html/images/slot_img/slot-bn-ttg.jpg'] },
        ultimateslots: {
            banner1: ['/public/html/images/slot_img/ultimate-bn.jpg'],
            banner2: ['/public/html/images/slot_img/ultimate-highlight-bn.jpg'],
            banner2png: ['/public/html/images/slot_img/ultimate-highlight-bn.png'],
        },
    },
    CASINO_BANNER: {
        sagaming: {
            desktop: {
                bannerAlt1: ['casino-bn-sa'],
                banner1: ['/public/html/images/casino/casino-bn-sa.jpg'],
                bannerAlt2: ['casino-feature-sa'],
                banner2: ['/public/html/images/casino/casino-highlight-sa.jpg'],
                banner2png: ['/public/html/images/casino/casino-highlight-sa.png'],
            },
            mobile: {
                bannerAlt1: ['casino-logo-sa'],
                banner1: ['/public/html/images/cat_img/cat-casino-logo-sa.png'],
                bannerAlt2: ['casino-mobile-sa'],
                banner2: ['/public/html/images/cat_img/casino_mobile_sa.jpg'],
                banner2png: ['/public/html/images/cat_img/casino_mobile_sa.png'],
            },
            backgroundCasino: {
                background01: ['/public/html/images/casino/livecasinoBg.png'],
            },
        },
        dreamgaming: {
            desktop: {
                bannerAlt1: ['casino-bn-dg'],
                banner1: ['/public/html/images/casino/casino-bn-dg.jpg'],
                bannerAlt2: ['casino-feature-dg'],
                banner2: ['/public/html/images/casino/casino-highlight-dg.jpg'],
                banner2png: ['/public/html/images/casino/casino-highlight-dg.png'],
            },
            mobile: {
                bannerAlt1: ['casino-logo-dg'],
                banner1: ['/public/html/images/cat_img/cat-casino-logo-dg.png'],
                bannerAlt2: ['casino-mobile-dg'],
                banner2: ['/public/html/images/cat_img/casino_mobile_dg.jpg'],
                banner2png: ['/public/html/images/cat_img/casino_mobile_dg.png'],
            },
        },
        asiagaming: {
            desktop: {
                bannerAlt1: ['casino-bn-ag'],
                banner1: ['/public/html/images/casino/casino-bn-ag.jpg'],
                bannerAlt2: ['casino-highlight-ag'],
                banner2: ['/public/html/images/casino/casino-highlight-ag.jpg'],
                banner2png: ['/public/html/images/casino/casino-highlight-ag.png'],
            },
            mobile: {
                bannerAlt1: ['casino-logo-ag'],
                banner1: ['/public/html/images/cat_img/cat-casino-logo-ag.png'],
                bannerAlt2: ['casino-mobile-ag'],
                banner2: ['/public/html/images/cat_img/casino_mobile_ag.jpg'],
                banner2png: ['/public/html/images/cat_img/casino_mobile_ag.png'],
            },
        },
        asiagaming2: {
            desktop: {
                bannerAlt1: ['casino-bn-ag'],
                banner1: ['/public/html/images/casino/casino-bn-ag.jpg'],
                bannerAlt2: ['casino-highlight-ag'],
                banner2: ['/public/html/images/casino/casino-highlight-ag.jpg'],
                banner2png: ['/public/html/images/casino/casino-highlight-ag.png'],
            },
            mobile: {
                bannerAlt1: ['casino-logo-ag'],
                banner1: ['/public/html/images/cat_img/cat-casino-logo-ag.png'],
                bannerAlt2: ['casino-mobile-ag'],
                banner2: ['/public/html/images/cat_img/casino_mobile_ag.jpg'],
                banner2png: ['/public/html/images/cat_img/casino_mobile_ag.png'],
            },
        },
        sexybaccarat: {
            desktop: {
                bannerAlt1: ['casino-bn-aesexy'],
                banner1: ['/public/html/images/casino/casino-bn-aesexy.jpg'],
                bannerAlt2: ['casino-highlight-aesexy'],
                banner2: ['/public/html/images/casino/casino-highlight-aesexy.jpg'],
                banner2png: ['/public/html/images/casino/casino-highlight-aesexy.png'],
            },
            mobile: {
                bannerAlt1: ['casino-logo-aesexy'],
                banner1: ['/public/html/images/cat_img/cat-casino-logo-aesexy.png'],
                bannerAlt2: ['casino-mobile-aesexy'],
                banner2: ['/public/html/images/cat_img/casino_mobile_aesexy.jpg'],
                banner2png: ['/public/html/images/cat_img/casino_mobile_aesexy.png'],
            },
        },
        ebet: {
            desktop: {
                bannerAlt1: ['casino-bn-ebet'],
                banner1: ['/public/html/images/casino/casino-bn-ebet.jpg'],
                bannerAlt2: ['casino-highlight-ebet'],
                banner2: ['/public/html/images/casino/casino-highlight-ebet.jpg'],
                banner2png: ['/public/html/images/casino/casino-highlight-ebet.png'],
            },
            mobile: {
                bannerAlt1: ['casino-logo-ebet'],
                banner1: ['/public/html/images/cat_img/cat_casino_ebet.png'],
                bannerAlt2: ['casino-mobile-ebet'],
                banner2: ['/public/html/images/cat_img/casino_mobile_ebet.jpg'],
                banner2png: ['/public/html/images/cat_img/casino_mobile_ebet.png'],
            },
        },
        allbet: {
            desktop: {
                bannerAlt1: ['casino-bn-allbet'],
                banner1: ['/public/html/images/casino/casino-bn-allbet.jpg'],
                bannerAlt2: ['casino-highlight-allbet'],
                // galleryBanner: [
                //     "/public/html/images/casino/casino-allbet-hall-dragon.jpg",
                //     "/public/html/images/casino/casino-allbet-hall-multi.jpg",
                //     "/public/html/images/casino/casino-allbet-hall-vip.jpg",
                //     "/public/html/images/casino/casino-allbet-hall-bmq.jpg"
                // ]
                galleryBanner: [
                    {
                        id: '1',
                        banner: '/public/html/images/casino/casino-allbet-hall-dragon.jpg',
                        translation: 'casino:casino.ab.ab-dragon-hall',
                    },
                    {
                        id: '2',
                        banner: '/public/html/images/casino/casino-allbet-hall-multi.jpg',
                        translation: 'casino:casino.ab.ab-multiplay-hall',
                    },
                    {
                        id: '3',
                        banner: '/public/html/images/casino/casino-allbet-hall-vip.jpg',
                        translation: 'casino:casino.ab.ab-vip-hall',
                    },
                    {
                        id: '4',
                        banner: '/public/html/images/casino/casino-allbet-hall-bmq.jpg',
                        translation: 'casino:casino.ab.ab-bmq-hall',
                    },
                ],
            },
            mobile: {
                bannerAlt1: ['casino-logo-allbet'],
                banner1: ['/public/html/images/cat_img/cat-casino-logo-allbet.png'],
                bannerAlt2: ['casino-mobile-allbet'],
                banner2: ['/public/html/images/cat_img/casino_mobile_allbet.jpg'],
                banner2png: ['/public/html/images/cat_img/casino_mobile_allbet.png'],
            },
        },
        evolutiongaming: {
            desktop: {
                bannerAlt1: ['casino-bn-evo'],
                banner1: ['/public/html/images/casino/casino-bn-evo.jpg'],
                bannerAlt2: ['casino-highlight-evo'],
                banner2: ['/public/html/images/casino/casino-highlight-evo.jpg'],
                banner2png: ['/public/html/images/casino/casino-highlight-evo.png'],
            },
            mobile: {
                bannerAlt1: ['casino-logo-evo'],
                banner1: ['/public/html/images/cat_img/cat-casino-logo-evo.png'],
                bannerAlt2: ['casino-mobile-evo'],
                banner2: ['/public/html/images/cat_img/casino_mobile_evo.jpg'],
                banner2png: ['/public/html/images/cat_img/casino_mobile_evo.png'],
            },
        },
        gameplay: {
            desktop: {
                bannerAlt1: ['casino-bn-gp'],
                banner1: ['/public/html/images/casino/casino-bn-gp.jpg'],
                bannerAlt2: ['casino-highlight-gp'],
                banner2: ['/public/html/images/casino/casino-highlight-gp.jpg'],
                banner2png: ['/public/html/images/casino/casino-highlight-gp.png'],
            },
            mobile: {
                bannerAlt1: ['casino-logo-gp'],
                banner1: ['/public/html/images/cat_img/cat-casino-logo-gp.png'],
                bannerAlt2: ['casino-mobile-gp'],
                banner2: ['/public/html/images/cat_img/casino_mobile_gp.jpg'],
                banner2png: ['/public/html/images/cat_img/casino_mobile_gp.png'],
            },
        },
        playtech: {
            desktop: {
                bannerAlt1: ['casino-bn-pt'],
                banner1: ['/public/html/images/casino/casino-bn-pt.jpg'],
                bannerAlt2: ['casino-highlight-pt'],
                banner2: ['/public/html/images/casino/casino-highlight-pt.jpg'],
                banner2png: ['/public/html/images/casino/casino-highlight-pt.png'],
            },
            mobile: {
                bannerAlt1: ['casino-logo-pt'],
                banner1: ['/public/html/images/cat_img/cat-casino-logo-pt.png'],
                bannerAlt2: ['casino-mobile-pt'],
                banner2: ['/public/html/images/cat_img/casino_mobile_pt.jpg'],
                banner2png: ['/public/html/images/cat_img/casino_mobile_pt.png'],
            },
        },
        microGamingPlus: {
            desktop: {
                bannerAlt1: ['casino-bn-mgp'],
                banner1: ['/public/html/images/casino/casino-bn-mgp.jpg'],
                bannerAlt2: ['casino-highlight-mgp'],
                banner2: ['/public/html/images/casino/casino-highlight-mgp.jpg'],
                banner2png: ['/public/html/images/casino/casino-highlight-mgp.png'],
            },
            mobile: {
                bannerAlt1: ['casino-logo-mgp'],
                banner1: ['/public/html/images/cat_img/cat-casino-logo-mgp.png'],
                bannerAlt2: ['casino-mobile-mgp'],
                banner2: ['/public/html/images/cat_img/casino_mobile_mgp.jpg'],
                banner2png: ['/public/html/images/cat_img/casino_mobile_mgp.png'],
            },
        },
        golddeluxe: {
            desktop: {
                bannerAlt1: ['casino-bn-gd'],
                banner1: ['/public/html/images/casino/casino-bn-gd.jpg'],
                bannerAlt2: ['casino-highlight-gd'],
                banner2: ['/public/html/images/casino/casino-highlight-gd.jpg'],
                banner2png: ['/public/html/images/casino/casino-highlight-gd.png'],
            },
            mobile: {
                bannerAlt1: ['casino-logo-gd'],
                banner1: ['/public/html/images/cat_img/cat-casino-logo-gd.png'],
                bannerAlt2: ['casino-mobile-gd'],
                banner2: ['/public/html/images/cat_img/casino_mobile_gd.jpg'],
                banner2png: ['/public/html/images/cat_img/casino_mobile_gd.png'],
            },
        },
        wm: {
            desktop: {
                bannerAlt1: ['casino-bn-wm'],
                banner1: ['/public/html/images/casino/casino-bn-wm.jpg'],
                bannerAlt2: ['casino-highlight-wm'],
                banner2: ['/public/html/images/casino/casino-highlight-wm.jpg'],
                banner2png: ['/public/html/images/casino/casino-highlight-wm.png'],
            },
            mobile: {
                bannerAlt1: ['casino-logo-wm'],
                banner1: ['/public/html/images/cat_img/cat-casino-logo-wm.png'],
                bannerAlt2: ['casino-mobile-wm'],
                banner2: ['/public/html/images/cat_img/casino_mobile_wm.jpg'],
                banner2png: ['/public/html/images/cat_img/casino_mobile_wm.png'],
            },
        },
    },
    LOTTERY_BANNER: {
        qqkeno: {
            banner1: '/public/html/images/lottery/lottery-bn-qqkeno.jpg',
            bannerAlt1: 'qqkeno-bn',
        },
        qqkenothai: {
            banner1: '/public/html/images/lottery/lottery-bn-qqthai.jpg',
            bannerAlt1: 'qqkeno-thai-bn',
        },
    },
    SPORT_BANNER: {
        m8: {
            title: 'Sports M8',
        },
        cmd: {
            title: 'Sports CMD',
        },
        br: {
            bannerAlt: ['virtual-bn'],
            banner: ['/public/html/images/sports/virtual-bn.jpg'],
            galleryBanner: [
                {
                    id: '1',
                    banner: '/public/html/images/sports/virtual-sub-football.png',
                    bannerAlt: 'virtual-sub-football',
                    type: 'football',
                    gameCode: 'VFL',
                },
                {
                    id: '2',
                    banner: '/public/html/images/sports/virtual-sub-basketball.png',
                    bannerAlt: 'virtual-sub-basketball',
                    type: 'basketball',
                    gameCode: 'VBL',
                },
                {
                    id: '3',
                    banner: '/public/html/images/sports/virtual-sub-tennis.png',
                    bannerAlt: 'virtual-sub-tennis',
                    type: 'tennis',
                    gameCode: 'VTO',
                },
                {
                    id: '4',
                    banner: '/public/html/images/sports/virtual-sub-horse.png',
                    bannerAlt: 'virtual-sub-horse',
                    type: 'hourses',
                    gameCode: 'VHC',
                },
                {
                    id: '5',
                    banner: '/public/html/images/sports/virtual-sub-greyhounds.png',
                    bannerAlt: 'virtual-sub-greyhounds',
                    type: 'greyhounds',
                    gameCode: 'VDR',
                },
            ],
        },
        ufa: {
            bannerAlt: ['ufabet-bn'],
            banner: ['/public/html/images/sports/ufabet-bn.jpg'],
        },
        sport: {
            banner: ['/public/html/images/sports/sport_banner.jpg'],
        },
    },
    ESPORT_BANNER: {
        mainBanner: ['/public/html/images/sports/main-esport.png'],
        ime: {
            banner2: ['/public/html/images/sports/sports-bg.jpg'],
        },
        leagueBanner: ['/public/html/images/sports/esport-1.png'],
        dataBanner: ['/public/html/images/sports/esport-2.jpg'],
        counterStrikBanner: ['/public/html/images/sports/esport-3.jpg'],
        andMoreBanner: ['/public/html/images/sports/esport-4.jpg'],
    },
    SIDEBAR_IMG: {
        contactBanner: ['/public/html/images/home_img/contact_dd_speed.png'],
        hotgamesIcon: ['/public/html/images/home_img/hg_dd_icon_hot.gif'],
        hotgamesGif: ['/public/html/images/home_img/hg_dd_icon_hot2.gif'],
        hotBanner: ['/public/html/images/home_img/hg_dd_bn_fog.png'],
        hotgameNewBorder: ['/public/html/images/home_img/hg_dd_band_new.png'],
        hotgameHotBorder: ['/public/html/images/home_img/hg_dd_band_hot.png'],
        hotgameRecBorder: ['/public/html/images/home_img/hg_dd_band_reco.png'],
        hotgamesNew: ['/public/html/images/home_img/hg_dd_icon_new.gif'],
    },
    AFF_IMG: {
        mainBanner: ['/public/html/images/home_img/aff-1.png'],
        banner2: ['/public/html/images/home_img/aff-2.png'],
        banner3: ['/public/html/images/home_img/aff-3.png'],
        banner4: ['/public/html/images/home_img/aff-4.png'],
        banner5: ['/public/html/images/home_img/aff-5.png'],
    },
    SLIDER_BUTTON: {
        nextButton: ['/public/html/images/home_img/button_right.png'],
        prevButton: ['/public/html/images/home_img/button_left.png'],
    },
    JACKPORT_IMG: {
        jackpotBg: ['/public/html/images/home_img/jackpot-base.png'],
        jackpotGif: ['/public/html/images/home_img/progressive_jackpot.gif'],
    },
    GUIDE_STEP: {
        step1: ['/public/html/images/home_img/step-1-en.png'],
        step2: ['/public/html/images/home_img/step-2-en.png'],
        step3: ['/public/html/images/home_img/step-3-en.png'],
        step4: ['/public/html/images/home_img/step-4-en.png'],
        step5: ['/public/html/images/home_img/step-5-en.png'],
    },
    CONTACT_ICON: {
        livechat: ['/public/html/images/home_img/livechat.png'],
        gmail: ['/public/html/images/home_img/gmail.png'],
        wechat: ['/public/html/images/home_img/wc.png'],
        phone: ['/public/html/images/home_img/phone.png'],
        wahtapp: ['/public/html/images/home_img/phone02.png'],
        email: ['/public/html/images/home_img/mail.png'],
    },
    HOME_INFOR: [
        {
            id: '1',
            name: 'Sports',
            path: '/public/html/images/home_img/home-sports-icon.png',
            pathOv: '/public/html/images/home_img/home-sports-icon-ov.png',
            route: '/cmd368',
            desc: 'home:home.content.sports',
        },
        {
            id: '2',
            name: 'Casino',
            path: '/public/html/images/home_img/home-casino-icon.png',
            pathOv: '/public/html/images/home_img/home-casino-icon-ov.png',
            route: '/casino/sexy-baccarat',
            desc: 'home:home.content.casino',
        },
        {
            id: '3',
            name: 'Slots',
            path: '/public/html/images/home_img/home-slots-icon.png',
            pathOv: '/public/html/images/home_img/home-slots-icon-ov.png',
            route: '/slots/spadeGaming',
            desc: 'home:home.content.slots',
        },
        {
            id: '4',
            name: 'Fishing',
            path: '/public/html/images/home_img/home-fishing-icon.png',
            pathOv: '/public/html/images/home_img/home-fishing-icon-ov.png',
            route: '/sg-fishingwar',
            desc: 'home:home.content.fishing',
        },
        {
            id: '5',
            name: 'Lottery',
            path: '/public/html/images/home_img/home-lottery-icon.png',
            pathOv: '/public/html/images/home_img/home-lottery-icon-ov.png',
            route: '/qqkeno-thai',
            desc: 'home:home.content.lottery',
        },
    ],
    HOME_CONTENT: {
        slot: ['/public/html/images/home_img/home_slot.jpg'],
        casino: ['/public/html/images/home_img/home_casino.jpg'],
        fishing: ['/public/html/images/home_img/home_fishing.jpg'],
        sports: ['/public/html/images/home_img/home_sports.jpg'],
        contact: ['/public/html/images/home_img/home_contact.jpg'],
        accordinSilder01: ['/public/html/images/home_img/accordinSilder01.png'],
        accordinSilderHover01: ['/public/html/images/home_img/accordinSilder01-on.png'],
        accordinSilder02: ['/public/html/images/home_img/accordinSilder02.png'],
        accordinSilderHover02: ['/public/html/images/home_img/accordinSilder02-on.png'],
        accordinSilder03: ['/public/html/images/home_img/accordinSilder03.png'],
        accordinSilderHover03: ['/public/html/images/home_img/accordinSilder03-on.png'],
        accordinSilder04: ['/public/html/images/home_img/accordinSilder04.png'],
        accordinSilderHover04: ['/public/html/images/home_img/accordinSilder04-on.png'],
    },
};

export default image;
