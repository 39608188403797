import React from 'react';
import ReactLoading from 'react-loading';

const SmallLoading = () => (
    <div key={'bubbles'} className='small-loading-box'>
        <ReactLoading type={'bubbles'} className='small-loading' color='gray' style={{ width: '36px', height: '36px' }} />
    </div>
);

export default SmallLoading;
