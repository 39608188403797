import { registerConstant } from '../constant';
export const registerAction = {
    selectTelCode,
};

function selectTelCode(telCode, telCodeCountry) {
    return (dispatch) => {
        dispatch({ type: registerConstant.SELECTED_TELCODE, payload: { telCode, telCodeCountry } });
    };
}
