import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { userConstants } from '@redux/constant';
import { useSelector } from 'react-redux';

import { pu_getTelCodeFromPhoneNumber } from '@utils/phone-util';
import { pu_maskPhone } from '@utils/phone-util';
import { useGetCountryRegions } from '@hooks/general-hook';

export const useGetContactVerificationStatus = () => {
    const dispatch = useDispatch();

    const getContactVerificationStatus = async () => {
        const res = await window.SPL_Member.loadContactsVerification();

        dispatch({ type: userConstants.UPDATE_USER_VERIFICATION, payload: { verifications: { personal: res } } });
    };

    return {
        getContactVerificationStatus,
    };
};

export const useGetMemberPhoneInfo = () => {
    const user = useSelector((state) => state.user);

    const [initTelCodeComplete, setInitTelCodeComplete] = useState(false);
    const [selectedTelCode, setSelectedTelCode] = useState(null);
    const [displayPhone, setDisplayPhone] = useState(null);

    // hooks
    const { internationalRegions, regions } = useGetCountryRegions();

    useEffect(() => {
        getMemberTelCode();
        getMemberMaskedPhone();
    }, []);

    useEffect(() => {
        if (!initTelCodeComplete) {
            return;
        }

        // only to handle if member phone format is invalid, return a default telCode
        if (!selectedTelCode) {
            // first priority take international regions first (Only got data if got enable setting)
            if (internationalRegions?.length > 0) {
                const _fallbackRegionByCountry = internationalRegions.find((region) => region.countryCode === user?.account?.country);
                if (_fallbackRegionByCountry) {
                    setSelectedTelCode(_fallbackRegionByCountry?.telCode);
                } else {
                    const _fallbackRegionByCurrency = internationalRegions.find((region) =>
                        (region?.supportedCurrency || []).find((supportCurrency) => supportCurrency === user?.account?.currency),
                    );
                    setSelectedTelCode(_fallbackRegionByCurrency?.telCode);
                }
            } else if (regions?.length > 0) {
                const _fallbackRegionByCurrency = regions.find((region) => region.currency === user?.account?.currency);
                setSelectedTelCode(_fallbackRegionByCurrency?.telCode);
            }
        }
    }, [regions, internationalRegions, initTelCodeComplete, selectedTelCode]);

    // function
    const getMemberTelCode = () => {
        const extractedTelCode = pu_getTelCodeFromPhoneNumber(user?.account?.phone);
        setSelectedTelCode(extractedTelCode ? `+${extractedTelCode}` : null);
        setInitTelCodeComplete(true);
    };

    const getMemberMaskedPhone = () => {
        const displayPhone = pu_maskPhone(user?.account?.phone);
        setDisplayPhone(displayPhone);
    };

    return {
        selectedTelCode,
        displayPhone,
    };
};

// export const useGetMemberTelCode = ({ selectedTelCode }) => {
//     const { selectedTelCode, displayPhone } = useGetMemberPhoneInfo();

//     useEffect(()=> {

//     }, [selectedTelCode, ])
//     ffuseGetCountryRegions
//     useGetCountryRegions

// }
