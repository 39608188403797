import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
// import SVGMessage from 'root/components/Icon/SVGMessage'

import vm from '../delete-message-dialog.controller';
import { Translation, withTranslation } from 'react-i18next';

class DeleteMessageDialog extends Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
    }

    componentWillMount() {
        Modal.setAppElement('#root');
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        return (
            <Translation>
                {(t) => (
                    <Modal
                        isOpen={this.props.deleteMessageDialogIsShow}
                        shouldCloseOnEsc={!this.state.disableSubmitBtn}
                        shouldCloseOnOverlayClick={!this.state.disableSubmitBtn}
                        onRequestClose={() => this.props.closeDeleteMessageDialog(this.props.parentvm)}
                        overlayClassName='custom-modal-overlay'
                        className='custom-modal delete-message-dialog'
                    >
                        <div className='custom-modal-header delete-message-dialog-header'>
                            <div className='custom-modal-header-withIcon delete-message-header'>
                                {/* <SVGMessage name='trashbin'></SVGMessage> */}
                                {t('message:message.confirm-subject', 'Delete Selected Messages?')}
                            </div>
                            <button className='close-btn' onClick={() => this.props.closeDeleteMessageDialog(this.props.parentvm)}>
                                X
                            </button>
                        </div>
                        <div className='custom-modal-body delete-message-dialog-body'>
                            <span className='btn-submit' onClick={() => vm.submitForm()}>
                                {t('message:message.confirm', 'Confirm')}
                            </span>
                            {/* <button className='primary-btn' onClick={() => vm.submitForm()}>{t('message:message.confirm', 'Confirm')}</button> */}
                        </div>
                    </Modal>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation('message')(DeleteMessageDialog));
