import React, { Suspense } from 'react';
import Loading from './loading/loading';

const LazyComponent = (Component, props) => {
    return () => (
        <Suspense fallback={<Loading className='vw-100 vh-100'></Loading>}>
            <Component {...props}></Component>
        </Suspense>
    );
};

export default LazyComponent;
