import React from 'react';

class Chat extends React.Component {
    constructor() {
        super();
        this.state = {
            messages: [
                {
                    isSelf: false,
                    name: 'Service Jay',
                    time: '9.00am',
                    content: 'Hi, May I help u?',
                },
                { isSelf: true, name: 'Me', time: '9.10am', content: 'Yes, Thank u' },
            ],
        };
    }

    toSend() {
        const list = JSON.parse(JSON.stringify(this.state.messages));
        list.push(this.state.messages[1]);
        this.setState({ messages: list });
    }
    onClose() {
        this.props.doClose && this.props.doClose();
    }

    render() {
        return (
            <div className='chat-popup-box'>
                <div className='chat-popup'>
                    <div className='title'>
                        <i className='icon-more'></i>
                        <span>Welcome to ME88</span>
                        <div className='btns'>
                            <i
                                className='icon-to-min'
                                onClick={() => {
                                    this.onClose();
                                }}
                            ></i>
                            <i
                                className='icon-close'
                                onClick={() => {
                                    this.onClose();
                                }}
                            ></i>
                        </div>
                    </div>
                    <div className='content'>
                        <ul className='messages-list'>
                            {this.state.messages.map(({ name, time, content, isSelf }, i) => (
                                <li key={i + 'message'} data-self={isSelf ? 'true' : ''}>
                                    <div className='message-info'>
                                        <span>{name}</span>
                                        <span>{time}</span>
                                    </div>
                                    <div className='message-content'>{content}</div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className='tools'>
                        <input type='text' placeholder={'Write a Message...'} />
                        <div className='btns'>
                            <i
                                className='icon-send'
                                onClick={() => {
                                    this.toSend();
                                }}
                            ></i>
                            <i className='icon-attach'></i>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Chat;
