import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import { AiOutlineCopy } from 'react-icons/ai';
import {
    FacebookShareButton,
    FacebookIcon,
    EmailShareButton,
    EmailIcon,
    WhatsappShareButton,
    WhatsappIcon,
    TelegramShareButton,
    TelegramIcon,
    LineShareButton,
    LineIcon,
    TwitterShareButton,
    XIcon,
} from 'react-share';

import core from 'root/utils/core';

class ShareModal extends React.Component {
    constructor(props) {
        super();
    }

    componentDidMount() {}

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        const { txtToShare, isOpen, linkToShare } = this.props;

        return (
            <Translation>
                {(t) => (
                    <Fragment>
                        <div>
                            <div className={'share-modal-container ' + (isOpen ? '' : 'd-none')}>
                                <div className='share-modal'>
                                    <div className='md-title'>
                                        {t('referral:referral.shareTo', 'Share To')} <span onClick={() => this.props.share(txtToShare)}>x</span>
                                    </div>
                                    <div className='social-media-container'>
                                        <FacebookShareButton url={linkToShare} className='referral-share-link'>
                                            <FacebookIcon size={32} round={false} className='share-icon' />
                                            <span>Facebook</span>
                                        </FacebookShareButton>
                                        <EmailShareButton url={txtToShare} className='referral-share-link'>
                                            <EmailIcon size={32} round={false} className='share-icon' />
                                            <span>Email</span>
                                        </EmailShareButton>
                                        <WhatsappShareButton url={txtToShare} className='referral-share-link'>
                                            <WhatsappIcon size={32} round={false} className='share-icon' />
                                            <span>Whatsapp</span>
                                        </WhatsappShareButton>
                                        <TelegramShareButton url={txtToShare} className='referral-share-link'>
                                            <TelegramIcon size={32} round={false} className='share-icon' />
                                            <span>Telegram</span>
                                        </TelegramShareButton>
                                        <LineShareButton url={txtToShare} className='referral-share-link'>
                                            <LineIcon size={32} round={false} className='share-icon' />
                                            <span>LINE</span>
                                        </LineShareButton>
                                        <TwitterShareButton url={txtToShare} className='referral-share-link'>
                                            <XIcon size={32} round={false} className='share-icon' />
                                            <span>X</span>
                                        </TwitterShareButton>
                                        <button
                                            className='btn btn-submit'
                                            onClick={() => {
                                                core.copy(t, txtToShare);
                                            }}
                                        >
                                            <AiOutlineCopy></AiOutlineCopy>
                                            <span> {t('referral:referral.copyLink', 'Copy Link')}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <button onClick={() => this.props.share(txtToShare, linkToShare)} className='referral-btn'>
                                {t('referral:referral.share').toUpperCase()}
                            </button>
                        </div>
                    </Fragment>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['referral'])(withRouter(ShareModal)));
