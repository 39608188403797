import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import core from 'root/utils/core';

import Faq1 from './component/faq';

class Faq extends React.Component {
    components = {
        desktop: {
            default: Faq1,
        },
        mobile: {
            default: Faq1,
        },
    };

    render() {
        const TagName = core.getCorrectComponent(this.components, 'faq', this.props.screen.viewType);
        return <TagName />;
    }
}

export default connect(mapStateToProps, null)(Faq);
