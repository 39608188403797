// library
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

// logic
import { authSettingsAction } from '@redux/action';
import { MODULE_TYPE as MT } from '@constants';

/**
 *
 * @param {String} module - constant value from MODULE_TYPE
 * @param {Boolean} isPublic
 * @returns
 */
export const useGetAuthSettings = (module, isPublic, isNew) => {
    const dispatch = useDispatch();
    const authSettingsReducer = useSelector((state) => state.authSettingsReducer);
    const systemFeatureReducer = useSelector((state) => state.systemFeatureReducer);
    const _bindSettingsAuthSettings = authSettingsReducer?.processedData?.[MT?.BIND_AUTHENTICATOR];
    const _sixPinAuthSettings = authSettingsReducer?.processedData?.[MT?.SIX_PIN];
    const currentModuleAuthSettings = authSettingsReducer?.processedData?.[module];
    // const isRequiredCallAuthSettings = systemFeatureReducer?.systemFeature?.[module]?.otp_feature;
    const isRequiredCallAuthSettings = true; // 6d pin de case, will have otp_feature: false. hence, temp hardcode true

    const getAuthSettings = (reqUpdate) => {
        const splConfig = {
            ...(!window.isAffiliate && {
                otpOptions: {
                    isPublic: isPublic,
                    otpType: module,
                    isNew: isNew,
                },
            }),
            ...(window.isAffiliate && {
                authSettingsType: module,
                isPublic: isPublic,
                isNew: isNew,
            }),
        };
        dispatch(authSettingsAction.fetchAuthSettings(splConfig, reqUpdate));
    };

    useEffect(() => {
        if (isRequiredCallAuthSettings) {
            getAuthSettings();
        }
    }, [systemFeatureReducer]);

    // scenario use:
    // initial will call BIND_AUTHENTICATOR from the 1st useEffect. Assume if GA enabled on the CRYPTO_ADDRESS module
    // if user haven't bind yet, then will show GA popup. (based on gaEnabled and gaRegistered). Then fill in, submit and bind it.
    // After bind successfully, need update the CRYPTO_ADDRESS AUTHENTICATOR again, in order to prevent GA popup showed.
    // But if user bind and refresh, then won't have this issue because retrigger API is all the latest.
    // This function is just to prevent at each module add the callback, instead detect dependency and auto retrigger API.
    // if too heavy upon call API few times like this, then can consider manual dispatch the gaRegistered.
    useEffect(() => {
        if (_bindSettingsAuthSettings?.transitionFromUnbindToBindGA && currentModuleAuthSettings?.gaEnabled && !currentModuleAuthSettings?.gaRegistered) {
            dispatch(authSettingsAction.setModuleGoogleRegistered(module));
        }
    }, [_bindSettingsAuthSettings?.transitionFromUnbindToBindGA]);

    useEffect(() => {
        if (
            _sixPinAuthSettings?.transitionFromUnbindToBindSixPin &&
            currentModuleAuthSettings?.sixPinEnabled &&
            !currentModuleAuthSettings?.memberSixPinInfo?.sixPinRegistered
        ) {
            dispatch(authSettingsAction.setModuleSixPinRegistered(module));
        }
    }, [_sixPinAuthSettings?.transitionFromUnbindToBindSixPin]);

    // when main six_pin auth-settings locked, auto trigger update module lock status
    useEffect(() => {
        if (_sixPinAuthSettings?.transitionToLock && currentModuleAuthSettings?.sixPinEnabled && !currentModuleAuthSettings?.memberSixPinInfo?.sixPinLocked) {
            dispatch(authSettingsAction.setModuleSixPinLocked(module));
        }
    }, [_sixPinAuthSettings?.transitionToLock, currentModuleAuthSettings]);

    return {
        getAuthSettings,
    };
};
