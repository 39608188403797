import { modalConstants } from '../constant';

export const modalAction = {
    updateLoginModal,
};

function updateLoginModal(visible) {
    return (dispatch) => {
        dispatch({ type: modalConstants.UPDATE_LOGIN_MODAL, payload: visible });
    };
}
