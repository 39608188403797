import React, { Fragment } from 'react';
import { TweenMax } from 'gsap';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import PromoDetail from '../promotionDetails.selector';
import PromoApply from '../promotionApply.selector';
import PromoTransfer from '../promotionTransfer.selector';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import vm from '../promotion.controller';

let tween1, tween2;

class PromosPanel extends React.Component {
    constructor(props) {
        super();
        vm.init(this);
    }

    componentDidMount() {
        vm.initCompSettings();
        vm.getFloatNotificationId();
        vm.getMerchantLiveChat();
        vm.getSupportLinkPromotionEnabled();

        setTimeout(() => {
            vm.getPromotionContent('all', false);
        }, 500);
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    toggleTab() {
        tween1 && tween1.kill();
        tween2 && tween2.kill();

        TweenMax.set('.promos-list', {
            alpha: 0,
        });

        tween1 = TweenMax.delayedCall(0.3, function () {
            tween2 = TweenMax.staggerFromTo(
                '.promos-list, .promos-list li',
                0.3,
                {
                    y: 20,
                    alpha: 0,
                },
                {
                    y: 0,
                    alpha: 1,
                },
                0.08
            );
        });
    }

    getCustomBackgroundImage() {
        let inlineStyles = {};
        const { compSettings } = this.state;
        let { enableCNYTheme } = compSettings;
        inlineStyles.backgroundImage = `url(/public/html/images/background-register${enableCNYTheme ? '-cny' : ''}.png)`;
        inlineStyles.backgroundSize = 'cover';
        return inlineStyles;
    }

    render() {
        const { randomImage, compSettings } = this.state;
        const { enableCNYTheme } = compSettings;
        const { screen } = this.props;
        const { displaypromotionContents, promotionTypes } = this.state.promotionList;

        let promoApplyFormProps = {
            getCountryInfo: vm.getCountryInfo,
            getApplyFormField: vm.getApplyFormField,
            handleFieldChanged: vm.handleFieldChanged,
            handleValidation: vm.handleValidation,
            handleApplySubmit: vm.handleApplySubmit,
            toggleModal: vm.toggleModal.bind(this),
        };

        let promoApplyTransProps = {
            restoreWallet: vm.restoreWallet,
            toogleDropdown: vm.toogleDropdown,
            handleProviderSelect: vm.handleProviderSelect,
            handleFieldChanged: vm.handleFieldChanged,
            handleRequestTransfer: vm.handleRequestTransfer,
            toggleModal: vm.toggleModal.bind(this),
            handleTypeChange: vm.handleTypeChange,
        };

        return (
            <Translation>
                {(t) => (
                    <div className='promos-panel' style={this.getCustomBackgroundImage()}>
                        {this.state.promoDetailIsVisible && <PromoDetail {...this.state} toggleModal={vm.toggleModal.bind(this)} />}

                        {this.state.promoApplyFormIsVisible && <PromoApply {...this.state} {...promoApplyFormProps} />}

                        {this.state.promoApplyTransferIsVisible && <PromoTransfer {...this.state} {...promoApplyTransProps} />}

                        <div className='container'>
                            <div className='promos-box'>
                                <ul className='promos-nav'>
                                    {promotionTypes.map(
                                        ({ desc, promotionType, tabActive, count }, i) =>
                                            desc && (
                                                <li
                                                    data-key={promotionType}
                                                    key={promotionType}
                                                    className={tabActive ? 'on' : ''}
                                                    onClick={() => {
                                                        this.toggleTab();
                                                        vm.onTabChange(promotionType);
                                                    }}
                                                >
                                                    <div className='group'>
                                                        <span className='name'>{t(desc)}</span>
                                                        <span className='count'>{count}</span>
                                                    </div>
                                                </li>
                                            )
                                    )}
                                </ul>

                                {enableCNYTheme && (
                                    <div className='cny-random-image-container'>
                                        {randomImage &&
                                            randomImage.map((content, index) => {
                                                return <LazyLoadImage key={index} className='firework-1' src={content.src} style={content.style} alt='' />;
                                            })}
                                    </div>
                                )}

                                <ul className='promos-list'>
                                    {displaypromotionContents.map((content, index) => (
                                        <li key={content.id}>
                                            <div className='img'>
                                                <LazyLoadImage data-screen='web|pad' src={content.image} alt='title' />
                                                <LazyLoadImage data-screen='mobile' src={content.image} alt='title' />
                                            </div>

                                            <div className='content-box'>
                                                <div className='text-box'>
                                                    <div className='title'>{content.title}</div>
                                                    <div className='content'>{content.description}</div>
                                                </div>

                                                {content.isRemainingTime && (
                                                    <div className='time-box'>
                                                        <span>{t('promotion:promotion.remainingTime', 'Remaining time')} - </span>
                                                        <span className={`time ${content.remainingUnlimited ? 'unlimited' : ''}`}>
                                                            {content.remainingUnlimited ? (
                                                                <Fragment>&#8734;&#xfe0e;</Fragment>
                                                            ) : (
                                                                <Fragment>
                                                                    {content.remainingDays}
                                                                    &nbsp;
                                                                    {t('promotion:promotion.days', 'day(s)')} {content.remainingHours}
                                                                    &nbsp;
                                                                    {t('promotion:promotion.hours', 'hour(s)')}
                                                                </Fragment>
                                                            )}
                                                        </span>
                                                    </div>
                                                )}
                                            </div>

                                            <div className='cta-box'>
                                                <span className='btn-info' onClick={() => vm.toggleModal('showPromoDetails', content, index)}>
                                                    {t('promotion:promotion.button')}
                                                </span>
                                                {content.isAllowApply && (
                                                    <span className='btn-apply' onClick={() => vm.toggleModal('showPromoApply', content, index)}>
                                                        {t('promotion:promotion.apply')}
                                                    </span>
                                                )}
                                            </div>
                                        </li>
                                    ))}

                                    {enableCNYTheme && screen.viewType !== 'mobile' && (
                                        <div className='cny-bottom-container'>
                                            <LazyLoadImage className='biggoldcoin' src={'/public/html/images/cny/biggoldcoin.png'} alt='' />
                                            <LazyLoadImage className='dragon' src={'/public/html/images/cny/dragon.png'} alt='' />
                                        </div>
                                    )}
                                </ul>
                            </div>
                        </div>
                       
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation('promotion')(withRouter(PromosPanel)));
