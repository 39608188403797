import React from 'react';
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component';
import commonUtil from 'root/utils/common-util';
import providerService from 'root/utils/providerService';
import memberService from 'root/utils/memberService';
import core from 'root/utils/core';
import { portalAction } from 'root/redux/action';

const MAX_MV_SHOW = {
    web: 4,
    mobile: 4,
};

const HOTMATCH_SPORTS_ID = {
    SOCCER: '1',
    BASKETBALL: '2',
};

const ODDSFEED_TEAM_NAME_TO_SHOW = [
    // EPL
    { name: 'Manchester City', isTicked: true },
    { name: 'Liverpool', isTicked: true },
    { name: 'Chelsea', isTicked: true },
    { name: 'Tottenham Hotspur', isTicked: true },
    { name: 'Arsenal', isTicked: true },
    { name: 'Manchester United', isTicked: true },
    { name: 'Manchester Utd', isTicked: true },
    { name: 'West Ham' },
    { name: 'Wolverhampton Wanderers' },
    { name: 'Leicester City' },
    { name: 'Leicester' },
    { name: 'Brighton & Hove Albion', isTicked: true },
    { name: 'Brighton', isTicked: true },
    { name: 'Brentford', isTicked: true },
    { name: 'Newcastle United', isTicked: true },
    { name: 'Newcastle', isTicked: true },
    { name: 'Crystal Palace' },
    { name: 'Aston Villa' },
    { name: 'Southampton' },
    { name: 'Everton' },
    { name: 'Leeds' },
    { name: 'Fulham' },
    { name: 'Bournemouth' },
    { name: 'Nottingham Forest' },
    // end of EPL
    // Spanish La Liga
    { name: 'Real Madrid', isTicked: true },
    { name: 'Barcelona', isTicked: true },
    { name: 'Atlético Madrid', isTicked: true },
    { name: 'Atletico de Madrid' },
    { name: 'Sevilla' },
    { name: 'Real Betis', isTicked: true },
    { name: 'Real Sociedad', isTicked: true },
    { name: 'Villarreal', isTicked: true },
    { name: 'Athletic Bilbao', isTicked: true },
    { name: 'Osasuna', isTicked: true },
    { name: 'Celta Vigo' },
    { name: 'Valencia' },
    { name: 'Rayo Vallecano' },
    { name: 'Espanyol' },
    { name: 'Getafe' },
    { name: 'Elche' },
    { name: 'Mallorca', isTicked: true },
    { name: 'Cádiz' },
    { name: 'Eibar' },
    { name: 'Almería' },
    { name: 'Girona', isTicked: true },
    { name: 'Valladolid' },
    // end of Spanish La Liga
    // German Bundesliga
    { name: 'Bayern Munich ©', isTicked: true },
    { name: 'Bayern Munchen', isTicked: true },
    { name: 'Borussia Dortmund', isTicked: true },
    { name: 'Bayer Leverkusen', isTicked: true },
    { name: 'RB Leipzig', isTicked: true },
    { name: 'Union Berlin', isTicked: true },
    { name: 'SC Freiburg', isTicked: true },
    { name: '1. FC Köln' },
    { name: 'Cologne' },
    { name: 'Mainz', isTicked: true },
    { name: 'Mainz 05', isTicked: true },
    { name: '1899 Hoffenheim' },
    { name: 'Hoffenheim' },
    { name: 'Borussia Mönchengladbach', isTicked: true },
    { name: 'Borussia Monchengladbach', isTicked: true },
    { name: 'Eintracht Frankfurt', isTicked: true },
    { name: 'VfL Wolfsburg', isTicked: true },
    { name: 'Wolfsburg', isTicked: true },
    { name: 'VfL Bochum' },
    { name: 'Bochum' },
    { name: 'FC Augsburg' },
    { name: 'Augsburg' },
    { name: 'VfB Stuttgart' },
    { name: 'Hertha BSC (Q)' },
    { name: 'Hertha Berlin' },
    { name: 'Schalke 04' },
    { name: 'Schalke' },
    { name: 'Werder Bremen' },
    // end of German Bundesliga
    // Italy Series A
    { name: 'Milan', isTicked: true },
    { name: 'AC Milan', isTicked: true },
    { name: 'Inter Milan', isTicked: true },
    { name: 'Napoli', isTicked: true },
    { name: 'Juventus', isTicked: true },
    { name: 'Lazio', isTicked: true },
    { name: 'Roma', isTicked: true },
    { name: 'AS Roma', isTicked: true },
    { name: 'Fiorentina', isTicked: true },
    { name: 'Atalanta', isTicked: true },
    { name: 'Hellas Verona' },
    { name: 'Verona' },
    { name: 'Torino', isTicked: true },
    { name: 'Sassuolo' },
    { name: 'Udinese' },
    { name: 'Bologna', isTicked: true },
    { name: 'Empoli' },
    { name: 'Sampdoria' },
    { name: 'Spezia' },
    { name: 'Salernitana' },
    { name: 'Monza' },
    { name: 'Lecce' },
    { name: 'Cremonese' },
    // end of Italy Series A
    // French Ligue 1
    { name: 'Paris Saint Germain', isTicked: true },
    { name: 'Monaco', isTicked: true },
    { name: 'AS Monaco', isTicked: true },
    { name: 'Marseille', isTicked: true },
    { name: 'Rennes', isTicked: true },
    { name: 'Stade Rennais', isTicked: true },
    { name: 'Strasbourg' },
    { name: 'Nice', isTicked: true },
    { name: 'Lens', isTicked: true },
    { name: 'Lyon', isTicked: true },
    { name: 'Nantes' },
    { name: 'Lille', isTicked: true },
    { name: 'Brest' },
    { name: 'Reims' },
    { name: 'Montpellier' },
    { name: 'Angers' },
    { name: 'Troyes' },
    { name: 'Clermont', isTicked: true },
    { name: 'Lorient', isTicked: true },
    { name: 'Auxerre' },
    { name: 'Toulouse' },
    { name: 'Ajaccio' },
    // end of French Ligue 1
    // EFL Championship
    { name: 'Fulham' },
    { name: 'Bournemouth' },
    { name: 'Huddersfield Town' },
    { name: 'Nottingham Forest' },
    { name: 'Sheffield United', isTicked: true },
    { name: 'Sheffield Utd', isTicked: true },
    { name: 'Luton Town', isTicked: true },
    { name: 'Middlesbrough', isTicked: true },
    { name: 'Blackburn Rovers', isTicked: true },
    { name: 'Millwall', isTicked: true },
    { name: 'West Bromwich Albion', isTicked: true },
    { name: 'West Bromwich', isTicked: true },
    { name: 'Queens Park Rangers' },
    { name: 'Coventry City', isTicked: true },
    { name: 'Coventry', isTicked: true },
    { name: 'Preston North End' },
    { name: 'Stoke City' },
    { name: 'Swansea City', isTicked: true },
    { name: 'Swansea', isTicked: true },
    { name: 'Blackpool' },
    { name: 'Bristol City' },
    { name: 'Cardiff City' },
    { name: 'Hull City' },
    { name: 'Birmingham City' },
    { name: 'Reading' },
    { name: 'Wigan Athletic' },
    { name: 'Rotherham United' },
    { name: 'Burnley', isTicked: true },
    { name: 'Sunderland', isTicked: true },
    // end of EFL Championship
    // Brazil Serie A
    { name: 'Botafogo', isTicked: true },
    { name: 'Palmeiras', isTicked: true },
    { name: 'Flamengo', isTicked: true },
    { name: 'Atletico Mineiro', isTicked: true },
    { name: 'Fluminense', isTicked: true },
    { name: 'Gremio Porto Alegre [W]', isTicked: true },
    { name: 'Athletico Paranaense', isTicked: true },
    { name: 'Sao Paulo', isTicked: true },
    { name: 'Cruzeiro', isTicked: true },
    { name: 'SC Internacional', isTicked: true },
    { name: 'Bragantino' },
    { name: 'Fortaleza' },
    { name: 'Santos' },
    { name: 'Bahia' },
    { name: 'Corinthians' },
    { name: 'Cuiaba' },
    { name: 'Goias' },
    { name: 'America -MG' },
    { name: 'Vasco da Gama' },
    { name: 'Guarani FC' },
    { name: 'Associacao Portuguesa de Desportos [W]' },
    { name: 'EC Vitoria [W]' },
    { name: 'Sao Caetano' },
    { name: 'Nautico Capibaribe' },
    { name: 'Bangu' },
    { name: 'Sport Recife' },
    { name: 'Coritiba' },
    // end of Brazil Serie A
    // Japan J1 League
    { name: 'Yokohama F Marinos', isTicked: true },
    { name: 'Nagoya', isTicked: true },
    { name: 'Vissel Kobe', isTicked: true },
    { name: 'Urawa Red Diamonds', isTicked: true },
    { name: 'Sanfrecce Hiroshima', isTicked: true },
    { name: 'Cerezo Osaka', isTicked: true },
    { name: 'Kashima Antlers', isTicked: true },
    { name: 'Consadole Sapporo', isTicked: true },
    { name: 'Kawasaki Frontale', isTicked: true },
    { name: 'Sagan Tosu', isTicked: true },
    { name: 'FC Tokyo' },
    { name: 'Avispa Fukuoka' },
    { name: 'Kyoto Sanga FC' },
    { name: 'Gamba Osaka' },
    { name: 'Albirex Niigata' },
    { name: 'Kashiwa ' },
    { name: 'Yokohama FC' },
    { name: 'Shonan Bellmare' },
    // end of Japan J1 League
];

const controller = {
    vm: null,

    init(vm) {
        this.vm = vm;
        this.vm.state = {
            homePageBanner: [],
            announcementsDisplay: [],
            sportsScrollingBanner: [],
            oddsFeedArray: [],
            contactList: [],
            jackpotList: [],
            hotGameList: [],
            favoritedGame: [],
            announcementPopupList: [],
            announcementPopupVersion: 0,
            announcementPopupIsShow: false,
            jackpotTimer: null,
            jackpotIdList: [],
            jackpotObj: null,
            customTranslationObj: null,
            videoUrl: '',
            activeImage: 0,
            appInstructionList: null,
            topWinnerJson: [],
        };
    },

    onHomeMounted() {
        core.portalIsLoading(controller.vm.props, false);
    },

    getHotGames() {
        memberService.getJustForYouGame(controller.vm, controller.vm.props);
    },

    getMemberFavoriteGame() {
        memberService.getMemberFavoriteGame();
    },

    updateMemberFavoriteGame(game) {
        memberService.updateMemberFavoriteGame(controller.vm, controller.vm.props, game);
    },

    getHomePageBanner(isMobile) {
        const { language, screen } = controller.vm.props;
        window.SPL_Content.getHomePageBanner(language.countryLanguageKey, screen.viewType).then((result) => {
            // purposely massage the image before set variable
            // dont know why the size of the banner must be 2000 x 500 and png format
            // ** now is force hardcode 2000px x 500px
            let homePageBanner = [];
            for (let i = 0; i < result.length; i++) {
                let bannerObj = controller.generateBannerElement(result[i].url, result[i].webPUrl, result[i].href, isMobile);
                homePageBanner.push(bannerObj);
            }
            controller.vm.setState({ homePageBanner: homePageBanner });
        });
    },

    affiliateOnClick() {
        commonUtil.getAffiliateLink(controller.vm).then(() => {
            return controller.bannerOnClicked(controller.vm.state.affiliateLink);
        });
    },

    generateBannerElement(url, webPUrl, href, isMobile) {
        if (isMobile) {
            return {
                content: (
                    <div
                        onClick={() => {
                            controller.bannerOnClicked(href);
                        }}
                    >
                        <LazyLoadComponent>
                            <picture>
                                <source type='image/webp' srcSet={webPUrl} />
                                <img src={url} alt='' />
                            </picture>
                        </LazyLoadComponent>
                    </div>
                ),
            };
        } else {
            // content = banner
            // tab = custom pagination button
            return {
                content: (
                    <div
                        onClick={() => {
                            controller.bannerOnClicked(href);
                        }}
                    >
                        <LazyLoadComponent>
                            <picture>
                                <source type='image/webp' srcSet={webPUrl} />
                                <img data-screen='web' src={url} alt='' style={{ objectFit: 'fill', maxWidth: '100%' }} />
                            </picture>
                        </LazyLoadComponent>
                        <LazyLoadComponent>
                            <picture>
                                <source type='image/webp' srcSet={webPUrl} />
                                <img height={450} data-screen='pad' src={url} style={{ objectFit: 'fill', maxWidth: '2000px' }} alt='' />
                            </picture>
                        </LazyLoadComponent>
                    </div>
                ),
                tab: (
                    <div>
                        <LazyLoadComponent>
                            <picture>
                                <source type='image/webp' srcSet={webPUrl} />
                                <img src={url} alt='' />
                            </picture>
                        </LazyLoadComponent>
                        <div className='loading-line'></div>
                    </div>
                ),
                href: href,
            };
        }
    },

    bannerOnClicked(href) {
        if (href && href !== '') {
            if (href.indexOf('http') !== -1) {
                window.open(href, '_blank');
            } else {
                controller.vm.props.history.push(href);
            }
        }
    },

    getAnnouncement(contentType) {
        const { user, language } = controller.vm.props;

        window.SPL_Content.getAnnoucementListWithType(user.isLogin, language.countryLanguageKey, 'Home', contentType).then((announcements) => {
            if (announcements.announcementsWithPopUpDisplay.length > 0) {
                controller.vm.setState({
                    announcementPopupList: announcements.announcementsWithPopUpDisplay,
                    announcementPopupVersion: announcements.popupVersion,
                    announcementPopupIsShow: true,
                });
            }

            if (announcements.announcementsDisplay.length > 0) {
                let announcementsDisplay = [];

                if (announcements.popupVersion === 1 || (announcements.announcementsDisplay.length > 0 && announcements.announcementsDisplay[0][contentType] === undefined)) {
                    announcementsDisplay = announcements.announcementsDisplay;
                } else {
                    for (let i = 0; i < announcements.announcementsDisplay.length; i++) {
                        let txt = announcements.announcementsDisplay[i][contentType].text;

                        if (txt && txt !== '') {
                            announcementsDisplay.push(txt);
                        }
                    }
                }

                controller.vm.setState({ announcementsDisplay: announcementsDisplay });
            }
        });
    },

    closePopupAnnouncement() {
        controller.vm.setState({ announcementPopupIsShow: false });
    },

    getSportsScrollingBanner(contentType) {
        const { language } = controller.vm.props;
        let currencyLang = language.currencyLang;

        if (language.country === 'KH') currencyLang = 'KHUSD';

        let loadGetSubmenu = new Promise((resolve) => {
            window.SPL_Content.getSubmenu(language.countryLanguageKey, contentType, 'S').then((data) => resolve(data));
        });

        let loadProviderGameMaintenanceList = new Promise((resolve) => {
            window.SPL_Provider.providerGameMaintenanceList(null, null, currencyLang).then((result) => resolve(result));
        });

        let loadPortalSettings = new Promise((resolve) => {
            window.SPL_JsonSettings.getPortalSettings((settings) => resolve(settings));
        });

        Promise.all([loadGetSubmenu, loadProviderGameMaintenanceList, loadPortalSettings]).then((loadResult) => {
            let submenu = loadResult[0];
            let providerMaintenance = loadResult[1];
            let portalSettings = loadResult[2];

            let returnArray = [];
            let sportsScrollingBanner = [];

            if (portalSettings && portalSettings.sportsScrollingBanner) {
                sportsScrollingBanner = portalSettings.sportsScrollingBanner;
                let providers = Object.keys(sportsScrollingBanner);

                for (let i = 0; i < providers.length; i++) {
                    let found = submenu.filter((a) => a.provider === providers[i]);

                    if (providerMaintenance) {
                        for (let j = 0; j < providerMaintenance.length; j++) {
                            let maintenanceProvider = providerMaintenance[j].provider;

                            if (providers[i] === maintenanceProvider) {
                                found.maintenance = providerMaintenance[j];
                            }
                        }
                    }

                    if (found && found.length > 0) {
                        let bannerElem = this.generateSportScrollingElement(providers[i], sportsScrollingBanner[providers[i]], found, found.maintenance);
                        returnArray.push(bannerElem);
                    }
                }
                controller.vm.setState({ sportsScrollingBanner: returnArray });
            }
        });
    },

    generateSportScrollingElement(provider, setting, data, maintenance) {
        let langSetting = setting[controller.vm.props.language.countryLanguageKey],
            img_key = '';

        if (provider === 'CMD') {
            img_key = 'c';
        } else if (provider === 'IBC') {
            img_key = 'i';
        } else if (provider === 'BTI') {
            img_key = 'b';
        } else if (provider === 'BR') {
            img_key = 'v';
        } else if (provider === 'M8') {
            img_key = 'm';
        } else if (provider === 'SBO' || provider === 'SBO2') {
            img_key = 's';
        }

        return {
            content: (
                <div className={`item-content ${data[0].isDisableProvider ? 'provider-disable' : ''} provider-${provider}`}>
                    <div className='item-content-wrapper'>
                        <span className='icon-box'>
                            <i className={langSetting.icon}></i>
                        </span>
                        <p>
                            {langSetting.desc1}
                            <br />
                            {langSetting.desc2}
                            <br />
                            {langSetting.desc3}
                            <br />
                            {langSetting.desc4}
                        </p>
                        <span
                            className='btn'
                            onClick={() => {
                                controller.sportsBannerPlayNow(provider, langSetting.gameCode, '', data[0].isDisableProvider, maintenance, langSetting.title);
                            }}
                        >
                            {controller.vm.props.t('home:home.web.playNow', 'Play Now')}
                        </span>
                    </div>
                </div>
            ),
            tab: (
                <>
                    <LazyLoadImage className={'provider-character provider-' + provider} src={`/public/html/images/homepage/section/${img_key}.png`} />
                    <div className={provider}>
                        <span className={`${provider} ${data[0].isDisableProvider ? 'provider-disable' : ''}`}>{langSetting.title}</span>
                    </div>
                </>
            ),
        };
    },

    sportsBannerPlayNow(provider, gameCode, matchId, disableProvider, providerMaintenance, providerMaintenanceName) {
        const { history } = controller.vm.props;
        if (providerMaintenance) {
            history.push({
                pathname: '/maintenance',
                search: '?provider=' + providerMaintenanceName + '&startTime=' + providerMaintenance.startTime + '&endTime=' + providerMaintenance.endTime + '',
            });
        } else {
            if (disableProvider) {
                controller.vm.props.history.push('/redirect-error');
            } else {
                const { language, screen, user } = controller.vm.props;
                let gameObj = {
                    provider: provider,
                    gameCode: gameCode,
                    category: 'S',
                    matchId: matchId,
                };
                providerService.launchGameFromHomePage(gameObj, language, user.isLogin, screen, false, controller.vm);
            }
        }
    },

    getHomePageOddsFeed() {
        const vm = controller.vm;
        const { currencyLang } = vm.props.language;

        window.SPL_Content.getBtiSoccerMatchOddsFeed(currencyLang, ODDSFEED_TEAM_NAME_TO_SHOW).then((sportMatchData) => {
            let returnArray = [];
            if (sportMatchData && sportMatchData[HOTMATCH_SPORTS_ID.SOCCER]) {
                const dataList = sportMatchData[HOTMATCH_SPORTS_ID.SOCCER];
                for (let i = 0; i < dataList.length; i++) {
                    const currentData = dataList[i];

                    let bannerElem = controller.generateOddsFeedElement(currentData);
                    returnArray.push(bannerElem);
                }
                vm.setState({ oddsFeedArray: returnArray });
            }
        });
    },

    generateOddsFeedElement(matchObj) {
        const vm = controller.vm;
        const { customTranslationObj } = vm.state;
        const { id, away, home, startDate } = matchObj;

        let oddFeedTitle = (customTranslationObj && customTranslationObj.oddsFeedTitle) || '';
        return {
            content: { oddFeedTitle, home, away, startDate, id, sportsBannerPlayNow: controller.sportsBannerPlayNow },
        };
    },

    getContact() {
        const { language, screen } = controller.vm.props;
        core.getContact(language, screen).then((data) => {
            controller.vm.setState({ contactList: data.contactList });
        });
    },

    contactOnClick(contact) {
        if (contact.name === 'LiveChat') {
            //window.open(url, "", "width=500,height=700,toolbar=no,location=no,directories=no,status=yes,menubar=no,scrollbars=no");
            core.openLivechat(controller.vm.props.language.countryLanguageKey, controller.vm.props.screen.viewType);
        } else {
            let url = window.SPL_Content.getContactUrl(contact);

            if (url && url !== '') {
                window.open(url);
            }
        }
    },

    detemineOpenRegisterOrLoginPopup() {
        let urlParam = window.SPL_Content.parse_query_string();
        if (urlParam.openRegister && urlParam.openRegister === '1') {
            controller.vm.props.dispatch(portalAction.floatingPageUpdated('register'));
        } else if (urlParam.openLogin && urlParam.openLogin === '1') {
            controller.vm.props.dispatch(portalAction.floatingPageUpdated('login'));
        }
    },

    getJackpotValue() {
        const { user, language } = controller.vm.props;
        let currency = language.currencyLang;
        if (user.isLogin) {
            currency = user.account.currency;
        }

        window.SPL_Content.getJackpotDisplay2('SG', currency).then((data) => {
            let jackpotObj = {
                provider: 'SG',
                amount: Number(data.jackpotValue),
            };
            controller.vm.setState({ jackpotObj: jackpotObj });
        });
    },

    getSocialMediaUrl(url) {
        if (url) {
            window.open(url);
        }
    },

    getVideoSliderData() {
        const { countryLanguageKey, key, country } = controller.vm.props.language;
        const { viewType } = controller.vm.props.screen;

        let customImg = {
            th: {
                default: '/public/html/images/homepage/comng-soon-thumbnail.png',
                withKey: true,
            },
            vn: {
                default: '/public/html/images/homepage/comng-soon-thumbnail.png',
                withKey: true,
            },
        };
        window.SPL_JsonSettings.getWebsiteSettings((data) => {
            let videoSlideObjList = [];
            let videoSlideObj = {};
            if (data !== undefined && data !== null) {
                let videoSlideArray = data.videoSlideObj;
                let videoSlideObjImageList = [];
                let videoSlideObjVideoList = [];

                if (videoSlideArray) {
                    if (videoSlideArray.length > 0) {
                        videoSlideArray.sort(function (a, b) {
                            return a.seq - b.seq;
                        });

                        for (var i = 0; i < videoSlideArray.length; i++) {
                            videoSlideObj = {};
                            videoSlideObjVideoList = videoSlideArray[i].videoURLList;
                            if (videoSlideObjVideoList) {
                                for (let j = 0; j < videoSlideObjVideoList.length; j++) {
                                    if (videoSlideObjVideoList[j].language === countryLanguageKey) {
                                        videoSlideObj.isPlay = false;

                                        let newVideoUrl = controller.checkVideoUrl(videoSlideObjVideoList[j].videoUrl);

                                        let iframePlayerAPICall = newVideoUrl;
                                        videoSlideObj.videoSrc = iframePlayerAPICall;

                                        let url = controller.vm.state.videoUrl;
                                        if (url === '' && videoSlideObjVideoList[j].videoUrl !== '') {
                                            url = controller.checkVideoUrl(videoSlideObjVideoList[j].videoUrl);
                                            controller.vm.setState({ videoUrl: url });
                                        }
                                    }
                                }
                            } else {
                                videoSlideObj.videoSrc = videoSlideObjVideoList.videoUrl;
                            }

                            videoSlideObjImageList = videoSlideArray[i].videoImageList;
                            for (let j = 0; j < videoSlideObjImageList.length; j++) {
                                if (videoSlideObjImageList[j].language === countryLanguageKey) {
                                    videoSlideObj.imgSrc = videoSlideObjImageList[j].imageUrl;
                                }
                            }

                            if (videoSlideObj.videoSrc || videoSlideObj.imgSrc) {
                                videoSlideObjList.push(videoSlideObj);
                            }
                        }
                    } else {
                        videoSlideObj.videoSrc = data.video_url;
                        videoSlideObjList.push(videoSlideObj);
                    }
                }
            }

            // NOTE: sorry I enhance a bit, since the old method have bug & doesn't fully meet the criteria anyway.
            const getComingSoonImagePath = (index) => {
                const mobilePrefix = viewType === 'mobile' ? 'mobile-' : '';
                if (customImg[country.toLowerCase()] && customImg[country.toLowerCase()].default) {
                    return customImg[country.toLowerCase()].default;
                } else {
                    return `/public/html/images/homepage/namewee-${mobilePrefix}side-${index}_${key}.png`;
                }
            };

            videoSlideObjList &&
                videoSlideObjList.forEach((e, i) => {
                    if (e.imgSrc === '') {
                        e.imgSrc = getComingSoonImagePath(i + 1);
                        e.disabled = true;
                        // } /*if (viewType === 'mobile')*/ else {
                        //     // NOTE: now both desktop & mobile using the same image
                        //     if (customImg[country.toLowerCase()] && customImg[country.toLowerCase()].withKey) {
                        //         e.imgSrc = `/public/html/images/homepage/custom-namewee-mobile-side-${i + 1}_${key}.png`;
                        //     } else {
                        //         e.imgSrc = `/public/html/images/homepage/namewee-mobile-side-${i + 1}.png`;
                        //     }
                    }
                });

            if (videoSlideObjList && videoSlideObjList.length < MAX_MV_SHOW[viewType]) {
                const startIndex = videoSlideObjList.length;
                const endIndex = MAX_MV_SHOW[viewType];
                for (let i = startIndex, l = endIndex; i < l; i++) {
                    // NOTE: missing the 4th coming soon image in mobile, as mobile displaying 4.
                    videoSlideObjList.push({ imgSrc: getComingSoonImagePath(i + 1), disabled: true, videoSrc: '' });
                }
            }

            controller.vm.setState({
                videoSlideObjList: videoSlideObjList,
            });
        });
    },

    nameweeSelectorOnClick(url, index) {
        if (url && url !== '') {
            let url_ = controller.checkVideoUrl(url);
            controller.vm.setState({
                videoUrl: url_,
                activeImage: index,
            });
        }
    },

    checkVideoUrl(tempVideoUrl) {
        let extraYoutubeParams = '?enablejsapi=1&autoplay=1&mute=1';

        if (tempVideoUrl) {
            let newUrl = tempVideoUrl;

            if (tempVideoUrl.includes('&')) {
                let getParam = tempVideoUrl.substring(tempVideoUrl.indexOf('&'));
                let checkParam = getParam.substring(1, 2);
                if (checkParam === 't') {
                    let getParamValue = getParam.substring(getParam.indexOf('=') + 1);
                    if (getParamValue.includes('s')) {
                        getParamValue = getParamValue.replace('s', '');
                    }
                    newUrl = newUrl.replace(getParam, '');
                    newUrl = newUrl + extraYoutubeParams + '&start=' + getParamValue;
                }
            } else if (tempVideoUrl.includes('streamable.com')) {
                newUrl = newUrl + '?autoplay=0';
            } else {
                newUrl = newUrl + extraYoutubeParams;
            }

            return newUrl;
        }
    },

    getAppInstructionData() {
        window.SPL_JsonSettings.getAppInstructionJson((list) => {
            controller.vm.setState({ appInstructionList: list });
        });
    },

    //   getJackpotValue() {
    //     const { user, language, t } = controller.vm.props;
    //     let currency = language.currencyLang;
    //     let jackpotList = [];
    //     let jackpotIdList = [];

    //     if (user.isLogin) {
    //       currency = user.account.currency;
    //     }

    //     let sgPromise = getJackpotByProvider('SG', null, 0);
    //     let ptPromise = getJackpotByProvider('PT2', null, 1);
    //     let scrPromise = getJackpotByProvider('SCR', 'small_jackpot', 2);

    //     Promise.all([sgPromise, ptPromise, scrPromise]).then(result => {
    //         for (let i = 0; i < result.length; i++) {
    //             if (result[i].isEmpty) {
    //                 // do nothing
    //             } else {
    //                 let jackpotId = 'jackpot-' + i;
    //                 jackpotIdList.push(jackpotId);
    //                 jackpotList.push(result[i]);
    //             }
    //         }

    //         let timer = setInterval(() => {
    //             let loopList = controller.vm.state.jackpotIdList;
    //             for (let i = 0; i < loopList.length; i++) {
    //                 let correctId = loopList[i].split('-')[1];
    //                 let foundElem = document.getElementById(loopList[i]);
    //                 let foundProvider = document.getElementById('jackpot-provider-' + correctId);
    //                 let foundSpan = document.getElementById('span-' + correctId);
    //                 if (foundElem && foundSpan) {
    //                     let value = foundElem.value;
    //                     const randomNum = genRand(0.01, 0.99, 2);
    //                     let newNumber = (parseFloat(value) + randomNum).toFixed(2);
    //                     let lastDigit = newNumber.slice(-1);
    //                     if (parseInt(lastDigit) === 0) {
    //                         newNumber = parseFloat(newNumber) + 0.11;
    //                     }
    //                     foundElem.value = newNumber;
    //                     let newJackpotObj = {
    //                         provider: foundProvider.value,
    //                         amount: Number(newNumber)
    //                     };
    //                     let newJackpotElem = generateJackpotElem(newJackpotObj, correctId);
    //                     let jackpotList = controller.vm.state.jackpotList;
    //                     jackpotList[i] = newJackpotElem;
    //                     controller.vm.setState({ jackpotList: jackpotList });
    //                 }
    //             }
    //          }, 1000);

    //         controller.vm.setState({ jackpotList: jackpotList, jackpotTimer: timer, jackpotIdList: jackpotIdList });
    //     });

    //     function genRand(min, max, decimalPlaces) {
    //         var rand = Math.random() * (max - min) + min;
    //         var power = Math.pow(10, decimalPlaces);
    //         return Math.floor(rand * power) / power;
    //     }

    //     function getJackpotByProvider(provider, jackpotType, i) {
    //       return new Promise(function (resolve) {
    //         let jackpotObj = {
    //           provider: '',
    //           amount: ''
    //         };
    //         let jackpotElem = '';

    //         if (jackpotType === 'small_jackpot') {
    //           window.SPL_Content.getSlotJackpot(currency, provider).then((data) => {
    //             jackpotObj = {
    //               provider: provider,
    //               amount: Number(data)
    //             };
    //             jackpotElem = generateJackpotElem(jackpotObj, i);

    //             resolve(jackpotElem);
    //           });
    //         } else {
    //           window.SPL_Content.getJackpotDisplay2(provider, currency).then((data) => {
    //             if (data.displayJackpot === false) {
    //               resolve({ isEmpty: true });
    //             }

    //             jackpotObj = {
    //               provider: provider,
    //               amount: Number(data.jackpotValue)
    //             };
    //             jackpotElem = generateJackpotElem(jackpotObj, i);

    //             resolve(jackpotElem);
    //           });
    //         }
    //       });
    //     }

    //     function generateJackpotElem(obj, i) {
    //         return {
    //             content: (
    //               <div>
    //                 <img src={`/public/html/images/homepage/top_game/jackpot-${obj.provider}.png`} alt=""></img>
    //                 <div className="content">
    //                   <span className="jackpot">
    //                     {t('global:global.hotgames.progressiveJackpot', 'PROGRESSIVE JACKPOT')}
    //                   </span>
    //                   <div className="icon-box">
    //                     {/* <i className="icon-mega888"></i> */}
    //                   </div>
    //                   {/* <span>
    //                     {window.SPL_Other.formatAmount(obj.amount)}
    //                   </span> */}
    //                   <input id={'jackpot-' + i} type='hidden' value={obj.amount}></input>
    //                   <input id={'jackpot-provider-' + i} type='hidden' value={obj.provider}></input>
    //                   <span id={'span-' + i}>
    //                     {window.SPL_Other.formatAmount(obj.amount)}
    //                   </span>
    //                 </div>
    //               </div>
    //             )
    //         }
    //     }
    //   }

    getTopWinnerList() {
        window.SPL_JsonSettings.getTopWinnerList((data) => {
            controller.vm.setState({ topWinnerList: data });
        });
    },
};

export default controller;
