import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import core from 'root/utils/core';
import DesktopCasino from './desktop/FourD';
import MobileCasino from './mobile';

class FourD extends React.Component {
    components = {
        desktop: {
            default: DesktopCasino,
        },
        mobile: {
            default: MobileCasino,
        },
    };

    render() {
        const TagName = core.getCorrectComponent(this.components, '4d', this.props.screen.viewType);
        return <TagName></TagName>;
    }
}

export default connect(mapStateToProps, null)(FourD);
