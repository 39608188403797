import { gameConstants } from '../constant';
export const gameAction = {
    loadGame,
};

function loadGame(gameObj) {
    return (dispatch) => {
        dispatch({ type: gameConstants.LOAD_GAME_PAGE, payload: gameObj });
    };
}
