import React from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import CustomDatePicker from 'root/components/customDatePicker';
import { BsArrowUpDown } from 'react-icons/bs';
import Pagination from 'root/webapp/shared/pagination/pagination.selector';
import moment from 'moment';
import FlipMove from 'react-flip-move';
import MobileSelect from 'root/webapp/shared/component/mobileSelect/mobileSelect';

import vm from '../transaction.controller';

class Transaction extends React.Component {
    constructor() {
        super();
        vm.init(this);
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        return (
            <Translation>
                {(t) => (
                    <div>
                        <div>
                            <div className='group-item transaction-date'>
                                <div className='title'>
                                    <span>{t('history:history:datatime', 'Transaction Date')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='group'>
                                    <ul>
                                        {this.state.date.list.map((item, i) => (
                                            <li
                                                key={item}
                                                className={[i === this.state.date.active ? 'on' : ''].join(' ')}
                                                onClick={() => {
                                                    vm.handleDateRange(i, this);
                                                    this.setState({ ...this.state, date: { ...this.state.date, active: i } });
                                                }}
                                            >
                                                <span>{t(item)}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className='group-item date-select'>
                                <div className='title'>{t('transaction:transaction.history.filter.start_date', 'Start Date').split(':')}</div>
                                <div className='group'>
                                    <div className='input-box'>
                                        <label>
                                            <CustomDatePicker
                                                placeholderText={t('transaction:transaction.history.filter.start_date', 'Start Date')}
                                                selected={this.state.fields.startDate}
                                                onChange={(date) => vm.handleDateChanged(date, true, this)}
                                                popperPlacement='bottom-start'
                                            />
                                            <i className='icon-date'></i>
                                        </label>
                                    </div>
                                    <span>{t('transaction:transaction.history.filter.to')}</span>
                                    <div className='input-box'>
                                        <label>
                                            <CustomDatePicker
                                                placeholderText={t('transaction:transaction.history.filter.end_date', 'End Date')}
                                                selected={this.state.fields.endDate}
                                                onChange={(date) => vm.handleDateChanged(date, false, this)}
                                                popperPlacement='bottom-end'
                                            />
                                            <i className='icon-date'></i>
                                        </label>
                                    </div>
                                </div>
                                {/* <div className="group-item">
                                    <MobileSelect itemList={this.state.transTypeList} itemKey={'desc'} onChange={(e) => vm.handleFieldChanged(e, 'selectedTransactionIndex')}
                                        itemIndex={this.state.fields.selectedTransactionIndex} title={t('transaction:transaction.history.filter.trans_type_no_colon', 'Transaction Type')} required={true} />
                                </div>
                                <div className="group-item">
                                    <MobileSelect itemList={this.state.statusList} itemKey={'desc'} onChange={(e) => vm.handleFieldChanged(e, 'selectedStatusIndex')}
                                        itemIndex={this.state.fields.selectedStatusIndex} title={t('transaction:transaction.history.filter.trans_status', 'Transaction Status')} required={true} />
                                </div> */}
                            </div>
                            <div className='group-item date-select'>
                                <div className='group align-right '>
                                    <button className='btn-submit' onClick={() => vm.searchHistory(0, this)}>
                                        {t('transaction:transaction.deposit.cashdeposit.submitbtn', 'Submit').toUpperCase()}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='history-list'>
                            <div className='table-container'>
                                <div className='table-header table-header-6'>
                                    <ul>
                                        <li>
                                            <div className='sorting-column'>
                                                <span>{t('transaction:transaction.history.date', 'Date')}</span>
                                                <BsArrowUpDown onClick={() => vm.sortHistory('createdDate')}></BsArrowUpDown>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='sorting-column'>
                                                <span>{t('transaction:transaction.history.amount', 'Amount')}</span>
                                                <BsArrowUpDown onClick={() => vm.sortHistory('amount')}></BsArrowUpDown>
                                            </div>
                                        </li>
                                        <li>{t('transaction:transaction.history.method', 'Method')}</li>
                                        <li>{t('transaction:transaction.history.filter.trans_type_no_colon', 'Transaction Type')}</li>
                                        <li>
                                            <div className='sorting-column'>
                                                <span>{t('transaction:transaction.history.status', 'Status')}</span>
                                                <BsArrowUpDown onClick={() => vm.sortHistory('status')}></BsArrowUpDown>
                                            </div>
                                        </li>
                                    </ul>
                                    {this.state.histories.length ? (
                                        <FlipMove className='table-list table-list-6' enterAnimation='fade' leaveAnimation='fade'>
                                            {this.state.histories.map((history, i) => (
                                                <ul key={i}>
                                                    <li>
                                                        <span>{moment(history.date).format('YYYY-MM-DD HH:mm').toString()}</span>
                                                    </li>
                                                    <li>{history.amount}</li>
                                                    <li>{t(history.method)}</li>
                                                    <li>{t(history.transType)}</li>
                                                    <li>{t(history.status)}</li>
                                                </ul>
                                            ))}
                                        </FlipMove>
                                    ) : (
                                        <div className='table-list'>
                                            <span>{t('transaction:transaction.emptyHistory')}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <Pagination
                                pagingList={this.state.pagingList}
                                handlePrevNextClick={vm.handlePrevNextClick}
                                totalCount={this.state.totalCount}
                                currentPage={this.state.currentPage}
                                itemPerPage={this.state.itemPerPage}
                                handlePageClick={vm.handlePageClick}
                            ></Pagination>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['history', 'settings', 'transaction', 'providerName'])(withRouter(Transaction)));
