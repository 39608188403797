import React, { lazy } from 'react';
import core from 'root/utils/core';
import LazyComponent from 'root/webapp/shared/lazyComponent';
const Pagination1 = lazy(() => import('./component/pagination'));
const Pagination2 = lazy(() => import('./component/pagination02'));

class Pagination extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
    }

    components = {
        default: Pagination2,
        pagination1: Pagination1,
        pagination2: Pagination2,
    };

    render() {
        const TagName = LazyComponent(core.getCorrectComponent(this.components, 'pagination', null), this.props);
        return <TagName {...this.props}></TagName>;
    }
}

export default Pagination;
