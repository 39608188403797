import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import core from 'root/utils/core';
import DesktopFastGames from './desktop/fast-games';
import MobileFastGames from './mobile';

class FastGames extends React.Component {
    components = {
        desktop: {
            default: DesktopFastGames,
        },
        mobile: {
            default: MobileFastGames,
        },
    };

    render() {
        const TagName = core.getCorrectComponent(this.components, 'slots', this.props.screen.viewType);
        return <TagName></TagName>;
    }
}

export default connect(mapStateToProps, null)(FastGames);
