import { dropdownConstants } from '../constant';
export const dropdownAction = {
    dropdownUpdate,
};

function dropdownUpdate(dropdownObj) {
    return (dispatch) => {
        if (dropdownObj) {
            if (dropdownObj.item && dropdownObj.isToggle) {
                if (dropdownObj.item === 'userLeaderboard') {
                    dropdownObj.userLeaderboardDropdown = !dropdownObj.userLeaderboardDropdown;
                }
            }
        } else {
            dropdownObj = {
                certainElementDropdown: null,
            };
        }
        dispatch({ type: dropdownConstants.DROPDOWN_UPDATE, payload: dropdownObj });
    };
}
