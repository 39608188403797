import React, { FC } from 'react';
import PromosPanel from './PromosPanel';

class Promos extends React.Component {
    render() {
        return <PromosPanel></PromosPanel>;
    }
}

export default Promos;
