import { providerConstants } from '../constant/provider.constant';

const initialState = {
    rawData: {
        maintenanceList: null,
        hotGameSliders: null,
        justForYouGame: null,
        favoriteGames: null,
    },
    processedData: {
        maintenanceList: null,
        hotGameSliders: null,
        justForYouGame: null,
        favoriteGames: null,
    },
};

export function providerReducer(state = initialState, action) {
    switch (action.type) {
        case providerConstants.GET_PROVIDER_MAINTENANCE_LIST:
            return {
                ...state,
                rawData: {
                    ...state?.rawData,
                    maintenanceList: action?.payload?.maintenanceList,
                },
                processedData: {
                    ...state?.processedData,
                    maintenanceList: action?.payload?.processedMaintenanceList,
                },
            };

        // ********* just for you game session **********
        case providerConstants.GET_JUST_FOR_YOU_GAME:
            return {
                ...state,
                rawData: {
                    ...state?.rawData,
                    justForYouGame: action.payload.justForYouGame,
                },
            };
        case providerConstants.SET_JUST_FOR_YOU_GAME:
            return {
                ...state,
                processedData: {
                    ...state?.processedData,
                    justForYouGame: action.payload.justForYouGame,
                },
            };

        // ********* hot game slider session **********
        case providerConstants.GET_HOT_GAME_SLIDER:
            return {
                ...state,
                rawData: {
                    ...state?.rawData,
                    hotGameSliders: action.payload.hotGameSliders,
                },
            };
        case providerConstants.SET_HOT_GAME_SLIDER:
            return {
                ...state,
                processedData: {
                    ...state?.processedData,
                    hotGameSliders: action.payload.hotGameSliders,
                },
            };

        // ********* member favorites game session **********
        case providerConstants.GET_MEMBER_FAVORITE_GAMES:
            return {
                ...state,
                rawData: {
                    ...state?.rawData,
                    favoriteGames: action.payload.favoriteGames,
                },
            };
        case providerConstants.UPDATE_MEMBER_FAVORITE_GAMES:
            return {
                ...state,
                rawData: {
                    ...state?.rawData,
                    favoriteGames: action.payload.favoriteGames,
                },
            };
        case providerConstants.SET_MEMBER_FAVORITE_GAMES:
            return {
                ...state,
                processedData: {
                    ...state?.processedData,
                    favoriteGames: action.payload.favoriteGames,
                },
            };
        default:
            return state;
    }
}
