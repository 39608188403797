import React, { Fragment } from 'react';

const getViewBox = (name) => {
    switch (name) {
        default:
            return '0 0 45 45';
    }
};

const getPath = (name) => {
    switch (name) {
        case 'bank_dbs':
            return (
                <Fragment>
                    <path
                        className='st144'
                        d='M32.9,5H12.1C8.2,5,5,8.2,5,12.1v20.8c0,3.9,3.2,7.1,7.1,7.1h20.8c3.9,0,7.1-3.2,7.1-7.1V12.1
	C40,8.2,36.8,5,32.9,5'
                    />
                    <g>
                        <g id='Logomark_3_'>
                            <polygon id='Fill-1_3_' className='st13' points='10.5,20.1 15.5,20.1 15.5,15 10.5,15 		' />
                            <path
                                className='st145'
                                d='M15.3,20l-1.8-1.5c0,0-0.2-0.2-0.5-0.2c-0.3,0-0.5,0.2-0.5,0.2L10.6,20l0,0l1.5-1.8c0,0,0.2-0.2,0.2-0.5
			c0-0.3-0.2-0.5-0.2-0.5l-1.5-1.8l0,0l1.8,1.5c0,0,0.2,0.2,0.5,0.2c0.3,0,0.5-0.2,0.5-0.2l1.8-1.5l0,0l-1.5,1.8
			c0,0-0.2,0.2-0.2,0.5c0,0.3,0.2,0.5,0.2,0.5L15.3,20L15.3,20z M16.2,17.6L16.2,17.6c0-0.5,0-0.9,0.4-1.7c0.1-0.2,0.3-0.6,0-0.9
			c-0.3-0.3-0.6-0.2-0.8-0.1c0.1-0.2,0.2-0.5-0.1-0.8c-0.4-0.3-0.7-0.1-0.9,0c-0.8,0.4-1.2,0.4-1.7,0.4c-0.5,0-0.9,0-1.7-0.4
			c-0.2-0.1-0.6-0.3-0.9,0c-0.3,0.3-0.2,0.6-0.1,0.8c-0.2-0.1-0.5-0.2-0.8,0.1c-0.3,0.4-0.1,0.7,0,0.9c0.4,0.8,0.4,1.2,0.4,1.7
			c0,0.5,0,0.9-0.4,1.7c-0.1,0.2-0.3,0.6,0,0.9c0.3,0.3,0.6,0.2,0.8,0.1c-0.1,0.2-0.2,0.5,0.1,0.8c0.4,0.3,0.7,0.1,0.9,0
			c0.8-0.4,1.2-0.4,1.7-0.4c0.5,0,0.9,0,1.7,0.4c0.2,0.1,0.6,0.3,0.9,0c0.3-0.3,0.2-0.6,0.1-0.8c0.2,0.1,0.5,0.2,0.8-0.1
			c0.3-0.4,0.1-0.7,0-0.9C16.2,18.5,16.2,18.1,16.2,17.6L16.2,17.6z'
                            />
                        </g>
                    </g>
                    <path
                        id='DBS_1_'
                        className='st13'
                        d='M21.7,20.6c0.6-0.2,1.4-1.3,1.3-3.1c-0.1-1.7-0.8-3-2.5-3.2c0,0-0.3,0-0.6,0c-0.3,0-0.4,0-0.4,0.3
	c0,0.6-0.1,5.2,0,5.8c0,0.1,0,0.3,0.4,0.3C20.6,20.8,21.2,20.8,21.7,20.6z M21,14c1.4,0,3.4,1,3.3,3.5c0,2.2-1.6,3.5-3,3.5h-3.7
	l0-0.1c0.2,0,0.4-0.2,0.5-0.3c0.1-0.5,0.1-5.3,0-6.1c0-0.2-0.2-0.4-0.5-0.4l0-0.1C17.7,14,20.7,14,21,14z M29,19.3
	c0-1.5-1.1-1.9-2.2-2c0,0,0-0.1,0-0.1c0.4,0,1.8-0.2,1.8-1.6c-0.1-1.4-1.1-1.4-1.5-1.4c-0.3,0-0.4,0-0.5,0c-0.2,0-0.3,0-0.3,0.3
	c0,0-0.1,2.5-0.1,4.2c0,0.9,0,1.7,0,1.7c0,0.2,0,0.3,0.4,0.4c0.4,0,1,0,1.4,0C28.4,20.6,29,20.4,29,19.3z M28.6,17.3
	c1.7,0.4,1.8,1.7,1.8,2C30.3,20.9,29,21,28.4,21h-4l0-0.1c0.3,0,0.5-0.2,0.5-0.7l0-5.4c0-0.6-0.1-0.8-0.5-0.8l0-0.1h3
	c0.6,0,2.2-0.1,2.4,1.4C30,16.7,28.7,17.2,28.6,17.3z M35.1,14L35.1,14l0.1,1.2h-0.1c-0.1-0.5-0.6-1-1.6-1c-1.1-0.1-1.6,0.6-1.7,1.3
	c0,0.9,0.7,1,1.7,1.3c0.5,0.1,2.2,0.3,2.1,2.3c-0.1,1.4-1.3,2-2.9,2c0,0-0.7,0-1.7-0.3c-0.2-0.1-0.2,0-0.3,0.1l-0.1,0l0-1.3l0.1,0
	c0,0.1,0.1,0.4,0.3,0.6c0.2,0.2,0.6,0.6,1.5,0.6c0.9,0,1.7-0.4,1.8-1.4c0-0.3,0-0.9-0.6-1.2c-0.5-0.2-1.8-0.4-2.4-0.9
	c0,0-0.8-0.5-0.6-1.5c0.2-1.4,1.3-1.8,2.4-1.8c0,0,0.9,0,1.7,0.2c0,0,0.2,0.1,0.3,0C35.1,14.1,35.1,14.1,35.1,14L35.1,14z'
                    />
                    <path
                        className='st13'
                        d='M10.2,27.9c-0.2,0-0.5-0.1-0.6-0.1c-0.2-0.1-0.2-0.4,0.1-0.6c0.1,0,0.3,0.1,0.7,0.1c0.2-1.4,0.4-2.7,0.5-3
	c0-0.1,0-0.3,0.3-0.3c0.2,0,0.4,0.1,0.5,0.2c-0.1,0.5-0.3,1.8-0.5,3C12.3,27,13,26.1,13,25c0-0.8-0.6-1.3-1.5-1.3
	c-1.3,0-1.7,0.7-1.8,1.2c0,0,0,0-0.1,0c-0.1,0-0.6-0.3-0.7-0.5c0,0,0.3-1.3,2.2-1.3c1.4,0,2.6,0.8,2.6,2.1c0,1.3-1.1,2.6-2.8,2.7
	L10.7,30c0,0.2,0.4-0.1,0.3,0.2c-0.1,0.3-0.2,0.6-0.4,0.8c0,0.1-0.1,0.1-0.2,0.1c-0.2,0-0.6-0.2-0.7-0.2C9.9,30.1,10,29,10.2,27.9z'
                    />
                    <path
                        className='st13'
                        d='M16,30c-0.2,0.4-0.6,0.8-1.1,0.8c-0.8,0-1.3-0.8-1.3-2c0-1.9,0.9-3.7,2.1-3.7c0.7,0,1,0.5,1,0.8
	c0,0.1,0,0.1,0,0.1c0.1,0,0.5,0,0.4,0.3c-0.3,1.2-0.6,2.2-0.6,3c0,0.5,0.1,0.8,0.4,0.8c0.5,0,0.7-0.7,0.8-1.3c0,0,0.3,0,0.3,0.3
	c0,0.5-0.4,1.7-1.2,1.7C16.3,30.7,16.1,30.3,16,30L16,30z M14.8,30.1c0.6,0,1.2-2.5,1.2-2.8c0,0-0.3-0.1-0.5-0.2
	c0.2-0.3,0.4-0.7,0.4-1c0-0.1,0-0.3-0.2-0.3c-0.8,0-1.2,2.5-1.2,3.5C14.4,29.8,14.5,30.1,14.8,30.1z'
                    />
                    <path
                        className='st13'
                        d='M18,25.4c0-0.1,0.1-0.1,0.2-0.1c0.2,0,0.5,0.2,0.5,0.4c-0.3,1.2-0.7,2.5-0.7,3.7c0,0.3,0,0.5,0.2,0.5
	c0.3,0,0.8-1,0.9-1.5c0.1-0.4,0.4-3.3,0.7-3.3c0.3,0,0.7,0.2,0.7,0.4c0,1-0.5,2.1-0.6,3c-0.2,1-0.5,3.3-0.5,4.3c0,0.1,0,0.1-0.1,0.1
	c-0.2,0-0.5-0.1-0.6-0.2c-0.1-0.1-0.1-0.1-0.1-0.3c0-0.4,0.3-1.8,0.4-2.6c-0.1,0.3-0.4,0.6-0.7,0.6c-0.8,0-1-0.9-1-1.5
	C17.3,27.8,17.7,26.5,18,25.4z'
                    />
                    <path
                        className='st13'
                        d='M20.8,30.3c0.2-1.9,0.7-5.8,1.1-7.1c0.2-0.2,0.7,0.1,0.8,0.3c-0.3,1.3-0.9,4.5-1.1,6.6
	c1.1-0.3,1.9-0.4,2.4-0.3c0.1,0,0.6,0.8,0.3,0.8c-0.8,0-1.6,0.1-2.8,0.4c0,0.1,0,0.2,0,0.3c0,0.2-0.5,0-0.7-0.1c0,0,0,0-0.1,0
	c-0.3,0.1-0.6-0.6-0.5-0.7C20.5,30.4,20.6,30.3,20.8,30.3z'
                    />
                    <path
                        className='st13'
                        d='M26.7,30c-0.2,0.4-0.6,0.8-1.1,0.8c-0.8,0-1.3-0.8-1.3-2c0-1.9,0.9-3.7,2.1-3.7c0.7,0,1,0.5,1,0.8
	c0,0.1,0,0.1,0,0.1c0.1,0,0.5,0,0.4,0.3c-0.3,1.2-0.6,2.2-0.6,3c0,0.5,0.1,0.8,0.4,0.8c0.5,0,0.7-0.7,0.8-1.3c0,0,0.3,0,0.3,0.3
	c0,0.5-0.4,1.7-1.2,1.7C27,30.7,26.8,30.3,26.7,30L26.7,30z M25.5,30.1c0.6,0,1.2-2.5,1.2-2.8c0,0-0.3-0.1-0.5-0.2
	c0.2-0.3,0.4-0.7,0.4-1c0-0.1,0-0.3-0.2-0.3c-0.8,0-1.2,2.5-1.2,3.5C25.1,29.8,25.2,30.1,25.5,30.1z'
                    />
                    <path
                        className='st13'
                        d='M30.3,27c-0.2,0.3-1,2.4-1.1,3.8c0,0,0,0.1-0.3,0.1c-0.4,0-0.7-0.4-0.7-0.6c0-1.1,0.5-7,0.9-7.4
	c0.2-0.2,0.8,0,0.8,0.3c0,0.6-0.2,1-0.3,1.6c-0.1,0.6-0.5,3.3-0.5,3.8l0.1,0c0.2-0.7,1.1-3.2,1.6-3.2c0.3,0,0.8,0.2,0.8,0.4
	c0,0.5-0.5,2.4-0.5,3.7c0,0.4,0.1,0.7,0.5,0.7c0.3,0,0.6-0.6,0.8-1.1c0,0,0.3,0,0.3,0.3c0,0.7-0.7,1.4-1.4,1.4c-0.8,0-1.1-1-1.1-1.7
	c0-0.7,0.2-1.8,0.3-2L30.3,27z'
                    />
                    <path
                        className='st13'
                        d='M35.2,24.6c0,0-0.6-0.1-0.8,0.6c-0.2,0.7-0.7,2.4-0.8,2.8c-0.1,0.4-0.3,1.1-0.1,1.1c0.2,0,0.4,0,0.6-0.6
	c0.2-0.6,1.1-2.6,1.2-3C35.5,25.2,35.5,24.7,35.2,24.6z'
                    />
                    <circle className='st13' cx='33.5' cy='30.1' r='0.6' />
                </Fragment>
            );

        case 'bank_uob':
            return (
                <Fragment>
                    <path
                        className='st143'
                        d='M32.9,5H12.1C8.2,5,5,8.2,5,12.1v20.8c0,3.9,3.2,7.1,7.1,7.1h20.8c3.9,0,7.1-3.2,7.1-7.1V12.1
	C40,8.2,36.8,5,32.9,5'
                    />
                    <g>
                        <g>
                            <path
                                className='st18'
                                d='M14.6,22.3c0,0.2,0.2,0.2,0.4,0.2H15c0,0,0,0,0,0c0.4,0,0.4-0.2,0.4-0.2c0,0,0-0.1,0-0.1v-7.3
			c0-0.2-0.2-0.2-0.4-0.2H15c-0.2,0-0.4,0-0.4,0.2c0,0,0,0.1,0,0.1v7.2c0,0,0,0,0,0'
                            />
                            <path
                                className='st18'
                                d='M9.9,22.3c0,0.2,0.2,0.2,0.4,0.2h0.1c0,0,0,0,0,0c0.4,0,0.4-0.2,0.4-0.2c0,0,0-0.1,0-0.1v-7.3
			c0-0.2-0.2-0.2-0.4-0.2h-0.1c-0.2,0-0.4,0-0.4,0.2c0,0,0,0.1,0,0.1v7.2c0,0,0,0,0,0'
                            />
                            <path
                                className='st18'
                                d='M11.4,22.3c0,0.2,0.2,0.2,0.4,0.2h0.1c0,0,0,0,0,0c0.4,0,0.4-0.2,0.4-0.2c0,0,0-0.1,0-0.1v-7.3
			c0-0.2-0.2-0.2-0.4-0.2h-0.1c-0.2,0-0.4,0-0.4,0.2c0,0,0,0.1,0,0.1v7.2c0,0,0,0,0,0'
                            />
                            <path
                                className='st18'
                                d='M13,22.3c0,0.2,0.2,0.2,0.4,0.2h0.1c0,0,0,0,0,0c0.4,0,0.4-0.2,0.4-0.2c0,0,0-0.1,0-0.1v-7.3
			c0-0.2-0.2-0.2-0.4-0.2h-0.1c-0.2,0-0.4,0-0.4,0.2c0,0,0,0.1,0,0.1v7.2c0,0,0,0,0,0'
                            />
                            <path
                                className='st18'
                                d='M8.9,18.1c-0.2,0-0.2,0.2-0.2,0.4v0.1c0,0,0,0,0,0C8.7,19,8.9,19,8.9,19C9,19,9,19,9,19h7.3
			c0.2,0,0.2-0.2,0.2-0.4v-0.1c0-0.2,0-0.4-0.2-0.4c0,0-0.1,0-0.1,0H9.1c0,0,0,0,0,0'
                            />
                        </g>
                        <g>
                            <path
                                className='st13'
                                d='M27.1,20.9L27.1,20.9c-1.2,0-1.7-0.7-1.7-2.4c0-1.7,0.4-2.3,1.6-2.3h0c1.2,0,1.6,0.6,1.6,2.3
			C28.7,20.2,28.2,20.9,27.1,20.9 M27,15.2c-2.1,0-3,1-3,3.3c0,2.2,1,3.3,2.9,3.3h0.1c2,0,2.9-1.1,2.9-3.3
			C30,16.3,29.1,15.2,27,15.2'
                            />
                            <path
                                className='st13'
                                d='M18.8,21.4c0.4,0.3,1,0.5,1.9,0.5h0.1c0.9,0,1.5-0.1,1.9-0.5c0.6-0.5,0.7-1,0.7-2l0-3.7c0-0.1,0-0.1-0.1-0.2
			c-0.1-0.1-0.3-0.1-0.6-0.1c-0.3,0-0.5,0-0.6,0.1C22,15.5,22,15.6,22,15.6l0,0.7v3.2c0,0.6,0,0.8-0.3,1c-0.3,0.2-0.7,0.2-1,0.2
			c-0.3,0-0.7,0-1-0.2c-0.3-0.2-0.3-0.4-0.3-1l0-3.9c0-0.1,0-0.1-0.1-0.2c-0.1-0.1-0.3-0.1-0.6-0.1c-0.3,0-0.5,0-0.6,0.1
			C18,15.5,18,15.6,18,15.6l0,0v3.7C18,20.4,18.1,20.9,18.8,21.4'
                            />
                            <path
                                className='st13'
                                d='M33.6,20.9h-1.5v-1.9h1.6c0.9,0,1,0.4,1,1C34.7,20.7,34.4,20.9,33.6,20.9 M32.1,16.2h1.5
			c0.8,0,0.8,0.4,0.8,0.9c0,0.6-0.3,0.9-0.9,0.9h-1.4V16.2z M35.2,15.7c-0.3-0.3-0.7-0.3-1.6-0.3H31c-0.1,0-0.2,0.1-0.2,0.2v6
			c0,0.1,0.1,0.2,0.2,0.2h2.8c0.8,0,1.3,0,1.7-0.4c0.5-0.4,0.6-1,0.6-1.5c0-0.6-0.2-1.1-0.6-1.3c-0.2-0.1-0.3-0.2-0.5-0.3l-0.1,0
			l0.1-0.1c0.5-0.3,0.7-0.6,0.7-1.3C35.7,16.6,35.6,16,35.2,15.7'
                            />
                        </g>
                        <rect x='15.3' y='25' className='st13' width='0.5' height='5.4' />
                        <polygon
                            className='st13'
                            points='26.7,30.3 26.7,25 26.1,25 26.1,27.3 23.2,27.3 23.2,25 22.6,25 22.6,30.3 23.2,30.3 23.2,27.7 
		26.1,27.7 26.1,30.3 	'
                        />
                        <polygon className='st13' points='31.6,25 27.4,25 27.4,25.4 29.3,25.4 29.3,30.3 29.7,30.3 29.7,25.4 31.6,25.4 	' />
                        <polyline className='st13' points='34.1,27.8 32.6,25 32,25 33.9,28.2 33.9,30.3 34.4,30.3 34.4,28.2 36.3,25 35.7,25 34.1,27.8 	' />
                        <polygon
                            className='st13'
                            points='13.9,30.3 13.9,25 13.2,25 11.3,29.6 9.5,25 8.7,25 8.7,30.3 9.3,30.3 9.3,26.3 11.1,30.3 11.5,30.3 
		13.3,26.1 13.3,30.3 	'
                        />
                        <path
                            className='st13'
                            d='M19.3,28.2h1.5v1.2c0,0-0.4,0.5-1.3,0.5c-1.1,0-1.6-0.8-1.6-2.4c0-1.5,0.5-2.2,1.6-2.2c0.7,0,1.2,0.4,1.4,1.2
		h0.6c-0.2-1.1-0.9-1.6-2-1.6c-1.8,0-2.1,1.5-2.1,2.7c0,1,0.3,2.8,2.2,2.8c1.5,0,1.9-0.8,1.9-0.8v-1.9h-2V28.2z'
                        />
                    </g>
                </Fragment>
            );

        default:
            return <path />;
    }
};

const getStyle = (name) => {
    switch (name) {
        case 'bank_dbs':
        case 'bank_uob':
            return `
	.st13{fill:#FFFFFF;}
	.st18{fill:#E21F26;}
	.st143{fill:#263471;}
	.st144{fill:#2F2F2F;}
	.st145{fill:#EC2C25;}
				`;

        default:
            return;
    }
};

const SVGBankApp = ({ name = '', style = {}, fill = '', viewBox = '', width = '100%', height = '100%', className = '', customClassName = '' }) => (
    <svg
        width={width}
        style={style}
        height={height}
        className={`${className} ${customClassName}`}
        xmlns='http://www.w3.org/2000/svg'
        viewBox={viewBox || getViewBox(name)}
        xmlnsXlink='http://www.w3.org/1999/xlink'
    >
        <style dangerouslySetInnerHTML={{ __html: getStyle(name) }}></style>
        {getPath(name, { fill })}
    </svg>
);

export default SVGBankApp;
