
import commonUtil from 'root/utils/common-util';

const controller = {
    vm: null,

    init(vm) {
        controller.vm = vm;
        let currentDate = new Date();

        let dateMonth = currentDate.getMonth() +1;
        let dateYear = currentDate.getFullYear();
        controller.vm.state = {
            navList: [
                {
                    path: '/myaccount/deposit',
                    key: 'deposit',
                    name: 'settings:settings.tabs.heading.deposit',
                },
                {
                    path: '/myaccount/transfer',
                    key: 'transfer',
                    name: 'settings:settings.tabs.heading.transfer',
                },
                {
                    path: '/myaccount/withdraw',
                    key: 'withdraw',
                    name: 'settings:settings.tabs.heading.withdraw',
                },
                {
                    path: '/myaccount/history',
                    key: 'history',
                    name: 'settings:settings.tabs.heading.history',
                },
            ],
            selectedMenuColor: '',
            inboxCount: 0,
            notificationCount: 0,
            memberGroupImg: '',
            memberGroupColor: '',
            // leaderboard fields
            fields: {
                dateYear: dateYear,
                dateMonth: dateMonth,
            },
        };
    },


    initCompSettings() { 
        const { history, user } = controller.vm.props;
        return new Promise((resolve) => { 
            // Step 1: Standard way to get from portal settings 
            let leaderboardSettings = commonUtil.getSettingFromPortalSettings({
                vm: controller.vm,
                settingName: 'leaderboardSettings',
            });

            let showLeaderboardCurrency = false

            if (user && user.account && user.account.currency) {
                if (leaderboardSettings && leaderboardSettings.leaderboardCurrencyShow) {
                    showLeaderboardCurrency = leaderboardSettings.leaderboardCurrencyShow.some((currency) => currency === user.account.currency);
                }
            }
            // Step 2: Massage again to get the specific data you need (Skip if not needed)
            // Step 3: Initial all into compSettings
            let compSettings = {
                leaderboardCurrencyShow: showLeaderboardCurrency,
            };

            controller.vm.setState({ compSettings }, () => {
                resolve();
            });
        }); 
    }
}

export default controller;