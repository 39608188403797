import { regionConstants } from '../constant/';

export const regionAction = {
    fetchCountryJson,
    fetchProcessedRegions,
    fetchRegionSettingsJson,
    fetchProcessedInternationalRegions,
};

function fetchCountryJson() {
    return (dispatch) => {
        window.SPL_JsonSettings.getCountryJson(function (countryJson) {
            dispatch({ type: regionConstants.FETCH_COUNTRY_JSON, payload: { countryJson } });
        });
    };
}

/**
 * get processed regions
 * NOTE: required fetchCountryJson first
 */
function fetchProcessedRegions() {
    return (dispatch, getState) => {
        const { regionReducer } = getState();

        if (regionReducer?.processedData?.regions) {
            return;
        }
        const countryRawJson = regionReducer?.rawData?.countryJson;

        const finalRegions = [];
        (Object.keys(countryRawJson) || []).forEach((telCode) => {
            const regionData = countryRawJson?.[telCode];
            const updatedRegionData = {
                ...regionData,
                telCode: `+${telCode}`,
            };
            finalRegions.push(updatedRegionData);
        });

        dispatch({ type: regionConstants.FETCH_PROCESSED_REGIONS, payload: { regions: finalRegions } });
    };
}

/**
 * get international region settings
 */
function fetchRegionSettingsJson() {
    return async (dispatch, getState) => {
        const { regionReducer } = getState();

        if (regionReducer?.rawData?.regionSettingsJson) {
            return;
        }

        const { portal } = getState();
        const supportedRegions = Object.keys(portal?.settings?.registerConfiguration?.internationalFeature?.supportedRegion) || [];

        const [data] = await Promise.all([window.SPL_Register.getRegionSettingJson(supportedRegions)]);
        dispatch({ type: regionConstants.FETCH_REGION_SETTINGS_JSON, payload: { regionSettingsJson: data } });
        return { regionSettingsJson: data };
    };
}

/**
 * get processed international regions
 * NOTE: required fetchRegionSettingsJson first
 */
function fetchProcessedInternationalRegions() {
    return async (dispatch, getState) => {
        const { regionReducer } = getState();

        if (regionReducer?.processedData?.internationalRegions) {
            return;
        }

        try {
            const regionSettingsJson = regionReducer?.rawData?.regionSettingsJson;
            const internationalRegions = [];

            if (!regionSettingsJson) {
                return;
            }
            regionSettingsJson.forEach((regionSetting) => {
                // filters the regions if the region doesn't have countryCode or supportCountry
                if (!regionSetting?.countryCode || !regionSetting?.supportCountry) {
                    return;
                }

                const updatedRegionSetting = {
                    ...regionSetting,
                    telCodeFlag: '/public/html/default_whitelabel/template/images/international-icon/' + regionSetting.countryCode + '.png',
                };
                internationalRegions.push(updatedRegionSetting);
            });
            dispatch({ type: regionConstants.FETCH_PROCESSED_INTERNATIONAL_REGIONS, payload: { internationalRegions: internationalRegions } });
        } catch (err) {
            console.error(err);
            return;
        }
    };
}
