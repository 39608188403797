import { popupConstants } from '../constant';

import { POPUP_TYPE } from '@constants';

const initialState = {
    page: false,
    data: null,
    isActive: false,
    items: {
        [POPUP_TYPE.VERIFY_EMAIL]: false,
        [POPUP_TYPE.VERIFY_PHONE]: false,
        [POPUP_TYPE.GA]: false,
        [POPUP_TYPE.EDIT_VERIFY_AUTH]: false,
        [POPUP_TYPE.PIN_ENTRY]: false,
        [POPUP_TYPE.SIX_PIN_SETUP]: false,
    },
    config: {
        [POPUP_TYPE.EDIT_VERIFY_AUTH]: {
            selectedAuthContact: null, // used for control control fields/translation for edit member info popup
        },
        [POPUP_TYPE.PIN_ENTRY]: {
            // used for control translations, GA / SIX_PIN using same layout and logic.
            isGaEnabled: false,
            isSixPinEnabled: false,
        },
    },
    submitSuccessCb: null,
};

export function popup(state = initialState, action) {
    let isActive = false;
    let popupProps = {};
    if (action && action.payload && action.payload.isActive) {
        isActive = action.payload.isActive;
    }
    if (action && action.payload && action.payload.popupType) {
        popupProps = action.payload.popupProps;
    }
    switch (action.type) {
        case popupConstants.POPUP_UPDATE:
            return {
                ...state,
                page: action.payload.page,
                data: action.payload.data,
            };

        case popupConstants.SET_POPUP_VISIBLE:
            if (isActive) {
                document.body.classList.add('popup-open');
            } else {
                document.body.classList.remove('popup-open');
            }
            return {
                ...state,
                isActive: action.payload.isActive,
            };

        case popupConstants.TOGGLE_POPUP:
            return {
                ...state,
                popupType: action.payload.popupType,
                popupProps: popupProps,
            };

        case popupConstants.SET_POPUP:
            return {
                ...state,
                items: {
                    ...state.items,
                    [action?.payload?.item]: action?.payload?.isVisible,
                },
            };

        case popupConstants.SET_SUBMIT_SUCCESS_CB:
            return {
                ...state,
                submitSuccessCb: action?.payload?.fn,
            };
        default:
            return state;
    }
}

// similar, just change to use this name for easier trace purpose.
export function popupReducer(state = initialState, action) {
    switch (action.type) {
        case popupConstants.SET_POPUP:
            return {
                ...state,
                items: {
                    ...state.items,
                    [action?.payload?.item]: action?.payload?.isVisible,
                },
            };

        case popupConstants.SET_SUBMIT_SUCCESS_CB:
            return {
                ...state,
                submitSuccessCb: action?.payload?.fn,
            };

        case popupConstants.SET_ENTERED_PIN:
            return {
                ...state,
                enteredPin: action?.payload?.enteredPin,
            };

        // each popup might have config required
        case popupConstants.SET_ITEM_CONFIG:
            return {
                ...state,
                config: {
                    ...state.config,
                    [action?.payload?.item]: action?.payload?.config,
                },
            };
        default:
            return state;
    }
}
