import { depositConstant } from '../constant';
export const depositAction = {
    updateDepositSubmission,
};

function updateDepositSubmission(submissionData) {
    return (dispatch) => {
        dispatch({ type: depositConstant.UPDATE_DEPOSIT_SUBMISSION, payload: submissionData });
    };
}
