export const EVENT_TYPE = {
    TRANSFER_LOAD_PROVIDER_WALLET: 'TRANSFER_LOAD_PROVIDER_WALLET',
};

class EventEmitter {
    events;
    constructor(events) {
        this.events = events || {};
    }

    subscribe(name, cb) {
        const events = this.events;
        (events[name] || (events[name] = [])).push(cb);
        return {
            unsubscribe: () => events[name] && events[name].splice(events[name].indexOf(cb) >>> 0, 1),
        };
    }

    emit(name, ...args) {
        (this.events[name] || []).forEach((fn) => fn(...args));
    }
}

const eventEmitter = new EventEmitter();
export default eventEmitter;

// reference: https://css-tricks.com/understanding-event-emitters
