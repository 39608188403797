import { portalConstants } from '../constant';
export const portalAction = {
    setSettings,
    pageUpdated,
    floatingPageUpdated,
    portalIsLoading,
    affiliateValidated,
    setIsSubmitting,
};

function setSettings(settings) {
    return (dispatch) => {
        dispatch({ type: portalConstants.SET_PORTAL_SETTINGS, payload: settings });
    };
}

function pageUpdated(pathname) {
    return (dispatch) => {
        dispatch({ type: portalConstants.PAGE_UPDATED, payload: { pathname: pathname } });
    };
}

function floatingPageUpdated(type) {
    let view = {
        sidemenu: false,
        register: false,
        login: false,
    };

    switch (type) {
        case 'login':
            view.login = true;
            document.body.style.overflow = 'hidden';
            break;
        case 'register':
            view.register = true;
            document.body.style.overflow = 'hidden';
            break;
        case 'reset':
            view.reset = true;
            document.body.style.overflow = 'hidden';
            break;
        case 'sidemenu':
            view.sidemenu = true;
            document.body.style.overflow = 'hidden';

            if (!window.isAgent && !window.isAffiliate) {
                if (window.SPL_LiteApp.isZT828LiteApp()) {
                    window.SPL_LiteApp.onSideMenuToggle(view.sidemenu);
                }
            }

            break;
        default:
            document.body.style.overflow = '';
            break;
    }

    return (dispatch) => {
        dispatch({ type: portalConstants.FLOATING_PAGE_UPDATED, payload: { view: view } });
    };
}

function portalIsLoading(flag) {
    return (dispatch) => {
        dispatch({ type: portalConstants.PORTAL_LOADING, payload: { isLoading: flag } });
    };
}

function affiliateValidated(flag) {
    return (dispatch) => {
        dispatch({ type: portalConstants.AFFILIATE_VALIDATED, payload: flag });
    };
}

function setIsSubmitting(flag) {
    return (dispatch) => {
        dispatch({ type: portalConstants.SET_IS_SUBMITTING, payload: { isSubmitting: flag } });
    };
}
