import React, { lazy } from 'react'
import { connect } from 'react-redux'
import { mapStateToProps } from 'root/redux/selector/selector'
import LazyComponent from 'root/webapp/shared/lazyComponent'
import core from 'root/utils/core';

// lazy load by component because there will be many component in selector
// but only use 1 component at 1 time
const MDSponsorship = lazy(() => import('./sponsorship'))

class Sponsorship extends React.Component {

    components = {
        desktop: {
            default: LazyComponent(MDSponsorship),
            sponsorship: LazyComponent(MDSponsorship),
        },
        mobile: {
            default: LazyComponent(MDSponsorship),
            sponsorship: LazyComponent(MDSponsorship),
        }
    };

    render() {
        const TagName = core.getCorrectComponent(this.components, 'sponsorship', this.props.screen.viewType);
        return <TagName />
    }
}

export default connect(mapStateToProps, null)(Sponsorship);