// library
import React from 'react';
import { DebounceInput } from 'react-debounce-input';

// components / selector
import styles from './input.module.scss';

const Input = ({ debouncedInputMS = 0, type, name, value, className, onFocus, onChange, onBlur, placeholder, disabled, readOnly, onKeyPress }) => {
    return (
        <DebounceInput
            debounceTimeout={debouncedInputMS}
            type={type}
            name={name}
            className={`${styles.Input_input} standard-input ${className || ''}`}
            value={value}
            onFocus={onFocus}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            disabled={disabled}
            readOnly={readOnly}
            onKeyPress={onKeyPress}
        ></DebounceInput>
    );
};

export default Input;
