import React from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import GameList from 'root/webapp/shared/component/GameList';
import Loading from 'root/webapp/shared/loading/loading';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import JackpotCarousel from 'root/webapp/shared/component/JackpotCarousel';
import { useGetJustForYouGames, useGetMemberFavoriteGames } from '@hooks/provider-hook';
import { providerAction } from '../../../../redux/action';

class DroppingHot extends React.Component {
    test = null;
    constructor() {
        super();
        this.state = {
            active: 0,
            navList: [],
            list: null,
            isFavGame: false,
            hotGameList: [],
            favoritedGame: [],
            jackpotList: [],
            gameType: 'recommend',
            jackpotAmount: null,
        };
    }

    componentDidMount() {
        this.getHotGames();
        this.getGamesTab();
        this.test = this;
    }

    componentDidUpdate(prevProps) {
        if (this.props.jackpotList && this.state.jackpotList.length !== this.props.jackpotList.length) {
            this.setState({ jackpotList: this.props.jackpotList });
        }

        if (this.state.jackpotAmount === null && this.props.jackpotObj && this.props.jackpotObj.amount > 0) {
            this.setState({ jackpotAmount: this.props.jackpotObj.amount });
        }

        if (
            this.state.navList[this.state.active].name === 'global:global.hotgames.favourite-games' &&
            this.state.list &&
            this.props.providerReducer?.processedData?.favoriteGames &&
            this.state.list.length !== this.props.providerReducer?.processedData?.favoriteGames.length
        ) {
            this.setState({ list: this.props.providerReducer?.processedData?.favoriteGames, isFavGame: true });
        }

        if (prevProps?.providerReducer?.processedData?.justForYouGame !== this.props.providerReducer?.processedData?.justForYouGame) {
            this.getHotGames();
        }
    }

    getHotGames() {
        this.setState({ list: this.props?.providerReducer?.processedData?.justForYouGame?.[this.state.gameType] });
        // memberService.getJustForYouGame(this, this.props, () => {
        //     this.setState({ list: this.state.hotGameList });
        // });
    }

    getGamesTab() {
        const { isLogin } = this.props.user;
        let favouriteTab = { name: 'global:global.hotgames.favourite-games' },
            navList = [{ name: 'global:global.hotgames.recommend-games' }, { name: 'global:global.hotgames.hot-games' }];

        if (isLogin) {
            navList.splice(1, 0, favouriteTab);
        }

        this.setState({ navList: navList });
    }

    toggleTab(type, index) {
        if (index == this.state.active) return;
        this.setState({ active: index, list: null });

        if (type === 'global:global.hotgames.favourite-games') {
            this.setState({ list: this.props.providerReducer?.processedData?.favoriteGames, isFavGame: true });
        } else if (type === 'global:global.hotgames.recommend-games') {
            this.setState({ isFavGame: false, gameType: 'recommend' }, () => {
                this.getHotGames();
            });
        } else if (type === 'global:global.hotgames.hot-games') {
            this.setState({ isFavGame: false, gameType: 'hot' }, () => {
                this.getHotGames();
            });
        }
    }

    gotoSlot() {
        this.props.history.push('/slots');
    }

    updateMemberFavoriteGame(game) {
        this.props.dispatch(providerAction.updateMemberFavoriteGames(game));
        // memberService.updateMemberFavoriteGame(this.parentVm, this, game);
    }

    render() {
        const { navList } = this.state;

        let favouriteSource = {
            favoritedGame: this.props.providerReducer?.processedData?.favoriteGames,
            updateMemberFavoriteGame: this.updateMemberFavoriteGame,
        };
        return (
            <Translation>
                {(t) => (
                    <div className='dropping-hot'>
                        <DroppingHotInit />
                        {this.props?.portal?.isSubmitting && <Loading className='vw-100 vh-100'></Loading>}

                        <div className='container'>
                            <span className='item-title'>{t('global:global.hotgames.title', "DROPPING LIKE IT'S HOT!")}</span>

                            <div className='group'>
                                {/* {this.state.jackpotList && this.state.jackpotList.length > 0 &&
                  <div className="slider-box">
                    <Slider items={this.state.jackpotList} transionName="slider-fade" />
                  </div>
                } */}
                                <div className='slider-box'>
                                    <div className='slider'>
                                        <div className='slider-container'>
                                            <div>
                                                <div>
                                                    <LazyLoadImage src={`/public/html/images/homepage/top_game/jackpot-SG.png`} alt='' />
                                                    <div className='content'>
                                                        <span className='jackpot'>{t('global:global.hotgames.progressiveJackpot', 'PROGRESSIVE JACKPOT')}</span>
                                                        <div className='icon-box'></div>
                                                        {this.state.jackpotAmount && (
                                                            <JackpotCarousel amount={this.state.jackpotAmount} customClass={'home-jackpot-carousel'}></JackpotCarousel>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='games-list'>
                                    <ul className='nav-tabs'>
                                        {navList.length > 0 &&
                                            navList.map(({ name }, i) => (
                                                <li
                                                    className={[i == this.state.active ? 'on' : ''].join(' ')}
                                                    key={i}
                                                    onClick={() => {
                                                        this.toggleTab(name, i);
                                                    }}
                                                >
                                                    {name == 'global:global.hotgames.recommend-games' && (
                                                        <span data-screen='mobile'>{t('global:global.hotgames.recommend', 'RECOMMEND')}</span>
                                                    )}
                                                    {name == 'global:global.hotgames.favourite-games' && (
                                                        <span data-screen='mobile'>{t('global:global.hotgames.favourite', 'FAVOURITE')}</span>
                                                    )}
                                                    <span> {t(name)} </span>
                                                </li>
                                            ))}
                                        <div
                                            className='more-games'
                                            data-screen='web|pad'
                                            onClick={() => {
                                                this.gotoSlot();
                                            }}
                                        >
                                            <span>{t('global:global.hotgames.more-games', 'more games')}</span>
                                            <span> &gt; </span>
                                        </div>
                                        <div
                                            className='more-games'
                                            data-screen='mobile'
                                            onClick={() => {
                                                this.gotoSlot();
                                            }}
                                        >
                                            <span>{t('global:global.hotgames.more', 'MORE')}</span>
                                            <span> &gt; </span>
                                        </div>
                                    </ul>

                                    {this.state.list === null ? (
                                        <div></div>
                                    ) : this.state.list?.length > 0 ? (
                                        <TransitionGroup className='game-content-container'>
                                            <CSSTransition key={this.state.active} classNames='fade-next' timeout={500}>
                                                <GameList
                                                    list={this.state.list}
                                                    isFavGame={this.state.isFavGame}
                                                    maxGameShow={this.props.maxGameShow}
                                                    {...favouriteSource}
                                                    parentVm={this}
                                                    searchKeyword={this.props.searchKeyword}
                                                    history={this.props.history}
                                                />
                                            </CSSTransition>
                                        </TransitionGroup>
                                    ) : this.props?.portal?.isSubmitting ? (
                                        <div></div>
                                    ) : (
                                        <span className='no-data'>{t('slot:slot.noGameAdded', 'No Game Added')}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['global', 'slot'])(withRouter(DroppingHot)));

export const DroppingHotInit = () => {
    useGetJustForYouGames();
    useGetMemberFavoriteGames();
    return <></>;
};
