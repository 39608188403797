const controller = {
    vm: null,

    init(vm) {
        this.vm = vm;
        this.vm.state = {
            faqContent: '',
            isLoading: true,
        };
    },

    getFaq() {
        const { language } = controller.vm.props;

        window.SPL_Content.getFaqPage02(language.countryLanguageKey, true).then((res) => {
            if (res && res.content && res.content.includes('{{MerchantName}}')) {
                res.content = res.content.replace('{{MerchantName}}', window.globMerchantName);
            }

            controller.vm.setState({ faqContent: res.content, isLoading: false });
        });
    },
};

export default controller;
