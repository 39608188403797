// react/library
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation, Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';

// components/files
import MobileSelect from 'root/webapp/shared/component/mobileSelect/mobileSelect';

// controller/logic
import vm from '../../qrcode-pay.controller';
import transactionHelper from 'root/utils/transaction-helper';
import { trans_getDepositAmountOptionByMethod } from 'root/utils/transaction-util';
import { AMOUNT_DEPOSIT_METHOD_CODE } from 'root/utils/constants';

import TransactionNote from 'root/webapp/shared/transaction-note/transaction-note.selector';
import BankIcon from '../../../../../../../../components/molecules/bank-icon/bank-icon';
import PaymentGatewayIcon from '../../../../../../../../components/molecules/payment-icon/payment-icon';
class QrCodePay extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
    }

    componentDidMount() {
        vm.initCompSettings();
        if (this.state.depositAllSettings !== null) {
            vm.loadQRbank();
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    componentDidUpdate(prevProps) {
        const prevDepositAmountOptions = JSON.stringify(trans_getDepositAmountOptionByMethod(prevProps.depositAmountOptions, AMOUNT_DEPOSIT_METHOD_CODE.QR));
        const currentDepositAmountOptions = JSON.stringify(this.state.depositAmountOptions);
        if (prevDepositAmountOptions !== currentDepositAmountOptions) {
            this.setState({ depositAmountOptions: trans_getDepositAmountOptionByMethod(this.props.depositAmountOptions, AMOUNT_DEPOSIT_METHOD_CODE.QR) });
        }
    }

    render() {
        const {
            bankList,
            depositAmountOptions,
            depositMinMaxLimit,
            isFormValid,
            disableDepositButton,
            qrpaySupportedBankList,
            selectedMethodIndex,
            showAmountField,
            depositAllSettings,
            selectedBankIndex,
            fields,
            errors,
            amountErrorValue,
            methodList,
            compSettings,
            customFilename,
        } = this.state;

        let transactionNoteProps = {
            hideDisplayInfo: compSettings && compSettings.hideDisplayInfo,
            showCustomArticle: this.state.showCustomDepositArticle,
            customArticleContent: this.state.showCustomDepositArticleContent,
            customArticleIsJson: this.state.customArticleIsJson,
            customContentStyle: this.state.customContentStyle,
            depositMinMaxLimit: this.state.depositMinMaxLimit,
            customFilename: customFilename,
        };
        const { portal } = this.props;
        let userCurrency = this.props.user && this.props.user.account && this.props.user.account.currency ? this.props.user.account.currency : null;

        if (userCurrency === 'KHUSD') userCurrency = 'USD';

        return (
            <Translation>
                {(t) => (
                    <Fragment>
                        {this.state.showQrBankList && (
                            <div
                                className='qrbanklist-box'
                                onClick={() => {
                                    vm.toggleQrPayBankList(false);
                                }}
                            >
                                <div
                                    className='qrbanklist-popup'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <div className='title'>
                                        <span>{t('transaction:transaction.deposit.qrpay.supportedBanks.popup-title', 'List of supported bank for QR code deposit')}</span>
                                        <i
                                            className='icon-close3'
                                            onClick={() => {
                                                vm.toggleQrPayBankList(false);
                                            }}
                                        ></i>
                                    </div>
                                    <div className='content qrbanklist-content'>
                                        {this.state.qrpaySupportedBankList.map((bank, index) => {
                                            return bank.isDummyBank ? (
                                                <div className='bank-container' key={index} style={{ marginRight: index % 3 === 0 ? '0' : '0.15rem' }}></div>
                                            ) : (
                                                <div className='bank-container' key={index} style={{ marginRight: index % 3 === 0 ? '0' : '0.15rem' }}>
                                                    <div className='icon-container'>
                                                        <BankIcon bankCode={bank.code} />
                                                    </div>
                                                    <div className='bankname-container'>{bank.bankName}</div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className='group-item bank-options'>
                            <div className='title'>
                                <span>{t('transaction:transaction.deposit.onlinetransfer.bank', 'Bank Options')}</span>
                                <span className='required'>*</span>
                            </div>
                            <div className='bank-list bank-selector'>
                                <ul className='bank-list'>
                                    {methodList.map((e, i) => (
                                        <li
                                            key={i}
                                            className='small-bank bank-acc-list'
                                            onClick={() => {
                                                vm.changeQRChannel(e, i);
                                            }}
                                        >
                                            <div className='member-bankacc'>
                                                <label>
                                                    <input
                                                        type='radio'
                                                        name='bank-account'
                                                        value='0'
                                                        checked={selectedMethodIndex === i}
                                                        onChange={() => {
                                                            vm.changeQRChannel(e, i);
                                                        }}
                                                    />
                                                    <span className='checkmark'></span>
                                                    <div className='banklist-group'>
                                                        <div className='group-box bankgroup-box-container'>
                                                            <div className={`bank-img ${e.channel === `ezpqr` ? 'wider-bank-img' : ''}`}>
                                                                <div
                                                                    className={[
                                                                        'icon-bank',
                                                                        e.channel === `ezpqr` ? 'icon-bank-wider' : 'icon-bank-big',
                                                                        selectedMethodIndex === i ? 'icon-bank-on' : 'icon-bank-off',
                                                                    ].join(' ')}
                                                                >
                                                                    <div className={`icon-${e.channel}`}>
                                                                        <PaymentGatewayIcon pgCode={e.channel} />
                                                                    </div>
                                                                </div>
                                                                <div className='bank-active'></div>
                                                            </div>

                                                            <div className='bank-name'>{e.displayName}</div>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        {bankList.length > 0 && (
                            <MobileSelect
                                itemList={bankList}
                                itemKey={'name'}
                                onChangeIndexType={true}
                                onChange={(e) => vm.onBankChanged(bankList[selectedBankIndex], e)}
                                itemIndex={selectedBankIndex}
                                title={t('transaction:transaction.deposit.onlinetransfer.qrOption', 'Options')}
                                required={true}
                            />
                        )}

                        {showAmountField && (
                            <div className='group-item input-item'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.onlinetransfer.amount', 'Deposit Amount')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='amount-content special-group'>
                                    <div className='input-box'>
                                        <div className='deposit-input-box'>
                                            <input
                                                id='depositamount'
                                                type='numeric'
                                                autoComplete='off'
                                                placeholder={t('global:global.form.online-transfer-amount-placeholder', {
                                                    min: window.SPL_Other.formatAmount(depositMinMaxLimit ? depositMinMaxLimit.min : 0),
                                                    max: window.SPL_Other.formatAmount(depositMinMaxLimit ? depositMinMaxLimit.max : 0),
                                                })}
                                                min={'0'}
                                                value={fields['amount']}
                                                onChange={(e) => vm.handleAmountChange(e)}
                                                onBlur={() => {
                                                    transactionHelper.handleValidation(this);
                                                }}
                                            />
                                            {portal?.settings?.features?.depositPagesSettings?.enableAccValueButton && depositAmountOptions?.length > 0 && (
                                                <div className='deposit-amount-clear-button' onClick={() => transactionHelper.clearInputFieldAmount(this)}>
                                                    <img src='/public/html/images/settings/clear-button.svg' alt='clear-button-img' />
                                                </div>
                                            )}
                                        </div>
                                        <div className='error-validation'>
                                            <Trans
                                                i18nKey={errors['amount']}
                                                values={{ amount: window.SPL_Other.formatAmount(amountErrorValue), currency: userCurrency }}
                                            ></Trans>
                                        </div>
                                    </div>
                                    <ul>
                                        {depositAmountOptions.map((item) => (
                                            <li
                                                key={item}
                                                onClick={() => {
                                                    transactionHelper.onDepositAmountClicked(this, item);
                                                }}
                                            >
                                                {item}
                                            </li>
                                        ))}
                                    </ul>
                                    <div className='input-box'>
                                        <input
                                            className='qr-reminder'
                                            type='text'
                                            readOnly={true}
                                            value={t('transaction:transaction.deposit.qrpay.reminder', 'Reminder: QR deposit can only be used to scan once only')}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        {qrpaySupportedBankList.length > 0 && (
                            <div className='group-item input-item'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.qrpay.supportedBanks.title', 'Supported Banks')}</span>
                                </div>
                                <div className='amount-content'>
                                    <div
                                        className='qr-information'
                                        onClick={() => {
                                            vm.toggleQrPayBankList(true);
                                        }}
                                    >
                                        {t('transaction:transaction.deposit.qrpay.supportedBanks.detail', 'Click to see the bank list for QR code deposit')}
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className='group-item submit-container'>
                            <div className='title'></div>
                            <div className='amount-content'>
                                <div className='flexbox m-standard-2-button-container'>
                                    <button
                                        className='btn btn-submit standard-button'
                                        onClick={() => {
                                            vm.submitForm();
                                        }}
                                        disabled={!isFormValid || disableDepositButton || this.state.receiptRequired}
                                    >
                                        {t('transaction:transaction.deposit.deposithistory.submitbtn', 'SUBMIT').toUpperCase()}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <TransactionNote {...transactionNoteProps} />
                    </Fragment>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'settings'])(withRouter(QrCodePay)));
