import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { TweenMax } from 'gsap';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import LeftSubMenu from 'root/webapp/shared/component/wap/LeftSubMenu';
import HomeMemberProfile from 'root/webapp/shared/component/wap/HomeMemberProfile';

import vm from '../e-sports.controller';

class Esports extends React.Component {
    tween1;
    tween2;

    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        vm.getEsportsProviderList(true).then((data) => {
            this.toggleAni();
        });
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    toggleAni() {
        this.tween1 && this.tween1.kill();
        this.tween2 && this.tween2.kill();

        TweenMax.set('.brand-page ul.ani-ul > li', {
            alpha: 0,
        });

        this.tween1 = TweenMax.delayedCall(0.3, function () {
            this.tween2 = TweenMax.staggerTo('.brand-page ul.ani-ul > li', 0.3, { alpha: 1 }, 0.08);
        });
    }

    checkMaintenance(esport) {
        
        if (esport) {
            if (!esport.maintenance){
                vm.launchGame(esport.provider, esport.isDisableProvider);
            }
        }
    }

    render() {
        return (
            <Translation>
                {(t) => (
                    <Fragment>
                        <div className='esports-page'>
                            <HomeMemberProfile />
                            <div className='esports-box-container'>
                                <div className='brand-page'>
                                    <LeftSubMenu />
                                    <div className='esports-list-box'>
                                        <span className='item-title'>{t('sport:sport.mobile.esport_title', 'CHOOSE YOUR FAVORITE PROVIDERS!')}</span>
                                        <ul className='ani-ul'>
                                            {this.state.list.map((esportsObj, i) => (
                                                <li
                                                    key={esportsObj.key}
                                                    onClick={() => {
                                                        this.checkMaintenance(esportsObj);
                                                    }}
                                                    className={`${esportsObj.isDisableProvider ? 'provider-disable' : ''}`}
                                                >
                                                    <LazyLoadImage 
                                                        src={'/public/html/images/e-sports/mobile/' + esportsObj.key + '.png'} 
                                                        alt='' 
                                                        className={esportsObj.maintenance ? 'maintenance-cover' : ''} 
                                                    />
                                                    {esportsObj.isHotProvider && (
                                                        <div className='hotnewicon-esports-container'>
                                                            <LazyLoadImage src='/public/html/images/hoticon_mobile.svg' alt=''></LazyLoadImage>
                                                        </div>
                                                    )}
                                                    {esportsObj.isNewProvider && (
                                                        <div className='hotnewicon-esports-container'>
                                                            <LazyLoadImage src='/public/html/images/newicon_mobile.svg' alt=''></LazyLoadImage>
                                                        </div>
                                                    )}
                                                    <p className={esportsObj.maintenance ? 'maintenance-cover' : ''} >{t(esportsObj.mobileText)}</p>

                                                    {esportsObj.maintenance && 
                                                        <div className='maintenance-hover-box'></div>
                                                    }
                                                </li>
                                                
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation('sport')(withRouter(Esports)));
