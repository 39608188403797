// react/library
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';

// components/files
import MobileSelect from 'root/webapp/shared/component/mobileSelect/mobileSelect';
import TransactionNote from 'root/webapp/shared/transaction-note/transaction-note.selector';

// controller/logic
import vm from '../../express-deposit.controller';
import BankIcon from '../../../../../../../../components/molecules/bank-icon/bank-icon';

class QrCodePay extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
    }

    componentDidMount() {
        vm.initCompSettings();
        if (this.state.depositAllSettings !== null) {
            vm.loadExpressDepositFilteredBank();
            vm.loadBankAccounts();
            vm.getCorrectDepositMinMaxLimit();
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    componentDidUpdate() {}

    render() {
        const {
            showAllBankMaintenaceMsg,
            showNotBankSupportMsg,
            expressDepositMemberBankList,
            expressDepositMerchantBankList,
            verifyAccountList,
            isFormValid,
            fields,
            verifyBank,
            compSettings,
        } = this.state;

        let transactionNoteProps = {
            hideDisplayInfo: this.state.compSettings.hideDisplayInfo,
            customOnlineTransactionMode: this.props.t('transaction:transaction.deposit.onlinetransfer.online', 'Online'),
            depositMinMaxLimit: this.state.depositMinMaxLimit,
            showCustomArticle: this.state.showCustomDepositArticle,
            customArticleContent: this.state.showCustomDepositArticleContent,
            customArticleIsJson: this.state.customArticleIsJson,
            customContentStyle: this.state.customContentStyle,
            hideDefaultNote: [!this.state.showDefaultArticle, true, true],
            transactionMethodName: this.props.t('transaction:transaction.transfer.quickTransfer', 'Quick Pay'),
        };

        let userCurrency = this.props.user && this.props.user.account && this.props.user.account.currency ? this.props.user.account.currency : null;

        if (userCurrency === 'KHUSD') userCurrency = 'USD';

        return (
            <Translation>
                {(t) => (
                    <>
                        <div className='group-item bank-options'>
                            <div className='title'>
                                <span>{t('transaction:transaction.deposit.expressDeposit.toBankAccount', 'To Bank Account')}</span>
                                <span className='required'>*</span>
                            </div>
                            <div className='bank-list express-deposit-box'>
                                {expressDepositMemberBankList && !this.state.showAllBankMaintenaceMsg ? (
                                    <Fragment>
                                        {expressDepositMerchantBankList && !showNotBankSupportMsg ? (
                                            <Fragment>
                                                {expressDepositMerchantBankList.map((bank, i) => {
                                                    return (
                                                        <ul className='bank-column-box'>
                                                            <Fragment>
                                                                <li className='icon-hover-box'>
                                                                    <div className={'bank-icon-box'}>
                                                                        <BankIcon bankCode={expressDepositMemberBankList.bankCode} />

                                                                        <span className='bank-name'>{bank.name}</span>
                                                                    </div>
                                                                </li>
                                                                <div className='express-deposit-bank-list'>
                                                                    <span className='account-holder'>{bank.accountName}</span>
                                                                    <span className='account-holder'>{bank.accountNumber}</span>
                                                                </div>
                                                            </Fragment>
                                                        </ul>
                                                    );
                                                })}
                                            </Fragment>
                                        ) : (
                                            <span className='request-text'>
                                                {t(
                                                    'transaction:transaction.deposit.expressDeposit.unavailableExpressBank',
                                                    'Express Deposit unavailable now, Please SELECT other Deposit Option, sorry for inconvenience caused'
                                                )}
                                            </span>
                                        )}
                                    </Fragment>
                                ) : (
                                    <span className='request-text'>
                                        {t('transaction:transaction.deposit.expressDeposit.requestAddBank', 'Please add your BANK account details for use Express Deposit')}
                                    </span>
                                )}
                            </div>
                        </div>
                        {expressDepositMemberBankList && !showAllBankMaintenaceMsg && (
                            <div className='group-item bank-options'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.expressDeposit.yourBankAccount', 'Your Bank Account')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='bank-list express-deposit-box'>
                                    <div className='bank-column-box'>
                                        <div className='bank-icon-box'>
                                            <BankIcon bankCode={expressDepositMemberBankList.bankCode} />

                                            <span className='bank-name'>{expressDepositMemberBankList.bankName}</span>
                                        </div>
                                        <div className='express-deposit-bank-list'>
                                            <span className='account-holder'>
                                                {expressDepositMemberBankList.accountName
                                                    ? expressDepositMemberBankList.accountName
                                                    : expressDepositMemberBankList.accountHolder}
                                            </span>
                                            <span className='account-holder'>{expressDepositMemberBankList.accountNumber}</span>
                                        </div>
                                    </div>
                                </div>

                                <button
                                    className='express-deposit-btn-submit btn-submit'
                                    onClick={() => {
                                        vm.removeBankList(expressDepositMemberBankList);
                                    }}
                                >
                                    {t('transaction:transaction.deposit.expressDeposit.remove', 'Remove').toUpperCase()}
                                </button>
                            </div>
                        )}

                        <div className='bank-add'>
                            <MobileSelect
                                itemList={this.state.verifyBank}
                                itemKey={'bankName'}
                                onChange={(e) => vm.handleBankDetailChange(e)}
                                itemIndex={this.state.selectedBankDetailIndex}
                                title={t('bank:bank.bank', 'Bank')}
                                required={expressDepositMemberBankList && !showAllBankMaintenaceMsg ? false : true}
                                disabledDropDown={expressDepositMemberBankList && !showAllBankMaintenaceMsg ? true : false}
                                isPleaseSelect={true}
                                isSelected={false}
                                requiredReset={this.state.displayDefault}
                                newItemArray={expressDepositMemberBankList}
                            />
                            <div
                                className='addBankExressDeposit'
                                onClick={() => {
                                    vm.addButtonOnClicked();
                                }}
                            >
                                <span>{t('transaction:transaction.deposit.expressDeposit.addExpressDeposit', 'Click here to add bank for Express Deposit')}</span>
                            </div>
                            <div className='group-item input-item'>
                                <div className='title'>
                                    <span>{t('bank:bank.newBank.account-name', 'Account Name')}</span>
                                    <span className={`required ${expressDepositMemberBankList && !showAllBankMaintenaceMsg ? 'non-required' : ''}`}>*</span>
                                </div>
                                <div className='group'>
                                    <div className='input-box'>
                                        <input
                                            type='text'
                                            value={this.state.fields['accountName']}
                                            disabled
                                            id='accountNumber'
                                            placeholder={verifyAccountList.accountName ? verifyAccountList.accountName : t('bank:bank.newBank.account-name', 'Account Name')}
                                        ></input>
                                    </div>
                                </div>
                            </div>
                            <div className='group-item input-item'>
                                <div className='title'>
                                    <span>{t('bank:bank.newBank.account-number', 'Account Number')}</span>
                                    <span className={`required ${expressDepositMemberBankList && !showAllBankMaintenaceMsg ? 'non-required' : ''}`}>*</span>
                                </div>
                                <div className='group'>
                                    <div className='input-box'>
                                        {
                                            <input
                                                type='text'
                                                value={this.state.fields['accountNumber']}
                                                onChange={(e) => vm.handleFieldChange(e, 'accountNumber')}
                                                id='accountNumber'
                                                placeholder={
                                                    verifyAccountList.accountNumber ? verifyAccountList.accountNumber : t('bank:bank.newBank.account-number', 'Account Number')
                                                }
                                                disabled
                                            ></input>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className='group-item submit-container'>
                                <div className='title'></div>
                                <div className='amount-content'>
                                    <button
                                        className={`express-deposit-btn-submit btn-submit standard-button ${
                                            expressDepositMemberBankList && !showAllBankMaintenaceMsg ? 'disable-grey' : ''
                                        }`}
                                        disabled={!isFormValid}
                                        onClick={() => {
                                            vm.handleSubmitExpressDepositBank();
                                        }}
                                    >
                                        {t('global:entity.action.select').toUpperCase()}
                                    </button>
                                </div>
                            </div>
                        </div>
                        {compSettings && !compSettings.hideDisplayNotice && <TransactionNote {...transactionNoteProps} />}
                    </>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'settings'])(withRouter(QrCodePay)));
