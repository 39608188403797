import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import moment from 'moment';

import vm from '../message-detail-dialog.controller';
import { Translation, withTranslation } from 'react-i18next';

class MessageDetailDialog extends Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
    }

    componentWillMount() {
        Modal.setAppElement('#root');
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        return (
            <Translation>
                {(t) => (
                    <Modal
                        isOpen={this.props.messageDetailDialogIsShow}
                        shouldCloseOnEsc={true}
                        shouldCloseOnOverlayClick={true}
                        onRequestClose={() => this.props.closeMessageDetailDialog(this.props.parentvm)}
                        overlayClassName='custom-modal-overlay'
                        className='custom-modal message-detail-modal'
                    >
                        <div className='custom-modal-header message-detail-title-container'>
                            <div className='titleWarpper'>
                                <h3 className='message-detail-title'>{this.state.messageObj.title}</h3>
                                <h5>{moment(this.state.messageObj.sentTime || this.state.messageObj.createdDate).format('DD-MM-YYYY HH:mm').toString()}</h5>
                            </div>
                            <button className='close-btn' onClick={() => this.props.closeMessageDetailDialog(this.props.parentvm)}>
                                X
                            </button>
                        </div>
                        <div className='custom-modal-body'>
                            <div dangerouslySetInnerHTML={{ __html: this.state.messageObj.message }}></div>
                        </div>
                    </Modal>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation('')(MessageDetailDialog));
