// library
import React from 'react';

// components
import Button from '@components/atoms/button/button';
import Input from '@components/atoms/input/input';
import styles from './otp-form-field.module.scss';
import { FormLabel } from '@components/molecules/form-field/form-field';
import { FormFieldWrapper } from '@components/molecules/form-field/form-field';

// temp component, expect do in form-field, but form-field component have other ticket updating.
const OtpFormField = ({ ...props }) => {
    const { label, name, value, onClick, onChange, disabled, buttonText, errMsg, verifyAttemptMsg, placeholder, layout, isMandatory, inputClassName } = props;
    return (
        <>
            <FormFieldWrapper className={`${styles.otpField} ${props.className}`} layout={layout}>
                <FormLabel className={styles.otpFieldLabel} label={label} isMandatory={isMandatory} />

                <div className={`${styles.otpFieldWrapper} ${onClick ? styles.withOtpButton : ''} standard-form-field-wrapper`}>
                    <Input className={`standard-input ${styles.otpInput} ${inputClassName || ''}`} name={name} value={value} onChange={onChange} placeholder={placeholder} />
                    {onClick && (
                        <div className={`${styles.otpButtonContainer}`}>
                            <Button
                                id={'otp-btn'}
                                scssClasses={'primary-btn'}
                                className={`standard-button standard-submit-button ${styles.otpButton}`}
                                disabled={disabled}
                                onClick={onClick}
                            >
                                <span>{buttonText}</span>
                            </Button>
                        </div>
                    )}
                </div>

                {errMsg && <div className={`${styles.errorValidation} standard-error ${styles?.[layout] || ''}`}>{errMsg}</div>}
                {verifyAttemptMsg && <div className={`${styles.errorValidation} standard-error ${styles?.[layout] || ''}`}>{verifyAttemptMsg}</div>}
            </FormFieldWrapper>
        </>
    );
};

export default OtpFormField;
