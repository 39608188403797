import React, { Fragment } from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Slider from 'root/webapp/shared/component/Slider';
import SliderMobile from 'root/webapp/shared/component/Slider2';

class SportsGames extends React.Component {
    constructor() {
        super();
        this.state = {
            active: 0,
            items: [],
        };
    }

    componentDidUpdate() {
        if (this.props.sportsScrollingBanner && this.props.sportsScrollingBanner.length !== this.state.items.length) {
            this.setState({ items: this.props.sportsScrollingBanner });
        }
    }

    mobileDom() {
        return (
            <div className='sports-games-slider-box' data-screen='mobile'>
                <SliderMobile activedIndex={0} space={'0.31rem'} items={this.state.items} />
            </div>
        );
    }

    render() {
        return (
            <Translation>
                {(t) => (
                    <div className='sports-games'>
                        <div className='item-title'>{t('global:global.bestSportsGames', 'BEST SPORTS GAMES')}</div>
                        {this.state.items.length > 0 && (
                            <Fragment>
                                <TransitionGroup className='content-item' data-screen='web|pad'>
                                    <CSSTransition key={this.state.active} timeout={500} classNames='slider-fade-prev'>
                                        {this.state.items[this.state.active].content}
                                    </CSSTransition>
                                </TransitionGroup>
                                <div className='sports-games-slider-box' data-screen='web|pad'>
                                    <Slider
                                        items={this.state.items}
                                        transionName='fade-next'
                                        forceTransionName='fade-next'
                                        ignoreTouchEvent={true}
                                        onChange={(active) => {
                                            this.setState({ ...this.state, active });
                                        }}
                                    />
                                </div>
                                {this.mobileDom()}
                            </Fragment>
                        )}
                    </div>
                )}
            </Translation>
        );
    }
}

export default withTranslation('global')(SportsGames);
