// react/library
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation, Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';

// components/files
import TransactionNote from 'root/webapp/shared/transaction-note/transaction-note.selector';

// controller/logic
import vm from '../../quick-pay.controller';
import transactionHelper from 'root/utils/transaction-helper';
import commonUtil from '../../../../../../../../utils/common-util';
import { trans_getDepositAmountOptionByMethod } from 'root/utils/transaction-util';
import { AMOUNT_DEPOSIT_METHOD_CODE } from 'root/utils/constants';
import PaymentGatewayIcon from '../../../../../../../../components/molecules/payment-icon/payment-icon';
import BankIcon from '../../../../../../../../components/molecules/bank-icon/bank-icon';

class QuickPay extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
    }

    componentDidMount() {
        vm.initCompSettings();
        if (this.state.depositAllSettings !== null) {
            vm.loadDepositDetails();
        }

        commonUtil.loadCustomTranslation('quick-pay', this, function () {});
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    componentDidUpdate(prevProps) {
        const prevDepositAmountOptions = JSON.stringify(trans_getDepositAmountOptionByMethod(prevProps.depositAmountOptions, AMOUNT_DEPOSIT_METHOD_CODE.QP));
        const currentDepositAmountOptions = JSON.stringify(this.state.depositAmountOptions);
        if (prevDepositAmountOptions !== currentDepositAmountOptions) {
            this.setState({ depositAmountOptions: trans_getDepositAmountOptionByMethod(this.props.depositAmountOptions, AMOUNT_DEPOSIT_METHOD_CODE.QP) });
        }
    }

    render() {
        const {
            bankList,
            depositAmountOptions,
            depositMinMaxLimit,
            isFormValid,
            disableDepositButton,
            methodList,
            selectedMethodIndex,
            showAmountField,
            customTranslationObj,
            customFilename,
        } = this.state;
        let transactionNoteProps = {
            hideDisplayInfo: this.state.compSettings.hideDisplayInfo,
            customOnlineTransactionMode: this.props.t('transaction:transaction.deposit.onlinetransfer.online2', 'Online'),
            depositMinMaxLimit: this.state.depositMinMaxLimit,
            showCustomArticle: this.state.showCustomDepositArticle,
            customArticleContent: this.state.showCustomDepositArticleContent,
            customArticleIsJson: this.state.customArticleIsJson,
            customContentStyle: this.state.customContentStyle,
            hideDefaultNote: [!this.state.showDefaultArticle, true, true],
            transactionMethodName: this.props.t('transaction:transaction.transfer.quickPay', 'Quick Pay'),
            customOptionTitle: this.props.t('transaction:transaction.deposit.cashdeposit.option2', 'Option'),
            customModeTile: this.props.t('transaction:transaction.deposit.cashdeposit.mode2', 'Mode'),
            customFilename: customFilename,
        };
        const { portal } = this.props;

        let userCurrency = this.props.user && this.props.user.account && this.props.user.account.currency ? this.props.user.account.currency : null;

        if (userCurrency === 'KHUSD') userCurrency = 'USD';

        return (
            <Translation>
                {(t) => (
                    <Fragment>
                        <div className='group-item bank-options'>
                            <div className='title'>
                                <span>{t('transaction:transaction.deposit.cashdeposit.channel', 'Deposit Channel')}</span>
                                <span className='required'>*</span>
                            </div>
                            <div className='bank-list'>
                                <ul>
                                    {methodList.map((method, i) => (
                                        <li
                                            key={i}
                                            className={['icon-hover-box', selectedMethodIndex === i ? 'on' : 'off'].join(' ')}
                                            onClick={() => {
                                                vm.onMethodChanged(i);
                                            }}
                                        >
                                            <span className='icon-box'>
                                                <div className={`icon-${method.code}`}>
                                                    <PaymentGatewayIcon pgCode={method.code} />
                                                </div>
                                            </span>
                                            <span>
                                                {customTranslationObj && customTranslationObj[method.code] ? customTranslationObj[method.code] : t(method.displayName)}
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        {bankList && bankList.length > 0 && (
                            <div className='group-item bank-options'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.onlinetransfer.bank', 'Bank Options')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='bank-list'>
                                    <ul>
                                        {bankList.map((bank, i) => (
                                            <li
                                                key={i}
                                                className={[
                                                    'icon-hover-box',
                                                    this.state.selectedBankIndex === i ? 'on' : '',
                                                    bank.code.toLowerCase() === 'ezpqr' || bank.code.toLowerCase() === 'h2pp' ? 'qrbank-item' : '',
                                                ].join(' ')}
                                                onClick={() => {
                                                    vm.onBankChanged(bank);
                                                }}
                                                onMouseEnter={() => {
                                                    this.setState({ hoveredIndex: i });
                                                }}
                                                onMouseLeave={() => {
                                                    this.setState({ hoveredIndex: null });
                                                }}
                                            >
                                                <span
                                                    className={`icon-box ${
                                                        bank.code.toLowerCase() === 'ezpqr' || bank.code.toLowerCase() === 'h2pp' ? 'qrbank-icon-box' : ''
                                                    }`}
                                                >
                                                    <i
                                                        className={[
                                                            'icon-bank',
                                                            this.state.selectedBankIndex === i || (this.state.hoveredIndex !== null && this.state.hoveredIndex === i)
                                                                ? 'icon-bank-on'
                                                                : 'icon-bank-off',
                                                            bank.code.toLowerCase() === 'ezpqr' || bank.code.toLowerCase() === 'h2pp' ? 'qrbank-icon-bank' : '',
                                                        ].join(' ')}
                                                    >
                                                        <BankIcon bankCode={bank.code} />
                                                    </i>
                                                </span>
                                                <span>{bank.name}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        )}

                        {showAmountField && (
                            <div className='group-item input-item'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.onlinetransfer.amount', 'Deposit Amount')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='amount-content'>
                                    <div className='input-box'>
                                        <div className='deposit-input-box'>
                                            <input
                                                id='depositamount'
                                                type='numeric'
                                                autoComplete='off'
                                                placeholder={t('global:global.form.online-transfer-amount-placeholder', {
                                                    min: window.SPL_Other.formatAmount(depositMinMaxLimit ? depositMinMaxLimit.min : 0),
                                                    max: window.SPL_Other.formatAmount(depositMinMaxLimit ? depositMinMaxLimit.max : 0),
                                                })}
                                                min={'0'}
                                                value={this.state.fields['amount']}
                                                onChange={(e) => vm.handleAmountChange(e)}
                                                onBlur={() => {
                                                    transactionHelper.handleValidation(this);
                                                }}
                                            />
                                            {portal?.settings?.features?.depositPagesSettings?.enableAccValueButton && depositAmountOptions?.length > 0 && (
                                                <div className='deposit-amount-clear-button' onClick={() => transactionHelper.clearInputFieldAmount(this)}>
                                                    <img src='/public/html/images/settings/clear-button.svg' alt='clear-button-img' />
                                                </div>
                                            )}
                                        </div>
                                        <div className='error-validation'>
                                            <Trans
                                                i18nKey={this.state.errors['amount']}
                                                values={{ amount: window.SPL_Other.formatAmount(this.state.amountErrorValue), currency: userCurrency }}
                                            ></Trans>
                                        </div>
                                    </div>
                                    <ul>
                                        {depositAmountOptions.map((item) => (
                                            <li
                                                key={item}
                                                onClick={() => {
                                                    transactionHelper.onDepositAmountClicked(this, item);
                                                }}
                                            >
                                                {item}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        )}

                        <div className='group-item submit-container'>
                            <div className='title'></div>
                            <div className='amount-content'>
                                <div className='flexbox'>
                                    <button
                                        className='btn btn-submit'
                                        onClick={() => {
                                            vm.submitForm();
                                        }}
                                        disabled={!isFormValid || disableDepositButton || this.state.receiptRequired}
                                    >
                                        {t('transaction:transaction.deposit.deposithistory.submitbtn', 'SUBMIT').toUpperCase()}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <TransactionNote {...transactionNoteProps} />
                        {/* <div className='notice-box'>
                            <span className='item-title'>{t('transaction:transaction.important-notice', 'IMPORTANT NOTICE')}</span>
                            <div className='display-info'>
                                <span className='key'>{t('transaction:transaction.deposit.cashdeposit.option1', 'Option:Bank Transfer')}</span>
                                <span className='value'></span>
                            </div>
                            <div className='display-info'>
                                <span className='key'>{t('transaction:transaction.deposit.cashdeposit.mode1', 'Mode:Online & Offline')}</span>
                                <span className='value'></span>
                            </div>
                            <div className='display-info'>
                                <span className='key'>{t('transaction:transaction.deposit.cashdeposit.min/maxlimit', 'Min/Max Limit')}</span>
                                <span className='value'>
                                    {this.state.depositMinMaxLimit === null ? (
                                        <SmallLoading></SmallLoading>
                                    ) : (
                                        ` ${this.props.user.account.currency} ${window.SPL_Other.formatAmount(
                                            this.state.depositMinMaxLimit && this.state.depositMinMaxLimit.min
                                        )} 
                                                    / ${window.SPL_Other.formatAmount(this.state.depositMinMaxLimit && this.state.depositMinMaxLimit.max)}`
                                    )}
                                </span>
                            </div>

                            <div className='display-info'>
                                <span className='key'>{t('transaction:transaction.deposit.cashdeposit.dailylimit', 'Daily Limit:')}</span>
                                <span className='value'>&nbsp;{t('transaction:transaction.deposit.cashdeposit.unlimited', 'Unlimited')}</span>
                            </div>
                            <div className='display-info'>
                                <span className='key'>{t('transaction:transaction.deposit.cashdeposit.totalallowed', 'Total Allowed:')}</span>
                                <span className='value'>&nbsp; {t('transaction:transaction.deposit.cashdeposit.unlimited', 'Unlimited')}</span>
                            </div>

                            <div className='display-info'>
                                <p>
                                    {t(
                                        'transaction:transaction.deposit.cashdeposit.note1',
                                        'Note: Please use Bank Transfer via your local bank account. We do not accept all kinds of deposit by ""Cheque"" or ""Bank Draft"" (Company OR Personal Cheque) as your deposit method. '
                                    )}
                                </p>
                            </div>
                        </div> */}
                    </Fragment>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'settings'])(withRouter(QuickPay)));
