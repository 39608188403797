// library
import Modal from 'react-modal';
import SVG from 'react-inlinesvg';
import React, { useEffect } from 'react';

// components
import styles from './popup.module.scss';

const Popup = ({ isOpen, ...props }) => {
    const { className, shouldCloseOnOverlayClick, shouldCloseOnEsc, onRequestClose, overlayClassName, children } = props;

    useEffect(() => {
        Modal.setAppElement('#root');
    }, []);

    return (
        <>
            <Modal
                overlayClassName={`standard-popup-overlay ${styles.standardPopupOverlay} ${overlayClassName || ''}`}
                className={`standard-popup-modal-container ${styles.standardPopupModalContainer} ${className || ''}`}
                isOpen={isOpen}
                shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
                shouldCloseOnEsc={shouldCloseOnEsc}
                onRequestClose={onRequestClose}
            >
                {children}
            </Modal>
        </>
    );
};

export const PopupHeader = ({ popupTitle, ...props }) => {
    const { onRequestClose } = props;
    return (
        <div
            className={`standard-popup-modal-header ${props.className} ${styles.standardPopupModalHeader} ${
                onRequestClose ? styles.popUpHeaderWithTwoDIV : styles.popUpHeaderWithOneDIV
            }`}
        >
            <span className={`fs-18 ${styles.standardModalTitle}`}>{popupTitle}</span>
            {onRequestClose && (
                <div id='close-btn' className={`${styles.iconClose} standard-modal-close`} onClick={onRequestClose}>
                    <SVG src={`/public/html/images/common_img/icon-cross.svg`} />
                </div>
            )}
        </div>
    );
};

export const PopupBody = ({ ...props }) => {
    return <div className={`fs-16 ${props.className} ${styles.standardPopupModalBody}  ${props.scssClass}`}>{props.children}</div>;
};

export const PopupFooter = ({ ...props }) => {
    return <div className={`standard-popup-modal-footer ${styles.standardPopupModalFooter}`}>{props.children}</div>;
};

export default Popup;
