// library
import React from 'react';
import SVG from 'react-inlinesvg';

// component / files
import styles from './svg-icon.module.scss';

const SVGIcon = ({ ...props }) => {
    const { classes, scssClasses, src, disableWrapper, onClick, onMouseEnter, onMouseOver, onMouseLeave } = props;
    if (disableWrapper) {
        return <SVG src={src} onClick={onClick} onMouseEnter={onMouseEnter} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} />;
    } else {
        return (
            <button
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
                className={`${styles.buttonWrapper} ${styles.svgIcon} ${styles[classes]} ${scssClasses}`}
            >
                <SVG src={src} />
            </button>
        );
    }
};

export default SVGIcon;
