import moment from 'moment';
import CryptoJS from 'crypto-js';
import { userAction } from '../redux/action';

// Params
// vm (Required) -> this
// settingName (Required) -> the name of setting is getting
// defaultValue -> return specific value if setting not found
// notFeatures -> if the setting not inside features set true
// notViewType -> if the setting not contain 'web' and 'mobile' set true
// isCurrencyLang -> if the setting is define by CurrencyLanguage or countryLanguageKey

export function gu_getSettingFromPortalSettings(params) {
    const { vm, settingName } = params;
    let settingObj = null;

    if (vm && vm.props) {
        let portal = vm.props.portal;

        // Get the settings out from portal settings
        if (params.notFeatures) {
            if (portal && portal.settings && portal.settings[settingName]) {
                settingObj = portal.settings[settingName];
            }
        } else {
            if (portal && portal.settings && portal.settings.features && portal.settings.features[settingName]) {
                settingObj = portal.settings.features[settingName];
            }
        }

        if (settingObj) {
            // Check screen view type
            if (!params.notViewType) {
                let viewType = 'web'; // default if not found
                if (vm.props.screen && vm.props.screen.viewType) {
                    viewType = vm.props.screen.viewType;
                }

                if (settingObj[viewType]) {
                    settingObj = settingObj[viewType];
                }
            }

            // Check if currency language needed
            if (params.isCurrencyLang) {
                settingObj = gu_getContentByCurrencyLanguage(vm, settingObj);
            }
        }
    } else {
        alert('Error! Calling getSettingFromPortalSettings function without vm / settingName');
    }

    if (settingObj) {
        return settingObj;
    } else if (!settingObj && params.defaultValue !== undefined) {
        return params.defaultValue;
    } else {
        return {};
    }
}

export function gu_getContentByCurrencyLanguage(vm, content, countryLanguageKeySettings = {}) {
    let countryLanguageKey = null;
    let country = null;
    let currency = null;
    let languageKey = null;

    if (vm && vm.props && content) {
        const { language } = vm.props;
        let user = null;

        // Step 1: Get selected currency and language
        // Step 1.0: Get language key
        if (vm.props.oriProps && vm.props.oriProps.language) {
            languageKey = vm.props.oriProps.language.key;
            country = vm.props.oriProps.language.country;
        } else if (vm.props.language) {
            languageKey = vm.props.language.key;
            country = vm.props.language.country;
        }

        // Step 1.1: Check if login, get account currency
        if (vm.props.oriProps) {
            if (vm.props.oriProps.user && vm.props.oriProps.user.isLogin) {
                user = vm.props.oriProps.user;
            }
        } else if (vm.props.user && vm.props.user.isLogin) {
            user = vm.props.user;
        }

        const convertUSD = () => {
            if (vm.props.oriProps) {
                currency = vm.props.oriProps.language.country + vm.props.currencyLang;
            } else if (countryLanguageKeySettings && countryLanguageKeySettings.convertUSD) {
                currency = vm.props.language.country + (language && language.currencyLang);
            }
        };

        if (user && user.account) {
            currency = user.account.currency;
            if (currency === 'USD') {
                convertUSD();
            }
        }

        // Step 1.2: If not login, get current currencyLang based on language selected
        if (!currency) {
            if (vm.props.currencyLang || (language && language.currencyLang)) {
                currency = vm.props.currencyLang || (language && language.currencyLang);
                if (currency === 'USD') {
                    convertUSD();
                }
            }
        }
        if (currency && currency.currencyLang) {
            currency = currency.currencyLang;
        }

        // Step 2: Get content
        // Step 2.1: Check if content contains selected currency
        if (content[country]) {
            const regionContent = content[country];
            if (regionContent[languageKey]) {
                return regionContent[languageKey];
            }
            return regionContent;
        } else if (content[currency]) {
            let selectedContentJson = content[currency];
            if (selectedContentJson && selectedContentJson[languageKey]) {
                // Step 2.1.1: Get correct currency > languageKey
                return selectedContentJson[languageKey];
            } else if (selectedContentJson && selectedContentJson['en'] && !selectedContentJson.disableDefaultTranslate) {
                // Step 2.1.2: Get default languageKey 'en'
                return selectedContentJson['en'];
            } else if (selectedContentJson.disableDefaultTranslate) {
                return null;
            } else {
                return selectedContentJson;
            }
        } else if (content['ALL']) {
            // Step 2.2: Check if having general translate for all language
            let selectedContentJson = content['ALL'];
            if (selectedContentJson && selectedContentJson[languageKey]) {
                // Step 2.2.1: Get correct currency > languageKey
                return selectedContentJson[languageKey];
            } else if (selectedContentJson && selectedContentJson['en'] && !selectedContentJson.disableDefaultTranslate) {
                // Step 2.2.2: Get default languageKey 'en'
                return selectedContentJson['en'];
            } else if (selectedContentJson.disableDefaultTranslate) {
                return null;
            } else {
                return selectedContentJson;
            }
        } else {
            // If content already filter/without currency, direct take content by language
            // **Not recommended, try to avoid
            if (content[languageKey]) {
                return content[languageKey];
            } else if (content['en'] && !content.disableDefaultTranslate) {
                return content['en'];
            } else if (content.disableDefaultTranslate) {
                return null;
            }

            // Step 3: Fallback control - used for countryLanguage key like en-my, ms-my
            if (vm.props.language && vm.props.language.countryLanguageKey) {
                countryLanguageKey = vm.props.language.countryLanguageKey;
            } else if (vm.props.countryLanguageKey) {
                countryLanguageKey = vm.props.countryLanguageKey;
            }
            let defaultCountryLanguageKey = window.countryLanguageKey;

            if (!countryLanguageKeySettings.noLowerCase) {
                countryLanguageKey = countryLanguageKey.toLowerCase();
                defaultCountryLanguageKey = defaultCountryLanguageKey.toLowerCase();
            }
            if (!countryLanguageKeySettings.showUnderScore) {
                countryLanguageKey = countryLanguageKey.replace('_', '-');
                defaultCountryLanguageKey = defaultCountryLanguageKey.replace('_', '-');
            }

            if (countryLanguageKey && content[countryLanguageKey]) {
                // Step 3.1: Try to get current countryLanguageKey
                return content[countryLanguageKey];
            } else if (content[defaultCountryLanguageKey]) {
                // Step 3.2: Try to get default countryLanguageKey
                return content[defaultCountryLanguageKey];
            } else {
                return content; // Return back contnet if all condition does not match
            }
        }
    } else {
        return content;
    }
}

export function gu_togglePopup(vm, component, customPopUpContent) {
    let popupCollection = vm.state.popupCollection ? vm.state.popupCollection : {};
    popupCollection[component] = !popupCollection[component];
    vm.setState({
        popupCollection,
        customPopUpContent,
    });
}

// this function is to handle e/e.target.value
// e.g. onClick pass index as e/ onChange pass index as e
// but still recommend direct pass e.target.value, if not need special handling like this
export function gu_massageIndex(e) {
    let massagedIndex = e;
    if (e && e.target) {
        massagedIndex = e.target.value;
    } else if (e || e === 0) {
        massagedIndex = e;
    }
    return massagedIndex;
}

export function gu_getByViewType(obj, vm) {
    if (!vm) {
        alert('vm is not valid/passed into function getByViewType()');
        return;
    }

    const { screen } = vm.props;
    let viewType = 'web'; // default if not found
    if (screen && screen.viewType) {
        viewType = screen.viewType;
    }
    return obj && (obj[viewType] || obj[viewType] === '' || typeof obj[viewType] === 'boolean') ? obj[viewType] : obj;
}

export function gu_jsonConcat(json1, json2, vm) {
    let jsonResult = window.SPL_Other.deepCopy(json1);

    for (var key in json2) {
        let certainTranslate = gu_getContentByCurrencyLanguage(vm, json2[key]);

        if (!(json2 && json2[key] && json2[key].disableDefaultTranslate && !certainTranslate)) {
            jsonResult[key] = json2[key];
        }
    }

    return jsonResult;
}

// Get Custom Translation from /public/html/settings/custom-translation.json
// (Parameter) currentPage: refer as the page requesting and the key of the custom-translation.json
export function gu_loadCustomTranslation(currentPage, vm, callback) {
    let customTranslationObj = {};

    let countryLanguageKey = null;
    let combinedPagesTranslation = {};

    if (vm.props.language && vm.props.language.countryLanguageKey) {
        countryLanguageKey = vm.props.language.countryLanguageKey.toLowerCase();
    } else if (vm.props.countryLanguageKey) {
        countryLanguageKey = vm.props.countryLanguageKey.toLowerCase();
    }

    if (countryLanguageKey === null) {
        return;
    }

    if (vm.props && vm.props.portal.settings && vm.props.portal.settings.features && vm.props.portal.settings.features.loadCustomTranslation) {
        window.SPL_Content.getCustomTranslation().then((data) => {
            if (Array.isArray(currentPage)) {
                let tempPageKeyi18n = [];
                for (let i = 0; i < currentPage.length; i++) {
                    // pageKey = currentPageKey
                    let pageKey = currentPage[i];
                    if (data && data[countryLanguageKey] && data[countryLanguageKey][pageKey]) {
                        tempPageKeyi18n.push(gu_getByViewType(data[countryLanguageKey][pageKey], vm));
                        if (i === 0) {
                            combinedPagesTranslation = tempPageKeyi18n[0];
                        } else if (i > 0 && tempPageKeyi18n[i - 1]) {
                            combinedPagesTranslation = gu_jsonConcat(combinedPagesTranslation, tempPageKeyi18n[i]);
                        }
                    } else if (data && data[pageKey]) {
                        let filteredData = gu_getContentByCurrencyLanguage(vm, data[pageKey], { showUnderScore: true });
                        filteredData = gu_getByViewType(filteredData, vm);
                        if (filteredData) {
                            for (let key in filteredData) {
                                if (typeof filteredData[key] === 'object') {
                                    filteredData[key] = gu_getContentByCurrencyLanguage(vm, filteredData[key], { showUnderScore: true });
                                }
                            }
                            tempPageKeyi18n.push(filteredData);
                            if (i === 0) {
                                combinedPagesTranslation = tempPageKeyi18n[0];
                            } else if (i > 0 && tempPageKeyi18n[i - 1]) {
                                combinedPagesTranslation = gu_jsonConcat(combinedPagesTranslation, tempPageKeyi18n[i]);
                            }
                        }
                    }
                }
                customTranslationObj = combinedPagesTranslation;
            } else {
                if (data && data[countryLanguageKey] && data[countryLanguageKey][currentPage]) {
                    customTranslationObj = gu_getByViewType(data[countryLanguageKey][currentPage], vm);
                } else if (data && data[currentPage]) {
                    let filteredData = gu_getContentByCurrencyLanguage(vm, data[currentPage], { showUnderScore: true });
                    filteredData = gu_getByViewType(filteredData, vm);
                    if (filteredData) {
                        for (let key in filteredData) {
                            if (typeof filteredData[key] === 'object') {
                                filteredData[key] = gu_getContentByCurrencyLanguage(vm, filteredData[key], { showUnderScore: true });
                            }
                        }
                        customTranslationObj = filteredData;
                    }
                }
            }

            vm.setState({ customTranslationObj }, () => {
                callback && callback();
            });
        });
    } else {
        vm.setState({ customTranslationObj }, () => {
            callback && callback();
        });
    }
}

export function gu_getNestedValue(obj, key) {
    try {
        if (key) {
            key = key.replace(/\[(\w+)\]/g, '.$1'); // split indexes.
            key = key.replace(/^\.+/, ''); // remove leading dot.
            const a = key.split('.');
            for (var i = 0, n = a.length; i < n; ++i) {
                var k = a[i];
                if (k in obj) {
                    obj = obj[k];
                } else {
                    return;
                }
            }
        }

        return obj;
    } catch (err) {
        return undefined;
    }
}

export function gu_filterCommonKey(parentElement = false, childElement = false, filterSettings = {}) {
    let params = {
        loop: filterSettings.loop || false, // use for filter multi key, eg: web: { THB: { en: "test" } }
        baseOnUserCurrency: filterSettings.baseOnUserCurrency || false, // currency will get member currency
        customFilterKeys: filterSettings.customFilterKeys || [], // use for filter special key
    };

    return window.SPL_Content.filterCommonKeyHandler(gu_getNestedValue(parentElement, childElement), params);
}

export function gu_1DayExpires() {
    const currentDate = new Date();
    const timeOneDay = 24 * 60 * 60 * 1000;
    currentDate.setHours(23, 59, 59, 0);
    const duration = currentDate.getTime() - new Date().getTime(timeOneDay);
    return duration / timeOneDay;
}

/**
 * convert the date format in order to retrieve correct data from BE
 * e.g. Tue Nov 07 2023 10:48:57 GMT+0800 (Singapore Standard Time) => '2023_11_07'
 * @param {Date} date
 */
export function gu_formatNewDateToCustomFormat(date, dateFormat) {
    const returnDate = moment(date).utcOffset('+08:00').format(dateFormat);
    return returnDate;
}

/**
 * Special function for 4D draw results (only appends timestamp for every 30 seconds)
 * @param {string} path
 * @returns
 */
export function gu_appendTimeStampIntoPath(path, customTimeStampDate, customIntervalRoundingUnit) {
    let connector = '?';
    if (path.includes('?')) {
        connector = '&';
    }
    const roundedTime = new Date(
        Math.floor(customTimeStampDate ? new Date(customTimeStampDate) : new Date() / customIntervalRoundingUnit || 30000) * customIntervalRoundingUnit || 30000
    ); // Round down to the nearest 30 seconds

    const timeStamp = Math.trunc(roundedTime.getTime() / 1000); // Convert to seconds = 1000, minutes = 60000

    const updatedPath = path + connector + 'timeStamp=' + timeStamp;

    return updatedPath;
}

export function gu_decryptData(data, secretKey) {
    try {
        const decodeBase64SecretKey = CryptoJS.enc.Base64.parse(secretKey);

        const encryptString = data;
        const randomString = 'qkemtdhted567fd988213kdecrqkemtdhted567fd988213kdecrqkemtdhted567fd988213kdec';
        const preDecodeString = encryptString.replace(randomString, '');
        const decodeBase64EncryptedData = CryptoJS.enc.Base64.parse(preDecodeString);

        const decryptedData = CryptoJS.AES.decrypt(
            {
                ciphertext: decodeBase64EncryptedData,
            },
            decodeBase64SecretKey,
            {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7,
            }
        ).toString(CryptoJS.enc.Utf8);
        return decryptedData;
    } catch (err) {
        console.error('decrypt data error', err);
    }
}

// String handling
export function gu_replaceAll(str, search, replacement) {
    return str.replace(new RegExp(search, 'g'), replacement);
}

export function gu_getFourDResult(date, customTimeStampDate, customIntervalRoundingUnit) {
    const formattedDateTime = gu_formatNewDateToCustomFormat(date, 'YYYY_MM_DD');
    const filePath = `/draw-results/results/${formattedDateTime}/become.js`;
    const updatedFilePath = gu_appendTimeStampIntoPath(filePath, customTimeStampDate, customIntervalRoundingUnit);

    try {
        const request = new XMLHttpRequest();
        request.open('GET', updatedFilePath, false); // Third parameter is set to 'false' for synchronous
        request.send(null);
        const scriptText = request.responseText;

        const uniqueSecretKey = 'ZGpmZ2tobmVpdGhmcnRoZ252dXRuZGh0amZodm5ybmU=';
        const decryptedData = gu_decryptData(gu_replaceAll(scriptText, /\|\|/g, '//'), uniqueSecretKey);
        return decryptedData;
    } catch (err) {
        return '';
    }
}

export function gu_extractUnusedKeywordsForDrawResults(data, regex) {
    const jsonString = data?.match(regex)?.[0];
    try {
        const jsonData = JSON.parse(jsonString) || '';
        return jsonData;
    } catch (err) {
        return '';
    }
}

export function gu_getFourDPayout(language, props) {
    let fileUrl = `${window.location.origin}/draw-results/payout/mock/payout_${language}.json`;
    let request = new XMLHttpRequest();
    request.open('GET', fileUrl, false); // Third parameter is set to 'false' for synchronous
    request.send(null);
    if (request.status === 200) {
        let scriptText = request.responseText;
        return scriptText;
    } else {
        console.error('Error fetching the script. Status:', request.status);
        return null;
    }
}

export function gu_logout(dispatch, reduxCountryLanguageKey) {
    dispatch(userAction.logout()).then(() => {
        if (!window.isAgent && !window.isAffiliate) {
            if (window.SPL_LiteApp.isZT828LiteApp()) {
                window.SPL_LiteApp.onLogoutTapped();
            }
        }

        let path = '/';
        let langKey = '';
        let pathname = window.location.pathname;
        let pathArray = pathname.split('/');

        if (pathArray && pathArray.length >= 2 && pathArray[1].indexOf('-') !== -1) {
            langKey = pathArray[1];
        } else if (reduxCountryLanguageKey) {
            langKey = reduxCountryLanguageKey.toLowerCase().replace('_', '-');
        } else if (window && window.countryLanguageKey) {
            langKey = window.countryLanguageKey.toLowerCase().replace('_', '-');
        }

        path = '/' + langKey;

        window.location.href = path + (window.isAffiliate ? '/aff' : '');
    });
}

export function gu_validateDigit(value) {
    if (value.match(/^(\d*)\.{0,1}(\d){0,2}$/)) {
        return true;
    }
    return false;
}
