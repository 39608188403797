import notification from 'root/utils/notification';
import { gu_1DayExpires } from 'root/utils/general-util';

const controller = {
    vm: null,

    init(vm) {
        this.vm = vm;
        this.vm.state = {
            newsLetter: [],
            isNewsLetterOpen: false,
            fields: {
                telCode: '',
                phone: '',
                otpCode: '',
            },
            fieldsClick: {
                phone: false,
                otpCode: false,
            },
            optionsList: {
                telCode: [],
            },
            isApplyButtonDisable: true,
            isSendOtpButtonDisable: true,
        };
    },

    getNewsLetter() {
        window.SPL_Member.getNewsLetter().then((data) => {
            if (data && data.length > 0) {
                const vm = controller.vm;

                // let fields = vm.state.fields;
                // fields['phone'] = data[0].phone;
                vm.setState({ isNewsLetterOpen: true, newsLetter: data });
            }
        });
    },

    closeNewsLetterPopUp() {
        controller.vm.setState({ isNewsLetterOpen: false });
        window.SPL_LiteApp.sendHideNewsLetterPopUp();
        window.SPL_Cookie.setCookie('hideNewsLetterPopUp', true, gu_1DayExpires());
    },

    getCountryInfo() {
        window.SPL_Content.getCountryInfo().then((telCodeList) => {
            const vm = controller.vm;
            const { language } = vm.props;
            const country = language.country;

            let obj = {
                telCode: [],
            };

            let fields = vm.state.fields;
            for (let i = 0; i < telCodeList.length; i++) {
                obj.telCode.push('+' + telCodeList[i].telCode);

                if (telCodeList[i].country === country) {
                    fields.telCode = '+' + telCodeList[i].telCode;
                }
            }
            vm.setState({ fields, telCodeList, optionsList: obj });
        });
    },

    handleFieldFocus(field, isFocus) {
        const vm = controller.vm;
        const { fieldsClick } = vm.state;
        if (field === 'phone' || field === 'otpCode') {
            fieldsClick[field] = isFocus;
            controller.vm.setState({ fieldsClick });
        }
    },

    handleFieldChanged(e, field) {
        const vm = controller.vm;
        const { fields, telCodeList, isApplyButtonDisable } = vm.state;
        const { t } = vm.props;

        const validatePhone = () => {
            let phone = fields['phone'];
            let telCode = fields['telCode'];

            if (phone) {
                window.SPL_Register.validatePhone(phone, window.merchantCode, telCode).then((errMsg) => {
                    if (errMsg.length > 0) {
                        notification.showNotification('error', t(errMsg[0]));

                        if (!controller.vm.state.isSendOtpButtonDisable) {
                            vm.setState({ isSendOtpButtonDisable: true });
                        }
                    } else {
                        vm.setState({ isSendOtpButtonDisable: false });
                    }
                });
            }
        };

        if (field === 'telCode') {
            if (fields[field] !== e) {
                fields[field] = e.target.value;
                for (let i = 0; i < telCodeList.length; i++) {
                    if (`+${telCodeList[i].telCode}` === fields[field]) {
                        vm.setState({ fields }, validatePhone);
                    }
                }
            }
        }

        if (field === 'phone' && typeof fields['phone'] !== undefined) {
            fields[field] = e.target.value;
            vm.setState({ fields }, validatePhone);
        }

        if (field === 'otpCode' && typeof fields['otpCode'] !== undefined) {
            fields[field] = e.target.value;
            vm.setState({ fields });
            let otpCode = fields['otpCode'];

            const phoneNumber = controller._getPhone();
            window.SPL_Member.validateNewsLetterOTP(phoneNumber, otpCode).then((response) => {
                // response will return true false
                if (response) {
                    vm.setState({ isApplyButtonDisable: false });
                } else {
                    notification.showNotification('error', t('global:global.verification.codeIncorrect'));
                    if (!isApplyButtonDisable) {
                        vm.setState({ isApplyButtonDisable: true });
                    }
                }
            });
        }
    },

    getOtp() {
        // not sure if intended return null
        const phoneNumber = controller._getPhone();

        const onSuccess = (response) => {
            // success response no return anything
            notification.showNotification('success', controller.vm.props.t('global:global.verification.yourVerification'));
        };

        const onError = (response) => {
            // {"description":"Maximum Attempts Exceed","message":"Maximum Attempts Exceed","detail":null,"errorCode":null}
            notification.showNotification('error', response.message);
        };

        window.SPL_Member.getNewsLetterOTP(phoneNumber).then(onSuccess, onError);
    },

    updateNewsLetterSubscription(isUnSubscribe) {
        const vm = controller.vm;
        const { otpCode } = vm.state.fields;

        const phoneNumber = controller._getPhone();
        window.SPL_Member.updateNewsLetter(phoneNumber, otpCode, isUnSubscribe).then((response) => {
            const { t } = vm.props;

            // response will return true
            if (response) {
                if (!isUnSubscribe) {
                    // if subscribe
                    notification.showNotification('success', t('newsletter:newsLetter.verifySuccess1'));
                }
                // NOTE: unsubscribe no need message?

                vm.setState({ isNewsLetterOpen: false }); // if true close pop up
            } else {
                notification.showNotification('error', t('global:global.verification.invalidOTP'));
            }
        });
    },

    _getPhone() {
        const { phone, telCode } = controller.vm.state.fields;

        let amendPhone = phone.toString();
        if (amendPhone.startsWith('0')) {
            amendPhone = amendPhone.substring(1, amendPhone.length);
        }

        let amendTelCode = telCode.toString();
        if (telCode.startsWith('+')) {
            amendTelCode = amendTelCode.substring(1, amendTelCode.length);
        }

        return amendTelCode + amendPhone;
    },
};

export default controller;
