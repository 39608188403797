import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { Translation, withTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import vm from 'root/webapp/portal/settings/profile/subcomponent/edit-dob-popup.controller';

class EditDOBPopup extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
    }

    componentDidMount() {}

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        const { fields, optionList, maxDob } = this.state;
        const { isOpen, closeFunc, updateFieldWithPopupData, loadContactsVerification } = this.props;

        return (
            <Translation>
                {(t) => (
                    <Modal
                        overlayClassName='standard-popup-overlay'
                        className='standard-popup-modal-container edit-dob-popup-modal-container'
                        isOpen={isOpen}
                        shouldCloseOnOverlayClick={false}
                        shouldCloseOnEsc={false}
                        onRequestClose={() => closeFunc()}
                    >
                        <div className='standard-popup-modal-header edit-dob-popup-modal-header'>
                            <div>
                                {t('global:entity.action.edit')} {t('global:global.form.dob')}
                            </div>
                        </div>
                        <div className={`standard-popup-modal-body edit-dob-popup-modal-body`}>
                            <div className='dob-field-container'>
                                <div>{t('global:global.form.dob')}</div> <span>&nbsp;*</span>
                                <select
                                    className='input'
                                    value={fields['date']}
                                    placeholder={t('transaction:transaction.history.date')}
                                    onChange={(e) => {
                                        vm.handleFieldChange('date', e);
                                    }}
                                >
                                    {optionList['date'].map((option, i) => (
                                        <option key={i} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                                <select
                                    className='input'
                                    value={fields['month']}
                                    placeholder={t('leaderboard:leaderboard.month')}
                                    onChange={(e) => {
                                        vm.handleFieldChange('month', e);
                                    }}
                                >
                                    {optionList['month'].map((option, i) => (
                                        <option key={i} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                                <ReactDatePicker
                                    autoComplete='off'
                                    type='text'
                                    showYearPicker
                                    placeholderText={t('leaderboard:leaderboard.year')}
                                    selectsStart
                                    selected={fields['dob']}
                                    maxDate={maxDob}
                                    onChange={(e) => {
                                        vm.handleFieldChange('dob', e);
                                    }}
                                    timeFormat='yyyy'
                                    dateFormat='yyyy'
                                    className='input'
                                    popperPlacement='bottom-end'
                                />
                            </div>
                            <div className='btn-container'>
                                <button
                                    className='btn-submit'
                                    disabled={!fields['date'] || !fields['month'] || !fields['dob']}
                                    onClick={() => {
                                        vm.submitEditDOB(() => {
                                            updateFieldWithPopupData();
                                            loadContactsVerification();
                                            closeFunc();
                                        });
                                    }}
                                >
                                    {t('global:entity.action.save')}
                                </button>
                                <button className='btn-cancel' onClick={() => closeFunc()}>
                                    {t('global:entity.action.cancel')}
                                </button>
                            </div>
                        </div>
                    </Modal>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction'])(EditDOBPopup));
