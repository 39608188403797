import { portalAction } from 'root/redux/action/portal.action';
import { userAction } from 'root/redux/action/user.action';
import core from 'root/utils/core';
import { languageAction } from 'root/redux/action/language.action';
import { systemFeatureAction } from 'root/redux/action/system-feature.action';
import notification from 'root/utils/notification';
const controller = {
    vm: null,

    init(vm) {
        this.vm = vm;
        this.vm.state = {
            fields: {
                login: '',
                password: '',
                rememberMe: false,
            },
            isFormValid: false,
            isLoading: false,
            errorMsg: '',
            bioMode: '',
            isLiteAppLoginData: false,
            turnstileValidation: false,
            captchaToken: false,
        };
    },

    close() {
        controller.vm.props.dispatch(portalAction.floatingPageUpdated(''));
    },

    login() {
        const { isFormValid, isLoading, fields, captchaToken } = controller.vm.state;

        if (isFormValid && !isLoading) {
            controller.vm.setState({ isLoading: true });
            core.portalIsLoading(controller.vm.props, true);
            let loginObj = {
                domain: '',
                isAffiliate: window.isAffiliate ? window.isAffiliate : false,
                isVipLogin: false,
                language: controller.vm.props.language.key,
                merchantCode: window.merchantCode,
                login: fields.login,
                password: fields.password,
                rememberMe: fields.rememberMe,
                ...(captchaToken !== null && { captchaToken }),
            };

            window.SPL_Member.validateIpDevice(fields.login).then((data) => {
                if (data.error) {
                    controller.vm.setState({ isLoading: false }, () => {
                        core.portalIsLoading(controller.vm.props, false);
                        window.location.href = '/redirect-error?' + data.error;
                    });
                } else {
                    controller.vm.props
                        .dispatch(userAction.login(loginObj))
                        .then(() => {
                            controller.vm.props.dispatch(userAction.isAuthenticated()).then(() => {
                                customFunctionAfterLoginEvent();

                                if (!window.isAgent && !window.isAffiliate) {
                                    if (window.SPL_LiteApp.isZT828LiteApp()) {
                                        const { login, password, rememberMe } = loginObj;
                                        const { user } = controller.vm.props;
                                        const currencyData = user && user.account && user.account.currency;
                                        const bioEnabled = window.SPL_LiteApp.isTouchBioAuthUnlocked() || window.SPL_LiteApp.isFaceBioAuthUnlocked() ? '1' : '0';
                                        window.SPL_LiteApp.onLoginSuccess(login, password, rememberMe, bioEnabled, currencyData);
                                    }
                                }

                                // controller.vm.setState({ isLoading: false }, () => {
                                //     // core.portalIsLoading(controller.vm.props, false);
                                //     window.location.href = '/home';
                                // });
                            });
                            window.SPL_FingerPrint.checkingFp();
                        })
                        .catch(() => {
                            controller.vm.setState({ isLoading: false });
                            core.portalIsLoading(controller.vm.props, false);
                            const { failLoginCode } = controller.vm.props.user;
                            if (window.SPL_Member.isValidFailLoginCode(failLoginCode)) {
                                controller.vm.setState({ errorMsg: `global:error.loginError.${failLoginCode}` });
                            } else {
                                controller.vm.setState({ errorMsg: 'global:error.authentication' });
                            }
                        });
                }
            });
        }

        function customFunctionAfterLoginEvent(callback) {
            window.SPL_Content.getLanguageList().then((languageListJson) => {
                const { settings } = controller.vm.props.portal;
                const { language, user, history } = controller.vm.props;
                let countryLanguage = language.countryLanguageKey;
                let currentDomainLanguageKey = countryLanguage && countryLanguage.toLowerCase().replace('_', '-'); // dependency on settngs.enableRedirectAfterLogin
                let remainCurrentDomainLangKey = controller.getNestedValue(settings, 'features.remainCurrentDomainLangKey'); // dependency on settngs.enableRedirectAfterLogin

                if (settings && settings.enableRedirectAfterLogin) {
                    let globalCurrency = languageListJson.filter(function (a) {
                        let globalCountry = a.languages.filter(function (b) {
                            if (user.account && user.account.currency === 'KHUSD') {
                                return b.countryCode.toLowerCase() === 'kh';
                            } else {
                                return b.currency === user.account.currency;
                            }
                        });

                        return globalCountry.length > 0;
                    });

                    if (globalCurrency.length <= 0) {
                        globalCurrency = languageListJson.filter((a) => a.countryName === 'International');
                    }

                    let customRegionDefaultLangKey = controller.getNestedValue(settings, 'features.customRegionDefaultLangKey');
                    if (customRegionDefaultLangKey) {
                        let region = customRegionDefaultLangKey;

                        countryLanguage =
                            globalCurrency[0] &&
                            globalCurrency[0].languages.filter(function (a) {
                                return a.key === region[a.countryName];
                            });

                        if (!(countryLanguage && countryLanguage.length > 0)) {
                            countryLanguage = globalCurrency[0].languages;
                        }

                        countryLanguage = countryLanguage[0].countryLanguageKey;
                    } else {
                        countryLanguage = globalCurrency[0].languages[0].countryLanguageKey;
                    }

                    let countryLanguageKey = countryLanguage;

                    core.setLanguageKeyAndCountryLanguage(controller.vm, countryLanguageKey, languageAction);
                }

                countryLanguage = countryLanguage.toLowerCase();
                countryLanguage = countryLanguage.replace('_', '-');

                core.portalIsLoading(controller.vm.props, false);
                // redirect to last url if url param contains redirect
                let urlParam = window.SPL_Content.parse_query_string();
                if (urlParam.redirect) {
                    history.push(urlParam.redirect);
                    callback && callback();
                } else {
                    //check user login language key and previous language key to decide isRedirect
                    let checkIsRedirect = new Promise(function (resolve) {
                        core.getCurrentLanguage(false, false, controller.vm, languageAction).then((langPath) => {
                            resolve({
                                isRedirect: true, // diff language
                                currentLang: langPath && langPath.computeLanguage,
                            });
                        });
                    });

                    Promise.all([checkIsRedirect]).then((result) => {
                        let redirectRes = result && result[0];
                        let finalCountryLanguage = remainCurrentDomainLangKey ? currentDomainLanguageKey : redirectRes.currentLang || countryLanguage;

                        if (finalCountryLanguage) {
                            window.location.href = '/' + finalCountryLanguage;
                        } else {
                            window.location.href = '/';
                        }
                    });
                }
            });
        }
    },

    toggleRememberMe() {
        let fields = controller.vm.state.fields;
        fields.rememberMe = !fields.rememberMe;
        controller.vm.setState({ fields });
    },

    handleFieldChanged(e, field) {
        let fields = controller.vm.state.fields;
        fields[field] = e.target.value;
        controller.vm.setState({ fields }, () => {
            controller.handleValidation();
        });
    },

    handleValidation() {
        const { fields } = controller.vm.state;
        let isFormValid = true;
        if (fields['login'] === '' || fields['password'] === '') {
            isFormValid = false;
        }
        controller.vm.setState({ isFormValid: isFormValid });
    },

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            controller.login();
        }
    },

    getLanguageList() {
        window.SPL_Content.getLanguageList().then((data) => {
            const currentLanguage = {},
                countryLanguageKey = controller.vm.props.language.countryLanguageKey;

            const user = controller.vm.props.user;
            const account = user.account;
            let memberCurrency = null;
            if (account) {
                memberCurrency = account.currency;

                if (memberCurrency) {
                    data = data.filter((list) => list.currency === memberCurrency);
                }
            }

            for (let i = 0; i < data.length; i++) {
                const languages = data[i].languages;
                for (let j = 0; j < languages.length; j++) {
                    if (languages[j].countryLanguageKey === countryLanguageKey) {
                        Object.assign(currentLanguage, languages[j]);
                    }
                }
            }

            controller.vm.setState({ languageList: data, currentLanguage: currentLanguage });
        });
    },

    updateCurrentLanguage() {
        const countryLanguage = controller.vm.props.language;
        controller.vm.setState({ currentLanguage: countryLanguage });
    },

    changeLanguage(langObject) {
        core.setLanguageKeyAndCountryLanguage(controller.vm, langObject.countryLanguageKey, languageAction, false, false, true);
    },

    initCheckLiteAppLoginInfo() {
        if (!window.isAgent && !window.isAffiliate) {
            if (window.SPL_LiteApp.isZT828LiteApp()) {
                const isLiteAppLoginData = window.SPL_LiteApp.isLoginDataExist() ? true : false;

                let bioMode = '';
                if (window.SPL_LiteApp.isTouchBioAuthUnlocked()) {
                    bioMode = 'TouchID';
                } else if (window.SPL_LiteApp.isFaceBioAuthUnlocked()) {
                    bioMode = 'FaceID';
                }
                controller.vm.setState({
                    isLiteAppLoginData,
                    bioMode,
                });
            }
        }
    },

    onBiometricClicked() {
        if (!window.isAgent && !window.isAffiliate) {
            if (window.SPL_LiteApp.isZT828LiteApp()) {
                window.SPL_LiteApp.onBiometricAuthenticationTapped(controller.vm.state.bioMode);
            }
        }
    },

    onLanguageDisplayClick() {
        const { history, dispatch } = controller.vm.props;
        dispatch(portalAction.floatingPageUpdated(''));

        history.push({ pathname: '/language', previousMenuRoute: '/home?p=login' });
    },

    getNestedValue(obj, key) {
        try {
            key = key.replace(/\[(\w+)\]/g, '.$1'); // split indexes.
            key = key.replace(/^\.+/, ''); // remove leading dot.
            const a = key.split('.');
            for (var i = 0, n = a.length; i < n; ++i) {
                var k = a[i];
                if (k in obj) {
                    obj = obj[k];
                } else {
                    return;
                }
            }
            return obj;
        } catch (err) {
            return undefined;
        }
    },
    async initCloudflareSettings() {
        await controller.vm.props.dispatch(systemFeatureAction.loadSystemFeature());
        const turnstileEnabled = controller.enabledTurnstile();
        if (turnstileEnabled) {
            controller.vm.props.dispatch(systemFeatureAction.fetchCloudFlareTurnstileSetting());
            controller.vm.setState({ turnstileValidation: true });
        }
    },
    handleTurnStileToken(token) {
        controller.vm.setState({ fields: { ...controller.vm.state.fields }, turnstileValidation: false, captchaToken: token });
    },
    enabledTurnstile() {
        const skipLiteApp = controller.turnstileSkipLiteApp();
        return (
            (controller.vm.props?.systemFeatureReducer?.systemFeature?.LOGIN?.verify_security_validation || []).includes('CLOUDFLARE_TURNSTILE') &&
            skipLiteApp &&
            !!controller.vm.props?.systemFeatureReducer?.siteKey
        );
    },
    turnstileSkipLiteApp() {
        const { portal } = controller.vm.props;
        const isAllowTurnstileForLiteApp = portal?.settings?.isAllowTurnstileForLiteApp || false;
        return isAllowTurnstileForLiteApp || !window.SPL_LiteApp.isZT828LiteApp();
    },
    turnstileErrorHandling() {
        const { t } = controller.vm.props;
        notification.showNotification('error', t('register:register.messages.error.accessRestricted'));
    },
};

export default controller;
