import React, { Component } from 'react';
import { Translation, withTranslation, Trans } from 'react-i18next';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import { DebounceInput } from 'react-debounce-input';
import vm from './news-letter.controller';

class NewsLetterPopUp extends Component {
    constructor() {
        super();
        vm.init(this);
    }

    componentWillMount() {
        Modal.setAppElement('#root');
    }

    componentDidMount() {
        const hideNewsLetterPopUpCookie = window.SPL_Cookie.getCookie('hideNewsLetterPopUp');
        if (!hideNewsLetterPopUpCookie) {
            vm.getNewsLetter();
            vm.getCountryInfo();
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        const { isNewsLetterOpen, fields, fieldsClick, optionsList, isApplyButtonDisable, isSendOtpButtonDisable } = this.state;
        const { language, screen, portal } = this.props;
        const languageKey = language.key.indexOf('-') !== -1 ? language.key.substring(0, language.key.indexOf('-')) : language.key;
        const { closeNewsLetterPopUp, handleFieldChanged, handleFieldFocus, getOtp, updateNewsLetterSubscription } = vm;

        let cssClassPrefix = '';
        if (screen.viewType !== 'web') {
            cssClassPrefix = 'm-';
        }

        const hideNewsLetterPopUpCookie = window.SPL_Cookie.getCookie('hideNewsLetterPopUp');
        if (!isNewsLetterOpen || portal.isLoading || hideNewsLetterPopUpCookie) return null;

        return (
            <Translation>
                {(t) => (
                    <Modal
                        isOpen={true}
                        shouldCloseOnOverlayClick={true}
                        shouldCloseOnEsc={true}
                        onRequestClose={() => {
                            closeNewsLetterPopUp();
                        }}
                        overlayClassName='custom-modal-overlay modal-overflow'
                        className={`${cssClassPrefix}news-letter-modal`}
                    >
                        <img src={`/public/html/images/news-letter-free-gift-${languageKey}.png`} alt=''></img>
                        <div className='news-letter-info'>
                            <div className='news-letter-input'>
                                <div className='news-letter-phone-container'>
                                    <select
                                        className='phone'
                                        value={fields['telCode']}
                                        onChange={(e) => {
                                            handleFieldChanged(e, 'telCode');
                                        }}
                                    >
                                        {optionsList['telCode'].map((option, i) => (
                                            <option key={i} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                    <DebounceInput
                                        type='text'
                                        debounceTimeout={'1000'}
                                        values={fields['phone']}
                                        onChange={(e) => {
                                            handleFieldChanged(e, 'phone');
                                        }}
                                        onFocus={() => {
                                            handleFieldFocus('phone', true);
                                        }}
                                        onBlur={() => {
                                            handleFieldFocus('phone', false);
                                        }}
                                        className='input-field'
                                    />
                                    {!fields['phone'] && !fieldsClick['phone'] && (
                                        <div className='phone-placeholder-text'>
                                            <Trans i18nKey='global:global.form.phone-placeholder-asterisk'></Trans>
                                        </div>
                                    )}
                                </div>
                                <div className='news-letter-otp-container'>
                                    <DebounceInput
                                        type='text'
                                        debounceTimeout={'1000'}
                                        values={fields['otpCode']}
                                        onChange={(e) => {
                                            handleFieldChanged(e, 'otpCode');
                                        }}
                                        onFocus={() => {
                                            handleFieldFocus('otpCode', true);
                                        }}
                                        onBlur={() => {
                                            handleFieldFocus('otpCode', false);
                                        }}
                                        className='input-field'
                                    />
                                    {!fields['otpCode'] && !fieldsClick['otpCode'] && (
                                        <div className='otp-placeholder-text'>
                                            <Trans i18nKey='global:global.verification.otp'></Trans>
                                        </div>
                                    )}
                                    <button className='news-letter-sendotp-button' disabled={isSendOtpButtonDisable} onClick={() => getOtp()}>
                                        {t('global:global.verification.sendOtp')}
                                    </button>
                                </div>
                                <button className='news-letter-apply-button' disabled={isApplyButtonDisable} onClick={() => updateNewsLetterSubscription(false)}>
                                    {t('global:global.form.apply')}
                                </button>
                            </div>
                        </div>
                        <div className='news-letter-disclaimer'>{t('newsletter:newsLetter.disclaimer')}</div>
                        <div className='news-letter-unsubscribe' onClick={() => updateNewsLetterSubscription(true)}>
                            {t('newsletter:newsLetter.unsubscribe')}
                        </div>
                        <div className='news-letter-close-box' onClick={() => closeNewsLetterPopUp()}>
                            &times;
                        </div>
                    </Modal>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['global', 'home', 'newsletter'])(NewsLetterPopUp));
