import React from 'react';
import SVGElements from 'root/components/SVGElements';

const controller = {
    vm: null,
    init(vm) {
        this.vm = vm;
        this.vm.state = {
            userObj: {
                playerId: '',
                playerPassword: '',
            },
            megaUrl: '',
        };
    },

    getMegaUrl() {
        let domain = '';
        const { language } = controller.vm.props;

        if (window.location.hostname === 'localhost') {
            domain = window.tempDomain;
        } else {
            domain = window.location.hostname;
        }

        let param = {
            domain: domain,
            provider: 'MEGA',
            merchantCode: window.merchantCode,
            currency: language && language.currencyLang,
        };

        window.SPL_Provider.getMegaUrl(param).then((data) => {
            controller.vm.setState({ megaUrl: data.gameLink });
        });
    },

    getGameLoginCredential() {
        let domain = '';

        if (window.location.hostname === 'localhost') {
            domain = window.tempDomain;
        } else {
            domain = window.location.hostname;
        }

        let params = {
            domain: domain,
            provider: 'MEGA',
        };

        window.SPL_Provider.getGameSession(params).then((userObj) => {
            controller.vm.setState({ userObj: userObj });
        });
    },

    downloadMegaApp() {
        window.SPL_Other.openNewWindow(controller.vm.state.megaUrl);
    },
};

export default controller;
