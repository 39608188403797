import React, { Fragment } from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';

class Notice extends React.Component {
    constructor() {
        super();
        this.state = {
            in: false,
            items: [],
            currentIndex: 0,
            currentDisplaying: '',
            getNoticeContent: false,
        };
    }

    componentDidUpdate() {
        if (this.props.items && this.props.items.length !== this.state.items.length) {
            this.setState({ items: this.props.items }, () => {
                this.setState({
                    currentIndex: 0,
                    currentDisplaying: this.state.items[0],
                    in: true,
                });
            });
        }
    }

    toggleIn(flag) {
        const { items } = this.state;
        let currentIndex = this.state.currentIndex;
        let currentDisplaying = '';

        if (currentIndex + 1 >= items.length) {
            currentIndex = 0;
        } else {
            currentIndex = currentIndex + 1;
        }
        currentDisplaying = items[currentIndex];

        this.setState({
            in: !this.state.in,
            currentIndex: currentIndex,
            currentDisplaying: currentDisplaying,
        });
    }

    render() {
        return (
            <Translation>
                {(t) => (
                    <Fragment>
                        <CSSTransition classNames='alert' unmountOnExit timeout={300} in={this.state.getNoticeContent}>
                            <div className='home-notice-box' onClick={() => this.setState({ getNoticeContent: false })}>
                                <div
                                    className='notice-popup'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <p className='notice-title'>{t('global:global.announcement3', 'GENERAL ANNOUNCEMENT')}</p>

                                    <ul className='notice-content'>
                                        {this.state.items.map((info, i) => {
                                            return <li key={i} dangerouslySetInnerHTML={{ __html: info }} />;
                                        })}
                                    </ul>

                                    <div className='btn-item'>
                                        <span className='btn-close' onClick={() => this.setState({ getNoticeContent: false })}>
                                            {t('global:global.verification.close', 'Close')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </CSSTransition>

                        <div className='notice'>
                            <div className='container'>
                                <div className='content' onClick={() => this.state.currentDisplaying && this.setState({ getNoticeContent: true })}>
                                    {/* somehow need to have this 2 event to make sure the announcement will keep loop infinity */}
                                    <CSSTransition
                                        in={this.state.in}
                                        classNames='msg'
                                        timeout={20000}
                                        onEntered={() => this.toggleIn('enter')}
                                        onExited={() => this.toggleIn('exit')}
                                    >
                                        <span dangerouslySetInnerHTML={{ __html: this.state.currentDisplaying }} />
                                    </CSSTransition>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )}
            </Translation>
        );
    }
}

export default withTranslation('global')(Notice);
