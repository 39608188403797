import React from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';

import Loading from 'root/webapp/shared/loading/loading';
import Slider from 'react-slick';
import { gu_getContentByCurrencyLanguage } from '../../../../utils/general-util';

class TopWinner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentSlideIndex: 0,
        };
    }

    componentDidMount() {
        this.updateWinnerList();
    }

    componentDidUpdate(prevProps) {
        // check if topWinnerList has changed
        if (this.props.topWinnerList !== prevProps.topWinnerList) {
            this.updateWinnerList();
        }
    }

    updateWinnerList() {
        const { topWinnerList } = this.props;
        const winnerList = gu_getContentByCurrencyLanguage(this, topWinnerList);

        if (winnerList && winnerList['other-winner']) {
            // repeating providers and name to display a smaller gap and not-so-empty slide
            while (winnerList['other-winner'].length < 6) {
                // Repeat the existing data in the array
                winnerList['other-winner'] = winnerList['other-winner'].concat(winnerList['other-winner']);
            }

            // only keep the first 6 data
            winnerList['other-winner'] = winnerList['other-winner'].slice(0, 6);

            this.setState({ winnerList });
        }
    }

    getProviderName(providerCode) {
        switch (providerCode) {
            case 'PP':
                return 'Pragmatic Play';
            case 'NS':
                return 'Nextspin';
            case 'EVO':
                return 'Evolution Gaming';
            case 'BTI':
                return 'me88 Sports';
            case 'CMD':
                return 'CMD368';
            default:
                return 'Unknown Provider';
        }
    }

    render() {
        const { topWinnerList, language } = this.props;
        const winnerList = gu_getContentByCurrencyLanguage(this, topWinnerList);
        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 5,
            slidesToScroll: 1,
            initialSlide: 0,
            arrows: false,
            autoplay: true,
            speed: 3000,
            autoplaySpeed: 3000,
            cssEase: 'linear',
        };

        return (
            <Translation>
                {(t) => (
                    <div className='top-winner'>
                        {this.state.isLoading && <Loading className='vw-100 vh-100'></Loading>}
                        <div className='container'>
                            <span className='title' style={{ color: '#FF4E00' }}>
                                {t('home:home.topWinner.title')}
                            </span>
                            <div className='top-winner-container'>
                                <div className='top-3-container'>
                                    {winnerList &&
                                        winnerList['top-winner']?.length > 0 &&
                                        winnerList['top-winner']?.map((topWinner, i) => {
                                            const imagePath = `/public/html/images/topWinner/${topWinner.provider}.png`;
                                            return (
                                                <div className='block' key={i}>
                                                    <span className={`${topWinner.provider}-provider provider`}>{`${this.getProviderName(topWinner.provider)}`}</span>
                                                    <div>
                                                        <span className='desc'>{topWinner.username}</span>
                                                        <span className='desc'>
                                                            {language.currencyLang}&nbsp;
                                                            {topWinner.amount.toLocaleString('en-US')}
                                                        </span>
                                                    </div>
                                                    <img src={imagePath} alt={topWinner.provider} />
                                                </div>
                                            );
                                        })}
                                </div>
                                <div className='slider-mask'>
                                    <div className='gradient-mask gradient-mask-start'></div>
                                    <Slider {...settings}>
                                        {winnerList &&
                                            winnerList['other-winner']?.length > 0 &&
                                            winnerList['other-winner']?.map((otherWinner, i) => {
                                                const imagePath = `/public/html/images/topWinner/${otherWinner.provider}.jpg`;
                                                return (
                                                    <div className='carousel-container' key={i}>
                                                        <div className='children'>
                                                            <img src={imagePath} alt={otherWinner.provider} />
                                                            <div className='username-curr'>
                                                                <span>{otherWinner.username}</span>
                                                                <span>
                                                                    {language.currencyLang}&nbsp;
                                                                    {otherWinner.amount.toLocaleString('en-US')}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </Slider>
                                    <div className='gradient-mask gradient-mask-end'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['global', 'slot'])(withRouter(TopWinner)));
