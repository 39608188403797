import React from 'react';
import AnnouncementPopup1 from './component/announcement-popup';

import core from 'root/utils/core';

class AnnouncementPopup extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
    }

    components = {
        default: AnnouncementPopup1,
    };

    render() {
        const TagName = core.getCorrectComponent(this.components, 'announcement-popup', null);
        return <TagName {...this.props}></TagName>;
    }
}

export default AnnouncementPopup;
