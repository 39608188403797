import { OTP_VERIFICATION_METHOD, CONTACT_VERIFICATION_METHODS } from '@constants';

export function ou_otpErrorHandler(err) {
    let _errObj = {
        key: err?.response?.data?.errorCode,
        message: err?.response?.data?.message,
        description: err?.response?.data?.description,
    };

    // temp handling until BE introduce errorCode
    if (_errObj?.description === 'Maximum Attempts Exceed') {
        _errObj.key = 'maximumAttemptExceedWithLimit';
        _errObj.maxAttempt = _errObj.message;
    } else if (_errObj?.message === 'phoneNotExist') {
        _errObj.key = 'phoneNotExist';
    } else if (_errObj?.message === 'emailNotExist') {
        _errObj.key = 'emailNotExist';
    } else if (_errObj?.description === 'Invalid Key') {
        _errObj.key = 'invalidOTP';
    } else if (_errObj?.description === 'Invalid Verification') {
        _errObj.key = 'invalidVerification';
    }
    return _errObj;
}

export function ou_getPhoneMethods(portal) {
    const phoneOtpMethods = [
        // { type: null, i18n: 'otp:otp.pleaseSelect' },
        { type: OTP_VERIFICATION_METHOD.PHONE, i18n: 'otp:otp.verification.method.phone' },
        { type: OTP_VERIFICATION_METHOD.WHATSAPP, i18n: 'otp:otp.verification.method.whatsapp' },
    ];

    const enablePhoneMultiplePlatform = portal.settings?.features?.settingsConfiguration?.enablePhoneMultiplePlatform;
    if (!enablePhoneMultiplePlatform) phoneOtpMethods.pop();

    return phoneOtpMethods;
}

export function ou_filterMasterSwitchAuthMethods(authMethods = [], contactType = null) {
    let sendOTPToList = [];

    if (contactType) {
        authMethods = authMethods?.filter((method) => (CONTACT_VERIFICATION_METHODS?.[contactType] || []).includes(method?.type || method)) || [];
    } else {
        authMethods = authMethods?.filter((method) => Object.keys(CONTACT_VERIFICATION_METHODS).includes(method?.type || method)) || [];
    }

    authMethods.map((method) => {
        const type = (method?.type || method)?.toLowerCase();
        sendOTPToList.push({
            key: type,
            i18n: type?.charAt(0).toUpperCase() + type?.slice(1),
            type: type.toUpperCase(),
        });
    });

    return sendOTPToList;
}
