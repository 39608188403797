import React from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import commonUtil from 'root/utils/common-util';
import leaderboardService from 'root/utils/leaderboard-service';
import ImageHelper from 'root/webapp/shared/image-helper/image-helper';
import vm from '../member.controller';

class MemberProfile extends React.Component {
    constructor() {
        super();
        vm.init(this);
        vm.state = {
            username: '',
            memberGroup: '',
            memberGroupIcon: '',
            memberGroupImg: '',
            memberGroupColor: '',
        };
    }

    componentDidMount() {
        const { user } = this.props;
        vm.initCompSettings();
        
        leaderboardService.getMemberRank(this);
        leaderboardService.refreshLeaderboardMonthAndYear(this);
        this.setState({
            username: user.account.login,
            memberGroup: user.account.groupName,
            memberGroupIcon: user.account.imagePath,
        });

        setTimeout(() => {
            commonUtil.loadMemberGroupAutomationSummary(this, user);
        }, 100);
    }

    render() {
        const { currentMemberRank, compSettings } = this.state;
        return (
            <Translation>
                {(t) => (
                    <div className='member-info'>
                        <div className='img-face'>
                            {this.props.user.account.profilePicture ? (
                                <LazyLoadImage src={this.props.user.account.profilePicture} alt='' />
                            ) : (
                                <LazyLoadImage src='/public/html/images/transfer/personal.png' alt='' />
                            )}
                        </div>
                        <span className='username'>{this.state.username}</span>
                        <div className='level-box'>
                            {/* {this.state.memberGroupIcon && this.state.memberGroupIcon !== '' ?
                                <i className="icon-level" style={{ backgroundImage: `url("${this.state.memberGroupIcon}")` }}></i>
                                : <i className="icon-level"></i>
                            } */}
                            <div>
                                <span>{t('settings:settings.memberGroup', 'Member Level')}</span>
                                <span style={{ color: this.state.memberGroupColor }}>{this.state.memberGroup}</span>
                                {compSettings && compSettings.leaderboardCurrencyShow &&
                                    <div className='leaderboard-rank'>
                                        <ImageHelper displayImg={'/public/html/images/leaderboard/gold-medal.png'} className='leaderboard'/>
                                        <span>{currentMemberRank ? currentMemberRank : '-'}</span>
                                    </div>
                                }
                                
                            </div>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation('')(withRouter(MemberProfile)));
