import { generalConstants } from '../constant';

const initialState = {
    dataPage: null,
};

export function generalReducer(state = initialState, action) {
    switch (action.type) {
        case generalConstants.SET_DATA_PAGE:
            return {
                ...state,
                dataPage: action.payload,
            };
        default:
            return state;
    }
}
