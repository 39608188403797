// react/library
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation, Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';

// components/files

// controller/logic
import vm from '../../bank-qr-deposit.controller';
import transactionHelper from 'root/utils/transaction-helper';
import TransactionNote from 'root/webapp/shared/transaction-note/transaction-note.selector';
import { trans_getDepositAmountOptionByMethod } from 'root/utils/transaction-util';
import { AMOUNT_DEPOSIT_METHOD_CODE } from 'root/utils/constants';
import PaymentGatewayIcon from '../../../../../../../../components/molecules/payment-icon/payment-icon';
import BankIcon from '../../../../../../../../components/molecules/bank-icon/bank-icon';

class BankQRDeposit extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
    }

    componentDidMount() {
        vm.initCompSettings();
        if (this.state.depositAllSettings !== null) {
            vm.loadQRbank();
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    componentDidUpdate(prevProps) {
        const prevDepositAmountOptions = JSON.stringify(trans_getDepositAmountOptionByMethod(prevProps.depositAmountOptions, AMOUNT_DEPOSIT_METHOD_CODE.BQR));
        const currentDepositAmountOptions = JSON.stringify(this.state.depositAmountOptions);
        if (prevDepositAmountOptions !== currentDepositAmountOptions) {
            this.setState({ depositAmountOptions: trans_getDepositAmountOptionByMethod(this.props.depositAmountOptions, AMOUNT_DEPOSIT_METHOD_CODE.BQR) });
        }
    }

    render() {
        const {
            depositAmountOptions,
            depositMinMaxLimit,
            isFormValid,
            disableDepositButton,
            disableThirdPartyButton,
            selectedMethodIndex,
            compSettings,
            thirdPartyMemberBank,
            selectedBankIndex,
            bankList,
            methodList,
        } = this.state;

        const { paymentGatewaySetting } = this.props;

        let transactionNoteProps = {
            hideDisplayInfo: compSettings && compSettings.hideDisplayInfo,
            showCustomArticle: this.state.showCustomDepositArticle,
            customArticleContent: this.state.showCustomDepositArticleContent,
            customArticleIsJson: this.state.customArticleIsJson,
            customContentStyle: this.state.customContentStyle,
            depositMinMaxLimit: this.state.depositMinMaxLimit,
        };

        let userCurrency = this.props.user && this.props.user.account && this.props.user.account.currency ? this.props.user.account.currency : null;

        if (userCurrency === 'KHUSD') userCurrency = 'USD';

        return (
            <Translation>
                {(t) => (
                    <Fragment>
                        <div className='group-item bank-options'>
                            <div className='title'>
                                <span>{t('transaction:transaction.deposit.cashdeposit.channel', 'Deposit Channel')}</span>
                                <span className='required'>*</span>
                            </div>
                            <div className='bank-list'>
                                <ul>
                                    {this.state.methodList.map((e, i) => (
                                        <li
                                            key={i}
                                            className={[
                                                'icon-hover-box',
                                                selectedMethodIndex === i ? 'on' : 'off',
                                                e.channel.toLowerCase() === 'ezpqr' || e.channel.toLowerCase() === 'h2pp' ? 'qrbank-item' : '',
                                            ].join(' ')}
                                            onClick={() => {
                                                vm.changeQRChannel(e, i);
                                            }}
                                            onMouseEnter={() => {
                                                this.setState({ qrHoveredIndex: i });
                                            }}
                                            onMouseLeave={() => {
                                                this.setState({ qrHoveredIndex: null });
                                            }}
                                        >
                                            <span className={`icon-box ${e.channel.toLowerCase() === 'ezpqr' || e.channel.toLowerCase() === 'h2pp' ? 'qrbank-icon-box' : ''}`}>
                                                <div className={`icon-${e.channel}`}>
                                                    <PaymentGatewayIcon pgCode={e.channel} />
                                                </div>
                                            </span>
                                            <span>{e.displayName}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        {paymentGatewaySetting &&
                        paymentGatewaySetting.bankQRWithMemberBank &&
                        methodList[selectedMethodIndex] &&
                        methodList[selectedMethodIndex].code &&
                        paymentGatewaySetting.bankQRWithMemberBank.includes(methodList[selectedMethodIndex].code) ? (
                            <>
                                {thirdPartyMemberBank && thirdPartyMemberBank.length > 0 ? (
                                    <>
                                        <div className='group-item bank-options'>
                                            <div className='title'>
                                                <span>{t('transaction:transaction.deposit.onlinetransfer.bankName', 'Bank name')}</span>
                                                <span className='required'>*</span>
                                            </div>
                                            <div className='bank-list'>
                                                <ul>
                                                    {thirdPartyMemberBank.map((bank, i) => (
                                                        <li
                                                            key={i}
                                                            className={[
                                                                'icon-hover-box',
                                                                this.state.selectedBankIndex === i ? 'on' : '',
                                                                bank.bankCode.toLowerCase() === 'ezpqr' || bank.bankCode.toLowerCase() === 'h2pp' ? 'qrbank-item' : '',
                                                            ].join(' ')}
                                                            onClick={() => {
                                                                vm.onThirdPartyBankChanged(i);
                                                            }}
                                                            onMouseEnter={() => {
                                                                this.setState({ hoveredIndex: i });
                                                            }}
                                                            onMouseLeave={() => {
                                                                this.setState({ hoveredIndex: null });
                                                            }}
                                                        >
                                                            <span
                                                                className={`icon-box ${
                                                                    bank.bankCode.toLowerCase() === 'ezpqr' || bank.bankCode.toLowerCase() === 'h2pp' ? 'qrbank-icon-box' : ''
                                                                }`}
                                                            >
                                                                <i
                                                                    className={[
                                                                        'icon-bank',
                                                                        this.state.selectedBankIndex === i ||
                                                                        (this.state.hoveredIndex !== null && this.state.hoveredIndex === i)
                                                                            ? 'icon-bank-on'
                                                                            : 'icon-bank-off',
                                                                        bank.bankCode.toLowerCase() === 'ezpqr' || bank.bankCode.toLowerCase() === 'h2pp'
                                                                            ? 'qrbank-icon-bank'
                                                                            : '',
                                                                    ].join(' ')}
                                                                >
                                                                    <div>
                                                                        <BankIcon bankCode={bank.bankCode} />
                                                                    </div>
                                                                </i>
                                                            </span>
                                                            <span>{bank.displayName}</span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='group-item input-item'>
                                            <div className='title'>
                                                <span>{t('transaction:transaction.deposit.cashdeposit.bankaccount', 'Bank Account')}</span>
                                                <span className='required'>*</span>
                                            </div>
                                            <div className='bank-account-container'>
                                                <div className='input-box'>
                                                    <input
                                                        id='backaccount'
                                                        type='text'
                                                        autoComplete='off'
                                                        disabled={true}
                                                        readOnly={true}
                                                        value={thirdPartyMemberBank[selectedBankIndex].accountHolder}
                                                    />
                                                </div>
                                                <div className='input-box'>
                                                    <input
                                                        id='backaccount'
                                                        type='text'
                                                        autoComplete='off'
                                                        disabled={true}
                                                        readOnly={true}
                                                        value={thirdPartyMemberBank[selectedBankIndex].accountNumber}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className='group-item'>
                                        <span className='reminder-message'>
                                            {t('transaction:transaction.deposit.onlinetransfer.reminderMsg', 'Please add Member Bank Account to proceed. Supported bank:')}
                                            {bankList.map((bank) => (
                                                <span className='bank-name'>{bank.name}</span>
                                            ))}
                                        </span>
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className='group-item bank-options'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.onlinetransfer.bank', 'Bank Options')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='bank-list'>
                                    <ul>
                                        {bankList.map((bank, i) => (
                                            <li
                                                key={i}
                                                className={[
                                                    'icon-hover-box',
                                                    this.state.selectedBankIndex === i ? 'on' : '',
                                                    bank.code.toLowerCase() === 'ezpqr' || bank.code.toLowerCase() === 'h2pp' ? 'qrbank-item' : '',
                                                ].join(' ')}
                                                onClick={() => {
                                                    vm.onBankChanged(bank);
                                                }}
                                                onMouseEnter={() => {
                                                    this.setState({ hoveredIndex: i });
                                                }}
                                                onMouseLeave={() => {
                                                    this.setState({ hoveredIndex: null });
                                                }}
                                            >
                                                <span
                                                    className={`icon-box ${
                                                        bank.code.toLowerCase() === 'ezpqr' || bank.code.toLowerCase() === 'h2pp' ? 'qrbank-icon-box' : ''
                                                    }`}
                                                >
                                                    <i
                                                        className={[
                                                            'icon-bank',
                                                            this.state.selectedBankIndex === i || (this.state.hoveredIndex !== null && this.state.hoveredIndex === i)
                                                                ? 'icon-bank-on'
                                                                : 'icon-bank-off',
                                                            bank.code.toLowerCase() === 'ezpqr' || bank.code.toLowerCase() === 'h2pp' ? 'qrbank-icon-bank' : '',
                                                        ].join(' ')}
                                                    >
                                                        <div>
                                                            <BankIcon bankCode={bank.bankCode} />
                                                        </div>
                                                    </i>
                                                </span>
                                                <span>{bank.name}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        )}

                        <div className='group-item input-item'>
                            <div className='title'>
                                <span>{t('transaction:transaction.deposit.onlinetransfer.amount', 'Deposit Amount')}</span>
                                <span className='required'>*</span>
                            </div>
                            <div className='amount-content'>
                                <div className='input-box'>
                                    <input
                                        id='depositamount'
                                        type='numeric'
                                        autoComplete='off'
                                        placeholder={t('global:global.form.online-transfer-amount-placeholder', {
                                            min: window.SPL_Other.formatAmount(depositMinMaxLimit ? depositMinMaxLimit.min : 0),
                                            max: window.SPL_Other.formatAmount(depositMinMaxLimit ? depositMinMaxLimit.max : 0),
                                        })}
                                        min={'0'}
                                        value={this.state.fields['amount']}
                                        onChange={(e) => transactionHelper.handleFieldChanged(this, e, 'amount')}
                                        onBlur={() => {
                                            transactionHelper.handleValidation(this);
                                        }}
                                    />
                                    <div className='error-validation'>
                                        <Trans
                                            i18nKey={this.state.errors['amount']}
                                            values={{ amount: window.SPL_Other.formatAmount(this.state.amountErrorValue), currency: userCurrency }}
                                        ></Trans>
                                    </div>
                                </div>
                                <ul>
                                    {depositAmountOptions.map((item) => (
                                        <li
                                            key={item}
                                            onClick={() => {
                                                transactionHelper.onDepositAmountClicked(this, item);
                                            }}
                                        >
                                            {item}
                                        </li>
                                    ))}
                                </ul>
                                <div className='qr-reminder error-validation'>
                                    {t('transaction:transaction.deposit.qrpay.reminder', 'Reminder: QR deposit can only be used to scan once only')}
                                </div>
                            </div>
                            {compSettings && compSettings.showCurrencyConversion && (
                                <div className='currency-rate'>
                                    <span className='currency-conversion'>{compSettings.showCurrencyConversion}</span>
                                </div>
                            )}
                        </div>

                        <div className='group-item submit-container'>
                            <div className='title'></div>
                            <div className='amount-content'>
                                <div className='flexbox'>
                                    <button
                                        className='btn btn-submit'
                                        onClick={() => {
                                            vm.submitForm();
                                        }}
                                        disabled={!isFormValid || disableDepositButton}
                                    >
                                        {t('transaction:transaction.deposit.deposithistory.submitbtn', 'SUBMIT').toUpperCase()}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <TransactionNote {...transactionNoteProps} />
                    </Fragment>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'settings', 'global'])(withRouter(BankQRDeposit)));
