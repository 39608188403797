import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import SmallLoading from 'root/webapp/shared/loading/smallLoading';

import vm from '../../referral.controller';

import MyReferral from './myreferral';
import MyBonus2 from './mybonus2';
import MyBonusComm from './mybonus-comm';

class Referral extends React.Component {
    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        vm.momentJSLocalization();
        vm.getReferralSettings();
        vm.getReferralTypeEnabledAndMemberSummaryGroupAutomation();
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        return (
            <Translation>
                {(t) => (
                    <Fragment>
                        <div data-page='/myaccount/'>
                            <div className='m-myaccount-referral-container'>
                                <div className='tab-list m-standard-2-tab-container'>
                                    <div
                                        className={`tab-header standard-tab ${this.state.selectedTab === 'myReferral' ? 'active' : ''}`}
                                        onClick={() => vm.toogleTab('myReferral')}
                                    >
                                        {t('settings:settings.myReferral', 'My Referral')}
                                    </div>
                                    <div className={`tab-header standard-tab ${this.state.selectedTab === 'myBonus' ? 'active' : ''}`} onClick={() => vm.toogleTab('myBonus')}>
                                        {t('settings:settings.myBonus', 'My Bonus')}
                                    </div>
                                </div>

                                {this.state.isLoading ? (
                                    <SmallLoading></SmallLoading>
                                ) : (
                                    <div>
                                        {this.state.selectedTab === 'myReferral' && (
                                            <MyReferral {...this.state} toggleModal={vm.toggleModal.bind(this)} share={vm.share.bind(this)}></MyReferral>
                                        )}

                                        {this.state.selectedTab === 'myBonus' && !this.state.enabledReferralComm && (
                                            <MyBonus2
                                                {...this.state}
                                                handleDateChanged={vm.handleDateChanged.bind(this)}
                                                searchHistory={vm.searchHistory.bind(this)}
                                                handlePrevNextClick={vm.handlePrevNextClick.bind(this)}
                                                handlePageClick={vm.handlePageClick.bind(this)}
                                                toggleModal={vm.toggleModal.bind(this)}
                                                openBonusDetailDialog={vm.openBonusDetailDialog.bind(this)}
                                            ></MyBonus2>
                                        )}

                                        {this.state.selectedTab === 'myBonus' && this.state.enabledReferralComm && (
                                            <MyBonusComm
                                                {...this.state}
                                                handleDateChanged={vm.handleDateChanged.bind(this)}
                                                searchHistory={vm.searchHistory.bind(this)}
                                                handlePrevNextClick={vm.handlePrevNextClick.bind(this)}
                                                handlePageClick={vm.handlePageClick.bind(this)}
                                                toggleModal={vm.toggleModal.bind(this)}
                                                openBonusDetailDialog={vm.openBonusDetailDialog.bind(this)}
                                            ></MyBonusComm>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </Fragment>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'referral', 'settings'])(withRouter(Referral)));
