import React from 'react';
import { Translation, withTranslation } from 'react-i18next';
import ScrollContainer from 'react-indiana-drag-scroll';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import SVGContact from 'root/components/svgIcon/svgContact';
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component';

class Contact extends React.Component {
    constructor() {
        super();
        this.state = {
            contactList: [],
        };
    }

    componentDidUpdate() {
        if (this.props.contactList && this.props.contactList.length !== this.state.contactList.length) {
            this.setState({ contactList: this.props.contactList });
        }
    }

    render() {
        const langKey = this.props.language.key.toLowerCase();
        return (
            <Translation>
                {(t) => (
                    <div className='contact-box'>
                        <LazyLoadImage src={`/public/html/images/homepage/contact-${langKey}.png`}></LazyLoadImage>
                        <ul className='contact-list'>
                            <ScrollContainer className='contact-list' hideScrollbars={false}>
                                {this.state.contactList.map((contact, i) => {
                                    return (
                                        <li key={i}>
                                            <SVGContact className='contactSvg' name={contact.name}></SVGContact>
                                            {/* <i style={{ backgroundImage: `url("/public/html/images/contact/${contact.name}.png")` }}></i> */}
                                            <div
                                                onClick={() => {
                                                    if (contact.customTranslate || contact.isLiveChat) {
                                                        this.props.contactOnClick(contact);
                                                    }
                                                }}
                                            >
                                                <span>{t(contact.translate, contact.name)}</span>
                                                {contact.isLiveChat ? (
                                                    // livechat
                                                    <span>{t(contact.desc).toUpperCase()}</span>
                                                ) : (
                                                    // normal contact
                                                    <span>{contact.customTranslate ? contact.customTranslate : contact.desc}</span>
                                                )}
                                            </div>
                                        </li>
                                    );
                                })}
                            </ScrollContainer>
                        </ul>

                        {/* <ul className="download-list" >
                            <li onClick={() => { this.props.history.push('/download#android') }}>
                                <span>{t('global:global.banner.androidDownload', 'Android')}</span>
                            </li>
                            <li onClick={() => { this.props.history.push('/download#ios') }}>
                                <span>{t('global:global.banner.iosDownload', 'iOS')}</span>
                            </li>
                        </ul> */}
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['global'])(withRouter(Contact)));
