import React from 'react';
import { Translation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import SmallLoading from 'root/webapp/shared/loading/smallLoading';
import SVGElements from 'root/components/SVGElements';

class Turnover extends React.Component {
    constructor() {
        super();
        this.state = {
            navList: [
                { name: 'Provider Balance', translate: 'settings:settings.providerbalance' },
                { name: 'Weekly Turnover', translate: 'settings:settings.weeklyturnover' },
            ],
            active: 0,
            refeshing: false,
        };
    }

    reloadOnClick = () => {
        const { getMainWallet, loadProviders } = this.props;
        getMainWallet();
        loadProviders(true);
    };

    render() {
        const { totalProviderBalance, totalProviderTurnover, iconLoading } = this.props.parentState;
        return (
            <Translation>
                {(t) => (
                    <div className='turnover-panel'>
                        <ul className='turnover-nav'>
                            {this.state.navList.map(({ name, translate }, i) => (
                                <li
                                    key={name}
                                    className={[i === this.state.active ? 'on' : ''].join('')}
                                    onClick={() => {
                                        this.setState({ ...this.state, active: i });
                                    }}
                                >
                                    <span>{t(translate)}</span>
                                </li>
                            ))}
                        </ul>
                        <div className='total-box'>
                            <span>{t('settings:settings.total')}</span>
                            <div>
                                <span>
                                    {this.state.active === 0 ? window.SPL_Other.formatAmount(totalProviderBalance) : window.SPL_Other.formatAmount(totalProviderTurnover)}
                                </span>
                                <span className='icon-box'>
                                    <CSSTransition classNames='rotate360' in={iconLoading} timeout={1000}>
                                        <SVGElements
                                            className='icon-reload'
                                            name='reload-icon'
                                            onClick={() => {
                                                this.reloadOnClick();
                                            }}
                                        />
                                    </CSSTransition>
                                </span>
                            </div>
                        </div>

                        <div className='turnover-content'>
                            {this.props.parentState.categorizedProviderList.length ? (
                                this.props.parentState.categorizedProviderList.map(({ title, list }, i) => {
                                    if (title !== 'Wallet') {
                                        return (
                                            <>
                                                {list && list.length > 0 && (
                                                    <div key={title}>
                                                        <div className='title'>
                                                            <span>{title}</span>
                                                        </div>
                                                        <ul>
                                                            {list.map(({ balance, turnover, display }) => (
                                                                <li key={display}>
                                                                    <span>{display === 's-Sports' ? 'SBOBet' : display === 'Shi Cai Lottery' ? 'Shi Cai' : display}</span>
                                                                    {this.state.active === 0 ? (
                                                                        <span>{balance ? window.SPL_Other.formatAmount(balance) : window.SPL_Other.formatAmount('0.00')}</span>
                                                                    ) : (
                                                                        <span>
                                                                            {turnover ? window.SPL_Other.formatAmount(title.turnover) : window.SPL_Other.formatAmount('0.00')}
                                                                        </span>
                                                                    )}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}
                                            </>
                                        );
                                    }
                                })
                            ) : (
                                <SmallLoading></SmallLoading>
                            )}
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default Turnover;
