import React from 'react';
import { useSelector } from 'react-redux';
import DFreeSpin from './components/freespin';
import MFreeSpin from './components/m-freespin';

const FreeSpin = () => {
    const isMobile = useSelector((s) => s.screen.viewType === 'mobile');

    return isMobile ? <MFreeSpin /> : <DFreeSpin />;
};

export default FreeSpin;
