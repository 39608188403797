import React from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';

import QRCode from 'qrcode.react';
import commonUtil from 'root/utils/common-util';
import Slider from 'root/webapp/shared/component/Slider';
import Instruction from './Instruction';

class AppDownload extends React.Component {
    constructor(props) {
        super();
        this.props = props;
        this.state = {
            appDownloadLinkData: {},
            openPopoup: false,
            phoneType: null,
            instruction: [],
            slides: [],
        };
    }

    componentDidMount() {
        this.getAppInstructionList();
        commonUtil.getAppDownloadLink(this);
    }

    appIntructionOnclick(mobile) {
        this.setState({ openPopoup: !this.state.openPopoup, phoneType: mobile });
    }

    getAppInstructionList() {
        const { language } = this.props;
        window.SPL_JsonSettings.getAppInstructionJson((data) => {
            if (data) {
                let appSlides = data.slides;
                if (appSlides && appSlides[language.key]) {
                    appSlides = appSlides[language.key];
                }
                this.setState({ instruction: data, slides: appSlides });
            }
        });
    }

    renderQRCode(deviceType) {
        const { appDownloadLinkData } = this.state;
        return (
            <QRCode
                value={commonUtil.getQRCodeValue(appDownloadLinkData && appDownloadLinkData[deviceType])}
                level='M'
                renderAs='svg'
                size={90}
                bgColor='#efefef'
                fgColor='black'
            />
        );
    }

    renderSlides() {
        let slidesContent = [];
        const { slides } = this.state;
        if (slides) {
            slides.map((slide, i) => {
                let obj = {};
                obj.content = (
                    <Translation>
                        {(t) => (
                            <div className='slide-container'>
                                <div className='slide-visual'>
                                    <img alt='' src={slide.img} />
                                </div>
                                <div className='slide-headlines'>
                                    {this.props.t(slide.headline) + ' '}
                                    <span>{this.props.t(slide.headline_h)}</span>
                                </div>
                            </div>
                        )}
                    </Translation>
                );
                slidesContent.push(obj);
            });
        }

        return slidesContent;
    }

    render() {
        const { viewType } = this.props.screen;
        const { appDownloadLinkData } = this.state;

        const isMobile = viewType === 'mobile';
        const instructionPopUp = {
            isMobile,
            device: this.state.phoneType,
            togglePopUp: this.appIntructionOnclick.bind(this),
        };

        return (
            <Translation>
                {(t) => (
                    <div className='app-download'>
                        <div className='container'>
                            <div className='app-download-container'>
                                <div className='section-qr'>
                                    <div className='brand-container'>
                                        <div className='brand-logo'>
                                            <img alt='' src={'/public/html/images/footer/me88-apps-footer-icon.png'} />
                                        </div>
                                        <div className='brand-quote'>
                                            <span>{t('app:app.banner.greatGames', 'Great Games ')}</span>
                                            {t('app:app.banner.allDevices', 'on All Devices')}
                                        </div>
                                        <div className='brand-title' dangerouslySetInnerHTML={{ __html: t(`app:app.banner.title`) }}></div>
                                    </div>
                                    <div className='qr-container'>
                                        {appDownloadLinkData && appDownloadLinkData.android && (
                                            <div className='qr-block'>
                                                <div className='qr-code'>{this.renderQRCode('ios')}</div>
                                                <div className='qr-platform-icon'>
                                                    <img alt='' src={'/public/html/images/homepage/app_download/ios.svg'} />
                                                </div>
                                                <div className='qr-platform-instruction' onClick={() => this.appIntructionOnclick('ios')}>
                                                    <img alt='' src={'/public/html/images/homepage/app_download/info-icon.svg'} />
                                                    {t('app:app.banner.instruction', 'Instruction')}
                                                </div>
                                            </div>
                                        )}

                                        {appDownloadLinkData && appDownloadLinkData.ios && (
                                            <div className='qr-block'>
                                                <div className='qr-code'>{this.renderQRCode('android')}</div>
                                                <div className='qr-platform-icon'>
                                                    <img alt='' src={'/public/html/images/homepage/app_download/android.svg'} />
                                                </div>
                                                <div className='qr-platform-instruction' onClick={() => this.appIntructionOnclick('android')}>
                                                    <img alt='' src={'/public/html/images/homepage/app_download/info-icon.svg'} />
                                                    {t('app:app.banner.instruction', 'Instruction')}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {this.state.openPopoup && this.state.instruction && (
                                        <Instruction type={this.state.phoneType} instructionPopUp={instructionPopUp} instruction={this.state.instruction} />
                                    )}
                                </div>
                                <div className='section-slider'>
                                    {this.state.slides.length > 0 && <Slider autoPlay={true} items={this.renderSlides(t)} transionName='slider-fade' />}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['app'])(withRouter(AppDownload)));
