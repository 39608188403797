import React from 'react';
import { Translation, withTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';

class AnnouncementPopup extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
        this.state = {
            slideValue: "0",
            slideMaxValue: "100",
            debouncedInputMS: 1000,
            showSliderCaptcha: this.props.showSliderCaptcha
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    sliderRecaptchaOnChange(e) {
        if (e.target && e.target.value) {
            this.setState({ slideValue: e.target.value });

            let rangeStyle = document.getElementsByClassName('rangeStyle')[0];
            if (rangeStyle) {
                if (rangeStyle.getElementsByTagName('style').length > 0) {
                    rangeStyle.getElementsByTagName('style')[0].innerHTML +=
                        '.slider-recaptcha input::-webkit-slider-thumb {border: 1px solid #ff4d00;} .slider-recaptcha input::-moz-range-thumb {border: 1px solid #ff4d00; height: 1.4rem !important;}';
                } else {
                    rangeStyle.innerHTML +=
                        '<style>.slider-recaptcha input::-webkit-slider-thumb {border: 1px solid #ff4d00;} .slider-recaptcha input::-moz-range-thumb {border: 1px solid #ff4d00; height: 1.4rem !important;}}</style>';
                }
            }
        }
    }

    sliderRecaptchaOnTouchEnd() {
        const { slideMaxValue } = this.state;
        
        let _slideValue = this.state.slideValue;
        if (_slideValue === slideMaxValue) {
            this.closeRecaptchaPopUp();
        } else {
            let sliderInterval;
            sliderInterval = setInterval(()=> {
                if (_slideValue > 0) {
                    _slideValue--;
                    this.setState({ slideValue: _slideValue })
                } else if (_slideValue === 0) {
                    clearInterval(sliderInterval);
                    this.setState({ slideValue: _slideValue });

                    let rangeStyle = document.getElementsByClassName('rangeStyle')[0];
                    if (rangeStyle) {
                        if (rangeStyle.getElementsByTagName('style').length > 0) {
                            rangeStyle.getElementsByTagName('style')[0].innerHTML +=
                                '.slider-recaptcha input::-webkit-slider-thumb {border: 0;} .slider-recaptcha input::-moz-range-thumb {border: 0; height: 1.5rem !important;}';
                        } else {
                            rangeStyle.innerHTML +=
                                '<style>.slider-recaptcha input::-webkit-slider-thumb {border: 0;} .slider-recaptcha input::-moz-range-thumb {border: 0; height: 1.5rem !important;} </style>';
                        }
                    }
                }
            }, 1);
        }
    }

    closeRecaptchaPopUp() {
        this.setState({ showSliderCaptcha: false});
        this.props.triggerV3token();
    }

    render() {
        return (
            <Translation>
                {(t) => (
                    <Modal
                        overlayClassName='m-standard-popup-overlay m-slider-recaptcha-popup-overlay'
                        className='standard-popup-modal-container slider-recaptcha-modal-container'
                        isOpen={this.state.showSliderCaptcha}
                        shouldCloseOnOverlayClick={false}
                        shouldCloseOnEsc={false}
                        ariaHideApp={false}
                        onRequestClose={() => this.closeRecaptchaPopUp()}
                    >
                        <div className='standard-popup-modal-header slideHeader'>
                            <span className='standard-modal-title'>{t('register:register.form.slideCaptcha', 'Slide to continue')}</span>
                        </div>
                        <div className='standard-popup-modal-body slider-recaptcha'>
                            <input type='range' min='0' max={this.state.slideMaxValue} value={this.state.slideValue} 
                                onChange={(e) => this.sliderRecaptchaOnChange(e)}
                                onTouchEnd={() => this.sliderRecaptchaOnTouchEnd()}
                            />
                            <div className='rangeStyle'></div>
                            <span>{t('register:register.form.slideCaptcha', 'Slide to continue')}</span>
                        </div>
                        </Modal>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['register'])(AnnouncementPopup));
