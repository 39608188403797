// library
import React from 'react';
import styles from './button.module.scss';

const Button = ({ ...props }) => {
    const { classes, scssClasses, style, variant, disabled, onClick, onMouseOver, onMouseLeave, onMouseEnter, className, children } = props;
    return (
        <button
            className={`${styles.button || ''} ${styles[variant] || ''} ${styles[classes] || ''} ${scssClasses || ''} ${className || ''} atoms-button`}
            style={style}
            disabled={disabled}
            onClick={onClick}
            onMouseLeave={onMouseLeave}
            onMouseEnter={onMouseEnter}
            onMouseOver={onMouseOver}
        >
            {children}
        </button>
    );
};

export default Button;
