import React, { Fragment } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import providerService from 'root/utils/providerService';
import notification from 'root/utils/notification';
import { portalAction } from 'root/redux/action';
import { TweenMax } from 'gsap';
import { pu_processGameDataWithFavGames } from '@utils/provider-util';

const controller = {
    vm: null,
    providerData: {
        GG: {
            key: 'gg',
            provider: 'GG',
            suspend: [
                { left: 680, bottom: 170, width: 77, height: 83, suspendImg: '/public/html/images/fishing/icon/suspend/1_2.png' },
                { left: 500, bottom: 270, width: 113, height: 119, suspendImg: '/public/html/images/fishing/icon/suspend/1_3.png' },
            ],
            text: 'fishing:fishing.gg.desc_1',
            mobileText: 'fishing:fishing.gg.mobile_desc_1',
            bgImg: '/public/html/images/fishing/gg-banner.png',
            routeName: '',
            maintenance: false,
        },
        PT: {
            key: 'pt',
            provider: 'PT',
            suspend: [
                { left: 680, bottom: 170, width: 77, height: 83, suspendImg: '/public/html/images/fishing/icon/suspend/2_2.png' },
                { left: 500, bottom: 270, width: 113, height: 119, suspendImg: '/public/html/images/fishing/icon/suspend/2_3.png' },
            ],
            text: 'fishing:fishing.pt.desc_1',
            mobileText: 'fishing:fishing.pt.mobile_desc_1',
            bgImg: '/public/html/images/fishing/pt-banner.png',
            maintenance: false,
        },
        SA: {
            key: 'sa',
            provider: 'SA',
            suspend: [
                { left: 680, bottom: 170, width: 77, height: 83, suspendImg: '/public/html/images/fishing/icon/suspend/4_2.png' },
                { left: 500, bottom: 270, width: 113, height: 119, suspendImg: '/public/html/images/fishing/icon/suspend/4_3.png' },
            ],
            text: 'fishing:fishing.sa.desc_1',
            mobileText: 'fishing:fishing.sa.mobile_desc_1',
            bgImg: '/public/html/images/fishing/sa-banner.png',
            maintenance: false,
        },
        SGFW: {
            key: 'sgfw',
            provider: 'SGFW',
            suspend: [
                { left: 170, bottom: 100, width: 200, height: 197, suspendImg: '/public/html/images/fishing/icon/suspend/5_2.png' },
                { left: 570, bottom: 88, width: 130, height: 121, suspendImg: '/public/html/images/fishing/icon/suspend/5_3.png' },
                { left: 500, bottom: 180, width: 260, height: 257, suspendImg: '/public/html/images/fishing/icon/suspend/5_4.png' },
            ],
            text: 'fishing:fishing.sgfw.desc_1',
            mobileText: 'fishing:fishing.sgfw.mobile_desc_1',
            bgImg: '/public/html/images/fishing/sgfw-banner.png',
            routeName: 'sg-fishingwar',
            maintenance: false,
        },
        SGAH: {
            key: 'sgah',
            provider: 'SGAH',
            suspend: [
                { left: 60, bottom: 300, width: 80, height: 77, suspendImg: '/public/html/images/fishing/icon/suspend/6_2.png' },
                { left: 190, bottom: 90, width: 70, height: 52, suspendImg: '/public/html/images/fishing/icon/suspend/6_3.png' },
                { left: 650, bottom: 290, width: 109, height: 78, suspendImg: '/public/html/images/fishing/icon/suspend/6_4.png' },
            ],
            text: 'fishing:fishing.sgah.desc_1',
            mobileText: 'fishing:fishing.sgah.mobile_desc_1',
            bgImg: '/public/html/images/fishing/sgah-banner.png',
            routeName: 'sgah-fishing',
            maintenance: false,
        },
        SGZP: {
            key: 'sgzp',
            provider: 'SGZP',
            suspend: [
                { left: 60, bottom: 300, width: 80, height: 77, suspendImg: '/public/html/images/fishing/icon/suspend/7_2.png' },
                { left: 190, bottom: 90, width: 70, height: 52, suspendImg: '/public/html/images/fishing/icon/suspend/7_3.png' },
                { left: 650, bottom: 290, width: 109, height: 78, suspendImg: '/public/html/images/fishing/icon/suspend/7_4.png' },
            ],
            text: 'fishing:fishing.sgzp.desc_1',
            mobileText: 'fishing:fishing.sgzp.mobile_desc_1',
            bgImg: '/public/html/images/fishing/sgzp-banner.png',
            routeName: 'sgzp-fishing',
            maintenance: false,
        },
        BFJL: {
            key: 'bfjl',
            provider: 'BFJL',
            suspend: [
                { left: 560, bottom: 260, width: 130, height: 145, suspendImg: '/public/html/images/fishing/icon/suspend/8_2.png' },
                { left: 120, bottom: 110, width: 140, height: 93, suspendImg: '/public/html/images/fishing/icon/suspend/8_3.png' },
            ],
            text: 'fishing:fishing.bfjl.desc_1',
            mobileText: 'fishing:fishing.bfjl.mobile_desc_1',
            bgImg: '/public/html/images/fishing/bfjl-banner.png',
            routeName: 'bfjl-fishing',
            maintenance: false,
        },
        BLJL: {
            key: 'bljl',
            provider: 'BLJL',
            suspend: [
                { left: 60, bottom: 300, width: 80, height: 77, suspendImg: '/public/html/images/fishing/icon/suspend/9_2.png' },
                { left: 190, bottom: 90, width: 70, height: 52, suspendImg: '/public/html/images/fishing/icon/suspend/9_3.png' },
            ],
            text: 'fishing:fishing.bljl.desc_1',
            mobileText: 'fishing:fishing.bljl.mobile_desc_1',
            bgImg: '/public/html/images/fishing/bljl-banner.png',
            routeName: 'bljl-fishing',
            maintenance: false,
        },
        DTJL: {
            key: 'dtjl',
            provider: 'DTJL',
            suspend: [
                { left: 60, bottom: 300, width: 80, height: 77, suspendImg: '/public/html/images/fishing/icon/suspend/10_2.png' },
                { left: 190, bottom: 90, width: 70, height: 52, suspendImg: '/public/html/images/fishing/icon/suspend/10_3.png' },
                { left: 650, bottom: 290, width: 109, height: 78, suspendImg: '/public/html/images/fishing/icon/suspend/10_4.png' },
            ],
            text: 'fishing:fishing.dtjl.desc_1',
            mobileText: 'fishing:fishing.dtjl.mobile_desc_1',
            bgImg: '/public/html/images/fishing/dtjl-banner.png',
            routeName: 'dtjl-fishing',
            maintenance: false,
        },
        DFJL: {
            key: 'dfjl',
            provider: 'DFJL',
            suspend: [
                { left: 60, bottom: 300, width: 80, height: 77, suspendImg: '/public/html/images/fishing/icon/suspend/11_2.png' },
                { left: 190, bottom: 90, width: 70, height: 52, suspendImg: '/public/html/images/fishing/icon/suspend/11_3.png' },
                { left: 650, bottom: 290, width: 109, height: 78, suspendImg: '/public/html/images/fishing/icon/suspend/11_4.png' },
            ],
            text: 'fishing:fishing.dfjl.desc_1',
            mobileText: 'fishing:fishing.dfjl.mobile_desc_1',
            bgImg: '/public/html/images/fishing/dfjl-banner.png',
            routeName: 'dfjl-fishing',
            maintenance: false,
        },
        HFJL: {
            key: 'hfjl',
            provider: 'HFJL',
            suspend: [
                { left: 60, bottom: 300, width: 80, height: 77, suspendImg: '/public/html/images/fishing/icon/suspend/12_2.png' },
                { left: 190, bottom: 90, width: 70, height: 52, suspendImg: '/public/html/images/fishing/icon/suspend/12_3.png' },
            ],
            text: 'fishing:fishing.hfjl.desc_1',
            mobileText: 'fishing:fishing.hfjl.mobile_desc_1',
            bgImg: '/public/html/images/fishing/hfjl-banner.png',
            routeName: 'hfjl-fishing',
            maintenance: false,
        },
        JFJL: {
            key: 'jfjl',
            provider: 'JFJL',
            suspend: [
                { left: 60, bottom: 300, width: 80, height: 77, suspendImg: '/public/html/images/fishing/icon/suspend/13_2.png' },
                { left: 190, bottom: 90, width: 70, height: 52, suspendImg: '/public/html/images/fishing/icon/suspend/13_3.png' },
            ],
            text: 'fishing:fishing.jfjl.desc_1',
            mobileText: 'fishing:fishing.jfjl.mobile_desc_1',
            bgImg: '/public/html/images/fishing/jfjl-banner.png',
            routeName: 'jfjl-fishing',
            maintenance: false,
        },
        MFJL: {
            key: 'mfjl',
            provider: 'MFJL',
            suspend: [
                { left: 60, bottom: 300, width: 80, height: 77, suspendImg: '/public/html/images/fishing/icon/suspend/14_2.png' },
                { left: 190, bottom: 90, width: 70, height: 52, suspendImg: '/public/html/images/fishing/icon/suspend/14_3.png' },
            ],
            text: 'fishing:fishing.mfjl.desc_1',
            mobileText: 'fishing:fishing.mfjl.mobile_desc_1',
            bgImg: '/public/html/images/fishing/mfjl-banner.png',
            routeName: 'mfjl-fishing',
            maintenance: false,
        },
        RFJL: {
            key: 'rfjl',
            provider: 'RFJL',
            suspend: [
                { left: 60, bottom: 300, width: 80, height: 77, suspendImg: '/public/html/images/fishing/icon/suspend/15_2.png' },
                { left: 190, bottom: 90, width: 70, height: 52, suspendImg: '/public/html/images/fishing/icon/suspend/15_3.png' },
                { left: 650, bottom: 290, width: 109, height: 78, suspendImg: '/public/html/images/fishing/icon/suspend/15_4.png' },
            ],
            text: 'fishing:fishing.rfjl.desc_1',
            mobileText: 'fishing:fishing.rfjl.mobile_desc_1',
            bgImg: '/public/html/images/fishing/rfjl-banner.png',
            routeName: 'rfjl-fishing',
            maintenance: false,
        },
        SG: {
            key: 'sg',
            provider: 'SG',
            suspend: [
                { left: 460, bottom: 260, width: 130, height: 145, suspendImg: '/public/html/images/fishing/icon/suspend/3_2.png' },
                { left: 120, bottom: 110, width: 140, height: 93, suspendImg: '/public/html/images/fishing/icon/suspend/3_3.png' },
                { left: 490, bottom: 80, width: 190, height: 185, suspendImg: '/public/html/images/fishing/icon/suspend/3_4.png' },
            ],
            // text: 'fishing:fishing.sg.desc_1',
            text: 'global:global.submenu.slots.spadeGaming',
            mobileText: 'fishing:fishing.sg.mobile_desc_1',
            bgImg: '/public/html/images/fishing/sg-banner.png',
            routeName: 'sg-fishing',
            maintenance: false,
        },
        JL: {
            key: 'jl',
            provider: 'JL',
            suspend: [
                { left: 60, bottom: 300, width: 80, height: 77, suspendImg: '/public/html/images/fishing/icon/suspend/15_2.png' },
                { left: 190, bottom: 90, width: 70, height: 52, suspendImg: '/public/html/images/fishing/icon/suspend/15_3.png' },
                { left: 650, bottom: 290, width: 109, height: 78, suspendImg: '/public/html/images/fishing/icon/suspend/15_4.png' },
            ],
            text: 'global:global.submenu.slots.jili',
            mobileText: 'fishing:fishing.rfjl.mobile_desc_1',
            bgImg: '/public/html/images/fishing/rfjl-banner.png',
            routeName: 'jl-fishing',
            maintenance: false,
        },
        NS: {
            key: 'ns',
            provider: 'NS',
            suspend: [
                { left: 60, bottom: 300, width: 80, height: 77, suspendImg: '/public/html/images/fishing/icon/suspend/15_2.png' },
                { left: 190, bottom: 90, width: 70, height: 52, suspendImg: '/public/html/images/fishing/icon/suspend/15_3.png' },
                { left: 650, bottom: 290, width: 109, height: 78, suspendImg: '/public/html/images/fishing/icon/suspend/15_4.png' },
            ],
            text: 'global:global.submenu.casino.nextspin',
            mobileText: 'fishing:fishing.rfjl.mobile_desc_1',
            bgImg: '/public/html/images/fishing/rfjl-banner.png',
            routeName: 'ns-fishing',
            maintenance: false,
        },
        MT: {
            key: 'mt',
            provider: 'MT',
            suspend: [
                { left: 60, bottom: 300, width: 80, height: 77, suspendImg: '/public/html/images/fishing/icon/suspend/15_2.png' },
                { left: 190, bottom: 90, width: 70, height: 52, suspendImg: '/public/html/images/fishing/icon/suspend/15_3.png' },
                { left: 650, bottom: 290, width: 109, height: 78, suspendImg: '/public/html/images/fishing/icon/suspend/15_4.png' },
            ],
            text: 'global:global.submenu.casino.minited',
            mobileText: 'fishing:fishing.rfjl.mobile_desc_1',
            bgImg: '/public/html/images/fishing/rfjl-banner.png',
            routeName: 'mt-fishing',
            maintenance: false,
        },
        CQ9: {
            key: 'cq9',
            provider: 'CQ9',
            suspend: [
                { left: 60, bottom: 300, width: 80, height: 77, suspendImg: '/public/html/images/fishing/icon/suspend/15_2.png' },
                { left: 190, bottom: 90, width: 70, height: 52, suspendImg: '/public/html/images/fishing/icon/suspend/15_3.png' },
                { left: 650, bottom: 290, width: 109, height: 78, suspendImg: '/public/html/images/fishing/icon/suspend/15_4.png' },
            ],
            text: 'global:global.submenu.slots.cq9',
            mobileText: 'fishing:fishing.rfjl.mobile_desc_1',
            bgImg: '/public/html/images/fishing/cq9-banner.png',
            routeName: 'cq9-fishing',
            maintenance: false,
        },
        YGR: {
            key: 'ygr',
            provider: 'YGR',
            suspend: [
                { left: 60, bottom: 300, width: 80, height: 77, suspendImg: '/public/html/images/fishing/icon/suspend/15_2.png' },
                { left: 190, bottom: 90, width: 70, height: 52, suspendImg: '/public/html/images/fishing/icon/suspend/15_3.png' },
                { left: 650, bottom: 290, width: 109, height: 78, suspendImg: '/public/html/images/fishing/icon/suspend/15_4.png' },
            ],
            text: 'global:global.submenu.slots.ygr',
            mobileText: 'fishing:fishing.rfjl.mobile_desc_1',
            bgImg: '/public/html/images/fishing/ygr-banner.png',
            routeName: 'ygr-fishing',
            maintenance: false,
        },
        FC: {
            key: 'fc',
            provider: 'FC',
            suspend: [
                { left: 60, bottom: 300, width: 80, height: 77, suspendImg: '/public/html/images/fishing/icon/suspend/15_2.png' },
                { left: 190, bottom: 90, width: 70, height: 52, suspendImg: '/public/html/images/fishing/icon/suspend/15_3.png' },
                { left: 650, bottom: 290, width: 109, height: 78, suspendImg: '/public/html/images/fishing/icon/suspend/15_4.png' },
            ],
            text: 'global:global.submenu.slots.fc',
            mobileText: 'fishing:fishing.rfjl.mobile_desc_1',
            bgImg: '/public/html/images/fishing/fc-banner.png',
            routeName: 'fc-fishing',
            maintenance: false,
        },
        JDB: {
            key: 'jdb',
            provider: 'JDB',
            suspend: [
                { left: 60, bottom: 300, width: 80, height: 77, suspendImg: '/public/html/images/fishing/icon/suspend/15_2.png' },
                { left: 190, bottom: 90, width: 70, height: 52, suspendImg: '/public/html/images/fishing/icon/suspend/15_3.png' },
                { left: 650, bottom: 290, width: 109, height: 78, suspendImg: '/public/html/images/fishing/icon/suspend/15_4.png' },
            ],
            text: 'global:global.submenu.slots.jdb',
            mobileText: 'fishing:fishing.jdb.mobile_desc_1',
            bgImg: '/public/html/images/fishing/jdb-banner.png',
            routeName: 'jdb-fishing',
            maintenance: false,
        },
        JKR: {
            key: 'jkr',
            provider: 'JKR',
            suspend: [
                { left: 60, bottom: 300, width: 80, height: 77, suspendImg: '/public/html/images/fishing/icon/suspend/16_2.png' },
                { left: 190, bottom: 90, width: 70, height: 52, suspendImg: '/public/html/images/fishing/icon/suspend/16_3.png' },
                { left: 650, bottom: 290, width: 109, height: 78, suspendImg: '/public/html/images/fishing/icon/suspend/16_4.png' },
            ],
            text: 'global:global.submenu.slots.joker',
            mobileText: 'fishing:fishing.rfjl.mobile_desc_1',
            bgImg: '/public/html/images/fishing/jkr-banner.png',
            routeName: 'jkr-fishing',
            maintenance: false,
        },
        PS: {
            key: 'ps',
            provider: 'PS',
            suspend: [
                { left: 60, bottom: 300, width: 80, height: 77, suspendImg: '/public/html/images/fishing/icon/suspend/16_2.png' },
                { left: 190, bottom: 90, width: 70, height: 52, suspendImg: '/public/html/images/fishing/icon/suspend/16_3.png' },
                { left: 650, bottom: 290, width: 109, height: 78, suspendImg: '/public/html/images/fishing/icon/suspend/16_4.png' },
            ],
            text: 'global:global.submenu.slots.ps',
            bgImg: '/public/html/images/fishing/ps-banner.png',
            routeName: 'ps-fishing',
            maintenance: false,
        },
        SPX: {
            key: 'spx',
            provider: 'SPX',
            suspend: [
                { left: 60, bottom: 300, width: 80, height: 77, suspendImg: '/public/html/images/fishing/icon/suspend/16_2.png' },
                { left: 190, bottom: 90, width: 70, height: 52, suspendImg: '/public/html/images/fishing/icon/suspend/16_3.png' },
                { left: 650, bottom: 290, width: 109, height: 78, suspendImg: '/public/html/images/fishing/icon/suspend/16_4.png' },
            ],
            text: 'global:global.submenu.slots.spx',
            bgImg: '/public/html/images/fishing/spx-banner.png',
            routeName: 'spx-fishing',
            maintenance: false,
        },
        DS: {
            key: 'ds',
            provider: 'DS',
            suspend: [
                { left: 60, bottom: 300, width: 80, height: 77, suspendImg: '/public/html/images/fishing/icon/suspend/16_2.png' },
                { left: 190, bottom: 90, width: 70, height: 52, suspendImg: '/public/html/images/fishing/icon/suspend/16_3.png' },
                { left: 650, bottom: 290, width: 109, height: 78, suspendImg: '/public/html/images/fishing/icon/suspend/16_4.png' },
            ],
            text: 'global:global.submenu.slots.ds',
            bgImg: '/public/html/images/fishing/ds-banner.png',
            routeName: 'ds-fishing',
            maintenance: false,
        },
        VP: {
            key: 'vp',
            provider: 'VP',
            suspend: [
                { left: 60, bottom: 300, width: 80, height: 77, suspendImg: '/public/html/images/fishing/icon/suspend/16_2.png' },
                { left: 190, bottom: 90, width: 70, height: 52, suspendImg: '/public/html/images/fishing/icon/suspend/16_3.png' },
                { left: 650, bottom: 290, width: 109, height: 78, suspendImg: '/public/html/images/fishing/icon/suspend/16_4.png' },
            ],
            text: 'providerName:ProviderName.VP',
            bgImg: '/public/html/images/fishing/vp-banner.png',
            routeName: 'vp-fishing',
            maintenance: false,
        },
        FS: {
            key: 'fs',
            provider: 'FS',
            suspend: [
                { left: 60, bottom: 300, width: 80, height: 77, suspendImg: '/public/html/images/fishing/icon/suspend/15_2.png' },
                { left: 190, bottom: 90, width: 70, height: 52, suspendImg: '/public/html/images/fishing/icon/suspend/15_3.png' },
                { left: 650, bottom: 290, width: 109, height: 78, suspendImg: '/public/html/images/fishing/icon/suspend/15_4.png' },
            ],
            text: 'providerName:ProviderName.FS',
            bgImg: '/public/html/images/fishing/fs-banner.png',
            routeName: 'fs-fishing',
            maintenance: false,
        },
        R88: {
            key: 'r88',
            provider: 'R88',
            suspend: [
                { left: 60, bottom: 300, width: 80, height: 77, suspendImg: '/public/html/images/fishing/icon/suspend/15_2.png' },
                { left: 190, bottom: 90, width: 70, height: 52, suspendImg: '/public/html/images/fishing/icon/suspend/15_3.png' },
                { left: 650, bottom: 290, width: 109, height: 78, suspendImg: '/public/html/images/fishing/icon/suspend/15_4.png' },
            ],
            text: 'providerName:ProviderName.R88',
            bgImg: '/public/html/images/fishing/r88-banner.png',
            routeName: 'r88-fishing',
            maintenance: false,
        }
    },

    init(vm) {
        this.vm = vm;
        this.vm.state = {
            list: [],
            currentProvider: '',
            currentProviderKey: '',
            providerCodeList: [],
            isInit: true,
            selectedIndex: null,
            brandActive: 0,
        };
    },

    getProviderGameList(provider) {
        const { language } = controller.vm.props;
        const { keyword, tween1, tween2 } = controller.vm.state;
        let domain = '',
            gameList = [],
            currencyLang = language.currencyLang;

        if (window.location.hostname === 'localhost') {
            // local use
            domain = window.tempDomain;
        } else {
            domain = window.location.hostname;
        }

        if (language.country === 'KH') currencyLang = 'KHUSD';

        let param = {
            category: 'FISHING',
            merchantCode: domain,
            currencyCode: currencyLang,
            isTemplate: false,
        };

        param.provider = provider;
        window.SPL_Provider.getMerchantGames(param, success, fail);

        function success(data) {
            for (let i = 0; i < data.length; i++) {
                data[i].imggame = `/public/html/games/images/s1/${provider}/${data[i].game}.jpg`;
                data[i].text = data[i].name;
                data[i].gameCode = data[i].game;
                data[i].category = data[i].category;

                //massage gamelist name
                if (data[i].i18n && language.key !== 'zh-CN') {
                    let gameName = data[i].name;
                    try {
                        gameName = JSON.parse(data[i].i18n);
                        if (gameName[language.key.toUpperCase()]) {
                            data[i].name = gameName[language.key.toUpperCase()];
                        }
                    } catch (e) {}
                } else {
                    if (language.key === 'zh-CN') {
                        data[i].name = data[i].nameCN;
                    }
                }

                if (keyword) {
                    if (data[i].name.toLowerCase().includes(keyword.toLowerCase())) {
                        gameList.push(data[i]);
                    }
                } else {
                    gameList.push(data[i]);
                }
            }

            controller.arrangeSeq(gameList);
        }

        let brandList = controller.vm.state.brandList;
        for (let j = 0; j < brandList.length; j++) {
            if (provider === brandList[j].provider) {
                gameList.underMaintenance = brandList[j].underMaintenance;
            }
        }

        function fail(data) {
            gameList = null;
            controller.vm.setState({ gameList: gameList });
        }
    },

    arrangeSeq(gameList) {
        const { currentProvider, tween1, tween2 } = controller.vm.state;

        window.SPL_Content.getGamesArrangementList(currentProvider).then((json) => {
            if (json) {
                if (json[currentProvider] && json[currentProvider]['desktop']['Arrangement']) {
                    let gamesArrangement = json[currentProvider]['desktop']['Arrangement'];

                    gameList.forEach(function (e) {
                        e.seq += 100;
                        for (var i = 0; i < gamesArrangement.length; i++) {
                            if (e.game == gamesArrangement[i].game) {
                                e.seq = parseInt(gamesArrangement[i].seq);
                            }
                        }
                    });

                    gameList.sort(function (a, b) {
                        return a.seq - b.seq;
                    });
                }
            }

            controller.getMemberFavoriteGame(gameList, tween1, tween2);
        });
    },

    checkUnderMaintenance(providerObj, underMaintenance, i) {
        if (!underMaintenance) {
            controller.updateSelectedProvider(providerObj, underMaintenance);
            controller.toggleTab(i, 'brand');
        }
    },

    updateSelectedProvider(providerObj) {
        controller.vm.setState({ currentProvider: providerObj.provider, currentProviderKey: providerObj.key, gameList: [] }, () => {
            const { tween1, tween2 } = controller.vm.state;
            controller.getFishingProviderList(tween1, tween2, providerObj);
        });
    },

    toggleTab(index, type = 'type') {
        if (type === 'type' && index === controller.vm.state.typeActive) return;
        if (type === 'type') {
            controller.vm.setState({ typeActive: index });
        } else {
            controller.vm.setState({ brandActive: index });
        }
    },

    getFishingProviderList(tween1, tween2, providerObj, key = '', isMobile = false) {
        const { language, screen, t } = controller.vm.props;
        let view = screen.viewType;
        let currencyLang = language.currencyLang;

        if (language.country === 'KH') currencyLang = 'KHUSD';

        if (view === 'mobile') {
            isMobile = true;
        }

        controller.vm.setState({
            gameList: [],
        });

        return new Promise(function (resolve) {
            let loadGetSubmenu = new Promise((resolve) => {
                window.SPL_Content.getSubmenu(language.countryLanguageKey, view, 'F').then((data) => resolve(data));
            });

            let loadProviderGameMaintenanceList = new Promise((resolve) => {
                window.SPL_Provider.providerGameMaintenanceList(null, null, currencyLang).then((result) => resolve(result));
            });

            Promise.all([loadGetSubmenu, loadProviderGameMaintenanceList]).then((loadResult) => {
                let submenu = loadResult[0];
                let providerMaintenance = loadResult[1];

                let count = 0;
                let list = [];
                let fishingData = []; // reset value first
                let providerCodeList = [];
                fishingData.length = 0;
                let brandList = [];
                let currentProvider = providerObj.provider ? providerObj.provider : providerObj ? providerObj : controller.vm.state.currentProvider;
                let currentProviderKey = providerObj.key ? providerObj.key : providerObj ? providerObj.toLowerCase() : controller.vm.state.currentProviderKey;
                let pathname = window.location.pathname;

                for (let i = 0; i < submenu.length; i++) {
                    let provider = submenu[i].provider;
                    const isNewProvider = submenu[i].isNewProvider;
                    const isHotProvider = submenu[i].isHotProvider;

                    if (providerMaintenance) {
                        for (let j = 0; j < providerMaintenance.length; j++) {
                            let maintenanceProvider = providerMaintenance[j].provider;
                            if (provider === maintenanceProvider) {
                                submenu[i].gameMaintenance = providerMaintenance[j].gameMaintenance;
                            }
                        }
                    }

                    if (submenu[i].name === 'SG FishingWar') {
                        provider = 'SGFW';
                    } else if (submenu[i].name === 'SG Alien Hunter') {
                        provider = 'SGAH';
                    } else if (submenu[i].name === 'SG ZombieParty') {
                        provider = 'SGZP';
                    } else if (submenu[i].name === 'Booming Fish JILI') {
                        provider = 'BFJL';
                    } else if (submenu[i].name === 'Boom Legend JILI') {
                        provider = 'BLJL';
                    } else if (submenu[i].name === 'Dinosaur Tycoon JILI') {
                        provider = 'DTJL';
                    } else if (submenu[i].name === 'Dragon Fortune JILI') {
                        provider = 'DFJL';
                    } else if (submenu[i].name === 'Happy Fishing JILI') {
                        provider = 'HFJL';
                    } else if (submenu[i].name === 'Jackpot Fishing JILI') {
                        provider = 'JFJL';
                    } else if (submenu[i].name === 'Mega Fishing JILI') {
                        provider = 'MFJL';
                    } else if (submenu[i].name === 'Royal Fishing JILi') {
                        provider = 'RFJL';
                    }

                    let foundData = controller.providerData[provider];
                    let disableProvider = submenu[i].isDisableProvider;

                    if (foundData) {
                        foundData.isNewProvider = isNewProvider;
                        foundData.isHotProvider = isHotProvider;
                    }

                    if (providerMaintenance) {
                        for (let j = 0; j < providerMaintenance.length; j++) {
                            let maintenanceProvider = providerMaintenance[j].provider;
                            if (provider === maintenanceProvider) {
                                submenu[i].gameMaintenance = providerMaintenance[j].gameMaintenance;
                            }
                        }
                    }

                    if (foundData) {
                        Object.assign(foundData, { isDisableProvider: disableProvider });

                        // desktop handling
                        if (!isMobile) {
                            foundData.maintenance = submenu[i].gameMaintenance;
                            let bannerElement = generateFishingBannerElement(provider, foundData, count);
                            count = count + 1;

                            fishingData.push(foundData);
                            list.push(bannerElement);
                        } else {
                            // mobile handling
                            foundData.maintenance = submenu[i].gameMaintenance;
                            list.push(foundData);
                        }

                        const isWebConditionProvider = !currentProvider && !currentProviderKey && i === 0 && !isMobile;
                        const isMobileConditionProvider = isMobile && pathname.includes(foundData.routeName);

                        if (isWebConditionProvider || isMobileConditionProvider) {
                            currentProvider = foundData.provider;
                            currentProviderKey = foundData.key;
                        }

                        brandList.push(foundData);
                        providerCodeList.push(provider);
                        controller.vm.setState(
                            {
                                list: list,
                                providerCodeList: providerCodeList,
                                brandList: brandList,
                                tween1: tween1,
                                tween2: tween2,
                                currentProvider: currentProvider,
                                currentProviderKey: currentProviderKey,
                            },
                            () => {
                                if (currentProvider) {
                                    controller.getProviderGameList(currentProvider);
                                }
                            }
                        );
                        resolve(fishingData);
                    }
                }
            });
        });

        function generateFishingBannerElement(provider, data, index) {
            return {
                content: (
                    <div>
                        <LazyLoadImage src={data.bgImg} alt='' />
                        {data.blockImg && (
                            <LazyLoadImage
                                key='item-block'
                                style={{ position: 'absolute', left: 0, top: 0, zIndex: 1, pointerEvents: 'none' }}
                                className='item-block'
                                src={data.blockImg}
                            />
                        )}
                        {data.girlImg && (
                            <LazyLoadImage
                                key='item-girl'
                                style={{ position: 'absolute', left: 0, top: 0, zIndex: 1, pointerEvents: 'none' }}
                                className='item-girl'
                                src={data.girlImg}
                            />
                        )}
                        <div className='container'>
                            <div className={provider}>
                                {data.suspend.map((item, j) => (
                                    <div id={`suspend_${index}_${j}`} className={['suspend'].join(' ')} key={`${index + 1}_${j + 2}`}>
                                        <LazyLoadImage src={item.suspendImg} alt='' />
                                    </div>
                                ))}
                            </div>
                            <div className='content'>
                                <span className='icon-box'>
                                    <i className={'icon-' + data.key}></i>
                                    {/* <img className={'provider-icon icon-' + data.key} src={data.icon} /> */}
                                </span>
                                <span className='desc' dangerouslySetInnerHTML={{ __html: t(data.text, { interpolation: { escapeValue: false } }) }}></span>
                                <span
                                    className={`btn ${data.maintenance ? 'maintenance-disable' : ''}`}
                                    onClick={() => {
                                        controller.launchGame(provider, data.isDisableProvider, data.maintenance);
                                    }}
                                >
                                    {t('casino:casino.playNow', 'PLAY NOW')}
                                </span>
                            </div>
                        </div>
                    </div>
                ),

                tab: (
                    <div className={`${data.isDisableProvider ? 'provider-disable' : ''}`}>
                        <i className={['icon-small-' + data.key, 'gray'].join(' ')}></i>
                        <i className={['icon-small-' + data.key, 'on'].join(' ')}></i>
                        {/* <img className={['icon-small-' + data.key, 'gray'].join(' ')} src={data.icon} />
            <img className={['icon-small-' + data.key, 'on'].join(' ')} src={data.icon} /> */}
                    </div>
                ),
                providerMaintenance: data.maintenance ? <Fragment>{data.maintenance && <div className='maintenance-hover-box'></div>}</Fragment> : null,
            };
        }
    },

    launchGame(provider, disableProvider, maintenance) {
        if (!maintenance) {
            if (disableProvider) {
                controller.vm.props.history.push('/redirect-error');
            } else {
                const { language, screen, user } = controller.vm.props;
                let extraParam = null;
                let gameObj = {
                    provider: provider,
                    category: 'F',
                };

                providerService.launchGame(gameObj, language, user.isLogin, screen, false, extraParam, controller.vm);
            }
        }
    },

    selectProvider(index) {
        const { providerCodeList, isInit } = controller.vm.state;
        // to prevent 1st time loading to execute 2 times
        // control with isInit flag
        if (isInit) {
            controller.vm.setState({ isInit: false });
        } else {
            let providerCode = providerCodeList[index];
            let foundProvider = controller.providerData[providerCode];
            switch (foundProvider.routeName) {
                default:
                    controller.vm.props.history.push('/fishing/' + foundProvider.routeName);
                    break;
            }
        }
    },

    selectProviderBasedOnUrl() {
        const { providerCodeList } = controller.vm.state;
        let switchProvider = true;
        if (window.location.pathname.indexOf('/fishing') >= 0 && window.location.pathname.indexOf('/fishing/') <= -1) {
            switchProvider = false;
        }

        // check current provider based on url,
        // define selectedIndex to makesure Slider component
        // will auto select the provider when 1st time init
        if (switchProvider && providerCodeList.length > 0) {
            for (let i = 0; i < providerCodeList.length; i++) {
                let providerCode = providerCodeList[i];
                let routeName = controller.providerData[providerCode].routeName;

                if (window.location.pathname.indexOf(routeName) >= 0) {
                    controller.vm.setState({ selectedIndex: i });
                    return i;
                }
            }
            controller.vm.setState({ selectedIndex: 0 });
            return null;
        } else {
            controller.vm.setState({ selectedIndex: 0 });
            return null;
        }
    },

    handleKeywordChanged(e) {
        controller.vm.setState({ keyword: e.target.value }, () => {
            const { tween1, tween2, currentProvider, currentProviderKey } = controller.vm.state;
            controller.getFishingProviderList(tween1, tween2, currentProvider, currentProviderKey);
        });
    },

    getMemberFavoriteGame(gameList, tween1, tween2) {
        const _favoriteGames = controller.vm.props.providerReducer?.processedData?.favoriteGames || [];
        const newGameList = pu_processGameDataWithFavGames(gameList, _favoriteGames);
        controller.vm.setState({ gameList: newGameList, loading: false }, () => {
            controller.doSuspendAni(tween1, tween2);
        });
    },

    updateMemberFavoriteGame(game) {
        controller.vm.setState({ loading: true }, () => {
            const { screen } = controller.vm.props;
            const { isLogin } = controller.vm.props.user;
            const { gameList, favoritedGame, tween1, tween2 } = controller.vm.state;
            let isMobile = null;
            if (screen.viewType === 'web') {
                isMobile = false;
            } else {
                isMobile = true;
            }

            if (isLogin) {
                let delelteGame = false;

                for (let i = 0; i < favoritedGame.length; i++) {
                    if (favoritedGame[i].id === game.id) {
                        delelteGame = true;
                    }
                }

                if (delelteGame === true) {
                    window.SPL_Member.deleteMemberFavoriteGame(game, isMobile).then((data) => {
                        controller.getMemberFavoriteGame(gameList, tween1, tween2);
                    });
                } else {
                    window.SPL_Member.updateMemberFavoriteGame(game, isMobile).then((data) => {
                        controller.getMemberFavoriteGame(gameList, tween1, tween2);
                    });
                }
            } else {
                if (isMobile) {
                    controller.vm.props.dispatch(portalAction.floatingPageUpdated('login'));
                } else {
                    notification.showNotification('error', controller.vm.props.t('home:home.loginText'));
                }

                controller.vm.setState({ loading: false });
            }
        });
    },

    doSuspendAni(tween1, tween2) {
        tween1 && tween1.kill();
        tween2 && tween2.kill();

        TweenMax.set('ul.game-content li', {
            alpha: 0,
        });

        tween1 = TweenMax.delayedCall(0.3, function () {
            tween2 = TweenMax.staggerTo('ul.game-content li', 0.3, { alpha: 1 }, 0.08);
        });
    },
};

export default controller;
