import React, { FC } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import FourDPanel from './FourDPanel';

class FourD extends React.Component {
    render() {
        return <FourDPanel></FourDPanel>;
    }
}

export default connect(mapStateToProps)(withTranslation('fourD')(withRouter(FourD)));
