import moment from 'moment';
import { userAction } from '../../../../../redux/action/user.action';

const controller = {
    vm: null,

    init(vm) {
        this.vm = vm;

        const defaultDob = moment(Date.now()).subtract(18, 'years').toDate();
        const defaultMonth = defaultDob.getMonth() + 1;
        const defaultYear = defaultDob.getFullYear();
        const defaultDateLength = new Date(defaultYear, defaultMonth, 0).getDate();
        this.vm.state = {
            fields: {
                date: new Date().getDate(),
                month: defaultMonth,
                dob: defaultDob,
            },
            optionList: {
                date: Array.from({ length: defaultDateLength }, (_, i) => i + 1),
                month: Array.from({ length: 12 }, (_, i) => i + 1),
            },
            defaultDateLength,
            maxDob: defaultDob,
        };
    },

    handleFieldChange(type, e) {
        const vm = controller.vm;
        const { fields, optionList, defaultDateLength } = vm.state;

        if (type === 'dob') fields[type] = e;
        else fields[type] = e.target.value;

        let dateLength = defaultDateLength;
        if (type === 'month' || type === 'dob') {
            dateLength = new Date(fields['dob'].getFullYear(), fields['month'], 0).getDate();

            if (defaultDateLength !== dateLength) {
                if (fields['date'] > dateLength) fields['date'] = 1;
                optionList.date = Array.from({ length: dateLength }, (_, i) => i + 1);
            }
        }

        vm.setState({ fields, optionList, defaultDateLength: dateLength });
    },

    submitEditDOB(onSucceedCallback) {
        const vm = controller.vm;
        const { fields } = vm.state;
        let { dob, month, date } = fields;

        const { user, dispatch } = vm.props;
        const account = user.account;

        vm.setState({ isSubmitting: true });

        if (date < 10) date = '0' + date;
        if (month < 10) month = '0' + month;

        let year;
        if (dob) year = dob.getFullYear();
        const finalDob = year + '-' + month + '-' + date;
        const param = {
            id: account.id,
            dob: finalDob,
        };

        window.SPL_Member.updateMemberDetails(param).then((res) => {
            if (res.status === 'S') {
                account.dob = finalDob;
                dispatch(userAction.updateCertainUser(account));

                onSucceedCallback && onSucceedCallback();
            }
            vm.setState({ isSubmitting: false });
        });
    },
};

export default controller;
