import React from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { CSSTransition } from 'react-transition-group';
import { createPortal } from 'react-dom';
import providerService from 'root/utils/providerService';
import { Fragment } from 'react';
import Slider from 'react-slick';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useGetHotGameSlider, useGetMemberFavoriteGames } from '@hooks/provider-hook';
import { providerAction } from '@redux/action';

class RecommendGames extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // list: props.list,
            mobileActive: 0,
            isShowMobileBox: false,
            mobileSelectedGameObj: null,
            list: [],
        };
    }
    async componentDidMount() {}

    openMobileHoverBox(i, gameObj) {
        if (!document.querySelector('#fixed-container')) return; //非mobile版
        document.body.style.overflow = 'hidden';
        //document.querySelector('#root').style.filter = "blur(1px)";
        //document.querySelector('#fixed-container .tool-bar').style.filter = 'blur(1px)';

        this.setState((state) => ({ ...state, mobileActive: i, isShowMobileBox: true, mobileSelectedGameObj: gameObj }));
    }

    closeMobileHoverBox() {
        if (!document.querySelector('#fixed-container')) return; //非mobile版
        document.body.style.overflow = '';
        //document.querySelector('#root').style.filter = "";
        document.querySelector('#fixed-container .tool-bar').style.filter = '';
        this.setState((state) => ({ ...state, isShowMobileBox: false, mobileSelectedGameObj: null }));
    }

    onGameClick(game, isFun) {
        const { history, screen } = this.props;
        const { isShowMobileBox } = this.state;
        let isWeb = screen.viewType === 'web';

        // NOTE: when closing modal have transition, user might clicked play now or demo during close.
        if (isWeb === false && !isShowMobileBox) {
            return;
        }

        if (game && game.underMaintenance) {
            history.push({
                pathname: '/maintenance',
                search: '?provider=' + game.providerMaintenanceName + '&startTime=' + game.underMaintenance.startTime + '&endTime=' + game.underMaintenance.endTime + '',
            });

            document.body.style.overflow = '';
            if (this.props.screen.viewType === 'mobile') {
                document.querySelector('#fixed-container .tool-bar').style.filter = '';
            }
        } else {
            if (this.props.handleCustomClick) {
                this.props.handleCustomClick(game, isFun);
            } else {
                const { language, screen, user } = this.props;
                let gameObj = {
                    provider: game.provider,
                    gameCode: game.gameCode,
                    category: game.category,
                };

                providerService.launchGameFromHomePage(gameObj, language, user.isLogin, screen, isFun, this);
            }
        }
    }

    render() {
        const { screen, language, providerReducer } = this.props;
        let isWeb = screen.viewType === 'web';
        const list = providerReducer?.processedData?.hotGameSliders || [];

        const setting = {
            autoplay: true,
            autoplaySpeed: 4000,
            infinite: true,
            slidesToShow: 5,
            slidesToScroll: 1,
            variableWidth: true,
            swipeToSlide: true,
            arrows: false,
            dots: false,
        };
        return (
            <Translation>
                {(t) => (
                    <>
                        <div className='recommend-games-container'>
                            <RecommendGameInit />
                            <div className='game-content'>
                                <Slider {...setting}>
                                    {(list || []).map((gameObj, i) => {
                                        return (
                                            <div
                                                key={i}
                                                className='force-hover game'
                                                onClick={() => {
                                                    this.openMobileHoverBox(i, gameObj);
                                                }}
                                            >
                                                <LazyLoadImage className='recommend-icon' src={`/public/html/images/slots/recommend/${gameObj.provider}.jpg`} alt='' />
                                                <div
                                                    className={['img-box', 'icon-game-common'].join(' ')}
                                                    style={{ backgroundImage: gameObj.customImage || `url("${gameObj.thumbnail}")` || `url("${gameObj.imggame}")` }}
                                                >
                                                    <div className='hover-box'>
                                                        {!list?.underMaintenance && (
                                                            <Fragment>
                                                                <span
                                                                    style={{ textTransform: 'uppercase' }}
                                                                    onClick={() => {
                                                                        this.onGameClick(gameObj, false);
                                                                    }}
                                                                >
                                                                    {t('slot:slot.playNow', 'Play Now').toLowerCase()}
                                                                </span>
                                                                {gameObj.playForFun && (
                                                                    <span
                                                                        style={{ textTransform: 'uppercase' }}
                                                                        onClick={() => {
                                                                            this.onGameClick(gameObj, true);
                                                                        }}
                                                                    >
                                                                        {t('slot:slot.demo', 'Demo').toLowerCase()}
                                                                    </span>
                                                                )}
                                                            </Fragment>
                                                        )}
                                                    </div>
                                                </div>

                                                <p>
                                                    <span>{language.key === 'zh-CN' ? gameObj.nameCN : gameObj.name}</span>
                                                    <i
                                                        className={[gameObj.isFav ? 'icon-love-on' : 'icon-love-off'].join(' ')}
                                                        onClick={() => {
                                                            // isWeb && this.props.updateMemberFavoriteGame(gameObj);
                                                            const notificationMsg = t('home:home.loginText');
                                                            isWeb && this.props.dispatch(providerAction.updateMemberFavoriteGames(gameObj, notificationMsg));
                                                        }}
                                                    ></i>
                                                </p>
                                            </div>
                                        );
                                    })}
                                </Slider>
                            </div>

                            {document.querySelector('#fixed-container') &&
                                createPortal(
                                    <CSSTransition classNames='alert' in={this.state.isShowMobileBox} timeout={500} unmountOnExit>
                                        <div className='mobile-hover-box-wrap'>
                                            <div>
                                                <div
                                                    className={['img-box', 'icon-game-common'].join(' ')}
                                                    style={{
                                                        backgroundImage: this.state.mobileSelectedGameObj
                                                            ? this.state.mobileSelectedGameObj.customImage
                                                                ? `url("${this.state.mobileSelectedGameObj.thumbnail}")`
                                                                : `url("${this.state.mobileSelectedGameObj.imggame}")`
                                                            : '',
                                                    }}
                                                >
                                                    <div className='hover-box'>
                                                        <span
                                                            style={{ textTransform: 'uppercase' }}
                                                            onClick={() => {
                                                                this.onGameClick(this.state.mobileSelectedGameObj, false);
                                                            }}
                                                        >
                                                            {t('slot:slot.playNow', 'Play Now').toLowerCase()}
                                                        </span>
                                                        {this.state.mobileSelectedGameObj && this.state.mobileSelectedGameObj.playForFun && (
                                                            <span
                                                                style={{ textTransform: 'uppercase' }}
                                                                onClick={() => {
                                                                    this.onGameClick(this.state.mobileSelectedGameObj, true);
                                                                }}
                                                            >
                                                                {t('slot:slot.demo', 'Demo').toLowerCase()}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>

                                                <p>
                                                    <span className='rctvybunim'>
                                                        {list[this.state.mobileActive] &&
                                                            (list[this.state.mobileActive].name
                                                                ? list[this.state.mobileActive].name
                                                                : list[this.state.mobileActive].text
                                                                ? list[this.state.mobileActive].text
                                                                : list[this.state.mobileActive].content)}
                                                    </span>
                                                    <i
                                                        className={[
                                                            list[this.state.mobileActive] && list[this.state.mobileActive].isFav ? 'icon-love-on' : 'icon-love-off',
                                                        ].join(' ')}
                                                        onClick={() => {
                                                            this.setState({ isShowMobileBox: !list[this.state.mobileActive]?.isFav });
                                                            this.closeMobileHoverBox();
                                                            // this.props.updateMemberFavoriteGame(list?.[this.state.mobileActive]);
                                                            const notificationMsg = t('home:home.loginText');
                                                            this.props.dispatch(providerAction.updateMemberFavoriteGames(list?.[this.state.mobileActive], notificationMsg));
                                                        }}
                                                    ></i>
                                                </p>

                                                <i
                                                    className='icon-close'
                                                    onClick={() => {
                                                        this.closeMobileHoverBox();
                                                    }}
                                                ></i>
                                            </div>
                                        </div>
                                    </CSSTransition>,

                                    document.querySelector('#fixed-container')
                                )}
                        </div>
                    </>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['slot', 'global'])(RecommendGames));

export const RecommendGameInit = () => {
    useGetHotGameSlider();
    useGetMemberFavoriteGames();
    return <></>;
};
