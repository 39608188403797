import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import { Translation, withTranslation } from 'react-i18next';

import vm from '../special-promo-dialog.controller';

class SpecialPromoDialog extends Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        return (
            <Translation>
                {(t) => (
                    <Modal
                        isOpen={this.props.specialPromoDialogIsShow}
                        shouldCloseOnOverlayClick={true}
                        shouldCloseOnEsc={true}
                        onRequestClose={() => this.props.closeSpecialPromoDialog(this.props.parentvm)}
                        overlayClassName='custom-modal-overlay'
                        className='custom-modal m-special-promo-modal'
                    >
                        <div className='custom-modal-header'>{this.props.specialPromoDetail.title}</div>
                        <div className='custom-modal-body'>
                            <table className='promo-info'>
                                <tr>
                                    <td>{t(`transaction:transaction.transfer.promoDetail.${this.props.specialPromoDetail.targetType}`)}</td>
                                    <td>{this.props.specialPromoDetail.targetMultiplier}</td>
                                </tr>
                                {Object.keys(this.props.specialPromoDetail.dynamic).map((key, value) => {
                                    return (
                                        <tr key={key}>
                                            <td>{t(`transaction:transaction.transfer.promoDetail.${key}`)}</td>
                                            <td>{value}</td>
                                        </tr>
                                    );
                                })}
                            </table>
                        </div>
                    </Modal>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation('transaction')(SpecialPromoDialog));
