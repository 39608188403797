import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';

import Banner from './Banner';
import Sports from './Sports';
import PromoteSectionBanner from './PromoteSectionBanner';
import SidePopup from './SidePopup';
import DroppingHot from './DroppingHot';
import Donotmiss from './Donotmiss';
import Ad from './Ad';
import AppDownload from './AppDownload';
import Notice from 'root/webapp/shared/component/Notice';
import AnnouncementPopup from 'root/webapp/shared/announcement-popup/announcement-popup.selector';
import { CSSTransition } from 'react-transition-group';
import vm from '../home.controller';
import commonUtil from 'root/utils/common-util';
import TopWinner from './TopWinner';

class Home extends React.Component {
    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        let thiscomponent = this;
        commonUtil.loadCustomTranslation('home', this, function () {
            vm.getHomePageBanner();
            vm.getSportsScrollingBanner('web');
            vm.getHomePageOddsFeed();
            vm.getContact();
            vm.getJackpotValue();
            vm.onHomeMounted();
            vm.getVideoSliderData();
            vm.getAppInstructionData();
            vm.getTopWinnerList();

            if (thiscomponent.props.location.pathname === '/home') {
                setTimeout(() => {
                    vm.getAnnouncement('desktop');
                }, 500);
            }
        });

        // vm.getHotGames();
    }

    componentWillUnmount() {
        if (this.state.jackpotTimer) {
            clearInterval(this.state.jackpotTimer);
            this.setState({ jackpotTimer: null });
        }
        this.setState = () => {
            return;
        };
    }

    render() {
        let announcementPopupSource = {
            announcementList: this.state.announcementPopupList,
            popupVersion: this.state.announcementPopupVersion,
            closePopupAnnouncement: vm.closePopupAnnouncement,
        };

        let adsProps = {
            videoUrl: this.state.videoUrl,
            videoSlideObjList: this.state.videoSlideObjList,
            nameweeSelectorOnClick: vm.nameweeSelectorOnClick,
            activeImage: this.state.activeImage,
        };

        let appInstruction = {
            appInstructionList: this.state.appInstructionList,
        };

        return (
            <Fragment>
                {/* home banner */}
                <Banner items={this.state.homePageBanner} />
                {/* Announcement Popup */}
                <CSSTransition classNames='alert' unmountOnExit timeout={300} in={this.state.announcementPopupIsShow}>
                    <AnnouncementPopup {...announcementPopupSource} />
                </CSSTransition>
                {/* scrolling announcement */}
                <Notice items={this.state.announcementsDisplay} />
                {/* sports banner + odds feed + contact list */}
                <Sports
                    sportsScrollingBanner={this.state.sportsScrollingBanner}
                    oddsFeedArray={this.state.oddsFeedArray}
                    contactList={this.state.contactList}
                    contactOnClick={vm.contactOnClick}
                />
                {/* useless dota banner */}
                <Ad {...this.props} getSocialMediaUrl={vm.getSocialMediaUrl} {...adsProps} /> {/* some banner dont know need to dynamic or not */}
                {/*hongwei is here for top winner */}
                <TopWinner topWinnerList={this.state.topWinnerList} />
                {/* hot slot games */}
                <DroppingHot jackpotObj={this.state.jackpotObj} jackpotList={this.state.jackpotList} maxGameShow={10} />
                {/* 4 game category quick shortcut menu */}
                {/* <Donotmiss /> */}
                {/* livechat lai de */}
                {/* Hide it because Live Chat Script is inject by third party */}
                {/* <SidePopup />  */}
                <PromoteSectionBanner {...this.props} affiliateOnClick={vm.affiliateOnClick} />
                <AppDownload {...appInstruction} />
            </Fragment>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['home', 'casino'])(withRouter(Home)));
