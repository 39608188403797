export function psw_validatePasswordLengthValidity(password, minLength, maxLength) {
    if (password?.length >= minLength && password?.length <= maxLength) {
        return true;
    } else {
        return false;
    }
}

// this function usually is used when change/reset password, and input current password
export function psw_validateCurrentPassword(oldPassword, newPassword) {
    let errorKey = null;
    if (!oldPassword && oldPassword?.length <= 0) {
        // errMsg.push('global:global.messages.validate.newpassword.required');
        errorKey = 'password_required';
    } else if (newPassword && newPassword === oldPassword) {
        // errMsg.push('global:global.messages.validate.newpassword.samePassword');
        errorKey = 'password_same';
    }
    return errorKey;
}

export function psw_validateConfirmPassword(password, confirmPassword) {
    let errorKey = null;
    if (confirmPassword?.length <= 0) {
        errorKey = 'password_required';
    } else {
        if (password !== confirmPassword) {
            errorKey = 'confirm_password_not_match';
        }
    }
    return errorKey;
}
