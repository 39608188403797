import { generalConstants } from '../constant';
export const generalAction = {
    setDataPage,
};

function setDataPage(designs) {
    return (dispatch) => {
        dispatch({ type: generalConstants.SET_DATA_PAGE, payload: designs });
    };
}
