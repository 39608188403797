// react/library
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';

// components/files

// controller/logic
import vm from '../../mobile-card-deposit.controller';
import PaymentGatewayIcon from '../../../../../../../../components/molecules/payment-icon/payment-icon';

class MobileCardDeposit extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
    }

    componentDidMount() {
        if (this.state.depositAllSettings !== null) {
            vm.initCompSettings().then(() => {
                vm.loadDepositDetails();
            });
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    componentDidUpdate() {}

    render() {
        const { methodList, selectedMethodIndex, specialNoticeContent } = this.state;

        return (
            <Translation>
                {(t) => (
                    <Fragment>
                        <div className='group-item bank-options'>
                            <div className='title'>
                                <span>{t('transaction:transaction.deposit.cashdeposit.channel', 'Deposit Channel')}</span>
                                <span className='required'>*</span>
                            </div>

                            <div className='bank-list bank-selector'>
                                <ul className='bank-list'>
                                    {methodList.map((method, i) => (
                                        <li
                                            key={i}
                                            className='small-bank bank-acc-list'
                                            onClick={() => {
                                                vm.onMethodChanged(i);
                                            }}
                                        >
                                            <div className='member-bankacc'>
                                                <label>
                                                    <input
                                                        type='radio'
                                                        name='bank-account'
                                                        value='0'
                                                        checked={selectedMethodIndex === i}
                                                        onChange={() => {
                                                            vm.onMethodChanged(i);
                                                        }}
                                                    />
                                                    <span className='checkmark'></span>
                                                    <div className='banklist-group'>
                                                        <div className='group-box bankgroup-box-container'>
                                                            <div className={`bank-img ${method.code.toLowerCase() === `ezpqr` ? 'wider-bank-img' : ''}`}>
                                                                <div
                                                                    className={[
                                                                        'icon-bank',
                                                                        method.code.toLowerCase() === `ezpqr` ? 'icon-bank-wider' : 'icon-bank-big',
                                                                        selectedMethodIndex === i ? 'icon-bank-on' : 'icon-bank-off',
                                                                    ].join(' ')}
                                                                >
                                                                    <div className={`icon-${method.code}`}>
                                                                        <PaymentGatewayIcon pgCode={method.code} />
                                                                    </div>
                                                                </div>
                                                                <div className='bank-active'></div>
                                                            </div>

                                                            <div className='bank-name'>{method.displayName}</div>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        {specialNoticeContent && (
                            <div className='notice-box special-notice-box'>
                                {(specialNoticeContent || []).map((content, i) => (
                                    <>
                                        <span class='item-title'>{content.head}</span>
                                        <div class='display-info'>
                                            <p>{content.desc}</p>
                                        </div>
                                    </>
                                ))}
                            </div>
                        )}

                        <div className='group-item submit-container'>
                            <div className='title'></div>
                            <div className='amount-content'>
                                <div className='flexbox'>
                                    <button
                                        className='btn btn-submit'
                                        onClick={() => {
                                            vm.submitForm();
                                        }}
                                    >
                                        {t('transaction:transaction.deposit.deposithistory.submitbtn', 'SUBMIT').toUpperCase()}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'settings'])(withRouter(MobileCardDeposit)));
