// library
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// logic
import { useGetAuthSettings } from '@hooks/affiliate-hook';
import { popupAction } from '@redux/action/popup.action';
import { POPUP_TYPE } from '@utils/constants';
import { useGetLiveChatUrl, useGetCurrentTranslation } from '@hooks/general-hook';
import notification from '@utils/notification';

/**
 * This component was just created to access redux from hook as class based component can't use hook. Can assume, component created to get settings.
 * @param {String} module - constant value from MODULE_TYPE
 * @param {Boolean} isPublic
 * @returns
 */
const AuthSettings = ({ module, isPublic, isNew }) => {
    useGetAuthSettings(module, isPublic, isNew);

    return <></>;
};

export default AuthSettings;

// hook created when onClick button required show auth-settings popup (e.g. 6d pin/GA)
// callback is original popup
export const useAuthSettingsSetup = ({ module, callback, submitSuccessCb }) => {
    const dispatch = useDispatch();

    // redux
    const authSettingsReducer = useSelector((state) => state.authSettingsReducer);
    const language = useSelector((state) => state.language);

    // hooks
    const { openLiveChat } = useGetLiveChatUrl();
    const { translationData } = useGetCurrentTranslation('transaction');

    const handleSetup = () => {
        const _masterSwitchAuthSettingsModuleData = authSettingsReducer?.processedData?.[module];
        if (!_masterSwitchAuthSettingsModuleData) {
            // no authSettings mean no required setup
            callback && callback();
            return;
        }

        const { gaEnabled, gaRegistered, sixPinEnabled, memberSixPinInfo } = _masterSwitchAuthSettingsModuleData;

        // if want mix with old API, can consider do mapping
        if (gaEnabled && !gaRegistered) {
            // set GA popup
            dispatch(popupAction.setPopup(POPUP_TYPE.GA, true));
            dispatch(popupAction.setSubmitSuccessCb(submitSuccessCb));
        } else if (sixPinEnabled && !memberSixPinInfo?.sixPinRegistered) {
            dispatch(popupAction.setPopup(POPUP_TYPE.SIX_PIN_SETUP, true));
            submitSuccessCb && dispatch(popupAction.setSubmitSuccessCb(submitSuccessCb));
        } else if (sixPinEnabled && memberSixPinInfo?.sixPinLocked) {
            openLiveChat && openLiveChat();
            handleShowErrorPopup();
        } else {
            // normal popup
            callback && callback();
        }
    };

    const handleShowErrorPopup = () => {
        notification.showNotification(
            'error',
            translationData?.sixDigitPin?.pinErrorReachedLimit?.[language?.key] || 'You have keyed in the PIN incorrectly for 3 times, kindly contact Customer Service.'
        );
    };

    return { handleSetup };
};
