import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../reducer/reducer';

const loadState = () => {
    try {
        const key = localStorage.getItem('language');
        const countryLanguageKey = localStorage.getItem('LANGUAGE_CURRENCY');
        const country = localStorage.getItem('country');
        const currencyLang = localStorage.getItem('currencyLang');
        if (key && countryLanguageKey) {
            const serializedState = {
                language: {
                    key,
                    countryLanguageKey,
                    country,
                    currencyLang,
                },
            };

            return { ...serializedState };
        }
        return {};
    } catch (error) {
        return undefined;
    }
};

const saveState = (state) => {
    try {
        localStorage.setItem('language', state.language.key);
        localStorage.setItem('LANGUAGE_CURRENCY', state.language.countryLanguageKey);
        localStorage.setItem('country', state.language.country);
        localStorage.setItem('currencyLang', state.language.currencyLang);
    } catch (error) {
        console.log('save state', error);
    }
};
const store = createStore(rootReducer, loadState(), applyMiddleware(thunkMiddleware));
store.subscribe(() => {
    saveState(store.getState());
});
export default store;
