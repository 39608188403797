import React, { Fragment, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Trans, Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { TweenMax } from 'gsap';
import { CSSTransition } from 'react-transition-group';
import QRCode from 'qrcode.react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SVGElements from 'root/components/SVGElements';
import vm from '../download.controller';
import commonUtil from '../../../../utils/common-util';

const AutoReduceFontSize = ({ text }) => {
    const spanRef = useRef(null);

    useEffect(() => {
        const adjustFontSize = () => {
            const spanElement = spanRef.current;
            const textWidth = spanElement.offsetWidth;
            const parentElement = spanElement.parentNode;
            const spanWidth = spanElement.parentElement.offsetWidth;
            const previousSibling = parentElement.previousSibling;

            if (textWidth > spanWidth) {
                const currentFontSize = parseInt(window.getComputedStyle(spanElement).fontSize);
                const newFontSize = currentFontSize * (spanWidth / textWidth);
                spanElement.style.fontSize = `${newFontSize}px`;
                if (previousSibling) {
                    previousSibling.style.fontSize = `${newFontSize}px`;
                }
            }
        };

        adjustFontSize();

        window.addEventListener('resize', adjustFontSize);

        return () => {
            window.removeEventListener('resize', adjustFontSize);
        };
    }, [text]);

    return <span ref={spanRef}>{text}</span>;
};

class DownloadPanel extends React.Component {
    aniList = [];
    constructor() {
        super();
        vm.init(this);
    }

    componentWillMount() {
        this.doSuspendAni();
    }

    componentDidMount() {
        vm.initCompSettings();
        vm.getHashToShowActiveTab();
        vm.getAppDownloadLink();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location && this.props.location && prevProps.location.hash !== this.props.location.hash) {
            vm.getHashToShowActiveTab();
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    doSuspendAni() {
        this.aniList.forEach((item) => item.kill());
        this.aniList.length = 0;
        const randomItem = (list) => {
            return list[0 + Math.round(Math.random() * (list.length - 1 - 0))];
        };
        const idList = ['#suspend_1', '#suspend_2'];
        const endStyleStore = [];

        const startPosition = {
            0: {
                pad: {
                    left: '5.6rem',
                    top: '5rem',
                },
                web: {
                    left: '3.3rem',
                    top: '3.7rem',
                },
            },
            1: {
                pad: {
                    left: '3.5rem',
                    top: '7.2rem',
                },
                web: {
                    left: '1.8rem',
                    top: '5rem',
                },
            },
        };

        TweenMax.delayedCall(0.05, () => {
            idList.map((id, i) => {
                const $item = document.querySelector(id);
                endStyleStore[i] = {
                    left: getComputedStyle($item).left,
                    top: getComputedStyle($item).top,
                };

                TweenMax.set(id, {
                    x: -parseFloat(getComputedStyle($item).width) / 2,
                    y: 0,
                    ...startPosition[String(i)][window.innerWidth >= 1025 ? 'web' : 'pad'],
                    opacity: 0,
                    rotation: [0, 3].includes(i) ? 0 : randomItem([-80, -40, 40, 80, 100]),
                    onComplete: () => {
                        //console.log(getComputedStyle($item).top)
                        TweenMax.set(id, {
                            left: getComputedStyle($item).left,
                            top: getComputedStyle($item).top,
                        });

                        this.aniList.push(
                            TweenMax.to(id, 0.8, {
                                x: 0,
                                y: 0,
                                left: endStyleStore[i].left,
                                top: endStyleStore[i].top,
                                opacity: 1,
                                rotation: 0,
                                delay: 0.2 * i + 0.5,
                                onComplete: () => {
                                    TweenMax.set(id, {
                                        left: '',
                                        top: '',
                                    });

                                    if (i == idList.length - 1) {
                                        idList.map((id) => {
                                            this.aniList.push(
                                                TweenMax.to(id, randomItem([5, 6, 7]), {
                                                    x: `${randomItem(['+', '-'])}=${randomItem([5, 15, 25])}`,
                                                    y: `${randomItem(['+', '-'])}=${randomItem([5, 15, 25])}`,
                                                    rotation: randomItem([15, 10, -10, -5]),
                                                    yoyo: true,
                                                    repeat: -1,
                                                })
                                            );
                                        });
                                    }
                                },
                            })
                        );
                    },
                });
            });
        });
    }

    renderTab(t, tab) {
        const { active } = this.state;
        return (
            <li
                className={['icon-hover-box', active === tab ? 'on' : ''].join(' ')}
                onClick={() => {
                    vm.handleTabClick(tab);
                }}
            >
                <i className={[`icon-${tab}`, active === tab ? 'on' : ''].join(' ')}></i>
                <span>{t(`app:app.appDownload.${tab}`)}</span>
            </li>
        );
    }

    renderBottomContent(t) {
        const { list, listActive, active, compSettings, appDownloadLinkData } = this.state;
        const isMobile = this.props.screen.viewType === 'mobile';
        const { customMaintenanceDisable } = compSettings;

        if (active !== 'app') {
            return (
                <ul className='download-items'>
                    {list.map((downloadObj, i) => (
                        <li key={i} className='download-item'>
                            <div className='img'>
                                <LazyLoadImage src={downloadObj.img} data-screen='web|pad' alt='' />
                                <LazyLoadImage src={downloadObj.mimg} data-screen='mobile' alt='' />
                            </div>
                            {customMaintenanceDisable && customMaintenanceDisable[active] && customMaintenanceDisable[active].includes(downloadObj.provider) && (
                                <div class='maintenance-box'>
                                    <SVGElements className='maintenance' name='maintenance' />
                                </div>
                            )}
                            <div className='other'>
                                <div className={`tools ${compSettings.customMaintenanceDisable[active].includes(downloadObj.provider) && 'maintenance-tools'}`}>
                                    <span
                                        onClick={() => {
                                            vm.downloadApp(downloadObj);
                                        }}
                                    >
                                        {t('home:home.mobile.download', 'download')}
                                    </span>
                                    <span className='force-hover' onClick={() => vm.getQrCode(i, downloadObj)}>
                                        <AutoReduceFontSize text={t('home:home.mobile.scanQrCode', 'Scan QR code')} />
                                        <div className='qr-box-container'>
                                            {
                                                <CSSTransition classNames='slider-y-bottom-in' in={listActive === i} timeout={500} unmountOnExit>
                                                    <div className='qr-box'>
                                                        <QRCode
                                                            value={vm.qrcode(downloadObj)}
                                                            className='download_qr'
                                                            level='M'
                                                            renderAs='svg'
                                                            size='85'
                                                            bgColor='black'
                                                            fgColor='white'
                                                        />
                                                    </div>
                                                </CSSTransition>
                                            }
                                        </div>
                                    </span>
                                </div>
                                <span>{downloadObj.desc}</span>
                            </div>
                        </li>
                    ))}
                </ul>
            );
        } else {
            const renderQRCode = (deviceType) => {
                const { appDownloadLinkData } = this.state;
                return (
                    <div className='download-container'>
                        <QRCode
                            value={commonUtil.getQRCodeValue(appDownloadLinkData && appDownloadLinkData[deviceType])}
                            level='M'
                            renderAs='svg'
                            size='121'
                            bgColor='#efefef'
                            fgColor='black'
                        />
                        <button
                            onClick={() => {
                                commonUtil.onAppDownloadClick(deviceType, appDownloadLinkData && appDownloadLinkData[deviceType]);
                            }}
                        >
                            {t(`app:app.download`)}
                        </button>
                        <div className={`corner-img-container`}>
                            <img src={`/public/html/images/download/${deviceType}-icon.svg`} alt=''></img>
                        </div>
                    </div>
                );
            };

            const renderMobileWithoutQR = (deviceType) => {
                const { appDownloadLinkData } = this.state;
                return (
                    <div className='download-container'>
                        <img src={`/public/html/images/download/${deviceType}-icon.svg`} alt=''></img>
                        <button
                            onClick={() => {
                                commonUtil.onAppDownloadClick(deviceType, appDownloadLinkData && appDownloadLinkData[deviceType]);
                            }}
                        >
                            {t(`app:app.download`)}
                        </button>
                    </div>
                );
            };

            const renderAndroidInstallGuide = () => {
                return (
                    <div className='install-steps'>
                        <img src={`/public/html/images/download/android-icon-rounded.svg`} alt=''></img>
                        <div className='steps-container'>
                            <div className='title'>{<Trans i18nKey='app:app.appDownload.installSteps.android.title'></Trans>}</div>
                            <div className='steps'>{t(`app:app.appDownload.installSteps.android.desc1`)}</div>
                            <div className='steps'>{t(`app:app.appDownload.installSteps.android.desc2`)}</div>
                            <div className='steps'>{t(`app:app.appDownload.installSteps.android.desc3`)}</div>
                        </div>
                    </div>
                );
            };

            const renderIOSInstallGuide = () => {
                return (
                    <div className='install-steps'>
                        <img src={`/public/html/images/download/ios-icon-rounded.svg`} alt=''></img>
                        <div className='steps-container'>
                            <div className='title'>{<Trans i18nKey='app:app.appDownload.installSteps.ios.title'></Trans>}</div>
                            <div className='steps'>{t(`app:app.appDownload.installSteps.ios.desc1`)}</div>
                            <div className='steps'>{t(`app:app.appDownload.installSteps.ios.desc2`)}</div>
                            <div className='steps'>{t(`app:app.appDownload.installSteps.ios.desc3`)}</div>
                            <div className='steps'>{t(`app:app.appDownload.installSteps.ios.desc4`)}</div>
                            <div className='steps'>{t(`app:app.appDownload.installSteps.ios.specialDesc`)}</div>
                        </div>
                    </div>
                );
            };

            return (
                <div className='app-content'>
                    <div className='top-container'>
                        {!isMobile ? (
                            <Fragment>
                                <div className='left-container'>
                                    <div className='title'>{t(`app:app.appDownload.title`)}</div>
                                    <img src='/public/html/images/download/me88-apps-icon.png' alt=''></img>
                                    <div className='desc'>{t(`app:app.appDownload.desc`)}</div>
                                </div>
                                <div className='right-container'>
                                    {appDownloadLinkData && appDownloadLinkData.android && renderQRCode('android')}
                                    {appDownloadLinkData && appDownloadLinkData.ios && renderQRCode('ios')}
                                </div>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <div className='top-content'>
                                    <img src='/public/html/images/download/me88-apps-icon.png' alt=''></img>
                                    <div className='info-container'>
                                        <div className='title'>{t(`app:app.appDownload.title`)}</div>
                                        <div className='desc'>{t(`app:app.appDownload.desc`)}</div>
                                    </div>
                                </div>
                                <div className='bottom-content'>
                                    {appDownloadLinkData && appDownloadLinkData.android && renderMobileWithoutQR('android')}
                                    {appDownloadLinkData && appDownloadLinkData.ios && renderMobileWithoutQR('ios')}
                                </div>
                            </Fragment>
                        )}
                    </div>
                    <div className='divider'></div>
                    <div className='bottom-container'>
                        {appDownloadLinkData && appDownloadLinkData.android && renderAndroidInstallGuide()}
                        {appDownloadLinkData && appDownloadLinkData.ios && renderIOSInstallGuide()}
                    </div>
                </div>
            );
        }
    }

    render() {
        return (
            <Translation>
                {(t) => (
                    <div className='download-panel'>
                        {this.state.qrCode && (
                            <div className='qr-box'>
                                <div className='qr-popup'>
                                    <i className='icon-close3' onClick={() => vm.getQrCode()}></i>
                                    <QRCode value={vm.qrcode(this.state.qrObj)} className='download_qr' level='M' renderAs='svg' size='85' bgColor='black' fgColor='white' />
                                </div>
                            </div>
                        )}

                        <div className='container'>
                            <div data-screen='web|pad' className='suspend' id='suspend_1'>
                                <LazyLoadImage src='/public/html/images/download/suspend/ball.png' alt='' />
                            </div>
                            <div data-screen='web|pad' className='suspend' id='suspend_2'>
                                <LazyLoadImage src='/public/html/images/download/suspend/robot.png' alt='' />
                            </div>

                            <div className='download-list'>
                                <ul className='download-nav'>
                                    {!window.SPL_LiteApp.isZT828LiteApp() && this.renderTab(t, 'app')}
                                    {!window.SPL_LiteApp.isZT828LiteApp() && <div className='divider'></div>}

                                    {this.renderTab(t, 'android')}
                                    <div className='divider'></div>
                                    {this.renderTab(t, 'ios')}
                                </ul>
                                {this.renderBottomContent(t)}
                            </div>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['global', 'home', 'app'])(withRouter(DownloadPanel)));
