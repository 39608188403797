import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import core from 'root/utils/core';

import MobileCardDeposit1 from './component/desktop/mobile-card-deposit';

import MobileMobileCardDeposit1 from './component/mobile/m-mobile-card-deposit';

class MobileCardDeposit extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
    }

    components = {
        desktop: {
            default: MobileCardDeposit1,
        },
        mobile: {
            default: MobileMobileCardDeposit1,
        },
    };

    render() {
        const TagName = core.getCorrectComponent(this.components, 'mobile-card-deposit', this.props.screen.viewType);
        return <TagName {...this.props} />;
    }
}

export default connect(mapStateToProps, null)(MobileCardDeposit);
