import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { TweenMax } from 'gsap';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import LeftSubMenu from 'root/webapp/shared/component/wap/LeftSubMenu';
import HomeMemberProfile from 'root/webapp/shared/component/wap/HomeMemberProfile';
import vm from '../fishing.controller';

class Fishing extends React.Component {
    tween1;
    tween2;

    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        vm.getFishingProviderList(this.tween1, this.tween2, '').then((data) => {
            this.toggleAni();
        });
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    toggleAni() {
        this.tween1 && this.tween1.kill();
        this.tween2 && this.tween2.kill();

        TweenMax.set('.brand-page ul.ani-ul > li', {
            alpha: 0,
        });

        this.tween1 = TweenMax.delayedCall(0.3, function () {
            this.tween2 = TweenMax.staggerTo('.brand-page ul.ani-ul > li', 0.3, { alpha: 1 }, 0.08);
        });
    }

    onClick(provider) {
        const { history } = this.props;

        history.push({
            pathname: '/fishing/' + provider.routeName,
            query: { provider: provider.provider },
        });
    }

    checkMaintenance(fishing) {
        if (fishing) {
            if (!fishing.underMaintenance) {
                this.onClick(fishing);
                // vm.updateSelectedProvider(fishing);
            }
        }
    }

    render() {
        return (
            <Translation>
                {(t) => (
                    <Fragment>
                        <div className='fishing-page'>
                            <HomeMemberProfile />
                            <div className='fishing-box-container'>
                                <div className='brand-page'>
                                    <LeftSubMenu />
                                    <div className='fishing-list-box'>
                                        <span className='item-title'>{t('fishing:fishing.mobile.title', 'YOUR FAVORITE FISHING GAMES ARE HERE')}</span>
                                        <ul className='ani-ul'>
                                            {this.state.brandList &&
                                                this.state.brandList.map((fishingObj, i) => (
                                                    <li
                                                        key={fishingObj.key}
                                                        onClick={() => {
                                                            this.checkMaintenance(fishingObj);
                                                        }}
                                                        className={`${fishingObj.isDisableProvider ? 'provider-disable' : ''}`}
                                                    >
                                                        <LazyLoadImage
                                                            src={'/public/html/images/fishing/mobile/' + fishingObj.key + '.png'}
                                                            alt=''
                                                            className={fishingObj.maintenance ? 'maintenance-cover' : ''}
                                                        />
                                                        {/* <p className={fishingObj.maintenance ? 'maintenance-cover' : ''}>{t(fishingObj.mobileText)}</p> */}
                                                        {fishingObj.isHotProvider && <div className='hoticon-fishing-container'></div>}
                                                        {fishingObj.isNewProvider && <div className='newicon-fishing-container'></div>}
                                                        {fishingObj.maintenance && <div className='maintenance-hover-box'></div>}
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation('fishing')(withRouter(Fishing)));
