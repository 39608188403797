import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import core from 'root/utils/core';
import DesktopHome from './desktop/Home';
import MobileHome from './mobile/index';

class Home extends React.Component {
    components = {
        desktop: {
            default: DesktopHome,
        },
        mobile: {
            default: MobileHome,
        },
    };

    render() {
        const TagName = core.getCorrectComponent(this.components, 'home', this.props.screen.viewType);
        return <TagName></TagName>;
    }
}

export default connect(mapStateToProps, null)(Home);
