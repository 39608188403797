import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import SmallLoading from 'root/webapp/shared/loading/smallLoading';
import SVGTransfer from 'root/components/svgIcon/svgTransfer';

class TransferSelect extends React.Component {
    constructor() {
        super();
        this.state = {
            itemIndex: 0,
            typeIndex: 0,
            providerBalance: null,
            providerIndexName: '',
            showItem: false,
        };
    }

    toggleBtn(key, isShow) {
        this.setState((state) => {
            return { ...state, [key]: isShow ?? !state[key] };
        });
    }

    handleEvent(e) {
        switch (e.type) {
            case 'click':
                this.clickListOut(e);
                break;

            default:
                break;
        }
    }

    clickListOut(e) {
        if (this.state.showItem) {
            const stopEventEl = [this.refs.currentSelectRef, this.refs.itemRef];
            for (let index = 0; index < stopEventEl.length; index++) {
                let child = stopEventEl[index];
                if (child && child.contains(e.target)) {
                    return;
                }
            }

            this.setState((state) => ({ ...state, showItem: false }));
        }
    }

    initEvent(off) {
        window[off ? 'removeEventListener' : 'addEventListener']('click', this);
    }

    componentDidUpdate(prevProps) {
        const { parentState, providerIndex, providerCategorySelect, type } = this.props;
        const { providerList } = parentState;
        // select type index
        if (providerIndex !== prevProps.providerIndex || providerList !== prevProps.parentState.providerList) {
            providerCategorySelect(providerIndex, type);
        }
    }

    currentSelect(providerIndex, providerDetails, category) {
        const { parentState } = this.props;
        const { categorizedProviderListByMoreType } = parentState;
        let providerIndexName = '';

        if (category === 'provider' && categorizedProviderListByMoreType[providerIndex]) {
            providerIndexName = categorizedProviderListByMoreType[providerIndex].title;
        } else {
            providerIndexName = providerDetails.display;
        }

        this.setState({ providerIndexName: providerIndexName });
    }

    toggleShowItem(isShow) {
        this.setState((state) => {
            return { ...state, showItem: isShow ?? !state.showItem };
        });
    }

    componentWillMount() {
        this.initEvent();
    }

    componentWillUnmount() {
        this.initEvent(true);
    }

    onChange = (item, index) => {
        const { onChange } = this.props;
        setTimeout(() => {
            onChange(item);
        }, 1);
        this.setState((state) => ({
            ...state,
            itemIndex: index,
            showItem: false,
        }));
    };

    showProviderBalanceMobile(providerItemList, category) {
        let providerBalance = 0.0;

        if (providerItemList) {
            if (category === 'provider') {
                for (let i = 0; i < providerItemList.length; i++) {
                    providerBalance += providerItemList[i].balance;
                }

                providerBalance = window.SPL_Other.formatAmount(providerBalance);
            } else {
                providerBalance = window.SPL_Other.formatAmount(providerItemList.balance);
            }
        }
        this.setState({ providerBalance: providerBalance });
    }

    render() {
        const { parentState, onCategorySelect, type, isInvalid, updateProviderItemIndex, updateProviderIndex, t } = this.props;

        const { categorizedProviderListByMoreType, transferSelectIndexFrom, transferSelectItemIndexFrom, transferSelectIndexTo, transferSelectItemIndexTo } = parentState;

        let { typeIndex, typeItemIndex, providerBalance, providerIndexName } = this.state;

        // for showing first provider sum balance
        if (!providerBalance) {
            if (categorizedProviderListByMoreType[typeIndex] && categorizedProviderListByMoreType[typeIndex].list) {
                for (let i = 0; i < categorizedProviderListByMoreType[typeIndex].list.length; i++) {
                    providerBalance += categorizedProviderListByMoreType[typeIndex].list[i].balance;
                }
            }
            providerBalance = window.SPL_Other.formatAmount(providerBalance);
        }

        //for showing first provider name
        if (!providerIndexName) {
            if (categorizedProviderListByMoreType[typeIndex]) {
                providerIndexName = categorizedProviderListByMoreType[typeIndex].title;
            }
        }

        typeItemIndex = transferSelectItemIndexFrom;

        if (type === 'from') {
            typeItemIndex = transferSelectItemIndexFrom;
            if (transferSelectIndexFrom > -1) {
                typeIndex = transferSelectIndexFrom;
            }
        } else {
            typeItemIndex = transferSelectItemIndexTo;
            if (transferSelectIndexTo > -1) {
                typeIndex = transferSelectIndexTo;
            }
        }

        return (
            <div className='transfer-select'>
                <div className='type-list'>
                    <ul>
                        {categorizedProviderListByMoreType.length ? (
                            categorizedProviderListByMoreType.map(({ name, title, key, list }, i) =>
                                list.length ? (
                                    <li
                                        key={name}
                                        className={[i === typeIndex ? 'on' : ''].join(' ')}
                                        onClick={() => {
                                            onCategorySelect(i, type);
                                            updateProviderIndex(i, type);
                                            this.currentSelect(i, list, 'provider');
                                            this.showProviderBalanceMobile(list, 'provider');
                                        }}
                                    >
                                        <span className='icon-box'>
                                            <SVGTransfer name={key} className={`${key}-svg`} color='#ff4d00' />
                                        </span>

                                        <span>{t(title)}</span>
                                    </li>
                                ) : (
                                    <></>
                                )
                            )
                        ) : (
                            <SmallLoading></SmallLoading>
                        )}
                    </ul>
                </div>

                <div
                    ref={'currentSelectRef'}
                    className={['current-select on', isInvalid ? 'danger' : ''].join(' ')}
                    onClick={() => {
                        this.toggleShowItem();
                    }}
                >
                    <span>{providerIndexName === '' ? <SmallLoading></SmallLoading> : t(providerIndexName)}</span>

                    <CSSTransition classNames='spread-bottom' in={this.state.showItem} timeout={300} unmountOnExit>
                        <ul
                            className='item-list-box'
                            ref={'itemRef'}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            {categorizedProviderListByMoreType.length ? (
                                categorizedProviderListByMoreType[typeIndex].list.map((item, index) => (
                                    <li
                                        key={item.display}
                                        className={[index === typeItemIndex ? 'on' : ''].join(' ')}
                                        onClick={() => {
                                            this.onChange(item, index);
                                            this.currentSelect(index, item, 'providerList');
                                            this.showProviderBalanceMobile(item, 'providerList');
                                            updateProviderItemIndex(index, type);
                                        }}
                                    >
                                        <span>{item.display === 's-Sports' ? 'SBOBet' : item.display}</span>
                                    </li>
                                ))
                            ) : (
                                <SmallLoading></SmallLoading>
                            )}
                        </ul>
                    </CSSTransition>
                    <div className='group'>
                        <span>{providerBalance}</span>
                        <span className='icon-box'>
                            <CSSTransition classNames='rotate180' in={this.state.showItem} timeout={300}>
                                <i className='icon-arrow2'></i>
                            </CSSTransition>
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, null)(withTranslation(['settings'])(withRouter(TransferSelect)));
