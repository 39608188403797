import { trans_handleBankStatementClearReceipt, trans_handleBankStatementReceiptImageChange } from 'root/utils/transaction-util';
import { gu_getSettingFromPortalSettings, gu_massageIndex } from '../../../../utils/general-util';
import transactionHelper from '../../../../utils/transaction-helper';
import { popupAction } from '@redux/action/popup.action';
import { MODULE_TYPE as MT, POPUP_TYPE } from '@constants';

const controller = {
    vm: null,

    init(vm) {
        controller.vm = vm;
        controller.vm.state = {
            // bankList: controller.vm.props.parentvm.state.bankList, // not using
            // memberBankList: controller.vm.props.parentvm.state.memberBankList, // not using
            // unOwnedBankList: controller.vm.props.parentvm.state.unOwnedBankList, // not using
            // showBankBranch: controller.vm.props.parentvm.state.depositAllSettings.showBankBranch, // not using

            showBankName: false,
            bankNameErrMsg: [],
            statusMsg: '',

            accountBankfields: {
                selectedBankIndex: 0,
                bankName: '',
                bankBranch: '',
                accountName: controller.vm.props.user.account.name,
                accountNumber: '',
            },
            errors: {},
            receiptImg: [],
            receiptImgFile: null,
            isInvalidUploadFileType: false,

            compSettings: {},
        };
    },

    initCompSettings() {
        return new Promise((resolve) => {
            controller.vm.setState({}, () => {
                const { user } = controller.vm.props;
                const { currency } = user.account;

                // Step 1: Standard way to get from portal settings
                let tempSettings = gu_getSettingFromPortalSettings({
                    vm: controller.vm,
                    settingName: 'withdrawalSettings',
                });

                // Step 2: Massage again to get the specific data you need (Skip if not needed)
                let reqBankStatementByCurrencies = false;
                if (tempSettings.reqBankStatementByCurrencies) {
                    reqBankStatementByCurrencies = tempSettings.reqBankStatementByCurrencies.indexOf(currency) !== -1;
                }

                // Step 3: Initial all into compSettings
                let compSettings = {
                    reqBankStatementByCurrencies,
                };

                controller.vm.setState({ compSettings }, () => {
                    resolve();
                });
            });
        });
    },

    isNumber(val) {
        return /^\d*$/.test(val);
    },

    handleBankChange(e) {
        let accountBankfields = controller.vm.state.accountBankfields;
        let showBankName = false;
        let val = gu_massageIndex(e);
        let bankObj = controller.vm.props.unOwnedBankList && controller.vm.props.unOwnedBankList[val] ? controller.vm.props.unOwnedBankList[val] : {};
        if (bankObj.bankId === 0 || bankObj.bankId === '0' || bankObj.bankId === 'o') {
            showBankName = true;
        }

        accountBankfields.selectedBankIndex = val;

        controller.vm.setState({ showBankName: showBankName, accountBankfields });
    },

    handleFieldChange(e, field) {
        transactionHelper.handleFieldChanged(controller.vm, e, field);
    },

    validateBankName() {
        transactionHelper.validateBankName(controller.vm);
    },

    isValidForm() {
        transactionHelper.isValidForm(controller.vm);
    },

    handleReceiptImageChange(e) {
        trans_handleBankStatementReceiptImageChange(controller.vm, e);
    },

    handleClearReceipt() {
        trans_handleBankStatementClearReceipt(controller.vm);
    },

    handleSubmit() {
        // used for check if required trigger six_pin
        const handlePreSubmit = () => {
            const { dispatch, authSettingsReducer, popupReducer } = controller.vm.props;
            const bankDetailBankAuthSettings = authSettingsReducer?.processedData?.[MT.BANK_DETAIL_BANK];
            const _config = {
                gaEnabled: bankDetailBankAuthSettings?.gaEnabled,
                sixPinEnabled: bankDetailBankAuthSettings?.sixPinEnabled,
            };

            if (bankDetailBankAuthSettings?.sixPinEnabled && !popupReducer?.enteredPin) {
                dispatch(popupAction.setItemConfig(POPUP_TYPE.PIN_ENTRY, _config));
                dispatch(popupAction.setPopup(POPUP_TYPE.PIN_ENTRY, true));
                dispatch(popupAction.setSubmitSuccessCb(handleSubmit.bind(controller.vm)));
            } else {
                handleSubmit();
            }
        };

        const handleSubmit = () => {
            transactionHelper.handdleAddAccountBankSubmit(controller.vm);
        };

        handlePreSubmit();
    },
};

export default controller;
