import React, { Fragment } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import providerService from 'root/utils/providerService';

const controller = {
    vm: null,
    providerData: {
        IME: {
            key: 'ime',
            provider: 'IME',
            text: 'sport:sport.ime.desc_1',
            mobileText: 'sport:sport.ime.mobile_desc_1',
            bgImg: '/public/html/images/e-sports/ime-banner.png',
        },
        TF: {
            key: 'tf',
            provider: 'TF',
            text: 'sport:sport.tf.desc_1',
            mobileText: 'sport:sport.tf.mobile_desc_1',
            bgImg: '/public/html/images/e-sports/tf-banner.png',
        },
    },

    init(vm) {
        this.vm = vm;
        this.vm.state = {
            list: [],
        };
    },

    getEsportsProviderList(isMobile = false) {
        const { language, t } = controller.vm.props;
        let contentType = 'web';
        let currencyLang = language.currencyLang;

        if (language.country === 'KH') currencyLang = 'KHUSD';

        if (isMobile) {
            contentType = 'mobile';
        }

        return new Promise(function (resolve) {
            let loadGetSubmenu = new Promise((resolve) => {
                window.SPL_Content.getSubmenu(language.countryLanguageKey, contentType, 'ES').then((data) => resolve(data));
            });

            let loadProviderGameMaintenanceList = new Promise((resolve) => {
                window.SPL_Provider.providerGameMaintenanceList(null, null, currencyLang).then((result) => resolve(result));
            });

            Promise.all([loadGetSubmenu, loadProviderGameMaintenanceList]).then((loadResult) => {
                let submenu = loadResult[0];
                let providerMaintenance = loadResult[1];

                let count = 0;
                let list = [];
                let eSportsData = []; // reset value first
                eSportsData.length = 0;

                for (let i = 0; i < submenu.length; i++) {
                    let provider = submenu[i].provider;
                    let foundData = controller.providerData[provider];
                    let disableProvider = submenu[i].isDisableProvider;
                    const isHotProvider = submenu[i].isHotProvider;
                    const isNewProvider = submenu[i].isNewProvider;

                    if (providerMaintenance) {
                        for (let j = 0; j < providerMaintenance.length; j++) {
                            let maintenanceProvider = providerMaintenance[j].provider;
                            if (provider === maintenanceProvider) {
                                submenu[i].gameMaintenance = providerMaintenance[j].gameMaintenance;
                            }
                        }
                    }

                    if (foundData) {
                        Object.assign(foundData, { isDisableProvider: disableProvider });

                        // desktop handling
                        if (!isMobile) {
                            foundData.maintenance = submenu[i].gameMaintenance;
                            let bannerElement = generateEsportsBannerElement(provider, foundData, count, isHotProvider, isNewProvider);
                            count = count + 1;
                            eSportsData.push(foundData);
                            list.push(bannerElement);
                        } else {
                            // mobile handling
                            foundData.isHotProvider = isHotProvider;
                            foundData.isNewProvider = isNewProvider;
                            foundData.maintenance = submenu[i].gameMaintenance;
                            list.push(foundData);
                        }
                        controller.vm.setState({ list: list });
                        resolve(eSportsData);
                    }
                }
            });
        });

        function generateEsportsBannerElement(provider, data, index, isHotProvider, isNewProvider) {
            return {
                content: (
                    <div>
                        <LazyLoadImage src={data.bgImg} alt='' className='banner-img' />
                        {data.blockImg && (
                            <LazyLoadImage
                                key='item-block'
                                style={{ position: 'absolute', left: 0, top: 0, zIndex: 1, pointerEvents: 'none' }}
                                className='item-block'
                                src={data.blockImg}
                            />
                        )}
                        {data.girlImg && (
                            <LazyLoadImage
                                key='item-girl'
                                style={{ position: 'absolute', left: 0, top: 0, zIndex: 1, pointerEvents: 'none' }}
                                className='item-girl'
                                src={data.girlImg}
                            />
                        )}
                        <div className={'container ' + provider}>
                            {data.suspend &&
                                data.suspend.map((item, j) => (
                                    <div id={`suspend_${index}_${j}`} className={['suspend'].join(' ')} key={`${index + 1}_${j + 2}`}>
                                        <LazyLoadImage src={item.suspendImg} alt='' />
                                    </div>
                                ))}
                            <div className='content'>
                                <span className='icon-box'>
                                    <i className={'icon-' + data.key}></i>
                                </span>
                                <span className='desc' dangerouslySetInnerHTML={{ __html: t(data.text, { interpolation: { escapeValue: false } }) }}></span>
                                <span
                                    className={`btn ${data.maintenance ? 'maintenance-disable' : ''}`}
                                    onClick={() => {
                                        controller.launchGame(provider, data.disableProvider, data.maintenance);
                                    }}
                                >
                                    {t('casino:casino.playNow', 'PLAY NOW')}
                                </span>
                            </div>
                        </div>
                    </div>
                ),

                tab: (
                    <div className={`${data.isDisableProvider ? 'provider-disable' : ''}`}>
                        <i className={['icon-small-' + data.key, 'gray'].join(' ')}></i>
                        <i className={['icon-small-' + data.key, 'on'].join(' ')}></i>
                        {isHotProvider && <div className='hot-provider-casino'></div>}
                        {isNewProvider && <div className='new-provider-casino'></div>}
                    </div>
                ),
                providerMaintenance: data.maintenance ? <Fragment>{data.maintenance && <div className='maintenance-hover-box'></div>}</Fragment> : null,
            };
        }
    },

    launchGame(provider, disableProvider, maintenance) {
        if (!maintenance) {
            if (disableProvider) {
                controller.vm.props.history.push('/redirect-error');
            } else {
                const { language, screen, user } = controller.vm.props;
                let extraParam = null;
                let gameObj = {
                    provider: provider,
                    category: 'ES',
                };

                providerService.launchGame(gameObj, language, user.isLogin, screen, false, extraParam, controller.vm);
            }
        }
    },
};

export default controller;
