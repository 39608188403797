import { walletAction } from 'root/redux/action/wallet.action';
import core from 'root/utils/core';
import notification from 'root/utils/notification';
import Utils from 'root/utils';
import { t } from 'i18next';
import APIClient from '../../../../utils/APIClient';
import commonUtil from '../../../../utils/common-util';
import { trans_massageHideAddBank, trans_handleBankStatementClearReceipt, trans_handleBankStatementReceiptImageChange } from 'root/utils/transaction-util';
import { AMOUNT_DEPOSIT_METHOD_CODE, MODULE_TYPE as MT, POPUP_TYPE, OTP_VERIFICATION_METHOD as OVM, OTP_VERIFICATION_TYPE as OVT } from '@constants';
import { portalAction, authSettingsAction } from '@redux/action';
import { popupAction } from '@redux/action/popup.action';

const controller = {
    vm: null,

    init(vm) {
        this.vm = vm;
        this.vm.state = {
            isOnlineTransfer: true,
            isCrypto: false,
            isEwallet: false,

            isLoading: false,
            iconLoading: false,
            depositSettings: null,
            limit: null,
            minmaxLimit: null,
            cryptoMinmaxLimit: null,
            selectedBankIndex: 0,
            displayBankList: [],
            accountName: '',
            showBankName: false,
            disableWithdrawButton: false,
            restoreOnLoad: false,
            mainWallet: null,
            receiptImg: [],

            fields: {
                bankName: '',
                bankBranch: '',
                accountNumber: '',
                amount: '',
            },
            errors: {},
            disabledFields: {
                bankName: false,
                bankBranch: false,
                accountNumber: false,
            },

            announcementPopupList: [],
            announcementPopupVersion: 1,
            announcementPopupIsShow: false,
            withdrawTime: null,
            showWithdrawMaintenceTime: false,

            minmaxLimitDisplay: {
                minAmountDisplay: null,
                maxAmountDisplay: null,
            },

            cryptoEnabled: false,
            cryptoChain: '',
            selectedCryptoIndex: 0,
            selectedChainIndex: 0,
            addressData: '',
            addressOption: [],
            networkOption: [],
            transactionDetails: '',
            cryptoExchangeRate: 0,
            chainName: '',
            showCurrencyAndCoin: false,
            cryptoWithdrawFee: '',
            guaranteedUntilTime: '',
            disabledWithdrawCryptoButton: true,
            getlatestRate: false,
            reload: false,
            memberAddressOption: [],
            addressModal: false,
            addAddressErrMsg: '',
            remindMinAmount: '',
            cryptoFeeCharge: '',
            currencyAmount: '',
            cryptoAmount: '',
            withdrawChainRemark: '',
            selectedCurrency: '',
            cryptoAddressDisable: true,
            otpCode: '',
            disableOtpBtn: true,
            submittedOTP: false,
            countdownTimer: 0,
            networkAndCoin: {},
            decimal: '',
            roundUp: '',
            ewalletEnabled: false,
            ewalletList: [],
            compSettings: {},
            ewalletDisplayBank: [],
            withdrawalTab: [
                { code: 'B', actualCode: 'B', translation: 'transaction:transaction.deposit.cashdeposit.banktransfer', id: 'Bank Transfer' },
                { code: 'CT', actualCode: 'CT', translation: 'transaction:transaction.deposit.cashdeposit.crypto', id: 'Crypto' },
                { code: 'R', actualCode: 'R', translation: 'transaction:transaction.deposit.cashdeposit.ewallet', id: 'Ewallet' },
            ],
            memberBankList: [],
            withdrawAmountOptions: {},
            memberDecimalSettings: {},
            isSubmitting: false,
            amountOptions: [],
        };
    },

    initCompSettings() {
        return new Promise((resolve) => {
            const { user } = controller.vm.props;
            const { currency } = user.account;
            // Step 1: Standard way to get from portal settings
            let tempSettings = commonUtil.getSettingFromPortalSettings({
                vm: controller.vm,
                settingName: 'withdrawalSettings',
            });

            // Step 2: Massage again to get the specific data you need (Skip if not needed)
            let reqBankStatementByCurrencies = false;
            if (tempSettings.reqBankStatementByCurrencies) {
                reqBankStatementByCurrencies = tempSettings.reqBankStatementByCurrencies.indexOf(currency) !== -1;
            }

            // Step 3: Initial all into compSettings
            let compSettings = {
                reqBankStatementByCurrencies,
                seperateTngToEwallet: tempSettings && tempSettings.seperateTngToEwallet,
            };

            controller.vm.setState({ compSettings }, () => {
                window.SPL_Content.getBankNameJson(controller.vm.props);
                resolve();
            });
        });
    },

    initWithdrawTab() {
        controller.vm.setState({
            accountName: controller.vm.props.user.account.name,
            fields: {
                bankName: '',
                bankBranch: '',
                accountNumber: '',
                amount: '',
                currencyAmount: '',
                cryptoAmount: '',
            },
            receiptImg: [],
        });

        let currency = controller.vm.props.user.account.currency;
        window.SPL_Transaction.loadDepositRelatedSettings(currency).then((depositSettings) => {
            const { withdrawalTab } = controller.vm.state;
            if (depositSettings) {
                // init hideAddBankAccount by 1st tab code
                let optionCode = withdrawalTab && withdrawalTab[0] && withdrawalTab[0].code;
                depositSettings.hideAddBankAccount = trans_massageHideAddBank(controller.vm, 'withdraw', optionCode);
            }
            controller.vm.setState({ depositSettings: depositSettings }, () => {
                controller.loadBankAccounts();
            });
        });
    },

    onWithdrawAmountClicked(item) {
        let fields = controller.vm.state.fields;
        fields['amount'] = item;
        controller.vm.setState({ fields }, () => {
            controller.handleValidation();
        });
    },

    loadBankAccounts() {
        const { t } = controller.vm.props;
        const { depositSettings, ewalletList, ewalletEnabled } = controller.vm.state;

        window.SPL_Transaction.loadAllOwnedAndUnOwnedBank().then(async (data) => {
            let otherBank = {
                bankId: null,
                bankName: t('transaction:transaction.withdrawal.otherBanks', 'Other Bank'),
                bankNameOnly: t('transaction:transaction.withdrawal.otherBanks', 'Other Bank'),
                shortBankNameOnly: t('transaction:transaction.withdrawal.otherBanks', 'Other Bank'),
                bankBranch: '',
                memberBankId: null,
                accountNumber: '',
                bankCode: 'other',
            };

            let displayBank = [];
            let ewalletDisplayBank = [];

            if (depositSettings.hideAddBankAccount) {
                let otherBankList = data.memberBankList.filter((a) => a.bankId === null);

                displayBank = data.bankList.filter((a) => a.memberBankId !== null);

                if (otherBankList.length > 0) {
                    otherBankList[0].bankId = null;
                    otherBankList[0].bankNameOnly = otherBankList[0].bankName;
                    otherBankList[0].shortBankNameOnly = otherBankList[0].bankName;
                    displayBank.push(otherBankList[0]);
                }
            } else {
                displayBank = data.bankList;
                if (!depositSettings.disableOtherBanks) {
                    let otherBankList = data.memberBankList.filter((a) => a.bankId === null);
                    if (otherBankList.length > 0) {
                        otherBankList[0].bankId = null;
                        otherBankList[0].bankNameOnly = otherBankList[0].bankName;
                        otherBankList[0].shortBankNameOnly = otherBankList[0].bankName;
                        displayBank.push(otherBankList[0]);
                    } else {
                        displayBank.push(otherBank);
                    }
                }
            }

            // for (const bank of displayBank) {
            //     bank.svgData = await window.SPL_Other.renderTemplateSVG(`${commonUtil.getDepositImgPath('bank-icon')}${bank.code}.svg`, `${bank.code}`);
            // }

            // load bank tab
            window.SPL_Content.getBankName(controller.vm.state.withdrawalTab, 'option');

            //filter tng in bank transfer
            if (ewalletEnabled && ewalletList && ewalletList.length > 0) {
                for (let i = 0; i < ewalletList.length; i++) {
                    ewalletDisplayBank.push(displayBank.find((data) => data.code === ewalletList[i].code));
                    displayBank = displayBank.filter((data) => data.code !== ewalletList[i].code);
                }
            }

            controller.vm.setState(
                {
                    displayBankList: displayBank,
                    ewalletDisplayBank: ewalletDisplayBank,
                    memberBankList: data.memberBankList,
                },
                () => {
                    if (!Utils.force2Mobile()) {
                        controller.handleBankChanged(0)();
                    }

                    // TODO: double check current behaviour, suppose everytime handle bank change should call this too.
                    // controller.getRemainingAvailableWithdrawDetails(displayBank[0], displayBank[0].bankType);
                    controller.preGetRemainingAvailableWithdrawDetails();
                }
            );
        });
    },

    preGetRemainingAvailableWithdrawDetails() {
        const { isOnlineTransfer, isCrypto, isEwallet } = controller.vm.state;
        if (isOnlineTransfer) {
            const { displayBankList, selectedBankIndex } = controller.vm.state;
            controller.getRemainingAvailableWithdrawDetails(displayBankList[selectedBankIndex], 'B');
            controller.getSelectedMethodWithdrawAmountOptions('B');
        } else if (isCrypto) {
            controller.getRemainingAvailableWithdrawDetails('', 'CT');
            controller.getSelectedMethodWithdrawAmountOptions('CT');
        } else if (isEwallet) {
            const { ewalletDisplayBank, selectedBankIndex } = controller.vm.state;
            controller.getRemainingAvailableWithdrawDetails(ewalletDisplayBank[selectedBankIndex], 'R');
            controller.getSelectedMethodWithdrawAmountOptions('R');
        }
    },

    getRemainingAvailableWithdrawDetails(selectedBank, bankType) {
        let { minmaxLimitDisplay, cryptoMinmaxLimit } = controller.vm.state;
        const { t } = controller.vm.props;
        window.SPL_Transaction.getRemainingAvailableWithdrawDetails(selectedBank, bankType).then((limit) => {
            for (const key in limit) {
                if (typeof limit[key] === 'number') {
                    limit[key] = window.SPL_Other.formatAmount(limit[key]);
                } else {
                    limit[key] = t(limit[key]);
                }

                if (key === 'maxCount' && limit[key]) {
                    let maxCountStr = limit[key];
                    let maxCountVal = Math.floor(Number(maxCountStr));
                    limit[key] = maxCountVal.toString();
                }
            }

            if (bankType === 'CT') {
                cryptoMinmaxLimit = limit;
                cryptoMinmaxLimit.minLimit = cryptoMinmaxLimit.minLimit.replace(',', '');
                cryptoMinmaxLimit.dailyMaxAmount = cryptoMinmaxLimit.dailyMaxAmount.replace(',', '');
                controller.vm.setState({ cryptoMinmaxLimit: cryptoMinmaxLimit }, () => {
                    controller.handleValidation();
                });
            } else {
                minmaxLimitDisplay['minAmountDisplay'] = limit.minLimitDisplay;
                minmaxLimitDisplay['maxAmountDisplay'] = limit.maxAmountDisplay;
                controller.vm.setState({ limit: limit, minmaxLimitDisplay, minmaxLimit: limit }, () => {
                    controller.handleValidation();
                });
            }
        });
    },

    handleBankChanged(selectedBankIndex) {
        return (callback) => {
            controller.vm.setState({ selectedBankIndex: selectedBankIndex }, () => {
                this.handleClearReceipt();
                const { displayBankList, selectedBankIndex } = controller.vm.state;
                let fields = controller.vm.state.fields;
                let disabledFields = controller.vm.state.disabledFields;
                let showBankName = false;

                let bankObj = displayBankList[selectedBankIndex];

                // bank records that contains member bank information
                // populate the field and disable the input
                if (bankObj.memberBankId !== null) {
                    fields['bankName'] = bankObj.bankName;
                    fields['bankBranch'] = bankObj.bankBranch;
                    fields['accountNumber'] = bankObj.accountNumber;
                    disabledFields['bankName'] = true;
                    disabledFields['bankBranch'] = true;
                    disabledFields['accountNumber'] = true;
                } else {
                    fields['bankName'] = '';
                    fields['bankBranch'] = '';
                    fields['accountNumber'] = '';
                    disabledFields['bankName'] = false;
                    disabledFields['bankBranch'] = false;
                    disabledFields['accountNumber'] = false;
                }

                if (bankObj.bankId === null) {
                    showBankName = true;
                }

                controller.vm.setState({ fields, disabledFields, showBankName: showBankName }, () => {
                    callback && callback();
                });
            });
        };
    },

    handleFieldChanged(e, field) {
        let fields = controller.vm.state.fields;
        fields[field] = e.target.value;

        controller.vm.setState({ fields }, () => {
            controller.handleValidation();
        });
    },

    handleValidation() {
        const { showBankName, minmaxLimit, depositSettings } = controller.vm.state;
        let fields = controller.vm.state.fields;
        let errors = {};
        let isFormValid = true;

        for (const key in minmaxLimit) {
            minmaxLimit[key] = minmaxLimit[key].replace(/,/g, '');
        }

        if (showBankName && !fields['bankName']) {
            isFormValid = false;
            errors['bankName'] = 'bank:bank.newBank.bankNamerequired';
        } else {
            errors['bankName'] = '';
        }

        if (typeof fields['accountNumber'] !== undefined) {
            let accountNumberStr = fields['accountNumber'];
            if (!accountNumberStr || accountNumberStr.length <= 0) {
                isFormValid = false;
                errors['accountNumber'] = 'inputHelpBlock:inputHelpBlock.transfer.accountNumberRequired';
            } else {
                if (!accountNumberStr.match(/^[0-9]*$/)) {
                    isFormValid = false;
                    errors['accountNumber'] = 'transaction:transaction.withdrawal.accountNumber-error';
                } else {
                    errors['accountNumber'] = '';
                }
            }
        }

        if (typeof fields['amount'] !== undefined) {
            let amountStr = fields['amount'];
            if (!amountStr || amountStr === '0' || amountStr.length <= 0) {
                isFormValid = false;
                errors['amount'] = 'transaction:transaction.withdrawal.invalidAmount';
            } else {
                let amount = Number(amountStr);
                if (amount >= 0) {
                    // decimal checking
                    if (depositSettings.disableDecimalWithdraw && !amountStr.match(/^[0-9]*$/)) {
                        isFormValid = false;
                        errors['amount'] = 'transaction:transaction.withdrawal.disableDecimalError';
                    } else if (amount < minmaxLimit.minLimit) {
                        isFormValid = false;
                        errors['amount'] = 'transaction:transaction.withdrawal.invalidAmount';
                    } else if (amount > minmaxLimit.dailyMaxAmount) {
                        isFormValid = false;
                        errors['amount'] = 'transaction:transaction.withdrawal.invalidAmount';
                    } else {
                        errors['amount'] = '';
                    }
                } else {
                    isFormValid = false;
                    errors['amount'] = 'transaction:transaction.withdrawal.invalidAmount';
                }
            }
        }

        controller.vm.setState({
            fields,
            errors,
            isFormValid: isFormValid,
        });
    },

    submitForm() {
        const { isFormValid, fields } = controller.vm.state;
        const { t, authSettingsReducer, popupReducer, dispatch } = controller.vm.props;
        let _currentModule = null;
        if (controller.vm.state.isOnlineTransfer) {
            _currentModule = MT.WITHDRAWAL_BANK;
        } else if (controller.vm.state.isEwallet) {
            _currentModule = MT.WITHDRAWAL_EWALLET;
        }
        const _currentModuleAuthSettings = authSettingsReducer?.processedData?.[_currentModule];
        const _config = {
            gaEnabled: _currentModuleAuthSettings?.gaEnabled,
            sixPinEnabled: _currentModuleAuthSettings?.sixPinEnabled,
        };

        if (isFormValid) {
            controller.vm.setState({ disableWithdrawButton: true });
            // get main wallet amount again and do validation before proceed
            window.SPL_Member.getMainWallet().then((mainWallet) => {
                // mainWallet.data.balance
                if (fields.amount > mainWallet.data.balance) {
                    controller.vm.setState({ disableDecimalWithdraw: false });
                    controller.vm.setState({ disableWithdrawButton: false });
                    notification.showNotification('error', t('transaction:transaction.transfer.insuffficientMainWallet'));
                    return;
                }

                if (_currentModuleAuthSettings?.sixPinEnabled && !popupReducer?.enteredPin) {
                    core.portalIsLoading(controller.vm.props, false);
                    dispatch(popupAction.setItemConfig(POPUP_TYPE.PIN_ENTRY, _config));
                    dispatch(popupAction.setPopup(POPUP_TYPE.PIN_ENTRY, true));
                    dispatch(popupAction.setSubmitSuccessCb(controller.continueSubmitForm.bind(this)));
                    controller.vm.setState({ disableWithdrawButton: false });
                } else {
                    controller.continueSubmitForm();
                }
            });
        }
    },

    continueSubmitForm() {
        const { isFormValid, fields, displayBankList, selectedBankIndex, accountName, receiptImgFile } = controller.vm.state;
        const { t, popupReducer, dispatch } = controller.vm.props;

        if (isFormValid) {
            dispatch(portalAction.setIsSubmitting(true));
            controller.vm.setState({ disableWithdrawButton: true });
            let bankId = 0;
            let memberBankId = 0;
            let bankName = '';
            let bankObj = displayBankList[selectedBankIndex];

            if (bankObj.memberBankId) {
                memberBankId = bankObj.memberBankId;
            }

            if (bankObj.bankId) {
                bankId = bankObj.bankId;
            }

            if (bankObj.id) {
                bankName = bankObj.bankName;
            } else {
                bankName = fields.bankName;
            }

            let withdrawObj = {
                accountName: accountName,
                accountNumber: fields.accountNumber,
                memberBankId: memberBankId,
                memberBankBankId: bankId,
                amount: fields.amount,
                bankName: bankName,
                merchantCode: window.merchantCode,
                bankStatementPath: receiptImgFile,
                transactionOption: 'B',
                sixDigitPin: popupReducer?.enteredPin,
                verificationType: OVT.WITHDRAWAL_BANK,
            };
            core.portalIsLoading(controller.vm.props, true);

            window.SPL_Transaction.requestWithdraw(withdrawObj)
                .then((data) => {
                    if (data.status === 'Fail') {
                        if (data.errorCode === 2717) {
                            dispatch(authSettingsAction.setSixPinLocked()); // this will trigger affiliate-hook setModuleSixPinLocked
                        }

                        controller.vm.setState({ disableWithdrawButton: false });
                        notification.showNotification('error', t(data.errMsg));
                    } else {
                        controller.initWithdrawTab();
                        controller.vm.props.dispatch(walletAction.udpateLoadWallet(true));
                        controller.vm.setState({ disableWithdrawButton: false });
                        notification.showNotification('success', t('transaction:transaction.withdrawal.request-success'));
                        dispatch(popupAction.setPopup(POPUP_TYPE.PIN_ENTRY, false));
                        dispatch(popupAction.setEnteredPin(''));
                    }
                    core.portalIsLoading(controller.vm.props, false);
                    dispatch(portalAction.setIsSubmitting(false));
                })
                .catch((err) => {
                    controller.vm.setState({ disableWithdrawButton: false });
                    core.portalIsLoading(controller.vm.props, false);
                    dispatch(portalAction.setIsSubmitting(false));
                });
        }
    },

    getMainWallet() {
        controller.vm.setState({
            iconLoading: true,
            restoreOnLoad: true,
        });

        window.SPL_Member.getMainWallet().then((mainWallet) => {
            controller.vm.setState({
                mainWallet: mainWallet.data,
                iconLoading: false,
                restoreOnLoad: false,
            });
        });
    },

    getMerchantWithdrawAmountOptions() {
        window.SPL_Member.getMerchantWithdrawAmountOptions().then((withdrawAmount) => {
            controller.vm.setState({
                withdrawAmountOptions: withdrawAmount,
            });
        });
    },

    restoreWallet() {
        const { restoreOnLoad, disableWithdrawButton } = controller.vm.state;

        if (!restoreOnLoad && !disableWithdrawButton) {
            controller.vm.setState({ iconLoading: true, restoreOnLoad: true });
            window.SPL_Transaction.transferAllBackMainWallet()
                .then(() => {
                    setTimeout(() => {
                        controller.getMainWallet();
                    }, 5000);
                })
                .catch(() => {
                    setTimeout(() => {
                        controller.getMainWallet();
                    }, 5000);
                });
        }
    },

    getPopupAnnouncement(contentType) {
        const { user, language } = controller.vm.props;
        window.SPL_Content.getAnnoucementListWithType(user.isLogin, language.countryLanguageKey, 'Withdrawal', contentType).then((announcements) => {
            if (announcements.announcementsWithPopUpDisplay.length > 0) {
                controller.vm.setState({
                    announcementPopupList: announcements.announcementsWithPopUpDisplay,
                    announcementPopupVersion: announcements.popupVersion,
                    announcementPopupIsShow: true,
                });
            }
        });
    },

    closePopupAnnouncement(vm) {
        vm.setState({ announcementPopupIsShow: false });
    },

    getMaintenceTime() {
        window.SPL_Content.getMerchantFeatures('showWithdrawMaintenceTime', function (enabled) {
            if (enabled) {
                controller.vm.setState({ showWithdrawMaintenceTime: enabled });
                const { language } = controller.vm.props;
                window.SPL_Content.getWithdrawMaintenceTime(language.countryLanguageKey, (res) => {
                    controller.vm.setState({ withdrawTime: res.data });
                });
            }
        });
    },

    onMethodChange(method, optionCode) {
        let depositSettings = controller.vm.state.depositSettings;

        if (depositSettings) {
            depositSettings.hideAddBankAccount = trans_massageHideAddBank(controller.vm, 'withdraw', optionCode);
        }

        let stateToUpdate = {
            depositSettings: depositSettings,
        };
        if (method === 'onlineTransfer') {
            stateToUpdate = { ...stateToUpdate, isOnlineTransfer: true, isCrypto: false, isEwallet: false };
        } else if (method === 'crypto') {
            stateToUpdate = { ...stateToUpdate, isOnlineTransfer: false, isCrypto: true, isEwallet: false };
        } else if (method === 'ewallet') {
            stateToUpdate = { ...stateToUpdate, isOnlineTransfer: false, isCrypto: false, isEwallet: true };
        }

        controller.vm.setState(stateToUpdate, () => {
            controller.preGetRemainingAvailableWithdrawDetails();
        });
    },

    getSelectedMethodWithdrawAmountOptions(method) {
        const methodCode = {
            onlineTransfer: AMOUNT_DEPOSIT_METHOD_CODE.B,
            crypto: AMOUNT_DEPOSIT_METHOD_CODE.CT,
            ewallet: AMOUNT_DEPOSIT_METHOD_CODE.R,
            B: AMOUNT_DEPOSIT_METHOD_CODE.B,
            CT: AMOUNT_DEPOSIT_METHOD_CODE.CT,
            R: AMOUNT_DEPOSIT_METHOD_CODE.R,
        };

        const code = methodCode[method];

        const { withdrawAmountOptions } = controller.vm.state;

        switch (code) {
            case AMOUNT_DEPOSIT_METHOD_CODE.B:
                controller.vm.setState({
                    amountOptions: withdrawAmountOptions?.quickAmounts?.find((el) => el?.method === AMOUNT_DEPOSIT_METHOD_CODE.B)?.quickAmount?.split(',') || [],
                });
                break;

            case AMOUNT_DEPOSIT_METHOD_CODE.CT:
                controller.vm.setState({
                    amountOptions: withdrawAmountOptions?.quickAmounts?.find((el) => el?.method === AMOUNT_DEPOSIT_METHOD_CODE.CT)?.quickAmount?.split(',') || [],
                });
                break;

            case AMOUNT_DEPOSIT_METHOD_CODE.R:
                controller.vm.setState({
                    amountOptions: withdrawAmountOptions?.quickAmounts?.find((el) => el?.method === AMOUNT_DEPOSIT_METHOD_CODE.R)?.quickAmount?.split(',') || [],
                });
                break;
            default:
                break;
        }
    },

    loadEwallet() {
        return new Promise((resolve) => {
            const { compSettings } = controller.vm.state;
            if (compSettings && compSettings.seperateTngToEwallet) {
                window.SPL_Transaction.getWithdrawalBankList().then((data) => {
                    if (data && data.R && data.R.R.length > 0) {
                        controller.vm.setState({ ewalletEnabled: true, ewalletList: data.R.R });
                        resolve();
                    } else {
                        resolve();
                    }
                });
            } else {
                resolve();
            }
        });
    },

    loadCryptoWithdraw() {
        window.SPL_Transaction.getCryptWithdrawBank().then((data) => {
            if (data) {
                controller.vm.setState({ cryptoEnabled: data.showCrypto }, () => {
                    let addressData = data.coinAndSupportNetwork,
                        addressOption = [{ displayName: controller.vm.props.t('transaction:transaction.deposit.crypto.selectCryptoCoin') }];

                    if (addressData && [addressData].length > 0) {
                        for (let i = 0; i < Object.keys(addressData).length; i++) {
                            addressOption.push({ displayName: Object.keys(addressData)[i] });
                        }

                        controller.vm.setState({ addressData: addressData, addressOption: addressOption, selectedCurrency: addressOption[0].displayName }, () => {
                            controller.filterAddressData();
                        });
                    }

                    controller.loadCryptoBankGroup();
                    controller.loadTransactionDetails();
                });
            }
        });
    },

    changeCryptoChannel(e, screen) {
        let value = '';

        if (screen === 'mobile') {
            value = e;
        } else {
            value = e.target.value;
        }

        controller.vm.setState({ selectedCryptoIndex: value, selectedChainIndex: 0, disabledWithdrawCryptoButton: true }, () => {
            controller.loadCryptoBankGroup();
            controller.loadTransactionDetails();
        });
    },

    getMemberDepositWithdrawDecimalSettings() {
        window.SPL_Member.getMemberDepositWithdrawDecimalSettings().then((data) => {
            if (data) {
                controller.vm.setState({ memberDecimalSettings: data?.withdrawal || {} });
            }
        });
    },

    handleAmountChange(e) {
        const value = e.target.value;
        if (value) {
            let regex = '';
            if (controller.vm.state.memberDecimalSettings?.[AMOUNT_DEPOSIT_METHOD_CODE.B]) {
                regex = /^[0-9]+$/;
            } else {
                regex = /^(\d*)\.{0,1}(\d){0,2}$/;
            }
            if (value.toString().match(regex)) {
                controller.handleFieldChanged(e, 'amount');
            }
        } else {
            controller.handleFieldChanged(e, 'amount');
        }
    },

    loadCryptoBankGroup() {
        let params = {
            purpose: 'W',
            option: 'CT',
        };

        const { selectedCryptoIndex, selectedBankIndex } = controller.vm.state;

        window.SPL_Transaction.getMerchantBankGroupWithOption(params).then((data) => {
            if (data && data.channelOptions && data.options && data.channelOptions.length > 0) {
                for (let i = 0; i < data.channelOptions.length; i++) {
                    data.channelOptions[i].displayName = controller.vm.props.t(data.channelOptions[i].displayName);
                }
                let cryptoDecimal = '';
                let cryptoRoundUp = '';
                if (
                    data.options[data.channelOptions[selectedCryptoIndex].key] &&
                    data.options[data.channelOptions[selectedCryptoIndex].key][selectedBankIndex] &&
                    data.options[data.channelOptions[selectedCryptoIndex].key][selectedBankIndex].decimal
                ) {
                    cryptoDecimal = data.options[data.channelOptions[selectedCryptoIndex].key][selectedBankIndex].decimal;
                    cryptoRoundUp = data.options[data.channelOptions[selectedCryptoIndex].key][selectedBankIndex].roundUp
                        ? data.options[data.channelOptions[selectedCryptoIndex].key][selectedBankIndex].roundUp
                        : '';
                }

                controller.vm.setState(
                    {
                        cryptoOptions: data.channelOptions,
                        chainKey: data.channelOptions[selectedCryptoIndex].key,
                        decimal: cryptoDecimal,
                        roundUp: cryptoRoundUp,
                    },
                    async () => {
                        let cryptoChain = data.options[controller.vm.state.chainKey];
                        // for (let i = 0; i < cryptoChain.length; i++) {
                        //     let chain = cryptoChain[i];
                        //     chain.svgData = await window.SPL_Other.renderTemplateSVG(
                        //         `${commonUtil.getDepositImgPath()}${chain.coin}_${chain.network}.svg`,
                        //         `${chain.coin}_${chain.network}`
                        //     );
                        // }
                        controller.vm.setState({ cryptoChain: cryptoChain }, () => {
                            controller.getCryptoAddress();
                            controller.loadCryptoDisplaySettings();
                        });
                    }
                );
            }
        });
    },

    getCryptoAddress() {
        const { cryptoChain, selectedChainIndex } = controller.vm.state;

        window.SPL_Transaction.getMemberCryptoAddress().then((data) => {
            if (data) {
                let params = {
                    selectedCrypto: cryptoChain[selectedChainIndex],
                    orginalAddressList: data.orginalAddressList,
                };

                window.SPL_Transaction.filterAddressAndGetExchangeRate(params).then((filterData) => {
                    controller.vm.setState({
                        cryptoExchangeRate: filterData.cryptoExchangeRate,
                        memberAddressOption: filterData.cryptoAddressList,
                        chainBranch: cryptoChain[selectedChainIndex].branch,
                        chainName: cryptoChain[selectedChainIndex].province,
                        selectedAddress: 0,
                    });
                    core.portalIsLoading(controller.vm.props, false);
                });
            }
        });
    },

    loadCryptoDisplaySettings() {
        const { countryLanguageKey } = controller.vm.props.language;
        const { cryptoChain, selectedChainIndex } = controller.vm.state;
        let bankCode = cryptoChain[selectedChainIndex].displayBankCode;

        window.SPL_Transaction.loadCryptoDisplaySettings(countryLanguageKey).then((data) => {
            if (data) {
                if (data.showCurrencyAndCoin) {
                    controller.vm.setState({ showCurrencyAndCoin: true });
                }

                if (data.cryptoWithdrawFee) {
                    controller.vm.setState({ cryptoWithdrawFee: data.cryptoWithdrawFee });
                }

                if (data.cryptoWithdrawTimeOut) {
                    let guaranteedUntilTime = window.SPL_Transaction.calculateExpireDate(data.cryptoWithdrawTimeOut);

                    controller.vm.setState({ guaranteedUntilTime: guaranteedUntilTime }, () => {
                        setTimeout(() => {
                            controller.vm.setState({ getlatestRate: true }, () => {
                                controller.validateWithdrawCryptoSubmitBtn();
                            });
                        }, data.cryptoWithdrawTimeOut);
                    });
                }

                if (data.withdrawNotice) {
                    let withdrawChainRemark = data.withdrawNotice[bankCode];

                    controller.vm.setState({ withdrawChainRemark: withdrawChainRemark });
                }

                if (data.networkAndCoin) {
                    controller.vm.setState({ networkAndCoin: data.networkAndCoin });
                }
            }
        });
    },

    loadTransactionDetails() {
        window.SPL_Transaction.loadTransactionDetails().then((data) => {
            if (data) {
                controller.vm.setState({ transactionDetails: data });
            }
        });
    },

    onChainChanged(chainObj) {
        core.portalIsLoading(controller.vm.props, true);

        const { selectedChainIndex, cryptoChain } = controller.vm.state;
        let foundIndex = cryptoChain.indexOf(chainObj);

        if (foundIndex !== selectedChainIndex) {
            controller.vm.setState({ selectedChainIndex: foundIndex, currencyAmount: '', cryptoAmount: '', disabledWithdrawCryptoButton: true, selectedAddress: 0 }, () => {
                controller.getCryptoAddress();
                controller.loadCryptoDisplaySettings();
            });
        }
    },

    validateWithdrawCryptoSubmitBtn() {
        const { selectedChainIndex, cryptoChain, getlatestRate, cryptoAmount, currencyAmount, remindMinAmount } = controller.vm.state;

        if (cryptoChain[selectedChainIndex] === '' || getlatestRate || !cryptoAmount || !currencyAmount || remindMinAmount !== '') {
            controller.vm.setState({ disabledWithdrawCryptoButton: true });
        } else {
            controller.vm.setState({ disabledWithdrawCryptoButton: false });
        }
    },

    onReload() {
        controller.vm.setState({ reload: true });

        controller.loadCryptoDisplaySettings();

        setTimeout(() => {
            controller.vm.setState({ getlatestRate: false }, () => {
                controller.validateWithdrawCryptoSubmitBtn();
            });
        }, 1000);
    },

    handleMemberAddressChange(value) {
        controller.vm.setState({ selectedAddress: value }, () => {
            controller.validateWithdrawCryptoSubmitBtn();
        });
    },

    getAddressModal(value) {
        controller.vm.setState({
            addressModal: value,
            addAddressErrMsg: '',
            address: '',
            otpCode: '',
            countdownTimer: 0,
            submittedOTP: false,
            addAddressErrMsg: '',
            cryptoAddressDisable: true,
            disableOtpBtn: true,
        });
    },

    calculateExchangeRate(amount, isCrypto) {
        const { selectedChainIndex, cryptoChain, cryptoExchangeRate, cryptoWithdrawFee } = controller.vm.state;

        let params = {
            amount: Number(amount),
            isCrypto: isCrypto,
            selectedCrypto: cryptoChain[selectedChainIndex],
            cryptoWithdrawFee: cryptoWithdrawFee,
            cryptoExchangeRate: cryptoExchangeRate,
        };

        let calculateExchangeRate = window.SPL_Transaction.calculateExchangeRate(params);

        if (isCrypto) {
            controller.vm.setState({ currencyAmount: calculateExchangeRate.amount }, () => {
                if (
                    calculateExchangeRate.amount < Number(controller.vm.state.limit.minLimit) ||
                    calculateExchangeRate.amount > Number(controller.vm.state.limit.dailyMaxAmount)
                ) {
                    controller.vm.setState(
                        {
                            remindMinAmount: controller.vm.props.t('global:global.form.amount-placeholder', {
                                min: Number(controller.vm.state.limit.minLimit).toFixed(2),
                                max: Number(controller.vm.state.limit.dailyMaxAmount).toFixed(2),
                            }),
                        },
                        () => {
                            controller.validateWithdrawCryptoSubmitBtn();
                        }
                    );
                } else {
                    controller.vm.setState({ remindMinAmount: '' }, () => {
                        controller.validateWithdrawCryptoSubmitBtn();
                    });
                }
            });
        } else {
            controller.vm.setState({ cryptoAmount: calculateExchangeRate.cryptoAmount }, () => {
                controller.validateWithdrawCryptoSubmitBtn();
            });
        }

        if (calculateExchangeRate.cryptoFeeCharge || calculateExchangeRate.cryptoFeeCharge === 0) {
            controller.vm.setState({ cryptoFeeCharge: calculateExchangeRate.cryptoFeeCharge });
        }
    },

    handleCryptoAmountChange(value) {
        if (value.toString().match(/^(\d*)\.{0,1}(\d){0,2}$/)) {
            controller.vm.setState({ cryptoAmount: value, withdrawErrMsg: '' }, () => {
                controller.calculateExchangeRate(value, true);
            });
        }
    },

    handleCryptoCurrencyAmountChange(value) {
        if (value.toString().match(/^(\d*)\.{0,1}(\d){0,2}$/)) {
            controller.vm.setState({ currencyAmount: value, withdrawErrMsg: '' });

            if (!value || value < Number(controller.vm.state.cryptoMinmaxLimit.minLimit) || value > Number(controller.vm.state.cryptoMinmaxLimit.dailyMaxAmount)) {
                controller.vm.setState(
                    {
                        cryptoAmount: 0,
                        remindMinAmount: controller.vm.props.t('global:global.form.amount-placeholder', {
                            min: Number(controller.vm.state.cryptoMinmaxLimit.minLimit).toFixed(2),
                            max: Number(controller.vm.state.cryptoMinmaxLimit.dailyMaxAmount).toFixed(2),
                        }),
                    },
                    () => {
                        controller.validateWithdrawCryptoSubmitBtn();
                    }
                );
            } else {
                controller.vm.setState({ remindMinAmount: '' });
                controller.calculateExchangeRate(value, false);
            }
        }
    },

    handleWithdrawCryptoSubmit() {
        const { currencyAmount } = controller.vm.state;
        const { authSettingsReducer, popupReducer, dispatch } = controller.vm.props;
        const _msWithdrawalCryptoAModule = authSettingsReducer?.processedData?.[MT.WITHDRAWAL_CRYPTO];

        core.portalIsLoading(controller.vm.props, true);
        controller.vm.setState({ isSubmitting: true });

        const _config = {
            gaEnabled: _msWithdrawalCryptoAModule?.gaEnabled,
            sixPinEnabled: _msWithdrawalCryptoAModule?.sixPinEnabled,
        };

        window.SPL_Member.getMainWallet().then((mainWallet) => {
            if (currencyAmount > mainWallet.data.balance) {
                core.portalIsLoading(controller.vm.props, false);
                controller.vm.setState({ withdrawErrMsg: 'transaction:transaction.transfer.insuffficientMainWallet', isSubmitting: false });
                return;
            }

            // if enable GA on crypto withdrawal module, but haven't got enter pin number for GA yet, then will trigger pin popup
            if (_msWithdrawalCryptoAModule?.gaEnabled && !popupReducer?.enteredPin) {
                core.portalIsLoading(controller.vm.props, false);
                dispatch(popupAction.setItemConfig(POPUP_TYPE.PIN_ENTRY, _config));
                dispatch(popupAction.setPopup(POPUP_TYPE.PIN_ENTRY, true));
                dispatch(popupAction.setSubmitSuccessCb(controller.submitWithdrawCrypto));
                controller.vm.setState({ isSubmitting: false });
            } else {
                controller.submitWithdrawCrypto();
            }
        });
    },

    submitWithdrawCrypto() {
        const { name } = controller.vm.props.user.account;
        const { currencyAmount, selectedChainIndex, cryptoChain, cryptoWithdrawFee, cryptoExchangeRate, memberAddressOption, selectedAddress } = controller.vm.state;
        const { t, authSettingsReducer, popupReducer, dispatch } = controller.vm.props;
        const _msCryptoAddressModule = authSettingsReducer?.processedData?.[MT.CRYPTO_ADDRESS];

        controller.vm.setState({ isSubmitting: true });
        dispatch(portalAction.setIsSubmitting(true));

        let params = {
            amount: currencyAmount,
            selectedCrypto: cryptoChain[selectedChainIndex],
            cryptoWithdrawFee: cryptoWithdrawFee,
            cryptoExchangeRate: cryptoExchangeRate,
            selectedMemberAddress: memberAddressOption[selectedAddress],
            accountName: name,
            ...(_msCryptoAddressModule?.otp_feature && {
                verificationType: OVT.WITHDRAWAL_CRYPTO,
            }),
            ...(_msCryptoAddressModule?.gaEnabled && {
                verificationMethod: OVM.GA,
                verifyKey: popupReducer?.enteredPin,
            }),
        };

        window.SPL_Transaction.getAllowCryptoTransaction()
            .then((cryptoDetails) => {
                if (cryptoDetails && cryptoDetails.blockWithdraw) {
                    let autoclosedTimmer = Number(cryptoDetails.minutes) * 60000;
                    notification.showNotification('error', t('error:error.cryptoAccountUnderReview'), { autoClose: autoclosedTimmer }, true);
                    core.portalIsLoading(controller.vm.props, false);
                } else {
                    window.SPL_Transaction.submitWithdrawCrypto(params).then((data) => {
                        if (data) {
                            if (data.status === 'S') {
                                controller.vm.props.dispatch(walletAction.udpateLoadWallet(true));
                                controller.vm.setState({ disableWithdrawButton: false, isSubmitting: false });
                                notification.showNotification('success', controller.vm.props.t('transaction:transaction.withdrawal.request-success'));
                                controller.vm.setState({ currencyAmount: '', cryptoAmount: '', selectedAddress: 0 });
                                dispatch(popupAction.setEnteredPin(''));
                            } else {
                                if (data.errorCode === 2717) {
                                    dispatch(authSettingsAction.setSixPinLocked()); // this will trigger affiliate-hook setModuleSixPinLocked
                                }

                                notification.showNotification('error', controller.vm.props.t(data.msg));
                                controller.vm.setState({ disableWithdrawButton: false, isSubmitting: false });
                            }
                        }
                        dispatch(portalAction.setIsSubmitting(false));
                        core.portalIsLoading(controller.vm.props, false);
                    });
                }
            })
            .catch(() => {
                dispatch(portalAction.setIsSubmitting(false));
                core.portalIsLoading(controller.vm.props, false);
                controller.vm.setState({ disableWithdrawButton: false, isSubmitting: false });
            });
    },

    handleCryptoCurrencyChange(e) {
        controller.vm.setState({ selectedCurrency: e.target.value }, () => {
            controller.filterAddressData();
            controller.checkOtpBtnDisable();
        });
    },

    filterAddressData() {
        const { addressData, networkAndCoin, selectedCurrency } = controller.vm.state;

        let networkFilter = '',
            networkOption = [{ displayName: 'transaction:transaction.deposit.crypto.selectNetwork' }],
            addressFilter = [];

        if (selectedCurrency) {
            networkFilter = networkAndCoin && networkAndCoin[selectedCurrency] ? networkAndCoin[selectedCurrency] : [];
            addressFilter = addressData[selectedCurrency];
            if (networkFilter && networkFilter.length > 0) {
                for (let j = 0; j < addressFilter.length; j++) {
                    for (let i = 0; i < networkFilter.length; i++) {
                        if (addressFilter[j].indexOf(networkFilter[i]) !== -1) {
                            networkOption.push({ displayName: networkFilter[i] });
                        }
                    }
                }
            }
        }

        controller.vm.setState({ networkOption: networkOption, selectedNetwork: networkOption[0].displayName }, () => {
            controller.checkAddressBtnDisable();
        });
    },

    handleCryptoNetworkChange(e) {
        controller.vm.setState({ selectedNetwork: e.target.value }, () => {
            controller.checkAddressBtnDisable();
            controller.checkOtpBtnDisable();
        });
    },

    handleAddressChange(e) {
        if (e.target.value.match(/^([^`~!@#$%^&*()\-_=+[{\]}\\\|;:',<.>\/? ]*)$/)) {
            controller.vm.setState({ address: e.target.value }, () => {
                controller.checkAddressBtnDisable();
                controller.checkOtpBtnDisable();
            });
        }
    },

    checkAddressBtnDisable() {
        const { selectedCurrency, selectedNetwork, address, otpCode } = controller.vm.state;
        const { addCryptoAddressRequireOTP } = controller.vm.props.portal.settings;

        if (selectedCurrency && selectedNetwork && address && ((addCryptoAddressRequireOTP && otpCode) || (!addCryptoAddressRequireOTP && !otpCode))) {
            controller.vm.setState({ cryptoAddressDisable: false });
        } else {
            controller.vm.setState({ cryptoAddressDisable: true });
        }

        controller.vm.setState({ addAddressErrMsg: '' });
    },

    handleCryptoAddressSubmit() {
        const { selectedCurrency, selectedNetwork, address } = controller.vm.state;
        const params = {
            address: address,
            cryptoCoin: selectedCurrency,
            cryptoNetwork: selectedNetwork,
        };

        const onSuccess = () => {
            core.portalIsLoading(controller.vm.props, true);
            controller.vm.setState(
                {
                    addressModal: false,
                    address: '',
                    otpCode: '',
                    countdownTimer: 0,
                    submittedOTP: false,
                    addAddressErrMsg: '',
                    cryptoAddressDisable: true,
                    disableOtpBtn: true,
                },
                () => {
                    controller.getCryptoAddress();
                }
            );
        };

        const onError = (errMsg) => {
            controller.vm.setState({ addressModal: true, addAddressErrMsg: errMsg });
        };

        APIClient.createMemberCryptoAddress(params, onSuccess, onError);
    },

    sendOtpCode() {
        if (!controller.vm.state.disableOtpBtn) {
            window.SPL_Member.triggerSendVerification('CRYPTO', null, controller.vm.state.address)
                .then(() => {
                    controller.vm.setState(
                        {
                            submittedOTP: true,
                            countdownTimer: 60,
                            addAddressErrMsg: '',
                        },
                        () => {
                            controller.timerCount();
                        }
                    );
                })
                .catch((error) => {
                    if (error) {
                        let errorMsg = error.message.response.data;
                        if (errorMsg) {
                            if (errorMsg.message === 'Email Incorrect') {
                                controller.vm.setState({ addAddressErrMsg: 'global:global.verification.differentEmail' });
                            } else if (errorMsg.message === 'Maximum Attempts Exceed') {
                                controller.vm.setState({ addAddressErrMsg: 'global:global.verification.cryptoMaxAttempts' });
                            } else if (errorMsg.message === 'transaction.transfer.email.has.been.verified') {
                                controller.vm.setState({ addAddressErrMsg: 'global:global.verification.yourEmailVerified' });
                            } else {
                                controller.vm.setState({ addAddressErrMsg: 'global:global.verification.generalError' });
                            }
                        }
                    }
                });
        }
    },

    timerCount() {
        let vm = controller.vm,
            secondCount = 0;

        if (vm.state.countdownTimer > 0) {
            setTimeout(() => {
                secondCount = vm.state.countdownTimer - 1;
                vm.setState({ countdownTimer: secondCount });
                controller.timerCount();
            }, 1000);
        }
    },

    handleOtpChange(e) {
        controller.vm.setState({ otpCode: e.target.value }, () => {
            if (controller.vm.state.otpCode.length === 6) {
                controller.validateOtp();
            }
        });
    },

    validateOtp() {
        core.portalIsLoading(controller.vm.props, true);
        window.SPL_Member.validateOtp(null, controller.vm.state.otpCode, controller.vm.state.address)
            .then((result) => {
                if (result) {
                    controller.checkAddressBtnDisable();
                } else {
                    controller.vm.setState({
                        addAddressErrMsg: 'global:global.verification.codeIncorrect',
                        cryptoAddressDisable: true,
                    });
                }
                core.portalIsLoading(controller.vm.props, false);
            })
            .catch(() => {
                controller.vm.setState({
                    addAddressErrMsg: 'global:global.verification.generalError',
                });
                core.portalIsLoading(controller.vm.props, false);
            });
    },

    checkOtpBtnDisable() {
        const { selectedCurrency, selectedNetwork, address } = controller.vm.state;

        if (selectedCurrency && selectedCurrency.length > 0 && selectedNetwork && selectedNetwork.length > 0 && address) {
            controller.vm.setState({ disableOtpBtn: false });
        } else {
            controller.vm.setState({ disableOtpBtn: true });
        }
    },

    handleClearReceipt() {
        trans_handleBankStatementClearReceipt(controller.vm);
    },

    handleReceiptImageChange(e) {
        trans_handleBankStatementReceiptImageChange(controller.vm, e);
    },
};

export default controller;
