import transactionHelper from 'root/utils/transaction-helper';
import commonUtil from '../../../../../../utils/common-util';
import { trans_getDepositAmountOptionByMethod } from 'root/utils/transaction-util';
const controller = {
    vm: null,

    init(vm) {
        controller.vm = vm;
        controller.vm.state = {
            filteredDepositApiJson: controller.vm.props.filteredDepositApiJson,
            depositAllSettings: controller.vm.props.depositSettings,
            depositMinMaxLimit: { min: 0, max: 0 },
            depositAmountOptions: trans_getDepositAmountOptionByMethod(controller.vm.props.depositAmountOptions),

            fields: {
                amount: '',
                reference: '',
            },
            errors: {},

            verifyBank: [],
            verifyAccountList: '',
            expressDepositMemberBankList: null,
            expressDepositMerchantBankList: null,
            showNotBankSupportMsg: false,
            showAllBankMaintenaceMsg: false,

            //notice artical settings
            showDefaultArticle: false,
            showCustomDepositArticle: false,
            showCustomDepositArticleContent: '',

            enableCustomArticleJSON: false,
            compSettings: [],
        };
    },

    initCompSettings() {
        return new Promise((resolve) => {
            // Step 1: Standard way to get from portal settings
            let tempSettings = commonUtil.getSettingFromPortalSettings({
                vm: controller.vm,
                settingName: 'expressDepositSettings',
            });

            // Step 2: Massage again to get the specific data you need (Skip if not needed)

            // Step 3: Initial all into compSettings
            let compSettings = {
                hideDisplayInfo: tempSettings.hideDisplayInfo,
                showCustomArticle: tempSettings.showCustomArticle,
                enableCustomArticleJSON: tempSettings.enableCustomArticleJSON,
                hideDisplayNotice: tempSettings.hideDisplayNotice,
                isCustomArticlePath: tempSettings.isCustomArticlePath,
            };

            controller.vm.setState({ compSettings }, () => {
                const { showCustomArticle } = controller.vm.state.compSettings;
                if (showCustomArticle) {
                    controller.readCustomDepositArticle();
                }
                resolve();
            });
        });
    },

    loadExpressDepositFilteredBank() {
        window.SPL_Member.getExpressDepositFilteredBank().then(async (res) => {
            let merchantBank = res.data.merchantBankList;
            let memberBank = res.data.memberBankList;

            let expressDepositBankList = [];
            let expressDepositMerchantBankList = [];

            let showNotBankSupportMsg = merchantBank.length <= 0;
            let showAllBankMaintenaceMsg = memberBank.length <= 0;

            if (memberBank.length >= 1) {
                for (let i = 0; i < memberBank.length; i++) {
                    expressDepositBankList = controller.copyMemberBank(memberBank[i], true);
                    // expressDepositBankList.svgData = await window.SPL_Other.renderTemplateSVG(
                    //     `${commonUtil.getDepositImgPath()}${expressDepositBankList.code}.svg`,
                    //     `${expressDepositBankList.code}`
                    // );
                }
            }

            if (merchantBank.length >= 1) {
                for (let j = 0; j < merchantBank.length; j++) {
                    const bank = merchantBank[j];
                    // bank.svgData = await window.SPL_Other.renderTemplateSVG(`${commonUtil.getDepositImgPath()}${bank.code}.svg`, `${bank.code}`);
                    expressDepositMerchantBankList.push(bank);
                }
            }

            controller.vm.setState({
                expressDepositMemberBankList: expressDepositBankList,
                expressDepositMerchantBankList: expressDepositMerchantBankList,
                showAllBankMaintenaceMsg: showAllBankMaintenaceMsg,
                showNotBankSupportMsg: showNotBankSupportMsg,
            });
        });
    },

    getCorrectDepositMinMaxLimit() {
        const currency = controller.vm.props.user.account.currency;

        window.SPL_Member.getExpressDepositDecimalInfo(currency).then((data) => {
            if (data) {
                let result = data;
                if (data.min === undefined || data.min === null || data.min === '') {
                    result.min = 0;
                }
                if (data.max === undefined || data.max === null || data.max === '' || data.max >= 9999999) {
                    result.max = controller.vm.props.t('transaction:transaction.deposit.onlinetransfer.unlimited', 'Unlimited');
                }

                controller.vm.setState({
                    depositMinMaxLimit: result,
                });
            } else {
                controller.vm.setState({
                    depositMinMaxLimit: {},
                });
            }
        });
    },

    loadBankAccounts() {
        let loadAllOwnedAndUnOwnedBank = new Promise((resolve) => {
            window.SPL_Transaction.loadAllOwnedAndUnOwnedBank().then((data) => resolve(data));
        });
        let loadGetVerifiedBankAccountsByType = new Promise((resolve) => {
            window.SPL_Member.getVerifiedBankAccountsByType().then((value) => resolve(value));
        });

        Promise.all([loadAllOwnedAndUnOwnedBank, loadGetVerifiedBankAccountsByType]).then((result) => {
            let ownedBankList = result[0];
            let verifyMemberBank = result[1];

            let memberBankList = ownedBankList.memberBankList;
            let bankList = ownedBankList.bankList;
            let verifyBank = [];
            for (let i = 0; i < memberBankList.length; i++) {
                for (let j = 0; j < bankList.length; j++) {
                    const memberBank = memberBankList[i];
                    const bankListName = bankList[i];
                    if (bankListName) {
                        memberBank.displayName = `${memberBank.bankName} ${memberBank.accountNumber} ${memberBank.accountHolder}`;
                    }
                }
            }

            for (let j = 0; j < verifyMemberBank.length; j++) {
                if (verifyMemberBank[j] && verifyMemberBank[j].verified) {
                    verifyBank.push(verifyMemberBank[j]);
                }
            }

            controller.vm.setState({
                memberBankList: ownedBankList.memberBankList,
                unOwnedBankList: ownedBankList.unOwnedBankList,
                verifyBank: verifyBank,
            });
        });
    },

    copyMemberBank(memberBankList, isMerchantBank) {
        const { country } = controller.vm.props.language;
        if (isMerchantBank) {
            memberBankList.bankName = window.SPL_Translation.getShorterBankName(memberBankList.bankCode, country);
        } else {
            memberBankList.bankName = window.SPL_Translation.getShorterBankName(memberBankList.bankCode, country);
        }

        return {
            id: memberBankList.id,
            name: memberBankList.bankName,
            accountHolder: memberBankList.accountName,
            accountNumber: memberBankList.accountNumber,
            accountNumberDisplay: memberBankList.accountNumber,
            bankCode: memberBankList.bankCode,
            isChecked: false,
            bankName: memberBankList.bankName,
        };
    },

    addButtonOnClicked() {
        controller.vm.props.history.push('/myaccount/bankingdetails');
    },

    removeBankList(memberBank) {
        memberBank.preferredBank = false;

        window.SPL_Member.updatePreferredBank(memberBank).then((yes) => {
            controller.vm.setState({ expressDepositMemberBankList: null, expressDepositMerchantBankList: null, showAllBankMaintenaceMsg: false });
            controller.loadExpressDepositFilteredBank();
        });
    },

    handleBankDetailChange(e) {
        const { verifyBank } = controller.vm.state;

        let verifyAccountList = '';
        let isFormValid = false;

        if (verifyBank && verifyBank[e.target.value]) {
            verifyAccountList = verifyBank[e.target.value];
            isFormValid = true;
        }

        controller.vm.setState({ verifyAccountList: verifyAccountList, isFormValid: isFormValid, selectedChannel: e.target.value }, () => {
            transactionHelper.isValidForm(controller.vm);
        });
    },

    handleFieldChanged(e, field) {
        let fields = controller.vm.state.fields;
        fields[field] = e.target.value;
        controller.vm.setState({ fields }, () => {
            controller.handleValidation();
        });
    },

    handleSubmitExpressDepositBank() {
        const { verifyAccountList } = controller.vm.state;
        let isFormValid = false;

        transactionHelper.resetForm(controller.vm);
        //set selected bank as preferred bank
        verifyAccountList.preferred = true;

        window.SPL_Member.updatePreferredBank(verifyAccountList).then((yes) => {
            controller.vm.setState({
                expressDepositMemberBankList: verifyAccountList,
                isFormValid: isFormValid,
                selectedChannel: ' ',
                showAllBankMaintenaceMsg: false,
                displayDefault: true,
            });
            controller.loadExpressDepositFilteredBank(verifyAccountList);
        });
    },

    readCustomDepositArticle() {
        const { compSettings } = controller.vm.state;
        const customFilename = compSettings && compSettings.isCustomArticlePath ? 'e' : '';
        transactionHelper.readCustomDepositArticle(controller.vm, customFilename);
    },
};

export default controller;
