import React, { Fragment } from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import SVGElements from 'root/components/SVGElements';
import SVG from 'react-inlinesvg';

// controller / util
import vm from '../../deposit-landing.controller';
import commonUtil from '../../../../../../../utils/common-util';
import { gu_getNestedValue } from '../../../../../../../utils/general-util';
import SmallLoading from 'root/webapp/shared/loading/smallLoading';
import AuthSettings from '@components/molecules/auth-settings/auth-settings';
import { MODULE_TYPE as MT } from '@constants';
class MDeposit extends React.Component {
    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
    }

    componentDidMount() {
        vm.initCompSettings().then(() => {
            vm.initPaymentGatewaySetting();
            vm.getMemberDepositWithdrawDecimalSettings();
            commonUtil.loadCustomTranslation('deposit-landing', this, function () {
                vm.initDepositPage();
            });
            vm.loadDepositOptions();
        });
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    renderSelectedTab() {
        const Content = vm.getTabContent();
        const { paymentGatewaySetting, compSettings } = this.state;
        let param = {
            depositSettings: this.state.depositAllSettings ? this.state.depositAllSettings : null,
            depositDataJson: this.state.depositDataJson ? this.state.depositDataJson : null,
            filteredDepositApiJson: this.state.filteredDepositApiJson ? this.state.filteredDepositApiJson : null,
            depositAmountOptions: this.state.depositAmountOptions,
            customCountdown: gu_getNestedValue(compSettings, 'depositSettings.customCountdown'),
            paymentGatewaySetting,
            memberDecimalSettings: this.state.memberDecimalSettings,
        };
        if (Content) {
            return <Content {...param}></Content>;
        }

        return <div></div>;
    }

    render() {
        const { displayTab, paymentCategories, selectedPaymentCategory, customTranslationObj, isSubLoading, compSettings } = this.state;
        return (
            <Translation>
                {(t) => (
                    <div data-page='/myaccount/'>
                        <AuthSettings module={MT?.BANK_DETAIL_BANK} isPublic={false} />

                        <div className='deposit-page'>
                            {/* Categorized Tab Layer */}
                            {paymentCategories && paymentCategories.length > 0 && (
                                <div className='categorized-deposit-tabs'>
                                    {(paymentCategories || []).map((category, i) => {
                                        const { translation } = category;
                                        let isTranslationArr = Array.isArray(translation);
                                        return (
                                            <div
                                                key={i}
                                                className={`deposit-tab ${selectedPaymentCategory === category ? 'active' : ''}`}
                                                onClick={() => vm.onPaymentCategoryChange(category)}
                                            >
                                                {isTranslationArr ? (
                                                    translation.map((desc, i) => {
                                                        if (desc) {
                                                            return <span key={i}>{desc}</span>;
                                                        } else {
                                                            return (
                                                                <Fragment key={i}>
                                                                    <SVG src='/public/html/images/settings/icon-time.svg' />
                                                                </Fragment>
                                                            );
                                                        }
                                                    })
                                                ) : (
                                                    <span>{translation}</span>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}

                            {/* Options Layer */}
                            <div className='group-item deposit-options'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.onlinetransfer.option', 'Deposit Options')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='deposit-options-list'>
                                    <ul>
                                        {(displayTab || []).map((tab, i) => {
                                            const { selected, code, actualCode, key, translation } = tab;
                                            return (
                                                <li
                                                    key={i}
                                                    className={['icon-hover-box', selected ? 'on' : 'off'].join(' ')}
                                                    onClick={() => {
                                                        vm.toggleTab(i, tab);
                                                    }}
                                                >
                                                    <span className='icon-box'>
                                                        {gu_getNestedValue(compSettings.depositSettings, `customDepositOptions.${actualCode}.img`) ? (
                                                            <img src={compSettings.depositSettings.customDepositOptions[actualCode].img} alt={`${actualCode}.jpg`} />
                                                        ) : (
                                                            <SVG src={`/public/html/images/deposit/${actualCode}.svg`}>
                                                                <Fragment>
                                                                    {tab.key !== 'fpy2' && <i className={'icon-deposit-' + key}></i>}
                                                                    {tab.key === 'fpy2' && (
                                                                        <SVGElements
                                                                            className={key}
                                                                            name={tab.key}
                                                                            width='300%'
                                                                            height='300%'
                                                                            color={selected ? '#FF4E00' : 'gray'}
                                                                        />
                                                                    )}
                                                                </Fragment>
                                                            </SVG>
                                                        )}
                                                    </span>
                                                    <span>
                                                        {code === 'H2PP'
                                                            ? 'Promptpay'
                                                            : gu_getNestedValue(customTranslationObj, `${actualCode}`)
                                                            ? t(customTranslationObj[actualCode])
                                                            : gu_getNestedValue(customTranslationObj, `${code}`)
                                                            ? t(customTranslationObj[code])
                                                            : t(translation)}
                                                    </span>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>

                            {isSubLoading ? <SmallLoading /> : this.renderSelectedTab()}
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['global', 'settings', 'transaction', 'register'])(withRouter(MDeposit)));
