import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { TweenMax } from 'gsap';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const aniList = [];

class Games_3D_Banner extends React.Component {
    constructor() {
        super();
        this.state = {};
    }

    componentWillMount() {
        this.doSuspendAni();
    }

    doSuspendAni() {
        aniList.forEach((item) => item.kill());
        aniList.length = 0;
        const randomItem = (list) => {
            return list[0 + Math.round(Math.random() * (list.length - 1 - 0))];
        };
        const idList = ['#suspend_1', '#suspend_2', '#suspend_3', '#suspend_4'];
        const endStyleStore = [];

        TweenMax.delayedCall(0.05, () => {
            idList.map((id, i) => {
                const $item = document.querySelector(id);
                endStyleStore[i] = {
                    left: getComputedStyle($item).left,
                    bottom: getComputedStyle($item).bottom,
                };
                TweenMax.set(id, {
                    x: -parseFloat(getComputedStyle($item).width) / 2,
                    y: 0,
                    bottom: 0,
                    left: '50%',
                    opacity: 0,
                    rotation: [0, 3].includes(i) ? 0 : randomItem([-80, -40, 40, 80, 100]),
                    onComplete: () => {
                        TweenMax.set(id, {
                            left: getComputedStyle($item).left,
                        });

                        aniList.push(
                            TweenMax.to(id, 0.8, {
                                x: 0,
                                y: 0,
                                left: endStyleStore[i].left,
                                bottom: endStyleStore[i].bottom,
                                opacity: 1,
                                rotation: 0,
                                delay: 0.2 * i + 0.5,
                                onComplete: () => {
                                    TweenMax.set(id, {
                                        left: '',
                                        bottom: '',
                                    });

                                    if (i == idList.length - 1) {
                                        idList.map((id) => {
                                            aniList.push(
                                                TweenMax.to(id, randomItem([5, 6, 7]), {
                                                    x: `${randomItem(['+', '-'])}=${randomItem([5, 15, 25])}`,
                                                    y: `${randomItem(['+', '-'])}=${randomItem([5, 15, 25])}`,
                                                    rotation: randomItem([15, 10, -10, -5]),
                                                    yoyo: true,
                                                    repeat: -1,
                                                })
                                            );
                                        });
                                    }
                                },
                            })
                        );
                    },
                });
            });
        });
    }

    render() {
        return (
            <div className='games-3d-banner'>
                <div className='container'>
                    <div className='suspend' id='suspend_1'>
                        <LazyLoadImage src='/public/html/images/3d_games/icon/suspend/suspend_1.png' alt='' />
                    </div>
                    <div className='suspend' id='suspend_2'>
                        <LazyLoadImage src='/public/html/images/3d_games/icon/suspend/suspend_2.png' alt='' />
                    </div>
                    <div className='suspend' id='suspend_3'>
                        <LazyLoadImage src='/public/html/images/3d_games/icon/suspend/suspend_3.png' alt='' />
                    </div>
                    <div className='suspend' id='suspend_4'>
                        <LazyLoadImage src='/public/html/images/3d_games/icon/suspend/suspend_4.png' alt='' />
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(withTranslation()(withRouter(Games_3D_Banner)));
