import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { TweenMax } from 'gsap';

import LeftSubMenu from 'root/webapp/shared/component/wap/LeftSubMenu';
import HomeMemberProfile from 'root/webapp/shared/component/wap/HomeMemberProfile';

import vm from '../sports.controller';

class Sports extends React.Component {
    tween1;
    tween2;

    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        vm.getSportsProviderList(true).then((data) => {
            this.toggleAni();
        });
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    toggleAni(pass) {
        this.tween1 && this.tween1.kill();
        this.tween2 && this.tween2.kill();

        this.tween1 = TweenMax.delayedCall(0.3, function () {
            this.tween2 = TweenMax.staggerTo('.brand-page ul.ani-ul > li', 0.3, { alpha: 1 }, 0.08);
        });
    }

    checkMaintenance(sports, desktop) {
        if (!sports.maintenance) {
            vm.launchGame(sports.provider, sports.isDisableProvider, '', desktop);
        }
    }

    render() {
        return (
            <Translation>
                {(t) => (
                    <Fragment>
                        <div className='sports-page'>
                            <HomeMemberProfile />
                            <div className='sports-box-container'>
                                <div className='brand-page'>
                                    <LeftSubMenu />
                                    <div className='sports-list-box'>
                                        <span className='item-title'>{t('sport:sport.mobile.title', 'CHOOSE YOUR FAVORITE PROVIDERS!')}</span>
                                        <ul className='ani-ul'>
                                            {this.state.list.map((sportsObj, i) => (
                                                <li
                                                    key={sportsObj.key}
                                                    className={`${sportsObj.isDisableProvider ? 'provider-disable' : ''}`}
                                                >
                                                    <LazyLoadImage
                                                        src={'/public/html/images/sports/mobile/' + sportsObj.key + '.png'}
                                                        className={sportsObj.maintenance ? 'maintenance-cover' : ''}
                                                        alt=''
                                                    />
                                                    {sportsObj.isHotProvider && (
                                                        <div className='hoticon-sports-container'>
                                                            <LazyLoadImage src='/public/html/images/hoticon_mobile.svg' alt=''></LazyLoadImage>
                                                        </div>
                                                    )}
                                                    {sportsObj.isNewProvider && (
                                                        <div className='newicon-sports-container'>
                                                            <LazyLoadImage src='/public/html/images/newicon_mobile.svg' alt=''></LazyLoadImage>
                                                        </div>
                                                    )}
                                                    <div className='button-container'>
                                                        <button className={`playnow-btn ${this.props && (this.props.language.key === 'ms' || this.props.language.key === 'vi') ? 'playnow-btn-ms' : ''}`} onClick={() => {
                                                            this.checkMaintenance(sportsObj);
                                                        }}>
                                                            {t('mobile:mobile.playNow')}
                                                        </button>
                                                        <button className='dekstop-btn' onClick={() => {
                                                            this.checkMaintenance(sportsObj, true);
                                                        }}>
                                                            {t('mobile:mobile.desktop', 'DESKTOP')}
                                                        </button>
                                                    </div>
                                                    {/* <p className={sportsObj.maintenance ? 'maintenance-cover' : ''}>{t(sportsObj.mobileText)}</p> */}
                                                    {sportsObj.maintenance &&
                                                        <div className='maintenance-hover-box'></div>
                                                    }
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['sport', 'mobile'])(withRouter(Sports)));
