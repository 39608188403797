const controller = {
    vm: null,

    init(vm) {
        controller.vm = vm;
        controller.vm.state = {};
    },
};

export default controller;
