import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import SmallLoading from 'root/components/smallLoading';

import vm from '../terms.controller';

class Terms extends React.Component {
    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        setTimeout(() => {
            vm.getTerms();
        }, 500);
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        return (
            <Translation>
                {(t) => (
                    <div className='terms-panel'>
                        <div className='container'>
                            {this.state.isLoading ? (
                                <div className='loading-box'>
                                    <SmallLoading></SmallLoading>
                                </div>
                            ) : (
                                <Fragment>
                                    <div className='panel-title'>{t('global:footer.terms-and-condition', 'Terms & Conditions')}</div>

                                    <div className='panel-content' onClick={vm.getCollapseItem} dangerouslySetInnerHTML={{ __html: this.state.termsContent }}></div>
                                </Fragment>
                            )}
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation('global')(withRouter(Terms)));
