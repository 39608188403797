// react/library
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation, Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import { AiOutlineCopy } from 'react-icons/ai';

// components/files
import { LazyLoadImage } from 'react-lazy-load-image-component';
import AddBankDialog from '../../../../../add-bank-dialog/component/add-bank-dialog_96c';
import TransactionNote from 'root/webapp/shared/transaction-note/transaction-note.selector';
import ImageHelper from 'root/webapp/shared/image-helper/image-helper';

// controller/logic
import vm from '../../bank-apps.controller';
import core from 'root/utils/core';
import transactionHelper from 'root/utils/transaction-helper';
import { popupAction } from '../../../../../../../../redux/action';
import { gu_loadCustomTranslation } from 'root/utils/general-util';
import { trans_getDepositAmountOptionByMethod } from 'root/utils/transaction-util';
import { AMOUNT_DEPOSIT_METHOD_CODE } from 'root/utils/constants';
import BankIcon from '../../../../../../../../components/molecules/bank-icon/bank-icon';
class BankTransfer extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
    }

    componentDidMount() {
        if (this.state.depositAllSettings !== null) {
            vm.initCompSettings();
            vm.getMerchantAndMemberBanks();
            // vm.loadMerchantBank();
            // transactionHelper.loadMemberBankAccounts(this);
            // vm.getCorrectDepositMinMaxLimit();
            vm.loadBankTransferDropdownOption();
            gu_loadCustomTranslation('bank-apps', this);
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    componentDidUpdate(prevProps) {
        const prevDepositAmountOptions = JSON.stringify(trans_getDepositAmountOptionByMethod(prevProps.depositAmountOptions, AMOUNT_DEPOSIT_METHOD_CODE.BA));
        const currentDepositAmountOptions = JSON.stringify(this.state.depositAmountOptions);
        if (prevDepositAmountOptions !== currentDepositAmountOptions) {
            this.setState({ depositAmountOptions: trans_getDepositAmountOptionByMethod(this.props.depositAmountOptions, AMOUNT_DEPOSIT_METHOD_CODE.BA) });
        }
    }

    render() {
        const {
            bankList,
            selectedBankIndex,
            depositAmountOptions,
            depositMinMaxLimit,
            depositOptions,
            depositAllSettings,
            isFormValid,
            disableDepositButton,
            unOwnedBankList,
            showAmountField,
            popupCollection,
            customTranslationObj,
        } = this.state;

        let addBankDialogProps = {
            addBankSuccess: vm.addBankSuccess,
            unOwnedBankList: unOwnedBankList,
        };

        let userCurrency = this.props.user && this.props.user.account && this.props.user.account.currency ? this.props.user.account.currency : null;

        if (userCurrency === 'KHUSD') userCurrency = 'USD';

        let transactionNoteProps = {
            customFilename: this.state.customFilename,
            hideDisplayInfo: this.state.compSettings.hideDisplayInfo,
            depositMinMaxLimit: this.state.depositMinMaxLimit,
            showCustomArticle: this.state.showCustomDepositArticle,
            customArticleContent: this.state.showCustomDepositArticleContent,
            customArticleIsJson: this.state.customArticleIsJson,
            customContentStyle: this.state.customContentStyle,
            hideDefaultNote: [!this.state.showDefaultArticle, true, true],
            transactionMethodName: this.props.t('transaction:transaction.deposit.cashdeposit.banktransfer', 'Bank Transfer'),
            customTotalAllowTitle: this.props.t('transaction:transaction.deposit.cashdeposit.totalallowed2', 'Total Allow'),
            customMinMaxTitle: this.props.t('transaction:transaction.deposit.cashdeposit.min/maxlimit2', 'Min / Max'),
        };

        return (
            <Translation>
                {(t) => (
                    <Fragment>
                        {(this.state.addBankDialogIsShow || (popupCollection && popupCollection['add-bank'])) && (
                            <AddBankDialog
                                {...addBankDialogProps}
                                closeFunction={() => {
                                    vm.togglePopup('add-bank');
                                    this.props.dispatch(popupAction.setPopupVisible(false));
                                }}
                            />
                        )}

                        <div className='group-item bank-options'>
                            <div className='title'>
                                <span>{t('transaction:transaction.deposit.onlinetransfer.bank', 'Bank Options')}</span>
                                <span className='required'>*</span>
                            </div>
                            <div className='bank-list'>
                                <ul>
                                    {bankList.map((bank, i) => (
                                        <li
                                            key={i}
                                            className={[
                                                'icon-hover-box',
                                                this.state.selectedBankIndex === i ? 'on' : '',
                                                bank.code.toLowerCase() === 'ezpqr' || bank.code.toLowerCase() === 'h2pp' ? 'qrbank-item' : '',
                                            ].join(' ')}
                                            onClick={() => {
                                                vm.onBankChanged(bank);
                                            }}
                                            onMouseEnter={() => {
                                                this.setState({ hoveredIndex: i });
                                            }}
                                            onMouseLeave={() => {
                                                this.setState({ hoveredIndex: null });
                                            }}
                                        >
                                            <span className={`icon-box ${bank.code.toLowerCase() === 'ezpqr' || bank.code.toLowerCase() === 'h2pp' ? 'qrbank-icon-box' : ''}`}>
                                                <i
                                                    className={[
                                                        'icon-bank',
                                                        this.state.selectedBankIndex === i || (this.state.hoveredIndex !== null && this.state.hoveredIndex === i)
                                                            ? 'icon-bank-on'
                                                            : 'icon-bank-off',
                                                        bank.code.toLowerCase() === 'ezpqr' || bank.code.toLowerCase() === 'h2pp' ? 'qrbank-icon-bank' : '',
                                                    ].join(' ')}
                                                >
                                                    <div>
                                                        <BankIcon bankCode={bank.code} />
                                                    </div>
                                                </i>
                                            </span>
                                            <span>{bank.name}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        {bankList?.length > 0 && bankList[selectedBankIndex].image && (
                            <div className='group-item bankApp-QR'>
                                <div className='bank-account-container'>
                                    <ImageHelper displayImg={bankList[selectedBankIndex].image} className={'qr-img-bankApp'} />
                                </div>
                            </div>
                        )}

                        {bankList?.length > 0 && (
                            <div className='group-item input-item'>
                                <div className='title'>
                                    <span>
                                        {customTranslationObj?.bankAccount
                                            ? t(customTranslationObj?.bankAccount)
                                            : t('transaction:transaction.deposit.cashdeposit.bankaccount', 'Bank Account')}
                                    </span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='bank-account-container'>
                                    <div className='input-box'>
                                        <input
                                            id='backaccount'
                                            type='text'
                                            autoComplete='off'
                                            disabled={true}
                                            readOnly={true}
                                            value={bankList[selectedBankIndex].accountHolder}
                                        />
                                        <AiOutlineCopy onClick={() => core.copy(t, bankList[selectedBankIndex].accountHolder)}></AiOutlineCopy>
                                    </div>
                                    <div className='input-box'>
                                        <input
                                            id='backaccount'
                                            type='text'
                                            autoComplete='off'
                                            disabled={true}
                                            readOnly={true}
                                            value={bankList[selectedBankIndex].bankAppsPhone}
                                        />
                                        <AiOutlineCopy onClick={() => core.copy(t, bankList[selectedBankIndex].bankAppsPhone)}></AiOutlineCopy>
                                    </div>
                                </div>
                            </div>
                        )}

                        {showAmountField && (
                            <div className='group-item input-item'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.onlinetransfer.amount', 'Deposit Amount')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='amount-content'>
                                    <div className='input-box'>
                                        <input
                                            id='depositamount'
                                            type='numeric'
                                            autoComplete='off'
                                            placeholder={t('global:global.form.online-transfer-amount-placeholder', {
                                                min: window.SPL_Other.formatAmount(depositMinMaxLimit ? depositMinMaxLimit.min : 0),
                                                max: window.SPL_Other.formatAmount(depositMinMaxLimit ? depositMinMaxLimit.max : 0),
                                            })}
                                            min={'0'}
                                            value={this.state.fields['amount']}
                                            onChange={(e) => vm.handleAmountChange(e)}
                                            onBlur={() => {
                                                transactionHelper.handleValidation(this);
                                            }}
                                        />
                                        <div className='error-validation'>
                                            <Trans
                                                i18nKey={this.state.errors['amount']}
                                                values={{ amount: window.SPL_Other.formatAmount(this.state.amountErrorValue), currency: userCurrency }}
                                            ></Trans>
                                        </div>
                                    </div>
                                    <ul>
                                        {depositAmountOptions.map((item) => (
                                            <li
                                                key={item}
                                                onClick={() => {
                                                    transactionHelper.onDepositAmountClicked(this, item);
                                                }}
                                            >
                                                {item}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        )}

                        {this.state.depositAllSettings.showDepositChannel && (
                            <div className='group-item input-item'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.cashdeposit.channel', 'Deposit Channel')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='amount-content'>
                                    <div className='input-box'>
                                        <select value={this.state.selectedChannel} onChange={(e) => vm.changeChannel(e)}>
                                            {depositOptions.map((channel, index) => (
                                                <option key={index} value={index}>
                                                    {channel.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        )}

                        {depositAllSettings.showDepositChannel && (
                            <div className='group-item input-item'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.depositBankDetails', 'Bank Details')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='amount-content'>
                                    <div className='input-box'>
                                        <select value={this.state.selectedMemberBankIndex} onChange={(e) => vm.handleBankDetailChange(e)}>
                                            {this.state.memberBankList.map((bank, index) => (
                                                <option key={index} value={index}>
                                                    {bank.displayName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                {!depositAllSettings.hideAddBankAccount && (
                                    <button
                                        className='addBankButton'
                                        onClick={() => {
                                            vm.togglePopup('add-bank');
                                            this.props.dispatch(popupAction.setPopupVisible(true));
                                        }}
                                    >
                                        {t('transaction:transaction.deposit.addBank.add', 'ADD')}
                                    </button>
                                )}
                            </div>
                        )}

                        {this.state.receiptImg.length > 0 && (
                            <div className='group-item'>
                                <div className='title'></div>
                                <div className='amount-content'>
                                    <div className='input-box receipt-image-box'>
                                        <LazyLoadImage src={this.state.receiptImg} alt='' />
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className='group-item submit-container'>
                            <div className='title'></div>
                            <div className='amount-content'>
                                <div className='flexbox'>
                                    <button
                                        className='btn btn-submit'
                                        onClick={() => {
                                            vm.submitForm();
                                        }}
                                        disabled={!isFormValid || disableDepositButton || this.state.receiptRequired}
                                    >
                                        {t('transaction:transaction.deposit.deposithistory.submitbtn', 'SUBMIT').toUpperCase()}
                                    </button>
                                    {this.state.depositAllSettings.uploadReceipt && (
                                        <button
                                            className='btn btn-reset'
                                            onClick={() => {
                                                transactionHelper.handleUploadReceiptButtonOnClick();
                                            }}
                                        >
                                            {t('transaction:transaction.deposit.cashdeposit.upload', 'UPLOAD RECEIPT').toUpperCase()}
                                            <input
                                                id='hiddenFileInput'
                                                style={{ display: 'none' }}
                                                type='file'
                                                accept='image/*'
                                                onChange={(e) => transactionHelper.handleReceiptImageChange(e, this)}
                                            ></input>
                                        </button>
                                    )}
                                </div>
                                {this.state.receiptRequired && <div className='error-validation'>{t('transaction:transaction.deposit.receiptIsRequired')}</div>}
                            </div>
                        </div>

                        <TransactionNote {...transactionNoteProps} />
                    </Fragment>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'settings'])(withRouter(BankTransfer)));
