import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import { Translation, withTranslation } from 'react-i18next';

// component / file

// util / controller

class SelectDropdown extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
        this.state = {};
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        // global props
        // passed props
        const { className, optionLabel, svg, customStyle } = this.props;
        // passed props func
        const { onClickFunction } = this.props;

        return (
            <Translation>
                {(t) => (
                    <div
                        className={`standard-select-options ${className} ${svg ? 'include-svg' : ''}`}
                        onClick={onClickFunction && onClickFunction}
                        style={customStyle && customStyle}
                    >
                        <p>{optionLabel}</p>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps, null)(withTranslation()(withRouter(SelectDropdown)));
