import React, { lazy } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import core from 'root/utils/core';
import LazyComponent from 'root/webapp/shared/lazyComponent';

// lazy load by component because there will be many component in selector
// but only use 1 component at 1 time
const Password = lazy(() => import('./component/desktop/password'));

const MPassword = lazy(() => import('./component/mobile/m-password'));

class Settingspassword extends React.Component {
    components = {
        desktop: {
            default: LazyComponent(Password),
        },
        mobile: {
            default: LazyComponent(MPassword),
        },
    };

    render() {
        const TagName = core.getCorrectComponent(this.components, 'password', this.props.screen.viewType);
        return <TagName></TagName>;
    }
}

export default connect(mapStateToProps, null)(Settingspassword);
