import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import CockfightPanel from './CockfightPanel';

class Cockfight extends React.Component {
    render() {
        return <CockfightPanel></CockfightPanel>;
    }
}

export default connect(mapStateToProps)(withTranslation(['cockfight'])(withRouter(Cockfight)));
