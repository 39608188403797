import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';

import { portalAction } from 'root/redux/action';

import WebRegister from '../desktop/RegisterPanel';
import commonUtil from '../../../../utils/common-util';

class Register extends React.Component {
    render() {
        const { history } = this.props;
        return (
            <div className={`mobile-register ${commonUtil.getIsZT828LiteApp() ? 'mobile-login-liteapp' : ''}`}>
                <div className='bg-content-section'>
                    <WebRegister
                        onClose={() => {
                            this.props.dispatch(portalAction.floatingPageUpdated(''));
                        }}
                        toDeposit={() => {
                            history.replace('/funds/deposit');
                            this.props.dispatch(portalAction.floatingPageUpdated(''));
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, null)(withRouter(Register));
