import core from 'root/utils/core';
import { languageAction } from 'root/redux/action/language.action';

const controller = {
    vm: null,

    init(vm) {
        this.vm = vm;
        this.vm.state = {
            languageList: [],
        };
    },

    getLanguageList() {
        window.SPL_Content.getLanguageList().then((data) => {
            let currentLanguage = {},
                countryLanguageKey = controller.vm.props.language.countryLanguageKey;

            let user = controller.vm.props.user;
            let account = user.account;
            let memberCurrency = null;
            if (account) {
                memberCurrency = account.currency;
 

                if (memberCurrency) {
                    data = data.filter((list) => list.currency === memberCurrency);
                }
            }

            for (let i = 0; i < data.length; i++) {
                let languages = data[i].languages;

                for (let j = 0; j < languages.length; j++) {
                    if (languages[j].countryLanguageKey === countryLanguageKey) {
                        Object.assign(currentLanguage, languages[j]);
                    }
                }
            }

            controller.vm.setState({ languageList: data, currentLanguage: currentLanguage });
        });
    },

    changeLanguage(langObject) {
        core.setLanguageKeyAndCountryLanguage(this.vm, langObject.countryLanguageKey, languageAction, true, false, true);
    },
};

export default controller;
