import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import core from 'root/utils/core';

import BankTransfer1 from './component/desktop/bank-transfer';

import MobileBankTransfer1 from './component/mobile/bank-transfer';

class BankTransfer extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
    }

    components = {
        desktop: {
            default: BankTransfer1,
        },
        mobile: {
            default: MobileBankTransfer1,
        },
    };

    render() {
        const TagName = core.getCorrectComponent(this.components, 'bank-transfer', this.props.screen.viewType);
        return <TagName {...this.props} />;
    }
}

export default connect(mapStateToProps, null)(BankTransfer);

