import providerService from 'root/utils/providerService';
import { TweenMax } from 'gsap';
import notification from 'root/utils/notification';
import { portalAction } from 'root/redux/action';
import { pu_processGameDataWithFavGames } from '@utils/provider-util';

const controller = {
    vm: null,
    providerData: {
        SPB: { provider: 'SPB', key: 'spb', text: 'global:global.submenu.slots.spb', routeName: 'spb', underMaintenance: false },
        T1G: { provider: 'T1G', key: 't1g', text: 'global:global.submenu.slots.t1g', routeName: 't1g', underMaintenance: false },
        BB: { provider: 'BB', key: 'bb', text: 'global:global.submenu.slots.bb', routeName: 'bb', underMaintenance: false },
    },

    init(vm) {
        this.vm = vm;
        this.vm.state = {
            currentProvider: '',
            currentProviderKey: '',
            tabFilter: 'HTML5',
            keyword: '',
            brandActive: 0,
            typeActive: 0,
            typeList: [],
            brandList: [],
            gameList: [],
            showMore: false,
            favoritedGame: [],
            tween1: null,
            tween2: null,
            displayGames: [],
        };
    },

    getProviderList(tween1, tween2, selectProviderBasedOnUrl = false) {
        const { language } = controller.vm.props;
        let currencyLang = language.currencyLang;

        if (language.country === 'KH') currencyLang = 'KHUSD';

        let loadGetSubmenu = new Promise((resolve) => {
            window.SPL_Content.getSubmenu(language.countryLanguageKey, 'web', 'FG').then((data) => resolve(data));
        });

        let loadProviderGameMaintenanceList = new Promise((resolve) => {
            window.SPL_Provider.providerGameMaintenanceList(null, null, currencyLang).then((result) => resolve(result));
        });

        return new Promise((resolve) => {
            Promise.all([loadGetSubmenu, loadProviderGameMaintenanceList]).then((loadResult) => {
                let submenu = loadResult[0];
                let providerMaintenance = loadResult[1];

                let brandList = [];
                let currentProvider = '';
                let currentProviderKey = '';
                let brandActive = 0;

                if (window.location.pathname.indexOf('/fastgames') >= 0 && window.location.pathname.indexOf('/fastgames/') <= -1) {
                    selectProviderBasedOnUrl = false;
                }

                for (let i = 0; i < submenu.length; i++) {
                    let provider = submenu[i].provider;
                    const isHotProvider = submenu[i].isHotProvider;
                    const isNewProvider = submenu[i].isNewProvider;

                    let foundProvider = controller.providerData[provider];

                    if (providerMaintenance) {
                        for (let j = 0; j < providerMaintenance.length; j++) {
                            let maintenanceProvider = providerMaintenance[j].provider;
                            if (provider === maintenanceProvider) {
                                foundProvider.underMaintenance = providerMaintenance[j].gameMaintenance;
                            }
                        }
                    }

                    if (foundProvider) {
                        if (selectProviderBasedOnUrl === false) {
                            if (i === 0) {
                                currentProvider = foundProvider.provider;
                                currentProviderKey = foundProvider.key;
                                brandActive = i;
                            }
                        } else {
                            // Create a regular expression to match the whole word "drg"
                            var regex = new RegExp('\\b' + foundProvider.routeName + '\\b');

                            // test is used to check whether regular expression is matching correctly for each foundProvider.
                            if (regex.test(window.location.pathname)) {
                                currentProvider = foundProvider.provider;
                                currentProviderKey = foundProvider.key;
                                brandActive = i;
                            }
                        }

                        foundProvider.isHotProvider = isHotProvider;
                        foundProvider.isNewProvider = isNewProvider;

                        brandList.push(foundProvider);
                    }
                }

                controller.vm.setState(
                    {
                        brandList: brandList,
                        brandActive: brandActive,
                        currentProvider: currentProvider,
                        currentProviderKey: currentProviderKey,
                        tween1: tween1,
                        tween2: tween2,
                    },
                    () => {
                        resolve();
                    }
                );
            });
        });
    },

    getDefaultTab() {
        const { currentProvider } = controller.vm.state;
        // all WhiteLabel required set NS default tab as hot
        if (currentProvider === 'NS') {
            return 'HOT';
        } else {
            return 'HTML5';
        }
    },

    getMobileProviderList() {
        return new Promise(function (resolve) {
            const { language } = controller.vm.props;
            let currencyLang = language.currencyLang;

            if (language.country === 'KH') currencyLang = 'KHUSD';

            let loadGetSubmenu = new Promise((resolve) => {
                window.SPL_Content.getSubmenu(language.countryLanguageKey, 'mobile', 'FG').then((data) => resolve(data));
            });

            let loadProviderGameMaintenanceList = new Promise((resolve) => {
                window.SPL_Provider.providerGameMaintenanceList(null, null, currencyLang).then((result) => resolve(result));
            });

            Promise.all([loadGetSubmenu, loadProviderGameMaintenanceList]).then((loadResult) => {
                let submenu = loadResult[0];
                let providerMaintenance = loadResult[1];

                let brandList = [];

                for (let i = 0; i < submenu.length; i++) {
                    let provider = submenu[i].provider;
                    const isHotProvider = submenu[i].isHotProvider;
                    const isNewProvider = submenu[i].isNewProvider;

                    let foundProvider = controller.providerData[provider];

                    if (providerMaintenance) {
                        for (let j = 0; j < providerMaintenance.length; j++) {
                            let maintenanceProvider = providerMaintenance[j].provider;
                            if (provider === maintenanceProvider) {
                                foundProvider.underMaintenance = providerMaintenance[j].gameMaintenance;
                            }
                        }
                    }

                    if (foundProvider) {
                        foundProvider.isHotProvider = isHotProvider;
                        foundProvider.isNewProvider = isNewProvider;

                        brandList.push(foundProvider);
                    }
                }

                controller.vm.setState({ brandList: brandList });
                resolve();
            });
        });
    },

    handleKeywordChanged(e) {
        controller.vm.setState({ keyword: e.target.value }, () => {
            const { currentProvider, tabFilter, keyword } = controller.vm.state;
            controller.getProviderGameList(currentProvider, tabFilter, keyword);
        });
    },

    checkUnderMaintenance(providerObj, underMaintenance, i) {
        if (!underMaintenance) {
            // controller.resetProviderFilter();
            controller.updateSelectedProvider(providerObj, underMaintenance);
            // vm.getProviderGameList(providerObj.provider);
            controller.toggleTab(i, 'brand');
        }
    },

    toggleTab(index, type = 'type') {
        if (type === 'type' && index === controller.vm.state.typeActive) return;
        if (type === 'type') {
            controller.vm.setState({ typeActive: index });
        } else {
            controller.vm.setState({ brandActive: index });
        }
    },

    updateSelectedProvider(providerObj) {
        controller.vm.props.history.push('/fastgames/' + providerObj.routeName);
        controller.vm.setState({ currentProvider: providerObj.provider, currentProviderKey: providerObj.key, showMore: false }, () => {
            const { currentProvider, tabFilter, keyword } = controller.vm.state;
            controller.getProviderGameList(currentProvider, tabFilter, keyword);
        });
    },

    updateTabFilter(type) {
        controller.vm.setState({ tabFilter: type.toUpperCase() }, () => {
            const { currentProvider, tabFilter, keyword } = controller.vm.state;
            controller.getProviderGameList(currentProvider, tabFilter, keyword);
        });
    },

    resetProviderFilter() {
        controller.vm.setState({
            tabFilter: 'HTML5',
            // keyword: '',
            typeActive: 0,
            typeList: [],
            gameList: [],
        });
    },

    getProviderGameList(provider, tabFilter = 'HTML5', keyword = '', tween1, tween2) {
        const { language } = controller.vm.props;
        const providerCategory = ['All', 'Hot', 'New'];
        let passProvider = provider;

        controller.vm.setState({ gameList: [] });

        return new Promise(async (resolve) => {
            let param = {
                category: 'FAST', //ONLY FOR FASTGAME
                merchantCode: window?.location?.hostname,
                currencyCode: language?.currencyLang,
                isTemplate: false,
                provider: passProvider,
            };

            let data = await window.SPL_Provider.getMerchantGamesFunc(param);

            for (let i = 0; i < data.length; i++) {
                if (data[i].customImage) {
                    data[i].imggame = data[i].thumbnail;
                } else {
                    data[i].imggame = `/public/html/games/images/s1/${provider}/${data[i].game}.jpg`;
                }
                data[i].text = language.key === 'zh-CN' ? data[i].nameCN : data[i].name;
            }
            let displayGame = [];

            if (!controller.vm.state.showMore && data?.length > 25) {
                for (let i = 0; i < 25; i++) {
                    displayGame.push(data?.displayGames[i]);
                }
            } else {
                displayGame = data;
                controller.vm.setState({ showMore: true });
            }

            let brandList = controller.vm.state.brandList;
            if (brandList) {
                for (let j = 0; j < brandList.length; j++) {
                    if (provider === brandList[j].provider) {
                        displayGame.underMaintenance = brandList[j]?.underMaintenance;
                    }
                }
            }

            controller.vm.setState({ typeList: providerCategory, loading: false }, () => {
                controller.getMemberFavoriteGame(displayGame, tween1, tween2);
                resolve();
            });
        });
    },

    getMemberFavoriteGame(gameList, tween1, tween2) {
        const { language } = controller.vm.props;

        for (let i = 0; i < gameList.length; i++) {
            gameList[i].isFav = false;

            //massage gamelist name
            if (gameList[i].i18n && language.key !== 'zh-CN') {
                let gameName = gameList[i].name;
                try {
                    gameName = JSON.parse(gameList[i].i18n);
                    if (gameName[language.key.toUpperCase()]) {
                        gameList[i].name = gameName[language.key.toUpperCase()];
                    }
                } catch (e) {}
            } else {
                if (language.key === 'zh-CN') {
                    gameList[i].name = gameList[i].nameCN;
                }
            }
        }
        const _favoriteGames = controller.vm.props.providerReducer?.processedData?.favoriteGames || [];
        const newGameList = pu_processGameDataWithFavGames(gameList, _favoriteGames);

        controller.vm.setState({ gameList: newGameList }, () => {
            controller.doSuspendAni(tween1, tween2);
        });
    },

    updateMemberFavoriteGame(game) {
        controller.vm.setState({ loading: true }, () => {
            const { screen } = controller.vm.props;
            const { isLogin } = controller.vm.props.user;
            const { favoritedGame, tween1, tween2 } = controller.vm.state;
            let isMobile = null;

            if (screen.viewType === 'web') {
                isMobile = false;
            } else {
                isMobile = true;
            }

            if (isLogin) {
                let delelteGame = false;

                for (let i = 0; i < favoritedGame.length; i++) {
                    if (favoritedGame[i].id === game.id) {
                        delelteGame = true;
                    }
                }

                if (delelteGame === true) {
                    window.SPL_Member.deleteMemberFavoriteGame(game, isMobile).then((data) => {
                        controller.getProviderGameList(game.provider, undefined, undefined, tween1, tween2);
                    });
                } else {
                    window.SPL_Member.updateMemberFavoriteGame(game, isMobile).then((data) => {
                        controller.getProviderGameList(game.provider, undefined, undefined, tween1, tween2);
                    });
                }
            } else {
                if (isMobile) {
                    controller.vm.props.dispatch(portalAction.floatingPageUpdated('login'));
                } else {
                    notification.showNotification('error', controller.vm.props.t('home:home.loginText'));
                }

                controller.vm.setState({ loading: false });
            }
        });
    },

    // animation effect
    doSuspendAni(tween1, tween2) {
        tween1 && tween1.kill();
        tween2 && tween2.kill();

        TweenMax.set('ul.game-content li', {
            alpha: 0,
        });

        tween1 = TweenMax.delayedCall(0.3, function () {
            tween2 = TweenMax.staggerTo('ul.game-content li', 0.3, { alpha: 1 }, 0.08);
        });
    },

    showMoreGames() {
        controller.vm.setState({ showMore: true }, () => {
            const { currentProvider, tabFilter } = controller.vm.state;
            controller.getProviderGameList(currentProvider, tabFilter);
        });
    },

    launchSlotGame(gameObj, isFun) {
        const { language, screen, user } = controller.vm.props;
        let params = {
            domain: window.location.hostname,
            isMobile: screen.isMobile,
            isFun: isFun,
            isMenuMode: !isFun,
            isMiniGame: false,
            languageKey: language.key,
        };

        if (gameObj) {
            gameObj.category = 'SL';
        }

        if (controller.vm.state.provider === 'UL' || gameObj.provider === 'UL') {
            params.gameCode = 'S-UL01';
            params.provider = 'UL';
        } else {
            params.gameCode = gameObj.game;
            params.provider = gameObj.provider;
        }

        providerService.launchGame(gameObj, language, user.isLogin, screen, isFun, params, controller.vm);
    },
};

export default controller;
