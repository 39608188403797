import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import core from 'root/utils/core';

import RegionQR1 from './component/desktop/region-qr';

import MobileRegionQR1 from './component/mobile/region-qr';

class RegionQR extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
    }

    components = {
        desktop: {
            default: RegionQR1,
        },
        mobile: {
            default: MobileRegionQR1,
        },
    };

    render() {
        const TagName = core.getCorrectComponent(this.components, 'region-qr', this.props.screen.viewType);
        return <TagName {...this.props} />;
    }
}

export default connect(mapStateToProps, null)(RegionQR);
