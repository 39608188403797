import React, { Component } from 'react';
import { Translation, withTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import vm from '../promotion-details-dialog.controller';
import { MdClose } from 'react-icons/md';

class PromotionDetailsDialog extends Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
    }

    componentWillMount() {
        Modal.setAppElement('#root');
    }

    componentDidMount() {}

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    promoDetailsDisplay = () => {
        const { promodetails } = this.props;
        return Object.keys(promodetails).map((key) => (
            <div>
                {key} {promodetails[key]}
            </div>
        ));
    };

    render() {
        return (
            <Translation>
                {(t) => (
                    <Modal
                        isOpen={this.props.promotionDetailsDialogIsShow}
                        shouldCloseOnOverlayClick={true}
                        shouldCloseOnEsc={true}
                        onRequestClose={() => this.props.closePromotionDetailsDialog()}
                        overlayClassName='custom-modal-overlay'
                        className='promos-detail-popup'
                    >
                        <div className='popup-header'>
                            <span>{this.props.promodetails.promodetailsTitle}</span>
                             <MdClose className='close-btn' onClick={() => this.props.closePromotionDetailsDialog()} />
                        </div>
                        <div className='popup-body'>
                            <table className='promo-info'>
                                <tbody>
                                    {Object.keys(this.props.promodetails).map((key) => {
                                        if (key === 'targetType') {
                                            return (
                                                <tr key={key}>
                                                    <td>{t(`transaction:transaction.transfer.promoDetail.${this.props.promodetails.targetType}`)}</td> : 
                                                    <td>{this.props.promodetails.targetMultiplier}</td>
                                                </tr>
                                            );
                                        } else if (key !== 'targetType' && key !== 'targetMultiplier' && key !== 'promodetailsTitle') {
                                            return (
                                                <tr key={key}>
                                                    <td>{t(`transaction:transaction.transfer.promoDetail.${key}`)}</td> : 
                                                    <td>{this.props.promodetails[key]}</td>
                                                </tr>
                                            );
                                        }
                                    })}
                                </tbody>
                            </table>
                            <div className='terms'>* {t('transaction:transaction.transfer.promoDetail.tnc')}</div>
                        </div>
                    </Modal>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation('')(PromotionDetailsDialog));
