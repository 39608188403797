import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import SmallLoading from 'root/webapp/shared/loading/smallLoading';
import SVGTransfer from 'root/components/svgIcon/svgTransfer';

class TransferSelect extends React.Component {
    constructor() {
        super();
        this.state = {
            typeIndex: 0,
            typeItemIndex: 0,
            itemIndex: -1,
        };
    }

    onChange = (item, index) => {
        const { onChange } = this.props;
        setTimeout(() => {
            onChange(item, index);
        }, 1);
        this.setState((state) => ({
            ...state,
            itemIndex: index,
        }));
        this.props.onClose && this.props.onClose();
    };

    render() {
        const { type, parentState, updateProviderIndex, updateProviderItemIndex, t } = this.props;
        const {
            transferSelectIndexFrom,
            transferSelectItemIndexFrom,
            providerList,
            fromProvider,
            toProvider,
            categorizedProviderListByMoreType,
            transferSelectIndexTo,
            transferSelectItemIndexTo,
        } = parentState;
        let { typeIndex, typeItemIndex } = this.state;

        if (type === 'from') {
            if (transferSelectIndexFrom > -1) {
                typeIndex = transferSelectIndexFrom;
                typeItemIndex = transferSelectItemIndexFrom;
            } else {
                let provider = providerList[fromProvider];
                let defaultIndex = categorizedProviderListByMoreType.findIndex((p) => t(p.title) === provider.display);

                typeIndex = defaultIndex;
            }
        } else {
            if (transferSelectIndexTo > -1) {
                typeIndex = transferSelectIndexTo;
                typeItemIndex = transferSelectItemIndexTo;
            } else {
                let provider = providerList[toProvider];
                let defaultIndex = categorizedProviderListByMoreType.findIndex((p) => t(p.title) === provider.display);

                typeIndex = defaultIndex;
            }
        }

        return (
            <div className='transfer-select'>
                <div className='type-list'>
                    <ul>
                        {categorizedProviderListByMoreType.length > 0 ? (
                            categorizedProviderListByMoreType.map(
                                ({ name, title, key, list }, i) =>
                                    list.length > 0 && (
                                        <li
                                            key={name}
                                            className={[i === typeIndex ? 'on' : ''].join(' ')}
                                            onClick={() => {
                                                updateProviderIndex(i, type);
                                            }}
                                        >
                                            <span className='icon-box'>
                                                <SVGTransfer name={key} className={`${key}-svg`} color='#ff4d00' />
                                            </span>
                                            <span>{t(title)}</span>
                                        </li>
                                    )
                            )
                        ) : (
                            <SmallLoading></SmallLoading>
                        )}
                    </ul>
                </div>

                <TransitionGroup className='item-list'>
                    <CSSTransition timeout={0} classNames='fade-prev' key={typeIndex}>
                        <ul>
                            {categorizedProviderListByMoreType.length > 0 && categorizedProviderListByMoreType[typeIndex]?.list?.length > 0 ? (
                                categorizedProviderListByMoreType[typeIndex].list.map((item, i) => (
                                    <li
                                        key={item.display}
                                        className={[i === typeItemIndex ? 'on' : ''].join(' ')}
                                        onClick={() => {
                                            this.onChange(item, i);
                                            updateProviderItemIndex(i, type);
                                        }}
                                    >
                                        <span>{item.display === 's-Sports' ? 'SBOBet' : item.display === 'Shi Cai Lottery' ? 'Shi Cai' : item.display}</span>
                                    </li>
                                ))
                            ) : (
                                <SmallLoading></SmallLoading>
                            )}
                        </ul>
                    </CSSTransition>
                </TransitionGroup>
            </div>
        );
    }
}

export default connect(mapStateToProps, null)(withTranslation(['settings'])(withRouter(TransferSelect)));
