import moment from 'moment';
import core from 'root/utils/core';

const controller = {
    vm: null,
    transType: 'depositWithdraw',

    init(vm) {
        let currentDate = new Date();
        let startDate = new Date(currentDate.getTime() - 6 * 24 * 3600000);
        let endDate = currentDate;

        this.vm = vm;
        this.vm.state = {
            isLoading: false,
            histories: [],

            sorting: {
                createdDate: 'desc',
            },
            currentSort: 'createdDate,desc',

            fields: {
                startDate: startDate,
                endDate: endDate,
                selectedTransactionIndex: 0,
                selectedStatusIndex: 0,
            },

            transTypeList: [
                { value: '', desc: 'transaction:transaction.history.filter.all' },
                { value: 'D', desc: 'transaction:transaction.trans_type.deposit' },
                { value: 'W', desc: 'transaction:transaction.trans_type.withdrawal' },
            ],
            statusList: [
                { value: '', desc: 'transaction:transaction.history.filter.all' },
                { value: 'P', desc: 'transaction:transaction.status.pending' },
                { value: 'A', desc: 'transaction:transaction.status.accepted' },
                { value: 'R', desc: 'transaction:transaction.status.rejected' },
                { value: 'I', desc: 'transaction:transaction.status.inProgress' },
            ],

            currentPage: 1,
            itemPerPage: 5,
            totalCount: 0,
            totalPage: 0,
            pagingList: [],
            searchDialogIsShow: false,
            historyDetailDialogIsShow: false,
            displayList: [],
            date: {
                active: 2,
                list: [
                    'transaction:transaction.history.filter.today',
                    'transaction:transaction.history.filter.3days',
                    'transaction:transaction.history.filter.aweek',
                    'transaction:transaction.history.filter.amonth',
                ],
            },
        };
    },

    handleDateChanged(date, isStartDate, vm) {
        controller.vm = vm;
        let fields = controller.vm.state.fields;
        if (isStartDate) {
            fields.startDate = date;
        } else {
            fields.endDate = date;
        }
        controller.vm.setState({ fields });
    },

    handleFieldChanged(e, field) {
        let fields = controller.vm.state.fields;
        fields[field] = e.target.value;
        controller.vm.setState({ fields });
    },

    handlePrevNextClick(isPrev, firstLast) {
        const { currentPage, totalPage } = controller.vm.state;

        if (firstLast) {
            if (firstLast === 'first') {
                controller.handlePageClick(1);
            } else {
                controller.handlePageClick(totalPage);
            }
        } else {
            if (isPrev) {
                controller.handlePageClick(currentPage - 1);
            } else {
                controller.handlePageClick(currentPage + 1);
            }
        }
    },

    handlePageClick(newPageNum) {
        const { currentPage, totalPage } = controller.vm.state;
        if (currentPage === newPageNum || newPageNum === 0 || newPageNum > totalPage) {
            // do nothing
        } else {
            controller.vm.setState({ currentPage: newPageNum }, () => {
                controller.searchHistory(newPageNum);
            });
        }
    },

    sortHistory(key) {
        let sorting = controller.vm.state.sorting;
        let found = sorting[key];
        if (found) {
            if (found === 'desc') {
                sorting[key] = 'asc';
            } else {
                sorting[key] = 'desc';
            }
        } else {
            sorting[key] = 'desc';
        }
        let sortParam = key + ',' + sorting[key];
        controller.vm.setState({ sorting, currentSort: sortParam }, () => {
            controller.searchHistory(controller.vm.state.currentPage);
        });
    },

    searchHistory(pageNum, vm) {
        if (vm) {
            controller.vm = vm;
        }
        core.portalIsLoading(controller.vm.props, true);

        controller.vm.setState({ isLoading: true });
        const { fields, itemPerPage, currentSort, transTypeList, statusList } = controller.vm.state;
        const { language } = controller.vm.props;
        let transactionTypeObj = transTypeList[fields.selectedTransactionIndex];
        let statusObj = statusList[fields.selectedStatusIndex];

        let params = {
            start: new Date(new Date(fields.startDate).setHours(0, 0, 0)),
            end: new Date(new Date(fields.endDate).setHours(23, 59, 59)),
            type: transactionTypeObj.value,
            status: statusObj.value,
            page: pageNum,
            size: itemPerPage,
            lang: language.key,
            sort: currentSort,
        };

        window.SPL_Transaction.loadHistories(controller.transType, params)
            .then((data) => {
                let totalCount = data.headers['x-total-count'];
                let totalPage = Math.ceil(totalCount / itemPerPage);
                let histories = data.histories;
                controller.vm.setState(
                    {
                        isLoading: false,
                        histories: histories,
                        totalCount: totalCount,
                        totalPage: totalPage,
                    },
                    () => {
                        controller.handlePagination();
                    }
                );
                core.portalIsLoading(controller.vm.props, false);
            })
            .catch((err) => {
                core.portalIsLoading(controller.vm.props, false);
            });
    },

    handlePagination() {
        const { currentPage, totalPage } = controller.vm.state;
        let pagingList = [];

        for (let i = 0; i < totalPage; i++) {
            let active = false;
            if (i + 1 === currentPage) {
                active = true;
            }

            let pageObj = {
                pageNum: i + 1,
                active: active,
            };
            pagingList.push(pageObj);
        }
        controller.vm.setState({ pagingList: pagingList });
    },

    openSearchDialog() {
        controller.vm.setState({ searchDialogIsShow: true });
    },

    closeSearchDialog(vm, submitted, fields, selectedTypeId) {
        vm.setState(
            {
                searchDialogIsShow: false,
            },
            () => {
                if (submitted && selectedTypeId === controller.transType) {
                    vm.setState({ fields: fields }, () => {
                        controller.searchHistory(0);
                    });
                } else if (submitted) {
                    let path = '/myaccount/history/' + selectedTypeId;
                    if (selectedTypeId === 'transfer') {
                        path = '/myaccount/history';
                    }
                    controller.vm.props.history.push(path, fields);
                }
            }
        );
    },

    handleSearchParamFromOtherHistoryTab(fields) {
        controller.vm.setState({ fields }, () => {
            controller.searchHistory(0);
        });
    },

    openHistoryDetailDialog(historyObj) {
        const { t } = controller.vm.props;
        let displayList = [
            {
                key: t('history:history.method'),
                value: t(historyObj.method),
            },
            {
                key: t('history:history.id'),
                value: historyObj.id,
            },
            {
                key: t('history:history.amount'),
                value: window.SPL_Other.formatAmount(historyObj.amount),
            },
            {
                key: t('transaction:transaction.deposit.deposithistory.status'),
                value: t(historyObj.status),
            },
            {
                key: t('history:history.datatime'),
                value: moment(historyObj.createdDate).format('YYYY-MM-DD HH:mm').toString(),
            },
        ];
        controller.vm.setState({ historyDetailDialogIsShow: true, displayList: displayList });
    },

    closeHistoryDetailDialog(vm) {
        vm.setState({ historyDetailDialogIsShow: false, displayList: [] });
    },

    handleDateRange(type, vm) {
        controller.vm = vm;
        let fields = controller.vm.state.fields;
        let currentDate = new Date();

        switch (type) {
            case 0:
                fields.startDate = new Date(currentDate.getTime());
                break;
            case 1:
                fields.startDate = new Date(currentDate.getTime() - 2 * 24 * 3600000);
                break;
            case 2:
                fields.startDate = new Date(currentDate.getTime() - 6 * 24 * 3600000);
                break;
            case 3:
                fields.startDate = new Date(currentDate.getTime() - 30 * 24 * 3600000);
                break;
            default:
                fields.startDate = controller.vm.state.fields.startDate;
                break;
        }
        controller.vm.setState({ fields });
    },

    translateEnum(type, status) {
        if (type === 'transType') {
            if (status === 'D') {
                return 'transaction:transaction.trans_type.deposit';
            } else {
                return 'transaction:transaction.trans_type.withdrawal';
            }
        } else if (type === 'method') {
            if (status === 'T') {
                return 'transaction:transaction.payment_type.transfer';
            } else if (status === 'O') {
                return 'transaction:transaction.payment_type.online_banking';
            } else if (status === 'C') {
                return 'transaction:transaction.payment_type.counter_deposit';
            } else if (status === 'A') {
                return 'transaction:transaction.payment_type.atm';
            } else if (status === 'SE') {
                return 'transaction:transaction.payment_type.sevenEleven';
            } else if (status === 'BA') {
                return 'transaction:transaction.payment_type.bankApps';
            } else {
                return 'transaction:transaction.payment_type.reload';
            }
        } else if (type === 'status') {
            if (status === 'A') {
                return 'transaction:transaction.status.accepted';
            } else if (status === 'R') {
                return 'transaction:transaction.status.rejected';
            } else if (status === 'I') {
                return 'transaction:transaction.status.inProgress';
            } else {
                return 'transaction:transaction.status.pending';
            }
        }
    },
};

export default controller;
