import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import Pagination from 'root/webapp/shared/pagination/pagination.selector';
import { FaRegCalendar } from 'react-icons/fa';

import SearchHistoryDialog from './searchModal';
import BonusDetailModal from './mybonus-details';

class Referral extends React.Component {
    constructor(props) {
        super();
        this.props = props;
    }

    componentDidMount() {}

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        let searchBonusModalProps = {
            parentState: this.props,
            showSearchModal: this.props.showSearchModal,
            toggleModal: this.props.toggleModal,
            handleDateChanged: this.props.handleDateChanged,
            searchHistory: this.props.searchHistory,
        };

        let BonusDetailModalProps = {
            parentState: this.props,
            displayList: this.props.displayList,
            toggleModal: this.props.toggleModal,
        };

        return (
            <Translation>
                {(t) => (
                    <Fragment>
                        {this.props.showSearchModal && <SearchHistoryDialog {...searchBonusModalProps}></SearchHistoryDialog>}
                        {this.props.showDetailModal && <BonusDetailModal {...BonusDetailModalProps}></BonusDetailModal>}

                        <div className='m-bonus-container'>
                            <button className='header-button-calendar' onClick={() => this.props.toggleModal('search')}>
                                <FaRegCalendar></FaRegCalendar>
                            </button>

                            {this.props.histories.length === 0 ? (
                                <div className='emptyHistoryContainer dedede'>{t('transaction:transaction.emptyHistory')}</div>
                            ) : (
                                <Fragment>
                                    <div className='m-bonus-table'>
                                        <div className='m-bonus-row-header'>
                                            <div className='m-bonus-column'>
                                                <span>{t('settings:settings.memberLogin', 'Member Login')}</span>
                                            </div>
                                            <div className='m-bonus-column'>
                                                <span>{t('transaction:transaction.history.provider', 'Provider')}</span>
                                            </div>
                                            <div className='m-bonus-column'>
                                                <span>{t('settings:settings.totalEligibleTurnOver', 'Total Eligible Turn Over')}</span>
                                            </div>
                                            <div className='sorting-column'>
                                                <span>{t('settings:settings.totalWinLoss', 'Total Win Loss')}</span>
                                            </div>
                                            <div className='m-bonus-column'>
                                                <span>{t('settings:settings.status', 'Status')}</span>
                                            </div>
                                        </div>

                                        {this.props.histories.map((history, index) => {
                                            return (
                                                <div key={index} className='m-bonus-row' onClick={() => this.props.openBonusDetailDialog(history)}>
                                                    <div className='m-bonus-column'>{history.memberLogin}</div>
                                                    <div className='m-bonus-column'>{history.provider}</div>
                                                    <div className='m-bonus-column'>{history.totalValidTurnOver - history.totalPromoTurnOver}</div>
                                                    <div className='m-bonus-column'>{history.totalWinLoss}</div>
                                                    <div className='m-bonus-column'>{history.status}</div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <Pagination
                                        pagingList={this.props.pagingList}
                                        handlePrevNextClick={this.props.handlePrevNextClick}
                                        handlePageClick={this.props.handlePageClick}
                                    ></Pagination>
                                </Fragment>
                            )}
                        </div>
                    </Fragment>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'referral', 'settings'])(withRouter(Referral)));
