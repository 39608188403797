import moment from 'moment';

const controller = {
    vm: null,

    init(vm) {
        let currentDate = new Date();
        let startDate = new Date(currentDate.getTime() - 6 * 24 * 3600000);
        let endDate = currentDate;

        this.vm = vm;
        this.vm.state = {
            method: 'rebate',
            provider: [],
            pendingRebate: [],
            pendingRebateTransaction: [],
            lastRebateDate: '-',
            lastClaimDate: '-',
            isLoading: false,
            histories: [],

            sorting: {
                createdDate: 'desc',
            },
            currentSort: 'createdDate,desc',

            fields: {
                startDate: startDate,
                endDate: endDate,
            },

            currentPage: 1,
            itemPerPage: 5,
            totalCount: 0,
            totalPage: 0,
            totalPendingClaim: 0,
            pagingList: [],

            searchDialogIsShow: false,
            historyDetailDialogIsShow: false,
            displayList: [],
            date: {
                active: 2,
                list: [
                    'transaction:transaction.history.filter.today',
                    'transaction:transaction.history.filter.3days',
                    'transaction:transaction.history.filter.aweek',
                    'transaction:transaction.history.filter.amonth',
                ],
            },

            //claim all status
            isCompleted: false,
            isGenerating: false,
            isError: false,
            showHistoryPopup: false,
            selectedHistory: [],
            selectedRebateList: [],
            selectedProvider: null,
            rebatePeriodStart: null,
            rebatePeriodEnd: null,
        };
    },

    onMethodChange(method) {
        controller.vm.setState({ method: method });
    },

    onProviderChange(provider, selectedRebateList) {
        controller.vm.setState({ provider: provider, selectedRebateList: selectedRebateList });
    },

    getPendingRebateTransaction() {
        let loadPendingMemberRebateTransaction = new Promise((resolve) => {
            window.SPL_Transaction.getPendingMemberRebateTransaction().then((data) => resolve(data));
        });
        let loadMemberLastRebateTransaction = new Promise((resolve) => {
            window.SPL_Transaction.getLastTransactionDateTime().then((time) => resolve(time));
        });

        let loadInstantRebateProviderCategory = new Promise((resolve) => {
            window.SPL_JsonSettings.getInstantRebateProviderCategoryJson((cat) => resolve(cat));
        });

        Promise.all([loadPendingMemberRebateTransaction, loadMemberLastRebateTransaction, loadInstantRebateProviderCategory]).then((result) => {
            let [pendingRebate, lastRebateTransaction, providerCategory] = result;
            let { totalPendingClaim } = controller.vm.state;
            let { pendingRebateTransaction } = controller.vm.state;

            let lastRebateDate = '-';
            let lastClaimDate = '-';
            let historyKey = [];

            if (pendingRebate) {
                if (providerCategory && providerCategory.providerCategory) {
                    pendingRebate = controller.massageCorrectProvider(pendingRebate, providerCategory.providerCategory);
                }
                //for hide when rebate earn is 0
                pendingRebateTransaction = pendingRebate.pendingRebateData.filter((rebate) => {
                    return Number(rebate.rebateAmount) - Number(rebate.releasedAmount) > 0;
                });

                //total claim amount
                totalPendingClaim = pendingRebateTransaction.map((data) => data.rebateAmount).reduce((prev, curr) => prev + curr, 0);

                //to total up duplicate product, provider and rate
                for (let i = 0; i < pendingRebateTransaction.length; i++) {
                    let res = {
                        memberGroupCode: pendingRebateTransaction[i].memberGroupCode,
                        product: pendingRebateTransaction[i].product,
                        provider: pendingRebateTransaction[i].provider,
                        rate: pendingRebateTransaction[i].rate,
                        rebateAmount: 0,
                        totalEligibleTurnOver: 0,
                    };

                    for (let j = 0; j < pendingRebateTransaction.length; j++) {
                        if (
                            pendingRebateTransaction[i].memberGroupCode === pendingRebateTransaction[j].memberGroupCode &&
                            pendingRebateTransaction[i].product === pendingRebateTransaction[j].product &&
                            pendingRebateTransaction[i].rate === pendingRebateTransaction[j].rate &&
                            pendingRebateTransaction[i].provider === pendingRebateTransaction[j].provider
                        ) {
                            res.rebateAmount = res.rebateAmount + pendingRebateTransaction[j].rebateAmount;

                            if (pendingRebateTransaction[j].includePromoTurnover) {
                                res.totalEligibleTurnOver += pendingRebateTransaction[j].totalValidTurnOver;
                            } else {
                                res.totalEligibleTurnOver += pendingRebateTransaction[j].totalValidTurnOver - pendingRebateTransaction[j].totalPromoTurnOver;
                            }
                        }
                    }
                    historyKey.push(res);
                }

                //massage history duplicate data
                pendingRebateTransaction = [...new Set(historyKey.map((d) => d.product))].map((product) => {
                    let filterProduct = historyKey.filter((d) => d.product === product).map((d) => d);
                    let filterProvider = filterProduct.filter((current, index) => {
                        return (
                            filterProduct.findIndex(
                                (user) => user.provider === current.provider && user.memberGroupCode === current.memberGroupCode && user.rate === current.rate
                            ) === index
                        );
                    });
                    return {
                        product: product,
                        productHistories: filterProvider,
                    };
                });

                if (lastRebateTransaction) {
                    //massage date
                    lastRebateTransaction = JSON.parse(lastRebateTransaction);
                    lastRebateDate =
                        new Date(lastRebateTransaction.lastGenerateTime).toLocaleDateString('zh-Hans-CN') +
                        ' ' +
                        new Date(lastRebateTransaction.lastGenerateTime).toLocaleTimeString('it-IT');
                    if (lastRebateTransaction.lastClaimTime) {
                        lastClaimDate =
                            new Date(lastRebateTransaction.lastClaimTime).toLocaleDateString('zh-Hans-CN') +
                            ' ' +
                            new Date(lastRebateTransaction.lastClaimTime).toLocaleTimeString('it-IT');
                    }
                }

                controller.vm.setState({
                    provider: pendingRebateTransaction[0] && pendingRebateTransaction[0].product,
                    selectedRebateList: pendingRebateTransaction[0] && pendingRebateTransaction[0].productHistories,
                    pendingRebate: pendingRebate.pendingRebateData,
                    pendingRebateTransaction: pendingRebateTransaction,
                    totalPendingClaim,
                    lastRebateDate: lastRebateDate,
                    lastClaimDate: lastClaimDate,
                });
            }
        });
    },

    massageCorrectProvider(providerList, categoryList) {
        if (providerList && categoryList) {
            let rebateData = providerList.pendingRebateData ? providerList.pendingRebateData : providerList;
            for (let i = 0; i < rebateData.length; i++) {
                if (categoryList[rebateData[i].provider] && categoryList[rebateData[i].provider][rebateData[i].product]) {
                    rebateData[i].product = categoryList[rebateData[i].provider][rebateData[i].product]
                        ? categoryList[rebateData[i].provider][rebateData[i].product]
                        : rebateData[i].product;
                }
            }
        }
        return providerList;
    },

    handleClaimSubmit() {
        const { pendingRebate } = controller.vm.state;
        let arrayList = [];

        for (let i = 0; i < pendingRebate.length; i++) {
            let item = {};
            item.id = pendingRebate[i].id;
            item.memberRebateTransactionId = pendingRebate[i].memberRebateTransactionId;
            arrayList.push(item);
        }
        let param = {
            jsonData: JSON.stringify(arrayList),
        };

        controller.vm.setState({ disableClaim: true }, () => {
            window.SPL_Transaction.claimAllRebateTransactions(param).then((data) => {
                if (data) {
                    controller.getMemberInstantClaimStatus();
                }
            });
        });
    },

    getMemberInstantClaimStatus() {
        const onSuccess = (response) => {
            const vm = controller.vm;
            const { status, error } = response.data;

            const statusAction = (timer, trueState, callback) => {
                vm.setState(trueState);
                setTimeout(() => {
                    callback();
                }, timer);
            };

            if (status === 'C') {
                const timer = 10000;
                if (error) {
                    statusAction(timer, { isError: true }, () => {
                        vm.setState({ isError: false });
                    });
                } else {
                    statusAction(timer, { isCompleted: true, disableClaim: false }, () => {
                        vm.setState({ isCompleted: false });
                    });
                }

                window.location.reload();
            } else {
                statusAction(20000, { isGenerating: true }, () => {
                    controller.getMemberInstantClaimStatus();
                });
            }
        };
        window.SPL_Transaction.getMemberInstantClaimStatus(onSuccess);
    },

    //history

    handleDateChanged(date, isStartDate, vm) {
        controller.vm = vm;
        let fields = controller.vm.state.fields;
        if (isStartDate) {
            fields.startDate = date;
        } else {
            fields.endDate = date;
        }
        controller.vm.setState({ fields });
    },

    handlePrevNextClick(isPrev, firstLast) {
        const { currentPage, totalPage } = controller.vm.state;

        if (firstLast) {
            if (firstLast === 'first') {
                controller.handlePageClick(1);
            } else {
                controller.handlePageClick(totalPage);
            }
        } else {
            if (isPrev) {
                controller.handlePageClick(currentPage - 1);
            } else {
                controller.handlePageClick(currentPage + 1);
            }
        }
    },

    handlePageClick(newPageNum) {
        const { currentPage, totalPage } = controller.vm.state;
        if (currentPage === newPageNum || newPageNum === 0 || newPageNum > totalPage) {
            // do nothing
        } else {
            controller.vm.setState({ currentPage: newPageNum }, () => {
                controller.searchHistory(newPageNum);
            });
        }
    },

    sortHistory(key) {
        let sorting = controller.vm.state.sorting;
        let found = sorting[key];
        if (found) {
            if (found === 'desc') {
                sorting[key] = 'asc';
            } else {
                sorting[key] = 'desc';
            }
        } else {
            sorting[key] = 'desc';
        }
        let sortParam = key + ',' + sorting[key];
        controller.vm.setState({ sorting, currentSort: sortParam }, () => {
            controller.searchHistory(controller.vm.state.currentPage);
        });
    },

    massageRecords(histories) {
        histories = [...new Set(histories.map((d) => d.rebatePeriodStart + ',' + d.rebatePeriodEnd))].map((date) => {
            let splitDate = date.split(',');
            let rebatePeriodStart = moment(splitDate[0]).format('YYYY-MM-DD').toString();
            let rebatePeriodEnd = moment(splitDate[1]).format('YYYY-MM-DD').toString();

            return {
                rebatePeriodStart,
                rebatePeriodEnd,
                rebateHistories: histories.filter((d) => d.rebatePeriodStart + ',' + d.rebatePeriodEnd === date).map((d) => d),
            };
        });

        return histories;
    },

    searchHistory(pageNum, vm) {
        if (vm) {
            controller.vm = vm;
        }

        const { fields } = controller.vm.state;
        let start = new Date(new Date(fields.startDate).setUTCHours(0, 0, 0));
        let end = new Date(new Date(fields.endDate).setUTCHours(23, 59, 59));

        window.SPL_Member.loadMemberRebateSummaryPage(pageNum - 1, start, end).then((data) => {
            let rebateSummary = data;
            if (rebateSummary && rebateSummary.histories) {
                rebateSummary.histories.filter((rebate) => {
                    rebate.totalEligibleTurnOver = rebate.totalValidTurnOver - rebate.totalPromoTurnOver;
                });
                controller.vm.setState(
                    { histories: rebateSummary.histories, totalCount: rebateSummary.totalItems, totalPage: rebateSummary.pageSize, todosPerPage: 10 },
                    () => {
                        controller.handlePagination();
                    }
                );
            }
        });
    },

    handlePagination() {
        const { currentPage, totalPage } = controller.vm.state;
        let pagingList = [];

        for (let i = 0; i < totalPage; i++) {
            let active = false;
            if (i + 1 === currentPage) {
                active = true;
            }

            let pageObj = {
                pageNum: i + 1,
                active: active,
            };
            pagingList.push(pageObj);
        }
        controller.vm.setState({ pagingList: pagingList });
    },

    toggleHistoryPopup(selectedHistory) {
        let loadDetailsMemberRebateSummary = new Promise((resolve) => {
            window.SPL_Member.getDetailsMemberRebateSummary({
                start: selectedHistory && selectedHistory.rebatePeriodStart,
                end: selectedHistory && selectedHistory.rebatePeriodEnd,
                transactionDate: selectedHistory && selectedHistory.transactionDate,
            }).then((hist) => resolve(hist));
        });
        let loadInstantRebateProviderCategory = new Promise((resolve) => {
            window.SPL_JsonSettings.getInstantRebateProviderCategoryJson((cat) => resolve(cat));
        });
        Promise.all([loadDetailsMemberRebateSummary, loadInstantRebateProviderCategory]).then((result) => {
            let [rebateSummary, providerCategory] = result;
            let historyKey = [];

            if (rebateSummary) {
                let histories = rebateSummary.rebateHistories;

                if (histories) {
                    //filter empty turnover
                    histories = histories.filter((turnover) => {
                        turnover.totalEligibleTurnOver = turnover.totalValidTurnOver - turnover.totalPromoTurnOver;
                        return Number(turnover.releasedAmount) > 0;
                    });
                    if (providerCategory && providerCategory.providerCategory) {
                        controller.massageCorrectProvider(histories, providerCategory.providerCategory);
                    }

                    //to total up duplicate product, provider and rate
                    for (let i = 0; i < histories.length; i++) {
                        let res = {
                            memberGroupCode: histories[i].memberGroupCode,
                            product: histories[i].product,
                            provider: histories[i].provider,
                            rate: histories[i].rate,
                            releasedAmount: 0,
                            totalEligibleTurnOver: 0,
                        };

                        for (let j = 0; j < histories.length; j++) {
                            if (
                                histories[i].memberGroupCode === histories[j].memberGroupCode &&
                                histories[i].product === histories[j].product &&
                                histories[i].rate === histories[j].rate &&
                                histories[i].provider === histories[j].provider
                            ) {
                                res.releasedAmount = res.releasedAmount + histories[j].releasedAmount;

                                if (histories[j].includePromoTurnover) {
                                    res.totalEligibleTurnOver += histories[j].totalValidTurnOver;
                                } else {
                                    res.totalEligibleTurnOver += histories[j].totalValidTurnOver - histories[j].totalPromoTurnOver;
                                }
                            }
                        }
                        historyKey.push(res);
                    }

                    //massage history duplicate data
                    historyKey = [...new Set(historyKey.map((d) => d.product))].map((product) => {
                        let filterProduct = historyKey.filter((d) => d.product === product).map((d) => d);
                        let filterProvider = filterProduct.filter((current, index) => {
                            return (
                                filterProduct.findIndex(
                                    (user) => user.provider === current.provider && user.memberGroupCode === current.memberGroupCode && user.rate === current.rate
                                ) === index
                            );
                        });
                        return {
                            product: product,
                            productHistories: filterProvider,
                        };
                    });
                }
            }
            controller.vm.setState({
                showHistoryPopup: !controller.vm.state.showHistoryPopup,
                selectedHistory: historyKey,
                //in historypopout need to select the first one
                selectedProvider: selectedHistory && selectedHistory[0] && selectedHistory[0].product,
                rebatePeriodStart: selectedHistory && selectedHistory.rebatePeriodStart,
                rebatePeriodEnd: selectedHistory && selectedHistory.rebatePeriodEnd,
            });
        });
    },

    handleDateRange(type, vm) {
        controller.vm = vm;
        let fields = controller.vm.state.fields;
        let currentDate = new Date();

        switch (type) {
            case 0:
                fields.startDate = new Date(currentDate.getTime());
                break;
            case 1:
                fields.startDate = new Date(currentDate.getTime() - 2 * 24 * 3600000);
                break;
            case 2:
                fields.startDate = new Date(currentDate.getTime() - 6 * 24 * 3600000);
                break;
            case 3:
                fields.startDate = new Date(currentDate.getTime() - 30 * 24 * 3600000);
                break;
            default:
                fields.startDate = controller.vm.state.fields.startDate;
                break;
        }
        controller.vm.setState({ fields });
    },
};

export default controller;
