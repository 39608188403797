import React from 'react';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Modal from 'react-modal';
import CloseButton from './close-button';

const FloatingFreeSpinModal = ({
    modalIsOpen,
    handleCloseModal,
    popupImage,
    textMessageColor,
    textProviderColor,
    popupClaimDataList,
    claimExpired,
    checkMemberInboxMessage,
}) => {
    const { t } = useTranslation(['freespin', 'providerName']);

    if (!popupImage) return null;

    return (
        <Modal
            ariaHideApp={false}
            overlayClassName='standard-popup-overlay'
            className='standard-popup-modal-container freespin-popup-modal-container'
            isOpen={modalIsOpen}
            onRequestClose={handleCloseModal}
            shouldCloseOnOverlayClick={true}
        >
            <div className='freespin-popup-modal-body'>
                <div className='freespin-close-btn' onClick={handleCloseModal}>
                    <CloseButton />
                </div>
                <LazyLoadImage src={popupImage} />
                <div className='freespin-message'>
                    <div className='freespin-title'>
                        <span style={{ color: textMessageColor }}>{t('congratulationsText')}</span>
                        <br />
                        <span style={{ color: textProviderColor }}>
                            {t('providerText')}:{' '}
                            {popupClaimDataList?.slice(0, 2).map((item, index, arr) => (
                                <span key={item?.provider}>
                                    {t('providerWithNumberFreeSpins', {
                                        providerCode: t(`providerName:ProviderName.${item.provider}`, item.provider),
                                        freeSpinCount: item?.freeSpinCount,
                                        gameName: item?.games?.[0]?.name ? ` (${item.games[0].name})` : '',
                                    })}
                                    {`${index !== arr.length - 1 ? ', ' : ''}`}
                                </span>
                            ))}
                        </span>
                    </div>
                    {claimExpired && (
                        <div style={{ color: textProviderColor }} className='freespin-claim'>
                            {t('claimBefore', { claimExpired })}
                        </div>
                    )}
                    {checkMemberInboxMessage && (
                        <div style={{ color: textProviderColor }} className='freespin-noticed'>
                            {t('kindlyCheck')}
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default FloatingFreeSpinModal;
