// library
import { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

// logic / core
import { providerAction } from '@redux/action';
import { gu_filterCommonKey } from '@utils/general-util';

export const useGetProviderMaintenanceList = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(providerAction.getProviderMaintenanceList());
    }, []);
    return {};
};

// get data from custom-hot-game-slider.json
export const useGetHotGameSlider = () => {
    const dispatch = useDispatch();
    const providerReducer = useSelector((state) => state.providerReducer);

    const { processedData = {}, rawData = {} } = providerReducer || {};
    const { favoriteGames = [], maintenanceList } = processedData;

    useEffect(() => {
        dispatch(providerAction.getHotGameSliderJson());
    }, []);
    useEffect(() => {
        if (!rawData?.hotGameSliders) {
            return;
        }
        const filterHotGameCommonKey = gu_filterCommonKey(rawData.hotGameSliders);

        // Process maintenance data
        let updatedHotGameSlider = (filterHotGameCommonKey || []).map((hotGame) => {
            const foundMaintenanceProvider = (maintenanceList || []).find((maintenanceData) => maintenanceData.provider === hotGame.provider);
            return {
                ...hotGame,
                underMaintenance: foundMaintenanceProvider || null,
                providerMaintenanceName: foundMaintenanceProvider ? hotGame.name : null,
            };
        });

        // Process favorite games data
        updatedHotGameSlider = (updatedHotGameSlider || []).map((hotGame) => {
            const _gameCode = hotGame?.game || hotGame?.gameCode;
            const matchedFavGame = (favoriteGames || []).find((favGame) => favGame.game === _gameCode);

            return {
                ...hotGame,
                isFav: !!matchedFavGame,
                id: matchedFavGame?.id || null,
                gameCode: _gameCode,
            };
        });

        dispatch(providerAction.setHotGameSlider(updatedHotGameSlider));
    }, [rawData?.hotGameSliders, maintenanceList, favoriteGames]);
    return {};
};

// get data from custom-just-for-you-games.json
export const useGetJustForYouGames = () => {
    const dispatch = useDispatch();
    const providerReducer = useSelector((state) => state.providerReducer);
    const { processedData = {}, rawData = {} } = providerReducer || {};
    const { favoriteGames = [], maintenanceList = [] } = processedData;

    useEffect(() => {
        dispatch(providerAction.getJustForYouGameListJson());
    }, []);

    useEffect(() => {
        if (!rawData?.justForYouGame) {
            return;
        }
        const _justForYouGame = rawData?.justForYouGame;

        // Update both hotGame and recommendGame in justForYouGame
        // Combine maintenance and favorite updates in a single function
        const updateGameData = (gameList) => {
            return (gameList || []).map((game) => {
                const _gameCode = game?.game || game?.gameCode;

                // Update maintenance info
                const foundMaintenanceProvider = (maintenanceList || []).find((maintenanceData) => maintenanceData.provider === game.provider);

                // Update favorite info
                const matchedFavGame = (favoriteGames || []).find((favGame) => favGame.game === _gameCode);

                return {
                    ...game,
                    underMaintenance: foundMaintenanceProvider || null,
                    providerMaintenanceName: foundMaintenanceProvider ? game.name : null,
                    isFav: matchedFavGame ? true : false,
                    id: matchedFavGame?.id || null,
                };
            });
        };

        const updatedJustForYouGame = {
            ..._justForYouGame,
            hot: updateGameData(_justForYouGame.hot),
            recommend: updateGameData(_justForYouGame.recommend),
        };

        dispatch(providerAction.setJustForYouGameList(updatedJustForYouGame));
    }, [rawData.justForYouGame, maintenanceList, favoriteGames]);

    return {};
};

export const useGetMemberFavoriteGames = () => {
    const dispatch = useDispatch();
    const providerReducer = useSelector((state) => state.providerReducer);
    const { processedData = {}, rawData = {} } = providerReducer || {};
    const { maintenanceList = [], favoriteGames } = processedData;

    useEffect(() => {
        dispatch(providerAction.getMemberFavoriteGames());
    }, []);

    useEffect(() => {
        const processedFavoriteGames = (rawData.favoriteGames || []).map((favGameList) => {
            const favMaintenanceInfo = maintenanceList.find((data) => data.provider === favGameList.provider);
            return {
                ...favGameList,
                imggame: `/public/html/games/images/s1/${favGameList.provider}/${favGameList.game}.jpg`,
                isFav: true,
                underMaintenance: favMaintenanceInfo || null,
            };
        });

        // Deep comparison using JSON.stringify, if normal compare object will always false (based on JS doc)
        if (JSON.stringify(processedFavoriteGames) !== JSON.stringify(favoriteGames)) {
            dispatch(providerAction.setMemberFavoriteGames(processedFavoriteGames));
        }
    }, [rawData.favoriteGames, maintenanceList]);

    return {};
};
