import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';

import SVGElements from 'root/components/SVGElements';
import { LazyLoadImage } from 'react-lazy-load-image-component';

class ImageHelper extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
        this.state = {
            SVGComponentType: null,
        };
    }

    componentDidMount() {
        this.setState({
            SVGComponentType: SVGElements,
        });
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    addDefaultSrc(e, src) {
        let defaultImg = src;
        let http = new XMLHttpRequest();
        http.open('HEAD', defaultImg, false);
        http.send();
        if (http.status !== 404) {
            e.target.src = defaultImg;
        }
    }

    render() {
        {
            /* 
            Image helper structure
            Params:
            displayImg       - (String) image path
            displaySVG       - (whole svg tag)
            isSVG            - (Boolean) settings's flag to determine to use svg
            alt              - (String)
            className        - (String)
            effect           - (String) opacity/blur/black-white/null
            isHide           - (Boolean) hide if condition true
            fallbackSrc      - (String) default image if cant found
            style            - (Object) Styling e.g. {width: '0.3'}
            svgType          - (String) value need contain the existing svg file (e.g. SVGRegister, SVGMaster)
            onMouseEnter     - (Callback) onMouseEnter action
            onMouseLeave     - (Callback) onMouseLeave action
        */
        }

        const { displayImg, displaySVG, isSVG, alt, className, effect, isHide, fallbackSrc, style, svgType, onClick, onMouseEnter, onMouseLeave } = this.props;

        const { SVGComponentType } = this.state;

        return (
            <>
                {isHide ? (
                    <></>
                ) : (
                    <>
                        {isSVG ? (
                            <>
                                {svgType && SVGComponentType ? (
                                    <SVGComponentType name={displaySVG} className={className} style={style} onClick={onClick} />
                                ) : (
                                    <>{displaySVG}</>
                                )}
                            </>
                        ) : (
                            <>
                                {displayImg && (
                                    <LazyLoadImage
                                        src={displayImg}
                                        alt={alt ? alt : ''}
                                        className={className && className}
                                        effect={effect !== undefined ? effect : 'opacity'}
                                        onError={(e) => {
                                            fallbackSrc && this.addDefaultSrc(e, fallbackSrc);
                                        }}
                                        style={style}
                                        onMouseEnter={() => {
                                            onMouseEnter && onMouseEnter();
                                        }}
                                        onMouseLeave={() => {
                                            onMouseLeave && onMouseLeave();
                                        }}
                                    />
                                )}
                            </>
                        )}
                    </>
                )}
            </>
        );
    }
}

export default connect(mapStateToProps, null)(withRouter(ImageHelper));
