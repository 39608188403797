import commonUtil from 'root/utils/common-util';

const controller = {
    vm: null,
    
    init(vm) {
        let date = new Date();
        let dateMonth = date.getMonth() + 1;
        let dateYear = date.getFullYear();
        controller.vm = vm;
        controller.vm.state = {
            compSettings:[],
            // leaderboard fields
            fields: {
                dateYear: dateYear,
                dateMonth: dateMonth,
            },
        };
    },

    initCompSettings() {
        const { user } = controller.vm.props;
        return new Promise((resolve) => {
            // Step 1: Standard way to get from portal settings
            let leaderboardSettings = commonUtil.getSettingFromPortalSettings({
                vm: controller.vm,
                settingName: 'leaderboardSettings',
            });

            let showLeaderboardCurrency = false

            if (user && user.account && user.account.currency) {
                if (leaderboardSettings && leaderboardSettings.leaderboardCurrencyShow) {
                    leaderboardSettings.leaderboardCurrencyShow = leaderboardSettings.leaderboardCurrencyShow.filter((currency) => currency === user.account.currency);
                    if (leaderboardSettings.leaderboardCurrencyShow.length > 0) {
                        showLeaderboardCurrency = true
                    }
                }
            }
            // Step 2: Massage again to get the specific data you need (Skip if not needed)
            // Step 3: Initial all into compSettings
            let compSettings = {
                leaderboardCurrencyShow: showLeaderboardCurrency,
            };

            controller.vm.setState({ compSettings }, () => {
                resolve();
            });
        });
    }
};

export default controller;
