import React, { Fragment } from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import ImageHelper from 'root/webapp/shared/image-helper/image-helper';
import Slider from 'react-slick';

class Instruction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentSlideIndex: 0, 
        };
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    
    renderDesktopCommon(commonConfig) {
        const { title, title02, title03, title04, isHideLeftArrow, isHideRightArrow, togglePopUp } = commonConfig;
        return (
            <Fragment>
                <div className='title-container'>
                    <div className='title'>{title}</div>
                    <div className='title'>{title02}</div>
                    <div className='title'>{title03}</div>
                    <div className='title'>{title04}</div>
                </div>

                {!isHideLeftArrow && (
                    <div
                        className='left-arrow'
                        onClick={() => {
                            this.previous();
                        }}
                    >
                        <ImageHelper 
                            displaySVG={'left-arrow'}
                            isSVG={true}
                            svgType={'SVGElements'}
                        />
                    </div>
                )}

                {!isHideRightArrow && (
                    <div
                        className='right-arrow'
                        onClick={() => {
                            this.next();
                        }}
                    >
                       <ImageHelper 
                            displaySVG={'right-arrow'}
                            isSVG={true}
                            svgType={'SVGElements'}
                        />
                    </div>
                )}

                <div onClick={() => togglePopUp()}>
                    <ImageHelper 
                        displayImg={'/public/html/images/close2.png'}
                        className={'close-icon'}
                    />
                </div>
                
            </Fragment>
        );
    }

    renderDesktopSlide(config) {
        const { stepTitle, desc, desc02, device, i, title04 } = config;

        return (
            <div key={`ins-slider-${i}`} className={`instruction-popup-body`}>
                <div className='container-wrapper'>
                    <div className={`left-container ${title04 ? 'extra-margin' : ''}`}>
                        <div className='step-title'>{stepTitle}</div>
                        <div className='desc'>{desc}</div>
                        <div className='desc'>{desc02 && desc02}</div>
                    </div>

                    <div className='right-container'>
                        <img src={`/public/html/images/instruction_pop_up/${device}/Step-${i + 1}.png`} alt=''></img>
                    </div>
                </div>
            </div>
        );
    }

    renderMobileCommon(commonConfig) {
        const { title, title02, title03, mobileTitle03, isHideLeftArrow, isHideRightArrow, togglePopUp } = commonConfig;
        return (
            <Fragment key='ins-mobile-common'>
                <div className='top-container'>
                    <div className='title'>{title}</div>
                    <div className='mobileTitle'>{mobileTitle03}</div>
                </div>

                {!isHideLeftArrow && (
                    <div
                        className='left-arrow'
                        onClick={() => {
                            this.previous();
                        }}
                    >
                        <ImageHelper 
                            displaySVG={'left-arrow'}
                            isSVG={true}
                            svgType={'SVGElements'}
                        />
                    </div>
                )}

                {!isHideRightArrow && (
                    <div
                        className='right-arrow'
                        onClick={() => {
                            this.next();
                        }}
                    >
                        <ImageHelper 
                            displaySVG={'right-arrow'}
                            isSVG={true}
                            svgType={'SVGElements'}
                        />
                    </div>
                )}

                <div onClick={() => togglePopUp()}>
                    <ImageHelper 
                        displayImg={'/public/html/images/close2.png'}
                        className={'close-icon'}
                    />
                </div>
            </Fragment>
        );
    }

    renderMobileSlide(config) {
        const { stepTitle, desc, desc02, mobileDesc, device, i } = config;

        return (
            <div key={`ins-slider-${i}`} className={`instruction-popup-body`}>
                <div className='container-wrapper'>
                    <div className='mid-container'>
                        <img src={`/public/html/images/instruction_pop_up/${device}/Step-${i + 1}.png`} alt=''></img>
                    </div>

                    <div className='bot-container'>
                        <div className='step-title'>{stepTitle}</div>
                        <div className='desc'>{desc}</div>
                        <div className='mobileDesc'>{mobileDesc && mobileDesc}</div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { currentSlideIndex } = this.state;
        const { instructionPopUp, language, instruction } = this.props;
        const { device, isMobile, togglePopUp } = instructionPopUp;

        const translationList = instruction && instruction[device] && instruction[device][language.key.split('-')[0]] && instruction[device][language.key.split('-')[0]];
        if (!translationList.length) return null;

        const isHideLeftArrow = translationList.length === 1 || currentSlideIndex === 0;
        const isHideRightArrow = translationList.length === 1 || currentSlideIndex === translationList.length - 1;

        const { title, title02, title03, title04, mobileTitle03 } = translationList[0];
        const commonConfig = {
            title,
            title02,
            title03,
            title04,
            mobileTitle03,
            isHideLeftArrow,
            isHideRightArrow,
            togglePopUp,
        };
        const items = [!isMobile ? this.renderDesktopCommon(commonConfig) : this.renderMobileCommon(commonConfig)];

        const slideItems = [];
        for (let i = 0, l = translationList.length; i < l; i++) {
            const { stepTitle, desc, desc02, mobileDesc } = translationList[i];
            const config = { stepTitle, desc, desc02, mobileDesc, device, i, title04 };
            const slideContent = !isMobile ? this.renderDesktopSlide(config) : this.renderMobileSlide(config);
            slideItems.push(slideContent);
        }

        const sliderConfig = {
            speed: 10,
            fade: true,
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            swipeToSlide: true,
            autoplay: false,
            autoplaySpeed: 3000,
            pauseOnHover: true,
            arrows: false,
            beforeChange: (oldIndex, newIndex) => {
                this.setState({ currentSlideIndex: newIndex });
            },
        };
        return (
            <Translation>
                {(t) => (
                        <div className='instruction-popup-container'>
                            <div className='instruction-popup-box'>
                                {items}
                                <Slider ref={(e) => (this.slider = e)} {...sliderConfig}>
                                    {slideItems.map((e) => {
                                        return e;
                                    })}
                                </Slider>
                            </div>
                            <div className='popup-overlay' onClick={togglePopUp}></div>
                        </div>
                        
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['global', 'home'])(withRouter(Instruction)));
