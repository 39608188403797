import { toISOString } from 'core-js/fn/date';
import React from 'react';
import Slider from 'root/webapp/shared/component/Slider';

class Banner extends React.Component {
    constructor() {
        super();
        this.state = {
            items: [],
        };
    }

    componentDidMount() {
        this.setState({ items: this.props.items });
    }

    componentDidUpdate() {
        if (this.props.items.length !== this.state.items.length) {
            this.setState({ items: this.props.items });
        }
    }

    doLoadingLineAni(index, progress) {
        if (document.querySelectorAll('.banner .loading-line')) {
            const $loadingLine = document.querySelectorAll('.banner .loading-line')[index];

            $loadingLine && ($loadingLine.style.width = `${100 * progress}%`);
        }
    }

    render() {
        return (
            <div className='home-container'>
                <div className='banner'>{this.state.items.length > 0 && <Slider dur={10000} allowPause={false} items={this.state.items} />}</div>
            </div>
        );
    }
}

export default Banner;
