import React from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import vm from '../../instant-rebate.controller';
import CustomDatePicker from 'root/components/customDatePicker';
import moment from 'moment';
import commonUtil from 'root/utils/common-util';
import HistoryPopup from '../../sub-component/history-popup/mobile/history-popup';
import Pagination from 'root/webapp/shared/pagination/pagination.selector';

class MRebate extends React.Component {
    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
        this.myRef = React.createRef();
    }

    componentDidMount() {
        commonUtil.loadCustomTranslation('instant-rebate', this, function () {});
        vm.getPendingRebateTransaction();
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    toggle() {
        this.setState({ isOpenRebate: !this.state.isOpenRebate });
    }

    toggleHistory() {
        this.setState({ isOpenHistory: !this.state.isOpenHistory });
    }

    getShowMoreList(data) {
        if (this.state.isOpenRebate) {
            return data;
        }
        return data.slice(0, 2);
    }

    getHistoryShowMoreList(data) {
        if (this.state.isOpenHistory) {
            return data;
        }
        return data.slice(0, 2);
    }

    render() {
        const {
            provider,
            pendingRebateTransaction,
            totalPendingClaim,
            lastRebateDate,
            lastClaimDate,
            customTranslationObj,
            isGenerating,
            isCompleted,
            isError,
            selectedRebateList,
            showHistoryPopup,
            histories,
            selectedHistory,
            selectedProvider,
            rebatePeriodStart,
            rebatePeriodEnd,
            disableClaim,
        } = this.state;

        let userCurrency = this.props.user && this.props.user.account && this.props.user.account.currency ? this.props.user.account.currency : null;

        if (userCurrency === 'KHUSD') userCurrency = 'USD';

        return (
            <Translation>
                {(t) => (
                    <div data-page='/myaccount/' ref={this.myRef}>
                        {showHistoryPopup ? (
                            <HistoryPopup
                                provider={selectedProvider}
                                startDate={rebatePeriodStart}
                                endDate={rebatePeriodEnd}
                                item={selectedHistory}
                                isOpen={showHistoryPopup}
                                closeFunc={vm.toggleHistoryPopup}
                            />
                        ) : (
                            <div className='rebate-page'>
                                <div className='group-item rebate-option m-standard-2-tab-container'>
                                    <span className={this.state.method === 'rebate' ? 'on' : ''} onClick={() => vm.onMethodChange('rebate')}>
                                        {t('settings:settings.instantRebateTab.rebate', 'Rebate')}
                                    </span>
                                    <span className={this.state.method === 'history' ? 'on' : ''} onClick={() => vm.onMethodChange('history')}>
                                        {t('settings:settings.instantRebateTab.history', 'History')}
                                    </span>
                                </div>

                                {this.state.method === 'rebate' && (
                                    <>
                                        <div className='rebate-claim-box'>
                                            <span>
                                                {t('rebate:rebate.lastUpdate', 'Last update')}
                                                {lastRebateDate}
                                            </span>
                                            <span className='claim-figure'>
                                                {userCurrency} {totalPendingClaim && totalPendingClaim.toFixed(2)}
                                            </span>
                                            <button
                                                className='btn-submit claim-all'
                                                onClick={() => {
                                                    vm.handleClaimSubmit();
                                                }}
                                                disabled={totalPendingClaim === 0 || isGenerating || disableClaim}
                                            >
                                                {t('rebate:rebate.claimAll', 'Claim All').toUpperCase()}
                                            </button>
                                            <span>
                                                {t('rebate:rebate.lastClaim', 'Last claimed')}
                                                {lastClaimDate}
                                            </span>
                                            <div className='claim-processing'>
                                                {isGenerating ? (
                                                    <span>{t('rebate:rebate.claimInProgress', 'claimInProgress')}</span>
                                                ) : isCompleted ? (
                                                    <span>{t('rebate:rebate.claimCompleted', 'claimCompleted')}</span>
                                                ) : (
                                                    isError && <span>{t('rebate:rebate.reclaim', 'reclaim')}</span>
                                                )}
                                            </div>
                                        </div>

                                        <div className='group-item'>
                                            {/* show provider display */}
                                            {pendingRebateTransaction && pendingRebateTransaction.length > 0 && (
                                                <div className='rebate-option-title rebate-option'>
                                                    {pendingRebateTransaction.map((rebateProvider, i) => (
                                                        <span
                                                            className={provider === rebateProvider.product ? 'on' : ''}
                                                            onClick={() => vm.onProviderChange(rebateProvider.product, rebateProvider.productHistories)}
                                                        >
                                                            {customTranslationObj && customTranslationObj[rebateProvider.product]
                                                                ? t(customTranslationObj[rebateProvider.product])
                                                                : rebateProvider.product}
                                                        </span>
                                                    ))}
                                                </div>
                                            )}

                                            {/* show provider data display */}
                                            {selectedRebateList && (
                                                <div className='rebate-provider-list'>
                                                    {this.getShowMoreList(selectedRebateList).map((hist, i) => (
                                                        <ul>
                                                            <li>
                                                                <span>{t('rebate:rebate.memberLevel', 'Membership Level')}</span>
                                                                <span className='data'>{hist.memberGroupCode}</span>
                                                            </li>
                                                            <li>
                                                                <span>{t('rebate:rebate.provider', 'Provider')}</span>
                                                                <span className='data'>
                                                                    {customTranslationObj && customTranslationObj[hist.provider]
                                                                        ? t(customTranslationObj[hist.provider])
                                                                        : hist.provider}
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span>{t('rebate:rebate.rebate', 'Rebate %')}</span>
                                                                <span className='data'>{hist.rate}</span>
                                                            </li>
                                                            <li>
                                                                <span>{t('rebate:rebate.validBet', 'Valid Bet')}</span>
                                                                <span className='data'>{hist.totalEligibleTurnOver}</span>
                                                            </li>
                                                            <li>
                                                                <span>{t('rebate:rebate.rebateEarned', 'Amount')}</span>
                                                                <span className='data rebate-earned-data'>{hist.rebateAmount.toFixed(2)}</span>
                                                            </li>
                                                        </ul>
                                                    ))}

                                                    {selectedRebateList.length > 2 && (
                                                        <div className='rebate-show-button' onClick={() => this.toggle(selectedRebateList)}>
                                                            {/* {t('', 'ShowMore')} */}
                                                            <span>{this.state.isOpenRebate ? t('', 'ShowLess') : t('', 'ShowMore')}</span>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}

                                {this.state.method === 'history' && (
                                    <div>
                                        <div>
                                            <div className='group-item transaction-date'>
                                                <div className='title'>
                                                    <span>{t('transaction:transaction.history.filter.trans_date')}</span>
                                                    <span className='required'>*</span>
                                                </div>
                                                <div className='group'>
                                                    <ul>
                                                        {this.state.date.list.map((item, i) => (
                                                            <li
                                                                key={item}
                                                                className={[i === this.state.date.active ? 'on' : ''].join(' ')}
                                                                onClick={() => {
                                                                    vm.handleDateRange(i, this);
                                                                    this.setState({ ...this.state, date: { ...this.state.date, active: i } });
                                                                }}
                                                            >
                                                                <span>{t(item)}</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className='group-item date-select'>
                                                <div className='title'>{t('transaction:transaction.history.filter.start_date', 'Start Date')}</div>
                                                <div className='group'>
                                                    <div className='input-box'>
                                                        <label>
                                                            <CustomDatePicker
                                                                placeholderText={t('transaction:transaction.history.filter.start_date', 'Start Date')}
                                                                selected={this.state.fields.startDate}
                                                                onChange={(date) => vm.handleDateChanged(date, true, this)}
                                                                popperPlacement='bottom-start'
                                                            />
                                                            {/* <input type="text" placeholder="2020-03-30" /> */}
                                                            <i className='icon-date'></i>
                                                        </label>
                                                    </div>
                                                    <span>{t('transaction:transaction.history.filter.to')}</span>
                                                    <div className='input-box'>
                                                        <label>
                                                            <CustomDatePicker
                                                                placeholderText={t('transaction:transaction.history.filter.end_date', 'End Date')}
                                                                selected={this.state.fields.endDate}
                                                                onChange={(date) => vm.handleDateChanged(date, false, this)}
                                                                popperPlacement='bottom-end'
                                                            />
                                                            <i className='icon-date'></i>
                                                        </label>
                                                    </div>
                                                </div>
                                                <button className='btn-submit' onClick={() => vm.searchHistory(0)}>
                                                    {t('transaction:transaction.deposit.cashdeposit.submitbtn', 'Submit').toUpperCase()}
                                                </button>
                                            </div>
                                        </div>
                                        <div className='history-list'>
                                            <div className='table-container'>
                                                <div className='rebate-provider-list group-item'>
                                                    {this.getHistoryShowMoreList(histories).map((history, i) => (
                                                        <ul>
                                                            <li>
                                                                <span>{t('rebate:rebate.transactionDate', 'Transaction Date')}</span>
                                                                <span className='data'>{moment(history.rebatePeriodEnd).format('YYYY-MM-DD').toString()}</span>
                                                            </li>
                                                            <li>
                                                                <span>{t('rebate:rebate.rebatePeriod', 'Rebate Period')}</span>
                                                                <span
                                                                    className='data rebate-date'
                                                                    onClick={() => {
                                                                        vm.toggleHistoryPopup(history);
                                                                    }}
                                                                >
                                                                    {moment(history.rebatePeriodStart).format('DD').toString() +
                                                                        '-' +
                                                                        moment(history.rebatePeriodEnd).format('DD-MM-YYYY').toString()}
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span>{t('rebate:rebate.totalValidBet', 'Total Valid Bet')}</span>
                                                                <span className='data'>{window.SPL_Other.formatAmount(history.totalValidTurnOver)}</span>
                                                            </li>
                                                            <li>
                                                                <span>{t('rebate:rebate.totalPromoTurnover', 'Total Promo Turnover')}</span>
                                                                <span className='data'>{window.SPL_Other.formatAmount(history.totalPromoTurnOver)}%</span>
                                                            </li>
                                                            <li>
                                                                <span>{t('rebate:rebate.totalEligibleTurnover', 'Total Eligible Turnover')}</span>
                                                                <span className='data'>{window.SPL_Other.formatAmount(history.totalEligibleTurnOver)}</span>
                                                            </li>
                                                            <li>
                                                                <span>{t('rebate:rebate.rebateEarned', 'Amount')}</span>
                                                                <span className='data rebate-earned-data'>
                                                                    {window.SPL_Other.formatAmount(history.releasedAmount)
                                                                        ? window.SPL_Other.formatAmount(history.releasedAmount)
                                                                        : 0}
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    ))}

                                                    {histories.length > 2 && (
                                                        <div className='rebate-show-button' onClick={() => this.toggleHistory(histories)}>
                                                            {/* {t('', 'ShowMore')} */}
                                                            <span>{this.state.isOpenHistory ? t('', 'ShowLess') : t('', 'ShowMore')}</span>
                                                        </div>
                                                    )}
                                                </div>
                                                <Pagination
                                                    pagingList={this.state.pagingList}
                                                    handlePrevNextClick={vm.handlePrevNextClick}
                                                    totalCount={this.state.totalCount}
                                                    currentPage={this.state.currentPage}
                                                    itemPerPage={10}
                                                    handlePageClick={vm.handlePageClick}
                                                ></Pagination>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'rebate', 'global', 'register'])(withRouter(MRebate)));
