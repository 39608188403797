import { providerConstants } from '../constant/';
import { portalAction } from './portal.action';
import notification from '@utils/notification';

export const providerAction = {
    getProviderMaintenanceList,

    getHotGameSliderJson,
    setHotGameSlider,

    getJustForYouGameListJson,
    setJustForYouGameList,

    getMemberFavoriteGames,
    updateMemberFavoriteGames,
    setMemberFavoriteGames,
};

function getProviderMaintenanceList() {
    return (dispatch, getState) => {
        const { language, user } = getState();
        const _currency = user.isLogin ? user?.account?.currency : language.currencyLang;

        window.SPL_Provider.providerGameMaintenanceList(null, null, _currency).then((response) => {
            const rawData = response || [];
            const updatedData = rawData.map((data) => {
                return {
                    provider: data?.provider,
                    endTime: data?.endTime,
                    starttime: data?.startTime,
                };
            });

            dispatch({ type: providerConstants.GET_PROVIDER_MAINTENANCE_LIST, payload: { maintenanceList: rawData, processedMaintenanceList: updatedData } });
        });
    };
}

// ********** Just For You Games *********
function getJustForYouGameListJson() {
    return async (dispatch) => {
        const res = await window.SPL_Content.getJustForYouGameList();
        dispatch({ type: providerConstants.GET_JUST_FOR_YOU_GAME, payload: { justForYouGame: res } });
    };
}

function setJustForYouGameList(justForYouGame) {
    return (dispatch) => {
        dispatch({ type: providerConstants.SET_JUST_FOR_YOU_GAME, payload: { justForYouGame: justForYouGame } });
    };
}
// ********** Just For You Games End *********

// ********** Hot Games *********
// get custom-hot-game-slider.json
function getHotGameSliderJson() {
    return async (dispatch) => {
        const res = await window.SPL_Content.getHotGameSlider();
        dispatch({ type: providerConstants.GET_HOT_GAME_SLIDER, payload: { hotGameSliders: res || [] } });
    };
}

function setHotGameSlider(hotGameSliders) {
    return (dispatch) => {
        dispatch({ type: providerConstants.SET_HOT_GAME_SLIDER, payload: { hotGameSliders: hotGameSliders } });
    };
}
// ********** Hot Games End *********

// ********** Favorite Games *********
function getMemberFavoriteGames() {
    return async (dispatch, getState) => {
        const { screen, providerReducer } = getState();
        const isMobile = screen.viewType === 'mobile';

        if (providerReducer?.rawData?.favoriteGames) {
            return;
        }

        try {
            const rawFavoriteGamesApiData = await window.SPL_Member.getMemberFavoriteGame(isMobile);
            const rawFavoriteGames = rawFavoriteGamesApiData?.favoritedGame || [];

            dispatch({ type: providerConstants.GET_MEMBER_FAVORITE_GAMES, payload: { favoriteGames: rawFavoriteGames } });
        } catch (err) {
            console.error(err);
        }
    };
}

function updateMemberFavoriteGames(game, notificationMsg) {
    return async (dispatch, getState) => {
        const { screen, providerReducer, user, portal } = getState();
        const isMobile = screen.viewType === 'mobile';
        const _gameCode = game?.game || game?.gameCode;
        const favoriteGames = providerReducer?.processedData?.favoriteGames;

        if (portal?.isSubmitting) {
            return;
        }
        const isExistedFavGame = favoriteGames?.some((favGame) => favGame.game === _gameCode);

        if (!user?.isLogin) {
            if (isMobile) {
                dispatch(portalAction.floatingPageUpdated('login'));
            } else {
                notification.showNotification('error', notificationMsg);
            }
            return;
        }

        dispatch(portalAction.setIsSubmitting(true));
        try {
            const action = isExistedFavGame ? 'deleteMemberFavoriteGame' : 'updateMemberFavoriteGame';
            const updatedFavGames = await window.SPL_Member[action](game, isMobile);
            dispatch({ type: providerConstants.UPDATE_MEMBER_FAVORITE_GAMES, payload: { favoriteGames: updatedFavGames?.favoritedGame } });
            dispatch(portalAction.setIsSubmitting(false));
        } catch (err) {
            console.error(err);
            dispatch(portalAction.setIsSubmitting(false));
        }
    };
}

function setMemberFavoriteGames(favoriteGames) {
    return (dispatch) => {
        dispatch({ type: providerConstants.SET_MEMBER_FAVORITE_GAMES, payload: { favoriteGames: favoriteGames } });
    };
}
// ********** Favorite Games End *********
