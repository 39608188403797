import React from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import RegisterPanel from './RegisterPanel';

class Register extends React.Component {
    constructor() {
        super();
    }

    render() {
        return (
            <RegisterPanel
                onClose={() => {
                    this.props.history.push('/home');
                }}
            />
        );
    }
}

export default connect(mapStateToProps)(withTranslation('')(withRouter(Register)));
