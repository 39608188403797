import { authSettingsConstant } from '../constant';
export const authSettingsAction = {
    fetchAuthSettings,
    setGoogleMemberRegistered,
    setModuleGoogleRegistered,
    setModuleSixPinRegistered,
    setSixPinMemberRegistered,
    setSixPinLocked,
    setModuleSixPinLocked,
};

function fetchAuthSettings(splConfig, reqUpdate) {
    return async (dispatch, getState) => {
        const { authSettingsReducer, user } = getState();

        const authType = window.isAffiliate ? splConfig?.authSettingsType : splConfig?.otpOptions?.otpType;
        if (!reqUpdate && authSettingsReducer?.rawData?.[authType]) {
            return;
        }

        try {
            const getCategorizedAuthSettings = window.SPL_Member.getCategorizedAuthSettings;
            let response = await getCategorizedAuthSettings(splConfig);

            const processedAuthMethods = (response?.data?.authMethod || []).filter((method) => {
                if (method.type === 'PHONE' && !user?.account?.phone) {
                    return false;
                }
                if (method.type === 'EMAIL' && !user?.account?.email) {
                    return false;
                }
                return true;
            });
            const processedData = {
                ...response?.data,
                authMethod: processedAuthMethods,
            };

            dispatch({ type: authSettingsConstant.FETCH_AUTH_SETTINGS, payload: { authType: authType, rawData: response?.data, processedData: processedData } });
        } catch (err) {
            console.error(err);
        }
    };
}

// only update on BIND_AUTHENTICATION module
function setGoogleMemberRegistered(isGaRegistered) {
    return (dispatch) => {
        return new Promise((resolve) => {
            // transitionFromUnbindToBind indicate user just from unbind status become binded. To control some logic e.g. detect this and update module auth-settings again.
            dispatch({ type: authSettingsConstant.SET_GOOGLE_MEMBER_REGISTERED, payload: { gaRegistered: isGaRegistered, transitionFromUnbindToBindGA: true } });
            resolve();
        });
    };
}

// update on the module member entered, (dependency on setGoogleMemberRegistered scenario only).
// only will set true, hence don't need pass flag
function setModuleGoogleRegistered(module) {
    return (dispatch) => {
        return new Promise((resolve) => {
            // transitionFromUnbindToBind indicate user just from unbind status become binded. To control some logic e.g. detect this and update module auth-settings again.
            dispatch({ type: authSettingsConstant.SET_MODULE_GOOGLE_REGISTERED, payload: { module: module } });
            resolve();
        });
    };
}

// only update on SIX_PIN module
function setSixPinMemberRegistered() {
    return (dispatch) => {
        return new Promise((resolve) => {
            // transitionFromUnbindToBindSixPin indicate user just from unbind status become binded. To control some logic e.g. detect this and update module auth-settings again.
            dispatch({ type: authSettingsConstant.SET_SIX_PIN_MEMBER_REGISTERED, payload: {} });
            resolve();
        });
    };
}

function setModuleSixPinRegistered(module) {
    return (dispatch) => {
        return new Promise((resolve) => {
            // transitionFromUnbindToBind indicate user just from unbind status become binded. To control some logic e.g. detect this and update module auth-settings again.
            dispatch({ type: authSettingsConstant.SET_MODULE_SIX_PIN_REGISTERED, payload: { module: module } });
            resolve();
        });
    };
}

// set pin locked, when member exceed limit, set lock status to trigger rerender for sixPinNotice component
function setSixPinLocked() {
    return (dispatch) => {
        return new Promise((resolve) => {
            // transitionFromUnbindToBindSixPin indicate user just from unbind status become binded. To control some logic e.g. detect this and update module auth-settings again.
            dispatch({ type: authSettingsConstant.SET_SIX_PIN_LOCKED, payload: {} });
            resolve();
        });
    };
}

// when setSixPinLocked trigger, useEffect will auto trigger setModuleSixPinLocked by module
function setModuleSixPinLocked(module) {
    return (dispatch) => {
        return new Promise((resolve) => {
            // transitionFromUnbindToBindSixPin indicate user just from unbind status become binded. To control some logic e.g. detect this and update module auth-settings again.
            dispatch({ type: authSettingsConstant.SET_MODULE_SIX_PIN_LOCKED, payload: { module: module } });
            resolve();
        });
    };
}
