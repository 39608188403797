// react/library
import React from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation, Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import { AiOutlineCopy } from 'react-icons/ai';
import DatePicker from 'react-datepicker';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// components/files
import TransactionNote from 'root/webapp/shared/transaction-note/transaction-note.selector';

// controller/logic
import vm from '../../reload.controller';
import core from 'root/utils/core';
import transactionHelper from 'root/utils/transaction-helper';
import BankIcon from '../../../../../../../../components/molecules/bank-icon/bank-icon';

class BankTransfer extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
    }

    componentDidMount() {
        vm.initCompSettings();
        if (this.state.depositAllSettings !== null) {
            vm.initReloadPage();
            vm.getMerchantAndMemberBanks();
            // vm.getCorrectDepositMinMaxLimit();
        }
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    componentDidUpdate() {}

    clearReceipt() {
        this.setState({
            receiptImg: [],
            receiptImgFile: null,
            isReceiptValid: false,
        });
    }

    onBankClick(bank) {
        this.clearReceipt();
        vm.onBankChanged(bank);
    }

    render() {
        const {
            bankList,
            selectedBankIndex,
            depositAmountOptions,
            depositMinMaxLimit,
            depositAllSettings,
            fields,
            receiptImg,
            hoveredIndex,
            errors,
            amountErrorValue,
            reload_date,
            receiptRequired,
            showAmountField,
        } = this.state;
        const { user, portal } = this.props;

        let transactionNoteProps = {
            hideDisplayInfo: this.state.compSettings.hideDisplayInfo,
            customOnlineTransactionMode: this.props.t('transaction:transaction.deposit.onlinetransfer.online', 'Online'),
            depositMinMaxLimit: this.state.depositMinMaxLimit,
            showCustomArticle: this.state.showCustomDepositArticle,
            customArticleContent: this.state.showCustomDepositArticleContent,
            customArticleIsJson: this.state.customArticleIsJson,
            customContentStyle: this.state.customContentStyle,
            hideDefaultNote: [!this.state.showDefaultArticle, true, true],
            transactionMethodName: this.props.t('transaction:transaction.deposit.reload.trueMoney', 'True Money'),
        };

        let userCurrency = user && user.account && user.account.currency ? user.account.currency : null;

        if (userCurrency === 'KHUSD') userCurrency = 'USD';

        return (
            <Translation>
                {(t) => (
                    <>
                        <div className='group-item bank-options reload'>
                            <div className='title'>
                                <span>{t('transaction:transaction.deposit.onlinetransfer.bank', 'Bank Options')}</span>
                                <span className='required'>*</span>
                            </div>
                            <div className='bank-list'>
                                <ul>
                                    {bankList.map((bank, i) => (
                                        <li
                                            key={i}
                                            className={[
                                                'icon-hover-box',
                                                selectedBankIndex === i ? 'on' : '',
                                                bank.code.toLowerCase() === 'ezpqr' || bank.code.toLowerCase() === 'h2pp' ? 'qrbank-item' : '',
                                            ].join(' ')}
                                            onClick={() => {
                                                this.onBankClick(bank);
                                            }}
                                            onMouseEnter={() => {
                                                this.setState({ hoveredIndex: i });
                                            }}
                                            onMouseLeave={() => {
                                                this.setState({ hoveredIndex: null });
                                            }}
                                        >
                                            <span className={`icon-box ${bank.code.toLowerCase() === 'ezpqr' || bank.code.toLowerCase() === 'h2pp' ? 'qrbank-icon-box' : ''}`}>
                                                <i
                                                    className={[
                                                        'icon-bank',
                                                        selectedBankIndex === i || (hoveredIndex !== null && hoveredIndex === i) ? 'icon-bank-on' : 'icon-bank-off',
                                                        bank.code.toLowerCase() === 'ezpqr' || bank.code.toLowerCase() === 'h2pp' ? 'qrbank-icon-bank' : '',
                                                    ].join(' ')}
                                                >
                                                    <BankIcon bankCode={bank.code} />
                                                </i>
                                            </span>
                                            <span>{bank.name}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        {bankList.length > 0 && (
                            <div className='group-item input-item'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.cashdeposit.bankaccount', 'Bank Account')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='bank-account-container'>
                                    <div className='input-box'>
                                        <input
                                            id='backaccount'
                                            type='text'
                                            autoComplete='off'
                                            disabled={true}
                                            readOnly={true}
                                            value={bankList[selectedBankIndex].accountHolder}
                                        />
                                        <AiOutlineCopy onClick={() => core.copy(t, bankList[selectedBankIndex].accountHolder)}></AiOutlineCopy>
                                    </div>
                                    <div className='input-box'>
                                        <input
                                            id='backaccount'
                                            type='text'
                                            autoComplete='off'
                                            disabled={true}
                                            readOnly={true}
                                            value={bankList[selectedBankIndex].accountNumber}
                                        />
                                        <AiOutlineCopy onClick={() => core.copy(t, bankList[selectedBankIndex].accountNumber)}></AiOutlineCopy>
                                    </div>
                                </div>
                            </div>
                        )}

                        {showAmountField && (
                            <div className='group-item input-item'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.onlinetransfer.amount', 'Deposit Amount')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='amount-content'>
                                    <div className='input-box'>
                                        <div className='deposit-input-box'>
                                            <input
                                                id='depositamount'
                                                type='numeric'
                                                autoComplete='off'
                                                placeholder={t('global:global.form.online-transfer-amount-placeholder', {
                                                    min: window.SPL_Other.formatAmount(depositMinMaxLimit ? depositMinMaxLimit.min : 0),
                                                    max: window.SPL_Other.formatAmount(depositMinMaxLimit ? depositMinMaxLimit.max : 0),
                                                })}
                                                min={'0'}
                                                value={fields['amount']}
                                                onChange={(e) => vm.handleAmountChange(e)}
                                                onBlur={() => {
                                                    transactionHelper.handleValidation(this);
                                                }}
                                            />
                                            {portal?.settings?.features?.depositPagesSettings?.enableAccValueButton && depositAmountOptions?.length > 0 && (
                                                <div className='deposit-amount-clear-button' onClick={() => transactionHelper.clearInputFieldAmount(this)}>
                                                    <img src='/public/html/images/settings/clear-button.svg' alt='clear-button-img' />
                                                </div>
                                            )}
                                        </div>
                                        <div className='error-validation'>
                                            <Trans
                                                i18nKey={errors['amount']}
                                                values={{ amount: window.SPL_Other.formatAmount(amountErrorValue), currency: userCurrency }}
                                            ></Trans>
                                        </div>
                                    </div>
                                    <ul>
                                        {depositAmountOptions.map((item) => (
                                            <li
                                                key={item}
                                                onClick={() => {
                                                    transactionHelper.onDepositAmountClicked(this, item);
                                                }}
                                            >
                                                {item}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        )}

                        <div className='group-item input-item'>
                            <div className='title'>
                                <span>{t('transaction:transaction.deposit.reload.date_time', 'Deposit Date/Time')}</span>
                                <span className='required'>*</span>
                            </div>
                            <div className='date-content'>
                                <DatePicker
                                    selected={reload_date}
                                    onChange={(date) => vm.getReloadDate(date)}
                                    timeInputLabel='Time:'
                                    dateFormat='dd/MM/yyyy HH:mm'
                                    showTimeInput
                                />
                            </div>
                        </div>

                        {depositAllSettings.showRefId && (
                            <div className='group-item input-item'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.deposit.cashdeposit.referenceid', 'Reference')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='amount-content'>
                                    <div className='input-box'>
                                        <input
                                            id='reference'
                                            type='text'
                                            autoComplete='off'
                                            placeholder={t('transaction:transaction.deposit.cashdeposit.referenceid', 'Reference')}
                                            value={fields['reference']}
                                            onChange={(e) => transactionHelper.handleFieldChanged(this, e, 'reference')}
                                            onBlur={() => {
                                                transactionHelper.handleValidation(this);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        {receiptImg.length > 0 && (
                            <div className='group-item'>
                                <div className='title'></div>
                                <div className='amount-content'>
                                    <div className='input-box receipt-image-box'>
                                        <LazyLoadImage src={receiptImg} alt='' />
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className='group-item submit-container'>
                            <div className='title'></div>
                            <div className='amount-content'>
                                <div className='flexbox'>
                                    <button
                                        className='btn btn-submit'
                                        onClick={() => {
                                            vm.submitForm();
                                        }}
                                        disabled={vm.isSubmitButtonDisabled()}
                                    >
                                        {t('transaction:transaction.deposit.deposithistory.submitbtn', 'SUBMIT').toUpperCase()}
                                    </button>
                                    {depositAllSettings.uploadReceipt && (
                                        <button
                                            className='btn btn-reset'
                                            onClick={() => {
                                                transactionHelper.handleUploadReceiptButtonOnClick();
                                            }}
                                        >
                                            {t('transaction:transaction.deposit.cashdeposit.upload', 'UPLOAD RECEIPT').toUpperCase()}
                                            <input
                                                id='hiddenFileInput'
                                                style={{ display: 'none' }}
                                                type='file'
                                                accept='image/*'
                                                key={receiptImg}
                                                onChange={(e) => vm.handleReceiptChange(e)}
                                            ></input>
                                        </button>
                                    )}
                                </div>
                                {receiptRequired && <div className='error-validation'>{t('transaction:transaction.deposit.receiptIsRequired')}</div>}
                            </div>
                        </div>

                        <TransactionNote {...transactionNoteProps} />
                        {/* <div className='notice-box'>
                            <span className='item-title'>{t('transaction:transaction.important-notice', 'IMPORTANT NOTICE')}</span>
                            <div className='display-info'>
                                <span className='key'>{t('transaction:transaction.deposit.reload.option1', 'Option: True Money')}</span>
                                <span className='value'></span>
                            </div>
                            <div className='display-info'>
                                <span className='key'>{t('transaction:transaction.deposit.reload.mode1', 'Mode: Online')}</span>
                                <span className='value'></span>
                            </div>
                            <div className='display-info'>
                                <span className='key'>{t('transaction:transaction.deposit.onlinetransfer.min/maxlimit', 'Min/Max Limit')}</span>
                                <span className='value'>
                                    {depositMinMaxLimit === null ? (
                                        <SmallLoading></SmallLoading>
                                    ) : (
                                        ` ${this.props.user.account.currency} ${window.SPL_Other.formatAmount(depositMinMaxLimit ? depositMinMaxLimit.min : 0)} 
                                                / ${window.SPL_Other.formatAmount(depositMinMaxLimit ? depositMinMaxLimit.max : 0)}`
                                    )}
                                </span>
                            </div>

                            <div className='display-info'>
                                <span className='key'>{t('transaction:transaction.deposit.cashdeposit.dailylimit', 'Daily Limit:')}</span>
                                <span className='value'>&nbsp;{t('transaction:transaction.deposit.cashdeposit.unlimited', 'Unlimited')}</span>
                            </div>
                            <div className='display-info'>
                                <span className='key'>{t('transaction:transaction.deposit.cashdeposit.totalallowed', 'Total Allowed:')}</span>
                                <span className='value'>&nbsp; {t('transaction:transaction.deposit.cashdeposit.unlimited', 'Unlimited')}</span>
                            </div>

                            <div className='display-info'>
                                <p>
                                    {t(
                                        'transaction:transaction.deposit.reload.note1',
                                        'Now you can deposit by using True Money anytime anywhere. Normally True Money transfer will take about 3 minutes. 3rd party account deposit is not allowed. If you have any inquiry, please contact our live support.'
                                    )}
                                </p>
                            </div>
                        </div> */}
                    </>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['transaction', 'settings'])(withRouter(BankTransfer)));
