import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Translation, withTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { mapStateToProps } from 'root/redux/selector/selector';
import SVGElements from 'root/components/SVGElements';
import commonUtil from 'root/utils/common-util';
import leaderboardService from 'root/utils/leaderboard-service';

import vm from '../wap/home-member-profile.controller';
class HomeMemberProfile extends React.Component {
    constructor(props) {
        super();
        vm.init(this);
        vm.state = {};
    }

    componentDidMount() {
        vm.initCompSettings();
        this.getCurrentPage();
        this.countMsg();

        leaderboardService.getMemberRank(this);
        leaderboardService.refreshLeaderboardMonthAndYear(this);
        this.msgId = setInterval(() => {
            this.countMsg();
        }, 60000);

        setTimeout(() => {
            commonUtil.loadMemberGroupAutomationSummary(this, this.props.user);
        }, 100);
    }

    getCurrentPage() {
        const { location } = this.props;
        let pathname = location.pathname,
            color = '#ff4e00';

        if (pathname.startsWith('/')) {
            pathname = pathname.split('/');
            pathname = pathname.slice(1);
        }

        switch (pathname[0]) {
            case 'sports':
                color = '#00a1ff';
                break;

            case 'esports':
                color = '#ff4e00';
                break;

            case 'casino':
                color = '#f3c301';
                break;

            case 'slots':
                color = '#eb1c22';
                break;

            case 'poker':
                color = '#c500f8';
                break;

            case 'fishing':
                color = '#00dacf';
                break;

            case 'games':
                color = '#fd03c0';
                break;

            case 'vip':
                color = '#cd9c31';
                break;

            case 'lottery':
                color = '#00d32e';
                break;

            case 'cockfight':
                color = '#935a2e';
                break;

            case '4d':
                color = '#9e88eb';
                break;

            default:
                color = color;
                break;
        }

        this.setState({ selectedMenuColor: color });
    }

    countMsg = () => {
        if (this.props.user.isLogin) {
            window.SPL_Member.getUnreadMessageCount().then((data) => {
                this.setState({ inboxCount: data.unreadInboxCount, notificationCount: data.unreadNotificationsCount });
            });
        }
    };

    render() {
        const { history, user, location } = this.props;
        const { currentMemberRank, compSettings } = this.state;

        let classBasedOnPath = location.pathname.substring(1);

        return (
            <Translation>
                {(t) => (
                    <div className='acct-bar' style={{ height: user.isLogin ? '' : '0px' }}>
                        {user.account && (
                            <div className='member-info'>
                                <div className='name-box'>
                                    <div className='img-face'>
                                        <LazyLoadImage src={'/public/html/images/mobile/personal.png'} alt='' />
                                        {this.state.inboxCount + this.state.notificationCount > 0 && (
                                            <span className='msg-tag'>{this.state.inboxCount + this.state.notificationCount}</span>
                                        )}
                                    </div>
                                    <span className='username'>{user.account.login ? user.account.login : '-'}</span>
                                </div>

                                <div className='level-box'>
                                    {/* <i className="icon-level"></i> */}
                                    <LazyLoadImage className='icon-level' src={this.state.memberGroupImg} />
                                    <div>
                                        <span>{t('settings:settings.memberGroup', 'Member Level')}</span>
                                        {compSettings && compSettings.leaderboardCurrencyShow && currentMemberRank ? (
                                            <span className='leaderboard-rank' style={{ color: this.state.memberGroupColor }}>
                                                {currentMemberRank}
                                            </span>
                                        ) : (
                                            <span style={{ color: this.state.memberGroupColor }}>{user.account.groupName ? user.account.groupName : '-'}</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}

                        <ul>
                            {this.state.navList.map(({ key, name, path }) => (
                                <li
                                    key={key}
                                    className={history.location.pathname == path ? 'on' : ''}
                                    onClick={() => {
                                        history.location.pathname != path && history.replace(path);
                                    }}
                                >
                                    {this.state.selectedMenuColor && !this.state.isLoading && (
                                        <span className={`icon-box ${classBasedOnPath}-icon-box`}>
                                            {/* <i className={"icon-wap-" + key}></i> */}
                                            <SVGElements name={key} className={`icon-wap-${key} ${key}`} color={this.state.selectedMenuColor} />
                                        </span>
                                    )}
                                    <span>{t(name)}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation('settings')(withRouter(HomeMemberProfile)));
