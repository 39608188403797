import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import React from 'react';
import { CMS_BANK_IMAGE_PATH } from '../../../utils/constants';

const BankIcon = ({ bankCode }) => {
    const userRedux = useSelector((state) => state.user);
    const currency = userRedux?.account?.currency;

    if (bankCode) {
        return (
            <SVG src={`${CMS_BANK_IMAGE_PATH}/${currency}/${bankCode}.svg`}>
                <LazyLoadImage alt={bankCode} src={`/public/images/bankImg/${currency}/${bankCode}.png`} />
            </SVG>
        );
    } else {
        return <SVG src={`/public/html/images/common_img/icon-default-bank.svg`} />;
    }
};

BankIcon.propTypes = {
    bankCode: PropTypes.string.isRequired,
};

export default BankIcon;

export const CryptoBankIcon = ({ bankCode, coin }) => {
    const userRedux = useSelector((state) => state.user);
    const currency = userRedux?.account?.currency;
    return (
        <SVG src={`${CMS_BANK_IMAGE_PATH}/${currency}/${bankCode}.svg`}>
            <SVG src={`${CMS_BANK_IMAGE_PATH}/${currency}/${coin}.svg`} />
        </SVG>
    );
};
