import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';

class HistoryDetailDialog extends PureComponent {
    props = null;

    constructor(props) {
        super();
        this.props = props;
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        return (
            <Translation>
                {(t) => (
                    <div className='m-bonus-detail-modal-overlay'>
                        <div className='custom-modal-container'>
                            <div className='custom-modal-header'>
                                {t('settings:settings.myBonus', 'My Bonus')}
                                <button className='close-btn' onClick={() => this.props.toggleModal('details')}>
                                    X
                                </button>
                            </div>
                            <div className='custom-modal-body'>
                                <div className='m-history-table'>
                                    {this.props.displayList.map((display, index) => {
                                        return (
                                            <div key={index} className='m-history-row'>
                                                <label className='m-history-column'>{display.key}</label>
                                                <span className='m-history_dott'>: </span>
                                                <span className='m-history-column'>{display.value}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['home', 'transaction'])(HistoryDetailDialog));
