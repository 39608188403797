import React from 'react';
import Odometer from 'react-odometerjs';

function genRand(min, max, decimalPlaces) {
    var rand = Math.random() * (max - min) + min;
    var power = Math.pow(10, decimalPlaces);
    return Math.floor(rand * power) / power;
}

class JackpotCarousel extends React.Component {
    constructor() {
        super();
        this.state = {
            jackpotAmount: null,
            timer: null,
        };
    }

    componentDidMount() {
        if (this.props.amount > 0) {
            let timer = setInterval(() => {
                const randomNum = genRand(0.01, 0.99, 2);
                let newNumber = (parseFloat(this.state.jackpotAmount) + randomNum).toFixed(2);
                let lastDigit = newNumber.slice(-1);
                if (parseInt(lastDigit) === 0) {
                    newNumber = parseFloat(newNumber) + 0.11;
                }

                this.setState({
                    jackpotAmount: newNumber,
                });
            }, 3000);

            this.setState({ jackpotAmount: this.props.amount, timer: timer });
        }
    }

    componentWillUnmount() {
        if (this.state.timer !== null) {
            clearInterval(this.state.timer);
        }
        this.setState = () => {
            return;
        };
    }

    render() {
        return (
            <div className={this.props.customClass ? this.props.customClass : ''}>
                <Odometer value={this.state.jackpotAmount} format='(,ddd).dd' dur={500} />
            </div>
        );
    }
}

export default JackpotCarousel;
