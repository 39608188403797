import React, { lazy } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import core from 'root/utils/core';

import DesktopVip from './desktop/Vip';
import MobileVip from './mobile/Vip';

class Vip extends React.Component {
    components = {
        desktop: {
            default: DesktopVip,
        },
        mobile: {
            default: MobileVip,
        },
    };

    render() {
        const TagName = core.getCorrectComponent(this.components, 'vip', this.props.screen.viewType);
        return <TagName></TagName>;
    }
}

export default connect(mapStateToProps, null)(Vip);
