import { gameConstants } from '../constant';

const initialState = {
    gameProvider: {},
    url: '',
};

export function game(state = initialState, action) {
    switch (action.type) {
        case gameConstants.LOAD_GAME_PAGE:
            return {
                ...state,
                gameProvider: action.payload,
            };
        case gameConstants.LAUNCH_GAME:
            return {
                ...state,
                url: action.payload,
            };
        default:
            return state;
    }
}
