import React from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import DatePicker from 'react-datepicker';
import { BsArrowUpDown } from 'react-icons/bs';
import Pagination from 'root/webapp/shared/pagination/pagination.selector';
import moment from 'moment';
import FlipMove from 'react-flip-move';

import vm from '../transfer.controller';

class Transfer extends React.Component {
    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        vm.initCompSettings();
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        const { customTranslationObj } = this.state;
        const { language } = this.props;
        let datepickerLanguage = '';
        if (language.countryLanguageKey === 'TH_TH') {
            datepickerLanguage = 'th';
        } else if (language.countryLanguageKey === 'ZH_MY') {
            datepickerLanguage = 'zhCN';
        }
        // vm.translateEnum
        return (
            <Translation>
                {(t) => (
                    <div>
                        <div>
                            <div className='group-item transaction-date'>
                                <div className='title'>
                                    <span>{t('transaction:transaction.history.filter.trans_date')}</span>
                                    <span className='required'>*</span>
                                </div>
                                <div className='group'>
                                    <ul>
                                        {(this.state.date.list || []).map((item, i) => (
                                            <li
                                                key={item}
                                                className={[i === this.state.date.active ? 'on' : ''].join(' ')}
                                                onClick={() => {
                                                    vm.handleDateRange(i, this);
                                                    this.setState({ ...this.state, date: { ...this.state.date, active: i } });
                                                }}
                                            >
                                                <span>{t(item)}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className='group-item date-select'>
                                <div className='title'>{t('transaction:transaction.history.filter.start_date', 'Start Date')}</div>
                                <div className='group'>
                                    <div className='input-box'>
                                        <label>
                                            <DatePicker
                                                locale={datepickerLanguage}
                                                autoComplete='off'
                                                type='text'
                                                placeholderText={t('transaction:transaction.history.filter.start_date', 'Start Date')}
                                                selectsStart
                                                selected={this.state.fields.startDate}
                                                onChange={(date) => vm.handleDateChanged(date, true, this)}
                                                dateFormat='yyyy-MM-dd'
                                            />
                                            {/* <input type="text" placeholder="2020-03-30" /> */}
                                            <i className='icon-date'></i>
                                        </label>
                                    </div>
                                    <span>{t('transaction:transaction.history.filter.to')}</span>
                                    <div className='input-box'>
                                        <label>
                                            <DatePicker
                                                locale={datepickerLanguage}
                                                autoComplete='off'
                                                type='text'
                                                placeholderText={t('transaction:transaction.history.filter.end_date', 'End Date')}
                                                selectsStart
                                                selected={this.state.fields.endDate}
                                                onChange={(date) => vm.handleDateChanged(date, false, this)}
                                                dateFormat='yyyy-MM-dd'
                                            />
                                            <i className='icon-date'></i>
                                        </label>
                                    </div>
                                    <button className='btn-submit' onClick={() => vm.searchHistory(0, this)}>
                                        {t('transaction:transaction.deposit.cashdeposit.submitbtn', 'Submit').toUpperCase()}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='history-list'>
                            <div className='table-header table-header-6'>
                                <ul>
                                    <li>
                                        <span>{t('transaction:transaction.history.date', 'Date')}</span>
                                        <BsArrowUpDown onClick={() => vm.sortHistory('createdDate')}></BsArrowUpDown>
                                    </li>
                                    <li>{t('transaction:transaction.history.from', 'From')}</li>
                                    <li>{t('transaction:transaction.history.to', 'To')}</li>
                                    <li>
                                        <span>{t('transaction:transaction.history.amount', 'Amount')}</span>
                                        <BsArrowUpDown onClick={() => vm.sortHistory('amount')}></BsArrowUpDown>
                                    </li>
                                    <li>{t('transaction:transaction.history.promoName', 'Promo Name')}</li>
                                    <li>
                                        {' '}
                                        <span>{t('transaction:transaction.history.status', 'Status')}</span>
                                        <BsArrowUpDown onClick={() => vm.sortHistory('status')}></BsArrowUpDown>
                                    </li>
                                </ul>
                            </div>
                            {this.state.histories.length ? (
                                <FlipMove className='table-list table-list-6' enterAnimation='fade' leaveAnimation='fade'>
                                    {this.state.histories.map((history, i) => {
                                        return (<ul key={i}>
                                            <li>
                                                <span>{moment(history.createdDate).format('YYYY-MM-DD HH:mm').toString()}</span>
                                            </li>
                                            <li>
                                                {customTranslationObj && customTranslationObj[history.fromProvider]
                                                    ? t(customTranslationObj[history.fromProvider])
                                                    : t(history.from)}
                                            </li>
                                            <li>
                                                <span>
                                                    {customTranslationObj && customTranslationObj[history.toProvider]
                                                        ? t(customTranslationObj[history.toProvider])
                                                        : t(history.to)}
                                                </span>
                                            </li>
                                            <li>
                                                <span>{`${history.currency === 'KHUSD' ? 'USD' : history.currency} ${window.SPL_Other.formatAmount(history.amount)}`}</span>
                                            </li>
                                            <li>
                                                <span>{history.promoCode}</span>
                                            </li>
                                            <li>
                                                <span>{t(history.status)}</span>
                                            </li>
                                        </ul>);
                                    })}
                                </FlipMove>
                            ) : (
                                <div className='table-list'>
                                    <span>{t('transaction:transaction.emptyHistory')}</span>
                                </div>
                            )}
                        </div>
                        <Pagination
                            pagingList={this.state.pagingList}
                            handlePrevNextClick={vm.handlePrevNextClick}
                            totalCount={this.state.totalCount}
                            currentPage={this.state.currentPage}
                            itemPerPage={this.state.itemPerPage}
                            handlePageClick={vm.handlePageClick}
                        ></Pagination>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['history', 'settings', 'transaction', 'providerName'])(withRouter(Transfer)));
