import { PhoneNumberUtil } from 'google-libphonenumber';

export function pu_getTelCodeFromPhoneNumber(phoneNumber) {
    const phoneUtil = PhoneNumberUtil.getInstance();
    try {
        phoneUtil.parse(phoneNumber); // pre validate phone, sometime the format is invalid set from BE (e.g. auto generated)
        const number = phoneUtil.parseAndKeepRawInput(phoneNumber);
        const isValid = phoneUtil.isValidNumber(number);

        if (isValid) {
            const telCode = number.getCountryCode();
            return telCode;
        } else {
            // asign user egister currency telCode
            return null;
        }
    } catch (error) {
        console.error(error);
        return null;
    }
}

export function pu_maskPhone(base) {
    if (base && base !== '') {
        if (base.includes('+')) {
            base = base.substring(1, base.length);
        }

        if (base.length > 4) {
            return '+' + repeatStr('*', 4) + base.substring(base.length - 4);
        } else {
            return '+' + repeatStr('*', 4) + base;
        }
    }
    return base;
}

function repeatStr(character, length) {
    var result = '';
    for (var x = 0; x < length; x++) result += character;
    return result;
}
