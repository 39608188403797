import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import core from 'root/utils/core';
import DesktopMaintenance from './desktop/maintenance';
import MobileMaintenance from './mobile';

class Maintenance extends React.Component {
    components = {
        desktop: {
            default: DesktopMaintenance,
        },
        mobile: {
            default: MobileMaintenance,
        },
    };

    render() {
        const TagName = core.getCorrectComponent(this.components, 'maintenance', this.props.screen.viewType);
        return <TagName />;
    }
}

export default connect(mapStateToProps, null)(Maintenance);
