import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { TweenMax } from 'gsap';

import LeftSubMenu from 'root/webapp/shared/component/wap/LeftSubMenu';
import HomeMemberProfile from 'root/webapp/shared/component/wap/HomeMemberProfile';

import vm from '../fast-games.controller';

class FastGames extends React.Component {
    tween1;
    tween2;

    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        vm.getMobileProviderList().then(() => {
            this.toggleAni();
        });
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    toggleAni() {
        this.tween1 && this.tween1.kill();
        this.tween2 && this.tween2.kill();

        TweenMax.set('.brand-page ul.ani-ul > li', {
            alpha: 0,
        });

        this.tween1 = TweenMax.delayedCall(0.3, function () {
            this.tween2 = TweenMax.staggerTo('.brand-page ul.ani-ul > li', 0.3, { alpha: 1 }, 0.08);
        });
    }

    onClick(provider) {
        const { history } = this.props;
        history.push({
            pathname: '/fastgames/' + provider.routeName,
            query: { provider: provider.provider },
        });
    }

    checkMaintenance(slot) {
        if (!slot.underMaintenance) {
            vm.updateSelectedProvider(slot);
            this.onClick(slot);
        }
    }

    render() {
        return (
            <Translation>
                {(t) => (
                    <Fragment>
                        <div className='fastgames-page'>
                            <HomeMemberProfile />
                            <div className='fastgames-box-container'>
                                <div className='brand-page'>
                                    <LeftSubMenu />
                                    <div className='fastgames-list-box'>
                                        <span className='item-title'>{t('slot:slot.mobile.fg', 'Fun Fast Game')}</span>
                                        <ul className='ani-ul'>
                                            {this.state.brandList.map((slotProvider, i) => (
                                                <li
                                                    key={i}
                                                    onClick={() => {
                                                        this.checkMaintenance(slotProvider);
                                                    }}
                                                >
                                                    <LazyLoadImage
                                                        src={'/public/html/images/fast-games/mobile/' + slotProvider.key + '.png'}
                                                        alt=''
                                                        className={slotProvider.underMaintenance ? 'maintenance-cover' : ''}
                                                    />
                                                    {slotProvider.isHotProvider && (
                                                        <div className='hoticon-fastgames-container'>
                                                            <LazyLoadImage src='/public/html/images/hoticon_mobile.svg' alt=''></LazyLoadImage>
                                                        </div>
                                                    )}
                                                     {slotProvider.isNewProvider && (
                                                        <div className='newicon-fastgames-container'>
                                                            <LazyLoadImage src='/public/html/images/slots/newicon_mobile.svg' alt=''></LazyLoadImage>
                                                        </div>
                                                    )}
                                                    {slotProvider.underMaintenance && <div className='maintenance-hover-box'></div>}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation('slot')(withRouter(FastGames)));
