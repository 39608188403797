import core from 'root/utils/core';
import commonUtil from '../../../../../../utils/common-util';
import transactionHelper from '../../../../../../utils/transaction-helper';
import { trans_handlePGDepositCountDown } from 'root/utils/transaction-util';
import notification from 'root/utils/notification';
import { AMOUNT_DEPOSIT_METHOD_CODE } from 'root/utils/constants';

const METHOD_OR_CHANNEL_CODE = 'QP';
const controller = {
    vm: null,

    init(vm) {
        controller.vm = vm;
        controller.vm.state = {
            filteredDepositApiJson: controller.vm.props.filteredDepositApiJson,
            depositAllSettings: controller.vm.props.depositSettings,
            depositAmountOptions: [],

            depositMinMaxLimit: { min: 0, max: 0 },
            methodList: [],
            bankList: [],
            selectedMethodCache: {},
            selectedMethodIndex: 0,
            selectedBankIndex: 0,
            hoveredIndex: null,

            // should be can put at parent de variables
            fields: {
                amount: '',
                reference: '',
            },
            errors: {},
            disableDepositButton: false,
            isFormValid: false,
            showAmountField: true,

            //notice artical settings
            showDefaultArticle: false,
            showCustomDepositArticle: false,
            showCustomDepositArticleContent: '',

            enableCustomArticleJSON: false,
            compSettings: [],
            depositSubmitCountdown: null,
        };
    },

    initCompSettings() {
        const { filteredDepositApiJson } = controller.vm.state;
        const { customCountdown } = controller.vm.props;
        const paymentCode = filteredDepositApiJson && filteredDepositApiJson.optionCode;

        return new Promise((resolve) => {
            // Step 1: Standard way to get from portal settings
            let tempSettings = commonUtil.getSettingFromPortalSettings({
                vm: controller.vm,
                settingName: 'quickpaySettings',
            });

            // Step 2: Massage again to get the specific data you need (Skip if not needed)

            // Step 3: Initial all into compSettings
            let compSettings = {
                hideDisplayInfo: tempSettings.hideDisplayInfo,
                showCustomArticle: tempSettings.showCustomArticle,
                enableCustomArticleJSON: tempSettings.enableCustomArticleJSON,
                isCustomArticlePath: tempSettings.isCustomArticlePath,
            };

            controller.vm.setState({ compSettings }, () => {
                const { showCustomArticle } = controller.vm.state.compSettings;
                if (showCustomArticle) {
                    controller.readCustomDepositArticle();
                }
                trans_handlePGDepositCountDown(controller.vm, false, customCountdown, paymentCode, Date.now().valueOf(), { disableDepositButton: true });
                resolve();
            });
        });
    },

    loadDepositDetails() {
        const { filteredDepositApiJson } = controller.vm.state;

        // if enableJsonAPi true from deposit-landing
        if (filteredDepositApiJson && filteredDepositApiJson.channel) {
            controller.newDepositMethod();
        }
    },

    async newDepositMethod() {
        const vm = controller.vm;
        const { paymentGatewaySetting, user } = vm.props;
        const { filteredDepositApiJson, selectedMethodIndex } = vm.state;
        const currency = user.account.currency;

        let _bankList = [];
        let _methodList = [];

        if (filteredDepositApiJson?.optionCode !== METHOD_OR_CHANNEL_CODE) {
            return;
        }

        for (let channel in filteredDepositApiJson.channel) {
            if (filteredDepositApiJson.channel[channel]) {
                let method = filteredDepositApiJson.channel[channel][0];
                method.displayName = method.displayName ? method.displayName : window.SPL_Translation.getBankChannelTranslation(method, 'channelCode');
                // method.svgData = await window.SPL_Other.renderTemplateSVG(`${commonUtil.getDepositImgPath()}${method.code}.svg`, `${method.code}`);
                _methodList.push(method);
            }
        }
        if (_methodList.length > 0) {
            core.portalIsLoading(vm.props, true);
            let selectedMethodObj = _methodList[selectedMethodIndex];
            window.SPL_Transaction.getDepositDetailsOnMethodChange([], selectedMethodObj, currency).then(async (data) => {
                _bankList = data.banks ? data.banks : [];
                // for (const bank of _bankList) {
                //     bank.svgData = await window.SPL_Other.renderTemplateSVG(`${commonUtil.getDepositImgPath()}${bank.code}.svg`, `${bank.code}`);
                // }

                // cache 1st selected method list's banks
                let selectedMethodCache = vm.state.selectedMethodCache;
                let selectedMethodObj = _methodList[selectedMethodIndex];
                selectedMethodCache[selectedMethodObj.id] = _bankList;

                const method = _methodList[0];
                const { showAmountField, isFormValid } = transactionHelper.getDepositAmountFieldNotRequiredConfig(paymentGatewaySetting, method);

                vm.setState(
                    {
                        methodList: _methodList,
                        selectedMethodCache,
                        bankList: _bankList,
                        showAmountField,
                        isFormValid,
                    },
                    () => {
                        // controller.getCorrectDepositMinMaxLimit();
                        commonUtil.getDepositMinMax(
                            vm,
                            'QP',
                            controller.vm.props.user.account.currency,
                            controller.vm.state.methodList[controller.vm.state.selectedMethodIndex].code
                        );
                        core.portalIsLoading(vm.props, false);
                    }
                );
            });
        }
    },

    onMethodChanged(index) {
        const vm = controller.vm;
        const { paymentGatewaySetting } = vm.props;
        const { methodList, fields } = vm.state;
        const currency = vm.props.user.account.currency;

        let _selectedMethodCache = vm.state.selectedMethodCache;
        let newSelectedMethodObj = methodList[index];
        fields.amount = '';

        const method = methodList[index];
        const { showAmountField, isFormValid } = transactionHelper.getDepositAmountFieldNotRequiredConfig(paymentGatewaySetting, method);

        vm.setState(
            {
                fields,
                selectedMethodIndex: index,
                selectedBankIndex: 0,
                showAmountField,
                isFormValid,
            },
            () => {
                if (_selectedMethodCache[newSelectedMethodObj.id]) {
                    vm.setState({
                        bankList: _selectedMethodCache[newSelectedMethodObj.id],
                    });
                } else {
                    window.SPL_Transaction.getDepositDetailsOnMethodChange([], newSelectedMethodObj, currency).then(async (data) => {
                        let _bankList = data.banks ? data.banks : [];
                        // cache the selected new methodlist bank list
                        // for (const bank of _bankList) {
                        //     bank.svgData = await window.SPL_Other.renderTemplateSVG(`${commonUtil.getDepositImgPath()}${bank.code}.svg`, `${bank.code}`);
                        // }

                        _selectedMethodCache[newSelectedMethodObj.id] = _bankList;

                        vm.setState({
                            selectedMethodCache: _selectedMethodCache,
                            bankList: _bankList,
                        });
                    });
                }
                // controller.getCorrectDepositMinMaxLimit();
                commonUtil.getDepositMinMax(
                    controller.vm,
                    'QP',
                    controller.vm.props.user.account.currency,
                    controller.vm.state.methodList[controller.vm.state.selectedMethodIndex].code
                );
            }
        );
    },

    onBankChanged(bankObj, onChangeValue) {
        const { selectedBankIndex, bankList } = controller.vm.state;

        // mobile use dao
        if (onChangeValue !== undefined) {
            controller.vm.setState({ selectedBankIndex: onChangeValue }, () => {
                // controller.getCorrectDepositMinMaxLimit(onChangeValue);
                commonUtil.getDepositMinMax(
                    controller.vm,
                    'QP',
                    controller.vm.props.user.account.currency,
                    controller.vm.state.methodList[controller.vm.state.selectedMethodIndex].code
                );
            });
        } else {
            let foundIndex = bankList.indexOf(bankObj);
            if (foundIndex !== selectedBankIndex) {
                controller.vm.setState({ selectedBankIndex: foundIndex }, () => {
                    // controller.getCorrectDepositMinMaxLimit(bankObj);
                    commonUtil.getDepositMinMax(
                        controller.vm,
                        'QP',
                        controller.vm.props.user.account.currency,
                        controller.vm.state.methodList[controller.vm.state.selectedMethodIndex].code
                    );
                });
            }
        }
    },

    handleAmountChange(e) {
        const value = e.target.value;
        if (value) {
            let regex = '';
            if (controller.vm.props.memberDecimalSettings?.[AMOUNT_DEPOSIT_METHOD_CODE.QP]) {
                regex = /^[0-9]+$/;
            } else {
                regex = /^(\d*)\.{0,1}(\d){0,2}$/;
            }
            if (value.toString().match(regex)) {
                transactionHelper.handleFieldChanged(controller.vm, e, 'amount');
            }
        } else {
            transactionHelper.handleFieldChanged(controller.vm, e, 'amount');
        }
    },

    getCorrectDepositMinMaxLimit() {
        const { depositAllSettings, selectedMethodIndex, methodList } = controller.vm.state;
        const currency = controller.vm.props.user.account.currency;
        let _depositMinMaxLimit = { min: 0, max: 0 };
        let methodObj = methodList[selectedMethodIndex];

        if (methodObj && depositAllSettings.depositJson.quickPay[methodObj.code]) {
            _depositMinMaxLimit = depositAllSettings.depositJson.quickPay[methodObj.code][currency];
        }
        controller.vm.setState({ depositMinMaxLimit: _depositMinMaxLimit });
    },

    submitForm() {
        const { isFormValid, disableDepositButton } = controller.vm.state;

        if (isFormValid && !disableDepositButton) {
            controller.submitThirdPartyDeposit();
        }
    },

    submitThirdPartyDeposit() {
        const vm = controller.vm;
        const { methodList, bankList, fields, selectedBankIndex, selectedMethodIndex, depositSubmitCountdown } = vm.state;
        const { language, filteredDepositApiJson, depositSubmission, t, customCountdown } = vm.props;
        const { features } = vm.props.portal.settings;

        const countdown = customCountdown || typeof customCountdown === 'number' ? customCountdown : 30;
        const paymentCode = filteredDepositApiJson && filteredDepositApiJson.optionCode;
        const submissionData = depositSubmission && depositSubmission.submissionData;
        const paymentObj = submissionData && submissionData[paymentCode];

        if (paymentObj && paymentObj.submissionRetryTime > Date.now().valueOf()) {
            // controller.vm.setState({ disableDepositButton: true });
            notification.showNotification('error', t('transaction:transaction.deposit.resubmitError', { retryTime: depositSubmitCountdown }));
        } else {
            proceedSubmit();
        }

        function proceedSubmit() {
            let hideLangPathUrl = false;
            if (features && features.hideLangPathUrl) {
                hideLangPathUrl = features.hideLangPathUrl;
            }
            let methodObj = methodList[selectedMethodIndex];
            let bankCode = null;
            if (bankList && bankList.length > 0 && bankList[selectedBankIndex]) {
                bankCode = bankList[selectedBankIndex].code;
            }
            let amount = fields.amount;
            let langPath = language.countryLanguageKey.replace('_', '-').toLowerCase();
            let callbackUrl = `/${langPath}/myaccount/deposit`;
            let stateUrl = '';

            if (hideLangPathUrl) {
                stateUrl = '/quickpayredirect';
            } else {
                stateUrl = `/${langPath}/quickpayredirect`;
            }
            window.SPL_Transaction.requestDepositOnlineTransfer(methodObj, bankCode, amount, null, null, stateUrl, language.key, callbackUrl).then((data) => {
                if (data.errorCode) {
                    notification.showNotification('error', data.message);
                } else {
                    trans_handlePGDepositCountDown(controller.vm, true, countdown, paymentCode, Date.now().valueOf(), { disableDepositButton: false });
                }
            });
        }
    },

    readCustomDepositArticle() {
        const { compSettings } = controller.vm.state;
        const customFilename = compSettings && compSettings.isCustomArticlePath ? 'qp' : '';
        controller.vm.setState({ customFilename: customFilename });
        transactionHelper.readCustomDepositArticle(controller.vm, customFilename);
    },
};

export default controller;
