import { passwordConstants } from '../constant/password.constant';

const initialState = {
    passwordRules: null,
    rawData: {
        passwordConfigApiData: null,
    },
    processedData: {
        passwordConfig: null,
    },
};

export function passwordReducer(state = initialState, action) {
    switch (action.type) {
        case passwordConstants.GET_PASSWORD_RULES:
            return {
                ...state,
                passwordRules: action.payload,
            };

        case passwordConstants.GET_PASSWORD_CONFIG_API:
            return {
                ...state,
                rawData: {
                    ...state.rawData,
                    passwordConfigApiData: action.payload,
                    status: true,
                },
            };

        case passwordConstants.FETCH_PROCESSED_PASSWORD_CONFIG:
            return {
                ...state,
                processedData: {
                    ...state.processedData,
                    passwordConfig: action.payload,
                },
            };

        default:
            return state;
    }
}
