import React from 'react';

class MobileCardRedirect extends React.Component {
    isCalled = false;

    componentDidMount() {
        if (!this.isCalled) {
            this.isCalled = true;
            window.SPL_Transaction.onlineTransferRedirectUrl();
        }
    }

    render() {
        return <div></div>;
    }
}

export default MobileCardRedirect;
