import { Component } from 'react';
import { withRouter } from 'react-router-dom';

class RedirectPage extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.startRedirect();
    }

    componentWillUnmount() {}

    startRedirect() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const liteapp = urlParams.get('liteapp');

        switch (liteapp) {
            case 'iosDL': {
                const affId = window.SPL_Cookie.getCookie('AffCode');
                const addLinkParam = affId ? `?affid=${affId}` : '';
                const param = {
                    dynamicLinkInfo: {
                        domainUriPrefix: 'https://perff.page.link',
                        link: `https://perff.page.link/Eit5${addLinkParam}`,
                        androidInfo: {
                            androidPackageName: 'com.mecards.app',
                            androidMinPackageVersionCode: '18',
                        },
                        iosInfo: {
                            iosBundleId: 'com.app.perff',
                        },
                        navigationInfo: {
                            enableForcedRedirect: true,
                        },
                    },
                };

                const onSuccess = (res) => {
                    window.location.href = res;
                };

                const onError = (err) => {};

                window.SPL_LiteApp.fetchInHouseAppAPI('/v1/shortLinks?key=AIzaSyBzGwzvGT1gp-o_XwGcWYnJ8yJ0DBvrWW0', param).then(onSuccess, onError);

                break;
            }

            case 'androidDL': {
                // do nothing

                break;
            }

            default:
                break;
        }
    }

    render() {
        return null;
    }
}

export default withRouter(RedirectPage);
