import React, { lazy } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import core from 'root/utils/core';

import DesktopPsy8 from './desktop/Psy8';
import MobilePsy8 from './mobile/Psy8';

class Psy8 extends React.Component {
    components = {
        desktop: {
            default: DesktopPsy8,
        },
        mobile: {
            default: MobilePsy8,
        },
    };

    render() {
        const TagName = core.getCorrectComponent(this.components, 'psy8', this.props.screen.viewType);
        return <TagName></TagName>;
    }
}

export default connect(mapStateToProps, null)(Psy8);
