import React, { Fragment } from 'react';
import SlotBanner from './SlotsBanner';
import SlotsGames from './SlotsGames';

class Slots extends React.Component {
    render() {
        return (
            <Fragment>
                <SlotBanner />
                <SlotsGames />
            </Fragment>
        );
    }
}

export default Slots;
