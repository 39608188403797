import React from 'react';
import FishingBanner from './components/FishingBanner';
import FishingGames from './components/FishingGames';

class FishingPanel extends React.Component {
    render() {
        return (
            <div className='provider-panel'>
               <FishingBanner />
               <FishingGames />
            </div>
        );
    }
}

export default FishingPanel;
