import core from 'root/utils/core';
import { userAction } from 'root/redux/action/user.action';
import memberService from 'root/utils/memberService';
import { languageAction } from 'root/redux/action/language.action';
import { walletAction } from 'root/redux/action/wallet.action';
import { generalAction } from 'root/redux/action/general.action';
import commonUtil from '../../../utils/common-util';
import leaderboardService from 'root/utils/leaderboard-service';
import { gu_getSettingFromPortalSettings } from 'root/utils/general-util';
import { promotionAction } from 'root/redux/action/promotion.action';

const controller = {
    vm: null,
    accountDropDownList: [
        {
            name: 'message',
            content: 'global:global.breadcums.messaging',
            route: '/myaccount/messaging',
        },
        {
            name: 'deposit',
            content: 'global:global.breadcums.deposit',
            route: '/myaccount/deposit',
        },
        {
            name: 'withdraw',
            content: 'global:global.breadcums.withdraw',
            route: '/myaccount/withdraw',
        },
        {
            name: 'transfer',
            content: 'global:global.breadcums.transfer',
            route: '/myaccount/transfer',
        },
        {
            name: 'history',
            content: 'global:global.breadcums.history',
            route: '/myaccount/history',
        },
    ],

    init(vm) {
        this.vm = vm;
        let date = new Date();
        let dateMonth = date.getMonth() + 1;
        let dateYear = date.getFullYear();
        this.vm.state = {
            firstLayerMenuList: [],
            secondLayerMenuList: [],
            selectedMenuName: '',
            mainWalletRefreshing: false,
            mainWallet: null,
            dropdownMenuIsShowed: false,
            messageUnreadCountTimer: null,
            totalUnreadMessageCount: 0,
            currentLanguage: '',
            languageList: [],
            tag: '',
            affiliateLink: '',
            // leaderboard fields
            fields: {
                dateYear: dateYear,
                dateMonth: dateMonth,
            },

            // due to current package unable to use rtk, so temp do at here first.
            navMenus: [],
        };
    },

    initCompSettings() {
        const { user } = controller.vm.props;
        return new Promise((resolve) => {
            // Step 1: Standard way to get from portal settings
            let leaderboardSettings = commonUtil.getSettingFromPortalSettings({
                vm: controller.vm,
                settingName: 'leaderboardSettings',
            });

            let showLeaderboardCurrency = false;

            let promotionSettings = gu_getSettingFromPortalSettings({
                vm: controller.vm,
                settingName: 'promotionSettings',
            });
            
            if (user && user.account && user.account.currency) {
                if (leaderboardSettings && leaderboardSettings.leaderboardCurrencyShow) {
                    showLeaderboardCurrency = leaderboardSettings.leaderboardCurrencyShow.some((currency) => currency === user.account.currency);
                }
            }

            // Step 2: Massage again to get the specific data you need (Skip if not needed)
            // Step 3: Initial all into compSettings
            let compSettings = {
                leaderboardCurrencyShow: showLeaderboardCurrency,
                specialForYouEnable: promotionSettings?.specialForYouEnable,
            };

            controller.vm.setState({ compSettings }, () => {
                const { compSettings } = controller.vm.state;

                if (compSettings?.specialForYouEnable) {
                    controller.getAdditionalAccountDropDownList();
                }
                resolve();
            });
        });
    },

    getAdditionalAccountDropDownList() {
        let accountDropDownList = controller.accountDropDownList;
        const { user, language } = controller.vm.props;

        let params = {
            languageKey: language?.countryLanguageKey,
            login: user?.account?.login,
            size: 10,
            page: 0,
        };
        controller.vm.props.dispatch(promotionAction.getPromotionData(params));

         let promo = {
            name: 'specialForYou',
            content: 'global:global.settingTabTitle.specialforyou',
            route: '/myaccount/specialforyou',
        };
        accountDropDownList.push(promo);
        
        controller.vm.setState({ accountDropDownList: accountDropDownList });
    },

    getBrandLogo() {
        const vm = controller.vm;
        const { language } = vm.props;
        window.SPL_Content.getBrandLogo(language.countryLanguageKey).then((res) => {
            vm.setState({ brandLogoObj: res });
        });
    },

    // special handling for selected menu
    specialHandlingForMenu(pathname) {
        switch (pathname.toLowerCase()) {
            // case 'slots':
            //   pathname = 'slot';
            //   break;
            case 'promotions':
                pathname = 'promotion';
                break;
            case 'esports':
                pathname = 'e-sport';
                break;
            case 'cmd368':
            case 'virtualsports-landing':
                pathname = 'sports';
                break;
            default:
                break;
        }
        if (pathname === '') {
            pathname = 'home';
        }

        return pathname;
    },

    getCurrentPage() {
        const { location } = controller.vm.props;
        let pathname = location.pathname;

        let sliceCount = 1;
        if (pathname.startsWith('/')) {
            pathname = pathname.split('/');
            pathname = pathname.slice(sliceCount);
        }

        pathname = controller.specialHandlingForMenu(pathname[0]);
        controller.setDataPage(pathname);
    },

    getMenu(callback) {
        const { language, user, portal } = controller.vm.props;

        window.SPL_Content.getMenu(language.countryLanguageKey, 'web').then((data) => {
            // filter out menu is showing after Login
            let menuDataList = [];

            menuDataList = data;

            if (!user || !user.isLogin) {
                menuDataList = data.filter((nav) => {
                    // if didn't set in custom-menu.json then by default no filter
                    if (!nav.showAfterLogin) {
                        return true;
                    }

                    // if set in custom-menu.json then can filter by using ALL or other languages eg.EN_VN
                    if (nav.showAfterLogin.includes('ALL')) {
                        return false;
                    }

                    if (nav.showAfterLogin.includes(language.countryLanguageKey)) {
                        return false;
                    }

                    return true;
                });
            }

            // purposely filter mobile out because it is at 2nd layer menu
            let firstLayerMenuList = menuDataList.filter((a) => {
                if (!user.isLogin) {
                    return a.name.toLowerCase() !== 'mobile' && a.name !== 'Favourite';
                } else {
                    return a.name.toLowerCase() !== 'mobile';
                }
            });

            // 2nd layer menu list contains mobile at first
            // then manually pump in switch mobile and livechat menu
            let secondLayerMenuList = menuDataList.filter((a) => a.name.toLowerCase() === 'mobile');
            let downloadMenu = {
                name: 'download',
                content: 'global:global.menu.mobile',
                route: '/download',
            };
            let switchMobileMenu = {
                name: 'switch-mobile',
                content: 'global:infoCentre.mobile',
                route: 'switch-mobile',
            };
            let livechatMenu = {
                name: 'livechat',
                content: 'global:global.menu.livechat',
                route: 'livechat',
            };

            secondLayerMenuList.push(downloadMenu);
            secondLayerMenuList.push(switchMobileMenu);
            secondLayerMenuList.push(livechatMenu);

            if (
                portal &&
                portal.settings &&
                portal.settings.showAffiliate &&
                portal.settings.showAffiliate.includes(language.countryLanguageKey) &&
                controller.vm.state.affiliateLink
            ) {
                let affiliateMenu = {
                    name: 'affiliate',
                    content: 'global:footer.affiliate',
                    route: controller.vm.state.affiliateLink,
                };
                secondLayerMenuList.push(affiliateMenu);
            }

            controller.vm.setState(
                {
                    firstLayerMenuList: firstLayerMenuList,
                    secondLayerMenuList: secondLayerMenuList,
                },
                () => {
                    callback && callback();
                }
            );
        });
    },

    menuClicked(menu) {
        const { countryLanguageKey } = controller.vm.props.language;

        if (menu.route === 'switch-mobile') {
            window.SPL_Other.setViewPort('mobile');
        } else if (menu.route === 'livechat') {
            core.openLivechat(controller.vm.props.language.countryLanguageKey, controller.vm.props.screen.viewType);
        } else if (menu.url) {
            let url = menu.url;

            if (menu.url[countryLanguageKey]) {
                url = menu.url[countryLanguageKey];
            }

            window.open(url);
        } else {
            const { selectedMenuName } = controller.vm.state;
            let pathname = menu.route;

            if (pathname && pathname.startsWith('/')) {
                pathname = pathname.slice(1);
            }

            pathname = controller.specialHandlingForMenu(pathname);
            if (selectedMenuName !== pathname) {
                controller.setDataPage(pathname);
                controller.vm.props.history.push(menu.route);
            }
        }

        if (menu.name === 'affiliate') {
            window.open(menu.route);
        }
    },

    setDataPage(pathname) {
        controller.vm.props.dispatch(generalAction.setDataPage(pathname));
        // controller.vm.setState({ selectedMenuName: pathname });
    },

    dropdownMenuClicked(route) {
        let menuObj = {
            route: route,
        };
        controller.menuClicked(menuObj);
    },

    getLanguageList() {
        window.SPL_Content.getLanguageList().then((data) => {
            let currentLanguage = {},
                countryLanguageKey = controller.vm.props.language.countryLanguageKey;

            let user = controller.vm.props.user;
            let account = user.account;
            let memberCurrency = null;

            if (account) {
                memberCurrency = account.currency;

                if (memberCurrency) {
                    data = data.filter((list) => list.currency === memberCurrency);
                }
            }

            for (let i = 0; i < data.length; i++) {
                let languages = data[i].languages;

                for (let j = 0; j < languages.length; j++) {
                    if (languages[j].countryLanguageKey === countryLanguageKey) {
                        Object.assign(currentLanguage, languages[j]);
                    }
                }
            }

            controller.vm.setState({ languageList: data, currentLanguage: currentLanguage });
        });
    },

    changeLanguage(langObject) {
        core.setLanguageKeyAndCountryLanguage(controller.vm, langObject.countryLanguageKey, languageAction, true, false, true);
    },

    updateCurrentLanguage() {
        let countryLanguage = controller.vm.props.language;

        controller.vm.setState({ currentLanguage: countryLanguage });
    },

    openLoginPage() {
        // controller.vm.props.dispatch(portalAction.floatingPageUpdated('login'));
        controller.vm.setState({ tag: 'login' }, () => {
            controller.vm.props.history.push('/login');
        });
    },

    openResetPasswordPage() {
        controller.vm.setState({ tag: 'forgot' }, () => {
            controller.vm.props.history.push('/resetpassword');
        });
    },

    goToRegister() {
        controller.vm.setState({ tag: '' }, () => {
            controller.vm.props.history.push('/register');
        });
    },

    close() {
        controller.vm.setState({ tag: '' }, () => {
            controller.vm.props.history.push('/home');
        });
    },

    logout() {
        controller.vm.props.dispatch(userAction.logout()).then(() => {
            window.location.href = '/home';
        });
    },

    initAfterLogin() {
        if (controller.vm.props.user.isLogin) {
            controller.refreshWallet();
            controller.startMemberMessageUnreadCountTimer();
        }
    },

    refreshWallet() {
        const { mainWalletRefreshing } = controller.vm.state;
        if (!mainWalletRefreshing) {
            controller.vm.props.dispatch(walletAction.udpateLoadWallet(true));
            memberService.getMainWallet(controller.vm);
        }
    },

    restoreWallet() {
        memberService.restoreWallet(controller.vm, controller.vm.props);
    },

    startMemberMessageUnreadCountTimer() {
        if (controller.vm.props.user.isLogin) {
            controller.getMemberMessageCount();
            let messageUnreadCountTimer = setInterval(controller.getMemberMessageCount, 180000);
            controller.vm.setState({ messageUnreadCountTimer: messageUnreadCountTimer });
        }
    },

    stopMemberMessageUnreadCountTimer() {
        const { messageUnreadCountTimer } = controller.vm.state;
        if (messageUnreadCountTimer) {
            clearInterval(messageUnreadCountTimer);
            controller.vm.setState({ messageUnreadCountTimer: null });
        }
    },

    getMemberMessageCount() {
        if (controller.vm.props.user.isLogin) {
            memberService.getMemberMessageCount(controller.vm);
        }
    },

    toggleDropdownMenu(forceClose) {
        let dropdownMenuIsShowed = controller.vm.state.dropdownMenuIsShowed;
        if (forceClose) {
            dropdownMenuIsShowed = false;
        } else {
            dropdownMenuIsShowed = !dropdownMenuIsShowed;
        }
        controller.vm.setState({ dropdownMenuIsShowed: dropdownMenuIsShowed });
    },

    onDateYearChange(value, field) {
        leaderboardService.onDateYearChange(controller.vm, value, field);
    },
};

export default controller;
