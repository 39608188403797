import React, { Fragment } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import providerService from 'root/utils/providerService';

const controller = {
    vm: null,
    providerData: {
        CMD: {
            key: 'cmd',
            provider: 'CMD',
            suspend: [
                { left: -90, bottom: 50, width: 150, height: 90, suspendImg: '/public/html/images/sports/icon/suspend/1_2.png' },
                { left: 650, bottom: 400, width: 50, height: 70, suspendImg: '/public/html/images/sports/icon/suspend/1_3.png' },
                { left: 800, bottom: 410, width: 150, height: 89, suspendImg: '/public/html/images/sports/icon/suspend/1_4.png' },
            ],
            text: 'sport:sport.cmd.desc_1',
            mobileText: 'sport:sport.cmd.mobile_desc_1',
            bgImg: '/public/html/images/sports/cmd-banner.png',
            routeName: 'cmd368',
            maintenance: false,
        },
        IBC: {
            key: 'ibc',
            provider: 'IBC',
            suspend: [
                { left: 380, bottom: 60, width: 70, height: 60, suspendImg: '/public/html/images/sports/icon/suspend/2_2.png' },
                { left: 660, bottom: 280, width: 50, height: 70, suspendImg: '/public/html/images/sports/icon/suspend/2_3.png' },
                // { left: 410, bottom: 270, width: 90, height: 90, suspendImg: '/public/html/images/sports/icon/suspend/2_4.png' },
            ],
            text: 'sport:sport.ibc.desc_1',
            mobileText: 'sport:sport.ibc.mobile_desc_1',
            bgImg: '/public/html/images/sports/ibc-banner.png',
            routeName: 'saba',
            maintenance: false,
        },
        BR: {
            key: 'br',
            provider: 'BR',
            suspend: [
                { left: 0, bottom: 350, width: 60, height: 70, suspendImg: '/public/html/images/sports/icon/suspend/3_2.png' },
                { left: 590, bottom: 360, width: 110, height: 80, suspendImg: '/public/html/images/sports/icon/suspend/3_3.png' },
            ],
            text: 'sport:sport.br.desc_1',
            mobileText: 'sport:sport.br.mobile_desc_1',
            bgImg: '/public/html/images/sports/betradar-banner.png',
            routeName: 'virtualsports-landing',
            maintenance: false,
        },
        // SBO: {
        //     key: 'sbo',
        //     provider: 'SBO',
        //     suspend: [
        //         { left: 680, bottom: 170, width: 77, height: 83, suspendImg: '/public/html/images/sports/icon/suspend/4_2.png' },
        //         { left: 500, bottom: 270, width: 113, height: 119, suspendImg: '/public/html/images/sports/icon/suspend/4_3.png' },
        //         { left: 500, bottom: 270, width: 113, height: 119, suspendImg: '/public/html/images/sports/icon/suspend/4_4.png' },
        //     ],
        //     text: 'sport:sport.sbo.desc_4',
        //     mobileText: 'sport:sport.sbo.mobile_desc_1',
        //     bgImg: '/public/html/images/sports/sbo-banner.png',
        //     routeName: 'sbo',
        //     maintenance: false,
        // },
        SBO2: {
            key: 'sbo2',
            provider: 'SBO2',
            suspend: [
                { left: 680, bottom: 170, width: 77, height: 83, suspendImg: '/public/html/images/sports/icon/suspend/4_2.png' },
                { left: 500, bottom: 270, width: 113, height: 119, suspendImg: '/public/html/images/sports/icon/suspend/4_3.png' },
                { left: 500, bottom: 270, width: 113, height: 119, suspendImg: '/public/html/images/sports/icon/suspend/4_4.png' },
            ],
            text: 'sport:sport.sbo.desc_4',
            mobileText: 'sport:sport.sbo.mobile_desc_1',
            bgImg: '/public/html/images/sports/sbo-banner.png',
            routeName: 'sbo',
            maintenance: false,
        }, 
        M8: {
            key: 'm8',
            provider: 'M8',
            suspend: [
                { left: 680, bottom: 170, width: 77, height: 3, suspendImg: '/public/html/images/sports/icon/suspend/5_1.png' },
                // { left: 680, bottom: 170, width: 77, height: 83, suspendImg: '/public/html/images/sports/icon/suspend/5_2.png' },
                { left: 500, bottom: 270, width: 113, height: 119, suspendImg: '/public/html/images/sports/icon/suspend/5_3.png' },
            ],
            bgImg: '/public/html/images/sports/m8-banner.png',
            text: 'sport:sport.m8.desc_1',
            mobileText: 'sport:sport.m8.mobile_desc_1',
            routeName: 'm8',
            maintenance: false,
        },
        UFA: {
            key: 'ufa',
            provider: 'UFA',
            suspend: [
                { left: 680, bottom: 170, width: 77, height: 83, suspendImg: '/public/html/images/sports/icon/suspend/6_2.png' },
                { left: 500, bottom: 270, width: 113, height: 119, suspendImg: '/public/html/images/sports/icon/suspend/6_3.png' },
            ],
            text: 'sport:sport.ufa.desc_1',
            mobileText: 'sport:sport.ufa.mobile_desc_1',
            bgImg: '/public/html/images/sports/ufa-banner.png',
            route: 'ufa',
            maintenance: false,
        },
        BTI: {
            key: 'bti',
            provider: 'BTI',
            suspend: [
                { left: 50, bottom: 270, width: 150, height: 110, suspendImg: '/public/html/images/sports/icon/suspend/7_2.png' },
                { left: 740, bottom: 370, width: 130, height: 100, suspendImg: '/public/html/images/sports/icon/suspend/7_3.png' },
                // { left: 270, bottom: 110, width: 90, height: 90, suspendImg: '/public/html/images/sports/icon/suspend/7_4.png' },
            ],
            text: 'sport:sport.bti.desc_1',
            mobileText: 'sport:sport.bti.mobile_desc_1',
            bgImg: '/public/html/images/sports/bti-banner.png',
            routeName: 'bti',
            maintenance: false,
        },
    },

    init(vm) {
        this.vm = vm;
        this.vm.state = {
            list: [],
            providerCodeList: [],
            isInit: true,
            selectedIndex: null,
        };
    },

    getSportsProviderList(isMobile = false) {
        const { language, t } = controller.vm.props;
        let contentType = 'web';
        let currencyLang = language.currencyLang;

        if (language.country === 'KH') currencyLang = 'KHUSD';

        if (isMobile) {
            contentType = 'mobile';
        }

        return new Promise(function (resolve) {
            let loadGetSubmenu = new Promise((resolve) => {
                window.SPL_Content.getSubmenu(language.countryLanguageKey, contentType, 'S').then((data) => resolve(data));
            });

            let loadProviderGameMaintenanceList = new Promise((resolve) => {
                window.SPL_Provider.providerGameMaintenanceList(null, null, currencyLang).then((result) => resolve(result));
            });

            Promise.all([loadGetSubmenu, loadProviderGameMaintenanceList]).then((loadResult) => {
                let submenu = loadResult[0];
                let providerMaintenance = loadResult[1];

                let count = 0;
                let list = [];
                let sportsData = []; // reset value first
                let providerCodeList = [];
                sportsData.length = 0;

                for (let i = 0; i < submenu.length; i++) {
                    let provider = submenu[i].provider;
                    let foundData = controller.providerData[provider];
                    let disableProvider = submenu[i].isDisableProvider;
                    const isHotProvider = submenu[i].isHotProvider;
                    const isNewProvider = submenu[i].isNewProvider;

                    if (providerMaintenance) {
                        for (let j = 0; j < providerMaintenance.length; j++) {
                            let maintenanceProvider = providerMaintenance[j].provider;
                            if (provider === maintenanceProvider) {
                                submenu[i].gameMaintenance = providerMaintenance[j].gameMaintenance;
                            }
                        }
                    }

                    if (foundData) {
                        Object.assign(foundData, { isDisableProvider: disableProvider });

                        // desktop handling
                        if (!isMobile) {
                            foundData.maintenance = submenu[i].gameMaintenance;
                            let bannerElement = generateSportsBannerElement(provider, foundData, count, isHotProvider, isNewProvider);
                            count = count + 1;

                            sportsData.push(foundData);
                            list.push(bannerElement);
                        } else {
                            // mobile handling
                            foundData.isHotProvider = isHotProvider;
                            foundData.isNewProvider = isNewProvider;
                            foundData.maintenance = submenu[i].gameMaintenance;
                            list.push(foundData);
                        }

                        providerCodeList.push(provider);
                    }
                }
                controller.vm.setState({ list: list, providerCodeList: providerCodeList });
                resolve(sportsData);
            });
        });

        function generateSportsBannerElement(provider, data, index, isHotProvider, isNewProvider) {
            return {
                content: (
                    <div>
                        <LazyLoadImage src={data.bgImg} alt=''  className='banner-img'/>
                        {data.blockImg && (
                            <LazyLoadImage
                                key='item-block'
                                style={{ position: 'absolute', left: 0, top: 0, zIndex: 1, pointerEvents: 'none' }}
                                className='item-block'
                                src={data.blockImg}
                            />
                        )}
                        {data.girlImg && (
                            <LazyLoadImage
                                key='item-girl'
                                style={{ position: 'absolute', left: 0, top: 0, zIndex: 1, pointerEvents: 'none' }}
                                className='item-girl'
                                src={data.girlImg}
                            />
                        )}
                        <div className={'container ' + provider}>
                            {data.suspend.map((item, j) => (
                                <div id={`suspend_${index}_${j}`} className={['suspend'].join(' ')} key={`${index + 1}_${j + 2}`}>
                                    <LazyLoadImage src={item.suspendImg} alt='' />
                                </div>
                            ))}
                            <div className='content'>
                                <span className='icon-box'>
                                    <i className={'icon-' + data.key}></i>
                                </span>
                                <span className={`desc ${provider+'-desc'}`} dangerouslySetInnerHTML={{ __html: t(data.text, { interpolation: { escapeValue: false } }) }}></span>
                                <span
                                    className={`btn ${data.maintenance ? 'maintenance-disable' : ''}`}
                                    onClick={() => {
                                        controller.launchGame(provider, data.isDisableProvider, data.maintenance);
                                    }}
                                >
                                    {t('sport:sport.betnow', 'BET NOW')}
                                </span>
                            </div>
                        </div>
                    </div>
                ),

                tab: (
                    <div className={`${data.isDisableProvider ? 'provider-disable' : ''}`}>
                        <i className={['icon-small-' + data.key, 'gray'].join(' ')}></i>
                        <i className={['icon-small-' + data.key, 'on'].join(' ')}></i>
                        {isHotProvider && <div className='hot-provider-casino'></div>}
                        {isNewProvider && <div className='new-provider-casino'></div>}
                    </div>
                ),

                providerMaintenance: data.maintenance ? <Fragment>{data.maintenance && <div className='maintenance-hover-box'></div>}</Fragment> : null,
            };
        }
    },

    launchGame(provider, disableProvider, maintenance, desktop) {
        if (!maintenance) {
            if (disableProvider) {
                controller.vm.props.history.push('/redirect-error');
            } else {
                const { language, screen, user } = controller.vm.props;
                let extraParam = null;
                let gameObj = {
                    provider: provider,
                    category: 'S',
                };

                providerService.launchGame(gameObj, language, user.isLogin, screen, false, extraParam, controller.vm, desktop);
            }
        }
    },

    selectProvider(index) {
        const { providerCodeList, isInit } = controller.vm.state;
        // to prevent 1st time loading to execute 2 times
        // control with isInit flag
        if (isInit) {
            controller.vm.setState({ isInit: false });
        } else {
            let providerCode = providerCodeList[index];
            let foundProvider = controller.providerData[providerCode];
            switch (foundProvider.routeName) {
                case 'cmd368':
                case 'virtualsports-landing':
                    controller.vm.props.history.push('/' + foundProvider.routeName);
                    break;
                default:
                    controller.vm.props.history.push('/sports/' + foundProvider.routeName);
                    break;
            }
        }
    },

    selectProviderBasedOnUrl() {
        const { providerCodeList } = controller.vm.state;
        let switchProvider = true;
        if (window.location.pathname.indexOf('/sports') >= 0 && window.location.pathname.indexOf('/sports/') <= -1) {
            switchProvider = false;
        }

        // check current provider based on url,
        // define selectedIndex to makesure Slider component
        // will auto select the provider when 1st time init
        if (switchProvider && providerCodeList.length > 0) {
            for (let i = 0; i < providerCodeList.length; i++) {
                let providerCode = providerCodeList[i];
                let routeName = controller.providerData[providerCode].routeName;

                if (window.location.pathname.indexOf(routeName) >= 0) {
                    controller.vm.setState({ selectedIndex: i });
                    return i;
                }
            }
            controller.vm.setState({ selectedIndex: 0 });
            return null;
        } else {
            controller.vm.setState({ selectedIndex: 0 });
            return null;
        }
    },
};

export default controller;
