import React, { Component, Fragment } from 'react';
import { createPortal } from 'react-dom';
// import vm from '../message-detail-dialog.controller'
import { Translation, withTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactCodeInput from 'react-verification-code-input';
import { mapStateToProps } from 'root/redux/selector/selector';
import vm from '../phone-verification-dialog.controller';

class MPhoneVerificationDialog extends Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
    }

    componentWillMount() {
        Modal.setAppElement('#root');
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        vm.getMemberPhone();
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        return (
            <Translation>
                {(t) => (
                    <div className='phone-verification'>
                        {this.props.phoneVerificationDialogIsShow &&
                            createPortal(
                                <div className={`tool-bar funds ${this.props.phoneVerificationDialogIsShow ? 'phone-verification' : ''} `}>
                                    <div className='title-box'>
                                        <div className='left'>
                                            <span>{}</span>
                                        </div>

                                        <div className='icon-box'>
                                            <button
                                                className='close-btn'
                                                onClick={() => {
                                                    this.props.closePhoneVerificationDialog(this.props.parentvm);
                                                    if (this.state.step === 'SUCCEED') {
                                                        this.props.phoneVerificationDialogClosedCallback();
                                                    }
                                                }}
                                            >
                                                X
                                            </button>
                                        </div>
                                    </div>
                                </div>,
                                document.querySelector('#fixed-container')
                            )}
                        <div className={`phone-verification-sub ${this.state.step}`}>
                            {this.state.step === 'INPUT_PHONE' && (
                                <Fragment>
                                    <div className='custom-modal-header phone-verification-header'>
                                        {t('global:global.verification.verifyYourMobileNumber', 'Verify Your Mobile Number')}
                                    </div>
                                    <div className='custom-modal-body phone-verification-body'>
                                        <div className='verify-desc'>
                                            <div>{t('global:global.verification.verifyDescription1')}</div>
                                            <div>
                                                {t('global:global.verification.verifyDescription2')} &nbsp;
                                                <span className='phone-number'>{this.state.phone && this.state.phone !== '' && this.state.displayPhone}</span>
                                            </div>
                                        </div>
                                        <div className='mobile-title'>{t('global:global.verification.enterMobileNumber')}</div>
                                        <div className='input-div'>
                                            <input
                                                type='text'
                                                value={this.state.enterPhone}
                                                onChange={(e) => vm.handleFieldChanged(e)}
                                                placeholder={t('global:global.form.phone-placeholder')}
                                            ></input>
                                        </div>
                                        <div className='button-div'>
                                            <button className='primary-btn btn-otp' disabled={!this.state.isCorrectPhone} onClick={() => vm.sendVerificationCode()}>
                                                {t('global:global.verification.getOtp').toUpperCase()}
                                            </button>
                                        </div>
                                    </div>
                                </Fragment>
                            )}

                            {this.state.step === 'ENTER_OTP' && (
                                <Fragment>
                                    <div className='custom-modal-header phone-verification-header'>{t('global:global.verification.enterOTPCode')}</div>
                                    <div className='custom-modal-body phone-verification-body'>
                                        {this.state.codeIncorrect && <div>{t('global:global.verification.codeIncorrect', 'OTP is incorrect')}</div>}
                                        {/* <span>{t('global:global.verification.enterOTPCodeDescription')}</span> */}
                                        <div>{t('global:global.verification.yourVerification', '')}</div>
                                        <div className='phone-number'>{this.state.enterPhone}</div>
                                        <ReactCodeInput type='number' fields={6} onChange={(e) => vm.handleOtpChangeByValue(e)} />
                                        <div className='dont-receive-otp'>
                                            {t('global:global.verification.dontReceive')} &nbsp;
                                            {this.state.allowResend && (
                                                <span onClick={() => vm.resendOtp()} className='resend-otp'>
                                                    {t('global:global.verification.resendOTP', 'Resend OTP')} ({this.state.countdown})
                                                </span>
                                            )}
                                            {!this.state.allowResend && (
                                                <span className='resend-otp disabled'>
                                                    {t('global:global.verification.resendOTP', 'Resend OTP')} ({this.state.countdown})
                                                </span>
                                            )}
                                        </div>

                                        <div className='button-div'>
                                            <button
                                                className='primary-btn btn-otp'
                                                disabled={this.state.otpCode === null || this.state.otpCode === ''}
                                                onClick={() => vm.validateOtp()}
                                            >
                                                {t('global:global.verification.verify&Proceed', 'Validate OTP').toUpperCase()}
                                            </button>
                                        </div>
                                    </div>
                                </Fragment>
                            )}

                            {/* close button added continue transaction callback */}
                            {this.state.step === 'SUCCEED' && (
                                <Fragment>
                                    <div className='custom-modal-header phone-verification-header'>{t('global:global.verification.yourNumberVerified')}</div>
                                    <div className='custom-modal-body phone-verification-body'>{t('global:global.verification.verifiedSuccessMsg')}</div>
                                </Fragment>
                            )}

                            {this.state.step === 'FAILED' && (
                                <Fragment>
                                    <div className='custom-modal-header phone-verification-header'>
                                        {t('global:global.verification.verifyYourPhoneNumber', 'Verify Your Mobile Number')}
                                    </div>
                                    <div className='custom-modal-body phone-verification-body'>
                                        {t(this.state.errorMsg)}
                                        <br />
                                        {t('global:global.verification.contactCS')}
                                    </div>
                                </Fragment>
                            )}
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation('')(withRouter(MPhoneVerificationDialog)));
