import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { mapStateToProps } from 'root/redux/selector/selector';
import image from 'root/utils/image';
import PhoneVerificationDialog from '../../../phone-verification-dialog/phone-verification-dialog.selector';
import PromotionDetailsDialog from '../../../promotion-details-dialog/promotion-details-dialog.selector';
import SpecialPromoDialog from '../../../special-promo-dialog/special-promo-dialog.selector';
import vm from '../../transfer.controller';
import TransferSelect from './transferSelect';
import Turnover from './turnover';
import { DebounceInput } from 'react-debounce-input';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { gu_loadCustomTranslation } from '../../../../../../utils/general-util';

class Transfer extends React.Component {
    isMount = false;
    props = null;
    updateProviderCount = 0;
    constructor(props) {
        super();
        this.props = props;
        this.updateProviderCount = props.updateProviderCount;
        vm.init(this);
    }

    toggleFrom(isShow) {
        this.setState({ ...this.state, from: isShow ?? !this.state.from });
    }
    toggleTo(isShow) {
        this.setState({ ...this.state, to: isShow ?? !this.state.to });
    }

    clickListOut = (e) => {
        let from = false,
            to = false;
        if (this.state.from) {
            const stopEventEl = [this.refs.inputFromRef, this.refs.fromSelectRef];
            for (let index = 0; index < stopEventEl.length; index++) {
                let child = stopEventEl[index];
                if (child && child.contains(e.target)) {
                    from = this.state.from;
                    break;
                }
            }
        }

        if (this.state.to) {
            const stopEventEl = [this.refs.inputToRef, this.refs.toSelectRef];
            for (let index = 0; index < stopEventEl.length; index++) {
                let child = stopEventEl[index];
                if (child && child.contains(e.target)) {
                    to = this.state.to;
                    break;
                }
            }
        }

        this.setState({ ...this.state, from, to });
    };

    handleEvent(e) {
        switch (e.type) {
            case 'click':
                this.clickListOut(e);
                break;
        }
    }

    initEvent(off) {
        window[off ? 'removeEventListener' : 'addEventListener']('click', this);
    }

    componentDidMount() {
        this.initEvent();
        this.isMount = true;

        gu_loadCustomTranslation('transfer', this, function () {
            vm.registerEvent();
            vm.loadProviders(true);
        });
        vm.loadPortalSettings();
        vm.getMainWallet();
    }

    componentDidUpdate() {
        if (this.props.updateProviderCount !== this.updateProviderCount) {
            this.updateProviderCount = this.props.updateProviderCount;
            vm.loadProviders(true);
        }
    }

    componentWillMount() {
        this.initEvent(true);
    }

    componentWillUnmount() {
        vm.unregisterEvent();
        this.setState = () => {
            return;
        };
    }

    render() {
        const { providerList, fromProvider, toProvider, isValidSpecialPromoAmount, isValidSpecialPromoProvider, specialPromo } = this.state;
        const { t } = this.props;
        let specialPromoDialogProps = {
            closeSpecialPromoDialog: vm.closeSpecialPromoDialog,
            specialPromoDialogIsShow: this.state.specialPromoDialogIsShow,
            parentvm: this,
            specialPromoDetail: this.state.specialPromoDetail,
        };

        let smsVerificationDialogProps = {
            parentvm: this,
            phoneVerificationDialogIsShow: this.state.phoneVerificationDialogIsShow,
            closePhoneVerificationDialog: vm.closePhoneVerificationDialog,
            phoneVerificationDialogClosedCallback: this.state.phoneVerificationDialogClosedCallback,
        };

        let promotionDetailsDialogProps = {
            parentvm: this,
            promotionDetailsDialogIsShow: this.state.promotionDetailsDialogIsShow,
            closePromotionDetailsDialog: vm.closePromotionDetailsDialog,
            promodetails: this.state.promodetails,
        };

        return (
            <div className='transfer-page'>
                {/* special promo dialog */}
                {this.state.specialPromoDetail !== null && <SpecialPromoDialog {...specialPromoDialogProps}></SpecialPromoDialog>}
                {/* verify phone verification sms popup */}
                {this.state.phoneVerificationDialogIsShow && <PhoneVerificationDialog {...smsVerificationDialogProps}></PhoneVerificationDialog>}
                {this.state.promotionDetailsDialogIsShow && <PromotionDetailsDialog {...promotionDetailsDialogProps}></PromotionDetailsDialog>}

                <div className='form-box'>
                    <div className='item-group group-absolute'>
                        <i className='icon-transfer-btn' onClick={() => vm.swapProvider()}></i>
                    </div>
                    <div className='item-group group-transfer'>
                        <div className='title'>{t('transaction:transaction.transfer.from', 'From')}</div>
                        <div className='group'>
                            <div
                                ref={'inputFromRef'}
                                className='input-box input-box-from'
                                onClick={() => {
                                    this.toggleFrom();
                                }}
                            >
                                <input
                                    type='text'
                                    readOnly={true}
                                    value={providerList.length ? (providerList[fromProvider].display === 's-Sports' ? 'SBOBet' : providerList[fromProvider].display) : ''}
                                />
                                <span className='icon-box'>
                                    <CSSTransition classNames='rotate180' in={this.state.from} timeout={300}>
                                        <i className='icon-arrow2'></i>
                                    </CSSTransition>
                                </span>
                            </div>
                            <div className='input-box'>
                                <input type='text' disabled value={providerList.length ? window.SPL_Other.formatAmount(providerList[fromProvider].balance) : '0.00'} />
                            </div>
                            <CSSTransition classNames='spread-bottom' in={this.state.from} timeout={300} unmountOnExit>
                                <div
                                    ref={'fromSelectRef'}
                                    className='transfer-select-box'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <TransferSelect
                                        onClose={() => {
                                            this.toggleFrom(false);
                                        }}
                                        parentState={this.state}
                                        updateProviderIndex={vm.updateProviderSelect}
                                        updateProviderItemIndex={vm.updateProviderItemSelect}
                                        type={'from'}
                                        onChange={(provider, i) => {
                                            vm.changeProviderFromObjectParams(provider, null, i);
                                        }}
                                    />
                                </div>
                            </CSSTransition>
                        </div>
                    </div>
                    <div className='item-group group-transfer'>
                        <div className='title'> {t('transaction:transaction.transfer.to', 'To')}</div>
                        <div className='group'>
                            <div
                                ref={'inputToRef'}
                                className='input-box input-box-to'
                                onClick={() => {
                                    this.toggleTo();
                                }}
                            >
                                <input
                                    className={[specialPromo && !isValidSpecialPromoProvider ? 'danger' : '']}
                                    type='text'
                                    readOnly={true}
                                    value={providerList.length ? (providerList[toProvider].display === 's-Sports' ? 'SBOBet' : providerList[toProvider].display) : ''}
                                />
                                <span className='icon-box'>
                                    <CSSTransition classNames='rotate180' in={this.state.to} timeout={300}>
                                        <i className='icon-arrow2'></i>
                                    </CSSTransition>
                                </span>
                            </div>
                            <div className='input-box'>
                                <input
                                    type='text'
                                    readOnly
                                    value={providerList.length ? window.SPL_Other.formatAmount(providerList[toProvider].balance) : window.SPL_Other.formatAmount(0)}
                                />
                            </div>
                            <CSSTransition classNames='spread-bottom' in={this.state.to} timeout={300} unmountOnExit>
                                <div
                                    ref={'toSelectRef'}
                                    className='transfer-select-box'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <TransferSelect
                                        onClose={() => {
                                            this.toggleTo(false);
                                        }}
                                        parentState={this.state}
                                        type={'to'}
                                        updateProviderIndex={vm.updateProviderSelect}
                                        updateProviderItemIndex={vm.updateProviderItemSelect}
                                        onChange={(provider, i) => {
                                            vm.changeProviderToObjectParams(provider, null, i);
                                        }}
                                    />
                                </div>
                            </CSSTransition>
                        </div>
                    </div>
                    {/* promotion */}
                    {this.state.allowSpecialCode === false && (
                        <div>
                            <div className='item-group group-code'>
                                <div className='title'>{t('transaction:transaction.transfer.promocode', 'Promo Code')} *</div>
                                <div className='group'>
                                    <div className='input-box'>
                                        <select value={this.state.selectedPromotion} onChange={(e) => vm.changePromotionByEvent(e)} disabled={!this.state.promoListVisible}>
                                            <option value={-1}>{t('global:global.form.pleaseSelect')}</option>
                                            {this.state.promoList.map((promotion, index) => (
                                                <option key={index} value={index}>
                                                    {promotion.bonusName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {this.state.promoList.length && this.state.selectedPromotion >= 0 ? (
                                        <span onClick={() => vm.toggleShowPromoDetails()}>{t('transaction:transaction.transfer.promoDetail.viewDetail', 'Promo Detail')}</span>
                                    ) : (
                                        <></>
                                    )}
                                    {this.state.showPromoTnc && this.state.promoList !== null && this.state.promoList.length > 0 && (
                                        <div className='promo-tnc' onClick={() => vm.goToPromoPage()}>
                                            <span>{t('transaction:transaction.transfer.tnCMessage')}</span>
                                        </div>
                                    )}
                                    {/* <div className="input-box">
                    <input type="text" disabled value="0.00" />
                  </div> */}
                                </div>
                            </div>
                        </div>
                    )}
                    {/* allow special code enabled, then show promotion related ui at another row */}
                    {this.state.allowSpecialCode && (
                        <div>
                            <div className='item-group group-code'>
                                <div className='title'>{t('transaction:transaction.transfer.specialCode', 'Special Code')}</div>
                                <div className='group'>
                                    <div className='input-box'>
                                        <DebounceInput
                                            type='text'
                                            placeholder={t('transaction:transaction.transfer.specialCode', 'Special Code')}
                                            disabled={this.state.promoList.length > 0 && this.state.selectedPromotion >= 0}
                                            value={this.state.specialPromoCode}
                                            onChange={(e) => {
                                                vm.handleSpecialPromoCodeChange(e);
                                            }}
                                            debounceTimeout={500}
                                        ></DebounceInput>
                                        {this.state.specialPromoCode !== '' && this.state.isValidSpecialPromoCode && (
                                            <LazyLoadImage
                                                src={image.specialPromoCodeValid}
                                                style={{ cursor: 'pointer' }}
                                                className='specialCodeImg'
                                                onClick={() => vm.openSpecialPromoDialog()}
                                                alt='valid.png'
                                            />
                                        )}
                                        {this.state.specialPromoCode !== '' && !this.state.isValidSpecialPromoCode && (
                                            <LazyLoadImage src={image.specialPromoCodeInvalid} className='specialCodeImg' alt='invalid.png' />
                                        )}
                                    </div>
                                    {/* <span>Promo Details</span> */}
                                </div>
                            </div>
                            <div className='item-group group-code'>
                                <div className='title'>{t('transaction:transaction.transfer.promocode', 'Promo Code')} *</div>
                                <div className='group'>
                                    <div className='input-box'>
                                        <select
                                            value={this.state.selectedPromotion}
                                            onChange={(e) => vm.changePromotionByEvent(e)}
                                            disabled={!this.state.promoListVisible || this.state.specialPromoCode}
                                        >
                                            <option value={-1}>{t('global:global.form.pleaseSelect')}</option>
                                            {this.state.promoList.map((promotion, index) => (
                                                <option key={index} value={index}>
                                                    {promotion.bonusName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {this.state.promoList.length && this.state.selectedPromotion >= 0 ? (
                                        <span onClick={() => vm.toggleShowPromoDetails()}>{t('transaction:transaction.transfer.promoDetail.viewDetail', 'Promo Detail')}</span>
                                    ) : (
                                        <></>
                                    )}
                                    {this.state.showPromoTnc && this.state.promoList !== null && this.state.promoList.length > 0 && (
                                        <div className='promo-tnc' onClick={() => vm.goToPromoPage()}>
                                            <span>{t('transaction:transaction.transfer.tnCMessage')}</span>
                                        </div>
                                    )}
                                    {/* <div className="input-box">
                    <input type="text" disabled value="0.00" />
                  </div> */}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className='item-group group-amount'>
                        <div className='title'>{t('transaction:transaction.transfer.amount', 'Amount')}</div>
                        <div className='group'>
                            <div className='input-box'>
                                <input
                                    type='numeric'
                                    className={[specialPromo && !isValidSpecialPromoAmount ? 'danger' : '']}
                                    placeholder={
                                        this.state.specialPromoAmountPlaceHolder !== null && this.state.specialPromoAmountPlaceHolder !== ''
                                            ? this.state.specialPromoAmountPlaceHolder
                                            : t('global:global.form.accountamount-placeholder', 'Amount')
                                    }
                                    min='0'
                                    max={this.state.maximumAmount}
                                    value={this.state.transferAmount}
                                    onChange={(e) => vm.handleAmountChange(e)}
                                ></input>
                                <span className='btn-all-in' onClick={vm.AllInOnClick}>
                                    {t('transaction:transaction.transfer.allIn')}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className='item-group group-btn'>
                        <button
                            className='btn btn-submit'
                            onClick={() => vm.submitTransfer(t)}
                            disabled={this.state.disabledDepositButton || (this.state.specialPromoCode !== '' && this.state.isValidSpecialPromoCode === false)}
                        >
                            {t('transaction:transaction.history.filter.submit', 'Submit').toUpperCase()}
                        </button>
                        <span className='btn btn-reset' onClick={vm.resetOnClick}>
                            {t('transaction:transaction.reset')}
                        </span>
                    </div>
                    {this.state.isSuccess && <div className='success'>{t('transaction:transaction.transfer.success')}</div>}
                </div>
                <Turnover
                    parentState={this.state}
                    getMainWallet={vm.getMainWallet}
                    loadProviders={() => {
                        vm.loadProviders(true);
                    }}
                />
            </div>
        );
    }
}

export default connect(mapStateToProps, null)(withTranslation(['transaction', 'home', 'settings'])(withRouter(Transfer)));
