import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { Translation, withTranslation } from 'react-i18next';

class QRCodePopup extends Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
    }

    render() {
        return (
            <Translation>
                {() => (
                    <Modal
                        overlayClassName='standard-popup-overlay'
                        className='standard-popup-modal-container qrcode-popup-modal-container'
                        isOpen={this.props.qrCodeDialogIsShow}
                        shouldCloseOnOverlayClick={true}
                        shouldCloseOnEsc={true}
                        onRequestClose={() => this.props.closeQrCodeDialog()}
                    >
                        <div className='standard-popup-modal-body'>
                            <img src={this.props.qrCodeImg} alt='' style={{ display: 'block', margin: 'auto' }}></img>
                        </div>
                    </Modal>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation()(withRouter(QRCodePopup)));
