import React from 'react';
import Steps from './sports/Steps';
import Contact from './sports/Contact';
import SportsGames from './sports/SportsGames';
import UPEvents from './sports/UPEvents';

class Sports extends React.Component {
    render() {
        return (
            <div className='sports'>
                <Steps />
                <div className='container'>
                    <div className='group'>
                        <SportsGames sportsScrollingBanner={this.props.sportsScrollingBanner} />
                        <UPEvents oddsFeedArray={this.props.oddsFeedArray} />
                    </div>
                    <Contact contactList={this.props.contactList} contactOnClick={this.props.contactOnClick} />
                </div>
            </div>
        );
    }
}

export default Sports;
