import { userConstants } from '../constant';

const initialState = {
    isLogin: false,
    isFailedLogin: false,
    account: null,
    userName: '',
    password: '',
    isMemberRequested: false,
    showResetPassword: false,
    isLoading: false,
    showAnnoucement: '',
    depositAnnouncement: '',
    withdrawAnnouncement: '',
    isSpecialDialog: false,
    totalUnreadMessageCount: 0,
    totalUnreadNotificationCount: 0,
    totalUnreadPaymentCount: 0,
    totalUnreadPromotionCount: 0,
    totalUnreadSystemCount: 0,
    affConfig: {
        affiliateid: '',
        refCurrency: '',
        disabledAffiliate: false,
        referralToggle: false,
    },
    verifications: {
        personal: null,
    },
};

export function user(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                ...state,
                password: action.payload.password,
                userName: action.payload.login,
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                ...state,
                password: '',
                isLogin: action.payload.isLogin,
            };
        case userConstants.LOGIN_FAILURE:
            return {
                ...state,
                isLogin: action.payload.isLogin,
                isFailedLogin: action.payload.isFailedLogin,
            };

        case userConstants.LOGOUT:
            return {
                ...state,
                isLogin: action.payload.isLogin,
                account: null,
            };
        case userConstants.GET_MEMBER_ACCOUNT:
            return {
                ...state,
                account: action.payload,
            };
        case userConstants.GET_MEMBER_REQUEST:
            return {
                ...state,
                isMemberRequested: action.payload,
            };
        case userConstants.GET_MEMBER_ACCOUNT_FAILED:
            return {
                ...state,
                account: null,
            };
        case userConstants.SHOW_RESET_PASSWORD:
            return {
                ...state,
                showResetPassword: action.payload.showResetPassword,
                failLoginCode: action.payload.code,
            };
        case userConstants.IS_LOADING:
            return {
                ...state,
                isLoading: action.payload.isLoading,
            };
        case userConstants.HIDE_HOMEPAGE_ANNOUNCEMENT:
            return {
                ...state,
                showAnnoucement: action.payload.showAnnoucement,
            };
        case userConstants.HIDE_DEPOSIT_ANNOUNCEMENT:
            return {
                ...state,
                depositAnnouncement: action.payload.depositAnnouncement,
            };
        case userConstants.HIDE_WITHDRAW_ANNOUNCEMENT:
            return {
                ...state,
                withdrawAnnouncement: action.payload.withdrawAnnouncement,
            };
        case userConstants.GET_UNREAD_MSG:
            return {
                ...state,
                unreadMsg: action.payload.unreadMsg,
            };
        case userConstants.OPEN_SPECIAL_DIALOG:
            return {
                ...state,
                isSpecialDialog: action.payload.isSpecialDialog,
            };
        case userConstants.CLOSE_SPECIAL_DIALOG:
            return {
                ...state,
                isSpecialDialog: action.payload.isSpecialDialog,
            };
        case userConstants.MESSAGE_UNREAD_COUNT:
            return {
                ...state,
                totalUnreadMessageCount: action.payload.totalUnreadMessageCount,
                totalUnreadNotificationCount: action.payload.totalUnreadNotificationCount,
                totalUnreadPaymentCount: action.payload.totalUnreadPaymentCount,
                totalUnreadPromotionCount: action.payload.totalUnreadPromotionCount,
                totalUnreadSystemCount: action.payload.totalUnreadSystemCount,
            };
        case userConstants.GET_AFFILIATE_OR_REFERRAL_CODE:
            return {
                ...state,
                affConfig: action.payload.affConfig,
            };
        case userConstants.UPDATE_USER_VERIFICATION:
            return {
                ...state,
                verifications: action.payload.verifications,
            };
        default:
            return state;
    }
}
