import commonUtil from 'root/utils/common-util';

const controller = {
    vm: null,

    init(vm) {
        this.vm = vm;
        this.vm.state = {
            tabList: ['app', 'android', 'ios'],
            active: window.SPL_LiteApp.isZT828LiteApp() ? 'android' : 'app',
            listActive: -1,
            list: [],
            qrCode: false,
            qrObj: '',
            appDownloadLinkData: {},
            compSettings: [],
        };
    },

    getAppDownloadLink() {
        commonUtil.getAppDownloadLink(controller.vm);
    },

    getDownloadGameList(filterType) {
        setTimeout(() => {
            window.SPL_Content.getDownloadGameList(filterType).then((data) => {
                controller.vm.setState({ list: data });
            });
        }, 0);
    },

    handleTabClick(tabName, forceLoad = false) {
        const vm = controller.vm;
        if (vm.state.active === tabName && !forceLoad) {
            return;
        }

        vm.setState({ 
            active: tabName,
            listActive: -1
        });
        switch (tabName) {
            case 'android':
                controller.getDownloadGameList('ANDROID');
                break;

            case 'ios':
                controller.getDownloadGameList('iOS');
                break;

            case 'app':
                controller.vm.setState({ list: [] });
                break;

            default:
                break;
        }
    },

    downloadApp(game) {
        window.SPL_Other.downloadURI(game.download, game.provider);
    },

    qrcode(list) {
        const url = window.location.protocol + '//' + window.location.hostname + '/';
        if (!list.download.includes('https') && !list.download.includes('http')) {
            const combineUrl = url + list.download;
            return combineUrl;
        } else {
            return list.download;
        }
    },

    initCompSettings() {
        return new Promise((resolve) => {
            // Step 1: Standard way to get from portal settings
            let tempSettings = commonUtil.getSettingFromPortalSettings({
                vm: controller.vm,
                settingName: 'downloadPageSettings',
                notViewType: true,
            });

            // Step 2: Massage again to get the specific data you need (Skip if not needed)

            // Step 3: Initial all into compSettings

            let compSettings = {
                customMaintenanceDisable: tempSettings.customMaintenanceDisable,
            };

            controller.vm.setState({ compSettings }, () => {
                resolve();
            });
        });
    },

    getHashToShowActiveTab() {
        const vm = controller.vm;
        const { hash } = vm.props.location;
        const { active } = vm.state;
        if (hash && hash.length > 0) {
            if (hash.toLowerCase().includes('ios')) {
                controller.handleTabClick('ios', true);
            } else {
                controller.handleTabClick('android', true);
            }
        } else if (active === 'app') {
            controller.handleTabClick('app', true);
        } else {
            controller.handleTabClick('android', true);
        }
    },

    getQrCode(i, downloadObj) {
        const { screen } = controller.vm.props;
        let isWeb = screen.viewType === 'web';

        if (isWeb) {
            controller.vm.setState({ ...controller.vm.state, listActive: i == controller.vm.state.listActive ? -1 : i });
        } else {
            controller.vm.setState({ qrCode: !controller.vm.state.qrCode, qrObj: downloadObj });
        }
    },
};

export default controller;
