import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { isAndroid } from 'react-device-detect';
import providerService from 'root/utils/providerService';

const controller = {
    vm: null,
    init(vm) {
        this.vm = vm;
        this.vm.state = {
            list: [],
            userObj: {
                playerId: '-',
                playerPassword: '-',
            },
            bannerIcon: [
                { left: 500, bottom: 260, width: 150, height: 130, suspendImg: '/public/html/images/poker/suspend/1_1.png' },
                { left: 160, bottom: 150, width: 100, height: 100, suspendImg: '/public/html/images/poker/suspend/1_2.png' },
                { left: 250, bottom: 305, width: 60, height: 44, suspendImg: '/public/html/images/poker/suspend/1_3.png' },
            ],
        };
    },

    getPokerBannerElement(viewType) {
        const { language, t } = controller.vm.props;
        let currencyLang = language.currencyLang;

        if (language.country === 'KH') currencyLang = 'KHUSD';

        return new Promise(function (resolve) {
            window.SPL_Provider.providerGameMaintenanceList('IDN', null, currencyLang).then((result) => {
                let providerMaintenance = null;
                let list = [];

                if (result) {
                    for (let j = 0; j < result.length; j++) {
                        providerMaintenance = result[j];
                    }
                }

                if (viewType === 'mobile') {
                    list.underMaintenance = providerMaintenance;
                } else {
                    let bannerElement = generatePokerBannerElement(providerMaintenance);
                    list.push(bannerElement);
                }
                controller.vm.setState({ list: list });
                resolve(controller.vm.state.bannerIcon);
            });
        });

        function generatePokerBannerElement(maintenance) {
            return {
                content: (
                    <div>
                        <LazyLoadImage src='/public/html/images/poker/poker-banner.jpg' alt='' />

                        <div className='container'>
                            <div className='poker-suspend'>
                                {controller.vm.state.bannerIcon.map((item, j) => (
                                    <div id={`suspend_0_${j}`} className={['suspend'].join(' ')} key={`1_${j + 2}`}>
                                        <LazyLoadImage src={item.suspendImg} alt='' />
                                    </div>
                                ))}
                            </div>
                            <div className='content'>
                                <span className='icon-box'>
                                    <i className='icon-poker2'></i>
                                </span>
                                <span className='desc'>
                                    {t('poker:poker.desc1')} <br />
                                    {t('poker:poker.desc2')} <br />
                                    {t('poker:poker.desc3')}
                                </span>
                                <span
                                    className={`btn ${maintenance ? 'maintenance-disable' : ''}`}
                                    onClick={() => {
                                        controller.launchGame(maintenance);
                                    }}
                                >
                                    {t('poker:poker.button.playNow', 'PLAY NOW')}
                                </span>
                            </div>
                        </div>
                    </div>
                ),
            };
        }
    },

    launchGame(maintenance) {
        const { history } = controller.vm.props;
        if (maintenance) {
            history.push({
                pathname: '/maintenance',
                search: '?provider=Poker&startTime=' + maintenance.startTime + '&endTime=' + maintenance.endTime + '',
            });
        } else {
            const { language, screen, user } = controller.vm.props;
            if (language.currencyLang === 'VND') return;

            let extraParam = null;
            let gameObj = {
                provider: 'IDN',
                category: 'P',
            };

            providerService.launchGame(gameObj, language, user.isLogin, screen, false, extraParam, controller.vm);
        }
    },

    getGameLoginCredential() {
        let domain = '';

        if (window.location.hostname === 'localhost') {
            domain = window.tempDomain;
        } else {
            domain = window.location.hostname;
        }

        let params = {
            domain: domain,
            provider: 'IDN',
        };

        window.SPL_Provider.getGameSession(params).then((userObj) => {
            controller.vm.setState({ userObj: userObj });
        });
    },

    downloadApps() {
        let os = 'iOS';
        if (isAndroid) {
            os = 'ANDROID';
        }
        window.SPL_Content.getGameLinkForMobileVersion(os, 'IDN').then((data) => {
            if (os === 'ANDROID') {
                window.SPL_Other.downloadURI(data.link, 'idn.apk');
            } else {
                window.SPL_Other.downloadURI(data.link, '');
            }
        });
    },
};

export default controller;
