import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import DatePicker from 'react-datepicker';

import { Translation, withTranslation } from 'react-i18next';

class SearchHistoryDialog extends Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        const { language } = this.props;
        const { fields } = this.props.parentState;

        let datepickerLanguage = '';
        if (language.countryLanguageKey === 'TH_TH') {
            datepickerLanguage = 'th';
        } else if (language.countryLanguageKey === 'ZH_MY') {
            datepickerLanguage = 'zhCN';
        }

        return (
            <Translation>
                {(t) => (
                    <div className='referral-search-modal-overlay'>
                        {/* custom-modal-container */}
                        <div className=' custom-modal m-search-dialog-modal'>
                            <div className='custom-modal-header'>
                                <h3>{t('home:home.mobile.history', 'History')}</h3>
                                <button className='close-btn' onClick={() => this.props.toggleModal('search')}>
                                    X
                                </button>
                            </div>
                            <div className='custom-modal-body'>
                                <div className='form-field'>
                                    <div className='mandatory'>{`${t('transaction:transaction.history.filter.start_date', 'Start Date')}:`}</div>
                                    <DatePicker
                                        locale={datepickerLanguage}
                                        autoComplete='off'
                                        type='text'
                                        placeholderText={`${t('transaction:transaction.history.filter.start_date', 'Start Date')}:`}
                                        selectsStart
                                        selected={fields.startDate}
                                        onChange={(date) => this.props.handleDateChanged(date, true)}
                                        dateFormat='yyyy-MM-dd'
                                    />
                                </div>
                                <div className='form-field'>
                                    <div className='mandatory'>{t('transaction:transaction.history.filter.end_date', 'End Date')}</div>
                                    <DatePicker
                                        locale={datepickerLanguage}
                                        autoComplete='off'
                                        type='text'
                                        placeholderText={t('transaction:transaction.history.filter.end_date', 'End Date')}
                                        selectsStart
                                        selected={fields.endDate}
                                        onChange={(date) => this.props.handleDateChanged(date, true)}
                                        dateFormat='yyyy-MM-dd'
                                    />
                                </div>
                            </div>
                            <div className='custom-modal-footer '>
                                <div className='tab-btn-container'>
                                    <button onClick={() => this.props.searchHistory()}>
                                        {t('transaction:transaction.deposit.cashdeposit.submitbtn', 'Submit').toUpperCase()}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['home', 'transaction'])(SearchHistoryDialog));
