/** Register Constant */
export const REG_FIELD = {
    USERNAME: 'username',
    PASSWORD: 'password',
    SEARCHKEYWORD: 'searchKeyword',
    TELCODE: 'telCode',
    INTERNATIONAL_TELCODE: 'internationalTelCode',
    PHONE: 'phone',
    FULLNAME: 'fullname',
    EMAIL: 'email',
    FIRSTNAME: 'firstname',
    LASTNAME: 'lastname',
    CURRENCY: 'currency',
    AFFILIATEID: 'affiliateid',
};
