import React from 'react';
import { CSSTransition } from 'react-transition-group';
import Chat from './sidepopup/Chat';
import ContactUs from './sidepopup/ContactUs';
import Language from './sidepopup/Language';
import context from './context';
class SidePopup extends React.Component {
    constructor() {
        super();
        this.state = {
            showChat: false,
            showLan: false,
            showContact: false,
            lan: window._cacheData || 'en_US',
        };
    }

    componentWillMount() {
        context.event.on('open-side-lan', (lanKey) => {
            this.setState({
                ...this.state,
                showChat: false,
                showLan: !this.state.showLan,
                showContact: false,
            });
        });

        context.event.on('sync-lan', (lanKey) => {
            if (lanKey == this.state.lan) return;
            window._cacheData = lanKey;
            this.setState({
                ...this.state,
                lan: lanKey,
            });
        });
    }

    render() {
        return (
            <div className='side-popup'>
                <div className='tools-btns'>
                    <span
                        className='btn-chat icon-btn-chat'
                        onClick={() => {
                            this.setState({
                                ...this.state,
                                showChat: !this.state.showChat,
                                showLan: false,
                                showContact: false,
                            });
                        }}
                    ></span>
                    <span
                        className={['btn-lan', 'icon-gray-small', `icon-${this.state.lan}`].join(' ')}
                        onClick={() => {
                            this.setState({
                                ...this.state,
                                showChat: false,
                                showLan: !this.state.showLan,
                                showContact: false,
                            });
                        }}
                    ></span>
                    <span
                        className='btn-other icon-btn-other'
                        onClick={() => {
                            this.setState({
                                ...this.state,
                                showChat: false,
                                showLan: false,
                                showContact: !this.state.showContact,
                            });
                        }}
                    ></span>
                </div>
                <CSSTransition classNames='slider-x-right-in' in={this.state.showChat} timeout={500} unmountOnExit>
                    <Chat
                        doClose={() => {
                            this.setState({ ...this.state, showChat: false });
                        }}
                    />
                </CSSTransition>
                <CSSTransition classNames='slider-x-right-in' in={this.state.showContact} timeout={500} unmountOnExit>
                    <ContactUs />
                </CSSTransition>
                <CSSTransition classNames='slider-x-right-in' in={this.state.showLan} timeout={500} unmountOnExit>
                    <Language />
                </CSSTransition>
            </div>
        );
    }
}

export default SidePopup;
