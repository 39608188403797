import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { portalAction } from 'root/redux/action/portal.action';

function RouterChangeHandler({ t }) {
    let history = useHistory();
    const languageState = useSelector((state) => state.language);
    const dispatch = useDispatch();

    useEffect(() => {
        let rawPageTitle = window.SPL_Other.getPageTitle(history.location.pathname);
        let titleKey = '';
        if (rawPageTitle !== '') {
            titleKey = t(rawPageTitle, { merchant: window.globMerchantName });
            // if translate file dont have translation
            if (rawPageTitle.includes(titleKey)) {
                titleKey = t('global:global.title', { merchant: window.globMerchantName });
            }
        } else {
            titleKey = t('global:global.title', { merchant: window.globMerchantName });
        }
        window.SPL_Other.updateTitle(titleKey, languageState.countryLanguageKey, history.location.pathname);
        dispatch(portalAction.pageUpdated(history.location.pathname));
    }, [history.location.pathname]);

    return <div style={{ display: 'none' }}></div>;
}

export default withTranslation('')(withRouter(RouterChangeHandler));
