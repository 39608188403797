import React from 'react';
import { Trans, Translation, withTranslation } from 'react-i18next';

class Steps extends React.Component {
    steps = [
        {
            key: '1',
            content: 'home:home.step.createAcc',
        },
        {
            key: '2',
            content: 'home:home.step.makeDeposit',
        },
        {
            key: '3',
            content: 'home:home.step.startWin',
        },
    ];

    render() {
        return (
            <Translation>
                {(t) => (
                    <div className='step-box'>
                        <div className='container'>
                            <ul>
                                {this.steps.map(({ key, content }) => (
                                    <li key={key}>
                                        <i className={'icon-step-' + key}></i>
                                        <div>
                                            <span>
                                                {t(`home:home.step.step-${key}`, `STEP ${key}`)} <b> &nbsp;{`>`}</b>
                                            </span>
                                            <span>
                                                <Trans i18nKey={content} />
                                            </span>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default withTranslation('home')(Steps);
