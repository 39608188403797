import React from 'react';
import ReactLoading from 'react-loading';

const list = [
    {
        prop: 'bubbles',
        name: 'Bubbles',
    },
];

const SmallLoading = () => (
    <div className='small-loading'>
        {list.map((l) => (
            <div key={l.prop}>
                <ReactLoading type={l.prop} color='secondary' style={{ width: '32px', height: '32px' }} />
            </div>
        ))}
    </div>
);

export default SmallLoading;
