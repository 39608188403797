import { userAction } from 'root/redux/action/user.action';
import notification from 'root/utils/notification';
import core from './core';
import { walletAction } from 'root/redux/action/wallet.action';
import controller from '../webapp/portal/promos/promotion.controller';
import { portalAction, providerAction } from '@redux/action';
import eventEmitter, { EVENT_TYPE } from './eventEmmiter';

// centralize member function, to minimize duplicate function call
// in the project

// variable standardization across all viewmodel
// mainWalletRefreshing
// mainWallet
// unreadMessageCount

const memberService = {
    getMainWallet(vm, props) {
        vm.setState({
            mainWalletRefreshing: true,
            mainWallet: null,
        });

        window.SPL_Member.getMainWallet().then((mainWallet) => {
            vm.props.dispatch(walletAction.updateWallet(mainWallet.data));

            vm.setState({
                mainWallet: mainWallet.data,
                mainWalletRefreshing: false,
            });
            if (props) {
                core.portalIsLoading(props, false);
            }
        });
    },

    getMemberMessageCount(vm) {
        window.SPL_Member.getUnreadMessageCount().then(function (count) {
            vm.setState({
                totalUnreadMessageCount: count.totalUnreadCount,
                totalUnreadNotificationCount: count.totalUnreadNotificationCount,
                totalUnreadPaymentCount: count.totalUnreadPaymentCount,
                totalUnreadPromotionCount: count.totalUnreadPromotionCount,
                totalUnreadSystemCount: count.totalUnreadSystemCount,
            });
            vm.props.dispatch(
                userAction.updateUnreadMsg(
                    count.totalUnreadCount,
                    count.totalUnreadNotificationCount,
                    count.totalUnreadPaymentCount,
                    count.totalUnreadPromotionCount,
                    count.totalUnreadSystemCount
                )
            );
        });
    },

    restoreWallet(vm, props) {
        core.portalIsLoading(props, true);
        vm.setState({
            mainWalletRefreshing: true,
            mainWallet: null,
        });
        window.SPL_Transaction.transferAllBackMainWallet()
            .then(() => {
                setTimeout(() => {
                    vm.props.dispatch(walletAction.udpateLoadWallet(true));
                    memberService.getMainWallet(vm, props);
                    eventEmitter.emit(EVENT_TYPE.TRANSFER_LOAD_PROVIDER_WALLET);
                }, 5000);
            })
            .catch(() => {
                setTimeout(() => {
                    vm.props.dispatch(walletAction.udpateLoadWallet(true));
                    memberService.getMainWallet(vm, props);
                    eventEmitter.emit(EVENT_TYPE.TRANSFER_LOAD_PROVIDER_WALLET);
                }, 5000);
            });
    },

    classifyMemberGroupCategory(memberGroup) {
        let memberGroupCategory = ['NORMAL', 'BRONZE', 'SILVER', 'GOLD', 'PLATINUM', 'DIAMOND'];

        for (var i = 0; i < memberGroupCategory.length; i++) {
            if (memberService.similarity(memberGroupCategory[i], memberGroup) > 0.9) {
                return memberGroupCategory[i];
            }
        }

        return memberGroup;
    },

    similarity(s1, s2) {
        var m = 0;

        // Exit early if either are empty.
        if (s1.length === 0 || s2.length === 0) {
            return 0;
        }

        s1 = s1.toUpperCase();
        s2 = s2.toUpperCase();
        // Exit early if they're an exact match.
        if (s1 === s2) {
            return 1;
        }

        var range = Math.floor(Math.max(s1.length, s2.length) / 2) - 1;
        var s1Matches = new Array(s1.length);
        var s2Matches = new Array(s2.length);

        for (var i = 0; i < s1.length; i++) {
            var low = i >= range ? i - range : 0;
            var high = i + range <= s2.length - 1 ? i + range : s2.length - 1;

            for (var j = low; j <= high; j++) {
                if (s1Matches[i] !== true && s2Matches[j] !== true && s1[i] === s2[j]) {
                    ++m;
                    s1Matches[i] = s2Matches[j] = true;
                    break;
                }
            }
        }

        // Exit early if no matches were found.
        if (m === 0) {
            return 0;
        }
        // Count the transpositions.
        var k = 0;
        var numTrans = 0;

        for (var i = 0; i < s1.length; i++) {
            if (s1Matches[i] === true) {
                for (var j = k; j < s2.length; j++) {
                    if (s2Matches[j] === true) {
                        k = j + 1;
                        break;
                    }
                }

                if (s1[i] !== s2[j]) {
                    ++numTrans;
                }
            }
        }

        var weight = (m / s1.length + m / s2.length + (m - numTrans / 2) / m) / 3;
        var l = 0;
        var p = 0.1;

        if (weight > 0.7) {
            while (s1[l] === s2[l] && l < 4) {
                ++l;
            }

            weight = weight + l * p * (1 - weight);
        }

        return weight;
    },

    updateUserSeamless(vm) {
        vm.setState(
            {
                disabledSeamless: !vm.state.disabledSeamless,
            },
            () => {
                const { disabledSeamless, hasFakeSeamless } = vm.state;
                const { t } = vm.props;
                // TODO: after all super portal switch to use super portal 2, need to change back
                // purposely reverse flag because library side will reverse the flag also
                window.SPL_Member.updateMemberSeamlessStatus(!disabledSeamless, hasFakeSeamless).then((res) => {
                    let message = '';
                    if (res.isFakeSeamlessDisabled) {
                        message = t('transaction:transaction.main-wallet-auto-transfer.fake-seamless-disabled');
                    } else {
                        message = t('transaction:transaction.main-wallet-auto-transfer.fake-seamless-enabled');
                    }
                    notification.showNotification('info', message);
                });
            }
        );
    },

    getHotGames(vm, props) {
        window.SPL_Content.getHotGameSlider().then((res) => {
            if (res && res.length > 0) {
                // not yet integrate favorite game lagi
                // isFav flag no set
                vm.setState({ hotGameList: res }, () => {
                    memberService.getMemberFavoriteGame(vm, props);
                });
            }
        });
    },

    getJustForYouGame(vm, props, cb) {
        const { language } = props;
        let currencyLang = language.currencyLang;

        if (language.country === 'KH') currencyLang = 'KHUSD';

        let loadGetJustForYouGameList = new Promise((resolve) => {
            window.SPL_Content.getJustForYouGameList().then((res) => resolve(res));
        });

        let loadProviderGameMaintenanceList = new Promise((resolve) => {
            window.SPL_Provider.providerGameMaintenanceList(null, null, currencyLang).then((result) => resolve(result));
        });

        Promise.all([loadGetJustForYouGameList, loadProviderGameMaintenanceList]).then((loadResult) => {
            let gameList = loadResult[0];
            let providerMaintenance = loadResult[1];

            if (gameList && gameList[vm.state.gameType] && gameList[vm.state.gameType].length > 0) {
                if (providerMaintenance) {
                    for (let i = 0; i < gameList[vm.state.gameType].length; i++) {
                        for (let j = 0; j < providerMaintenance.length; j++) {
                            let maintenanceProvider = providerMaintenance[j].provider;
                            if (gameList[vm.state.gameType][i].provider === maintenanceProvider) {
                                gameList[vm.state.gameType][i].underMaintenance = providerMaintenance[j];
                                gameList[vm.state.gameType][i].providerMaintenanceName = gameList[vm.state.gameType][i].name;
                            }
                        }
                    }
                }

                vm.setState({ hotGameList: gameList[vm.state.gameType] }, () => {
                    if (cb) {
                        cb();
                    }
                    memberService.getMemberFavoriteGame(vm, props);
                });
            }
        });
    },

    getMemberFavoriteGame(vm, props) {
        const { screen, language } = props;
        let isMobile = null,
            hotGame = vm.state.hotGameList,
            currencyLang = language.currencyLang;

        if (screen.viewType === 'web') {
            isMobile = false;
        } else {
            isMobile = true;
        }

        for (let i = 0; i < hotGame.length; i++) {
            hotGame[i].isFav = false;
        }

        if (language.country === 'KH') currencyLang = 'KHUSD';

        window.SPL_Member.getMemberFavoriteGame(isMobile).then((data) => {
            let favGameList = data.favoritedGame;

            for (let k = 0; k < favGameList.length; k++) {
                favGameList[k].imggame = `/public/html/games/images/s1/${favGameList[k].provider}/${favGameList[k].game}.jpg`;

                for (let a = 0; a < hotGame.length; a++) {
                    if (favGameList[k].game === hotGame[a].gameCode) {
                        hotGame[a].isFav = true;
                        hotGame[a].id = favGameList[k].id;
                    }
                }
            }

            vm.setState({ favoritedGame: favGameList, hotGameList: hotGame, isLoading: false });
        });

        let loadGetMemberFavoriteGame = new Promise((resolve) => {
            window.SPL_Member.getMemberFavoriteGame(isMobile).then((res) => resolve(res));
        });

        let loadProviderGameMaintenanceList = new Promise((resolve) => {
            window.SPL_Provider.providerGameMaintenanceList(null, null, currencyLang).then((result) => resolve(result));
        });

        Promise.all([loadGetMemberFavoriteGame, loadProviderGameMaintenanceList]).then((loadResult) => {
            let favoriteGame = loadResult[0];
            let providerMaintenance = loadResult[1];

            let favGameList = favoriteGame.favoritedGame;

            for (let k = 0; k < favGameList.length; k++) {
                favGameList[k].imggame = `/public/html/games/images/s1/${favGameList[k].provider}/${favGameList[k].game}.jpg`;
                favGameList[k].isFav = true;

                if (providerMaintenance) {
                    for (let j = 0; j < providerMaintenance.length; j++) {
                        let maintenanceProvider = providerMaintenance[j].provider;

                        if (favGameList[k].provider === maintenanceProvider) {
                            favGameList[k].underMaintenance = providerMaintenance[j];
                        }
                    }
                }

                for (let a = 0; a < hotGame.length; a++) {
                    if (favGameList[k].game === hotGame[a].gameCode) {
                        hotGame[a].isFav = true;
                    }
                }
            }
            vm.setState({ favoritedGame: favGameList, hotGameList: hotGame, isLoading: false });
        });
    },

    updateMemberFavoriteGame(vm, props, game) {
        vm.setState({ isLoading: true }, () => {
            const { screen } = props;
            const { isLogin } = props.user;
            const { favoritedGame } = vm.state;
            let isMobile = null;
            let gameCode = game.game ? game.game : game.gameCode;

            if (screen.viewType === 'web') {
                isMobile = false;
            } else {
                isMobile = true;
            }

            if (isLogin) {
                let delelteGame = false;

                for (let i = 0; i < favoritedGame.length; i++) {
                    if (favoritedGame[i].game === gameCode) {
                        delelteGame = true;
                    }
                }

                if (delelteGame === true) {
                    vm.props.dispatch(providerAction.deleteMemberFavoriteGame(game));
                    // window.SPL_Member.deleteMemberFavoriteGame(game, isMobile).then((data) => {
                    //     memberService.getMemberFavoriteGame(vm, props);
                    // });
                } else {
                    vm.props.dispatch(providerAction.updateMemberFavoriteGame(game));
                    // window.SPL_Member.updateMemberFavoriteGame(game, isMobile).then((data) => {
                    //     memberService.getMemberFavoriteGame(vm, props);
                    // });
                }
            } else {
                if (isMobile) {
                    vm.props.dispatch(portalAction.floatingPageUpdated('login'));
                } else {
                    notification.showNotification('error', props.t('home:home.loginText'));
                }

                vm.setState({ isLoading: false });
            }
        });
    },
};

export default memberService;
