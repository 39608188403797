import notification from 'root/utils/notification';

const provider = {
    providers: {
        explaination: {
            launchFromMenu: 'format: category-menuName',
            transferSettingIcon: 'setting page there use de transfer icon',
            footerIcon: 'footer use punya icon path, ordering based on top down of this json',
            menuName: 'menu name in menu-settings.json',
        },
        SG: {
            launchFromMenu: ['F-SG Fishing', 'F-SG FishingWar'],
            transferSettingIcon: '/public/html/images/footer/spadegaming_hover.png',
            footerIcon: '/public/html/images/footer/ftr_brand_spade_ov.png',
            footerIconOf: '/public/html/images/footer/ftr_brand_spade_of.png',
            menuName: 'SpadeGaming',
        },
        PT: {
            launchFromMenu: ['C-PlayTech', 'F-PT Fishing'],
            transferSettingIcon: '/public/html/images/footer/playtech_hover.png',
            menuName: 'PT2',
        },
        PT2: {
            launchFromMenu: ['C-PT2'],
            transferSettingIcon: '/public/html/images/footer/playtech_hover.png',
            footerIcon: '/public/html/images/footer/ftr_brand_pt_ov.png',
            footerIconOf: '/public/html/images/footer/ftr_brand_pt_of.png',
            jackpotIcon: '/public/html/images/home_img/provider_playtech.png',
            menuName: 'PT2',
        },
        MG: {
            transferSettingIcon: '/public/html/images/footer/microgaming_hover.png',
        },
        MGP: {
            launchFromMenu: ['C-MicroGamingPlus'],
            transferSettingIcon: '/public/html/images/footer/microgaming_hover.png',
            footerIcon: '/public/html/images/footer/ftr_brand_mg_ov.png',
            footerIconOf: '/public/html/images/footer/ftr_brand_mg_of.png',
            menuName: 'MicroGamingPlus',
        },
        TTG: {
            transferSettingIcon: '/public/html/images/footer/toptrend_hover.png',
            footerIcon: '/public/html/images/footer/ftr_brand_ttg_ov.png',
            footerIconOf: '/public/html/images/footer/ftr_brand_ttg_of.png',
            menuName: 'TTG',
        },
        GP: {
            launchFromMenu: [
                'C-GamePlay',
                'L-GamePlay Fast3 Lottery',
                'L-GamePlay Keno Lottery',
                'L-GamePlay Ladder Lottery',
                'L-GamePlay Rps Lottery',
                'L-GamePlay Sode Lottery',
                'L-GamePlay Taixiu Lottery',
                'L-GamePlay Thor Lottery',
                'L-GamePlay Lucky Derby Lottery',
                'L-GamePlay Pk10 Lottery',
                'L-GamePlay Thai Lottery',
            ],
            transferSettingIcon: '/public/html/images/footer/gameplay_hover.png',
            footerIcon: '/public/html/images/footer/ftr_brand_gp_ov.png',
            footerIconOf: '/public/html/images/footer/ftr_brand_gp_of.png',
            menuName: 'GamePlay',
        },
        AG: {
            launchFromMenu: ['C-AsiaGaming'],
            transferSettingIcon: '/public/html/images/footer/asiagaming_hover.png',
        },
        AG2: {
            launchFromMenu: ['C-AsiaGaming2'],
            transferSettingIcon: '/public/html/images/footer/asiagaming_hover.png',
            footerIcon: '/public/html/images/footer/ftr_brand_ag_ov.png',
            footerIconOf: '/public/html/images/footer/ftr_brand_ag_of.png',
            menuName: 'AsiaGaming2',
        },
        SCR: {
            transferSettingIcon: '/public/html/images/footer/918kiss_hover.png',
        },
        SCR2: {
            transferSettingIcon: '/public/html/images/footer/918kiss_hover.png',
            footerIcon: '/public/html/images/footer/ftr_brand_scr_ov.png',
            footerIconOf: '/public/html/images/footer/ftr_brand_scr_of.png',
            jackpotIcon: '/public/html/images/home_img/provider_918kiss.png',
            menuName: '918Kiss',
        },
        AB: {
            launchFromMenu: ['C-Allbet'],
            transferSettingIcon: '/public/html/images/footer/allbet_hover.png',
            footerIcon: '/public/html/images/footer/ftr_brand_allbet_ov.png',
            footerIconOf: '/public/html/images/footer/ftr_brand_allbet_of.png',
            menuName: 'Allbet',
        },
        GD: {
            launchFromMenu: ['C-GoldDeluxe'],
            transferSettingIcon: '/public/html/images/footer/golddeluxe_hover.png',
            footerIcon: '/public/html/images/footer/ftr_brand_gd_ov.png',
            footerIconOf: '/public/html/images/footer/ftr_brand_gd_of.png',
            menuName: 'GoldDeluxe',
        },
        DG: {
            launchFromMenu: ['C-DreamGaming'],
            transferSettingIcon: '/public/html/images/footer/dreamgaming_hover.png',
            footerIcon: '/public/html/images/footer/ftr_brand_dream_ov.png',
            footerIconOf: '/public/html/images/footer/ftr_brand_dream_of.png',
            menuName: 'DreamGaming',
        },
        BMK: {
            launchFromMenu: ['C-BMK'],
            transferSettingIcon: '/public/html/images/footer/evolutiongaming.png',
        },
        BMK2: {
            launchFromMenu: ['C-Bamako2'],
            transferSettingIcon: '/public/html/images/footer/evolutiongaming.png',
        },
        EVO: {
            launchFromMenu: ['C-Evo'],
            transferSettingIcon: '/public/html/images/footer/evolutiongaming.png',
            footerIcon: '/public/html/images/footer/ftr_brand_evo_ov.png',
            footerIconOf: '/public/html/images/footer/ftr_brand_evo_of.png',
            menuName: 'Evo',
        },
        GG: {
            launchFromMenu: ['F-GG Fishing'],
            transferSettingIcon: '/public/html/images/footer/gggaming_hover.png',
            footerIcon: '/public/html/images/footer/ftr_brand_ggg_ov.png',
            footerIconOf: '/public/html/images/footer/ftr_brand_ggg_of.png',
            menuName: 'SG Fishing',
        },
        IDN: {
            launchFromMenu: ['menu-Poker'],
            transferSettingIcon: '/public/html/images/footer/idn_hover.png',
            footerIcon: '/public/html/images/footer/ftr_brand_idn_ov.png',
            footerIconOf: '/public/html/images/footer/ftr_brand_idn_of.png',
            menuName: 'Poker',
        },
        CMD: {
            launchFromMenu: ['S-CMD368'],
            transferSettingIcon: '/public/html/images/footer/cmd368_hover.png',
            footerIcon: '/public/html/images/footer/ftr_brand_cmd_ov.png',
            footerIconOf: '/public/html/images/footer/ftr_brand_cmd_of.png',
            menuName: 'CMD368',
        },
        BR: {
            launchFromMenu: ['S-BetRadar'],
            transferSettingIcon: '/public/html/images/footer/betradar_hover.png',
            footerIcon: '/public/html/images/footer/ftr_brand_br_ov.png',
            footerIconOf: '/public/html/images/footer/ftr_brand_br_of.png',
            menuName: 'BetRadar',
        },
        IBC: {
            transferSettingIcon: '/public/html/images/footer/maxbet_hover.png',
            footerIcon: '/public/html/images/footer/ftr_brand_maxbet_ov.png',
            footerIconOf: '/public/html/images/footer/ftr_brand_maxbet_of.png',
            menuName: 'IBC',
        },
        SBO: {
            launchFromMenu: ['S-SBO'],
            transferSettingIcon: '/public/html/images/footer/sbobet_hover.png',
            footerIcon: '/public/html/images/footer/sbobet_hover.png',
            footerIconOf: '/public/html/images/footer/sbobet.png',
            menuName: 'SBOBET',
        },
        SBO2: {
            launchFromMenu: ['S-SBO'],
            transferSettingIcon: '/public/html/images/footer/sbobet_hover.png',
            footerIcon: '/public/html/images/footer/sbobet_hover.png',
            footerIconOf: '/public/html/images/footer/sbobet.png',
            menuName: 'SBOBET',
        },
        UG: {
            launchFromMenu: ['C-SexyBaccarat'],
            transferSettingIcon: '/public/html/images/footer/sexybaccarat_hover.png',
            footerIcon: '/public/html/images/footer/ftr_brand_aesexy_ov.png',
            footerIconOf: '/public/html/images/footer/ftr_brand_aesexy_of.png',
            menuName: 'SexyBaccarat',
        },
        AES: {
            launchFromMenu: ['C-SexyBaccarat'],
            transferSettingIcon: '/public/html/images/footer/sexybaccarat_hover.png',
            footerIcon: '/public/html/images/footer/ftr_brand_aesexy_ov.png',
            footerIconOf: '/public/html/images/footer/ftr_brand_aesexy_of.png',
            menuName: 'SexyBaccarat',
        },
        AES2: {
            launchFromMenu: ['C-SexyBaccarat'],
            transferSettingIcon: '/public/html/images/footer/sexybaccarat_hover.png',
            footerIcon: '/public/html/images/footer/ftr_brand_aesexy_ov.png',
            footerIconOf: '/public/html/images/footer/ftr_brand_aesexy_of.png',
            menuName: 'SexyBaccarat2',
        },
        UL: {
            launchFromMenu: ['SL-Ultimate'],
            transferSettingIcon: '/public/html/images/footer/ultimate_hover.png',
            footerIcon: '/public/html/images/footer/ftr_brand_ultimate_ov.png',
            footerIconOf: '/public/html/images/footer/ftr_brand_ultimate_of.png',
            menuName: 'Ultimate',
        },
        MEGA: {
            transferSettingIcon: '/public/html/images/footer/mega888_hover.png',
            footerIcon: '/public/html/images/footer/ftr_brand_mega_ov.png',
            footerIconOf: '/public/html/images/footer/ftr_brand_mega_of.png',
            menuName: 'Mega888',
        },
        UFA: {
            transferSettingIcon: '/public/html/images/footer/ufabet_hover.png',
            footerIcon: '/public/html/images/footer/ftr_brand_ufabet_ov.png',
            footerIconOf: '/public/html/images/footer/ftr_brand_ufabet_of.png',
            menuName: 'UFA',
        },
        PP: {
            transferSettingIcon: '/public/html/images/footer/pragmaticplay_hover.png',
            footerIcon: '/public/html/images/footer/ftr_brand_pp_ov.png',
            footerIconOf: '/public/html/images/footer/ftr_brand_pp_of.png',
            menuName: 'PragmaticPlay',
        },
        PG: {
            transferSettingIcon: '/public/html/images/footer/playngo_hover.png',
            footerIcon: '/public/html/images/footer/ftr_brand_playngo_ov.png',
            footerIconOf: '/public/html/images/footer/ftr_brand_playngo_of.png',
            menuName: 'PlayNGo',
        },
        EBET: {
            launchFromMenu: ['C-EBET'],
            transferSettingIcon: '/public/html/images/footer/ebet_hover.png',
            footerIcon: '/public/html/images/footer/ftr_brand_ebet_of.png',
            footerIconOf: '/public/html/images/footer/ftr_brand_ebet_ov.png',
            menuName: 'EBET',
        },
        IME: {
            transferSettingIcon: '/public/html/images/footer/ime_hover.png',
            footerIcon: '/public/html/images/footer/ime_hover.png',
            menuName: 'IME',
        },
        NS: {
            transferSettingIcon: '/public/html/images/footer/nextspin_hover.png',
            footerIcon: '/public/html/images/footer/nextspin_hover.png',
            menuName: 'NextSpin',
        },
        SA: {
            launchFromMenu: ['C-SAGaming', 'F-SA Fishing'],
            transferSettingIcon: '/public/html/images/footer/sagaming_hover.png',
            footerIcon: '/public/html/images/footer/ftr_brand_sa_ov.png',
            footerIconOf: '/public/html/images/footer/ftr_brand_sa_of.png',
            jackpotIcon: '/public/html/images/home_img/provider_sa_gaming.png',
            menuName: 'SAGaming',
        },
        QQK: {
            launchFromMenu: ['L-QQKeno Lottery', 'L-QQKenoThai Lottery'],
            transferSettingIcon: '/public/html/images/footer/qqkeno_hover.png',
        },
        M8: {
            launchFromMenu: ['S-M8'],
            transferSettingIcon: '/public/html/images/footer/m8bet_hover.png',
        },
        BTI: {
            transferSettingIcon: '/public/html/images/footer/bti_hover.png',
        },
        KM: {
            transferSettingIcon: '/public/html/images/footer/km_hover.png',
        },
        KM2: {
            transferSettingIcon: '/public/html/images/footer/km_hover.png',
        },
        WM: {
            transferSettingIcon: '/public/html/images/footer/wm_hover.png',
        },
        W: {
            transferSettingIcon: '/public/html/images/footer/wm_hover.png',
        },
        QQT: {
            transferSettingIcon: '/public/html/images/footer/qqkeno_hover.png',
        },
    },
    SPORT_PROVIDER_LIST: {
        provider: [
            {
                id: 1,
                provider: 'BR',
                img: '/public/html/images/sports/bt.jpg',
            },
            {
                id: 2,
                provider: 'CMD',
                img: '/public/html/images/sports/cmd.jpg',
            },
            {
                id: 3,
                provider: 'm8',
                img: '/public/html/images/sports/m8.jpg',
            },
            {
                id: 4,
                provider: 'MB',
                img: '/public/html/images/sports/mb.jpg',
            },
            {
                id: 5,
                provider: 'SBO',
                img: '/public/html/images/sports/sb.jpg',
            },
        ],
    },

    isAbleLaunchGameFromMenu(menuObj) {
        let found = provider.providers[menuObj.provider];
        if (found) {
            let key = menuObj.category + '-' + menuObj.name;
            return found.launchFromMenu && found.launchFromMenu.indexOf(key) >= 0;
        }
    },

    getHotMatches(countryLanguageKey, viewType) {
        return new Promise(function (resolve, reject) {
            window.SPL_Content.getIbcMatch(countryLanguageKey, viewType).then((result) => {
                // vm.setState({ hotMatchArray: result.ibcUpComingResults });
                // console.log(vm.state.hotMatchArray,'provider.js')
                // console.log(result)
                resolve(result);
            });
        });
    },

    launchGameFromIndex(vm, providerObj, isFun) {
        providerObj.isFun = isFun;
        const { language, user, screen } = vm.props;

        provider.launchGameFromMenu(providerObj, language, user.isLogin, screen, function () {
            notification.showNotification('error', vm.props.t('home:home.loginText'));
        });
    },

    launchGameFromMenu(menuObj, language, isLogin, screen, alertLoginCallback) {
        let category = menuObj.category;
        let isMobile = false;
        let gameParam = null;
        if (menuObj.provider === 'IDN') {
            category = 'P';
        }
        if (screen.viewType === 'mobile') {
            isMobile = true;
        }

        if (menuObj.gameCode) {
            gameParam = {
                domain: window.location.hostname,
                isMobile: screen.isMobile,
                isFun: false,
                isMenuMode: true,
                isMiniGame: false,
                languageKey: language.key,
                provider: menuObj.provider,
                gameCode: menuObj.gameCode,
            };
        }

        window.SPL_Provider.startProviderGameV2(menuObj.provider, category, language.key, isMobile, false, isLogin, alertLoginCallback, gameParam);
    },

    launchGameFromHomePage(gameObj, language, isLogin, screen, alertLoginCallback) {
        // if (gameObj) {
        //     gameObj.category = 'SL';
        // }
        let isMobile = false;
        if (screen.viewType === 'mobile') {
            isMobile = true;
        }
        let customParam = {
            domain: window.location.hostname,
            isMobile: screen.isMobile,
            isFun: false,
            isMenuMode: true,
            isMiniGame: false,
            languageKey: language.key,
            gameCode: gameObj.gameCode,
            provider: gameObj.provider,
        };

        window.SPL_Provider.startProviderGameV2(gameObj.provider, gameObj.category, language.key, isMobile, false, isLogin, alertLoginCallback, customParam);
    },

    launchGame(vm, isFun, extraParam) {
        return new Promise(function (resolve, reject) {
            var providerCode = null;
            var category = null;

            switch (vm.state.currentProvider) {
                case 'gggaming':
                    category = 'F';
                    providerCode = 'GG';
                    break;
                case 'safishing':
                    category = 'F';
                    providerCode = 'SA';
                    break;
                case 'sgfishing':
                    category = 'F';
                    providerCode = 'SG';
                    break;
                case 'cashfish':
                    category = 'F';
                    providerCode = 'PT2';
                    break;
                case 'sgfishingwar':
                    category = 'F';
                    providerCode = 'SGFW';
                    break;

                case 'poker':
                    category = 'P';
                    providerCode = 'IDN';
                    break;

                case 'qqkeno':
                    category = 'L';
                    providerCode = 'QQK';
                    break;
                case 'qqkenothai':
                    category = 'L';
                    providerCode = 'QQKTHAI';
                    break;

                case 'dreamgaming':
                    category = 'C';
                    providerCode = 'DG';
                    break;
                case 'sagaming':
                    category = 'C';
                    providerCode = 'SA';
                    break;

                case 'asiagaming':
                case 'asiagaming2':
                    category = 'C';
                    providerCode = 'AG2';
                    break;

                case 'sexybaccarat':
                    category = 'C';
                    providerCode = 'AES';
                    break;
                case 'sexybaccarat2':
                    category = 'C';
                    providerCode = 'AES2';
                    break;
                case 'allbet':
                    category = 'C';
                    providerCode = 'AB';
                    break;
                case 'evolutiongaming':
                    category = 'C';
                    providerCode = 'EVO';
                    break;
                case 'gameplay':
                    category = 'C';
                    providerCode = 'GP';
                    break;
                case 'playtech':
                    category = 'C';
                    providerCode = 'PT2';
                    break;
                case 'golddeluxe':
                    category = 'C';
                    providerCode = 'GD';
                    break;
                case 'ebet':
                    category = 'C';
                    providerCode = 'EBET';
                    break;

                case 'virtualsports-landing':
                    category = 'S';
                    providerCode = 'BR';
                    break;
                case 'ufa':
                    category = 'S';
                    providerCode = 'UFA';
                    break;
                case 'sbo':
                case 'sbo2':
                    category = 'S';
                    providerCode = 'SBO';
                    break;
                case 'cmd368':
                    category = 'S';
                    providerCode = 'CMD';
                    break;
                default:
                    break;
            }

            if (providerCode === null || category === null) {
                console.log('provider not defined');
                alertLoginCallback();
            } else {
                var gameObj = { provider: providerCode, category: category };

                const { language, screen, user } = vm.props;
                let isMobile = false;
                if (screen.viewType === 'mobile') {
                    isMobile = true;
                }
                window.SPL_Provider.startProviderGameV2(gameObj.provider, gameObj.category, language.key, isMobile, isFun, user.isLogin, alertLoginCallback, null, extraParam);
            }

            function alertLoginCallback() {
                vm.setState({ loginModalVisible: true });
            }

            resolve(vm);
        });
    },

    getCurrentProviderFromURL(vm) {
        return new Promise(function (resolve, reject) {
            var path = window.location.pathname;
            var index = path.lastIndexOf('/');
            path = path
                .slice(index + 1)
                .replace('-', '')
                .toLowerCase();

            if (path.includes('slots')) {
                vm.setState({ category: 'S' });
            } else if (path.includes('casino')) {
                vm.setState({ category: 'C' });
            }

            vm.setState({ currentProvider: path });
            resolve(vm);
        });
    },

    getFooterProviderList(countryLanguageKey) {
        return new Promise(function (resolve) {
            window.SPL_JsonSettings.getMenuSettingsJson((menuSettings) => {
                if (menuSettings[countryLanguageKey] && menuSettings[countryLanguageKey].web) {
                    let webMenu = menuSettings[countryLanguageKey].web;
                    let menus = webMenu.menu.ordering;
                    let submenus = webMenu.submenu;
                    let submenuKeys = Object.keys(submenus);
                    let indexMenus = webMenu.indexMenu;
                    let indexKeys = null;
                    let menuShowingList = [];

                    for (let i = 0; i < menus.length; i++) {
                        if (menus[i] === 'Poker') {
                            menuShowingList.push(menus[i]);
                        }
                    }

                    for (let i = 0; i < submenuKeys.length; i++) {
                        let submenuList = submenus[submenuKeys[i]].ordering;
                        for (let j = 0; j < submenuList.length; j++) {
                            menuShowingList.push(submenuList[j]);
                        }
                    }

                    if (indexMenus) {
                        indexKeys = Object.keys(indexMenus);
                        for (let i = 0; i < indexKeys.length; i++) {
                            let indexmenuList = indexMenus[indexKeys[i]].ordering;
                            for (let j = 0; j < indexmenuList.length; j++) {
                                menuShowingList.push(indexmenuList[j]);
                            }
                        }
                    }

                    // get all available footer icon list
                    let footerIconList = [];
                    let providerList = provider.providers;
                    let providers = Object.keys(providerList);
                    for (let i = 0; i < providers.length; i++) {
                        if (providers[i] === 'explaination') {
                            continue;
                        }
                        let provider = providerList[providers[i]];
                        if (provider.footerIcon && provider.footerIcon !== '') {
                            let foundShowingMenu = menuShowingList.filter((a) => a === provider.menuName);
                            if (foundShowingMenu.length > 0) {
                                footerIconList.push(provider);
                            }
                        }
                    }
                    resolve(footerIconList);
                } else {
                    resolve([]);
                }
            });
        });
    },
};

export default provider;
