import React from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import commonUtil from 'root/utils/common-util';

import moment from 'moment';

class HistoryPopup extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
        this.state = {
            selectedRebateList: this.props.item[0].productHistories,
            provider: this.props.item[0].product,
        };
    }

    componentDidMount() {
        commonUtil.loadCustomTranslation('instant-rebate', this, function () {});
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    onProviderHistoryChange(provider, selectedRebateList) {
        this.setState({ provider: provider, selectedRebateList: selectedRebateList });
    }

    toggle() {
        this.setState({ isOpenRebate: !this.state.isOpenRebate });
    }

    getShowMoreList(data) {
        if (this.state.isOpenRebate) {
            return data;
        }
        return data.slice(0, 2);
    }

    render() {
        const { closeFunc, item, startDate, endDate } = this.props;
        const { customTranslationObj, selectedRebateList, provider } = this.state;
        return (
            <Translation>
                {(t) => (
                    <div className='rebate-popup'>
                        <div className='standard-popup-modal-header rebate-popup-title group-item'>
                            <div className='standard-modal-close' onClick={() => closeFunc()}>
                                <span> ←</span>
                            </div>
                            <div className='popup-header-left'>
                                <div className='popup-title'>{moment(startDate).format('YYYY-MM-DD').toString() + '-' + moment(endDate).format('YYYY-MM-DD').toString()}</div>
                            </div>
                        </div>
                        <div className='group-item'>
                            {/* show provider display */}
                            {item && item.length > 0 && (
                                <div className='rebate-popup-option-title rebate-option'>
                                    {item.map((rebateProvider, i) => (
                                        <span
                                            className={provider === rebateProvider.product ? 'on' : ''}
                                            onClick={() => this.onProviderHistoryChange(rebateProvider.product, rebateProvider.productHistories)}
                                        >
                                            {customTranslationObj && customTranslationObj[rebateProvider.product]
                                                ? t(customTranslationObj[rebateProvider.product])
                                                : rebateProvider.product}
                                        </span>
                                    ))}
                                </div>
                            )}

                            {/* show provider data display */}
                            {selectedRebateList && (
                                <div className='rebate-popup-provider-list'>
                                    {this.getShowMoreList(selectedRebateList).map((hist, i) => (
                                        <ul>
                                            <li>
                                                <span>{t('rebate:rebate.memberLevel', 'Member Level')}</span>
                                                <span className='data'>{hist.memberGroupCode}</span>
                                            </li>
                                            <li>
                                                <span>{t('rebate:rebate.provider', 'Provider')}</span>
                                                <span className='data'>
                                                    {customTranslationObj && customTranslationObj[hist.provider] ? t(customTranslationObj[hist.provider]) : hist.provider}
                                                </span>
                                            </li>
                                            <li>
                                                <span>{t('rebate:rebate.rebate', 'Rebate %')}</span>
                                                <span className='data'>{hist.rate}</span>
                                            </li>
                                            <li>
                                                <span>{t('rebate:rebate.validBet', 'Valid Bet')}</span>
                                                <span className='data'>{hist.totalEligibleTurnOver}</span>
                                            </li>
                                            <li>
                                                <span>{t('rebate:rebate.rebateEarned', 'Rebate Earned')}</span>
                                                <span className='data rebate-earned-data'>{hist.releasedAmount.toFixed(2)}</span>
                                            </li>
                                        </ul>
                                    ))}

                                    {selectedRebateList.length > 2 && (
                                        <div className='rebate-show-button' onClick={() => this.toggle(selectedRebateList)}>
                                            {/* {t('', 'ShowMore')} */}
                                            <span>{this.state.isOpenRebate ? t('', 'ShowLess') : t('', 'ShowMore')}</span>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['rebate'])(HistoryPopup));
