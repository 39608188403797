import moment from 'moment';
import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import { Translation, withTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { portalAction } from 'root/redux/action';
import { mapStateToProps } from 'root/redux/selector/selector';
import vm from '../message-detail-dialog.controller';

class MessageDetailDialog extends Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
        vm.init(this);
    }

    componentWillMount() {
        Modal.setAppElement('#root');
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        return (
            <Translation>
                {(t) => (
                    <div>
                        {this.props.user.isSpecialDialog &&
                            createPortal(
                                <div className={`tool-bar funds ${this.props.user.isSpecialDialog ? 'messageDetails' : ''} `}>
                                    <div className='title-box'>
                                        <div className='left'>
                                            <div className='icon-box'>
                                                <i
                                                    className='icon-back'
                                                    onClick={() => {
                                                        this.props.closeMessageDetailDialog(this.props.parentvm);
                                                    }}
                                                ></i>
                                            </div>
                                            <span>{}</span>
                                        </div>

                                        <div className='icon-box'>
                                            <i
                                                className='icon-menu'
                                                onClick={() => {
                                                    this.props.dispatch(portalAction.floatingPageUpdated('sidemenu'));
                                                }}
                                            ></i>
                                        </div>
                                    </div>
                                </div>,
                                document.querySelector('#fixed-container')
                            )}
                        <div className='inbox-details'>
                            <div className='inbox-details-sub'>
                                <div className='inbox-list inbox-tab-container'>
                                    <div
                                        className={['inbox-tab', 'on'].join(' ')}
                                        onClick={() => {
                                            vm.handleTabClick('I');
                                        }}
                                    >
                                        <div className={'title'}>
                                            {' '}
                                            <span>{this.state.messageObj.title}</span>{' '}
                                        </div>
                                        <div className={'date'}>
                                            {' '}
                                            <span>{moment(this.state.messageObj.sentTime || this.state.messageObj.createdDate).format('DD-MM-YYYY HH:mm').toString()}</span>{' '}
                                        </div>
                                    </div>
                                </div>
                                <div className='inbox-detail'>
                                    <div className='inbox-detail-content' dangerouslySetInnerHTML={{ __html: this.state.messageObj.message }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation('')(withRouter(MessageDetailDialog)));
