import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { withRouter } from 'react-router-dom';
import { TweenMax } from 'gsap';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import LeftSubMenu from 'root/webapp/shared/component/wap/LeftSubMenu';
import HomeMemberProfile from 'root/webapp/shared/component/wap/HomeMemberProfile';

import vm from '../4d.controller';

class FourD extends React.Component {
    tween1;
    tween2;

    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        vm.getFourDProviderList(true).then((data) => {
            this.toggleAni();
        });
    }

    toggleAni() {
        this.tween1 && this.tween1.kill();
        this.tween2 && this.tween2.kill();

        TweenMax.set('.brand-page ul.ani-ul > li', {
            alpha: 0,
        });

        this.tween1 = TweenMax.delayedCall(0.3, function () {
            this.tween2 = TweenMax.staggerTo('.brand-page ul.ani-ul > li', 0.3, { alpha: 1 }, 0.08);
        });
    }

    checkMaintenance(fourD) {
        if (fourD) {
            if (!fourD.maintenance) {
                vm.updateSelectedProvider(fourD);
                this.onClick(fourD);
            }
        }
    }

    onClick(provider) {
        const { history } = this.props;

        history.push({
            pathname: '/4d/' + provider.routeName,
            query: { provider: provider.provider },
        });
    }

    render() {
        return (
            <Translation>
                {(t) => (
                    <Fragment>
                        <div className='four-d-page'>
                            <HomeMemberProfile />
                            <div className='four-d-box-container'>
                                <div className='brand-page'>
                                    <LeftSubMenu />
                                    <div className='four-d-list-box'>
                                        <span className='item-title'>{t('fourD:fourD.4dLottery', '4D Lottery')}</span>
                                        <ul className='ani-ul'>
                                            {this.state.list.map((fourDObj, i) => (
                                                <li
                                                    key={fourDObj.key}
                                                    onClick={() => {
                                                        this.checkMaintenance(fourDObj);
                                                    }}
                                                    className={`${fourDObj.isDisableProvider ? 'provider-disable' : ''}`}
                                                >
                                                    {fourDObj.isHotProvider && (
                                                        <div class='hotnewicon-fourd-container'>
                                                            <img src='/public/html/images/hoticon_mobile.svg' alt='' />
                                                        </div>
                                                    )}

                                                    {fourDObj.isNewProvider && (
                                                        <div class='hotnewicon-fourd-container'>
                                                            <img src='/public/html/images/newicon_mobile.svg' alt='' />
                                                        </div>
                                                    )}
                                                    <LazyLoadImage
                                                        src={'/public/html/images/fourD/mobile/' + fourDObj.key + '.png'}
                                                        alt=''
                                                        className={fourDObj.maintenance ? 'maintenance-cover' : ''}
                                                    />

                                                    {fourDObj.maintenance && <div className='maintenance-hover-box'></div>}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation('fourD')(withRouter(FourD)));
