import moment from 'moment';
import React from 'react';
import FlipMove from 'react-flip-move';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapStateToProps } from 'root/redux/selector/selector';
import Pagination from 'root/webapp/shared/pagination/pagination.selector';
import DeleteMessageDialog from '../../../delete-message-dialog/delete-message-dialog.selector';
// import SVGMessage from 'root/components/Icon/SVGMessage'
import MessageDetailDialog from '../../../message-detail-dialog/message-detail-dialog.selector';
import vm from '../../message.controller';

class Message extends React.Component {
    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        vm.getMessages(0, this.state.currentMsgType);
        vm.getMemberMessageCount();
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        const { currentMsgType } = this.state;

        let messageDetailDialogProps = {
            parentvm: this,
            messageDetailDialogIsShow: this.state.messageDetailDialogIsShow,
            closeMessageDetailDialog: vm.closeMessageDetailDialog,
            selectedMessageObj: this.state.selectedMessageObj,
        };

        let deleteMessageDialogProps = {
            parentvm: this,
            deleteMessageDialogIsShow: this.state.deleteMessageDialogIsShow,
            closeDeleteMessageDialog: vm.closeDeleteMessageDialog,
            selectedMessageIds: this.state.selectedMessageIds,
            getMemberMessageCount: vm.getMemberMessageCount,
        };

        const { totalUnreadNotificationCount, totalUnreadPaymentCount, totalUnreadPromotionCount, totalUnreadSystemCount } = this.props.user;

        return (
            <Translation>
                {(t) => (
                    <div className='inbox-page'>
                        {this.state.messageDetailDialogIsShow && <MessageDetailDialog {...messageDetailDialogProps}></MessageDetailDialog>}

                        {this.state.deleteMessageDialogIsShow && <DeleteMessageDialog {...deleteMessageDialogProps}></DeleteMessageDialog>}
                        <div className='inbox-list inbox-tab-container'>
                            <div
                                className={['inbox-tab', currentMsgType === 'P' ? 'on' : ''].join(' ')}
                                onClick={() => {
                                    vm.handleTabClick('P');
                                }}
                            >
                                <span>
                                    {t('settings:settings.profiletab.promotion')} ({totalUnreadPromotionCount > 0 ? totalUnreadPromotionCount : 0})
                                </span>
                            </div>
                            <div
                                className={['inbox-tab', currentMsgType === 'PT' ? 'on' : ''].join(' ')}
                                onClick={() => {
                                    vm.handleTabClick('PT');
                                }}
                            >
                                <span>
                                    {t('settings:settings.profiletab.payment')} ({totalUnreadPaymentCount > 0 ? totalUnreadPaymentCount : 0})
                                </span>
                            </div>
                            <div
                                className={['inbox-tab', currentMsgType === 'N' ? 'on' : ''].join(' ')}
                                onClick={() => {
                                    vm.handleTabClick('N');
                                }}
                            >
                                <span>
                                    {t('settings:settings.profiletab.system')} ({totalUnreadSystemCount > 0 ? totalUnreadSystemCount : 0})
                                </span>
                            </div>
                            <div
                                className={['inbox-tab', currentMsgType === 'I' ? 'on' : ''].join(' ')}
                                onClick={() => {
                                    vm.handleTabClick('I');
                                }}
                            >
                                <span>
                                    {t('settings:settings.profiletab.notification')} ({totalUnreadNotificationCount > 0 ? totalUnreadNotificationCount : 0})
                                </span>
                            </div>
                        </div>
                        <div className='inbox-list'>
                            <ul>
                                <li>
                                    <div className='check-box'>
                                        <i
                                            className={['checkbox', this.state.allChecked ? 'on' : ''].join(' ')}
                                            onClick={() => {
                                                vm.handleSelectAll();
                                            }}
                                        ></i>
                                    </div>
                                </li>
                                <li>
                                    <span
                                        onClick={() => {
                                            vm.handleSelectAll();
                                        }}
                                    >
                                        {t('message:message.selectAll', 'Select All')}
                                    </span>
                                </li>
                                <li></li>
                                <li className='icon-list'>
                                    <i
                                        className='icon-read'
                                        onClick={() => {
                                            vm.readMessageByBatch();
                                        }}
                                    ></i>
                                    <i
                                        className={['icon-star', this.state.allFlag ? 'on' : ''].join(' ')}
                                        onClick={() => {
                                            vm.flagSelectedMessage();
                                        }}
                                    ></i>
                                    <i
                                        className='icon-delete'
                                        onClick={() => {
                                            vm.openDeleteMessageDialog();
                                        }}
                                    ></i>
                                </li>
                            </ul>

                            <div className='table-header'>
                                <ul>
                                    <li></li>
                                    <li>{t('message:message.date')}</li>
                                    <li>{t('message:message.subject')}</li>
                                    <li></li>
                                </ul>
                            </div>
                            <FlipMove className='table-list' enterAnimation='fade' leaveAnimation='fade'>
                                {this.state.messageList.map((msgData, index) => (
                                    <ul key={index} className={[msgData.status === 'N' || msgData.status === 'FN' ? 'newMessage' : ''].join('')}>
                                        <li>
                                            <div className='check-box'>
                                                <i
                                                    className={['checkbox', msgData.isChecked ? 'on' : ''].join(' ')}
                                                    onClick={() => {
                                                        vm.handleRowCheckbox(index);
                                                    }}
                                                ></i>
                                            </div>
                                        </li>
                                        <li onClick={() => vm.openMessageDetailDialog(index)}>
                                            <span> {moment(msgData.sentTime || msgData.createdDate).format('DD-MM-YYYY HH:mm').toString()}</span>
                                        </li>
                                        <li onClick={() => vm.openMessageDetailDialog(index)}>
                                            <span>{msgData.title}</span>
                                        </li>

                                        <li className='icon-list'>
                                            <i
                                                className={['icon-star', vm.isFlag(msgData.status) ? 'on' : ''].join(' ')}
                                                onClick={() => {
                                                    vm.flagMessage(index);
                                                }}
                                            ></i>
                                            <i
                                                className='icon-delete'
                                                onClick={() => {
                                                    vm.openOneDeleteMessageDialog(msgData);
                                                }}
                                            ></i>
                                        </li>
                                    </ul>
                                ))}
                            </FlipMove>
                            <Pagination
                                pagingList={this.state.pagingList}
                                handlePrevNextClick={vm.handlePrevNextClick}
                                handlePageClick={vm.handlePageClick}
                                currentPage={this.state.currentPage}
                                itemPerPage={this.state.itemPerPage}
                                totalCount={this.state.totalCount}
                                currentMsgType={currentMsgType}
                            ></Pagination>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation(['message', 'settings'])(withRouter(Message)));
