import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import core from 'root/utils/core';

import BankQRDeposit1 from './component/desktop/bank-qr-deposit';

import MobileBankQRDeposit1 from './component/mobile/m-bank-qr-deposit';

class BankQRDeposit extends React.Component {
    props = null;

    constructor(props) {
        super();
        this.props = props;
    }

    components = {
        desktop: {
            default: BankQRDeposit1,
        },
        mobile: {
            default: MobileBankQRDeposit1,
        },
    };

    render() {
        const TagName = core.getCorrectComponent(this.components, 'bank-qr-deposit', this.props.screen.viewType);
        return <TagName {...this.props} />;
    }
}

export default connect(mapStateToProps, null)(BankQRDeposit);
