import React from 'react';
import { Translation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { mapStateToProps } from 'root/redux/selector/selector';
import SmallLoading from 'root/webapp/shared/loading/smallLoading';
import vm from '../../ewallet.controller';
import MobileSelect from 'root/webapp/shared/component/mobileSelect/mobileSelect';
import BankIcon from '../../../../../../components/molecules/bank-icon/bank-icon';

class Ewallet extends React.Component {
    // props = null;

    constructor(props) {
        super();
        vm.init(this);
    }

    componentDidMount() {
        vm.checkFirstMemberBank();
        vm.getMemberDepositWithdrawDecimalSettings();
    }

    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        const { ewalletList, user, minmaxLimit, screen, amountOptions } = this.props;
        const { account } = user;
        const { selectedBankIndex, hoveredIndex, isLoading } = this.state;
        let userCurrency = this.props.user && this.props.user.account && this.props.user.account.currency ? this.props.user.account.currency : null;

        if (userCurrency === 'KHUSD') userCurrency = 'USD';

        return (
            <Translation>
                {(t) => (
                    <>
                        <div className='group-item'>
                            {screen.viewType !== 'mobile' && (
                                <div className='title'>
                                    <span>{t('bank:bank.newBank.eWallet')}</span>
                                    <span className='required'>*</span>
                                </div>
                            )}
                            <div className={`bank-list`}>
                                {!isLoading && ewalletList && ewalletList.length > 0 ? (
                                    <ul>
                                        {screen.viewType === 'mobile' ? (
                                            <div className='group-item'>
                                                <MobileSelect
                                                    itemList={ewalletList}
                                                    itemKey={'name'}
                                                    onChange={(e) => {
                                                        window.SPL_Other.sequenceCallback(vm.handleChanged.bind(vm, e))(() => {
                                                            vm.handleValidation();
                                                        });
                                                    }}
                                                    itemIndex={selectedBankIndex}
                                                    title={t('bank:bank.newBank.eWallet', 'Ewallet')}
                                                    required={true}
                                                    onChangeIndexType={true}
                                                    isPleaseSelect={false}
                                                />
                                            </div>
                                        ) : (
                                            <>
                                                {ewalletList.map((bank, i) => (
                                                    <li
                                                        key={i}
                                                        className={['icon-hover-box', i === selectedBankIndex ? 'on' : '', `${account.currency}`].join(' ')}
                                                        onClick={() => {
                                                            window.SPL_Other.sequenceCallback(vm.handleChanged.bind(vm, i))(() => {
                                                                vm.handleValidation();
                                                            });
                                                        }}
                                                        onMouseEnter={() => {
                                                            this.setState({ hoveredIndex: i });
                                                        }}
                                                        onMouseLeave={() => {
                                                            this.setState({ hoveredIndex: null });
                                                        }}
                                                    >
                                                        <span className={`icon-box ${i === selectedBankIndex || i === hoveredIndex ? 'icon-bank-on' : 'icon-bank-off'}`}>
                                                            <BankIcon bankCode={bank.bankCode || bank.code} />
                                                        </span>

                                                        <span>{bank.name}</span>
                                                    </li>
                                                ))}
                                            </>
                                        )}
                                    </ul>
                                ) : (
                                    <SmallLoading></SmallLoading>
                                )}
                            </div>
                        </div>

                        <div className='group-item input-item'>
                            <div className='title'>
                                <span>{t('bank:bank.newBank.walletName', 'Ewallet Name')}</span>
                                <span className='required'>*</span>
                            </div>
                            <div className='group'>
                                <div className='input-box'>
                                    <input type='text' value={account.name} disabled={true}></input>
                                </div>
                            </div>
                        </div>

                        <div className='group-item input-item'>
                            <div className='title'>
                                <span>{t('bank:bank.newBank.walletNumber', 'Ewallet Number')}</span>
                                <span className='required'>*</span>
                            </div>
                            <div className='group'>
                                <div className='input-box'>
                                    <input
                                        type='text'
                                        placeholder='Exp : 1234567'
                                        value={this.state.fields['accountNumber']}
                                        onChange={(e) => vm.handleFieldChanged(e, 'accountNumber')}
                                        onBlur={() => vm.handleValidation()}
                                        disabled={this.state.disabledFields['accountNumber']}
                                    ></input>
                                </div>
                                <div className='error-validation'>{t(this.state.errors['accountNumber'])}</div>
                            </div>
                        </div>

                        <div className='group-item input-item'>
                            <div className='title'>
                                <span>{t('transaction:transaction.withdrawal.amount', 'Amount')}</span>
                                <span className='required'>*</span>
                            </div>
                            <div className='group'>
                                <div className='input-box'>
                                    {minmaxLimit ? (
                                        <input
                                            type='numeric'
                                            min='0'
                                            value={this.state.fields['amount']}
                                            onChange={(e) => vm.handleAmountChange(e, 'amount')}
                                            onBlur={() => vm.handleValidation()}
                                            placeholder={t('global:global.form.amount-placeholder', {
                                                min: minmaxLimit ? minmaxLimit.minLimitDisplay : '',
                                                max: minmaxLimit ? minmaxLimit.maxLimitDisplay : '',
                                            })}
                                        ></input>
                                    ) : (
                                        <SmallLoading></SmallLoading>
                                    )}
                                </div>
                                {amountOptions && (
                                    <ul>
                                        {amountOptions?.map((item) => (
                                            <li
                                                key={item}
                                                onClick={() => {
                                                    vm.onWithdrawAmountClicked(item);
                                                }}
                                            >
                                                {item}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                                <div className='error-validation'>{t(this.state.errors['amount'])}</div>
                            </div>
                        </div>

                        <button className='btn-submit' disabled={this.state.disableWithdrawButton || !this.state.isFormValid} onClick={() => vm.submitForm()}>
                            {t('transaction:transaction.withdrawal.withdraw', 'Withdraw').toUpperCase()}
                        </button>
                    </>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps)(withTranslation([''])(Ewallet));
