import moment from 'moment';
import 'moment/locale/zh-cn';
import 'moment/locale/th';
import 'moment/locale/ms';

const controller = {
    vm: null,

    init(vm) {
        let currentDate = new Date();
        let startDate = currentDate.getTime() - 7 * 24 * 3600000;
        let endDate = currentDate;

        this.vm = vm;
        this.vm.state = {
            referralSettings: null,
            referralCode: null,
            isLoading: false,
            enabledReferralComm: false,
            showShareModal: false,
            showTermsModal: false,
            showSearchModal: false,
            showDetailModal: false,
            selectedTab: 'myReferral',
            fields: {
                startDate: startDate,
                endDate: endDate,
            },

            sorting: {
                createdDate: 'desc',
            },
            currentSort: 'createdDate,desc',
            currentPage: 1,
            itemPerPage: 5,
            predicate: 'id',
            reverse: false,
            totalPage: 0,
            pagingList: [],

            histories: [],
            disableMemberBecomeReferral: false,
        };
    },

    toogleTab(tab) {
        if (tab !== controller.vm.state.selectedTab) {
            controller.vm.setState({ selectedTab: tab });
        }
    },

    async getReferralTypeEnabledAndMemberSummaryGroupAutomation() {
        const referralSettingData = await window.SPL_Member.getReferralTypeEnabledAndMemberSummaryGroupAutomation();

        controller.vm.setState({
            disableMemberBecomeReferral: referralSettingData?.disableMemberBecomeReferral,
        });
    },

    getReferralSettings() {
        let currency = controller.vm.props.user.account.currency;
        const lang = controller.vm.props.language.key;
        controller.vm.setState({ isLoading: true });

        if (currency === 'KHUSD') {
            currency = 'USD';
        }

        window.SPL_Referral.initReferralSetting(currency, lang, window.globMerchantName).then((data) => {
            let referralOption = null;
            if (data && data.referralSetting && data.referralSetting.referralOption) {
                referralOption = data.referralSetting.referralOption;
            }

            // handling for json file
            const { lastModifiedDate } = data.referralDetail;
            if (lastModifiedDate) {
                const lowerCaseLang = lang.toLowerCase();
                data.referralDetail.year = moment(lastModifiedDate).format('YYYY');
                data.referralDetail.month = moment(lastModifiedDate).format(lowerCaseLang === 'zh-cn' ? 'M' : 'MMMM');
                data.referralDetail.day = moment(lastModifiedDate).format(lowerCaseLang === 'zh-cn' ? 'D' : 'Do');
            }

            controller.vm.setState({ referralSettings: data, referralOption });

            window.SPL_Referral.getReferralTypeEnabled().then((data) => {
                if (data && data.referralComm) {
                    controller.vm.setState({ enabledReferralComm: true });
                }
            });
            controller.vm.setState({ isLoading: false });
        });
    },

    searchHistory() {
        if (controller.vm.state.enabledReferralComm) {
            controller.searchReferralComm();
        } else {
            controller.searchReferrals();
        }
    },

    searchReferrals() {
        const { currentPage, itemPerPage, fields, predicate, reverse } = controller.vm.state;
        const { startDate, endDate } = fields;
        let start = moment(startDate).format('YYYY-MM-DD').toString();
        let end = moment(endDate).format('YYYY-MM-DD').toString();
        let params = {
            page: currentPage,
            itemsPerPage: itemPerPage,
            predicate: predicate,
            reverse: reverse,
        };

        window.SPL_Referral.searchAllReferrals(params, start, end).then((res) => {
            controller.vm.setState(
                {
                    histories: res.myReferrals,
                    totalItems: res.totalItems,
                    totalPageSize: res.totalPageSize,
                },
                () => {
                    controller.handlePagination();
                }
            );
        });
    },

    searchReferralComm() {
        const { currentPage, itemPerPage, fields, predicate, reverse } = controller.vm.state;
        const { startDate, endDate } = fields;
        let start = moment(startDate).format('YYYY-MM-DD').toString();
        let end = moment(endDate).format('YYYY-MM-DD').toString();
        let params = {
            page: currentPage,
            itemsPerPage: itemPerPage,
            predicate: predicate,
            reverse: reverse,
        };

        window.SPL_Referral.searchAllReferrals(params, start, end).then((res) => {
            controller.vm.setState(
                {
                    histories: res.myReferrals,
                    totalItems: res.totalItems,
                    totalPageSize: res.totalPageSize,
                },
                () => {
                    controller.handlePagination();
                }
            );
        });
    },

    handleDateChanged(date, isStartDate) {
        let fields = controller.vm.state.fields;
        if (isStartDate) {
            fields.startDate = date;
        } else {
            fields.endDate = date;
        }
        controller.vm.setState({ fields });
    },

    handlePrevNextClick(isPrev, firstLast) {
        const { currentPage, totalPage } = controller.vm.state;

        if (firstLast) {
            if (firstLast === 'first') {
                controller.handlePageClick(1);
            } else {
                controller.handlePageClick(totalPage);
            }
        } else {
            if (isPrev) {
                controller.handlePageClick(currentPage - 1);
            } else {
                controller.handlePageClick(currentPage + 1);
            }
        }
    },

    handlePageClick(newPageNum) {
        const { currentPage, totalPage } = controller.vm.state;
        if (currentPage === newPageNum || newPageNum === 0 || newPageNum > totalPage) {
            // do nothing
        } else {
            controller.vm.setState({ currentPage: newPageNum }, () => {
                controller.searchHistory(newPageNum);
            });
        }
    },

    handlePagination() {
        const { currentPage, totalPage } = controller.vm.state;
        let pagingList = [];

        for (let i = 0; i < totalPage; i++) {
            let active = false;
            if (i + 1 === currentPage) {
                active = true;
            }

            let pageObj = {
                pageNum: i + 1,
                active: active,
            };
            pagingList.push(pageObj);
        }
        controller.vm.setState({ pagingList: pagingList, showSearchModal: false });
    },

    toggleModal(modal) {
        switch (modal) {
            case 'search':
                controller.vm.setState({ showSearchModal: !controller.vm.state.showSearchModal });
                break;
            case 'terms':
                controller.vm.setState({ showTermsModal: !controller.vm.state.showTermsModal });
                break;
            case 'share':
                controller.vm.setState({ showShareModal: !controller.vm.state.showShareModal });
                break;
            case 'details':
                controller.vm.setState({ showDetailModal: !controller.vm.state.showDetailModal });
                break;
            default:
                break;
        }
    },

    share(code, link) {
        if (navigator.share) {
            navigator
                .share({
                    title: 'Referral Code',
                    text: code,
                })
                .then(() => {
                    if (!window.isAgent && !window.isAffiliate) {
                        if (window.SPL_LiteApp.isZT828LiteApp()) {
                            window.SPL_LiteApp.onShareButtonTapped(code, link);
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            controller.toggleModal('share');
        }
    },

    openBonusDetailDialog(historyObj) {
        const { t } = controller.vm.props;

        let displayList = [];
        if (!controller.vm.state.enabledReferralComm) {
            displayList = [
                {
                    key: t('settings:settings.memberLogin'),
                    value: historyObj.refereeLogin,
                },
                {
                    key: t('settings:settings.registerTime'),
                    value: moment(historyObj.date).format('YYYY-MM-DD HH:mm').toString(),
                },
                {
                    key: t('settings:settings.currency'),
                    value: historyObj.refereeCurrency,
                },
                {
                    key: t('settings:settings.totalDeposit'),
                    value: historyObj.targetDepositAmount,
                },
                {
                    key: t('settings:settings.bonusAmount'),
                    value: historyObj.referralBonusAmount,
                },
                {
                    key: t('settings:settings.status'),
                    value: t(historyObj.status),
                },
            ];
        } else {
            displayList = [
                {
                    key: t('settings:settings.memberLogin'),
                    value: historyObj.memberLogin,
                },
                {
                    key: t('transaction:transaction.filter.start_date'),
                    value: historyObj.startTime,
                },
                {
                    key: t('transaction:transaction.filter.end_date'),
                    value: historyObj.endTime,
                },
                {
                    key: t('transaction:transaction.history.provider'),
                    value: historyObj.provider,
                },
                {
                    key: t('settings:settings.currency'),
                    value: historyObj.currency,
                },
                {
                    key: t('settings:settings.totalEligibleTurnOver'),
                    value: historyObj.totalValidTurnOver - historyObj.totalPromoTurnOver,
                },
                {
                    key: t('settings:settings.totalWinLoss'),
                    value: historyObj.totalWinLoss,
                },
                {
                    key: t('settings:settings.commType'),
                    value: historyObj.commissionType,
                },
                {
                    key: t('settings:settings.commRate'),
                    value: historyObj.commissionRate,
                },
                {
                    key: t('settings:settings.commAmount'),
                    value: historyObj.referralCommAmount,
                },
                {
                    key: t('settings:settings.status'),
                    value: t(historyObj.status),
                },
                {
                    key: t('settings:settings.ApprovedDate'),
                    value: moment(historyObj.lastModifiedDate).format('YYYY-MM-DD HH:mm').toString(),
                },
            ];
        }

        controller.vm.setState({ showDetailModal: true, displayList: displayList });
    },

    momentJSLocalization() {
        const { key } = controller.vm.props.language;
        const lang = key ? key.toLowerCase() : 'en';
        moment.locale(lang);
    },
};

export default controller;
