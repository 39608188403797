import memberService from 'root/utils/memberService';

const controller = {
    vm: null,

    init(vm) {
        this.vm = vm;
        this.vm.state = {
            hotGameList: [],
            favoritedGame: [],
            active: 0,
            isLoading: false,
            search: '',
            jackpotObj: null,
        };
    },

    getHotGames() {
        memberService.getHotGames(controller.vm, controller.vm.props);
    },

    getMemberFavoriteGame() {
        memberService.getMemberFavoriteGame();
    },

    updateMemberFavoriteGame(game) {
        memberService.updateMemberFavoriteGame(controller.vm, controller.vm.props, game);
    },

    handleKeywordChanged(e) {
        let keyword = e.target.value;
        controller.vm.setState({ search: keyword });
    },

    getJackpotValue() {
        const { user, language, t } = controller.vm.props;
        let currency = language.currencyLang;
        if (user.isLogin) {
            currency = user.account.currency;
        }

        window.SPL_Content.getJackpotDisplay2('SG', currency).then((data) => {
            let jackpotObj = {
                provider: 'SG',
                amount: Number(data.jackpotValue),
            };
            controller.vm.setState({ jackpotObj: jackpotObj });
        });
    },
};

export default controller;
