export function pu_fetchHotGameSliderJSON(vm) {
    return new Promise((resolve) => {
        window.SPL_Content.getHotGameSlider(vm.props).then((res) => {
            resolve(res);
        });
    });
}

export function pu_processGameDataWithFavGames(gameList = [], favGames = []) {
    if (favGames?.length <= 0) {
        return gameList;
    }

    let newGameList = [...gameList];
    for (let k = 0; k < favGames.length; k++) {
        for (let a = 0; a < gameList.length; a++) {
            if (newGameList[a].id === favGames[k].id) {
                newGameList[a].isFav = true;
            }
        }
    }

    return newGameList;
}
