import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Translation, withTranslation } from 'react-i18next';
import { mapStateToProps } from 'root/redux/selector/selector';
import { CSSTransition } from 'react-transition-group';
import { withRouter } from 'react-router-dom';
import { portalAction } from 'root/redux/action';
import SVGElements from 'root/components/SVGElements';

import SmallLoading from 'root/webapp/shared/loading/smallLoading';

import vm from '../../navbar.controller';

class MNavbar extends React.Component {
    constructor() {
        super();
        vm.init(this);
    }

    componentDidMount() {
        vm.initAfterLogin();
        vm.getCurrentPage();
        vm.getBrandLogo();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.portal.pathname !== this.props.portal.pathname) {
            vm.getCurrentPage();
        }
    }

    componentWillUnmount() {
        vm.stopMemberMessageUnreadCountTimer();
        this.setState = () => {
            return;
        };
    }

    render() {
        const dataPage = this.props?.generalReducer?.dataPage;
        const { brandLogoObj } = this.state;
        let userCurrency = this.props.user && this.props.user.account && this.props.user.account.currency ? this.props.user.account.currency : null;

        if (userCurrency === 'KHUSD') userCurrency = 'USD';

        return (
            <Translation>
                {(t) => (
                    <div className='tool-bar'>
                        <div className='icon-box'>
                            <i
                                data-page={dataPage}
                                className='icon-logo'
                                style={{
                                    backgroundImage: `url(${brandLogoObj && brandLogoObj.brandLogoMobile})`,
                                }}
                                onClick={() => {
                                    this.props.history.push('/home');
                                }}
                            ></i>
                        </div>
                        <div className='content'>
                            {this.props.user.isLogin ? (
                                <div className='restore-box'>
                                    <div className='currency-count'>
                                        {this.state.mainWallet !== null ? (
                                            <Fragment>
                                                <span className='currency' data-page={dataPage}>
                                                    {userCurrency}
                                                </span>
                                                <span className='count'>{window.SPL_Other.formatAmount(this.state.mainWallet.balance)}</span>
                                            </Fragment>
                                        ) : (
                                            <SmallLoading></SmallLoading>
                                        )}
                                    </div>
                                    <CSSTransition classNames='rotate360' in={this.state.mainWalletRefreshing} timeout={1000}>
                                        <span className='icon-box' data-page={dataPage}>
                                            <SVGElements
                                                className='icon-reload'
                                                name='reload-icon'
                                                onClick={() => {
                                                    vm.refreshWallet();
                                                }}
                                            />
                                            {/* <i className="icon-reload" onClick={() => { vm.refreshWallet(); }}></i> */}
                                        </span>
                                    </CSSTransition>
                                    <span className='icon-box' data-page={dataPage}>
                                        <SVGElements
                                            className='icon-restore'
                                            name='restore-icon'
                                            onClick={() => {
                                                vm.restoreWallet();
                                            }}
                                        />
                                        {/* <i className="icon-restore" onClick={() => { vm.restoreWallet(); }}></i> */}
                                    </span>
                                </div>
                            ) : (
                                <div className='login-btn'>
                                    <span
                                        className='btn-login'
                                        onClick={() => {
                                            this.props.dispatch(portalAction.floatingPageUpdated('login'));
                                        }}
                                    >
                                        {t('global:global.menu.account.fixLogin', 'LOGIN')}
                                    </span>
                                    <span
                                        className='btn-sign-up'
                                        data-page={dataPage}
                                        onClick={() => {
                                            this.props.dispatch(portalAction.floatingPageUpdated('register'));
                                        }}
                                    >
                                        {t('global:global.menu.account.register', 'JOIN NOW')}
                                    </span>
                                </div>
                            )}
                        </div>
                        <div className='icon-box'>
                            <i
                                className='icon-menu'
                                onClick={() => {
                                    this.props.dispatch(portalAction.floatingPageUpdated('sidemenu'));
                                }}
                            ></i>
                        </div>
                    </div>
                )}
            </Translation>
        );
    }
}

export default connect(mapStateToProps, null)(withTranslation('home')(withRouter(MNavbar)));
