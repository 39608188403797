import React, { useEffect, useState, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// core/logic/helper
import { MODULE_TYPE, OTP_VERIFICATION_TYPE, CRYPTO_ADDRESS_FIELD as CAF, OTP_VERIFICATION_METHOD } from '@utils/constants';
import { useRequestOtp } from '@hooks/otp-hook';
import notification from '@utils/notification';

// components
import styles from './add-crypto-address-popup.module.scss';
import Popup, { PopupHeader, PopupBody } from '@components/organisms/popup/popup';
import OtpFormField from '@components/molecules/otp-form-field/otp-form-field';
import Button from '@components/atoms/button/button';
import FormField from '@components/molecules/form-field/form-field';

/**
 *
 * @param {String} selectedWithdrawalCoin -- passed from withdrawal crypto add bank, if normally open add bank, this will be null
 * @returns
 */
const AddCryptoAddressPopup = ({ selectedWithdrawalCoin = null, ...props }) => {
    const { isOpen, onRequestClose, onCryptoAddressPopupSubmitSuccessCb } = props;
    const { t } = useTranslation(['transaction', 'settings', 'googleAuthenticator']); //TODO: convert all to use specific page (e.g. bank-detail.json)
    const otpNs = 'otp:otp';

    // redux
    const authSettingsReducer = useSelector((state) => state.authSettingsReducer);
    const _msCryptoAddressModule = authSettingsReducer?.processedData?.[MODULE_TYPE.CRYPTO_ADDRESS];
    const language = useSelector((state) => state.language);
    const portal = useSelector((state) => state.portal);
    const addCryptoAddressRequireOTP = _msCryptoAddressModule?.gaEnabled ? false : portal?.settings?.addCryptoAddressRequireOTP; // new flow OTP enabled, force ignored this old flow setting.

    // state
    const getInitialFields = () => ({
        [CAF.COIN]: { value: '', isMandatory: true, errMsg: '' },
        [CAF.NETWORK]: { value: '', isMandatory: true, errMsg: '' },
        [CAF.ADDRESS]: { value: '', isMandatory: true, errMsg: '' },
        ...(addCryptoAddressRequireOTP && {
            [CAF.OTP]: { value: '', isMandatory: true, errMsg: '' },
        }),
        ...(_msCryptoAddressModule?.gaEnabled && {
            [CAF.GA]: { value: '', isMandatory: true, errMsg: '' },
        }),
    });

    const [coinAndNetworkApiData, setCoinAndNetworkApiData] = useState(false);
    const [supportedCoinAndNetworkApiData, setSupportedCoinAndNetworkApiData] = useState(false);
    const [coins, setCoins] = useState([]);
    const [networks, setNetworks] = useState(null);
    const [fields, setFields] = useState(getInitialFields);
    const [changedField, setChangedField] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(0);

    // custom var
    const _verificationType = window.isAffiliate ? OTP_VERIFICATION_TYPE.AFF_CRYPTO_ADDRESS : OTP_VERIFICATION_TYPE.CRYPTO_ADDRESS;

    useEffect(() => {
        const getCoinAndNetwork = async () => {
            const data = await window.SPL_Transaction.getCoinandNetwork();
            setCoinAndNetworkApiData(data);
        };
        const getSupportedCoinAndNetwork = async () => {
            const data = await window.SPL_Transaction.getCryptWithdrawBank();
            const _coins = Object.keys(data?.coinAndSupportNetwork); // if passed coin, array only show the coin.
            setSupportedCoinAndNetworkApiData(data?.coinAndSupportNetwork);
            setCoins(_coins);
        };
        getCoinAndNetwork();
        getSupportedCoinAndNetwork();
    }, [language?.countryLanguageKey]);

    const onCoinChange = (coin) => {
        setFields((prevFields) => ({
            ...prevFields,
            coin: { ...prevFields.coin, value: coin },
            network: { ...prevFields.network, value: null },
        }));
        getNetworksByCoin(coin);
    };

    const getNetworksByCoin = (coin) => {
        // existing portal code, not sure purpose of getting _oriNetworks, since _supportedNetwork got full data jor
        const _supportedNetworks = supportedCoinAndNetworkApiData?.[coin];
        const _oriNetworks = coinAndNetworkApiData?.[coin];
        const _processedNetworks = (_oriNetworks || []).filter((network) => _supportedNetworks.includes(network));
        setNetworks(_processedNetworks);
    };

    const onNetworkChange = (network) => {
        setFields((prevFields) => ({
            ...prevFields,
            network: { ...prevFields.network, value: network },
        }));
    };

    const onInputFieldChange = (event) => {
        const { name, value } = event.target;
        setFields((prevFields) => ({
            ...prevFields,
            [name]: { ...prevFields?.[name], value: value },
        }));
        setChangedField(name);
    };
    useEffect(() => {
        if (!fields?.[changedField]) {
            return;
        }
        switch (changedField) {
            default:
                break;
        }
    }, [changedField, fields?.[changedField]?.value]);

    // ===== hook useRequestOtp config =====
    const onRequestOtpSuccess = () => {
        setFields((prevFields) => ({
            ...prevFields,
            [CAF.OTP]: { ...prevFields[CAF.OTP], errMsg: '' },
        }));
    };
    const onRequestOtpFail = (err) => {
        const _errMsg = t(`otp:otp.request.message.${err?.key}`, err?.message, { attemptCount: err?.maxAttempt });

        setFields((prevFields) => ({
            ...prevFields,
            [CAF.OTP]: { ...prevFields[CAF.OTP], errMsg: _errMsg },
        }));
    };
    const { requestOtp, isRequestingOtp, isOtpRequested, otpCountdownTimer } = useRequestOtp({
        splParams: {
            contactType: 'CRYPTO',
            cryptoAddress: fields?.[CAF.ADDRESS]?.value,
        },
        onRequestOtpSuccess, // Callback on successful OTP request
        onRequestOtpFail, // Callback on failed OTP request
        isOldFlow: addCryptoAddressRequireOTP,
    });

    const isOtpButtonDisabled = () => {
        const isDisabled = !fields?.[CAF.ADDRESS]?.value || isRequestingOtp || otpCountdownTimer > 0;
        return isDisabled;
    };

    const isSubmitButtonDisabled = () => {
        const hasEmptyField = Object.values(fields).some((field) => !field.value);

        const hasErrorBlocking = Object.entries(fields).some(([key, field]) => {
            // if is otp, skip checking
            const hasError = key !== 'otp' && field.error;
            return hasError;
        });

        const isDisabled = hasEmptyField || hasErrorBlocking || isSubmitting || (addCryptoAddressRequireOTP && !isOtpRequested);
        return isDisabled;
    };

    const onCryptoAddressSubmit = async () => {
        setIsSubmitting(true);
        let payload = {
            address: fields?.[CAF.ADDRESS]?.value,
            cryptoCoin: fields?.[CAF.COIN]?.value,
            cryptoNetwork: fields?.[CAF.NETWORK]?.value,
            ...(_msCryptoAddressModule?.gaEnabled && {
                verificationType: _verificationType,
                verificationMethod: OTP_VERIFICATION_METHOD.GA, // future expect preferMethod, currently new MFA UI only will got GA
                verifyKey: fields?.[CAF.GA]?.value, // future expect otp field
            }),
        };

        try {
            const res = await window.SPL_Transaction.createMemberCryptoAddress(payload, window.isAffiliate);
            if (res?.status === 'F') {
                notification.showNotification('error', res.msg);
                return;
            } else if (res?.status === 'S') {
                // success callback action
                onRequestClose && onRequestClose(); // can consider redux
                onCryptoAddressPopupSubmitSuccessCb && onCryptoAddressPopupSubmitSuccessCb(); // can consider use redux for callback too
            } else {
                if (res?.msg === 'Address duplicate') {
                    notification.showNotification('error', t('global:global.verification.duplicateCryptoAddress', 'Address duplicate'), { autoClose: 2000 });
                } else {
                    notification.showNotification('error', t(res?.msg || res?.detail), { autoClose: 2000 });
                }
            }
            setIsSubmitting(false);
        } catch (err) {
            setIsSubmitting(false);
            console.error(err);
        }
    };

    // massage variables for render
    const getCoinsDropdownConfig = () => {
        const _coins = (coins || []).map((data) => {
            return {
                key: data,
                i18n: data,
                isActive: fields?.[CAF?.COIN]?.value === data,
                onClick: () => {
                    onCoinChange(data);
                },
            };
        });
        return _coins;
    };

    const getNetworksDropdownConfig = () => {
        return (networks || []).map((network, i) => {
            return {
                key: i,
                i18n: network,
                onClick: () => {
                    onNetworkChange(network);
                },
            };
        });
    };

    if (!isOpen) {
        return;
    }

    return (
        <Suspense>
            <Popup isOpen={isOpen} onRequestClose={onRequestClose} overlayClassName={styles.cryptoAddressPopupOverlay} className={styles.cryptoAddressPopup}>
                {/* Load AuthSettings component again, in case user refresh  */}
                <section className={styles.popupInnerContainer}>
                    <PopupHeader
                        className={styles.cryptoAddressPopupHeader}
                        popupTitle={t('transaction:transaction.deposit.crypto.addAddress', 'Add Address')}
                        onRequestClose={onRequestClose}
                    />

                    <PopupBody className={styles.cryptoAddressPopupBody}>
                        {/* Crypto Currency */}
                        <FormField
                            label={t('transaction:transaction.deposit.crypto.cryptoCurrency', 'cryptoCurrency')}
                            inputClassName={`fs-16 ${styles.formSelect}`}
                            className={styles.formField}
                            isMandatory={true}
                            name={CAF?.COIN}
                            value={fields?.[CAF?.COIN]?.value}
                            layout='vertical'
                            isSelectDropDown={true}
                            selectedLabel={fields?.[CAF?.COIN]?.value || t('transaction:transaction.deposit.crypto.selectCryptoCoin')}
                            dropdownItems={getCoinsDropdownConfig() || []}
                        />

                        {/* Network */}
                        <FormField
                            label={t('transaction:transaction.deposit.crypto.network', 'Network')}
                            inputClassName={`fs-16 ${styles.formSelect}`}
                            className={styles.formField}
                            isMandatory={true}
                            name={CAF?.NETWORK}
                            value={fields?.[CAF?.NETWORK]?.value}
                            layout='vertical'
                            isSelectDropDown={true}
                            selectedLabel={fields?.[CAF?.NETWORK]?.value || t('transaction:transaction.deposit.crypto.selectNetwork')}
                            dropdownItems={getNetworksDropdownConfig() || []}
                        />

                        {/* Crypto Address */}
                        <FormField
                            label={t('transaction:transaction.deposit.crypto.address', 'Address')}
                            className={styles.formField}
                            inputClassName={`fs-16 ${styles.formInput}`}
                            isMandatory={true}
                            name={CAF.ADDRESS}
                            value={fields?.[CAF.ADDRESS]?.value}
                            errorMessage={t(fields?.[CAF.ADDRESS]?.errMsg, fields?.[CAF.ADDRESS]?.errMsg)}
                            onChange={onInputFieldChange}
                            layout='vertical'
                        />

                        {addCryptoAddressRequireOTP && (
                            <OtpFormField
                                label={'OTP'}
                                isMandatory={true}
                                className={styles.formField}
                                inputClassName={`fs-16 ${styles.formInput}`}
                                name={CAF.OTP}
                                value={fields?.[CAF.OTP]?.value}
                                onChange={onInputFieldChange}
                                disabled={isOtpButtonDisabled()}
                                onClick={requestOtp}
                                buttonText={t(`${otpNs}.button.getOtp`, 'Get OTP') + ` ${otpCountdownTimer > 0 ? otpCountdownTimer : ''}`}
                                errMsg={fields?.[CAF.OTP]?.errMsg}
                                layout='vertical'
                            />
                        )}

                        {_msCryptoAddressModule?.gaEnabled && (
                            <FormField
                                label={t('googleAuthenticator:googleAuthenticator.field.gaCode.label', 'Google Authentication Code')}
                                className={styles.formField}
                                inputClassName={`fs-16 ${styles.formInput}`}
                                isMandatory={true}
                                name={CAF.GA}
                                value={fields?.[CAF.GA]?.value}
                                errorMessage={t(fields?.[CAF.GA]?.errMsg, fields?.[CAF.GA]?.errMsg)}
                                onChange={onInputFieldChange}
                                layout='vertical'
                            />
                        )}

                        <section className={`${styles.submitBtnSection} `}>
                            <div className={`${styles.submitBtnContainer}`}>
                                <Button className={` primary-btn`} disabled={isSubmitButtonDisabled()} onClick={onCryptoAddressSubmit}>
                                    {t('settings:settings.form.button', 'Save')}
                                </Button>
                            </div>
                        </section>
                    </PopupBody>
                </section>
            </Popup>
        </Suspense>
    );
};

export default AddCryptoAddressPopup;
