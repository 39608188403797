import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SVGElements from 'root/components/SVGElements';

const controller = {
    vm: null,
    init(vm) {
        this.vm = vm;
        this.vm.state = {
            userObj: {
                playerId: '',
                playerPassword: '',
            },
            scrGuide: [],
            navigation: <SVGElements className='icon-arrow-prev' name='arrow-down-icon' />,
            scrUrl: '',
        };
    },

    getScrGuide() {
        let scrGuide = [],
            bannerElement = '';

        for (let i = 0; i < 11; i++) {
            bannerElement = generateScrGuideElement(i);
            scrGuide.push(bannerElement);
        }

        controller.vm.setState({ scrGuide: scrGuide });

        function generateScrGuideElement(i) {
            return {
                content: (
                    <div>
                        <LazyLoadImage src={`/public/html/images/slots/918kiss/${i + 1}.png`} />
                    </div>
                ),
            };
        }
    },

    getScrUrl() {
        let domain = '';

        if (window.location.hostname === 'localhost') {
            domain = window.tempDomain;
        } else {
            domain = window.location.hostname;
        }

        var param = {
            domain: domain,
            provider: 'SCR2',
        };

        window.SPL_Provider.getScrUrl(param).then((data) => {
            controller.vm.setState({ scrUrl: data.gameLink });
        });
    },

    getGameLoginCredential() {
        let domain = '';

        if (window.location.hostname === 'localhost') {
            domain = window.tempDomain;
        } else {
            domain = window.location.hostname;
        }

        let params = {
            domain: domain,
            provider: 'SCR2',
        };

        window.SPL_Provider.getGameSession(params).then((userObj) => {
            controller.vm.setState({ userObj: userObj });
        });
    },

    downloadScrApp() {
        window.SPL_Other.openNewWindow(controller.vm.state.scrUrl);
    },
};

export default controller;
